import { FormHelperText, FormLabel, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format, setISODay } from 'date-fns';
import { FormikContextType } from 'formik';
import React from 'react';

import { COLORS } from '~/@components/@theme';
import { Checkbox } from '~/@components/Checkbox';
import { LocalizableText } from '~/@components/LocalizableText';
import { Section } from '~/@components/Section';
import { TimeInput } from '~/@components/TimeInput';
import { WorkTimeDayInput } from '~/graphql';

import { LandfillEditFormValues } from '../LandfillEdit.types';

type DayProps = {
    day: WorkTimeDayInput;
    onChange: (dayNumber: number, input: Partial<WorkTimeDayInput>) => void;
    className: string;
};

const Day = ({ day, onChange, className }: DayProps) => (
    <Grid container spacing={1} alignItems="center" className={className}>
        <Grid item xs={day.isOpen ? 4 : 12}>
            <Checkbox
                checked={day.isOpen}
                onChange={() => onChange(day.dayNumber, { isOpen: !day.isOpen })}
                label={format(setISODay(new Date(), day.dayNumber), 'EEEE')}
            />
        </Grid>
        {day.isOpen && (
            <>
                <Grid item xs={4}>
                    <TimeInput
                        value={day.openFrom}
                        onChange={(_: unknown, time: string) => onChange(day.dayNumber, { ...day, openFrom: time })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TimeInput
                        value={day.openTo}
                        onChange={(_: unknown, time: string) => onChange(day.dayNumber, { ...day, openTo: time })}
                    />
                </Grid>
            </>
        )}
    </Grid>
);

type Props = { formik: FormikContextType<LandfillEditFormValues> };

export const LandfillEditWorkTime = ({ formik }: Props) => {
    const workTime = formik.values.workTime;
    const error = formik.errors.workTime?.toString();

    const handleChange = (dayNumber: number, input: Partial<WorkTimeDayInput>) => {
        const changed = workTime.map(d => (d.dayNumber !== dayNumber ? d : { ...d, ...input }));
        formik.setFieldValue('workTime', changed, true);
    };

    const { root, dayStyle } = useStyles();

    return (
        <Section dense className={root}>
            <FormLabel>{<LocalizableText code={'LandfillForm.openingHours'} />}</FormLabel>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {workTime.slice(0, 5).map(d => (
                        <Day key={d.dayNumber} day={d} onChange={handleChange} className={dayStyle} />
                    ))}
                </Grid>
                <Grid item xs={6}>
                    {workTime.slice(5, 7).map(d => (
                        <Day key={d.dayNumber} day={d} onChange={handleChange} className={dayStyle} />
                    ))}
                </Grid>
            </Grid>
            {error && <FormHelperText error={true}>{error}</FormHelperText>}
        </Section>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: COLORS.grayLighter,
    },
    dayStyle: {
        height: 48,
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        '& .MuiTextField-root': {
            backgroundColor: theme.palette.background.paper,
        },
    },
}));
