import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import Fade, { FadeProps } from '@material-ui/core/Fade';
import React from 'react';

const Transition: React.ElementType<FadeProps> = React.forwardRef(function TransitionComponent(props, ref) {
    return <Fade ref={ref} {...props} />;
});

type Props = Omit<DialogProps, 'open' | 'onClose'> & {
    onClose?: () => void;
    children: React.ReactNode;
};

export const SimpleDialog = (props: Props) => {
    const { onClose, children, ...restProps } = props;

    return (
        <MuiDialog
            open={true}
            scroll="paper"
            maxWidth="md"
            TransitionComponent={Transition}
            onClose={onClose}
            {...restProps}>
            {children}
        </MuiDialog>
    );
};
