import { gql } from '@apollo/client';

export const LocalizationStringFragment = gql`
    fragment LocalizationStringFragment on LocalizationString {
        code
        value
    }
`;

export const LocalizationStringsQuery = gql`
    query LocalizationStringsQuery($language: Language!) {
        localizationStrings(language: $language) {
            ...LocalizationStringFragment
        }
    }

    ${LocalizationStringFragment}
`;

export const LocalizationStringUpdateMutation = gql`
    mutation LocalizationStringUpdateMutation($input: LocalizationStringInput!) {
        updateLocalizationString(input: $input) {
            ...LocalizationStringFragment
        }
    }

    ${LocalizationStringFragment}
`;
