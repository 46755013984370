import React from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { WhatsappIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { showConfirmDialog } from '~/@store/common';
import { IDetailedProject } from '~/@store/projects';
import { useProjectEnableWhatsapp } from '~/@store/projects/project.hooks/useProjectEnableWhatsapp';
import { useSettingsData } from '~/@store/settings';
import { ProjectWhatsappLink } from '~/@views/common/ProjectWhatsappLink';

type Props = { project: IDetailedProject };

export const ProjectWhatsappButton = ({ project }: Props) => {
    const [enableWhatsapp, isEnabling] = useProjectEnableWhatsapp(project.id);
    const { data } = useSettingsData();

    const isWhatsappEnabled = !!data?.settings?.featuresFlags.whatsappEnabled;
    const whatsappGroupLink = project.whatsappGroupLink;

    const onClick = () => {
        showConfirmDialog({
            title: <LocalizableText code="ProjectWhatsapp.enableWhatsappForProject" />,
            render: () => <LocalizableText code="ProjectWhatsapp.enableWhatsappConfirm" />,
            icon: WhatsappIcon,
        }).then(confirmed => confirmed && enableWhatsapp());
    };

    return whatsappGroupLink ? (
        <ProjectWhatsappLink whatsappGroupLink={whatsappGroupLink} />
    ) : isWhatsappEnabled ? (
        <ActionButton disabled={isEnabling} startIcon={<WhatsappIcon />} onClick={onClick}>
            <LocalizableText code="ProjectWhatsapp.enableWhatsapp" />
        </ActionButton>
    ) : null;
};
