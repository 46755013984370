import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const EditIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 25 25" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.71 5.6325C21.1 6.0225 21.1 6.6525 20.71 7.0425L18.88 8.8725L15.13 5.1225L16.96 3.2925C17.35 2.9025 17.98 2.9025 18.37 3.2925L20.71 5.6325ZM3 21.0025V17.2525L14.06 6.1925L17.81 9.9425L6.75 21.0025H3Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
