import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { LandfillStatusRemoveMutation, LandfillStatusRemoveMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../landfillStatuses.queries';

export type LandfillStatusRemoveFunc = (id: string) => Promise<void>;

export const useLandfillStatusRemove = (): [LandfillStatusRemoveFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        LandfillStatusRemoveMutation,
        LandfillStatusRemoveMutationVariables
    >(queries.LandfillStatusRemoveMutation);

    const removeFunc = useCallback(
        (id: string) => {
            return callMutation({
                variables: { id },
                refetchQueries: [
                    {
                        query: queries.LandfillStatusesListQuery,
                        variables: {},
                    },
                ],
            })
                .then(() => {})
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [removeFunc, mutationResult.loading];
};
