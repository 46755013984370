import { UnitOfMeasure } from '@common/enums';

import { DumpLoadMode } from '~/utils/dumpLoad';

export type IDate = Date | null;

export type IDates = { startDate: IDate; endDate: IDate };

export type IFormValues = {
    id: string | null;
    dumpType: {
        id: string;
        name: string;
        tonnsPerCubicM: number | null;
        contaminationLevelAvailable: boolean;
    } | null;
    unitOfMeasure: UnitOfMeasure;
    dates: IDates;
    amount: number;
    comment: string;

    stackability: boolean;
    over50cm: boolean;

    withTOC: boolean;
    TOCPercent: number | null;
    withInvasiveSpecies: boolean;
    invasiveSpecies: string;

    /* misc */
    newComment: string;

    requestQuote: boolean;
    canRequestQuote: boolean;

    mode: DumpLoadMode;
    matchWithPinpointer: boolean;
    landfillName: string;
    landfillAddress: string;

    analysisFile: File | null;
};

export enum FormNames {
    id = 'id',
    dumpType = 'dumpType',
    status = 'status',
    unitOfMeasure = 'unitOfMeasure',
    dates = 'dates',
    amount = 'amount',
    comment = 'comment',

    solidState = 'solidState',
    leachingState = 'leachingState',
    substances = 'substances',

    stackability = 'stackability',
    over50cm = 'over50cm',
    withTransport = 'withTransport',
    withTOC = 'withTOC',
    TOCPercent = 'TOCPercent',
    withInvasiveSpecies = 'withInvasiveSpecies',
    invasiveSpecies = 'invasiveSpecies',

    transportLine = 'transportLine',

    newComment = 'newComment',
    requestQuote = 'requestQuote',
    landfillName = 'landfillName',
    landfillAddress = 'landfillAddress',
    matchWithPinpointer = 'matchWithPinpointer',
}

export type { ProjectQuery_project as IProject, ProjectQuery_project_dumpLoads as IDumpLoad } from '~/graphql';
