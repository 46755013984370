import React from 'react';

import { FilePreview } from './FilePreview';

type Props = {
    file: File;
};

export const NewFilePreview = ({ file }: Props) => {
    const [base64, setBase64] = React.useState('');

    React.useEffect(() => {
        const objectUrl = URL.createObjectURL(file);
        setBase64(objectUrl);

        return () => {
            URL.revokeObjectURL(objectUrl);
            setBase64('');
        };
    }, [file]);

    if (!base64) return null;

    return <FilePreview base64={base64} filename={file.name} />;
};
