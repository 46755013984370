import isEqual from 'lodash/isEqual';

import { FileFormats } from '../enums';
import { isNotNullOrUndefined } from '../validations/types.validation';

/**
 * Format string template:
 *
 * formatString('Hello $1 $2!', 'User', 'Name') -> 'Hello User Name!'
 *
 * @param str string
 * @param values ...Array<string>
 * @returns {string}
 */
export function formatString(str: string, ...values: string[]) {
    return values.reduce((s, v, i) => s.replace('$' + String(i + 1), v), str);
}

const isPdfRegexp = new RegExp(`.${FileFormats.PDF}$`, 'ig');

export const isPdf = (filename: string): boolean => {
    if (!filename) return false;

    return !!filename.match(isPdfRegexp);
};

export const roundDecimalPlaces = (n: number, decimalsPlaces: number): number => {
    const divider = Math.pow(10, decimalsPlaces);

    return Math.round((n + Number.EPSILON) * divider) / divider;
};

export const areEqualArrays = <T = unknown>(arr1: T[], arr2: T[], areEqualItems = isEqual): boolean => {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i += 1) {
        if (!areEqualItems(arr1[i], arr2[i])) return false;
    }

    return true;
};

type Check = (value: string) => boolean;
type Format = (value: string) => string;
const EXT_PREFIX = '+46';

const PHONE_NUMBER_FORMATTERS: { check: Check; format: Format }[] = [
    {
        check: (value: string) => value.startsWith(EXT_PREFIX) && value.length <= 10 && value.length > 3,
        format: (p: string) => `${p.slice(0, 3)} ${p.slice(3)}`,
    },
    {
        check: (value: string) => value.startsWith(EXT_PREFIX) && value.length === 11,
        format: (p: string) => `${p.slice(0, 3)} ${p.slice(3, 5)} ${p.slice(5)}`,
    },
    {
        check: (value: string) => value.startsWith(EXT_PREFIX) && value.length === 12,
        format: (p: string) => `${p.slice(0, 3)} ${p.slice(3, 6)} ${p.slice(6)}`,
    },
    {
        check: (value: string) => value.startsWith(EXT_PREFIX) && value.length >= 13,
        format: (p: string) => `${p.slice(0, 3)} ${p.slice(3, 7)} ${p.slice(7)}`,
    },
];

export const formatUserPhoneNumber = (value: string | null | undefined): string | null | undefined =>
    value && (PHONE_NUMBER_FORMATTERS.find(({ check }) => check(value))?.format(value) || value);

const GAP_SYMBOLS = [' ', '-'];
const REPLACE_INPUT_SYMBOLS_REGEX = new RegExp(`(?!^\\+)[^\\d${GAP_SYMBOLS.join('')}]`, 'g');

export const cleanDigits = (str: string) => str.replace(/\D/g, '');

const replaceSymbolRepeatsWithSymbol = (val: string, sym: string) => val.replace(new RegExp(`${sym}{2,}`, 'g'), sym);

export const transformPhoneNumberInput = (input?: string | null): string | null | undefined => {
    if (!input) return input;

    const value = input.replace(REPLACE_INPUT_SYMBOLS_REGEX, '');

    return GAP_SYMBOLS.reduce((val, sym) => replaceSymbolRepeatsWithSymbol(val, sym), value);
};

export const splitLineByWords = (line: string) =>
    line
        .split(' ')
        .map(w => w.trim())
        .filter(s => s !== '');

export const compareString = (a: string, b: string): 1 | 0 | -1 => {
    if (a === b) return 0;

    return a > b ? 1 : -1;
};

/**
 * Check that all values in array is unique.
 *
 * @param {array} arr array of values
 * @return {boolean} is all values unique.
 */
export function isUnique(arr: unknown[]): boolean {
    return new Set(arr).size === arr.length;
}

export function getDuplicate<T>(arr: T[]): T | undefined {
    return arr.find((el, ind) => arr.indexOf(el) !== ind);
}

export function makeRecord<T, Key extends string, Value>(
    arr: T[],
    key: (v: T) => Key,
    value: (v: T) => Value
): Record<Key, Value> {
    const record = {} as Record<Key, Value>;
    for (const item of arr) {
        record[key(item)] = value(item);
    }

    return record;
}

export function makeMap<T>(arr: T[], key: (v: T) => string): Map<string, T> {
    return arr.reduce((map, v) => {
        map.set(key(v), v);

        return map;
    }, new Map<string, T>());
}

export function makeMap2<A, B>(arr: A[], key: (a: A) => string, value: (a: A) => B): Map<string, B> {
    return arr.reduce((map, v) => {
        map.set(key(v), value(v));

        return map;
    }, new Map<string, B>());
}

export function filterNotNull<T>(array: Array<T | null | undefined>): T[] {
    return array.filter(isNotNullOrUndefined);
}
