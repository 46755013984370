import './ProjectMassSchedule.scss';

import { replaceRouteParams, routes } from '@common/routes';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useState, VFC } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { GranularitySwitchValue, SochiGranularitySwitch } from '~/@sochi-components/SochiGranularitySwitch';
import { SochiInfoPanel } from '~/@sochi-components/SochiInfoPanel';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import i18n from '~/i18n';

import { Colors, Granularity } from '../../../../../../../common/enums';
import type { ProjectCommonDataProps } from '../../ProjectPage/ProjectPage';
import { MassSchedules } from '../MassSchedules';
import { LoadStatus } from '../types';

type ProjectMassScheduleProps = ProjectCommonDataProps;

export const ProjectMassSchedule: VFC<ProjectMassScheduleProps> = ({ project, history, match: { params } }) => {
    const { className, element } = useFunctionalBem(ProjectMassSchedule);

    const [filter, setFilter] = useState<GranularitySwitchValue>(Granularity.week);

    const goToTheOffers = useCallback(() => {
        history.push({
            pathname: replaceRouteParams(routes.sochi.offerOutgoing, {
                projectId: project.id,
            }),
        });
    }, [history, project]);

    const { massId } = params;

    const mass = project.dumpLoads.find(el => el.id === massId);

    if (isEmpty(mass)) return <div className={className} />;

    return (
        <div className={className}>
            {mass?.status === LoadStatus.REQUESTED ? (
                <SochiInfoPanel text={<LocalizableText code={'InfoMessagesSochi.ACTIVE_OFFER'} />} color={Colors.green}>
                    <Button className={element('button-contained')} variant="contained" onClick={goToTheOffers}>
                        <LocalizableText code={'goToOffers'} />
                    </Button>
                </SochiInfoPanel>
            ) : (
                <>
                    <SochiTitle title={`"${mass!.dumpType?.name || i18n.NA}" ${i18n.transportSchedule}`}>
                        <SochiGranularitySwitch value={filter} onChange={setFilter} />
                    </SochiTitle>
                    <MassSchedules massData={mass!} scheduleViewFilter={filter} />
                </>
            )}
        </div>
    );
};
