import { formatString } from '@common/utils';
import { isSameDay } from 'date-fns';
import { FormikConfig, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { IDeliveryLine, UpdateDeliveryLinesFunc } from '~/@store/deliveryLines';
import { DeliveryLineUpdateInput } from '~/graphql';
import i18n from '~/i18n';
import { hasOwnProperty } from '~/utils';
import { parseDateFrom } from '~/utils/date';
import { booleanToDeliveryLineStatus, deliveryLineStatusToBoolean } from '~/utils/deliveryLine';

export type IReceiptFieldValue = {
    receiptUrl: string | null;
    file?: File | null;
};

export type IDeliveryLineValues = {
    weight: number;
    date: Date | null;
    truckRegistrationNumber: string;
    truckCapacity: number | null;
    customerInvoice: string;
    transportInvoice: string;
    landfillInvoice: string;
    receipt: IReceiptFieldValue;
    verified: boolean;
    comment: string;
    line: IDeliveryLine;
};

const isEqualValues = (val1: IDeliveryLineValues, val2: IDeliveryLineValues): boolean =>
    Object.keys(val1).reduce<boolean>((acc, key) => {
        if (!acc) return false;

        if (key === 'date') return val1.date && val2.date ? isSameDay(val1.date, val2.date) : val1.date === val2.date;

        if (key === 'receipt') return !val1.receipt.file && !val2.receipt.file;

        return hasOwnProperty(val1, key) && hasOwnProperty(val2, key) && val1[key] === val2[key];
    }, true);

export const transformInputData = (dl: IDeliveryLine): IDeliveryLineValues => ({
    weight: dl.weight,
    date: parseDateFrom(dl.plannedStartDate),
    truckRegistrationNumber: dl.truckRegistrationNumber || '',
    truckCapacity: dl.truckCapacity,
    customerInvoice: dl.customerInvoiceNumber || '',
    transportInvoice: dl.transportationInvoiceNumber || '',
    landfillInvoice: dl.landfillInvoiceNumber || '',
    receipt: {
        receiptUrl: dl.receiptUrl,
    },
    verified: deliveryLineStatusToBoolean(dl.status),
    comment: dl.comment || '',
    line: dl,
});

export type IDeliveryLinesMassEditFormValues = {
    deliveryLines: IDeliveryLineValues[];
};

export const useDeliveryLinesMassEditFormikContext = () => useFormikContext<IDeliveryLinesMassEditFormValues>();

export function getDeliveryLinesMassEditFormikConfig(
    deliveryLines: IDeliveryLineValues[],
    updateDeliveryLines: UpdateDeliveryLinesFunc
): FormikConfig<IDeliveryLinesMassEditFormValues> {
    return {
        initialValues: {
            deliveryLines,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            deliveryLines: Yup.array().of(
                Yup.object().shape({
                    weight: Yup.number().moreThan(0).required(i18n.errorCodes.DELIVERY_LINE_WEIGHT_CANNOT_BE_EMPTY),
                    date: Yup.date().nullable().required(i18n.errorCodes.DELIVERY_LINE_DATE_CANNOT_BE_EMPTY),
                    truckRegistrationNumber: Yup.string()
                        .nullable()
                        .trim()
                        .required(i18n.errorCodes.DELIVERY_LINE_TRUCK_NUMBER_CANNOT_BE_EMPTY),
                    customerInvoice: Yup.string().nullable(),
                    transportInvoice: Yup.string().nullable(),
                    landfillInvoice: Yup.string().nullable(),
                    truckCapacity: Yup.number()
                        .nullable()
                        .min(0, formatString(i18n.mustBeGreaterThanZero, i18n.DeliveryLinesTable.truckCapacity)),
                })
            ),
        }),
        onSubmit: async values => {
            const changedLines = values.deliveryLines.filter((dl, index) => !isEqualValues(dl, deliveryLines[index]!));
            const preparedLines: DeliveryLineUpdateInput[] = changedLines.map(dl => ({
                id: dl.line.id,
                weight: dl.weight,
                plannedStartDate: dl.date?.toISOString(),
                truckRegistrationNumber: dl.truckRegistrationNumber,
                truckCapacity: dl.truckCapacity,
                customerInvoiceNumber: dl.customerInvoice,
                transportationInvoiceNumber: dl.transportInvoice,
                landfillInvoiceNumber: dl.landfillInvoice,
                ...(dl.receipt.file && { receipt: dl.receipt.file }),
                status: booleanToDeliveryLineStatus(dl.verified),
                comment: dl.comment,
            }));

            await updateDeliveryLines(preparedLines);
        },
    };
}
