import './LoginCarousel.scss';

import React, { Component } from 'react';

import { getBem } from '~/@sochi-components/@bem';

import i18n from '../../../../i18n';
import grass from '../../assets/grass.png';
import road from '../../assets/road.png';
import truck from '../../assets/Truck.png';

interface Slide {
    image: typeof grass;
    alt: string;
    header: string;
    text: string;
}

const getSlides = (): Slide[] => {
    return [
        { image: grass, alt: 'grass', header: i18n.loginCarouselHeader1, text: i18n.loginCarouselText1 },
        { image: truck, alt: 'truck', header: i18n.loginCarouselHeader2, text: i18n.loginCarouselText2 },
        { image: road, alt: 'road', header: i18n.loginCarouselHeader3, text: i18n.loginCarouselText3 },
    ];
};

type ILoginCarouselState = {
    slides: Slide[];
    step: number;
};

export class LoginCarousel extends Component<{}, ILoginCarouselState> {
    interval?: ReturnType<typeof setInterval>;

    bem = getBem(this);
    state: ILoginCarouselState = { slides: [], step: 0 };

    componentDidMount() {
        const slideStepDelay = 5000;
        this.interval = setInterval(this.step, slideStepDelay);

        if (!this.state.slides.length) {
            this.setState({ slides: getSlides() });
        }
    }

    componentWillUnmount() {
        this.interval && clearInterval(this.interval);
    }

    step = () => {
        this.setState(({ slides, step }) => ({
            step: (step + 1) % slides.length,
        }));
    };

    render() {
        const { className, element } = this.bem;
        const { slides } = this.state;

        return (
            <div className={className}>
                {slides.map((slide, index) => (
                    <div key={slide.alt} className={element('slide-content', { hidden: this.state.step !== index })}>
                        <img src={slide.image} alt={slide.alt} />
                        <div className={element('information')}>
                            <h4 className={element('title')}>{slide.header}</h4>
                            <p className={element('text')}>{slide.text}</p>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
