import {
    AbilityCan,
    AbilitySubjects,
    DumpLoadFields,
    LoadStatus,
    ProjectStatus,
    UserContextRole,
    UserRole,
} from '../../enums';
import type { IAbilityFunction } from '../abilities.types';
import { ICaslCondition } from '../utils';
import { DumpLoadSubject, IProjectDumpLoadCalculatedSubject } from './projectTypes';

const {
    transportSchedule,
    comments,
    landfill,
    destinationInfo,
    priceData,
    destination,
    destinationLandfill,
    distance,
    places,
    toxicAnalysisApprovedExternally,
    siteConfirmed,
    creditCheck,
    yourOrderNumber,
    contractSigned,
    landfillOrdered,
    beastMaterialId,
} = DumpLoadFields;

const { DELETED } = ProjectStatus;
const { DRAFT, REQUESTED, CONFIRMED, ORDERED, IN_PROGRESS, DONE, DISCARDED, NOT_ORDERED } = LoadStatus;
const { CRUD, READ, UPDATE, CREATE } = AbilityCan;
const { ADMIN, EMPLOYEE, EXTERNAL_RECEIVER, EXCAVATOR_OPERATOR, DRIVER } = UserRole;
const { PROJECT_USER, GUEST, PROJECT_DRIVER, PROJECT_EXCAVATOR_OPERATOR } = UserContextRole;
const { FIELD_AFFECT_DESTINATION, DUMP_LOAD_DECLARATION, STATUS_EXCLUSIVELY, INTERNAL_PRICES } = DumpLoadSubject;

export function defineAbilitiesForProjectDumpLoad(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<IProjectDumpLoadCalculatedSubject>;

    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _allow(can, AbilitySubjects.PROJECT_DUMPLOAD, shape);

    const forbid = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _forbid(can, AbilitySubjects.PROJECT_DUMPLOAD, shape);

    // Admins

    allow(CRUD, { relationRole: { $in: [ADMIN, EMPLOYEE] } });

    forbid(UPDATE, {
        relationRole: { $in: [ADMIN, EMPLOYEE] },
        dumpLoadSubject: { $all: [DISCARDED, FIELD_AFFECT_DESTINATION] },
    });
    forbid(UPDATE, {
        relationRole: { $in: [ADMIN, EMPLOYEE] },
        dumpLoadSubject: { $all: [NOT_ORDERED, FIELD_AFFECT_DESTINATION] },
    });
    forbid(UPDATE, {
        relationRole: { $in: [ADMIN, EMPLOYEE] },
        dumpLoadSubject: { $all: [CONFIRMED, FIELD_AFFECT_DESTINATION] },
    });
    forbid(UPDATE, {
        relationRole: { $in: [ADMIN, EMPLOYEE] },
        dumpLoadSubject: { $all: [ORDERED, FIELD_AFFECT_DESTINATION] },
    });
    forbid(UPDATE, {
        relationRole: { $in: [ADMIN, EMPLOYEE] },
        dumpLoadSubject: { $all: [IN_PROGRESS, FIELD_AFFECT_DESTINATION] },
    });
    forbid(UPDATE, {
        relationRole: { $in: [ADMIN, EMPLOYEE] },
        dumpLoadSubject: { $all: [DONE, FIELD_AFFECT_DESTINATION] },
    });

    // User

    allow(CRUD, { relationRole: PROJECT_USER });

    // Guest

    allow(READ, { relationRole: GUEST });

    // All external_receivers

    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, projectSubject: DELETED });
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: DISCARDED });

    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: NOT_ORDERED });

    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [REQUESTED, transportSchedule] } });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [CONFIRMED, transportSchedule] } });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [ORDERED, transportSchedule] } });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [IN_PROGRESS, transportSchedule] } });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [DONE, transportSchedule] } });

    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [REQUESTED, DUMP_LOAD_DECLARATION] } });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [ORDERED, DUMP_LOAD_DECLARATION] } });
    forbid(UPDATE, {
        relationRole: EXTERNAL_RECEIVER,
        dumpLoadSubject: { $all: [IN_PROGRESS, DUMP_LOAD_DECLARATION] },
    });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [DONE, DUMP_LOAD_DECLARATION] } });

    // Drivers

    allow(READ, { relationRole: PROJECT_DRIVER, dumpLoadSubject: IN_PROGRESS });

    // Excavator

    allow(READ, { relationRole: PROJECT_EXCAVATOR_OPERATOR, dumpLoadSubject: IN_PROGRESS });

    // Fields affects destination

    forbid(UPDATE, {
        relationRole: EXTERNAL_RECEIVER,
        dumpLoadSubject: { $all: [REQUESTED, FIELD_AFFECT_DESTINATION] },
    });
    forbid(UPDATE, {
        relationRole: EXTERNAL_RECEIVER,
        dumpLoadSubject: { $all: [CONFIRMED, FIELD_AFFECT_DESTINATION] },
    });
    forbid(UPDATE, {
        relationRole: EXTERNAL_RECEIVER,
        dumpLoadSubject: { $all: [ORDERED, FIELD_AFFECT_DESTINATION] },
    });
    forbid(UPDATE, {
        relationRole: EXTERNAL_RECEIVER,
        dumpLoadSubject: { $all: [IN_PROGRESS, FIELD_AFFECT_DESTINATION] },
    });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [DONE, FIELD_AFFECT_DESTINATION] } });

    // Prices and landfill info

    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: INTERNAL_PRICES });

    forbid(CRUD, { relationRole: EXCAVATOR_OPERATOR, dumpLoadSubject: priceData });
    forbid(CRUD, { relationRole: DRIVER, dumpLoadSubject: priceData });

    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [DRAFT, priceData] } });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [REQUESTED, priceData] } });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [NOT_ORDERED, priceData] } });

    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [DRAFT, destinationLandfill] } });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [REQUESTED, destinationLandfill] } });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [CONFIRMED, destinationLandfill] } });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [NOT_ORDERED, destinationLandfill] } });
    allow(READ, { relationRole: EXCAVATOR_OPERATOR, dumpLoadSubject: { $all: [IN_PROGRESS, destinationLandfill] } });

    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [DRAFT, distance] } });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [REQUESTED, distance] } });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [CONFIRMED, distance] } });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [NOT_ORDERED, distance] } });
    allow(READ, { relationRole: EXCAVATOR_OPERATOR, dumpLoadSubject: { $all: [IN_PROGRESS, distance] } });

    forbid(UPDATE, { dumpLoadSubject: { $all: [DRAFT, priceData] } });
    forbid(UPDATE, { dumpLoadSubject: { $all: [ORDERED, priceData] } });
    forbid(UPDATE, { dumpLoadSubject: { $all: [IN_PROGRESS, priceData] } });
    forbid(UPDATE, { dumpLoadSubject: { $all: [DONE, priceData] } });
    forbid(UPDATE, { dumpLoadSubject: { $all: [DISCARDED, priceData] } });
    forbid(UPDATE, { dumpLoadSubject: { $all: [NOT_ORDERED, priceData] } });

    forbid(UPDATE, { dumpLoadSubject: { $all: [ORDERED, destination] } });
    forbid(UPDATE, { dumpLoadSubject: { $all: [IN_PROGRESS, destination] } });
    forbid(UPDATE, { dumpLoadSubject: { $all: [DONE, destination] } });
    forbid(UPDATE, { dumpLoadSubject: { $all: [DISCARDED, destination] } });
    forbid(UPDATE, { dumpLoadSubject: { $all: [NOT_ORDERED, destination] } });

    forbid(READ, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: places });

    // Admin only fields

    const adminOnlyFields = [
        comments,
        landfill,
        destinationInfo,
        toxicAnalysisApprovedExternally,
        siteConfirmed,
        creditCheck,
        yourOrderNumber,
        contractSigned,
        landfillOrdered,
        beastMaterialId,
    ];

    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $in: adminOnlyFields } });
    forbid(CRUD, { relationRole: DRIVER, dumpLoadSubject: { $in: adminOnlyFields } });
    forbid(CRUD, { relationRole: EXCAVATOR_OPERATOR, dumpLoadSubject: { $in: adminOnlyFields } });

    // Admin edit only fields

    forbid([CREATE, UPDATE], { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: toxicAnalysisApprovedExternally });
    forbid([CREATE, UPDATE], { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: priceData });
    forbid([CREATE, UPDATE], { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: destination });

    // Statuses exclusively

    allow(UPDATE, {
        relationRole: PROJECT_USER,
        dumpLoadSubject: { $all: [DRAFT, STATUS_EXCLUSIVELY] },
    });
    allow(UPDATE, {
        relationRole: PROJECT_USER,
        dumpLoadSubject: { $all: [CONFIRMED, STATUS_EXCLUSIVELY] },
    });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [REQUESTED, STATUS_EXCLUSIVELY] } });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [ORDERED, STATUS_EXCLUSIVELY] } });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [IN_PROGRESS, STATUS_EXCLUSIVELY] } });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [DONE, STATUS_EXCLUSIVELY] } });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [DISCARDED, STATUS_EXCLUSIVELY] } });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, dumpLoadSubject: { $all: [NOT_ORDERED, STATUS_EXCLUSIVELY] } });

    forbid([CREATE, UPDATE], { projectSubject: DELETED });
}
