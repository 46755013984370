import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { LandfillRemoveCertificateMutation, LandfillRemoveCertificateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../landfills.queries';

type RemoveFunc = (fileId: string) => Promise<unknown>;

export const useLandfillRemoveCertificateMutation = (landfill: { id: string }): [RemoveFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        LandfillRemoveCertificateMutation,
        LandfillRemoveCertificateMutationVariables
    >(queries.LandfillRemoveCertificateMutation);

    const removeCert = useCallback<RemoveFunc>(
        fileId => {
            const { id } = landfill;

            return callMutation({
                variables: { landfillId: id, certId: fileId },
                refetchQueries: [
                    {
                        query: queries.AdminLandfillQuery,
                        variables: { id },
                    },
                ],
            }).catch(handleApolloError);
        },
        [landfill, callMutation]
    );

    return [removeCert, mutationResult.loading];
};
