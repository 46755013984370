import React from 'react';

import { Button } from '~/@components/Button';
import { LandfillFragment } from '~/graphql';
import i18n from '~/i18n';

import { showLandfillBatchReportDialog } from './LandfillBatchReportDialog';
import { showLandfillBatchSelectProjectDialog } from './LandfillBatchSelectProjectDialog';

type Props = {
    landfill: LandfillFragment;
};

export const LandfillBatchReportButton = ({ landfill }: Props) => {
    const handleClick = async () => {
        const projectName = await showLandfillBatchSelectProjectDialog(landfill);

        if (!projectName) return;

        showLandfillBatchReportDialog(landfill, projectName);
    };

    return (
        <Button variant="contained" color="primary" onClick={handleClick}>
            {i18n.report}
        </Button>
    );
};
