import { observer } from 'mobx-react';
import React, { Fragment } from 'react';

import { adminDialogStore } from '~/@store/common/AdminDialogStore';

export const AdminDialogContainer = observer(() => {
    const dialogs = adminDialogStore.dialogs.slice();

    return (
        <>
            {dialogs.map(d => (
                <Fragment key={d.key}>{d.render(d.resolve)}</Fragment>
            ))}
        </>
    );
});
