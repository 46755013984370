import Button from '@material-ui/core/Button';
import React, { FC } from 'react';

import { TranslateIcon } from '~/@components/Icon';
import { useLocalizationEditToggle } from '~/@store/common';
import i18n from '~/i18n';

type Props = {
    className?: string;
};

export const LocalizationModeButton: FC<Props> = ({ className }) => {
    const { enabled, change } = useLocalizationEditToggle();

    return (
        <Button
            onClick={() => change(!enabled)}
            size="large"
            startIcon={<TranslateIcon color={enabled ? 'secondary' : undefined} />}
            className={className}
            title={i18n.localizationMode}
        />
    );
};
