import React, { FC } from 'react';

import { Button } from '~/@components/Button';
import { Checkbox } from '~/@components/Checkbox';
import { FormikTextField } from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import { useDumpLoadAbilities } from '~/@store/dumpLoads';
import { DumpLoadEditBeastMaterialSelect } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm/DumpLoadEditFields/DumpLoadEditBeastMaterialSelect';
import { useDumpLoadFormikContext } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm/DumpLoadEditFormik';
import { showDumpLoadPeppolSettingsDialog } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm/DumpLoadPeppolSettingsDialog';

export const DumpLoadEditPeppolFields: FC = () => {
    const formik = useDumpLoadFormikContext();
    const { dumpLoad } = formik.values;
    const { canUpdateDumpLoad } = useDumpLoadAbilities(dumpLoad);

    const disabled = !canUpdateDumpLoad;

    return (
        <>
            <Grid item xs={4}>
                <DumpLoadEditBeastMaterialSelect />
            </Grid>
            <Grid item xs={4}>
                <Button
                    onClick={() => showDumpLoadPeppolSettingsDialog(dumpLoad)}
                    fullWidth
                    variant="contained"
                    color="primary">
                    <LocalizableText code={'DespatchAdviceSettingsDialog.settings'} />
                </Button>
            </Grid>
            <Grid item xs={4}>
                <Checkbox
                    label={<LocalizableText code={'DespatchAdviceSettingsDialog.connected'} />}
                    onChange={() => {}}
                    disabled
                    checked={dumpLoad.hasPeppolSettings}
                />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField
                    label={<LocalizableText code={'DumpLoadCard.yourOrderNumber'} />}
                    name="yourOrderNumber"
                    formik={formik}
                    disabled={disabled}
                />
            </Grid>
        </>
    );
};
