import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { DumpTypeUpdateMutation, DumpTypeUpdateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../dumpType.queries';
import { IDumpType, UpdateDumpTypeFunc } from '../types';

export const useDumpTypeUpdateMutation = (dumpType: IDumpType | null): [UpdateDumpTypeFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<DumpTypeUpdateMutation, DumpTypeUpdateMutationVariables>(
        queries.DumpTypeUpdateMutation
    );

    const updateDumpType = useCallback<UpdateDumpTypeFunc>(
        input => {
            if (!dumpType) return Promise.reject(new Error('Cannot update without dumpType'));

            const { id } = dumpType;

            return callMutation({
                variables: { input: { ...input } },
                refetchQueries: [
                    {
                        query: queries.DumpTypeByIdQuery,
                        variables: { id },
                    },
                ],
                awaitRefetchQueries: true,
            }).catch(handleApolloError);
        },
        [callMutation, dumpType]
    );

    return [updateDumpType, mutationResult.loading];
};
