import React from 'react';

import { bugsnagNotify } from '~/bugsnag';
import i18n from '~/i18n';

type Props = {
    name: string;
};

const errorStyle = { padding: 40, fontSize: 20 };

export class ErrorBoundary extends React.Component<Props> {
    state = { hasError: false };

    componentDidCatch(error: Error, { componentStack }: React.ErrorInfo) {
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        bugsnagNotify('Error boundary: ' + this.props.name, { message: error.message }, componentStack);
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) return <div style={errorStyle}>{i18n.errorRetry}</div>;

        return this.props.children;
    }
}
