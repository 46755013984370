import { ApolloError } from '@apollo/client';

import { IUser } from '~/@store/users';

import { HttpCodes, UserContextRole, UserRole } from '../../../common/enums';
import i18n from '../i18n';
import { handleApolloError } from './index';

export type UserContextRoleItem = {
    id: string;
    name: string;
    value: UserContextRole;
};

export type IUserInfo = {
    id: string;
    name: string | null;
    surname: string | null;
};

export const isExternalReceiver = ({ role }: IUser): boolean => role === UserRole.EXTERNAL_RECEIVER;

export const getUserFullName = (user: IUserInfo): string => {
    return `${user.name || i18n.NA}${user.surname ? ' ' + user.surname : ''}`;
};

export const handleUserBlockedNetworkError = (
    error: ApolloError,
    refetch?: (...args: unknown[]) => Promise<unknown>
): Promise<never> => {
    const networkError: (Error & { statusCode?: number }) | null = error.networkError;
    const statusCode = networkError?.statusCode;

    switch (statusCode) {
        case HttpCodes.LOCKED:
        case HttpCodes.UNAUTHORIZED:
            return Promise.reject(error); // WITHOUT ERROR MESSAGES
        default:
            return handleApolloError(error, refetch);
    }
};

export enum UserStatus {
    Blocked = 'Blocked',
    Enabled = 'Enabled',
    EmailConfirmed = 'EmailConfirmed',
    Registered = 'Registered',
}

export const UserStatusTranslation = {
    [UserStatus.Blocked]: i18n.UserStatus.BLOCKED,
    [UserStatus.Enabled]: i18n.UserStatus.ENABLED,
    [UserStatus.EmailConfirmed]: i18n.UserStatus.EMAIL_CONFIRMED,
    [UserStatus.Registered]: i18n.UserStatus.REGISTERED,
};

type UserType = {
    confirmed?: boolean | null;
    verified?: boolean | null;
    blocked?: boolean | null;
};

export const getUserStatus = ({ blocked, verified, confirmed }: UserType): UserStatus => {
    if (blocked) return UserStatus.Blocked;

    if (verified) return UserStatus.Enabled;

    if (confirmed) return UserStatus.EmailConfirmed;

    return UserStatus.Registered;
};

export const userStatusToFilterValues: Record<UserStatus, UserType> = {
    [UserStatus.Blocked]: { blocked: true },
    [UserStatus.Enabled]: { blocked: false, verified: true },
    [UserStatus.EmailConfirmed]: { blocked: false, verified: false, confirmed: true },
    [UserStatus.Registered]: { blocked: false, verified: false, confirmed: false },
};
