import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';

import { DeleteButton, showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { FormCardButtons, FormCardContainer } from '~/@components/FormCard';
import { FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import {
    ILandfillStatus,
    useLandfillStatusCreate,
    useLandfillStatusPermissions,
    useLandfillStatusRemove,
    useLandfillStatusUpdate,
} from '~/@store/landfillStatuses';
import i18n from '~/i18n';

import { getLandfillStatusFormikConfig } from './LandfillStatusFormik';
import { LandfillStatusIconSelectField } from './LandfillStatusIconSelectField';

type Props = {
    status: ILandfillStatus | null;
    closeForm: () => void;
};

export const LandfillStatusForm = ({ status, closeForm }: Props) => {
    const [createStatus, isCreating] = useLandfillStatusCreate();
    const [updateStatus, isUpdating] = useLandfillStatusUpdate();
    const [removeStatus, isRemoving] = useLandfillStatusRemove();

    const handleRemove = async () => {
        if (!status) return;
        const confirmed = await showDeleteConfirmationDialog(
            <LocalizableText code={'LandfillStatuses.confirmDeleteStatus'} />,
            status.name
        );

        if (!confirmed) return;
        await removeStatus(status.id);
        closeForm();
    };

    const formik = useFormik(getLandfillStatusFormikConfig(status, status ? updateStatus : createStatus, closeForm));

    const { canCreate, canUpdate, canDelete } = useLandfillStatusPermissions();
    const canEdit = status ? canUpdate : canCreate;

    return (
        <FormCardContainer formik={formik} showProgress={isCreating || isUpdating || isRemoving}>
            <Grid container justify="space-between" alignItems="center">
                <Text variant="TitleBold">
                    <LocalizableText
                        code={status ? 'LandfillStatuses.editLandfillStatus' : 'LandfillStatuses.createLandfillStatus'}
                    />
                </Text>
                {status && <DeleteButton onClick={handleRemove} disabled={!canDelete} />}
            </Grid>
            <FormikTextField name="name" formik={formik} disabled={!canEdit} />
            <LandfillStatusIconSelectField formik={formik} disabled={!canEdit} />
            <FormCardButtons
                formik={formik}
                onCancel={closeForm}
                submitLabel={status ? i18n.save : i18n.create}
                submitDisabled={!canEdit}
            />
        </FormCardContainer>
    );
};
