import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const LogOutIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.22222 2.00125H19.7778C21 2.00125 22 3.00125 22 4.22347V19.779C22 21.0013 21 22.0013 19.7778 22.0013H4.22222C2.98889 22.0013 2 21.0013 2 19.779V15.3346H4.22222V19.779H19.7778V4.22347H4.22222V8.66792H2V4.22347C2 3.00125 2.98889 2.00125 4.22222 2.00125ZM11.7942 17.5568L10.1695 15.9901L13.1424 13.1124H2V10.8901H13.1424L10.1695 8.01236L11.7942 6.4457L17.5556 12.0013L11.7942 17.5568Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
