import { Engine, Fuel } from '@common/enums';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { ICompanySearchRecord } from '~/@store/companies';
import { IDeliveryLine } from '~/@store/deliveryLines';
import { IDumpLoadSearchRecord } from '~/@store/dumpLoads/dumpLoads.hooks/useDumpLoadSearchFunc';
import { VehicleType } from '~/@store/vehicleTypes';
import i18n from '~/i18n';

export type IDeliveryLineFormValues = {
    deliveryLine?: IDeliveryLine;
    dumpLoad: IDumpLoadSearchRecord | null;
    weight: number | null;
    date: Date | null;
    company: ICompanySearchRecord | null;
    truckRegistrationNumber: string;
    truckCapacity: number | null;
    customerInvoice: string;
    transportInvoice: string;
    landfillInvoice: string;
    receipt: File | null;
    canSelectOrder: boolean;
    comment: string;
    verified: boolean;
    vehicleType: VehicleType | null;
    fuel: Fuel | null;
    engine: Engine | null;
};

export const getDeliveryLineFormikName = (index: number, field: keyof IDeliveryLineFormValues) =>
    `deliveryLines.${index}.${field}`;

export const getDeliveryLineValidationSchema = (
    canCreateDeliveryLine?: (order: IDumpLoadSearchRecord) => boolean,
    deliveryLine?: { receiptUrl: string | null }
) =>
    Yup.object().shape({
        dumpLoad: Yup.object()
            .nullable()
            .required(i18n.errorCodes.DELIVERY_LINE_ORDER_CANNOT_BE_EMPTY)
            .test('Permission validation', i18n.errorCodes.DELIVERY_LINE_DUMP_LOAD_IS_DONE, function (this, order) {
                if (!order || !canCreateDeliveryLine) return true;

                return canCreateDeliveryLine(order as IDumpLoadSearchRecord);
            }),
        weight: Yup.number().nullable().required(i18n.errorCodes.DELIVERY_LINE_WEIGHT_CANNOT_BE_EMPTY),
        date: Yup.date().nullable().required(i18n.errorCodes.DELIVERY_LINE_DATE_CANNOT_BE_EMPTY),
        company: Yup.object().nullable(),
        truckRegistrationNumber: Yup.string()
            .nullable()
            .trim()
            .required(i18n.errorCodes.DELIVERY_LINE_TRUCK_NUMBER_CANNOT_BE_EMPTY),
        truckCapacity: Yup.number().nullable(),
        customerInvoice: Yup.string().nullable(),
        transportInvoice: Yup.string().nullable(),
        landfillInvoice: Yup.string().nullable(),
        vehicleType: Yup.object().nullable().required(i18n.errorCodes.DELIVERY_LINE_VEHICLE_TYPE_CANNOT_BE_EMPTY),
        fuel: Yup.string().required(i18n.errorCodes.DELIVERY_LINE_FUEL_CANNOT_BE_EMPTY),
        engine: Yup.string().required(i18n.errorCodes.DELIVERY_LINE_ENGINE_CANNOT_BE_EMPTY),
        receipt: Yup.mixed()
            .nullable()
            .test(
                'Receipt validation',
                i18n.errorCodes.DELIVERY_LINE_RECEIPT_CANNOT_BE_EMPTY,
                function (this, receipt) {
                    return !!receipt || !!deliveryLine?.receiptUrl;
                }
            ),
    });

export const useDeliveryLineFormikContext = () => useFormikContext<IDeliveryLineFormValues>();
