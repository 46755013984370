// eslint-disable-next-line pinpointer/equal-enum
export enum LANDFILL_VIEW_MODE {
    ALL_LANDFILLS = 'all-landfills',
    ACTIVE_LANDFILLS = 'active-landfills',
    SUBAREAS = 'subareas',
}

// eslint-disable-next-line pinpointer/equal-enum
export enum PROJECT_VIEW_MODE {
    ALL_PROJECTS = 'all-projects',
    ACTIVE_PROJECTS = 'active-projects',
}

// eslint-disable-next-line pinpointer/equal-enum
export enum DELIVERY_LINE_VIEW_MODE {
    DELIVERY_LINES = 'delivery-lines',
    DELETED_LINES = 'deleted-delivery-lines',
    IN_PROGRESS_LINES = 'in-progress-delivery-lines',
}

export enum USER_VIEW_MODE {
    USERS = 'USERS',
    DELETED_USERS = 'DELETED_USERS',
}
