import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { SubstancesUpdatePositionFunc, useSubstancesUpdatePosition } from './substances.hooks';
import { ISubstance, ISubstanceChild } from './substances.types';

export type ISubstancesContextType = {
    selectedSubstance: ISubstance | ISubstanceChild | null;
    selectSubstance: (v: ISubstance | ISubstanceChild | null) => void;
    substances: ISubstance[];
    updatePositions: SubstancesUpdatePositionFunc;
    isPositionsUpdating: boolean;
};

const SubstancesContext = createContext<ISubstancesContextType>({
    selectedSubstance: null,
    selectSubstance: () => {},
    substances: [],
    updatePositions: () => Promise.resolve(),
    isPositionsUpdating: false,
});

export const useSubstancesContext = () => useContext(SubstancesContext);

type Props = {
    substances: ISubstance[];
};

export const SubstancesContextProvider: React.FC<Props> = ({ substances, children }) => {
    const [updatePositions, isPositionsUpdating] = useSubstancesUpdatePosition();

    const [selectedId, setSelectedId] = useState<string | null>(null);

    const selectedSubstance: ISubstance | ISubstanceChild | null = useMemo(() => {
        if (!selectedId) return null;

        return substances.reduce<ISubstance | ISubstanceChild | null>((res, s) => {
            if (res) return res;
            if (s.id === selectedId) return s;

            return s.children?.find(child => child.id === selectedId) || null;
        }, null);
    }, [substances, selectedId]);

    const selectSubstance = useCallback((s: ISubstance | ISubstanceChild | null) => setSelectedId(s?.id || null), []);

    return (
        <SubstancesContext.Provider
            value={{
                selectedSubstance,
                selectSubstance,
                substances,
                updatePositions,
                isPositionsUpdating,
            }}>
            {children}
        </SubstancesContext.Provider>
    );
};
