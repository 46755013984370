import { makeStyles } from '@material-ui/core/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';

import { Button } from '~/@components/Button';
import { FormikContextTextField } from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import {
    FormNames,
    getLandfillBatchFormikConfig,
    IFormValues,
} from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm/LandfillBatchFormik';
import { SochiDumpCategoriesSelect } from '~/@views/UserView/ProjectPage/common/SochiDumpCategoriesSelect';
import { LandfillQuery_landfill, LandfillQuery_landfill_batches } from '~/graphql';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';

export type IBatch = LandfillQuery_landfill_batches;

type Props = {
    landfill: LandfillQuery_landfill;
    batch: IBatch | null;
    onClose: () => void;
};

const LandfillBatchForm: FC<Props> = ({ landfill, batch, onClose }) => {
    const formik = useFormik(getLandfillBatchFormikConfig(landfill.id, batch, onClose));
    const { setFieldValue, values, errors, touched } = formik;

    const { buttons } = useStyles();

    return (
        <DialogContent>
            <FormikProvider value={formik}>
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Text variant="TitleBold">
                                <LocalizableText
                                    code={
                                        batch
                                            ? 'LandfillPage.batchCard.editBatch'
                                            : 'LandfillPage.batchCard.createBatch'
                                    }
                                />
                            </Text>
                        </Grid>
                        <Grid item xs={12}>
                            <FormikContextTextField<IFormValues, FormNames.name>
                                name={FormNames.name}
                                label={<LocalizableText code={'LandfillPage.batchCard.name'} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SochiDumpCategoriesSelect
                                onChange={dt => setFieldValue(FormNames.dumpType, dt)}
                                massCategory={values[FormNames.dumpType]}
                                errorMessage={touched[FormNames.dumpType] ? errors[FormNames.dumpType] : undefined}
                                label={<LocalizableText code={'LandfillPage.batchCard.dumpType'} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikContextTextField<IFormValues, FormNames.comment>
                                name={FormNames.comment}
                                label={<LocalizableText code={'LandfillPage.batchCard.comment'} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={buttons}>
                                <Button variant="outlined" onClick={onClose}>
                                    {i18n.cancel}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={formik.isSubmitting || !formik.dirty}>
                                    {i18n.save}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </DialogContent>
    );
};

const useStyles = makeStyles(() => ({
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));

export const showLandfillBatchForm = (landfill: LandfillQuery_landfill, batch: IBatch | null) =>
    showCustomDialog({
        render: closeDialog => <LandfillBatchForm landfill={landfill} batch={batch} onClose={closeDialog} />,
    });
