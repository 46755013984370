import { formatString } from '@common/utils';
import { FormikConfig } from 'formik';
import * as Yup from 'yup';

import { IToxicSumRule } from '~/@store/toxicLimits';
import { ToxicSumRuleInput } from '~/graphql';
import i18n from '~/i18n';

export type ISumRuleFormValues = {
    name: string;
    faSumLevel: number;
    substances: Array<{ substanceId: string; threshold: number }>;
};

export const getSumRuleFormikConfig = (
    sumRule: IToxicSumRule | null,
    submitFunc: (values: ToxicSumRuleInput) => Promise<unknown>
): FormikConfig<ISumRuleFormValues> => ({
    initialValues: {
        name: sumRule?.name || '',
        faSumLevel: sumRule?.faSumLevel || 0,
        substances: [...(sumRule?.substances || [])],
    },
    validationSchema: Yup.object().shape({
        name: Yup.string().trim().required(formatString(i18n.isRequired, i18n.name)),
        faSumLevel: Yup.number().min(1, formatString(i18n.isRequired, i18n.amount)),
        substances: Yup.array().of(
            Yup.object().shape({
                threshold: Yup.number().min(1),
            })
        ),
    }),
    onSubmit: values => {
        const { name, faSumLevel, substances } = values;

        return submitFunc({
            name,
            faSumLevel,
            substances: substances.map(({ substanceId, threshold }) => ({ substanceId, threshold })),
        });
    },
});
