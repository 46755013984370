import type { ReactNode } from 'react';

export enum MessagePlaces {
    GLOBAL = 'GLOBAL',
    IN_PAGE = 'IN_PAGE',
}

export enum MessageTypes {
    INFO = 'INFO',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export enum TimeoutModifier {
    LONG = 'LONG',
    SHORT = 'SHORT',
}

export type MessageType = `${MessageTypes}`;

export type MessagePlace = `${MessagePlaces}`;

export type MessageAction = { label: string; callback: () => unknown | Promise<unknown> };

export class MessageModel {
    type: MessageType;
    message: string;
    action?: MessageAction;
    timeout: number;

    constructor(type: MessageType, message: string, timeout: number, action?: MessageAction) {
        this.type = type;
        this.message = message;
        this.action = action;
        this.timeout = timeout;
    }
}

export type DialogButtons = { ok: ReactNode; cancel?: ReactNode };

let dialogKey = 1;

export class MessageDialogModel {
    title?: ReactNode;
    resolver: (result: boolean) => void = () => {};
    buttons: DialogButtons;
    message?: ReactNode;

    key = dialogKey++;

    constructor(dialog: { title?: ReactNode; message?: ReactNode; buttons: DialogButtons }) {
        this.title = dialog.title;
        this.buttons = dialog.buttons;
        this.message = dialog.message;
    }
}

export type ValueResolver<T = boolean> = (value?: T) => void;

export type ComponentDialogRender<T = boolean> = (closeDialog: ValueResolver<T>) => ReactNode;

export class ComponentDialogModel<T = boolean> {
    title?: ReactNode | null;
    resolver: ValueResolver<T> = () => {};
    render: ComponentDialogRender<T>;
    noContainer: boolean;
    noModalComponent: boolean;
    forceCrossClose: boolean;

    key = dialogKey++;

    constructor(dialog: {
        title?: ReactNode;
        render: ComponentDialogRender<T>;
        noContainer?: boolean;
        forceCrossClose?: boolean;
        noModalComponent?: boolean;
    }) {
        this.title = dialog.title;
        this.render = dialog.render;
        this.noContainer = dialog.noContainer || false;
        this.forceCrossClose = dialog.forceCrossClose || false;
        this.noModalComponent = dialog.noModalComponent || false;
    }
}
