import React from 'react';

import { PdfNoBreakPage } from '~/@components/@hooks/use-pdf-export';
import { makeStyles } from '~/@components/@theme';
import { Text } from '~/@components/Text';
import { IComment } from '~/@store/comments';
import i18n from '~/i18n';
import { formatDateTime, parseDateFrom } from '~/utils/date';
import { getUserFullName } from '~/utils/user';

type Props = {
    comment: IComment;
};

export const Comment = ({ comment }: Props) => {
    const { authorStyle, dateStyle, infoStyle, infoText, root } = useStyles();

    return (
        <div className={root}>
            <PdfNoBreakPage>
                <div className={infoStyle}>
                    <Text className={infoText}>{comment.text}</Text>
                    <Text className={authorStyle}>{comment.author ? getUserFullName(comment.author) : i18n.NA}</Text>
                </div>
                <Text className={dateStyle}>{formatDateTime(parseDateFrom(comment.date))}</Text>
            </PdfNoBreakPage>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    authorStyle: {
        color: theme.palette.text.secondary,
    },
    dateStyle: {
        color: theme.palette.text.secondary,
        lineHeight: '140%',
        marginTop: 4,
    },
    infoStyle: {
        borderRadius: theme.shape.borderRadius,
        boxSizing: 'border-box',
        padding: '11px 16px',
        backgroundColor: theme.palette.background.input,
    },
    infoText: {
        color: theme.palette.text.primary,
        wordBreak: 'break-word',
    },
    root: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(3),
        },
    },
}));
