import React, { FC, useMemo } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { Summaries } from '~/@sochi-components/Summmaries';
import { LandfillQuery_landfill } from '~/graphql';
import { fmtVolInTon } from '~/utils/numbers';

type Props = {
    landfill: LandfillQuery_landfill;
};

export const VolumeInfo: FC<Props> = ({ landfill }) => {
    const [incoming, outgoing] = useMemo(
        () =>
            landfill.ordersInfo.reduce(
                (acc, order) => {
                    return [
                        acc[0]! + (order.dumpLoad.inbound ? 0 : order.deliveredVolume),
                        acc[1]! + (order.dumpLoad.inbound ? order.deliveredVolume : 0),
                    ];
                },
                [0, 0]
            ),
        [landfill]
    );

    const items = useMemo(
        () => [
            {
                label: <LocalizableText code={'Materials.incomingTotal'} />,
                value: fmtVolInTon(incoming),
            },
            {
                label: <LocalizableText code={'LandfillForm.capacity'} />,
                value: fmtVolInTon(landfill.capacity),
            },
            {
                label: <LocalizableText code={'Materials.outgoingTotal'} />,
                value: fmtVolInTon(outgoing),
            },
        ],
        [landfill, incoming, outgoing]
    );

    return <Summaries items={items} />;
};
