import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/users/users.queries';
import { UserLandfillRemoveMutation, UserLandfillRemoveMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import { userLandfillsStore } from '../userRelations.store';

export type DeleteUserLandfillFunc = (userId: string, landfillId: string) => Promise<unknown>;

export const useUserLandfillDeleteMutation = (): [DeleteUserLandfillFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<UserLandfillRemoveMutation, UserLandfillRemoveMutationVariables>(
        queries.UserLandfillRemoveMutation
    );

    const deleteUserLandfill = useCallback<DeleteUserLandfillFunc>(
        (userId: string, landfillId: string) => {
            return callMutation({
                variables: {
                    userId,
                    landfillId,
                },
                refetchQueries: [
                    {
                        query: queries.AdminUserLandfillsQuery,
                        variables: userLandfillsStore.userRelationsQueryVariables,
                    },
                ],
            })
                .then(result =>
                    result.data?.userLandfillRemove
                        ? result.data.userLandfillRemove
                        : Promise.reject(new Error('Empty response "UserLandfillRemoveMutation"'))
                )
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [deleteUserLandfill, mutationResult.loading];
};
