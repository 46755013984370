import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import {
    CompaniesPagedQuery as ICompaniesPagedQuery,
    CompaniesPagedQuery_companiesPaged,
    CompaniesPagedQuery_companiesPaged_edges_node,
    CompaniesPagedQueryVariables,
} from '~/graphql';

import { CompaniesPagedQuery } from '../companies.queries';
import { companiesStore } from '../companies.store';

export type ICompany = CompaniesPagedQuery_companiesPaged_edges_node;

export type IPagedCompany = CompaniesPagedQuery_companiesPaged;

export const usePagedCompanies = (): QueryResult<ICompaniesPagedQuery, CompaniesPagedQueryVariables> => {
    const variables: CompaniesPagedQueryVariables = useDebouncedObservable(
        () => companiesStore.companiesQueryVariables,
        TIMEOUT_ON_QUERY_DEBOUNCE
    );

    return useQuery<ICompaniesPagedQuery, CompaniesPagedQueryVariables>(CompaniesPagedQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
        onCompleted: data => companiesStore.pagination.onQueryComplete(data.companiesPaged),
    });
};
