import { MenuProps } from '@material-ui/core';

export const ROWS_PER_PAGE_OPTIONS = [20, 50, 100];

export const PAGINATION_SELECT_MENU_PROPS: Partial<MenuProps> = {
    elevation: 4,
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    transformOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
    getContentAnchorEl: null,
};
