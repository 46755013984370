import { FileFormats } from '../../../common/enums';
import { landfillDeclarationsUri, uploadDeclarationUri } from '../apolloClient';
import { downloadFile } from './files';

type GetDeclarationParams = { projectId: string; projectLoadId: string } | { orderNumber: string };

export function downloadDeclarationOfWaste(params: GetDeclarationParams, name: string) {
    const data = {
        ...params,
        format: FileFormats.PDF,
    };

    const filename = `${name}.${FileFormats.PDF}`;

    return downloadFile(uploadDeclarationUri, data, filename, true, true);
}

export function downloadLandfillDeclarations(params: { landfillId: string }, name: string) {
    const filename = `${name}.${FileFormats.ZIP}`;

    return downloadFile(landfillDeclarationsUri, params, filename, true, true);
}
