import { observer } from 'mobx-react';
import React from 'react';

import { substanceEditStore } from '~/@store/substances';

import EditSubstanceDropdown from './EditSubstanceDropdown';

type Props = {
    disableContaminationLevels?: boolean;
    showSolid?: boolean;
    showLeaching?: boolean;
};

export const EditSubstanceView = observer(
    ({ disableContaminationLevels, showSolid = true, showLeaching = true }: Props) => {
        if (!substanceEditStore.initialized) return null;

        return (
            <div>
                {substanceEditStore.SOLID && showSolid && (
                    <EditSubstanceDropdown
                        store={substanceEditStore.SOLID}
                        disableContaminationLevels={disableContaminationLevels}
                    />
                )}
                {substanceEditStore.LEACHING && showLeaching && (
                    <EditSubstanceDropdown
                        store={substanceEditStore.LEACHING}
                        disableContaminationLevels={disableContaminationLevels}
                    />
                )}
            </div>
        );
    }
);
