import { useFormik } from 'formik';
import React, { useCallback, VFC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { FormCard } from '~/@components/FormCard';
import { FormikBooleanSwitchField, FormikNumberField, FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import {
    IDumpType,
    useDumpTypeCreateMutation,
    useDumpTypeDeleteMutation,
    useDumpTypePermissions,
    useDumpTypeUpdateMutation,
} from '~/@store/dumpTypes';
import { commonPrecisions } from '~/config/enum';
import i18n from '~/i18n';

import { getDumpTypeFormikConfig } from './DumpTypeFormik';

type DumpTypeFormProps = {
    onClose: () => void;
    dumpType?: IDumpType;
};

export const DumpTypeForm: VFC<DumpTypeFormProps> = ({ onClose, dumpType = null }) => {
    const { formCard, container, nameField, tonnsPerCubicMField, usedInDeclarationField, descriptionField } =
        useStyles();

    const [createDumpType, isCreating] = useDumpTypeCreateMutation();
    const [updateDumpType, isUpdating] = useDumpTypeUpdateMutation(dumpType);
    const [deleteDumpType, isDeleting] = useDumpTypeDeleteMutation();

    const onDeleteClick = useCallback(() => {
        if (!dumpType) return;

        return showDeleteConfirmationDialog(
            <LocalizableText code={'DumpTypeCard.deleteConfirmation'} />,
            dumpType.name
        ).then(confirmed => {
            if (confirmed) {
                const ignoredPromise = deleteDumpType(dumpType.id);
                onClose();
            }
        });
    }, [deleteDumpType, dumpType, onClose]);

    const formik = useFormik(getDumpTypeFormikConfig(dumpType, dumpType ? updateDumpType : createDumpType, onClose));

    const { canCreate, canUpdate, canDelete } = useDumpTypePermissions();
    const canEdit = dumpType ? canUpdate : canCreate;

    return (
        <FormCard
            className={formCard}
            title={<LocalizableText code={dumpType ? 'DumpTypeCard.editTitle' : 'DumpTypeCard.createTitle'} />}
            subTitle={dumpType?.externalId ? `${i18n.DumpTypeCard.id}: ${dumpType?.externalId}` : undefined}
            onClose={onClose}
            onSubmit={formik.handleSubmit}
            onDelete={!!dumpType ? onDeleteClick : undefined}
            isCloseDisabled={formik.isSubmitting}
            isSubmitDisabled={!formik.dirty || formik.isSubmitting || !canEdit}
            isDeleteDisabled={formik.isSubmitting || !canDelete}
            showProgress={isCreating || isUpdating || isDeleting}>
            <div className={container}>
                <FormikTextField
                    label={<LocalizableText code={'DumpTypeCard.name'} />}
                    className={nameField}
                    name="name"
                    formik={formik}
                    disabled={!canEdit}
                />
                <FormikNumberField
                    label={<LocalizableText code={'DumpTypeCard.tonnsPerCubicM'} />}
                    className={tonnsPerCubicMField}
                    name="tonnsPerCubicM"
                    formik={formik}
                    precision={commonPrecisions.decimal}
                    disabled={!canEdit}
                />
                <FormikBooleanSwitchField
                    className={usedInDeclarationField}
                    commonLabel={<LocalizableText code={'DumpTypeCard.usedInDeclaration'} />}
                    name="usedInDeclaration"
                    formik={formik}
                    disabled={!canEdit}
                />
                <FormikTextField
                    label={<LocalizableText code={'DumpTypeCard.description'} />}
                    className={descriptionField}
                    name="description"
                    formik={formik}
                    disabled={!canEdit}
                />
            </div>
        </FormCard>
    );
};

const useStyles = makeStyles(theme => ({
    formCard: {
        minWidth: 394,
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '144px 1fr',
        rowGap: theme.spacing(4),
        columnGap: theme.spacing(1),
    },
    nameField: {
        gridColumn: '1 / -1',
        gridRow: 1,
    },
    tonnsPerCubicMField: {
        gridColumn: '1 / 2',
        gridRow: 2,
    },
    usedInDeclarationField: {
        gridColumn: '2 / 3',
        gridRow: 2,
        justifyContent: 'flex-end',
    },
    descriptionField: {
        gridColumn: '1 / -1',
        gridRow: 3,
    },
}));
