import React, { memo, VFC } from 'react';

import { showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { DeleteIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { CellButton } from '~/@components/Table/TableCellActions/CellButton';
import { IBlacklistItem, useDeleteFromBlacklistMutation } from '~/@store/blacklist';
import { useUserAbilities } from '~/contexts';
import { canDeleteUser } from '~/utils/auth';

type Props = {
    item: IBlacklistItem;
};

export const DeleteFromBlacklistCell: VFC<Props> = memo(({ item }) => {
    const ability = useUserAbilities();
    const canDelete = canDeleteUser(ability);

    const [deleteFromBlacklist, deleting] = useDeleteFromBlacklistMutation();

    const handleDelete = () =>
        showDeleteConfirmationDialog(<LocalizableText code={'BlacklistPage.deleteConfirmation'} />, item.email).then(
            confirmed => {
                if (confirmed) {
                    const ignoredPromise = deleteFromBlacklist(item.id);
                }
            }
        );

    return (
        <CellButton onClick={handleDelete} disabled={!canDelete || deleting}>
            <DeleteIcon />
        </CellButton>
    );
});
