import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import { MapWithContentContainer } from '~/@sochi-components/MapWithContentContainer';
import { CreationMode, homePageMapStore } from '~/@user-store/homePageMap';
import { FloatPanelContext } from '~/@views/UserView/common/FloatMobilePanel';
import { HomePageMap } from '~/@views/UserView/HomePage/HomePageMap';
import { ProjectEditForm } from '~/@views/UserView/ProjectPage/ProjectEditForm';
import { TopBar } from '~/@views/UserView/UserViewLayout/TopBar';
import { useUserAbilities } from '~/contexts';

import { LandfillCreateForm } from '../LandfillPage/common/LandfillCreateForm';
import { UserViewLayout } from '../UserViewLayout';
import { CreateWizard } from './CreateWizard';
import { ProjectLandfillLists } from './ProjectLandfillLists';

export const HomePage: FC = observer(() => {
    const { user } = useUserAbilities();
    const { root } = useStyles();

    useEffect(() => {
        const ignored = homePageMapStore.init(user.role);

        return () => homePageMapStore.clearSelected();
    }, [user.role]);

    const renderContent = () => {
        return (
            <>
                {homePageMapStore.creationMode === null && <ProjectLandfillLists />}
                {homePageMapStore.creationMode === CreationMode.CHOOSE && <CreateWizard />}
            </>
        );
    };

    const renderMap = () => {
        return <HomePageMap />;
    };

    return (
        <FloatPanelContext.Provider
            value={{
                closeDisabled: homePageMapStore.isFloatPanelDisabled,
                open: homePageMapStore.isFloatPanelOpen,
                switchMode: () => homePageMapStore.changeFloatPanelState(),
            }}>
            <UserViewLayout>
                <TopBar />
                <div className={root}>
                    {(homePageMapStore.creationMode === null ||
                        homePageMapStore.creationMode === CreationMode.CHOOSE) && (
                        <MapWithContentContainer renderContent={renderContent} renderMap={renderMap} />
                    )}
                    {homePageMapStore.creationMode === CreationMode.PROJECT && (
                        <ProjectEditForm project={null} onBack={() => homePageMapStore.revertCreationMode()} />
                    )}
                    {homePageMapStore.creationMode === CreationMode.LANDFILL && (
                        <LandfillCreateForm landfill={null} onBack={() => homePageMapStore.revertCreationMode()} />
                    )}
                </div>
            </UserViewLayout>
        </FloatPanelContext.Provider>
    );
});

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: 'calc(100% - 120px)',

        [theme.breakpoints.down('md')]: {
            height: 'calc(100% - 50px)',
        },
    },
}));
