import React from 'react';

import { SochiTooltip } from '~/@sochi-components/SochiTooltip';
import i18n from '~/i18n';
import { getHighlightFunc } from '~/utils';
import { getUserFullName, IUserInfo } from '~/utils/user';

interface Props {
    receivers: IUserInfo[];
    highlightText?: string;
}

function RestReceiversCount({ receivers }: Pick<Props, 'receivers'>) {
    if (!receivers.length) return null;

    return (
        <SochiTooltip
            disabled={!receivers.length}
            content={
                <ul>
                    {receivers.map((el, i) => (
                        <li key={i}>{getUserFullName(el)}</li>
                    ))}
                </ul>
            }>
            <div>
                <b>
                    + {receivers.length} {receivers.length === 1 ? i18n.receiver : i18n.receivers}
                </b>
            </div>
        </SochiTooltip>
    );
}

export function SochiReceivers({ receivers, highlightText = '' }: Props) {
    const highlight = getHighlightFunc(highlightText);

    const receiverToShow =
        (highlightText && receivers.find(r => `${r.name} ${r.surname}`.includes(highlightText))) || receivers[0];

    const restReceivers = receiverToShow ? receivers.filter(r => r !== receiverToShow) : receivers;

    if (receiverToShow)
        return (
            <div>
                {highlight(getUserFullName(receiverToShow))}
                <RestReceiversCount receivers={restReceivers} />
            </div>
        );

    return <div>{i18n.NA}</div>;
}
