import React from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { projectsStore } from '~/@store/projects/project.store';
import { ProjectsTable } from '~/@views/AdminView/ProjectsPage';

import { useCompanyDetailContext } from '../CompanyDetailContext';

export const CompanyProjectsTab = () => {
    const company = useCompanyDetailContext();

    useSyncEffect(() => projectsStore.setCompanyTabViewMode(company.id));

    return <ProjectsTable />;
};
