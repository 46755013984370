import { add, format, getISODay, isBefore, startOfISOWeek } from 'date-fns';
import * as mongoose from 'mongoose';

import { TransportScheduleWeekFormat } from '../constants';
import type { IUnits } from '../enums';
import { DumpLoadStatus, ErrorCodes, LoadStatus } from '../enums';
import type { IDumpLoadStatus, IErrorCode, IWeekAmount } from '../types';
import { isAfterDay, isBeforeDay, isSameOrBefore, parseDateFrom } from '../utils';

export function getTransportScheduleWeeksArray(startDate: string | Date, endDate: string | Date): Array<IWeekAmount> {
    if (!startDate || !endDate) return [];
    const start = parseDateFrom(startDate);
    const end = parseDateFrom(endDate);
    if (!start || !end || isBefore(end, start)) return [];
    let weeks = [];
    let current = getISODay(start) > 5 ? startOfISOWeek(add(start, { weeks: 1 })) : startOfISOWeek(start);

    while (isSameOrBefore(current, end)) {
        weeks.push({
            week: format(current, TransportScheduleWeekFormat),
            amount: 0,
        });
        current = add(current, { weeks: 1 });
    }

    return weeks;
}

export function distributeEvenlyTransportSchedule(
    startDate: string | Date,
    endDate: string | Date,
    inputAmount: number | null
): Array<IWeekAmount> {
    let weeks = getTransportScheduleWeeksArray(startDate, endDate);
    if (typeof inputAmount !== 'number' || !(inputAmount >= 1)) return weeks;
    if (weeks.length === 0) return weeks;

    if (weeks.length === 1) {
        weeks[0]!.amount = inputAmount;

        return weeks;
    }

    const start = parseDateFrom(startDate);
    const firstWeekStart = parseDateFrom(weeks[0]!.week);
    const end = parseDateFrom(endDate);
    weeks = weeks.map(({ week, amount }) => ({
        week,
        amount,
        days: 5,
    }));
    // @ts-ignore
    weeks[0].days = isSameOrBefore(start, firstWeekStart) ? 5 : Math.max(6 - getISODay(start), 0);
    // @ts-ignore
    weeks[weeks.length - 1].days = Math.min(getISODay(end), 5);
    // @ts-ignore
    const totalDays = weeks.reduce((s, w) => s + w.days, 0);
    const amountDaysModulo = inputAmount % totalDays;
    const amountPerDay = (inputAmount - amountDaysModulo) / totalDays;
    // @ts-ignore
    weeks.forEach(w => (w.amount = w.days * amountPerDay));
    let amountWeeksModulo = amountDaysModulo % weeks.length;
    const amountPerWeek = (amountDaysModulo - amountWeeksModulo) / weeks.length;
    weeks.forEach(w => (w.amount += amountPerWeek));
    weeks.forEach(w => {
        w.amount += amountWeeksModulo > 0 ? 1 : 0;
        amountWeeksModulo--;
    });

    return weeks.map(w => ({
        week: w.week,
        amount: w.amount,
    }));
}

interface IProjectDates {
    startDate?: string | Date | null | undefined;
    endDate?: string | Date | null | undefined;
}

interface IDumpLoadDates {
    date?: Date | string | null | undefined;
    endDate?: Date | string | null | undefined;
    status?: `${LoadStatus}` | null | undefined;
}

export function areProjectDatesInvalid(project: IProjectDates, masses?: IDumpLoadDates[]): IErrorCode | null {
    const projectStartDate = project.startDate ? parseDateFrom(project.startDate) : null;
    const projectEndDate = project.endDate ? parseDateFrom(project.endDate) : null;
    let errCode: IErrorCode | null | undefined;

    if (projectStartDate && projectEndDate && isAfterDay(projectStartDate, projectEndDate)) {
        errCode = ErrorCodes.PROJECT_END_DATE_BEFORE_START_DATE;
    }

    if (!errCode && Array.isArray(masses)) {
        for (const mass of masses) {
            if (mass.status === DumpLoadStatus.DISCARDED) continue;

            const parsedMassDate = parseDateFrom(mass.date);
            const parsedMassEndDate = parseDateFrom(mass.endDate);

            if (parsedMassDate && projectStartDate && isAfterDay(projectStartDate, parsedMassDate)) {
                errCode = ErrorCodes.PROJECT_START_DATE_AFTER_MASS_START_DATE;
                break;
            }

            if (parsedMassEndDate && projectEndDate && isBeforeDay(projectEndDate, parsedMassEndDate)) {
                errCode = ErrorCodes.PROJECT_END_DATE_BEFORE_MASS_END_DATE;
                break;
            }
        }
    }

    return errCode || null;
}

export function areMassDatesInvalid(project: IProjectDates, mass: IDumpLoadDates): IErrorCode | null {
    if (mass.status === DumpLoadStatus.DISCARDED) return null;

    const projectStartDate = project.startDate ? parseDateFrom(project.startDate) : null;
    const projectEndDate = project.endDate ? parseDateFrom(project.endDate) : null;
    const massStartDate = mass.date ? parseDateFrom(mass.date) : null;
    let errCode: IErrorCode | null | undefined;

    if (massStartDate && projectStartDate && isBeforeDay(massStartDate, projectStartDate)) {
        errCode = ErrorCodes.MASS_START_DATE_BEFORE_PROJECT_START_DATE;
    }

    const massEndDate = mass.endDate ? parseDateFrom(mass.endDate) : null;

    if (massEndDate) {
        if (projectStartDate && isBeforeDay(massEndDate, projectStartDate)) {
            errCode = ErrorCodes.MASS_END_DATE_BEFORE_PROJECT_START_DATE;
        } else if (massStartDate && isBeforeDay(massEndDate, massStartDate)) {
            errCode = ErrorCodes.MASS_END_DATE_BEFORE_MASS_START_DATE;
        } else if (projectEndDate && isAfterDay(massEndDate, projectEndDate)) {
            errCode = ErrorCodes.PROJECT_END_DATE_MUST_BE_UPDATED;
        }
    }

    return errCode || null;
}

export const canCalculateDestination = (
    dumpTypeId: (string | null | undefined) | mongoose.Types.ObjectId,
    status: IDumpLoadStatus | null | undefined,
    amount: number | null | undefined,
    unitOfMeasure: IUnits | null | undefined
) =>
    Boolean(
        [DumpLoadStatus.DRAFT, DumpLoadStatus.REQUESTED].includes(status as LoadStatus) &&
            dumpTypeId &&
            amount &&
            unitOfMeasure
    );
