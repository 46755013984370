import { IconButton, List } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback, useRef, useState } from 'react';

import { Theme } from '~/@components/@theme';
import { COLORS } from '~/@components/@theme/colors';
import { DropDownIcon } from '~/@components/Icon';

type Props = {
    title: React.ReactNode;
    sortDirection?: number | null;
    readonly?: boolean;
};

export const TableHeadCell: FC<Props> = ({ title, sortDirection, children, readonly = false }) => {
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    const disabled = !children || (Array.isArray(children) && !children.some(Boolean));

    const { root, titleElement, button, icon, popper, paper } = useStyles({
        sortDirection,
        open,
        disabled,
    });

    const dropDownRevert = !!sortDirection && sortDirection < 0;

    const showButton = !disabled || !!sortDirection;

    return (
        <div className={root} onClick={!disabled ? handleOpen : undefined}>
            <div className={titleElement}>{title}</div>
            {showButton && (
                <IconButton className={button} size="small" ref={anchorRef} disabled={readonly}>
                    <DropDownIcon className={icon} revert={dropDownRevert} />
                </IconButton>
            )}
            {!disabled && !readonly && (
                <Popper
                    className={popper}
                    open={open}
                    anchorEl={anchorRef.current}
                    transition
                    placement="bottom-start"
                    disablePortal={true}>
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: 'top',
                            }}>
                            <Paper className={paper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <List disablePadding>{children}</List>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </div>
    );
};

const useStyles = makeStyles<Theme, { sortDirection?: number | null; open: boolean; disabled?: boolean }>(() => ({
    root: ({ sortDirection, disabled }) => ({
        position: 'relative',
        minHeight: 48,
        display: 'flex',
        alignItems: 'flex-end',
        cursor: 'pointer',
        margin: '-4px -16px -8px',
        padding: '4px 16px 8px',
        pointerEvents: disabled ? 'none' : undefined,

        '&:hover $button': {
            backgroundColor: sortDirection ? COLORS.brandLight : COLORS.grayLight,
        },
    }),
    titleElement: {
        flexGrow: 1,
        paddingRight: 4,
    },
    button: ({ sortDirection, open, disabled }) => ({
        backgroundColor: sortDirection
            ? disabled
                ? COLORS.grayDark
                : COLORS.brandLight
            : open
            ? COLORS.grayLight
            : COLORS.transparent,
        color: sortDirection ? COLORS.white : 'currentColor',
        width: 20,
        height: 20,
        padding: 0,
        pointerEvents: 'none',
    }),
    icon: {
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    popper: {
        zIndex: 1500,
        top: '9px !important',
    },
    paper: {
        width: 250,
        maxHeight: 'calc(100vh - 250px)',
        overflowY: 'auto',
    },
}));
