import React from 'react';

import { getBem } from '~/@sochi-components/@bem';

const wrapperStyle = { display: 'contents' };

type ClickAwayListenerProps = {
    onClickAway: () => void;
    children: React.ReactNode;
};

export class ClickAwayListener extends React.Component<ClickAwayListenerProps> {
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    bem = getBem(this);

    wrapper = React.createRef<HTMLDivElement>();

    handleClickOutside = (event: MouseEvent) => {
        const domNode = this.wrapper.current;
        const targetNode = event.target instanceof Node ? event.target : null;
        const onClickAway = this.props.onClickAway;

        if (!domNode || !domNode.contains(targetNode)) {
            onClickAway && setTimeout(onClickAway);
        }
    };

    render() {
        return (
            <div ref={this.wrapper} className={this.bem.className} style={wrapperStyle}>
                {this.props.children}
            </div>
        );
    }
}
