import { ContaminationLevel, ContaminationType } from '@common/enums';
import { leachingLevels, solidLevels } from '@common/toxic-analysis';
import { ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { getContaminationLevelLabel } from '~/@store/toxicLimits/toxicLimits.helpers';

type Props = {
    valueGetter: () => ContaminationLevel | null | undefined;
    setValue: (v: ContaminationLevel | null) => void;
    mode: ContaminationType;
};

export const ContaminationLevelFilter = ({ valueGetter, setValue, mode }: Props) => {
    const { selected } = useStyles();
    const value = useObservable(valueGetter);

    const filterValues = useMemo(
        () => (mode === ContaminationType.SOLID ? [ContaminationLevel.na, ...solidLevels] : leachingLevels),
        [mode]
    );

    return (
        <>
            <ListItem button divider onClick={() => setValue(null)}>
                ---
            </ListItem>
            {filterValues.map(v => (
                <ListItem
                    key={`${v}`}
                    button
                    divider
                    onClick={() => setValue(v)}
                    className={value === v ? selected : undefined}>
                    <ListItemText>{getContaminationLevelLabel(v, mode)}</ListItemText>
                </ListItem>
            ))}
        </>
    );
};

const useStyles = makeStyles(theme => ({
    selected: {
        backgroundColor: theme.palette.background.default,
    },
}));
