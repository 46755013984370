import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { companiesStore } from '~/@store/companies/companies.store';
import {
    CompanyCreate,
    CompanyCreateMutation,
    CompanyCreateMutation_companyCreate,
    CompanyCreateMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../companies.queries';

export type CompanyCreateFunc = (input: CompanyCreate) => Promise<CompanyCreateMutation_companyCreate>;

export const useCompanyCreateMutation = (): [CompanyCreateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<CompanyCreateMutation, CompanyCreateMutationVariables>(
        queries.CompanyCreateMutation
    );

    const createCompany = useCallback<CompanyCreateFunc>(
        input => {
            return callMutation({
                variables: { input },
                refetchQueries: [
                    { query: queries.CompaniesPagedQuery, variables: companiesStore.companiesQueryVariables },
                ],
                awaitRefetchQueries: true,
            })
                .then(({ data }) =>
                    data?.companyCreate
                        ? data.companyCreate
                        : Promise.reject(new Error('Empty response "CompanyCreateMutation'))
                )
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [createCompany, mutationResult.loading];
};
