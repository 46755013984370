import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { IDeviation } from '~/@user-store/deviations';
import { useDeviationProjectAbilities } from '~/@user-store/deviations/deviation.abilities';
import { projectDeviationMutations } from '~/@user-store/deviations/deviation.project.mutations';
import { DeviationForm } from '~/@views/UserView/common/DeviationForm';
import { projectDeviationPhotoUrl } from '~/apolloClient';
import { IAbilityProject } from '~/utils/auth';

type Props = {
    closeDialog: () => void;
    deviation?: IDeviation | null;
    project: IAbilityProject & { name: string };
};

export const ProjectDeviationForm = ({ closeDialog, deviation, project }: Props) => {
    const abilities = useDeviationProjectAbilities(deviation || null, project);

    return (
        <DeviationForm
            closeDialog={closeDialog}
            deviation={deviation}
            mutations={projectDeviationMutations}
            abilities={abilities}
            parentEntity={project}
            parentEntityLabel={<LocalizableText code={'project'} />}
            photoUrl={projectDeviationPhotoUrl}
        />
    );
};
