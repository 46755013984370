import './Modal.scss';

import React from 'react';

import { getBem } from '~/@sochi-components/@bem';

import { hideBodyScroll, restoreBodyScroll } from '../../utils';

type ModalProps = {
    children: React.ReactNode;
    noPadding?: boolean;
};

export class Modal extends React.Component<ModalProps> {
    static modalsCounter = 0;

    bem = getBem(this);

    componentDidMount() {
        Modal.handleBodyScrollAppearance(+1);
    }

    componentWillUnmount() {
        Modal.handleBodyScrollAppearance(-1);
    }

    static handleBodyScrollAppearance(v: number) {
        Modal.modalsCounter += v;
        if (Modal.modalsCounter === 0) {
            restoreBodyScroll();
        } else {
            hideBodyScroll();
        }
    }

    render() {
        const { element } = this.bem;
        const noPadding = this.props.noPadding || false;

        return <div className={element('modal-container', { 'no-padding': noPadding })}>{this.props.children}</div>;
    }
}
