import { FormikContextType } from 'formik';
import React from 'react';

import { FormikAsyncAutocompleteField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { ICompanySearchRecord, useCompaniesSearchFunc } from '~/@store/companies';

import { LandfillEditFormValues } from '../LandfillEdit.types';

type Props = { formik: FormikContextType<LandfillEditFormValues> };

export const LandfillEditCustomer = ({ formik }: Props) => {
    const customersSearch = useCompaniesSearchFunc(false);

    return (
        <FormikAsyncAutocompleteField
            label={<LocalizableText code={'customer'} />}
            name={'customer'}
            formik={formik}
            renderOption={(customer: ICompanySearchRecord) => customer.name}
            loadOptions={customersSearch}
        />
    );
};
