import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { COLORS } from '~/@components/@theme/colors';
import { Chip } from '~/@components/Chip';
import {
    despatchAdviceResponsesStore,
    DespatchAdviceResponsesStoreFilterField,
} from '~/@store/beastIntegration/despatchAdviceResponses.store';
import { removeEmptyKeys } from '~/@store/common';
import { formatFilterValue } from '~/@views/AdminView/common/utils';
import i18n from '~/i18n';

type Props = {
    disabled?: boolean;
};

const FieldNames: Record<DespatchAdviceResponsesStoreFilterField, string> = {
    date: i18n.DespatchAdviceResponses.date,
    responseType: i18n.DespatchAdviceResponses.responseType,
    declineReason: i18n.DespatchAdviceResponses.declineReason,
};

export const DespatchAdviceResponseFilterSummary = observer(({ disabled = false }: Props) => {
    const filterValues = removeEmptyKeys({ ...despatchAdviceResponsesStore.filter.values });

    const { root, chip } = useStyles();

    return (
        <div className={root}>
            {Object.keys(filterValues).map(objectKey => {
                const key = objectKey as DespatchAdviceResponsesStoreFilterField;
                const value = filterValues[key];
                if (value === null || value === undefined) return null;
                const chipContent = `${FieldNames[key]}: "${formatFilterValue(value)}"`;

                return (
                    <Chip
                        key={key}
                        className={chip}
                        color={COLORS.black}
                        onDelete={() => despatchAdviceResponsesStore.filter.setValue(key, null)}
                        deleteIcon={<Icon>close</Icon>}
                        disabled={disabled}>
                        {chipContent}
                    </Chip>
                );
            })}
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        marginRight: theme.spacing(1),
    },
}));
