import { endOfDay, isAfter, isBefore, isDate, isEqual, isValid, parseISO, startOfDay } from 'date-fns';

export type DateLike = Date | number | string | null | undefined | void;

export const parseDateFrom = (value: DateLike): Date | null => {
    if (!value) return null;
    if (value instanceof Date) return value;
    if (typeof value === 'number') return new Date(value);

    let parsedDate = parseISO(value);
    if (isValid(parsedDate)) return parsedDate;

    parsedDate = new Date(value);
    if (isValid(parsedDate)) return parsedDate;

    return null;
};

export const isSameOrBefore = (date: Date, dateToCompare: Date): boolean => {
    return isEqual(date, dateToCompare) || isBefore(date, endOfDay(dateToCompare));
};

export const isSameOrAfter = (date: Date, dateToCompare: Date): boolean => {
    return isEqual(date, dateToCompare) || isAfter(date, startOfDay(dateToCompare));
};

export const isBeforeDay = (date: Date, dateToCompare: Date): boolean => {
    return !isSameOrAfter(date, dateToCompare);
};

export const isAfterDay = (date: Date, dateToCompare: Date): boolean => {
    return !isSameOrBefore(date, dateToCompare);
};

export const isBetweenDates = (date: Date, dateLeft: Date, dateRight: Date) =>
    isSameOrAfter(date, dateLeft) && isSameOrBefore(date, dateRight);

export const isValidDate = (value: Date | null | undefined): value is Date => isDate(value) && isValid(value);

export function isDateBeforeToday(date: DateLike) {
    const parsed = parseDateFrom(date);
    if (!parsed) return false;

    return isBeforeDay(parsed, new Date());
}
