import { gql } from '@apollo/client';

export const GptConversationHistoryQuery = gql`
    query GptConversationHistory($input: GptConversationInput!) {
        chatGptConversation(input: $input) {
            id
            userInput
            aiOutput
        }
    }
`;

export const SendGptMessageMutation = gql`
    mutation SendGptMessage($input: GptConversationInput!, $text: String!) {
        sendChatGptMessage(input: $input, text: $text) {
            id
            userInput
            aiOutput
        }
    }
`;
