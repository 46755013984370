import { getVehicleTypeSubject } from '@common/abilities/vehicleTypes';
import { AbilityCan } from '@common/enums';

import { useUserAbilities } from '~/contexts';

import { VehicleType } from '../vehicleTypes.types';

type VehicleTypePermissions = {
    canRead: boolean;
    canCreate: boolean;
    canUpdate: boolean;
    canDelete: boolean;
};

export const useVehicleTypePermissions = (vehicle?: VehicleType | null | undefined): VehicleTypePermissions => {
    const { ability, user } = useUserAbilities();

    return {
        canRead: ability.can(AbilityCan.READ, getVehicleTypeSubject(user)),
        canCreate: ability.can(AbilityCan.CREATE, getVehicleTypeSubject(user)),
        canUpdate: ability.can(AbilityCan.UPDATE, getVehicleTypeSubject(user)),
        canDelete: ability.can(AbilityCan.DELETE, getVehicleTypeSubject(user)) && !!vehicle,
    };
};
