import { LocationType } from '../enums';

export * from './common';

export * from './test-utils';

export * from './date';

export const LAT_MIN = -90;

export const LAT_MAX = 90;

export const LNG_MIN = -180;

export const LNG_MAX = 180;

export const defaultCenter = {
    lat: 59.36213,
    lng: 13.17153,
    text: 'Unnamed Road, 660 50 Vålberg, Sweden',
    type: LocationType.VALID,
};

export function hasKey<T extends object, K extends PropertyKey>(obj: T, key: K): obj is T & Record<K, unknown> {
    return key in obj;
}
