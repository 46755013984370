import { gql } from '@apollo/client/core';

import { CommentFragment } from '~/@store/comments';

import { AuthorFragment, FileDocFragment } from '../queries/queries.fragment';

export const DeviationFragment = gql`
    fragment DeviationFragment on Deviation {
        id
        ticketNumber
        issue
        description
        suggestedSolution
        status
        author {
            ...AuthorFragment
        }
        photos {
            ...FileDocFragment
        }
        comments {
            ...CommentFragment
        }
        dateSent
        dateClosed
    }

    ${AuthorFragment}
    ${CommentFragment}
    ${FileDocFragment}
`;

export const DeviationsByProjectQuery = gql`
    query DeviationsByProjectQuery($id: ID!) {
        deviationsByProject(projectId: $id) {
            ...DeviationFragment
        }
    }

    ${DeviationFragment}
`;

export const ProjectDeviationCreateMutation = gql`
    mutation ProjectDeviationCreateMutation($input: DeviationProjectCreate!) {
        deviationProjectCreate(input: $input) {
            ...DeviationFragment
        }
    }

    ${DeviationFragment}
`;

export const ProjectDeviationUpdateMutation = gql`
    mutation ProjectDeviationUpdateMutation($id: ID!, $input: DeviationProjectUpdate!) {
        deviationProjectUpdate(id: $id, input: $input) {
            ...DeviationFragment
        }
    }

    ${DeviationFragment}
`;

export const ProjectDeviationCommentMutation = gql`
    mutation ProjectDeviationCommentMutation($id: ID!, $input: DeviationProjectCommentInput!) {
        deviationProjectComment(id: $id, input: $input) {
            ...DeviationFragment
        }
    }

    ${DeviationFragment}
`;

export const ProjectDeviationCloseMutation = gql`
    mutation ProjectDeviationCloseMutation($id: ID!, $files: [Upload!]!) {
        deviationProjectClose(id: $id, files: $files) {
            ...DeviationFragment
        }
    }

    ${DeviationFragment}
`;

export const ProjectDeviationRemoveFileMutation = gql`
    mutation ProjectDeviationRemoveFileMutation($id: ID!, $fileId: ID!) {
        deviationProjectRemoveFile(id: $id, fileId: $fileId) {
            ...DeviationFragment
        }
    }
    ${DeviationFragment}
`;

export const DeviationsByLandfillQuery = gql`
    query DeviationsByLandfillQuery($id: ID!) {
        deviationsByLandfill(landfillId: $id) {
            ...DeviationFragment
        }
    }

    ${DeviationFragment}
`;

export const LandfillDeviationCreateMutation = gql`
    mutation LandfillDeviationCreateMutation($input: DeviationLandfillCreate!) {
        deviationLandfillCreate(input: $input) {
            ...DeviationFragment
        }
    }

    ${DeviationFragment}
`;

export const LandfillDeviationUpdateMutation = gql`
    mutation LandfillDeviationUpdateMutation($id: ID!, $input: DeviationLandfillUpdate!) {
        deviationLandfillUpdate(id: $id, input: $input) {
            ...DeviationFragment
        }
    }

    ${DeviationFragment}
`;

export const LandfillDeviationCommentMutation = gql`
    mutation LandfillDeviationCommentMutation($id: ID!, $input: DeviationLandfillCommentInput!) {
        deviationLandfillComment(id: $id, input: $input) {
            ...DeviationFragment
        }
    }

    ${DeviationFragment}
`;

export const LandfillDeviationCloseMutation = gql`
    mutation LandfillDeviationCloseMutation($id: ID!, $files: [Upload!]!) {
        deviationLandfillClose(id: $id, files: $files) {
            ...DeviationFragment
        }
    }

    ${DeviationFragment}
`;

export const LandfillDeviationRemoveFileMutation = gql`
    mutation LandfillDeviationRemoveFileMutation($id: ID!, $fileId: ID!) {
        deviationLandfillRemoveFile(id: $id, fileId: $fileId) {
            ...DeviationFragment
        }
    }
    ${DeviationFragment}
`;
