import { TextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';

import { FormikTextField } from './FormikTextField';

type FormikTextFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends string ? TFieldName : never;
} & Omit<TextFieldProps, 'value'>;

export function FormikContextTextField<TValues extends {}, TFieldName extends keyof TValues & string>({
    className,
    ...props
}: FormikTextFieldProps<TValues, TFieldName>) {
    const formik = useFormikContext<TValues>();
    const { root } = useStyles();

    return <FormikTextField<TValues, TFieldName> className={cn(root, className)} formik={formik} {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
}));
