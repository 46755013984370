import './SochiSpoilerView.scss';

import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';

import i18n from '../../i18n';
import { SearchIcon } from '../Icons';
import { SochiTitle } from '../SochiTitle';

type BaseProps = {
    title: React.ReactNode;
    cropClassName: string;
    buttons?: React.ReactNode;
    contentLengthKey?: string | number;
};
type SimpleProps = BaseProps & {
    children: React.ReactNode;
};
type WithSearchProps = BaseProps & {
    withSearch: true;
    children: (searchString: string) => React.ReactNode;
};
type Props = SimpleProps | WithSearchProps;
type State = {
    showAll: boolean;
    isHideNeeded: boolean;
    searchString: string;
};

export class SochiSpoilerView extends React.Component<Props, State> {
    state = {
        showAll: false,
        isHideNeeded: true,
        searchString: '',
    };

    cropContainer = React.createRef<HTMLDivElement>();
    bem = getBem(this);

    componentDidMount() {
        if (this.props.cropClassName) {
            window.addEventListener('resize', this.checkContentVisibility);
            setTimeout(this.checkContentVisibility);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.contentLengthKey !== this.props.contentLengthKey) this.checkContentVisibility();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkContentVisibility);
    }

    toggleShowAll = () => {
        this.setState(state => ({
            showAll: !state.showAll,
        }));
    };

    setSearch = (searchString: string) =>
        this.setState({
            searchString,
        });

    checkContentVisibility = () => {
        const containerHeight = this.cropContainer.current?.clientHeight
            ? this.cropContainer.current.clientHeight + 2
            : 0;

        const contentHeight = this.cropContainer.current?.scrollHeight || 0;
        const contentIsFit = contentHeight <= containerHeight;

        if (contentIsFit) {
            this.setState({
                isHideNeeded: false,
                showAll: false,
            });
        } else {
            this.setState({
                isHideNeeded: true,
            });
        }
    };

    render() {
        const { className, element } = this.bem;

        const { title, children, buttons, cropClassName } = this.props;

        const { showAll, isHideNeeded, searchString } = this.state;

        const content = typeof children === 'function' ? children(searchString) : children;
        const shouldCrop = !showAll && !searchString;

        return (
            <div className={className}>
                <SochiTitle title={title}>
                    {'withSearch' in this.props && this.props.withSearch && (
                        <TextField
                            className={element('search')}
                            placeholder={i18n.search}
                            value={searchString}
                            onChange={e => this.setSearch(e.target.value)}
                            fullWidth={false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon className={element('search-icon')} color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    {buttons}
                    <div className={element('spoiler-button-container')}>
                        {searchString ? (
                            <Button color="primary" variant="contained" onClick={() => this.setSearch('')}>
                                {i18n.clear}
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={!isHideNeeded}
                                onClick={this.toggleShowAll}>
                                <LocalizableText code={showAll ? 'hide' : 'showAll'} />
                            </Button>
                        )}
                    </div>
                </SochiTitle>
                <div ref={this.cropContainer} className={shouldCrop ? cropClassName : undefined}>
                    {content}
                </div>
            </div>
        );
    }
}
