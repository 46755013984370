import Container from '@material-ui/core/Container';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import { makeStyles } from '~/@components/@theme';
import { COLORS } from '~/@components/@theme/colors';
import { Grid } from '~/@components/Grid';
import { Text } from '~/@components/Text';

type Props = {
    children: ReactNode;
};

export const PageLight = ({ children }: Props) => {
    const { rootStyle } = useStyles();

    return (
        <Grid container className={rootStyle}>
            {children}
        </Grid>
    );
};

type HeaderProps = {
    icon?: ReactNode;
    title?: ReactNode;
    leftActions?: ReactNode;
    rightActions?: ReactNode;
};

const Header: FC<HeaderProps> = ({ children, icon, title, leftActions, rightActions }) => {
    const {
        headerStyle,
        containerStyle,
        headerInnerStyle,
        headerRightActionsStyle,
        headerLeftActionsStyle,
        headerTitleStyle,
        headerContentStyle,
    } = useStyles();

    return (
        <Grid item xs={12} className={headerStyle}>
            <Container className={containerStyle}>
                <div className={headerInnerStyle}>
                    {icon || null}
                    {title ? (
                        <Text className={headerTitleStyle} variant="TitleBold">
                            {title}
                        </Text>
                    ) : null}
                    <div className={headerContentStyle}>{children}</div>
                    {leftActions ? <div className={headerLeftActionsStyle}>{leftActions}</div> : null}
                    {rightActions ? <div className={headerRightActionsStyle}>{rightActions}</div> : null}
                </div>
            </Container>
        </Grid>
    );
};

type ContentProps = {
    subTitle?: ReactNode;
    className?: string;
    fullWidth?: boolean;
};

const Content: FC<ContentProps> = ({ children, subTitle, className, fullWidth }) => {
    const { contentStyle, contentInnerStyle, containerStyle, contentSubTitleStyle } = useStyles();

    return (
        <Grid item xs={12} className={classNames(contentStyle, className)}>
            <Container maxWidth={fullWidth ? 'xl' : 'lg'} className={containerStyle}>
                <div className={contentInnerStyle}>
                    {subTitle && (
                        <Text className={contentSubTitleStyle} variant="TableBold">
                            {subTitle}
                        </Text>
                    )}
                    {children}
                </div>
            </Container>
        </Grid>
    );
};

PageLight.Header = Header;
PageLight.Content = Content;

const useStyles = makeStyles(theme => ({
    rootStyle: {
        height: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    containerStyle: {
        height: '100%',
    },
    contentStyle: {
        height: 'calc(100% - 56px)',
        overflow: 'auto',
    },
    contentInnerStyle: {
        paddingTop: theme.spacing(2),
        height: '100%',
    },
    contentSubTitleStyle: {
        padding: theme.spacing(1, 0),
        lineHeight: 1.3,
        color: COLORS.grayDark,
    },
    headerStyle: {
        height: 56,
        backgroundColor: COLORS.grayLightest,
        boxShadow: theme.customShadows[2],
        position: 'relative',
    },
    headerInnerStyle: {
        height: '100%',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        color: COLORS.textBody,
        gap: 18,
    },
    headerTitleStyle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    headerContentStyle: {
        flexGrow: 1,
        marginLeft: 42,
    },
    headerLeftActionsStyle: {
        position: 'absolute',
        left: theme.spacing(3),
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    headerRightActionsStyle: {
        position: 'absolute',
        right: theme.spacing(3),
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}));
