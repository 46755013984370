import { Grid } from '@material-ui/core';
import React from 'react';

import { FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';

import { useCreateCompanyFormikContext } from './CompanyCreateFormik';

export const CompanyCreateFormFields = () => {
    const formik = useCreateCompanyFormikContext();

    return (
        <Grid container spacing={3} justify={'space-between'}>
            <Grid item xs={12}>
                <FormikTextField label={<LocalizableText code={'CompanyCard.orgName'} />} name="name" formik={formik} />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField
                    label={<LocalizableText code={'CompanyCard.orgNumber'} />}
                    name="organizationNumber"
                    formik={formik}
                />
            </Grid>
        </Grid>
    );
};
