import Button, { ButtonProps } from '@material-ui/core/Button';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import React, { ChangeEvent } from 'react';

import { useDisable } from '~/@components/@hooks';
import { MAX_PAYLOAD_SIZE } from '~/config/constants';
import i18n from '~/i18n';
import message from '~/services/message';

type FileUploadButtonProps = Pick<
    ButtonProps,
    'color' | 'variant' | 'disabled' | 'fullWidth' | 'children' | 'className'
> & {
    iconButton?: false;
    onChange: (f: File) => void;
    acceptFileTypes?: string;
    checkFileSize?: boolean;
};

type FileUploadIconButtonProps = Pick<IconButtonProps, 'color' | 'disabled' | 'children' | 'className' | 'title'> & {
    iconButton: true;
    onChange: (f: File) => void;
    acceptFileTypes?: string;
    checkFileSize?: boolean;
};

export const FileUploadButton = ({
    children,
    acceptFileTypes,
    onChange,
    checkFileSize = true,
    iconButton = false,
    ...restProps
}: FileUploadButtonProps | FileUploadIconButtonProps) => {
    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            const file = e.target.files[0]!;
            if (checkFileSize && file.size > MAX_PAYLOAD_SIZE) {
                message.global.error(i18n.tooLargeFile);

                return;
            }

            e.target.value = '';
            onChange(file);
        }
    };

    const disabled = useDisable() || restProps.disabled;

    return iconButton ? (
        <IconButton component="label" {...restProps} disabled={disabled}>
            {children}
            <input
                type="file"
                accept={acceptFileTypes}
                style={inputStyle}
                onChange={onFileChange}
                disabled={disabled}
            />
        </IconButton>
    ) : (
        <Button component="label" {...restProps} disabled={disabled}>
            {children}
            <input
                type="file"
                accept={acceptFileTypes}
                style={inputStyle}
                onChange={onFileChange}
                disabled={disabled}
            />
        </Button>
    );
};

const inputStyle = { display: 'none' };
