import { getDuplicate, isUnique } from '@common/utils';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import isString from 'lodash/isString';
import React, { ReactNode, useMemo } from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import i18n from '~/i18n';

import { ResizableTableCell } from './ResizableTableCell';
import { ResizableTableHeadCell } from './ResizableTableHeadCell';

export enum ColumnAlign {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    CENTER = 'CENTER',
}

export type ITableColumn<TRow> = {
    title: React.ReactNode;
    render: (row: TRow, index: number) => ReactNode;
    // required for column width manipulation. see https://redmine.pinpointer.se/issues/4491
    name: string;
    cellClassName?: string;
    hidden?: boolean;
    align?: ColumnAlign;
    minWidth?: number;
};

interface TableProps<TRow> {
    columns: ITableColumn<TRow>[];
    items: TRow[];
    keyGetter: (r: TRow) => string;
    // required for column width manipulation. see https://redmine.pinpointer.se/issues/4491
    name: string;
    className?: string;
    emptyListMessage?: ReactNode;
}

const CellSize = {
    MAX: 160,
    MIN: 96,
    REGULAR: 120,
};

// Extend is needed here to define TRow as generic instead of JSX tag
export const Table = <TRow extends unknown>({
    items,
    columns,
    keyGetter,
    name: tableName,
    emptyListMessage,
    className,
}: TableProps<TRow>) => {
    const visibleColumns = useMemo(() => columns.filter(c => !c.hidden), [columns]);

    const {
        defaultRoot,
        paper,
        tableContainer,
        table,
        tableCell,
        tableCellHead,
        tableCellBorder,
        stickyCell,
        stickyHead,
        ...alignStyle
    } = useStyles({ columnsCount: visibleColumns.length });

    useSyncEffect(() => {
        const names = columns.map(c => c.name);
        if (!isUnique(names)) throw new TypeError('Column names must be unique: ' + getDuplicate(names));
    }, [columns]);

    return (
        <div className={className || defaultRoot}>
            <Paper className={paper} variant="outlined">
                <TableContainer className={tableContainer}>
                    <MuiTable stickyHeader className={table} aria-label={`${tableName} table`}>
                        <TableHead>
                            <TableRow>
                                {visibleColumns.map((column, index) => {
                                    const isTh = index === 0;

                                    return (
                                        <ResizableTableHeadCell
                                            key={column.name}
                                            tableName={tableName}
                                            columnName={column.name}
                                            minWidth={column.minWidth}
                                            className={classNames(tableCellHead, tableCellBorder, {
                                                [stickyHead]: isTh,
                                            })}>
                                            {column.title}
                                        </ResizableTableHeadCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={visibleColumns.length}>
                                        <Typography variant="h5">{emptyListMessage || i18n.emptyList}</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {items.map((row, rowInd) => (
                                <TableRow key={keyGetter(row)}>
                                    {visibleColumns.map((column, index) => {
                                        const isTh = index === 0;

                                        const content = column.render(row, rowInd);

                                        const cellProps: TableCellProps = {
                                            component: isTh ? 'th' : undefined,
                                            className: classNames(
                                                tableCell,
                                                tableCellBorder,
                                                { [stickyCell]: isTh },
                                                alignStyle[column.align || ColumnAlign.LEFT],
                                                column.cellClassName
                                            ),
                                            scope: isTh ? 'row' : undefined,
                                            title: isString(content) ? content : undefined,
                                        };

                                        return (
                                            <ResizableTableCell
                                                key={`${keyGetter(row)}-${column.name}`}
                                                tableName={tableName}
                                                columnName={column.name}
                                                minWidth={column.minWidth}
                                                {...cellProps}>
                                                {content}
                                            </ResizableTableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </MuiTable>
                </TableContainer>
            </Paper>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    defaultRoot: {
        height: 'calc(100vh - 180px)',
    },
    paper: {
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        borderColor: theme.palette.sectionBorder,
    },
    tableContainer: {
        height: '100%',
    },
    table: ({ columnsCount }: { columnsCount: number }) => ({
        minWidth: `max(100%, ${columnsCount * CellSize.REGULAR}px)`,
    }),
    tableCellHead: {
        position: 'sticky',
        padding: '4px 16px 8px',
        verticalAlign: 'bottom',
    },
    tableCellBorder: {
        borderRight: `1px solid ${theme.palette.grey[200]} !important`,
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        '&:last-child': {
            borderRightWidth: '0 !important',
        },
    },
    tableCell: {
        position: 'relative',
        height: 40,
        padding: '0 16px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    stickyCell: {
        borderRight: `1px solid ${theme.palette.grey[300]} !important`,
        position: 'sticky',
        left: 0,
        zIndex: 1,
    },
    stickyHead: {
        borderRight: `1px solid ${theme.palette.grey[300]} !important`,
        position: 'sticky',
        left: 0,
        zIndex: 3,
    },
    [ColumnAlign.LEFT]: {
        textAlign: 'left',
    },
    [ColumnAlign.RIGHT]: {
        textAlign: 'right',
    },
    [ColumnAlign.CENTER]: {
        textAlign: 'center',
    },
}));
