import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { StorePagination } from '~/@components/Pagination';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import { ICompany, IPagedCompany, usePagedCompanies } from '~/@store/companies';
import { companiesStore } from '~/@store/companies/companies.store';
import { CompaniesFilterSummary } from '~/@views/AdminView/CompaniesPage/CompaniesTable/CompaniesFilterSummary';
import { useCompaniesColumns } from '~/@views/AdminView/CompaniesPage/CompaniesTable/useCompaniesColumns';

export const CompaniesTable = () => {
    const query = usePagedCompanies();

    const { table, tableHeader } = useStyles();

    const columns = useCompaniesColumns();

    return (
        <QueryStateHandler
            query={query}
            dataGetter={query => query.data?.companiesPaged || query.previousData?.companiesPaged}>
            {(data: IPagedCompany) => (
                <>
                    <div className={tableHeader}>
                        <CompaniesFilterSummary />
                        <StorePagination store={companiesStore.pagination} />
                    </div>
                    <Table<ICompany>
                        columns={columns}
                        items={data.edges.map(e => e.node)}
                        keyGetter={o => o.id}
                        className={table}
                        name="CompaniesTable"
                    />
                </>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        height: 'calc(100% - 48px)',
    },
    tableHeader: {
        height: 48,
        display: 'flex',
        marginTop: -8,
    },
}));
