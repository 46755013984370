import React from 'react';

import { HintTooltip } from '../HintTooltip';

interface Props {
    label: React.ReactNode;
    hint: NonNullable<React.ReactNode>;
    className?: string;
    tooltipClassName?: string;
}

export const LabelWithHint = ({ label, hint, ...rest }: Props) => {
    return (
        <>
            {label} <HintTooltip text={hint} {...rest} />
        </>
    );
};
