import { ReactNode } from 'react';

import i18n from '../../i18n';
import dialogStore from '../../stores/dialogStore';
import { ComponentDialogModel, ComponentDialogRender, MessageDialogModel } from '../../stores/models';

/**
 * Show alert dialog
 * ```
 * You can pass message or title or both for displaying content. Also you can change label of CLOSE button with
 * okLabel - default i18n.DialogButtons.close
 * ```
 * @param config Configuration of alert;
 * @returns Promise<boolean> Will be resolved with TRUE after closing dialog;
 */
export const showAlertDialog = (config: { message?: string; title?: string; okLabel?: string }): Promise<boolean> => {
    const { title, message, okLabel } = config;

    return dialogStore._showDialog(
        new MessageDialogModel({ title, message, buttons: { ok: okLabel || i18n.DialogButtons.close } })
    );
};

/**
 * Show confirm dialog
 * ```
 * You can pass message or title or both for displaying content. Also you can change labels of OK and CANCEL button with
 * okLabel - default i18n.DialogButtons.confirm
 * cancelLabel - default i18n.DialogButtons.cancel
 * ```
 * @param config Configuration of confirm;
 * @returns Promise<boolean> Will be resolved with TRUE on OK click, or FALSE on CANCEL click;
 */
export const showConfirmDialog = (config: {
    message?: ReactNode;
    title?: ReactNode;
    okLabel?: ReactNode;
    cancelLabel?: ReactNode;
}): Promise<boolean> => {
    const { title, message, okLabel, cancelLabel } = config;

    return dialogStore._showDialog(
        new MessageDialogModel({
            title,
            message,
            buttons: {
                ok: okLabel || i18n.DialogButtons.confirm,
                cancel: cancelLabel || i18n.DialogButtons.cancel,
            },
        })
    );
};

/**
 * Show custom dialog with custom content.
 * ```
 * Receive optional title string for rendering title (if you dont want to render title by yourself in content)
 * Receive render function, which will be called with one parameter:
 *    closeDialog: (result: boolean) => void
 * Result of called render function will be rendered inside dialog, so this callback can be used to close dialog
 * from content of dialog.
 * ```
 * @param config Configuration of custom dialog;
 * @returns Promise<boolean> - Will be resolved after child component will call closeDialog(boolean);
 */
export const showCustomDialog = <T extends unknown = boolean>(config: {
    title?: ReactNode;
    render: ComponentDialogRender<T>;
    noContainer?: boolean;
    forceCrossClose?: boolean;
    noModalComponent?: boolean;
}): Promise<T> => {
    const { title, render, noContainer, forceCrossClose, noModalComponent } = config;

    return dialogStore._showDialog<T>(
        new ComponentDialogModel<T>({ title, render, noContainer, noModalComponent, forceCrossClose })
    );
};

// Debug and testing purpose
if (window.location.href.includes('localhost')) {
    // @ts-ignore Global variable for testing
    window.test_dialog = { showAlertDialog, showConfirmDialog, showCustomDialog };
}
