import { Divider, ListItemIcon, ListItemText } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Check } from '@material-ui/icons';
import React, { useCallback, useMemo, useState } from 'react';

import { CommonAutocomplete, ICommonAutocompleteProps } from '~/@components/Autocomplete/CommonAutocomplete';
import i18n from '~/i18n';

export type AutocompleteMultiSelectProps<TValue extends { id: string }> = ICommonAutocompleteProps & {
    options: Array<TValue>;
    selectedOptions: Array<TValue>;
    onChange: (values: Array<TValue>) => void;
    renderOption: (v: TValue) => string;
};

export const AutocompleteMultiSelect = <TValue extends { id: string }>({
    options,
    selectedOptions,
    onChange,
    searchValue,
    renderOption,
    ...restProps
}: AutocompleteMultiSelectProps<TValue>) => {
    const [open, setOpen] = useState(!!restProps.autoFocus);

    const handleOptionSelect = useCallback(
        (v: TValue) => {
            const newItems = [...selectedOptions, v];
            onChange(newItems);
        },
        [selectedOptions, onChange]
    );

    const handleOptionDeselect = useCallback(
        (v: TValue) => {
            onChange(selectedOptions.filter(o => o.id !== v.id));
        },
        [selectedOptions, onChange]
    );

    const valueToRender = useMemo(() => {
        if (open) return searchValue;

        if (selectedOptions.length === 0) return '';

        if (selectedOptions.length === 1) return renderOption(selectedOptions[0]!);

        return `${selectedOptions.length} ${i18n.MultiSelect.selected}`;
    }, [open, searchValue, selectedOptions, renderOption]);

    const availableOptions = useMemo(() => {
        return options.filter(o => !selectedOptions.find(s => s.id === o.id));
    }, [options, selectedOptions]);

    return (
        <CommonAutocomplete
            {...restProps}
            searchValue={valueToRender}
            open={open}
            setOpen={setOpen}
            popupContent={
                <MenuList>
                    {selectedOptions.map(o => (
                        <MenuItem onClick={() => handleOptionDeselect(o)} key={o.id}>
                            <ListItemIcon>
                                <Check />
                            </ListItemIcon>
                            {renderOption(o)}
                        </MenuItem>
                    ))}
                    {!!selectedOptions.length && <Divider />}
                    {availableOptions.map(o => (
                        <MenuItem onClick={() => handleOptionSelect(o)} key={o.id}>
                            <ListItemText inset>{renderOption(o)}</ListItemText>
                        </MenuItem>
                    ))}
                    {!availableOptions.length && <MenuItem disabled>{i18n.noDataForFilter}</MenuItem>}
                </MenuList>
            }
        />
    );
};
