import './SochiLocationEdit.scss';

import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { NumberField } from '~/@components/NumberField';
import { TextField } from '~/@components/TextField';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ILocation } from '~/@store/locations';
import { commonInputTypes, commonPrecisions } from '~/config/enum';

type Props = {
    location: Partial<ILocation>;
    readOnly?: boolean;
    onInputLocation: (location: Partial<ILocation>) => void;
    onChangeLatLng: (lat: number | undefined, lng: number | undefined) => void;
    onChangeText: (text: string) => void;
    hideCoordinates?: boolean;
};

export const SochiLocationEdit = ({
    location,
    readOnly,
    onInputLocation,
    onChangeLatLng,
    onChangeText,
    hideCoordinates,
}: Props) => {
    const { className, element } = useFunctionalBem(SochiLocationEdit);

    return (
        <div className={className}>
            {!hideCoordinates && (
                <div className={element('lat-lng')}>
                    <div className={element('part')}>
                        <InputLabel>
                            <LocalizableText code={'latitude'} />
                        </InputLabel>
                        <NumberField
                            type={commonInputTypes.decimal}
                            precision={commonPrecisions.location}
                            value={location.lat || 0}
                            disabled={readOnly}
                            onChange={value => onInputLocation({ ...location, lat: value })}
                            onBlur={() => onChangeLatLng(location.lat, location.lng)}
                        />
                    </div>
                    <div className={element('part')}>
                        <InputLabel>
                            <LocalizableText code={'longitude'} />
                        </InputLabel>
                        <NumberField
                            type={commonInputTypes.decimal}
                            precision={commonPrecisions.location}
                            value={location.lng || 0}
                            disabled={readOnly}
                            onChange={value => onInputLocation({ ...location, lng: value })}
                            onBlur={() => onChangeLatLng(location.lat, location.lng)}
                        />
                    </div>
                </div>
            )}
            <div>
                <TextField
                    label={<LocalizableText code={'address'} />}
                    value={location.text || ''}
                    disabled={readOnly}
                    placeholder=""
                    onChange={e => onInputLocation({ ...location, text: e.target.value })}
                    onBlur={() => onChangeText(location.text || '')}
                />
            </div>
        </div>
    );
};
