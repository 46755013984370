import { AbilityBuilder } from '@casl/ability';
import { useMemo } from 'react';

import { defineAbilitiesForUsers, IUserCalculatedSubject } from '../../../../common/abilities/users';
import { CommonRenderRule, formatCondition, mapCommonPart, TypedConditionsRule } from './common';

type UserRule = TypedConditionsRule<IUserCalculatedSubject>;

type UserRenderRule = CommonRenderRule & {
    subject: string;
};

export const useUserRules = (): UserRenderRule[] =>
    useMemo(() => {
        const { rules, can, cannot } = AbilityBuilder.extract();

        defineAbilitiesForUsers(can, cannot);

        return (rules as UserRule[]).map(r => ({
            ...mapCommonPart(r),
            subject: formatCondition(r.conditions?.subSubject),
        }));
    }, []);
