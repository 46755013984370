import './DocumentPreview.scss';

import classnames from 'classnames';
import React, { VFC } from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';

import { isPdf } from '../../../../common/utils';
import { ClickAwayListener } from '../ClickAwayListener';
import { ImgLoader } from '../ImgLoader';
import { SochiPdfPreview } from '../SochiPdfPreview';

type Params = Record<string, string>;

type Props = {
    downloadUrl: string;
    params?: Params;
    filename: string;
    className?: string;
};

export const DocumentPreview: VFC<Props> = ({ className, downloadUrl, params = {}, filename }) => {
    const { block, element } = useFunctionalBem(DocumentPreview);

    const isPdfFile = isPdf(filename);

    return (
        <div className={classnames(className, block({ 'with-pdf': isPdfFile }))}>
            {!isPdfFile && (
                <ImgLoader url={downloadUrl} params={params}>
                    {imgBase64 => <img alt={i18n.document} className={element('image')} src={imgBase64} />}
                </ImgLoader>
            )}
            {isPdfFile && <SochiPdfPreview url={downloadUrl} fetchData={params} />}
        </div>
    );
};

export const showDocumentPreviewDialog = (
    downloadUrl: string,
    params: Params = {},
    filename: string,
    title?: string
): void => {
    const ignoredPromise = showCustomDialog({
        title: title || filename,
        forceCrossClose: true,
        render: closeDialog => (
            <ClickAwayListener onClickAway={closeDialog}>
                <DocumentPreview downloadUrl={downloadUrl} params={params} filename={filename} />
            </ClickAwayListener>
        ),
    });
};
