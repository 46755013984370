import { UnitOfMeasure } from '@common/enums';
import { formatAmount } from '@common/utils/formaters';
import React from 'react';

import { NullableFormatter } from './NullableFormatter';

type Props = {
    children: number | null | undefined;
    unit: UnitOfMeasure;
};

export const AmountFormatter = (props: Props) => (
    <NullableFormatter>{props.children ? formatAmount(props.children, props.unit) : null}</NullableFormatter>
);
