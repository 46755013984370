import './LandfillSchedule.scss';

import { Granularity } from '@common/enums';
import React, { useMemo, useState } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ScheduleChart } from '~/@sochi-components/charts/ScheduleChart';
import type { GranularitySwitchValue } from '~/@sochi-components/SochiGranularitySwitch';
import { SochiGranularitySwitch } from '~/@sochi-components/SochiGranularitySwitch';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import type { SeriesIntervalValue } from '~/utils';
import { fmtVolInTon } from '~/utils/numbers';
import { convertOrderVolumesToSeriesData } from '~/utils/order';

import type { LandfillDetailsDataProps } from '../LandfillPage';

const getSubHeader = (data: SeriesIntervalValue[]): string => {
    const total = data.reduce((t, el) => {
        t += el.planned + el.delivered;

        return t;
    }, 0);

    return fmtVolInTon(total);
};

export const LandfillSchedule = ({ landfill }: LandfillDetailsDataProps) => {
    const { className } = useFunctionalBem(LandfillSchedule);

    const [granularity, setGranularity] = useState<GranularitySwitchValue>(Granularity.week);

    const isEmptyData = landfill.ordersInfo.length === 0;
    const series = useMemo(() => landfill.ordersInfo.map(convertOrderVolumesToSeriesData), [landfill]);

    return (
        <div className={className}>
            <SochiTitle title={<LocalizableText code={'LandfillSchedule.title'} />}>
                <SochiGranularitySwitch onChange={setGranularity} value={granularity} readOnly={isEmptyData} />
            </SochiTitle>
            <ScheduleChart
                data={series}
                filter={granularity}
                getSubHeader={getSubHeader}
                noContentMessage={!isEmptyData ? <LocalizableText code={'noTransportScheduleForFilters'} /> : undefined}
            />
        </div>
    );
};
