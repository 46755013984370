import React, { FC } from 'react';

import { NullableFormatter } from '~/@components/Formatters/NullableFormatter';
import { LocalizableText } from '~/@components/LocalizableText';

import { makeStyles } from '../@theme';

type Props = {
    children?: {
        inbound: boolean;
        skipMatching: boolean;
    } | null;
    narrow?: boolean;
    inline?: boolean;
};

export const DumpLoadModeFormatter: FC<Props> = ({ inline, narrow, children }) => {
    const { root, narrowStyle } = useStyles();

    if (!children) return <NullableFormatter>{null}</NullableFormatter>;

    if (inline)
        return (
            <>
                <LocalizableText
                    code={children.inbound ? 'DumploadSummarySection.inbound' : 'DumploadSummarySection.outbound'}
                />
                <LocalizableText
                    code={
                        children.skipMatching
                            ? 'DumploadSummarySection.withoutMatching'
                            : 'DumploadSummarySection.withMatching'
                    }
                />
            </>
        );

    return (
        <div className={root}>
            <div>
                <LocalizableText
                    code={children.inbound ? 'DumploadSummarySection.inbound' : 'DumploadSummarySection.outbound'}
                />
            </div>
            <div className={narrow ? narrowStyle : undefined}>
                <LocalizableText
                    code={
                        children.skipMatching
                            ? 'DumploadSummarySection.withoutMatching'
                            : 'DumploadSummarySection.withMatching'
                    }
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    },
    narrowStyle: {
        marginTop: -8,
    },
}));
