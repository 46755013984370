import { Card, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

import { DistanceFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';

import { useDumpLoadFormikContext } from '../../DumpLoadEditFormik';
import { DumpLoadLandfillDestinationRow } from './DumpLoadLandfillDestinationRow';

export const DumpLoadLandfillSelectedDestination: FC = () => {
    const { root, distanceInfo } = useStyles();

    const formik = useDumpLoadFormikContext();

    const destination = formik.values.destination;

    return destination ? (
        <Card>
            <div className={root}>
                <DumpLoadLandfillDestinationRow landfill={destination.landfill} />
                <div className={distanceInfo}>
                    <Text variant="SmallText" color="textSecondary" display="inline">
                        <LocalizableText code={'DumploadSummarySection.Distance'} />
                    </Text>
                    <Text variant="BodyBold" display="inline">
                        <DistanceFormatter>{destination.distance}</DistanceFormatter>
                    </Text>
                </div>
            </div>
        </Card>
    ) : null;
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    distanceInfo: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'flex-end',
    },
}));
