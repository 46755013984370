import React from 'react';

import { FormikSelectField } from '~/@components/FormikFields';
import { UnitOfMeasure } from '~/graphql';
import i18n from '~/i18n';

import { ISubareaFormik } from '../SubareaEdit.types';

type Props = { formik: ISubareaFormik };

export const SubareaUnitSelect = ({ formik }: Props) => {
    return (
        <FormikSelectField
            label={i18n.unitOfMeasure}
            name="unitOfMeasure"
            formik={formik}
            items={Object.values(UnitOfMeasure)}
            renderValue={unit => i18n.unitsOfMeasure[unit]}
            keyGetter={v => v}
            addEmptyOption={false}
        />
    );
};
