import './LoginPage.scss';

import { routes } from '@common/routes';
import type { FormikProps } from 'formik';
import { Form } from 'formik';
import React, { Component } from 'react';
import type { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { Button } from '~/@components/Button';
import { FormikContextTextField } from '~/@components/FormikFields';
import { getBem } from '~/@sochi-components/@bem';
import type { WithAbilitiesProps } from '~/contexts';
import type { WithSettingsProps } from '~/contexts';
import i18n from '~/i18n';

import { AuthDivider } from '../common/AuthDivider';
import { AuthTitle } from '../common/AuthTitle';
import { AuthWrapper } from '../common/AuthWrapper';

export interface ILoginFormValues {
    login: string;
    password: string;
}

enum FormNames {
    login = 'login',
    password = 'password',
}

export type ILoginFormProps = FormikProps<ILoginFormValues> &
    WithAbilitiesProps &
    WithSettingsProps &
    RouteComponentProps;

export class LoginPage extends Component<ILoginFormProps> {
    bem = getBem(this);

    renderTitle = () => `${i18n.loginScreen.welcomeTo} ${this.props.app}`;

    renderSubtitle = () => `${i18n.loginScreen.join} ${this.props.app} ${i18n.loginScreen.today}`;

    goToSignUp = () => this.props.history.push(routes.signup);

    render() {
        const { element } = this.bem;

        return (
            <AuthWrapper>
                <AuthTitle title={this.renderTitle()} subtitle={i18n.loginScreen.signInToContinue} />
                <Form className={element('form')}>
                    <FormikContextTextField<ILoginFormValues, FormNames.login>
                        name={FormNames.login}
                        label={i18n.email}
                    />
                    <FormikContextTextField<ILoginFormValues, FormNames.password>
                        name={FormNames.password}
                        type="password"
                        label={i18n.password}
                    />

                    <Link className={element('forgot-password')} to={routes.forgotPassword}>
                        {i18n.forgotPassword}
                    </Link>

                    <div>
                        <Button type="submit" color="primary" size="large" variant="contained">
                            {i18n.signIn}
                        </Button>
                    </div>
                </Form>

                <AuthDivider />

                <AuthTitle title={i18n.loginScreen.notAMember} subtitle={this.renderSubtitle()} />
                <Button className={element('create-button')} size="large" onClick={this.goToSignUp}>
                    {i18n.createAccount}
                </Button>
            </AuthWrapper>
        );
    }
}
