import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { DateFilter, StringFilter, TableHeadCell } from '~/@components/Table/TableHeadCell';
import { TimeSpentFilterField, timeSpentStore, userFilter } from '~/@store/timeSpent';
import { UserFilter } from '~/@views/AdminView/common/UserFilter';

type Props = {
    title: React.ReactNode;
    fieldName: TimeSpentFilterField;
    withFilter?: boolean;
    withSort?: boolean;
    disabled?: boolean;
};

export const TimeSpentHeadCell = observer(({ title, fieldName, withFilter, disabled }: Props) => {
    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        switch (fieldName) {
            case 'dumpLoadNumber':
                return (
                    <StringFilter
                        valueGetter={() => timeSpentStore.filter.values[fieldName]}
                        setValue={v => timeSpentStore.filter.setValue(fieldName, v)}
                    />
                );
            case 'date':
                return (
                    <DateFilter
                        valueGetter={() => timeSpentStore.filter.values[fieldName]}
                        setValue={v => timeSpentStore.filter.setValue(fieldName, v)}
                    />
                );
            case 'user':
                return (
                    <UserFilter
                        valueGetter={() => timeSpentStore.filter.values[fieldName]}
                        setValue={v => timeSpentStore.filter.setValue(fieldName, v)}
                        filter={userFilter}
                    />
                );
            default:
                return null;
        }
    }, [withFilter, fieldName]);

    return (
        <TableHeadCell title={title} sortDirection={null} readonly={disabled}>
            {filterSection}
        </TableHeadCell>
    );
});
