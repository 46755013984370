import { AbilitySubjects } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { ISettingCalculatedSubject } from './settingTypes';

export const getSettingSubject = (user: IAbilityUser): ISettingCalculatedSubject => {
    const relationRole = [user.role!];

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.SETTINGS,
        relationRole,
    };
};
