import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { UserPasswordResetMutation, UserPasswordResetMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../users.queries';

export type UserPasswordResetFunc = (userId: string) => Promise<boolean>;

export const useUserPasswordResetMutation = (): [UserPasswordResetFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<UserPasswordResetMutation, UserPasswordResetMutationVariables>(
        queries.UserPasswordResetMutation
    );

    const resetUserPassword = useCallback<UserPasswordResetFunc>(
        userId => {
            return callMutation({
                variables: { userId },
            })
                .then(result => !!result.data?.userPasswordReset)
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [resetUserPassword, mutationResult.loading];
};
