import { formatString } from '@common/utils';
import { Button, PropTypes } from '@material-ui/core';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { showConfirmDialog } from '~/@store/common';
import { ChangeStatusFunc } from '~/@store/dumpLoads';
import { useProjectAbilities } from '~/@store/projects';
import { DumpLoadActionType, LoadStatus } from '~/graphql';
import i18n from '~/i18n';

import { useDumpLoadFormikContext } from './DumpLoadEditFormik';

const ColorsMap: Record<DumpLoadActionType, PropTypes.Color> = {
    [DumpLoadActionType.BUTTON]: 'primary',
    [DumpLoadActionType.LINK]: 'default',
};

type Props = {
    updateStatus: ChangeStatusFunc;
};

const getButtonTitle = (status: LoadStatus) =>
    formatString(i18n.DumpLoadCard.statusWillChange, i18n.DumploadStatusesSochi[status], status);

export const DumpLoadEditChangeStatus = ({ updateStatus }: Props) => {
    const formik = useDumpLoadFormikContext();
    const { dumpLoad } = formik.values;

    async function handleChangeStatus(status: LoadStatus) {
        if (status === LoadStatus.DONE) {
            const confirmed = await showConfirmDialog({
                title: <LocalizableText code={'DumpLoadsTable.orderStatus'} />,
                render: () => <LocalizableText code={'DumploadStatusQuestMarkAsDone'} />,
            });

            if (!confirmed) return;
        }

        await updateStatus(status);

        formik.resetForm();
    }

    const { canEditProject } = useProjectAbilities(dumpLoad.project);

    return (
        <>
            {dumpLoad.actionsToShow.map(action => (
                <Button
                    key={action.actionName}
                    title={getButtonTitle(action.toStatus)}
                    variant="contained"
                    color={ColorsMap[action.type]}
                    onClick={() => handleChangeStatus(action.toStatus)}
                    disabled={!canEditProject}>
                    {i18n.DumploadActions[action.actionName]}
                </Button>
            ))}
        </>
    );
};
