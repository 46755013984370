import React from 'react';

import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { showCustomDialog } from '~/@store/common';
import { IDumpLoadSearchRecord } from '~/@store/dumpLoads';

import { DeliveryLineCreateForm } from './DeliveryLineCreateForm';

type DeliveryLineEditDialogProps = {
    dumpLoad: IDumpLoadSearchRecord | null;
    onClose: (v: boolean) => void;
};

const DeliveryLineCreateDialog = ({ dumpLoad, onClose }: DeliveryLineEditDialogProps) => {
    return (
        <CardDialog
            onClose={() => onClose(false)}
            title={<LocalizableText code={'DeliveryLineCard.createHeader'} />}
            maxWidth="lg"
            fullWidth>
            <DeliveryLineCreateForm onClose={onClose} dumpLoad={dumpLoad} />
        </CardDialog>
    );
};

export const showDeliveryLineCreateDialog = (dumpLoad: IDumpLoadSearchRecord | null = null) =>
    showCustomDialog({
        render: closeDialog => <DeliveryLineCreateDialog onClose={closeDialog} dumpLoad={dumpLoad} />,
    });
