import { getCompanySubject } from '@common/abilities/companies';
import { CompanySubject } from '@common/abilities/companies';
import { AbilityCan, LandfillFields } from '@common/enums';
import React, { FC } from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import { SochiCustomerSelect } from '~/@views/UserView/common/SochiCustomerSelect';
import { canReadLandfillField } from '~/utils/auth';

import { FormNames } from './landfillFormikHOC';
import { useLandfillFormContext } from './LandfillFormWithContext';

type Props = {
    className: string;
};

export const LandfillCustomerSelect: FC<Props> = ({ className }) => {
    const bem = useFunctionalBem(LandfillCustomerSelect, className);
    const { landfill, user, values, errors, ability, touched, setFieldValueAndTouchIt, isSubmitting } =
        useLandfillFormContext();

    if (!canReadLandfillField(user, ability, landfill, LandfillFields.customer)) return null;

    return (
        <div className={bem.className}>
            <SochiCustomerSelect
                disabled={isSubmitting || !ability.can(AbilityCan.READ, getCompanySubject(user, [CompanySubject.LIST]))}
                errorMessage={(touched[FormNames.customer] && errors[FormNames.customer]?.toString()) || undefined}
                value={values[FormNames.customer] || null}
                onChange={value => setFieldValueAndTouchIt(FormNames.customer, value, true)}
            />
        </div>
    );
};
