import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { LandfillCreateMutation, LandfillCreateMutationVariables, LandfillInput } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../landfills.queries';
import { landfillsStore } from '../landfills.store';
import { IDetailedLandfill } from '../landfills.types';

type CreateLandfillFunc = (input: LandfillInput) => Promise<IDetailedLandfill>;

export const useLandfillCreateMutation = (): [CreateLandfillFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<LandfillCreateMutation, LandfillCreateMutationVariables>(
        queries.LandfillCreateMutation
    );

    const createLandfill = useCallback<CreateLandfillFunc>(
        input => {
            return callMutation({
                variables: { input },
                refetchQueries: [
                    {
                        query: queries.AdminLandfillsPagedQuery,
                        variables: landfillsStore.landfillsQueryVariables,
                    },
                ],
            })
                .then(result => result.data?.landfillCreate || Promise.reject(new Error('Empty response')))
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [createLandfill, mutationResult.loading];
};
