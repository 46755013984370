import { ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';

export type OptionValue = {
    id: string;
    name: string;
};

type Props<T> = {
    values: T[];
    valueGetter: () => T | null | undefined;
    setValue: (v: T | null) => void;
};

export const SochiSingleOptionFilter = <T extends OptionValue>({ values, valueGetter, setValue }: Props<T>) => {
    const { selected } = useStyles();

    const filter = useObservable(valueGetter);

    const handleFilterChange = useCallback(
        (value: T) => {
            if (!filter) {
                setValue(value);

                return;
            }

            if (filter.id === value.id) {
                setValue(null);
            } else {
                setValue(value);
            }
        },
        [setValue, filter]
    );

    return (
        <>
            {values.map(v => (
                <ListItem
                    key={v.id}
                    button
                    divider
                    onClick={() => handleFilterChange(v)}
                    className={filter?.id === v.id ? selected : undefined}>
                    <ListItemText>{v.name}</ListItemText>
                </ListItem>
            ))}
        </>
    );
};

const useStyles = makeStyles(theme => ({
    selected: {
        backgroundColor: theme.palette.background.default,
    },
}));
