import React, { VFC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { StorePagination } from '~/@components/Pagination';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import { IPagedUserActivities, IUserActivity, userActivitiesStore, useUserActivities } from '~/@store/userActivities';

import { UserActivityFilterSummary, useUserActivityColumns } from './UserActivity';

export const UserActivityTab: VFC = () => {
    const query = useUserActivities();
    const columns = useUserActivityColumns();

    const { table, tableFooter } = useStyles();

    return (
        <QueryStateHandler
            query={query}
            dataGetter={query => query.data?.activitiesPaged || query.previousData?.activitiesPaged}>
            {(data: IPagedUserActivities) => (
                <>
                    <Table<IUserActivity>
                        columns={columns}
                        items={data.edges.map(e => e.node)}
                        keyGetter={o => o.id}
                        className={table}
                        name="UserActivity"
                    />
                    <div className={tableFooter}>
                        <UserActivityFilterSummary />
                        <StorePagination store={userActivitiesStore.pagination} />
                    </div>
                </>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        height: 'calc(100% - 72px)',
    },
    tableFooter: {
        height: 48,
        display: 'flex',
    },
}));
