import { formatString, parseDateFrom } from '@common/utils';
import { format, isSameDay, max } from 'date-fns';
import { FormikContextType } from 'formik';
import React, { FC } from 'react';

import { Grid } from '~/@components/Grid';
import { HintTooltip } from '~/@components/HintTooltip';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { SochiDateRangePicker } from '~/@sochi-components/SochiDatePicker';
import {
    FormNames,
    IDate,
    IFormValues,
    IProject,
} from '~/@views/UserView/ProjectPage/ProjectPriceCalculator/AddEditMassForm/AddEditMass.types';
import { useUserAbilities } from '~/contexts';
import i18n from '~/i18n';
import { canUpdateProjectDateBeforeToday } from '~/utils/auth';

type Props = {
    formik: FormikContextType<IFormValues>;
    project: IProject;
    readOnly: boolean;
};

export const DateFields: FC<Props> = ({ formik, project, readOnly }) => {
    const { values, errors, touched, setFieldValue } = formik;

    const projectEndDate = parseDateFrom(project.endDate);
    const abilityContext = useUserAbilities();

    const minDate = React.useMemo(() => {
        const canSetDateBeforeToday = canUpdateProjectDateBeforeToday(abilityContext, project);

        const projectStartDate = parseDateFrom(project.startDate) || new Date();

        if (canSetDateBeforeToday) {
            return projectStartDate;
        } else {
            return max([projectStartDate, new Date()]);
        }
    }, [abilityContext, project]);

    return (
        <>
            <Grid item xs={12}>
                <Text variant="TitleBold">
                    <LocalizableText code={'ProjectAddEditMass.dates'} />
                    <HintTooltip text={<LocalizableText code={'ProjectAddEditMass.datesHint'} />} />
                </Text>
            </Grid>
            <Grid item lg={4} md={6}>
                <SochiDateRangePicker
                    startDatePlaceholder={i18n.startDate}
                    endDatePlaceholder={i18n.endDate}
                    onChange={dates => setFieldValue(FormNames.dates, { ...values.dates, ...dates })}
                    values={values.dates}
                    errorMessage={
                        touched[FormNames.dates] && errors[FormNames.dates] ? `${errors[FormNames.dates]}` : undefined
                    }
                    minDate={minDate}
                    message={
                        projectEndDate
                            ? formatString(
                                  i18n.ProjectAddEditMass.calendarInfoProjectEndDate,
                                  format(projectEndDate, i18n.dateFormat)
                              )
                            : ''
                    }
                    shouldHighlightDate={(date: IDate) => !!date && !!projectEndDate && isSameDay(projectEndDate, date)}
                    isStartDateReadOnly={readOnly}
                    isEndDateReadOnly={readOnly}
                />
            </Grid>
        </>
    );
};
