import React, { ChangeEvent, useCallback, VFC } from 'react';

import { TextField } from '~/@components/TextField';
import { useFunctionalBem } from '~/@sochi-components/@bem';

import type { IInputForDeclarationProps } from './ProjectDumpLoadDeclaration';

export const ProjectDumpLoadDeclarationInput: VFC<IInputForDeclarationProps> = ({
    value,
    onChange,
    readOnly,
    name,
    className: classNameExternal,
}) => {
    const { className } = useFunctionalBem(ProjectDumpLoadDeclarationInput, classNameExternal);
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChange(e.target.value),
        [onChange]
    );

    return (
        <TextField className={className} value={value || ''} onChange={handleChange} disabled={readOnly} name={name} />
    );
};
