import { Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';
import React from 'react';

import { DatePicker, DatePickerProps } from '~/@components/DatePicker';

type Props = {
    name: string;
} & Omit<DatePickerProps, 'onChange' | 'name' | 'value'>;

export const FormikTypeUnsafeDateField = ({ name, disabled, ...restProps }: Props) => {
    return (
        <Field name={name}>
            {({ field, form, meta }: FieldProps) => (
                <DatePicker
                    {...field}
                    onChange={v => {
                        form.setFieldTouched(field.name, true);
                        form.setFieldValue(field.name, v);
                    }}
                    name={name}
                    error={meta.touched && !!meta.error}
                    {...restProps}
                    disabled={disabled || form.isSubmitting}
                />
            )}
        </Field>
    );
};
