import React from 'react';

import { useDeviceMedia } from '~/contexts';

import { PageNavDesktopMenu } from './PageNavDesktopMenu';
import { PageNavMenuProps } from './PageNavMenu.types';
import { PageNavMobileMenu } from './PageNavMobileMenu';

export const PageNavMenu = (props: PageNavMenuProps) => {
    const { isMobileOrTablet } = useDeviceMedia();

    return isMobileOrTablet ? <PageNavMobileMenu {...props} /> : <PageNavDesktopMenu {...props} />;
};
