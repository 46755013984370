import { DespatchAdviceSource } from '@common/enums';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { DateFormatter, NullableFormatter, UserFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn } from '~/@components/Table';
import { ITimeSpent } from '~/@store/timeSpent';
import { BeastCell } from '~/@views/AdminView/common/BeastCell';
import { TimeSpentHeadCell } from '~/@views/AdminView/TimeSpentPage/TimeSpentHeadCell';

export const useTimeSpentColumns = () => {
    const { buttonCell } = useStyles();

    // noinspection UnnecessaryLocalVariableJS
    const columns: ITableColumn<ITimeSpent>[] = [
        {
            title: <TimeSpentHeadCell title={<LocalizableText code="TimeSpent.user" />} fieldName="user" withFilter />,
            render: row => <UserFormatter>{row.user}</UserFormatter>,
            name: 'user',
        },
        {
            title: <LocalizableText code="TimeSpent.licensePlate" />,
            render: row => row.licensePlate,
            name: 'licensePlate',
        },
        {
            title: (
                <TimeSpentHeadCell
                    title={<LocalizableText code="TimeSpent.dumpLoadNumber" />}
                    fieldName="dumpLoadNumber"
                    withFilter
                />
            ),
            render: row => row.dumpLoadNumber,
            name: 'dumpLoadNumber',
        },
        {
            title: <TimeSpentHeadCell title={<LocalizableText code="TimeSpent.date" />} fieldName="date" withFilter />,
            render: row => <DateFormatter>{row.date}</DateFormatter>,
            name: 'date',
        },
        {
            title: <LocalizableText code="TimeSpent.hoursSpent" />,
            render: row => row.hoursSpent,
            name: 'hoursSpent',
        },
        {
            title: <LocalizableText code="TimeSpent.comment" />,
            render: row => <NullableFormatter>{row.comment}</NullableFormatter>,
            name: 'comment',
        },
        {
            title: <LocalizableText code={'DeliveryLinesTable.beastStatus'} />,
            render: row => <BeastCell entity={row} source={DespatchAdviceSource.TIME_SPENT} />,
            cellClassName: buttonCell,
            name: 'beast',
        },
    ];

    return columns;
};

const useStyles = makeStyles(() => ({
    buttonCell: {
        minWidth: 'max-content',
    },
}));
