import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { Page } from '~/@components/Page';

import { CompaniesTable } from './CompaniesTable';
import { CompaniesUsersRoute, CompaniesUsersRouteSwitch } from './CompaniesUsersRouteSwitch';
import { showCompanyCreateDialog } from './CompanyCreateForm';

export const CompaniesPage = () => {
    const { button } = useStyles();

    return (
        <Page>
            <Page.Header distributedHeader>
                <Grid container alignItems={'center'} spacing={3}>
                    <Grid item>
                        <CompaniesUsersRouteSwitch route={CompaniesUsersRoute.companies} />
                    </Grid>
                </Grid>
                <ActionButton className={button} onClick={showCompanyCreateDialog}>
                    <LocalizableText code={'CompaniesTable.createCompany'} />
                </ActionButton>
            </Page.Header>
            <Page.Content>
                <CompaniesTable />
            </Page.Content>
        </Page>
    );
};

const useStyles = makeStyles({
    button: {
        whiteSpace: 'nowrap',
    },
});
