import './FilesInput.scss';

import { IconButton, InputLabel } from '@material-ui/core';
import React, { ReactNode, useCallback } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { CloseCrossIcon } from '~/@sochi-components/Icons';
import i18n from '~/i18n';

import { MAX_PAYLOAD_SIZE } from '../../../config/constants';
import { globalMessage } from '../../../services/message';

type FilesInputProps = {
    value: File[];
    onChange: (v: File[]) => void;
    label?: ReactNode;
    errorMessage?: string;
    readOnly?: boolean;
    className?: string;
};

export const FilesInput = ({
    value: files,
    label,
    errorMessage,
    onChange,
    readOnly,
    className: externalClassName,
}: FilesInputProps) => {
    const { className, element } = useFunctionalBem(FilesInput, externalClassName);

    const handleAddFiles = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newFiles = Array.from(event.target.files || []);
            event.target.value = '';

            if (newFiles.some(nf => nf.size >= MAX_PAYLOAD_SIZE)) {
                return globalMessage.error(i18n.tooLargeFile);
            }

            onChange([...files, ...newFiles]);
        },
        [files, onChange]
    );

    const handleDeleteFile = useCallback(
        (file: File) => {
            onChange(files.filter(f => f !== file));
        },
        [files, onChange]
    );

    const inputRef = React.useRef<HTMLInputElement>(null);
    const onInputClick = useCallback(() => {
        inputRef.current?.click();
    }, []);

    return (
        <div className={className}>
            {label && <InputLabel error={!!errorMessage}>{errorMessage || label}</InputLabel>}
            <div className={element('files-container')}>
                {files.map((f, i) => (
                    <div key={String(i) + f.name} className={element('file-box')} title={f.name}>
                        <Text className={element('file-name')}>{f.name}</Text>
                        <IconButton onClick={() => handleDeleteFile(f)}>
                            <CloseCrossIcon color="primary" fontSize="small" />
                        </IconButton>
                    </div>
                ))}
            </div>

            {!readOnly && (
                <div className={element('file-box')}>
                    <input
                        ref={inputRef}
                        className={element('input')}
                        id="file-add-input"
                        type="file"
                        onChange={handleAddFiles}
                    />
                    <Button color="primary" variant="contained" size="small" onClick={onInputClick}>
                        <LocalizableText code={'addFile'} />
                    </Button>
                </div>
            )}
        </div>
    );
};
