import React, { useEffect, useState } from 'react';

import { useDebounce } from '~/@components/@hooks/use-debounce';
import { useProgressState } from '~/@components/@hooks/use-progress-state';
import { TIMEOUT_ON_SEARCH_DEBOUNCE } from '~/@store/common';

import { AutocompleteMultiSelect } from './AutocompleteMultiSelect';
import { ICommonAutocompleteProps } from './CommonAutocomplete';

export type AutocompleteMultiSelectAsyncProps<TValue> = {
    selectedOptions: Array<TValue>;
    onChange: (values: Array<TValue>) => void;
    renderOption: (v: TValue) => string;
    loadOptions: (search: string) => Promise<Array<TValue>>;
} & Omit<ICommonAutocompleteProps, 'searchValue' | 'onSearchChange'>;

export const AutocompleteMultiSelectAsync = <TValue extends { id: string }>({
    loadOptions,
    ...restProps
}: AutocompleteMultiSelectAsyncProps<TValue>) => {
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState<Array<TValue>>([]);

    const debouncedSearch = useDebounce(search, TIMEOUT_ON_SEARCH_DEBOUNCE);

    const [isLoading, _loadOptions] = useProgressState(loadOptions);

    useEffect(() => {
        if (debouncedSearch) _loadOptions(debouncedSearch).then(setOptions);
    }, [debouncedSearch, _loadOptions]);

    return (
        <AutocompleteMultiSelect
            {...restProps}
            searchValue={search}
            onSearchChange={setSearch}
            options={options}
            isLoading={isLoading}
        />
    );
};
