import { format, isValid, parse } from 'date-fns';

import { FieldsAffectsTransportSchedule } from '../constants';
import { DumpLoadFields, ErrorCodes } from '../enums';
import type { IErrorCode } from '../types';
import { parseDateFrom } from '../utils';
import { isNotEmpty, isNumber, isString } from './types.validation';

function _isArrayAscending(arr: number[]) {
    for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i]! >= arr[i + 1]!) return false;
    }

    return true;
}

function _isArrayDescending(arr: number[]) {
    for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i]! <= arr[i + 1]!) return false;
    }

    return true;
}

export function isArraySorted(arr: Array<number>, ascending: boolean = true) {
    return ascending ? _isArrayAscending(arr) : _isArrayDescending(arr);
}

export const serviceOrgNumber = '0000000000';

export const sanitizeOrganizationNumber = (input: string) => input.replace(/\D/g, '');

const ORG_NUMBER_VALIDATION_REGEX = /^\d{6}[-]?\d{4}$/;

export const isValidOrgNr = (value: string | null | undefined) => {
    if (!value) return false;

    return ORG_NUMBER_VALIDATION_REGEX.test(value) && sanitizeOrganizationNumber(value) !== serviceOrgNumber;
};

const GLN_VALIDATION_REGEX = /^\d{13}$/;

export const isValidGLN = (value: string | null | undefined) => {
    if (!value) return false;

    return GLN_VALIDATION_REGEX.test(value);
};

export const isValidLandfillStatusName = (value: string | null | undefined) => {
    return typeof value === 'string' && value.length > 0;
};

export const isValidLandfillName = (value: string | null | undefined) => {
    return isString(value) && value.length > 0;
};

const email_re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email: string | null | undefined) => {
    return isNotEmpty(email) && email_re.test(email.trim());
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _get(obj: Record<string, any>, field: keyof typeof obj) {
    if (field === DumpLoadFields.date || field === DumpLoadFields.endDate) {
        const date = parseDateFrom(obj[field]);

        return date ? format(date, 'yyyy-MM-dd') : null;
    }

    return obj[field];
}

// @ts-ignore:next-line ToDo: #2315
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function shouldTransportScheduleEvenly(savedDumpload: any, changedDumpload: any) {
    for (let i = 0, length = FieldsAffectsTransportSchedule.length; i < length; i++) {
        const field = FieldsAffectsTransportSchedule[i]!;
        if (_get(savedDumpload, field) !== _get(changedDumpload, field)) return true;
    }

    return false;
}

export function isValidAmount(a: number | null | undefined) {
    return typeof a === 'number' && !isNaN(a) && Math.ceil(a) === a && a >= 0 && a <= 1000000;
}

export function isValidTimeFormat(time: string) {
    return isValid(parse(time, 'HH:mm', new Date()));
}

export function validateWorkTime(
    workTime: Array<{
        dayNumber: number;
        openFrom: string;
        openTo: string;
        isOpen: boolean;
    }>
): null | IErrorCode {
    if (workTime.length !== 7) return ErrorCodes.INVALID_INPUT;
    if (
        workTime.some(
            (d, index) => d.dayNumber !== index + 1 || !isValidTimeFormat(d.openFrom) || !isValidTimeFormat(d.openTo)
        )
    )
        return ErrorCodes.INVALID_INPUT;
    if (workTime.some(d => d.openFrom > d.openTo)) return ErrorCodes.OPEN_FROM_AFTER_OPEN_TO;
    if (workTime.every(d => !d.isOpen)) return ErrorCodes.NO_OPEN_WORK_DAY;

    return null;
}

// length of sequence of only digits without leading 0 or +46
const USER_PHONE_NUMBER_MIN_ACTUAL_NUMBER_SEQ_LENGTH = 5;

// length of a sequence of digits only without a leading +, which is recommended by E.164
const USER_PHONE_NUMBER_MAX_E164_NUMBER_SEQ_LENGTH = 15;

const USER_PHONE_NUMBER_START_VALIDATION_REGEX = /^0|^\+46/;

const USER_PHONE_NUMBER_VALIDATORS: { check: (value: string) => boolean; code: IErrorCode }[] = [
    {
        check: (value: string) => !value.match(USER_PHONE_NUMBER_START_VALIDATION_REGEX),
        code: ErrorCodes.PHONE_NUMBER_HAVE_TO_BE_LOCAL_OR_INTERNATIONAL,
    },
    {
        check: (value: string) =>
            value.replace(USER_PHONE_NUMBER_START_VALIDATION_REGEX, '').replace(/\D/g, '').length <
            USER_PHONE_NUMBER_MIN_ACTUAL_NUMBER_SEQ_LENGTH,
        code: ErrorCodes.PHONE_NUMBER_TOO_SHORT,
    },
    {
        check: (value: string) => value.replace(/\D/g, '').length > USER_PHONE_NUMBER_MAX_E164_NUMBER_SEQ_LENGTH,
        code: ErrorCodes.PHONE_NUMBER_TOO_LONG,
    },
];

export const validateUserPhoneNumber = (input?: string | null): null | IErrorCode => {
    if (!input) return null;

    return USER_PHONE_NUMBER_VALIDATORS.find(({ check }) => check(input))?.code || null;
};

export const validateOrganizationNumber = (value?: string | null): null | IErrorCode => {
    return isValidOrgNr(value) ? null : ErrorCodes.INVALID_ORGANIZATION_NUMBER;
};

export const validateGLN = (value?: string | null): null | IErrorCode => {
    return isValidGLN(value) ? null : ErrorCodes.INVALID_GLN;
};

export const MIN_TOC_VALUE = 0;

export const MAX_TOC_VALUE = 100;

export const validateTOCValue = (value: number | null | undefined): null | IErrorCode => {
    if (!isNumber(value)) return ErrorCodes.TOC_VALUE_REQUIRED;
    if (value < MIN_TOC_VALUE || value > MAX_TOC_VALUE) return ErrorCodes.TOC_VALUE_INCORRECT;

    return null;
};
