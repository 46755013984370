import { useMutation } from '@apollo/client';
import { UserContextRole } from '@common/enums';
import { useCallback } from 'react';

import * as projectQueries from '~/@store/projects/projects.queries';
import * as userQueries from '~/@store/users/users.queries';
import {
    ProjectAddUser,
    ProjectAddUserVariables,
    ProjectUpdateMutation,
    ProjectUpdateMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import { userProjectsStore } from '../userRelations.store';

export type AddUserToProjectFunc = (
    userId: string,
    projectId: string,
    role: UserContextRole,
    ver: number
) => Promise<unknown>;

export const useAddUserToProject = () => {
    const [callAddUser, { loading: isAdding }] = useMutation<ProjectAddUser, ProjectAddUserVariables>(
        projectQueries.ProjectAddUserMutation
    );

    const [callUpdateProject, { loading: isUpdating }] = useMutation<
        ProjectUpdateMutation,
        ProjectUpdateMutationVariables
    >(projectQueries.ProjectUpdateMutation);

    const addUserToProject = useCallback<AddUserToProjectFunc>(
        async (userId, projectId, role, ver) => {
            if (role === UserContextRole.OWNER) {
                return callUpdateProject({
                    variables: { input: { id: projectId, owner: { id: userId }, ver } },
                    refetchQueries: [
                        {
                            query: userQueries.AdminUserProjectsQuery,
                            variables: userProjectsStore.userRelationsQueryVariables,
                        },
                    ],
                }).catch(handleApolloError);
            } else {
                return callAddUser({
                    variables: { userId, projectId, role },
                    refetchQueries: [
                        {
                            query: userQueries.AdminUserProjectsQuery,
                            variables: userProjectsStore.userRelationsQueryVariables,
                        },
                        {
                            query: projectQueries.ProjectQuery,
                            variables: { id: projectId },
                        },
                    ],
                }).catch(handleApolloError);
            }
        },
        [callUpdateProject, callAddUser]
    );

    return [addUserToProject, isAdding || isUpdating] as const;
};
