import React from 'react';

import { NullableFormatter } from '~/@components/Formatters';
import { IProject } from '~/@store/projects';

type Props = {
    project: IProject;
};

export const ProjectCommentCell = ({ project }: Props) => {
    const { comment } = project;

    return (
        <div title={comment || undefined}>
            <NullableFormatter>{comment}</NullableFormatter>
        </div>
    );
};
