export const getGradientBetweenColors = (colorStart: string, colorEnd: string, value: number) => {
    let result = '#';
    for (let i = 0; i < 3; i++) {
        const partStart = parseInt(colorStart.slice(i * 2 + 1, (i + 1) * 2 + 1), 16);
        const partEnd = parseInt(colorEnd.slice(i * 2 + 1, (i + 1) * 2 + 1), 16);
        const part = Math.floor(partStart + (partEnd - partStart) * value);

        result += part.toString(16).padStart(2, '0');
    }

    return result;
};
