import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useMemo, useRef } from 'react';

import { COLORS } from '~/@components/@theme';
import { ILandfillLight } from '~/@store/landfills';
import { useDumpLoadFormikContext } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm/DumpLoadEditFormik';

import { DumpLoadLandfillDestinationRow } from './DumpLoadLandfillDestinationRow';
import { landfillSearchStore } from './landfillSearch.store';

type Props = {
    landfills: ILandfillLight[];
    className?: string;
};

export const DumpLoadLandfillDestinationList: FC<Props> = observer(({ landfills, className }) => {
    const { root, highlight } = useStyles();

    const { values, setValues } = useDumpLoadFormikContext();

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    });

    const handleSelect = (landfill: ILandfillLight) => {
        setValues({
            ...values,
            destinationLandfillId: landfill.id,
        });
    };

    const filteredLandfills = useMemo(
        () =>
            landfillSearchStore.search.length
                ? landfills.filter(
                      l =>
                          values.destinationLandfillId === l.id ||
                          l.name.toLowerCase().includes(landfillSearchStore.search.toLowerCase()) ||
                          l.location.text.toLowerCase().includes(landfillSearchStore.search.toLowerCase())
                  )
                : landfills,
        [landfills, values, landfillSearchStore.search]
    );

    return (
        <div className={cn(root, className)}>
            {filteredLandfills.map((l, i) => (
                <div key={l.id} ref={values.destinationLandfillId === l.id ? ref : undefined}>
                    <DumpLoadLandfillDestinationRow
                        landfill={l}
                        onSelect={handleSelect}
                        selected={values.destinationLandfillId === l.id}
                        className={i % 2 === 0 ? highlight : undefined}
                    />
                </div>
            ))}
        </div>
    );
});

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    highlight: {
        background: COLORS.grayLightest,
    },
}));
