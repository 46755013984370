import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { CheckIcon, MinusIcon } from '~/@components/Icon';

interface Props {
    children: boolean;
}

export const CheckFormatter = (props: Props) => {
    const { checked, unChecked } = useStyles();

    return (
        <div className={props.children ? checked : unChecked}>
            {props.children ? <CheckIcon color="inherit" /> : <MinusIcon color="inherit" />}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    checked: {
        color: theme.palette.success.main,
    },
    unChecked: {
        color: theme.palette.greyMarker,
    },
}));
