import { getProjectDumpLoadSubject } from '@common/abilities/projects';
import { AbilityCan } from '@common/enums';
import React from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { IDetailedProject } from '~/@store/projects';
import { showDumpLoadCreateDialog } from '~/@views/AdminView/ProjectDetailPage/DumpLoadCreateForm';
import { useUserAbilities } from '~/contexts';

type Props = { project: IDetailedProject };

export const DumpLoadCreateButton = ({ project }: Props) => {
    const { user, ability } = useUserAbilities();
    const canCreate = ability.can(AbilityCan.CREATE, getProjectDumpLoadSubject(user, project, null));

    return (
        <ActionButton disabled={!canCreate} onClick={() => showDumpLoadCreateDialog(project)}>
            <LocalizableText code={'addMass'} />
        </ActionButton>
    );
};
