import { AbilityCan, AbilitySubjects, UserRole } from '../../enums';
import { IAbilityFunction } from '../abilities.types';
import { ICaslCondition } from '../utils';
import { ISettingCalculatedSubject } from './settingTypes';

const { CRUD, READ } = AbilityCan;
const { ADMIN, EMPLOYEE } = UserRole;

export function defineAbilitiesForSettings(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<ISettingCalculatedSubject>;
    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) => _allow(can, AbilitySubjects.SETTINGS, shape);

    allow(CRUD, { relationRole: ADMIN });
    allow(READ, { relationRole: EMPLOYEE });
}
