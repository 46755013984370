import { VehicleKind } from '@common/enums';
import { formatString } from '@common/utils';
import { FormikConfig, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { getFuelConsumptionValue, VehicleType } from '~/@store/vehicleTypes';
import { Fuel, VehicleTypeInput } from '~/graphql';
import i18n from '~/i18n';

export type IVehicleTypeFormValues = {
    id: string | null | undefined;
    name: string;
    externalId: string;
    kind: VehicleKind;
    [Fuel.Diesel]: number;
    [Fuel.HVO100]: number;
    [Fuel.Electric]: number;
};

export const useVehicleTypeFormikContext = () => useFormikContext<IVehicleTypeFormValues>();

export function getVehicleTypeFormikConfig(
    vehicleType: VehicleType | null,
    handleSubmitFunc: (input: VehicleTypeInput) => Promise<VehicleType>,
    closeForm: () => void
): FormikConfig<IVehicleTypeFormValues> {
    return {
        initialValues: {
            id: vehicleType?.id,
            name: vehicleType?.name || '',
            kind: vehicleType?.kind || VehicleKind.ON_SITE,
            externalId: vehicleType?.externalId || '',
            Diesel: (vehicleType && getFuelConsumptionValue(vehicleType, Fuel.Diesel)) || 0,
            HVO100: (vehicleType && getFuelConsumptionValue(vehicleType, Fuel.HVO100)) || 0,
            Electric: (vehicleType && getFuelConsumptionValue(vehicleType, Fuel.Electric)) || 0,
        },
        validationSchema: Yup.object().shape<IVehicleTypeFormValues>({
            id: Yup.string().nullable(),
            name: Yup.string().trim().required(formatString(i18n.isRequired, i18n.VehicleType.name)),
            kind: Yup.string()
                .oneOf(Object.values(VehicleKind))
                .required(formatString(i18n.isRequired, i18n.VehicleType.kind)),
            externalId: Yup.string().trim().required(formatString(i18n.isRequired, i18n.VehicleType.externalId)),
            Diesel: Yup.number(),
            HVO100: Yup.number(),
            Electric: Yup.number(),
        }),
        onSubmit: async (values, { resetForm }) => {
            const { name, kind, externalId, Diesel, HVO100, Electric } = values;

            const input: VehicleTypeInput = {
                name,
                kind,
                externalId,
                fuelConsumptions: [
                    { fuel: Fuel.Diesel, value: Diesel },
                    { fuel: Fuel.HVO100, value: HVO100 },
                    { fuel: Fuel.Electric, value: Electric },
                ],
            };

            await handleSubmitFunc(input);
            vehicleType ? resetForm() : closeForm();
        },
    };
}
