import React from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { usersStore } from '~/@store/users';
import { useCompanyDetailContext } from '~/@views/AdminView/CompanyDetailPage/CompanyDetailContext';

import { UsersTable } from '../../UsersPage';

export const CompanyUsersTab = () => {
    const company = useCompanyDetailContext();

    useSyncEffect(() => usersStore.setCompanyTabViewMode(company.id));

    return <UsersTable />;
};
