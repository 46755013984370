import { isEqual, maxBy } from 'lodash';

import { ContaminationLevel, ContaminationType } from '../enums';
import { isFaBySumRule } from './analyzeSumRule';
import { getAmountsForLevel, normalizeSubstanceAmounts } from './toxicAnalysis.common';
import { ISubstanceAmount, IToxicSumRule, LimitValues, SubstanceLimitMap } from './toxicAnalysis.types';

export const solidLevels = [
    ContaminationLevel.mrr,
    ContaminationLevel.km,
    ContaminationLevel.mkm,
    ContaminationLevel.ifa,
    ContaminationLevel.fa,
];

const levelOrder: Partial<Record<ContaminationLevel, number>> = {
    mrr: 0,
    km: 1,
    mkm: 2,
    ifa: 3,
    fa: 4,
};

const getMaxLevel = (levels: ContaminationLevel[]) =>
    maxBy(levels, level => levelOrder[level] || -1) || ContaminationLevel.mrr;

const calcLevel = (amount: number, limits: LimitValues | null | undefined): ContaminationLevel => {
    if (!limits) return ContaminationLevel.mrr;
    if (limits.fa && amount >= limits.fa) return ContaminationLevel.fa;
    if (limits.mkm && amount > limits.mkm) return ContaminationLevel.ifa;
    if (limits.km && amount > limits.km) return ContaminationLevel.mkm;
    if (limits.mrr && amount > limits.mrr) return ContaminationLevel.km;

    return ContaminationLevel.mrr;
};

export function getSolidContaminationLevel(
    substanceAmounts: ISubstanceAmount[],
    limitsMap: SubstanceLimitMap,
    sumRules: IToxicSumRule[]
): ContaminationLevel {
    const amounts = normalizeSubstanceAmounts(substanceAmounts, true, false);

    if (amounts.length === 0) return ContaminationLevel.na;

    const levels = amounts.map(({ amount, substanceId }) => calcLevel(amount, limitsMap[substanceId]));

    const resultLevel = getMaxLevel(levels);

    if (resultLevel !== ContaminationLevel.fa && isFaBySumRule(sumRules, substanceAmounts)) {
        return ContaminationLevel.fa;
    }

    return resultLevel;
}

export function isExactSolidLevel(
    level: ContaminationLevel,
    substanceAmounts: ISubstanceAmount[],
    limitsMap: SubstanceLimitMap
) {
    return isEqual(
        normalizeSubstanceAmounts(substanceAmounts, true, false),
        getAmountsForLevel(ContaminationType.SOLID, limitsMap, level)
    );
}
