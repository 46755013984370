import { ContaminationLevel } from '@common/enums';
import { FormikConfig } from 'formik';

import { IFormValues } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchAnalysisForm/LandfillBatchAnalysisFormik.types';
import { IAnalysis } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/landfillBatchLog.types';
import { BatchAnalysisInput } from '~/graphql';

export const getLandfillBatchAnalysisFormikConfig = (
    batchId: string,
    analysis: IAnalysis | null,
    onSave: (input: BatchAnalysisInput) => Promise<void>
): FormikConfig<IFormValues> => ({
    initialValues: {
        date: analysis?.date || new Date(),
        result: analysis?.analysisResult || ContaminationLevel.mrr,
        file: null,
    },
    onSubmit: async values => {
        const { date, result, file } = values;

        const input: BatchAnalysisInput = {
            batchId,
            date: date.toISOString(),
            analysisResult: result,
        };

        if (file) input.upload = file;

        await onSave(input);
    },
});
