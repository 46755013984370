import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import React, { useState } from 'react';

import { COLORS } from '~/@components/@theme/colors';

import { ITableCellAction } from './interfaces';
import { TableActionButton } from './TableActionButton';

type Props = {
    children: React.ReactNode;
    actions: Array<ITableCellAction>;
};

const BUTTON_FADE_WIDTH = 36;

const getRootStyle = (buttonsCount: number): React.CSSProperties => ({ minWidth: buttonsCount * BUTTON_FADE_WIDTH });

const getActionsContainerStyle = (buttonsCount: number): React.CSSProperties => ({
    backgroundImage: `linear-gradient(270deg, ${COLORS.grayLightest} ${
        buttonsCount * BUTTON_FADE_WIDTH
    }px, rgba(255, 255, 255, 0) ${buttonsCount * BUTTON_FADE_WIDTH + BUTTON_FADE_WIDTH}px)`,
});

export const TableCellActions = ({ children, actions }: Props) => {
    const { root, actionsContainer, hidden } = useStyles();

    const buttonsCount = actions.length;

    const [show, setShow] = useState(false);

    return (
        <div
            style={getRootStyle(buttonsCount)}
            className={root}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}>
            {children}
            {actions.length > 0 && (
                <div
                    style={getActionsContainerStyle(buttonsCount)}
                    className={cn(actionsContainer, { [hidden]: !show })}>
                    {actions.map((action, index) => (
                        <TableActionButton key={action.variant + index} action={action} />
                    ))}
                </div>
            )}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: 20,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    actionsContainer: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        right: 0,
        paddingRight: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    hidden: {
        position: 'absolute',
        overflow: 'hidden',
        clip: 'rect(0 0 0 0)',
        height: 1,
        width: 1,
        margin: -1,
        padding: 0,
        border: 0,
    },
}));
