import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { CreateSumRuleMutation, CreateSumRuleMutationVariables, ToxicSumRuleInput } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../toxicLimits.queries';

type ToxicSumRuleCreateFunc = (input: ToxicSumRuleInput) => Promise<unknown>;

export const useToxicSumRuleCreate = (): [ToxicSumRuleCreateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<CreateSumRuleMutation, CreateSumRuleMutationVariables>(
        queries.CreateSumRuleMutation,
        {
            refetchQueries: [
                {
                    query: queries.ToxicLimitsQuery,
                },
            ],
        }
    );

    const create: ToxicSumRuleCreateFunc = useCallback(
        input => callMutation({ variables: { input } }).catch(handleApolloError),
        [callMutation]
    );

    return [create, mutationResult.loading];
};
