import { useMutation } from '@apollo/client';
import { UserContextRole } from '@common/enums';

import { ProjectAddOrInviteUser, ProjectAddOrInviteUserVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import { ProjectAddOrInviteUserMutation } from '../projects.queries';

type Result = 'ADDED' | 'INVITED';

type ProjectLike = {
    id: string;
    userLinks: Array<{ userId: string; role: UserContextRole }>;
};

export const useProjectAddUser = (project: ProjectLike) => {
    const [callMutation, { loading }] = useMutation<ProjectAddOrInviteUser, ProjectAddOrInviteUserVariables>(
        ProjectAddOrInviteUserMutation
    );

    const addUser = (variables: ProjectAddOrInviteUserVariables): Promise<Result> => {
        return callMutation({ variables })
            .then(result => {
                const resultProject = result.data?.projectAddOrInviteUser;
                if (!resultProject) throw new Error('Empty result ProjectAddOrInviteUser');

                const usersLenBefore = project.userLinks.length;
                const usersLenAfter = resultProject.userLinks.length;

                return usersLenAfter === usersLenBefore ? 'INVITED' : 'ADDED';
            })
            .catch(handleApolloError);
    };

    return [addUser, loading] as const;
};
