import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/dumpLoads/dumpLoads.queries';
import { DumpLoadRemoveAnalysisMutation, DumpLoadRemoveAnalysisMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

export type AnalysisFileDeleteFunc = (projectId: string, dumpLoadId: string, fileId: string) => Promise<unknown>;

export const useAnalysisFileDeleteMutation = (): [AnalysisFileDeleteFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        DumpLoadRemoveAnalysisMutation,
        DumpLoadRemoveAnalysisMutationVariables
    >(queries.DumpLoadRemoveAnalysisMutation);

    const deleteAnalysisFile = useCallback<AnalysisFileDeleteFunc>(
        (projectId, dumpLoadId, fileId) => {
            return callMutation({
                variables: { projectId, dumpLoadId, fileId },
            }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [deleteAnalysisFile, mutationResult.loading];
};
