import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { Button } from '~/@components/Button';
import { CloseIcon, MenuIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { ArrowDownIcon } from '~/@sochi-components/Icons';

import { COLOR } from '../@theme';
import { PageNavMenuProps } from './PageNavMenu.types';

export const PageNavMobileMenu = ({
    onBack,
    onBackLabel = <LocalizableText code={'back'} />,
    title: titleText,
    items,
}: PageNavMenuProps) => {
    const [isOpen, setOpen] = useState(false);

    const { menuButton, buttonContainer, titleContainer, menu, title, button, menuItem, selected } = useStyles({
        open: isOpen,
    });

    const location = useLocation();

    const links = useMemo(() => {
        return items?.map(item => (
            <Link
                key={item.url}
                to={item.url}
                className={cn(menuItem, { [selected]: location && location.pathname.includes(item.url) })}>
                {item.title}
            </Link>
        ));
    }, [items, location, selected, menuItem]);

    return (
        <div>
            {!isOpen && (
                <div className={cn(menuButton, buttonContainer)}>
                    <IconButton onClick={() => setOpen(true)}>
                        <MenuIcon />
                    </IconButton>
                </div>
            )}
            <div className={menu}>
                <div className={titleContainer}>
                    <div className={title}>{titleText}</div>
                    <div className={buttonContainer}>
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                {links}
                {onBack && (
                    <div className={button}>
                        <Button
                            color="info"
                            variant="outlined"
                            startIcon={<ArrowDownIcon fontSize="inherit" />}
                            onClick={onBack}>
                            {onBackLabel}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    menuButton: {
        position: 'absolute',
        left: 0,
        top: '8px',
        zIndex: 100,
    },
    buttonContainer: {
        '& .MuiIconButton-root': {
            color: theme.palette.primary.contrastText,
        },
    },
    menu: ({ open }: { open: boolean }) => ({
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        width: '180px',
        background: theme.palette.secondary.main,
        zIndex: 100,
        display: 'flex',
        flexDirection: 'column',
        transform: 'translateX(-100%)',
        transition: 'transform 0.1s',
        ...(open && {
            transform: 'translateX(0%)',
        }),
    }),
    titleContainer: {
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRight: `1px solid ${theme.palette.primary.contrastText}`,
        borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
        alignItems: 'center',
    },
    title: {
        fontSize: '14px',
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
        marginLeft: '12px',
        display: 'block',
        maxWidth: '120px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    menuItem: {
        fontSize: '14px',
        padding: '14px 10px',
        borderLeft: '10px solid transparent',
        color: theme.palette.primary.contrastText,
        textDecoration: 'none',
        '&:hover, &:focus': {
            color: theme.palette.primary.contrastText,
            textDecoration: 'none',
        },
    },
    selected: {
        borderColor: COLOR.orange,
        fontWeight: 'bold',
        textDecoration: 'none',
    },
    button: {
        margin: '10px 20px',
        '& .MuiButton-outlined': {
            paddingLeft: '14px',
            paddingRight: '22px',
        },
        '& .MuiSvgIcon-root': {
            transform: 'rotate(90deg)',
            fontSize: '14px',
        },
    },
}));
