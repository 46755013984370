import { computed } from 'mobx';

import { BlacklistQueryVariables } from '~/graphql';

import { FilterStore } from '../common';

export type BlacklistStoreFilter = {
    email?: string | null;
};

export enum BlacklistItemField {
    email = 'email',
}

class BlacklistStore {
    filter = new FilterStore<BlacklistStoreFilter>();

    @computed
    get blacklistQueryVariables(): BlacklistQueryVariables {
        const { email } = this.filter.values;

        return { search: email || '' };
    }
}

export const blacklistStore = new BlacklistStore();
