import { makeStyles } from '@material-ui/core';
import React from 'react';

import { SimpleDialog } from '~/@components/Dialog';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { IDetailedUser, useDetailedUser } from '~/@store/users';

import { UserComments } from './UserComments';

type Props = {
    userId: string;
    onClose: (v: boolean) => void;
};

export const UserCommentsDialog = ({ onClose, userId }: Props) => {
    const query = useDetailedUser(userId);
    const { comments } = useStyles();

    return (
        <SimpleDialog onClose={() => onClose(true)}>
            <QueryStateHandler query={query} dataGetter={query => query.data?.user || undefined}>
                {(user: IDetailedUser) => {
                    return <UserComments className={comments} comments={user.comments || []} userId={user.id} />;
                }}
            </QueryStateHandler>
        </SimpleDialog>
    );
};

const useStyles = makeStyles(() => ({
    comments: {
        width: 420,
    },
}));
