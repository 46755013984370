import { FilterRange } from '@common/types';
import { parseDateFrom } from '@common/utils';
import { isNullOrUndefined } from '@common/validations/types.validation';
import { action, computed, observable } from 'mobx';

import { FilterStore } from '~/@store/common';
import { IDeliveryEvent } from '~/@user-store/landfillDeliveryEvents/useDeliveryEvents';
import { filterAndSortCollection, getDateRangeFilterFn, getStringSearchFilterFn } from '~/utils/filters';

export type LandfillDeliveryEventsStoreFilter = {
    date?: FilterRange<Date> | null;
    licensePlate?: string | null;
};

export enum LandfillDeliveryEventsFilterField {
    date = 'date',
    licensePlate = 'licensePlate',
}

class LandfillDeliveryEventsStore {
    filter = new FilterStore<LandfillDeliveryEventsStoreFilter>();

    @observable
    list: IDeliveryEvent[] = [];

    @action
    setList(newList: IDeliveryEvent[]) {
        this.list = newList;
    }

    @action
    clear() {
        this.filter.clearValues();
    }

    @computed
    get filteredList(): IDeliveryEvent[] {
        const { date, licensePlate } = this.filter.values;

        const filters: ((item: IDeliveryEvent) => boolean)[] = [];

        if (date) filters.push(getDateRangeFilterFn(date, r => parseDateFrom(r.date)));
        if (licensePlate) filters.push(getStringSearchFilterFn(licensePlate, r => r.licensePlate));

        return filterAndSortCollection(this.list, filters, { field: 'id', direction: -1 });
    }

    @computed
    get isAnyFilterSet(): boolean {
        return Object.values(this.filter.values).some(f => !isNullOrUndefined(f));
    }
}

export const landfillDeliveryEventsStore = new LandfillDeliveryEventsStore();
