import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { DumpTypeCreateMutation, DumpTypeCreateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../dumpType.queries';
import { CreateDumpTypeFunc } from '../types';

export const useDumpTypeCreateMutation = (): [CreateDumpTypeFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<DumpTypeCreateMutation, DumpTypeCreateMutationVariables>(
        queries.DumpTypeCreateMutation
    );

    const createDumpType = useCallback<CreateDumpTypeFunc>(
        input => {
            return callMutation({
                variables: { input },
                refetchQueries: [
                    {
                        query: queries.DumpTypesListQuery,
                    },
                ],
                awaitRefetchQueries: true,
            }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [createDumpType, mutationResult.loading];
};
