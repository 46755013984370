import { IAbility, IAbilityComplexSubject, IAbilityUser } from '@common/abilities/abilities.types';
import { createContext, useContext } from 'react';

import { MeQuery_me } from '~/graphql';

export type IProfileUser = MeQuery_me;

export type IUser = IAbilityUser & Omit<IProfileUser, 'role'>;

export const emptyUser: IUser = {} as IUser;

export const emptyAbility: IAbility = {
    can: (_, __) => false,
    cannot: (_, __) => true,
    subject: (_, __) => ({} as IAbilityComplexSubject),
};

export type IAbilityContext = IAbility;

export type IAbilityUserContext = {
    ability: IAbility;
    user: IUser;
    setUser: (v: IProfileUser) => void;
};

export const emptyAbilityUserContext = {
    ability: emptyAbility,
    user: emptyUser,
    setUser: () => {},
};

export const AbilityUserContext = createContext<IAbilityUserContext>(emptyAbilityUserContext);

export const useUserAbilities = () => useContext<IAbilityUserContext>(AbilityUserContext);
