import { sum } from 'lodash';

import { makeRecord } from '../utils';
import { isNumber } from '../validations/types.validation';
import { ISubstanceAmount, IToxicSumRule } from './toxicAnalysis.types';

export const isFaBySumRule = (sumRules: IToxicSumRule[], amounts: ISubstanceAmount[]) => {
    const amountsMap = makeRecord(
        amounts,
        a => a.substanceId,
        a => a.amount
    );

    return sumRules.some(rule => {
        const overThresholdAmounts: Array<number> = rule.substances.map(sub => {
            const amount = amountsMap[sub.substanceId];

            return isNumber(amount) && amount >= sub.threshold ? amount : 0;
        });

        return sum(overThresholdAmounts) >= rule.faSumLevel;
    });
};
