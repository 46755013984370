import React, { ReactNode, useState } from 'react';

import { useSearchQuery } from '~/@components/@hooks';
import { makeStyles } from '~/@components/@theme';
import { PageLight } from '~/@components/PageLight';

import { StyledTab, StyledTabs } from './StyledTabs';

export type ICustomTabConfig = {
    label: ReactNode;
    render?: () => ReactNode;
    onClick?: () => void;
    subTitle?: ReactNode;
    leftActionButton?: () => ReactNode;
    rightActionButton?: () => ReactNode;
    hidden?: boolean;
    fullWidth?: boolean;
    name?: string;
};

interface Props {
    tabs: Array<ICustomTabConfig>;
    title: ReactNode;
    leftActions?: ReactNode;
    rightActions?: ReactNode;
    isDisabled?: boolean;
    headerIcon?: ReactNode;
    contentClassName?: string;
    hideSingleTabLabel?: boolean;
}

export const CustomTabs = ({
    tabs,
    leftActions,
    rightActions,
    title,
    isDisabled,
    headerIcon,
    contentClassName,
    hideSingleTabLabel,
}: Props) => {
    const query = useSearchQuery();
    const initialTab = query.tab ? tabs.findIndex(t => t.name === query.tab) : 0;

    const [currentTabIndex, setCurrentTabIndex] = useState(initialTab || 0);

    const { tabItem } = useStyles();

    const tabHandler = (_event: unknown, value: number) => {
        const tab = tabs[value];

        if (tab?.render) setCurrentTabIndex(value);

        if (tab?.onClick) tab.onClick();
    };

    const visibleTabs = tabs.filter(t => !t.hidden);
    const currentTab = visibleTabs[currentTabIndex];

    const hideTabLabels = hideSingleTabLabel && visibleTabs.length === 1;

    return (
        <PageLight>
            <PageLight.Header
                icon={headerIcon}
                title={title}
                leftActions={currentTab?.leftActionButton?.() || leftActions}
                rightActions={currentTab?.rightActionButton?.() || rightActions}>
                <div>
                    {!hideTabLabels && (
                        <StyledTabs value={currentTabIndex} onChange={tabHandler}>
                            {visibleTabs.map((tab, index) => {
                                return (
                                    <StyledTab
                                        id={`tab-${index}`}
                                        className={tabItem}
                                        key={index}
                                        value={index}
                                        label={tab.label}
                                        aria-controls={`tabpanel-${index}`}
                                        disabled={isDisabled || index === currentTabIndex}
                                    />
                                );
                            })}
                        </StyledTabs>
                    )}
                </div>
            </PageLight.Header>
            <PageLight.Content
                subTitle={currentTab?.subTitle}
                className={contentClassName}
                fullWidth={!!currentTab?.fullWidth}>
                {currentTab?.render?.()}
            </PageLight.Content>
        </PageLight>
    );
};

const useStyles = makeStyles(theme => ({
    tabItem: {
        padding: theme.spacing(1, 2),
    },
}));
