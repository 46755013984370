import './ProjectOffers.scss';

import { ContaminationType } from '@common/enums';
import { IconButton } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { ButtonGroupSwitch } from '~/@components/ButtonGroupSwitch';
import { DateFormatter, DumpLoadStatusFormatter, NullableFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ArrowRightIcon } from '~/@sochi-components/Icons';
import { PageNavMenu } from '~/@sochi-components/PageNavMenu';
import { ISochiTableColumn, SochiTable } from '~/@sochi-components/SochiTable';
import { ProjectsOffersListQuery } from '~/@store/projects/projects.queries';
import { getContaminationLevelLabel } from '~/@store/toxicLimits';
import { TopBar } from '~/@views/UserView/UserViewLayout/TopBar';
import type { ProjectsOffersListQuery as OffersQuery, ProjectsOffersListQueryVariables } from '~/graphql';
import { showCustomDialog } from '~/services/dialog';
import type { WithGraphqlProps } from '~/services/hoc';
import { handledGraphql } from '~/services/hoc';
import { handleProjectGraphqlPermissionError } from '~/utils/project';

import { ProjectOfferModal } from '../ProjectOfferModal';
import { ProjectOffersTopBar } from '../ProjectOffersTopBar';
import { IOffer, LoadStatus } from '../types';

enum FilterValues {
    SENT = 'SENT',
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
    ALL = 'ALL',
}

const filterOptions = [
    {
        value: FilterValues.SENT,
        label: <LocalizableText code={'sent'} />,
    },
    {
        value: FilterValues.ACCEPTED,
        label: <LocalizableText code={'accepted'} />,
    },
    {
        value: FilterValues.DECLINED,
        label: <LocalizableText code={'declined'} />,
    },
    {
        value: FilterValues.ALL,
        label: <LocalizableText code={'all'} />,
    },
];

const filterOffer = (selectedFilter: FilterValues) => (offer: IOffer) => {
    if (selectedFilter === FilterValues.ALL) return true;

    const { status } = offer;
    switch (status) {
        case LoadStatus.CONFIRMED:
            return selectedFilter === FilterValues.ACCEPTED;
        case LoadStatus.NOT_ORDERED:
            return selectedFilter === FilterValues.DECLINED;
        case LoadStatus.REQUESTED:
            return selectedFilter === FilterValues.SENT;
        default:
            return false;
    }
};

type ProjectOffersProps = RouteComponentProps<{ projectId: string | undefined }>;

type InjectedProps = WithGraphqlProps<ProjectOffersProps, OffersQuery, ProjectsOffersListQueryVariables>;

const ProjectOffers = ({ data, history, match }: InjectedProps) => {
    const { className, element } = useFunctionalBem(ProjectOffers);

    const [selectedFilter, setSelectedFilter] = useState(FilterValues.SENT);

    const onBackHandler = useCallback(() => history.goBack(), [history]);

    const showInfo = useCallback(
        async (dumpLoad: IOffer) => {
            const reload = await showCustomDialog({
                render: closeDialog => (
                    <ProjectOfferModal
                        dumpLoad={dumpLoad}
                        project={dumpLoad.project!}
                        onClose={closeDialog}
                        history={history}
                    />
                ),
            });

            if (reload) await data.refetch();
        },
        [data, history]
    );

    const tableColumns = useMemo<ISochiTableColumn<IOffer>[]>(
        () => [
            { render: data => data.project?.name, title: <LocalizableText code={'ProjectForm.projectLabel'} /> },
            {
                title: <LocalizableText code={'ProjectOffers.declarationNumber'} />,
                render: data => <NullableFormatter>{data.declaration?.serialNumber}</NullableFormatter>,
                alignLeft: true,
            },
            {
                title: <LocalizableText code={'masses'} />,
                render: data => <NullableFormatter>{data.dumpType?.name}</NullableFormatter>,
                alignLeft: true,
            },
            {
                title: <LocalizableText code={'classification'} />,
                render: data => (
                    <NullableFormatter>
                        {getContaminationLevelLabel(data.solidTestResult, ContaminationType.SOLID)}
                    </NullableFormatter>
                ),
                alignLeft: true,
            },
            {
                title: <LocalizableText code={'status'} />,
                render: data => <DumpLoadStatusFormatter>{data.status}</DumpLoadStatusFormatter>,
                alignLeft: true,
            },
            {
                title: <LocalizableText code={'DumploadSummarySection.startDate'} />,
                render: data => <DateFormatter>{data.date}</DateFormatter>,
            },
            {
                title: <LocalizableText code={'view'} />,
                render: data => (
                    <IconButton className={element('view-offer-info')} onClick={() => showInfo(data)}>
                        <ArrowRightIcon color="primary" fontSize="inherit" />
                    </IconButton>
                ),
            },
        ],
        [element, showInfo]
    );

    const offers = useMemo<IOffer[]>(
        () => data.projectsOffers?.filter(filterOffer(selectedFilter)) || [],
        [data, selectedFilter]
    );

    return (
        <>
            <TopBar />
            <div className={className}>
                {match.params.projectId ? (
                    <ProjectOffersTopBar projectId={match.params.projectId} onBack={onBackHandler} />
                ) : (
                    <PageNavMenu onBack={onBackHandler} />
                )}
                <div className={element('wrapper')}>
                    <div className={element('filter')}>
                        <ButtonGroupSwitch
                            color="secondary"
                            options={filterOptions}
                            value={selectedFilter}
                            onChange={setSelectedFilter}
                        />
                    </div>
                    <SochiTable columns={tableColumns} items={offers} keyGetter={({ id }) => id} stickyFirstColumn />
                </div>
            </div>
        </>
    );
};

const withData = handledGraphql<OffersQuery, ProjectOffersProps, ProjectsOffersListQueryVariables, InjectedProps>(
    ProjectsOffersListQuery,
    {
        options: props => {
            return {
                fetchPolicy: 'network-only',
                variables: {
                    projectId: props.match.params.projectId || '',
                },
            };
        },
    },
    handleProjectGraphqlPermissionError
);

export default withData(ProjectOffers);
