import './GlobalLoader.scss';

import { observer } from 'mobx-react';
import React from 'react';

import { getBem } from '~/@sochi-components/@bem';
import { SochiLoader } from '~/@sochi-components/SochiLoader';

import loaderStore from '../../../../stores/loaderStore';

@observer
export class GlobalLoader extends React.Component<{}> {
    bem = getBem(this);

    render() {
        const { block, element } = this.bem;

        return (
            <div className={block({ showing: loaderStore.isLoading })}>
                <div className={element('loader-container')}>
                    <SochiLoader show />
                </div>
            </div>
        );
    }
}
