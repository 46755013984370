import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as projectQueries from '~/@store/projects/projects.queries';
import * as userQueries from '~/@store/users/users.queries';
import {
    ProjectRemoveUser,
    ProjectRemoveUserVariables,
    ProjectUpdateMutation,
    ProjectUpdateMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import { userProjectsStore } from '../userRelations.store';

export type RemoveUserFromProjectFunc = (
    userId: string,
    projectId: string,
    ver: number,
    isManager: boolean
) => Promise<unknown>;

export const useRemoveUserFromProject = () => {
    const [callRemove, { loading: isRemoving }] = useMutation<ProjectRemoveUser, ProjectRemoveUserVariables>(
        projectQueries.ProjectRemoveUserMutation
    );

    const [callUpdateProject, { loading: isUpdating }] = useMutation<
        ProjectUpdateMutation,
        ProjectUpdateMutationVariables
    >(projectQueries.ProjectUpdateMutation);

    const removeUserFromProject = useCallback<RemoveUserFromProjectFunc>(
        async (userId, projectId, ver, isManager) => {
            if (isManager) {
                return callUpdateProject({
                    variables: {
                        input: { id: projectId, owner: null, ver },
                    },
                    refetchQueries: [
                        {
                            query: userQueries.AdminUserProjectsQuery,
                            variables: userProjectsStore.userRelationsQueryVariables,
                        },
                    ],
                }).catch(handleApolloError);
            } else {
                return callRemove({
                    variables: { projectId, userId },
                    refetchQueries: [
                        {
                            query: userQueries.AdminUserProjectsQuery,
                            variables: userProjectsStore.userRelationsQueryVariables,
                        },
                    ],
                }).catch(handleApolloError);
            }
        },
        [callRemove, callUpdateProject]
    );

    return [removeUserFromProject, isRemoving || isUpdating] as const;
};
