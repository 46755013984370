import { AbilityCan, AbilitySubjects } from '@common/enums';

import { useUserAbilities } from '~/contexts';

type LandfillStatusPermissions = {
    canRead: boolean;
    canCreate: boolean;
    canUpdate: boolean;
    canDelete: boolean;
};

export const useLandfillStatusPermissions = (): LandfillStatusPermissions => {
    const { ability } = useUserAbilities();

    return {
        canRead: ability.can(AbilityCan.READ, AbilitySubjects.LANDFILL_STATUS),
        canCreate: ability.can(AbilityCan.CREATE, AbilitySubjects.LANDFILL_STATUS),
        canUpdate: ability.can(AbilityCan.UPDATE, AbilitySubjects.LANDFILL_STATUS),
        canDelete: ability.can(AbilityCan.DELETE, AbilitySubjects.LANDFILL_STATUS),
    };
};
