import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';

import { PaginationStore } from '~/@store/common';
import i18n from '~/i18n';

import { COLORS } from '../@theme/colors';
import { ClickableText } from '../ClickableText';
import { Text } from '../Text';
import { PAGINATION_SELECT_MENU_PROPS } from './constants';
import { PaginationSelectIcon } from './PaginationSelectIcon';

type Props = {
    className?: string;
    store: PaginationStore;
    disabled?: boolean;
    canChangeRowsPerPage?: boolean;
};

export const StorePagination = observer(
    ({ store, className, disabled = false, canChangeRowsPerPage = true }: Props) => {
        const handleChangePage = useCallback(
            (_, pageNumber: number) => {
                const nextPage = pageNumber + 1;

                if (nextPage >= 1 && nextPage <= store.pageCount) store.setPage(nextPage);
            },
            [store]
        );

        const handleChangeRowsPerPage = useCallback<React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>>(
            event => {
                const nextRowsPerPage = parseInt(event.target.value, 10);
                store.setRowsPerPage(nextRowsPerPage);
            },
            [store]
        );

        const {
            paginationButton,
            paginationNum,
            paginationNumButton,
            paginationText,
            currentPaginationPage,
            disabledText,
        } = useStyles();

        const { rowsPerPage, page: currentPage, numbersOfPages, totalCount, pageCount } = store;

        useEffect(() => {
            if (currentPage > pageCount && pageCount !== 0) store.onClear();
        }, [store, currentPage, pageCount]);

        if (!currentPage || !totalCount) return null;

        return (
            <>
                <TablePagination
                    component="div"
                    className={className}
                    nextIconButtonText={i18n.Table.nextPage}
                    backIconButtonText={i18n.Table.prevPage}
                    nextIconButtonProps={{ className: paginationButton, disabled }}
                    backIconButtonProps={{ className: paginationButton, disabled }}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${i18n.Table.of} ${count}`}
                    // TablePagination indexes pages from 0
                    page={currentPage - 1}
                    count={totalCount}
                    labelRowsPerPage={i18n.Table.rowsPerPage}
                    rowsPerPageOptions={canChangeRowsPerPage ? store.ROWS_PER_PAGE_OPTIONS : []}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    SelectProps={{
                        MenuProps: PAGINATION_SELECT_MENU_PROPS,
                        IconComponent: PaginationSelectIcon,
                    }}
                />
                <div className={paginationNum}>
                    {numbersOfPages.map((n, i) => {
                        if (n === currentPage) {
                            return (
                                <Text key={i} className={currentPaginationPage}>
                                    {n}
                                </Text>
                            );
                        } else if (n) {
                            return (
                                <ClickableText key={i} onClick={() => store.setPage(n)} disabled={disabled}>
                                    <div className={cn(paginationNumButton, { [disabledText]: disabled })}>{n}</div>
                                </ClickableText>
                            );
                        } else {
                            return (
                                <Text key={i} className={paginationText}>
                                    ...
                                </Text>
                            );
                        }
                    })}
                </div>
            </>
        );
    }
);

const useStyles = makeStyles(() => ({
    paginationButton: {
        width: 32,
        height: 32,
        marginLeft: 16,
        borderRadius: 4,
        backgroundColor: COLORS.grayLight,
    },

    paginationNum: {
        paddingTop: 10,
        paddingLeft: 16,
        display: 'flex',
        height: 32,
    },

    paginationNumButton: {
        minWidth: 'auto',
        padding: 5,
        borderRadius: '5%',
        lineHeight: 1.5,
    },

    currentPaginationPage: {
        fontWeight: 'bold',
        minWidth: 'auto',
        padding: 4,
        borderRadius: '5%',
        lineHeight: 1.5,
    },

    paginationText: {
        padding: '2% 5px 5px 5px',
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: '5%',
        lineHeight: 1.5,
    },
    disabledText: {
        color: COLORS.grayDark,
    },
}));
