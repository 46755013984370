import { Granularity } from '@common/enums';
import { getStartOfGranularity } from '@common/functions';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { FC, useMemo, useState } from 'react';

import { ButtonGroupSwitch } from '~/@components/ButtonGroupSwitch';
import { LocalizableText } from '~/@components/LocalizableText';
import { MultiSelect } from '~/@sochi-components/MultiSelect';
import { SochiDatePicker } from '~/@sochi-components/SochiDatePicker';
import i18n from '~/i18n';

import { dashboardFilterStore } from '../dashboardFilter.store';

export const ProjectDashboardFilters: FC = observer(() => {
    const options = useMemo(
        () => [
            { label: <LocalizableText code={'TimeFilters.DAY'} />, value: Granularity.day },
            { label: <LocalizableText code={'TimeFilters.WEEK'} />, value: Granularity.isoWeek },
            { label: <LocalizableText code={'TimeFilters.MONTH'} />, value: Granularity.month },
        ],
        []
    );

    const [selectedGranularity, setGranularity] = useState<Granularity | undefined>(undefined);

    const handleChangeGranularity = (gran: Granularity | undefined) => {
        if (!gran) return;

        const now = new Date();
        dashboardFilterStore.setDates(getStartOfGranularity(now, gran), now);
        setGranularity(gran);
    };

    const handleChangeStartDate = (start: Date | null | undefined) => {
        if (!start) return;
        dashboardFilterStore.setDates(start);
        setGranularity(undefined);
    };

    const handleChangeEndDate = (end: Date | null | undefined) => {
        if (!end) return;
        dashboardFilterStore.setDates(undefined, end);
        setGranularity(undefined);
    };

    return (
        <Grid container justify="space-between" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <ButtonGroupSwitch
                    color="secondary"
                    options={options}
                    value={selectedGranularity}
                    onChange={handleChangeGranularity}
                    size="medium"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
                <SochiDatePicker value={dashboardFilterStore.startDate} onChange={handleChangeStartDate} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
                <SochiDatePicker value={dashboardFilterStore.endDate} onChange={handleChangeEndDate} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
                <MultiSelect
                    items={dashboardFilterStore.dumpLoadNumbers}
                    selected={dashboardFilterStore.selectedDumpLoadNumbers}
                    onSelect={values => dashboardFilterStore.setSelectedDumpLoadNumbers(values)}
                    keyGetter={i => i}
                    renderItem={i => i}
                    placeholder={i18n.ProjectDashboard.serialNumber}
                    disabled={dashboardFilterStore.filteredDeliveryItemsByDates.length === 0}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
                <MultiSelect
                    items={dashboardFilterStore.licensePlates}
                    selected={dashboardFilterStore.selectedLicensePlates}
                    onSelect={values => dashboardFilterStore.setSelectedLicensePlates(values)}
                    keyGetter={i => i}
                    renderItem={i => i}
                    placeholder={i18n.ProjectDashboard.licensePlate}
                    disabled={dashboardFilterStore.filteredDeliveryItemsByDates.length === 0}
                />
            </Grid>
        </Grid>
    );
});
