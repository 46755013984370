import { FormikConfig } from 'formik';
import * as Yup from 'yup';

import i18n from '~/i18n';

export type IAccountForm = {
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
};

export function getChangePasswordFormikConfig(
    changePassword: (input: IAccountForm) => unknown,
    isCurrentUserPassword: boolean
): FormikConfig<IAccountForm> {
    return {
        initialValues: {
            oldPassword: '',
            newPassword: '',
            repeatPassword: '',
        },
        validationSchema: Yup.object().shape({
            oldPassword: Yup.string().test(
                'Old password required',
                i18n.AccountPage.validation.fieldMustFilled,
                function (this, value) {
                    if (isCurrentUserPassword) return !!value;

                    return true;
                }
            ),
            newPassword: Yup.string().required(i18n.AccountPage.validation.fieldMustFilled),
            repeatPassword: Yup.string()
                .required(i18n.AccountPage.validation.fieldMustFilled)
                .test('passwords-match', i18n.AccountPage.validation.passwordsMustMatch, function (value) {
                    return this.parent.newPassword === value;
                }),
        }),
        onSubmit: async (values, { resetForm }) => {
            await changePassword(values);

            resetForm();
        },
    };
}
