import { useMutation } from '@apollo/client';

import * as landfillQueries from '~/@store/landfills/landfills.queries';
import {
    LandfillDeliveryEventAttachToLine,
    LandfillDeliveryEventAttachToLineVariables,
    LandfillDeliveryEventsVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import {
    LandfillDeliveryEventAttachToLineMutation,
    LandfillDeliveryEventsQuery,
} from './landfillDeliveryEvents.queries';

export const useAttachDeliveryEvent = (landfillId: string) => {
    const [callMutation, data] = useMutation<
        LandfillDeliveryEventAttachToLine,
        LandfillDeliveryEventAttachToLineVariables
    >(LandfillDeliveryEventAttachToLineMutation);

    const refetchVariables: LandfillDeliveryEventsVariables = { landfillId };

    const attach = (eventId: string, lineId: string) =>
        callMutation({
            variables: { id: eventId, deliveryLineId: lineId },
            refetchQueries: [
                { query: LandfillDeliveryEventsQuery, variables: refetchVariables },
                { query: landfillQueries.LandfillQuery, variables: { id: landfillId } },
            ],
            awaitRefetchQueries: true,
        }).catch(handleApolloError);

    return [attach, data.loading] as const;
};
