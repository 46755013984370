import React, { FC, useCallback } from 'react';

import { PageWrapper } from '~/@sochi-components/PageWrapper';
import type { IDeviation } from '~/@user-store/deviations';
import * as queries from '~/@user-store/deviations/deviation.queries';
import { DeviationTable } from '~/@views/UserView/common/DeviationTable';
import { useUserAbilities } from '~/contexts';
import { showCustomDialog } from '~/services/dialog';
import type { WithGraphqlProps } from '~/services/hoc';
import { handledGraphql } from '~/services/hoc';
import { canCreateProjectDeviation } from '~/utils/auth';
import { handleProjectGraphqlPermissionError } from '~/utils/project';

import type {
    DeviationsByProjectQuery as IDeviationsByProjectQuery,
    DeviationsByProjectQueryVariables,
} from '../../../../../graphql';
import type { ProjectCommonDataProps } from '../../ProjectPage/ProjectPage';
import { ProjectDeviationForm } from '../ProjectDeviationForm';

type ExternalProps = ProjectCommonDataProps;

type InjectedProps = WithGraphqlProps<ExternalProps, IDeviationsByProjectQuery, DeviationsByProjectQueryVariables>;

const ProjectDeviations: FC<InjectedProps> = ({ project, data }) => {
    const abilityContext = useUserAbilities();

    const handleOpenDeviation = useCallback(
        (deviation: IDeviation) => {
            showCustomDialog({
                render: closeDialog => (
                    <ProjectDeviationForm project={project} deviation={deviation} closeDialog={closeDialog} />
                ),
            }).then(() => data.refetch());
        },
        [project, data]
    );

    const handleAddDeviation = useCallback(() => {
        showCustomDialog({
            render: closeDialog => <ProjectDeviationForm project={project} closeDialog={closeDialog} />,
        }).then(() => data.refetch());
    }, [project, data]);

    return (
        <PageWrapper>
            <DeviationTable
                deviations={data.deviationsByProject || []}
                createDeviation={handleAddDeviation}
                openDeviation={handleOpenDeviation}
                canCreateDeviation={canCreateProjectDeviation(abilityContext, project)}
            />
        </PageWrapper>
    );
};

const withData = handledGraphql<
    IDeviationsByProjectQuery,
    ExternalProps,
    DeviationsByProjectQueryVariables,
    InjectedProps
>(
    queries.DeviationsByProjectQuery,
    {
        options: (props: ExternalProps) => {
            return {
                fetchPolicy: 'network-only',
                variables: {
                    id: props.project.id,
                },
            };
        },
    },
    handleProjectGraphqlPermissionError
);

export default withData(ProjectDeviations);
