import { IconButton, ListItem, ListItemSecondaryAction, makeStyles } from '@material-ui/core';
import { add, isAfter, isBefore, sub } from 'date-fns';
import React, { useCallback, useState } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { COLOR } from '~/@sochi-components/@theme';
import { CloseCrossIcon } from '~/@sochi-components/Icons';
import { SochiDatePicker } from '~/@sochi-components/SochiDatePicker';
import i18n from '~/i18n';
import { isValidDate, parseDateFrom } from '~/utils/date';

import { FilterRange } from '../../../../../common/types';

type Props = {
    valueGetter: () => FilterRange<Date> | null | undefined;
    setValue: (v: FilterRange<Date> | null) => void;
    maxIntervalInMonths?: number;
};

export const SochiDateFilter = ({ valueGetter, setValue, maxIntervalInMonths }: Props) => {
    const { picker, closeButton } = useStyles();

    const [from, to] = useObservable(valueGetter) || [null, null];

    const [inputFrom, setInputFrom] = useState(from);
    const [inputTo, setInputTo] = useState(to);

    const handleFrom = useCallback(
        (v: Date | null) => {
            setInputFrom(v);
            const fromValue = isValidDate(v) ? v : null;

            if (!fromValue && maxIntervalInMonths) return;

            let toValue = to || null;
            const bound = add(parseDateFrom(fromValue)!, { months: maxIntervalInMonths });

            if (fromValue && toValue && maxIntervalInMonths && isAfter(toValue, bound)) {
                toValue = bound;
                setInputTo(bound);
            }

            setValue([fromValue, toValue]);
        },
        [setValue, to, maxIntervalInMonths]
    );

    const handleTo = useCallback(
        (v: Date | null) => {
            setInputTo(v);
            const toValue = isValidDate(v) ? v : null;

            if (!toValue && maxIntervalInMonths) return;

            let fromValue = from || null;
            const bound = sub(parseDateFrom(toValue)!, { months: maxIntervalInMonths });

            if (toValue && fromValue && maxIntervalInMonths && isBefore(fromValue, bound)) {
                fromValue = bound;
                setInputFrom(bound);
            }

            setValue([fromValue, toValue]);
        },
        [setValue, from, maxIntervalInMonths]
    );

    return (
        <>
            <ListItem divider disableGutters>
                <SochiDatePicker
                    className={picker}
                    autoOk
                    placeholder={i18n.from}
                    maxDate={to}
                    value={inputFrom || null}
                    onChange={handleFrom}
                    onBlur={() => setInputFrom(from)}
                    invalidDateMessage={null}
                />
                <ListItemSecondaryAction>
                    <IconButton
                        className={closeButton}
                        onClick={() => handleFrom(null)}
                        size="small"
                        edge="end"
                        disabled={!from}>
                        <CloseCrossIcon fontSize="small" color="inherit" />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
                <SochiDatePicker
                    className={picker}
                    autoOk
                    placeholder={i18n.to}
                    minDate={from}
                    value={inputTo || null}
                    onChange={handleTo}
                    onBlur={() => setInputTo(to)}
                    invalidDateMessage={null}
                />
                <ListItemSecondaryAction>
                    <IconButton
                        className={closeButton}
                        onClick={() => handleTo(null)}
                        size="small"
                        edge="end"
                        disabled={!to}>
                        <CloseCrossIcon fontSize="small" color="inherit" />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    picker: {
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
            backgroundColor: 'transparent !important',
            WebkitBoxShadow: 'unset',
        },
        '& .MuiInputBase-root fieldset': {
            borderColor: COLOR.grayLight3,
        },
        '& .MuiInputBase-root.Mui-focused fieldset': {
            borderColor: COLOR.grayLight3,
        },
        '& .MuiInputBase-root:hover fieldset': {
            borderColor: COLOR.grayLight3,
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 4,
        },
    },
    closeButton: {
        color: theme.palette.error.main,
    },
}));
