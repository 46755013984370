import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { LandfillStatusCreateMutation, LandfillStatusCreateMutationVariables, LandfillStatusInput } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../landfillStatuses.queries';

export type LandfillStatusCreateFunc = (input: LandfillStatusInput) => Promise<void>;

export const useLandfillStatusCreate = (): [LandfillStatusCreateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        LandfillStatusCreateMutation,
        LandfillStatusCreateMutationVariables
    >(queries.LandfillStatusCreateMutation);

    const createFunc: LandfillStatusCreateFunc = useCallback(
        (input: LandfillStatusInput) => {
            return callMutation({
                variables: { input },
                refetchQueries: [
                    {
                        query: queries.LandfillStatusesListQuery,
                        variables: {},
                    },
                ],
                awaitRefetchQueries: true,
            })
                .then(() => {})
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [createFunc, mutationResult.loading];
};
