import { useCallback, useEffect, useState } from 'react';

import { useUserAbilities } from '~/contexts';
import i18n from '~/i18n';

import { LockEntityType } from './locks.types';
import { formatLockErrorMessage } from './locks.utils';
import { useLockFunc } from './useLockFunc';
import { useRemoveLockFunc } from './useRemoveLockFunc';

type Retry = () => void;

type UseLockState = {
    canEdit: boolean;
    loading: boolean;
    error: string | null;
    retry: Retry;
};

const noop = () => {};

const disabledState: UseLockState = { canEdit: true, retry: noop, error: null, loading: false };

export const useLock = (entityType: LockEntityType, entityId: string, disabled = false): UseLockState => {
    const { user: me } = useUserAbilities();

    const lockFunc = useLockFunc(entityType, entityId);
    const removeFunc = useRemoveLockFunc(entityType, entityId);
    const [state, setState] = useState<UseLockState>({ canEdit: false, loading: true, error: null, retry: noop });

    const tryLock: Retry = useCallback(() => {
        setState(state => ({ ...state, loading: true }));

        lockFunc()
            .then(lock =>
                setState({
                    loading: false,
                    canEdit: lock.userId === me.id,
                    error: lock.userId === me.id ? null : formatLockErrorMessage(lock),
                    retry: tryLock,
                })
            )
            .catch(() => {
                setState({ loading: false, error: i18n.error, canEdit: false, retry: tryLock });
            });
    }, [me, lockFunc]);

    useEffect(() => {
        if (disabled) return;

        tryLock();

        return removeFunc;
    }, [tryLock, removeFunc, disabled]);

    useEffect(() => {
        if (disabled) return;

        window.addEventListener('beforeunload', removeFunc);

        return () => window.removeEventListener('beforeunload', removeFunc);
    }, [removeFunc, disabled]);

    return disabled ? disabledState : state;
};
