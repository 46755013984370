import { adminRoutes, replaceRouteParams } from '@common/routes';
import { stringify } from 'qs';

import { PROJECT_TABS } from '~/@store/projects/projects.constants';

export type ProjectDetailParams = { id: string };

export type LandfillDetailParams = { id: string };

export type CustomerDetailParams = { id: string };

export type DriverDetailParams = { id: string };

export const projectDetailRoute = (id: string) => replaceRouteParams(adminRoutes.project, { id });

export const userDetailRoute = (id: string) => replaceRouteParams(adminRoutes.user, { id });

export const projectDocumentsRoute = (id: string) =>
    replaceRouteParams(adminRoutes.project, { id }) + '?' + stringify({ tab: PROJECT_TABS.documentation });

export const projectDumpLoadRoute = (id: string, dumpLoadId: string) =>
    replaceRouteParams(adminRoutes.project, { id }) + '?' + stringify({ dumpLoadId });

export const companyDetailRoute = (id: string) => replaceRouteParams(adminRoutes.company, { id });

export const landfillDetailRoute = (id: string) => replaceRouteParams(adminRoutes.landfill, { id });
