import { makeStyles } from '@material-ui/core';
import React from 'react';

import { BiSideLayout } from '~/@components/BiSideLayout';
import { CompanyCommentsView } from '~/@views/AdminView/CompaniesPage/CompanyCommentsView';

import { useCompanyDetailContext } from '../CompanyDetailContext';
import { CompanyEditForm } from '../CompanyEditForm';

export const CompanyEditTab = () => {
    const company = useCompanyDetailContext();

    const { commentsView } = useStyles();

    return (
        <BiSideLayout>
            <BiSideLayout.LeftSideContent>
                <CompanyEditForm company={company} />
            </BiSideLayout.LeftSideContent>
            <BiSideLayout.RightSideContent>
                <CompanyCommentsView
                    className={commentsView}
                    comments={company.comments || []}
                    companyId={company.id}
                />
            </BiSideLayout.RightSideContent>
        </BiSideLayout>
    );
};

const useStyles = makeStyles(() => ({
    commentsView: {
        height: 768,
    },
}));
