import React from 'react';

import { ClickableText } from '~/@components/ClickableText/ClickableText';
import { FilePreviewTooltip } from '~/@components/FilePreview';
import { LocalizableText } from '~/@components/LocalizableText';
import {
    DELIVERY_LINE_VIEW_MODE,
    deliveryLinesStore,
    IDeliveryLine,
    useDeliveryLineGetReceiptFunc,
} from '~/@store/deliveryLines';

import { showDeliveryLineUpdateDialog } from '../DeliveryLineEditForm';

type Props = {
    deliveryLine: IDeliveryLine;
};

export const DeliveryLineReceiptCell = ({ deliveryLine }: Props) => {
    const openReceipt = () =>
        showDeliveryLineUpdateDialog(
            deliveryLine.id,
            deliveryLinesStore.mode === DELIVERY_LINE_VIEW_MODE.DELIVERY_LINES ? 'update' : 'complete'
        );

    const loadFunc = useDeliveryLineGetReceiptFunc(deliveryLine);

    if (!deliveryLine.receiptUrl) return null;

    return (
        <FilePreviewTooltip fileName={deliveryLine.receiptUrl} loadFunc={loadFunc}>
            <ClickableText onClick={openReceipt}>
                <LocalizableText code={'DeliveryLinesTable.viewReceipt'} />
            </ClickableText>
        </FilePreviewTooltip>
    );
};
