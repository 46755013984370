import { format } from 'date-fns';

import sv from '../i18n/locales/sv';

export type DeliveryLineKind = {
    weight: number;
    truckCapacity?: number | null;
};

export const getLoadPercentage = ({ weight, truckCapacity }: DeliveryLineKind): number | null => {
    return weight && truckCapacity ? Math.ceil((100 * weight) / truckCapacity) : null;
};

type IDeliveryLine = {
    orderNumber: string;
    deliveryLineIdx: number;
    plannedStartDate: Date | string;
    receiptUrl: string | null | undefined;
};

export const getReceiptFileName = ({
    orderNumber,
    deliveryLineIdx,
    plannedStartDate,
    receiptUrl,
}: IDeliveryLine): string | null => {
    return getDeliveryArtefactFileName(receiptUrl, orderNumber, new Date(plannedStartDate), deliveryLineIdx);
};

export const getDeliveryArtefactFileName = (
    url: string | null | undefined,
    orderNumber: string,
    date: Date,
    index: number
): string | null => {
    const extension = url?.split('.').pop();
    if (!extension) return null;
    const order = orderNumber.replace(/\//, '');
    const datePart = format(date, sv.dateFormat);
    const indexPart = String((index || 0) + 1).padStart(3, '0');

    return `${order}_${datePart}_${indexPart}.${extension}`;
};
