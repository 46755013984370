import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';

import { COLORS } from '~/@components/@theme/colors';
import { Chip } from '~/@components/Chip';
import { DumpLoadStatusFormatter } from '~/@components/Formatters';
import { IColoredStatus, IStatus } from '~/@store/common';
import { DUMP_LOAD_STATUSES, IDumpLoadStatus } from '~/@store/dumpLoads';
import { LoadStatus } from '~/graphql';

type Props = { children: LoadStatus | null | void; onClick?: () => void; withBackground?: boolean };

export const DumpLoadStatusChip = ({ children, onClick, withBackground }: Props) => {
    const { whiteBackground } = useStyles();
    const status = useMemo<IStatus<IDumpLoadStatus> | undefined>(() => {
        return DUMP_LOAD_STATUSES.find(el => el.value === children);
    }, [children]);

    const className = withBackground ? whiteBackground : undefined;

    if (!status) {
        return (
            <Chip onClick={onClick} className={className}>
                <DumpLoadStatusFormatter>{children}</DumpLoadStatusFormatter>
            </Chip>
        );
    }

    return (
        <Chip color={(status as IColoredStatus<IDumpLoadStatus>).color} onClick={onClick} className={className}>
            {status.title}
        </Chip>
    );
};

const useStyles = makeStyles(() => ({
    whiteBackground: { backgroundColor: COLORS.white },
}));
