const DEFAULT_RGB_ARR = [0, 0, 0];

const arrToRgbaString = (arr: number[], alpha = 1): string => `rgba(${arr.join(', ')}, ${alpha})`;

function hexToRgbArray(hex: string) {
    const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (normal) return normal.slice(1).map(e => parseInt(e, 16));

    const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    if (shorthand) return shorthand.slice(1).map(e => 0x11 * parseInt(e, 16));

    return DEFAULT_RGB_ARR;
}

export const hexToRgba = (hex: string, alpha = 1) => {
    const arr = hexToRgbArray(hex);

    if (arr.length !== 3) return arrToRgbaString(DEFAULT_RGB_ARR, alpha);

    return arrToRgbaString(arr, alpha);
};
