import { UnitOfMeasure } from '@common/enums';
import { formatString } from '@common/utils';
import { FormikConfig } from 'formik';
import { RouterProps } from 'react-router-dom';
import * as Yup from 'yup';

import { CreateDumpLoadFunc } from '~/@store/dumpLoads';
import { IDumpTypeSelect } from '~/@store/dumpTypes';
import { IDetailedProject } from '~/@store/projects';
import { projectDumpLoadRoute } from '~/@store/routes';
import i18n from '~/i18n';

type IFormValues = {
    dumpType: IDumpTypeSelect | null;
    amount: number;
    unitOfMeasure: UnitOfMeasure;
};

export const getDumpLoadCreateFormik = (
    project: IDetailedProject,
    create: CreateDumpLoadFunc,
    onClose: () => void,
    history: RouterProps['history']
): FormikConfig<IFormValues> => ({
    initialValues: {
        dumpType: null,
        amount: 0,
        unitOfMeasure: UnitOfMeasure.TONNS,
    },
    validationSchema: Yup.object().shape({
        dumpType: Yup.object().nullable().required(formatString(i18n.isRequired, i18n.massCategory)),
        amount: Yup.number().moreThan(0, formatString(i18n.isRequired, i18n.amount)),
    }),
    onSubmit: async values => {
        const { dumpType, amount, unitOfMeasure } = values;

        const { id: dumpLoadId } = await create({
            dumpTypeId: dumpType?.id,
            amount,
            unitOfMeasure,
            date: project.startDate,
            endDate: project.endDate,
        });

        onClose();
        history.push(projectDumpLoadRoute(project.id, dumpLoadId));
    },
});
