import { LinearProgress } from '@material-ui/core';
import React, { useCallback } from 'react';

import { IComment } from '~/@store/comments';
import { useAddDumpLoadCommentMutation } from '~/@store/dumpLoads';
import { CommentsBlock } from '~/@views/AdminView/common/CommentsBlock';

type Props = {
    projectId: string;
    dumpLoadId: string;
    comments: IComment[];
    className?: string;
    readOnly?: boolean;
};

export const DumpLoadCommentsView = ({ className, comments, projectId, dumpLoadId, readOnly }: Props) => {
    const [addDumpLoadCommentMutation, isAdding] = useAddDumpLoadCommentMutation();

    const onNewComment = useCallback(
        (text: string) => {
            return addDumpLoadCommentMutation(projectId, dumpLoadId, text);
        },
        [projectId, dumpLoadId, addDumpLoadCommentMutation]
    );

    return (
        <div className={className}>
            {isAdding && <LinearProgress />}
            <CommentsBlock readOnly={isAdding || readOnly} comments={comments} onNewComment={onNewComment} />
        </div>
    );
};
