import { QueryResult } from '@apollo/client/react/types/types';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import React, { useCallback, useState } from 'react';

import { ConnectionInput } from '~/graphql';
import i18n from '~/i18n';

import { PAGINATION_SELECT_MENU_PROPS, ROWS_PER_PAGE_OPTIONS } from './constants';
import { PaginationSelectIcon } from './PaginationSelectIcon';

type GqlConnection = {
    pageInfo: {
        startCursor: string;
        hasNextPage: boolean;
        endCursor: string;
        hasPreviousPage: boolean;
        currentPage: number;
    };
    pageCount: number;
    totalCount: number;
};

type VariablesWithConnection = { connection?: ConnectionInput | null };

type Props<TData, TVariables extends VariablesWithConnection> = {
    className?: string;
    query: QueryResult<TData, TVariables>;
    connection: GqlConnection;
};

export const Pagination = <TData, TVariables extends VariablesWithConnection>({
    connection,
    query,
    className,
}: Props<TData, TVariables>) => {
    const { totalCount, pageInfo } = connection;

    const onPaginate = useCallback(
        (connection: ConnectionInput) => {
            const ignoredPromise = query.fetchMore({
                variables: { ...query.variables, connection },
                updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
        },
        [query]
    );

    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]!);

    const handleChangePage = useCallback(
        (_, pageNumber: number) => {
            onPaginate({ page: pageNumber + 1, first: rowsPerPage });
        },
        [onPaginate, rowsPerPage]
    );

    const handleChangeRowsPerPage = useCallback<React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>>(
        event => {
            const nextRowsPerPage = parseInt(event.target.value, 10);
            setRowsPerPage(nextRowsPerPage);
            onPaginate({ page: 1, first: nextRowsPerPage });
        },
        [onPaginate, setRowsPerPage]
    );

    const { paginationButton } = useStyles();

    return (
        <TablePagination
            component="div"
            className={className}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            count={totalCount || 0}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={i18n.Table.rowsPerPage}
            nextIconButtonText={i18n.Table.nextPage}
            backIconButtonText={i18n.Table.prevPage}
            nextIconButtonProps={{ className: paginationButton }}
            backIconButtonProps={{ className: paginationButton }}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${i18n.Table.of} ${count}`}
            page={pageInfo.currentPage - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
                MenuProps: PAGINATION_SELECT_MENU_PROPS,
                IconComponent: PaginationSelectIcon,
            }}
        />
    );
};

const useStyles = makeStyles(theme => ({
    paginationButton: {
        width: 32,
        height: 32,
        marginLeft: 16,
        borderRadius: '5%',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.inputBorder}`,
    },
}));
