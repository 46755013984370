import { LinearProgress } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';

import { IDeliveryLine, useDeliveryLineUpdateMutation } from '~/@store/deliveryLines';

import { DeliveryLineEditActions } from './DeliveryLineEditActions';
import { DeliveryLineEditFields } from './DeliveryLineEditFields';
import { DeliveryLineEditLayout } from './DeliveryLineEditLayout';
import { DeliveryLineReceiptView } from './DeliveryLineReceiptView';
import { getDeliveryLineUpdateFormikConfig } from './DeliveryLineUpdateFormik';

type Props = {
    deliveryLine: IDeliveryLine;
    onClose: (v: boolean) => void;
};

export const DeliveryLineUpdateForm = ({ deliveryLine, onClose }: Props) => {
    const [updateDeliveryLine, isUpdating] = useDeliveryLineUpdateMutation(deliveryLine);

    return (
        <Formik {...getDeliveryLineUpdateFormikConfig(updateDeliveryLine, deliveryLine)}>
            {({ values }) => (
                <DeliveryLineEditLayout
                    form={
                        <Form>
                            {isUpdating && <LinearProgress />}
                            <DeliveryLineEditFields />
                            <DeliveryLineEditActions onClose={onClose} />
                        </Form>
                    }
                    preview={<DeliveryLineReceiptView deliveryLine={deliveryLine} newReceipt={values.receipt} />}
                />
            )}
        </Formik>
    );
};
