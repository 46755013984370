import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { showCustomDialog } from '~/@store/common';
import { ILandfill, useDetailedLandfill } from '~/@store/landfills';

import { LandfillReceiversSelect } from './LandfillReceiversSelect';

type Props = {
    landfillId: string;
    onClose: (v: boolean) => void;
};

export const LandfillReceiversSelectDialog = ({ onClose, landfillId }: Props) => {
    const { root } = useStyles();
    const closeHandler = () => onClose(false);
    const query = useDetailedLandfill(landfillId);

    return (
        <CardDialog
            className={root}
            onClose={closeHandler}
            title={<LocalizableText code={'LandfillReceiversSearch.contactPersons'} />}>
            <QueryStateHandler query={query} dataGetter={query => query.data?.landfill || undefined}>
                {(landfill: ILandfill) => {
                    return <LandfillReceiversSelect landfill={landfill} />;
                }}
            </QueryStateHandler>
        </CardDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(3),
        minWidth: 522,
    },
}));

export const showLandfillReceiversSelectDialog = (landfillId: string) =>
    showCustomDialog({
        render: closeDialog => <LandfillReceiversSelectDialog landfillId={landfillId} onClose={closeDialog} />,
    });
