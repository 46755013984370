import { validateUserPhoneNumber } from '@common/validations/common.validation';
import { FormikConfig } from 'formik';
import * as Yup from 'yup';

import { AccountUpdateFunc, IAccount } from '~/@store/account';
import { errorCodes } from '~/config/enum';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';

export type IAccountFormValues = {
    accountId: string;
    name: string;
    surname: string;
    email: string;
    phoneNumber: string;
};

export function getAccountFormikConfig(
    account: IAccount,
    saveAccount: AccountUpdateFunc
): FormikConfig<IAccountFormValues> {
    return {
        initialValues: {
            accountId: account.id,
            name: account.name,
            surname: account.surname || '',
            email: account.email,
            phoneNumber: account.phoneNumber || '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(i18n.AccountPage.validation.nameEmpty),
            phoneNumber: Yup.string()
                .trim()
                .test('User phone number validation', '', function (phoneNumber) {
                    const errorCode = validateUserPhoneNumber(phoneNumber);

                    if (errorCode)
                        return this.createError({
                            path: 'phoneNumber',
                            message: i18n.errorCodes[errorCode] || i18n.errorCodes[errorCodes.INVALID_INPUT],
                        });

                    return true;
                }),
        }),
        onSubmit: async ({ name, surname, phoneNumber }, { resetForm }) => {
            await saveAccount({
                name,
                surname,
                phoneNumber,
            });

            globalMessage.success(i18n.configSaved);
            resetForm();
        },
    };
}
