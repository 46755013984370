import { IReactionDisposer, reaction } from 'mobx';
import { useEffect, useMemo, useState } from 'react';

export const useObservable = <TValue>(valueGetter: () => TValue, dependencyList: Array<unknown> = []) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [memoizedGetter, initialValue] = useMemo(() => [valueGetter, valueGetter()], dependencyList);

    const [value, setValue] = useState<TValue>(initialValue);

    useEffect(() => {
        return reaction(
            memoizedGetter,
            (v: TValue) => {
                setValue(v);
            },
            { fireImmediately: true }
        ) as IReactionDisposer;
    }, [memoizedGetter]);

    return value;
};
