import { Formik } from 'formik';
import React from 'react';

import { DeleteButton } from '~/@components/DeleteButton';
import { SimpleDialog } from '~/@components/Dialog';
import { Divider } from '~/@components/Divider';
import { FormCardButtons, FormCardContainer } from '~/@components/FormCard';
import { FormikBooleanField, FormikNumberField, FormikTextField } from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { showCustomDialog } from '~/@store/common';
import {
    CustomScript,
    useCustomScriptAddMutation,
    useCustomScriptRemoveMutation,
    useCustomScriptUpdateMutation,
    useSettingsPermissions,
} from '~/@store/settings';
import i18n from '~/i18n';

type Props = {
    script: CustomScript | null;
    closeForm?: () => void;
};

type FormValues = Omit<CustomScript, 'id' | '__typename'>;

const defaultValues = {
    text: '',
    src: '',
    async: false,
    order: 1,
    active: true,
};

export const CustomScriptForm = ({ script, closeForm }: Props) => {
    const initialValues: FormValues = script || defaultValues;

    const [addScript] = useCustomScriptAddMutation();
    const [updateScript] = useCustomScriptUpdateMutation(script?.id!);
    const [removeScript, isDeleting] = useCustomScriptRemoveMutation();

    const onSubmit = async (values: FormValues) => {
        if (script) {
            await updateScript(values);
        } else {
            await addScript(values).then(closeForm);
        }
    };

    const onDelete = () => removeScript(script?.id!);

    const title = (
        <LocalizableText
            code={script ? 'settingsPage.customScripts.editCustomScript' : 'settingsPage.customScripts.addCustomScript'}
        />
    );

    const { canEdit } = useSettingsPermissions();

    return (
        <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
            {formik => (
                <FormCardContainer formik={formik} showProgress={isDeleting}>
                    <Grid container justify="space-between" alignItems="center">
                        <Text variant="TitleBold">{title}</Text>
                        {script && canEdit && <DeleteButton onClick={onDelete} disabled={isDeleting} />}
                    </Grid>
                    <FormikTextField
                        label={<LocalizableText code={'settingsPage.customScripts.src'} />}
                        name="src"
                        formik={formik}
                    />
                    <FormikTextField
                        label={<LocalizableText code={'settingsPage.customScripts.text'} />}
                        name="text"
                        formik={formik}
                        multiline
                        rows={4}
                    />
                    <FormikBooleanField
                        label={<LocalizableText code={'settingsPage.customScripts.async'} />}
                        name="async"
                        formik={formik}
                    />
                    <Divider />
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3}>
                            <FormikNumberField
                                label={<LocalizableText code={'settingsPage.customScripts.order'} />}
                                name="order"
                                formik={formik}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <FormikBooleanField
                                label={<LocalizableText code={'settingsPage.customScripts.active'} />}
                                name="active"
                                formik={formik}
                            />
                        </Grid>
                    </Grid>

                    <FormCardButtons formik={formik} onCancel={closeForm || formik.resetForm} submitLabel={i18n.save} />
                </FormCardContainer>
            )}
        </Formik>
    );
};

export const addNewScript = () =>
    showCustomDialog({
        render: closeDialog => (
            <SimpleDialog maxWidth="sm" fullWidth>
                <CustomScriptForm script={null} closeForm={() => closeDialog()} />
            </SimpleDialog>
        ),
    });
