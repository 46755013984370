import { ErrorCodes } from '@common/enums';
import { isErrorDto } from '@common/validations/types.validation';

import i18n from '~/i18n';

/**
 * Utility error class, mainly for promise rejections, what we don't want to track in BugSnag
 */
export class NotAnError extends Error {}

export class FrontApiError extends Error {
    code!: ErrorCodes;

    constructor(message: string, code: ErrorCodes) {
        super(message);

        this.code = code;
    }

    static fromDto(error: unknown) {
        if (isErrorDto(error)) {
            return new FrontApiError(error.error, error.code);
        } else {
            return FrontApiError.fromError(error);
        }
    }

    static fromError(error: unknown | Error) {
        const defaultCode = ErrorCodes.NETWORK_ERROR;

        if (error && error instanceof Error) {
            return new FrontApiError(error.message, defaultCode);
        } else {
            return new FrontApiError(i18n.errorCodes.NETWORK_ERROR, defaultCode);
        }
    }
}
