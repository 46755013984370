import './ProjectHistory.scss';

import { replaceRouteParams, routes } from '@common/routes';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, VFC } from 'react';
import { useHistory } from 'react-router';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { MassCard } from '~/@sochi-components/cards/MassCard';
import { SochiInfoPanel } from '~/@sochi-components/SochiInfoPanel';
import { SochiTitle } from '~/@sochi-components/SochiTitle';

import { ProjectDoneActionHeader } from '../common/ProjectDoneActionHeader';
import type { ProjectCommonDataProps } from '../ProjectPage/ProjectPage';

export const ProjectHistory: VFC<ProjectCommonDataProps> = ({ project }) => {
    const { className, element } = useFunctionalBem(ProjectHistory);

    const history = useHistory();

    const onInspect = useCallback(
        (massId: string) => {
            history.push({
                pathname: replaceRouteParams(routes.sochi.projectMassDetail, {
                    projectId: project.id,
                    massId,
                }),
            });
        },
        [history, project]
    );

    return (
        <div className={className}>
            <ProjectDoneActionHeader project={project} />
            <SochiTitle title={<LocalizableText code={'projectHistory'} />} />
            {isEmpty(project.dumpLoads) ? (
                <SochiInfoPanel text={<LocalizableText code={'noMasses'} />} />
            ) : (
                <div className={element('masses-wrapper')}>
                    {project.dumpLoads.map(d => (
                        <MassCard
                            key={d.id}
                            mass={d}
                            action={{
                                labelCode: 'inspect',
                                onClick: () => onInspect(d.id),
                                disabled: !d.deliveredVolume,
                            }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
