import { gql } from '@apollo/client';

export const ProfileFragment = gql`
    fragment ProfileFragment on User {
        id
        name
        surname
        email
        phoneNumber
        role
        confirmed
        verified
        blocked
        startPage
        customer {
            id
            name
        }
        customerId
    }
`;

export const MeQuery = gql`
    query MeQuery {
        me {
            ...ProfileFragment
        }
    }

    ${ProfileFragment}
`;

export const AccountUpdateMutation = gql`
    mutation AccountUpdateMutation($input: ProfileInput!) {
        selfUpdateProfile(input: $input) {
            ...ProfileFragment
        }
    }

    ${ProfileFragment}
`;

export const AccountChangePasswordMutation = gql`
    mutation AccountChangePassword($input: ChangePasswordInput!) {
        selfChangePassword(input: $input)
    }
`;
