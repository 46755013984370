import React, { FC } from 'react';

import { SingleOptionFilter } from '~/@components/Table/TableHeadCell';
import { DumpLoadFilterFields, dumpLoadsStore } from '~/@store/dumpLoads';
import { dumpLoadModeFilterValues } from '~/utils/dumpLoad';

export const DumpLoadModeFilter: FC = () => {
    return (
        <SingleOptionFilter
            values={dumpLoadModeFilterValues}
            valueGetter={() => dumpLoadModeFilterValues.find(v => v.value === dumpLoadsStore.filter.values.mode)}
            setValue={v => dumpLoadsStore.filter.setValue(DumpLoadFilterFields.mode, v?.value)}
        />
    );
};
