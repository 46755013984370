import React, { memo, VFC } from 'react';

import { showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { DeleteIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { CellButton } from '~/@components/Table/TableCellActions/CellButton';
import { useUserLandfillDeleteMutation } from '~/@store/users';
import { useUserAbilities } from '~/contexts';
import { canUpdateUser } from '~/utils/auth';

import { useUserDetailContext } from '../UserDetailContext';

type Props = {
    landfill: { id: string; name: string };
    disabled?: boolean;
};

export const UserLandfillDeleteCell: VFC<Props> = memo(({ landfill, disabled }) => {
    const ability = useUserAbilities();
    const user = useUserDetailContext();
    const canDelete = canUpdateUser(ability, user);

    const [deleteUserLandfill, deleting] = useUserLandfillDeleteMutation();

    const handleDelete = () =>
        showDeleteConfirmationDialog(
            <LocalizableText code={'UserEditPage.deleteUserLandfillConfirmation'} />,
            landfill.name
        ).then(confirmed => {
            if (confirmed) {
                const ignoredPromise = deleteUserLandfill(user.id, landfill.id);
            }
        });

    return (
        <CellButton onClick={handleDelete} disabled={!canDelete || deleting || !!disabled}>
            <DeleteIcon />
        </CellButton>
    );
});
