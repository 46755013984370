import { ContaminationLevel, ContaminationType } from '@common/enums';
import { normalizeSubstanceAmounts } from '@common/toxic-analysis/toxicAnalysis.common';
import { ISubstanceAmount } from '@common/toxic-analysis/toxicAnalysis.types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEqual } from 'lodash';
import React, { useMemo } from 'react';

import { useDisable } from '~/@components/@hooks';
import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { showCustomDialog } from '~/@store/common';
import { ToxicLevelColorSchemas } from '~/@store/substances';
import { getContaminationLevelLabel } from '~/@store/toxicLimits';
import { SubstancesEditDialog } from '~/@views/AdminView/common/SubstancesEditDialog';
import i18n from '~/i18n';

import { useDumpLoadFormikContext } from '../DumpLoadEditFormik';

type Props = {
    disabled: boolean;
};

export const DumpLoadEditSubstances = ({ disabled }: Props) => {
    const { root } = useStyles();

    const formik = useDumpLoadFormikContext();

    disabled = useDisable() || disabled;

    const disableContaminationLevels = !formik.values.dumpType?.contaminationLevelAvailable;

    const handleEditFinish = (
        substances: ISubstanceAmount[],
        solidState: boolean,
        leachingState: boolean,
        solidContaminationLevel: ContaminationLevel | null
    ) => {
        if (
            solidState === formik.values.solidState &&
            leachingState === formik.values.leachingState &&
            isEqual(
                normalizeSubstanceAmounts(
                    formik.values.substances,
                    formik.values.solidState,
                    formik.values.leachingState
                ),
                normalizeSubstanceAmounts(substances, solidState, leachingState)
            )
        )
            return;

        formik.setFieldTouched('substances');
        formik.setFieldValue('substances', substances);
        formik.setFieldValue('solidState', solidState);
        formik.setFieldValue('leachingState', leachingState);
        formik.setFieldValue('solidContaminationLevel', solidContaminationLevel);
    };

    const showSubstanceForm = () => {
        const ignoredPromise = showCustomDialog({
            render: closeDialog => (
                <SubstancesEditDialog
                    substanceAmounts={formik.values.substances}
                    solidState={formik.values.solidState}
                    leachingState={formik.values.leachingState}
                    onEditFinish={handleEditFinish}
                    title={<LocalizableText code={'projectTestresult'} />}
                    onClose={() => closeDialog(true)}
                    readOnlySolid={disabled}
                    readOnlyLeaching={!formik.values.matchWithPinpointer}
                />
            ),
        });
    };

    const { substances: substanceAmounts, solidState, leachingState, solidContaminationLevel } = formik.values;

    const substancesWithValuesCount = useMemo(
        () => normalizeSubstanceAmounts(substanceAmounts, solidState, leachingState).length,
        [substanceAmounts, solidState, leachingState]
    );

    const substancesButtonDefaultColor = 'rgba(0, 0, 0, 0.23)';

    const substancesButtonStyle =
        solidContaminationLevel && !disableContaminationLevels
            ? ToxicLevelColorSchemas[solidContaminationLevel]
            : { borderColor: substancesButtonDefaultColor };

    const contaminationLevelContent = disableContaminationLevels ? (
        <LocalizableText code={'contaminationLevelUnavailable'} />
    ) : solidContaminationLevel ? (
        `${i18n.contaminationLevel}: ${getContaminationLevelLabel(solidContaminationLevel, ContaminationType.SOLID)}`
    ) : (
        `${i18n.toxicAnalysys}: ${substancesWithValuesCount} ${i18n.substances.toUpperCase()}`
    );

    return (
        <div className={root}>
            <Button
                variant={'outlined'}
                style={substancesButtonStyle}
                fullWidth
                onClick={showSubstanceForm}
                disabled={disableContaminationLevels}>
                <Text variant="BodyBold">{contaminationLevelContent}</Text>
            </Button>
            {disableContaminationLevels && (
                <LabelWithHint
                    label={''}
                    hint={<LocalizableText code={'contaminationLevelsForDumpTypeUnavailable'} />}
                />
            )}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'center',
    },
}));
