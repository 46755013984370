import React from 'react';

import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { showCustomDialog } from '~/@store/common';
import { IDeliveryLine } from '~/@store/deliveryLines';
import { IDeliveryLineWithHistory, useDeliveryLineHistory } from '~/@store/deliveryLines';

import { DeliveryLineHistoryForm } from './DeliveryLineHistoryForm';

type Props = {
    line: IDeliveryLine;
    onClose: () => void;
};

const DeliveryLineHistoryDialog = ({ line, onClose }: Props) => {
    const query = useDeliveryLineHistory(line.id);

    return (
        <CardDialog
            onClose={onClose}
            title={<LocalizableText code={'DeliveryLineHistoryCard.caption'} />}
            maxWidth="lg">
            <QueryStateHandler
                query={query}
                dataGetter={query => query.data?.pplDeliveryLine || query.previousData?.pplDeliveryLine || undefined}>
                {(data: IDeliveryLineWithHistory) => <DeliveryLineHistoryForm historyLines={data.history || []} />}
            </QueryStateHandler>
        </CardDialog>
    );
};

export const showDeliveryLineHistoryDialog = (line: IDeliveryLine) =>
    showCustomDialog({
        render: closeDialog => <DeliveryLineHistoryDialog onClose={() => closeDialog(true)} line={line} />,
    });
