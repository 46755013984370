import React, { useMemo } from 'react';

import { NullableFormatter } from '~/@components/Formatters';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { IDeliveryLine } from '~/@store/deliveryLines';

type Props = {
    deliveryLine: IDeliveryLine;
};

export const DeliveryLineLandfillInvoiceCell = ({ deliveryLine }: Props) => {
    const actions: Array<ITableCellAction> = useMemo(
        () => [
            {
                variant: ActionVariant.COPY,
                content: deliveryLine.landfillInvoiceNumber,
            },
        ],
        [deliveryLine]
    );

    return (
        <TableCellActions actions={actions}>
            <NullableFormatter>{deliveryLine.landfillInvoiceNumber}</NullableFormatter>
        </TableCellActions>
    );
};
