import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { AddToBlacklistMutation, AddToBlacklistMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../blacklist.queries';
import { blacklistStore } from '../blacklist.store';

type AddToBlacklistFunc = (email: string) => Promise<unknown>;

export const useAddToBlacklistMutation = (): [AddToBlacklistFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<AddToBlacklistMutation, AddToBlacklistMutationVariables>(
        queries.AddToBlacklistMutation
    );

    const addToBlacklist = useCallback<AddToBlacklistFunc>(
        (email: string) => {
            return callMutation({
                variables: { input: { email } },
                refetchQueries: [{ query: queries.BlacklistQuery, variables: blacklistStore.blacklistQueryVariables }],
            }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [addToBlacklist, mutationResult.loading];
};
