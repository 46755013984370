import { Form } from 'formik';
import React, { createContext, useCallback, useContext, useMemo } from 'react';

import { ILocation } from '~/@store/locations';
import { useGeocodeWithState } from '~/@store/locations/useGeocodeWithState';
import { canCreateLandfill, canUpdateLandfill } from '~/utils/auth';

import { IFormNames, landfillFormikHOC, LandfillFormProps } from './landfillFormikHOC';

export type LandfillFormContextType = LandfillFormProps & {
    setFieldValueAndTouchIt: (name: IFormNames, value?: unknown, shouldValidate?: boolean) => void;
    readOnly: boolean;
    isLoading: boolean;
    getByLatLng: (lat: number, lng: number) => Promise<ILocation>;
    getByText: (text: string) => Promise<ILocation>;
};

const LandfillFormContext = createContext<LandfillFormContextType>({} as LandfillFormContextType); // use empty object to init value only

export const useLandfillFormContext = () => useContext(LandfillFormContext);

type LandfillFormWithContextProps = LandfillFormProps;

export const LandfillFormWithContext = landfillFormikHOC((props: LandfillFormWithContextProps) => {
    const setFieldValueAndTouchIt = useCallback(
        (name: IFormNames, value?: unknown, shouldValidate: boolean = false): void => {
            props.setFieldTouched(name, true);
            props.setFieldValue(name, value, shouldValidate);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.setFieldTouched, props.setFieldValue]
    );

    const readOnly = useMemo(
        () =>
            props.landfill
                ? !canUpdateLandfill(props.user, props.ability, props.landfill)
                : !canCreateLandfill(props.user, props.ability),
        [props.user, props.landfill, props.ability]
    );

    const [loading, getByLatLng, getByText] = useGeocodeWithState();

    return (
        <LandfillFormContext.Provider
            value={{
                ...props,
                setFieldValueAndTouchIt,
                readOnly,
                isLoading: loading,
                getByLatLng,
                getByText,
            }}>
            <Form>{props.children}</Form>
        </LandfillFormContext.Provider>
    );
});
