import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { COLORS } from '~/@components/@theme/colors';
import { Chip } from '~/@components/Chip';
import { removeEmptyKeys } from '~/@store/common';
import { subareasStore, SubareaStoreFilterFields } from '~/@store/subareas';
import { formatFilterValue } from '~/@views/AdminView/common/utils';
import i18n from '~/i18n';

const FieldNames: Record<SubareaStoreFilterFields, string> = {
    [SubareaStoreFilterFields.landfillName]: i18n.SubareasTable.landfillName,
    [SubareaStoreFilterFields.landfillComment]: i18n.SubareasTable.landfillComment,
    [SubareaStoreFilterFields.landfillGateFee]: i18n.SubareasTable.landfillGateFee,
    [SubareaStoreFilterFields.landfillLocation]: i18n.SubareasTable.landfillLocation,
    [SubareaStoreFilterFields.landfillMarginPercent]: i18n.SubareasTable.landfillMarginPercent,
    [SubareaStoreFilterFields.landfillMinMargin]: i18n.SubareasTable.landfillMinMargin,
    [SubareaStoreFilterFields.landfillStatus]: i18n.SubareasTable.landfillStatus,
    [SubareaStoreFilterFields.dumpType]: i18n.SubareasTable.dumpType,
    [SubareaStoreFilterFields.materialId]: i18n.SubareasTable.externalId,
    [SubareaStoreFilterFields.comment]: i18n.SubareasTable.comment,
    [SubareaStoreFilterFields.currentAmount]: i18n.SubareasTable.currentAmount,
    [SubareaStoreFilterFields.nonStackable]: i18n.SubareasTable.nonStackable,
    [SubareaStoreFilterFields.over50cm]: i18n.SubareasTable.over50,
    [SubareaStoreFilterFields.priceBase]: i18n.SubareasTable.priceBase,
    [SubareaStoreFilterFields.priceNotStackable]: i18n.SubareasTable.priceNotStackable,
    [SubareaStoreFilterFields.priceOver50cm]: i18n.SubareasTable.priceOver50cm,
    [SubareaStoreFilterFields.priceUpdatedAt]: i18n.SubareasTable.priceUpdatedAt,
    [SubareaStoreFilterFields.solidTestResult]: i18n.SubareasTable.contaminationLevel,
    [SubareaStoreFilterFields.isProvider]: i18n.SubareasTable.isProvider,
};

export const SubareasFilterSummary = () => {
    const filterValues = useObservable(() => removeEmptyKeys({ ...subareasStore.filter.values }));

    const { root, chip } = useStyles();

    return (
        <div className={root}>
            {Object.keys(filterValues).map(objectKey => {
                const key = objectKey as SubareaStoreFilterFields;
                const value = filterValues[key];
                if (value === null || value === undefined) return null;
                const chipContent = `${FieldNames[key]}: "${formatFilterValue(value)}"`;

                return (
                    <Chip
                        key={key}
                        className={chip}
                        color={COLORS.black}
                        onDelete={() => subareasStore.filter.setValue(key, null)}
                        deleteIcon={<Icon>close</Icon>}>
                        {chipContent}
                    </Chip>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        marginRight: theme.spacing(1),
    },
}));
