import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { LandfillQuery_landfill } from '~/graphql';

import { MaterialsColumn } from './MaterialsColumn';
import { VolumeInfo } from './VolumeInfo';

type Props = {
    landfill: LandfillQuery_landfill;
};

export const LandfillMaterials: FC<Props> = ({ landfill }) => {
    const { root } = useStyles();

    return (
        <div className={root}>
            <VolumeInfo landfill={landfill} />
            <MaterialsColumn landfill={landfill} />
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        width: '100%',
    },
}));
