import React, { VFC } from 'react';

import { hasOwnProperty } from '~/utils';

import { useFunctionalBem } from '../@bem';
import icon0 from './assets/icon3d-0.png';
import icon1 from './assets/icon3d-1.png';
import icon2 from './assets/icon3d-2.png';
import icon3 from './assets/icon3d-3.png';
import icon4 from './assets/icon3d-4.png';
import icon5 from './assets/icon3d-5.png';
import iconDisabled from './assets/icon3d-disabled.png';

export const icons = {
    iconDisabled,
    icon0,
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
};

type LandfillStatusIconProps = {
    className?: string;
    icon?: string;
};

export const LandfillStatusIcon: VFC<LandfillStatusIconProps> = ({ icon = 'icon0', className }) => {
    const { element } = useFunctionalBem(LandfillStatusIcon, className);

    const srcIcon = hasOwnProperty<typeof icons, string, string>(icons, icon) ? icons[icon] : icons['icon0'];

    return <img className={element('icon')} alt={icon} src={srcIcon} />;
};
