import { AbilityCan, AbilitySubjects, ReleaseNoteType } from '@common/enums';
import { routes } from '@common/routes';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { AdminThemeContainer } from '~/@components/@theme';
import { ReleaseNotePopup } from '~/@views/common/ReleaseNotePopup';
import { useUserAbilities } from '~/contexts';

import { AdminViewRoot } from './AdminViewRoot';

export const AdminView = (props: RouteComponentProps) => {
    const { ability, user } = useUserAbilities();

    if (ability.cannot(AbilityCan.READ, AbilitySubjects.ADMIN_PAGES)) {
        return (
            <Redirect
                to={{
                    pathname: user.startPage || routes.root,
                    state: { state: { from: props.location } },
                }}
            />
        );
    }

    return (
        <AdminThemeContainer>
            <ReleaseNotePopup type={ReleaseNoteType.ADMIN} />
            <AdminViewRoot {...props} />
        </AdminThemeContainer>
    );
};
