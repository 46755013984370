import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import { makeStyles } from '~/@components/@theme';
import { UserFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn } from '~/@components/Table';
import { IProject } from '~/@store/projects';
import { AdminProjectColumn, ProjectFilterFields, projectsStore } from '~/@store/projects/project.store';

import { ProjectStatusChip } from '../common/ProjectStatusChip';
import { ProjectCommentCell } from './ProjectCommentCell';
import { ProjectHeadCell } from './ProjectHeadCell';
import { ProjectLocationCell } from './ProjectLocationCell';
import { ProjectNameCell } from './ProjectNameCell';

export const useProjectsColumns = (): ITableColumn<IProject>[] => {
    const { projectName, status, location } = useStyles();

    const hiddenColumns = useObservable(() => projectsStore.hiddenColumns);
    const isHidden = useCallback((column: AdminProjectColumn) => hiddenColumns.includes(column), [hiddenColumns]);

    return useMemo<ITableColumn<IProject>[]>(
        () => [
            {
                title: (
                    <ProjectHeadCell
                        title={<LocalizableText code={'ProjectsTable.name'} />}
                        fieldName={ProjectFilterFields.name}
                        withFilter
                        withSort
                    />
                ),
                render: row => <ProjectNameCell project={row} />,
                cellClassName: projectName,
                hidden: isHidden(AdminProjectColumn.name),
                name: AdminProjectColumn.name,
            },
            {
                title: (
                    <ProjectHeadCell
                        title={<LocalizableText code={'ProjectsTable.status'} />}
                        fieldName={ProjectFilterFields.status}
                        withFilter
                    />
                ),
                render: row => <ProjectStatusChip>{row.status}</ProjectStatusChip>,
                cellClassName: status,
                hidden: isHidden(AdminProjectColumn.status),
                name: AdminProjectColumn.status,
            },
            {
                title: (
                    <ProjectHeadCell
                        title={<LocalizableText code={'ProjectsTable.comment'} />}
                        fieldName={ProjectFilterFields.comment}
                        withFilter
                    />
                ),
                render: row => <ProjectCommentCell project={row} />,
                hidden: isHidden(AdminProjectColumn.comment),
                name: AdminProjectColumn.comment,
            },
            {
                title: (
                    <ProjectHeadCell
                        title={<LocalizableText code={'ProjectsTable.location'} />}
                        fieldName={ProjectFilterFields.locationText}
                        withFilter
                        withSort
                    />
                ),
                render: row => <ProjectLocationCell project={row} />,
                cellClassName: location,
                hidden: isHidden(AdminProjectColumn.location),
                name: AdminProjectColumn.location,
            },
            {
                title: (
                    <ProjectHeadCell
                        title={<LocalizableText code={'ProjectsTable.customer'} />}
                        fieldName={ProjectFilterFields.customerName}
                        withFilter
                        withSort
                    />
                ),
                render: row => row.customer?.name,
                hidden: isHidden(AdminProjectColumn.customer),
                name: AdminProjectColumn.customer,
            },
            {
                title: (
                    <ProjectHeadCell
                        title={<LocalizableText code={'ProjectsTable.salesResponsible'} />}
                        fieldName={ProjectFilterFields.ownerName}
                        withFilter
                    />
                ),
                render: row => <UserFormatter>{row.owner}</UserFormatter>,
                hidden: isHidden(AdminProjectColumn.owner),
                name: AdminProjectColumn.owner,
            },
        ],
        [isHidden, location, projectName, status]
    );
};

const useStyles = makeStyles(() => ({
    projectName: {
        width: 160,
    },
    status: {
        width: 120,
    },
    location: {
        maxWidth: 630,
    },
}));
