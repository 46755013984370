import './ProjectTransportScheduleContainer.scss';

import type { WithApolloClient } from '@apollo/client/react/hoc';
import { withApollo } from '@apollo/client/react/hoc';
import React, { Component } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import { ProjectQuery } from '~/@store/projects';
import { ProjectTransportScheduleMutation } from '~/@store/projects/projects.queries';

import type {
    DumploadTransportScheduleUpdate,
    ProjectQuery_project,
    ProjectTransportScheduleMutation as IProjectTransportScheduleMutation,
    ProjectTransportScheduleMutationVariables,
} from '../../../../../../graphql';
import i18n from '../../../../../../i18n';
import { showCustomDialog } from '../../../../../../services/dialog';
import { handleLoadingPromise } from '../../../../../../services/loader';
import message from '../../../../../../services/message';
import type { ValueResolver } from '../../../../../../stores/models';
import { handleApolloError } from '../../../../../../utils';
import { ProjectTransportSchedulePanel } from '../ProjectTransportSchedulePanel';

type Props = WithApolloClient<{
    project: ProjectQuery_project;
    className?: string;
}>;

class ProjectTransportScheduleContainer extends Component<Props> {
    bem = getBem(this);

    getSaveHandler = (closeDialog: ValueResolver) => (dumpLoads: Array<DumploadTransportScheduleUpdate>) => {
        const client = this.props.client;
        const { project } = this.props;

        const variables: ProjectTransportScheduleMutationVariables = {
            input: {
                dumpLoads: [...dumpLoads],
                id: project.id,
                ver: project.ver,
            },
        };

        handleLoadingPromise(
            client!
                .mutate<IProjectTransportScheduleMutation, ProjectTransportScheduleMutationVariables>({
                    mutation: ProjectTransportScheduleMutation,
                    variables,
                    update: (cache, { data }) => {
                        if (!data) return;
                        const { projectUpdateTransportSchedule: project } = data;

                        return cache.writeQuery({
                            query: ProjectQuery,
                            variables: { id: project?.id },
                            data: { project },
                        });
                    },
                })
                .then(() => closeDialog(true))
                .then(() => message.inPage.success(i18n.TransportSchedule.transportScheduleSaved))
                .catch(handleApolloError)
        );
    };

    showTransportSchedule = () => {
        showCustomDialog({
            title: <LocalizableText code={'TransportSchedule.editTransportSchedule'} />,
            render: (closeDialog: ValueResolver) => (
                <div className={this.bem.className}>
                    <ProjectTransportSchedulePanel
                        project={this.props.project}
                        dumpLoads={[...this.props.project.dumpLoads]}
                        onClose={closeDialog}
                        onSave={this.getSaveHandler(closeDialog)}
                    />
                </div>
            ),
        });
    };

    render() {
        return (
            <Button
                className={this.props.className}
                variant="contained"
                color="primary"
                onClick={this.showTransportSchedule}
                disabled={!this.props.project.dumpLoads.length}>
                {<LocalizableText code={'TransportSchedule.editTransportSchedule'} />}
            </Button>
        );
    }
}

export default withApollo<Props>(ProjectTransportScheduleContainer);
