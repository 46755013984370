import { FilterRange } from '@common/types';
import { isNullOrUndefined } from '@common/validations/types.validation';
import { chain } from 'lodash';

import { SortInput } from '~/graphql';
import { isSameOrAfter, isSameOrBefore, isValidDate } from '~/utils/date';

type ValueGetter<TObj, TValue> = (obj: TObj) => TValue | null | undefined;

const matchOperatorsRegex = /[|\\{}()[\]^$+*?.-]/g;

export const escapeRegExp = (str: string | null | undefined): string => {
    return (str || '').replace(matchOperatorsRegex, '\\$&');
};

export const getStringSearchFilterFn =
    <TObj extends {}>(sample: string, valueGetter: ValueGetter<TObj, string>) =>
    (obj: TObj): boolean => {
        const value = valueGetter(obj);
        if (isNullOrUndefined(value)) return false;

        return RegExp(escapeRegExp(sample), 'i').test(value);
    };

export const getNumberGteFilterFn =
    <TObj extends {}>(sample: number, valueGetter: ValueGetter<TObj, number>) =>
    (obj: TObj): boolean =>
        (valueGetter(obj) || 0) >= (sample || 0);

export const getNumberLteFilterFn =
    <TObj extends {}>(sample: number, valueGetter: ValueGetter<TObj, number>) =>
    (obj: TObj): boolean =>
        (valueGetter(obj) || 0) <= (sample || 0);

export const getNumberRangeFilterFn =
    <TObj extends {}>([from, to]: FilterRange<number>, valueGetter: ValueGetter<TObj, number>) =>
    (obj: TObj): boolean => {
        return (
            (isNullOrUndefined(from) || getNumberGteFilterFn(from, valueGetter)(obj)) &&
            (isNullOrUndefined(to) || getNumberLteFilterFn(to, valueGetter)(obj))
        );
    };

export const getDateRangeFilterFn =
    <TObj extends {}>([from, to]: FilterRange<Date>, valueGetter: ValueGetter<TObj, Date>) =>
    (obj: TObj): boolean => {
        const value = valueGetter(obj);
        if (!isValidDate(value)) return false;

        return (!isValidDate(from) || isSameOrAfter(value, from)) && (!isValidDate(to) || isSameOrBefore(value, to));
    };

export const getBooleanFilterFn =
    <TObj extends {}>(sample: boolean, valueGetter: ValueGetter<TObj, unknown>) =>
    (obj: TObj): boolean =>
        Boolean(valueGetter(obj)) === sample;

export const filterAndSortCollection = <TObj extends {}>(
    collection: TObj[],
    filters: ((obj: TObj) => boolean)[],
    sort: SortInput
): TObj[] => {
    const result = chain(collection)
        .filter(item => filters.reduce((acc, f) => acc && f(item), Boolean(true)))
        .sortBy(sort.field)
        .value();

    return sort.direction > 0 ? result : result.reverse();
};
