import './DeclarationOfWaste.scss';

import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import { DocumentCard } from '~/@sochi-components/cards/DocumentCard';
import { SochiSpoilerView } from '~/@sochi-components/SochiSpoilerView';
import type { ProjectQuery_project, ProjectQuery_project_dumpLoads } from '~/graphql';
import i18n from '~/i18n';

import { showCustomDialog } from '../../../../../services/dialog';
import { DeclarationFormModal } from '../DeclarationFormModal';

type DeclarationOfWasteProps = {
    project: ProjectQuery_project;
};

export class DeclarationOfWaste extends React.Component<DeclarationOfWasteProps> {
    bem = getBem(this);

    showDeclarationOfWasteForm = (mass: ProjectQuery_project_dumpLoads) => {
        const { project } = this.props;
        const ignoredPromise = showCustomDialog({
            title: <LocalizableText code={'declarationOfWaste'} />,
            render: closeModal => <DeclarationFormModal onClose={closeModal} project={project} mass={mass} />,
        });
    };

    render() {
        const { project } = this.props;
        const { element, className } = this.bem;
        const masses = project.dumpLoads || [];

        return (
            <div className={className}>
                <SochiSpoilerView
                    title={<LocalizableText code={'ProjectDocuments.declarationOfWaste'} />}
                    cropClassName={element('crop-cards')}>
                    <div className={element('waste-cards')}>
                        {masses.map(mass => {
                            return (
                                <DocumentCard
                                    key={mass.id}
                                    number={mass.declaration?.serialNumber || ''}
                                    onClick={() => {
                                        this.showDeclarationOfWasteForm(mass);
                                    }}
                                    name={mass.dumpType?.name || i18n.NA}
                                    status={mass.status || ''}
                                    viewButtonTitle={<LocalizableText code={'declarationOfWasteComponent.view'} />}
                                />
                            );
                        })}
                    </div>
                </SochiSpoilerView>
            </div>
        );
    }
}
