import { useMutation } from '@apollo/client';

import { VehicleTypeCreate, VehicleTypeCreateVariables, VehicleTypeInput } from '~/graphql';
import { handleApolloError } from '~/utils';

import { VehicleTypeCreateMutation, VehicleTypesQuery } from '../vehicleTypes.queries';
import { VehicleType } from '../vehicleTypes.types';

export const useVehicleTypeCreate = () => {
    const [callMutation, { loading }] = useMutation<VehicleTypeCreate, VehicleTypeCreateVariables>(
        VehicleTypeCreateMutation,
        {
            refetchQueries: [{ query: VehicleTypesQuery }],
            awaitRefetchQueries: true,
        }
    );

    const createVehicleType = (input: VehicleTypeInput): Promise<VehicleType> =>
        callMutation({ variables: { input } })
            .then(res => res.data!.vehicleTypeCreate)
            .catch(handleApolloError);

    return [createVehicleType, loading] as const;
};
