import { useMutation } from '@apollo/client';

import * as landfillQueries from '~/@store/landfills/landfills.queries';
import {
    LandfillDeliveryEventDetachFromLine,
    LandfillDeliveryEventDetachFromLineVariables,
    LandfillDeliveryEventsVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import {
    LandfillDeliveryEventDetachFromLineMutation,
    LandfillDeliveryEventsQuery,
} from './landfillDeliveryEvents.queries';

export const useDetachDeliveryEvent = (landfillId: string) => {
    const [callMutation, data] = useMutation<
        LandfillDeliveryEventDetachFromLine,
        LandfillDeliveryEventDetachFromLineVariables
    >(LandfillDeliveryEventDetachFromLineMutation);

    const refetchVariables: LandfillDeliveryEventsVariables = { landfillId };

    const detach = (lineId: string) =>
        callMutation({
            variables: { deliveryLineId: lineId },
            refetchQueries: [
                { query: LandfillDeliveryEventsQuery, variables: refetchVariables },
                { query: landfillQueries.LandfillQuery, variables: { id: landfillId } },
            ],
            awaitRefetchQueries: true,
        }).catch(handleApolloError);

    return [detach, data.loading] as const;
};
