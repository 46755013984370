import React, { useMemo } from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { CustomTabs } from '~/@components/CustomTabs';
import { BoxIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import {
    ISubstance,
    sortSubstancesAndChildrenByPosition,
    SubstancesContextProvider,
    useSubstancePermissions,
} from '~/@store/substances';
import { ContaminationType } from '~/graphql';
import i18n from '~/i18n';

import { showSubstanceCreateDialog } from './SubstanceEditForm';
import { SubstancesPageTabContent } from './SubstancesPageTabContent';

type Props = {
    substances: ISubstance[];
};

export const SubstancesPageTabs = ({ substances }: Props) => {
    const sortedSubstances = useMemo(() => sortSubstancesAndChildrenByPosition(substances), [substances]);

    const solidSubstances = useMemo(
        () => sortedSubstances.filter(s => s.contaminationTypes.includes(ContaminationType.SOLID)),
        [sortedSubstances]
    );

    const leachingSubstances = useMemo(
        () => sortedSubstances.filter(s => s.contaminationTypes.includes(ContaminationType.LEACHING)),
        [sortedSubstances]
    );

    const { canCreate } = useSubstancePermissions();

    const tabs = useMemo(
        () => [
            {
                label: <LocalizableText code={'solids'} />,
                subTitle: <LocalizableText code={'Substances.substanceName'} />,
                render: () => (
                    <SubstancesContextProvider substances={solidSubstances}>
                        <SubstancesPageTabContent />
                    </SubstancesContextProvider>
                ),
                rightActionButton: () => (
                    <ActionButton
                        onClick={() => showSubstanceCreateDialog(ContaminationType.SOLID)}
                        disabled={!canCreate}>
                        {i18n.create}
                    </ActionButton>
                ),
            },
            {
                label: <LocalizableText code={'leaching'} />,
                subTitle: <LocalizableText code={'Substances.substanceName'} />,
                render: () => (
                    <SubstancesContextProvider substances={leachingSubstances}>
                        <SubstancesPageTabContent />
                    </SubstancesContextProvider>
                ),
                rightActionButton: () => (
                    <ActionButton
                        onClick={() => showSubstanceCreateDialog(ContaminationType.LEACHING)}
                        disabled={!canCreate}>
                        {i18n.create}
                    </ActionButton>
                ),
            },
        ],
        [solidSubstances, leachingSubstances, canCreate]
    );

    return <CustomTabs headerIcon={<BoxIcon />} tabs={tabs} title={<LocalizableText code={'Admin.substances'} />} />;
};
