import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/substances/substances.queries';
import {
    ContaminationType,
    SubstanceGroupBy,
    SubstanceUpdateMutation,
    SubstanceUpdateMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

type SubstanceUpdateInput = {
    id: string | null;
    name: string;
    contaminationTypes: Array<ContaminationType>;
    groupBy: SubstanceGroupBy;
    parentId: string | null;
};

type SubstanceUpdateFunc = (input: SubstanceUpdateInput) => Promise<unknown>;

export const useSubstanceUpdate = (): [SubstanceUpdateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<SubstanceUpdateMutation, SubstanceUpdateMutationVariables>(
        queries.SubstanceUpdateMutation,
        {
            refetchQueries: [{ query: queries.SubstancesListQuery }],
        }
    );

    const updateSubstance = useCallback<SubstanceUpdateFunc>(
        ({ id, name, contaminationTypes, groupBy, parentId }) => {
            if (!id) return Promise.reject(new Error('Cannot update without id'));
            const variables: SubstanceUpdateMutationVariables = parentId
                ? { id, name }
                : { id, name, contaminationTypes, groupBy };

            return callMutation({ variables }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [updateSubstance, mutationResult.loading];
};
