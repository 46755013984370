import { gql } from '@apollo/client';

import { CommentFragment } from '~/@store/comments/comments.queries';
import { PageInfoFragment } from '~/@store/common/common.queries';

import { SubLandfillFragment, SubProjectFragment, UserFragment } from './users.queries.fragment';

export const AdminUsersSearchQuery = gql`
    query AdminUsersSearchQuery($filter: UserFilter!, $limit: Int!) {
        users(filter: $filter, connection: { first: $limit }) {
            id
            name
            surname
            email
            role
        }
    }
`;

export const AdminUsersPagedQuery = gql`
    query AdminUsersPagedQuery($filter: UserFilter, $sort: SortInput, $connection: ConnectionInput) {
        usersPaged(filter: $filter, sort: $sort, connection: $connection) {
            edges {
                cursor
                node {
                    ...UserFragment
                    lastCommentText
                }
            }
            pageInfo {
                ...PageInfoFragment
            }
            pageCount
            totalCount
        }
    }

    ${UserFragment}
    ${PageInfoFragment}
`;

export const AdminUserQuery = gql`
    query AdminUserQuery($id: ID!) {
        user(id: $id) {
            ...UserFragment
            comments {
                ...CommentFragment
            }
        }
    }

    ${UserFragment}
    ${CommentFragment}
`;

export const AdminUserProjectsQuery = gql`
    query AdminUserProjectsQuery($filter: ProjectFilter, $sort: SortInput, $connection: ConnectionInput) {
        projectsPaged(filter: $filter, sort: $sort, connection: $connection) {
            edges {
                cursor
                node {
                    ...SubProjectFragment
                }
            }
            pageInfo {
                ...PageInfoFragment
            }
            pageCount
            totalCount
        }
    }

    ${SubProjectFragment}
    ${PageInfoFragment}
`;

export const AdminUserLandfillsQuery = gql`
    query AdminUserLandfillsQuery($filter: LandfillFilter, $sort: SortInput, $connection: ConnectionInput) {
        landfillsPaged(filter: $filter, sort: $sort, connection: $connection) {
            edges {
                cursor
                node {
                    ...SubLandfillFragment
                }
            }
            pageInfo {
                ...PageInfoFragment
            }
            pageCount
            totalCount
        }
    }

    ${SubLandfillFragment}
    ${PageInfoFragment}
`;

export const UsersListQuery = gql`
    query UsersListQuery($filter: UserFilter, $sort: SortInput, $connection: ConnectionInput) {
        users(filter: $filter, sort: $sort, connection: $connection) {
            ...UserFragment
        }
    }

    ${UserFragment}
`;

export const UserUpdateMutation = gql`
    mutation UserUpdateMutation($input: UserUpdate!) {
        userUpdate(input: $input) {
            ...UserFragment
        }
    }

    ${UserFragment}
`;

export const UserVerifyMutation = gql`
    mutation UserVerifyMutation($userId: ID!, $verified: Boolean, $blocked: Boolean) {
        userVerify(userId: $userId, verified: $verified, blocked: $blocked) {
            ...UserFragment
        }
    }

    ${UserFragment}
`;

export const UserCreateMutation = gql`
    mutation UserCreateMutation($input: UserCreate!) {
        userCreate(input: $input) {
            ...UserFragment
        }
    }

    ${UserFragment}
`;

export const UserRemoveMutation = gql`
    mutation UserRemoveMutation($id: ID!, $sendNotify: Boolean) {
        userRemove(id: $id, sendNotify: $sendNotify)
    }
`;

export const UserRestoreMutation = gql`
    mutation UserRestoreMutation($id: ID!) {
        userRestore(id: $id) {
            id
        }
    }
`;

export const UserPasswordResetMutation = gql`
    mutation UserPasswordResetMutation($userId: ID!) {
        userPasswordReset(userId: $userId) {
            ...UserFragment
        }
    }

    ${UserFragment}
`;

export const UserPasswordChangeMutation = gql`
    mutation UserPasswordChangeMutation($input: UserChangePasswordInput!) {
        userChangePassword(input: $input)
    }
`;

export const UserLandfillCreateMutation = gql`
    mutation UserLandfillCreateMutation($userId: ID!, $landfillId: ID!) {
        userLandfillCreate(userId: $userId, landfillId: $landfillId) {
            ...UserFragment
        }
    }

    ${UserFragment}
`;

export const UserLandfillRemoveMutation = gql`
    mutation UserLandfillRemoveMutation($userId: ID!, $landfillId: ID!) {
        userLandfillRemove(userId: $userId, landfillId: $landfillId) {
            ...UserFragment
        }
    }

    ${UserFragment}
`;

export const UserCommentCreateMutation = gql`
    mutation UserCommentCreateMutation($userId: ID!, $text: String!) {
        userAddComment(id: $userId, text: $text) {
            id
            comments {
                ...CommentFragment
            }
            lastCommentText
        }
    }

    ${CommentFragment}
`;
