import { ProjectFields } from '@common/enums';
import { areProjectDatesInvalid } from '@common/functions/common.project';
import { replaceRouteParams, routes } from '@common/routes';
import { formatString } from '@common/utils';
import { add, isAfter, isSameDay, isValid } from 'date-fns';
import { isBefore } from 'date-fns/esm';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';
import * as Yup from 'yup';

import { DEFAULT_DATE_PICKER_MAX_DATE, DEFAULT_DATE_PICKER_MIN_DATE } from '~/@sochi-components/SochiDatePicker';
import { ICompanySearchRecord } from '~/@store/companies';
import { gqlTypeToLocation } from '~/@store/locations';
import { ProjectCreateMutation } from '~/@store/projects';
import { homePageMapStore } from '~/@user-store/homePageMap';
import client from '~/apolloClient';
import { withAbilities } from '~/contexts';
import type {
    ProjectCreateMutation as ProjectCreateMutation_gql,
    ProjectCreateMutation_projectCreate,
    ProjectCreateMutationVariables,
    ProjectQuery_project,
} from '~/graphql';
import { LocationType } from '~/graphql';
import i18n from '~/i18n';
import { handleLoadingPromise } from '~/services/loader';
import { globalMessage } from '~/services/message';
import { handleApolloError } from '~/utils';
import { canReadProject, canReadProjectCustomer, canUpdateProject, canUpdateProjectCustomer } from '~/utils/auth';
import { formatDate, parseDateFrom } from '~/utils/date';
import { updateProject } from '~/utils/project';

import type { IDates } from './ProjectDateRange';
import type { ProjectFormProps, ProjectFormValues } from './ProjectForm';
import { ProjectForm } from './ProjectForm';

const isSameDates = (dates: IDates, project: ProjectQuery_project | null) => {
    if (!project) return false;

    const projectStartDate = parseDateFrom(project.startDate);
    const projectEndDate = parseDateFrom(project.endDate);

    if (!projectStartDate || !projectEndDate) return false;

    return isSameDay(dates.startDate, projectStartDate) && isSameDay(dates.endDate, projectEndDate);
};

const createProject = (variables: ProjectCreateMutationVariables): Promise<ProjectCreateMutation_projectCreate> =>
    handleLoadingPromise(
        client.mutate<ProjectCreateMutation_gql>({
            mutation: ProjectCreateMutation,
            variables,
        })
    )
        .then(res => {
            const project = res.data?.projectCreate;
            if (!project) return Promise.reject();

            return project;
        })
        .catch(handleApolloError);

const formikHOC = withFormik<ProjectFormProps, ProjectFormValues>({
    mapPropsToValues: ({ project }) => {
        return {
            name: project?.name || '',
            dates: {
                startDate: parseDateFrom(project?.startDate) || new Date(),
                endDate: parseDateFrom(project?.endDate) || add(new Date(), { months: 1 }),
            },
            location: project?.location
                ? gqlTypeToLocation(project.location)
                : homePageMapStore.selectedPlacePojo || homePageMapStore.defaultLocation,
            invoiceReference: project?.invoiceReference || '',
            comment: project?.comment || '',
            customer: project?.customer || undefined,
        };
    },

    validationSchema: ({ project, ...props }: ProjectFormProps) =>
        Yup.object().shape({
            name: Yup.string().trim().required(i18n.errorCodes.PROJECT_NAME_CANNOT_BE_EMPTY),
            dates: Yup.object<IDates>().test('Dates validation', '', function (dates) {
                if (!dates) return false;
                if (isSameDates(dates, project)) return true;

                const { startDate, endDate } = dates;
                const path = 'dates';

                if (!startDate || !endDate)
                    return this.createError({
                        message: formatString(i18n.isRequired, i18n.dates),
                        path,
                    });

                if (!isValid(startDate) || !isValid(endDate))
                    return this.createError({
                        message: formatString(i18n.errorCodes.INVALID_DATE, i18n.dates),
                        path,
                    });

                if (
                    isBefore(startDate, DEFAULT_DATE_PICKER_MIN_DATE) ||
                    isBefore(endDate, DEFAULT_DATE_PICKER_MIN_DATE)
                )
                    return this.createError({
                        message: formatString(i18n.Dates.canNotBeBefore, formatDate(DEFAULT_DATE_PICKER_MIN_DATE)),
                        path,
                    });

                if (isAfter(startDate, DEFAULT_DATE_PICKER_MAX_DATE) || isAfter(endDate, DEFAULT_DATE_PICKER_MAX_DATE))
                    return this.createError({
                        message: formatString(i18n.Dates.canNotBeAfter, formatDate(DEFAULT_DATE_PICKER_MAX_DATE)),
                        path,
                    });

                const errorCode = areProjectDatesInvalid(dates, project?.dumpLoads);

                if (!errorCode) return true;

                return this.createError({ message: i18n.errorCodes[errorCode], path });
            }),
            location: Yup.object<ProjectFormValues['location']>().test('Location validation', '', function (location) {
                if (!location || !location.lat || !location.lng || !location.text)
                    return this.createError({
                        message: i18n.errorCodes.PROJECT_LOCATION_CANNOT_BE_EMPTY,
                        path: 'location',
                    });

                if (location.type === LocationType.UNKNOWN) {
                    return this.createError({
                        message: i18n.errorCodes.LOCATION_NOT_GEOCODED,
                        path: 'location',
                    });
                }

                if (location.type === LocationType.ON_WATER) {
                    return this.createError({
                        message: i18n.errorCodes.PROJECT_LOCATION_ON_WATER,
                        path: 'location',
                    });
                }

                if (location.type === LocationType.UNREACHABLE) {
                    return this.createError({
                        message: i18n.errorCodes.LOCATION_UNREACHABLE,
                        path: 'location',
                    });
                }

                return true;
            }),
            comment: Yup.string(),
            invoiceReference: Yup.string(),
            customer: Yup.object<ICompanySearchRecord>().test(
                'Customer validation',
                i18n.errorCodes.PROJECT_CUSTOMER_REQUIRED,
                function (value) {
                    if (!canReadProjectCustomer(props, project)) return true;

                    if (!canUpdateProjectCustomer(props, project)) return true;

                    if (project && value === null) {
                        return this.createError({
                            message: i18n.errorCodes.PROJECT_CUSTOMER_REQUIRED,
                            path: 'customer',
                        });
                    }

                    return !(!project && !value);
                }
            ),
        }),

    handleSubmit: (values, { props, resetForm }) => {
        const { project, history, ability } = props;
        const { dates, name, location, comment, invoiceReference, customer } = values;

        if (!project) {
            const variables: ProjectCreateMutationVariables = {
                input: {
                    location,
                    name,
                    startDate: dates.startDate.toISOString(),
                    endDate: dates.endDate.toISOString(),
                    invoiceReference,
                    customerId: canUpdateProjectCustomer(props, null) ? customer?.id : undefined,
                },
            };

            createProject(variables).then(project => {
                homePageMapStore.setCreationMode(null);
                history.push(
                    replaceRouteParams(routes.sochi.projectDetail, {
                        projectId: project.id,
                    })
                );
            });
        } else {
            const canChangeField = (field: ProjectFields) =>
                canReadProject(props, project, [field]) && canUpdateProject(props, project, [field]);

            const variables = {
                input: {
                    id: project.id,
                    ver: project.ver,
                    name,
                    location: canChangeField(ProjectFields.location) ? location : undefined,
                    ...(!isSameDates(dates, project) && {
                        startDate: dates.startDate.toISOString(),
                        endDate: dates.endDate.toISOString(),
                    }),
                    comment,
                    invoiceReference,
                    customerId: canChangeField(ProjectFields.customerId) ? customer?.id : undefined,
                },
            };

            updateProject(variables, ability).then(() => {
                globalMessage.success(i18n.projectUpdatedSuccessfully);
                resetForm();
            });
        }
    },
});

export default withRouter(withAbilities(formikHOC(ProjectForm)));
