import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';

import { INumberFieldProps } from '../NumberField';
import { FormikNumberField } from './FormikNumberField';

type FormikNumberFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends number | null ? TFieldName : never;
} & Omit<INumberFieldProps, 'value' | 'onChange'>;

export function FormikContextNumberField<TValues extends {}, TFieldName extends keyof TValues & string>({
    className,
    ...props
}: FormikNumberFieldProps<TValues, TFieldName>) {
    const formik = useFormikContext<TValues>();
    const { root } = useStyles();

    return <FormikNumberField<TValues, TFieldName> className={cn(root, className)} formik={formik} {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
}));
