import './ProjectTransportSchedulePanel.scss';

import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { Button } from '~/@components/Button';
import { getBem } from '~/@sochi-components/@bem';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { AbilityUserContext, IAbilityUserContext } from '~/contexts';
import type { DumploadTransportScheduleUpdate } from '~/graphql';
import i18n from '~/i18n';

import { DumpLoadBlock } from '../DumpLoadBlock';
import { ProjectTransportScheduleColumns } from '../ProjectTransprtScheduleColumns';
import { IDumpLoadProp, IProjectProp, scheduleStore } from '../scheduleStore';

type ExternalProps = {
    project: IProjectProp;
    dumpLoads: IDumpLoadProp[];
    onClose: (flag: boolean) => void;
    onSave: (dumpLoads: Array<DumploadTransportScheduleUpdate>) => void;
};

@observer
export class ProjectTransportSchedulePanel extends Component<ExternalProps> {
    static contextType = AbilityUserContext;
    context!: IAbilityUserContext;
    bem = getBem(this);

    componentDidMount() {
        const { project, dumpLoads } = this.props;
        scheduleStore.initializeData(project, dumpLoads, this.context);
    }

    componentWillUnmount() {
        scheduleStore.clearData();
    }

    handleCancel = () => {
        this.props.onClose(false);
    };

    handleSave = () => {
        const { onSave } = this.props;
        const dumpLoads: DumploadTransportScheduleUpdate[] = scheduleStore
            .getDumpLoads()
            .map(({ id, date, endDate, transportSchedule }) => ({
                id,
                date: date.toISOString(),
                endDate: endDate.toISOString(),
                transportSchedule: transportSchedule.map(({ amount, week }) => ({ amount, week })),
            }));

        onSave(dumpLoads);
    };

    render() {
        const { className } = this.bem;
        const { dumpLoads, projectId } = scheduleStore;

        if (!projectId) return null;

        return (
            <div className={className}>
                <ProjectTransportScheduleColumns />

                {dumpLoads.map(d => (
                    <DumpLoadBlock key={d.id} dumpLoad={d} />
                ))}

                <ButtonGroup>
                    <Button variant="outlined" color="primary" onClick={this.handleCancel} size="large">
                        {i18n.cancel}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSave}
                        disabled={!scheduleStore.canSave}
                        size="large">
                        {i18n.save}
                    </Button>
                </ButtonGroup>
            </div>
        );
    }
}
