import { ContaminationLevel, ContaminationType } from '@common/enums';
import { makeStyles } from '@material-ui/core';
import React from 'react';

import { Chip } from '~/@components/Chip';
import { ToxicLevelColorSchemas } from '~/@store/substances';
import { getContaminationLevelLabel } from '~/@store/toxicLimits/toxicLimits.helpers';

type Props = { children: ContaminationLevel | null };

const getCLColor = (testResult: ContaminationLevel): string | null =>
    (ToxicLevelColorSchemas[testResult] || ToxicLevelColorSchemas.fa).backgroundColor;

export const ContaminationLevelFormatter = ({ children }: Props) => {
    const { chip } = useStyles();

    if (!children) return null;

    return (
        <Chip className={chip} variant="default" color={getCLColor(children)}>
            {getContaminationLevelLabel(children, ContaminationType.SOLID)}
        </Chip>
    );
};

const useStyles = makeStyles(() => ({
    chip: {
        flexShrink: 0,
        fontWeight: 'inherit',
    },
}));
