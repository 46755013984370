import { IconButton, Input, InputAdornment, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { CloseCrossIcon, SearchIcon } from '~/@sochi-components/Icons';
import i18n from '~/i18n';

type Props = {
    valueGetter: () => string | null | void;
    setValue: (v: string | null) => void;
};

export const SochiStringFilter = ({ valueGetter, setValue }: Props) => {
    const { closeButton, searchIcon } = useStyles();
    const filter = useObservable(valueGetter);

    const handleFilterChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value);
        },
        [setValue]
    );

    const handleFilterClear = useCallback(() => {
        setValue(null);
    }, [setValue]);

    return (
        <ListItem>
            <Input
                placeholder={i18n.search}
                value={filter || ''}
                onChange={handleFilterChange}
                disableUnderline
                fullWidth
                endAdornment={
                    <InputAdornment position="end">
                        {filter ? (
                            <IconButton className={closeButton} onClick={handleFilterClear} size="small">
                                <CloseCrossIcon fontSize="small" color="inherit" />
                            </IconButton>
                        ) : (
                            <SearchIcon className={searchIcon} fontSize="small" />
                        )}
                    </InputAdornment>
                }
            />
        </ListItem>
    );
};

const useStyles = makeStyles(theme => ({
    closeButton: {
        color: theme.palette.error.main,
    },
    searchIcon: {
        paddingRight: 5,
    },
}));
