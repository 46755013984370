import { Button, Divider, Grid } from '@material-ui/core';
import { FormikContextType } from 'formik';
import React from 'react';

import { IDetailedLandfill } from '~/@store/landfills';
import i18n from '~/i18n';

import { LandfillEditFormValues } from './LandfillEdit.types';

type Props = {
    formik: FormikContextType<LandfillEditFormValues>;
    landfill: IDetailedLandfill | null;
    onClose?: () => void;
};

export const LandfillEditActions = ({ formik, landfill, onClose }: Props) => {
    const canSubmit = !formik.isSubmitting && (!landfill || formik.dirty);

    const handleCancel = () => {
        if (!landfill || !formik.dirty) return onClose?.();

        formik.handleReset();
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3} justify="space-between">
                    <Grid item>
                        <Button disabled={!formik.dirty && !onClose} onClick={handleCancel} variant="contained">
                            {!formik.dirty && landfill && onClose ? i18n.close : i18n.cancel}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={!canSubmit} onClick={formik.submitForm} variant="contained" color="primary">
                            {landfill ? i18n.save : i18n.create}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
