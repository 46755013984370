import React from 'react';

import { CustomTabs } from '~/@components/CustomTabs';
import { PermissionsIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';

import { DeviationPermissions } from './DeviationPermissions';
import { DumpLoadPermissions } from './DumpLoadPermissions';
import { LandfillPermissions } from './LandfillPermissions';
import { ProjectPermissions } from './ProjectPermissions';
import { UserPermissions } from './UserPermissions';

const tabs = [
    {
        label: <LocalizableText code={'PermissionsPage.project'} />,
        render: () => <ProjectPermissions />,
    },
    {
        label: <LocalizableText code={'PermissionsPage.dumpLoad'} />,
        render: () => <DumpLoadPermissions />,
    },
    {
        label: <LocalizableText code={'PermissionsPage.landfill'} />,
        render: () => <LandfillPermissions />,
    },
    {
        label: <LocalizableText code={'PermissionsPage.deviation'} />,
        render: () => <DeviationPermissions />,
    },
    {
        label: <LocalizableText code={'PermissionsPage.user'} />,
        render: () => <UserPermissions />,
    },
];

export const PermissionsPage = () => {
    return (
        <CustomTabs
            tabs={tabs}
            headerIcon={<PermissionsIcon />}
            title={<LocalizableText code={'Admin.permissions'} />}
        />
    );
};
