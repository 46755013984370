import './MapWithContentContainer.scss';

import React from 'react';

import { FloatMobilePanel } from '~/@views/UserView/common/FloatMobilePanel';
import { useDeviceMedia } from '~/contexts';

import { useFunctionalBem } from '../@bem';

type Props = {
    renderContent: () => React.ReactNode;
    renderMap: () => React.ReactNode;
};

export const MapWithContentContainer = ({ renderContent, renderMap }: Props) => {
    const { element } = useFunctionalBem(MapWithContentContainer);
    const { isMobileOrTablet } = useDeviceMedia();

    return !isMobileOrTablet ? (
        <div className={element('desktop')}>
            <div className={element('side')}>{renderContent()}</div>
            <div className={element('desktop-map')}>{renderMap()}</div>
        </div>
    ) : (
        <div className={element('mobile')}>
            {renderMap()}
            <FloatMobilePanel>{renderContent()}</FloatMobilePanel>
        </div>
    );
};
