import React, { useCallback } from 'react';

import { ClickableText } from '~/@components/ClickableText';
import { showCustomDialog } from '~/@store/common';
import { IProject } from '~/@store/projects';

import { ProjectLocation } from '../ProjectLocation';

type Props = {
    project: IProject;
};

export const ProjectLocationCell = ({ project }: Props) => {
    const showLocation = useCallback(() => {
        const ignoredPromise = showCustomDialog({
            render: closeDialog => <ProjectLocation closeDialog={closeDialog} project={project} />,
        });
    }, [project]);

    return <ClickableText onClick={showLocation}>{project.location.text}</ClickableText>;
};
