import { gql } from '@apollo/client';

import { PageInfoFragment } from '~/@store/common/common.queries';
import { DeliveryLineShortFragment } from '~/@store/deliveryLines/deliveryLines.queries';

export const DespatchAdviceFragment = gql`
    fragment DespatchAdviceFragment on DespatchAdvice {
        id
        category
        error
        canSend
        adviceType
        createdAt
        sentAt
        transactionId
        body
    }
`;

export const DespatchAdviceResponseFragment = gql`
    fragment DespatchAdviceResponseFragment on DespatchAdviceResponse {
        id
        date
        body
        despatchAdviceId
        responseType
        transactionId
        declineReason
        deliveryLine {
            ...DeliveryLineShortFragment
        }
    }

    ${DeliveryLineShortFragment}
`;

export const AdminBeastDataQuery = gql`
    query AdminBeastDataQuery($entityId: ID!, $source: DespatchAdviceSource!) {
        despatchAdvices(entityId: $entityId, source: $source) {
            ...DespatchAdviceFragment
        }
        despatchAdviceResponses(entityId: $entityId, source: $source) {
            ...DespatchAdviceResponseFragment
        }
    }

    ${DespatchAdviceFragment}
    ${DespatchAdviceResponseFragment}
`;

export const AdminSendDespatchAdvicesMutation = gql`
    mutation AdminSendDespatchAdvicesMutation($id: ID!) {
        sendDespatchAdvice(id: $id) {
            ...DespatchAdviceFragment
        }
    }

    ${DespatchAdviceFragment}
`;

export const DespatchAdviceResponsesPagedQuery = gql`
    query DespatchAdviceResponsesPaged(
        $filter: DespatchAdviceResponseFilterInput
        $sort: SortInput
        $connection: ConnectionInput
    ) {
        despatchAdviceResponsesPaged(filter: $filter, sort: $sort, connection: $connection) {
            edges {
                cursor
                node {
                    ...DespatchAdviceResponseFragment
                }
            }
            pageInfo {
                ...PageInfoFragment
            }
            pageCount
            totalCount
        }
    }

    ${PageInfoFragment}
    ${DespatchAdviceResponseFragment}
`;

export const AdminDespatchAdviceDefaultSettingsQuery = gql`
    query AdminDespatchAdviceDefaultSettingsQuery {
        despatchAdviceDefaultSettings {
            despatchParty {
                id
                name
            }
            consignee {
                id
                name
            }
            allowSelect
        }
    }
`;

export const DespatchAdviceResponsesRequestMutation = gql`
    mutation DespatchAdviceResponsesRequest {
        requestAdviceResponses {
            ...DespatchAdviceResponseFragment
        }
    }

    ${DespatchAdviceResponseFragment}
`;
