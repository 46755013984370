import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { DEFAULT_SEARCH_LIMIT } from '~/@store/common';
import { LandfillFilter, LandfillsSearchQuery, LandfillsSearchQueryVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../landfills.queries';
import type { ILandfillSearchRecord } from '../landfills.types';

type LandfillsSearchFunc = (search: string) => Promise<Array<ILandfillSearchRecord>>;

export const useLandfillsSearchFunc = (
    filter: LandfillFilter = {},
    limit: number = DEFAULT_SEARCH_LIMIT
): LandfillsSearchFunc => {
    const client = useApolloClient();

    return useCallback(
        (name: string) => {
            return client
                .query<LandfillsSearchQuery, LandfillsSearchQueryVariables>({
                    query: queries.AdminLandfillsSearchQuery,
                    variables: {
                        filter: {
                            ...filter,
                            name,
                        },
                        limit,
                    },
                    fetchPolicy: 'network-only',
                })
                .then(res => res.data?.landfillsPaged.edges.map(({ node }) => node) || [])
                .catch(handleApolloError);
        },
        [client, filter, limit]
    );
};
