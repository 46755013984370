import { UserContextRole } from '@common/enums';
import { isValidEmail } from '@common/validations/common.validation';
import React, { useState } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { TextField } from '~/@components/TextField';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { useProjectAddUser } from '~/@store/projects';
import { ProjectQuery_project } from '~/graphql';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';
import { globalMessage } from '~/services/message';

import { ProjectUsersSelectRole } from './ProjectUsersSelectRole';

type Props = { project: ProjectQuery_project; onClose: () => void };

export const ProjectAddUserDialog = ({ project, onClose }: Props) => {
    const [role, setRole] = useState(UserContextRole.PROJECT_USER);
    const [email, setEmail] = useState('');
    const [addUser, isAdding] = useProjectAddUser(project);
    const canSubmit = isValidEmail(email);

    const onAdd = async () => {
        const result = await addUser({ projectId: project.id, role, email });

        switch (result) {
            case 'INVITED':
                globalMessage.success(i18n.ProjectForm.userInvited);
                break;
            case 'ADDED':
                globalMessage.success(i18n.projectUpdatedSuccessfully);
                break;
        }

        onClose();
    };

    return (
        <DialogContent>
            <ProjectUsersSelectRole currentRole={role} onRoleSelect={setRole} />
            <TextField
                label={i18n.enterNewUserEmail}
                value={email}
                onChange={e => setEmail(e.target.value)}
                autoFocus
            />
            <ButtonGroup>
                <Button color="primary" variant="outlined" onClick={onClose} disabled={isAdding}>
                    {i18n.cancel}
                </Button>
                <Button color="primary" variant="contained" onClick={onAdd} disabled={!canSubmit || isAdding}>
                    <LocalizableText code={'ProjectForm.invite'} />
                </Button>
            </ButtonGroup>
        </DialogContent>
    );
};

export const showAddUserDialog = (project: ProjectQuery_project) =>
    showCustomDialog({
        render: closeDialog => <ProjectAddUserDialog onClose={() => closeDialog(true)} project={project} />,
    });
