import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useCallback } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { Divider } from '~/@components/Divider';
import { CancelIcon, DoneAllIcon } from '~/@components/Icon';
import i18n from '~/i18n';

import { OptionValue } from './SingleOptionFilter';

type Props<T> = {
    values: T[];
    valueGetter: () => T[] | null | undefined;
    setValues: (v: T[] | null) => void;
    showMassActions?: boolean;
};

const noop = () => {};

export const MultiOptionFilter = <T extends OptionValue>({
    values,
    valueGetter,
    setValues,
    showMassActions = false,
}: Props<T>) => {
    const filter = useObservable(valueGetter);

    const handleFilterChange = useCallback(
        (value: T) => {
            if (!filter) {
                setValues([value]);

                return;
            }

            if (filter.find(item => item.id === value.id)) {
                setValues(filter.filter(v => v.id !== value.id));
            } else {
                setValues([...filter, value]);
            }
        },
        [setValues, filter]
    );

    return (
        <>
            {values.map(v => (
                <ListItem key={v.id} button divider onClick={() => handleFilterChange(v)}>
                    <ListItemIcon>
                        <Checkbox
                            color="primary"
                            checked={filter?.some(item => item.id === v.id) || false}
                            onChange={noop}
                        />
                    </ListItemIcon>
                    <ListItemText>{v.name}</ListItemText>
                </ListItem>
            ))}
            {showMassActions && (
                <>
                    <Divider />
                    <ListItem button divider onClick={() => setValues(values)}>
                        <ListItemIcon>
                            <DoneAllIcon />
                        </ListItemIcon>
                        <ListItemText>{i18n.OptionFilter.selectAll}</ListItemText>
                    </ListItem>
                    <ListItem button divider onClick={() => setValues(null)}>
                        <ListItemIcon>
                            <CancelIcon />
                        </ListItemIcon>
                        <ListItemText>{i18n.OptionFilter.clearAll}</ListItemText>
                    </ListItem>
                </>
            )}
        </>
    );
};
