import React, { FC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { FormikBooleanField } from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { useDumpLoadAbilities } from '~/@store/dumpLoads';
import { useDumpLoadFormikContext } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm/DumpLoadEditFormik';

export const DumpLoadEditCoordinationFields: FC = () => {
    const formik = useDumpLoadFormikContext();

    const { root } = useStyles();

    const { dumpLoad } = formik.values;
    const { canUpdateDumpLoad } = useDumpLoadAbilities(dumpLoad);

    const disabled = !canUpdateDumpLoad;

    return (
        <div className={root}>
            <Text variant="TitleBold">
                <LocalizableText code={'DumpLoadCard.coordination'} />
            </Text>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <FormikBooleanField
                        label={<LocalizableText code={'DumpLoadCard.siteConfirmed'} />}
                        name="siteConfirmed"
                        formik={formik}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormikBooleanField
                        label={<LocalizableText code={'DumpLoadCard.creditCheck'} />}
                        name="creditCheck"
                        formik={formik}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormikBooleanField
                        label={<LocalizableText code={'DumpLoadCard.toxicAnalysisApprovedExternally'} />}
                        name="toxicAnalysisApprovedExternally"
                        formik={formik}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormikBooleanField
                        label={<LocalizableText code={'DumpLoadCard.contractSigned'} />}
                        name="contractSigned"
                        formik={formik}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormikBooleanField
                        label={<LocalizableText code={'DumpLoadCard.landfillOrdered'} />}
                        name="landfillOrdered"
                        formik={formik}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));
