import { Icon, IconButton, Input, InputAdornment, InputBaseComponentProps, ListItem } from '@material-ui/core';
import { isNumber } from 'lodash';
import React, { ChangeEvent, useCallback } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import i18n from '~/i18n';

import { FilterRange } from '../../../../../common/types';

type Props = {
    valueGetter: () => FilterRange<number> | null | undefined;
    setValue: (v: FilterRange<number> | null) => void;
};

const NumberInputProps: InputBaseComponentProps = { inputMode: 'numeric', pattern: '[0-9]*' };

export const NumberFilter = ({ valueGetter, setValue }: Props) => {
    const [from, to] = useObservable(valueGetter) || [null, null];

    const handleFrom = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            if (value.trim() === '' || isNaN(Number(value))) {
                setValue([null, to || null]);
            } else {
                setValue([Number(value), to || null]);
            }
        },
        [setValue, to]
    );

    const handleTo = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            if (value.trim() === '' || isNaN(Number(value))) {
                setValue([from || null, null]);
            } else {
                setValue([from || null, Number(value)]);
            }
        },
        [setValue, from]
    );

    const handleClearFrom = useCallback(() => setValue([null, to || null]), [setValue, to]);
    const handleClearTo = useCallback(() => setValue([from || null, null]), [setValue, from]);

    return (
        <>
            <ListItem divider>
                <Input
                    placeholder={i18n.from}
                    inputProps={NumberInputProps}
                    value={isNumber(from) ? from : ''}
                    onChange={handleFrom}
                    disableUnderline
                    fullWidth
                    endAdornment={
                        <InputAdornment position="end">
                            {isNumber(from) ? (
                                <IconButton onClick={handleClearFrom} size="small">
                                    <Icon color="secondary">close</Icon>
                                </IconButton>
                            ) : (
                                <Icon>search</Icon>
                            )}
                        </InputAdornment>
                    }
                />
            </ListItem>
            <ListItem>
                <Input
                    placeholder={i18n.to}
                    inputProps={NumberInputProps}
                    value={isNumber(to) ? to : ''}
                    onChange={handleTo}
                    disableUnderline
                    fullWidth
                    endAdornment={
                        <InputAdornment position="end">
                            {isNumber(to) ? (
                                <IconButton onClick={handleClearTo} size="small">
                                    <Icon color="secondary">close</Icon>
                                </IconButton>
                            ) : (
                                <Icon>search</Icon>
                            )}
                        </InputAdornment>
                    }
                />
            </ListItem>
        </>
    );
};
