import { LocationFragment } from '~/graphql';

import { hasKey } from '../../../../common/utils';

export type ILocation = Omit<LocationFragment, '__typename'>;

export type LatLng = {
    lat: number;
    lng: number;
};

export function isValidLocationType(l: unknown): l is ILocation {
    if (typeof l !== 'object') return false;
    if (l === null) return false;

    return (
        hasKey(l, 'lat') &&
        typeof l.lat === 'number' &&
        hasKey(l, 'lng') &&
        typeof l.lng === 'number' &&
        hasKey(l, 'text') &&
        typeof l.text === 'string'
    );
}

export const gqlTypeToLocation = (data: LocationFragment): ILocation => {
    return {
        lat: data.lat,
        lng: data.lng,
        text: data.text,
        type: data.type,
    };
};
