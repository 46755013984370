import React, { VFC } from 'react';

import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { useDumpTypeById } from '~/@store/dumpTypes';
import { DumpTypeByIdQuery } from '~/graphql';

import { DumpTypeForm } from './DumpTypeForm';

type DumpTypeEditBlockProps = {
    onClose: () => void;
    dumpTypeId: string;
};

export const DumpTypeEditBlock: VFC<DumpTypeEditBlockProps> = ({ onClose, dumpTypeId }) => {
    const dumpTypeQuery = useDumpTypeById(dumpTypeId);

    return (
        <QueryStateHandler query={dumpTypeQuery} dataGetter={query => query.data} progressSize="small">
            {({ dumpType }: DumpTypeByIdQuery) =>
                dumpType ? <DumpTypeForm onClose={onClose} dumpType={dumpType} /> : <></>
            }
        </QueryStateHandler>
    );
};
