import { formatString } from '@common/utils';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { CardDialog } from '~/@components/Dialog';
import { Divider } from '~/@components/Divider';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { showCustomDialog } from '~/@store/common';
import { DELIVERY_LINE_VIEW_MODE, deliveryLinesStore } from '~/@store/deliveryLines';
import { IDetailedDumpLoad } from '~/@store/dumpLoads';
import { useDetailedDumpLoadByNumber } from '~/@store/dumpLoads/dumpLoads.hooks/useDetailedDumpLoadByNumber';
import { LockEntityType, WithLock } from '~/@store/locks';
import i18n from '~/i18n';

import { DeliveryLinesMassEditFormContainer } from './DeliveryLinesMassEditFormContainer';
import { DumpLoadFieldsInfo } from './DumpLoadFieldsInfo';

type Props = {
    serialNumber: string;
    onClose: (v: boolean) => void;
};

const DeliveryLinesMassEditDialog = ({ serialNumber, onClose }: Props) => {
    const { divider, dialogContent } = useStyles();

    const query = useDetailedDumpLoadByNumber(serialNumber);

    useSyncEffect(() => {
        deliveryLinesStore.setOrderNumber(serialNumber);
        deliveryLinesStore.setMode(DELIVERY_LINE_VIEW_MODE.DELIVERY_LINES);
    }, [serialNumber]);

    useEffect(() => {
        return () => deliveryLinesStore.setOrderNumber(null);
    }, []);

    return (
        <CardDialog
            title={formatString(i18n.OrderDeliveryLinesEditDialog.title, serialNumber)}
            fullScreen
            className={dialogContent}>
            <WithLock entityType={LockEntityType.ORDER} entityId={serialNumber}>
                <QueryStateHandler query={query} dataGetter={query => query.data?.dumpLoadByNumber || undefined}>
                    {(dumpLoad: IDetailedDumpLoad) => (
                        <>
                            <DumpLoadFieldsInfo dumpLoad={dumpLoad} />
                            <Divider className={divider} />
                            <DeliveryLinesMassEditFormContainer dumpLoad={dumpLoad} onClose={onClose} />
                        </>
                    )}
                </QueryStateHandler>
            </WithLock>
        </CardDialog>
    );
};

export const showDeliveryLinesMassEditDialog = (serialNumber: string) =>
    showCustomDialog({
        render: closeDialog => <DeliveryLinesMassEditDialog serialNumber={serialNumber} onClose={closeDialog} />,
    });

const useStyles = makeStyles(theme => ({
    dialogContent: {
        padding: theme.spacing(1, 3),
    },
    divider: {
        marginTop: 0,
        marginBottom: theme.spacing(2),
    },
}));
