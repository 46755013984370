import { formatTonns } from '@common/utils/formaters';
import { isString } from 'lodash';
import React from 'react';

import { COLORS, makeStyles } from '~/@components/@theme';
import { ClickableText } from '~/@components/ClickableText';
import {
    DumpLoadModeFormatter,
    DumpLoadTypeWithContaminationLevelFormatter,
    NullableFormatter,
} from '~/@components/Formatters';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { getLandfillCostPerTonn, getLandfillPricePerTonn, IDetailedDumpLoad } from '~/@store/dumpLoads';
import { showDumpLoadCommentsDialog } from '~/@views/AdminView/DumpLoadsPage/DumpLoadCommentsView';
import i18n from '~/i18n';
import { fmtCurr } from '~/utils/numbers';
import { getUserFullName } from '~/utils/user';

type Props = {
    dumpLoad: IDetailedDumpLoad;
};

const CommonField = ({ label, value }: { label: React.ReactNode; value: React.ReactNode }) => {
    const { field, label: labelClassName } = useStyles();

    return (
        <div className={field}>
            <Text className={labelClassName}>{label}:</Text>
            {isString(value) ? <NullableFormatter>{value}</NullableFormatter> : value}
        </div>
    );
};

export const DumpLoadFieldsInfo = ({ dumpLoad }: Props) => {
    const { container } = useStyles();

    const showComments = () => showDumpLoadCommentsDialog(dumpLoad.project!.id, dumpLoad.id);

    return (
        <div className={container}>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <CommonField
                        label={<LocalizableText code={'OrderCard.projectName'} />}
                        value={dumpLoad.project?.name || ''}
                    />
                    <CommonField
                        label={<LocalizableText code={'OrderCard.dumpType'} />}
                        value={
                            <DumpLoadTypeWithContaminationLevelFormatter>
                                {dumpLoad}
                            </DumpLoadTypeWithContaminationLevelFormatter>
                        }
                    />
                    <CommonField
                        label={<LocalizableText code={'OrderCard.salesResponsible'} />}
                        value={dumpLoad.project?.owner ? getUserFullName(dumpLoad.project.owner) : ''}
                    />
                    <CommonField
                        label={<LocalizableText code={'OrderCard.lastComment'} />}
                        value={
                            <ClickableText onClick={showComments}>
                                {dumpLoad.lastCommentText || i18n.addComment}
                            </ClickableText>
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <CommonField
                        label={<LocalizableText code={'OrderCard.customer'} />}
                        value={dumpLoad.project?.customer?.name || ''}
                    />
                    <CommonField
                        label={<LocalizableText code={'OrderCard.landfillCostExt'} />}
                        value={fmtCurr(getLandfillPricePerTonn(dumpLoad))}
                    />
                    <CommonField
                        label={<LocalizableText code={'DumpLoadsTable.mode'} />}
                        value={<DumpLoadModeFormatter inline>{dumpLoad}</DumpLoadModeFormatter>}
                    />
                    <CommonField
                        label={<LocalizableText code={'OrderCard.plannedVolume'} />}
                        value={formatTonns(dumpLoad.amountInTons)}
                    />
                    <CommonField
                        label={<LocalizableText code={'OrderCard.deliveredVolume'} />}
                        value={formatTonns(dumpLoad.deliveredVolume)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CommonField
                        label={<LocalizableText code={'OrderCard.receiver'} />}
                        value={dumpLoad.landfill?.name || ''}
                    />
                    <CommonField
                        label={<LocalizableText code={'OrderCard.landfillCost'} />}
                        value={fmtCurr(getLandfillCostPerTonn(dumpLoad))}
                    />
                    <CommonField
                        label={<LocalizableText code={'OrderCard.FANumber'} />}
                        value={dumpLoad.FANumber || ''}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: theme.spacing(2),
    },
    field: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'baseline',
        marginBottom: theme.spacing(0.5),
    },
    label: {
        color: COLORS.grayDark,
    },
}));
