import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props {
    children: string | null | void;
    startText?: string;
}

export const TableCellPlaceholder = ({ startText = '', children }: Props) => {
    const { root } = useStyles();

    const content = children || '';

    return (
        <div className={root} title={content}>
            {startText && `${startText} `}
            {content}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.secondary,
        height: 20,
        overflow: 'hidden',
    },
}));
