import { TransferType } from '@common/enums';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { FC, useCallback } from 'react';

import { Button } from '~/@components/Button';
import { ButtonGroupSwitch } from '~/@components/ButtonGroupSwitch';
import { FormikContextNumberField, FormikContextTextField, FormikDateField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { IBatchTransfer, useBatchTransferCreate, useBatchTransferUpdate } from '~/@user-store/landfillBatches';
import { LandfillBatchSelect } from '~/@views/UserView/LandfillPage/common/LandfillBatchSelect';
import { IBatch } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm';
import {
    FormNames,
    IFormValues,
} from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm/LandfillBatchFormik.types';
import {
    FieldType,
    getFieldTypeForType,
} from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchTransferForm/LandfillBatchTransfer.helpers';
import { getLandfillBatchTransferFormikConfig } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchTransferForm/LandfillBatchTransferFormik';
import { BatchTransferInput, LandfillQuery_landfill } from '~/graphql';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';

type Props = {
    landfill: LandfillQuery_landfill;
    batchId: string | null;
    transfer: IBatchTransfer | null;
    onClose: () => void;
};

const LandfillBatchTransferForm: FC<Props> = ({ landfill, batchId, transfer, onClose }) => {
    const [create, isCreating] = useBatchTransferCreate(landfill.id, batchId);
    const [update, isUpdating] = useBatchTransferUpdate(landfill.id, batchId);

    const onCreate = useCallback(
        async (input: BatchTransferInput) => {
            if (transfer) return;

            create(input).then(onClose);
        },
        [transfer, create, onClose]
    );

    const onUpdate = useCallback(
        async (input: BatchTransferInput) => {
            if (!transfer) return;

            update(transfer.id, input).then(onClose);
        },
        [transfer, update, onClose]
    );

    const formik = useFormik(getLandfillBatchTransferFormikConfig(transfer, transfer ? onUpdate : onCreate));
    const { setFieldValue, values, errors, touched } = formik;

    const { header, buttons } = useStyles();

    const disabled = isCreating || isUpdating;

    const type = values.type;

    return (
        <DialogContent>
            <FormikProvider value={formik}>
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className={header}>
                                <Text variant="TitleBold">
                                    <LocalizableText
                                        code={
                                            transfer
                                                ? 'LandfillPage.transferCard.editTransfer'
                                                : 'LandfillPage.transferCard.createTransfer'
                                        }
                                    />
                                </Text>
                                <ButtonGroupSwitch
                                    options={[
                                        {
                                            value: TransferType.INTERNAL,
                                            label: <LocalizableText code={'LandfillPage.transferCard.INTERNAL'} />,
                                        },
                                        {
                                            value: TransferType.INCOMING,
                                            label: <LocalizableText code={'LandfillPage.transferCard.INCOMING'} />,
                                        },
                                        {
                                            value: TransferType.OUTGOING,
                                            label: <LocalizableText code={'LandfillPage.transferCard.OUTGOING'} />,
                                        },
                                    ]}
                                    value={values.type}
                                    onChange={v => setFieldValue(FormNames.type, v)}
                                    color="primary"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <FormikDateField
                                formik={formik}
                                name={FormNames.date}
                                label={<LocalizableText code={'LandfillPage.transferCard.date'} />}
                                disabled={disabled || getFieldTypeForType(type, FormNames.date) === FieldType.DISABLED}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikContextNumberField<IFormValues, FormNames.weight>
                                name={FormNames.weight}
                                label={<LocalizableText code={'LandfillPage.transferCard.weight'} />}
                                disabled={
                                    disabled || getFieldTypeForType(type, FormNames.weight) === FieldType.DISABLED
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikContextTextField<IFormValues, FormNames.comment>
                                name={FormNames.comment}
                                label={<LocalizableText code={'LandfillPage.transferCard.comment'} />}
                                disabled={
                                    disabled || getFieldTypeForType(type, FormNames.comment) === FieldType.DISABLED
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LandfillBatchSelect
                                landfill={landfill}
                                selected={values.fromBatch}
                                onChange={v => setFieldValue(FormNames.fromBatch, v)}
                                label={<LocalizableText code={'LandfillPage.transferCard.fromBatch'} />}
                                errorMessage={touched[FormNames.fromBatch] ? errors[FormNames.fromBatch] : undefined}
                                disabled={
                                    disabled || getFieldTypeForType(type, FormNames.fromBatch) === FieldType.DISABLED
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LandfillBatchSelect
                                landfill={landfill}
                                selected={values.toBatch}
                                onChange={v => setFieldValue(FormNames.toBatch, v)}
                                label={<LocalizableText code={'LandfillPage.transferCard.toBatch'} />}
                                errorMessage={touched[FormNames.toBatch] ? errors[FormNames.toBatch] : undefined}
                                disabled={
                                    disabled || getFieldTypeForType(type, FormNames.toBatch) === FieldType.DISABLED
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikContextTextField<IFormValues, FormNames.externalProjectName>
                                name={FormNames.externalProjectName}
                                label={<LocalizableText code={'LandfillPage.transferCard.projectName'} />}
                                disabled={
                                    disabled ||
                                    getFieldTypeForType(type, FormNames.externalProjectName) === FieldType.DISABLED
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikContextTextField<IFormValues, FormNames.externalProjectAddress>
                                name={FormNames.externalProjectAddress}
                                label={<LocalizableText code={'LandfillPage.transferCard.projectAddress'} />}
                                disabled={
                                    disabled ||
                                    getFieldTypeForType(type, FormNames.externalProjectAddress) === FieldType.DISABLED
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikContextTextField<IFormValues, FormNames.licensePlate>
                                name={FormNames.licensePlate}
                                label={<LocalizableText code={'LandfillPage.transferCard.licensePlate'} />}
                                disabled={
                                    disabled || getFieldTypeForType(type, FormNames.licensePlate) === FieldType.DISABLED
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={buttons}>
                                <Button variant="outlined" onClick={onClose}>
                                    {i18n.cancel}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={formik.isSubmitting || !formik.dirty}>
                                    {i18n.save}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </DialogContent>
    );
};

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'normal',
            gap: theme.spacing(1),
        },
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));

export const showLandfillBatchTransferForm = (
    landfill: LandfillQuery_landfill,
    batch: IBatch | null,
    transfer: IBatchTransfer | null
) =>
    showCustomDialog({
        render: closeDialog => (
            <LandfillBatchTransferForm
                landfill={landfill}
                batchId={batch?.id || null}
                transfer={transfer}
                onClose={closeDialog}
            />
        ),
    });
