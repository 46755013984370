import {
    add,
    differenceInDays,
    differenceInMonths,
    differenceInSeconds,
    differenceInWeeks,
    differenceInYears,
    endOfDay,
    endOfISOWeek,
    endOfMonth,
    endOfSecond,
    endOfWeek,
    endOfYear,
    isBefore,
    isEqual,
    isValid,
    max,
    min,
    startOfDay,
    startOfISOWeek,
    startOfMonth,
    startOfSecond,
    startOfWeek,
    startOfYear,
} from 'date-fns';

import { Fuel, IGranularity } from '../enums';
import { VehicleType } from '../types';
import { isNumber } from '../validations/types.validation';

type IOrder = 1 | -1;

export const compareStrings = (order: IOrder) => (a: string, b: string) => {
    if (a < b) return -1 * order;
    if (a > b) return 1 * order;

    return 0;
};

export const getStartOfGranularity = (date: Date, granularity: IGranularity): Date => {
    switch (granularity) {
        case 'day':
            return startOfDay(date);
        case 'isoWeek':
            return startOfISOWeek(date);
        case 'month':
            return startOfMonth(date);
        case 'second':
            return startOfSecond(date);
        case 'week':
            return startOfWeek(date);
        case 'year':
            return startOfYear(date);
        default:
            return date;
    }
};

export const getEndOfGranularity = (date: Date, granularity: IGranularity): Date => {
    switch (granularity) {
        case 'day':
            return endOfDay(date);
        case 'isoWeek':
            return endOfISOWeek(date);
        case 'month':
            return endOfMonth(date);
        case 'second':
            return endOfSecond(date);
        case 'week':
            return endOfWeek(date);
        case 'year':
            return endOfYear(date);
        default:
            return date;
    }
};

export const getDiffWithGranularity = (dateLeft: Date, dateRight: Date, granularity: IGranularity): number => {
    switch (granularity) {
        case 'day':
            return differenceInDays(dateLeft, dateRight);
        case 'isoWeek':
            return differenceInWeeks(dateLeft, dateRight);
        case 'month':
            return differenceInMonths(dateLeft, dateRight);
        case 'second':
            return differenceInSeconds(dateLeft, dateRight);
        case 'week':
            return differenceInWeeks(dateLeft, dateRight);
        case 'year':
            return differenceInYears(dateLeft, dateRight);
        default:
            return 0;
    }
};

export const addGranularity = (date: Date, amount: number, granularity: IGranularity): Date => {
    switch (granularity) {
        case 'day':
            return add(date, { days: amount });
        case 'isoWeek':
            return add(date, { weeks: amount });
        case 'month':
            return add(date, { months: amount });
        case 'second':
            return add(date, { seconds: amount });
        case 'week':
            return add(date, { weeks: amount });
        case 'year':
            return add(date, { years: amount });
        default:
            return date;
    }
};

export function isValidDates(dates: Date[]): boolean {
    return dates.every(m => isValid(m));
}

export function getMinDate(dates: Date[]): Date {
    if (dates.length === 0) throw new RangeError('getMinDate: could not get min date from empty array');
    if (!isValidDates(dates)) throw new TypeError('getMinDate: could not get min date from invalid dates');

    return min(dates);
}

export function getMaxDate(dates: Date[]): Date {
    if (dates.length === 0) throw new RangeError('getMinDate: could not get max date from empty array');
    if (!isValidDates(dates)) throw new TypeError('getMaxDate: could not get max date from invalid dates');

    return max(dates);
}

/** Returns tuple of [min,max] date for given NON EMPTY array of Dates <br/>
 *
 *  Fail on empty array with RangeError <br/>
 *  Fail on invalid dates with TypeError
 *
 * @param dates
 * @returns {[Date, Date]}
 */
export function getBoundaryDates(dates: Date[]): [Date, Date] {
    return [getMinDate(dates), getMaxDate(dates)];
}

/** Generate array of Dates for each granularity in range of from-to.
 *
 *  If from === to, returns array of one element.
 *
 *  Fail on invalid dates with TypeError
 *
 * @param from
 * @param to
 * @param granularity
 * @returns {Array<Date>}
 */
export function getEachGranularity(from: Date, to: Date, granularity: IGranularity): Array<Date> {
    if (!isValid(from) || !isValid(to))
        throw new TypeError(`getEachGranularity: can not generate periods for invalid dates: ${from}, ${to}`);
    const [first, last] = getBoundaryDates([from, to]);
    let current = getStartOfGranularity(first, granularity);
    const resultDates: Array<Date> = [];

    while (isEqual(current, last) || isBefore(current, last)) {
        resultDates.push(current);
        current = addGranularity(current, 1, granularity);
    }

    return resultDates;
}

type IKeyExtractor<T> = (a: T) => string;
type IConcatFunc<T> = (a: T, b: T) => T;
type IMergeFunc<T> = (as: T[], bs: T[]) => T[];

/** Merge second array to first array using keyExtractor function to get matching key, and concat function to concat two values.
 *
 *  Result array is sorted by key.
 *
 * @param keyExtractor { T -> string }
 * @param concat { T, T -> T }
 * @param skipMissedKeys boolean Ignore values from sourceArr, which is not found in targetArr
 * @returns {function(Array<T>, Array<T>): Array<T>}
 */
export const mergeValuesByKey =
    <T>(keyExtractor: IKeyExtractor<T>, concat: IConcatFunc<T>, skipMissedKeys: boolean = false): IMergeFunc<T> =>
    (targetArr: Array<T>, sourceArr: Array<T>): Array<T> => {
        const keyMap: Map<string, T> = targetArr.reduce((map: Map<string, T>, a) => {
            map.set(keyExtractor(a), a);

            return map;
        }, new Map<string, T>());

        sourceArr.forEach(source => {
            const key = keyExtractor(source);
            const target = keyMap.get(key);

            if (!target) {
                if (!skipMissedKeys) keyMap.set(key, source);
            } else {
                keyMap.set(key, concat(target, source));
            }
        });

        return (Array.from(keyMap.entries()) as Array<[string, T]>)
            .sort(([keyA, _], [keyB, __]) => compareStrings(1)(keyA, keyB))
            .map(([_, value]) => value);
    };

export const getVersion = (branchName: string, commmitHash: string) => {
    if (!branchName) return 'dev.version';

    const pattern = /1[\.\-]00[\.\-]([0-9]{6})/;
    const match = pattern.exec(branchName);
    if (match) {
        return `1.00.${match[1]}`;
    } else {
        return `${branchName} ${commmitHash}`;
    }
};

export function toKm(meters: number): number;

export function toKm(meters: number | null | undefined): number | null;

export function toKm(meters: number | null | undefined) {
    return isNumber(meters) ? meters / 1000 : null;
}

export const roundUpThousand = (price: number): number => Math.ceil(price / 1000) * 1000;

export const getCO2Consumption = (
    vehicleType: Pick<VehicleType, 'fuelConsumptions'>,
    fuel: Fuel,
    amount: number
): number | null => {
    const consumptionPerUnit = vehicleType.fuelConsumptions.find(f => f.fuel === fuel)?.value;

    if (!consumptionPerUnit) return null;

    return consumptionPerUnit * amount;
};
