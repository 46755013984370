import { FormikContextType } from 'formik';
import React, { useCallback } from 'react';

import { FileUpload, IFileUploadProps } from '../FileUpload';

type FormikFileUploadFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends File | null ? TFieldName : never;
    formik: FormikContextType<TValues>;
} & Omit<IFileUploadProps, 'value' | 'onChange'>;

export function FormikFileUploadField<TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    disabled,
    helperText,
    ...restProps
}: FormikFileUploadFieldProps<TValues, TFieldName>) {
    const handleChange = useCallback(
        (value: File) => {
            formik.setFieldTouched(name, true);
            formik.setFieldValue(name, value);
        },
        [formik, name]
    );

    const value = formik.values[name];

    return (
        <FileUpload
            fullWidth
            variant="outlined"
            name={name}
            value={value as File}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[name] && !!formik.errors[name]}
            helperText={(formik.touched[name] && formik.errors[name]) || helperText}
            disabled={disabled || formik.isSubmitting}
            {...restProps}
        />
    );
}
