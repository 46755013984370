import { useMutation } from '@apollo/client';

import { RemoveAllLocksMutation } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from './locks.queries';

export const useRemoveAllLocksMutation = () => {
    const [callMutation] = useMutation<RemoveAllLocksMutation>(queries.RemoveAllLocksMutation);

    return () =>
        callMutation()
            .then(() => undefined)
            .catch(handleApolloError);
};
