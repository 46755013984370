import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { DumpTypeSelectQuery as IDumpTypeSelectQuery, DumpTypeSelectQuery_dumpTypes } from '~/graphql';

import { DumpTypeSelectQuery } from '../dumpType.queries';

export type IDumpTypeSelect = DumpTypeSelectQuery_dumpTypes;

export const useDumpTypeSelect = (): QueryResult<IDumpTypeSelectQuery, {}> => {
    return useQuery<IDumpTypeSelectQuery, {}>(DumpTypeSelectQuery);
};
