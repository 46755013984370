import { FilterRange } from '@common/types';
import { ListItem } from '@material-ui/core';
import { InputProps } from '@material-ui/core/Input/Input';
import { add, isAfter, isBefore, sub } from 'date-fns';
import React, { useCallback, useState } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { DatePicker } from '~/@components/DatePicker';
import i18n from '~/i18n';
import { isValidDate } from '~/utils/date';

type Props = {
    valueGetter: () => FilterRange<Date> | null | undefined;
    setValue: (v: FilterRange<Date> | null) => void;
    maxIntervalInMonths?: number;
};

const KeyboardInputProps: Partial<InputProps> = { disableUnderline: true, fullWidth: true };

export const DateFilter = ({ valueGetter, setValue, maxIntervalInMonths }: Props) => {
    const [from, to] = useObservable(valueGetter) || [null, null];

    const [inputFrom, setInputFrom] = useState(from);
    const [inputTo, setInputTo] = useState(to);

    const handleFrom = useCallback(
        (v: Date | null) => {
            setInputFrom(v);
            const fromValue = isValidDate(v) ? v : null;

            if (!fromValue) return;

            let toValue = to || null;
            const bound = add(fromValue, { months: maxIntervalInMonths });

            if (toValue && maxIntervalInMonths && isAfter(toValue, bound)) {
                toValue = bound;
                setInputTo(bound);
            }

            setValue([fromValue, toValue]);
        },
        [setValue, to, maxIntervalInMonths]
    );

    const handleTo = useCallback(
        (v: Date | null) => {
            setInputTo(v);
            const toValue = isValidDate(v) ? v : null;

            if (!toValue) return;

            let fromValue = from || null;
            const bound = sub(toValue, { months: maxIntervalInMonths });

            if (fromValue && maxIntervalInMonths && isBefore(fromValue, bound)) {
                fromValue = bound;
                setInputFrom(bound);
            }

            setValue([fromValue, toValue]);
        },
        [setValue, from, maxIntervalInMonths]
    );

    const required = !!maxIntervalInMonths;

    return (
        <>
            <ListItem divider>
                <DatePicker
                    fullWidth
                    clearable={!required}
                    autoOk
                    inputVariant="standard"
                    label={i18n.from}
                    maxDate={to}
                    value={inputFrom || null}
                    onChange={handleFrom}
                    onBlur={() => setInputFrom(from)}
                    InputProps={KeyboardInputProps}
                    required={required}
                />
            </ListItem>
            <ListItem>
                <DatePicker
                    fullWidth
                    clearable={!required}
                    autoOk
                    inputVariant="standard"
                    label={i18n.to}
                    minDate={from}
                    value={inputTo || null}
                    onChange={handleTo}
                    onBlur={() => setInputTo(to)}
                    InputProps={KeyboardInputProps}
                    required={required}
                />
            </ListItem>
        </>
    );
};
