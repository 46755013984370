import { getDefaultWorkTime } from '@common/constants';
import { LandfillFields } from '@common/enums';
import { replaceRouteParams, routes } from '@common/routes';
import { validateWorkTime } from '@common/validations/common.validation';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import * as Yup from 'yup';

import { ICompanySearchRecord } from '~/@store/companies';
import { gqlTypeToLocation, ILocation } from '~/@store/locations';
import { homePageMapStore } from '~/@user-store/homePageMap';
import { MAX_PAYLOAD_SIZE } from '~/config/constants';
import type { WithAbilitiesProps } from '~/contexts';
import { withAbilities } from '~/contexts';
import type { LandfillQuery_landfill } from '~/graphql';
import { LocationType } from '~/graphql';
import { globalMessage } from '~/services/message';
import { canChangeLandfillField, canReadLandfillField, ILandfillAbilityField } from '~/utils/auth';
import { createLandfill, ILandfillInput, updateLandfill } from '~/utils/landfill';
import { IUserInfo } from '~/utils/user';

import i18n from '../../../../../i18n';
import type { IWorkTimeDay } from './LandfillWorkTime/WorkTimeSelect';

export type LandfillFormValues = {
    name: string;
    comment: string;
    description: string;
    status: { id: string; name: string; icon: string } | null;
    location: ILocation;
    workTime: IWorkTimeDay[];
    gateFee: number;
    minMargin: number;
    marginPercent: number;
    receivers: IUserInfo[] | null;
    certificates: File[];
    customer?: ICompanySearchRecord | null;
    isLoading: boolean;
    capacity: number;
};

export type LandfillFormExternalProps = {
    landfill?: LandfillQuery_landfill | null | undefined;
    children?: React.ReactNode | null | undefined;
    onBack?: (() => void) | null | undefined;
};

export type LandfillFormProps = RouteComponentProps &
    LandfillFormExternalProps &
    FormikProps<LandfillFormValues> &
    WithAbilitiesProps;

export enum FormNames {
    name = 'name',
    comment = 'comment',
    description = 'description',
    status = 'status',
    location = 'location',
    workTime = 'workTime',
    gateFee = 'gateFee',
    minMargin = 'minMargin',
    marginPercent = 'marginPercent',
    receivers = 'receivers',
    certificates = 'certificates',
    customer = 'customer',
    capacity = 'capacity',
}

export type IFormNames = typeof FormNames[keyof typeof FormNames];

const formikHOC = withFormik<LandfillFormProps, LandfillFormValues>({
    mapPropsToValues: ({ landfill }) => {
        return {
            name: landfill?.name || '',
            comment: landfill?.comment || '',
            description: landfill?.description || '',
            minMargin: landfill?.minMargin || 0,
            marginPercent: landfill?.marginPercent || 0,
            gateFee: landfill?.gateFee || 0,
            location: landfill?.location
                ? gqlTypeToLocation(landfill.location)
                : homePageMapStore.selectedPlacePojo || homePageMapStore.defaultLocation,
            status: landfill?.status || null,
            workTime:
                landfill?.workTime.map(({ dayNumber, openFrom, openTo, isOpen }) => ({
                    dayNumber,
                    openFrom,
                    openTo,
                    isOpen,
                })) || getDefaultWorkTime(),
            receivers: landfill?.receivers
                ? landfill?.receivers.map(({ id, name = '', surname = '' }) => ({
                      id,
                      name,
                      surname,
                  }))
                : null,
            certificates: [],
            customer: landfill?.customer || undefined,
            isLoading: false,
            capacity: landfill?.capacity || 0,
        };
    },

    validationSchema: (props: LandfillFormProps) => {
        return Yup.object().shape({
            name: Yup.string().required(i18n.errorCodes.LANDFILL_NAME_CANNOT_BE_EMPTY),
            location: Yup.object<LandfillFormValues['location']>().test('Location validation', '', function (location) {
                if (!location || !location.lat || !location.lng || !location.text || !location.type)
                    return this.createError({
                        message: i18n.errorCodes.LANDFILL_LOCATION_CANNOT_BE_EMPTY,
                        path: 'location',
                    });

                if (location.type === LocationType.UNKNOWN) {
                    return this.createError({
                        message: i18n.errorCodes.LOCATION_NOT_GEOCODED,
                        path: 'location',
                    });
                }

                if (location.type === LocationType.ON_WATER) {
                    return this.createError({
                        message: i18n.errorCodes.LANDFILL_LOCATION_ON_WATER,
                        path: 'location',
                    });
                }

                if (location.type === LocationType.UNREACHABLE) {
                    return this.createError({
                        message: i18n.errorCodes.LOCATION_UNREACHABLE,
                        path: 'location',
                    });
                }

                return true;
            }),
            workTime: Yup.array().test('WorkTime validation', '', function (workTimeObject) {
                if (!workTimeObject) return true;

                const workTime = workTimeObject as IWorkTimeDay[];
                const errorCode = validateWorkTime([...workTime]);

                if (errorCode) {
                    return this.createError({ message: i18n.errorCodes[errorCode], path: 'workTime' });
                }

                return true;
            }),
            certificates: Yup.array().test(
                'Certificates size validation',
                i18n.errorCodes.FILE_TOO_LARGE,
                function (certificatesObject) {
                    return (
                        ((certificatesObject || []) as File[]).reduce((size, file) => size + file.size, 0) <
                        MAX_PAYLOAD_SIZE
                    );
                }
            ),
            [FormNames.customer]: Yup.object<ICompanySearchRecord>()
                .nullable()
                .test('Customer validation', i18n.errorCodes.LANDFILL_CUSTOMER_REQUIRED, function (value) {
                    const { user, ability, landfill } = props;
                    if (!canReadLandfillField(user, ability, landfill, LandfillFields.customer)) return true;
                    if (!canChangeLandfillField(user, ability, landfill, LandfillFields.customerId)) return true;

                    return !!value;
                }),
        });
    },

    handleSubmit: async (values, { props }) => {
        const { landfill, ability, history, user } = props;

        const {
            name,
            comment,
            description,
            status,
            location,
            workTime,
            gateFee,
            minMargin,
            marginPercent,
            receivers,
            certificates,
            customer,
            capacity,
        } = values;

        const input: ILandfillInput = {
            name,
            comment,
            description,
            workTime,
        };

        if (certificates.length > 0) input.certificates = [...certificates];

        const canChangeField = (field: ILandfillAbilityField) => canChangeLandfillField(user, ability, landfill, field);

        if (canChangeField(LandfillFields.status)) input.status = status ? status.id : null;
        if (canChangeField(LandfillFields.minMargin)) input.minMargin = minMargin;
        if (canChangeField(LandfillFields.marginPercent)) input.marginPercent = marginPercent;
        if (canChangeField(LandfillFields.gateFee)) input.gateFee = gateFee;
        if (canChangeField(LandfillFields.capacity)) input.capacity = capacity;

        if (canChangeField(LandfillFields.customerId)) input.customerId = customer?.id;
        if (canChangeField(LandfillFields.receiverIds))
            input.receiverIds = receivers ? receivers.map(({ id }) => id) : [];

        if (!landfill || canChangeField(LandfillFields.location)) input.location = location;

        if (landfill) {
            input.id = landfill.id;

            return updateLandfill(user, ability, landfill, input).then(() => {
                globalMessage.success(i18n.LandfillForm.landfillUpdatedSuccessfully);
            });
        } else {
            return createLandfill(user, ability, input).then(landfill => {
                homePageMapStore.setCreationMode(null);
                history.push(replaceRouteParams(routes.sochi.landfillInfo, { landfillId: landfill.id }));
            });
        }
    },
});

export const landfillFormikHOC = compose<LandfillFormProps, LandfillFormExternalProps>(
    withRouter,
    withAbilities,
    formikHOC
);
