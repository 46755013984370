import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/substances/substances.queries';
import { ContaminationType, SubstanceCreateMutation, SubstanceCreateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

type SubstanceCreateInput = {
    name: string;
    contaminationTypes: Array<ContaminationType>;
    parentId: string | null;
};

export type SubstanceCreateFunc = (input: SubstanceCreateInput) => Promise<unknown>;

export const useSubstanceCreate = (): [SubstanceCreateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<SubstanceCreateMutation, SubstanceCreateMutationVariables>(
        queries.SubstanceCreateMutation,
        {
            refetchQueries: [{ query: queries.SubstancesListQuery }],
        }
    );

    const createSubstance = useCallback<SubstanceCreateFunc>(
        ({ name, contaminationTypes, parentId }) => {
            const variables: SubstanceCreateMutationVariables = parentId
                ? { name, parentId }
                : { name, contaminationTypes };

            return callMutation({ variables }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [createSubstance, mutationResult.loading];
};
