import LoaderStore from '../../stores/loaderStore';

export function handleHourglasses(flag: boolean) {
    LoaderStore._setLoading(flag);
}

export function startLoading() {
    LoaderStore._setLoading(true);
}

export function stopLoading() {
    LoaderStore._setLoading(false);
}

export function handleLoadingPromise<T>(p: Promise<T>): Promise<T> {
    startLoading();
    p.finally(stopLoading);

    return p;
}

//Debug and testing purpose
if (window.location.href.includes('localhost')) {
    // @ts-ignore Global variable for testing purposes
    window.test_loader = { startLoading, stopLoading };
}
