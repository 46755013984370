import { useMutation } from '@apollo/client';

import { CustomScriptRemoveMutation, CustomScriptRemoveMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../settings.queries';

export const useCustomScriptRemoveMutation = () => {
    const [callMutation, mutationResult] = useMutation<CustomScriptRemoveMutation, CustomScriptRemoveMutationVariables>(
        queries.CustomScriptRemoveMutation
    );

    const removeCustomScript = (id: string) =>
        callMutation({ variables: { id }, refetchQueries: [{ query: queries.CustomScriptsQuery }] }).catch(
            handleApolloError
        );

    return [removeCustomScript, mutationResult.loading] as const;
};
