import { gql } from '@apollo/client';

export const RecalculateProjectSolidTestResultsMutation = gql`
    mutation RecalculateProjectSolidTestResults {
        recalculateProjectSolidTestResults {
            processed
            modified
        }
    }
`;

export const RecalculateLandfillSolidTestResultsMutation = gql`
    mutation RecalculateLandfillSolidTestResults {
        recalculateLandfillSolidTestResults {
            processed
            modified
        }
    }
`;
