import './SuccessOfferDialog.scss';

import React, { useCallback, useMemo, VFC } from 'react';

import { Button } from '~/@components/Button';
import { DumpLoadTypeWithContaminationLevelFormatter, TonnsFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { ISochiTableColumn, SochiTable } from '~/@sochi-components/SochiTable';

import { DumpLoadsUpdateStatusMutation_projectLoadsUpdateStatus_dumpLoads as IUpdatesDumpLoad } from '../../../../../graphql';

type SuccessOfferDialogProps = {
    closeDialog: () => void;
    goToOffersPage: () => void;
    dumpLoads: IUpdatesDumpLoad[];
};

export const SuccessOfferDialog: VFC<SuccessOfferDialogProps> = ({ dumpLoads, closeDialog, goToOffersPage }) => {
    const { className, element } = useFunctionalBem(SuccessOfferDialog);

    const goToOffersPageClickHandler = useCallback(() => {
        closeDialog();
        goToOffersPage();
    }, [closeDialog, goToOffersPage]);

    const columnsConfig = useMemo<ISochiTableColumn<IUpdatesDumpLoad>[]>(
        () => [
            {
                title: <LocalizableText code={'priceCalculatorPage.massType'} />,
                render: data => (
                    <DumpLoadTypeWithContaminationLevelFormatter>{data}</DumpLoadTypeWithContaminationLevelFormatter>
                ),
                alignLeft: true,
            },
            {
                title: <LocalizableText code={'priceCalculatorPage.description'} />,
                render: data => data.comment || '',
                cellClassName: element('comment-column'),
                alignLeft: true,
            },
            {
                title: <LocalizableText code={'priceCalculatorPage.weight'} />,
                render: data => <TonnsFormatter>{data.amountInTons}</TonnsFormatter>,
            },
        ],
        [element]
    );

    return (
        <DialogContent className={className}>
            <Text className={element('subtitle')} color="textSecondary" paragraph>
                <LocalizableText code={'offerModalNote'} />
            </Text>
            <SochiTable
                className={element('table')}
                items={dumpLoads}
                columns={columnsConfig}
                keyGetter={({ id }) => id}
            />
            <ButtonGroup>
                <Button color="primary" variant="outlined" size="large" onClick={goToOffersPageClickHandler}>
                    <LocalizableText code={'viewOffers'} />
                </Button>
                <Button color="primary" variant="contained" size="large" onClick={closeDialog}>
                    <LocalizableText code={'toProjectPage'} />
                </Button>
            </ButtonGroup>
        </DialogContent>
    );
};
