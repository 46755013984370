import { normalizeAmount } from '@common/UnitOfMeasure';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { COLORS } from '~/@components/@theme/colors';
import { Checkbox } from '~/@components/Checkbox';
import { LocalizableText } from '~/@components/LocalizableText';
import { NumberField } from '~/@components/NumberField';
import { Section } from '~/@components/Section';
import { ChangeStatusFunc, useDumpLoadAbilities } from '~/@store/dumpLoads';
import { commonPrecisions, UnitOfMeasure, Units } from '~/config/enum';
import i18n from '~/i18n';

import { DumpLoadEditChangeStatus } from './DumpLoadEditChangeStatus';
import { useDumpLoadFormikContext } from './DumpLoadEditFormik';

type Props = {
    updateStatus: ChangeStatusFunc;
};

export const DumpLoadEditPricesAndActions = ({ updateStatus }: Props) => {
    const formik = useDumpLoadFormikContext();
    const dumpLoad = formik.values.dumpLoad;
    const { root, section, numberFieldClassName } = useStyles();

    const { canUpdateDumpLoad, canUpdatePrices } = useDumpLoadAbilities(dumpLoad);

    const amountInTons =
        formik.values.unitOfMeasure === UnitOfMeasure.CUBIC_METERS
            ? normalizeAmount(
                  Units.CUBIC_METERS,
                  Units.TONNS,
                  formik.values.amount,
                  formik.values.dumpType?.tonnsPerCubicM || 1
              )
            : formik.values.amount;

    const disabled = !canUpdateDumpLoad;
    const pricesDisabled = !canUpdatePrices || formik.values.forceZeroPrices || amountInTons === 0;

    const landfillPricePerTon = amountInTons === 0 ? 0 : (formik.values.landfillPrice || 0) / amountInTons;
    const internalLandfillPricePerTon =
        amountInTons === 0 ? 0 : (formik.values.internalLandfillPrice || 0) / amountInTons;

    const handleLandfillPricePerTonChange = (value: number) => {
        formik.setFieldValue('landfillPrice', value * amountInTons);
    };

    const handleInternalLandfillPricePerTonChange = (value: number) => {
        formik.setFieldValue('internalLandfillPrice', value * amountInTons);
    };

    const handleZeroPricesChange = () => {
        const value = !formik.values['forceZeroPrices'];
        formik.setFieldValue('forceZeroPrices', value);

        if (value) {
            formik.setFieldValue('landfillPrice', 0, true);
            formik.setFieldValue('internalLandfillPrice', 0, true);
        }
    };

    return (
        <Section className={root}>
            <div className={section}>
                <NumberField
                    value={internalLandfillPricePerTon}
                    onChange={handleInternalLandfillPricePerTonChange}
                    precision={commonPrecisions.price}
                    className={numberFieldClassName}
                    label={<LocalizableText code={'DumploadPrice.internalLandfillPricePerTon'} />}
                    disabled={pricesDisabled}
                />
                <NumberField
                    value={landfillPricePerTon}
                    onChange={handleLandfillPricePerTonChange}
                    precision={commonPrecisions.price}
                    className={numberFieldClassName}
                    label={<LocalizableText code={'DumploadPrice.landfillPricePerTon'} />}
                    disabled={pricesDisabled}
                />
                <Checkbox
                    checked={formik.values['forceZeroPrices']}
                    label={<LocalizableText code={'DumploadPrice.forceZeroPrices'} />}
                    onChange={handleZeroPricesChange}
                    disabled={!canUpdatePrices}
                    fullWidth
                />
            </div>
            <div className={section}>
                {!formik.dirty && <DumpLoadEditChangeStatus updateStatus={updateStatus} />}
                {formik.dirty && (
                    <>
                        <Button
                            disabled={formik.isSubmitting}
                            onClick={formik.handleReset}
                            variant="contained"
                            color="default">
                            {i18n.cancel}
                        </Button>
                        <Button
                            disabled={formik.isSubmitting || disabled}
                            onClick={formik.submitForm}
                            variant="contained"
                            color="primary">
                            {i18n.save}
                        </Button>
                    </>
                )}
            </div>
        </Section>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        height: 90,
        display: 'flex',
        gap: theme.spacing(2),
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    section: {
        display: 'flex',
        gap: theme.spacing(2),
        flexDirection: 'row',
        alignItems: 'center',
    },
    numberFieldClassName: {
        minWidth: '250px',
        backgroundColor: COLORS.white,
    },
}));
