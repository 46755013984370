import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import cn from 'classnames';
import isString from 'lodash/isString';
import React, { ReactElement, ReactNode, useMemo } from 'react';

import i18n from '~/i18n';

export type ISochiTableColumn<TRow> = {
    title?: React.ReactNode;
    render: (row: TRow) => ReactNode;
    cellClassName?: string;
    cellComponent?: React.FC<TableCellProps>;
    hidden?: boolean;
    alignLeft?: boolean;
};

export interface ISochiTableProps<TRow> {
    columns: ISochiTableColumn<TRow>[];
    items: TRow[];
    keyGetter: (r: TRow) => string;
    className?: string;
    paperClassName?: string;
    emptyListMessage?: ReactNode;
    stickyFirstColumn?: boolean;
    dense?: boolean;
}

export const SochiTable = <TRow extends unknown>({
    columns,
    items,
    keyGetter,
    className,
    paperClassName,
    emptyListMessage,
    stickyFirstColumn,
    dense,
}: ISochiTableProps<TRow>): ReactElement => {
    const visibleColumns = useMemo(() => columns.filter(c => !c.hidden), [columns]);

    const { sticky, stickyCell, stickyHead, alignLeft, emptyListMsg, breakWordHead, denseCell } = useStyles();

    return (
        <Paper variant="outlined" className={paperClassName}>
            <TableContainer className={className}>
                <MuiTable stickyHeader aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {visibleColumns.map((column, index) => {
                                const isTh = index === 0 && stickyFirstColumn;

                                return (
                                    <TableCell
                                        key={isString(column.title) ? column.title : index}
                                        className={cn(
                                            {
                                                [stickyHead]: isTh,
                                                [sticky]: isTh,
                                                [alignLeft]: isTh || column.alignLeft,
                                                [denseCell]: dense,
                                            },
                                            breakWordHead
                                        )}>
                                        {column.title || ''}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.length === 0 && (
                            <TableRow>
                                <TableCell className={alignLeft} colSpan={visibleColumns.length}>
                                    <Typography className={emptyListMsg} variant="body1">
                                        {emptyListMessage || i18n.emptyList}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {items.map(row => (
                            <TableRow key={keyGetter(row)}>
                                {visibleColumns.map((column, index) => {
                                    const isTh = index === 0 && stickyFirstColumn;

                                    const content = column.render(row);

                                    const cellProps: TableCellProps & { key: string } = {
                                        key: `${keyGetter(row)}-${index}`,
                                        component: isTh ? 'th' : undefined,
                                        className: cn(
                                            {
                                                [stickyCell]: isTh,
                                                [sticky]: isTh,
                                                [alignLeft]: isTh || column.alignLeft,
                                                [denseCell]: dense,
                                            },
                                            column.cellClassName
                                        ),
                                        scope: isTh ? 'row' : undefined,
                                        title: isString(content) ? content : undefined,
                                    };

                                    const CellComponent = column.cellComponent || TableCell;

                                    return <CellComponent {...cellProps}>{content}</CellComponent>;
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </Paper>
    );
};

const useStyles = makeStyles(theme => ({
    sticky: {
        position: 'sticky',
        left: 0,

        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        [theme.breakpoints.down('md')]: {
            borderRight: `1px solid ${theme.palette.tableBorder} !important`,
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: 145,
        },

        '& .MuiFormControlLabel-root': {
            marginRight: 0,
        },
    },
    stickyHead: {
        zIndex: 3,
    },
    stickyCell: {
        zIndex: 2,
    },
    breakWordHead: {
        wordWrap: 'break-word',
        whiteSpace: 'normal',
    },
    alignLeft: {
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0.75),
        },
    },
    emptyListMsg: {
        [theme.breakpoints.down('md')]: {
            fontSize: '0.97em',
        },
    },
    denseCell: {
        height: 44,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));
