import { DeliveryLineStatus, DumpLoadStatus, UserRole } from '../../enums';
import { IAbilityComplexSubject } from '../abilities.types';

export interface IAbilityDeliveryLine {
    driverId: string | null | undefined;
    excavatorOperatorId: string | null | undefined;
    status: DeliveryLineStatus;
}

export enum DeliveryLineContextRole {
    AUTHOR = 'AUTHOR',
    PROJECT_EXCAVATOR_OPERATOR = 'PROJECT_EXCAVATOR_OPERATOR',
    PROJECT_DRIVER = 'PROJECT_DRIVER',
    RECEIVER_USER = 'RECEIVER_USER',
    SENDER_USER = 'SENDER_USER',
}

export enum DeliveryLineSubject {
    NO_DESTINATION = 'NO_DESTINATION',
    HISTORY = 'HISTORY',
    RECEIVER_FIELDS = 'RECEIVER_FIELDS',
    SENDER_FIELDS = 'SENDER_FIELDS',
    STATISTIC = 'STATISTIC',
}

export type IDeliveryLineSubject = `${DumpLoadStatus}` | `${DeliveryLineSubject}` | `${DeliveryLineStatus}`;

export interface IDeliveryLineCalculatedSubject extends IAbilityComplexSubject {
    relationRole: Array<DeliveryLineContextRole | UserRole>;
    subSubject: Array<IDeliveryLineSubject>;
}
