import './CreateWizard.scss';

import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { SideBarTitle } from '~/@sochi-components/SideBarTitle';
import { SochiBackButton } from '~/@sochi-components/SochiBackButton';
import { CreationMode, homePageMapStore } from '~/@user-store/homePageMap';

import { CreateWizardOption } from './CreateWizardOption';

export const CreateWizard = () => {
    const { className, element } = useFunctionalBem(CreateWizard);

    return (
        <div className={className}>
            <SochiBackButton onClick={() => homePageMapStore.revertCreationMode()} />
            <SideBarTitle
                title={<LocalizableText code={'getStarted'} />}
                subtitle={<LocalizableText code={'addSite'} />}
            />
            <div className={element('cards')}>
                <CreateWizardOption
                    title={<LocalizableText code={'iSender'} />}
                    subtitle={<LocalizableText code={'iSenderDescription'} />}
                    onClick={() => homePageMapStore.setCreationMode(CreationMode.PROJECT)}
                />
                <CreateWizardOption
                    title={<LocalizableText code={'iLandfill'} />}
                    subtitle={<LocalizableText code={'iLandfillDescription'} />}
                    onClick={() => homePageMapStore.setCreationMode(CreationMode.LANDFILL)}
                />
            </div>
        </div>
    );
};
