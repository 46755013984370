import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React, { ComponentType, FC } from 'react';

import { NotAnError } from '~/utils/error';

import config from './config';

class NotifyError extends Error {
    metadata?: object;
}

let bugsnagInitialized = false;

// https://redmine.pinpointer.se/issues/4836
const isDemo = process.env.WWW_BASE_URL?.includes('demo.pinpointer.se');

export const getBugsnag = (): ComponentType => {
    if (!config.bugsnagApiKey || isDemo) return WithoutErrorBoundary;

    Bugsnag.start({
        apiKey: config.bugsnagApiKey, // Key for pinpointer-web-test: 886ada0537f26c3a9fa709f3afb465e3'
        plugins: [new BugsnagPluginReact()],
        metadata: {
            INFO: {
                BASE_URL: config.wwwBaseUrl,
                VERSION: window.__version,
            },
        },
        onError: event => {
            if (event.originalError) {
                if (event.originalError instanceof NotAnError) return false;
                if (event.originalError instanceof NotifyError && event.originalError.metadata) {
                    event.addMetadata('INFO', event.originalError.metadata);
                }
            }

            return true;
        },
    });

    bugsnagInitialized = true;

    const bugsnagPlugin = Bugsnag.getPlugin('react');

    if (!bugsnagPlugin) return WithoutErrorBoundary;

    return bugsnagPlugin.createErrorBoundary(React);
};

export const bugsnagNotify = (errorMessage: string, metadata?: object, stack?: string) => {
    if (bugsnagInitialized) {
        const error = new NotifyError(errorMessage);
        if (metadata) error.metadata = metadata;
        if (stack) error.stack = stack;

        Bugsnag.notify(error);
    }
};

const WithoutErrorBoundary: FC = props => React.createElement(React.Fragment, props);

// function for invoke from console for checking bugsnag status
window.bugsnagNotify = (errorMessage: string = 'TEST ERROR') => {
    if (config.bugsnagApiKey) {
        Bugsnag.notify(
            new Error(errorMessage),
            event => {
                event.addMetadata('test', { errorMessage });
            },
            err => {
                if (err) {
                    console.log('Bugsnag error: ', err);
                } else {
                    console.log('Nice! Bugsnag is working!');
                }
            }
        );

        console.log('Error is sent to Bugsnag...');
    } else {
        console.log('WEB_BUGSNAG_API_KEY IS NOT DEFINED');
    }
};
