import client from '~/apolloClient';
import { ToxicLimitsQuery } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from './toxicLimits.queries';

export const getToxicLimitsData = (): Promise<ToxicLimitsQuery> => {
    return client
        .query<ToxicLimitsQuery>({
            query: queries.ToxicLimitsQuery,
        })
        .then(result => result.data)
        .catch(handleApolloError);
};
