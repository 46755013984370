import React from 'react';
import { Props } from 'react-pdf/dist/Document';
import { Document, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';

import { ErrorBoundary } from '../ErrorBoundary';

//https://github.com/wojtekmaj/react-pdf/issues/52
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const PdfDocument: React.FC<Props> = (props: Props) => (
    <ErrorBoundary name="PdfDocument">
        <Document {...props} />
    </ErrorBoundary>
);

export { Page as PdfPage, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
