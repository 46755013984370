import { ContaminationLevel, ContaminationType } from '@common/enums';

import { getContaminationLevelLabel } from '~/@store/toxicLimits/toxicLimits.helpers';
import i18n from '~/i18n';

export const getDropdownTitle = (
    contaminationType: ContaminationType,
    exactLevel: ContaminationLevel | null,
    allSubstancesEmpty: boolean
) => {
    const title =
        contaminationType === ContaminationType.SOLID
            ? i18n.toxicAnalysisSochi.head.totalest
            : i18n.toxicAnalysisSochi.head.laktest;

    if (allSubstancesEmpty) return title.replace('({})', '');

    if (exactLevel) {
        return title.replace(
            '{}',
            i18n.toxicAnalysisSochi.head.forLevel.replace(
                '{}',
                getContaminationLevelLabel(exactLevel, contaminationType)
            )
        );
    }

    return title.replace('{}', i18n.contaminationValues.custom);
};
