import React from 'react';

import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { showCustomDialog } from '~/@store/common';

import { ILandfill } from './SubareaEdit.types';
import SubareaEditForm from './SubareaEditForm';

type Props = {
    onClose: () => void;
    landfill: ILandfill;
};

export const SubareaCreateDialog = ({ onClose, landfill }: Props) => {
    return (
        <CardDialog
            title={<LocalizableText code={'LandfillPage.addSubarea'} />}
            onClose={onClose}
            maxWidth="md"
            fullWidth>
            <SubareaEditForm landfill={landfill} onClose={onClose} subarea={null} withOnHold={false} />
        </CardDialog>
    );
};

export const showSubareaCreateDialog = (landfill: ILandfill) =>
    showCustomDialog({
        render: close => <SubareaCreateDialog onClose={() => close(false)} landfill={landfill} />,
    });
