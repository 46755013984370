import { makeStyles } from '@material-ui/core';
import React, { FC, useState } from 'react';

import { Button } from '~/@components/Button';
import { ButtonGroupSwitch } from '~/@components/ButtonGroupSwitch';
import { LocalizableText } from '~/@components/LocalizableText';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { PageWrapper } from '~/@sochi-components/PageWrapper';
import { SochiTable } from '~/@sochi-components/SochiTable';
import { IBatchTransfer, useBatchTransfers } from '~/@user-store/landfillBatches';
import { LandfillBatchReportButton } from '~/@views/UserView/LandfillPage/common/LandfillBatchReport/LandfillBatchReportButton';
import { showLandfillBatchTransferForm } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchTransferForm';
import { useUserAbilities } from '~/contexts';
import { LandfillQuery_landfill, TransferType } from '~/graphql';
import i18n from '~/i18n';
import { canUpdateLandfill } from '~/utils/auth';

import { useLandfillTransfersColumns } from './useLandfillTransfersColumns';

type Props = {
    landfill: LandfillQuery_landfill;
};

export const LandfillBatchTransfers: FC<Props> = ({ landfill }) => {
    const { header } = useStyles();
    const [transferType, setTransferType] = useState(TransferType.INCOMING);

    const options = Object.values(TransferType).map(t => ({
        value: t,
        label: i18n.LandfillPage.transferCard[t],
    }));

    const query = useBatchTransfers(landfill.id);

    const { user, ability } = useUserAbilities();

    const readOnly = !canUpdateLandfill(user, ability, landfill);

    const columns = useLandfillTransfersColumns(landfill, transferType, readOnly);

    return (
        <PageWrapper>
            <div className={header}>
                <ButtonGroupSwitch options={options} value={transferType} onChange={setTransferType} />
                <LandfillBatchReportButton landfill={landfill} />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => showLandfillBatchTransferForm(landfill, null, null)}
                    disabled={readOnly}>
                    <LocalizableText code={'LandfillPage.batchLog.createTransfer'} />
                </Button>
            </div>
            <QueryStateHandler
                query={query}
                dataGetter={query => query.data?.batchTransfers || query.previousData?.batchTransfers}>
                {(transfers: IBatchTransfer[]) => (
                    <SochiTable
                        columns={columns}
                        items={transfers.filter(t => t.type === transferType)}
                        keyGetter={t => t.id}
                    />
                )}
            </QueryStateHandler>
        </PageWrapper>
    );
};

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
    },
}));
