import React, { FC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';

import { useDumpLoadFormikContext } from '../DumpLoadEditFormik';

export const DumpLoadSuggestedDestinationInfo: FC = () => {
    const formik = useDumpLoadFormikContext();

    const { root } = useStyles();

    return (
        <div className={root}>
            <FormikTextField
                name={'landfillName'}
                formik={formik}
                label={<LocalizableText code={'DumpLoadCard.suggestedLandfillName'} />}
                disabled
                copyable
            />
            <FormikTextField
                name={'landfillAddress'}
                formik={formik}
                label={<LocalizableText code={'DumpLoadCard.suggestedLandfillAddress'} />}
                disabled
                copyable
            />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
    },
}));
