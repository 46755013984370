type EventSubscriber<T> = (value: T) => void;

export class EventSource<T> {
    subscribers: Set<EventSubscriber<T>> = new Set();

    subscribe(subscriber: EventSubscriber<T>): () => boolean {
        this.subscribers.add(subscriber);

        return () => this.subscribers.delete(subscriber);
    }

    unsubscribe(subscriber: EventSubscriber<T>): boolean {
        return this.subscribers.delete(subscriber);
    }

    fire(value: T) {
        this.subscribers.forEach(sub => setTimeout(() => sub(value)));
    }
}
