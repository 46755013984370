import { action, computed, observable } from 'mobx';

import { DEFAULT_USER_RELATIONS_SORT } from '~/@store/users/users.constants';
import { AdminUserLandfillsQueryVariables, AdminUserProjectsQueryVariables } from '~/graphql';

import { FilterStore, PaginationStore, SortStore } from '../common';

export type UserRelationStoreFilter = {
    name?: string | null;
};

export enum UserRelationField {
    name = 'name',
}

type QueryVariables = AdminUserProjectsQueryVariables | AdminUserLandfillsQueryVariables;

export class UserRelationsStore {
    @observable
    userId?: string;

    @action
    setUserId(id: string) {
        this.userId = id;
    }

    pagination = new PaginationStore();
    filter = new FilterStore<UserRelationStoreFilter>(this.pagination.onClear);
    sort = new SortStore(DEFAULT_USER_RELATIONS_SORT, this.pagination.onClear);

    @computed
    get userRelationsQueryVariables(): QueryVariables {
        const filter = {
            userId: this.userId,
            name: this.filter.values.name,
        };

        return { filter, sort: this.sort.value, connection: this.pagination.connection };
    }
}

export const userProjectsStore = new UserRelationsStore();

export const userLandfillsStore = new UserRelationsStore();
