import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { DumpLoadsUpdateStatusMutation, DumpLoadsUpdateStatusMutationVariables, LoadStatus } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../dumpLoads.queries';
import type { IDumpLoad } from './usePagedDumpLoads';

export type ChangeStatusFunc = (status: LoadStatus) => Promise<unknown>;

export const useDumpLoadChangeStatusMutation = (dumpLoad: IDumpLoad): [ChangeStatusFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        DumpLoadsUpdateStatusMutation,
        DumpLoadsUpdateStatusMutationVariables
    >(queries.DumpLoadsUpdateStatusMutation);

    const updateStatus: ChangeStatusFunc = useCallback(
        status =>
            callMutation({
                variables: {
                    input: {
                        projectId: dumpLoad.project!.id,
                        ver: dumpLoad.project!.ver,
                        dumpLoads: [{ id: dumpLoad.id, status }],
                    },
                },
                refetchQueries: [
                    {
                        query: queries.AdminDetailedDumpLoadQuery,
                        variables: {
                            projectId: dumpLoad.project!.id,
                            dumpLoadId: dumpLoad.id,
                        },
                    },
                ],
                awaitRefetchQueries: true,
            }).catch(handleApolloError),
        [callMutation, dumpLoad]
    );

    return [updateStatus, mutationResult.loading];
};
