import { AbilityBuilder } from '@casl/ability';
import { useMemo } from 'react';

import {
    defineAbilitiesForLandfillDeviations,
    defineAbilitiesForProjectDeviations,
} from '../../../../common/abilities/deviations';
import { IDeviationCalculatedSubject } from '../../../../common/abilities/deviations/deviationTypes';
import { CommonRenderRule, formatCondition, mapCommonPart, TypedConditionsRule } from './common';

type DeviationRule = TypedConditionsRule<IDeviationCalculatedSubject>;

type DeviationRenderRule = CommonRenderRule & {
    subject: string;
};

export const useProjectDeviationRules = (): DeviationRenderRule[] =>
    useMemo(() => {
        const { rules, can, cannot } = AbilityBuilder.extract();

        defineAbilitiesForProjectDeviations(can, cannot);

        return (rules as DeviationRule[]).map(r => ({
            ...mapCommonPart(r),
            subject: formatCondition(r.conditions?.subSubject),
        }));
    }, []);

export const useLandfillDeviationRules = (): DeviationRenderRule[] =>
    useMemo(() => {
        const { rules, can, cannot } = AbilityBuilder.extract();

        defineAbilitiesForLandfillDeviations(can, cannot);

        return (rules as DeviationRule[]).map(r => ({
            ...mapCommonPart(r),
            subject: formatCondition(r.conditions?.subSubject),
        }));
    }, []);
