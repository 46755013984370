import { InputAdornment, makeStyles } from '@material-ui/core';
import React from 'react';

import { AutocompleteMultiSelectAsync, AutocompleteMultiSelectAsyncProps } from '~/@components/Autocomplete';
import { ArrowDownIcon } from '~/@sochi-components/Icons';

export function SochiAutocompleteMultiSelect<TValue extends { id: string }>(
    props: AutocompleteMultiSelectAsyncProps<TValue>
) {
    const { adornment, icon } = useStyles();

    return (
        <AutocompleteMultiSelectAsync
            {...props}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" className={adornment}>
                        <ArrowDownIcon color="primary" className={icon} />
                    </InputAdornment>
                ),
            }}
        />
    );
}

const useStyles = makeStyles(theme => ({
    adornment: {
        position: 'relative',
        width: 16,
    },
    icon: {
        top: 'calc(50% - 11px)',
        right: '-7px',
        position: 'absolute',
        ".MuiFormControl-root[aria-expanded='true'] &": {
            transform: 'rotate(180deg) translateY(3px)',
        },
        '.Mui-disabled &': {
            color: theme.palette.text.disabled,
        },
    },
}));
