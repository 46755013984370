import { UserContextRole } from '@common/enums';
import { formatString } from '@common/utils';
import React, { memo, VFC } from 'react';

import { showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { DeleteIcon } from '~/@components/Icon';
import { CellButton } from '~/@components/Table/TableCellActions/CellButton';
import { useProjectRemoveUser } from '~/@store/projects';
import i18n from '~/i18n';
import { getUserFullName } from '~/utils/user';

type Props = {
    projectId: string;
    user: { id: string; name: string; surname: string | null; contextRole: UserContextRole };
};

export const ProjectUserDeleteCell: VFC<Props> = memo(({ projectId, user }) => {
    const [removeUser, isRemoving] = useProjectRemoveUser();

    const handleDelete = () =>
        showDeleteConfirmationDialog(
            formatString(i18n.ProjectForm.confirmRemoveUser, i18n.UserContextRole[user.contextRole]),
            getUserFullName(user)
        ).then(confirmed => {
            if (confirmed) {
                const ignoredPromise = removeUser(projectId, user.id);
            }
        });

    return (
        <CellButton onClick={handleDelete} disabled={isRemoving}>
            <DeleteIcon />
        </CellButton>
    );
});
