import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import {
    BooleanFilter,
    DateFilter,
    NumberFilter,
    SingleOptionFilter,
    SortSection,
    StringFilter,
    TableHeadCell,
} from '~/@components/Table/TableHeadCell';
import { StringFilterWithEmptyOption } from '~/@components/Table/TableHeadCell/StringFilterWithEmptyOption';
import { DELIVERY_LINE_VIEW_MODE, MAX_DATE_INTERVAL_IN_MONTHS } from '~/@store/deliveryLines';
import { DeliveryLineField, deliveryLinesStore } from '~/@store/deliveryLines/deliveryLines.store';
import { SortInput } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    title: React.ReactNode;
    fieldName: DeliveryLineField;
    withFilter?: boolean;
    withSort?: boolean;
    disabled?: boolean;
};

export const DeliveryLineHeadCell = observer(({ title, fieldName, withFilter, withSort, disabled }: Props) => {
    const sortValue = useObservable(() => deliveryLinesStore.sort.value);
    const setSortValue = useCallback((v: SortInput) => deliveryLinesStore.sort.setValue(v), []);
    const isDeletedViewMode = deliveryLinesStore.mode === DELIVERY_LINE_VIEW_MODE.DELETED_LINES;

    const statusOptions = useObservable(() => deliveryLinesStore.statusOptions);

    const sortLabels = useMemo(() => {
        switch (fieldName) {
            case DeliveryLineField.deliveryLineIdx:
            case DeliveryLineField.weight:
            case DeliveryLineField.truckCapacity:
                return i18n.SortNumber;
            case DeliveryLineField.plannedStartDate:
                return i18n.SortDate;
            default:
                return i18n.SortString;
        }
    }, [fieldName]);

    const sortSection = useMemo(() => {
        if (!withSort || isDeletedViewMode) return null;

        return <SortSection fieldName={fieldName} sort={sortValue} setSort={setSortValue} labels={sortLabels} />;
    }, [withSort, fieldName, sortValue, setSortValue, sortLabels, isDeletedViewMode]);

    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        switch (fieldName) {
            case DeliveryLineField.orderNumber:
            case DeliveryLineField.transportCompany:
            case DeliveryLineField.truckRegistrationNumber:
            case DeliveryLineField.comment:
                return (
                    <StringFilter
                        valueGetter={() => deliveryLinesStore.filter.values[fieldName]}
                        setValue={v => deliveryLinesStore.filter.setValue(fieldName, v)}
                    />
                );
            case DeliveryLineField.customerInvoiceNumber:
            case DeliveryLineField.transportationInvoiceNumber:
            case DeliveryLineField.landfillInvoiceNumber:
                return (
                    <StringFilterWithEmptyOption
                        valueGetter={() => deliveryLinesStore.filter.values[fieldName]}
                        setValue={v => deliveryLinesStore.filter.setValue(fieldName, v)}
                    />
                );
            case DeliveryLineField.plannedStartDate:
                return (
                    <DateFilter
                        valueGetter={() => deliveryLinesStore.filter.values[fieldName]}
                        setValue={v => deliveryLinesStore.filter.setValue(fieldName, v)}
                        maxIntervalInMonths={MAX_DATE_INTERVAL_IN_MONTHS}
                    />
                );
            case DeliveryLineField.weight:
            case DeliveryLineField.truckCapacity:
                return (
                    <NumberFilter
                        valueGetter={() => deliveryLinesStore.filter.values[fieldName]}
                        setValue={v => deliveryLinesStore.filter.setValue(fieldName, v)}
                    />
                );
            case DeliveryLineField.verified:
                return (
                    <BooleanFilter
                        valueGetter={() => deliveryLinesStore.filter.values[fieldName]}
                        setValue={v => deliveryLinesStore.filter.setValue(fieldName, v)}
                    />
                );
            case DeliveryLineField.status:
                return (
                    <SingleOptionFilter
                        values={statusOptions}
                        valueGetter={() => deliveryLinesStore.filter.values[fieldName]}
                        setValue={v => deliveryLinesStore.filter.setValue(fieldName, v)}
                    />
                );

            default:
                return null;
        }
    }, [withFilter, fieldName, statusOptions]);

    return (
        <TableHeadCell
            title={title}
            sortDirection={sortValue?.field === fieldName && !isDeletedViewMode ? sortValue.direction : null}
            readonly={disabled}>
            {sortSection}
            {filterSection}
        </TableHeadCell>
    );
});
