import { AbilityCan, AbilitySubjects, UserRole } from '../../enums';
import { IAbilityFunction } from '../abilities.types';
import { ICaslCondition } from '../utils';
import { ILocalizationSubject } from './localizationTypes';

const { CRUD, READ } = AbilityCan;
const { ADMIN, EXTERNAL_RECEIVER, EMPLOYEE, EXCAVATOR_OPERATOR } = UserRole;

// forbid argument kept for uniform define ability calls
export function defineAbilitiesForLocalization(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<ILocalizationSubject>;

    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _allow(can, AbilitySubjects.LOCALIZATION, shape);

    allow(CRUD, { relationRole: ADMIN });
    allow(READ, { relationRole: EMPLOYEE });
    allow(READ, { relationRole: EXTERNAL_RECEIVER });
    allow(READ, { relationRole: EXCAVATOR_OPERATOR });
}
