import React, { useMemo } from 'react';

import { Chip } from '~/@components/Chip';
import { IColoredStatus, IStatus } from '~/@store/common';
import { PROJECT_STATUSES } from '~/@store/projects';
import { ProjectStatus } from '~/graphql';
import i18n from '~/i18n';

type Props = { children: ProjectStatus | null | void; onClick?: () => void };

export const ProjectStatusChip = ({ children, onClick }: Props) => {
    const status = useMemo<IStatus<ProjectStatus> | undefined>(() => {
        return PROJECT_STATUSES.find(el => el.value === children);
    }, [children]);

    if (!status) {
        return <Chip onClick={onClick}>{i18n.NA}</Chip>;
    }

    return (
        <Chip color={(status as IColoredStatus<ProjectStatus>).color} onClick={onClick}>
            {status.title}
        </Chip>
    );
};
