import { Icon, IconButton, Input, InputAdornment, ListItem } from '@material-ui/core';
import React, { useCallback } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import i18n from '~/i18n';

type Props = {
    valueGetter: () => string | null | void;
    setValue: (v: string | null) => void;
};

export const StringFilter = ({ valueGetter, setValue }: Props) => {
    const filter = useObservable(valueGetter);

    const handleFilterChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value);
        },
        [setValue]
    );

    const handleFilterClear = useCallback(() => {
        setValue(null);
    }, [setValue]);

    return (
        <>
            <ListItem>
                <Input
                    placeholder={i18n.search}
                    value={filter || ''}
                    onChange={handleFilterChange}
                    disableUnderline
                    fullWidth
                    endAdornment={
                        <InputAdornment position="end">
                            {filter ? (
                                <IconButton onClick={handleFilterClear} size="small">
                                    <Icon color="secondary">close</Icon>
                                </IconButton>
                            ) : (
                                <Icon>search</Icon>
                            )}
                        </InputAdornment>
                    }
                />
            </ListItem>
        </>
    );
};
