import { withFormik } from 'formik';
import * as Yup from 'yup';

import { forgotUri } from '~/apolloClient';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';
import { postRequest } from '~/utils/auth';
import { FrontApiError } from '~/utils/error';

import type { IForgotPasswordProps, IForgotPasswordValues } from './ForgotPassword';
import { ForgotPassword } from './ForgotPassword';

const formikHOC = withFormik<IForgotPasswordProps, IForgotPasswordValues>({
    mapPropsToValues: () => ({
        login: '',
        isResetSuccess: false,
    }),

    validationSchema: Yup.object().shape({
        login: Yup.string().trim().required(i18n.emailIsRequired),
    }),

    handleSubmit: async (values, { setFieldValue }) => {
        const { login } = values;

        return postRequest<{ is_success: boolean }>(forgotUri, {
            login,
        })
            .then(response => {
                if (response.is_success) {
                    setFieldValue('isResetSuccess', true);
                } else {
                    globalMessage.error(i18n.ForgotPasswordPage.resetFailed);
                }
            })
            .catch((error: FrontApiError) => {
                globalMessage.error(error.message);
            });
    },
});

export default formikHOC(ForgotPassword);
