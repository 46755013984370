import './ProjectDocuments.scss';

import classnames from 'classnames';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import type { IBemBag } from '~/@sochi-components/@bem';
import { getBem } from '~/@sochi-components/@bem';

import type { ProjectCommonDataProps } from '../../ProjectPage/ProjectPage';
import { Analysis } from '../Analysis';
import { DeclarationOfWaste } from '../DeclarationOfWaste';
import { OtherDocuments } from '../OtherDocuments';

type DocumentsProps = ProjectCommonDataProps;

export class ProjectDocuments extends React.PureComponent<DocumentsProps> {
    bem: IBemBag = getBem(this);
    render() {
        const { refetch, project } = this.props;
        if (!project) return null;

        const { className, element } = this.bem;

        return (
            <div className={className}>
                {project.dumpLoads.length ? (
                    <>
                        <Analysis project={project} />
                        <DeclarationOfWaste project={project} />
                        <OtherDocuments project={project} refetch={refetch} />
                    </>
                ) : (
                    <>
                        <h3 className={classnames(element('no-mass'), 'sochi-title')}>
                            <LocalizableText code={'projectNoMass'} />
                        </h3>
                        <OtherDocuments project={project} refetch={refetch} />
                    </>
                )}
            </div>
        );
    }
}
