import './SochiSearchInputs.scss';

import React from 'react';

import { getBem } from '~/@sochi-components/@bem';
import i18n from '~/i18n';

import { CloseCrossIcon, SearchIcon } from '../Icons';

type Props = {
    onChange: (v: string) => void;
    value: string | null | undefined;
    readOnly?: boolean;
    className?: string;
};

export class SochiSearchInput extends React.Component<Props> {
    bem = getBem(this);

    state = {
        value: this.props.value || '',
    };

    onChangeHandler = (e: { target: { value: string } }) => {
        const { onChange } = this.props;
        const value = e.target.value;
        this.setState({ value });
        if (onChange) onChange(value);
    };

    onClickHandler = () => {
        const { onChange } = this.props;
        const value = '';
        this.setState({ value });
        if (onChange) onChange(value);
    };

    render() {
        const { readOnly } = this.props;
        const { className, element } = this.bem;

        return (
            <div className={className}>
                <input
                    className={element('sochi-search-input')}
                    type="text"
                    placeholder={i18n.search}
                    value={this.state.value}
                    onChange={this.onChangeHandler}
                    disabled={!!readOnly}
                />
                <div className={element('sochi-icon')}>
                    {this.state.value && !readOnly ? (
                        <CloseCrossIcon
                            color="primary"
                            fontSize="inherit"
                            className={element('erase-icon')}
                            onClick={this.onClickHandler}
                        />
                    ) : (
                        <SearchIcon color="primary" fontSize="inherit" />
                    )}
                </div>
            </div>
        );
    }
}
