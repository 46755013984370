import './ScheduleChart.scss';

import { add, format, getISOWeek, getMonth, getYear, parse, startOfMonth } from 'date-fns';
import { isFunction, min } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ArrowLeftIcon, ArrowRightIcon } from '~/@sochi-components/Icons';
import type { SeriesData, SeriesIntervalData, SeriesIntervalValue } from '~/utils';
import { parseDateFrom } from '~/utils/date';

import { Colors, DateTextFormat, Granularity, IGranularity } from '../../../../../common/enums';
import {
    addGranularity,
    getEachGranularity,
    getEndOfGranularity,
    getStartOfGranularity,
} from '../../../../../common/functions/common';
import i18n from '../../../i18n';
import { GranularitySwitchValue } from '../../SochiGranularitySwitch';
import { SochiInfoPanel } from '../../SochiInfoPanel';

export type ScheduleChartColumnData = Map<string, SeriesIntervalValue>;

type IColumnName = {
    key: string;
    label: string;
};
const COLUMN_NUM = 8;
const GranularityFormats: Record<GranularitySwitchValue, string> = {
    [Granularity.year]: DateTextFormat.years,
    [Granularity.month]: DateTextFormat.months,
    [Granularity.week]: DateTextFormat.weeks,
};

const normalizeGranularity = (filter: GranularitySwitchValue) =>
    filter === Granularity.week ? Granularity.isoWeek : filter;

const convertToMap = (volumes: Array<SeriesIntervalValue>): ScheduleChartColumnData =>
    volumes.reduce((m, v) => {
        m.set(v.text, v);

        return m;
    }, new Map());

const getVolumesByFilter = (
    filter: GranularitySwitchValue,
    volumes: Array<SeriesIntervalData>
): SeriesIntervalValue[][] => {
    switch (filter) {
        case Granularity.year:
            return volumes.map(el => el.years);

        case Granularity.month:
            return volumes.map(el => el.months);

        default:
            return volumes.map(el => el.weeks);
    }
};

const getColumnName = (filter: GranularitySwitchValue, date: Date): string => {
    switch (filter) {
        case Granularity.year:
            return getYear(date).toString();

        case Granularity.month:
            return (getMonth(date) + 1).toString();

        default:
            return getISOWeek(date).toString();
    }
};

const generateColumns = (filter: GranularitySwitchValue, startDate: Date | null): IColumnName[] => {
    if (!startDate) return [];
    const granularity = normalizeGranularity(filter);

    const dates = getEachGranularity(
        startDate,
        addGranularity(startDate, COLUMN_NUM - 1, granularity),
        granularity as IGranularity
    );

    return dates.map(d => ({
        key: format(d, GranularityFormats[filter]),
        label: getColumnName(filter, d),
    }));
};

const getStartPeriod = (filter: GranularitySwitchValue, volumes: SeriesIntervalValue[][]): Date => {
    const keys = volumes.flat().map(v => v.text);
    const minDate = min(keys);

    return keys.length > 0 && minDate ? parse(minDate, GranularityFormats[filter], new Date()) : new Date();
};

type ScheduleChartProps = {
    data: SeriesData[];
    filter: GranularitySwitchValue;
    getSubHeader?: (data: SeriesIntervalValue[]) => string;
    scrollToCurrent?: boolean;
    noContentMessage?: React.ReactNode;
    renderCellContent?: (cell: SeriesIntervalValue) => React.ReactNode;
};

export const ScheduleChart = ({
    data,
    filter,
    getSubHeader,
    scrollToCurrent,
    noContentMessage,
    renderCellContent,
}: ScheduleChartProps) => {
    const { className, element } = useFunctionalBem(ScheduleChart);

    const currentKey = useMemo(() => format(new Date(), GranularityFormats[filter]), [filter]);
    const volumes = useMemo(
        () =>
            getVolumesByFilter(
                filter,
                data.map(d => d.values)
            ),
        [data, filter]
    );

    const values: Array<ScheduleChartColumnData> = useMemo(() => volumes.map(convertToMap), [volumes]);
    const [startPeriod, setStartPeriod] = useState<Date>(
        scrollToCurrent ? addGranularity(new Date(), (-COLUMN_NUM / 2) | 0, filter) : getStartPeriod(filter, volumes)
    );

    const [currentPeriod, setCurrentPeriod] = useState<Date>(new Date());

    useEffect(() => {
        setStartPeriod(() => {
            // We need to provide valid shift while changing chart interval filter
            const shift = filter === Granularity.week ? 1 : 0;

            return getStartOfGranularity(add(currentPeriod, { weeks: shift }), filter);
        });
    }, [filter, currentPeriod]);

    const moveScheduleBack = () => {
        setStartPeriod(currentStartPeriod => addGranularity(currentStartPeriod, -1, filter));
        setCurrentPeriod(currentPeriod => addGranularity(currentPeriod, -1, filter));
    };

    const moveScheduleForward = () => {
        setStartPeriod(currentStartPeriod => addGranularity(currentStartPeriod, 1, filter));
        setCurrentPeriod(currentPeriod => addGranularity(currentPeriod, 1, filter));
    };

    const renderDayPointer = (): React.ReactNode => {
        const granularity = normalizeGranularity(filter);
        const today = new Date().valueOf();
        const startOfPeriod = getStartOfGranularity(new Date(), granularity).valueOf();

        const endOfPeriod = getEndOfGranularity(new Date(), granularity).valueOf();

        const partOfPeriod = Math.floor(((today - startOfPeriod) / (endOfPeriod - startOfPeriod)) * 100);
        const style = {
            left: `${partOfPeriod}%`,
        };

        return <div className={element('day-pointer')} style={style} />;
    };

    const columnHeader = (column: IColumnName) => {
        if (filter === Granularity.week) {
            return `${i18n.week} ${column.label} ${format(
                parse(column.key, GranularityFormats[Granularity.week], new Date()),
                DateTextFormat.years
            )}`;
        } else if (filter === Granularity.month) {
            return format(startOfMonth(parseDateFrom(column.key)!), 'MMMM yyyy');
        } else if (filter === Granularity.year) {
            return column.label;
        }
    };

    const renderHeader = (column: IColumnName): React.ReactNode => {
        if (isFunction(getSubHeader)) {
            const seriesData: SeriesIntervalValue[] = values.reduce((t, el) => {
                const seriesValue = el.get(column.key);

                if (seriesValue) {
                    t.push(seriesValue);
                }

                return t;
            }, [] as SeriesIntervalValue[]);

            const subHeader = getSubHeader(seriesData);

            return (
                <div className={element('column-header', 'multi')}>
                    <div className={element('header-text')}>{columnHeader(column)}</div>
                    <div className={element('header-text', 'accent')}>{subHeader}</div>
                </div>
            );
        } else {
            return (
                <div className={element('column-header')}>
                    <div className={element('header-text')}>{columnHeader(column)}</div>
                </div>
            );
        }
    };

    const renderColumn = (column: IColumnName): Array<React.ReactNode> =>
        values.map((vMap, index) => {
            const series: SeriesData | void = data[index];
            const color = series?.color;
            const el = vMap.get(column.key);
            const modifiers = {
                delivered: el && el.delivered > 0,
                planned: el && el.delivered === 0 && el.planned > 0,
                stub: !el || (el.delivered === 0 && el.planned === 0),
                clickable: isFunction(series?.onClick),
                ...(color && {
                    [color as string]: true,
                }),
            };

            const onClick = series?.onClick;
            const handleClick = onClick ? () => onClick() : undefined;

            return (
                <div className={element('column-cell', modifiers)} key={'interval_block' + index} onClick={handleClick}>
                    {!!el && !!renderCellContent && renderCellContent(el)}
                </div>
            );
        });

    const renderSeriesName = (series: SeriesData, index: number, mobile: boolean) => {
        const name = series.name || i18n.NA;
        const addInfo = series.addInfo || '';
        const comment = series.comment || '';
        const color = !mobile ? series.color : null;
        const modifiers = {
            mobile,
            ...(color ? { [color]: true } : {}),
        };

        return (
            <React.Fragment key={series.id}>
                <div className={element('series-name', modifiers)}>
                    <div className={element('series-index')}>{index + 1}</div>
                    <div className={element('series-full-name', { mobile })} title={name}>
                        {name}
                    </div>
                    <div className={element('series-add-info', { mobile })}>{addInfo}</div>
                </div>
                <div className={element('series-comment', { mobile })}>{comment}</div>
            </React.Fragment>
        );
    };

    if (data.length === 0)
        return (
            <SochiInfoPanel
                text={noContentMessage || <LocalizableText code={'doesNotTransportScheduleYet'} />}
                color={Colors.green}
            />
        );

    return (
        <div className={className}>
            <div className={element('series-names', 'mobile')}>
                {data.map((series, index) => renderSeriesName(series, index, true))}
            </div>
            <div className={element('chart')}>
                <div className={element('series-names')}>
                    {data.map((series, index) => renderSeriesName(series, index, false))}
                </div>

                <div className={element('chart-columns')}>
                    <div className={element('left-arrow')} onClick={moveScheduleBack}>
                        <ArrowLeftIcon color="primary" fontSize="inherit" />
                    </div>
                    <div className={element('right-arrow')} onClick={moveScheduleForward}>
                        <ArrowRightIcon color="primary" fontSize="inherit" />
                    </div>
                    <div className={element('columns-counter')}>
                        {generateColumns(filter, startPeriod).map(el => {
                            return (
                                <div className={element('column')} key={el.key}>
                                    {currentKey === el.key ? renderDayPointer() : null}
                                    {renderHeader(el)}
                                    {renderColumn(el)}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
