import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ArrowDownIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.209519 5.09305C0.600043 4.70252 1.23321 4.70252 1.62373 5.09305L11 14.4693L20.3762 5.09305C20.7667 4.70252 21.3999 4.70252 21.7904 5.09305C22.1809 5.48357 22.1809 6.11674 21.7904 6.50726L11.7071 16.5906C11.3165 16.9811 10.6834 16.9811 10.2929 16.5906L0.209519 6.50726C-0.181005 6.11674 -0.181005 5.48357 0.209519 5.09305Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
