import { Form, Formik } from 'formik';
import React from 'react';

import { FadeProgress } from '~/@components/FadeProgress';
import { IDetailedCompany, useUpdateCompany } from '~/@store/companies';

import { CompanyEditActions } from './CompanyEditActions';
import { CompanyEditCommonFields } from './CompanyEditCommonFields';
import { getCompanyFormikConfig } from './CompanyEditFormik';

type Props = { company: IDetailedCompany };

export const CompanyEditForm = ({ company }: Props) => {
    const [updateCompany, isUpdating] = useUpdateCompany();

    const formik = getCompanyFormikConfig(company, updateCompany);

    return (
        <>
            <FadeProgress show={isUpdating} />
            <Formik {...formik}>
                <Form>
                    <CompanyEditCommonFields company={company} />
                    <CompanyEditActions />
                </Form>
            </Formik>
        </>
    );
};
