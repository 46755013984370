import { Divider, makeStyles } from '@material-ui/core';
import isString from 'lodash/isString';
import React from 'react';

import { COLORS } from '~/@components/@theme/colors';
import { Text } from '~/@components/Text';

import { CommonIconComponent } from '../Icon';
import { SimpleDialog } from './SimpleDialog';

type Props = {
    title: React.ReactNode;
    render: () => React.ReactNode;
    icon: CommonIconComponent;
    buttons: React.ReactNode;
    onClose: (result: boolean) => void;
};

export const CommonMessageDialog = ({ title, render, icon, onClose, buttons }: Props) => {
    const { dialog, dialogHeader, dialogIcon, dialogDivider, dialogContent, dialogButtons } = useStyles();

    const IconComp = icon;

    const renderContent = () => {
        const content = render();
        if (isString(content)) {
            return <Text variant="BodyRegular">{content}</Text>;
        } else {
            return content;
        }
    };

    return (
        <SimpleDialog maxWidth="sm" onClose={() => onClose(false)}>
            <div className={dialog}>
                <div className={dialogHeader}>
                    <IconComp className={dialogIcon} />
                    <Text variant="TitleBold">{title}</Text>
                    <Divider className={dialogDivider} />
                </div>
                <div className={dialogContent}>{renderContent()}</div>
                <div className={dialogButtons}>{buttons}</div>
            </div>
        </SimpleDialog>
    );
};

const useStyles = makeStyles(theme => ({
    dialog: {
        minWidth: 400,
        maxWidth: 'min-content',
        minHeight: 250,
        padding: theme.spacing(2),
        backgroundColor: COLORS.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: theme.spacing(4),
    },
    dialogHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    dialogIcon: {
        fontSize: '24px',
        marginBottom: theme.spacing(0.5),
    },
    dialogDivider: {
        width: 200,
        margin: '0 auto',
        backgroundColor: COLORS.gray,
        marginTop: theme.spacing(3),
    },
    dialogContent: {
        textAlign: 'center',
    },
    dialogButtons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
