import { Button, Divider, Grid } from '@material-ui/core';
import React from 'react';

import { useProjectAbilities } from '~/@store/projects';
import i18n from '~/i18n';

import { useProjectFormikContext } from './ProjectEditFormik';

type Props = {
    onClose?: () => void;
};

export const ProjectEditActions = ({ onClose }: Props) => {
    const formik = useProjectFormikContext();
    const project = formik.values.project;
    const { canEditProject } = useProjectAbilities(project);
    const canSubmit = !formik.isSubmitting && (project ? canEditProject && formik.dirty : true);

    const handleCancel = () => {
        if (!project || !formik.dirty) return onClose?.();

        formik.handleReset();
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3} justify="space-between">
                    <Grid item>
                        <Button disabled={!formik.dirty && !onClose} onClick={handleCancel} variant="contained">
                            {!formik.dirty && project && onClose ? i18n.close : i18n.cancel}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={!canSubmit} onClick={formik.submitForm} variant="contained" color="primary">
                            {project ? i18n.save : i18n.create}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
