import React from 'react';

import { FormikSelectField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { UnitOfMeasure } from '~/graphql';
import i18n from '~/i18n';

import { useDumpLoadFormikContext } from '../DumpLoadEditFormik';

type Props = { disabled?: boolean };

export const DumpLoadEditUnitSelect = ({ disabled }: Props) => {
    const formik = useDumpLoadFormikContext();

    return (
        <FormikSelectField
            label={<LocalizableText code={'unitOfMeasure'} />}
            name="unitOfMeasure"
            formik={formik}
            items={Object.values(UnitOfMeasure)}
            renderValue={unit => i18n.unitsOfMeasure[unit]}
            keyGetter={v => v}
            addEmptyOption={false}
            disabled={disabled}
        />
    );
};
