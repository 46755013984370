import React from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { showConfirmDialog } from '~/@store/common';
import { useUserVerifyMutation } from '~/@store/users';
import { useUserDetailContext } from '~/@views/AdminView/UserDetailPage/UserDetailContext';

export const UserConfirmButton = () => {
    const user = useUserDetailContext();
    const [verify, isLoading] = useUserVerifyMutation();

    const onApprove = () =>
        showConfirmDialog({
            title: <LocalizableText code={'UsersTable.userApproval'} />,
            render: () => <LocalizableText code={'UsersTable.approveConfirmation'} />,
        }).then(result => result && verify({ userId: user.id, verified: true }));

    if (user.verified) return null;

    return (
        <ActionButton onClick={onApprove} disabled={isLoading}>
            <LocalizableText code={'approveUser'} />
        </ActionButton>
    );
};
