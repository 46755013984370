import { AbilitySubjects } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { IToxicLimitCalculatedSubject } from './toxicLimitTypes';

export const getToxicLimitSubject = (user: IAbilityUser): IToxicLimitCalculatedSubject => {
    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.TOXIC_LIMITS,
        relationRole: [user.role!],
    };
};
