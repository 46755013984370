import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';

import { ISubstance, ToxicLimitsStore } from '~/@store/toxicLimits';

import { ToxicLimitsSubstanceRow } from './ToxicLimitsSubstanceRow';

type Props = {
    substance: ISubstance;
    store: ToxicLimitsStore;
};

export const ToxicLimitsRow = ({ substance, store }: Props) => {
    const { root } = useStyles();

    return (
        <Paper variant="outlined" className={root}>
            <ToxicLimitsSubstanceRow substance={substance} store={store} />
            {!!substance.children?.length &&
                substance.children.map(child => (
                    <ToxicLimitsSubstanceRow key={child.id} substance={child} store={store} />
                ))}
        </Paper>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(),
    },
}));
