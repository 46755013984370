import './SochiPdfPreview.scss';

import React from 'react';

import { PdfDocument, pdfjs, PdfPage } from '~/@components/PdfDocument';
import { getBem } from '~/@sochi-components/@bem';
import i18n from '~/i18n';
import { downloadFile } from '~/utils/files';

type SochiPdfPreviewProps = {
    className?: string;
    src?: Blob;
    url?: string;
    fetchData?: {};
    inPopup?: boolean;
    inContainer?: boolean;
};
type SochiPdfPreviewState = {
    src?: string | null;
    pages: { width: number }[];
    pageCount: number;
};

export class SochiPdfPreview extends React.PureComponent<SochiPdfPreviewProps, SochiPdfPreviewState> {
    wrapperRef = React.createRef<HTMLDivElement>();
    state: SochiPdfPreviewState = {
        src: null,
        pages: [],
        pageCount: 0,
    };

    bem = getBem(this);

    componentDidMount() {
        const { src, url, fetchData } = this.props;
        if (url) {
            downloadFile(url, fetchData, 'pdf-preview.pdf', false).then((blob: Blob) => {
                this.setBase64Url(this.getObjectUrlWithoutToolbar(blob));
            });
        } else if (src instanceof Blob) {
            this.setBase64Url(this.getObjectUrlWithoutToolbar(src));
        }
        window.addEventListener('resize', this.setPages);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setPages);
    }

    getObjectUrlWithoutToolbar(blob: Blob) {
        return URL.createObjectURL(blob) + '#toolbar=0';
    }

    setBase64Url = (src: string): void => {
        pdfjs
            .getDocument(src)
            .promise.then(doc => this.setState(state => ({ ...state, src, pageCount: doc.numPages }), this.setPages));
    };

    setPages = () => {
        if (!this.state.src) return;
        this.setState(state => ({
            ...state,
            //We must set up size dynamically, because we will listen 'resize' action and change width
            pages: Array.from({ length: state.pageCount }, () => ({
                width: (this.wrapperRef.current?.clientWidth || 250) - 16,
            })),
        }));
    };

    render() {
        const { block, element } = this.bem;
        const { src, pages } = this.state;
        const { inPopup, inContainer } = this.props;

        return (
            <div className={block({ 'in-popup': inPopup, 'in-container': inContainer })} ref={this.wrapperRef}>
                {!!src && (
                    <PdfDocument
                        className={element('pdf-container')}
                        file={src}
                        error={<div className={element('message')}>{i18n.error}</div>}
                        loading={<div className={element('message')}>{i18n.loading}</div>}>
                        {pages.map(({ width }, i) => (
                            <PdfPage key={`${src}${i}`} width={width} pageIndex={i} />
                        ))}
                    </PdfDocument>
                )}
            </div>
        );
    }
}
