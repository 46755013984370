import { UnitOfMeasure } from '@common/enums';
import React from 'react';

import { AmountFormatter } from './AmountFormatter';

type Props = {
    children: number | null | undefined;
};

export const TonnsFormatter = ({ children }: Props) => (
    <AmountFormatter unit={UnitOfMeasure.TONNS}>{children}</AmountFormatter>
);
