import { isPdf } from '@common/utils';
import { CircularProgress, Fade, makeStyles } from '@material-ui/core';
import React from 'react';

import { PdfDocument, PdfPage } from '~/@components/PdfDocument';
import { Text } from '~/@components/Text';
import { bugsnagNotify } from '~/bugsnag';
import i18n from '~/i18n';
import { convertBlobToBase64 } from '~/utils/files';

type Props = {
    children: React.ReactNode;
    fileName: string;
    loadFunc: () => Promise<Blob>;
};

const isOutsideElement = (el: HTMLDivElement | null, { x, y }: MouseEvent) => {
    if (!el) return false;
    const offset = 5;
    const { top, left, bottom, right } = el.getBoundingClientRect();

    return x < left - offset || x > right + offset || y < top - offset || y > bottom + offset;
};

export const FilePreviewTooltip = ({ children, fileName, loadFunc }: Props) => {
    const { tooltip, container, img, pdf } = useStyles();
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const loadingPromiseRef = React.useRef<Promise<unknown> | null>();
    const [src, setSrc] = React.useState('');
    const [isError, setError] = React.useState(false);
    const [isHover, setHover] = React.useState(false);
    const isPdfFile = isPdf(fileName);

    React.useEffect(() => {
        loadingPromiseRef.current = null;
        setSrc('');
        setError(false);
    }, [fileName]);

    const handleOpen = () => {
        setHover(true);

        if (loadingPromiseRef.current) return;

        loadingPromiseRef.current = loadFunc()
            .then(convertBlobToBase64)
            .then(setSrc)
            .catch(error => {
                bugsnagNotify('FilePreviewTooltip: File loading error', { message: error?.message, fileName });
                setError(true);
            });
    };

    const tooltipContent = React.useMemo(() => {
        if (isError)
            return (
                <div className={container}>
                    <Text color="error">{i18n.error}</Text>
                </div>
            );
        if (!src)
            return (
                <div className={container}>
                    <CircularProgress size={100} />
                </div>
            );

        if (isPdfFile)
            return (
                <PdfDocument
                    className={pdf}
                    file={src}
                    loading={<CircularProgress size={100} />}
                    onLoadError={() => setError(true)}>
                    <PdfPage pageIndex={0} width={window.innerWidth / 3} />
                </PdfDocument>
            );

        return <img alt={fileName} src={src} className={img} />;
    }, [fileName, isPdfFile, src, isError, img, pdf, container]);

    React.useEffect(() => {
        if (!isHover) return;

        const handleMove = (event: MouseEvent) => {
            if (isOutsideElement(anchorRef.current, event)) setHover(false);
        };

        window.addEventListener('mousemove', handleMove);

        return () => window.removeEventListener('mousemove', handleMove);
    }, [isHover]);

    return (
        <>
            <div onMouseEnter={handleOpen} ref={anchorRef} onClick={() => setHover(false)}>
                {children}
            </div>
            <Fade in={isHover}>
                <div className={tooltip}>{tooltipContent}</div>
            </Fade>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    tooltip: {
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 1500,
        top: theme.spacing(1),
        bottom: theme.spacing(1),
        right: theme.spacing(1),
        width: '40vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    container: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    pdf: {
        '& span': {
            pointerEvents: 'none !important',
        },
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));
