import { useMemo } from 'react';

import { useUserAbilities } from '~/contexts';
import { DeviationStatus } from '~/graphql';
import { IAbilityProject } from '~/utils/auth';

import { getLandfillDeviationSubject, getProjectDeviationSubject } from '../../../../common/abilities/deviations';
import { IAbilityLandfill } from '../../../../common/abilities/landfills';
import { AbilityCan } from '../../../../common/enums';
import { DeviationAbilities, IDeviation } from './deviation.types';

const useDeviationAbilities = (deviation: IDeviation | null, canUpdate: boolean) => {
    return useMemo(() => {
        const isActive =
            !!deviation && deviation.status !== DeviationStatus.DRAFT && deviation.status !== DeviationStatus.CLOSED;

        return {
            canChange: !deviation || canUpdate,
            canComment: isActive && canUpdate,
            canClose: isActive && canUpdate,
        };
    }, [deviation, canUpdate]);
};

export const useDeviationProjectAbilities = (
    deviation: IDeviation | null,
    project: IAbilityProject
): DeviationAbilities => {
    const { user, ability } = useUserAbilities();
    const canUpdate =
        !!deviation && ability.can(AbilityCan.UPDATE, getProjectDeviationSubject(user, project, deviation));

    return useDeviationAbilities(deviation, canUpdate);
};

export const useDeviationLandfillAbilities = (
    deviation: IDeviation | null,
    landfill: IAbilityLandfill
): DeviationAbilities => {
    const { user, ability } = useUserAbilities();
    const canUpdate =
        !!deviation && ability.can(AbilityCan.UPDATE, getLandfillDeviationSubject(user, landfill, deviation));

    return useDeviationAbilities(deviation, canUpdate);
};
