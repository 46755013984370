import './DeviationTable.scss';

import { DeviationStatus } from '@common/enums';
import { IconButton } from '@material-ui/core';
import React, { FC, useMemo, useState } from 'react';

import { Button } from '~/@components/Button';
import { ButtonGroupSwitch } from '~/@components/ButtonGroupSwitch';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ArrowRightIcon } from '~/@sochi-components/Icons';
import { ISochiTableColumn, SochiTable } from '~/@sochi-components/SochiTable';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { IDeviation } from '~/@user-store/deviations';
import i18n from '~/i18n';
import { formatDate } from '~/utils/date';

enum FilterValue {
    ACTIVE = 'ACTIVE',
    DRAFT = 'DRAFT',
    RESOLVED = 'RESOLVED',
}

const filterDeviations = (deviations: IDeviation[], filter: FilterValue) =>
    deviations.filter(d => {
        switch (d.status) {
            case DeviationStatus.DRAFT:
                return filter === FilterValue.DRAFT;
            case DeviationStatus.REQUESTED:
            case DeviationStatus.ANSWERED:
                return filter === FilterValue.ACTIVE;
            case DeviationStatus.CLOSED:
                return filter === FilterValue.RESOLVED;
            default:
                return false;
        }
    });

const filterOptions = Object.values(FilterValue).map(value => ({
    value,
    label: <LocalizableText code={`DeviationFilter.${value}`} />,
}));

type Props = {
    deviations: IDeviation[];
    openDeviation: (deviation: IDeviation) => unknown;
    createDeviation: () => unknown;
    canCreateDeviation: boolean;
};

export const DeviationTable: FC<Props> = ({ openDeviation, createDeviation, canCreateDeviation, deviations }) => {
    const { className, element } = useFunctionalBem(DeviationTable);

    const [currentFilter, setCurrentFilter] = useState<FilterValue>(FilterValue.ACTIVE);

    const tableColumns = useMemo<ISochiTableColumn<IDeviation>[]>(
        () => [
            { title: <LocalizableText code={'numberShort'} />, render: d => d.ticketNumber },
            {
                title: <LocalizableText code={'Deviation.issue'} />,
                render: d => <div className={element('issue-column')}>{d.issue}</div>,
                alignLeft: true,
            },
            { title: <LocalizableText code={'Deviation.author'} />, render: d => d.author.name || '', alignLeft: true },
            {
                title: <LocalizableText code={'Deviation.status'} />,
                render: d => i18n.deviationStatus[d.status],
                alignLeft: true,
            },
            {
                title: <LocalizableText code={'Deviation.dateSent'} />,
                render: d => formatDate(d.dateSent),
                alignLeft: true,
            },
            {
                title: <LocalizableText code={'Deviation.description'} />,
                render: d => <div className={element('description-column')}>{d.description}</div>,
                alignLeft: true,
            },
            {
                title: <LocalizableText code={'view'} />,
                render: d => (
                    <IconButton onClick={() => openDeviation(d)}>
                        <ArrowRightIcon color="primary" fontSize="inherit" />
                    </IconButton>
                ),
            },
        ],
        [element, openDeviation]
    );

    return (
        <div className={className}>
            <SochiTitle title={<LocalizableText code={'deviations'} />}>
                <Button color="primary" variant="contained" onClick={createDeviation} disabled={!canCreateDeviation}>
                    <LocalizableText code={'add'} />
                </Button>
                <ButtonGroupSwitch
                    color="secondary"
                    options={filterOptions}
                    value={currentFilter}
                    onChange={setCurrentFilter}
                />
            </SochiTitle>
            <SochiTable
                items={filterDeviations(deviations, currentFilter)}
                columns={tableColumns}
                keyGetter={({ id }) => id}
                stickyFirstColumn
                className={element('table')}
            />
        </div>
    );
};
