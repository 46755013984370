import './SochiDivider.scss';

import React from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';

type Props = {
    className?: string;
    dense?: boolean;
};

export const SochiDivider = ({ dense, className }: Props) => {
    const { block } = useFunctionalBem(SochiDivider, className);

    return <div className={block({ dense })} />;
};
