import React, { ReactNode } from 'react';

import { SochiAutocompleteMultiSelect } from '~/@sochi-components/SochiAutocomplete';
import { useUsersSearchFunc } from '~/@store/users';
import { UserFilter } from '~/graphql';
import i18n from '~/i18n';
import { getUserFullName, IUserInfo } from '~/utils/user';

type Props = {
    values: Array<IUserInfo>;
    onChange: (list: Array<IUserInfo>) => void;
    label: ReactNode;
    filter: UserFilter;
    readOnly?: boolean;
};

export const SochiUserSearchMultiSelect = ({ filter, values, onChange, label, readOnly }: Props) => {
    const userSearch = useUsersSearchFunc(filter);

    return (
        <SochiAutocompleteMultiSelect
            selectedOptions={values}
            onChange={onChange}
            label={label}
            loadOptions={userSearch}
            renderOption={getUserFullName}
            placeholder={i18n.searchUser}
            disabled={readOnly}
        />
    );
};
