import { FormikContextType } from 'formik';
import React, { useCallback } from 'react';

import { AutocompleteAsync, AutocompleteAsyncProps } from '~/@components/Autocomplete';

type FormikAsyncAutocompleteFieldProps<TValueType, TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends TValueType | null ? TFieldName : never;
    formik: FormikContextType<TValues>;
    clearable?: boolean;
} & Omit<AutocompleteAsyncProps<TValueType>, 'selectedOption' | 'onOptionSelect' | 'errorMessage'>;

export function FormikAsyncAutocompleteField<
    TValueType,
    TValues extends {},
    TFieldName extends keyof TValues & string
>({ name, formik, ...restProps }: FormikAsyncAutocompleteFieldProps<TValueType, TValues, TFieldName>) {
    const handleChange = useCallback(
        (v: TValueType | null) => {
            formik.setFieldTouched(name, true);
            formik.setFieldValue(name, v);
        },
        [formik, name]
    );

    return (
        <AutocompleteAsync
            selectedOption={formik.values[name] as unknown as TValueType | null}
            onOptionSelect={handleChange}
            errorMessage={formik.touched[name] && formik.errors[name]?.toString()}
            {...restProps}
        />
    );
}
