import { IHomePageLandfill, IOwnLandfill, IProject, LandfillType, ProjectType } from '~/@user-store/homePageMap/types';

export const isOwnLandfill = (input: IHomePageLandfill): input is IOwnLandfill => input.type === LandfillType.OWN;

export const isOwnProject = (input: IProject) => input.type === ProjectType.OWN;

type LocationLike = {
    lat: number;
    lng: number;
};

const earthRadius = 6371;

export const getDistance = (from: LocationLike, to: LocationLike) => {
    const latDelta = deg2rad(from.lat - to.lat);
    const lngDelta = deg2rad(from.lng - to.lng);

    const a =
        Math.pow(Math.sin(latDelta / 2), 2) +
        Math.cos(deg2rad(from.lat)) * Math.cos(deg2rad(to.lat)) * Math.pow(Math.sin(lngDelta / 2), 2);

    return 2 * earthRadius * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
};

const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
};
