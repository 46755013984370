import { adminRoutes } from '@common/routes';
import React from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { makeStyles } from '~/@components/@theme';
import { ButtonLink } from '~/@components/ButtonLink/ButtonLink';
import { LocalizableText } from '~/@components/LocalizableText';
import { Page } from '~/@components/Page';
import { usersStore } from '~/@store/users';
import { UsersTable } from '~/@views/AdminView/UsersPage/UsersTable';

import { CompaniesUsersRoute, CompaniesUsersRouteSwitch } from '../CompaniesPage/CompaniesUsersRouteSwitch';
import { UserAddButton } from './UserAddButton';
import { UserModeSwitch } from './UserModeSwitch';

export const UsersPage = () => {
    useSyncEffect(() => usersStore.setUsersPageViewMode());

    const { pageHeader, headerActions, menu } = useStyles();

    return (
        <Page>
            <Page.Header className={pageHeader}>
                <div className={menu}>
                    <CompaniesUsersRouteSwitch route={CompaniesUsersRoute.users} />
                    <UserModeSwitch />
                </div>
                <div className={headerActions}>
                    <ButtonLink to={adminRoutes.blacklist}>
                        <LocalizableText code={'BlacklistPage.blacklist'} />
                    </ButtonLink>
                    <UserAddButton />
                </div>
            </Page.Header>
            <Page.Content>
                <UsersTable />
            </Page.Content>
        </Page>
    );
};

const useStyles = makeStyles(theme => ({
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    headerActions: {
        display: 'flex',
        gap: theme.spacing(2),
    },
    menu: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(2),
    },
}));
