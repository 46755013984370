import { useFormik } from 'formik';
import React, { useCallback, VFC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { FormCard } from '~/@components/FormCard';
import { FormikNumberField, FormikSelectField, FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { fuelList, useVehicleTypePermissions } from '~/@store/vehicleTypes';
import { useVehicleTypeCreate, useVehicleTypeRemove, useVehicleTypeUpdate, VehicleType } from '~/@store/vehicleTypes';
import { commonPrecisions } from '~/config/enum';
import { VehicleKind } from '~/graphql';
import i18n from '~/i18n';

import { getVehicleTypeFormikConfig } from './VehicleTypeFormik';

type VehicleTypeFormProps = {
    onClose: () => void;
    vehicleType?: VehicleType;
};

export const VehicleTypeForm: VFC<VehicleTypeFormProps> = ({ onClose, vehicleType = null }) => {
    const { formCard, container } = useStyles();

    const [createVehicleType, isCreating] = useVehicleTypeCreate();
    const [updateVehicleType, isUpdating] = useVehicleTypeUpdate(vehicleType?.id || '');
    const [deleteVehicleType, isDeleting] = useVehicleTypeRemove(vehicleType?.id || '');

    const onDeleteClick = useCallback(() => {
        if (!vehicleType) return;

        return showDeleteConfirmationDialog(
            <LocalizableText code={'VehicleType.deleteConfirmation'} />,
            vehicleType.name
        ).then(confirmed => {
            if (confirmed) {
                deleteVehicleType();
                onClose();
            }
        });
    }, [deleteVehicleType, vehicleType, onClose]);

    const formik = useFormik(
        getVehicleTypeFormikConfig(vehicleType, vehicleType ? updateVehicleType : createVehicleType, onClose)
    );

    const { canCreate, canUpdate, canDelete } = useVehicleTypePermissions(vehicleType);
    const canEdit = vehicleType ? canUpdate : canCreate;

    return (
        <FormCard
            className={formCard}
            title={<LocalizableText code={vehicleType ? 'VehicleType.editTitle' : 'VehicleType.createTitle'} />}
            onClose={onClose}
            onSubmit={formik.handleSubmit}
            onDelete={!!vehicleType ? onDeleteClick : undefined}
            isCloseDisabled={formik.isSubmitting}
            isSubmitDisabled={!formik.dirty || formik.isSubmitting || !canEdit}
            isDeleteDisabled={formik.isSubmitting || !canDelete}
            showProgress={isCreating || isUpdating || isDeleting}>
            <div className={container}>
                <FormikSelectField
                    label={<LocalizableText code={'VehicleType.kind'} />}
                    name="kind"
                    formik={formik}
                    items={[VehicleKind.ON_SITE, VehicleKind.TRANSPORT]}
                    renderValue={i => i}
                    keyGetter={i => i}
                    disabled={!!vehicleType}
                />
                <FormikTextField
                    label={<LocalizableText code={'VehicleType.name'} />}
                    name="name"
                    formik={formik}
                    disabled={!canEdit}
                />
                <FormikTextField
                    label={<LocalizableText code={'VehicleType.externalId'} />}
                    name="externalId"
                    formik={formik}
                    disabled={!canEdit}
                />
                <Text variant="BodyBold" color="textSecondary">
                    <LocalizableText code="VehicleType.fuelConsumptions" /> (
                    {vehicleType?.kind === VehicleKind.TRANSPORT ? i18n.VehicleType.perKm : i18n.VehicleType.perHour})
                </Text>
                {fuelList.map(fuel => (
                    <FormikNumberField
                        key={fuel}
                        label={fuel}
                        name={fuel}
                        formik={formik}
                        precision={commonPrecisions.decimal}
                        disabled={!canEdit}
                    />
                ))}
            </div>
        </FormCard>
    );
};

const useStyles = makeStyles(theme => ({
    formCard: {
        minWidth: 394,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
}));
