import {
    DumpLoadSubject,
    getProjectDumpLoadSubject,
    IAbilityDumpLoad,
    IAbilityProject,
} from '@common/abilities/projects';
import { AbilityCan, DumpLoadFields } from '@common/enums';

import { useUserAbilities } from '~/contexts';

type DumpLoadLike = IAbilityDumpLoad & { siteConfirmed?: boolean | null; project: IAbilityProject | null };

export const useDumpLoadAbilities = (dumpLoad: DumpLoadLike) => {
    const { user, ability } = useUserAbilities();
    const project = dumpLoad.project;

    if (!project) return {};

    return {
        canUpdateDumpLoad: ability.can(AbilityCan.UPDATE, getProjectDumpLoadSubject(user, project, dumpLoad)),
        canUpdateFieldsAffectsDestination:
            ability.can(
                AbilityCan.UPDATE,
                getProjectDumpLoadSubject(user, project, dumpLoad, [DumpLoadSubject.FIELD_AFFECT_DESTINATION])
            ) && !dumpLoad.siteConfirmed,
        canUpdatePrices: ability.can(
            AbilityCan.UPDATE,
            getProjectDumpLoadSubject(user, project, dumpLoad, [DumpLoadFields.priceData])
        ),
    };
};
