import { isEqual, maxBy } from 'lodash';

import { ContaminationLevel, ContaminationType } from '../enums';
import { getAmountsForLevel, normalizeSubstanceAmounts } from './toxicAnalysis.common';
import { ISubstanceAmount, LimitValues, SubstanceLimitMap } from './toxicAnalysis.types';

export const leachingLevels = [ContaminationLevel.inert, ContaminationLevel.ifa, ContaminationLevel.limit];

const levelOrder: Partial<Record<ContaminationLevel, number>> = {
    inert: 0,
    ifa: 1,
    limit: 2,
    na: 3,
};

const getMaxLevel = (levels: ContaminationLevel[]) =>
    maxBy(levels, level => levelOrder[level] || -1) || ContaminationLevel.inert;

const calcLevel = (amount: number, limits: LimitValues | null | undefined): ContaminationLevel => {
    if (!limits) return ContaminationLevel.inert;
    if (limits.limit && amount > limits.limit) return ContaminationLevel.na;
    if (limits.ifa && amount > limits.ifa) return ContaminationLevel.limit;
    if (limits.inert && amount > limits.inert) return ContaminationLevel.ifa;

    return ContaminationLevel.inert;
};

export function getLeachingContaminationLevel(
    substanceAmounts: ISubstanceAmount[],
    limitsMap: SubstanceLimitMap
): ContaminationLevel {
    const amounts = normalizeSubstanceAmounts(substanceAmounts, false, true);

    if (amounts.length === 0) return ContaminationLevel.na;

    const levels = amounts.map(({ amount, substanceId }) => calcLevel(amount, limitsMap[substanceId]));

    return getMaxLevel(levels);
}

export function isExactLeachingLevel(
    level: ContaminationLevel,
    substanceAmounts: ISubstanceAmount[],
    limitsMap: SubstanceLimitMap
) {
    return isEqual(
        normalizeSubstanceAmounts(substanceAmounts, false, true),
        getAmountsForLevel(ContaminationType.LEACHING, limitsMap, level)
    );
}
