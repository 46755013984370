import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IDumpLoad } from '~/@store/dumpLoads';
import {
    ApplyDespatchAdviceSettingsMutation,
    ApplyDespatchAdviceSettingsMutationVariables,
    DespatchAdviceSettingsInput,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../dumpLoads.queries';

export type ApplyDespatchAdviceSettingsFunc = (input: DespatchAdviceSettingsInput[]) => Promise<unknown>;

export const useApplyDespatchSettingsMutation = (dumpLoad: IDumpLoad): [ApplyDespatchAdviceSettingsFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        ApplyDespatchAdviceSettingsMutation,
        ApplyDespatchAdviceSettingsMutationVariables
    >(queries.ApplyDespatchAdviceSettingsMutation);

    const applyDespatchSettings = useCallback<ApplyDespatchAdviceSettingsFunc>(
        settings => {
            return callMutation({
                variables: { orderNumber: dumpLoad.serialNumber, settings },
                refetchQueries: [
                    {
                        query: queries.AdminDetailedDumpLoadQuery,
                        variables: {
                            projectId: dumpLoad.project?.id || '',
                            dumpLoadId: dumpLoad.id,
                        },
                    },
                ],
                awaitRefetchQueries: true,
            }).catch(handleApolloError);
        },
        [dumpLoad, callMutation]
    );

    return [applyDespatchSettings, mutationResult.loading];
};
