import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { projectsStore } from '~/@store/projects/project.store';
import {
    ProjectCreate,
    ProjectCreateMutation,
    ProjectCreateMutation_projectCreate,
    ProjectCreateMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../projects.queries';

export type CreateProjectFunc = (input: ProjectCreate) => Promise<ProjectCreateMutation_projectCreate>;

export const useProjectCreate = (): [CreateProjectFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<ProjectCreateMutation, ProjectCreateMutationVariables>(
        queries.ProjectCreateMutation,
        {
            refetchQueries: [
                { query: queries.AdminProjectsPagedShortQuery, variables: projectsStore.projectsQueryVariables },
            ],
        }
    );

    const createProject = useCallback<CreateProjectFunc>(
        input => {
            return callMutation({
                variables: { input },
            })
                .then(result =>
                    result.data?.projectCreate
                        ? result.data.projectCreate
                        : Promise.reject(new Error('Empty response "ProjectUpdateMutation"'))
                )
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [createProject, mutationResult.loading];
};
