import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { DEFAULT_SEARCH_LIMIT } from '~/@store/common';
import {
    AdminProjectSearchQuery,
    AdminProjectSearchQuery_projectsPaged_edges_node,
    AdminProjectSearchQueryVariables,
    ProjectFilter,
    ProjectStatus,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../projects.queries';

export type IProjectSearchFilter = Omit<ProjectFilter, 'search'>;

export type IProjectSearchRecord = AdminProjectSearchQuery_projectsPaged_edges_node;

type UseProjectsSearchFunc = (search: string) => Promise<Array<IProjectSearchRecord>>;

const { NEW, IN_PROGRESS, DONE } = ProjectStatus;

export const useProjectsSearchFunc = (filter: ProjectFilter = {}): UseProjectsSearchFunc => {
    const client = useApolloClient();

    return useCallback(
        (search: string) => {
            return client
                .query<AdminProjectSearchQuery, AdminProjectSearchQueryVariables>({
                    query: queries.AdminProjectSearchQuery,
                    variables: {
                        limit: DEFAULT_SEARCH_LIMIT,
                        filter: { statuses: [NEW, IN_PROGRESS, DONE], ...filter, search },
                    },
                    fetchPolicy: 'network-only',
                })
                .then(res => res.data?.projectsPaged.edges.map(e => e.node) || [])
                .catch(handleApolloError);
        },
        [client, filter]
    );
};
