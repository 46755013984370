import React from 'react';

import { CommonIconComponent, ExclamationIcon } from '~/@components/Icon';
import i18n from '~/i18n';

import { CommonMessageDialog } from './CommonMessageDialog';
import { DialogActionButton } from './DialogActionButton';

type Props = {
    title: React.ReactNode;
    render: () => React.ReactNode;
    icon?: CommonIconComponent;
    closeLabel?: React.ReactNode;
    onClose: (result: boolean) => void;
};

export const AlertDialog = ({ title, render, icon, onClose, closeLabel = i18n.DialogButtons.ok }: Props) => {
    return (
        <CommonMessageDialog
            title={title}
            render={render}
            icon={icon || ExclamationIcon}
            onClose={onClose}
            buttons={
                <>
                    <div />
                    <DialogActionButton onClick={() => onClose(true)} color="primary">
                        {closeLabel}
                    </DialogActionButton>
                </>
            }
        />
    );
};
