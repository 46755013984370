import React from 'react';

import { DisableProvider } from '~/@components/@hooks';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { ISettings, useSettingsData, useSettingsPermissions } from '~/@store/settings';

import { SettingsTabs } from './SettingsTabs';

export const SettingsPage = () => {
    const query = useSettingsData();
    const { canEdit } = useSettingsPermissions();

    return (
        <DisableProvider disabled={!canEdit}>
            <QueryStateHandler query={query} dataGetter={query => query.data?.settings}>
                {(data: ISettings) => <SettingsTabs queryData={data} query={query} />}
            </QueryStateHandler>
        </DisableProvider>
    );
};
