import { replaceRouteParams, routes } from '@common/routes';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

import { ButtonGroupSwitch } from '~/@components/ButtonGroupSwitch';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import { SochiSearchInput } from '~/@sochi-components/SochiSearchInput';
import type { IProject } from '~/@user-store/homePageMap';
import { homePageMapStore } from '~/@user-store/homePageMap';
import { isOwnProject } from '~/@user-store/homePageMap/homePageMap.utils';
import { ProjectStatus } from '~/graphql';

import { ProjectCard } from '../../ProjectCard';

const options = [
    { label: <LocalizableText code={'projectStatuses.NEW'} />, value: ProjectStatus.NEW },
    { label: <LocalizableText code={'projectStatuses.IN_PROGRESS'} />, value: ProjectStatus.IN_PROGRESS },
    {
        label: <LocalizableText code={'projectStatuses.DONE'} />,
        value: ProjectStatus.DONE,
    },
];

export const ProjectList: FC = observer(() => {
    const { root, cards, emptyReplacer } = useStyles();

    const cardRefs = useRef(new Map<string, HTMLDivElement>());

    const history = useHistory();

    useEffect(() => {
        if (homePageMapStore.selectedProject?.id) {
            const element = cardRefs.current.get(homePageMapStore.selectedProject.id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [homePageMapStore.selectedProject?.id]);

    const saveRef = (id: string, element: HTMLDivElement | null) => {
        if (element) {
            cardRefs.current.set(id, element);
        }
    };

    const openProject = (project: IProject) => {
        history.push(replaceRouteParams(routes.sochi.projectDetail, { projectId: project.id }));
    };

    const onStatusChange = (value: ProjectStatus) => {
        homePageMapStore.applyProjectStatusFilter(value);
    };

    const activateProject = (project: IProject) => {
        homePageMapStore.onProjectClick(project);
    };

    const currentProjects = homePageMapStore.projectsOnMap;

    return (
        <div className={root}>
            <ButtonGroupSwitch
                color="secondary"
                options={options}
                value={homePageMapStore.projectStatusFilter}
                onChange={onStatusChange}
                fullWidth
            />
            <SochiSearchInput
                value={homePageMapStore.searchProjectFilter}
                onChange={value => {
                    homePageMapStore.applySearchProjectFilter(value);
                }}
            />
            <div className={cards}>
                <Grid container spacing={2}>
                    {currentProjects.length > 0 ? (
                        currentProjects.map(project => (
                            <Grid
                                key={project.id}
                                ref={element => saveRef(project.id, element)}
                                item
                                xs={12}
                                md={6}
                                lg={12}>
                                <ProjectCard
                                    onActivate={activateProject}
                                    onOpen={isOwnProject(project) ? openProject : undefined}
                                    key={`project-${project.id}`}
                                    project={project}
                                    highlightText={homePageMapStore.searchProjectFilter}
                                    isSelected={project.id === homePageMapStore.selectedProject?.id}
                                />
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12}>
                            <div className={emptyReplacer}>
                                <LocalizableText code={'noProjectsFound'} />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100% - 60px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',

        [theme.breakpoints.down('md')]: {
            height: 'calc(100% - 40px)',
        },
    },
    cards: {
        height: 'calc(100% - 115px)',
        overflow: 'scroll',
        overflowX: 'hidden',

        [theme.breakpoints.down('md')]: {
            height: 'calc(100% - 50px)',
        },
    },
    emptyReplacer: {
        fontSize: '18px',
        textAlign: 'center',
    },
}));
