import { action, computed, observable } from 'mobx';

import i18n from '~/i18n';

import { getGptConversationHistory, sendGptMessage } from './chatGpt.requests';
import type { GptConversationInput, IMessage } from './chatGpt.types';

type State = 'idle' | 'loading' | 'stream' | 'error';

const STREAM_TIMEOUT = 75;

class ChatGptStore {
    input: GptConversationInput = {};

    @observable
    inputText: string = '';

    @action
    setInputText = (v: string) => {
        this.inputText = v;
    };

    @observable
    activeMessage: null | IMessage = null;

    @observable
    state: State = 'idle';

    historyMessages = observable.array<IMessage>([]);

    @computed
    get messages(): IMessage[] {
        const messages = this.historyMessages.slice();
        if (this.activeMessage) messages.push(this.activeMessage);

        return messages;
    }

    @action
    setMessages = (messages: IMessage[]) => {
        this.historyMessages.replace(messages.slice().reverse());
    };

    @action
    addMessage = (message: IMessage) => {
        this.historyMessages.push(message);
    };

    @action
    onLoading = () => {
        this.state = 'loading';
        this.activeMessage = { id: 'new', userInput: this.inputText, aiOutput: '...' };
    };

    @action
    onError = (error: Error) => {
        this.state = 'error';
        this.activeMessage = { id: 'new', userInput: this.inputText, aiOutput: i18n.error };

        throw error;
    };

    @action
    startStream = (message: IMessage) => {
        this.activeMessage = { ...message, aiOutput: '' };
        this.state = 'stream';
        this.inputText = '';
    };

    @action
    onStream = (word: string) => {
        if (!this.activeMessage) return;

        this.activeMessage.aiOutput += ' ' + word;
    };

    @action
    endStream = () => {
        if (!this.activeMessage) return;

        this.historyMessages.push(this.activeMessage);
        this.activeMessage = null;
        this.state = 'idle';
    };

    init = (input: GptConversationInput) => {
        this.input = input;
        if (!!this.historyMessages.length || this.activeMessage) return;

        getGptConversationHistory(input).then(this.setMessages);
    };

    sendMessage = async () => {
        if (!this.inputText || this.state !== 'idle') return;
        this.onLoading();

        const newMessage = await sendGptMessage(this.input, this.inputText).catch(this.onError);

        this.startStream(newMessage);

        const wordsReversed = newMessage.aiOutput.split(' ').reverse();

        const onNext = () => {
            // Workaround for formatted ChatGPT answers
            let word;
            while (!word && wordsReversed.length > 0) {
                word = wordsReversed.pop();
            }

            if (word) {
                this.onStream(word);
                setTimeout(onNext, STREAM_TIMEOUT);
            } else {
                this.endStream();
            }
        };

        onNext();
    };
}

export const chatGptStore = new ChatGptStore();
