// http://redmine.pinpointer.se/issues/1177
import { I18n } from '@common/i18n/types';
import { setDefaultOptions } from 'date-fns';

import { en } from '../../common/i18n/locales/en';
import { sv } from '../../common/i18n/locales/sv';
import detectBrowserLanguage, { getDateFnsLocale, getFullBrowserLanguage } from './detectBrowserLanguage';

// const grad = {
//     MRR: 'Mindre än Ringa Risk (Eng. Less than small Risk)',
//     KM: 'Känslig Markanvändning (Eng. Sensitive land use)',
//     MKM: 'Mindre Känslig Markanvändning (Eng. Less Sensitive land use)',
//     IFA: 'Icke-Farligt Avfall (Eng. Non-hazardous waste)',
//     FA: 'Farligt Avfall (Eng. hazardous waste)'
// };

export const languages = { en, sv };

let availableLanguages = Object.keys(languages);

// switch between locales
export const lang: keyof typeof languages = detectBrowserLanguage(availableLanguages);

export const fullLang = getFullBrowserLanguage(lang);

const htmlElement = document.getElementsByTagName('html')[0];
htmlElement!.setAttribute('lang', fullLang);

setDefaultOptions({ locale: getDateFnsLocale(lang), weekStartsOn: 1 });

export default languages[lang] as I18n;
