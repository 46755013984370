import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React from 'react';
import { useEffect } from 'react';
import { chatGptStore } from 'src/@user-store/chatGpt';

import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';

import { GptInput } from './GptInput';
import { GptMessage } from './GptMessage';

type Props = {
    projectId?: string;
    landfillId?: string;
};

export const GptChat = observer(({ projectId, landfillId }: Props) => {
    const chatRef = React.useRef<HTMLDivElement>(null);
    const lastMessage = chatGptStore.messages[chatGptStore.messages.length - 1];

    useEffect(() => {
        chatRef.current && chatRef.current.scrollTo({ top: chatRef.current.scrollHeight });
    }, [lastMessage?.aiOutput]);

    useEffect(() => {
        chatGptStore.init({ projectId, landfillId });
    }, [projectId, landfillId]);

    const { root, chat, input, disclaimer } = useStyles();

    return (
        <div className={root}>
            <div className={chat} ref={chatRef}>
                <div className={disclaimer}>
                    <Text variant="BodyRegular" color="textSecondary">
                        <LocalizableText code="ChatGpt.disclaimer" />
                    </Text>
                </div>
                {chatGptStore.messages.map(m => (
                    <GptMessage key={m.id} message={m} />
                ))}
            </div>
            <div className={input}>
                <GptInput />
            </div>
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        height: '100%',
    },
    chat: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(0, 2),
        height: 'calc(100% - 50px)',
        overflowY: 'scroll',
    },
    input: {
        height: 50,
        padding: theme.spacing(0, 2),
    },
    disclaimer: {
        textAlign: 'center',
        maxWidth: '1200px',
        margin: 'auto',
        marginBottom: theme.spacing(2),
    },
}));
