import React from 'react';

import { usePdfExport } from '~/@components/@hooks';
import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { DownloadIcon } from '~/@sochi-components/Icons';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { ProjectJoinCodeContent } from '~/@views/UserView/common/ProjectJoinCodeContent/ProjectJoinCodeContent';
import { ProjectQuery_project } from '~/graphql';
import { processJoinCodePDf } from '~/utils/project';

type Props = {
    project: Pick<ProjectQuery_project, 'name' | 'location' | 'joinCode'>;
    onClose: () => void;
};

export const ProjectJoinPrintForm = ({ project, onClose }: Props) => {
    const [ExportContainer, generatePdf] = usePdfExport(project.name + '.pdf', processJoinCodePDf);

    return (
        <DialogContent>
            <ExportContainer>
                <ProjectJoinCodeContent project={project} />
            </ExportContainer>
            <ButtonGroup>
                <Button color="primary" variant="outlined" size="large" onClick={() => onClose()}>
                    <LocalizableText code={'close'} />
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={generatePdf}
                    startIcon={<DownloadIcon />}>
                    <LocalizableText code={'pdf'} />
                </Button>
            </ButtonGroup>
        </DialogContent>
    );
};
