import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';

import { DeleteButton, showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { FormCardButtons, FormCardContainer } from '~/@components/FormCard';
import { FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import {
    IBeastMaterial,
    useBeastMaterialCreate,
    useBeastMaterialPermissions,
    useBeastMaterialRemove,
    useBeastMaterialUpdate,
} from '~/@store/beastMaterials';
import i18n from '~/i18n';

import { getBeastMaterialFormikConfig } from './BeastMaterialFormik';

type Props = {
    material: IBeastMaterial | null;
    closeForm: () => void;
};

export const BeastMaterialForm = ({ material, closeForm }: Props) => {
    const [createMaterial, isCreating] = useBeastMaterialCreate();
    const [updateMaterial, isUpdating] = useBeastMaterialUpdate();
    const [removeMaterial, isRemoving] = useBeastMaterialRemove();

    const handleRemove = async () => {
        if (!material) return;
        const confirmed = await showDeleteConfirmationDialog(
            <LocalizableText code={'BeastMaterialsPage.confirmDelete'} />,
            material.name
        );

        if (!confirmed) return;
        await removeMaterial(material.id);
        closeForm();
    };

    const formik = useFormik(
        getBeastMaterialFormikConfig(
            material ? { material, saveFunc: updateMaterial } : { material: null, saveFunc: createMaterial },
            closeForm
        )
    );

    const { canCreate, canUpdate, canDelete } = useBeastMaterialPermissions();
    const canEdit = material ? canUpdate : canCreate;

    return (
        <FormCardContainer formik={formik} showProgress={isCreating || isUpdating || isRemoving}>
            <Grid container justify="space-between" alignItems="center">
                <Text variant="TitleBold">
                    <LocalizableText code={material ? 'BeastMaterialsPage.edit' : 'BeastMaterialsPage.create'} />
                </Text>
                {material && <DeleteButton onClick={handleRemove} disabled={!canDelete} />}
            </Grid>
            <FormikTextField
                name="name"
                formik={formik}
                disabled={!canEdit}
                label={<LocalizableText code={'BeastMaterialsPage.name'} />}
            />
            <FormikTextField
                name="beastId"
                formik={formik}
                disabled={!canEdit}
                label={<LocalizableText code={'BeastMaterialsPage.beastId'} />}
            />
            <FormCardButtons
                formik={formik}
                onCancel={closeForm}
                submitLabel={material ? i18n.save : i18n.create}
                submitDisabled={!canEdit}
            />
        </FormCardContainer>
    );
};
