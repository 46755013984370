import './Analysis.scss';

import React, { useMemo, VFC } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { DocumentCard } from '~/@sochi-components/cards/DocumentCard';
import { NoDocumentation } from '~/@sochi-components/cards/NoDocumentation';
import { SochiSpoilerView } from '~/@sochi-components/SochiSpoilerView';
import { showAnalysisDocumentsDialog } from '~/@views/UserView/ProjectPage/common/AnalysisDocuments';
import { useUserAbilities } from '~/contexts';
import i18n from '~/i18n';
import { canUploadProjectDocument } from '~/utils/auth';
import { fmtVolInTon } from '~/utils/numbers';

import { showAddAnalysisDialog } from '../../common/AnalysisDialog';
import type { ProjectDetailsDataProps } from '../../ProjectPage/ProjectPage';

type AnalysisProps = ProjectDetailsDataProps;

export const Analysis: VFC<AnalysisProps> = ({ project }) => {
    const { className, element } = useFunctionalBem(Analysis);
    const abilityContext = useUserAbilities();
    const canAdd = project && canUploadProjectDocument(abilityContext, project);

    const dumpLoadsWithAnalysis = useMemo(
        () => project.dumpLoads.filter(dumpLoad => !!dumpLoad.analysisFiles.length),
        [project]
    );

    const analysisDocuments: React.ReactNode[] = dumpLoadsWithAnalysis.map(dumpLoad => {
        return (
            <DocumentCard
                key={dumpLoad.id}
                title={dumpLoad.dumpType?.name || null}
                number={dumpLoad.serialNumber}
                info={dumpLoad.amountInTons ? fmtVolInTon(dumpLoad.amountInTons) : ''}
                addon={`${i18n.ProjectDocuments.files}: ${dumpLoad.analysisFiles.length}`}
                onClick={() => showAnalysisDocumentsDialog(project.id, dumpLoad.id)}
            />
        );
    });

    return (
        <div className={className}>
            <SochiSpoilerView
                title={<LocalizableText code={'ProjectDocuments.analysis'} />}
                buttons={
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => showAddAnalysisDialog(project)}
                        disabled={!canAdd}>
                        <LocalizableText code={'ProjectDocuments.add'} />
                    </Button>
                }
                cropClassName={element('crop-cards')}>
                <div className={element('analysis-cards')}>
                    {analysisDocuments.length ? analysisDocuments : <NoDocumentation />}
                </div>
            </SochiSpoilerView>
        </div>
    );
};
