import { ApolloError } from '@apollo/client';
import React from 'react';

import { Empty } from './Empty';
import { Error } from './Error';
import { Loader } from './Loader';

type IQueryResultProps = {
    loading?: boolean;
    empty?: boolean;
    error?: ApolloError;
    onRetry: () => void | Promise<unknown>;
    children?: React.ReactNode;
};

export const QueryResult = ({ loading, empty, error, onRetry, children }: IQueryResultProps) => {
    if (error) return <Error error={error} onRetry={onRetry} />;
    if (loading) return <Loader />;
    if (empty) return <Empty />;

    return <>{children}</>;
};
