import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { SochiAutocomplete } from '~/@sochi-components/SochiAutocomplete';
import { ICompanySearchRecord, useCompaniesSearchFunc } from '~/@store/companies';
import i18n from '~/i18n';

interface SochiCustomerSelectProp {
    disabled?: boolean;
    value: ICompanySearchRecord | null;
    onChange: (value: ICompanySearchRecord | null) => void;
    errorMessage?: string;
}

const renderCustomer = (customer: ICompanySearchRecord) => customer.name;

export function SochiCustomerSelect({ value, onChange, disabled, errorMessage }: SochiCustomerSelectProp) {
    const customersSearch = useCompaniesSearchFunc(false);

    return (
        <SochiAutocomplete
            label={<LocalizableText code={'company'} />}
            loadOptions={customersSearch}
            selectedOption={value}
            onOptionSelect={onChange}
            renderOption={renderCustomer}
            placeholder={i18n.searchCompany}
            errorMessage={errorMessage}
            disabled={disabled}
        />
    );
}
