import React from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { IUserCreateFormValues } from '~/@views/AdminView/UsersPage/UserCreateForm/UserCreateFormik';
import { useUserAbilities } from '~/contexts';
import { canCreateUser } from '~/utils/auth';

import { showUserCreateDialog } from '../UserCreateForm';

type Props = {
    defaultValues?: Partial<IUserCreateFormValues>;
};

export const UserAddButton = ({ defaultValues }: Props) => {
    const ability = useUserAbilities();

    return (
        <ActionButton disabled={!canCreateUser(ability)} onClick={() => showUserCreateDialog(defaultValues)}>
            <LocalizableText code={'UsersTable.addUser'} />
        </ActionButton>
    );
};
