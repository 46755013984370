export enum DatabaseImpactActions {
    SWITCH_TO_E2E_TEST_DB = 'SWITCH_TO_E2E_TEST_DB',
    SWITCH_TO_BRANCH_DB = 'SWITCH_TO_BRANCH_DB',
    RESET_E2E_TEST_DB = 'RESET_E2E_TEST_DB',
}

export enum TestId {
    SochiCreateProjectOrLandfillButton = 'SochiCreateProjectOrLandfillButton',
    SochiBackButton = 'SochiBackButton',

    Header = 'Header',
    HeaderLogoButton = 'HeaderLogoButton',
    HeaderExitButton = 'HeaderExitButton',
    NavigationMenu = 'NavigationMenu',
    NavigationMenuMore = 'NavigationMenuMore',
    NavigationSubMenu = 'NavigationSubMenu',
    GlobalSearchOpenButton = 'GlobalSearchOpenButton',
    GlobalSearchCloseButton = 'GlobalSearchCloseButton',
    GlobalSearchField = 'GlobalSearchField',
    GlobalSearchClearButton = 'GlobalSearchClearButton',
    PageNavDropdownButton = 'PageNavDropdownButton',
    PageNavDropdownList = 'PageNavDropdownList',
}
