import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { LandfillDetailedQuery as ILandfillDetailedQuery, LandfillDetailedQueryVariables } from '~/graphql';

import { AdminLandfillQuery } from '../landfills.queries';

export const useDetailedLandfill = (
    landfillId: string
): QueryResult<ILandfillDetailedQuery, LandfillDetailedQueryVariables> => {
    const variables: LandfillDetailedQueryVariables = { id: landfillId };

    return useQuery<ILandfillDetailedQuery, LandfillDetailedQueryVariables>(AdminLandfillQuery, {
        fetchPolicy: 'cache-first',
        variables,
    });
};
