import './DumpLoadDates.scss';

import { isValid } from 'date-fns';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import {
    DEFAULT_DATE_PICKER_MAX_DATE,
    DEFAULT_DATE_PICKER_MIN_DATE,
    SochiDatePicker,
} from '~/@sochi-components/SochiDatePicker';

import { DumpLoadFields } from '../../../../../../config/enum';
import i18n from '../../../../../../i18n';
import { IScheduleDumpLoad, scheduleStore } from '../scheduleStore';

type Props = {
    dumpLoad: IScheduleDumpLoad;
};

@observer
export class DumpLoadDates extends Component<Props> {
    bem = getBem(this);

    handleDateChange = (field: string) => (date: Date | null) => {
        date !== null &&
            !scheduleStore.isDateOutside(this.props.dumpLoad, field)(date) &&
            scheduleStore.handleDateChange(this.props.dumpLoad, field, date);
    };

    render() {
        const { dumpLoad } = this.props;
        const { className, element } = this.bem;
        const { date, endDate, readOnly } = dumpLoad;

        return (
            <div className={className}>
                <div className={element('date')}>
                    <span className={element('label')}>
                        <LocalizableText code={'TransportSchedule.startDate'} />
                    </span>
                    <SochiDatePicker
                        onChange={this.handleDateChange(DumpLoadFields.date)}
                        value={date}
                        autoOk
                        placeholder={i18n.TransportSchedule.startDate}
                        popoverPosition="left"
                        disabled={readOnly}
                        shouldDisableDate={scheduleStore.isDateOutside(this.props.dumpLoad, DumpLoadFields.date)}
                        invalidDateMessage={null}
                        minDate={scheduleStore.minDate}
                        minDateMessage={null}
                        maxDate={isValid(endDate) ? endDate : DEFAULT_DATE_PICKER_MAX_DATE}
                        maxDateMessage={null}
                    />
                </div>
                <div className={element('date')}>
                    <span className={element('label')}>
                        <LocalizableText code={'TransportSchedule.endDate'} />
                    </span>
                    <SochiDatePicker
                        onChange={this.handleDateChange(DumpLoadFields.endDate)}
                        value={endDate}
                        autoOk
                        placeholder={i18n.TransportSchedule.endDate}
                        popoverPosition="right"
                        disabled={readOnly}
                        shouldDisableDate={scheduleStore.isDateOutside(this.props.dumpLoad, DumpLoadFields.endDate)}
                        invalidDateMessage={null}
                        minDate={isValid(date) ? date : DEFAULT_DATE_PICKER_MIN_DATE}
                        minDateMessage={null}
                        maxDate={scheduleStore.maxDate}
                        maxDateMessage={null}
                    />
                </div>
            </div>
        );
    }
}
