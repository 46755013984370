import { useMutation } from '@apollo/client';

import { BatchLogQuery, CreateBatchAnalysisMutation } from '~/@user-store/landfillBatches/landfillBatch.queries';
import {
    BatchAnalysisInput,
    BatchLogQueryVariables,
    CreateBatchAnalysisMutation as ICreateBatchAnalysisMutation,
    CreateBatchAnalysisMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

export const useBatchAnalysisCreate = (landfillId: string, batchId: string) => {
    const [callMutation, data] = useMutation<ICreateBatchAnalysisMutation, CreateBatchAnalysisMutationVariables>(
        CreateBatchAnalysisMutation
    );

    const refetchVariables: BatchLogQueryVariables = { landfillId, batchId };

    const create = (input: BatchAnalysisInput) =>
        callMutation({
            variables: { landfillId, input },
            refetchQueries: [{ query: BatchLogQuery, variables: refetchVariables }],
            awaitRefetchQueries: true,
        }).catch(handleApolloError);

    return [create, data.loading] as const;
};
