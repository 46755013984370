import { gql } from '@apollo/client';

import { PageInfoFragment } from '~/@store/common/common.queries';
import { DumpTypeFragment } from '~/@store/dumpTypes/dumpType.queries';

import { LandfillShortFragment } from '../landfills/landfills.queries.fragment';

export const LandfillSubareaFragment = gql`
    fragment LandfillSubareaFragment on LandfillSubarea {
        id
        externalId
        comment
        dumpType {
            ...DumpTypeFragment
        }

        currentAmount
        reservedAmount
        deliveredAmount
        maxAmount

        unitOfMeasure
        priceBase
        priceOver50cm
        priceNotStackable

        grossPriceBase
        grossPriceOver50cm
        grossPriceNotStackable

        solidState
        allowSolidFA
        solidTestResult

        leachingState

        onHold

        substances {
            contaminationType
            substanceId
            amount
        }

        allowInvasiveSpecies
        invasiveSpecies
        allowTOC
        TOCPercent
        stackable
        over50cm
        priceUpdatedAt
        isProvider
    }

    ${DumpTypeFragment}
`;

export const LandfillSubareaShortFragment = gql`
    fragment LandfillSubareaShortFragment on LandfillSubarea {
        id
        externalId
        landfill {
            ...LandfillShortFragment
        }
        dumpType {
            id
            name
        }
        unitOfMeasure
        comment
        maxAmount
        currentAmount
        reservedAmount
        priceBase
        priceOver50cm
        priceNotStackable
        grossPriceBase
        priceUpdatedAt

        stackable
        over50cm
        solidTestResult
        isProvider
    }

    ${LandfillShortFragment}
`;

export const LandfillSubareasPagedQuery = gql`
    query LandfillSubareasPagedQuery($filter: SubareaFilter, $sort: SortInput, $connection: ConnectionInput) {
        landfillSubareasPaged(filter: $filter, sort: $sort, connection: $connection) {
            edges {
                cursor
                node {
                    ...LandfillSubareaShortFragment
                }
            }
            pageInfo {
                ...PageInfoFragment
            }
            pageCount
            totalCount
        }
    }

    ${LandfillSubareaShortFragment}
    ${PageInfoFragment}
`;
