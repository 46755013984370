import React, { useMemo } from 'react';

import {
    ISochiTableHeadCellProps,
    SochiStringFilter,
    SochiTableHeadCell,
} from '~/@sochi-components/SochiTable/SochiTableHeadCell';
import { SochiDateFilter } from '~/@sochi-components/SochiTable/SochiTableHeadCell/SochiDateFilter';
import {
    LandfillDeliveryEventsFilterField,
    landfillDeliveryEventsStore,
} from '~/@user-store/landfillDeliveryEvents/landfillDeliveryEvents.store';

type Props = {
    fieldName: LandfillDeliveryEventsFilterField;
    withFilter?: boolean;
} & Omit<ISochiTableHeadCellProps, 'sortDirection' | 'children'>;

export const LandfillDeliveryEventsTableHeadCell = ({ fieldName, withFilter, ...restProps }: Props) => {
    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        switch (fieldName) {
            case LandfillDeliveryEventsFilterField.licensePlate:
                return (
                    <SochiStringFilter
                        valueGetter={() => landfillDeliveryEventsStore.filter.values[fieldName]}
                        setValue={v => landfillDeliveryEventsStore.filter.setValue(fieldName, v)}
                    />
                );
            case LandfillDeliveryEventsFilterField.date:
                return (
                    <SochiDateFilter
                        valueGetter={() => landfillDeliveryEventsStore.filter.values[fieldName]}
                        setValue={v => landfillDeliveryEventsStore.filter.setValue(fieldName, v)}
                    />
                );

            default:
                return null;
        }
    }, [withFilter, fieldName]);

    return <SochiTableHeadCell {...restProps}>{filterSection}</SochiTableHeadCell>;
};
