import { ApolloError } from '@apollo/client';
import type { IGranularity, IUnits } from '@common/enums';
import {
    addGranularity,
    getDiffWithGranularity,
    getEndOfGranularity,
    getStartOfGranularity,
    getVersion,
} from '@common/functions';
import { startPages } from '@common/routes';
import type { IErrorCode } from '@common/types';
import { differenceInDays } from 'date-fns';
import { get } from 'lodash';
import React from 'react';

import { ChartColumnsNumber, MaxResolutions } from '~/config/constants';
import { globalMessage } from '~/services/message';
import type { MessageAction } from '~/stores/models';
import { NotAnError } from '~/utils/error';

import { Colors, ErrorCodes, Granularity, LoadStatus, Units as commonUnits } from '../../../common/enums';
import * as uom from '../../../common/UnitOfMeasure';
import browserHistory from '../browserHistory';
import i18n from '../i18n';

export const translateInvalidFields = (error: Error & { invalidFields?: string[] }) => {
    if (!error.invalidFields?.length) return '';

    return ` (${error.invalidFields.map(field => get(i18n.mandatoryFields, field, field)).join(', ')})`;
};

type IMessage = {
    code: ErrorCodes | null;
    text: string;
    onRetry?: MessageAction;
};

export const getErrorMessages = (error: Partial<ApolloError>, refetch?: () => Promise<unknown>): Array<IMessage> => {
    const { graphQLErrors, networkError, message } = error || {};
    const onRetry = refetch ? { label: i18n.retry, callback: () => refetch() } : undefined;

    if (networkError) {
        return [
            {
                code: ErrorCodes.NETWORK_ERROR,
                text: networkError.message || i18n.errorCodes.NETWORK_ERROR,
                onRetry,
            },
        ];
    }

    if (Array.isArray(graphQLErrors)) {
        let messages: IMessage[] = [];

        graphQLErrors.forEach(error => {
            let text: string;
            let code: ErrorCodes;
            if (error.code) {
                code = error.code;
                text =
                    (i18n.errorCodes[error.code as keyof typeof i18n.errorCodes] || i18n.error) +
                    translateInvalidFields(error);
            } else {
                code = ErrorCodes.UNKNOWN_GQL_ERROR;
                text = error.text?.replace(/\([A-Z_+]\)\Z/gi, '') || error.message || i18n.error;
            }

            messages.push({ code, text, onRetry });
        });

        return messages;
    }

    if (message) {
        return [{ code: null, text: message, onRetry: undefined }];
    }

    return [];
};

const notAnErrorCodes: Set<ErrorCodes> = new Set([
    ErrorCodes.COMPANY_DUPLICATE_ORGANIZATION_NUMBER,
    ErrorCodes.DUMP_LOAD_FIELDS_HAVE_INVALID_VALUES,
    ErrorCodes.VALUE_ALREADY_CHANGED,
    ErrorCodes.BEAST_MATERIAL_EXISTS,
    ErrorCodes.USER_REQUIRED_CUSTOMER,
    ErrorCodes.WRONG_VERSION,
    ErrorCodes.USER_EXISTS,
    ErrorCodes.USER_HAS_LINKED_PROJECTS,
    ErrorCodes.NO_ACTIVE_WHATSAPP_PHONE,
]);

/**
 * Handler for apollo errors. This show global error message on caching request error
 * ```
 * client.mutate(...)
 *    .then(...)
 *    .catch(handleApolloError);
 * ```
 */
export const handleApolloError = (error: ApolloError, refetch?: () => Promise<unknown>): Promise<never> => {
    const messages = getErrorMessages(error, refetch);
    messages.forEach(e => globalMessage.error(e.text, e.onRetry));

    return messages.every(m => m.code && notAnErrorCodes.has(m.code))
        ? Promise.reject(new NotAnError(error.message))
        : Promise.reject(error);
};

function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

type HighlightFunc = (textToHighlight: string | null | undefined) => React.ReactNode;

export const getHighlightFunc = (searchString: string | null | undefined): HighlightFunc => {
    if (!searchString?.trim()) return text => text;

    const regex = new RegExp(`(${escapeRegExp(searchString)})`, 'ig');

    return textToHighlight => {
        if (!textToHighlight?.trim()) return textToHighlight;

        return (
            <>
                {textToHighlight
                    .split(regex)
                    .map((textPart, index) =>
                        regex.test(textPart) ? (
                            <mark key={textPart + index}>{textPart}</mark>
                        ) : (
                            <span key={textPart + index}>{textPart}</span>
                        )
                    )}
            </>
        );
    };
};

export const Units = commonUnits;

export class UnitOfMeasure {
    static toCubic = uom.toCubic;
    static toTonns = uom.toTonns;
    static toString = uom.toString;
    static normalizeAmount = (sourceUom: IUnits, targetUom: IUnits, amount: number, tonnsPerCubicM: number) =>
        uom.normalizeAmount(sourceUom, targetUom, amount, tonnsPerCubicM);
}

export const sortByText = (a: { text: string }, b: { text: string }) => {
    if (a.text <= b.text) {
        return -1;
    } else {
        return 1;
    }
};

export const restoreBodyScroll = () => {
    const body = document.querySelector('body');
    if (!body) return;
    body.style.overflow = 'initial';
    body.style.maxHeight = 'initial';
};

export const hideBodyScroll = () => {
    const body = document.querySelector('body');
    if (!body) return;
    body.style.overflow = 'hidden';
    body.style.maxHeight = '100vh';
};

export const getChartColumnsNumber = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= MaxResolutions.mobile) {
        return ChartColumnsNumber.mobile;
    } else if (windowWidth <= MaxResolutions.tablet) {
        return ChartColumnsNumber.tablet;
    } else {
        return ChartColumnsNumber.desktop;
    }
};

export const generateVersionInfo = () => {
    const commitHash = window._env_.REACT_APP_VERSION || '';
    const branchName = window._env_.BRANCH_NAME || '';

    window.__version = getVersion(branchName, commitHash);
    window.__NODE_ENV = process.env.NODE_ENV;
};

export const isApolloError = (error: unknown): error is ApolloError => {
    return error instanceof ApolloError;
};

export type SeriesIntervalValue = {
    delivered: number;
    planned: number;
    text: string;
    info?: string;
};

export type SeriesIntervalData = {
    weeks: SeriesIntervalValue[];
    months: SeriesIntervalValue[];
    years: SeriesIntervalValue[];
};

export type SeriesData = {
    id: string;
    name?: string | null;
    addInfo?: string | null;
    comment?: string | null;
    color?: Colors | null;
    values: SeriesIntervalData;
    onClick?: () => void;
};

export const ScheduleChartColors: Record<LoadStatus, Colors> = Object.freeze({
    DRAFT: Colors.gray,
    REQUESTED: Colors.gray,
    CONFIRMED: Colors.orange,
    ORDERED: Colors.orange,
    IN_PROGRESS: Colors.orange,
    DONE: Colors.green,
    DISCARDED: Colors.red,
    NOT_ORDERED: Colors.red,
});

export type IDataRange = {
    start: Date;
    end: Date;
};

export const normalizeDataRange = (
    input: IDataRange,
    isStartChanged: boolean,
    granularity: IGranularity,
    maxSize: number
): IDataRange => {
    const actualGranularity = granularity === Granularity.week ? Granularity.isoWeek : granularity;

    let start = getStartOfGranularity(input.start, actualGranularity);
    let end = getEndOfGranularity(input.end, actualGranularity);

    const delta = getDiffWithGranularity(end, start, actualGranularity) + 1;

    if (delta <= 0 || delta > maxSize) {
        if (isStartChanged) {
            end = getEndOfGranularity(addGranularity(start, maxSize - 1, actualGranularity), actualGranularity);
        } else {
            start = getStartOfGranularity(addGranularity(end, -maxSize + 1, actualGranularity), actualGranularity);
        }
    }

    return { start, end };
};

export const dayDiff = (start: Date, end: Date): number => {
    return differenceInDays(start, end);
};

export const handleEntityGraphqlPermissionError = (
    error: ApolloError,
    errorCodeToDisplay: IErrorCode,
    refetch?: () => Promise<unknown>
): Promise<never> => {
    const { graphQLErrors } = error;

    if (Array.isArray(graphQLErrors)) {
        for (const graphQLError of graphQLErrors) {
            if (graphQLError.code === ErrorCodes.PERMISSION_ERROR) {
                browserHistory.replace(startPages.homepage);
                globalMessage.error(i18n.errorCodes[errorCodeToDisplay]);

                return Promise.reject(error);
            }
        }
    }

    return handleApolloError(error, refetch);
};

export const hasOwnProperty = <TObject extends {}, TProperty extends PropertyKey, TValue = unknown>(
    obj: TObject,
    prop: TProperty
): obj is TObject & Record<TProperty, TValue> => obj.hasOwnProperty(prop);
