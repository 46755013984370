import { TestId } from '@common/testConstants';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import React, { KeyboardEvent, RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

export interface IMenuGrowProps<T> {
    renderValue: (v: T) => React.ReactNode;
    items: Array<T>;
    value: T | null | void;
    onChange: (v: T) => void;
    placeHolder?: string;
    hideSelectedItem?: boolean;
    renderButton?: (
        displayValue: React.ReactNode,
        onClick: () => void,
        ref: RefObject<HTMLButtonElement>
    ) => React.ReactNode;
}

export const MenuGrow = <TValue extends unknown>({
    renderValue,
    items,
    value,
    onChange,
    placeHolder,
    hideSelectedItem = true,
    renderButton,
}: IMenuGrowProps<TValue>) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    useLayoutEffect(() => {
        anchorRef.current && anchorRef.current.setAttribute('data-testid', TestId.PageNavDropdownButton);
    }, []);

    const { root, button, popper, paper, itemClass } = useStyles({ open });

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleMenuClick = useCallback(
        (v: TValue) => {
            onChange(v);
            setOpen(false);
        },
        [setOpen, onChange]
    );

    const handleClose = useCallback(
        (event: React.MouseEvent<Document> | React.MouseEvent<HTMLDListElement>) => {
            if (anchorRef.current?.contains(event.target as Node)) {
                return;
            }

            setOpen(false);
        },
        [setOpen]
    );

    function handleListKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const menuId = 'menu-list-grow';

    const renderItems = hideSelectedItem ? items.filter(v => v !== value) : items;

    const displayValue = value ? renderValue(value) : placeHolder;

    return (
        <div className={root}>
            {renderButton ? (
                renderButton(displayValue, handleOpen, anchorRef)
            ) : (
                <Button
                    ref={anchorRef}
                    aria-controls={open ? menuId : undefined}
                    aria-haspopup="true"
                    onClick={handleOpen}
                    className={button}
                    endIcon={<Icon>keyboard_arrow_down</Icon>}>
                    {displayValue}
                </Button>
            )}
            <Popper
                className={popper}
                open={open}
                anchorEl={anchorRef.current}
                style={{ width: anchorRef.current?.offsetWidth }}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <Paper className={paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id={menuId}
                                    data-testid={TestId.PageNavDropdownList}
                                    onKeyDown={handleListKeyDown}
                                    onMouseLeave={handleClose}>
                                    {renderItems.map(item => (
                                        <MenuItem
                                            disabled={value === item}
                                            onClick={() => handleMenuClick(item)}
                                            key={JSON.stringify(item)}
                                            className={itemClass}>
                                            {renderValue(item)}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    button: ({ open }: { open: boolean }) => ({
        color: theme.palette.primary.main,
        height: 40,
        backgroundColor: 'inherit',
        border: `1px solid ${theme.palette.inputBorder}`,
        padding: '0 13px 0 16px',
        minWidth: 192,
        justifyContent: 'space-between',
        ...theme.customFont.select,
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
        ...(open && {
            boxShadow: theme.customShadows[2],
            backgroundColor: theme.palette.background.paper,
            pointerEvents: 'none',
        }),
    }),
    root: {
        display: 'flex',
    },
    popper: {
        zIndex: 999,
        marginTop: theme.spacing(1),
    },
    paper: {
        border: `1px solid ${theme.palette.inputBorder}`,
        boxShadow: theme.customShadows[2],
    },
    itemClass: {
        ...theme.customFont.select,
    },
}));
