import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode, useCallback, useRef, useState } from 'react';

const popoverId = 'header-menu-part';

type HeaderMenuDropdownProps = {
    button: ReactNode;
    children?: ReactNode;
};

export const HeaderMenuDropdown = ({ button, children }: HeaderMenuDropdownProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const onClickAway = useCallback(
        (event: React.MouseEvent<Document>) => {
            if (anchorRef.current?.contains(event.target as Node)) {
                return;
            }

            handleClose();
        },
        [handleClose]
    );

    const handleMouseLeave = useCallback(e => {
        if (
            e.relatedTarget instanceof Node &&
            (contentRef.current?.contains(e.relatedTarget) || anchorRef.current?.contains(e.relatedTarget))
        ) {
            return;
        }
        setOpen(false);
    }, []);

    const { btn, popper, paper } = useStyles({ open });

    return (
        <>
            <div
                ref={anchorRef}
                className={btn}
                onClick={handleOpen}
                onMouseEnter={handleOpen}
                onMouseLeave={handleMouseLeave}
                aria-controls={open ? popoverId : undefined}
                aria-haspopup="true">
                {button}
            </div>
            <Popper
                ref={contentRef}
                onMouseLeave={handleMouseLeave}
                className={popper}
                open={open}
                anchorEl={anchorRef.current}
                placement="bottom-start"
                transition>
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'left top',
                        }}>
                        <Paper className={paper} elevation={0} square>
                            <ClickAwayListener onClickAway={onClickAway}>{children}</ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    btn: ({ open }: { open: boolean }) => ({
        display: 'inline-block',
        backgroundColor: open ? theme.palette.primary.light : 'unset',
    }),
    popper: {
        zIndex: 999,
    },
    paper: {
        borderTop: '1px solid',
        borderColor: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
}));
