import React from 'react';

import { CommonIconComponent, QuestionIcon } from '~/@components/Icon';
import i18n from '~/i18n';

import { CommonMessageDialog } from './CommonMessageDialog';
import { DialogActionButton } from './DialogActionButton';

type Props = {
    title: React.ReactNode;
    render: () => React.ReactNode;
    icon?: CommonIconComponent;
    okLabel?: React.ReactNode;
    cancelLabel?: React.ReactNode;
    onClose: (result: boolean) => void;
};

export const ConfirmDialog = ({
    title,
    render,
    icon,
    onClose,
    okLabel = i18n.DialogButtons.confirm,
    cancelLabel = i18n.DialogButtons.cancel,
}: Props) => {
    return (
        <CommonMessageDialog
            title={title}
            render={render}
            icon={icon || QuestionIcon}
            onClose={onClose}
            buttons={
                <>
                    <DialogActionButton onClick={() => onClose(false)} variant="outlined" color="default">
                        {cancelLabel}
                    </DialogActionButton>
                    <DialogActionButton onClick={() => onClose(true)} color="primary">
                        {okLabel}
                    </DialogActionButton>
                </>
            }
        />
    );
};
