import { FormikConfig } from 'formik';

import { IDeliveryLine, UpdateDeliveryLineFunc } from '~/@store/deliveryLines';
import { DeliveryLineStatus, DeliveryLineUpdateInput } from '~/graphql';
import { parseDateFrom } from '~/utils/date';
import { booleanToDeliveryLineStatus } from '~/utils/deliveryLine';

import { getDeliveryLineValidationSchema, IDeliveryLineFormValues } from './DeliveryLineFormikCommon';

export function getDeliveryLineUpdateFormikConfig(
    updateDeliveryLine: UpdateDeliveryLineFunc,
    deliveryLine: IDeliveryLine
): FormikConfig<IDeliveryLineFormValues> {
    return {
        initialValues: {
            deliveryLine,
            dumpLoad: {
                id: '',
                serialNumber: deliveryLine.orderNumber,
                status: null,
            },
            weight: deliveryLine.weight,
            date: parseDateFrom(deliveryLine.plannedStartDate),
            company: deliveryLine.company,
            truckRegistrationNumber: deliveryLine.truckRegistrationNumber,
            truckCapacity: deliveryLine.truckCapacity,
            customerInvoice: deliveryLine.customerInvoiceNumber || '',
            transportInvoice: deliveryLine.transportationInvoiceNumber || '',
            landfillInvoice: deliveryLine.landfillInvoiceNumber || '',
            receipt: null,
            canSelectOrder: false,
            comment: deliveryLine.comment || '',
            verified: deliveryLine.status === DeliveryLineStatus.VERIFIED,
            vehicleType: deliveryLine.vehicleType,
            fuel: deliveryLine.fuel,
            engine: deliveryLine.engine,
        },
        validationSchema: getDeliveryLineValidationSchema(undefined, deliveryLine),
        onSubmit: async (values, { resetForm }) => {
            const {
                weight,
                date,
                company,
                truckRegistrationNumber,
                truckCapacity,
                customerInvoice,
                transportInvoice,
                landfillInvoice,
                receipt,
                comment,
                verified,
                vehicleType,
                fuel,
                engine,
            } = values;

            const input: DeliveryLineUpdateInput = {
                id: deliveryLine.id,
                weight,
                plannedStartDate: date?.toISOString(),
                companyId: company?.id || null,
                truckRegistrationNumber,
                truckCapacity,
                customerInvoiceNumber: customerInvoice,
                transportationInvoiceNumber: transportInvoice,
                landfillInvoiceNumber: landfillInvoice,
                status: booleanToDeliveryLineStatus(verified),
                comment,
                vehicleTypeId: vehicleType?.id,
                fuel,
                engine,
            };

            if (receipt) input.receipt = receipt;

            await updateDeliveryLine(input);
            resetForm();
        },
    };
}
