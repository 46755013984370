import './LightBox.scss';

import React, { ReactNode } from 'react';

import { getBem } from '~/@sochi-components/@bem';

import { showCustomDialog } from '../../../services/dialog';
import { ClickAwayListener } from '../../ClickAwayListener';

type LightBoxProps = {
    children?: (showImage: (src: string, caption?: ReactNode) => void) => ReactNode;
};

export class LightBox extends React.Component<LightBoxProps> {
    bem = getBem(this);

    showLightBox = (imageSrc: string, caption?: ReactNode) => {
        showCustomDialog({
            noContainer: true,
            render: closeDialog => (
                <div className={this.bem.element('image-container')} onClick={() => closeDialog(true)}>
                    <ClickAwayListener onClickAway={closeDialog}>
                        <img src={imageSrc} className={this.bem.element('image')} onClick={() => closeDialog(true)} />
                    </ClickAwayListener>
                    {caption && <div className={this.bem.element('caption')}>{caption}</div>}
                </div>
            ),
        });
    };

    render() {
        const { children } = this.props;

        return children ? children((src, caption) => this.showLightBox(src, caption)) : null;
    }
}
