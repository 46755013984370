import { Button, makeStyles } from '@material-ui/core';
import React, { useState, VFC } from 'react';

import { COLORS } from '~/@components/@theme/colors';
import { SimpleDialog } from '~/@components/Dialog';
import { Divider } from '~/@components/Divider';
import { DeleteIcon } from '~/@components/Icon';
import { IOSSwitch } from '~/@components/IOSSwitch';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { IUser } from '~/@store/users';
import { useUserDeleteMutation } from '~/@store/users';
import i18n from '~/i18n';

import { formatString } from '../../../../../../common/utils';

type Props = {
    user: IUser;
    closeDialog: () => void;
};

export const UserDeleteConfirmation: VFC<Props> = ({ user, closeDialog }) => {
    const {
        root,
        heading,
        notification,
        notificationLabel,
        notificationLabelText,
        notificationLabelValue,
        actions,
        divider,
        preformatted,
    } = useStyles();

    const [shouldNotify, setShouldNotify] = useState(true);
    const [deleteUser] = useUserDeleteMutation();

    const deleteUserHandler = () => {
        const ignoredPromise = deleteUser(user.id, shouldNotify);
        closeDialog();
    };

    return (
        <SimpleDialog onClose={closeDialog}>
            <div className={root}>
                <div className={heading}>
                    <DeleteIcon />
                    <Text variant="TitleBold">
                        <LocalizableText code={'UsersTable.userDeletion'} />
                    </Text>
                </div>
                <Divider className={divider} />
                <pre className={preformatted}>
                    {formatString(i18n.UsersTable.deletionConfirmation, user.email || '')}
                </pre>
                <div className={notification}>
                    <div className={notificationLabel}>
                        <Text variant="SmallText" className={notificationLabelText}>
                            <LocalizableText code={'UserEditPage.notifyUserWithEmail'} />:
                        </Text>
                        <Text variant="BodyBold" className={notificationLabelValue}>
                            {shouldNotify ? i18n.yes : i18n.no}
                        </Text>
                    </div>
                    <IOSSwitch checked={shouldNotify} onChange={(_, value) => setShouldNotify(value)} />
                </div>
                <div className={actions}>
                    <Button onClick={closeDialog} variant="outlined" color="default">
                        {i18n.cancel}
                    </Button>
                    <Button onClick={deleteUserHandler} variant="contained" color="primary">
                        {i18n.confirm}
                    </Button>
                </div>
            </div>
        </SimpleDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: 400,
        backgroundColor: COLORS.white,
        padding: theme.spacing(4, 2, 2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        alignItems: 'center',
    },
    heading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    divider: {
        width: 200,
    },
    notification: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    notificationLabel: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'baseline',
        gap: theme.spacing(1),
    },
    notificationLabelText: {
        color: COLORS.grayDark,
    },
    notificationLabelValue: {
        width: 24,
        lineHeight: '1 !important',
    },
    actions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    preformatted: {
        font: 'inherit',
        fontSize: 16,
        lineHeight: 1.375,
        backgroundColor: COLORS.transparent,
        border: 'none',
        padding: 0,
        margin: 0,
        textAlign: 'center',
    },
}));
