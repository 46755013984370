import { IconButton, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import { COLORS } from '~/@components/@theme/colors';
import { MenuRounded } from '~/@components/Icon';

import { DRAG_ELEM_CLASS } from './helpers';

type Props = {
    isChosen?: boolean;
    disabled?: boolean;
};

export const ReorderingButton = ({ isChosen, disabled }: Props) => {
    const { root, chosenButton } = useStyles();

    if (disabled) return null;

    return (
        <IconButton className={cn(root, DRAG_ELEM_CLASS, { [chosenButton]: isChosen })}>
            <MenuRounded />
        </IconButton>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        cursor: 'move',
    },
    chosenButton: {
        backgroundColor: COLORS.grayLight,
        color: COLORS.grayDarker,
    },
}));
