import { gql } from '@apollo/client/core';

import { CommentFragment } from '~/@store/comments/comments.queries';
import { PageInfoFragment } from '~/@store/common/common.queries';

export const CompanyFragment = gql`
    fragment CompanyFragment on PplCompany {
        id
        organizationNumber
        customerNumber
        name
        phone
        phone2
        email
        web
        contactPerson
        contactPerson2
        address
        address2
        zipCode
        city
        country
        comment
        active
        APIKey
        lastSyncAt
        isTransportCompany
        GLN
    }
`;

export const CompanyShortFragment = gql`
    fragment CompanyShortFragment on PplCompany {
        id
        name
        organizationNumber
    }
`;

export const CompaniesSearchQuery = gql`
    query CompaniesSearchQuery($search: String!, $limit: Int!, $isTransportCompany: Boolean) {
        pplCompanies(
            filter: { search: $search, isTransportCompany: $isTransportCompany }
            connection: { first: $limit }
        ) {
            id
            name
        }
    }
`;

export const CompaniesPagedQuery = gql`
    query CompaniesPagedQuery($filter: PplCompanyFilter, $sort: SortInput, $connection: ConnectionInput) {
        companiesPaged(filter: $filter, sort: $sort, connection: $connection) {
            edges {
                cursor
                node {
                    ...CompanyFragment
                    lastCommentText
                }
            }
            pageInfo {
                ...PageInfoFragment
            }
            pageCount
            totalCount
        }
    }

    ${CompanyFragment}
    ${PageInfoFragment}
`;

export const CompanyGenerateAPIKeyMutation = gql`
    mutation CompanyGenerateAPIKeyMutation($id: ID!) {
        companyGenerateAPIKey(id: $id) {
            ...CompanyFragment
        }
    }

    ${CompanyFragment}
`;

export const CompanyRemoveApiKeyMutation = gql`
    mutation CompanyRemoveApiKeyMutation($id: ID!) {
        companyRemoveApiKey(id: $id) {
            ...CompanyFragment
        }
    }

    ${CompanyFragment}
`;

export const CompanyQuery = gql`
    query CompanyQuery($id: ID!) {
        company(id: $id) {
            ...CompanyFragment
            comments {
                ...CommentFragment
            }
            lastCommentText
        }
    }

    ${CommentFragment}
    ${CompanyFragment}
`;

export const CompanyAddCommentMutation = gql`
    mutation CompanyAddCommentMutation($companyId: ID!, $text: String!) {
        companyAddComment(id: $companyId, text: $text) {
            id
            comments {
                ...CommentFragment
            }
            lastCommentText
        }
    }

    ${CommentFragment}
`;

export const CompanyUpdateMutation = gql`
    mutation CompanyUpdateMutation($input: CompanyUpdate!) {
        companyUpdate(input: $input) {
            ...CompanyFragment
        }
    }

    ${CompanyFragment}
`;

export const CompanyCreateMutation = gql`
    mutation CompanyCreateMutation($input: CompanyCreate!) {
        companyCreate(input: $input) {
            ...CompanyFragment
        }
    }

    ${CompanyFragment}
`;

export const CompanyRemoveMutation = gql`
    mutation CompanyRemoveMutation($id: ID!) {
        companyRemove(id: $id)
    }
`;

export const CompanyActiveConnectedEntitiesQuery = gql`
    query CompanyActiveConnectedEntitiesQuery($id: ID!) {
        companyActiveConnectedEntities(id: $id) {
            projects {
                id
                name
            }
            landfills {
                id
                name
            }
            users {
                id
                name
                surname
            }
        }
    }
`;
