import { FilterRange } from '@common/types';
import { computed } from 'mobx';

import { FilterStore, PaginationStore, removeEmptyKeys, SortStore } from '~/@store/common';
import {
    DespatchAdviceResponseFilterInput,
    DespatchAdviceResponsesPagedVariables,
    DespatchAdviceResponseType,
} from '~/graphql';

export type DespatchAdviceResponsesStoreFilter = {
    date?: FilterRange<Date> | null;
    declineReason?: string | null;
    responseType?: DespatchAdviceResponseType;
};

export type DespatchAdviceResponsesStoreFilterField = keyof DespatchAdviceResponsesStoreFilter;

class DespatchAdviceResponsesStore {
    pagination = new PaginationStore();
    filter = new FilterStore<DespatchAdviceResponsesStoreFilter>(this.pagination.onClear);
    sort = new SortStore({ field: 'date', direction: -1 }, this.pagination.onClear);

    @computed
    get despatchAdviceQueryVariables(): DespatchAdviceResponsesPagedVariables {
        const { date, ...rest } = removeEmptyKeys({ ...this.filter.values });
        const filter: DespatchAdviceResponseFilterInput = { ...rest };
        if (date?.[0]) filter.dateFrom = date[0].toISOString();
        if (date?.[1]) filter.dateTo = date[1].toISOString();

        return { filter, sort: this.sort.value, connection: this.pagination.connection };
    }
}

export const despatchAdviceResponsesStore = new DespatchAdviceResponsesStore();
