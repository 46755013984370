import React, { useMemo, VFC } from 'react';

import { CustomTabs, ICustomTabConfig } from '~/@components/CustomTabs';
import { LocalizableText } from '~/@components/LocalizableText';

import { UserConfirmButton } from './UserConfirmButton';
import { UserEditTab } from './UserEditTab';
import { UserLandfillAddButton, UserLandfillsTab } from './UserLandfillsTab';
import { UserProjectAddButton, UserProjectsTab } from './UserProjectsTab';

export const UserTabs: VFC = () => {
    const tabs: Array<ICustomTabConfig> = useMemo(
        () => [
            {
                label: <LocalizableText code={'editUser'} />,
                render: () => <UserEditTab />,
                rightActionButton: () => <UserConfirmButton />,
            },
            {
                label: <LocalizableText code={'landfills'} />,
                render: () => <UserLandfillsTab />,
                rightActionButton: () => <UserLandfillAddButton />,
            },
            {
                label: <LocalizableText code={'projects'} />,
                render: () => <UserProjectsTab />,
                rightActionButton: () => <UserProjectAddButton />,
            },
        ],
        []
    );

    return <CustomTabs title={<LocalizableText code={'editUser'} />} tabs={tabs} hideSingleTabLabel />;
};
