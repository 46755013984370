import './ProjectDoneActionHeader.scss';

import { ISochiFullRoutes, replaceRouteParams, routes } from '@common/routes';
import React, { useCallback, VFC } from 'react';
import { useHistory } from 'react-router';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { SochiInfoPanel } from '~/@sochi-components/SochiInfoPanel';
import { useUserAbilities } from '~/contexts';
import { canReadOffers, canReadPriceCalculator } from '~/utils/auth';

import { Colors } from '../../../../../../../common/enums';
import { ProjectQuery_project, ProjectStatus } from '../../../../../graphql';

type ProjectDoneActionHeaderPropsType = {
    project: ProjectQuery_project;
};

export const ProjectDoneActionHeader: VFC<ProjectDoneActionHeaderPropsType> = ({ project }) => {
    const { className } = useFunctionalBem(ProjectDoneActionHeader);

    const history = useHistory();

    const goToTheDestination = useCallback(
        (route: ISochiFullRoutes) => () => {
            history.push({
                pathname: replaceRouteParams(route, { projectId: project.id }),
            });
        },
        [history, project]
    );

    const ability = useUserAbilities();

    const canPerformAction = canReadPriceCalculator(ability, project) && canReadOffers(ability, project);

    if (project.status !== ProjectStatus.DONE || !canPerformAction) {
        return null;
    }

    return (
        <SochiInfoPanel
            className={className}
            text={<LocalizableText code={'itLooksLikeThisProjectIsComplete'} />}
            color={Colors.green}>
            <Button color="info" variant="outlined" onClick={goToTheDestination(routes.sochi.projectPriceCalculator)}>
                <LocalizableText code={'createNewMass'} />
            </Button>
            <Button color="info" variant="contained" onClick={goToTheDestination(routes.sochi.offerOutgoing)}>
                <LocalizableText code={'goToOffers'} />
            </Button>
        </SochiInfoPanel>
    );
};
