import './LandfillCertificates.scss';

import React from 'react';

import { FileUploadButton } from '~/@components/FileUploadButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import { DocumentCard } from '~/@sochi-components/cards/DocumentCard';
import { NoDocumentation } from '~/@sochi-components/cards/NoDocumentation';
import { SochiSpoilerView } from '~/@sochi-components/SochiSpoilerView';
import { landfillCertificateUri } from '~/apolloClient';
import { MAX_PAYLOAD_SIZE } from '~/config/constants';
import { withAbilities, WithAbilitiesProps } from '~/contexts';
import { LandfillQuery_landfill_certificates } from '~/graphql';
import i18n from '~/i18n';
import { showConfirmDialog } from '~/services/dialog';
import { globalMessage } from '~/services/message';
import { downloadFile } from '~/utils/files';
import { removeLandfillCertificate, updateLandfill } from '~/utils/landfill';

import type { LandfillDetailsDataProps } from '../../LandfillPage';

class LandfillCertificates extends React.Component<LandfillDetailsDataProps & WithAbilitiesProps> {
    bem = getBem(this);

    handleUpload = (file: File) => {
        if (file.size < MAX_PAYLOAD_SIZE) {
            const { user, ability, landfill } = this.props;
            const ignoredPromise = updateLandfill(user, ability, landfill, { id: landfill.id, certificates: [file] });
        } else {
            globalMessage.error(i18n.tooLargeFile);
        }
    };

    handleOpen = (cert: LandfillQuery_landfill_certificates) => () => {
        const { landfill } = this.props;
        const ignoredPromise = downloadFile(
            landfillCertificateUri,
            {
                landfillId: landfill.id,
                fileId: cert.id,
            },
            cert.name
        );
    };

    handleDelete = (fileId: string) => async () => {
        const { user, ability, landfill } = this.props;
        const isConfirmed = await showConfirmDialog({
            title: <LocalizableText code={'delete'} />,
            message: <LocalizableText code={'deleteFileConfirm'} />,
        });

        if (isConfirmed) {
            await removeLandfillCertificate(user, ability, landfill, fileId);
        }
    };

    render() {
        const { className, element } = this.bem;
        const { landfill } = this.props;

        return (
            <div className={className}>
                <SochiSpoilerView
                    key={landfill.certificates.length}
                    title={<LocalizableText code={'LandfillPage.documents'} />}
                    buttons={
                        <FileUploadButton color="primary" variant="contained" onChange={this.handleUpload}>
                            <LocalizableText code={'LandfillPage.upload'} />
                        </FileUploadButton>
                    }
                    cropClassName={element('crop-cards')}>
                    <div className={element('documents-cards')}>
                        {landfill.certificates.length === 0 && <NoDocumentation />}
                        {landfill.certificates.map((cert: LandfillQuery_landfill_certificates) => (
                            <DocumentCard
                                key={cert.id}
                                info={cert.name}
                                onClick={this.handleOpen(cert)}
                                onDelete={this.handleDelete(cert.id)}
                            />
                        ))}
                    </div>
                </SochiSpoilerView>
            </div>
        );
    }
}

export default withAbilities(LandfillCertificates);
