import React from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';

import { FormNames, ILandfillTextFieldProps, LandfillTextField } from '../LandfillFormParts';

type LandfillEditFormTextProps = ILandfillTextFieldProps & {
    className?: string;
    name: FormNames;
    rows?: number;
};

export const LandfillEditFormText = ({ className, ...restProps }: LandfillEditFormTextProps) => {
    const bem = useFunctionalBem(LandfillEditFormText, className);

    return (
        <div className={bem.className}>
            <LandfillTextField {...restProps} />
        </div>
    );
};
