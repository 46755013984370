import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDebounce } from '~/@components/@hooks/use-debounce';
import { useProgressState } from '~/@components/@hooks/use-progress-state';

import { Autocomplete } from './Autocomplete';
import { ICommonAutocompleteProps } from './CommonAutocomplete';

export type AutocompleteAsyncProps<TValue> = {
    selectedOption: TValue | null;
    onOptionSelect: (v: TValue | null) => void;
    renderOption: (v: TValue) => string;
    renderOptionSecondary?: (v: TValue) => string;
    loadOptions: (search: string) => Promise<Array<TValue>>;
    clearOnSelect?: boolean;
} & Omit<ICommonAutocompleteProps, 'searchValue' | 'onSearchChange'>;

export const AutocompleteAsync = <TValue extends unknown>({
    loadOptions,
    onOptionSelect,
    selectedOption,
    renderOption,
    clearOnSelect,
    ...restProps
}: AutocompleteAsyncProps<TValue>) => {
    const selectedString = useMemo(
        () => (selectedOption ? renderOption(selectedOption) : ''),
        [renderOption, selectedOption]
    );

    const [search, setSearch] = useState(selectedString);
    const [options, setOptions] = useState<Array<TValue>>([]);

    const debouncedSearch = useDebounce(search);

    const [isLoading, _loadOptions] = useProgressState(loadOptions);

    useEffect(() => {
        if (debouncedSearch && debouncedSearch !== selectedString) _loadOptions(debouncedSearch).then(setOptions);
    }, [debouncedSearch, _loadOptions, selectedString]);

    useEffect(() => {
        if (!selectedOption) setSearch('');
    }, [selectedOption]);

    const onSelect = useCallback(
        (value: TValue | null) => {
            onOptionSelect(value);
            setSearch(!clearOnSelect && value ? renderOption(value) : '');
            setOptions([]);
        },
        [clearOnSelect, onOptionSelect, renderOption]
    );

    return (
        <Autocomplete
            {...{ ...restProps, selectedOption, renderOption }}
            onOptionSelect={onSelect}
            searchValue={search}
            onSearchChange={setSearch}
            options={options}
            isLoading={isLoading}
        />
    );
};
