import { useProgressState } from '~/@components/@hooks';

import { ILocation } from './locations.types';
import { useGeocode } from './useGeocode';
import { useReverseGeocode } from './useReverseGeocode';

export const useGeocodeWithState = (): [
    boolean,
    (lat: number, lng: number) => Promise<ILocation>,
    (text: string) => Promise<ILocation>
] => {
    const reverseGeocode = useReverseGeocode();
    const geoCode = useGeocode();

    const [latLngLoading, getByLatLng] = useProgressState(reverseGeocode);

    const [textLoading, getByText] = useProgressState(geoCode);

    return [latLngLoading || textLoading, getByLatLng, getByText];
};
