import React from 'react';

import { Button } from '~/@components/Button';
import i18n from '~/i18n';

import { useLandfillFormContext } from '../LandfillFormParts';

export const LandfillEditFormResetButton = () => {
    const context = useLandfillFormContext();

    return (
        <Button variant="contained" color="info" onClick={() => context.resetForm()}>
            {i18n.cancel}
        </Button>
    );
};
