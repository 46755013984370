import React, { useCallback, VFC } from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { showCustomDialog } from '~/@store/common';
import { ILandfillSearchRecord } from '~/@store/landfills';
import { useUserLandfillCreateMutation } from '~/@store/users';
import { useUserAbilities } from '~/contexts';
import { canUpdateUser } from '~/utils/auth';

import { PopupLandfillSelect } from '../../common/PopupEditFields/PopupLandfillSelect';
import { useUserDetailContext } from '../UserDetailContext';

export const UserLandfillAddButton: VFC = () => {
    const ability = useUserAbilities();
    const user = useUserDetailContext();
    const [createUserLandfill, creating] = useUserLandfillCreateMutation();

    const submit = useCallback(
        (landfill: ILandfillSearchRecord | null) =>
            landfill ? createUserLandfill(user.id, landfill.id) : Promise.resolve(),
        [createUserLandfill, user.id]
    );

    const handleClick = useCallback(
        () =>
            showCustomDialog({
                render: closeDialog => (
                    <PopupLandfillSelect
                        label={<LocalizableText code={'landfill'} />}
                        onSubmit={submit}
                        onClose={closeDialog}
                        nullable={false}
                        excludeParticipantId={user.id}
                    />
                ),
            }),
        [user, submit]
    );

    return (
        <ActionButton onClick={handleClick} disabled={creating || !canUpdateUser(ability, user)}>
            <LocalizableText code={'UserEditPage.addLandfill'} />
        </ActionButton>
    );
};
