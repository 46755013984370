import { useMutation } from '@apollo/client';
import { PureQueryOptions } from '@apollo/client/core/types';

import {
    BatchLogQuery,
    BatchTransfersQuery,
    CreateBatchTransferMutation,
} from '~/@user-store/landfillBatches/landfillBatch.queries';
import {
    BatchTransferInput,
    CreateBatchTransferMutation as ICreateBatchTransferMutation,
    CreateBatchTransferMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

export const useBatchTransferCreate = (landfillId: string, batchId: string | null) => {
    const [callMutation, data] = useMutation<ICreateBatchTransferMutation, CreateBatchTransferMutationVariables>(
        CreateBatchTransferMutation
    );

    const refetchQueries: PureQueryOptions[] = [{ query: BatchTransfersQuery, variables: { landfillId } }];

    if (batchId) {
        refetchQueries.push({ query: BatchLogQuery, variables: { landfillId, batchId } });
    }

    const create = (input: BatchTransferInput) =>
        callMutation({
            variables: { landfillId, input },
            refetchQueries,
            awaitRefetchQueries: true,
        }).catch(handleApolloError);

    return [create, data.loading] as const;
};
