import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const PlusIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 32 32" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 5.66675C16.5523 5.66675 17 6.11446 17 6.66675V25.3334C17 25.8857 16.5523 26.3334 16 26.3334C15.4477 26.3334 15 25.8857 15 25.3334V6.66675C15 6.11446 15.4477 5.66675 16 5.66675Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.6665 16C5.6665 15.4477 6.11422 15 6.6665 15H25.3332C25.8855 15 26.3332 15.4477 26.3332 16C26.3332 16.5523 25.8855 17 25.3332 17H6.6665C6.11422 17 5.6665 16.5523 5.6665 16Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
