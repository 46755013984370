import { AbilityBuilder } from '@casl/ability';
import { useMemo } from 'react';

import { defineAbilitiesForLandfills, ILandfillCalculatedSubject } from '../../../../common/abilities/landfills';
import { CommonRenderRule, formatCondition, mapCommonPart, TypedConditionsRule } from './common';

type LandfillRule = TypedConditionsRule<ILandfillCalculatedSubject>;

type LandfillRenderRule = CommonRenderRule & {
    subject: string;
};

export const useLandfillRules = (): LandfillRenderRule[] =>
    useMemo(() => {
        const { rules, can, cannot } = AbilityBuilder.extract();

        defineAbilitiesForLandfills(can, cannot);

        return (rules as LandfillRule[]).map(r => ({
            ...mapCommonPart(r),
            subject: formatCondition(r.conditions?.subSubject),
        }));
    }, []);
