import './AddEditMassForm.scss';

import { ApolloError } from '@apollo/client';
import { DumpLoadSubject } from '@common/abilities/projects';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { Button } from '~/@components/Button';
import { FormikFileUploadField } from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { CheckBoxField } from '~/@sochi-components/FormikFields/CheckBoxField';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { substanceEditStore } from '~/@store/substances';
import { getAddEditMassFormikConfig } from '~/@views/UserView/ProjectPage/ProjectPriceCalculator/AddEditMassForm/AddEditMassFormik';
import { useUserAbilities } from '~/contexts';
import i18n from '~/i18n';
import { canUpdateDumpLoad } from '~/utils/auth';

import { FormNames, IDumpLoad, IFormValues, IProject } from './AddEditMass.types';
import {
    AdminCommentField,
    CommonFields,
    DateFields,
    LandfillFields,
    MassAttributesFields,
    ModeSelector,
    SubstancesFields,
} from './FormFields';

export type AddEditMassProps = {
    onClose: (shouldRefetch: boolean) => void;
    handleError: (error: ApolloError) => Promise<never>;
    dumpLoad?: IDumpLoad;
    project: IProject;
};

const Spacer = () => <Grid item xs={12} />;

export const AddEditMassForm = (props: AddEditMassProps) => {
    const { element, className } = useFunctionalBem(AddEditMassForm);
    const { project, dumpLoad, onClose } = props;

    const abilityContext = useUserAbilities();
    const formik = useFormik(getAddEditMassFormikConfig(props));

    const readOnly = dumpLoad
        ? !canUpdateDumpLoad(abilityContext, project, dumpLoad, [DumpLoadSubject.FIELD_AFFECT_DESTINATION])
        : false;

    const { values } = formik;

    const disableContaminationLevels = !!(values.dumpType && !values.dumpType.contaminationLevelAvailable);

    const mode = values.mode;
    const canRequestQuote = values.canRequestQuote;
    const needMatch = values.matchWithPinpointer;

    useSyncEffect(
        () =>
            substanceEditStore.init(
                dumpLoad?.substances || [],
                dumpLoad ? !!dumpLoad.solidState : true,
                !!dumpLoad?.leachingState,
                readOnly,
                readOnly
            ),
        [dumpLoad, readOnly]
    );

    return (
        <DialogContent>
            <div className={className}>
                <FormikProvider value={formik}>
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h2 className={element('title')}>
                                    <LocalizableText code={'ProjectAddEditMass.title'} />
                                </h2>
                            </Grid>
                            <Grid item xs={12}>
                                <ModeSelector formik={formik} readOnly={readOnly} />
                            </Grid>
                            <CommonFields formik={formik} readOnly={readOnly} />
                            <Grid item xs={12}>
                                <CheckBoxField
                                    name={FormNames.matchWithPinpointer}
                                    label={
                                        <LabelWithHint
                                            label={<LocalizableText code={'ProjectAddEditMass.matchWithPinpointer'} />}
                                            hint={
                                                <LocalizableText code={'ProjectAddEditMass.matchWithPinpointerHint'} />
                                            }
                                        />
                                    }
                                    readOnly={readOnly}
                                />
                            </Grid>
                            {mode === 'outbound' && (
                                <>
                                    <Spacer />
                                    <SubstancesFields disabled={disableContaminationLevels} showLeaching={needMatch} />
                                    {needMatch && (
                                        <>
                                            <MassAttributesFields
                                                formik={formik}
                                                readOnly={readOnly}
                                                element={element}
                                            />
                                            <Spacer />
                                            <DateFields formik={formik} readOnly={readOnly} project={project} />
                                        </>
                                    )}
                                </>
                            )}
                            {!needMatch && <LandfillFields readonly={readOnly} />}
                            <Grid item xs={12}>
                                <AdminCommentField<IFormValues, FormNames.newComment>
                                    project={project}
                                    dumpLoad={dumpLoad}
                                    name={FormNames.newComment}
                                />
                            </Grid>
                            {!dumpLoad && (
                                <>
                                    <Grid item xs={12}>
                                        <FormikFileUploadField
                                            formik={formik}
                                            name={'analysisFile'}
                                            label={<LocalizableText code={'ProjectDocuments.addAnalysis'} />}
                                        />
                                    </Grid>
                                    <Spacer />
                                </>
                            )}
                            {canRequestQuote && (
                                <div className={element('request-quote')}>
                                    <CheckBoxField
                                        name={FormNames.requestQuote}
                                        label={
                                            <LabelWithHint
                                                label={<LocalizableText code={'ProjectAddEditMass.requestQuote'} />}
                                                hint={<LocalizableText code={'ProjectAddEditMass.requestQuoteHint'} />}
                                            />
                                        }
                                    />
                                </div>
                            )}
                            <Grid item xs={12} className={element('buttons-block')}>
                                <Button variant="outlined" color="primary" size="large" onClick={() => onClose(false)}>
                                    {i18n.cancel}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    disabled={readOnly}>
                                    <LocalizableText
                                        code={
                                            values.requestQuote && values.canRequestQuote
                                                ? 'saveRequestAndClose'
                                                : 'saveAndClose'
                                        }
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>
            </div>
        </DialogContent>
    );
};
