import { DespatchAdviceType } from '@common/enums';
import React from 'react';

import { Chip } from '~/@components/Chip';
import { DESPATCH_ADVICE_TYPE } from '~/@store/beastIntegration';
import i18n from '~/i18n';

type Props = { type: DespatchAdviceType };

export const DespatchAdviceTypeChip = ({ type }: Props) => {
    return (
        <Chip color={DESPATCH_ADVICE_TYPE[type]} variant="default">
            {i18n.DespatchAdviceType[type]}
        </Chip>
    );
};
