import { AbilityCan, AbilitySubjects, UserRole } from '../../enums';
import { IAbilityFunction } from '../abilities.types';
import type { ICaslCondition } from '../utils';
import { IUserCalculatedSubject } from './userTypes';

const { CRUD, READ } = AbilityCan;
const { ADMIN, EMPLOYEE, EXTERNAL_RECEIVER, DRIVER, EXCAVATOR_OPERATOR } = UserRole;

export function defineAbilitiesForUsers(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<IUserCalculatedSubject>;

    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) => _allow(can, AbilitySubjects.USER, shape);
    const forbid = (can: AbilityCan | AbilityCan[], shape: SubjectShape) => _forbid(can, AbilitySubjects.USER, shape);

    allow(CRUD, { relationRole: ADMIN });
    allow(READ, { relationRole: EMPLOYEE });
    forbid(READ, { relationRole: EMPLOYEE, subSubject: ADMIN });
    allow(CRUD, { relationRole: EMPLOYEE, subSubject: EXTERNAL_RECEIVER });
    allow(CRUD, { relationRole: EMPLOYEE, subSubject: EXCAVATOR_OPERATOR });
    allow(CRUD, { relationRole: EMPLOYEE, subSubject: DRIVER });
}
