import { gql } from '@apollo/client';

import { PageInfoFragment } from '~/@store/common/common.queries';

export const UserActivityPagedQuery = gql`
    query UserActivityPagedQuery($minutesToCut: Int, $connection: ConnectionInput) {
        activitiesPaged(minutesToCut: $minutesToCut, connection: $connection) {
            edges {
                cursor
                node {
                    id
                    user {
                        id
                        name
                        surname
                        email
                        role
                    }
                    lastActivityDate
                    minutesAgo
                }
            }
            pageInfo {
                ...PageInfoFragment
            }
            pageCount
            totalCount
        }
    }

    ${PageInfoFragment}
`;
