import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { RemoveLockMutation, RemoveLockMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from './locks.queries';
import { LockEntityType } from './locks.types';

type RemoveLockFunc = () => void;

export const useRemoveLockFunc = (entityType: LockEntityType, entityId: string): RemoveLockFunc => {
    const client = useApolloClient();

    return useCallback(() => {
        client
            .mutate<RemoveLockMutation, RemoveLockMutationVariables>({
                mutation: queries.RemoveLockMutation,
                variables: {
                    input: {
                        entityId,
                        entityType,
                    },
                },
            })
            .catch(handleApolloError);
    }, [client, entityType, entityId]);
};
