import { AbilitySubjects, UserContextRole, UserRole } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { IAbilityLandfill } from '../landfills';
import { IAbilityDumpLoad, IAbilityProject } from '../projects';
import {
    DeliveryLineContextRole,
    DeliveryLineSubject,
    IAbilityDeliveryLine,
    IDeliveryLineCalculatedSubject,
    IDeliveryLineSubject,
} from './deliveryLineTypes';

export const getDeliveryLineSubject = (
    user: IAbilityUser,
    deliveryLine?: IAbilityDeliveryLine | null,
    dumpLoad?: IAbilityDumpLoad | null,
    project?: IAbilityProject | null,
    landfill?: IAbilityLandfill | null,
    subjects?: IDeliveryLineSubject[]
): IDeliveryLineCalculatedSubject => {
    const relationRole: IDeliveryLineCalculatedSubject['relationRole'] = [user.role!];
    const subSubject = subjects || [];

    if (user.role === UserRole.DRIVER) {
        if (deliveryLine?.driverId === user.id) relationRole.push(DeliveryLineContextRole.AUTHOR);
        if (project?.userLinks.some(l => l.userId === user.id))
            relationRole.push(DeliveryLineContextRole.PROJECT_DRIVER);
    }

    if (user.role === UserRole.EXCAVATOR_OPERATOR) {
        if (deliveryLine?.excavatorOperatorId === user.id) relationRole.push(DeliveryLineContextRole.AUTHOR);

        if (project?.userLinks.some(l => l.userId === user.id))
            relationRole.push(DeliveryLineContextRole.PROJECT_EXCAVATOR_OPERATOR);
    }

    if (dumpLoad?.status) subSubject.push(dumpLoad.status);

    if (dumpLoad) {
        if (!dumpLoad.destination?.landfillId) subSubject.push(DeliveryLineSubject.NO_DESTINATION);
    }

    if (deliveryLine) subSubject.push(deliveryLine.status);

    if (landfill && landfill.receiverIds.includes(user.id)) relationRole.push(DeliveryLineContextRole.RECEIVER_USER);

    if (project && project.userLinks.some(l => l.userId === user.id && l.role === UserContextRole.PROJECT_USER))
        relationRole.push(DeliveryLineContextRole.SENDER_USER);

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.DELIVERY_LINE,
        relationRole,
        subSubject,
    };
};
