import { useQuery } from '@apollo/client';
import type { QueryResult } from '@apollo/client/react/types/types';

import type { SettingsQuery as ISettingsQuery } from '~/graphql';

import { SettingsQuery } from '../settings.queries';

export const useSettingsData = (): QueryResult<ISettingsQuery, {}> => {
    return useQuery<ISettingsQuery, {}>(SettingsQuery, {
        fetchPolicy: 'cache-and-network',
    });
};
