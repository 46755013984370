import React from 'react';

import { FormikAsyncAutocompleteField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { IDumpLoadSearchRecord, useDumpLoadSearchFunc } from '~/@store/dumpLoads';
import { LoadStatus } from '~/graphql';

import { useDeliveryLineFormikContext } from './DeliveryLineFormikCommon';

export const DeliveryLineEditOrderSelect = () => {
    const formik = useDeliveryLineFormikContext();

    const ordersSearch = useDumpLoadSearchFunc({ statuses: [LoadStatus.IN_PROGRESS] });

    return (
        <FormikAsyncAutocompleteField
            label={<LocalizableText code={'DeliveryLineCard.orderNumber'} />}
            name={'dumpLoad'}
            formik={formik}
            loadOptions={ordersSearch}
            renderOption={(dumpLoad: IDumpLoadSearchRecord) => dumpLoad.serialNumber}
            disabled={!formik.values.canSelectOrder || formik.initialValues.verified}
        />
    );
};
