import './MaterialsColumn.scss';

import { LandfillSubareaFields } from '@common/enums';
import React from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { withAbilities, WithAbilitiesProps } from '~/contexts';
import type { LandfillQuery_landfill_subareas } from '~/graphql';
import { showCustomDialog } from '~/services/dialog';
import { canChangeLandfillField, canReadLandfillField, canUpdateLandfill } from '~/utils/auth';
import { updateSubarea } from '~/utils/landfill';

import type { LandfillDetailsDataProps } from '../../LandfillPage';
import { MaterialCard } from '../MaterialCard';
import { MaterialForm } from '../MaterialForm';

type MaterialsColumnProps = WithAbilitiesProps & LandfillDetailsDataProps;

type State = {
    isOpen: boolean;
};

class MaterialsColumn extends React.Component<MaterialsColumnProps, State> {
    bem = getBem(this);

    deliveredAmountsMap = this.props.landfill.ordersInfo.reduce((acc, info) => {
        if (!info.subareaId) return acc;

        if (!acc.has(info.subareaId)) acc.set(info.subareaId, 0);

        acc.set(info.subareaId, acc.get(info.subareaId)! + info.deliveredVolume);

        return acc;
    }, new Map<string, number>());

    addSubarea = () => {
        const { landfill } = this.props;

        const ignoredPromise = showCustomDialog({
            render: closeDialog => (
                <MaterialForm landfill={landfill} subarea={null} onClose={closeDialog} withOnHold={false} />
            ),
        });
    };

    editSubarea = (subarea: LandfillQuery_landfill_subareas) => {
        const { landfill } = this.props;

        const ignoredPromise = showCustomDialog({
            render: closeDialog => (
                <MaterialForm landfill={landfill} subarea={subarea} onClose={closeDialog} withOnHold={false} />
            ),
        });
    };

    onHoldToggleSubarea = (subarea: LandfillQuery_landfill_subareas) => {
        const { landfill } = this.props;
        const ignoredPromise = updateSubarea(landfill, {
            id: subarea.id,
            onHold: !subarea.onHold,
        });
    };

    render() {
        const subareas = this.props.landfill.subareas;
        const { user, ability, landfill } = this.props;

        const { className, element } = this.bem;

        const readOnly = !canUpdateLandfill(user, ability, landfill);

        const canReadGrossPrices =
            canReadLandfillField(user, ability, landfill, LandfillSubareaFields.grossPriceBase) &&
            canReadLandfillField(user, ability, landfill, LandfillSubareaFields.grossPriceOver50cm) &&
            canReadLandfillField(user, ability, landfill, LandfillSubareaFields.grossPriceNotStackable);

        const canUpdateOnHold = canChangeLandfillField(user, ability, landfill, LandfillSubareaFields.onHold);

        return (
            <div className={className}>
                <SochiTitle title={<LocalizableText code={'Materials.material'} />} className={element('title')}>
                    <Button color="primary" variant="contained" disabled={readOnly} onClick={this.addSubarea}>
                        <LocalizableText code={'Materials.add'} />
                    </Button>
                </SochiTitle>
                {subareas.map(s => (
                    <MaterialCard
                        key={s.id}
                        subarea={s}
                        canReadGrossPrices={canReadGrossPrices}
                        canUpdateOnHold={canUpdateOnHold}
                        canUpdate={!readOnly}
                        onEdit={this.editSubarea}
                        onHoldToggle={this.onHoldToggleSubarea}
                        currentAmount={this.deliveredAmountsMap.get(s.id) || 0}
                    />
                ))}
            </div>
        );
    }
}

export default withAbilities(MaterialsColumn);
