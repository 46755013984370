import React from 'react';

import { BiSideLayout } from '~/@components/BiSideLayout';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { CustomScript, useCustomScriptsData } from '~/@store/settings';
import { CustomScriptForm } from '~/@views/AdminView/SettingsPage/CustomScripts/CustomScriptForm';

import { CustomScriptsList } from './CustomScriptsList';

export const CustomScripts = () => {
    const query = useCustomScriptsData();

    const [selectedId, setSelectedId] = React.useState<null | string>(null);

    return (
        <QueryStateHandler query={query} dataGetter={query => query.data?.allCustomScripts}>
            {(scripts: CustomScript[]) => {
                const selected = scripts.find(s => s.id === selectedId) || null;

                return (
                    <BiSideLayout>
                        <BiSideLayout.LeftSideContent>
                            <CustomScriptsList
                                scripts={scripts}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                            />
                        </BiSideLayout.LeftSideContent>
                        <BiSideLayout.RightSideContent>
                            {selected && <CustomScriptForm script={selected} />}
                        </BiSideLayout.RightSideContent>
                    </BiSideLayout>
                );
            }}
        </QueryStateHandler>
    );
};
