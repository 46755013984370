import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const DoneAllIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path d="m11.416 16.17-3.47-3.47a.996.996 0 1 0-1.41 1.41l4.18 4.18c.39.39 1.02.39 1.41 0l10.58-10.58a.996.996 0 1 0-1.41-1.41l-9.88 9.87Z" />
            <rect x="16.537" y="5.592" width="2" height="9" rx="1" transform="rotate(45 16.537 5.592)" />
            <rect x="1.002" y="13.709" width="2" height="7" rx="1" transform="rotate(-45 1.002 13.71)" />
        </SvgIcon>
    );
};
