import React from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { ActionButton } from '~/@components/ActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { Page } from '~/@components/Page';
import { projectsStore } from '~/@store/projects/project.store';
import { showProjectCreateDialog } from '~/@views/AdminView/ProjectsPage/ProjectEditForm';

import { ProjectsTable } from './ProjectsTable';
import { ProjectViewModeSelect } from './ProjectViewModeSelect';

export const ProjectsPage: React.FC = () => {
    useSyncEffect(() => projectsStore.setProjectsPageViewMode());

    return (
        <Page>
            <Page.Header distributedHeader>
                <ProjectViewModeSelect />
                <ActionButton onClick={showProjectCreateDialog}>
                    <LocalizableText code={'ProjectForm.createProject'} />
                </ActionButton>
            </Page.Header>
            <Page.Content>
                <ProjectsTable />
            </Page.Content>
        </Page>
    );
};
