import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ArrowLeftIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <path
                d="M12 4.00125L13.41 5.41125L7.83 11.0013H20V13.0013H7.83L13.41 18.5913L12 20.0013L4 12.0013L12 4.00125Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
