import './NoDocumentation.scss';

import React, { VFC } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';

export const NoDocumentation: VFC<{
    className?: string;
}> = ({ className }) => {
    const { className: fullClassName } = useFunctionalBem(NoDocumentation, className);

    return (
        <div className={fullClassName}>
            <LocalizableText code={'ProjectDocuments.noDocumentationFound'} />
        </div>
    );
};
