import { ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import i18n from '~/i18n';

type Props = {
    valueGetter: () => boolean | null | void;
    setValue: (v: boolean | null) => void;
};

export const BooleanFilter = ({ valueGetter, setValue }: Props) => {
    const { selected } = useStyles();
    const value = useObservable(valueGetter);

    const handleTrueClick = useCallback(() => {
        setValue(value === true ? null : true);
    }, [value, setValue]);

    const handleFalseClick = useCallback(() => {
        setValue(value === false ? null : false);
    }, [value, setValue]);

    const handleClearClick = useCallback(() => {
        setValue(null);
    }, [setValue]);

    return (
        <>
            <ListItem button onClick={handleClearClick}>
                <ListItemText>---</ListItemText>
            </ListItem>
            <ListItem button onClick={handleTrueClick} className={value === true ? selected : undefined}>
                <ListItemText>{i18n.yes}</ListItemText>
            </ListItem>
            <ListItem button onClick={handleFalseClick} className={value === false ? selected : undefined}>
                <ListItemText>{i18n.no}</ListItemText>
            </ListItem>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    selected: {
        backgroundColor: theme.palette.background.default,
    },
}));
