import { getToxicLimitSubject } from '@common/abilities/toxicLimits';
import { AbilityCan } from '@common/enums';

import { useUserAbilities } from '~/contexts';

type UseToxicLimitsPermissions = {
    canCreate: boolean;
    canUpdate: boolean;
    canDelete: boolean;
};

export const useToxicLimitsPermissions = (): UseToxicLimitsPermissions => {
    const { user, ability } = useUserAbilities();

    return {
        canCreate: ability.can(AbilityCan.CREATE, getToxicLimitSubject(user)),
        canUpdate: ability.can(AbilityCan.UPDATE, getToxicLimitSubject(user)),
        canDelete: ability.can(AbilityCan.DELETE, getToxicLimitSubject(user)),
    };
};
