import './TopBar.scss';

import { AbilityCan, AbilitySubjects, UserRole } from '@common/enums';
import { adminRoutes } from '@common/routes';
import React, { useCallback, useState, VFC } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { COLOR } from '~/@sochi-components/@theme/styles';
import { ChevronDownIcon, GearsIcon } from '~/@sochi-components/Icons';
import { useUserAbilities } from '~/contexts';
import { ProjectQuery_project } from '~/graphql';
import i18n from '~/i18n';
import { canReadOffers } from '~/utils/auth';

import { OffersLink } from './OffersLink';
import { TopBarLogo } from './TopBarLogo';
import { TopBarMenuItem } from './TopBarMenuItem';
import { UserMenu } from './UserMenu';

type Props = {
    project?: ProjectQuery_project | null;
};

export const TopBar: VFC<Props> = ({ project }) => {
    const { className, element } = useFunctionalBem(TopBar);

    const [personMenu, setPersonMenu] = useState(false);
    const toggleMenu = useCallback(() => setPersonMenu(value => !value), []);

    const abilityContext = useUserAbilities();
    const { user, ability } = abilityContext;
    const canReadAdminPages = ability.can(AbilityCan.READ, AbilitySubjects.ADMIN_PAGES);
    const canReadOfferPage = project ? canReadOffers(abilityContext, project) : true;

    const renderCompany = () => {
        switch (user.role) {
            case UserRole.ADMIN:
            case UserRole.EMPLOYEE:
                return <LocalizableText code={'pinpointerAb'} />;
            case UserRole.EXTERNAL_RECEIVER:
                return user.customer?.name;
            default:
                return i18n.NA;
        }
    };

    return (
        <div className={className}>
            <TopBarLogo />
            {user && (
                <nav className={element('menu-wrapper')}>
                    {canReadOfferPage && <OffersLink project={project} />}
                    {canReadAdminPages && (
                        <TopBarMenuItem
                            name={<LocalizableText code={'admin'} />}
                            to={adminRoutes.base}
                            icon={<GearsIcon htmlColor={COLOR.brand} fontSize="inherit" />}
                        />
                    )}
                    <div className={element('personItem')} onClick={toggleMenu}>
                        <div className={element('avatar')}>
                            <div className={element('disc')}>{user.name[0]}</div>
                        </div>
                        <div className={element('personalInfo')}>
                            <div className={element('name')}>{user.name}</div>
                            <div className={element('company')}>{renderCompany()}</div>
                            <div className={element('caret', { opened: personMenu })}>
                                <ChevronDownIcon htmlColor={COLOR.brand} fontSize="inherit" />
                            </div>
                        </div>
                        {personMenu ? <UserMenu onMouseLeave={toggleMenu} /> : null}
                    </div>
                </nav>
            )}
        </div>
    );
};
