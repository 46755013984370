import { max, sum } from 'lodash';

import { ContaminationLevel, ContaminationType, SubstanceGroupBy } from '../enums';
import { compareString } from '../utils';
import { isNumber } from '../validations/types.validation';
import { ISubstanceAmount, ISubstanceAmountStrict, SubstanceLimitMap } from './toxicAnalysis.types';

export function calculateParentAmount(
    groupBy: SubstanceGroupBy | null,
    values: Array<number | null | undefined>
): number | null {
    const filteredValues = values.filter(v => !!v);
    if (!filteredValues.length) return null;

    switch (groupBy) {
        case SubstanceGroupBy.SUM:
            return sum(filteredValues);
        case SubstanceGroupBy.MAX:
        default:
            return max(filteredValues)!;
    }
}

export function getAmountsForLevel(
    contaminationType: ContaminationType,
    limitsMap: SubstanceLimitMap,
    level: ContaminationLevel
): ISubstanceAmount[] {
    return normalizeSubstanceAmounts(
        Object.entries(limitsMap).map(([substanceId, values]) => ({
            contaminationType,
            substanceId,
            amount: values[level] || null,
        })),
        contaminationType === ContaminationType.SOLID,
        contaminationType === ContaminationType.LEACHING
    );
}

export function normalizeSubstanceAmounts(
    substanceAmounts: Array<ISubstanceAmount>,
    solidState: boolean,
    leachingState: boolean
): Array<ISubstanceAmountStrict> {
    return substanceAmounts
        .filter(s => {
            switch (s.contaminationType) {
                case ContaminationType.SOLID:
                    return solidState;

                case ContaminationType.LEACHING:
                    return leachingState;

                default:
                    return false;
            }
        })
        .filter(s => isNumber(s.amount) && !!s.amount)
        .map(
            ({ contaminationType, substanceId, amount }) =>
                ({
                    contaminationType,
                    substanceId,
                    amount,
                } as ISubstanceAmountStrict)
        )
        .sort(
            (a, b) =>
                compareString(a.substanceId, b.substanceId) || compareString(a.contaminationType, b.contaminationType)
        );
}
