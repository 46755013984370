import { AbilitySubjects } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { BeastIntegrationSubject, IBeastIntegrationCalculatedSubject } from './beastIntegrationTypes';

export const getBeastIntegrationSubject = (
    user: IAbilityUser,
    subjects?: BeastIntegrationSubject[]
): IBeastIntegrationCalculatedSubject => {
    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.BEAST_INTEGRATION,
        relationRole: [user.role],
        subSubject: subjects || [],
    };
};
