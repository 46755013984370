import { makeStyles } from '@material-ui/core';
import React from 'react';

export const ToxicLimitsValueCellContainer: React.FC = ({ children }) => {
    const { root } = useStyles();

    return <div className={root}>{children}</div>;
};

const useStyles = makeStyles(theme => ({
    root: {
        width: 88,
        marginRight: theme.spacing(),
    },
}));
