import { gql } from '@apollo/client';

export const VehicleTypeFragment = gql`
    fragment VehicleTypeFragment on VehicleType {
        id
        kind
        name
        externalId
        fuelConsumptions {
            fuel
            value
        }
    }
`;

export const VehicleTypesQuery = gql`
    query VehicleTypes {
        vehicleTypes {
            ...VehicleTypeFragment
        }
    }

    ${VehicleTypeFragment}
`;

export const VehicleTypeCreateMutation = gql`
    mutation VehicleTypeCreate($input: VehicleTypeInput!) {
        vehicleTypeCreate(input: $input) {
            ...VehicleTypeFragment
        }
    }

    ${VehicleTypeFragment}
`;

export const VehicleTypeUpdateMutation = gql`
    mutation VehicleTypeUpdate($id: ID!, $input: VehicleTypeInput!) {
        vehicleTypeUpdate(id: $id, input: $input) {
            ...VehicleTypeFragment
        }
    }

    ${VehicleTypeFragment}
`;

export const VehicleTypeRemoveMutation = gql`
    mutation VehicleTypeRemove($id: ID!) {
        vehicleTypeRemove(id: $id)
    }
`;
