import { gql } from '@apollo/client';

export const LandfillStatusFragment = gql`
    fragment LandfillStatusFragment on LandfillStatus {
        id
        icon
        name
    }
`;

export const LandfillStatusesListQuery = gql`
    query LandfillStatusesListQuery($search: String, $first: Int, $after: ID) {
        landfillStatuses(search: $search, first: $first, after: $after) {
            ...LandfillStatusFragment
        }
    }

    ${LandfillStatusFragment}
`;

export const LandfillStatusQuery = gql`
    query LandfillStatusQuery($id: ID!) {
        landfillStatus(id: $id) {
            ...LandfillStatusFragment
        }
    }

    ${LandfillStatusFragment}
`;

export const LandfillStatusCreateMutation = gql`
    mutation LandfillStatusCreateMutation($input: LandfillStatusInput!) {
        landfillStatusCreate(input: $input) {
            ...LandfillStatusFragment
        }
    }

    ${LandfillStatusFragment}
`;

export const LandfillStatusUpdateMutation = gql`
    mutation LandfillStatusUpdateMutation($input: LandfillStatusInput!) {
        landfillStatusUpdate(input: $input) {
            ...LandfillStatusFragment
        }
    }

    ${LandfillStatusFragment}
`;

export const LandfillStatusRemoveMutation = gql`
    mutation LandfillStatusRemoveMutation($id: ID!) {
        landfillStatusRemove(id: $id)
    }
`;
