import { gql } from '@apollo/client';

export const CommentFragment = gql`
    fragment CommentFragment on Comment {
        id
        text
        date
        author {
            id
            name
            surname
        }
    }
`;
