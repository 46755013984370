import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback } from 'react';

import { useProgressState } from '~/@components/@hooks';
import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { LandfillWeightReceiptInfo } from '~/@user-store/weightReceipts/landfillWeightReceipts.store';
import { updateDeliveryLineBatch } from '~/@user-store/weightReceipts/weightReceiptsActions';
import { ISimpleBatch } from '~/@views/UserView/LandfillPage/common/LandfillBatchSelect';
import { BatchSelectForm } from '~/@views/UserView/LandfillPage/LandfillFollowUp/LandfillWeightReceipts/BatchSelectForm';
import { LandfillQuery_landfill } from '~/graphql';
import { showConfirmDialog, showCustomDialog } from '~/services/dialog';

type Props = {
    deliveryLine: LandfillWeightReceiptInfo;
    landfill: LandfillQuery_landfill;
    batch: ISimpleBatch | null;
    disabled?: boolean;
};

export const showSelectBatchDialog = (
    landfill: LandfillQuery_landfill,
    onSave: (batch: ISimpleBatch | null) => void,
    selected: ISimpleBatch | null
) =>
    showCustomDialog({
        render: closeDialog => (
            <BatchSelectForm landfill={landfill} onSave={onSave} onClose={closeDialog} selected={selected} />
        ),
    });

export const BatchAssignmentField: FC<Props> = ({ deliveryLine, landfill, batch, disabled = false }) => {
    const [isLoading, update] = useProgressState(updateDeliveryLineBatch);

    const { controls, buttons, batchName } = useStyles();

    const onSave = useCallback(
        async (newBatch: ISimpleBatch | null) => {
            if (!newBatch) {
                const answer = await showConfirmDialog({
                    title: <LocalizableText code={'ProjectDocuments.weightReceiptTable.detachFromBatchTitle'} />,
                    message: <LocalizableText code={'ProjectDocuments.weightReceiptTable.detachFromBatchMessage'} />,
                });

                if (!answer) return;
            }

            const ignored = update(landfill.id, deliveryLine.id, newBatch?.id || null);
        },
        [landfill, deliveryLine, update]
    );

    return (
        <>
            {batch ? (
                <div className={controls}>
                    <div className={batchName}>
                        <Text variant="BodyRegular">{batch.name}</Text>
                    </div>
                    <div className={buttons}>
                        <Button
                            onClick={() => showSelectBatchDialog(landfill, b => onSave(b), batch)}
                            size="small"
                            variant="contained"
                            color="primary"
                            disabled={disabled}>
                            <LocalizableText code={'ProjectDocuments.weightReceiptTable.change'} />
                        </Button>
                        <Button
                            onClick={() => onSave(null)}
                            size="small"
                            variant="outlined"
                            color="primary"
                            disabled={disabled}>
                            <LocalizableText code={'ProjectDocuments.weightReceiptTable.detach'} />
                        </Button>
                    </div>
                </div>
            ) : (
                <Button
                    onClick={() => showSelectBatchDialog(landfill, onSave, null)}
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={disabled}>
                    <LocalizableText code={'ProjectDocuments.weightReceiptTable.attach'} />
                </Button>
            )}
            {isLoading && <LinearProgress />}
        </>
    );
};

const useStyles = makeStyles(theme => ({
    controls: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        alignItems: 'flex-end',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'flex-end',
    },
    batchName: {
        maxWidth: '200px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
