import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

import { HelpIcon } from '../Icon';

interface HintTooltipProps {
    text: NonNullable<React.ReactNode>;
    className?: string;
    tooltipClassName?: string;
    Icon?: OverridableComponent<SvgIconTypeMap>;
}

export const HintTooltip = ({ text, className, tooltipClassName, Icon = HelpIcon }: HintTooltipProps) => {
    return (
        <Tooltip classes={{ tooltip: tooltipClassName }} title={text} leaveDelay={300} interactive>
            <Icon fontSize="small" className={className} />
        </Tooltip>
    );
};
