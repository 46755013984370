import React, { useCallback } from 'react';

import { ClickableText } from '~/@components/ClickableText/ClickableText';
import { TwoLinesFormatter } from '~/@components/Formatters';
import { TableCellPlaceholder } from '~/@components/Table/TableCellPlaceholder';
import { showCustomDialog } from '~/@store/common';
import { ICompany } from '~/@store/companies';
import i18n from '~/i18n';

import { CompanyCommentsDialog } from '../CompanyCommentsView';

type Props = {
    company: ICompany;
};

export const CompanyCommentsCell = ({ company }: Props) => {
    const openComments = useCallback(() => {
        const ignoredPromise = showCustomDialog({
            render: closeDialog => <CompanyCommentsDialog onClose={closeDialog} companyId={company.id} />,
        });
    }, [company]);

    return (
        <ClickableText onClick={openComments}>
            {company.lastCommentText ? (
                <TwoLinesFormatter>{company.lastCommentText}</TwoLinesFormatter>
            ) : (
                <TableCellPlaceholder startText="+">{i18n.addComment}</TableCellPlaceholder>
            )}
        </ClickableText>
    );
};
