import './SochiTooltip.scss';

import React, { ReactNode } from 'react';

import { getBem } from '~/@sochi-components/@bem';

type SochiTooltipProps = {
    content: string | ReactNode;
    children: ReactNode;
    className?: string;
    disabled?: boolean;
};

type SochiTooltipState = {
    open: boolean;
};

export class SochiTooltip extends React.Component<SochiTooltipProps, SochiTooltipState> {
    state = {
        open: false,
    };

    bem = getBem(this);

    getMouseHandler = (open: boolean) => () => {
        this.setState({ open });
    };

    render() {
        const { children, content, disabled = false } = this.props;

        if (disabled) return children;
        const { className, element } = this.bem;

        return (
            <div
                className={className}
                onMouseEnter={this.getMouseHandler(true)}
                onMouseLeave={this.getMouseHandler(false)}>
                {children}
                <div className={element('tool-tip', { open: this.state.open })}>{content}</div>
            </div>
        );
    }
}
