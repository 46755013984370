import React from 'react';
import { useParams } from 'react-router';

import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { ProjectDetailParams } from '~/@store/routes';
import { IDetailedUser, useDetailedUser } from '~/@store/users';

import { UserDetailContext } from './UserDetailContext';
import { UserTabs } from './UserTabs';

export const UserDetailPage = () => {
    const { id: userId } = useParams<ProjectDetailParams>();

    const userQuery = useDetailedUser(userId);

    return (
        <QueryStateHandler
            query={userQuery}
            dataGetter={query => query.data?.user}
            emptyContentMessage="errorCodes.USER_NOT_FOUND">
            {(user: IDetailedUser) => (
                <UserDetailContext.Provider value={user}>
                    <UserTabs />
                </UserDetailContext.Provider>
            )}
        </QueryStateHandler>
    );
};
