import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useMemo } from 'react';

import { usePdfExport } from '~/@components/@hooks';
import { Button } from '~/@components/Button';
import { useBatchTransfers } from '~/@user-store/landfillBatches';
import { getReportData } from '~/@user-store/landfillBatches/landfillBatchReport.helpers';
import { LandfillBatchReportView } from '~/@views/UserView/LandfillPage/common/LandfillBatchReport/LandfillBatchReportView';
import { LandfillFragment } from '~/graphql';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';

type Props = {
    projectName: string;
    landfill: LandfillFragment;
    onClose: () => void;
};

const LandfillBatchReportDialog = ({ landfill, onClose, projectName }: Props) => {
    const { data, loading } = useBatchTransfers(landfill.id);

    const transfers = data?.batchTransfers;

    const reportData = useMemo(
        () =>
            transfers
                ? getReportData(
                      {
                          lines: landfill.deliveryLinesInfo || [],
                          orders: landfill.ordersInfo,
                          transfers,
                      },
                      projectName
                  )
                : { rows: [] },
        [landfill, projectName, transfers]
    );

    const [ExportContainer, exportPdf] = usePdfExport(`traceability-report-for-${projectName}.pdf`);

    if (loading) return null;

    return (
        <Dialog fullScreen open={true}>
            <DialogTitle>
                {i18n.LandfillPage.batchReport.title}: {projectName}
            </DialogTitle>
            <DialogContent>
                <ExportContainer>
                    <LandfillBatchReportView data={reportData} />
                </ExportContainer>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={exportPdf}>
                    {i18n.export}
                </Button>
                <Button variant="contained" onClick={onClose}>
                    {i18n.close}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const showLandfillBatchReportDialog = (landfill: LandfillFragment, projectName: string) =>
    showCustomDialog({
        noModalComponent: true,
        render: closeDialog => (
            <LandfillBatchReportDialog projectName={projectName} landfill={landfill} onClose={closeDialog} />
        ),
    });
