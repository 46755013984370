import React, { memo, VFC } from 'react';

import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { ILandfill } from '~/@store/subareas';
import i18n from '~/i18n';

import { showLandfillReceiversSelectDialog } from '../LandfillReceiversSelectDialog';

type SubareaReceiversCellProps = {
    landfill: ILandfill;
};

export const SubareaReceiversCell: VFC<SubareaReceiversCellProps> = memo(({ landfill }) => {
    const mapReceivers = (receivers: { name: string }[] | null) => {
        if (!receivers) return null;
        switch (receivers.length) {
            case 0:
                return null;
            case 1:
                return receivers[0]!.name;
            default:
                return i18n.SubareasTable.receiversMany
                    .replace('{}', receivers[0]!.name)
                    .replace('{}', (receivers.length - 1).toString());
        }
    };

    const actions: ITableCellAction[] = [
        {
            variant: ActionVariant.EDIT,
            onClick: () => {
                const ignoredPromise = showLandfillReceiversSelectDialog(landfill.id);
            },
        },
    ];

    return <TableCellActions actions={actions}>{mapReceivers(landfill.receivers)}</TableCellActions>;
});
