import { FormLabel, Grid } from '@material-ui/core';
import React from 'react';

import { PhoneFormatter, UserFormatter } from '~/@components/Formatters';
import {
    FormikBooleanField,
    FormikDateField,
    FormikFileUploadField,
    FormikNumberField,
    FormikSelectField,
    FormikTextField,
} from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { LockEntityType, WithLock } from '~/@store/locks';
import { DeliveryLineVehicleTypeSelect } from '~/@views/AdminView/DeliveryLinesPage/DeliveryLineEditForm/DeliveryLineVehicleTypeSelect';
import { commonPrecisions, Engine, Fuel } from '~/config/enum';

import { DeliveryLineEditCompanySelect } from './DeliveryLineEditCompanySelect';
import { DeliveryLineEditOrderSelect } from './DeliveryLineEditOrderSelect';
import { useDeliveryLineFormikContext } from './DeliveryLineFormikCommon';

const engineItems = [Engine.Euro7, Engine.Euro6, Engine.Euro5, Engine.Euro4];
const fuelItems = [Fuel.Diesel, Fuel.Electric, Fuel.HVO100];

export const DeliveryLineEditFields = () => {
    const formik = useDeliveryLineFormikContext();

    const isVerified = formik.initialValues.verified;

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DeliveryLineEditOrderSelect />
                </Grid>
            </Grid>
            <WithLock entityType={LockEntityType.ORDER} entityId={formik.values.dumpLoad?.serialNumber}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <FormikNumberField
                            label={<LocalizableText code={'DeliveryLineCard.weight'} />}
                            precision={commonPrecisions.amount}
                            name="weight"
                            min={0}
                            formik={formik}
                            disabled={isVerified}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormikDateField
                            label={<LocalizableText code={'DeliveryLineCard.date'} />}
                            name="date"
                            formik={formik}
                            disabled={isVerified}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <DeliveryLineEditCompanySelect />
                    </Grid>

                    <Grid item xs={4}>
                        <FormikTextField
                            label={<LocalizableText code={'DeliveryLineCard.truckRegistrationNumber'} />}
                            name="truckRegistrationNumber"
                            formik={formik}
                            disabled={isVerified}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <FormikNumberField
                            label={<LocalizableText code={'DeliveryLineCard.truckCapacity'} />}
                            name="truckCapacity"
                            precision={commonPrecisions.amount}
                            min={0}
                            formik={formik}
                            disabled={isVerified}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <FormikTextField
                            label={<LocalizableText code={'DeliveryLineCard.customerInvoice'} />}
                            name="customerInvoice"
                            formik={formik}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <FormikTextField
                            label={<LocalizableText code={'DeliveryLineCard.transportInvoice'} />}
                            name="transportInvoice"
                            formik={formik}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <FormikTextField
                            label={<LocalizableText code={'DeliveryLineCard.landfillInvoice'} />}
                            name="landfillInvoice"
                            formik={formik}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <DeliveryLineVehicleTypeSelect />
                    </Grid>

                    <Grid item xs={4}>
                        <FormikSelectField
                            label={<LocalizableText code={'DeliveryLineCard.fuel'} />}
                            name={'fuel'}
                            formik={formik}
                            items={fuelItems}
                            renderValue={i => i}
                            keyGetter={i => i}
                            addEmptyOption={false}
                            valueCaption={i => i}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <FormikSelectField
                            label={<LocalizableText code={'DeliveryLineCard.engine'} />}
                            name={'engine'}
                            formik={formik}
                            items={engineItems}
                            renderValue={i => i}
                            keyGetter={i => i}
                            addEmptyOption={false}
                            valueCaption={i => i}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <FormikBooleanField
                            label={<LocalizableText code={'DeliveryLineCard.verified'} />}
                            name="verified"
                            formik={formik}
                        />
                    </Grid>

                    <Grid item xs={8}>
                        <FormikTextField
                            label={<LocalizableText code={'DeliveryLineCard.comment'} />}
                            name="comment"
                            formik={formik}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormikFileUploadField
                            label={<LocalizableText code={'DeliveryLineCard.receipt'} />}
                            name="receipt"
                            formik={formik}
                            disabled={isVerified}
                        />
                    </Grid>
                    {formik.values.deliveryLine?.driver ? (
                        <>
                            <Grid item xs={6}>
                                <FormLabel>
                                    <LocalizableText code="UserRole.DRIVER" />
                                </FormLabel>
                                <Text>
                                    <UserFormatter>{formik.values.deliveryLine.driver}</UserFormatter>
                                </Text>
                            </Grid>
                            <Grid item xs={6}>
                                <FormLabel>
                                    <LocalizableText code="DriverCard.phoneNumber" />
                                </FormLabel>
                                <Text>
                                    <PhoneFormatter>{formik.values.deliveryLine.driver.phoneNumber}</PhoneFormatter>
                                </Text>
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </WithLock>
        </>
    );
};
