import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { COLOR } from '~/@sochi-components/@theme';
import { ArrowDownIcon } from '~/@sochi-components/Icons';

import { PageNavMenuProps } from './PageNavMenu.types';

export const PageNavDesktopMenu = ({
    onBack,
    onBackLabel = <LocalizableText code={'back'} />,
    titleLabel,
    title: titleText,
    items,
}: PageNavMenuProps) => {
    const { root, menuItem, button, titleContainer, title, menuGroup, selected } = useStyles();

    const location = useLocation();

    return (
        <div className={root}>
            <div className={menuGroup}>
                {onBack && (
                    <div className={button}>
                        <Button
                            color="info"
                            variant="outlined"
                            startIcon={<ArrowDownIcon fontSize="inherit" />}
                            onClick={onBack}>
                            {onBackLabel}
                        </Button>
                    </div>
                )}
                <div className={titleContainer}>
                    {titleLabel && <Text color="textSecondary">{titleLabel}: </Text>}
                    <div className={title}>{titleText}</div>
                </div>
            </div>
            <div className={menuGroup}>
                {items?.map(item => (
                    <Link
                        key={item.url}
                        to={item.url}
                        className={cn(menuItem, { [selected]: location && location.pathname.includes(item.url) })}>
                        {item.title}
                    </Link>
                ))}
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 20px',
        borderTop: `1px solid ${theme.palette.primary.contrastText}`,
        background: theme.palette.secondary.main,
        gap: '60px',
    },
    menuGroup: {
        display: 'flex',
        flexDirection: 'row',
        gap: '38px',
        alignItems: 'center',
    },
    button: {
        padding: '20px 0',
        '& .MuiButton-outlined': {
            fontSize: '16px',
            paddingLeft: '16px',
            paddingRight: '24px',
        },
        '& .MuiSvgIcon-root': {
            transform: 'rotate(90deg)',
            fontSize: '20px',
        },
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'baseline',
    },
    title: {
        fontSize: '16px',
        color: theme.palette.primary.contrastText,
        fontFamily: theme.typography.fontFamily,
    },
    menuItem: {
        minHeight: '80px',
        height: '100%',
        fontSize: '14px',
        color: theme.palette.primary.contrastText,
        borderBottom: `4px solid ${COLOR.transparent}`,
        fontFamily: theme.typography.fontFamily,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',

        '&:hover, &:focus': {
            borderBottomColor: COLOR.orange,
            color: theme.palette.primary.contrastText,
            textDecoration: 'none',
        },
    },
    selected: {
        borderBottomColor: COLOR.orange,
    },
}));
