import { uniqBy } from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { makeStyles } from '~/@components/@theme';
import { MultiSelect } from '~/@sochi-components/MultiSelect';
import { COLOR } from '~/@views/UserView/ProjectPage/ProjectDashboard/dashboard.theme';
import { ProjectDeliveryItem } from '~/@views/UserView/ProjectPage/ProjectDashboard/dashboardFilter.store';
import i18n from '~/i18n';
import { getGradientBetweenColors } from '~/utils/color';
import { fmtVolInTon } from '~/utils/numbers';

import { DashboardNoData, DashboardTitle, DashboardToolTip } from '../common';
import { ProjectDashboardFilterItem } from '../ProjectDashboard';

type Props = {
    lines: ProjectDeliveryItem[];
    idGetter: (l: ProjectDeliveryItem) => string | undefined;
    nameGetter: (l: ProjectDeliveryItem) => string;
    title: React.ReactNode;
    sectionPlaceHolder: string;
    useFilter?: boolean;
};

type Series = {
    name: string;
    [key: string]: unknown;
};

export const ProjectSectionDashboard: FC<Props> = ({
    lines,
    idGetter,
    nameGetter,
    title,
    sectionPlaceHolder,
    useFilter = false,
}) => {
    const { chart, header, filter, filters } = useStyles();

    const [selectedItems, setSelectedItems] = useState<ProjectDashboardFilterItem[]>([]);

    const filteredLines = useMemo(
        () =>
            lines.filter(
                l => !useFilter || selectedItems.length === 0 || selectedItems.some(i => i.id === idGetter(l))
            ),
        [lines, selectedItems, idGetter, useFilter]
    );

    const [series, dataKeys] = useMemo(() => {
        const chartContent = filteredLines.reduce(
            (
                {
                    data,
                    dataKeys,
                }: {
                    data: Map<string, Series>;
                    dataKeys: string[];
                },
                line
            ) => {
                const id = idGetter(line);
                if (!id) return { data, dataKeys };

                const name = nameGetter(line);

                if (!data.has(id)) {
                    data.set(id, {
                        name,
                    });
                }

                const curSeries: Series = data.get(id)!;
                const value = line.value;

                const key = line.serialNumber;

                if (curSeries.hasOwnProperty(key)) {
                    curSeries[key] = (curSeries[key] as number) + value;
                } else {
                    curSeries[key] = value;
                }

                if (!dataKeys.includes(key)) dataKeys.push(key);

                return { data, dataKeys };
            },
            { data: new Map<string, Series>(), dataKeys: [] }
        );

        return [
            Array.from(chartContent.data.values()).sort((a, b) => a.name.localeCompare(b.name)),
            chartContent.dataKeys.sort().map((k, ind) => ({
                key: k,
                color: getGradientBetweenColors(
                    COLOR.primaryStartColor,
                    COLOR.primaryEndColor,
                    chartContent.dataKeys.length === 1 ? 0 : ind / (chartContent.dataKeys.length - 1)
                ),
            })),
        ];
    }, [filteredLines, idGetter, nameGetter]);

    const sections = useMemo(() => {
        const allSections = lines
            .map(l => ({ id: idGetter(l), name: nameGetter(l) }))
            .filter((l): l is ProjectDashboardFilterItem => !!l.id);

        return uniqBy(allSections, ({ id }) => id);
    }, [lines, idGetter, nameGetter]);

    return (
        <div className={chart}>
            <div className={header}>
                <DashboardTitle>{title}</DashboardTitle>
                {useFilter && (
                    <div className={filters}>
                        <MultiSelect
                            items={sections}
                            selected={selectedItems}
                            onSelect={setSelectedItems}
                            keyGetter={s => s.id}
                            renderItem={s => s.name}
                            placeholder={sectionPlaceHolder}
                            className={filter}
                            disabled={series.length === 0}
                        />
                    </div>
                )}
            </div>
            {series.length === 0 ? (
                <DashboardNoData />
            ) : (
                <ResponsiveContainer height={220}>
                    <BarChart data={series}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip
                            content={
                                <DashboardToolTip
                                    labelFormatter={v => `${i18n.ProjectDashboard.serialNumber}: ${v}`}
                                    valueFormatter={fmtVolInTon}
                                />
                            }
                            shared={false}
                        />
                        {dataKeys.map((k, ind) => (
                            <Bar dataKey={k.key} stackId="a" fill={k.color} key={ind} />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    chart: {
        height: 'calc(50vh - 160px)',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 8,
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    filters: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '8px',
    },
    filter: {
        width: '200px',
    },
}));
