import { withStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';

import { COLORS } from '~/@components/@theme';
import { COLOR } from '~/@sochi-components/@theme';

export const AuthSochiLabel = withStyles({
    root: {
        color: `${COLORS.white} !important`,
        '&.Mui-error.Mui-error': {
            color: `${COLOR.orangeLight} !important`,
        },
    },
})(InputLabel);
