import { ContaminationLevel } from '@common/enums';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { FC, useCallback } from 'react';

import { Button } from '~/@components/Button';
import { FormikDateField, FormikFileUploadField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { Select } from '~/@components/Select';
import { Text } from '~/@components/Text';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { useBatchAnalysisCreate, useBatchAnalysisUpdate } from '~/@user-store/landfillBatches';
import { getLandfillBatchAnalysisFormikConfig } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchAnalysisForm/LandfillBatchAnalysisFormik';
import { FormNames } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchAnalysisForm/LandfillBatchAnalysisFormik.types';
import { IBatch } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm';
import { IAnalysis } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/landfillBatchLog.types';
import { BatchAnalysisInput, LandfillQuery_landfill } from '~/graphql';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';

type Props = {
    landfill: LandfillQuery_landfill;
    batchId: string;
    analysis: IAnalysis | null;
    onClose: () => void;
};

const LandfillBatchAnalysisForm: FC<Props> = ({ landfill, batchId, analysis, onClose }) => {
    const [create, isCreating] = useBatchAnalysisCreate(landfill.id, batchId);
    const [update, isUpdating] = useBatchAnalysisUpdate(landfill.id, batchId);

    const onCreate = useCallback(
        async (input: BatchAnalysisInput) => {
            if (analysis) return;

            create(input).then(onClose);
        },
        [analysis, create, onClose]
    );

    const onUpdate = useCallback(
        async (input: BatchAnalysisInput) => {
            if (!analysis) return;

            update(analysis.id, input).then(onClose);
        },
        [analysis, update, onClose]
    );

    const formik = useFormik(getLandfillBatchAnalysisFormikConfig(batchId, analysis, analysis ? onUpdate : onCreate));
    const { setFieldValue, values } = formik;

    const { buttons } = useStyles();

    const disabled = isCreating || isUpdating;

    return (
        <DialogContent>
            <FormikProvider value={formik}>
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Text variant="TitleBold">
                                <LocalizableText
                                    code={
                                        analysis
                                            ? 'LandfillPage.analysisCard.editAnalysis'
                                            : 'LandfillPage.analysisCard.createAnalysis'
                                    }
                                />
                            </Text>
                        </Grid>
                        <Grid item xs={6}>
                            <FormikDateField
                                formik={formik}
                                name={FormNames.date}
                                label={<LocalizableText code={'LandfillPage.analysisCard.date'} />}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                items={[
                                    ContaminationLevel.na,
                                    ContaminationLevel.mrr,
                                    ContaminationLevel.km,
                                    ContaminationLevel.mkm,
                                    ContaminationLevel.ifa,
                                    ContaminationLevel.fa,
                                ]}
                                label={<LocalizableText code={'LandfillPage.analysisCard.contaminationLevel'} />}
                                value={values.result}
                                onChange={v => setFieldValue(FormNames.result, v)}
                                keyGetter={v => v}
                                renderValue={v => i18n.contaminationValues[v || ContaminationLevel.na]}
                                addEmptyOption={false}
                                disabled={disabled}
                                emptyLabel={<LocalizableText code={'contaminationValues.select'} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikFileUploadField
                                label={<LocalizableText code={'LandfillPage.analysisCard.report'} />}
                                name="file"
                                formik={formik}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={buttons}>
                                <Button variant="outlined" onClick={onClose}>
                                    {i18n.cancel}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={formik.isSubmitting || !formik.dirty}>
                                    {i18n.save}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </DialogContent>
    );
};

const useStyles = makeStyles(() => ({
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));

export const showLandfillBatchAnalysisForm = (
    landfill: LandfillQuery_landfill,
    batch: IBatch,
    analysis: IAnalysis | null
) =>
    showCustomDialog({
        render: closeDialog => (
            <LandfillBatchAnalysisForm
                landfill={landfill}
                batchId={batch.id}
                analysis={analysis}
                onClose={closeDialog}
            />
        ),
    });
