import './CreateWizardOption.scss';

import React, { VFC } from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import { COLOR } from '~/@sochi-components/@theme/styles';
import { ArrowForwardIcon } from '~/@sochi-components/Icons';

type Props = {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    onClick: () => void;
};

export const CreateWizardOption: VFC<Props> = ({ title, subtitle, onClick }) => {
    const { className, element } = useFunctionalBem(CreateWizardOption);

    return (
        <div className={className} onClick={onClick}>
            <div className={element('info')}>
                <div className={element('title')}>{title}</div>
                <div className={element('subtitle')}>{subtitle}</div>
            </div>
            <div className={element('button')}>
                <ArrowForwardIcon htmlColor={COLOR.white} fontSize="inherit" />
            </div>
        </div>
    );
};
