import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';

type Props = {
    onDragStart: () => void;
    onDrag: (delta: number) => void;
    onDragEnd: () => void;
};

export const Resizer = ({ onDragStart, onDrag, onDragEnd }: Props) => {
    const { root, dragging } = useStyles();
    const [isDragging, setDragging] = useState(false);
    const startX = useRef(0);
    const animationRef = useRef(false);

    const onMouseDown: MouseEventHandler = event => {
        event.preventDefault();
        setDragging(true);
        startX.current = event.clientX;
        onDragStart();
    };

    const onMouseMove = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            if (animationRef.current) return;

            animationRef.current = true;
            requestAnimationFrame(() => {
                onDrag(event.clientX - startX.current);
                animationRef.current = false;
            });
        },
        [onDrag]
    );

    const onMouseUp = useCallback(() => {
        setDragging(false);
        onDragEnd();
    }, [onDragEnd]);

    useEffect(() => {
        if (!isDragging) return;

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, [isDragging, onMouseMove, onMouseUp]);

    return <div className={cn(root, { [dragging]: isDragging })} onMouseDown={onMouseDown} />;
};

const useStyles = makeStyles(() => ({
    root: {
        position: 'absolute',
        right: 0,
        top: 0,
        width: 12,
        height: '100%',
        cursor: 'ew-resize',
    },
    dragging: {
        cursor: 'grabbing',
    },
}));
