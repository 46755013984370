import { LinearProgress } from '@material-ui/core';
import React, { useCallback } from 'react';

import { IComment } from '~/@store/comments';
import { useUserCommentCreateMutation } from '~/@store/users';
import { CommentsBlock } from '~/@views/AdminView/common/CommentsBlock';

type Props = {
    className?: string;
    comments: IComment[];
    userId: string;
};

export const UserComments = ({ className, comments, userId }: Props) => {
    const [userCommentCreate, loading] = useUserCommentCreateMutation();

    const onNewComment = useCallback((text: string) => userCommentCreate(userId, text), [userId, userCommentCreate]);

    return (
        <div className={className}>
            {loading && <LinearProgress />}
            <CommentsBlock readOnly={loading} comments={comments} onNewComment={onNewComment} />
        </div>
    );
};
