import { formatCoordinates, formatCurrency, formatDistance, formatNumber, formatTonns } from '@common/utils/formaters';

export const replaceSpaceWithNbsp = (value: string) => value.replace(/\s/g, String.fromCharCode(160));

export const fmtDecimal = (value: number | null | undefined, precision = 0): string =>
    formatNumber(value, precision, false);

export const fmtCurr = formatCurrency;

export const fmtDist = formatDistance;

export const fmtVolInTon = formatTonns;

export const fmtCoordinates = formatCoordinates;
