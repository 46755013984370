import type { IDeviationStatus } from '@common/enums';
import { InputLabel, makeStyles } from '@material-ui/core';
import type { FormikProps } from 'formik';
import { Form, FormikErrors } from 'formik';
import React from 'react';

import { Button } from '~/@components/Button';
import { FormikContextTextField } from '~/@components/FormikFields';
import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { TextField } from '~/@components/TextField';
import { COLOR } from '~/@sochi-components/@theme';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { ImagesInput } from '~/@sochi-components/ImagesInput';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { DialogTitle } from '~/@sochi-components/SochiDialog/DialogTitle';
import { SochiDivider } from '~/@sochi-components/SochiDivider';
import {
    DeviationAbilities,
    DeviationMutations,
    FormActions,
    FormNames,
    IDeviation,
    IFileDoc,
    IFormValues,
} from '~/@user-store/deviations';
import client from '~/apolloClient';
import { DeviationStatus } from '~/graphql';
import i18n from '~/i18n';
import { showConfirmDialog } from '~/services/dialog';
import { formatDate } from '~/utils/date';
import { ImageForUpload } from '~/utils/ImageForUpload';

const prepareFileError = (
    filesError?: string | string[] | FormikErrors<ImageForUpload> | FormikErrors<ImageForUpload>[]
): string | undefined => {
    if (Array.isArray(filesError)) {
        return prepareFileError(filesError[0]);
    }

    if (typeof filesError === 'string') {
        return filesError;
    }
};

export type DeviationFormProps = {
    closeDialog: () => void;
    deviation?: IDeviation | null | undefined;
    mutations: DeviationMutations;
    parentEntity: { id: string; name: string };
    parentEntityLabel: React.ReactNode;
    abilities: DeviationAbilities;
    photoUrl: string;
};

export const DeviationForm = (props: FormikProps<IFormValues> & DeviationFormProps) => {
    const { root, twoColumns, status, commentsContainer, commentAuthor, commentText, newComment } = useStyles();

    const getActionHandler =
        (action: FormActions, targetStatus?: IDeviationStatus) => (event?: React.MouseEvent<HTMLButtonElement>) => {
            event && event.preventDefault();
            const { setFieldValue, handleSubmit } = props;
            setFieldValue('action', action);
            if (targetStatus) setFieldValue(FormNames.status, targetStatus);
            setTimeout(handleSubmit);
        };

    const getUpdateLabel = (deviation: IDeviation) => {
        switch (deviation.status) {
            case DeviationStatus.DRAFT:
                return <LocalizableText code={'submit'} />;
            case DeviationStatus.CLOSED:
                return <LocalizableText code={'DeviationForm.reopen'} />;
            default:
                return <LocalizableText code={'update'} />;
        }
    };

    const handleNativeSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
        event.preventDefault();
        const {
            values: { deviation },
        } = props;

        if (!deviation) return getActionHandler(FormActions.CREATE, DeviationStatus.REQUESTED)();

        switch (deviation.status) {
            case DeviationStatus.DRAFT:
                return getActionHandler(FormActions.UPDATE)();
            case DeviationStatus.REQUESTED:
            case DeviationStatus.ANSWERED:
                return getActionHandler(FormActions.COMMENT)();
            default:
                return;
        }
    };

    const handleFileDelete = async (file: IFileDoc) => {
        if (!props.values.deviation) return;
        const deviation: IDeviation = props.values.deviation;

        const isConfirmed = await showConfirmDialog({
            title: <LocalizableText code={'confirm'} />,
            message: <LocalizableText code={'DeviationForm.confirmDelete'} />,
        });

        if (!isConfirmed) return;

        await props.mutations.removeFile(client, deviation, file);

        props.setFieldValue(FormNames.deviation, {
            ...deviation,
            photos: deviation.photos.filter(p => p.id !== file.id),
        });
    };

    const { values, errors, setFieldValue, abilities, parentEntity, parentEntityLabel } = props;
    const { deviation, newFiles } = values;
    const isDraft = deviation?.status === DeviationStatus.DRAFT;

    return (
        <div className={root}>
            <DialogTitle
                secondaryInfo={deviation && <div className={status}>{i18n.deviationStatus[deviation.status]}</div>}>
                {deviation ? (
                    `${i18n.DeviationForm.deviation} № ${deviation.ticketNumber}`
                ) : (
                    <LocalizableText code={'DeviationForm.newDeviation'} />
                )}
            </DialogTitle>
            <DialogContent>
                {deviation && (
                    <div className={twoColumns}>
                        <TextField label={parentEntityLabel} value={parentEntity.name} disabled fullWidth />
                        <TextField
                            label={<LocalizableText code={'Deviation.dateSent'} />}
                            value={formatDate(deviation.dateSent)}
                            disabled
                            fullWidth
                        />
                    </div>
                )}
                <Form onSubmit={handleNativeSubmit}>
                    {/** To properly handle native submit events, such as pressing Enter,
                             there must be exact one button with type = "submit".
                         */}
                    <button hidden type="submit" />
                    <FormikContextTextField<IFormValues, FormNames.issue>
                        name={FormNames.issue}
                        label={
                            <LabelWithHint
                                label={<LocalizableText code={'Deviation.issue'} />}
                                hint={<LocalizableText code={'Deviation.issueFieldHint'} />}
                            />
                        }
                        disabled={!abilities.canChange}
                    />
                    <FormikContextTextField<IFormValues, FormNames.description>
                        name={FormNames.description}
                        multiline
                        rows={4}
                        rowsMax={4}
                        label={
                            <LabelWithHint
                                label={<LocalizableText code={'Deviation.description'} />}
                                hint={<LocalizableText code={'Deviation.descriptionFieldHint'} />}
                            />
                        }
                        disabled={!abilities.canChange}
                    />
                    <FormikContextTextField<IFormValues, FormNames.suggestedSolution>
                        name={FormNames.suggestedSolution}
                        label={
                            <LabelWithHint
                                label={<LocalizableText code={'Deviation.suggestedSolution'} />}
                                hint={<LocalizableText code={'Deviation.suggestedSolutionFieldHint'} />}
                            />
                        }
                        disabled={!abilities.canChange && !abilities.canComment}
                    />
                    {Boolean(deviation?.comments.length) && (
                        <>
                            <InputLabel>
                                <LocalizableText code={'DeviationForm.conversationHistory'} />
                            </InputLabel>
                            <div className={commentsContainer}>
                                {deviation?.comments.map((comment, index) => (
                                    <div key={index}>
                                        <p className={commentAuthor}>
                                            {comment.author!.name || i18n.NA} [{formatDate(comment.date)}]:
                                        </p>
                                        <p className={commentText}>{comment.text}</p>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    <ImagesInput
                        filesOnServer={deviation?.photos || []}
                        onDelete={handleFileDelete}
                        params={{ deviationId: deviation?.id || '' }}
                        baseFilesUri={props.photoUrl}
                        canAdd={abilities.canChange}
                        canDelete={abilities.canChange}
                        newFiles={newFiles}
                        onChange={(files: ImageForUpload[]) => setFieldValue(FormNames.newFiles, files)}
                        errorMessage={prepareFileError(errors.newFiles)}
                    />

                    <ButtonGroup>
                        <Button color="primary" variant="outlined" size="large" onClick={props.closeDialog}>
                            <LocalizableText code={values.commentSubmitted ? 'close' : 'cancel'} />
                        </Button>
                        {!deviation && (
                            <>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    size="large"
                                    onClick={getActionHandler(FormActions.CREATE, DeviationStatus.DRAFT)}>
                                    <LocalizableText code={'saveAsDraft'} />
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    onClick={getActionHandler(FormActions.CREATE, DeviationStatus.REQUESTED)}>
                                    <LocalizableText code={'submit'} />
                                </Button>
                            </>
                        )}
                        {deviation && abilities.canChange && (
                            <>
                                {isDraft && (
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        size="large"
                                        onClick={getActionHandler(FormActions.UPDATE, DeviationStatus.DRAFT)}>
                                        <LocalizableText code={'saveAsDraft'} />
                                    </Button>
                                )}
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    onClick={getActionHandler(FormActions.UPDATE, DeviationStatus.REQUESTED)}>
                                    {getUpdateLabel(deviation)}
                                </Button>
                            </>
                        )}
                        {abilities.canClose && (
                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={getActionHandler(FormActions.CLOSE)}>
                                <LocalizableText code={'markAsResolved'} />
                            </Button>
                        )}
                    </ButtonGroup>
                    {abilities.canComment && (
                        <>
                            <SochiDivider />
                            <div className={newComment}>
                                <FormikContextTextField<IFormValues, FormNames.newComment>
                                    name={FormNames.newComment}
                                    label={<LocalizableText code={'DeviationForm.newComment'} />}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={getActionHandler(FormActions.COMMENT)}>
                                    <LocalizableText code={'send'} />
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </DialogContent>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        width: 560,
        [theme.breakpoints.down('md')]: {
            width: 'auto',
        },
    },
    twoColumns: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: '20px',
        marginBottom: theme.spacing(2),
    },
    status: {
        fontSize: '16px',
        padding: theme.spacing(1, 2),
        backgroundColor: COLOR.white,
    },
    commentsContainer: {
        backgroundColor: COLOR.grayLight3,
        padding: theme.spacing(2),
        wordBreak: 'break-word',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            padding: '11px 11px',
            fontSize: '14px',
        },
    },
    commentAuthor: {
        margin: 0,
        fontWeight: 'bold',
        paddingRight: '4px',
        color: COLOR.blackTransparent,
    },
    commentText: {
        color: COLOR.blackTransparent,
    },
    newComment: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'end',

        '& button': {
            margin: '2px 8px 20px',
        },
    },
}));
