import Card from '@material-ui/core/Card';
import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import { makeStyles } from '~/@components/@theme';
import { COLORS } from '~/@components/@theme/colors';

import { CornerArrowIcon } from '../Icon';

type CardListItemProps = PropsWithChildren<{
    isSubItem?: boolean;
    isActive?: boolean;
    onClick?: () => void;
    buttonEnd?: React.ReactNode;
    className?: string;
}>;

export const CardListItem = ({
    className,
    children,
    isSubItem,
    isActive = false,
    buttonEnd,
    onClick,
}: CardListItemProps) => {
    const { root, subItemIcon, card, active, selectable, content, addon } = useStyles();

    const isSelectable = !!onClick;

    return (
        <div className={cn(root, className)}>
            {isSubItem && <CornerArrowIcon className={subItemIcon} />}
            <Card className={cn(card, { [active]: isActive, [selectable]: isSelectable })}>
                <div className={content} onClick={onClick}>
                    {children}
                </div>
                {buttonEnd && <div className={addon}>{buttonEnd}</div>}
            </Card>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    subItemIcon: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(2),
    },
    card: {
        display: 'flex',
        flexGrow: 1,
        minHeight: 40,
        backgroundColor: COLORS.white,
        borderColor: COLORS.grayLight,
    },
    selectable: {
        backgroundColor: COLORS.grayLightest,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: COLORS.grayLighter,
            borderColor: COLORS.gray,
        },
    },
    active: {
        backgroundColor: COLORS.white,
        borderColor: COLORS.grayDarker,
        boxShadow: theme.customShadows[1],
        '&:hover': {
            backgroundColor: COLORS.white,
        },
    },
    content: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        flexGrow: 1,
    },
    addon: {
        width: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
    },
}));
