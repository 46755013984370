import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { UserVerifyMutation, UserVerifyMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../users.queries';

export type VerifyUserFunc = (input: UserVerifyMutationVariables) => Promise<unknown>;

export const useUserVerifyMutation = (): [VerifyUserFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<UserVerifyMutation, UserVerifyMutationVariables>(
        queries.UserVerifyMutation
    );

    const verifyUser = useCallback<VerifyUserFunc>(
        input => {
            return callMutation({
                variables: input,
            }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [verifyUser, mutationResult.loading];
};
