import { LandfillFields, UnitOfMeasure } from '@common/enums';
import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import { makeStyles } from '~/@components/@theme';
import {
    AmountFormatter,
    CurrencyFormatter,
    NullableFormatter,
    PercentFormatter,
    TwoLinesFormatter,
} from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn } from '~/@components/Table';
import { AdminLandfillColumn, ILandfill, landfillsStore, workTime } from '~/@store/landfills';

import { LandfillsHeadCell } from './LandfillsHeadCell';
import { LandfillNameCell } from './LandfillsRowCells';

export const useLandfillsColumns = (): ITableColumn<ILandfill>[] => {
    const { name, location } = useStyles();

    const hiddenColumns = useObservable(() => landfillsStore.hiddenColumns);
    const isHidden = useCallback((column: AdminLandfillColumn) => hiddenColumns.includes(column), [hiddenColumns]);

    return useMemo<ITableColumn<ILandfill>[]>(
        () => [
            {
                title: (
                    <LandfillsHeadCell
                        title={<LocalizableText code={'LandfillsTable.landfillName'} />}
                        fieldName={LandfillFields.name}
                        withFilter
                        withSort
                    />
                ),
                render: row => <LandfillNameCell landfill={row} />,
                cellClassName: name,
                hidden: isHidden(AdminLandfillColumn.name),
                name: AdminLandfillColumn.name,
            },
            {
                title: (
                    <LandfillsHeadCell
                        title={<LocalizableText code={'LandfillsTable.location'} />}
                        fieldName={LandfillFields.location}
                        withFilter
                        withSort
                    />
                ),
                render: row => row.location?.text,
                cellClassName: location,
                hidden: isHidden(AdminLandfillColumn.location),
                name: AdminLandfillColumn.location,
            },
            {
                title: <LocalizableText code={'LandfillsTable.openedAt'} />,
                render: row => workTime(row.workTime),
                hidden: isHidden(AdminLandfillColumn.openedAt),
                name: AdminLandfillColumn.openedAt,
            },
            {
                title: (
                    <LandfillsHeadCell
                        title={<LocalizableText code={'LandfillsTable.gateFee'} />}
                        fieldName={LandfillFields.gateFee}
                        withSort
                    />
                ),
                render: row => <CurrencyFormatter>{row.gateFee}</CurrencyFormatter>,
                hidden: isHidden(AdminLandfillColumn.gateFee),
                name: AdminLandfillColumn.gateFee,
            },
            {
                title: (
                    <LandfillsHeadCell
                        title={<LocalizableText code={'LandfillsTable.marginPercent'} />}
                        fieldName={LandfillFields.marginPercent}
                        withSort
                    />
                ),
                render: row => <PercentFormatter>{row.marginPercent}</PercentFormatter>,
                hidden: isHidden(AdminLandfillColumn.marginPercent),
                name: AdminLandfillColumn.marginPercent,
            },
            {
                title: (
                    <LandfillsHeadCell
                        title={<LocalizableText code={'LandfillsTable.minMargin'} />}
                        fieldName={LandfillFields.minMargin}
                        withSort
                    />
                ),
                render: row => <CurrencyFormatter>{row.minMargin}</CurrencyFormatter>,
                hidden: isHidden(AdminLandfillColumn.minMargin),
                name: AdminLandfillColumn.minMargin,
            },
            {
                title: <LocalizableText code={'LandfillsTable.capacity'} />,
                render: row => <AmountFormatter unit={UnitOfMeasure.TONNS}>{row.capacity}</AmountFormatter>,
                hidden: isHidden(AdminLandfillColumn.capacity),
                name: AdminLandfillColumn.capacity,
            },
            {
                title: <LocalizableText code={'LandfillsTable.user'} />,
                render: row => <NullableFormatter>{row.receivers?.length}</NullableFormatter>,
                hidden: isHidden(AdminLandfillColumn.user),
                name: AdminLandfillColumn.user,
            },
            {
                title: (
                    <LandfillsHeadCell
                        title={<LocalizableText code={'LandfillsTable.comments'} />}
                        fieldName={LandfillFields.comment}
                        withFilter
                        withSort
                    />
                ),
                render: row => <TwoLinesFormatter>{row.comment}</TwoLinesFormatter>,
                hidden: isHidden(AdminLandfillColumn.comment),
                name: AdminLandfillColumn.comment,
            },
        ],
        [isHidden, location, name]
    );
};

const useStyles = makeStyles(() => ({
    name: {
        maxWidth: 300,
    },
    location: {
        maxWidth: 300,
    },
}));
