import { gql } from '@apollo/client';

import { PageInfoFragment } from '~/@store/common/common.queries';
import { FileDocFragment } from '~/@user-store/queries/queries.fragment';

import { DumpTypeFragment } from '../dumpTypes/dumpType.queries';
import { LocationFragment } from '../locations/location.queries';
import { LandfillSubareaFragment } from '../subareas';
import { LandfillShortFragment } from './landfills.queries.fragment';

export const LandfillBatchFragment = gql`
    fragment LandfillBatchFragment on LandfillBatch {
        id
        name
        comment
        closedAt
        createdAt
        dumpType {
            id
            name
        }
    }
`;

export const LandfillFragment = gql`
    fragment LandfillFragment on Landfill {
        id
        name
        location {
            ...LocationFragment
        }
        gateFee
        minMargin
        marginPercent
        receiverIds
        receivers {
            id
            name
            surname
            email
            role
        }
        customerId
        customer {
            id
            name
        }
        workTime {
            dayNumber
            openFrom
            openTo
            isOpen
        }
        comment
        description
        status {
            id
            name
            icon
        }
        certificates {
            ...FileDocFragment
        }
        isDeleted
        capacity

        subareas {
            ...LandfillSubareaFragment
        }

        ordersInfo {
            id
            subareaId
            companyName
            projectName
            date
            orderNumber
            deliveredVolume
            pricePerTonn
            estimatedCO2
            volumes {
                weeks {
                    year
                    week
                    delivered
                    planned
                    text
                }
                months {
                    year
                    month
                    delivered
                    planned
                    text
                }
                years {
                    year
                    delivered
                    planned
                    text
                }
            }
            dumpLoad {
                id
                status
                amountInTons
                dumpType {
                    ...DumpTypeFragment
                }
                solidTestResult
                comment
                serialNumber
                inbound
            }
        }

        deliveryLinesInfo {
            id
            orderNumber
            weight
            receiptUrl
            truckRegistrationNumber
            plannedStartDate
            truckCapacity
            transportCompanyName
            receiverComment
            receiverCheck
            excavationDate
            startDeliveryDate
            endDeliveryDate
            status
            landfillInvoiceNumber
            inbound
            deliveryEvent {
                id
                photoUrl
            }
            batch {
                id
                name
            }
            driverComment
        }

        batches {
            ...LandfillBatchFragment
        }

        batches {
            ...LandfillBatchFragment
        }

        batches {
            ...LandfillBatchFragment
        }

        batches {
            ...LandfillBatchFragment
        }
    }

    ${LocationFragment}
    ${FileDocFragment}
    ${LandfillSubareaFragment}
    ${DumpTypeFragment}
    ${LandfillBatchFragment}
`;

export const LandfillLightFragment = gql`
    fragment LandfillLightFragment on Landfill {
        id
        name
        location {
            text
        }
        status {
            id
            name
            icon
        }
        isDeleted
    }
`;

export const AdminLandfillsPagedQuery = gql`
    query LandfillsPagedQuery($filter: LandfillFilter, $sort: SortInput, $connection: ConnectionInput) {
        landfillsPaged(filter: $filter, sort: $sort, connection: $connection) {
            edges {
                cursor
                node {
                    ...LandfillShortFragment
                }
            }
            pageInfo {
                ...PageInfoFragment
            }
            pageCount
            totalCount
        }
    }

    ${LandfillShortFragment}
    ${PageInfoFragment}
`;

export const AdminLandfillsSearchQuery = gql`
    query LandfillsSearchQuery($filter: LandfillFilter!, $limit: Int!) {
        landfillsPaged(filter: $filter, connection: { first: $limit }) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const AdminLandfillRemoveMutation = gql`
    mutation LandfillRemoveMutation($id: ID!) {
        landfillRemove(id: $id)
    }
`;

export const AdminLandfillQuery = gql`
    query LandfillDetailedQuery($id: ID!) {
        landfill(id: $id) {
            ...LandfillFragment
        }
    }

    ${LandfillFragment}
`;

export const AdminLandfillsQuery = gql`
    query AdminLandfillsQuery($filter: LandfillFilter, $sort: SortInput) {
        landfills(filter: $filter, sort: $sort) {
            ...LandfillLightFragment
        }
    }

    ${LandfillLightFragment}
`;

export const LandfillQuery = gql`
    query LandfillQuery($id: ID!) {
        landfill(id: $id) {
            ...LandfillFragment
        }
    }

    ${LandfillFragment}
`;

export const LandfillCreateMutation = gql`
    mutation LandfillCreateMutation($input: LandfillInput!) {
        landfillCreate(input: $input) {
            ...LandfillFragment
        }
    }

    ${LandfillFragment}
`;

export const LandfillUpdateMutation = gql`
    mutation LandfillUpdateMutation($input: LandfillInput!) {
        landfillUpdate(input: $input) {
            ...LandfillFragment
        }
    }

    ${LandfillFragment}
`;

export const LandfillRemoveCertificateMutation = gql`
    mutation LandfillRemoveCertificateMutation($landfillId: ID!, $certId: String!) {
        landfillRemoveCertificate(id: $landfillId, certificateId: $certId)
    }
`;

export const LandfillSubareaCreateMutation = gql`
    mutation LandfillSubareaCreateMutation($landfillId: ID!, $input: LandfillSubareaInput!) {
        landfillSubareaCreate(landfillId: $landfillId, input: $input) {
            ...LandfillFragment
        }
    }

    ${LandfillFragment}
`;

export const LandfillSubareaUpdateMutation = gql`
    mutation LandfillSubareaUpdateMutation($landfillId: ID!, $input: LandfillSubareaInput!) {
        landfillSubareaUpdate(landfillId: $landfillId, input: $input) {
            ...LandfillFragment
        }
    }

    ${LandfillFragment}
`;

export const LandfillSubareaDeleteMutation = gql`
    mutation LandfillSubareaDeleteMutation($landfillId: ID!, $id: ID!) {
        landfillSubareaDelete(landfillId: $landfillId, id: $id) {
            ...LandfillFragment
        }
    }

    ${LandfillFragment}
`;

export const LandfillBatchCreateMutation = gql`
    mutation LandfillBatchCreateMutation($landfillId: ID!, $input: LandfillBatchInput!) {
        landfillBatchCreate(landfillId: $landfillId, input: $input) {
            ...LandfillBatchFragment
        }
    }

    ${LandfillBatchFragment}
`;

export const LandfillBatchUpdateMutation = gql`
    mutation LandfillBatchUpdateMutation($landfillId: ID!, $id: String!, $input: LandfillBatchInput!) {
        landfillBatchUpdate(landfillId: $landfillId, id: $id, input: $input) {
            ...LandfillBatchFragment
        }
    }

    ${LandfillBatchFragment}
`;

export const LandfillBatchArchiveMutation = gql`
    mutation LandfillBatchArchiveMutation($landfillId: ID!, $id: String!) {
        landfillBatchArchive(landfillId: $landfillId, id: $id) {
            ...LandfillBatchFragment
        }
    }

    ${LandfillBatchFragment}
`;

export const LandfillBatchRestoreMutation = gql`
    mutation LandfillBatchRestoreMutation($landfillId: ID!, $id: String!) {
        landfillBatchRestore(landfillId: $landfillId, id: $id) {
            ...LandfillBatchFragment
        }
    }

    ${LandfillBatchFragment}
`;
