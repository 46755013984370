import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import {
    BlacklistQuery as IBlacklistQuery,
    BlacklistQuery_emailsblackedlist,
    BlacklistQueryVariables,
} from '~/graphql';

import { BlacklistQuery } from '../blacklist.queries';
import { blacklistStore } from '../blacklist.store';

export type IBlacklistItem = BlacklistQuery_emailsblackedlist;

export const useBlacklist = (): QueryResult<IBlacklistQuery, BlacklistQueryVariables> => {
    const variables: BlacklistQueryVariables = useDebouncedObservable(
        () => blacklistStore.blacklistQueryVariables,
        TIMEOUT_ON_QUERY_DEBOUNCE
    );

    return useQuery<IBlacklistQuery, BlacklistQueryVariables>(BlacklistQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
    });
};
