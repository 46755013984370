import { Button, makeStyles } from '@material-ui/core';
import React, { KeyboardEventHandler, useCallback, useMemo, useState } from 'react';

import { PdfHide } from '~/@components/@hooks/use-pdf-export';
import { SendIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { TextField } from '~/@components/TextField';
import { ENTER_KEY_NAME } from '~/@store/common/common.constants';

type Props = {
    readOnly: boolean;
    onSubmit: (text: string) => Promise<unknown>;
};

export const NewCommentInput = ({ readOnly, onSubmit }: Props) => {
    const { root, textField, button } = useStyles();
    const [value, setValue] = useState('');

    const handleChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>>(event => {
        setValue(event.target.value);
    }, []);

    const canSubmit = useMemo(() => value.trim().length > 0, [value]);

    const handleSubmit = useCallback(() => {
        onSubmit(value.trim()).then(() => setValue(''));
    }, [value, onSubmit]);

    const handleKeyPress = useCallback<KeyboardEventHandler<HTMLDivElement>>(
        event => {
            if (event.key === ENTER_KEY_NAME && canSubmit) handleSubmit();
        },
        [canSubmit, handleSubmit]
    );

    return (
        <PdfHide>
            <div className={root}>
                <TextField
                    disabled={readOnly}
                    className={textField}
                    value={value}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    label={<LocalizableText code={'CommentsView.newCommentPlaceholder'} />}
                />
                <Button
                    className={button}
                    variant="contained"
                    color="primary"
                    disabled={!canSubmit || readOnly}
                    onClick={handleSubmit}>
                    <SendIcon />
                </Button>
            </div>
        </PdfHide>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(4),
    },
    textField: {
        flexGrow: 1,
        height: 40,
        '@media print': {
            display: 'none',
        },
    },
    button: {
        width: 56,
        height: 40,
    },
}));
