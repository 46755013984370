import './ProjectCard.scss';

import React from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import { LocationMarkerIcon, UserRoundFilledIcon } from '~/@sochi-components/Icons';
import type { IProject } from '~/@user-store/homePageMap';
import { isOwnProject } from '~/@user-store/homePageMap/homePageMap.utils';
import { getHighlightFunc } from '~/utils';
import { fmtCoordinates } from '~/utils/numbers';

import { ProjectStatus } from './ProjectStatus';

type ProjectCardProps = {
    project: IProject;
    onOpen?: (project: IProject) => void;
    isSelected: boolean;
    onActivate?: (project: IProject) => void;
    highlightText?: string;
};

export class ProjectCard extends React.PureComponent<ProjectCardProps> {
    bem = getBem(this);

    openProject = (e: React.MouseEvent) => {
        const { onOpen, project } = this.props;
        onOpen && onOpen(project);

        e.stopPropagation();
    };

    render() {
        const { project, highlightText } = this.props;
        let { status } = project;
        const { block, element } = this.bem;
        const highlight = getHighlightFunc(highlightText);

        return (
            <div className={block({ isSelected: this.props.isSelected })}>
                <div className={element('title')}>
                    <div className={element('name')} title={project.name || ''}>
                        {highlight(project.name)}
                    </div>
                    {status && <ProjectStatus status={status} />}
                </div>
                <div className={element('body')}>
                    {isOwnProject(project) && (
                        <div className={element('section')}>
                            <UserRoundFilledIcon className={element('section-icon')} />
                            <div>{highlight(project.customer?.name)}</div>
                        </div>
                    )}
                    <div
                        className={element('section', {
                            isClickable: !!this.props.onActivate,
                        })}
                        onClick={() => this.props.onActivate?.(project)}>
                        <LocationMarkerIcon className={element('section-icon')} />
                        <div className={element('location')}>
                            <div>{highlight(project.location.text)}</div>
                            <div>{fmtCoordinates(project.location)}</div>
                        </div>
                    </div>
                </div>
                {this.props.onOpen && (
                    <div className={element('action')}>
                        <Button variant="outlined" color="primary" onClick={this.openProject}>
                            <LocalizableText code={'openProject'} />
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}
