import { DialogActions } from '@material-ui/core';
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade, { FadeProps } from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { isString } from 'lodash';
import React from 'react';

import { DialogTitleIconButton } from '~/@components/Dialog/DialogTitleIconButton';
import { CloseIcon } from '~/@components/Icon';

const Transition: React.ElementType<FadeProps> = React.forwardRef(function TransitionComponent(props, ref) {
    return <Fade ref={ref} {...props} />;
});

type Props = Omit<DialogProps, 'open' | 'onClose' | 'title'> & {
    onClose?: () => void;

    children: React.ReactNode;
    title: React.ReactNode | string;
    buttons?: React.ReactNode;
    className?: string;
};

export const CardDialog = (props: Props) => {
    const { onClose, children, title, buttons, className, ...restProps } = props;
    const { root, closeButton, titleStyle } = useStyles(props);

    return (
        <MuiDialog
            open={true}
            scroll="paper"
            maxWidth="md"
            TransitionComponent={Transition}
            onClose={onClose}
            className={root}
            {...restProps}>
            <DialogTitle disableTypography className={titleStyle}>
                {isString(title) ? <Typography variant="h2">{title}</Typography> : title}

                {onClose && (
                    <DialogTitleIconButton aria-label="close" className={closeButton} onClick={onClose}>
                        <CloseIcon />
                    </DialogTitleIconButton>
                )}
            </DialogTitle>
            <DialogContent className={className}>{children}</DialogContent>
            {buttons && <DialogActions>{buttons}</DialogActions>}
        </MuiDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(3),
        top: theme.spacing(1),
    },
    titleStyle: ({ onClose }: Props) => ({
        height: 48,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(3),
        padding: theme.spacing(0, 3),
        paddingRight: onClose ? theme.spacing(10) : theme.spacing(3),
    }),
}));
