import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

type Props = {
    className?: string;
};

export const CardList: React.FC<Props> = ({ className, children }) => {
    const { root } = useStyles();

    return <div className={classNames(root, className)}>{children}</div>;
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
}));
