import { formatString } from '@common/utils';
import LinearProgress from '@material-ui/core/LinearProgress';
import { observer } from 'mobx-react';
import React from 'react';

import { Button } from '~/@components/Button';
import { Text } from '~/@components/Text';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { BulkDownloadStore } from '~/@user-store/weightReceipts/bulkDownloadStore';
import i18n from '~/i18n';

type Props = {
    store: BulkDownloadStore;
    onClose: () => void;
};

export const BulkDownloadDialog = observer(({ store, onClose }: Props) => {
    React.useEffect(() => {
        if (!store.started) {
            store.download().finally(() => onClose());
        }
    }, [store, onClose]);

    const { loaded, total } = store;

    return (
        <DialogContent>
            <Text>{formatString(i18n.downloadProgress, String(loaded), String(total))}</Text>
            <LinearProgress value={Math.floor((100 * loaded) / total)} variant="determinate" />
            <ButtonGroup>
                <Button
                    size="large"
                    variant="outlined"
                    color="secondary"
                    onClick={store.cancel}
                    disabled={store.canceled}>
                    {i18n.cancel}
                </Button>
            </ButtonGroup>
        </DialogContent>
    );
});
