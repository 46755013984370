import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
    AdminDumpLoadDestinationUpdateMutation,
    AdminDumpLoadDestinationUpdateMutationVariables,
    DestinationInput,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../dumpLoads.queries';
import type { IDumpLoad } from './usePagedDumpLoads';

export type UpdateDumpLoadDestinationFunc = (input: DestinationInput) => Promise<unknown>;

export const useDumpLoadUpdateDestinationMutation = (dumpLoad: IDumpLoad): [UpdateDumpLoadDestinationFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        AdminDumpLoadDestinationUpdateMutation,
        AdminDumpLoadDestinationUpdateMutationVariables
    >(queries.AdminDumpLoadDestinationUpdateMutation);

    const updateDumpLoadDestination = useCallback<UpdateDumpLoadDestinationFunc>(
        input => {
            const { projectId } = input;

            return callMutation({
                variables: { input },
                refetchQueries: [
                    {
                        query: queries.AdminDetailedDumpLoadQuery,
                        variables: {
                            projectId,
                            dumpLoadId: dumpLoad.id,
                        },
                    },
                ],
                awaitRefetchQueries: true,
            }).catch(handleApolloError);
        },
        [dumpLoad, callMutation]
    );

    return [updateDumpLoadDestination, mutationResult.loading];
};
