import React, { useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import {
    CheckFormatter,
    ContaminationLevelFormatter,
    CurrencyFormatter,
    DumpLoadModeFormatter,
    NullableFormatter,
    TonnsFormatter,
    UserFormatter,
} from '~/@components/Formatters';
import { Link } from '~/@components/Link';
import { LocalizableText } from '~/@components/LocalizableText';
import { ColumnAlign, ITableColumn } from '~/@components/Table';
import {
    AllDumpLoads,
    DumpLoadFilterFields,
    dumpLoadsStore,
    getLandfillPricePerTonn,
    IDumpLoad,
} from '~/@store/dumpLoads';
import { companyDetailRoute, projectDocumentsRoute } from '~/@store/routes';
import { DumpLoadStatusChip } from '~/@views/AdminView/common/DumpLoadStatusChip';
import { LoadStatus } from '~/graphql';

import {
    DumpLoadAnalysisApprovalStateCell,
    DumpLoadAnalysisCell,
    DumpLoadCommentsCell,
    DumpLoadNumberCell,
    DumpLoadProjectCell,
    DumpLoadReceiverCell,
} from './DumpLoadCells';
import { DumpLoadsHeadCell } from './DumpLoadsHeadCell';

const { NOT_ORDERED, REQUESTED, CONFIRMED, ORDERED, IN_PROGRESS, DONE } = LoadStatus;
const { ALL_DUMP_LOADS } = AllDumpLoads;

export const useDumpLoadsColumns = (): ITableColumn<IDumpLoad>[] => {
    const status = useObservable(() => dumpLoadsStore.status);

    const allColumns = useMemo(() => {
        return {
            orderNumber: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.orderNumber'} />}
                        fieldName={DumpLoadFilterFields.serialNumber}
                        withFilter
                        withSort
                    />
                ),
                render: (row: IDumpLoad) => <DumpLoadNumberCell dumpLoad={row} />,
                name: 'orderNumber',
            },
            orderStatus: {
                title: <LocalizableText code={'DumpLoadsTable.orderStatus'} />,
                render: (row: IDumpLoad) => <DumpLoadStatusChip>{row.status}</DumpLoadStatusChip>,
                name: 'orderStatus',
            },
            projectName: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.projectName'} />}
                        fieldName={DumpLoadFilterFields.projectName}
                        withFilter
                        withSort
                    />
                ),
                render: (row: IDumpLoad) => <DumpLoadProjectCell dumpLoad={row} />,
                name: 'projectName',
            },
            joinCode: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.joinCode'} />}
                        fieldName={DumpLoadFilterFields.joinCode}
                        withFilter
                        withSort
                    />
                ),
                render: (row: IDumpLoad) => <NullableFormatter>{row.project?.joinCode}</NullableFormatter>,
                name: 'joinCode',
            },
            dumpType: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.dumpType'} />}
                        fieldName={DumpLoadFilterFields.dumpType}
                        withFilter
                        withSort
                    />
                ),
                render: (row: IDumpLoad) => <NullableFormatter>{row.dumpType?.name}</NullableFormatter>,
                name: 'dumpType',
            },
            solidTestResult: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.contaminationLevel'} />}
                        fieldName={DumpLoadFilterFields.solidTestResult}
                        withFilter
                        withSort
                    />
                ),
                render: (row: IDumpLoad) => (
                    <ContaminationLevelFormatter>{row.solidTestResult}</ContaminationLevelFormatter>
                ),
                name: 'solidTestResult',
            },
            faNumber: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.FANumber'} />}
                        fieldName={DumpLoadFilterFields.FANumber}
                        withFilter
                        withSort
                    />
                ),
                render: (row: IDumpLoad) => <NullableFormatter>{row.FANumber}</NullableFormatter>,
                name: 'FANumber',
            },
            customer: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.customer'} />}
                        fieldName={DumpLoadFilterFields.customerName}
                        withFilter
                        withSort
                    />
                ),
                render: (row: IDumpLoad) => (
                    <NullableFormatter>
                        {row.project?.customer && (
                            <Link to={companyDetailRoute(row.project.customer.id)} targetBlank>
                                {row.project.customer.name}
                            </Link>
                        )}
                    </NullableFormatter>
                ),
                name: 'customerName',
            },
            comments: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.comments'} />}
                        fieldName={DumpLoadFilterFields.comments}
                        withFilter
                    />
                ),
                render: (row: IDumpLoad) => <DumpLoadCommentsCell dumpLoad={row} />,
                name: 'comments',
            },
            toxicAnalysis: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.toxicAnalysis'} />}
                        fieldName={DumpLoadFilterFields.hasToxicAnalysisFiles}
                        withFilter
                    />
                ),
                render: (row: IDumpLoad) => <DumpLoadAnalysisCell dumpLoad={row} />,
                name: 'toxicAnalysis',
            },
            toxicAnalysisApprovalState: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.analysisApproved'} />}
                        fieldName={DumpLoadFilterFields.toxicAnalysisApprovalState}
                        withFilter
                    />
                ),
                render: (row: IDumpLoad) => <DumpLoadAnalysisApprovalStateCell dumpLoad={row} />,
                name: 'toxicAnalysisApprovalState',
            },
            receiver: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.receiver'} />}
                        fieldName={DumpLoadFilterFields.landfillName}
                        withFilter
                    />
                ),
                render: (row: IDumpLoad) => <DumpLoadReceiverCell dumpLoad={row} />,
                name: 'receiver',
            },
            contractSigned: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.contractSigned'} />}
                        fieldName={DumpLoadFilterFields.contractSigned}
                        withFilter
                    />
                ),
                render: (row: IDumpLoad) =>
                    row.project && (
                        <Link to={projectDocumentsRoute(row.project.id)} targetBlank>
                            <CheckFormatter>{!!row.contractSigned}</CheckFormatter>
                        </Link>
                    ),
                name: 'contractSigned',
            },
            landfillPricePerTon: {
                title: <LocalizableText code={'DumpLoadsTable.landfillPricePerTon'} />,
                render: (row: IDumpLoad) => <CurrencyFormatter>{getLandfillPricePerTonn(row)}</CurrencyFormatter>,
                align: ColumnAlign.RIGHT,
                name: 'landfillPricePerTon',
            },
            amount: {
                title: <LocalizableText code={'DumpLoadsTable.amount'} />,
                render: (row: IDumpLoad) => <TonnsFormatter>{row.amountInTons}</TonnsFormatter>,
                align: ColumnAlign.RIGHT,
                name: 'amount',
            },
            deliveredVolume: {
                title: <LocalizableText code={'DumpLoadsTable.deliveredVolume'} />,
                render: (row: IDumpLoad) => <TonnsFormatter>{row.deliveredVolume}</TonnsFormatter>,
                align: ColumnAlign.RIGHT,
                name: 'deliveredVolume',
            },
            salesResponsible: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.salesResponsible'} />}
                        fieldName={DumpLoadFilterFields.ownerName}
                        withFilter
                    />
                ),
                render: (row: IDumpLoad) => <UserFormatter>{row.project?.owner}</UserFormatter>,
                name: 'salesResponsible',
            },
            siteConfirmed: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.siteConfirmed'} />}
                        fieldName={DumpLoadFilterFields.siteConfirmed}
                        withFilter
                    />
                ),
                render: (row: IDumpLoad) => <CheckFormatter>{!!row.siteConfirmed}</CheckFormatter>,
                name: 'siteConfirmed',
            },
            creditCheck: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.creditCheck'} />}
                        fieldName={DumpLoadFilterFields.creditCheck}
                        withFilter
                    />
                ),
                render: (row: IDumpLoad) => <CheckFormatter>{!!row.creditCheck}</CheckFormatter>,
                name: 'creditCheck',
            },
            mode: {
                title: (
                    <DumpLoadsHeadCell
                        title={<LocalizableText code={'DumpLoadsTable.mode'} />}
                        fieldName={DumpLoadFilterFields.mode}
                        withFilter
                    />
                ),
                render: (row: IDumpLoad) => <DumpLoadModeFormatter narrow>{row}</DumpLoadModeFormatter>,
                name: 'mode',
            },
        };
    }, []);

    return useMemo(() => {
        switch (status) {
            case REQUESTED:
                return [
                    allColumns.orderNumber,
                    allColumns.projectName,
                    allColumns.mode,
                    allColumns.dumpType,
                    allColumns.solidTestResult,
                    allColumns.customer,
                    allColumns.toxicAnalysis,
                    allColumns.receiver,
                    allColumns.siteConfirmed,
                    allColumns.comments,
                ];
            case CONFIRMED:
            case NOT_ORDERED:
                return [
                    allColumns.orderNumber,
                    allColumns.projectName,
                    allColumns.mode,
                    allColumns.dumpType,
                    allColumns.solidTestResult,
                    allColumns.customer,
                    allColumns.receiver,
                    allColumns.salesResponsible,
                    allColumns.comments,
                ];
            case ORDERED:
                return [
                    allColumns.orderNumber,
                    allColumns.projectName,
                    allColumns.mode,
                    allColumns.dumpType,
                    allColumns.solidTestResult,
                    allColumns.customer,
                    allColumns.receiver,
                    allColumns.creditCheck,
                    allColumns.toxicAnalysisApprovalState,
                    allColumns.contractSigned,
                    allColumns.siteConfirmed,
                ];
            case IN_PROGRESS:
            case DONE:
                return [
                    allColumns.orderNumber,
                    allColumns.projectName,
                    allColumns.joinCode,
                    allColumns.dumpType,
                    allColumns.solidTestResult,
                    allColumns.mode,
                    allColumns.customer,
                    allColumns.receiver,
                    allColumns.faNumber,
                    allColumns.landfillPricePerTon,
                    allColumns.amount,
                    allColumns.deliveredVolume,
                    allColumns.comments,
                ];
            case ALL_DUMP_LOADS:
                return [
                    allColumns.orderNumber,
                    allColumns.orderStatus,
                    allColumns.projectName,
                    allColumns.mode,
                    allColumns.dumpType,
                    allColumns.solidTestResult,
                    allColumns.customer,
                    allColumns.receiver,
                    allColumns.salesResponsible,
                    allColumns.comments,
                ];
            default:
                return [];
        }
    }, [allColumns, status]);
};
