import React, { ComponentType, createContext, useContext, useState } from 'react';

const isMobileDevice = () => {
    return window.innerWidth <= 768;
};

const isMobileOrTabletDevice = () => {
    return window.innerWidth <= 1239;
};

export interface IDeviceMediaContext {
    isMobile: boolean;
    isMobileOrTablet: boolean;
}

const DeviceMediaContext = createContext<IDeviceMediaContext>({
    isMobile: isMobileDevice(),
    isMobileOrTablet: isMobileOrTabletDevice(),
});

export const DeviceMediaProvider: React.FC = ({ children }) => {
    const [state, setState] = useState<IDeviceMediaContext>({
        isMobile: isMobileDevice(),
        isMobileOrTablet: isMobileOrTabletDevice(),
    });

    React.useEffect(() => {
        const handleWindowResize = () => {
            setState({
                isMobile: isMobileDevice(),
                isMobileOrTablet: isMobileOrTabletDevice(),
            });
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return <DeviceMediaContext.Provider value={state}>{children}</DeviceMediaContext.Provider>;
};

export const withDeviceMedia = <TProps extends {}>(WrappedComponent: ComponentType<IDeviceMediaContext & TProps>) => {
    return (props: TProps) => (
        <DeviceMediaContext.Consumer>
            {({ isMobileOrTablet, isMobile }) => (
                <WrappedComponent {...props} isMobile={isMobile} isMobileOrTablet={isMobileOrTablet} />
            )}
        </DeviceMediaContext.Consumer>
    );
};

export const useDeviceMedia = () => useContext(DeviceMediaContext);
