import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useProjectRules } from '~/@store/permissions';

import { PermissionsTable } from './PermissionsTable';

export const ProjectPermissions = () => {
    const rules = useProjectRules();
    const extendedColumns = [
        {
            field: 'subject' as const,
            title: <LocalizableText code={'PermissionsPage.subject'} />,
        },
    ];

    return (
        <PermissionsTable
            title={<LocalizableText code={'PermissionsPage.project'} />}
            rules={rules}
            extendedColumns={extendedColumns}
        />
    );
};
