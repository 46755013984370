import { enUS, sv } from 'date-fns/locale';
import * as _ from 'lodash';

export const getFullBrowserLanguage = (lang: string) => {
    switch (lang) {
        case 'sv':
            return 'sv-SE';
        case 'en':
        default:
            return 'en-US';
    }
};

export const getDateFnsLocale = (lang: string) => {
    switch (lang) {
        case 'sv':
            return sv;
        case 'en':
        default:
            return enUS;
    }
};

export default (availableLanguages: string[]) => {
    // These window.navigator contain language information
    // 1. languages -> Array of preferred languages (eg ["en-US", "zh-CN", "ja-JP"]) Firefox^32, Chrome^32
    // 2. language  -> Preferred language as String (eg "en-US") Firefox^5, IE^11, Safari,
    //                 Chrome sends Browser UI language
    // 3. browserLanguage -> UI Language of IE
    // 4. userLanguage    -> Language of Windows Regional Options
    // 5. systemLanguage  -> UI Language of Windows
    let browserLanguagePropertyKeys = ['languages', 'language', 'browserLanguage', 'userLanguage', 'systemLanguage'];

    let detectedLocale = _.chain(window.navigator)
        .pick(browserLanguagePropertyKeys) //Get only language properties
        .values() //Get values of the properties
        .flatten() //flatten all arrays
        .compact() //Remove undefined values
        .map((x: string) => {
            return x.substr(0, 2); //Shorten strings to use two chars (en-US -> en)
        })
        .find(x => {
            // @ts-ignore FixMe: x should be string
            return _.indexOf(availableLanguages, x) >= 0;
        })
        .value();

    //If no locale is detected, fallback to 'en'
    // @ts-ignore FixMe: detectedLocale should be values of availableLanguages
    return (detectedLocale as 'en' | 'sv') || 'en';
};
