import React from 'react';

import { MapInput } from '~/@components/MapInput';
import { GoogleMapsMouseEvent, homePageMapStore } from '~/@user-store/homePageMap';
import { FormNames } from '~/@views/UserView/LandfillPage/common/LandfillFormParts/landfillFormikHOC';
import { useLandfillFormContext } from '~/@views/UserView/LandfillPage/common/LandfillFormParts/LandfillFormWithContext';
import { canChangeLandfillLocation } from '~/utils/auth';

type Props = {
    className?: string;
};

export const LandfillMapInput = ({ className }: Props) => {
    const context = useLandfillFormContext();

    const { values, setFieldValueAndTouchIt, isLoading, getByLatLng, ability, landfill, isSubmitting } = context;

    const updateLocationByLatLng = (arg: GoogleMapsMouseEvent) => {
        const lat = arg.latLng!.lat();
        const lng = arg.latLng!.lng();
        setFieldValueAndTouchIt(FormNames.location, { ...values.location, lat, lng });
        getByLatLng(lat, lng).then(result => setFieldValueAndTouchIt(FormNames.location, result, true));
    };

    return (
        <MapInput
            location={values.location}
            onClick={updateLocationByLatLng}
            readOnly={isLoading || isSubmitting || !canChangeLandfillLocation(ability, landfill)}
            className={className}
            zoom={homePageMapStore.currentZoom}
        />
    );
};
