import { gql } from '@apollo/client';

export const PageInfoFragment = gql`
    fragment PageInfoFragment on PageInfo {
        startCursor
        hasNextPage
        endCursor
        hasPreviousPage
        currentPage
    }
`;
