import { FilterRange } from '@common/types';
import { sub } from 'date-fns';
import { computed } from 'mobx';

import { FilterStore } from '~/@store/common';
import { IUserSearchRecord } from '~/@store/users';
import { TimeSpentFilterInput, TimeSpentQueryVariables } from '~/graphql';
import { serializeDateRange } from '~/utils/date';

export type TimeSpentStoreFilter = {
    user?: IUserSearchRecord | null;
    dumpLoadNumber?: string | null;
    date?: FilterRange<Date> | null;
};

export type TimeSpentFilterField = keyof TimeSpentStoreFilter;

class TimeSpentStore {
    private getDefaultFilter = () => ({
        date: [sub(new Date(), { days: 7 }), new Date()],
    });

    filter = new FilterStore<TimeSpentStoreFilter>(() => {}, this.getDefaultFilter());

    @computed
    get timeSpentQueryVariables(): TimeSpentQueryVariables {
        const { user, date, ...rest } = this.filter.values;

        const filter: TimeSpentFilterInput = { ...rest };
        if (date) filter.date = serializeDateRange(date);
        if (user) filter.userId = user.id;

        return { filter };
    }

    canResetFilter(field: TimeSpentFilterField): boolean {
        return field !== 'date';
    }
}

export const timeSpentStore = new TimeSpentStore();
