import { ReleaseNoteType } from '@common/enums';
import React from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { useReleaseNotesPermissions } from '~/@store/releaseNotes';
import { showReleaseNoteDialog } from '~/@views/AdminView/ReleaseNotesPage/ReleaseNoteForm';

type Props = {
    type: ReleaseNoteType;
};

export const AddReleaseNoteButton = ({ type }: Props) => {
    const { canCreate } = useReleaseNotesPermissions();

    if (!canCreate) return null;

    return (
        <ActionButton onClick={() => showReleaseNoteDialog(null, type)}>
            <LocalizableText code={'ReleaseNotes.createReleaseNote'} />
        </ActionButton>
    );
};
