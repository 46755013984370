import { gql } from '@apollo/client';

export const UpdateDeliveryLineSenderFieldsMutation = gql`
    mutation UpdateDeliveryLineSenderFieldsMutation($input: DeliveryLineSenderUpdateInput!) {
        deliveryLineUpdateSenderFields(input: $input) {
            id
            senderComment
            driverComment
        }
    }
`;

export const UpdateDeliveryLineReceiverFieldsMutation = gql`
    mutation UpdateDeliveryLineReceiverFieldsMutation($input: DeliveryLineReceiverUpdateInput!) {
        deliveryLineUpdateReceiverFields(input: $input) {
            id
            receiverComment
            receiverCheck
        }
    }
`;

export const UpdateDeliveryLineBatchMutation = gql`
    mutation UpdateDeliveryLineBatch($lineId: String!, $batchId: String) {
        deliveryLineUpdateBatch(lineId: $lineId, batchId: $batchId) {
            id
            batchId
            batch {
                id
                name
            }
        }
    }
`;
