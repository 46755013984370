import { isArray } from 'lodash';

import { ErrorCodes } from '../enums';
import { ErrorDto } from '../types';

export function isString(v: unknown): v is string {
    return typeof v === 'string';
}

export function isObject(v: unknown): v is object {
    return typeof v === 'object' && v !== null;
}

export function isDefined<T>(v: T | undefined): v is T {
    return v !== undefined;
}

export function isNumber(v: unknown): v is number {
    return typeof v === 'number' && !isNaN(v) && isFinite(v);
}

export function isFunction(v: unknown): v is Function {
    return typeof v === 'function';
}

export function isBoolean(v: unknown): v is boolean {
    return typeof v === 'boolean';
}

export function isDate(v: unknown): v is Date {
    return v instanceof Date;
}

export const objectIdRE = /^[a-f\d]{24}$/i;

export function isValidObjectId(str: unknown): str is string {
    return isString(str) && objectIdRE.test(str);
}

export function isArrayOfValidObjectIds(val: unknown): val is string[] {
    return isArray(val) && val.every(isValidObjectId);
}

export function isNullOrUndefined(val: unknown): val is null | undefined {
    return val === null || val === undefined;
}

export function isNotNullOrUndefined<T>(val: T | null | undefined): val is T {
    return val !== null && val !== undefined;
}

export function isNotEmpty(value?: string | null): value is string {
    return typeof value === 'string' && value.trim().length > 0;
}

export function hasProp<T extends {}, K extends string>(obj: T, prop: string): obj is T & Record<K, unknown> {
    return prop in obj;
}

export function isErrorDto(err: unknown): err is ErrorDto {
    return (
        isObject(err) && hasProp(err, 'error') && hasProp(err, 'code') && isString(err.code) && err.code in ErrorCodes
    );
}
