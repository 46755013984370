import React from 'react';

import { CardList } from '~/@components/CardList';
import { CardListItem } from '~/@components/CardListItem';
import { CheckIcon, MinusIcon } from '~/@components/Icon';
import { Text } from '~/@components/Text';
import { CustomScript } from '~/@store/settings';
import i18n from '~/i18n';

type Props = {
    scripts: CustomScript[];
    selectedId: string | null;
    setSelectedId: (id: string) => void;
};

const getStatusIcon = (isActive: boolean) =>
    isActive ? <CheckIcon color="inherit" /> : <MinusIcon color="secondary" />;

export const CustomScriptsList = ({ scripts, selectedId, setSelectedId }: Props) => {
    return (
        <CardList>
            {!scripts.length && <Text color="textSecondary">{i18n.emptyList}</Text>}
            {scripts.map(s => (
                <CardListItem
                    key={s.id}
                    onClick={() => setSelectedId(s.id)}
                    isActive={selectedId === s.id}
                    buttonEnd={getStatusIcon(s.active)}>
                    <Text color={s.active ? 'textPrimary' : 'textSecondary'}>{s.src || s.text}</Text>
                </CardListItem>
            ))}
        </CardList>
    );
};
