import { AbilityCan, AbilitySubjects, UserContextRole, UserRole } from '../../enums';
import { IAbilityFunction } from '../abilities.types';
import { ICaslCondition } from '../utils';
import { ITimeSpentCalculatedSubject } from './timeSpentTypes';

const { CRUD, READ, CREATE, UPDATE, DELETE } = AbilityCan;
const { ADMIN, EMPLOYEE, EXCAVATOR_OPERATOR } = UserRole;
const { AUTHOR } = UserContextRole;

// forbid argument kept for uniform define ability calls
export function defineAbilitiesForTimeSpent(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<ITimeSpentCalculatedSubject>;

    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _allow(can, AbilitySubjects.TIME_SPENT, shape);

    allow(CRUD, { relationRole: ADMIN });
    allow(READ, { relationRole: EMPLOYEE });

    allow(CREATE, { relationRole: EXCAVATOR_OPERATOR });

    allow(READ, { relationRole: AUTHOR });
    allow(UPDATE, { relationRole: AUTHOR });
    allow(DELETE, { relationRole: AUTHOR });
}
