import { useQuery } from '@apollo/client';

import { LandfillStatusesListQuery, LandfillStatusesListQueryVariables } from '~/graphql';

import * as queries from '../landfillStatuses.queries';

export const useLandfillStatusesList = () => {
    return useQuery<LandfillStatusesListQuery, LandfillStatusesListQueryVariables>(queries.LandfillStatusesListQuery, {
        fetchPolicy: 'cache-and-network',
        variables: {},
    });
};
