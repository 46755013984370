import { canUseChatGptForLandfill } from '@common/functions';
import React from 'react';
import { GptChat } from 'src/@views/UserView/common/GptChat';

import { PageNoScroll } from '~/@views/UserView/common/PageNoScroll';

import { LandfillDetailsDataProps } from '../LandfillPage';

export const LandfillAiTab = ({ landfill }: LandfillDetailsDataProps) => {
    const canUse = canUseChatGptForLandfill(landfill);

    if (!canUse) return null;

    return (
        <PageNoScroll>
            <GptChat landfillId={landfill.id} />
        </PageNoScroll>
    );
};
