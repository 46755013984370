import { routes } from '@common/routes';
import { makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';

import { ClickableText } from '~/@components/ClickableText';
import { LogoutIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { showConfirmDialog } from '~/@store/common';
import history from '~/browserHistory';
import { removeAuthToken } from '~/utils/auth';

type Props = {};

export const AccountLogoutButton = (_: Props) => {
    const { logout, logoutContent, logoutIcon, logoutParagraph } = useStyles();

    const handleLogout = useCallback(() => {
        showConfirmDialog({
            title: <LocalizableText code={'logout'} />,
            render: () => <LocalizableText code={'AccountPage.logoutMessage'} />,
            icon: LogoutIcon,
            okLabel: <LocalizableText code={'logout'} />,
        }).then(confirmed => {
            if (confirmed) {
                removeAuthToken();
                history.replace(routes.login);
            }
        });
    }, []);

    return (
        <div className={logout}>
            <ClickableText onClick={handleLogout}>
                <div className={logoutContent}>
                    <LogoutIcon className={logoutIcon} />
                    <Text className={logoutParagraph} paragraph>
                        <LocalizableText code={'logout'} />
                    </Text>
                </div>
            </ClickableText>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    logoutContent: {
        display: 'flex',
    },

    logoutIcon: {
        marginRight: theme.spacing(1),
        fontSize: '24px',
    },

    logoutParagraph: {
        fontSize: '16px',
    },

    logout: {
        width: 'max-content',
        paddingTop: theme.spacing(2),
    },
}));
