import './OtherDocuments.scss';
import '../Analysis/Analysis.scss';

import React from 'react';

import { FileUploadButton } from '~/@components/FileUploadButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { DocumentCard } from '~/@sochi-components/cards/DocumentCard';
import { NoDocumentation } from '~/@sochi-components/cards/NoDocumentation';
import { SochiSpoilerView } from '~/@sochi-components/SochiSpoilerView';
import { downloadCertificateUri } from '~/apolloClient';
import { useUserAbilities } from '~/contexts';
import { canDeleteProjectDocument, canUploadProjectDocument } from '~/utils/auth';
import { deleteProjectCertificate, uploadProjectCertificate } from '~/utils/project';

import { DocumentTypes } from '../../../../../config/enum';
import { downloadFile } from '../../../../../utils/files';
import type { ProjectDetailsDataProps } from '../../ProjectPage/ProjectPage';

type OtherDocumentsProps = ProjectDetailsDataProps;

export const OtherDocuments = ({ project, refetch }: OtherDocumentsProps) => {
    const { className, element } = useFunctionalBem(OtherDocuments);

    const handleDocumentUpload = (file: File) => {
        uploadProjectCertificate(file, project.id, refetch);
    };

    const downloadDocument = (filename: string) => {
        downloadFile(
            downloadCertificateUri,
            {
                type: DocumentTypes.PROJECT,
                id: project.id,
                filename,
            },
            filename
        );
    };

    const handleDocumentDelete = (filename: string) => {
        deleteProjectCertificate(filename, project.id, refetch);
    };

    const ability = useUserAbilities();

    const canAdd = canUploadProjectDocument(ability, project);
    const canRemove = canDeleteProjectDocument(ability, project);

    return (
        <div className={className}>
            <SochiSpoilerView
                key={project.certificates.length}
                title={<LocalizableText code={'ProjectDocuments.other'} />}
                buttons={
                    <FileUploadButton
                        color="primary"
                        variant="contained"
                        disabled={!canAdd}
                        onChange={handleDocumentUpload}>
                        <LocalizableText code={'ProjectDocuments.add'} />
                    </FileUploadButton>
                }
                cropClassName={element('crop-cards')}>
                <div className={element('other-documents-cards')}>
                    {project.certificates.length ? (
                        project.certificates.map((cert: string) => (
                            <DocumentCard
                                key={cert}
                                info={cert}
                                onClick={() => downloadDocument(cert)}
                                onDelete={canRemove ? () => handleDocumentDelete(cert) : undefined}
                                viewButtonTitle={<LocalizableText code={'ProjectDocuments.buttons.download'} />}
                            />
                        ))
                    ) : (
                        <NoDocumentation />
                    )}
                </div>
            </SochiSpoilerView>
        </div>
    );
};
