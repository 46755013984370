import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { IMessage } from 'src/@user-store/chatGpt';

import { Text } from '~/@components/Text';
import { COLOR } from '~/@sochi-components/@theme';

type Props = {
    message: IMessage;
};

export const GptMessage: React.VFC<Props> = observer(({ message }) => {
    const { userMessage, aiMessage, aiMessageText } = useStyles();

    return (
        <>
            <div className={userMessage}>
                <Text variant="BodyBold">{message.userInput}</Text>
            </div>
            <div className={aiMessage}>
                <pre>
                    <Text variant="BodyRegular" className={aiMessageText}>
                        {message.aiOutput}
                    </Text>
                </pre>
            </div>
        </>
    );
});

const useStyles = makeStyles(theme => ({
    userMessage: {
        padding: theme.spacing(2),
        borderRadius: 12,
        maxWidth: '66%',

        backgroundColor: COLOR.grayLight3,
        borderBottomRightRadius: 0,
        alignSelf: 'flex-end',
        marginBottom: theme.spacing(),
    },
    aiMessage: {
        padding: theme.spacing(2),
        borderRadius: 12,
        maxWidth: '66%',

        backgroundColor: COLOR.brandTransparent,
        borderBottomLeftRadius: 0,
        alignSelf: 'flex-start',
        marginBottom: theme.spacing(2),
    },
    aiMessageText: {
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    },
}));
