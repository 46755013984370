import { FormikContextType } from 'formik';
import React, { useCallback } from 'react';

import { ISelectProps, Select } from '~/@components/Select';

type FormikSelectFieldProps<TValueType, TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends TValueType | null ? TFieldName : never;
    formik: FormikContextType<TValues>;
} & Omit<ISelectProps<TValueType>, 'onChange' | 'value'>;

export function FormikSelectField<TValueType, TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    disabled,
    ...restProps
}: FormikSelectFieldProps<TValueType, TValues, TFieldName>) {
    const handleChange = useCallback(
        v => {
            formik.setFieldTouched(name, true);
            formik.setFieldValue(name, v);
        },
        [formik, name]
    );

    return (
        <Select
            name={name}
            onChange={handleChange}
            value={formik.values[name] as TValueType | null}
            onBlur={formik.handleBlur}
            error={formik.touched[name] && !!formik.errors[name]}
            helperText={formik.touched[name] && (formik.errors[name] as string)}
            disabled={disabled || formik.isSubmitting}
            {...restProps}
        />
    );
}
