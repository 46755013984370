import { ItemInterface } from 'react-sortablejs';

export type IOrderingItem = { id: string };

export type SortableListItem<TItem extends IOrderingItem> = ItemInterface & {
    id: string;
    item: TItem;
};

export const prepareSortableList = <TItem extends IOrderingItem>(items: TItem[]): SortableListItem<TItem>[] =>
    items.map(item => ({ id: item.id, item }));

export const isEqualSortableListsById = <TItem extends IOrderingItem>(
    origin: SortableListItem<TItem>[],
    list: SortableListItem<TItem>[]
): boolean => {
    if (origin.length !== list.length) return false;

    for (let idx = 0; idx < origin.length; idx++) {
        if (origin[idx]!.id !== list[idx]!.id) {
            return false;
        }
    }

    return true;
};

export const DRAG_ELEM_CLASS = 'drag_action_element_class';

export const DRAG_ELEM_CLASS_SELECTOR = `.${DRAG_ELEM_CLASS}`;
