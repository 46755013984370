import { DespatchAdviceSource } from '@common/enums';
import React, { VFC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { LocalizableText } from '~/@components/LocalizableText';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import { IDespatchAdvice, IDespatchAdviceResponse, useBeastData } from '~/@store/beastIntegration';

import { ExtendedDespatchAdvice, useBeastDataColumns } from './useBeastDataColumns';

type Props = {
    entityId: string;
    source: DespatchAdviceSource;
};

type QueryResult = {
    despatchAdvices: IDespatchAdvice[];
    responses: IDespatchAdviceResponse[];
};

const mapBeastData = (result: QueryResult): ExtendedDespatchAdvice[] => {
    return result.despatchAdvices.map(despatchAdvice => {
        const response = result.responses.find(response => response.despatchAdviceId === despatchAdvice.id);

        return {
            ...despatchAdvice,
            response: response || null,
        };
    });
};

export const BeastDataTable: VFC<Props> = ({ entityId, source }) => {
    const query = useBeastData(entityId, source);
    const columns = useBeastDataColumns();

    const { table } = useStyles();

    return (
        <QueryStateHandler
            query={query}
            dataGetter={query => ({
                despatchAdvices: query.data?.despatchAdvices || query.previousData?.despatchAdvices || [],
                responses: query.data?.despatchAdviceResponses || query.previousData?.despatchAdviceResponses || [],
            })}>
            {(data: QueryResult) => (
                <>
                    <Table<ExtendedDespatchAdvice>
                        columns={columns}
                        items={mapBeastData(data)}
                        keyGetter={l => l.id}
                        emptyListMessage={<LocalizableText code={'DespatchAdvicesTable.noDataFound'} />}
                        name="DespatchAdvicesTable"
                        className={table}
                    />
                </>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        maxHeight: '80vh',
    },
}));
