import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { LocalizableText } from '~/@components/LocalizableText';
import { Section } from '~/@components/Section';
import { IComment } from '~/@store/comments';

import { Comment } from './Comment';
import { NewCommentInput } from './NewCommentInput';

interface Props {
    comments: IComment[];
    onNewComment: (text: string) => Promise<unknown>;
    readOnly?: boolean;
}

export const CommentsBlock = ({ comments, onNewComment, readOnly = false }: Props) => {
    const { section, list } = useStyles();

    return (
        <Section title={<LocalizableText code={'CommentsView.comments'} />} className={section}>
            <NewCommentInput readOnly={readOnly} onSubmit={onNewComment} />
            <div className={list}>
                {[...comments].reverse().map(comment => (
                    <Comment key={comment.id} comment={comment} />
                ))}
            </div>
        </Section>
    );
};

const useStyles = makeStyles({
    section: {
        height: '100%',
        overflow: 'hidden',
    },
    list: {
        overflow: 'auto',
        height: 'calc(100% - 118px)',
    },
});
