import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const PinIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99984 1.66667C8.23173 1.66667 6.53603 2.36905 5.28579 3.61929C4.03555 4.86953 3.33317 6.56522 3.33317 8.33333C3.33317 10.915 5.01295 13.4202 6.8548 15.3644C7.75868 16.3185 8.66539 17.0996 9.34707 17.6424C9.60077 17.8444 9.82236 18.0127 9.99984 18.1438C10.1773 18.0127 10.3989 17.8444 10.6526 17.6424C11.3343 17.0996 12.241 16.3185 13.1449 15.3644C14.9867 13.4202 16.6665 10.915 16.6665 8.33333C16.6665 6.56522 15.9641 4.86953 14.7139 3.61929C13.4636 2.36905 11.7679 1.66667 9.99984 1.66667ZM9.99984 19.1667C9.53759 19.86 9.53738 19.8599 9.53714 19.8597L9.53475 19.8582L9.5292 19.8544L9.5101 19.8415C9.49388 19.8305 9.47073 19.8147 9.44114 19.7942C9.38195 19.7532 9.29689 19.6934 9.18966 19.616C8.97529 19.4612 8.6718 19.2352 8.30886 18.9462C7.58428 18.3692 6.61599 17.5357 5.64488 16.5106C3.73672 14.4965 1.6665 11.585 1.6665 8.33333C1.6665 6.1232 2.54448 4.00358 4.10728 2.44078C5.67008 0.877974 7.7897 0 9.99984 0C12.21 0 14.3296 0.877974 15.8924 2.44078C17.4552 4.00358 18.3332 6.1232 18.3332 8.33333C18.3332 11.585 16.263 14.4965 14.3548 16.5106C13.3837 17.5357 12.4154 18.3692 11.6908 18.9462C11.3279 19.2352 11.0244 19.4612 10.81 19.616C10.7028 19.6934 10.6177 19.7532 10.5585 19.7942C10.5289 19.8147 10.5058 19.8305 10.4896 19.8415L10.4705 19.8544L10.4649 19.8582L10.4632 19.8593C10.4629 19.8595 10.4621 19.86 9.99984 19.1667ZM9.99984 19.1667L10.4621 19.86C10.1822 20.0467 9.81705 20.0464 9.53714 19.8597L9.99984 19.1667Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99984 6.66667C9.07936 6.66667 8.33317 7.41286 8.33317 8.33333C8.33317 9.25381 9.07936 10 9.99984 10C10.9203 10 11.6665 9.25381 11.6665 8.33333C11.6665 7.41286 10.9203 6.66667 9.99984 6.66667ZM6.6665 8.33333C6.6665 6.49238 8.15889 5 9.99984 5C11.8408 5 13.3332 6.49238 13.3332 8.33333C13.3332 10.1743 11.8408 11.6667 9.99984 11.6667C8.15889 11.6667 6.6665 10.1743 6.6665 8.33333Z"
            />
        </SvgIcon>
    );
};
