import { PopoverOrigin } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePickerView, KeyboardDatePicker as MUIKeyboardDatePicker, useUtils } from '@material-ui/pickers';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import cn from 'classnames';
import { isValid, setHours } from 'date-fns';
import { isNull } from 'lodash';
import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { isAfterDay, isBeforeDay } from '~/utils/date';

import i18n from '../../i18n';
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from '../@theme/styles';
import { DateRangeIcon } from '../Icons';
import { DatePickerToolbar } from './DatePickerToolbar';

const DatePickerViewOrder: DatePickerView[] = ['year', 'month', 'date'];

export type TDateIOType = MaterialUiPickersDate;

type PopoverPosition = 'left' | 'center' | 'right';
type PopoverPositionSettings = {
    anchorOrigin: PopoverOrigin;
    transformOrigin: PopoverOrigin;
};

const getPopoverPositionSettings = (position?: PopoverPosition): PopoverPositionSettings | void =>
    position && {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: position,
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: position,
        },
    };

type SochiDatePickerProps = {
    className?: string;
    value: TDateIOType;
    onChange: (date: TDateIOType, value?: string | null) => void;
    message?: string;
    format?: string;
    onAccept?: (date: TDateIOType) => void;
    onClose?: () => void;
    onOpen?: () => void;
    onBlur?: () => void;
    open?: boolean;
    autoOk?: boolean;
    disabled?: boolean;
    disableFuture?: boolean;
    disablePast?: boolean;
    shouldDisableDate?: (day: TDateIOType) => boolean;
    shouldHighlightDate?: (day: TDateIOType) => boolean;
    invalidDateMessage?: ReactNode;
    maxDate?: TDateIOType;
    maxDateMessage?: ReactNode;
    minDate?: TDateIOType;
    minDateMessage?: ReactNode;
    strictCompareDates?: boolean;
    readOnly?: boolean; // text input only
    placeholder?: string;
    popoverPosition?: PopoverPosition;
    showPopoverFang?: boolean;
    label?: string;
    pickerOnly?: boolean;
};

export const SochiDatePicker: FC<SochiDatePickerProps> = ({
    className,
    value,
    open = false,
    onChange,
    onOpen,
    onClose,
    format = i18n.dateFormat,
    invalidDateMessage = i18n.errorCodes.INVALID_DATE,
    message,
    placeholder,
    popoverPosition,
    label,
    showPopoverFang = false,
    pickerOnly = false,
    shouldDisableDate,
    shouldHighlightDate,
    minDate,
    maxDate,
    ...restProps
}) => {
    const ToolbarComponent = useCallback(
        (props: ToolbarComponentProps) => <DatePickerToolbar message={message} {...props} />,
        [message]
    );

    const classes = useStyles();
    const containerRef = useRef<HTMLDivElement>(null);

    const utils = useUtils();

    const [isPopoverOpen, setIsPopoverOpen] = useState(open);

    useEffect(() => {
        setIsPopoverOpen(open);
    }, [open]);

    const handleOnOpen = useCallback(() => setIsPopoverOpen(true), []);
    const handleOnClose = useCallback(() => setIsPopoverOpen(false), []);

    useEffect(() => {
        if (isPopoverOpen) {
            onOpen && onOpen();
        } else {
            onClose && onClose();
        }
    }, [isPopoverOpen, onClose, onOpen]);

    const renderDay = useCallback(
        (day, _selectedDate, _isInCurrentMonth, dayComponent) => {
            const isNecessaryToHighlight = shouldHighlightDate && shouldHighlightDate(utils.date(day));

            return isNecessaryToHighlight ? (
                <span className={classes.dayHighlightWrapper}>{dayComponent}</span>
            ) : (
                dayComponent
            );
        },
        [classes, shouldHighlightDate, utils]
    );

    const isDateOutOfMinMaxDate = useCallback(
        (day: TDateIOType) =>
            !!day && ((!!minDate && isBeforeDay(day, minDate)) || (!!maxDate && isAfterDay(day, maxDate))),
        [maxDate, minDate]
    );

    const isDateDisabled = useCallback(
        (day: TDateIOType) => shouldDisableDate && shouldDisableDate(day),
        [shouldDisableDate]
    );

    const isDateDisabledOrOutOfRange = useCallback(
        (day: TDateIOType) => isDateDisabled(day) || isDateOutOfMinMaxDate(day),
        [isDateDisabled, isDateOutOfMinMaxDate]
    );

    const validateValue = useCallback(
        (val: TDateIOType): boolean => {
            if (isNull(val)) return true;

            return isValid(val) && !isDateDisabledOrOutOfRange(val);
        },
        [isDateDisabledOrOutOfRange]
    );

    const [isValueValid, setIsValueValid] = useState(validateValue(value));

    useEffect(() => setIsValueValid(validateValue(value)), [validateValue, value]);

    const onChangeHandler = useCallback(
        (raw: TDateIOType, value?: string | null) => {
            const date = raw ? setHours(raw, 12) : raw;

            onChange(date, value);
            setIsValueValid(validateValue(date));
        },
        [onChange, validateValue]
    );

    const inputWrapperClasses = cn({ [classes.inputWrapperInvalid]: !isValueValid });

    const popoverClasses = cn(classes.popover, {
        [classes.popoverLeft]: popoverPosition === 'left',
        [classes.popoverRight]: popoverPosition === 'right',
        [classes.popoverCenter]: popoverPosition === 'center',
        [classes.popoverShowFang]: showPopoverFang,
    });

    return (
        <div className={cn(classes.container, className)} ref={containerRef}>
            <MUIKeyboardDatePicker
                {...restProps}
                value={value}
                onChange={onChangeHandler}
                open={isPopoverOpen}
                onOpen={handleOnOpen}
                onClose={handleOnClose}
                format={format}
                variant="inline"
                orientation="portrait"
                inputVariant="outlined"
                views={DatePickerViewOrder}
                invalidDateMessage={invalidDateMessage}
                renderDay={renderDay}
                shouldDisableDate={isDateDisabledOrOutOfRange}
                label={label}
                InputProps={{
                    className: inputWrapperClasses,
                    inputProps: {
                        placeholder,
                    },
                    readOnly: pickerOnly,
                }}
                InputAdornmentProps={{
                    className: classes.inputAdornment,
                }}
                keyboardIcon={<DateRangeIcon className={classes.keyboardIcon} />}
                KeyboardButtonProps={{
                    className: classes.inputIcon,
                    disableRipple: true,
                }}
                ToolbarComponent={ToolbarComponent}
                leftArrowButtonProps={{
                    disableRipple: true,
                }}
                rightArrowButtonProps={{
                    disableRipple: true,
                }}
                PopoverProps={{
                    className: popoverClasses,
                    anchorEl: () => containerRef.current as Element,
                    PaperProps: {
                        elevation: 0,
                        square: true,
                    },
                    ...getPopoverPositionSettings(popoverPosition),
                }}
                fullWidth
            />
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        width: '100%',
    },
    inputIcon: {
        borderRadius: 0,
        width: '24px',
        height: '24px',
        padding: 0,
        color: COLOR.blue,

        '&:hover, &:focus': {
            backgroundColor: COLOR.transparent,
            color: COLOR.blue,
        },

        '&.MuiIconButton-root': {
            '& .Mui-disabled': {
                color: COLOR.blue,
            },
        },
    },
    keyboardIcon: {
        width: 26,
        height: 30,
    },
    inputWrapperInvalid: {
        color: COLOR.red,
        '& input::placeholder': {
            color: COLOR.black,
        },
    },
    inputAdornment: {
        marginLeft: '0px',
    },
    dayHighlightWrapper: {
        '& .MuiPickersDay': {
            '&-day': {
                backgroundColor: COLOR.brandTransparent,
            },
        },
    },
    popover: {
        // @ts-ignore Have no idea how to pass string to zIndex
        zIndex: '1500 !important',
        '& .MuiPopover-paper': {
            marginTop: '10px',
            backgroundColor: COLOR.white,
            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.16)',
            overflowY: 'unset',
            overflowX: 'unset',
        },

        '& .MuiPickersBasePicker-container': {
            position: 'relative',

            '&::after': {
                content: "''",
                position: 'absolute',
                display: 'none',
                top: '-18px',
                border: '10px solid transparent',
                borderBottomColor: COLOR.white,
                zIndex: 1500,
            },
        },

        '& .MuiPickersCalendarHeader': {
            '&-transitionContainer ': {
                height: '32px',
                '& > *': {
                    bottom: 0,
                },
            },

            '&-switchHeader': {
                height: '40px',
                padding: '0 12px',
                marginBottom: '16px',
                marginTop: 0,

                '& .MuiIconButton-root': {
                    borderRadius: 0,
                    width: '32px',
                    height: '32px',
                    color: COLOR.grayDark,

                    '& .MuiSvgIcon-root': {
                        width: '24px',
                        height: '24px',
                    },

                    '&:hover': {
                        backgroundColor: COLOR.grayLight,
                    },
                },

                '& .MuiIconButton-root.Mui-disabled': {
                    color: COLOR.gray4,
                },

                '& .MuiTypography-root': {
                    fontFamily: FONT_FAMILY.catamaran,
                    fontSize: '18px',
                    lineHeight: '18px',
                    fontWeight: FONT_WEIGHT.normal,
                    color: COLOR.textBody,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            },

            '&-daysHeader': {
                height: '40px',
                maxHeight: 'unset',

                '& .MuiPickersCalendarHeader-dayLabel': {
                    fontFamily: FONT_FAMILY.catamaran,
                    fontSize: '14px',
                    color: COLOR.grayDark,
                },
            },
        },

        '& .MuiPickersDay': {
            '&-day': {
                '& .MuiTypography-root': {
                    fontFamily: FONT_FAMILY.catamaran,
                    fontSize: '18px',
                },

                '& .MuiTouchRipple-root': {
                    display: 'none',
                },

                margin: 0,
                width: '40px',
                height: '40px',
                borderRadius: 0,
                color: COLOR.textBody,

                '&:hover': {
                    color: COLOR.textBody,
                    backgroundColor: COLOR.grayLight2,
                },
            },

            '&-current': {
                color: COLOR.blue,
            },

            '&-daySelected': {
                color: COLOR.white,
                backgroundColor: COLOR.blue,
            },

            '&-dayDisabled': {
                color: COLOR.gray3,
            },

            '&-hidden': {
                opacity: 1,
                color: COLOR.gray4,
            },
        },

        '& .MuiPickersCalendar-transitionContainer': {
            marginTop: '8px',
            minHeight: '240px',
            marginBottom: '12px',
        },

        '& .MuiPickersYearSelection-container': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

            '& .MuiPickersYear': {
                '&-root': {
                    margin: '4px 0',
                    padding: '4px 8px',
                    color: COLOR.textBody,
                    fontFamily: FONT_FAMILY.catamaran,
                    fontSize: '18px',
                    lineHeight: '24px',
                    fontWeight: FONT_WEIGHT.normal,
                    borderRadius: 0,

                    '&:hover': {
                        color: COLOR.textBody,
                        backgroundColor: COLOR.grayLight,
                    },
                },

                '&-yearDisabled': {
                    color: COLOR.gray3,
                },

                '&-yearSelected': {
                    color: COLOR.blue,
                    fontWeight: FONT_WEIGHT.normal,
                },
            },
        },

        '& .MuiPickersMonthSelection-container': {
            width: '100%',
            padding: '0 0 24px',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridColumnGap: '0px',
            gridRowGap: '0px',
            justifyItems: 'center',

            '& .MuiPickersMonth': {
                '&-root': {
                    flex: 'unset',
                    width: '45px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 0,

                    color: COLOR.textBody,
                    fontFamily: FONT_FAMILY.catamaran,
                    fontSize: '18px',
                    fontWeight: FONT_WEIGHT.normal,

                    '&:hover': {
                        color: COLOR.textBody,
                        backgroundColor: COLOR.grayLight,
                    },
                },

                '&-monthDisabled': {
                    color: COLOR.gray3,
                },

                '&-monthSelected': {
                    color: COLOR.blue,
                    fontWeight: FONT_WEIGHT.normal,
                },
            },
        },

        '& .MuiPickersBasePicker-pickerView': {
            minHeight: 'unset',
        },
    },
    popoverShowFang: {
        '& .MuiPickersBasePicker-container::after': {
            display: 'block',
        },
    },
    popoverLeft: {
        '& .MuiPickersBasePicker-container::after': {
            left: '14px',
        },
    },
    popoverRight: {
        '& .MuiPickersBasePicker-container::after': {
            right: '14px',
        },
    },
    popoverCenter: {
        '& .MuiPickersBasePicker-container::after': {
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },
});
