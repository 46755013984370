import { DespatchAdviceSource } from '@common/enums';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useObservable } from '~/@components/@hooks';
import { DateTimeFormatter, NullableFormatter, TonnsFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ColumnAlign, ITableColumn } from '~/@components/Table';
import { TableHeadCell } from '~/@components/Table/TableHeadCell';
import { DELIVERY_LINE_VIEW_MODE, DeliveryLineField, deliveryLinesStore, IDeliveryLine } from '~/@store/deliveryLines';
import { BeastCell } from '~/@views/AdminView/common/BeastCell';
import { LoadPercentageCell } from '~/@views/UserView/common/LoadPercentageCell';
import i18n from '~/i18n';

import {
    DeliveryLineCustomerInvoiceCell,
    DeliveryLineDateCell,
    DeliveryLineDeleteRestoreCell,
    DeliveryLineHistoryCell,
    DeliveryLineLandfillInvoiceCell,
    DeliveryLineReceiptCell,
    DeliveryLineTransportInvoiceCell,
    DeliveryLineTruckRegistrationNumberCell,
    DeliveryLineWeightCell,
} from './DeliveryLineCells';
import { DeliveryLineOrderNumberCell } from './DeliveryLineCells/DeliveryLineOrderNumberCell';
import { DeliveryLineHeadCell } from './DeliveryLineHeadCell';

export const useDeliveryLinesColumns = (): ITableColumn<IDeliveryLine>[] => {
    const { buttonCell } = useStyles();

    const isDeletedMode = useObservable(() => deliveryLinesStore.mode === DELIVERY_LINE_VIEW_MODE.DELETED_LINES);

    const isInProgressMode = useObservable(() => deliveryLinesStore.mode === DELIVERY_LINE_VIEW_MODE.IN_PROGRESS_LINES);

    // noinspection UnnecessaryLocalVariableJS
    const columns: ITableColumn<IDeliveryLine>[] = [
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.orderNumber'} />}
                    fieldName={DeliveryLineField.orderNumber}
                    withFilter
                    withSort
                />
            ),
            render: row => <DeliveryLineOrderNumberCell deliveryLine={row} />,
            name: 'orderNumber',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.weight'} />}
                    fieldName={DeliveryLineField.weight}
                    withFilter
                    withSort
                />
            ),
            render: row => <DeliveryLineWeightCell deliveryLine={row} />,
            align: ColumnAlign.RIGHT,
            name: 'weight',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.date'} />}
                    fieldName={DeliveryLineField.plannedStartDate}
                    withFilter
                    withSort
                />
            ),
            render: row => <DeliveryLineDateCell deliveryLine={row} />,
            name: 'date',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.truckRegistrationNumber'} />}
                    fieldName={DeliveryLineField.truckRegistrationNumber}
                    withFilter
                    withSort
                />
            ),
            render: row => <DeliveryLineTruckRegistrationNumberCell deliveryLine={row} />,
            name: 'truckRegistrationNumber',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.truckCapacity'} />}
                    fieldName={DeliveryLineField.truckCapacity}
                    withFilter
                    withSort
                />
            ),
            render: row => <TonnsFormatter>{row.truckCapacity}</TonnsFormatter>,
            align: ColumnAlign.RIGHT,
            name: 'truckCapacity',
        },
        {
            title: <LocalizableText code={'DeliveryLinesTable.loadPercentage'} />,
            render: row => <LoadPercentageCell line={row} />,
            align: ColumnAlign.RIGHT,
            name: 'loadPercentage',
        },
        {
            title: <LocalizableText code={'DeliveryLinesTable.driver'} />,
            render: row => <NullableFormatter>{row.driver?.name}</NullableFormatter>,
            name: 'driver',
            hidden: !isInProgressMode,
        },
        {
            title: <LocalizableText code={'DeliveryLinesTable.excavatorOperator'} />,
            render: row => <NullableFormatter>{row.excavatorOperator?.name}</NullableFormatter>,
            name: 'excavatorOperator',
            hidden: !isInProgressMode,
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.customerInvoice'} />}
                    fieldName={DeliveryLineField.customerInvoiceNumber}
                    withFilter
                    withSort
                />
            ),
            render: row => <DeliveryLineCustomerInvoiceCell deliveryLine={row} />,
            name: 'customerInvoiceNumber',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.transportInvoice'} />}
                    fieldName={DeliveryLineField.transportationInvoiceNumber}
                    withFilter
                    withSort
                />
            ),
            render: row => <DeliveryLineTransportInvoiceCell deliveryLine={row} />,
            name: 'transportationInvoiceNumber',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.landfillInvoice'} />}
                    fieldName={DeliveryLineField.landfillInvoiceNumber}
                    withFilter
                    withSort
                />
            ),
            render: row => <DeliveryLineLandfillInvoiceCell deliveryLine={row} />,
            name: 'landfillInvoiceNumber',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.status'} />}
                    fieldName={DeliveryLineField.status}
                    withFilter
                />
            ),
            render: row => i18n.DeliveryLineStatuses[row.status],
            name: 'status',
        },
        {
            title: <LocalizableText code={'DeliveryLinesTable.receipt'} />,
            render: row => <DeliveryLineReceiptCell deliveryLine={row} />,
            hidden: isDeletedMode,
            name: 'receipt',
        },
        {
            title: <LocalizableText code={'DeliveryLinesTable.history'} />,
            render: row => <DeliveryLineHistoryCell deliveryLine={row} />,
            cellClassName: buttonCell,
            hidden: isDeletedMode,
            name: 'history',
        },
        {
            title: <LocalizableText code={'DeliveryLinesTable.beastStatus'} />,
            render: row => <BeastCell entity={row} source={DespatchAdviceSource.DELIVERY_LINE} />,
            cellClassName: buttonCell,
            hidden: isDeletedMode,
            name: 'beast',
        },
        {
            title: (
                <TableHeadCell title={<LocalizableText code={'DeliveryLinesTable.deletedAt'} />} sortDirection={-1} />
            ),
            render: row => <DateTimeFormatter>{row.deletedAt}</DateTimeFormatter>,
            hidden: !isDeletedMode,
            name: 'deletedAt',
        },
        {
            title: '',
            render: row => <DeliveryLineDeleteRestoreCell deliveryLine={row} />,
            cellClassName: buttonCell,
            name: 'delete',
        },
    ];

    return columns;
};

const useStyles = makeStyles(() => ({
    buttonCell: {
        minWidth: 'max-content',
    },
}));
