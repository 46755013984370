import { DeliveryLineKind, getLoadPercentage } from '@common/functions/common.deliveryLine';
import { makeStyles } from '@material-ui/core';
import React from 'react';

import { COLORS } from '~/@components/@theme';
import { Text } from '~/@components/Text';
import i18n from '~/i18n';

type Props =
    | {
          line: DeliveryLineKind;
          percent?: undefined;
      }
    | {
          line?: undefined;
          percent: number | null;
      };

const PERCENT_EDGE = 90;

export const LoadPercentageCell = ({ line, percent }: Props) => {
    const { gray, green, red, orange } = useStyles();
    percent = line ? getLoadPercentage(line) : percent;
    const className = !percent
        ? gray
        : percent >= PERCENT_EDGE && percent <= 100
        ? green
        : percent > 100
        ? red
        : orange;

    return <Text className={className}>{percent ? percent + ' %' : i18n.NA}</Text>;
};

const useStyles = makeStyles(theme => ({
    gray: {
        color: theme.palette.grey[400],
    },
    green: {
        color: theme.palette.success.main,
    },
    red: {
        color: theme.palette.error.main,
    },
    orange: {
        color: COLORS.orange,
    },
}));
