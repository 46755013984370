import { ContaminationLevel, ContaminationType, LimitValidationErrorType } from '@common/enums';
import { SubstanceLimitMap } from '@common/toxic-analysis/toxicAnalysis.types';
import { makeRecord } from '@common/utils';
import { isNumber } from '@common/validations/types.validation';

import i18n from '~/i18n';
import { globalMessage } from '~/services/message';

import { IToxicLimit, ValueValidationResult } from './toxicLimits.types';

export const getSubstanceLimitMap = (limits: IToxicLimit[]): SubstanceLimitMap => {
    const recordedLimits = limits.map(({ substanceId, limits }) => ({
        substanceId,
        values: makeRecord(
            limits,
            l => l.levelName,
            l => l.value
        ),
    }));

    return makeRecord(
        recordedLimits,
        r => r.substanceId,
        r => r.values
    );
};

export const checkArrayIsAscendingAndGetErrorIndexes = (arr: Array<number | null>): number[] => {
    let errorIndexes = [];
    let prevNumberIndex: number = -1;
    let prevNumberValue: number | null = null;
    for (let currentIndex = 0; currentIndex < arr.length; currentIndex++) {
        const currentValue = arr[currentIndex];
        if (!isNumber(currentValue)) continue;

        if (isNumber(prevNumberValue) && currentValue <= prevNumberValue) {
            if (errorIndexes.length === 0) errorIndexes.push(prevNumberIndex);
            errorIndexes.push(currentIndex);
        }

        prevNumberValue = currentValue;
        prevNumberIndex = currentIndex;
    }

    return errorIndexes;
};

export const checkNewValueIsAscending = (
    prevValues: Array<number | null>,
    newValue: number,
    newValueIndex: number
): {
    errorType: LimitValidationErrorType;
    errorIndex: number;
} | null => {
    for (let currentIndex = 0; currentIndex < prevValues.length; currentIndex++) {
        if (currentIndex === newValueIndex) continue;
        const currentValue = prevValues[currentIndex]!;
        if (!isNumber(currentValue)) continue;
        if (newValue === currentValue)
            return {
                errorType: LimitValidationErrorType.equal,
                errorIndex: currentIndex,
            };
        if (currentIndex < newValueIndex && newValue < currentValue)
            return {
                errorType: LimitValidationErrorType.lesser,
                errorIndex: currentIndex,
            };
        if (currentIndex > newValueIndex && newValue > currentValue)
            return {
                errorType: LimitValidationErrorType.bigger,
                errorIndex: currentIndex,
            };
    }

    return null;
};

export const getContaminationLevelLabel = (level: ContaminationLevel | null, type: ContaminationType): string => {
    if (type === ContaminationType.LEACHING && level === ContaminationLevel.ifa)
        return i18n.contaminationValues.ifaLeaching;

    return i18n.contaminationValues[level || ContaminationLevel.na];
};

export const getSubstanceErrorMessage = (errorLevels: ContaminationLevel[], type: ContaminationType) =>
    `${i18n.invalidValues} ${errorLevels.map(l => getContaminationLevelLabel(l, type)).join(', ')}`;

export const showNewValueErrorMessage = (error: ValueValidationResult, type: ContaminationType) =>
    globalMessage.error(
        i18n.ToxicLimitErrorCodes[error.errorType]
            .replace('{item1}', getContaminationLevelLabel(error.valueLevel, type))
            .replace('{item2}', getContaminationLevelLabel(error.otherLevel, type))
    );
