import { UserFields, UserRole } from '../../enums';
import { IAbilityComplexSubject } from '../abilities.types';

export interface IAbilityTargetUser {
    id: string;
    role: UserRole | null;
}

export enum UserComplexSubject {
    ACTIVITY = 'ACTIVITY',
}

export type IUserRelationRole = UserRole;

export type IUserSubject = `${UserRole | UserFields | UserComplexSubject}`;

export interface IUserCalculatedSubject extends IAbilityComplexSubject {
    relationRole: Array<IUserRelationRole>;
    subSubject: Array<IUserSubject>;
}
