import { ApolloError } from '@apollo/client';
import type { ChildDataProps, DataValue, OperationOption } from '@apollo/client/react/hoc';
import { graphql } from '@apollo/client/react/hoc';
import type { DocumentNode } from 'graphql';
import React, { useEffect } from 'react';

import { handleApolloError } from '~/utils';

import { handleLoadingPromise, startLoading, stopLoading } from '../loader';

export const getDisplayName = <T extends unknown>(WC: React.ComponentType<T>) => {
    return WC.displayName || WC.name || WC.prototype.constructor.name || 'Component';
};

function wrapRefetch<A, R>(refetch: (vars: A) => Promise<R>) {
    return (vars: A) => {
        return handleLoadingPromise(refetch(vars));
    };
}

type GraphqlWrapper<Injected, External> = (component: React.ComponentType<Injected>) => React.ComponentType<External>;

export type WithGraphqlProps<TProps, TResult, TVariables> = ChildDataProps<TProps, TResult, TVariables>;

function getEmptyData<TResult, TVariables>(): DataValue<TResult, TVariables> {
    return {
        refetch: () => Promise.reject(new Error('Its a fake refetch')),
        loading: false,
        error: undefined,
    } as DataValue<TResult, TVariables>;
}

export const handledGraphql =
    <TResult, TProps extends {}, TVariables, TChildProps extends ChildDataProps<TProps, TResult, TVariables>>(
        document: DocumentNode,
        operationOptions?: OperationOption<TProps, TResult, TVariables, TChildProps>,
        customErrorHandler?: (error: ApolloError, refetch?: (args: unknown) => Promise<unknown>) => Promise<unknown>
    ): GraphqlWrapper<TChildProps, TProps> =>
    (WrappedComponent: React.ComponentType<TChildProps>) => {
        const graphqlHoc = graphql<TProps, TResult, TVariables, TChildProps>(document, operationOptions);
        let loadingState = false;

        const emptyData = getEmptyData<TResult, TVariables>();

        const ResultComponent: React.ComponentType<TProps> = graphqlHoc(function HandlerComponent(props: TChildProps) {
            let { data, ...rest } = props;

            // No data in case of using 'skip' directive in graphql config
            if (!data) data = emptyData;

            // Handling apollo-react refetch issue, which call do not change loading or networkStatus prop
            // https://github.com/apollographql/react-apollo/issues/321#issuecomment-599087392
            data.refetch = wrapRefetch(data.refetch);

            useEffect(
                function loadingEffect() {
                    if (data!.loading && !loadingState) {
                        loadingState = true;
                        startLoading();
                    }
                    if (!data!.loading && loadingState) {
                        loadingState = false;
                        stopLoading();
                    }

                    return () => {
                        if (data!.loading && loadingState) {
                            loadingState = false;
                            stopLoading();
                        }
                    };
                },
                [data, data.loading]
            );
            useEffect(
                function errorEffect() {
                    if (data!.error) {
                        customErrorHandler
                            ? customErrorHandler(data!.error, data!.refetch as (args: unknown) => Promise<unknown>)
                            : handleApolloError(data!.error, data!.refetch);
                    }
                },
                [data.error]
            );

            if (data.loading || Boolean(data.error)) return null;

            const wrappedProps = { ...rest, data } as TChildProps;

            return <WrappedComponent {...wrappedProps} />;
        });

        ResultComponent.displayName = `handledGraphql(${getDisplayName(WrappedComponent)})`;

        return ResultComponent;
    };
