import { USER_VIEW_MODE } from '@common/viewModes';
import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import { DateFormatter, DateTimeFormatter, EmailFormatter, UserRoleFormatter } from '~/@components/Formatters';
import { PhoneFormatter } from '~/@components/Formatters/PhoneFormatter';
import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn } from '~/@components/Table';
import { AdminUserColumn, IUser, UserField, usersStore } from '~/@store/users';

import { UserCommentsCell, UserCustomerCell, UserNameCell, UserStatusCell } from './UserCells';
import { UserHeadCell } from './UserHeadCell';

export const useUsersColumns = (): ITableColumn<IUser>[] => {
    const hiddenColumns = useObservable(() => usersStore.hiddenColumns);
    const mode = useObservable(() => usersStore.mode);
    const isHidden = useCallback((column: AdminUserColumn) => hiddenColumns.includes(column), [hiddenColumns]);
    const readOnly = mode === USER_VIEW_MODE.DELETED_USERS;

    return useMemo<ITableColumn<IUser>[]>(
        () => [
            {
                title: (
                    <UserHeadCell
                        title={<LocalizableText code={'UsersTable.name'} />}
                        fieldName={UserField.name}
                        withFilter
                        withSort
                    />
                ),
                render: row => <UserNameCell user={row} mode={mode} />,
                hidden: isHidden(AdminUserColumn.name),
                name: AdminUserColumn.name,
            },
            {
                title: (
                    <UserHeadCell
                        title={<LocalizableText code={'UsersTable.lastName'} />}
                        fieldName={UserField.surname}
                        withFilter
                        withSort
                    />
                ),
                render: row => row.surname,
                hidden: isHidden(AdminUserColumn.lastName),
                name: AdminUserColumn.lastName,
            },
            {
                title: (
                    <UserHeadCell
                        title={<LocalizableText code={'UsersTable.email'} />}
                        fieldName={UserField.email}
                        withFilter
                        withSort
                    />
                ),
                render: row => <EmailFormatter>{row.email}</EmailFormatter>,
                hidden: isHidden(AdminUserColumn.email),
                name: AdminUserColumn.email,
            },
            {
                title: (
                    <UserHeadCell
                        title={<LocalizableText code={'UsersTable.phone'} />}
                        fieldName={UserField.phoneNumber}
                        withFilter
                    />
                ),
                render: row => <PhoneFormatter>{row.phoneNumber}</PhoneFormatter>,
                hidden: isHidden(AdminUserColumn.phone),
                name: AdminUserColumn.phone,
            },
            {
                title: (
                    <UserHeadCell
                        title={<LocalizableText code={'UsersTable.customer'} />}
                        fieldName={UserField.customerName}
                        withFilter
                        withSort
                    />
                ),
                render: row => <UserCustomerCell user={row} disabled={readOnly} />,
                hidden: isHidden(AdminUserColumn.customer),
                name: AdminUserColumn.customer,
            },
            {
                title: (
                    <UserHeadCell
                        title={<LocalizableText code={'UsersTable.status'} />}
                        fieldName={UserField.status}
                        withFilter
                    />
                ),
                render: row => <UserStatusCell user={row} disabled={readOnly} />,
                hidden: isHidden(AdminUserColumn.status),
                name: AdminUserColumn.status,
            },
            {
                title: (
                    <UserHeadCell
                        title={<LocalizableText code={'UsersTable.role'} />}
                        fieldName={UserField.roles}
                        withFilter
                    />
                ),
                render: ({ role }) => <UserRoleFormatter>{role}</UserRoleFormatter>,
                hidden: isHidden(AdminUserColumn.role),
                name: AdminUserColumn.role,
            },
            {
                title: <LocalizableText code={'UsersTable.comments'} />,
                render: row => <UserCommentsCell user={row} disabled={readOnly} />,
                hidden: isHidden(AdminUserColumn.comments),
                name: AdminUserColumn.comments,
            },
            {
                title: (
                    <UserHeadCell
                        title={<LocalizableText code={'UsersTable.created'} />}
                        fieldName={UserField.createdAt}
                        withFilter
                        withSort
                    />
                ),
                render: row => <DateFormatter>{row.createdAt}</DateFormatter>,
                hidden: isHidden(AdminUserColumn.created),
                name: AdminUserColumn.created,
            },
            {
                title: (
                    <UserHeadCell
                        title={<LocalizableText code={'UsersTable.lastActivityDate'} />}
                        fieldName={UserField.lastActivityDate}
                        withFilter
                        withSort
                    />
                ),
                render: row => <DateTimeFormatter>{row.lastActivityDate}</DateTimeFormatter>,
                hidden: isHidden(AdminUserColumn.lastActivityDate),
                name: AdminUserColumn.lastActivityDate,
            },
            {
                title: (
                    <UserHeadCell
                        title={<LocalizableText code={'UsersTable.deletedAt'} />}
                        fieldName={UserField.deletedAt}
                        withSort
                    />
                ),
                render: row => <DateTimeFormatter>{row.deletedAt}</DateTimeFormatter>,
                hidden: isHidden(AdminUserColumn.deletedAt),
                name: AdminUserColumn.deletedAt,
            },
        ],
        [isHidden, mode, readOnly]
    );
};
