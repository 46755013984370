import { action, computed, observable } from 'mobx';

import * as queries from '~/@store/projects/projects.queries';
import { ProjectOffersCountQuery } from '~/graphql';

import client from '../apolloClient';
import { handleUserBlockedNetworkError } from '../utils/user';

class OffersStore {
    @observable
    _offersCount: number = 0;

    @computed
    get offersCount(): number {
        return this._offersCount;
    }

    @action
    _updateOffersCount = (newOffersCount: number) => {
        this._offersCount = newOffersCount;
    };

    loadOffersCount = () => {
        client
            .query<ProjectOffersCountQuery>({
                query: queries.ProjectOffersCountQuery,
                fetchPolicy: 'network-only',
            })
            .then(result => {
                this._updateOffersCount(result.data?.projectOffersCount?.count || 0);
            })
            .catch(handleUserBlockedNetworkError);
    };
}

export default new OffersStore();
