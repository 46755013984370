import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { IBemBag } from '~/@sochi-components/@bem';
import { ContaminationTypeStore } from '~/@store/substances';

import { EditSubstanceField } from './EditSubstanceField';

type Props = {
    store: ContaminationTypeStore;
    element: IBemBag['element'];
};

export const EditSubstanceList = ({ store, element }: Props) => {
    return (
        <>
            {store.substances.map(s => (
                <div
                    key={s.id}
                    className={element('parent-substance', { 'has-children': !isEmpty(s.children) })}
                    style={{ gridRow: 'span ' + ((s.children?.length || 0) + 1) }}>
                    <EditSubstanceField key={s.id} store={store} substance={s} />
                    {!isEmpty(s.children) && (
                        <div className={element('children-substances')}>
                            {s.children!.map(c => (
                                <EditSubstanceField key={c.id} store={store} substance={c} />
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </>
    );
};
