import { Grid, TextField, TextFieldProps } from '@material-ui/core';
import { InputBaseProps } from '@material-ui/core/InputBase/InputBase';
import React, { useMemo } from 'react';

import { FileUploadButton } from '~/@components/FileUploadButton';
import { DoneIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { ACCEPTED_DOCUMENT_FILE_TYPES } from '~/config/constants';

import { COLORS } from '../@theme/colors';

export type IFileUploadProps = {
    value: File | null;
    onChange: (v: File) => void;
} & Omit<TextFieldProps, 'onChange'>;

export function FileUpload({ value, onChange, disabled, ...textFieldProps }: IFileUploadProps) {
    const inputProps = useMemo<InputBaseProps>(
        () => (value ? { endAdornment: <DoneIcon htmlColor={COLORS.green} /> } : {}),
        [value]
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={10}>
                <TextField
                    fullWidth
                    value={value?.name || ''}
                    {...textFieldProps}
                    disabled={true}
                    InputProps={inputProps}
                />
            </Grid>
            <Grid item xs={2}>
                <FileUploadButton
                    variant="outlined"
                    color="primary"
                    onChange={onChange}
                    acceptFileTypes={ACCEPTED_DOCUMENT_FILE_TYPES}
                    disabled={disabled}
                    fullWidth>
                    <LocalizableText code={value ? 'replace' : 'upload'} />
                </FileUploadButton>
            </Grid>
        </Grid>
    );
}
