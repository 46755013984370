import './UserViewLayout.scss';

import { ReleaseNoteType } from '@common/enums';
import React, { FC } from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import { SochiThemeContainer } from '~/@sochi-components/@theme';
import { ReleaseNotePopup } from '~/@views/common/ReleaseNotePopup';

export const UserViewLayout: FC = ({ children }) => {
    const { className } = useFunctionalBem(UserViewLayout);

    return (
        <SochiThemeContainer>
            <ReleaseNotePopup type={ReleaseNoteType.CUSTOMER} />
            <div className={className}>{children}</div>
        </SochiThemeContainer>
    );
};
