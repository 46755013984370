import { replaceRouteParams, routes } from '@common/routes';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';

import { CustomTabs, ICustomTabConfig } from '~/@components/CustomTabs';
import { LocalizableText } from '~/@components/LocalizableText';
import { IDetailedProject, PROJECT_TABS } from '~/@store/projects';
import { ProjectDetailAddUser } from '~/@views/AdminView/ProjectDetailPage/ProjectDetailUsers/ProjectDetailAddUser';
import { ProjectWhatsappButton } from '~/@views/AdminView/ProjectDetailPage/ProjectDetailUsers/ProjectWhatsappButton';
import { ProjectEditForm } from '~/@views/AdminView/ProjectsPage/ProjectEditForm';
import i18n from '~/i18n';

import { DumpLoadCreateButton } from './DumpLoadCreateButton';
import { ProjectDetailDocuments } from './ProjectDetailDocuments';
import { ProjectDetailDumpLoads } from './ProjectDetailDumpLoads';
import { ProjectDetailUsers } from './ProjectDetailUsers';

type Props = {
    project: IDetailedProject;
};

export const ProjectTabs = ({ project }: Props) => {
    const history = useHistory();

    const tabs: Array<ICustomTabConfig> = useMemo(
        () => [
            {
                label: <LocalizableText code={'masses'} />,
                render: () => <ProjectDetailDumpLoads project={project} />,
                rightActionButton: () => <DumpLoadCreateButton project={project} />,
            },
            { label: <LocalizableText code={'editProject'} />, render: () => <ProjectEditForm project={project} /> },
            {
                name: PROJECT_TABS.documentation,
                label: <LocalizableText code={'documentations'} />,
                render: () => <ProjectDetailDocuments project={project} />,
            },
            {
                label: <LocalizableText code={'users'} />,
                render: () => <ProjectDetailUsers project={project} />,
                rightActionButton: () => (
                    <>
                        <ProjectWhatsappButton project={project} /> <ProjectDetailAddUser project={project} />
                    </>
                ),
            },
            {
                label: <LocalizableText code={'customerView'} />,
                onClick: () => history.push(replaceRouteParams(routes.sochi.projectDetail, { projectId: project.id })),
            },
        ],
        [project, history]
    );

    return <CustomTabs title={`${i18n.project}: ${project.name}`} tabs={tabs} />;
};
