import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/substances/substances.queries';
import { SubstanceRemoveMutation, SubstanceRemoveMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

type SubstanceDeleteFunc = (id: string) => Promise<unknown>;

export const useSubstanceDelete = (): [SubstanceDeleteFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<SubstanceRemoveMutation, SubstanceRemoveMutationVariables>(
        queries.SubstanceRemoveMutation,
        {
            refetchQueries: [{ query: queries.SubstancesListQuery }],
        }
    );

    const deleteSubstance = useCallback<SubstanceDeleteFunc>(
        id => {
            const variables: SubstanceRemoveMutationVariables = { id };

            return callMutation({ variables }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [deleteSubstance, mutationResult.loading];
};
