import { action, observable } from 'mobx';

import browserHistory from '../../browserHistory';

class LocationStore {
    constructor() {
        browserHistory.listen(location => {
            this.setPathname(location.pathname);
        });
    }

    @observable
    pathname: string = window.location.pathname;

    @action
    private setPathname(v: string) {
        this.pathname = v;
    }
}

export const locationStore = new LocationStore();
