import { Button, Grid } from '@material-ui/core';
import { FormikContextType } from 'formik';
import React from 'react';

import i18n from '~/i18n';

type Props<TValues> = {
    formik: FormikContextType<TValues>;
    onCancel: () => void;
    submitLabel: React.ReactNode;
    submitDisabled?: boolean;
    className?: string;
};

export const FormCardButtons = <TValues extends unknown>({
    formik,
    onCancel,
    submitLabel,
    submitDisabled,
    className,
}: Props<TValues>) => {
    return (
        <Grid container justify="space-between" className={className}>
            <Button disabled={formik.isSubmitting} onClick={onCancel} variant="outlined">
                {i18n.cancel}
            </Button>
            <Button
                disabled={!formik.dirty || formik.isSubmitting || submitDisabled}
                onClick={formik.submitForm}
                variant="contained"
                color="primary">
                {submitLabel}
            </Button>
        </Grid>
    );
};
