import { gql } from '@apollo/client';

export const TransportConfigurationFragment = gql`
    fragment TransportConfigurationFragment on TransportConfiguration {
        tonnsPerTruck
        pricePerHour
        pricePerKm
        tax
        fixedPrice
        co2perKm
    }
`;

export const FeaturesFlagsFragment = gql`
    fragment FeaturesFlagsFragment on FeaturesFlags {
        captchaEnabled
        chatGptEnabled
        peppolEnabled
        whatsappEnabled
    }
`;

export const CompanyConfigurationFragment = gql`
    fragment CompanyConfigurationFragment on CompanyConfiguration {
        orgName
        orgNumber
        place
        postNumber
        address
        tel
        email
    }
`;

export const SettingsKeysFragment = gql`
    fragment SettingsKeysFragment on SettingsKeys {
        captchaSiteKey
        captchaSecretKey
        chatGptApiKey
        chatGptAssistantId
        tickstarClientId
        tickstarClientSecret
        tickstarToken
        pinpointerGLN
        testDespatchPartyOrgNumber
        testConsigneeOrgNumber
        mytapiProductId
        mytapiToken
    }
`;

export const SettingsFragment = gql`
    fragment SettingsFragment on Settings {
        featuresFlags {
            ...FeaturesFlagsFragment
        }
        transportConfiguration {
            ...TransportConfigurationFragment
        }
        companyConfiguration {
            ...CompanyConfigurationFragment
        }
        keys {
            ...SettingsKeysFragment
        }
    }

    ${FeaturesFlagsFragment}
    ${TransportConfigurationFragment}
    ${CompanyConfigurationFragment}
    ${SettingsKeysFragment}
`;

export const SettingsQuery = gql`
    query SettingsQuery {
        settings {
            ...SettingsFragment
        }
    }

    ${SettingsFragment}
`;

export const SettingsMutate = gql`
    mutation SettingsMutate($input: SettingsInput!) {
        updateSettings(input: $input) {
            ...SettingsFragment
        }
    }

    ${SettingsFragment}
`;

export const CustomScriptFragment = gql`
    fragment CustomScriptFragment on CustomScript {
        id
        text
        src
        async
        order
        active
    }
`;

export const CustomScriptsQuery = gql`
    query CustomScriptsQuery {
        allCustomScripts {
            ...CustomScriptFragment
        }
    }

    ${CustomScriptFragment}
`;

export const CustomScriptAddMutation = gql`
    mutation CustomScriptAddMutation($input: CustomScriptInput!) {
        addCustomScript(input: $input) {
            ...CustomScriptFragment
        }
    }

    ${CustomScriptFragment}
`;

export const CustomScriptUpdateMutation = gql`
    mutation CustomScriptUpdateMutation($id: ID!, $input: CustomScriptInput!) {
        updateCustomScript(id: $id, input: $input) {
            ...CustomScriptFragment
        }
    }

    ${CustomScriptFragment}
`;

export const CustomScriptRemoveMutation = gql`
    mutation CustomScriptRemoveMutation($id: ID!) {
        removeCustomScript(id: $id)
    }
`;
