import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const DocumentIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 2.00125H14L20 8.00125V20.0013C20 21.1013 19.1 22.0013 18 22.0013H5.99C4.89 22.0013 4 21.1013 4 20.0013L4.01 4.00125C4.01 2.90125 4.9 2.00125 6 2.00125ZM8 18.0013H16V16.0013H8V18.0013ZM16 14.0013H8V12.0013H16V14.0013ZM13 3.50125V9.00125H18.5L13 3.50125Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
