import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { StorePagination } from '~/@components/Pagination';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import { IUser, IUsersPaged, usePagedUsers, usersStore } from '~/@store/users';
import i18n from '~/i18n';

import { UserFilterSummary } from './UserFilterSummary';
import { useUsersColumns } from './useUsersColumns';

export const UsersTable = () => {
    const query = usePagedUsers();
    const { table, tableHeader } = useStyles();

    const columns = useUsersColumns();

    return (
        <QueryStateHandler query={query} dataGetter={query => query.data?.usersPaged || query.previousData?.usersPaged}>
            {(data: IUsersPaged) => (
                <>
                    <div className={tableHeader}>
                        <UserFilterSummary />
                        <StorePagination store={usersStore.pagination} />
                    </div>
                    <Table<IUser>
                        columns={columns}
                        items={data.edges.map(e => e.node)}
                        keyGetter={u => u.id}
                        emptyListMessage={i18n.noDataForFilter}
                        className={table}
                        name="UserTable"
                    />
                </>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        height: 'calc(100% - 48px)',
    },
    tableHeader: {
        height: 48,
        display: 'flex',
        marginTop: -8,
    },
}));
