export enum ActionVariant {
    COPY = 'COPY',
    LINK = 'LINK',
    CARD = 'CARD',
    EDIT = 'EDIT',
    ADD = 'ADD',
    LIST = 'LIST',
    APPROVE = 'APPROVE',
    UPLOAD_FILE = 'UPLOAD_FILE',
    DELETE = 'DELETE',
    RESTORE = 'RESTORE',
    REJECT = 'REJECT',
    DOWNLOAD_FILE = 'DOWNLOAD_FILE',
}

interface SimpleCellAction {
    variant:
        | ActionVariant.EDIT
        | ActionVariant.CARD
        | ActionVariant.ADD
        | ActionVariant.LIST
        | ActionVariant.APPROVE
        | ActionVariant.DELETE
        | ActionVariant.RESTORE
        | ActionVariant.DOWNLOAD_FILE
        | ActionVariant.REJECT;
    onClick: () => void;
}

interface LinkCellAction {
    variant: ActionVariant.LINK;
    to: string | null | void;
    targetBlank?: boolean;
}

interface CopyCellAction {
    variant: ActionVariant.COPY;
    content: string | null | void;
}

interface UploadCellAction {
    variant: ActionVariant.UPLOAD_FILE;
    onChange: (f: File) => void;
    acceptFileTypes?: string;
    checkFileSize?: boolean;
}

type CellAction = SimpleCellAction | LinkCellAction | CopyCellAction | UploadCellAction;

export type ITableCellAction = CellAction & {
    disabled?: boolean;
    disabledReason?: string;
};
