import { isValidOrgNr, validateUserPhoneNumber } from '@common/validations/common.validation';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { signupUri } from '~/apolloClient';
import { withSettings } from '~/contexts';
import i18n, { lang } from '~/i18n';
import { globalMessage } from '~/services/message';
import { postRequest } from '~/utils/auth';
import { FrontApiError } from '~/utils/error';

import { errorCodes, UserRole } from '../../../config/enum';
import type { ISignupPageProps, ISignupPageValues } from './SignupPage';
import { FormNames, SignupPage } from './SignupPage';

const PAGE_SPECIFIC_MESSAGE_BY_CODE: Record<string, string> = {
    [errorCodes.TOO_MANY_REQUESTS]: i18n.SignupPage.limitError,
    [errorCodes.EMAIL_EXISTS]: i18n.SignupPage.userExists,
    [errorCodes.EMAIL_BLOCKED]: i18n.SignupPage.userBlacklisted,
};

const formikHOC = withFormik<ISignupPageProps, ISignupPageValues>({
    mapPropsToValues: () => ({
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        surName: '',
        phoneNumber: '',
        organizationNumber: '',
        organizationName: '',
        isTermsAgreed: false,
        isRegisterCompleted: false,
        captchaResult: null,
    }),

    validationSchema: ({ settings }: ISignupPageProps) =>
        Yup.object().shape({
            email: Yup.string().trim().email(i18n.invalidEmail).required(i18n.emailIsRequired),
            password: Yup.string().trim().required(i18n.passwordIsRequired).min(6, i18n.minPasswordLength),
            confirmPassword: Yup.string()
                .trim()
                .required(i18n.confirmPasswordIsRequired)
                .test('isPasswordMatch', i18n.passwordsDoNotMatch, function (value) {
                    return this.parent.password === value;
                }),
            name: Yup.string().trim().required(i18n.nameIsRequired),
            surName: Yup.string().trim().required(i18n.surNameIsRequired),
            phoneNumber: Yup.string()
                .trim()
                .required(i18n.phoneNumberIsRequired)
                .test('User phone number validation', '', function (phoneNumber) {
                    const errorCode = validateUserPhoneNumber(phoneNumber);

                    if (errorCode)
                        return this.createError({
                            path: 'phoneNumber',
                            message: i18n.errorCodes[errorCode] || i18n.errorCodes[errorCodes.INVALID_INPUT],
                        });

                    return true;
                }),
            organizationNumber: Yup.string()
                .trim()
                .required(i18n.organizationNumberIsRequired)
                .test('isValidOrgNr', i18n.organizationNumberMustBeTenDigit, function (value) {
                    return isValidOrgNr(value);
                }),
            organizationName: Yup.string().trim().required(i18n.organizationNameIsRequired),
            isTermsAgreed: Yup.boolean().oneOf([true], i18n.SignupPage.pleaseAcceptTermsNote),
            captchaResult: settings.captchaEnabled
                ? Yup.string().nullable().required(i18n.SignupPage.pleasePassCaptcha)
                : Yup.string().nullable(),
        }),

    handleSubmit: async (values, { setFieldValue }) =>
        postRequest<{}>(signupUri, {
            email: values.email,
            password: values.password,
            name: values.name,
            surname: values.surName,
            phoneNumber: values.phoneNumber,
            orgNr: values.organizationNumber,
            orgName: values.organizationName,
            role: UserRole.EXTERNAL_RECEIVER,
            language: lang,
            captchaResult: values.captchaResult,
        })
            .then(() => {
                setFieldValue(FormNames.isRegisterCompleted, true);
            })
            .catch((error: FrontApiError) => {
                if (PAGE_SPECIFIC_MESSAGE_BY_CODE[error.code]) {
                    globalMessage.error(PAGE_SPECIFIC_MESSAGE_BY_CODE[error.code]!);
                } else {
                    globalMessage.error(error.message);
                }
                setFieldValue(FormNames.captchaResult, null);
            }),
});

export default withSettings(formikHOC(SignupPage));
