import React from 'react';

import { FormikSelectField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { DumpTypeSelectQuery } from '~/@views/AdminView/common/DumpTypeSelectQuery';

import { ISubareaFormik, ISubareaFormValues } from '../SubareaEdit.types';

type Props = { formik: ISubareaFormik };

type IDumpType = Exclude<ISubareaFormValues['dumpType'], null>;

export const SubareaDumpTypeSelect = ({ formik }: Props) => {
    return (
        <DumpTypeSelectQuery>
            {(dumpTypes: IDumpType[]) => {
                return (
                    <FormikSelectField
                        label={<LocalizableText code={'Materials.materialCategory'} />}
                        name="dumpType"
                        formik={formik}
                        items={dumpTypes}
                        renderValue={(dumpType: IDumpType) => dumpType.name}
                        keyGetter={(dumpType: IDumpType) => dumpType.id}
                        addEmptyOption={false}
                    />
                );
            }}
        </DumpTypeSelectQuery>
    );
};
