import './AuthDivider.scss';

import React from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';

export const AuthDivider: React.VFC = () => {
    const { className } = useFunctionalBem(AuthDivider);

    return <div className={className} />;
};
