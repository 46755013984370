import React from 'react';

import { UserRole } from '~/graphql';
import i18n from '~/i18n';

type Props = { children: UserRole };

export const UserRoleFormatter = ({ children: role }: Props) => {
    return <>{i18n.UserRole[role]}</>;
};
