import { ReleaseNoteType } from '@common/enums';
import React from 'react';

import { getReleaseNotesByType, IReleaseNote } from '~/@store/releaseNotes';
import { showAdminReleaseNoteDialog } from '~/@views/AdminView/common/AdminReleaseNoteDialog';
import { showUserReleaseNoteDialog } from '~/@views/UserView/common/UserReleaseNoteDialog';

type Props = {
    type: ReleaseNoteType;
};

const getKey = (type: ReleaseNoteType) => `LAST_SEEN_RELEASE_NOTE_${type}`;

export const ReleaseNotePopup = ({ type }: Props) => {
    const handleNotes = React.useCallback(
        (notes: IReleaseNote[]) => {
            const lastSeenStr = localStorage.getItem(getKey(type));
            const lastSeen = lastSeenStr ? new Date(lastSeenStr) : null;

            const [first, ...rest] = notes;

            const haveUnseen = !!first && (lastSeen ? new Date(first.releaseDate) > lastSeen : true);

            if (haveUnseen) {
                localStorage.setItem(getKey(type), first.releaseDate);

                if (type === ReleaseNoteType.CUSTOMER) {
                    showUserReleaseNoteDialog(first, rest);
                } else {
                    showAdminReleaseNoteDialog(first);
                }
            }
        },
        [type]
    );

    React.useEffect(() => {
        getReleaseNotesByType(type).then(handleNotes);
    }, [type, handleNotes]);

    return null;
};
