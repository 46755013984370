import { Button, Grid, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import i18n from '~/i18n';

import { useDeliveryLinesMassEditFormikContext } from './DeliveryLinesMassEditFormik';

type Props = {
    onClose?: (v: boolean) => void;
};

export const DeliveryLinesMassEditActions = ({ onClose }: Props) => {
    const { root, hidden } = useStyles();

    const formik = useDeliveryLinesMassEditFormikContext();

    const onCancel = () => {
        if (formik.dirty) {
            formik.handleReset();
        } else {
            onClose?.(false);
        }
    };

    return (
        <Grid container spacing={3} justify="space-between" className={root}>
            <Grid item>
                <Button
                    disabled={formik.isSubmitting}
                    onClick={onCancel}
                    variant="contained"
                    className={cn({ [hidden]: !onClose && !formik.dirty })}>
                    {formik.dirty ? i18n.cancel : i18n.close}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    disabled={!formik.dirty || formik.isSubmitting}
                    onClick={formik.submitForm}
                    variant="contained"
                    color="primary">
                    {i18n.save}
                </Button>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
    },
    hidden: {
        visibility: 'hidden',
    },
}));
