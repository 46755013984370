import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

import { Text } from '~/@components/Text';

type Props = {
    title?: React.ReactNode;
    children?: React.ReactNode | React.ReactNode[];
    className?: string;
    dense?: boolean;
};

export const Section = ({ title, children, className, dense }: Props) => {
    const { root, titleStyle, denseStyle } = useStyles();

    return (
        <div className={classNames(root, className, { [denseStyle]: dense })}>
            {title && (
                <Text className={titleStyle} variant="BodyBold">
                    {title}
                </Text>
            )}
            {children}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        border: `1px solid ${theme.palette.sectionBorder}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
    },
    denseStyle: {
        padding: theme.spacing(2),
    },
    titleStyle: {
        marginBottom: theme.spacing(3),
    },
}));
