import { LinearProgress, makeStyles } from '@material-ui/core';
import React from 'react';

import { BiSideLayout } from '~/@components/BiSideLayout';
import { useSubstancesContext } from '~/@store/substances';

import { SubstanceEditForm } from './SubstanceEditForm';
import { SubstancesList } from './SubstancesList';

export const SubstancesPageTabContent = () => {
    const { substanceListScroll } = useStyles();
    const { isPositionsUpdating, selectedSubstance, selectSubstance } = useSubstancesContext();

    return (
        <BiSideLayout>
            {isPositionsUpdating && <LinearProgress color="primary" />}
            <BiSideLayout.LeftSideContent className={substanceListScroll}>
                <SubstancesList />
            </BiSideLayout.LeftSideContent>
            <BiSideLayout.RightSideContent>
                {selectedSubstance && (
                    <SubstanceEditForm substance={selectedSubstance} closeForm={() => selectSubstance(null)} />
                )}
            </BiSideLayout.RightSideContent>
        </BiSideLayout>
    );
};

const useStyles = makeStyles({
    substanceListScroll: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 170px)',
        paddingBottom: '8px',
    },
});
