import client from '~/apolloClient';
import {
    GptConversationHistory,
    GptConversationHistoryVariables,
    GptConversationInput,
    SendGptMessage,
    SendGptMessageVariables,
} from '~/graphql';
import { handleLoadingPromise } from '~/services/loader';
import { handleApolloError } from '~/utils';

import { GptConversationHistoryQuery, SendGptMessageMutation } from './chatGpt.queries';
import { IMessage } from './chatGpt.types';

export const getGptConversationHistory = (input: GptConversationInput): Promise<IMessage[]> =>
    handleLoadingPromise(
        client
            .query<GptConversationHistory, GptConversationHistoryVariables>({
                query: GptConversationHistoryQuery,
                variables: { input },
            })
            .then(res => res.data.chatGptConversation)
            .catch(handleApolloError)
    );

export const sendGptMessage = (input: GptConversationInput, text: string): Promise<IMessage> =>
    client
        .mutate<SendGptMessage, SendGptMessageVariables>({
            mutation: SendGptMessageMutation,
            variables: { input, text },
        })
        .catch(handleApolloError)
        .then(res => res.data?.sendChatGptMessage || Promise.reject(new Error('empty response')));
