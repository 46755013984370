import { useMutation } from '@apollo/client';

import { CustomScriptInput, CustomScriptUpdateMutation, CustomScriptUpdateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../settings.queries';

export const useCustomScriptUpdateMutation = (id: string) => {
    const [callMutation, mutationResult] = useMutation<CustomScriptUpdateMutation, CustomScriptUpdateMutationVariables>(
        queries.CustomScriptUpdateMutation
    );

    const updateCustomScript = ({ text, src, async, order, active }: CustomScriptInput) => {
        const input = { text, src, async, order, active };

        return callMutation({
            variables: { id, input },
            refetchQueries: [{ query: queries.CustomScriptsQuery }],
        }).catch(handleApolloError);
    };

    return [updateCustomScript, mutationResult.loading] as const;
};
