import { RefetchQueryDescription } from '@apollo/client/core/watchQueryOptions';

import { LandfillQuery } from '~/@store/landfills/landfills.queries';
import { ProjectQuery } from '~/@store/projects/projects.queries';
import client from '~/apolloClient';
import {
    UpdateDeliveryLineBatch,
    UpdateDeliveryLineBatchVariables,
    UpdateDeliveryLineReceiverFieldsMutation,
    UpdateDeliveryLineReceiverFieldsMutationVariables,
    UpdateDeliveryLineSenderFieldsMutation,
    UpdateDeliveryLineSenderFieldsMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import { BatchLogQuery } from '../landfillBatches';
import * as queries from './receipts.queries';

export const updateDeliveryLineSenderComment = (projectId: string, lineId: string, senderComment: string) =>
    client
        .mutate<UpdateDeliveryLineSenderFieldsMutation, UpdateDeliveryLineSenderFieldsMutationVariables>({
            mutation: queries.UpdateDeliveryLineSenderFieldsMutation,
            variables: { input: { id: lineId, senderComment } },
            refetchQueries: [
                {
                    query: ProjectQuery,
                    variables: { id: projectId },
                },
            ],
        })
        .catch(handleApolloError);

export const updateDeliveryLineDriverComment = (projectId: string, lineId: string, driverComment: string) =>
    client
        .mutate<UpdateDeliveryLineSenderFieldsMutation, UpdateDeliveryLineSenderFieldsMutationVariables>({
            mutation: queries.UpdateDeliveryLineSenderFieldsMutation,
            variables: { input: { id: lineId, driverComment } },
            refetchQueries: [
                {
                    query: ProjectQuery,
                    variables: { id: projectId },
                },
            ],
        })
        .catch(handleApolloError);

export const updateDeliveryLineReceiverComment = (landfillId: string, lineId: string, receiverComment: string) =>
    client
        .mutate<UpdateDeliveryLineReceiverFieldsMutation, UpdateDeliveryLineReceiverFieldsMutationVariables>({
            mutation: queries.UpdateDeliveryLineReceiverFieldsMutation,
            variables: { input: { id: lineId, receiverComment } },
            refetchQueries: [
                {
                    query: LandfillQuery,
                    variables: { id: landfillId },
                },
            ],
        })
        .catch(handleApolloError);

export const updateDeliveryLineReceiverCheck = (landfillId: string, lineId: string, receiverCheck: boolean) =>
    client
        .mutate<UpdateDeliveryLineReceiverFieldsMutation, UpdateDeliveryLineReceiverFieldsMutationVariables>({
            mutation: queries.UpdateDeliveryLineReceiverFieldsMutation,
            variables: { input: { id: lineId, receiverCheck } },
            refetchQueries: [
                {
                    query: LandfillQuery,
                    variables: { id: landfillId },
                },
            ],
        })
        .catch(handleApolloError);

export const updateDeliveryLineBatch = async (
    landfillId: string,
    lineId: string,
    batchId: string | null,
    previousBatchId?: string | null
) => {
    const refetchQueries: RefetchQueryDescription = [];

    if (previousBatchId) {
        refetchQueries.push({
            query: BatchLogQuery,
            variables: { landfillId, batchId: previousBatchId },
        });
    }

    return client
        .mutate<UpdateDeliveryLineBatch, UpdateDeliveryLineBatchVariables>({
            mutation: queries.UpdateDeliveryLineBatchMutation,
            variables: { lineId, batchId },
            refetchQueries,
        })
        .catch(handleApolloError);
};
