import './AuthTitle.scss';

import React from 'react';

import { getBem } from '~/@sochi-components/@bem';

type AuthTitleProps = { title: string; subtitle?: string };

export class AuthTitle extends React.Component<AuthTitleProps> {
    bem = getBem(this);
    render() {
        const { title, subtitle } = this.props;
        const { className, element } = this.bem;

        return (
            <div className={className}>
                <h2 className={element('title')}>
                    {title}
                    <div className={element('subtitle')}>{subtitle}</div>
                </h2>
            </div>
        );
    }
}
