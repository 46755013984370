import { styled, Switch, SwitchProps } from '@material-ui/core';
import React from 'react';

import { useDisable } from '../@hooks';
import { COLORS } from '../@theme/colors';

export const IOSSwitch = styled(({ disabled, ...rest }: SwitchProps) => (
    <Switch {...rest} disabled={useDisable() || disabled} />
))(({ theme }) => ({
    width: 40,
    height: 24,
    padding: 0,
    margin: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: -4,
        transitionDuration: '300ms',
        '&:hover': {
            background: `${COLORS.transparent} !important`,
        },
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: COLORS.white,
            '& + .MuiSwitch-track': {
                backgroundColor: COLORS.green,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: COLORS.green,
            border: '6px solid' + COLORS.white,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 17,
        height: 17,
    },
    '& .MuiSwitch-track': {
        borderRadius: theme.spacing(2),
        backgroundColor: COLORS.grayLighter,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
