import { isPdf } from '@common/utils';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { Button } from '~/@components/Button';
import { ClickableText } from '~/@components/ClickableText';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';
import { downloadFile } from '~/utils/files';

import { DocumentIcon } from '../Icons';
import { ImgLoader } from '../ImgLoader';
import { SochiPdfPreview } from '../SochiPdfPreview';

type Props = {
    url: string;
    filename: string;
    params?: { [key: string]: string };
    label?: React.ReactNode;
};

export const FilePreview: React.FC<Props> = ({ label, filename, url, params }) => {
    const isPdfFile = isPdf(filename);

    const handleFileDownload = () => {
        downloadFile(url, params, filename);
    };

    const { root, dialogContent, scroll, buttons } = useStyles();

    const renderFilePreview = () =>
        showCustomDialog({
            forceCrossClose: true,
            render: closeDialog => (
                <div className={dialogContent}>
                    <div>
                        <Text variant="TitleBold">{filename}</Text>
                    </div>
                    <div className={scroll}>
                        {!isPdfFile ? (
                            <ImgLoader key={filename} url={url} params={params}>
                                {imgBase64 => <img alt={i18n.pplDeliveryLine.receiptPhoto} src={imgBase64} />}
                            </ImgLoader>
                        ) : (
                            <SochiPdfPreview url={url} fetchData={params} inPopup />
                        )}
                    </div>
                    <div className={buttons}>
                        <Button color="primary" variant="outlined" onClick={() => closeDialog()}>
                            <LocalizableText code={'close'} />
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleFileDownload}>
                            <LocalizableText code={'ProjectPriceCalculator.downloadFile'} />
                        </Button>
                    </div>
                </div>
            ),
        });

    return (
        <ClickableText onClick={renderFilePreview} className={root}>
            <DocumentIcon color="primary" />
            {label || filename}
        </ClickableText>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        width: 'min(1000px, 100vw)',
        height: 'calc(100vh - 80px)',
        padding: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            width: '100vw',
            height: '100vh',
        },
    },
    scroll: {
        flex: 1,
        overflowY: 'scroll',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
    },
}));
