import { LANDFILL_VIEW_MODE } from './landfills.constants';
import type { IWorkTime } from './landfills.types';

export const workTime = (workTimeArray: Pick<IWorkTime, 'isOpen' | 'openFrom' | 'openTo'>[]): string => {
    const firstRow = workTimeArray.find(x => x.isOpen);

    return firstRow ? `${firstRow.openFrom} - ${firstRow.openTo}` : '';
};

export const getLandfillViewMode = (pathname: string): LANDFILL_VIEW_MODE => {
    if (pathname.includes(LANDFILL_VIEW_MODE.ACTIVE_LANDFILLS)) return LANDFILL_VIEW_MODE.ACTIVE_LANDFILLS;
    if (pathname.includes(LANDFILL_VIEW_MODE.SUBAREAS)) return LANDFILL_VIEW_MODE.SUBAREAS;

    return LANDFILL_VIEW_MODE.ALL_LANDFILLS;
};
