import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { SortSection, StringFilter, TableHeadCell } from '~/@components/Table/TableHeadCell';
import { landfillsStore } from '~/@store/landfills/landfills.store';
import { SortInput } from '~/graphql';
import i18n from '~/i18n';

import { LandfillFields } from '../../../../../../common/enums';

export interface LandfillsHeadCellProps {
    title: React.ReactNode;
    fieldName: LandfillFields;
    withFilter?: boolean;
    withSort?: boolean;
}

export const LandfillsHeadCell = (options: LandfillsHeadCellProps) => {
    const { title, fieldName, withFilter, withSort } = options;
    const sortValue = useObservable(() => landfillsStore.sort.value);
    const setSortValue = useCallback((v: SortInput) => landfillsStore.sort.setValue(v), []);

    const sortLabels = useMemo(() => {
        switch (fieldName) {
            case LandfillFields.createdAt:
                return i18n.SortDate;
            case LandfillFields.gateFee:
            case LandfillFields.minMargin:
            case LandfillFields.marginPercent:
                return i18n.SortNumber;
            default:
                return i18n.SortString;
        }
    }, [fieldName]);

    const sortSection = useMemo(() => {
        if (!withSort) return null;

        return (
            <SortSection fieldName={fieldName.toString()} sort={sortValue} setSort={setSortValue} labels={sortLabels} />
        );
    }, [withSort, fieldName, sortValue, setSortValue, sortLabels]);

    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        switch (fieldName) {
            case LandfillFields.name:
            case LandfillFields.location:
            case LandfillFields.comment:
                return (
                    <StringFilter
                        valueGetter={() => landfillsStore.filter.values[fieldName] as string | void | null}
                        setValue={v => landfillsStore.filter.setValue(fieldName, v)}
                    />
                );
            default:
                return null;
        }
    }, [withFilter, fieldName]);

    return (
        <TableHeadCell title={title} sortDirection={sortValue?.field === fieldName ? sortValue.direction : null}>
            {sortSection}
            {filterSection}
        </TableHeadCell>
    );
};
