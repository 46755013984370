import React, { useCallback, useMemo } from 'react';

import { Button } from '~/@components/Button';
import { TonnsFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ISochiTableColumn } from '~/@sochi-components/SochiTable';
import { IBatchTransfer, useBatchTransferRemove } from '~/@user-store/landfillBatches';
import { showLandfillBatchTransferForm } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchTransferForm';
import { LandfillQuery_landfill, TransferType } from '~/graphql';
import { showConfirmDialog } from '~/services/dialog';
import { formatDate, parseDateFrom } from '~/utils/date';

export const useLandfillTransfersColumns = (
    landfill: LandfillQuery_landfill,
    transferType: TransferType,
    readOnly: boolean
): ISochiTableColumn<IBatchTransfer>[] => {
    const [remove, isRemoving] = useBatchTransferRemove(landfill.id, null);

    const onRemove = useCallback(
        async (id: string) => {
            const confirmed = await showConfirmDialog({
                title: <LocalizableText code={'LandfillPage.transferCard.deleteTitle'} />,
                message: <LocalizableText code={'LandfillPage.transferCard.deleteMessage'} />,
            });

            if (!confirmed) return;

            await remove(id);
        },
        [remove]
    );

    const columns = useMemo(
        () => ({
            date: {
                title: <LocalizableText code="LandfillPage.transferCard.date" />,
                render: (t: IBatchTransfer) => formatDate(parseDateFrom(t.date)),
            },
            fromBatch: {
                title: <LocalizableText code="LandfillPage.transferCard.fromBatch" />,
                render: (t: IBatchTransfer) => t.fromBatch?.name,
                alignLeft: true,
            },
            toBatch: {
                title: <LocalizableText code="LandfillPage.transferCard.toBatch" />,
                render: (t: IBatchTransfer) => t.toBatch?.name,
                alignLeft: true,
            },
            externalProject: {
                title: <LocalizableText code="LandfillPage.transferCard.projectName" />,
                render: (t: IBatchTransfer) => (
                    <>
                        <div>{t.externalProject?.name}</div>
                        <div>{t.externalProject?.address}</div>
                    </>
                ),
                alignLeft: true,
            },
            weight: {
                title: <LocalizableText code="LandfillPage.transferCard.weight" />,
                render: (t: IBatchTransfer) => <TonnsFormatter>{t.weight}</TonnsFormatter>,
            },
            licensePlate: {
                title: <LocalizableText code="LandfillPage.transferCard.licensePlate" />,
                render: (t: IBatchTransfer) => t.licensePlate,
            },
            comment: {
                title: <LocalizableText code="LandfillPage.transferCard.comment" />,
                render: (t: IBatchTransfer) => t.comment,
            },
            edit: {
                title: '',
                render: (t: IBatchTransfer) => (
                    <>
                        <Button
                            variant="contained"
                            onClick={() => showLandfillBatchTransferForm(landfill, null, t)}
                            disabled={readOnly || isRemoving}>
                            <LocalizableText code={'edit'} />
                        </Button>
                        <Button variant="contained" onClick={() => onRemove(t.id)} disabled={readOnly || isRemoving}>
                            <LocalizableText code={'remove'} />
                        </Button>
                    </>
                ),
            },
        }),
        [landfill, onRemove, isRemoving, readOnly]
    );

    switch (transferType) {
        case TransferType.INCOMING:
            return [
                columns.date,
                columns.externalProject,
                columns.toBatch,
                columns.weight,
                columns.licensePlate,
                columns.comment,
                columns.edit,
            ];
        case TransferType.OUTGOING:
            return [
                columns.date,
                columns.fromBatch,
                columns.externalProject,
                columns.weight,
                columns.licensePlate,
                columns.comment,
                columns.edit,
            ];
        case TransferType.INTERNAL:
            return [columns.date, columns.fromBatch, columns.toBatch, columns.weight, columns.comment, columns.edit];
    }
};
