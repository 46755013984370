import React from 'react';

import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { FilesInput } from '~/@sochi-components/Inputs/FilesInput';

import { FormNames } from './landfillFormikHOC';
import { useLandfillFormContext } from './LandfillFormWithContext';

type LandfillFilesInputProps = {
    className?: string;
};

export const LandfillFilesInput = ({ className }: LandfillFilesInputProps) => {
    const bem = useFunctionalBem(LandfillFilesInput, className);
    const context = useLandfillFormContext();

    const { values, errors, touched, setFieldValueAndTouchIt, readOnly, isSubmitting } = context;

    return (
        <FilesInput
            className={bem.className}
            label={
                <LabelWithHint
                    label={<LocalizableText code={'LandfillForm.permit'} />}
                    hint={<LocalizableText code={'LandfillForm.permitFieldHint'} />}
                />
            }
            errorMessage={
                touched[FormNames.certificates] && errors[FormNames.certificates]
                    ? `${errors[FormNames.certificates]}`
                    : undefined
            }
            value={values[FormNames.certificates]}
            onChange={(v: File[]) => setFieldValueAndTouchIt(FormNames.certificates, v, true)}
            readOnly={readOnly || isSubmitting}
        />
    );
};
