import './TopBarMenuItem.scss';

import React, { ReactNode, VFC } from 'react';
import { Link } from 'react-router-dom';

import { useFunctionalBem } from '~/@sochi-components/@bem';

type SochiGlobalTopbarMenuItemProps = {
    to: string;
    icon: ReactNode;
    name: ReactNode;
    badge?: number | null;
    className?: string;
};

export const TopBarMenuItem: VFC<SochiGlobalTopbarMenuItemProps> = ({ to, icon, name, badge }) => {
    const { className, element } = useFunctionalBem(TopBarMenuItem);

    return (
        <Link className={className} to={to}>
            <span className={element('icon')}>{icon}</span>
            {badge ? <span className={element('badge')}>{badge}</span> : ''}
            <span className={element('name')}>{name}</span>
        </Link>
    );
};
