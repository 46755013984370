import { adminRoutes } from '@common/routes';
import { TestId } from '@common/testConstants';
import { formatString } from '@common/utils';
import { IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router';
import { CSSTransition } from 'react-transition-group';

import { TextField } from '~/@components/TextField';
import { SearchIcon } from '~/@sochi-components/Icons';
import { globalSearchStore, shouldUseGlobalSearchForPath } from '~/@store/globalSearch';
import i18n from '~/i18n';

import { COLORS } from '../@theme';
import { CloseIcon, CustomSearchIcon } from '../Icon';

type CheckFunc = (value: string) => boolean;

const PAGE_NAMES: { check: CheckFunc; pageName: string }[] = [
    {
        check: (pathname: string) => pathname.startsWith(adminRoutes.dumpLoadAllDumpLoads),
        pageName: i18n.globalSearch.orders,
    },
    {
        check: (pathname: string) => pathname.startsWith(adminRoutes.companies),
        pageName: i18n.globalSearch.companies,
    },
];

export const GlobalSearch = observer(() => {
    const { searchTransition, icon, iconInside, rootWrapper, searchWrapper } = useStyles();
    const ref = useRef<HTMLInputElement>();
    const isOpen = globalSearchStore.isOpen;

    const location = useLocation();

    const shouldUseGlobalSearch = useMemo(() => shouldUseGlobalSearchForPath(location.pathname), [location]);

    const pageItem = useMemo(() => PAGE_NAMES.find(({ check }) => check(location.pathname)), [location.pathname]);

    useEffect(() => {
        if (isOpen) ref.current?.focus();
    }, [isOpen]);

    return shouldUseGlobalSearch ? (
        <div className={rootWrapper}>
            <div className={searchWrapper}>
                <CSSTransition in={globalSearchStore.isOpen} timeout={0} className={searchTransition}>
                    <TextField
                        inputProps={{
                            ref,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon className={iconInside} />
                                </InputAdornment>
                            ),
                        }}
                        value={globalSearchStore.search}
                        onChange={e => {
                            globalSearchStore.setSearch(e.target.value);
                        }}
                        placeholder={
                            pageItem ? formatString(i18n.globalSearch.searchIn, pageItem.pageName) : i18n.search
                        }
                        data-testid={TestId.GlobalSearchField}
                    />
                </CSSTransition>
            </div>

            {isOpen ? (
                <IconButton
                    className={icon}
                    onClick={() => globalSearchStore.close()}
                    data-testid={TestId.GlobalSearchOpenButton}>
                    <CloseIcon />
                </IconButton>
            ) : (
                <IconButton
                    className={icon}
                    onClick={() => globalSearchStore.open()}
                    data-testid={TestId.GlobalSearchCloseButton}>
                    <CustomSearchIcon />
                </IconButton>
            )}
        </div>
    ) : null;
});

const useStyles = makeStyles(theme => ({
    rootWrapper: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    searchWrapper: {
        maxWidth: 380,
        minWidth: 160,
        width: 'calc(100% - 56px)',
        position: 'relative',
    },
    searchTransition: {
        position: 'absolute',
        zIndex: 1,
        right: 0,
        top: 0,
        opacity: 0,
        width: 0,
        transition: 'opacity 200ms, width 200ms',
        overflow: 'hidden',
        '& .MuiInputBase-input': {
            color: COLORS.white,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${COLORS.gray} !important`,
        },
        '&.enter-done': {
            opacity: 1,
            width: '100%',
        },
        '&.exit-done': {
            opacity: 0,
            width: 0,
        },
        '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: '0 !important',
        },
    },

    icon: {
        alignItems: 'center',
        display: 'flex',
        flexFlow: 'row nowrap',
        whiteSpace: 'nowrap',
        lineHeight: 1.5,
        height: 40,
        width: 40,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(0.5, 1),
        marginLeft: theme.spacing(2),
        borderRadius: 4,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
        },
        '& .MuiButton-startIcon': {
            marginLeft: 1,
        },
        '& .MuiIcon-root': {
            fontSize: 24,
        },
    },
    iconInside: {
        height: 40,
        width: 40,
        alignItems: 'center',
        display: 'flex',
        flexFlow: 'row nowrap',
        whiteSpace: 'nowrap',
        lineHeight: 1.5,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(0.5, 1),
        borderRadius: 4,
        '& .MuiIcon-root': {
            fontSize: 24,
        },
    },
}));
