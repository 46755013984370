import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { DumpTypeRemoveMutation, DumpTypeRemoveMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../dumpType.queries';
import { RemoveDumpTypeFunc } from '../types';

export const useDumpTypeDeleteMutation = (): [RemoveDumpTypeFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<DumpTypeRemoveMutation, DumpTypeRemoveMutationVariables>(
        queries.DumpTypeRemoveMutation
    );

    const deleteDumpType = useCallback<RemoveDumpTypeFunc>(
        id => {
            return callMutation({
                variables: { id },
                refetchQueries: [
                    {
                        query: queries.DumpTypesListQuery,
                    },
                ],
                awaitRefetchQueries: true,
            }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [deleteDumpType, mutationResult.loading];
};
