import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { Text } from '~/@components/Text';
import i18n from '~/i18n';
import { UserStatus, UserStatusTranslation } from '~/utils/user';

type Props = { children: UserStatus | null | void };

export const UserStatusFormatter = ({ children: status }: Props) => {
    const { root } = useStyles();

    return (
        <Text className={root} color={status === UserStatus.Blocked ? 'error' : 'textPrimary'} ellipsis>
            {status ? UserStatusTranslation[status] : i18n.NA}
        </Text>
    );
};

const useStyles = makeStyles({
    root: {
        font: 'inherit',
    },
});
