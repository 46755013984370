import { useMutation } from '@apollo/client';
import { DespatchAdviceSource } from '@common/enums';
import { useCallback } from 'react';

import * as beastQueries from '~/@store/beastIntegration/beastIntegration.queries';
import { DeliveryLineDeleteMutation, DeliveryLineDeleteMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../deliveryLines.queries';
import { deliveryLinesStore } from '../deliveryLines.store';
import type { IDeliveryLine } from '../deliveryLines.types';

type DeleteDeliveryLineFunc = () => Promise<unknown>;

export const useDeliveryLineDeleteMutation = (deliveryLine: IDeliveryLine): [DeleteDeliveryLineFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<DeliveryLineDeleteMutation, DeliveryLineDeleteMutationVariables>(
        queries.DeliveryLineDeleteMutation
    );

    const deleteDeliveryLine = useCallback<DeleteDeliveryLineFunc>(() => {
        return callMutation({
            variables: { id: deliveryLine.id },
            refetchQueries: [
                {
                    query: queries.AdminDeliveryLinesPagedQuery,
                    variables: deliveryLinesStore.deliveryLinesQueryVariables,
                },
                {
                    query: beastQueries.AdminBeastDataQuery,
                    variables: {
                        entityId: deliveryLine.id,
                        source: DespatchAdviceSource.DELIVERY_LINE,
                    },
                },
            ],
        }).catch(handleApolloError);
    }, [deliveryLine, callMutation]);

    return [deleteDeliveryLine, mutationResult.loading];
};
