import { VERSION_HEADER } from '@common/constants';
import { HttpCodes } from '@common/enums';
import { AxiosError } from 'axios';

import { bugsnagNotify } from '~/bugsnag';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';
import { getAuthToken } from '~/utils/auth';
import { NotAnError } from '~/utils/error';

export const getAxiosConfig = <TData extends unknown>(
    params: TData,
    responseType: 'blob' | 'json' = 'blob'
): {
    headers: { Authorization: string | null; [VERSION_HEADER]: string | undefined };
    params: TData;
    responseType: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
} => {
    const token = getAuthToken();
    const header = { Authorization: token ? `JWT ${token}` : null, [VERSION_HEADER]: window.__version };

    return {
        headers: header,
        params,
        responseType,
    };
};

const blobToString = (blob: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(String(reader.result));
        };
        reader.onerror = () => {
            reject(new Error('can not read as blob'));
        };
        reader.readAsText(blob);
    });

export const handleAxiosError = (error: AxiosError) => {
    if (error.response?.status === HttpCodes.NOT_ACCEPTABLE) {
        alert(i18n.errorCodes.WRONG_VERSION);

        return Promise.reject(new NotAnError(i18n.errorCodes.WRONG_VERSION));
    }

    const showCommonMessage = () => globalMessage.error(error.message);

    const dealWithErrorData = (errorData: { code?: string | null; error?: string | null } | undefined) => {
        if (errorData?.code) {
            type key = keyof typeof i18n.errorCodes;
            const errorMessage = i18n.errorCodes[errorData.code as key] || error.message || i18n.error;

            globalMessage.error(errorMessage);
        } else if (errorData?.error) {
            globalMessage.error(errorData.error);
        } else {
            showCommonMessage();
        }
    };

    if (error.response?.status === HttpCodes.PAYLOAD_TOO_LARGE) {
        globalMessage.error(i18n.ProjectDocuments.maximumFileSizeNote);
    } else if (error.response?.data instanceof Blob) {
        blobToString(error.response.data)
            .then(responseString => {
                if (responseString.startsWith('<html')) {
                    bugsnagNotify('Axios blob html error', { response: responseString });
                    showCommonMessage();
                } else {
                    const errorData = JSON.parse(responseString);
                    dealWithErrorData(errorData);
                }
            })
            .catch(e => {
                bugsnagNotify('Axios blob error', { message: e.message });
                showCommonMessage();
            });
    } else {
        dealWithErrorData(error.response?.data);
    }

    return Promise.reject(error);
};
