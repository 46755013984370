import merge from 'lodash/merge';
import defaultTo from 'lodash/defaultTo';
import cookie from 'js-cookie';

// @ts-ignore
const env: Record<string, string | undefined> = window._env_ || {};

const checkHostName = (s: string) => {
    const host = window.location.hostname || window.location.host;
    return host.startsWith(s);
};

const options = {
    all: {
        env: defaultTo(process.env.NODE_ENV, 'development'),
        isDev: process.env.NODE_ENV === 'development',
        isApp: checkHostName('app'),
        isAcc: checkHostName('acc'),
        isTest: checkHostName('test'),
        isLocal: checkHostName('localhost'),
        isFeatureBranch: checkHostName('task') || checkHostName('bug'),
        secret: 'WY2XEu351h5iH353Nag5TukVuOL6QVwg',
        syncOrderThrottle: 8000,
        googleMapsApiKey: env.GOOGLE_MAPS_API_KEY_WEB || '', // dynamic environment variable
        bugsnagApiKey: env.WEB_BUGSNAG_API_KEY || '',
        wwwBaseUrl: env.WWW_BASE_URL || '',
    },
    development: {
        server: {
            url: cookie.get('SERVER_IP') || 'localhost',
            port: process.env.SERVER_PORT || 3000,
            protocol: process.env.SERVER_PROTOCOL || 'http',
        },
        api: {
            port: process.env.API_PORT || 4080,
        },
    },
    production: {},
};

const config = merge(options.all, options[options.all.env as keyof typeof options]);

export default config;
