import { ContaminationType } from '@common/enums';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { CardDialog } from '~/@components/Dialog';
import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { showCustomDialog } from '~/@store/common';
import { substanceEditStore, ToxicLevelColorSchemas } from '~/@store/substances';
import { getContaminationLevelLabel } from '~/@store/toxicLimits';
import { SubstancesEditForm } from '~/@views/AdminView/common/SubstancesEditDialog/SubstancesEditForm';
import i18n from '~/i18n';

type Props = {
    disableContaminationLevels: boolean;
};

export const SubareaSubstancesEditButton = observer(({ disableContaminationLevels }: Props) => {
    const { root } = useStyles();

    const showSubstanceForm = () => {
        const ignoredPromise = showCustomDialog({
            render: closeDialog => (
                <CardDialog title={<LocalizableText code={'contaminationLevel'} />} onClose={closeDialog} fullWidth>
                    <SubstancesEditForm editStore={substanceEditStore} />
                </CardDialog>
            ),
        });
    };

    if (!substanceEditStore.initialized) return null;

    const substancesWithValuesCount = [
        ...substanceEditStore.SOLID!.substanceAmounts,
        ...substanceEditStore.SOLID!.substanceAmounts,
    ].filter(a => a.amount).length;

    const solidContaminationLevel = substanceEditStore.SOLID?.contaminationLevel;

    const substancesButtonDefaultColor = 'rgba(0, 0, 0, 0.23)';

    const substancesButtonStyle =
        solidContaminationLevel && !disableContaminationLevels
            ? ToxicLevelColorSchemas[solidContaminationLevel]
            : { borderColor: substancesButtonDefaultColor };

    const contaminationLevelContent = disableContaminationLevels ? (
        <LocalizableText code={'contaminationLevelUnavailable'} />
    ) : solidContaminationLevel ? (
        `${i18n.contaminationLevel}: ${getContaminationLevelLabel(solidContaminationLevel, ContaminationType.SOLID)}`
    ) : (
        `${i18n.toxicAnalysys}: ${substancesWithValuesCount} ${i18n.substances.toUpperCase()}`
    );

    return (
        <div className={root}>
            <Button
                variant={'outlined'}
                style={substancesButtonStyle}
                fullWidth
                onClick={showSubstanceForm}
                disabled={disableContaminationLevels}>
                <Text variant="BodyBold">{contaminationLevelContent}</Text>
            </Button>
            {disableContaminationLevels && (
                <LabelWithHint
                    label={''}
                    hint={<LocalizableText code={'contaminationLevelsForDumpTypeUnavailable'} />}
                />
            )}
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'center',
    },
}));
