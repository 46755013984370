import { MAX_TOC_VALUE, MIN_TOC_VALUE } from '@common/validations/common.validation';
import { FormikContextType } from 'formik';
import React, { FC } from 'react';

import { FormikContextNumberField, FormikContextTextField } from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { IBemBag } from '~/@sochi-components/@bem';
import { CheckBoxField } from '~/@sochi-components/FormikFields/CheckBoxField';
import {
    FormNames,
    IFormValues,
} from '~/@views/UserView/ProjectPage/ProjectPriceCalculator/AddEditMassForm/AddEditMass.types';
import { commonPrecisions } from '~/config/enum';
import i18n from '~/i18n';

type Props = {
    formik: FormikContextType<IFormValues>;
    readOnly: boolean;
    element: IBemBag['element'];
};

export const MassAttributesFields: FC<Props> = ({ formik, readOnly, element }) => {
    const { values } = formik;

    return (
        <>
            <Grid item xs={12}>
                <CheckBoxField
                    name={FormNames.withInvasiveSpecies}
                    label={<LocalizableText code={'ProjectAddEditMass.withInvasiveSpecies'} />}
                    readOnly={readOnly}
                    className={element('invasive-species')}
                />
            </Grid>
            {values.withInvasiveSpecies && (
                <Grid item xs={12}>
                    <FormikContextTextField<IFormValues, FormNames.invasiveSpecies>
                        name={FormNames.invasiveSpecies}
                        label={<LocalizableText code={'ProjectAddEditMass.invasiveSpeciesDescription'} />}
                        disabled={readOnly}
                        className={element('invasive-species-text')}
                        placeholder={i18n.ProjectAddEditMass.invasiveSpeciesPlaceholder}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <CheckBoxField
                    name={FormNames.withTOC}
                    label={
                        <LabelWithHint
                            label={<LocalizableText code={'ProjectAddEditMass.withTOC'} />}
                            hint={<LocalizableText code={'ProjectAddEditMass.withTOCHint'} />}
                        />
                    }
                    readOnly={readOnly}
                />
            </Grid>
            {values.withTOC && (
                <Grid item lg={2} md={4}>
                    <FormikContextNumberField<IFormValues, FormNames.TOCPercent>
                        name={FormNames.TOCPercent}
                        label={<LocalizableText code={'ProjectAddEditMass.TOCValue'} />}
                        precision={commonPrecisions.percent}
                        min={MIN_TOC_VALUE}
                        max={MAX_TOC_VALUE}
                        disabled={readOnly}
                        nullable
                    />
                </Grid>
            )}
            <Grid item lg={2} md={3} sm={6} xs={6}>
                <CheckBoxField
                    name={FormNames.stackability}
                    label={
                        <LabelWithHint
                            label={<LocalizableText code={'stackability'} />}
                            hint={<LocalizableText code={'ProjectAddEditMass.stackabilityFieldHint'} />}
                        />
                    }
                    readOnly={readOnly}
                />
            </Grid>
            <Grid item lg={2} md={9} sm={6} xs={6}>
                <CheckBoxField
                    name={FormNames.over50cm}
                    label={
                        <LabelWithHint
                            label={<LocalizableText code={'over50cm'} />}
                            hint={<LocalizableText code={'ProjectAddEditMass.over50cmFieldHint'} />}
                        />
                    }
                    readOnly={readOnly}
                />
            </Grid>
        </>
    );
};
