import { IconButton, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

import { CloseIcon, InfoIconOutlined } from '~/@components/Icon';
import { Link } from '~/@components/Link';
import { Text } from '~/@components/Text';
import { Tooltip } from '~/@components/Tooltip';
import { landfillDetailRoute } from '~/@store/routes';
import { LandfillStatusIcon } from '~/@views/AdminView/common/LandfillStatusIcon';
import { DestinationFragment_landfill_status } from '~/graphql';

type Props = {
    landfill: {
        status: DestinationFragment_landfill_status | null;
        name: string;
        id: string;
        isDeleted?: boolean | null;
    };
    showAdditionalInfo?: () => void;
};

export const DumpLoadLandfillInfo: FC<Props> = ({ landfill, showAdditionalInfo }) => {
    const { root, name } = useStyles();

    return (
        <div className={root}>
            {landfill.isDeleted ? (
                <CloseIcon color="disabled" />
            ) : (
                <LandfillStatusIcon status={landfill.status} withTooltip={false} />
            )}
            <Text
                variant="BodyBold"
                className={name}
                display="inline"
                color={landfill.isDeleted ? 'textSecondary' : 'textPrimary'}>
                <Tooltip title={landfill.name}>
                    <Link to={landfillDetailRoute(landfill.id)} targetBlank>
                        {landfill.name}
                    </Link>
                </Tooltip>
            </Text>
            {showAdditionalInfo && (
                <IconButton onClick={showAdditionalInfo}>
                    <InfoIconOutlined fontSize="small" />
                </IconButton>
            )}
        </div>
    );
};

export const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '&>*:first-child': {
            height: 20,
            marginRight: theme.spacing(),
        },
    },
    name: {
        minWidth: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
