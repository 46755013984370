import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { CompanyQuery, CompanyQuery_company, CompanyQueryVariables } from '~/graphql';

import * as queries from '../companies.queries';

export type IDetailedCompany = CompanyQuery_company;

export const useDetailedCompany = (companyId: string): QueryResult<CompanyQuery, CompanyQueryVariables> => {
    const variables: CompanyQueryVariables = { id: companyId };

    return useQuery<CompanyQuery, CompanyQueryVariables>(queries.CompanyQuery, {
        fetchPolicy: 'cache-first',
        variables,
    });
};
