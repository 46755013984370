import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { AdminUserQuery } from '~/@store/users/users.queries';
import { AdminUserQuery as IUserDetailedQuery, AdminUserQuery_user, AdminUserQueryVariables } from '~/graphql';

export type IDetailedUser = AdminUserQuery_user;

export const useDetailedUser = (userId: string): QueryResult<IUserDetailedQuery, AdminUserQueryVariables> => {
    const variables: AdminUserQueryVariables = { id: userId };

    return useQuery<IUserDetailedQuery, AdminUserQueryVariables>(AdminUserQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
    });
};
