import { Marker } from '@react-google-maps/api';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import { ErrorBoundary } from '~/@components/ErrorBoundary';
import { icons } from '~/@sochi-components/LandfillStatusIcon';
import { CreationMode, GoogleMapsLocation, GoogleMapsMouseEvent, homePageMapStore } from '~/@user-store/homePageMap';
import { isOwnLandfill, isOwnProject } from '~/@user-store/homePageMap/homePageMap.utils';
import { Map } from '~/@views/UserView/common/Map';
import landfillIconSelected from '~/@views/UserView/HomePage/assets/default-marker-l.png';
import projectIconSelected from '~/@views/UserView/HomePage/assets/default-marker-p.png';
import projectIcon from '~/@views/UserView/HomePage/assets/project1.png';
import { PoiInfoPanel } from '~/@views/UserView/HomePage/PoiInfoPanel';
import { hasOwnProperty } from '~/utils';

const placedMarkerLand = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
const placedMarkerWater = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
const activatedMarker = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
const externalLandfillMarker = 'https://maps.google.com/mapfiles/ms/icons/ltblue-dot.png';
const externalProjectMarker = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';

export const HomePageMap = observer(() => {
    const onSelectedPlaceDragEnd = (e: GoogleMapsMouseEvent): void => {
        const position = {
            lat: e.latLng!.lat(),
            lng: e.latLng!.lng(),
        };

        homePageMapStore.processPositionChange(position);
    };

    const onMapClick = (e: GoogleMapsMouseEvent) => {
        const position = {
            lat: e.latLng!.lat(),
            lng: e.latLng!.lng(),
        };

        homePageMapStore.processPositionChange(position);
        homePageMapStore.setCreationMode(CreationMode.CHOOSE);
    };

    const projectMarkers = homePageMapStore.projectsOnMap.map(p => {
        const icon = isOwnProject(p) ? projectIcon : externalProjectMarker;

        return (
            <Marker
                key={p.id.toString()}
                title={p.name}
                position={p.location as GoogleMapsLocation}
                icon={p.id === homePageMapStore.selectedProject?.id ? projectIconSelected : icon}
                onClick={() => homePageMapStore.onProjectClick(p)}
            />
        );
    });

    const landfillMarkers = homePageMapStore.landfillsOnMap.map(l => {
        const statusIcon = isOwnLandfill(l)
            ? l.status?.icon && hasOwnProperty<typeof icons, string, string>(icons, l.status.icon)
                ? icons[l.status.icon]
                : icons['icon0']
            : externalLandfillMarker;

        return (
            <Marker
                key={l.id.toString()}
                title={l.name}
                position={l.location as GoogleMapsLocation}
                icon={l.id === homePageMapStore.selectedLandfill?.id ? landfillIconSelected : statusIcon}
                onClick={() => homePageMapStore.onLandfillClick(l)}
            />
        );
    });

    const selectedPlace = homePageMapStore.selectedPlace ? (
        <Marker
            key={'selectedPlace'}
            position={homePageMapStore.selectedPlace}
            draggable={true}
            onDragEnd={onSelectedPlaceDragEnd}
            icon={
                homePageMapStore.isSelectPlaceMode
                    ? activatedMarker
                    : homePageMapStore.isOnWater
                    ? placedMarkerLand
                    : placedMarkerWater
            }
        />
    ) : null;

    return (
        <ErrorBoundary name="HomePageMap">
            <Map onMapClick={onMapClick} route={toJS(homePageMapStore.route)}>
                {projectMarkers}
                {landfillMarkers}
                {selectedPlace}
                {homePageMapStore.creationMode === null && <PoiInfoPanel />}
            </Map>
        </ErrorBoundary>
    );
});
