import { IconButton, makeStyles } from '@material-ui/core';
import React from 'react';

type Props = {
    buttons: Array<{
        icon: React.ComponentType;
        onClick: () => void;
    } | null>;
};

export const ControlButtons = (props: Props) => {
    const { root } = useStyles(props);

    return (
        <div className={root}>
            {props.buttons.map((button, index) => {
                if (!button) return <div key={index} />;
                const Icon = button.icon;

                return (
                    <IconButton key={index} onClick={button.onClick}>
                        <Icon />
                    </IconButton>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: ({ buttons }: Props) => ({
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        bottom: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: ((buttons.length || 1) * 2 - 1) * 32,
    }),
}));
