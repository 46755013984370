import { Button } from '@material-ui/core';
import React from 'react';

import i18n from '~/i18n';

import { showDumpLoadWasteDeclarationDialog } from '../../DumpLoadEditDeclarationDialog';
import { useDumpLoadFormikContext } from '../DumpLoadEditFormik';

export const DumpLoadEditDeclaration = () => {
    const formik = useDumpLoadFormikContext();

    return (
        <Button fullWidth variant="outlined" onClick={() => showDumpLoadWasteDeclarationDialog(formik.values.dumpLoad)}>
            {i18n.declarationOfWaste}: {formik.values.dumpLoad.serialNumber}
        </Button>
    );
};
