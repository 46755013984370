import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import type { SettingsMutateVariables } from '~/graphql';
import { SettingsInput, SettingsMutate as SettingsUpdateMutation } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../settings.queries';

type SaveSettingsFunc = (input: SettingsInput) => Promise<SettingsUpdateMutation>;

export const useSettingsUpdateMutation = (): [SaveSettingsFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<SettingsUpdateMutation, SettingsMutateVariables>(
        queries.SettingsMutate
    );

    const saveSettings = useCallback<SaveSettingsFunc>(
        input =>
            callMutation({ variables: { input } })
                .catch(handleApolloError)
                .then(result => result.data!),
        [callMutation]
    );

    return [saveSettings, mutationResult.loading];
};
