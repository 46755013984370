import { DELIVERY_LINE_VIEW_MODE } from '@common/viewModes';

export const DEFAULT_DELIVERY_LINES_SORT = {
    field: 'plannedStartDate',
    direction: -1,
};

export const DEFAULT_MASS_EDIT_LINES_SORT = {
    field: 'deliveryLineIdx',
    direction: -1,
};

export { DELIVERY_LINE_VIEW_MODE };
