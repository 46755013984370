import { formatDistanceKm, formatKg } from '@common/utils/formaters';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useMemo } from 'react';

import { COLORS, makeStyles } from '~/@components/@theme';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import {
    dashboardFilterStore,
    ProjectTimeSpentItem,
} from '~/@views/UserView/ProjectPage/ProjectDashboard/dashboardFilter.store';
import i18n from '~/i18n';

type TableRow = {
    licensePlate: string;
    vehicleTypeName: string;
    fuel: string;
    engine: string;
    hours: number;
    CO2Consumption: number;
};

const getKey = (i: ProjectTimeSpentItem) => `${i.licensePlate}_${i.vehicleType?.name || i18n.NA}_${i.engine}_${i.fuel}`;

export const ProjectCO2OperatorsTableDashboard: FC = observer(() => {
    const { table, tableHeader, textCell, valueCell, even, boldCell } = useStyles();

    const { series, totalHours, totalCO2 } = useMemo(() => {
        const { map, totalHours, totalCO2 } = dashboardFilterStore.filteredTimeSpentItems.reduce(
            (acc, cur) => {
                const key = getKey(cur);
                if (!acc.map.has(key))
                    acc.map.set(key, {
                        licensePlate: cur.licensePlate,
                        vehicleTypeName: cur.vehicleType?.name || i18n.NA,
                        fuel: cur.fuel,
                        engine: cur.engine,
                        hours: 0,
                        CO2Consumption: 0,
                    });

                const row = acc.map.get(key)!;
                row.CO2Consumption += cur.CO2Consumption;
                row.hours += cur.hours;

                acc.totalCO2 += cur.CO2Consumption;
                acc.totalHours += cur.hours;

                return acc;
            },
            { map: new Map<string, TableRow>(), totalHours: 0, totalCO2: 0 }
        );

        const keys = Array.from(map.keys()).sort();

        const series = keys.map(k => map.get(k)!);

        return { series, totalHours, totalCO2 };
    }, [dashboardFilterStore.filteredDeliveryItems]);

    return (
        <div className={table}>
            <Grid container xs={12} spacing={1}>
                <Grid item xs={12} className={tableHeader}>
                    <LocalizableText code={'ProjectDashboard.CO2Table.operators'} />
                </Grid>
                <Grid item xs={2} className={cn(textCell, boldCell)}>
                    <LocalizableText code={'ProjectDashboard.CO2Table.regNumber'} />
                </Grid>
                <Grid item xs={2} className={cn(textCell, boldCell)}>
                    <LocalizableText code={'ProjectDashboard.CO2Table.vehicleType'} />
                </Grid>
                <Grid item xs={2} className={cn(textCell, boldCell)}>
                    <LocalizableText code={'ProjectDashboard.CO2Table.fuel'} />
                </Grid>
                <Grid item xs={2} className={cn(textCell, boldCell)}>
                    <LocalizableText code={'ProjectDashboard.CO2Table.engine'} />
                </Grid>
                <Grid item xs={2} className={cn(textCell, boldCell)}>
                    <LocalizableText code={'ProjectDashboard.CO2Table.hours'} />
                </Grid>
                <Grid item xs={2} className={cn(textCell, boldCell)}>
                    <LocalizableText code={'ProjectDashboard.CO2Table.CO2'} />
                </Grid>
                {series.map((row, i) => (
                    <>
                        <Grid item xs={2} className={i % 2 === 0 ? even : undefined}>
                            {row.licensePlate}
                        </Grid>
                        <Grid item xs={2} className={cn(textCell, i % 2 === 0 ? even : undefined)}>
                            {row.vehicleTypeName}
                        </Grid>
                        <Grid item xs={2} className={cn(textCell, i % 2 === 0 ? even : undefined)}>
                            {row.fuel}
                        </Grid>
                        <Grid item xs={2} className={cn(textCell, i % 2 === 0 ? even : undefined)}>
                            {row.engine}
                        </Grid>
                        <Grid item xs={2} className={cn(valueCell, i % 2 === 0 ? even : undefined)}>
                            {formatDistanceKm(row.hours, false)}
                        </Grid>
                        <Grid item xs={2} className={cn(valueCell, i % 2 === 0 ? even : undefined)}>
                            {formatKg(row.CO2Consumption, false)}
                        </Grid>
                    </>
                ))}
                <Grid item xs={8} className={cn(valueCell, boldCell)}>
                    <LocalizableText code={'ProjectDashboard.CO2Table.total'} />
                </Grid>
                <Grid item xs={2} className={cn(valueCell, boldCell)}>
                    {formatDistanceKm(totalHours, false)}
                </Grid>
                <Grid item xs={2} className={cn(valueCell, boldCell)}>
                    {formatKg(totalCO2, false)}
                </Grid>
            </Grid>
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    table: {
        height: 'auto',
        fontFamily: 'Catamaran',
        gap: '12px',
        alignItems: 'center',
        fontSize: '16px',

        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
        },
    },
    tableHeader: {
        textAlign: 'center',
        fontSize: '18px',

        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
        },
    },
    textCell: {
        textAlign: 'center',
    },
    valueCell: {
        textAlign: 'right',
    },
    boldCell: {
        fontWeight: 'bold',
    },
    even: {
        background: COLORS.grayLight,
    },
}));
