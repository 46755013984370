import { useUserAbilities } from '~/contexts';
import { canCreateDumpType, canDeleteDumpType, canReadDumpType, canUpdateDumpType } from '~/utils/auth';

type DumpTypePermissions = {
    canRead: boolean;
    canCreate: boolean;
    canUpdate: boolean;
    canDelete: boolean;
};

export const useDumpTypePermissions = (): DumpTypePermissions => {
    const ability = useUserAbilities();

    return {
        canRead: canReadDumpType(ability),
        canCreate: canCreateDumpType(ability),
        canUpdate: canUpdateDumpType(ability),
        canDelete: canDeleteDumpType(ability),
    };
};
