import React from 'react';

import { DestinationSort, IDestination, sortDestinations } from '~/@store/dumpLoads';

import { DumpLoadDestinationRow } from './DumpLoadDestinationRow';

type Props = {
    destinations: IDestination[];
    selectedSubareaId: string | null;
    onDestinationSelect: (destination: IDestination) => void;
    sort: DestinationSort;
    cropCount: number | null;
};

export const DumpLoadDestinationSelect = ({
    destinations,
    selectedSubareaId,
    onDestinationSelect,
    sort,
    cropCount,
}: Props) => {
    const destinationsToShow = React.useMemo(() => {
        const sorted = sortDestinations(sort, destinations);

        return cropCount ? sorted.slice(0, cropCount) : sorted;
    }, [sort, cropCount, destinations]);

    return (
        <>
            {destinationsToShow
                .filter(d => d.subarea)
                .map(destination => (
                    <DumpLoadDestinationRow
                        key={destination.subarea!.id}
                        destination={destination}
                        selected={selectedSubareaId === destination.subarea!.id}
                        onDestinationSelect={onDestinationSelect}
                    />
                ))}
        </>
    );
};
