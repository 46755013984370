import { getReceiptFileName } from '@common/functions/common.deliveryLine';
import React from 'react';

import { IDeliveryLine } from '~/@store/deliveryLines';
import { downloadReceiptUrl } from '~/apolloClient';
import { downloadFile } from '~/utils/files';

type DeliveryLineGetReceiptFunc = () => Promise<Blob>;

export const useDeliveryLineGetReceiptFunc = (deliveryLine: IDeliveryLine): DeliveryLineGetReceiptFunc => {
    return React.useCallback(() => {
        return downloadFile(
            downloadReceiptUrl,
            {
                deliveryLineId: deliveryLine.id,
                filename: deliveryLine.receiptUrl,
            },
            getReceiptFileName(deliveryLine)!,
            false,
            false
        );
    }, [deliveryLine]);
};
