import { isManagerRole } from '@common/abilities/utils';
import React, { memo, VFC } from 'react';

import { showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { DeleteIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { CellButton } from '~/@components/Table/TableCellActions/CellButton';
import { useRemoveUserFromProject } from '~/@store/users';
import { useUserAbilities } from '~/contexts';
import { canUpdateUser } from '~/utils/auth';

import { useUserDetailContext } from '../UserDetailContext';

type Props = {
    project: { id: string; name: string; ver: number };
    disabled?: boolean;
};

export const UserProjectDeleteCell: VFC<Props> = memo(({ project, disabled }) => {
    const ability = useUserAbilities();
    const user = useUserDetailContext();
    const canDelete = canUpdateUser(ability, user);

    const [deleteUserProject, deleting] = useRemoveUserFromProject();

    const handleDelete = () =>
        showDeleteConfirmationDialog(
            <LocalizableText code={'UserEditPage.deleteUserProjectConfirmation'} />,
            project.name
        ).then(confirmed => {
            if (confirmed) {
                const ignoredPromise = deleteUserProject(user.id, project.id, project.ver, isManagerRole(user.role));
            }
        });

    return (
        <CellButton onClick={handleDelete} disabled={!canDelete || deleting || !!disabled}>
            <DeleteIcon />
        </CellButton>
    );
});
