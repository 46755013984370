import { action, IObservableArray, observable } from 'mobx';

import browserHistory from '../browserHistory';
import { ComponentDialogModel, MessageDialogModel } from './models';

class DialogStore {
    constructor() {
        browserHistory.listen(this.closeAllDialogs);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dialogs: IObservableArray<MessageDialogModel | ComponentDialogModel<any>> = observable([]);

    closeAllDialogs = () => {
        this.dialogs.forEach(d => {
            d.resolver(false);
        });
    };

    @action
    _showDialog = <T = boolean>(dialog: MessageDialogModel | ComponentDialogModel<T>): Promise<T> =>
        new Promise<T>(resolve => {
            // Resolver type are different in MessageDialogModel and ComponentDialogModel
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dialog.resolver = (result: any) => resolve(result);
            this.dialogs.push(dialog);
        }).then(result => {
            this.dialogs.remove(dialog);

            return result;
        });
}

export default new DialogStore();
