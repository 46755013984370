import { IAbilityLandfill } from '@common/abilities/landfills';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { IDeviation } from '~/@user-store/deviations';
import { useDeviationLandfillAbilities } from '~/@user-store/deviations/deviation.abilities';
import { landfillDeviationMutations } from '~/@user-store/deviations/deviation.landfill.mutations';
import { DeviationForm } from '~/@views/UserView/common/DeviationForm';
import { landfillDeviationPhotoUrl } from '~/apolloClient';

type Props = {
    closeDialog: () => void;
    deviation?: IDeviation | null;
    landfill: IAbilityLandfill & { id: string; name: string };
};

export const LandfillDeviationForm = ({ closeDialog, deviation, landfill }: Props) => {
    const abilities = useDeviationLandfillAbilities(deviation || null, landfill);

    return (
        <DeviationForm
            closeDialog={closeDialog}
            deviation={deviation}
            mutations={landfillDeviationMutations}
            abilities={abilities}
            parentEntity={landfill}
            parentEntityLabel={<LocalizableText code={'landfill'} />}
            photoUrl={landfillDeviationPhotoUrl}
        />
    );
};
