import { useCallback, useState } from 'react';

type Func<Args extends unknown[], Return> = (...args: Args) => Return;

/** Add { loading: boolean } state to async function */
export const useProgressState = <Args extends unknown[], Return extends Promise<unknown>>(
    asyncFunc: Func<Args, Return>
): [boolean, Func<Args, Return>] => {
    const [loading, setLoading] = useState(false);

    const wrappedFunc: Func<Args, Return> = useCallback(
        (...args: Args) => {
            setLoading(true);

            return asyncFunc(...args).finally(() => setLoading(false)) as Return;
        },
        [asyncFunc]
    );

    return [loading, wrappedFunc];
};
