import { isValid } from 'date-fns';
import { FormikContextType } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';

import { DatePicker, DatePickerProps } from '~/@components/DatePicker';

type FormikDateFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends Date | null ? TFieldName : never;
    formik: FormikContextType<TValues>;
} & Omit<DatePickerProps, 'onChange' | 'value'>;

export function FormikDateField<TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    disabled,
    ...restProps
}: FormikDateFieldProps<TValues, TFieldName>) {
    const value = formik.values[name];

    const [inputValue, setInputValue] = useState<Date | null>(null);

    useEffect(() => {
        if (value instanceof Date) setInputValue(value);
    }, [value]);

    const handleChange = useCallback(
        (v: Date | null) => {
            setInputValue(v);
            formik.setFieldTouched(name, true);
            if (isValid(v)) {
                formik.setFieldValue(name, v);
            } else {
                formik.setFieldValue(name, null);
            }
        },
        [name, formik]
    );

    return (
        <DatePicker
            fullWidth
            clearable
            autoOk
            name={name}
            value={inputValue}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[name] && !!formik.errors[name]}
            helperText={formik.touched[name] && formik.errors[name]}
            disabled={disabled || formik.isSubmitting}
            {...restProps}
        />
    );
}
