import { AbilitySubjects } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { IAbilityTargetUser, IUserCalculatedSubject, IUserRelationRole, IUserSubject } from './userTypes';

export const getUserSubject = (
    user: IAbilityUser,
    targetUser?: IAbilityTargetUser | null | undefined,
    subject?: IUserSubject
): IUserCalculatedSubject => {
    const subSubject = subject ? [subject] : [];
    const relationRole: IUserRelationRole[] = [user.role!];

    if (targetUser) {
        subSubject.push(targetUser.role!);
    }

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.USER,
        relationRole,
        subSubject,
    };
};
