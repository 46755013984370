import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const AccountIconOutlined = ({ fill = 'currentcolor', ...props }: SvgIconProps) => {
    return (
        <SvgIcon width="18" height="22" viewBox="0 0 18 22" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.64888 0.0636709C8.87526 -0.0212236 9.12474 -0.0212236 9.35112 0.0636709L17.3511 3.06367C17.7414 3.21003 18 3.58316 18 4V11C18 14.4464 15.7183 17.2003 13.6585 19.0026C12.6076 19.9221 11.5615 20.6408 10.78 21.1292C10.3882 21.3741 10.0603 21.5627 9.82807 21.6913C9.71187 21.7556 9.61943 21.805 9.55468 21.839C9.5223 21.856 9.49683 21.8692 9.47876 21.8784L9.45726 21.8894L9.45078 21.8926L9.44863 21.8937C9.44863 21.8937 9.44721 21.8944 9 21C8.55279 21.8944 8.55217 21.8941 8.55217 21.8941L8.54922 21.8926L8.54274 21.8894L8.52124 21.8784C8.50317 21.8692 8.4777 21.856 8.44532 21.839C8.38057 21.805 8.28813 21.7556 8.17193 21.6913C7.93968 21.5627 7.61177 21.3741 7.22 21.1292C6.43854 20.6408 5.39238 19.9221 4.3415 19.0026C2.28175 17.2003 0 14.4464 0 11V4C0 3.58316 0.258573 3.21003 0.648877 3.06367L8.64888 0.0636709ZM9 21L8.55217 21.8941C8.8337 22.0349 9.16569 22.0352 9.44721 21.8944L9 21ZM9 19.8628C9.19164 19.7541 9.4367 19.6103 9.72 19.4333C10.4385 18.9842 11.3924 18.3279 12.3415 17.4974C14.2817 15.7997 16 13.5536 16 11V4.693L9 2.068L2 4.693V11C2 13.5536 3.71825 15.7997 5.6585 17.4974C6.60762 18.3279 7.56146 18.9842 8.28 19.4333C8.5633 19.6103 8.80836 19.7541 9 19.8628Z"
                fill={fill}
            />
        </SvgIcon>
    );
};
