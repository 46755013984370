import { ContaminationType, SubstanceGroupBy } from '@common/enums';
import { formatString } from '@common/utils';
import { FormikConfig } from 'formik';
import * as Yup from 'yup';

import { ISubstance, ISubstanceChild } from '~/@store/substances';
import i18n from '~/i18n';

export type ISubstanceFormValues = {
    id: string | null;
    name: string;
    contaminationTypes: Array<ContaminationType>;
    groupBy: SubstanceGroupBy;
    parentId: string | null;
};

type SubstanceSaveFunc = (v: ISubstanceFormValues) => Promise<unknown>;

const getInitialValues = (
    substance: ISubstance | ISubstanceChild | null,
    parentId: string | null = null,
    contaminationType: ContaminationType | null = null
): ISubstanceFormValues => {
    if (substance?.__typename === 'Substance')
        return {
            id: substance.id,
            name: substance.name || '',
            contaminationTypes: substance.contaminationTypes || [],
            groupBy: substance.groupBy || SubstanceGroupBy.SUM,
            parentId: null,
        };

    if (substance?.__typename === 'SubstanceChild')
        return {
            id: substance.id,
            name: substance.name || '',
            contaminationTypes: [],
            groupBy: SubstanceGroupBy.SUM,
            parentId: substance.parentId,
        };

    return {
        id: null,
        name: '',
        contaminationTypes: contaminationType ? [contaminationType] : [],
        groupBy: SubstanceGroupBy.SUM,
        parentId,
    };
};

export const getSubstanceEditFormikConfig = (
    substance: ISubstance | ISubstanceChild | null,
    parentId: string | null | undefined,
    contaminationType: ContaminationType | null | undefined,
    saveFunc: SubstanceSaveFunc
): FormikConfig<ISubstanceFormValues> => ({
    initialValues: getInitialValues(substance, parentId, contaminationType),
    validationSchema: Yup.object().shape({
        name: Yup.string().trim().required(formatString(i18n.isRequired, i18n.substanceName)),
    }),
    onSubmit: async values => {
        await saveFunc(values);
    },
});
