import { AbilitySubjects } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { ISubstanceCalculatedSubject } from './substanceTypes';

export const getSubstanceSubject = (user: IAbilityUser): ISubstanceCalculatedSubject => {
    const relationRole = [user.role!];

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.SUBSTANCE,
        relationRole,
    };
};
