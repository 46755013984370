import React, { useMemo } from 'react';

import { CustomTabs, ICustomTabConfig } from '~/@components/CustomTabs';
import { LocalizableText } from '~/@components/LocalizableText';
import { UserAddButton } from '~/@views/AdminView/UsersPage/UserAddButton';
import { UserRole } from '~/graphql';

import { useCompanyDetailContext } from '../CompanyDetailContext';
import { CompanyEditTab } from './CompanyEditTab';
import { CompanyLandfillsTab } from './CompanyLandfillsTab';
import { CompanyProjectsTab } from './CompanyProjectsTab';
import { CompanyUsersTab } from './CompanyUsersTab';

export const CompanyTabs = () => {
    const company = useCompanyDetailContext();

    const tabs: Array<ICustomTabConfig> = useMemo(
        () => [
            {
                label: <LocalizableText code={'CompanyCard.tabs.edit'} />,
                render: () => <CompanyEditTab />,
            },
            {
                label: <LocalizableText code={'CompanyCard.tabs.projects'} />,
                render: () => <CompanyProjectsTab />,
                fullWidth: true,
            },
            {
                label: <LocalizableText code={'CompanyCard.tabs.landfills'} />,
                render: () => <CompanyLandfillsTab />,
                fullWidth: true,
            },
            {
                label: <LocalizableText code={'CompanyCard.tabs.users'} />,
                render: () => <CompanyUsersTab />,
                rightActionButton: () => (
                    <UserAddButton
                        defaultValues={{
                            customer: company,
                            role: UserRole.EXTERNAL_RECEIVER,
                        }}
                    />
                ),
                fullWidth: true,
            },
        ],
        [company]
    );

    return <CustomTabs title={company.name} tabs={tabs} />;
};
