import { TableCell, TableCellProps } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { MAX_WIDTH, MIN_WIDTH, tableWidthStore } from '~/@store/common/tableWidthStore';

interface Props extends TableCellProps {
    columnName: string;
    tableName: string;
    minWidth?: number;
}

export const ResizableTableCell = observer(
    ({ columnName, tableName, style, minWidth: columnMinWidth, ...rest }: Props) => {
        const width = tableWidthStore.widths[tableWidthStore.getKey(tableName, columnName)];
        let minWidth = width ?? MIN_WIDTH;
        if (columnMinWidth && minWidth < columnMinWidth) minWidth = columnMinWidth;
        const maxWidth = width ?? MAX_WIDTH;

        style = { ...style, minWidth, maxWidth };

        return <TableCell style={style} {...rest} />;
    }
);
