import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

import { formatDate } from '~/utils/date';

interface Props {
    children: Date | string | null | void;
}

export const DateFormatter = ({ children }: Props) => {
    const { root, grey } = useStyles();

    return <div className={classNames(root, { [grey]: !children })}>{formatDate(children)}</div>;
};

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 120,
    },
    grey: {
        color: theme.palette.grey[400],
    },
}));
