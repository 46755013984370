import './DeliveriesTable.scss';

import { DocumentTypes } from '@common/enums';
import { getLoadPercentage } from '@common/functions/common.deliveryLine';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import { Button } from '~/@components/Button';
import {
    ContaminationLevelFormatter,
    DistanceFormatter,
    NullableFormatter,
    TonnsFormatter,
} from '~/@components/Formatters';
import { LaptopIcon, TruckIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { DisplayItemCountSelect } from '~/@sochi-components/DisplayItemCountSelect';
import { SearchIcon } from '~/@sochi-components/Icons';
import type { ISochiTableColumn } from '~/@sochi-components/SochiTable';
import { SochiTable, SochiTableCellNoPadding } from '~/@sochi-components/SochiTable';
import { SochiHeadCellAlignment } from '~/@sochi-components/SochiTable/SochiTableHeadCell';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { TransportReceiptDialog } from '~/@sochi-components/TransportReceiptDialog';
import { BulkDownloadStore } from '~/@user-store/weightReceipts/bulkDownloadStore';
import {
    ProjectWeightReceiptFilterField,
    ProjectWeightReceiptInfo,
    projectWeightReceiptsStore,
} from '~/@user-store/weightReceipts/projectWeightReceipts.store';
import { BulkDownloadDialog } from '~/@views/UserView/common/BulkDownloadDialog/BulkDownloadDialog';
import { LoadPercentageCell } from '~/@views/UserView/common/LoadPercentageCell';
import { DeliveryLineTimesField } from '~/@views/UserView/ProjectPage/common/DeliveriesTable/DeliveryLineTimesField';
import { DriverCommentField } from '~/@views/UserView/ProjectPage/common/DeliveriesTable/DriverCommentField';
import { SenderCommentField } from '~/@views/UserView/ProjectPage/common/DeliveriesTable/SenderCommentField';
import { ProjectQuery_project_dumpLoads } from '~/graphql';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';
import { formatDate, parseDateFrom } from '~/utils/date';
import { downloadProjectReceipts, downloadProjectReceiptsXlsx } from '~/utils/project';

import { DeliveriesTableHeadCell } from './DeliveriesTableHeadCell';

type DumpLoad = ProjectQuery_project_dumpLoads;

type Props = {
    projectId: string;
    dumpLoads: DumpLoad[];
    downloadParams?: {
        id: string;
        name: string;
    };
    title: React.ReactNode;
};

const getLinesInfo = (dumpLoads: DumpLoad[]): Array<ProjectWeightReceiptInfo> =>
    dumpLoads
        .map(dumpLoad =>
            (dumpLoad.deliveryLines || []).map(dl => ({
                id: dl.id,
                receiptUrl: dl.receiptUrl,
                plannedStartDate: dl.plannedStartDate,
                excavationDate: dl.excavationDate,
                startDeliveryDate: dl.startDeliveryDate,
                endDeliveryDate: dl.endDeliveryDate,
                landfillName: dumpLoad.destinationLandfill?.name || null,
                orderNumber: dl.orderNumber,
                truckRegistrationNumber: dl.truckRegistrationNumber,
                truckCapacity: dl.truckCapacity,
                weight: dl.weight,
                serialNumber: dumpLoad.serialNumber,
                dumpType: dumpLoad.dumpType,
                solidTestResult: dumpLoad.solidTestResult,
                customerInvoice: dl.customerInvoiceNumber,
                distance: dumpLoad.distance,
                comment: dumpLoad.comment,
                senderComment: dl.senderComment,
                loadPercentage: getLoadPercentage(dl),
                company: dl.company,
                excavatorOperatorName: dl.excavatorOperator?.name || null,
                status: dl.status,
                driverComment: dl.driverComment,
            }))
        )
        .flat();

export const DeliveriesTable = ({ dumpLoads, downloadParams, title, projectId }: Props) => {
    const { className, element } = useFunctionalBem(DeliveriesTable);

    useEffect(() => {
        projectWeightReceiptsStore.setList(getLinesInfo(dumpLoads));
    }, [dumpLoads]);

    useEffect(() => {
        return () => projectWeightReceiptsStore.clear();
    }, []);

    const filteredLines: Array<ProjectWeightReceiptInfo> = useObservable(() => projectWeightReceiptsStore.filteredList);
    const lines: Array<ProjectWeightReceiptInfo> = useObservable(() => projectWeightReceiptsStore.itemsToDisplay);
    const isAnyFilterSet: boolean = useObservable(() => projectWeightReceiptsStore.isAnyFilterSet);

    const downloadReceipts = useCallback(() => {
        if (!downloadParams) return;
        const { id: projectId, name } = downloadParams;

        const ids = filteredLines.map(l => l.id);
        const downLoadFunc = (ids: string[], index: number) =>
            downloadProjectReceipts(projectId, `${name}-${i18n.receipts}-page-${index + 1}`, ids);

        const store = new BulkDownloadStore(downLoadFunc, ids);

        const ignoredPromise = showCustomDialog({
            title: i18n.receipts,
            render: closeDialog => <BulkDownloadDialog store={store} onClose={closeDialog} />,
        });
    }, [filteredLines, downloadParams]);

    const downloadReceiptsXlsx = useCallback(() => {
        if (downloadParams) {
            const ignoredPromise = downloadProjectReceiptsXlsx(
                downloadParams.id,
                downloadParams.name,
                filteredLines.map(l => l.id)
            );
        }
    }, [filteredLines, downloadParams]);

    const showReceipt = useCallback(transportedData => {
        const ignoredPromise = showCustomDialog({
            forceCrossClose: true,
            render: closeDialog => (
                <TransportReceiptDialog
                    closeDialog={closeDialog}
                    transportedData={transportedData}
                    documentType={DocumentTypes.PROJECT}
                />
            ),
        });
    }, []);

    const columns = useMemo((): Array<ISochiTableColumn<ProjectWeightReceiptInfo>> => {
        return [
            {
                render: (data: ProjectWeightReceiptInfo) => data.serialNumber,
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.declarationNumber'} />}
                        fieldName={ProjectWeightReceiptFilterField.serialNumber}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => <SenderCommentField line={data} projectId={projectId} />,
                title: (
                    <DeliveriesTableHeadCell
                        title={<LaptopIcon />}
                        fieldName={ProjectWeightReceiptFilterField.senderComment}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                cellClassName: element('sender-notes'),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => <DriverCommentField line={data} projectId={projectId} />,
                title: (
                    <DeliveriesTableHeadCell
                        title={<TruckIcon />}
                        fieldName={ProjectWeightReceiptFilterField.driverComment}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                cellClassName: element('driver-notes'),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => (
                    <NullableFormatter>{data.customerInvoice}</NullableFormatter>
                ),
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.customerInvoice'} />}
                        fieldName={ProjectWeightReceiptFilterField.customerInvoice}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => {
                    return formatDate(parseDateFrom(data.plannedStartDate));
                },
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.date'} />}
                        fieldName={ProjectWeightReceiptFilterField.plannedStartDate}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => data.dumpType?.name,
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.mass'} />}
                        fieldName={ProjectWeightReceiptFilterField.dumpTypeName}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => (
                    <ContaminationLevelFormatter>{data.solidTestResult}</ContaminationLevelFormatter>
                ),
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.level'} />}
                        fieldName={ProjectWeightReceiptFilterField.solidTestResult}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => <NullableFormatter>{data.comment}</NullableFormatter>,
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.comment'} />}
                        fieldName={ProjectWeightReceiptFilterField.comment}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => data.landfillName,
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.landfillName'} />}
                        fieldName={ProjectWeightReceiptFilterField.landfillName}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => (
                    <NullableFormatter>{data.truckRegistrationNumber}</NullableFormatter>
                ),
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.registrationNumber'} />}
                        fieldName={ProjectWeightReceiptFilterField.truckRegistrationNumber}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => (
                    <NullableFormatter>{data.excavatorOperatorName}</NullableFormatter>
                ),
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.excavatorOperator'} />}
                        fieldName={ProjectWeightReceiptFilterField.excavatorOperatorName}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => <DeliveryLineTimesField line={data} />,
                title: '',
                cellComponent: SochiTableCellNoPadding,
            },
            {
                render: (data: ProjectWeightReceiptInfo) => <TonnsFormatter>{data.truckCapacity}</TonnsFormatter>,
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.truckCapacity'} />}
                        fieldName={ProjectWeightReceiptFilterField.truckCapacity}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => <TonnsFormatter>{data.weight}</TonnsFormatter>,
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.weight'} />}
                        fieldName={ProjectWeightReceiptFilterField.weight}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: data => <LoadPercentageCell percent={data.loadPercentage} />,
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.loadPercentage'} />}
                        fieldName={ProjectWeightReceiptFilterField.loadPercentage}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: data => <DistanceFormatter>{data.distance}</DistanceFormatter>,
                title: (
                    <DeliveriesTableHeadCell
                        title={<LocalizableText code={'ProjectDocuments.weightReceiptTable.distance'} />}
                        fieldName={ProjectWeightReceiptFilterField.distance}
                        withFilter
                        withSort
                    />
                ),
            },
            {
                render: (data: ProjectWeightReceiptInfo) => (
                    <span
                        className={element('view', { disabled: !data.receiptUrl })}
                        onClick={() => (data.receiptUrl ? showReceipt(data) : undefined)}>
                        <SearchIcon color={data.receiptUrl ? 'primary' : 'disabled'} fontSize="inherit" />
                        <LocalizableText code={'view'} />
                    </span>
                ),
                title: <LocalizableText code={'ProjectDocuments.weightReceiptTable.scaleReceipt'} />,
            },
        ];
    }, [element, showReceipt, projectId]);

    return (
        <div className={className}>
            <SochiTitle title={title}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => projectWeightReceiptsStore.filter.clearValues()}
                    disabled={!isAnyFilterSet}>
                    {i18n.clear}
                </Button>
                {downloadParams && (
                    <>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={filteredLines.length === 0}
                            onClick={downloadReceiptsXlsx}>
                            <LocalizableText code={'ProjectDocuments.buttons.exportToXlsx'} />
                            {filteredLines.length ? <>&nbsp;({filteredLines.length})</> : null}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={filteredLines.length === 0}
                            onClick={downloadReceipts}>
                            <LocalizableText
                                code={
                                    isAnyFilterSet
                                        ? 'ProjectDocuments.buttons.downloadSelected'
                                        : 'ProjectDocuments.buttons.downloadAll'
                                }
                            />
                            {filteredLines.length ? <>&nbsp;({filteredLines.length})</> : null}
                        </Button>
                    </>
                )}
                <DisplayItemCountSelect store={projectWeightReceiptsStore} />
            </SochiTitle>
            <SochiTable
                columns={columns}
                items={lines}
                keyGetter={(item: ProjectWeightReceiptInfo) => item.id}
                emptyListMessage={
                    <LocalizableText
                        code={
                            isAnyFilterSet
                                ? 'noDataForFilter'
                                : 'ProjectDocuments.weightReceiptTable.cantFetchWeightReceiptRightNow'
                        }
                    />
                }
                stickyFirstColumn
                className={element('table')}
            />
        </div>
    );
};
