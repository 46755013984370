import { useCallback } from 'react';

import { exportDeliveryLinesUrl } from '~/apolloClient';
import { downloadFilePost } from '~/utils/files';

import { deliveryLinesStore } from '../deliveryLines.store';

export const useDownloadDeliveryLinesReport = () =>
    useCallback((type: 'pdf' | 'xlsx') => {
        const { filter, sort } = deliveryLinesStore.deliveryLinesQueryVariables;

        const ignoredPromise = downloadFilePost(exportDeliveryLinesUrl, { type, filter, sort }, `report.${type}`);
    }, []);
