import './SochiInfoPanel.scss';

import React from 'react';

import { getBem } from '~/@sochi-components/@bem';

import { Colors } from '../../../../common/enums';

type SochiInfoPanelColor = Colors.green | Colors.orange;

type SochiInfoPanelProps = {
    text?: React.ReactNode;
    color?: SochiInfoPanelColor;
    children?: React.ReactNode;
    // eslint-disable-next-line react/no-unused-prop-types
    className?: string;
};

export class SochiInfoPanel extends React.Component<SochiInfoPanelProps> {
    static defaultProps = {
        text: '',
        color: Colors.green,
        children: '',
    };

    bem = getBem(this);

    render() {
        const { text, color, children } = this.props;

        const { element, block } = this.bem;

        return (
            <div className={block(String(color))}>
                <div className={element('text')}>{text}</div>
                <div className={element('buttons')}>{children}</div>
            </div>
        );
    }
}
