import { Button, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';

import { COLORS } from '~/@components/@theme/colors';
import { SimpleDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { TextField } from '~/@components/TextField';
import { showCustomDialog } from '~/@store/common';
import { IDetailedUser } from '~/@store/users';

import { ChangePasswordForm } from './ChangePasswordForm';
import { ResetPasswordControl } from './ResetPasswordControl';

interface Props {
    user?: IDetailedUser;
}

export const ChangePasswordControl = ({ user }: Props) => {
    const { changePasswordButton, changePassword, changePasswordInput } = useStyles();

    const showChangePasswordDialog = useCallback(() => {
        showCustomDialog({
            render: closeDialog => (
                <SimpleDialog onClose={closeDialog} maxWidth="md">
                    <ChangePasswordForm userId={user?.id} onClose={closeDialog} />
                </SimpleDialog>
            ),
        });
    }, [user?.id]);

    return (
        <div className={changePassword}>
            <TextField
                className={changePasswordInput}
                label={<LocalizableText code={'AccountPage.password'} />}
                value="************"
                disabled
            />

            {user && <ResetPasswordControl user={user} />}

            <Button className={changePasswordButton} variant="outlined" onClick={showChangePasswordDialog}>
                <LocalizableText code={'change'} />
            </Button>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    changePassword: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    changePasswordInput: {
        flexGrow: 1,
        backgroundColor: COLORS.white,
        marginRight: theme.spacing(2),
    },

    changePasswordButton: {
        marginLeft: theme.spacing(2),
        height: 40,
    },
}));
