import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import React, { FC, useCallback } from 'react';

import { Button } from '~/@components/Button';
import { Divider } from '~/@components/Divider';
import { ContaminationLevelFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { COLOR } from '~/@sochi-components/@theme';
import { DocumentIcon, DownloadIcon } from '~/@sochi-components/Icons';
import { useBatchAnalysisRemove } from '~/@user-store/landfillBatches';
import { showLandfillBatchAnalysisForm } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchAnalysisForm';
import { IBatch } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm';
import { IAnalysis } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/landfillBatchLog.types';
import { downloadBatchAnalysis } from '~/apolloClient';
import { LandfillQuery_landfill } from '~/graphql';
import i18n from '~/i18n';
import { showConfirmDialog } from '~/services/dialog';
import { formatDate } from '~/utils/date';
import { downloadFile } from '~/utils/files';

type Props = {
    landfill: LandfillQuery_landfill;
    batch: IBatch;
    item: IAnalysis;
    disabled?: boolean;
};

export const BatchAnalysisCard: FC<Props> = ({ landfill, batch, item, disabled = false }) => {
    const { root, distributedRow, contentGroup, grayText, contentGroupCondensed, clickable } = useStyles();

    const [remove, isRemoving] = useBatchAnalysisRemove(landfill.id, batch.id);

    const handleDownloadFile = useCallback(() => {
        const extension = item.analysisFileUrl.split('.').pop();
        if (!extension) return;

        const ignoredPromise = downloadFile(
            downloadBatchAnalysis,
            { landfillId: landfill.id, fileName: item.analysisFileUrl },
            `report-${item.id}.${extension}`
        );
    }, [item, landfill]);

    const onRemove = useCallback(async () => {
        const answer = await showConfirmDialog({
            title: <LocalizableText code={'LandfillPage.analysisCard.deleteTitle'} />,
            message: <LocalizableText code={'LandfillPage.analysisCard.deleteMessage'} />,
        });

        if (answer) {
            await remove(item.id);
        }
    }, [remove, item]);

    const readOnly = disabled || isRemoving;

    return (
        <div className={root}>
            <div className={distributedRow}>
                <div className={contentGroup}>
                    <DocumentIcon color="disabled" />
                    <Text variant="BodyBold">
                        <LocalizableText code={'LandfillPage.batchLogCard.analysisTitle'} />
                    </Text>
                </div>
                <div className={contentGroup}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => showLandfillBatchAnalysisForm(landfill, batch, item)}
                        size="small"
                        disabled={readOnly}>
                        {i18n.edit}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={onRemove} size="small" disabled={disabled}>
                        {i18n.delete}
                    </Button>
                </div>
            </div>
            <Grid container spacing={2}>
                <Grid item lg={3} xs={4}>
                    <div className={contentGroup}>
                        <Text variant="BodyRegular">
                            <LocalizableText code={'LandfillPage.batchLogCard.contaminationLevel'} />
                        </Text>
                    </div>
                </Grid>
                <Grid item lg={9} xs={8}>
                    <div className={cn(contentGroup, clickable)} onClick={isRemoving ? undefined : handleDownloadFile}>
                        <ContaminationLevelFormatter>{item.analysisResult}</ContaminationLevelFormatter>
                        <div className={contentGroupCondensed}>
                            <DownloadIcon color={isRemoving ? 'disabled' : 'primary'} />
                            <Text variant="BodyRegular">{i18n.download}</Text>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Text variant="BodyRegular" className={grayText}>
                        {formatDate(item.date)}
                    </Text>
                </Grid>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: COLOR.white,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: '12px',
        width: '100%',
    },
    distributedRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contentGroup: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'center',
        height: '100%',
    },
    contentGroupCondensed: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
    },
    grayText: {
        color: COLOR.grayDarker,
    },
    clickable: {
        cursor: 'pointer',
    },
}));
