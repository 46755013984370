import { LandfillFields } from '@common/enums';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { COLORS } from '~/@components/@theme/colors';
import { Chip } from '~/@components/Chip';
import { removeEmptyKeys } from '~/@store/common';
import { landfillsStore, LandfillsStoreFilter, LandfillsStoreFilterFields } from '~/@store/landfills';
import { formatFilterValue } from '~/@views/AdminView/common/utils';
import i18n from '~/i18n';

type Props = {};

const FieldNames: LandfillsStoreFilter = {
    [LandfillFields.name]: i18n.LandfillsTable.landfillName,
    [LandfillFields.location]: i18n.LandfillsTable.location,
    [LandfillFields.comment]: i18n.LandfillsTable.comments,
};

export const LandfillsFilterSummary = (_props: Props) => {
    const filterValues = useObservable(() => removeEmptyKeys({ ...landfillsStore.filter.values }));

    const { root, chip } = useStyles();

    return (
        <div className={root}>
            {Object.keys(filterValues).map(objectKey => {
                const key = objectKey as LandfillsStoreFilterFields;
                const value = filterValues[key];
                if (value === null || value === undefined) return null;
                const chipContent = `${FieldNames[key]}: "${formatFilterValue(value)}"`;

                return (
                    <Chip
                        key={key}
                        className={chip}
                        color={COLORS.black}
                        onDelete={() => landfillsStore.filter.setValue(key, null)}
                        deleteIcon={<Icon>close</Icon>}>
                        {chipContent}
                    </Chip>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        marginRight: theme.spacing(1),
    },
}));
