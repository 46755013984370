import { GQL_MAX_INT, GQL_MIN_INT } from '@common/constants';
import { isNumber } from '@common/validations/types.validation';
import React, { useCallback, useMemo, useState } from 'react';

import { fmtDecimal } from '~/utils/numbers';

type NumberFieldConfig = {
    precision: number;
    autoFocus?: boolean;
    value: number | null;
    min?: number;
    max?: number;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    forceZero?: boolean;
};

type NumberFieldState = {
    displayValue: string;
    handleFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
    handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    handleChangeEventAndGetValue: (event: React.ChangeEvent<HTMLInputElement>) => number | undefined;
};

const parseValue = (v: string): number => {
    v = v.replace(',', '.');

    return isNaN(+v) ? 0 : Number(v);
};

export const useNumberFieldState = ({
    precision,
    autoFocus,
    value,
    min,
    max,
    onFocus,
    onBlur,
    forceZero,
}: NumberFieldConfig): NumberFieldState => {
    min = precision === 0 && !isNumber(min) ? GQL_MIN_INT : min;
    max = precision === 0 && !isNumber(max) ? GQL_MAX_INT : max;

    const formatRE = useMemo(
        () => (precision === 0 ? /^(-)?[0-9]*$/ : new RegExp(`^(-)?[0-9]*([\.,][0-9]{0,${precision}})?$`)),
        [precision]
    );

    const [focused, setFocused] = useState(autoFocus);
    const [localValue, setLocalValue] = useState(String(value || 0));

    const validateValue = useCallback(
        (value: number): boolean => {
            if (isNumber(min) && value < min) return false;
            if (isNumber(max) && value > max) return false;

            return true;
        },
        [min, max]
    );

    const clampValue = useCallback(
        (value: number): number => {
            if (isNumber(min)) value = Math.max(min, value);
            if (isNumber(max)) value = Math.min(max, value);

            return value;
        },
        [min, max]
    );

    const handleChangeEventAndGetValue = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): number | undefined => {
            const eventValue = event.target.value;
            if (!formatRE.test(eventValue)) return;
            const parsedValue = parseValue(eventValue);

            if (validateValue(parsedValue)) {
                setLocalValue(eventValue);

                return parsedValue;
            } else {
                const validValue = clampValue(parsedValue);
                setLocalValue(validValue.toString());

                return validValue;
            }
        },
        [formatRE, clampValue, validateValue]
    );

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(true);
        setLocalValue(value ? String(Number(Number(value).toFixed(precision))) : '');
        onFocus && onFocus(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        setLocalValue('');
        onBlur && onBlur(e);
    };

    const displayValue = useMemo(() => {
        if (focused) return localValue.replace('.', ',');
        if (forceZero && value === 0) return '0';

        return !value ? '' : fmtDecimal(value, precision);
    }, [focused, value, localValue, precision, forceZero]);

    return { displayValue, handleFocus, handleBlur, handleChangeEventAndGetValue };
};
