import React, { useMemo, VFC } from 'react';

import { ExtendedDespatchAdvice } from '~/@components/BeastDataDialog/BeastDataTable/useBeastDataColumns';
import { DateTimeFormatter, NullableFormatter } from '~/@components/Formatters';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { saveToClient } from '~/utils/files';

type Props = {
    despatchAdvice: ExtendedDespatchAdvice;
};

export const DespatchAdviceResponseInfoCell: VFC<Props> = ({ despatchAdvice }) => {
    const actions: Array<ITableCellAction> = useMemo(
        () => [
            {
                variant: ActionVariant.DOWNLOAD_FILE,
                onClick: () =>
                    !despatchAdvice.response
                        ? {}
                        : saveToClient(despatchAdvice.response.body, `${despatchAdvice.response.transactionId}.xml`),
            },
        ],
        [despatchAdvice]
    );

    if (!despatchAdvice.response) return <NullableFormatter>{null}</NullableFormatter>;

    return (
        <TableCellActions actions={actions}>
            <DateTimeFormatter>{despatchAdvice.response.date}</DateTimeFormatter>
        </TableCellActions>
    );
};
