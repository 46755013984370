import './ProjectStatus.scss';

import React from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ProjectStatus as ProjectStatusType } from '~/graphql';
import i18n from '~/i18n';

const ProjectStatusDisplay: Record<ProjectStatusType, string> = {
    NEW: i18n.projectStatuses.NEW,
    IN_PROGRESS: i18n.projectStatuses.IN_PROGRESS,
    DONE: i18n.projectStatuses.DONE,
    DELETED: i18n.projectStatuses.DELETED,
};

export type IProjectStatus = ProjectStatusType | null;

type Props = {
    status: IProjectStatus;
    className?: string;
};

export const ProjectStatus = ({ status, className }: Props) => {
    const { block } = useFunctionalBem(ProjectStatus, className);

    return (
        <div
            className={block({
                ongoing: status && ProjectStatusDisplay[status] === i18n.projectStatuses.NEW,
                completed: status && ProjectStatusDisplay[status] === i18n.projectStatuses.DONE,
                booked: status && ProjectStatusDisplay[status] === i18n.projectStatuses.IN_PROGRESS,
            })}>
            {(status && ProjectStatusDisplay[status]) || ''}
        </div>
    );
};
