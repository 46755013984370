import { Button, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isFunction } from 'lodash';
import React from 'react';

import { DisableProvider } from '~/@components/@hooks';
import { Section } from '~/@components/Section';
import { Text } from '~/@components/Text';
import { COLOR } from '~/@sochi-components/@theme';
import { useLock } from '~/@store/locks/useLock';
import i18n from '~/i18n';

import { LockEntityType } from '../../../../common/enums';

type Props = {
    entityType: LockEntityType;
    entityId: string | null | undefined;
    children: React.ReactNode | ((canEdit: boolean) => React.ReactNode);
};

export const WithLock = ({ entityType, entityId, children }: Props) => {
    const skipLock = !entityId;
    const { canEdit, error, loading, retry } = useLock(entityType, entityId || '', skipLock);
    const { section, retryButton } = useStyles();

    const infoBlock = error ? (
        <Section className={section} dense>
            <Text variant="BodyRegular">{error}</Text>
            <Button className={retryButton} variant="contained" color="primary" onClick={retry}>
                {i18n.retry}
            </Button>
        </Section>
    ) : null;

    return (
        <>
            {loading ? <LinearProgress /> : null}
            {infoBlock}
            <DisableProvider disabled={!canEdit}>{isFunction(children) ? children(canEdit) : children}</DisableProvider>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    section: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(1),
        alignItems: 'center',
        borderColor: COLOR.red,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    retryButton: {
        whiteSpace: 'nowrap',
    },
}));
