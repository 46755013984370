import { precisions } from '@common/utils/formaters';

export * from '../../../common/enums';

export { UserRole } from '../../../common/enums';
export { ErrorCodes as errorCodes } from '../../../common/enums';
export { Units as unitMeasurements } from '../../../common/enums';

export enum DumpLoadActionType {
    LINK = 'LINK',
    BUTTON = 'BUTTON',
}

export const ContaminationLevelCustom = 'CUSTOM';
export const ContaminationLevelSelect = 'SELECT';

export enum ScheduleDateFilters {
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export type IScheduleDateFilters = `${ScheduleDateFilters}`;

export enum commonInputTypes {
    boolean = 'boolean',
    text = 'text',
    password = 'password',
    hour = 'hour',
    coords = 'coords',
    minute = 'minute',
    decimal = 'decimal',
    price = 'price',
}

export { precisions as commonPrecisions } from '@common/utils/formaters';

export enum TextAlign {
    center = 'center',
    left = 'left',
}

export type ITextAlign = `${TextAlign}`;

export enum ChartValueType {
    SEK = 'SEK',
    VOL = 'VOL',
}

export type IChartValueType = `${ChartValueType}`;

export enum ChartMaterialCategory {
    ALL = 'ALL',
    OVER50 = 'OVER50',
    NON_STACKABLE = 'NON_STACKABLE',
}

export type IChartMaterialCategory = `${ChartMaterialCategory}`;
