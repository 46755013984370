import React, { FC, useCallback } from 'react';

import { makeStyles } from '~/@components/@theme';
import { IDetailedLandfill } from '~/@store/landfills';

import { useDumpLoadFormikContext } from '../DumpLoadEditFormik';
import { DumpLoadSuggestedDestination } from './DumpLoadSuggestedDestination';
import { DumpLoadEditLandfillDestination } from './LandfillDestination';
import { DumpLoadEditSubareaDestination } from './SubareaDestination';

export const DumpLoadEditDestination: FC = () => {
    const formik = useDumpLoadFormikContext();

    const { values, setValues } = formik;

    const { root } = useStyles();

    const onCreateLandfill = useCallback(
        (landfill: IDetailedLandfill) => {
            if (values.matchWithPinpointer) return;

            setValues({ ...values, destinationLandfillId: landfill.id });
        },
        [values, setValues]
    );

    return (
        <div className={root}>
            <DumpLoadSuggestedDestination onCreateLandfill={onCreateLandfill} />
            {values.matchWithPinpointer ? <DumpLoadEditSubareaDestination /> : <DumpLoadEditLandfillDestination />}
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
});
