import { action, observable } from 'mobx';

import browserHistory from '~/browserHistory';

class GlobalSearchStore {
    constructor() {
        browserHistory.listen(() => this.close());
    }

    @observable
    search: string = '';

    @action
    setSearch(value: string) {
        this.search = value;
    }

    @action
    clear() {
        this.search = '';
    }

    @observable
    isOpen: boolean = false;

    @action
    open() {
        this.isOpen = true;
    }

    @action
    close() {
        this.isOpen = false;
        this.search = '';
    }
}

export const globalSearchStore = new GlobalSearchStore();
