import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import {
    AdminDespatchAdviceSettingsQuery as IAdminDespatchAdviceSettingsQuery,
    AdminDespatchAdviceSettingsQuery_despatchAdviceSettingsForDumpLoad,
    AdminDespatchAdviceSettingsQueryVariables,
} from '~/graphql';

import { AdminDespatchAdviceSettingsQuery } from '../dumpLoads.queries';

export type IDespatchAdviceSettings = AdminDespatchAdviceSettingsQuery_despatchAdviceSettingsForDumpLoad;

export const useDespatchAdviceSettings = (
    orderNumber: string
): QueryResult<IAdminDespatchAdviceSettingsQuery, AdminDespatchAdviceSettingsQueryVariables> => {
    const variables: AdminDespatchAdviceSettingsQueryVariables = { orderNumber };

    return useQuery<IAdminDespatchAdviceSettingsQuery, AdminDespatchAdviceSettingsQueryVariables>(
        AdminDespatchAdviceSettingsQuery,
        {
            fetchPolicy: 'network-only',
            variables,
        }
    );
};
