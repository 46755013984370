import { formatNumber } from '@common/utils/formaters';
import React from 'react';

import { commonPrecisions } from '~/config/enum';

import { NullableFormatter } from './NullableFormatter';

type Props = {
    children: number | null | undefined;
};

export const PercentFormatter = (props: Props) => (
    <NullableFormatter>
        {props.children ? formatNumber(props.children, commonPrecisions.percent, false, '%') : null}
    </NullableFormatter>
);
