import { TransferType } from '@common/enums';
import { isNullOrUndefined } from '@common/validations/types.validation';

import {
    FormNames,
    IFormValues,
} from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm/LandfillBatchFormik.types';
import { BatchTransferInput } from '~/graphql';

export enum FieldType {
    MANDATORY = 'MANDATORY',
    OPTIONAL = 'OPTIONAL',
    DISABLED = 'DISABLED',
}

export const getFieldTypeForType = (type: TransferType, fieldName: FormNames) => {
    if ([FormNames.weight, FormNames.date].includes(fieldName)) return FieldType.MANDATORY;

    if (fieldName === FormNames.comment) return FieldType.OPTIONAL;

    if (type === TransferType.INTERNAL) {
        if (
            [FormNames.externalProjectName, FormNames.externalProjectAddress, FormNames.licensePlate].includes(
                fieldName
            )
        )
            return FieldType.DISABLED;

        if ([FormNames.toBatch, FormNames.fromBatch].includes(fieldName)) return FieldType.MANDATORY;
    }

    if (type === TransferType.INCOMING || type === TransferType.OUTGOING) {
        if (
            [FormNames.externalProjectName, FormNames.externalProjectAddress, FormNames.licensePlate].includes(
                fieldName
            )
        )
            return FieldType.MANDATORY;

        if (type === TransferType.INCOMING) {
            if (fieldName === FormNames.toBatch) return FieldType.MANDATORY;
            if (fieldName === FormNames.fromBatch) return FieldType.DISABLED;
        }

        if (type === TransferType.OUTGOING) {
            if (fieldName === FormNames.fromBatch) return FieldType.MANDATORY;
            if (fieldName === FormNames.toBatch) return FieldType.DISABLED;
        }
    }
};

export const canSkipValidation = (type: TransferType, fieldName: FormNames, value: unknown) => {
    const fieldType = getFieldTypeForType(type, fieldName);

    return fieldType === FieldType.DISABLED || (fieldType === FieldType.OPTIONAL && isNullOrUndefined(value));
};

export const getBatchTransferInput = (values: IFormValues): BatchTransferInput => {
    const type = values.type;
    const result: BatchTransferInput = { type };
    if (getFieldTypeForType(type, FormNames.weight) !== FieldType.DISABLED) result.weight = values.weight;
    if (getFieldTypeForType(type, FormNames.date) !== FieldType.DISABLED) result.date = values.date.toISOString();
    if (getFieldTypeForType(type, FormNames.comment) !== FieldType.DISABLED) result.comment = values.comment;
    if (getFieldTypeForType(type, FormNames.fromBatch) !== FieldType.DISABLED)
        result.fromBatchId = values.fromBatch?.id || '';
    if (getFieldTypeForType(type, FormNames.toBatch) !== FieldType.DISABLED)
        result.toBatchId = values.toBatch?.id || '';
    if (
        getFieldTypeForType(type, FormNames.externalProjectName) !== FieldType.DISABLED &&
        getFieldTypeForType(type, FormNames.externalProjectAddress) !== FieldType.DISABLED
    )
        result.externalProject = {
            name: values.externalProjectName || '',
            address: values.externalProjectAddress || '',
        };
    if (getFieldTypeForType(type, FormNames.licensePlate) !== FieldType.DISABLED)
        result.licensePlate = values.licensePlate;

    return result;
};
