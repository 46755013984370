import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ChevronDownIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 6.00125H20L12.0422 18.0013L4 6.00125Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
