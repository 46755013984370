import './ButtonGroup.scss';

import React from 'react';

import { getBem } from '~/@sochi-components/@bem';

type ButtonGroupType = {
    className?: string;
    children: React.ReactNode;
};

export class ButtonGroup extends React.PureComponent<ButtonGroupType> {
    bem = getBem(this);

    render() {
        return <div className={this.bem.className}>{this.props.children}</div>;
    }
}
