import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import { SortSection, StringFilter, TableHeadCell } from '~/@components/Table/TableHeadCell';
import { UserRelationField, UserRelationsStore } from '~/@store/users';
import { SortInput } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    title: React.ReactNode;
    fieldName: UserRelationField;
    withFilter?: boolean;
    withSort?: boolean;
    store: UserRelationsStore;
};

export const UserRelationHeadCell = ({ title, fieldName, withFilter, withSort, store }: Props) => {
    const sortValue = useObservable(() => store.sort.value);
    const setSortValue = useCallback((v: SortInput) => store.sort.setValue(v), [store.sort]);
    const sortLabels = i18n.SortString;

    const sortSection = useMemo(() => {
        if (!withSort) return null;

        return <SortSection fieldName={fieldName} sort={sortValue} setSort={setSortValue} labels={sortLabels} />;
    }, [withSort, fieldName, sortValue, setSortValue, sortLabels]);

    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        switch (fieldName) {
            case UserRelationField.name:
                return (
                    <StringFilter
                        valueGetter={() => store.filter.values[fieldName]}
                        setValue={v => store.filter.setValue(fieldName, v)}
                    />
                );
            default:
                return null;
        }
    }, [withFilter, fieldName, store.filter]);

    return (
        <TableHeadCell title={title} sortDirection={sortValue?.field === fieldName ? sortValue.direction : null}>
            {sortSection}
            {filterSection}
        </TableHeadCell>
    );
};
