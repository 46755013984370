import { Checkbox, FormControl, makeStyles, MenuItem, Select as MuiSelect } from '@material-ui/core';
import { SelectProps as MuiSelectProps } from '@material-ui/core/Select/Select';
import cn from 'classnames';
import React from 'react';

import { COLORS } from '~/@components/@theme';
import i18n from '~/i18n';

type SelectProps<TValue> = {
    items: TValue[];
    selected: TValue[];
    onSelect: (values: TValue[]) => void;
    keyGetter: (v: TValue) => string;
    renderItem: (v: TValue) => React.ReactNode;
} & Omit<MuiSelectProps, 'onSelect'>;

export const MultiSelect = <TValue extends unknown>({
    items,
    selected,
    onSelect,
    placeholder,
    keyGetter,
    renderItem,
    ...rest
}: SelectProps<TValue>) => {
    const { root, caption, defaultCaption, listItem } = useStyles();

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const {
            target: { value },
        } = event;

        if (Array.isArray(value)) {
            const itemIds = value as string[];

            onSelect(items.filter(i => itemIds.includes(keyGetter(i))));
        } else {
            onSelect([]);
        }
    };

    const renderValue = (selected: unknown) => {
        if (!Array.isArray(selected) || !selected.length)
            return <div className={cn(caption, defaultCaption)}>{placeholder}</div>;

        return <div className={caption}>{`${selected.length} ${i18n.MultiSelect.selected}`}</div>;
    };

    return (
        <FormControl className={root}>
            <MuiSelect
                onChange={handleChange}
                {...rest}
                renderValue={renderValue}
                value={selected.map(keyGetter)}
                displayEmpty
                multiple>
                {items.map(item => (
                    <MenuItem key={keyGetter(item)} value={keyGetter(item)}>
                        <div className={listItem}>
                            <Checkbox checked={selected.some(i => keyGetter(i) === keyGetter(item))} />
                            {renderItem(item)}
                        </div>
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        minWidth: '200px',
    },
    caption: {
        marginRight: '8px',
    },
    defaultCaption: {
        color: COLORS.grayDarker,
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        gap: '6px',
        width: '100%',
    },
}));
