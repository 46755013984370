import React, { memo, VFC } from 'react';

import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { landfillDetailRoute } from '~/@store/routes';

type Props = {
    landfill: { id: string; name: string };
};

export const UserLandfillCell: VFC<Props> = memo(({ landfill }) => {
    const actions: ITableCellAction[] = [
        {
            variant: ActionVariant.LINK,
            to: landfillDetailRoute(landfill.id),
        },
    ];

    return <TableCellActions actions={actions}>{landfill.name}</TableCellActions>;
});
