import { makeStyles } from '@material-ui/core';
import React from 'react';

import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Text } from '~/@components/Text';
import {
    DestinationCountOptions,
    getInitialDestinationSort,
    IDestination,
    IDestinationResult,
    useDestinationResults,
} from '~/@store/dumpLoads';
import i18n from '~/i18n';

import { useDumpLoadFormikContext } from '../../DumpLoadEditFormik';
import { DumpLoadDestinationControls } from './DumpLoadDestinationControls';
import { DumpLoadDestinationSelect } from './DumpLoadDestinationSelect';

export const DumpLoadEditSubareaDestination = () => {
    const { root } = useStyles();

    const formik = useDumpLoadFormikContext();

    const [sort, setSort] = React.useState(getInitialDestinationSort());

    const [count, setCount] = React.useState<number | null>(DestinationCountOptions.fifteen);

    const destinationsQuery = useDestinationResults(formik.values.dumpLoad, formik.values.substances);

    const handleChangeDestination = (destination: IDestination) => {
        formik.setValues({ ...formik.values, destination });
    };

    const selectedSubareaId = formik.values.destination?.subarea?.id || null;

    return (
        <QueryStateHandler
            query={destinationsQuery}
            dataGetter={query =>
                query.data?.calculateDestinationResult || query.previousData?.calculateDestinationResult || undefined
            }>
            {(destinationResult: IDestinationResult) => (
                <div className={root}>
                    {destinationResult.infoCode && (
                        <Text variant="BodyBold" color="error">
                            {i18n.AdminDestinationResultInfoCodes[destinationResult.infoCode]}
                        </Text>
                    )}
                    {destinationResult.destinations.length > 0 && (
                        <>
                            <DumpLoadDestinationControls
                                sort={sort}
                                setSort={setSort}
                                count={count}
                                setCount={setCount}
                            />
                            <DumpLoadDestinationSelect
                                selectedSubareaId={selectedSubareaId}
                                destinations={destinationResult.destinations}
                                onDestinationSelect={handleChangeDestination}
                                sort={sort}
                                cropCount={count}
                            />
                        </>
                    )}
                </div>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
}));
