import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { ReactNode, useCallback, useEffect, useRef } from 'react';

import { tableWidthStore } from '~/@store/common/tableWidthStore';

import { Resizer } from './Resizer';

interface Props {
    tableName: string;
    columnName: string;
    children: ReactNode;
    className?: string;
    minWidth?: number;
}

export const ResizableTableHeadCell = observer(({ tableName, columnName, children, className, minWidth }: Props) => {
    const { root } = useStyles();
    const cellRef = useRef<HTMLTableCellElement>(null);

    const initialWidth = useRef(0);

    useEffect(() => {
        // To stabilize column widths when changing sorting and filters https://redmine.pinpointer.se/issues/4992
        tableWidthStore.setWidth(tableName, columnName, cellRef.current!.clientWidth);
    }, [tableName, columnName]);

    const onDragStart = useCallback(() => {
        initialWidth.current = cellRef.current!.clientWidth;
    }, []);

    const onDrag = useCallback(
        (delta: number) => {
            tableWidthStore.setWidth(tableName, columnName, initialWidth.current + delta);
        },
        [tableName, columnName]
    );

    const onDragEnd = useCallback(() => {
        tableWidthStore.save();
    }, []);

    let width = tableWidthStore.widths[tableWidthStore.getKey(tableName, columnName)];

    if (width && minWidth && width < minWidth) width = minWidth;

    const style = width ? { minWidth: width, maxWidth: width } : {};

    return (
        <TableCell className={cn(root, className)} style={style} ref={cellRef}>
            {children}
            <Resizer onDragStart={onDragStart} onDrag={onDrag} onDragEnd={onDragEnd} />
        </TableCell>
    );
});

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
    },
}));
