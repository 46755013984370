import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { DumpTypeByIdQuery, DumpTypeByIdQueryVariables } from '~/graphql';

import * as queries from '../dumpType.queries';

export const useDumpTypeById = (dumpTypeId: string): QueryResult<DumpTypeByIdQuery, DumpTypeByIdQueryVariables> => {
    return useQuery<DumpTypeByIdQuery, DumpTypeByIdQueryVariables>(queries.DumpTypeByIdQuery, {
        variables: {
            id: dumpTypeId,
        },
    });
};
