import 'core-js';
import './index.scss';

import { ApolloProvider } from '@apollo/client/react';
import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';

import client from './apolloClient';
import App from './App';
import DialogStore from './stores/dialogStore';
import MessageStore from './stores/messageStore';
import { generateVersionInfo } from './utils/index';

const stores = { DialogStore, MessageStore };

const root = document.getElementById('root');

generateVersionInfo();

ReactDOM.render(
    <ApolloProvider client={client}>
        <Provider {...stores}>
            <App />
        </Provider>
    </ApolloProvider>,
    root
);
