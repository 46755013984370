import { IconButton, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import {
    FormikTypeUnsafeDateField,
    FormikTypeUnsafeNumberField,
    FormikTypeUnsafeTextField,
} from '~/@components/FormikFields';
import { FormikTypeUnsafeBooleanField } from '~/@components/FormikFields/FormikTypeUnsafeBooleanField';
import { LocalizableText } from '~/@components/LocalizableText';
import { ColumnAlign, ITableColumn } from '~/@components/Table';
import { UserRoundFilledIcon } from '~/@sochi-components/Icons';
import { DeliveryLineField } from '~/@store/deliveryLines';
import { BeastCell } from '~/@views/AdminView/common/BeastCell';
import { DeliveryLineHistoryCell } from '~/@views/AdminView/DeliveryLinesPage/DeliveryLineCells';
import { getDeliveryLineFormikName } from '~/@views/AdminView/DeliveryLinesPage/DeliveryLineEditForm/DeliveryLineFormikCommon';
import { commonPrecisions, DespatchAdviceSource } from '~/config/enum';
import { deliveryLineStatusToBoolean } from '~/utils/deliveryLine';

import { DeliveryLineHeadCell } from '../../DeliveryLinesPage/DeliveryLineHeadCell';
import { DeliveryLinesMassEditDeleteRestoreCell, DeliveryLinesMassEditReceiptCell } from './DeliveryLinesMassEditCells';
import { IDeliveryLineValues } from './DeliveryLinesMassEditFormik';
import { showDeliveryLineUserComments } from './DeliveryLinesMassEditUserComments';

export const useDeliveryLinesMassEditColumns = (
    disableActions: boolean = false
): ITableColumn<IDeliveryLineValues>[] => {
    const { inputCell, buttonCell } = useStyles();

    const isDeleted = (row: IDeliveryLineValues) => !!row.line.deletedAt;
    const isVerified = (row: IDeliveryLineValues) => deliveryLineStatusToBoolean(row.line.status);
    const isVerifiedOrDeleted = (row: IDeliveryLineValues) => isDeleted(row) || isVerified(row);

    const hasUserComment = (row: IDeliveryLineValues) => !!row.line.senderComment || !!row.line.receiverComment;
    const showUserComments = (row: IDeliveryLineValues) => showDeliveryLineUserComments(row.line);

    return [
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.number'} />}
                    fieldName={DeliveryLineField.deliveryLineIdx}
                    withSort
                    disabled={disableActions}
                />
            ),
            render: row => row.line.deliveryLineIdx + 1,
            name: 'deliveryLineIdx',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.weight'} />}
                    fieldName={DeliveryLineField.weight}
                    withFilter
                    withSort
                    disabled={disableActions}
                />
            ),
            cellClassName: inputCell,
            align: ColumnAlign.RIGHT,
            render: (row, ind) => (
                <FormikTypeUnsafeNumberField
                    name={getDeliveryLineFormikName(ind, 'weight')}
                    required
                    fullWidth
                    precision={commonPrecisions.amount}
                    disabled={isVerifiedOrDeleted(row)}
                />
            ),
            name: 'weight',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.date'} />}
                    fieldName={DeliveryLineField.plannedStartDate}
                    withFilter
                    withSort
                    disabled={disableActions}
                />
            ),
            cellClassName: inputCell,
            render: (row, ind) => (
                <FormikTypeUnsafeDateField
                    name={getDeliveryLineFormikName(ind, 'date')}
                    required
                    fullWidth
                    disabled={isVerifiedOrDeleted(row)}
                />
            ),
            name: 'date',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.truckRegistrationNumber'} />}
                    fieldName={DeliveryLineField.truckRegistrationNumber}
                    withFilter
                    withSort
                    disabled={disableActions}
                />
            ),
            cellClassName: inputCell,
            render: (row, ind) => (
                <FormikTypeUnsafeTextField
                    name={getDeliveryLineFormikName(ind, 'truckRegistrationNumber')}
                    required
                    fullWidth
                    disabled={isVerifiedOrDeleted(row)}
                />
            ),
            name: 'truckRegistrationNumber',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.customerInvoice'} />}
                    fieldName={DeliveryLineField.customerInvoiceNumber}
                    withFilter
                    withSort
                    disabled={disableActions}
                />
            ),
            cellClassName: inputCell,
            render: (row, ind) => (
                <FormikTypeUnsafeTextField
                    name={getDeliveryLineFormikName(ind, 'customerInvoice')}
                    fullWidth
                    disabled={isDeleted(row)}
                />
            ),
            name: 'customerInvoice',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.transportInvoice'} />}
                    fieldName={DeliveryLineField.transportationInvoiceNumber}
                    withFilter
                    withSort
                    disabled={disableActions}
                />
            ),
            cellClassName: inputCell,
            render: (row, ind) => (
                <FormikTypeUnsafeTextField
                    name={getDeliveryLineFormikName(ind, 'transportInvoice')}
                    fullWidth
                    disabled={isDeleted(row)}
                />
            ),
            name: 'transportInvoice',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.landfillInvoice'} />}
                    fieldName={DeliveryLineField.landfillInvoiceNumber}
                    withFilter
                    withSort
                    disabled={disableActions}
                />
            ),
            cellClassName: inputCell,
            render: (row, ind) => (
                <FormikTypeUnsafeTextField
                    name={getDeliveryLineFormikName(ind, 'landfillInvoice')}
                    fullWidth
                    disabled={isDeleted(row)}
                />
            ),
            name: 'landfillInvoice',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.comment'} />}
                    fieldName={DeliveryLineField.comment}
                    withFilter
                    withSort
                    disabled={disableActions}
                />
            ),
            cellClassName: inputCell,
            render: (row, ind) => (
                <FormikTypeUnsafeTextField
                    name={getDeliveryLineFormikName(ind, 'comment')}
                    fullWidth
                    disabled={isDeleted(row)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => showUserComments(row)} disabled={!hasUserComment(row)}>
                                    <UserRoundFilledIcon color={hasUserComment(row) ? 'primary' : 'disabled'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            ),
            name: 'comment',
        },
        {
            title: (
                <DeliveryLineHeadCell
                    title={<LocalizableText code={'DeliveryLinesTable.verified'} />}
                    fieldName={DeliveryLineField.verified}
                    withFilter
                />
            ),
            render: (row, ind) => (
                <FormikTypeUnsafeBooleanField
                    name={getDeliveryLineFormikName(ind, 'verified')}
                    disabled={isDeleted(row)}
                />
            ),
            name: 'verified',
        },
        {
            title: <LocalizableText code={'DeliveryLinesTable.receipt'} />,
            render: (row, ind) => (
                <DeliveryLinesMassEditReceiptCell index={ind} canView={!isDeleted(row)} canEdit={!isVerified(row)} />
            ),
            name: 'receipt',
            cellClassName: inputCell,
        },
        {
            title: <LocalizableText code={'DeliveryLinesTable.history'} />,
            render: row => <DeliveryLineHistoryCell deliveryLine={row.line} disabled={isDeleted(row)} />,
            name: 'history',
            cellClassName: buttonCell,
        },
        {
            title: <LocalizableText code={'DeliveryLinesTable.beastStatus'} />,
            render: row => <BeastCell entity={row.line} source={DespatchAdviceSource.DELIVERY_LINE} />,
            name: 'beast',
            cellClassName: buttonCell,
        },
        {
            title: '',
            render: row => (
                <DeliveryLinesMassEditDeleteRestoreCell deliveryLine={row.line} disabled={isVerified(row)} />
            ),
            name: 'delete',
            cellClassName: buttonCell,
        },
    ];
};

const useStyles = makeStyles(() => ({
    inputCell: {
        padding: '0',
    },
    buttonCell: {
        minWidth: 'max-content',
    },
}));
