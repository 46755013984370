export * from '../../../common/constants';

export const ChartColumnsNumber = {
    desktop: 9,
    tablet: 12,
    mobile: 14,
};
export const MaxResolutions = {
    desktop: Infinity,
    tablet: 1239,
    mobile: 768,
};

/** @description Nginx limits on request size - 20MB. So, we assume that 19MB reserved for files and 1MB is enough for rest gql data */
export const MAX_PAYLOAD_SIZE = 19 * 1024 * 1024;

export const ACCEPTED_DOCUMENT_FILE_TYPES = 'image/*, .pdf';
