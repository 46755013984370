import { ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';

import { DumpTypeSelectQuery } from '../DumpTypeSelectQuery';

type DumpTypeKind = { id: string; name: string };

type Props = {
    valueGetter: () => DumpTypeKind | null | undefined;
    setValue: (v: DumpTypeKind | null) => void;
};

export const DumpTypeFilter = ({ valueGetter, setValue }: Props) => {
    const { selected } = useStyles();
    const value = useObservable(valueGetter);

    return (
        <DumpTypeSelectQuery>
            {dumpTypes => (
                <>
                    <ListItem button divider onClick={() => setValue(null)}>
                        ---
                    </ListItem>
                    {dumpTypes.map(d => (
                        <ListItem
                            key={d.id}
                            button
                            divider
                            onClick={() => setValue(d)}
                            className={value?.id === d.id ? selected : undefined}>
                            <ListItemText>{d.name}</ListItemText>
                        </ListItem>
                    ))}
                </>
            )}
        </DumpTypeSelectQuery>
    );
};

const useStyles = makeStyles(theme => ({
    selected: {
        backgroundColor: theme.palette.background.default,
    },
}));
