import React from 'react';

import { HistoryIcon } from '~/@components/Icon';
import { CellButton } from '~/@components/Table/TableCellActions/CellButton';
import { IDeliveryLine } from '~/@store/deliveryLines';

import { showDeliveryLineHistoryDialog } from '../DeliveryLineHistoryForm';

type Props = {
    deliveryLine: IDeliveryLine;
    disabled?: boolean;
};

export const DeliveryLineHistoryCell = ({ deliveryLine, disabled = false }: Props) => {
    return (
        <CellButton onClick={() => showDeliveryLineHistoryDialog(deliveryLine)} disabled={disabled}>
            <HistoryIcon />
        </CellButton>
    );
};
