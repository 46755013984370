import React, { useMemo } from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { CustomTabs, ICustomTabConfig } from '~/@components/CustomTabs';
import { LocalizableText } from '~/@components/LocalizableText';
import { IDetailedLandfill, useLandfillUpdateMutation } from '~/@store/landfills';
import { LandfillSubareas } from '~/@views/AdminView/LandfillDetailPage/LandfillSubareas';
import { showSubareaCreateDialog } from '~/@views/AdminView/SubareasPage/SubareaEditForm';
import i18n from '~/i18n';

import { LandfillEditForm } from '../LandfillsPage/LandfillEditForm';
import { LandfillDocuments } from './LandfillDocuments';

type Props = {
    landfill: IDetailedLandfill;
};

export const LandfillTabs = ({ landfill }: Props) => {
    const [update] = useLandfillUpdateMutation(landfill);

    const tabs: Array<ICustomTabConfig> = useMemo(
        () => [
            {
                label: <LocalizableText code={'editLandfill'} />,
                render: () => <LandfillEditForm landfill={landfill} submit={update} />,
            },
            {
                label: <LocalizableText code={'subareas'} />,
                render: () => <LandfillSubareas landfill={landfill} />,
                fullWidth: true,
                rightActionButton: () => (
                    <ActionButton onClick={() => showSubareaCreateDialog(landfill)}>
                        <LocalizableText code={'LandfillPage.addSubarea'} />
                    </ActionButton>
                ),
            },
            {
                label: <LocalizableText code={'documentations'} />,
                render: () => <LandfillDocuments landfill={landfill} />,
                subTitle: <LocalizableText code={'LandfillPage.documents'} />,
            },
        ],
        [landfill, update]
    );

    return <CustomTabs title={`${i18n.landfill}: ${landfill.name}`} tabs={tabs} />;
};
