import './LandfillInfo.scss';

import { observer } from 'mobx-react';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import { SochiDivider } from '~/@sochi-components/SochiDivider';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { LandfillMapInput } from '~/@views/UserView/LandfillPage/common/LandfillFormParts/LandfillMapInput';
import { IDeviceMediaContext, withDeviceMedia } from '~/contexts';

import {
    FormNames,
    LandfillEditFormCommonFields,
    LandfillEditFormResetButton,
    LandfillEditFormSubmitButton,
    LandfillEditFormText,
    LandfillFilesInput,
    LandfillFormWithContext,
    LandfillWorkTime,
} from '../common/LandfillEditForm';
import type { LandfillDetailsDataProps } from '../LandfillPage';

@observer
class LandfillInfo extends React.Component<LandfillDetailsDataProps & IDeviceMediaContext> {
    bem = getBem(this);

    render() {
        const { className, element } = this.bem;
        const { isMobile } = this.props;

        return (
            <div className={className}>
                <LandfillFormWithContext landfill={this.props.landfill}>
                    <SochiTitle title={<LocalizableText code={'LandfillForm.updateLandfill'} />}>
                        <div className={element('button-group')}>
                            <LandfillEditFormResetButton />
                            <LandfillEditFormSubmitButton />
                        </div>
                    </SochiTitle>
                    <SochiDivider />
                    <div className={element('container')}>
                        <LandfillEditFormCommonFields className={element('left-sidebar')} />
                        <LandfillMapInput className={element('map')} />
                        <LandfillEditFormText
                            className={element('comments')}
                            name={FormNames.comment}
                            label={<LocalizableText code={'LandfillForm.comment'} />}
                            rows={isMobile ? 1 : 5}
                        />
                        <LandfillEditFormText
                            className={element('description')}
                            name={FormNames.description}
                            label={<LocalizableText code={'LandfillForm.description'} />}
                            rows={isMobile ? 1 : 5}
                        />
                        <LandfillWorkTime className={element('opening-hours')} />
                        <LandfillFilesInput className={element('permit-files')} />
                    </div>
                </LandfillFormWithContext>
            </div>
        );
    }
}

export default withDeviceMedia(LandfillInfo);
