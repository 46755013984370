import React from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { IDumpLoadSearchRecord } from '~/@store/dumpLoads';

import { showDeliveryLineCreateDialog } from '../DeliveryLineEditForm';

type Props = {
    dumpLoad?: IDumpLoadSearchRecord;
    disabled?: boolean;
};

export const DeliveryLineAddButton = ({ dumpLoad, disabled }: Props) => {
    const createDeliveryLine = () => showDeliveryLineCreateDialog(dumpLoad || null);

    return (
        <ActionButton onClick={createDeliveryLine} disabled={disabled}>
            <LocalizableText code={'DeliveryLinesTable.addButton'} />
        </ActionButton>
    );
};
