import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const RouteIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 25 25" fill="none" {...props}>
            <g clipPath="url(#clip0_1468_11176)">
                <path
                    d="M9.78244 11.1613L8.36244 12.5813C7.68244 11.8913 7.02244 11.0013 6.57244 9.64125L8.51244 9.15125C8.83244 10.0413 9.28244 10.6513 9.78244 11.1613ZM11.0024 6.00125L7.00244 2.00125L3.00244 6.00125H6.02244C6.04244 6.81125 6.10244 7.54125 6.21244 8.17125L8.15244 7.68125C8.08244 7.20125 8.03244 6.63125 8.02244 6.00125H11.0024ZM21.0024 6.00125L17.0024 2.00125L13.0024 6.00125H15.9924C15.8924 9.68125 14.7124 10.7513 13.4524 11.8813C12.9524 12.3213 12.4424 12.8013 12.0024 13.4313C11.6624 12.9413 11.2724 12.5513 10.8724 12.1913L9.46244 13.6013C10.3924 14.4513 11.0024 15.1413 11.0024 17.0013V22.0013H13.0024V17.0013C13.0024 14.9813 13.7124 14.3413 14.7924 13.3713C16.1724 12.1313 17.8724 10.5913 17.9924 6.00125H21.0024Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_1468_11176">
                    <rect width="24" height="24" fill="white" transform="translate(0.00244141 0.00125122)" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
