import { FormLabel, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import { AutocompleteAsync } from '~/@components/Autocomplete';
import { Divider } from '~/@components/Divider';
import i18n from '~/i18n';

import { DeleteIcon } from '../Icon';

const noWrapAndEllipsisProperty = { noWrap: true };

type Props<TValue> = {
    onChange: (v: TValue[]) => void;
    selected: TValue[];
    renderValue?: (v: TValue) => ReactNode;
    renderValueSecondary?: (v: TValue) => ReactNode;
    renderOption: (v: TValue) => string;
    renderOptionSecondary?: (v: TValue) => string;
    loadOptions: (search: string) => Promise<Array<TValue>>;
    label?: React.ReactNode;
    addLabel?: React.ReactNode;
    disabled?: boolean;
    showDivider?: boolean;
};

export function SelectList<TValue>({
    onChange,
    selected,
    loadOptions,
    renderValue,
    renderValueSecondary,
    renderOption,
    renderOptionSecondary,
    label,
    disabled,
    addLabel,
    showDivider,
}: Props<TValue>) {
    const listRef = React.useRef<HTMLUListElement>(null);

    const handleAdd = (v: TValue | null) => {
        if (!v) return;
        onChange([...selected, v]);
        setTimeout(() => listRef.current?.scrollTo(0, listRef.current.scrollHeight), 0);
    };

    const handleRemove = (v: TValue) => {
        onChange(selected.filter(item => item !== v));
    };

    const { list, listItem, labelEmptyList, labelCommon, divider } = useStyles();

    return (
        <div>
            {label && (
                <FormLabel className={cn({ labelCommon, [labelEmptyList]: !selected.length })}>{label}</FormLabel>
            )}
            {!!selected.length && (
                <>
                    <List className={list} dense ref={listRef}>
                        {selected.map(item => (
                            <ListItem ContainerProps={{ className: listItem }} key={JSON.stringify(item)}>
                                <ListItemText
                                    primary={renderValue ? renderValue(item) : renderOption(item)}
                                    secondary={
                                        renderValueSecondary
                                            ? renderValueSecondary(item)
                                            : renderOptionSecondary
                                            ? renderOptionSecondary(item)
                                            : null
                                    }
                                    primaryTypographyProps={noWrapAndEllipsisProperty}
                                    secondaryTypographyProps={noWrapAndEllipsisProperty}
                                />
                                {!disabled && (
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => handleRemove(item)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        ))}
                    </List>
                    {showDivider && <Divider className={divider} />}
                </>
            )}
            <AutocompleteAsync
                selectedOption={null}
                onOptionSelect={handleAdd}
                renderOption={renderOption}
                renderOptionSecondary={renderOptionSecondary}
                loadOptions={loadOptions}
                label={addLabel || i18n.search}
                disabled={disabled}
            />
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    list: {
        maxHeight: 210,
        paddingTop: 0,
        paddingBottom: 16,
        overflowY: 'auto',
        mask: 'linear-gradient(to bottom, black calc(100% - 16px), transparent)',
    },
    listItem: {
        '&:has(.MuiIconButton-root:hover)': {
            color: theme.palette.text.secondary,
            '& a': {
                color: theme.palette.text.secondary,
            },
        },
    },
    labelCommon: {
        marginBottom: theme.spacing(1),
    },
    labelEmptyList: {
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginBottom: theme.spacing(3),
    },
}));
