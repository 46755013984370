import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const CancelIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path d="M19.884 5.884a1.25 1.25 0 0 0-1.768-1.768L12 10.232 5.884 4.116a1.25 1.25 0 1 0-1.768 1.768L10.232 12l-6.116 6.116a1.25 1.25 0 0 0 1.768 1.768L12 13.768l6.116 6.116a1.25 1.25 0 0 0 1.768-1.768L13.768 12l6.116-6.116Z" />
        </SvgIcon>
    );
};
