import React, { useEffect } from 'react';

import { useSearchQuery } from '~/@components/@hooks';
import { Divider } from '~/@components/Divider';
import { IDetailedDumpLoad } from '~/@store/dumpLoads';
import { IDetailedProject } from '~/@store/projects';
import { ProjectShortInfo } from '~/@views/AdminView/common/ProjectShortInfo';
import { DumpLoadEditForm } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm';
import { DumpLoadSelect } from '~/@views/AdminView/ProjectDetailPage/ProjectDetailDumpLoads/DumpLoadSelect';

type Props = {
    project: IDetailedProject;
};

export const ProjectDetailDumpLoads = ({ project }: Props) => {
    const [selectedId, setSelectedId] = React.useState<string | null>(null);

    const { dumpLoadId } = useSearchQuery();

    useEffect(() => {
        const initialId = dumpLoadId || project.dumpLoads[0]?.id || null;

        setSelectedId(initialId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.dumpLoads.length, dumpLoadId]);

    const selectedDumpLoad: IDetailedDumpLoad | null = React.useMemo(() => {
        const dumpLoad = project.dumpLoads.find(d => d.id === selectedId);

        return dumpLoad ? { ...dumpLoad, project } : null;
    }, [selectedId, project]);

    return (
        <>
            <ProjectShortInfo project={project} />
            <Divider marginBottom marginTop />
            <DumpLoadSelect dumpLoads={project.dumpLoads} selectedId={selectedId} onSelect={setSelectedId} />
            {selectedDumpLoad && (
                <>
                    <Divider marginBottom marginTop />
                    <DumpLoadEditForm key={selectedDumpLoad.id} dumpLoad={selectedDumpLoad} />
                </>
            )}
        </>
    );
};
