import { SubstanceGroupBy } from '@common/enums';
import { FormikContextType } from 'formik';
import React from 'react';

import { FormikSelectField } from '~/@components/FormikFields';
import i18n from '~/i18n';

import { ISubstanceFormValues } from './SubstanceEditFormik';

type Props = {
    formik: FormikContextType<ISubstanceFormValues>;
    disabled?: boolean;
};

export const SubstanceGroupBySelect = ({ formik, disabled }: Props) => {
    return (
        <FormikSelectField
            addEmptyOption={false}
            label={i18n.groupBy}
            name="groupBy"
            formik={formik}
            items={Object.values(SubstanceGroupBy)}
            renderValue={v => v}
            keyGetter={v => v}
            disabled={disabled}
        />
    );
};
