import './SideBarTitle.scss';

import React from 'react';

import { getBem } from '~/@sochi-components/@bem';

export class SideBarTitle extends React.PureComponent<{ title: React.ReactNode; subtitle?: React.ReactNode }> {
    bem = getBem(this);
    render() {
        const { element, className } = this.bem;
        const { title, subtitle } = this.props;

        return (
            <div className={className}>
                <div className={element('title')}>{title}</div>
                {subtitle ? <div className={element('subtitle')}>{subtitle}</div> : null}
            </div>
        );
    }
}
