import { withStyles } from '@material-ui/core';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import React from 'react';

export const DialogTitleIconButton = withStyles(theme => ({
    root: {
        color: `${theme.palette.background.paper} !important`,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
}))((props: IconButtonProps) => <IconButton {...props} />);
