import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { LockMutation, LockMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from './locks.queries';
import { ILock, LockEntityType } from './locks.types';

type LockFunc = () => Promise<ILock>;

export const useLockFunc = (entityType: LockEntityType, entityId: string): LockFunc => {
    const client = useApolloClient();

    return useCallback(
        () =>
            client
                .mutate<LockMutation, LockMutationVariables>({
                    mutation: queries.LockMutation,
                    variables: {
                        input: {
                            entityType,
                            entityId,
                        },
                    },
                })
                .catch(handleApolloError)
                .then(res => res.data?.lock || Promise.reject(new Error('Empty response "LockMutation"'))),
        [client, entityType, entityId]
    );
};
