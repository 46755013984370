import { observer } from 'mobx-react';
import React from 'react';

import { NumberField } from '~/@components/NumberField';
import { ContaminationTypeStore, isSubstanceParentWithChildren } from '~/@store/substances';
import { ISubstance, ISubstanceChild } from '~/@store/toxicLimits';
import { commonPrecisions } from '~/config/enum';

type Props = {
    store: ContaminationTypeStore;
    substance: ISubstance | ISubstanceChild;
};

export const EditSubstanceField = observer(({ store, substance }: Props) => {
    const handleChange = (v: number) => {
        store.changeAmount(substance, v);
    };

    const value = store.substanceAmountsMap[substance.id] || null;

    const hasChildren = isSubstanceParentWithChildren(substance);

    return (
        <NumberField
            precision={commonPrecisions.decimal}
            onChange={handleChange}
            label={substance.name}
            value={value}
            disabled={store.readOnly || hasChildren}
            placeholder=""
        />
    );
});
