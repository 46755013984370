import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const CHECKED_ICON_CHEVRON_CLASSNAME = 'CustomCheckedIconChevron';

export const CheckedIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <rect y="0.00125122" width="24" height="24" fill="currentColor" />
            <path
                className={CHECKED_ICON_CHEVRON_CLASSNAME}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.7071 7.16371C20.0976 7.55424 20.0976 8.1874 19.7071 8.57793L10.5767 17.7084C10.3891 17.8959 10.1348 18.0013 9.86957 18.0013C9.60435 18.0013 9.34999 17.8959 9.16246 17.7084L4.29289 12.8388C3.90237 12.4483 3.90237 11.8151 4.29289 11.4246C4.68342 11.0341 5.31658 11.0341 5.70711 11.4246L9.86957 15.587L18.2929 7.16371C18.6834 6.77319 19.3166 6.77319 19.7071 7.16371Z"
                fill="white"
            />
        </SvgIcon>
    );
};
