import { isNumber } from 'lodash';

import { BatchCheckResult, BatchLogItem } from '../types';

export const validateBatchAmount = (logItems: BatchLogItem[]): BatchCheckResult => {
    logItems.sort((a, b) => +a.date - +b.date);

    let curAmount = 0;
    let date: Date | null = null;
    let result = new Map<string, { sign: number; amount: number }>();

    for (const item of logItems) {
        const sign = item.incoming ? 1 : -1;
        curAmount = curAmount + sign * item.weight;

        if (!date && curAmount < 0) date = item.date;

        result.set(item.id, {
            sign,
            amount: curAmount,
        });
    }

    const lastAmount = Array.from(result.values()).pop()?.amount;

    return {
        amounts: result,
        currentAmount: isNumber(lastAmount) ? lastAmount : 0,
    };
};
