import { Form, Formik } from 'formik';
import React from 'react';

import { FadeProgress } from '~/@components/FadeProgress';
import { IDetailedUser, useUserUpdateMutation } from '~/@store/users';

import { UserEditFormActions } from './UserEditFormActions';
import { UserEditFormFields } from './UserEditFormFields';
import { getEditUserFormikConfig } from './UserEditFormik';

interface Props {
    user: IDetailedUser;
}

export const UserEditForm = ({ user }: Props) => {
    const [updateUser, isUpdatingUser] = useUserUpdateMutation();
    const formik = getEditUserFormikConfig(user, updateUser);

    return (
        <>
            <FadeProgress show={isUpdatingUser} />
            <Formik {...formik}>
                <Form>
                    <UserEditFormFields user={user} />
                    <UserEditFormActions />
                </Form>
            </Formik>
        </>
    );
};
