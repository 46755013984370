import React, { memo, VFC } from 'react';

import { UserStatusFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { showConfirmDialog } from '~/@store/common';
import { IUser, useUserVerifyMutation } from '~/@store/users';
import { useUserAbilities } from '~/contexts';
import { canUpdateUser } from '~/utils/auth';
import { getUserStatus, UserStatus } from '~/utils/user';

const statusesForApproval = new Set<UserStatus>([UserStatus.EmailConfirmed, UserStatus.Registered]);

type UserStatusCellProps = {
    user: IUser;
    disabled?: boolean;
};

export const UserStatusCell: VFC<UserStatusCellProps> = memo(({ user, disabled = false }) => {
    const ability = useUserAbilities();
    const isAbleToUpdate = canUpdateUser(ability, user) && !disabled;

    const [verify] = useUserVerifyMutation();

    const onApprove = () =>
        showConfirmDialog({
            title: <LocalizableText code={'UsersTable.userApproval'} />,
            render: () => <LocalizableText code={'UsersTable.approveConfirmation'} />,
        }).then(result => result && verify({ userId: user.id, verified: true }));

    const onChangeBlocked = () =>
        showConfirmDialog({
            title: <LocalizableText code={user.blocked ? 'UsersTable.userEnablement' : 'UsersTable.userBlocking'} />,
            render: () => (
                <LocalizableText
                    code={user.blocked ? 'UsersTable.enablementConfirmation' : 'UsersTable.blockingConfirmation'}
                />
            ),
        }).then(result => result && verify({ userId: user.id, blocked: !user.blocked }));

    const status = getUserStatus(user);

    const actions: ITableCellAction[] = [];

    if (statusesForApproval.has(status) && user.customer && isAbleToUpdate) {
        actions.push({
            variant: ActionVariant.APPROVE,
            onClick: onApprove,
        });
    }

    if (isAbleToUpdate) {
        actions.push({
            variant: ActionVariant.EDIT,
            onClick: onChangeBlocked,
        });
    }

    return (
        <TableCellActions actions={actions}>
            <UserStatusFormatter>{status}</UserStatusFormatter>
        </TableCellActions>
    );
});
