import './DeclarationFormModal.scss';

import React, { Component } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { ProjectDumpLoadDeclaration, ProjectDumpLoadDeclarationClass } from '~/@components/ProjectDumpLoadDeclaration';
import { getBem } from '~/@sochi-components/@bem';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import * as queries from '~/@store/dumpLoads/dumpLoads.queries';
import { AbilityUserContext, IAbilityUserContext } from '~/contexts';
import {
    DumpLoadDeclarationQuery,
    DumpLoadDeclarationQueryVariables,
    ProjectQuery_project,
    ProjectQuery_project_dumpLoads,
} from '~/graphql';
import { canUpdateDumploadDeclaration } from '~/utils/auth';

import { FileFormats } from '../../../../../config/enum';
import { showConfirmDialog } from '../../../../../services/dialog';
import { handledGraphql, WithGraphqlProps } from '../../../../../services/hoc';
import { startLoading, stopLoading } from '../../../../../services/loader';
import { handleProjectGraphqlPermissionError } from '../../../../../utils/project';

type DeclarationFormModalProps = {
    onClose: () => void;
    project: ProjectQuery_project;
    mass: ProjectQuery_project_dumpLoads;
};

type InjectedProps = WithGraphqlProps<
    DeclarationFormModalProps,
    DumpLoadDeclarationQuery,
    DumpLoadDeclarationQueryVariables
>;

class DeclarationFormModal extends Component<InjectedProps> {
    static contextType = AbilityUserContext;
    context!: IAbilityUserContext;

    projectDumpLoadDeclarationRef?: ProjectDumpLoadDeclarationClass;
    bem = getBem(this);

    toggleLoader = (loading: boolean) => {
        loading ? startLoading() : stopLoading();
    };

    close = () => {
        if (this.projectDumpLoadDeclarationRef) this.projectDumpLoadDeclarationRef.handleClose();
    };

    downloadDeclaration = () => {
        if (this.projectDumpLoadDeclarationRef)
            this.projectDumpLoadDeclarationRef.checkDeclarationAndDownloadDocument(FileFormats.PDF);
    };

    saveAndClose = () => {
        if (this.projectDumpLoadDeclarationRef) this.projectDumpLoadDeclarationRef.handleClose(true);
    };

    confirmSave = async (onSuccess: () => void, onReject: () => void) => {
        const confirmed = await showConfirmDialog({
            message: <LocalizableText code={'saveFormAlert'} />,
        });

        confirmed ? onSuccess() : onReject();
    };

    render() {
        const { mass: dumpLoad, project, data, onClose } = this.props;
        const readOnly = !canUpdateDumploadDeclaration(this.context, project, dumpLoad);

        return (
            <DialogContent className={this.bem.className}>
                <ProjectDumpLoadDeclaration
                    customSaveApprove={this.confirmSave}
                    project={project}
                    dumpload={dumpLoad}
                    projectId={project.id}
                    projectLoadId={dumpLoad.id}
                    dumpType={dumpLoad.dumpType}
                    onLoad={this.toggleLoader}
                    customRef={(instance: ProjectDumpLoadDeclarationClass) =>
                        (this.projectDumpLoadDeclarationRef = instance)
                    }
                    data={data}
                    onClose={onClose}
                />
                <div className={this.bem.element('button-group')}>
                    <Button
                        className={this.bem.element('button-close')}
                        color="primary"
                        variant="outlined"
                        size="large"
                        onClick={this.close}>
                        <LocalizableText code={'close'} />
                    </Button>
                    <Button color="primary" variant="contained" size="large" onClick={this.downloadDeclaration}>
                        <LocalizableText code={'download'} />
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={this.saveAndClose}
                        disabled={readOnly}>
                        <LocalizableText code={'saveAndClose'} />
                    </Button>
                </div>
            </DialogContent>
        );
    }
}

const withData = handledGraphql<
    DumpLoadDeclarationQuery,
    DeclarationFormModalProps,
    DumpLoadDeclarationQueryVariables,
    InjectedProps
>(
    queries.DumpLoadDeclarationQuery,
    {
        options: ({ project, mass: dumpLoad }) => ({
            // fetchPolicy: 'network-only',
            variables: {
                projectId: project.id,
                projectLoadId: dumpLoad.id,
            },
        }),
    },
    handleProjectGraphqlPermissionError
);

export default withData(DeclarationFormModal);
