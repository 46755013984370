import React, { useMemo } from 'react';

import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { Text } from '~/@components/Text';
import { dumpLoadsStore, IDumpLoad } from '~/@store/dumpLoads';
import { showDeliveryLinesMassEditDialog } from '~/@views/AdminView/common/DeliveryLinesMassEditForm';

type Props = {
    dumpLoad: IDumpLoad;
};

export const DumpLoadNumberCell = ({ dumpLoad }: Props) => {
    const isVolumesMode = dumpLoadsStore.isVolumesMode;

    const actions: Array<ITableCellAction> = useMemo(() => {
        if (isVolumesMode) {
            return [
                {
                    variant: ActionVariant.EDIT,
                    onClick: () => showDeliveryLinesMassEditDialog(dumpLoad.serialNumber),
                },
            ];
        } else {
            return [
                {
                    variant: ActionVariant.COPY,
                    content: dumpLoad.serialNumber,
                },
            ];
        }
    }, [dumpLoad, isVolumesMode]);

    const hasNotVerifiedLines = !!dumpLoad.notVerifiedDeliveryLineCount;

    return (
        <TableCellActions actions={actions}>
            <Text variant="TableRegular" color={hasNotVerifiedLines ? 'error' : 'textPrimary'}>
                {dumpLoad.serialNumber}
            </Text>
        </TableCellActions>
    );
};
