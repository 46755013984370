import { useMutation } from '@apollo/client';

import { VehicleTypeRemove, VehicleTypeRemoveVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import { VehicleTypeRemoveMutation, VehicleTypesQuery } from '../vehicleTypes.queries';

export const useVehicleTypeRemove = (id: string) => {
    const [callMutation, { loading }] = useMutation<VehicleTypeRemove, VehicleTypeRemoveVariables>(
        VehicleTypeRemoveMutation,
        {
            refetchQueries: [{ query: VehicleTypesQuery }],
            awaitRefetchQueries: true,
        }
    );

    const removeVehicleType = (): Promise<boolean> =>
        callMutation({ variables: { id } })
            .then(res => !!res.data?.vehicleTypeRemove)
            .catch(handleApolloError);

    return [removeVehicleType, loading] as const;
};
