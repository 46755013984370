import React, { useCallback, useMemo } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import client from '~/apolloClient';
import { useUserAbilities } from '~/contexts';
import { DumpLoadActionName, LoadStatus } from '~/graphql';
import i18n from '~/i18n';
import { showConfirmDialog } from '~/services/dialog';
import { canUpdateDumpLoad } from '~/utils/auth';
import { changeDumpLoadStatus } from '~/utils/dumpLoad';

import { IDumpLoad, IProject } from '../../AddEditMassForm/AddEditMass.types';

type Props = {
    dumpLoad: IDumpLoad;
    project: IProject;
    onEdit: (dumpLoad: IDumpLoad) => void;
};

export const PriceCalculatorDeclarationNumberCell = ({ dumpLoad, project, onEdit: onEditProps }: Props) => {
    const ability = useUserAbilities();

    const onEdit = useCallback(() => onEditProps(dumpLoad), [dumpLoad, onEditProps]);

    const canEdit = useMemo(() => canUpdateDumpLoad(ability, project, dumpLoad), [ability, project, dumpLoad]);

    const canDoActions = useCallback(
        (actions: DumpLoadActionName[]) => dumpLoad.actionsToShow.some(action => actions.includes(action.actionName)),
        [dumpLoad]
    );

    const canDeleteOrReject = useMemo(
        () => canDoActions([DumpLoadActionName.DISCARD, DumpLoadActionName.REJECT]),
        [canDoActions]
    );

    const onDeleteOrReject = useCallback(async () => {
        const isReject = canDoActions([DumpLoadActionName.REJECT]);

        const confirmed = await showConfirmDialog({
            title: <LocalizableText code={isReject ? 'rejectMass' : 'deleteMass'} />,
            message: `${isReject ? i18n.confirmReject : i18n.confirmDelete} ${dumpLoad.dumpType?.name || i18n.NA}?`,
        });

        if (!confirmed) return;

        await changeDumpLoadStatus({
            client,
            dumpLoadIds: [dumpLoad.id],
            project,
            status: isReject ? LoadStatus.NOT_ORDERED : LoadStatus.DISCARDED,
        });
    }, [dumpLoad, project, canDoActions]);

    const actions: Array<ITableCellAction> = useMemo(
        () => [
            {
                variant: ActionVariant.EDIT,
                onClick: onEdit,
                disabled: !canEdit,
            },
            {
                variant: ActionVariant.DELETE,
                onClick: onDeleteOrReject,
                disabled: !canDeleteOrReject || !canEdit,
            },
        ],
        [onEdit, canEdit, canDeleteOrReject, onDeleteOrReject]
    );

    return <TableCellActions actions={actions}>{dumpLoad.serialNumber}</TableCellActions>;
};
