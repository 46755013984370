import { gql } from '@apollo/client';

import { PageInfoFragment } from '~/@store/common/common.queries';

export const DeliveryLineShortFragment = gql`
    fragment DeliveryLineShortFragment on PplDeliveryLine {
        id
        deliveryLineIdx
        orderNumber
        weight
        plannedStartDate
        truckRegistrationNumber
        truckCapacity
        customerInvoiceNumber
        transportationInvoiceNumber
        landfillInvoiceNumber
        receiptUrl
        deletedAt
        truckCapacity
        comment
        status
        company {
            id
            name
        }
    }
`;

export const DeliveryLineFragment = gql`
    fragment DeliveryLineFragment on PplDeliveryLine {
        id
        orderNumber
        deliveryLineIdx
        weight
        plannedStartDate
        excavationDate
        startDeliveryDate
        endDeliveryDate
        company {
            id
            name
        }
        truckRegistrationNumber
        truckCapacity
        customerInvoiceNumber
        transportationInvoiceNumber
        landfillInvoiceNumber
        receiptUrl
        deletedAt
        truckCapacity
        comment
        receiverComment
        senderComment
        status
        driver {
            id
            name
            surname
            phoneNumber
        }
        excavatorOperator {
            id
            name
            surname
            phoneNumber
        }
        beastStatus
        vehicleType {
            id
            name
            kind
            externalId
            fuelConsumptions {
                fuel
                value
            }
        }
        fuel
        engine
        driverComment
    }
`;

export const AdminDeliveryLinesPagedQuery = gql`
    query AdminDeliveryLinesPagedQuery(
        $filter: DeliveryLineFilterInput
        $sort: SortInput
        $connection: ConnectionInput
    ) {
        deliveryLinesPaged(filter: $filter, sort: $sort, connection: $connection) {
            edges {
                cursor
                node {
                    ...DeliveryLineFragment
                }
            }
            pageInfo {
                ...PageInfoFragment
            }
            pageCount
            totalCount
        }
    }

    ${DeliveryLineFragment}
    ${PageInfoFragment}
`;

export const AdminDeliveryLineHistoryQuery = gql`
    query AdminDeliveryLineHistoryQuery($id: ID!) {
        pplDeliveryLine(id: $id) {
            history {
                user {
                    id
                    name
                    surname
                    role
                }
                changes {
                    field
                    valueOld
                    valueNew
                }
                createdAt
            }
        }
    }
`;

export const DeliveryLineUpdateMutation = gql`
    mutation DeliveryLineUpdateMutation($input: DeliveryLineUpdateInput!) {
        deliveryLineUpdate(input: $input) {
            ...DeliveryLineFragment
        }
    }

    ${DeliveryLineFragment}
`;

export const DeliveryLineDeleteMutation = gql`
    mutation DeliveryLineDeleteMutation($id: ID!) {
        deliveryLineDelete(id: $id)
    }
`;

export const DeliveryLineRestoreMutation = gql`
    mutation DeliveryLineRestoreMutation($id: ID!) {
        deliveryLineRestore(id: $id)
    }
`;

export const DeliveryLineCreateMutation = gql`
    mutation DeliveryLineCreateMutation($input: DeliveryLineCreateInput!) {
        deliveryLineCreate(input: $input) {
            ...DeliveryLineFragment
        }
    }

    ${DeliveryLineFragment}
`;

export const DeliveryLinesUpdateMutation = gql`
    mutation DeliveryLinesUpdateMutation($input: [DeliveryLineUpdateInput!]!) {
        deliveryLinesUpdate(input: $input) {
            ...DeliveryLineFragment
        }
    }

    ${DeliveryLineFragment}
`;

export const DeliveryLineQuery = gql`
    query DeliveryLineQuery($id: ID!) {
        pplDeliveryLine(id: $id) {
            ...DeliveryLineFragment
        }
    }

    ${DeliveryLineFragment}
`;
