import { MAX_DUMP_LOAD_AMOUNT } from '@common/constants';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '~/@components/Button';
import { Divider } from '~/@components/Divider';
import { FormikNumberField, FormikSelectField } from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import { useDumpLoadCreateMutation } from '~/@store/dumpLoads';
import { IDumpTypeSelect } from '~/@store/dumpTypes';
import { IDetailedProject } from '~/@store/projects';
import { DumpTypeSelectQuery } from '~/@views/AdminView/common/DumpTypeSelectQuery';
import { UnitOfMeasure } from '~/graphql';
import i18n from '~/i18n';

import { getDumpLoadCreateFormik } from './DumpLoadCreateFormik';

type Props = {
    project: IDetailedProject;
    onClose: () => void;
};

export const DumpLoadCreateForm = ({ project, onClose }: Props) => {
    const [create] = useDumpLoadCreateMutation(project);
    const history = useHistory();

    const formik = useFormik(getDumpLoadCreateFormik(project, create, onClose, history));

    return (
        <FormikProvider value={formik}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DumpTypeSelectQuery>
                            {(dumpTypes: IDumpTypeSelect[]) => {
                                return (
                                    <FormikSelectField
                                        fullWidth
                                        label={<LocalizableText code={'massCategory'} />}
                                        name="dumpType"
                                        formik={formik}
                                        items={dumpTypes}
                                        renderValue={dumpType => dumpType.name}
                                        keyGetter={dumpType => dumpType.id}
                                        addEmptyOption={false}
                                    />
                                );
                            }}
                        </DumpTypeSelectQuery>
                    </Grid>
                    <Grid item xs={6}>
                        <FormikNumberField
                            fullWidth
                            label={<LocalizableText code={'amount'} />}
                            name="amount"
                            formik={formik}
                            min={0}
                            max={MAX_DUMP_LOAD_AMOUNT}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikSelectField
                            label={<LocalizableText code={'unitOfMeasure'} />}
                            name="unitOfMeasure"
                            formik={formik}
                            items={Object.values(UnitOfMeasure)}
                            renderValue={u => i18n.unitsOfMeasure[u]}
                            keyGetter={v => v}
                            addEmptyOption={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} container justify="space-between">
                        <Button variant="contained" onClick={onClose}>
                            {i18n.cancel}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={formik.submitForm}
                            disabled={formik.isSubmitting}>
                            {i18n.create}
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
};
