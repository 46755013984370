import React from 'react';
import { useParams } from 'react-router';

import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { IDetailedLandfill, useDetailedLandfill } from '~/@store/landfills';
import { LandfillDetailParams } from '~/@store/routes';

import { LandfillTabs } from './LandfillTabs';

export const LandfillDetailPage = () => {
    const { id } = useParams<LandfillDetailParams>();

    const query = useDetailedLandfill(id);

    return (
        <QueryStateHandler query={query} dataGetter={query => query.data?.landfill || undefined}>
            {(landfill: IDetailedLandfill) => {
                return <LandfillTabs landfill={landfill} />;
            }}
        </QueryStateHandler>
    );
};
