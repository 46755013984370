import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { useProgressState } from '~/@components/@hooks/use-progress-state';
import { AutocompleteAsync } from '~/@components/Autocomplete';
import { ILandfillSearchRecord, useLandfillsSearchFunc } from '~/@store/landfills';
import i18n from '~/i18n';

import { PopupEditDialog } from './PopupEditDialog';

type Props = {
    label?: React.ReactNode;
    initialValue?: ILandfillSearchRecord | null;
    onSubmit: (value: ILandfillSearchRecord | null) => Promise<unknown>;
    onClose: (v: boolean) => void;
    nullable?: boolean;
    disabled?: boolean;
    excludeParticipantId?: string;
};

const renderLandfill = (landfill: ILandfillSearchRecord) => landfill.name;

export const PopupLandfillSelect = ({
    label,
    initialValue = null,
    onClose,
    onSubmit,
    nullable = true,
    disabled,
    excludeParticipantId = undefined,
}: Props) => {
    const { root, textField } = useStyles();
    const [value, setValue] = useState<ILandfillSearchRecord | null>(initialValue);

    const [isSubmitting, submit] = useProgressState(onSubmit);

    const handleSubmit = () => {
        if (value) submit(value).then(() => onClose(true));
    };

    const handleClear = () => {
        if (nullable) submit(null).then(() => onClose(true));
    };

    const landfillSearch = useLandfillsSearchFunc({ excludeUserId: excludeParticipantId });
    const loadOptions = (search: string) => landfillSearch(search);

    return (
        <PopupEditDialog onClose={onClose}>
            <div className={root}>
                <AutocompleteAsync
                    label={label}
                    selectedOption={value}
                    onOptionSelect={setValue}
                    renderOption={renderLandfill}
                    loadOptions={loadOptions}
                    className={textField}
                    disabled={disabled}
                />
                <Button disabled={isSubmitting || disabled} variant="contained" color="primary" onClick={handleSubmit}>
                    {i18n.save}
                </Button>
                {nullable && (
                    <Button disabled={isSubmitting || disabled} variant="contained" onClick={handleClear}>
                        {i18n.clear}
                    </Button>
                )}
            </div>
        </PopupEditDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        gap: theme.spacing(),
    },
    textField: {
        backgroundColor: theme.palette.background.paper,
    },
}));
