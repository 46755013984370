import './RouteInfoCard.scss';

import React, { VFC } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { RouteIcon, TimeIcon } from '~/@sochi-components/Icons';

type RouteInfoCardProps = {
    distance: string;
    duration: string;
};

export const RouteInfoCard: VFC<RouteInfoCardProps> = ({ distance, duration }) => {
    const { className, element } = useFunctionalBem(RouteInfoCard);

    return (
        <div className={className}>
            <div className={element('title')}>
                <div className={element('name')}>
                    <LocalizableText code={'routeDetails'} />
                </div>
            </div>
            <div className={element('body')}>
                <div className={element('section')}>
                    <RouteIcon className={element('section-icon')} />
                    <div>{distance}</div>
                </div>
                <div className={element('section')}>
                    <TimeIcon className={element('section-icon')} />
                    <div>{duration}</div>
                </div>
            </div>
        </div>
    );
};
