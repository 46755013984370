import { useQuery } from '@apollo/client';
import { Button, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import React, { useRef, VFC } from 'react';

import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import {
    ICheckboxForDeclarationProps,
    IInputForDeclarationProps,
    ProjectDumpLoadDeclaration,
    ProjectDumpLoadDeclarationClass,
} from '~/@components/ProjectDumpLoadDeclaration';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { TextField } from '~/@components/TextField';
import { showConfirmDialog, showCustomDialog } from '~/@store/common/dialogs';
import { IDumpLoad } from '~/@store/dumpLoads';
import * as queries from '~/@store/dumpLoads/dumpLoads.queries';
import { useProjectAbilities } from '~/@store/projects';
import { FileFormats } from '~/config/enum';
import { DumpLoadDeclarationQuery, DumpLoadDeclarationQueryVariables } from '~/graphql';
import i18n from '~/i18n';

const MuiCheckboxForDeclaration: VFC<ICheckboxForDeclarationProps> = ({ checked, onChangeState, label, disabled }) => {
    return (
        <FormControlLabel
            style={{ marginLeft: 0 }}
            control={<Checkbox color="primary" checked={!!checked} onChange={() => onChangeState(!checked)} />}
            label={label}
            disabled={disabled}
        />
    );
};

const MuiInputForDeclaration: VFC<IInputForDeclarationProps> = ({ value, onChange, readOnly }) => (
    <TextField value={value || ''} onChange={e => onChange(e.target.value)} disabled={readOnly} fullWidth />
);

type DeclarationFormModalProps = {
    dumpLoad: IDumpLoad;
    onClose: () => void;
};

const DumpLoadEditDeclarationDialog = ({ dumpLoad, onClose }: DeclarationFormModalProps) => {
    const { canEditProject } = useProjectAbilities(dumpLoad.project);
    const projectDumpLoadDeclarationRef = useRef<ProjectDumpLoadDeclarationClass>();

    const downloadDeclaration = () => {
        if (projectDumpLoadDeclarationRef.current)
            projectDumpLoadDeclarationRef.current.checkDeclarationAndDownloadDocument(FileFormats.PDF);
    };

    const saveAndClose = () => {
        if (projectDumpLoadDeclarationRef.current) projectDumpLoadDeclarationRef.current.handleClose(true);
    };

    const confirmSave = async (onSuccess: () => void, onReject: () => void) => {
        const confirmed = await showConfirmDialog({
            title: '',
            render: () => i18n.saveFormAlert,
        });

        confirmed ? onSuccess() : onReject();
    };

    const query = useQuery<DumpLoadDeclarationQuery, DumpLoadDeclarationQueryVariables>(
        queries.DumpLoadDeclarationQuery,
        {
            variables: {
                projectId: dumpLoad.project!.id,
                projectLoadId: dumpLoad.id,
            },
        }
    );

    const { root } = useStyles();

    return (
        <CardDialog
            maxWidth="lg"
            fullWidth
            title={<LocalizableText code={'declarationOfWaste'} />}
            onClose={onClose}
            buttons={
                <>
                    <Button variant="contained" onClick={onClose}>
                        {i18n.close}
                    </Button>
                    <Button variant="contained" color="primary" onClick={downloadDeclaration}>
                        {i18n.download}
                    </Button>
                    <Button variant="contained" color="primary" onClick={saveAndClose} disabled={!canEditProject}>
                        {i18n.saveAndClose}
                    </Button>
                </>
            }>
            <QueryStateHandler query={query} dataGetter={query => query.data}>
                {(data: DumpLoadDeclarationQuery) => (
                    <div className={root}>
                        <ProjectDumpLoadDeclaration
                            InputComp={MuiInputForDeclaration}
                            CheckBoxComp={MuiCheckboxForDeclaration}
                            customSaveApprove={confirmSave}
                            project={dumpLoad.project!}
                            dumpload={dumpLoad}
                            projectId={dumpLoad.project!.id}
                            projectLoadId={dumpLoad.id}
                            dumpType={dumpLoad.dumpType}
                            customRef={(instance: ProjectDumpLoadDeclarationClass) =>
                                (projectDumpLoadDeclarationRef.current = instance)
                            }
                            data={data}
                            onClose={onClose}
                        />
                    </div>
                )}
            </QueryStateHandler>
        </CardDialog>
    );
};

export const showDumpLoadWasteDeclarationDialog = (dumpLoad: IDumpLoad) => {
    const ignoredPromise = showCustomDialog({
        render: closeDialog => <DumpLoadEditDeclarationDialog dumpLoad={dumpLoad} onClose={() => closeDialog(true)} />,
    });
};

const useStyles = makeStyles(() => ({
    root: {
        '& .ProjectDumpLoadDeclaration': {
            position: 'relative',
            background: 'none',
        },
        '& .ProjectDumpLoadDeclaration__content': {
            background: 'none',
            margin: 0,
            padding: 0,
        },
    },
}));
