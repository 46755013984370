import { useApolloClient } from '@apollo/client';
import { ErrorCodes } from '@common/enums';

import * as queries from '~/@store/locations/location.queries';
import { gqlTypeToLocation, ILocation } from '~/@store/locations/locations.types';
import { Geocode, GeocodeVariables, LocationType } from '~/graphql';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';
import { handleApolloError } from '~/utils';
import { NotAnError } from '~/utils/error';

type UseGeocodeResult = (address: string) => Promise<ILocation>;

export const useGeocode = (): UseGeocodeResult => {
    const client = useApolloClient();

    return (address: string) =>
        client
            .query<Geocode, GeocodeVariables>({
                query: queries.TextGeocode,
                variables: {
                    text: address,
                },
            })
            .catch(handleApolloError)
            .then(result => {
                if (result.data.geocode.type === LocationType.UNKNOWN) {
                    globalMessage.info(i18n.errorCodes[ErrorCodes.GOOGLE_API_ZERO_RESULTS]);

                    return Promise.reject(new NotAnError('Failed geocode'));
                }

                return gqlTypeToLocation(result.data.geocode);
            });
};
