import { cleanDigits, formatUserPhoneNumber } from '@common/utils';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { WhatsappIcon } from '~/@components/Icon';

import { NullableFormatter } from './NullableFormatter';

type Props = {
    children: string | null | undefined;
};

const getWhatsappLink = (phone: string) => `https://wa.me/${phone}`;

export const PhoneFormatter = ({ children }: Props) => {
    const { root, link } = useStyles();
    const digits = children ? cleanDigits(children) : null;

    if (!digits) return <NullableFormatter>{children}</NullableFormatter>;

    const showWhatsappIcon = !digits.startsWith('0');

    return (
        <span className={root}>
            {formatUserPhoneNumber(children)}
            {showWhatsappIcon && (
                <a className={link} href={getWhatsappLink(digits)} target="_blank" rel="noreferrer">
                    <WhatsappIcon />
                </a>
            )}
        </span>
    );
};

const useStyles = makeStyles(() => ({
    root: { display: 'flex', alignItems: 'center', gap: 8 },
    link: { height: 24 },
}));
