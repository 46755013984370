import { DespatchAdviceSource } from '@common/enums';
import React from 'react';

import { BeastDataTable } from '~/@components/BeastDataDialog/BeastDataTable';
import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { showCustomDialog } from '~/@store/common';

type Props = {
    entityId: string;
    source: DespatchAdviceSource;
    onClose: () => void;
};

const BeastDataDialog = ({ entityId, source, onClose }: Props) => {
    return (
        <CardDialog onClose={onClose} title={<LocalizableText code={'DespatchAdvicesTable.title'} />} maxWidth="lg">
            <BeastDataTable entityId={entityId} source={source} />
        </CardDialog>
    );
};

export const showBeastDataDialog = (entityId: string, source: DespatchAdviceSource) =>
    showCustomDialog({
        render: closeDialog => (
            <BeastDataDialog onClose={() => closeDialog(true)} entityId={entityId} source={source} />
        ),
    });
