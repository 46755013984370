import './SochiComponentDialog.scss';

import React from 'react';

import { getBem } from '~/@sochi-components/@bem';

import { ComponentDialogModel } from '../../../stores/models';
import { Modal } from '../../Modal';
import { DialogTitle } from '../DialogTitle';
import { SochiCloseDialogButton } from '../SochiCloseDialogButton';

type SochiComponentDialogProps = {
    dialog: ComponentDialogModel;
};

export class SochiComponentDialog extends React.Component<SochiComponentDialogProps> {
    bem = getBem(this);

    customContent = this.props.dialog.render(this.props.dialog.resolver);

    handleClose = () => this.props.dialog.resolver(false);

    render() {
        const { className } = this.bem;
        const { title, noContainer, noModalComponent, forceCrossClose } = this.props.dialog;

        if (noModalComponent) return this.customContent;

        if (noContainer) return <Modal noPadding>{this.customContent}</Modal>;

        return (
            <Modal>
                <div className={className}>
                    <SochiCloseDialogButton onClose={this.handleClose} mobileOnly={!forceCrossClose} />
                    {title && <DialogTitle hasCloseButton={forceCrossClose}>{title}</DialogTitle>}
                    {this.customContent}
                </div>
            </Modal>
        );
    }
}
