import { routes } from '@common/routes';
import React, { FC } from 'react';
import type { RouteComponentProps, RouteProps } from 'react-router';
import { withRouter } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import { useUserAbilities } from '~/contexts';
import { getAuthToken } from '~/utils/auth';

const NoAuthRouteComponent: FC<RouteProps & RouteComponentProps> = ({ component: Component, ...rest }) => {
    const { user } = useUserAbilities();

    const isLoggedIn = Boolean(user && user.id && user.confirmed && user.verified && !user.blocked && getAuthToken());

    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Redirect
                        to={{
                            pathname: user.startPage || routes.root,
                            state: { from: props.location },
                        }}
                    />
                ) : (
                    Component && <Component {...props} />
                )
            }
        />
    );
};

export default withRouter(NoAuthRouteComponent);
