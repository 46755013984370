import React from 'react';

import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { showCustomDialog } from '~/@store/common';

import { ProjectEditForm } from './ProjectEditForm';

type Props = {
    onClose: (v: boolean) => void;
};

const ProjectCreateDialog = ({ onClose }: Props) => {
    const handleClose = () => onClose(false);

    return (
        <CardDialog onClose={handleClose} title={<LocalizableText code={'ProjectForm.createProject'} />} fullWidth>
            <ProjectEditForm project={null} onClose={handleClose} />
        </CardDialog>
    );
};

export const showProjectCreateDialog = () =>
    showCustomDialog({
        render: closeDialog => <ProjectCreateDialog onClose={closeDialog} />,
    });
