import { UnitOfMeasure } from '@common/enums';
import { formatAmount } from '@common/utils/formaters';
import React, { FC } from 'react';

import { Text } from '~/@components/Text';
import i18n from '~/i18n';
import { formatDate } from '~/utils/date';

type Props = {
    item: {
        date: Date;
        amount?: number;
        signSymbol?: '+' | '-';
    };
};

export const BatchDateAmount: FC<Props> = ({ item }) => {
    return (
        <Text variant="BodyRegular" color={(item.amount || 0) >= 0 ? 'textPrimary' : 'error'}>{`${
            i18n.LandfillPage.batchLog.amountOn
        } ${formatDate(item.date)}: ${formatAmount(item.amount, UnitOfMeasure.TONNS)}`}</Text>
    );
};
