import { computed } from 'mobx';

import { DEFAULT_COMPANY_SORT } from '~/@store/companies/companies.constants';
import { globalSearchStore } from '~/@store/globalSearch';
import { CompaniesPagedQueryVariables, PplCompanyFilter } from '~/graphql';

import { FilterStore, PaginationStore, SortStore } from '../common';

export enum CompaniesStoreFilterFields {
    name = 'name',
    organizationNumber = 'organizationNumber',
    phone = 'phone',
    email = 'email',
    GLN = 'GLN',
}

export type CompaniesStoreFilter = {
    [CompaniesStoreFilterFields.name]: string | null | undefined;
    [CompaniesStoreFilterFields.organizationNumber]: string | null | undefined;
    [CompaniesStoreFilterFields.phone]: string | null | undefined;
    [CompaniesStoreFilterFields.email]: string | null | undefined;
    [CompaniesStoreFilterFields.GLN]: string | null | undefined;
};

class CompaniesStore {
    pagination = new PaginationStore();
    filter = new FilterStore<CompaniesStoreFilter>(this.pagination.onClear);
    sort = new SortStore(DEFAULT_COMPANY_SORT, this.pagination.onClear);

    @computed
    get companiesQueryVariables(): CompaniesPagedQueryVariables {
        const filter: PplCompanyFilter = { ...this.filter.values };
        if (globalSearchStore.search) filter.search = globalSearchStore.search;
        const sort = this.sort.value;
        const connection = this.pagination.connection;

        return { filter, sort, connection };
    }
}

export const companiesStore = new CompaniesStore();
