import './AuthText.scss';

import React from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';

type AuthTextProps = { transparent?: boolean };

export const AuthText: React.FC<AuthTextProps> = ({ children, transparent = false }) => {
    const { block } = useFunctionalBem(AuthText);

    return <p className={block({ transparent })}>{children}</p>;
};
