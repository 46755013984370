import './SochiTitle.scss';

import React from 'react';

import { getBem } from '~/@sochi-components/@bem';

type SochiTitleProps = {
    title: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
};

export class SochiTitle extends React.Component<SochiTitleProps> {
    bem = getBem(this);

    render() {
        const { className, element } = this.bem;

        const { title, children } = this.props;

        return (
            <div className={className}>
                <h2 className={element('title')}>{title}</h2>
                {children}
            </div>
        );
    }
}
