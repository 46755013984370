import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import {
    UserActivityPagedQuery as UserActivityPagedQueryType,
    UserActivityPagedQuery_activitiesPaged,
    UserActivityPagedQuery_activitiesPaged_edges_node,
    UserActivityPagedQueryVariables,
} from '~/graphql';

import { UserActivityPagedQuery } from '../userActivities.queries';
import { userActivitiesStore } from '../userActivities.store';

export type IUserActivity = UserActivityPagedQuery_activitiesPaged_edges_node;

export type IPagedUserActivities = UserActivityPagedQuery_activitiesPaged;

export const useUserActivities = (): QueryResult<UserActivityPagedQueryType, UserActivityPagedQueryVariables> => {
    const variables: UserActivityPagedQueryVariables = useDebouncedObservable(
        () => userActivitiesStore.userActivityQueryVariables,
        TIMEOUT_ON_QUERY_DEBOUNCE
    );

    return useQuery<UserActivityPagedQueryType, UserActivityPagedQueryVariables>(UserActivityPagedQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
        onCompleted: data => userActivitiesStore.pagination.onQueryComplete(data.activitiesPaged),
    });
};
