import { adminRoutes } from '@common/routes';
import React from 'react';
import { useHistory } from 'react-router';

import { MenuGrow } from '~/@components/MenuGrow';
import i18n from '~/i18n';

export enum CompaniesUsersRoute {
    companies = 'companies',
    users = 'users',
}

type RouteItem = { title: string; value: CompaniesUsersRoute; path: string };

const routes: RouteItem[] = [
    { title: i18n.CompaniesTable.route, value: CompaniesUsersRoute.companies, path: adminRoutes.companies },
    { title: i18n.UsersTable.route, value: CompaniesUsersRoute.users, path: adminRoutes.users },
];

type Props = {
    route: CompaniesUsersRoute;
};

const renderValue = (routeItem: RouteItem) => routeItem.title;

export const CompaniesUsersRouteSwitch = ({ route }: Props) => {
    const history = useHistory();

    const onChange = React.useCallback((routeItem: RouteItem) => history.push(routeItem.path), [history]);

    const value = routes.find(item => item.value === route)!;

    return <MenuGrow<RouteItem> items={routes} renderValue={renderValue} value={value} onChange={onChange} />;
};
