import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/projects/projects.queries';
import { ProjectAddCertificateMutation, ProjectAddCertificateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

type AddCertificateFunc = (file: File) => Promise<unknown>;

export const useProjectAddCertificate = (projectId: string): [AddCertificateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        ProjectAddCertificateMutation,
        ProjectAddCertificateMutationVariables
    >(queries.ProjectAddCertificateMutation, {
        refetchQueries: [{ query: queries.ProjectQuery, variables: { id: projectId } }],
    });

    const addCertificate = useCallback<AddCertificateFunc>(
        certificate => {
            return callMutation({
                variables: { id: projectId, certificate },
            }).catch(handleApolloError);
        },
        [callMutation, projectId]
    );

    return [addCertificate, mutationResult.loading];
};
