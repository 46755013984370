import { DependencyList, useEffect, useRef } from 'react';

const shallowCompare = (dep1: DependencyList | undefined, dep2: DependencyList | undefined) => {
    if (dep1 === dep2) return true;
    if (!dep1 || !dep2) return false;
    if (dep1.length !== dep2.length) return false;

    return dep1.every((d, i) => d === dep2[i]);
};

const FIRST_RUN = Symbol('FIRST_RUN');

export const useSyncEffect: typeof useEffect = (cb, deps) => {
    const depRef = useRef<DependencyList | undefined | typeof FIRST_RUN>(FIRST_RUN);

    if (depRef.current === FIRST_RUN || !shallowCompare(depRef.current, deps)) cb();

    depRef.current = deps;
};
