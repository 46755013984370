import React, { ReactElement } from 'react';

import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { useVehicleTypes, VehicleType } from '~/@store/vehicleTypes';

type Props = {
    children: (vehicleTypes: VehicleType[]) => ReactElement;
};

export function VehicleTypeSelectQuery({ children }: Props) {
    const query = useVehicleTypes();

    return (
        <QueryStateHandler query={query} dataGetter={query => query.data?.vehicleTypes} progressSize="linear">
            {(vehicleTypes: VehicleType[]) => children(vehicleTypes)}
        </QueryStateHandler>
    );
}
