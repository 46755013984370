import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { KeyboardEventHandler, useState } from 'react';

import { useProgressState } from '~/@components/@hooks/use-progress-state';
import { NumberField } from '~/@components/NumberField';
import { ENTER_KEY_NAME, ESC_KEY_NAME } from '~/@store/common';
import { PopupEditDialog } from '~/@views/AdminView/common/PopupEditFields/PopupEditDialog';
import i18n from '~/i18n';

type Props = {
    label: React.ReactNode;
    initialValue: number;
    onSubmit: (value: number) => Promise<unknown>;
    onClose: (v: boolean) => void;
    validation?: (v: number) => boolean;
    precision?: number;
};

export const PopupNumberEdit = ({ label, initialValue, onClose, validation, onSubmit, precision }: Props) => {
    const { root, numberField } = useStyles();

    const [value, setValue] = useState(initialValue);

    const [isLoading, submit] = useProgressState(onSubmit);

    const canSubmit = validation ? validation(value) : true;

    const handleSubmit = () => {
        submit(value).then(() => onClose(true));
    };

    const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = event => {
        if (event.key === ENTER_KEY_NAME && canSubmit) handleSubmit();
        if (event.key === ESC_KEY_NAME) onClose(false);
    };

    return (
        <PopupEditDialog onClose={onClose}>
            <div className={root}>
                <NumberField
                    autoFocus
                    label={label}
                    value={value}
                    onKeyPress={handleKeyPress}
                    onChange={setValue}
                    className={numberField}
                    disabled={isLoading}
                    precision={precision}
                />
                <Button disabled={isLoading || !canSubmit} variant="contained" color="primary" onClick={handleSubmit}>
                    {i18n.save}
                </Button>
            </div>
        </PopupEditDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        gap: theme.spacing(),
    },
    numberField: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));
