import React, { memo, VFC } from 'react';

import { CardListItem } from '~/@components/CardListItem';
import { Grid } from '~/@components/Grid';
import { Text } from '~/@components/Text';
import { fuelList, getFuelConsumptionValue, VehicleType } from '~/@store/vehicleTypes';
import i18n from '~/i18n';

type VehicleTypeCardProps = {
    vehicleType: VehicleType;
    select: () => void;
    isActive?: boolean;
};

export const VehicleTypeCard: VFC<VehicleTypeCardProps> = memo(({ vehicleType, isActive, select }) => {
    return (
        <CardListItem isActive={isActive} onClick={select}>
            <Grid container>
                <Grid item xs={6}>
                    <Text variant="BodyRegular" ellipsis>
                        {vehicleType.name}
                    </Text>
                    <Text variant="SmallText" color="textSecondary">
                        {vehicleType.kind}
                    </Text>
                    <Text variant="SmallText" color="textSecondary" ellipsis>
                        {i18n.VehicleType.id}: {vehicleType.externalId}
                    </Text>
                </Grid>

                {fuelList.map(fuel => (
                    <Grid item xs={2} key={fuel}>
                        {getFuelConsumptionValue(vehicleType, fuel) || 0}
                    </Grid>
                ))}
            </Grid>
        </CardListItem>
    );
});
