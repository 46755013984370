import './Empty.scss';

import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';

export const Empty = () => {
    const { className } = useFunctionalBem(Empty);

    return (
        <div className={className}>
            <LocalizableText code={'noElements'} />
        </div>
    );
};
