import './SochiUnitOfMeasureSelect.scss';

import React, { ReactNode } from 'react';

import { Select } from '~/@components/Select';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { IUnits, unitMeasurements } from '~/config/enum';
import i18n from '~/i18n';

const measureTranslation = {
    [unitMeasurements.TONNS]: i18n.tonns,
    [unitMeasurements.CUBIC_METERS]: i18n.cubicMeters,
};

type SochiUnitOfMeasureSelectProps = {
    onChange: (value: string) => void;
    disabled?: boolean;
    value?: IUnits | null;
    label?: ReactNode;
};

export const SochiUnitOfMeasureSelect = ({ value, label, disabled, onChange }: SochiUnitOfMeasureSelectProps) => {
    const { className } = useFunctionalBem(SochiUnitOfMeasureSelect);

    return (
        <Select
            className={className}
            label={label}
            items={Object.values(unitMeasurements)}
            value={value}
            onChange={v => v && onChange(v)}
            keyGetter={v => v || ''}
            renderValue={v => (v ? measureTranslation[v] : '')}
            disabled={disabled}
            addEmptyOption={false}
        />
    );
};
