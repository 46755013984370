import { UserRole } from '../../enums';
import { IAbilityComplexSubject } from '../abilities.types';

export enum BeastIntegrationSubject {
    DESPATCH_ADVICE = 'DESPATCH_ADVICE',
}

export interface IBeastIntegrationCalculatedSubject extends IAbilityComplexSubject {
    relationRole: UserRole[];
    subSubject: Array<BeastIntegrationSubject>;
}
