import i18n from '~/i18n';
import { formatFromNow } from '~/utils/date';
import { getUserFullName } from '~/utils/user';

import { formatString } from '../../../../common/utils';
import { ILock } from './locks.types';

export const formatLockErrorMessage = (lock: ILock) =>
    formatString(
        i18n.lock.entityLocked,
        lock.entityType,
        lock.entityId,
        getUserFullName(lock.user!),
        formatFromNow(lock.expire)
    );
