export const ChartColors = {
    green: '#008000',
    red: '#ff0000',
    yellow: '#ffff00',
    purple: '#800080',
    aqua: '#00ffff',
    violet: '#800080',
    azure: '#f0ffff',
    beige: '#f5f5dc',
    blue: '#0000ff',
    brown: '#a52a2a',
    cyan: '#00ffff',
    darkBlue: '#00008b',
    darkCyan: '#008b8b',
    darkGrey: '#a9a9a9',
    darkGreen: '#006400',
    darkKhaki: '#bdb76b',
    darkMagenta: '#8b008b',
    darkOliveGreen: '#556b2f',
    darkOrange: '#ff8c00',
    darkOrchid: '#9932cc',
    darkRed: '#8b0000',
    darkSalmon: '#e9967a',
    darkViolet: '#9400d3',
    fuchsia: '#ff00ff',
    gold: '#ffd700',
    indigo: '#4b0082',
    khaki: '#f0e68c',
    lightBlue: '#add8e6',
    lightCyan: '#e0ffff',
    lightGreen: '#90ee90',
    lightGrey: '#d3d3d3',
    lightPink: '#ffb6c1',
    lightYellow: '#ffffe0',
    lime: '#00ff00',
    magenta: '#ff00ff',
    maroon: '#800000',
    navy: '#000080',
    olive: '#808000',
    orange: '#ffa500',
    pink: '#ffc0cb',
    silver: '#c0c0c0',
    black: '#000000',
} as const;

export const ChartColorsArray: string[] = Object.keys(ChartColors).map(
    key => ChartColors[key as keyof typeof ChartColors]
);
