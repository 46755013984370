import { IconButton, InputAdornment } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';

import { useDebounce } from '~/@components/@hooks';
import { CloseIcon } from '~/@components/Icon';
import { TextField } from '~/@components/TextField';
import i18n from '~/i18n';

import { landfillSearchStore } from './landfillSearch.store';

export const DumpLoadLandfillDestinationFilter: FC = observer(() => {
    useEffect(() => landfillSearchStore.clearSearch(), []);

    const [value, setValue] = useState('');

    const debouncedSearch = useDebounce(value);

    useEffect(() => {
        landfillSearchStore.setSearch(debouncedSearch);
    }, [debouncedSearch]);

    return (
        <TextField
            value={value}
            onChange={e => setValue(e.target.value)}
            placeholder={i18n.search}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => setValue('')} disabled={!landfillSearchStore.search}>
                            <CloseIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
});
