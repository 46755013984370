import React, { PureComponent, ReactNode } from 'react';

import { getBem } from '~/@sochi-components/@bem';
import { SochiDateRangePicker } from '~/@sochi-components/SochiDatePicker';
import { AbilityUserContext, IAbilityUserContext } from '~/contexts';
import { canUpdateProjectDateBeforeToday, canUpdateProjectEndDate, IAbilityProject } from '~/utils/auth';

export type IDates = { startDate: Date; endDate: Date };

export type IMaybeDates = { startDate: Date | null } | { endDate: Date | null };

type ProjectDateRangeProps = {
    onChange: (state: IMaybeDates) => void;
    project?: IAbilityProject | null;
    values: IDates;
    readOnly: boolean;
    className?: string;
    label?: ReactNode;
    errorMessage?: string;
};

export class ProjectDateRange extends PureComponent<ProjectDateRangeProps> {
    static contextType = AbilityUserContext;
    context!: IAbilityUserContext;

    bem = getBem(this);

    render() {
        const { project, values, onChange, label, errorMessage, readOnly } = this.props;
        const { className } = this.bem;
        const canSetDateInPast = canUpdateProjectDateBeforeToday(this.context, project);
        const endDateReadOnly = !!project && !canUpdateProjectEndDate(this.context, project);
        const currentDate = new Date();

        return (
            <SochiDateRangePicker
                className={className}
                onChange={onChange}
                values={{ ...values }}
                minDate={!canSetDateInPast ? currentDate : undefined}
                isEndDateReadOnly={endDateReadOnly || readOnly}
                isStartDateReadOnly={readOnly}
                label={label}
                errorMessage={errorMessage}
            />
        );
    }
}
