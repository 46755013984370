import React from 'react';

import * as queries from '~/@store/account/account.queries';
import { defineAbilitiesFor } from '~/abilities';
import client from '~/apolloClient';
import { MeQuery, MeQuery_me } from '~/graphql';
import { getAuthToken } from '~/utils/auth';

import { AbilityUserContext, emptyAbility, emptyUser } from './AbilityUserContext';

export const AbilityUserLoaderAndProvider: React.FC = ({ children }) => {
    const [isLoading, setLoading] = React.useState(true);
    const [gqlUser, setUser] = React.useState<MeQuery_me | null>(null);

    React.useEffect(() => {
        const token = getAuthToken();

        if (token) {
            client
                .query<MeQuery>({
                    query: queries.MeQuery,
                    fetchPolicy: 'cache-first',
                })
                .then(({ data }) => {
                    setUser(data.me);
                })
                .catch(error => {
                    console.error(error); // eslint-disable-line
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, []);

    const contextValue = React.useMemo(
        () => ({
            ability: gqlUser ? defineAbilitiesFor(gqlUser) : emptyAbility,
            user: gqlUser ? gqlUser : emptyUser,
            setUser,
        }),
        [gqlUser]
    );

    return (
        <AbilityUserContext.Provider value={contextValue}>{isLoading ? null : children}</AbilityUserContext.Provider>
    );
};
