import { getReceiptFileName } from '@common/functions/common.deliveryLine';
import { IconButton } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';

import { makeStyles } from '~/@components/@theme';
import { FilePreviewTooltip } from '~/@components/FilePreview';
import { FileUploadButton } from '~/@components/FileUploadButton';
import { AddIcon, CloseIcon, DocumentStrokeIcon, DownloadIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { showAlertDialog } from '~/@store/common';
import { useDeliveryLineGetReceiptFunc } from '~/@store/deliveryLines';
import { getDeliveryLineFormikName } from '~/@views/AdminView/DeliveryLinesPage/DeliveryLineEditForm/DeliveryLineFormikCommon';
import { showDeliveryLineUpdateDialog } from '~/@views/AdminView/DeliveryLinesPage/DeliveryLineEditForm/DeliveryLineUpdateDialog';
import { downloadReceiptUrl } from '~/apolloClient';
import { ACCEPTED_DOCUMENT_FILE_TYPES } from '~/config/constants';
import i18n from '~/i18n';
import { downloadFile } from '~/utils/files';

import { useDeliveryLinesMassEditFormikContext } from '../DeliveryLinesMassEditFormik';

type Props = {
    index: number;
    canView: boolean;
    canEdit: boolean;
};

export const DeliveryLinesMassEditReceiptCell = ({ index, canView, canEdit }: Props) => {
    const formik = useDeliveryLinesMassEditFormikContext();
    const fieldName = getDeliveryLineFormikName(index, 'receipt');
    const deliveryLine = formik.values.deliveryLines[index];

    const { container, button } = useStyles();

    const deliveryLineId = useMemo(() => deliveryLine?.line.id || '', [deliveryLine]);
    const receiptUrl = useMemo(() => deliveryLine?.receipt.receiptUrl || '', [deliveryLine]);

    const openEditDialog = useCallback(() => {
        if (formik.dirty)
            return showAlertDialog({
                title: <LocalizableText code={'DeliveryLinesTable.viewReceipt'} />,
                render: () => <LocalizableText code={'DeliveryLinesTable.saveBeforeOpenEditDialog'} />,
            });

        return deliveryLine && showDeliveryLineUpdateDialog(deliveryLine.line.id);
    }, [deliveryLine, formik.dirty]);

    const handleDownloadFile = useCallback(() => {
        if (!deliveryLine?.line || !receiptUrl) return;

        const ignoredPromise = downloadFile(
            downloadReceiptUrl,
            { deliveryLineId, filename: receiptUrl },
            getReceiptFileName(deliveryLine.line)!
        );
    }, [deliveryLineId, deliveryLine?.line, receiptUrl]);

    const onFileUpload = useCallback(
        (file: File) => {
            formik.setFieldTouched(fieldName, true);
            formik.setFieldValue(fieldName, { ...deliveryLine?.receipt, file });
        },
        [formik, fieldName, deliveryLine]
    );

    const onFileReset = useCallback(() => {
        formik.setFieldValue(fieldName, formik.initialValues.deliveryLines[index]?.receipt);
    }, [fieldName, formik, index]);

    const loadFunc = useDeliveryLineGetReceiptFunc(deliveryLine?.line!);

    if (!canView) return null;

    return (
        <div className={container}>
            {deliveryLine?.receipt.file ? (
                <>
                    <Text variant="BodyRegular" ellipsis title={deliveryLine.receipt.file.name}>
                        {deliveryLine.receipt.file.name}
                    </Text>
                    <IconButton className={button} onClick={onFileReset} title={i18n.cancel}>
                        <CloseIcon />
                    </IconButton>
                </>
            ) : deliveryLine?.receipt.receiptUrl ? (
                <>
                    <FilePreviewTooltip fileName={receiptUrl} loadFunc={loadFunc}>
                        <IconButton
                            className={button}
                            onClick={openEditDialog}
                            title={i18n.DeliveryLinesTable.viewReceipt}
                            disabled={!canView}>
                            <DocumentStrokeIcon />
                        </IconButton>
                    </FilePreviewTooltip>
                    <IconButton
                        className={button}
                        onClick={handleDownloadFile}
                        title={i18n.DeliveryLinesTable.downloadReceipt}
                        disabled={!canView}>
                        <DownloadIcon />
                    </IconButton>
                </>
            ) : (
                <FileUploadButton
                    className={button}
                    onChange={onFileUpload}
                    acceptFileTypes={ACCEPTED_DOCUMENT_FILE_TYPES}
                    title={i18n.addFile}
                    iconButton
                    disabled={!canEdit}>
                    <AddIcon />
                </FileUploadButton>
            )}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        padding: theme.spacing(0, 2),
    },
    button: {
        width: 28,
        height: 28,
        minWidth: 'unset',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        transition: 'none',
        color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: theme.palette.text.secondary,
            color: theme.palette.action.hover,
        },
    },
}));
