import { action, observable, toJS } from 'mobx';

const LOCALSTORAGE_KEY = 'TableColumnWidths';

export const MIN_WIDTH = 40;

export const MAX_WIDTH = 600;

class TableWidthStore {
    constructor() {
        this.init();
    }

    @observable
    widths: Record<string, number> = {};

    @action
    private init() {
        const localStorageItem = localStorage.getItem(LOCALSTORAGE_KEY);
        if (!localStorageItem) return;

        try {
            this.widths = JSON.parse(localStorageItem);
        } catch (e) {
            console.error(e);
        }
    }

    save() {
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(toJS(this.widths)));
    }

    @action
    setWidth(tableName: string, columnName: string, width: number) {
        if (width < MIN_WIDTH || width > MAX_WIDTH) return;

        this.widths[this.getKey(tableName, columnName)] = width;
    }

    getKey(tableName: string, columnName: string) {
        return tableName + columnName;
    }
}

export const tableWidthStore = new TableWidthStore();
