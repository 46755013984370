import { FormControlLabel, Grid, makeStyles, Radio } from '@material-ui/core';
import cn from 'classnames';
import React, { FC } from 'react';

import { Text } from '~/@components/Text';
import { ILandfillLight } from '~/@store/landfills';
import i18n from '~/i18n';

import { DumpLoadLandfillInfo } from '../DumpLoadLandfillInfo';

type Props = {
    landfill: ILandfillLight;
    onSelect?: (landfill: ILandfillLight) => void;
    selected?: boolean;
    className?: string;
};

export const DumpLoadLandfillDestinationRow: FC<Props> = ({ landfill, onSelect, selected = false, className }) => {
    const { root, selectable } = useStyles();

    return (
        <Grid
            className={cn(root, className, { [selectable]: !!onSelect })}
            onClick={() => (onSelect ? onSelect(landfill) : undefined)}
            container>
            <Grid item xs={onSelect ? 5 : 6}>
                <DumpLoadLandfillInfo landfill={landfill} />
            </Grid>
            <Grid item xs={onSelect ? 5 : 6}>
                <Text>{landfill.location.text}</Text>
            </Grid>
            {onSelect && (
                <Grid item xs={2}>
                    <FormControlLabel
                        control={<Radio checked={selected} value="" />}
                        label={<Text variant="BodyBold">{selected ? i18n.selected : i18n.select}</Text>}
                        disabled={!!landfill.isDeleted}
                    />
                </Grid>
            )}
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    selectable: {
        cursor: 'pointer',
    },
}));
