import { gql } from '@apollo/client';

import { PageInfoFragment } from '~/@store/common/common.queries';

import { ProjectDetailedFragment, ProjectShortFragment } from './projects.queries.fragment';

export const AdminProjectsPagedShortQuery = gql`
    query AdminProjectsPagedShortQuery($filter: ProjectFilter, $sort: SortInput, $connection: ConnectionInput) {
        projectsPaged(filter: $filter, sort: $sort, connection: $connection) {
            edges {
                cursor
                node {
                    ...ProjectShortFragment
                }
            }
            pageInfo {
                ...PageInfoFragment
            }
            pageCount
            totalCount
        }
    }

    ${ProjectShortFragment}
    ${PageInfoFragment}
`;

export const AdminProjectQuery = gql`
    query AdminProjectQuery($id: ID!) {
        project(id: $id) {
            ...ProjectDetailedFragment
        }
    }

    ${ProjectDetailedFragment}
`;

export const AdminProjectSearchQuery = gql`
    query AdminProjectSearchQuery($filter: ProjectFilter, $limit: Int) {
        projectsPaged(filter: $filter, connection: { first: $limit }) {
            edges {
                node {
                    id
                    name
                    ver
                }
            }
        }
    }
`;

export const ProjectQuery = gql`
    query ProjectQuery($id: ID!) {
        project(id: $id) {
            ...ProjectDetailedFragment
        }
    }

    ${ProjectDetailedFragment}
`;

export const ProjectsOffersListQuery = gql`
    query ProjectsOffersListQuery($projectId: ID) {
        projectsOffers(projectId: $projectId) {
            id
            status
            declaration {
                serialNumber
            }
            date
            dumpType {
                id
                name
            }
            signDetails {
                canSendForSign
            }
            project {
                id
                name
                userLinks {
                    userId
                    role
                }
                customerId
                status
                ver
            }
            solidTestResult
        }
    }
`;

export const ProjectOffersCountQuery = gql`
    query ProjectOffersCountQuery {
        projectOffersCount {
            count
        }
    }
`;

export const SendReportQuery = gql`
    query SendReportQuery($email: Email!, $id: ID!) {
        sendProjectReport(email: $email, id: $id)
    }
`;

export const ProjectRemoveMutation = gql`
    mutation ProjectRemoveMutation($id: ID!) {
        projectRemove(id: $id)
    }
`;

export const ProjectRecoverMutation = gql`
    mutation ProjectRecoverMutation($id: ID!) {
        projectRecover(id: $id)
    }
`;

export const ProjectCreateMutation = gql`
    mutation ProjectCreateMutation($input: ProjectCreate!) {
        projectCreate(input: $input) {
            ...ProjectDetailedFragment
        }
    }

    ${ProjectDetailedFragment}
`;

export const ProjectUpdateMutation = gql`
    mutation ProjectUpdateMutation($input: ProjectUpdate!) {
        projectUpdate(input: $input) {
            ...ProjectDetailedFragment
        }
    }

    ${ProjectDetailedFragment}
`;

export const ProjectAddCertificateMutation = gql`
    mutation ProjectAddCertificateMutation($id: ID!, $certificate: Upload!) {
        projectAddCertificate(id: $id, certificate: $certificate)
    }
`;

export const ProjectRemoveCertificateMutation = gql`
    mutation ProjectRemoveCertificateMutation($id: ID!, $fileName: String!) {
        projectRemoveCertificate(id: $id, fileName: $fileName)
    }
`;

export const ProjectTransportScheduleMutation = gql`
    mutation ProjectTransportScheduleMutation($input: ProjectTransportScheduleUpdate!) {
        projectUpdateTransportSchedule(input: $input) {
            ...ProjectDetailedFragment
        }
    }

    ${ProjectDetailedFragment}
`;

export const ProjectAddOrInviteUserMutation = gql`
    mutation ProjectAddOrInviteUser($projectId: ID!, $email: Email!, $role: UserContextRole!) {
        projectAddOrInviteUser(projectId: $projectId, email: $email, role: $role) {
            id
            userLinks {
                userId
                role
            }
            users {
                id
            }
            ver
        }
    }
`;

export const ProjectAddUserMutation = gql`
    mutation ProjectAddUser($projectId: ID!, $userId: ID!, $role: UserContextRole!) {
        projectAddUser(projectId: $projectId, userId: $userId, role: $role) {
            id
            userLinks {
                userId
                role
            }
            users {
                id
            }
            ver
        }
    }
`;

export const ProjectRemoveUserMutation = gql`
    mutation ProjectRemoveUser($projectId: ID!, $userId: ID!) {
        projectRemoveUser(projectId: $projectId, userId: $userId) {
            id
            userLinks {
                userId
                role
            }
            users {
                id
            }
            ver
        }
    }
`;

export const ProjectEnableWhatsappMutation = gql`
    mutation ProjectEnableWhatsapp($projectId: ID!) {
        projectEnableWhatsapp(projectId: $projectId) {
            id
            ver
            whatsappGroupLink
        }
    }
`;
