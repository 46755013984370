import { AbilityCan, AbilitySubjects, ProjectFields, ProjectStatus, UserContextRole, UserRole } from '../../enums';
import type { IAbilityFunction } from '../abilities.types';
import { ICaslCondition } from '../utils';
import { IProjectCalculatedSubject, ProjectSubject } from './projectTypes';

const { owner, ownerUserId, customerId, customer, location, externalId } = ProjectFields;

const { IN_PROGRESS, DONE, DELETED } = ProjectStatus;
const { CRUD, CREATE, READ, UPDATE, UPLOAD, DELETE } = AbilityCan;
const { ADMIN, EMPLOYEE, EXTERNAL_RECEIVER, DRIVER, EXCAVATOR_OPERATOR } = UserRole;
const { PROJECT_USER, GUEST, PROJECT_EXCAVATOR_OPERATOR, PROJECT_DRIVER } = UserContextRole;
const {
    DUMP_LOAD_LIST,
    EXCAVATOR_DUMP_LOAD_LIST,
    PROJECT_DOCUMENT,
    PROJECT_USERS,
    PROJECT_DATES_BEFORE_TODAY,
    PROJECT_END_DATE_EXCLUSIVELY,
    MAINTENANCE,
    ALWAYS_EDITABLE_FIELDS,
    PRICE_CALCULATOR_PAGE,
    OFFERS,
    WHATSAPP,
} = ProjectSubject;

export function defineAbilitiesForProjects(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<IProjectCalculatedSubject>;
    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) => _allow(can, AbilitySubjects.PROJECT, shape);
    const forbid = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _forbid(can, AbilitySubjects.PROJECT, shape);

    allow(CRUD, { relationRole: { $in: [ADMIN, EMPLOYEE] } });
    allow([UPLOAD, DELETE], { relationRole: { $in: [ADMIN, EMPLOYEE] }, subSubject: PROJECT_DOCUMENT });

    // Driver

    allow(READ, { relationRole: PROJECT_DRIVER });
    forbid(READ, { relationRole: PROJECT_DRIVER, subSubject: owner });
    forbid(READ, { relationRole: PROJECT_DRIVER, subSubject: customer });
    forbid(READ, { relationRole: PROJECT_DRIVER, subSubject: externalId });

    // ProjectUser

    allow([READ, UPDATE], { relationRole: PROJECT_USER });
    allow([UPLOAD, DELETE], { relationRole: PROJECT_USER, subSubject: PROJECT_DOCUMENT });
    allow([UPDATE, DELETE], { relationRole: PROJECT_USER, subSubject: PROJECT_USERS });

    // Guest

    allow(READ, { relationRole: GUEST });
    forbid(READ, { relationRole: GUEST, subSubject: customer });
    forbid(READ, { relationRole: GUEST, subSubject: PRICE_CALCULATOR_PAGE });
    forbid(READ, { relationRole: GUEST, subSubject: OFFERS });

    // All external_receivers

    allow(CREATE, { relationRole: EXTERNAL_RECEIVER });

    forbid(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: customer });
    forbid([CREATE, UPDATE], { relationRole: EXTERNAL_RECEIVER, subSubject: customerId });

    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: DELETED });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, subSubject: IN_PROGRESS });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, subSubject: DONE });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, subSubject: WHATSAPP });
    // Allow project users to modify users, comment and invoiceReference in IN_PROGRESS and DONE statuses
    allow(UPDATE, { relationRole: PROJECT_USER, subSubject: { $all: [IN_PROGRESS, ALWAYS_EDITABLE_FIELDS] } });
    allow(UPDATE, { relationRole: PROJECT_USER, subSubject: { $all: [DONE, ALWAYS_EDITABLE_FIELDS] } });
    allow([UPDATE, DELETE], { relationRole: PROJECT_USER, subSubject: { $all: [IN_PROGRESS, PROJECT_USERS] } });
    allow([UPDATE, DELETE], { relationRole: PROJECT_USER, subSubject: { $all: [DONE, PROJECT_USERS] } });

    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: externalId });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: owner });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, subSubject: ownerUserId });
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: DUMP_LOAD_LIST });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, subSubject: PROJECT_DATES_BEFORE_TODAY });

    allow(UPDATE, { relationRole: { $in: [PROJECT_USER] }, subSubject: PROJECT_END_DATE_EXCLUSIVELY });

    // Excavator operators

    allow(READ, { relationRole: PROJECT_EXCAVATOR_OPERATOR });
    forbid(CRUD, { relationRole: EXCAVATOR_OPERATOR, subSubject: PROJECT_DOCUMENT });
    forbid(CRUD, { relationRole: EXCAVATOR_OPERATOR, subSubject: owner });
    forbid(CRUD, { relationRole: EXCAVATOR_OPERATOR, subSubject: customer });
    forbid(CRUD, { relationRole: EXCAVATOR_OPERATOR, subSubject: externalId });

    allow(READ, { relationRole: EXCAVATOR_OPERATOR, subSubject: EXCAVATOR_DUMP_LOAD_LIST });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: EXCAVATOR_DUMP_LOAD_LIST });

    // Forbid location changes for all not in NEW status

    forbid(UPDATE, { subSubject: { $all: [location, IN_PROGRESS] } });
    forbid(UPDATE, { subSubject: { $all: [location, DONE] } });
    forbid(UPDATE, { subSubject: { $all: [location, DELETED] } });

    // Prevent changing a deleted project

    forbid(UPDATE, { subSubject: DELETED });
    forbid([UPLOAD, DELETE], { subSubject: { $all: [PROJECT_DOCUMENT, DELETED] } });

    // Maintenance

    forbid(CRUD, { relationRole: { $in: [EMPLOYEE, EXTERNAL_RECEIVER, DRIVER] }, subSubject: MAINTENANCE });
}
