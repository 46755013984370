import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { TimeSpentIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Page } from '~/@components/Page';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import { Text } from '~/@components/Text';
import { ITimeSpent, useTimeSpent } from '~/@store/timeSpent';
import { TimeSpentFilterSummary } from '~/@views/AdminView/TimeSpentPage/TimeSpentFilterSummary';
import { useTimeSpentColumns } from '~/@views/AdminView/TimeSpentPage/useTimeSpentColumns';
import i18n from '~/i18n';

export const TimeSpentPage = () => {
    const query = useTimeSpent();

    const { table, tableHeader, header } = useStyles();
    const columns = useTimeSpentColumns();

    return (
        <Page>
            <Page.Header className={header}>
                <TimeSpentIcon />
                <Text variant="TitleBold">
                    <LocalizableText code="Admin.timeSpent" />
                </Text>
            </Page.Header>
            <Page.Content>
                <QueryStateHandler
                    query={query}
                    dataGetter={query => query.data?.timeSpentList || query.previousData?.timeSpentList}>
                    {(data: ITimeSpent[]) => (
                        <>
                            <div className={tableHeader}>
                                <TimeSpentFilterSummary />
                            </div>
                            <Table<ITimeSpent>
                                columns={columns}
                                items={data}
                                keyGetter={t => t.id}
                                emptyListMessage={i18n.noDataForFilter}
                                className={table}
                                name="TimeSpentTable"
                            />
                        </>
                    )}
                </QueryStateHandler>
            </Page.Content>
        </Page>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        height: 'calc(100% - 48px)',
    },
    tableHeader: {
        height: 48,
        display: 'flex',
        marginTop: -8,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
}));
