import React, { FC, useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { makeStyles } from '~/@components/@theme';
import { LocalizableText } from '~/@components/LocalizableText';
import { ProjectDeliveryItem } from '~/@views/UserView/ProjectPage/ProjectDashboard/dashboardFilter.store';
import i18n from '~/i18n';
import { getGradientBetweenColors } from '~/utils/color';
import { fmtVolInTon } from '~/utils/numbers';

import { DashboardTitle, DashboardToolTip } from '../common';
import { COLOR } from '../dashboard.theme';

type Props = {
    lines: ProjectDeliveryItem[];
};

type Series = {
    name: string;
    [key: string]: unknown;
};

const getSerialNumberFromKey = (key: string) => key.split('-')[1];

const inboundLabel = i18n.ProjectDashboard.inbound;
const outboundLabel = i18n.ProjectDashboard.outbound;

export const ProjectDumpLoadModeDashboard: FC<Props> = ({ lines }) => {
    const { chart } = useStyles();

    const [series, dataKeys] = useMemo(() => {
        const chartContent = lines.reduce(
            (
                {
                    data,
                    inboundKeys,
                    outboundKeys,
                }: {
                    data: Map<string, Series>;
                    inboundKeys: string[];
                    outboundKeys: string[];
                },
                line
            ) => {
                const label = line.inbound ? inboundLabel : outboundLabel;

                if (!data.has(label)) {
                    data.set(label, {
                        name: label,
                    });
                }

                const curSeries: Series = data.get(label)!;
                const value = line.value;

                const key = `${label}-${line.serialNumber}`;
                if (label === inboundLabel) {
                    if (!inboundKeys.includes(key)) inboundKeys.push(key);
                } else {
                    if (!outboundKeys.includes(key)) outboundKeys.push(key);
                }

                if (curSeries.hasOwnProperty(key)) {
                    curSeries[key] = (curSeries[key] as number) + value;
                } else {
                    curSeries[key] = value;
                }

                return { data, inboundKeys, outboundKeys };
            },
            { data: new Map<string, Series>(), inboundKeys: [], outboundKeys: [] }
        );

        return [
            Array.from(chartContent.data.values()),
            [
                ...chartContent.inboundKeys.sort().map((k, ind) => ({
                    key: k,
                    color: getGradientBetweenColors(
                        COLOR.primaryStartColor,
                        COLOR.primaryEndColor,
                        chartContent.inboundKeys.length === 1 ? 0 : ind / (chartContent.inboundKeys.length - 1)
                    ),
                })),
                ...chartContent.outboundKeys.sort().map((k, ind) => ({
                    key: k,
                    color: getGradientBetweenColors(
                        COLOR.secondaryStartColor,
                        COLOR.secondaryEndColor,
                        chartContent.outboundKeys.length === 1 ? 0 : ind / (chartContent.outboundKeys.length - 1)
                    ),
                })),
            ],
        ];
    }, [lines]);

    return (
        <div className={chart}>
            <DashboardTitle>
                <LocalizableText code={'ProjectDashboard.titleMode'} />
            </DashboardTitle>
            <ResponsiveContainer height={220}>
                <BarChart data={series}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip
                        content={
                            <DashboardToolTip
                                labelFormatter={v =>
                                    `${i18n.ProjectDashboard.serialNumber}: ${getSerialNumberFromKey(v)}`
                                }
                                valueFormatter={fmtVolInTon}
                            />
                        }
                        shared={false}
                    />
                    {dataKeys.map((k, ind) => (
                        <Bar dataKey={k.key} stackId="a" fill={k.color} key={ind} />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    chart: {
        height: 'calc(50vh - 160px)',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        },
    },
}));
