import { SvgIconProps } from '@material-ui/core';
import React, { useMemo } from 'react';

import { PinIcon } from '~/@components/Icon';
import { Tooltip } from '~/@components/Tooltip';
import { DefaultLandfillStatus, LandfillStatusIconColors } from '~/@store/landfillStatuses';

type Props = {
    status: { icon: string; name: string } | null | undefined;
    withTooltip?: boolean;
} & Partial<SvgIconProps>;

export const LandfillStatusIcon = ({ status, withTooltip = true, ...iconProps }: Props) => {
    const { icon, name } = status || DefaultLandfillStatus;

    const style = useMemo(
        () => ({
            color: LandfillStatusIconColors[icon] || LandfillStatusIconColors.iconDisabled,
        }),
        [icon]
    );

    if (!withTooltip) return <PinIcon style={style} {...iconProps} />;

    return (
        <Tooltip title={name}>
            <PinIcon style={style} {...iconProps} />
        </Tooltip>
    );
};
