import { gql } from '@apollo/client';

export const BatchTransferFragment = gql`
    fragment BatchTransferFragment on BatchTransfer {
        id
        date
        type
        fromBatch {
            id
            name
        }
        toBatch {
            id
            name
        }
        weight
        externalProject {
            name
            address
        }
        licensePlate
        comment
    }
`;

export const BatchAnalysisFragment = gql`
    fragment BatchAnalysisFragment on BatchAnalysis {
        id
        date
        analysisResult
        analysisFileUrl
    }
`;

export const BatchLogQuery = gql`
    query BatchLogQuery($landfillId: ID!, $batchId: ID!) {
        batchTransfers(landfillId: $landfillId, batchId: $batchId) {
            ...BatchTransferFragment
        }
        batchAnalyses(landfillId: $landfillId, batchId: $batchId) {
            ...BatchAnalysisFragment
        }
    }

    ${BatchTransferFragment}
    ${BatchAnalysisFragment}
`;

export const CreateBatchTransferMutation = gql`
    mutation CreateBatchTransferMutation($landfillId: ID!, $input: BatchTransferInput!) {
        createBatchTransfer(landfillId: $landfillId, input: $input) {
            ...BatchTransferFragment
        }
    }

    ${BatchTransferFragment}
`;

export const UpdateBatchTransferMutation = gql`
    mutation UpdateBatchTransferMutation($landfillId: ID!, $id: ID!, $input: BatchTransferInput!) {
        updateBatchTransfer(landfillId: $landfillId, id: $id, input: $input) {
            ...BatchTransferFragment
        }
    }

    ${BatchTransferFragment}
`;

export const RemoveBatchTransferMutation = gql`
    mutation RemoveBatchTransferMutation($landfillId: ID!, $id: ID!) {
        deleteBatchTransfer(landfillId: $landfillId, id: $id)
    }
`;

export const BatchTransfersQuery = gql`
    query BatchTransfers($landfillId: ID!) {
        batchTransfers(landfillId: $landfillId) {
            ...BatchTransferFragment
        }
    }

    ${BatchTransferFragment}
`;

export const CreateBatchAnalysisMutation = gql`
    mutation CreateBatchAnalysisMutation($landfillId: ID!, $input: BatchAnalysisInput!) {
        createBatchAnalysis(landfillId: $landfillId, input: $input) {
            ...BatchAnalysisFragment
        }
    }

    ${BatchAnalysisFragment}
`;

export const UpdateBatchAnalysisMutation = gql`
    mutation UpdateBatchAnalysisMutation($landfillId: ID!, $id: ID!, $input: BatchAnalysisInput!) {
        updateBatchAnalysis(landfillId: $landfillId, id: $id, input: $input) {
            ...BatchAnalysisFragment
        }
    }

    ${BatchAnalysisFragment}
`;

export const RemoveBatchAnalysisMutation = gql`
    mutation RemoveBatchAnalysisMutation($landfillId: ID!, $id: ID!) {
        deleteBatchAnalysis(landfillId: $landfillId, id: $id)
    }
`;
