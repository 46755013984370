import { ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { DumpLoadFilterFields, dumpLoadsStore, ToxicAnalysisApprovalState } from '~/@store/dumpLoads';
import i18n from '~/i18n';

const filterValues = [ToxicAnalysisApprovalState.APPROVED_EXTERNALLY, ToxicAnalysisApprovalState.NOT_APPROVED];

export const ToxicAnalysisApprovalStateFilter = () => {
    const { selected } = useStyles();
    const value = useObservable(() => dumpLoadsStore.filter.values.toxicAnalysisApprovalState);
    const handleChange = useCallback(v => {
        dumpLoadsStore.filter.setValue(DumpLoadFilterFields.toxicAnalysisApprovalState, v);
    }, []);

    return (
        <>
            <ListItem button divider onClick={() => handleChange(null)}>
                ---
            </ListItem>
            {filterValues.map(v => (
                <ListItem
                    key={`${v}`}
                    button
                    divider
                    onClick={() => handleChange(v)}
                    className={value === v ? selected : undefined}>
                    <ListItemText>{i18n.DumpLoadsTable.analysisApprovalState[v]}</ListItemText>
                </ListItem>
            ))}
        </>
    );
};

const useStyles = makeStyles(theme => ({
    selected: {
        backgroundColor: theme.palette.background.default,
    },
}));
