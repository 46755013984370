import { IconButton, List } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import type { PopperPlacementType } from '@material-ui/core/Popper/Popper';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode, useCallback, useRef, useState } from 'react';

import { Theme } from '~/@components/@theme';
import { COLORS } from '~/@components/@theme/colors';
import { ArrowDropDownIcon, ArrowDropUpIcon } from '~/@sochi-components/Icons';
import { hexToRgba } from '~/utils/theme';

export enum SochiHeadCellAlignment {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    CENTER = 'CENTER',
}

const FlexRowJustifyContent: Record<SochiHeadCellAlignment, string> = {
    [SochiHeadCellAlignment.LEFT]: 'flex-start',
    [SochiHeadCellAlignment.RIGHT]: 'flex-end',
    [SochiHeadCellAlignment.CENTER]: 'center',
};

const PopperPlacement: Record<SochiHeadCellAlignment, PopperPlacementType> = {
    [SochiHeadCellAlignment.LEFT]: 'bottom-start',
    [SochiHeadCellAlignment.RIGHT]: 'bottom-end',
    [SochiHeadCellAlignment.CENTER]: 'bottom',
};

export type ISochiTableHeadCellProps = {
    title: ReactNode;
    sortDirection?: number | null;
    alignment?: SochiHeadCellAlignment;
    children?: ReactNode;
};

const renderIcon = (sortDirection: number | null | undefined) =>
    (sortDirection || 1) === 1 ? <ArrowDropDownIcon fontSize="inherit" /> : <ArrowDropUpIcon fontSize="inherit" />;

export const SochiTableHeadCell = ({
    title,
    sortDirection,
    children,
    alignment = SochiHeadCellAlignment.RIGHT,
}: ISochiTableHeadCellProps) => {
    const anchorRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    const disabled = !children || (Array.isArray(children) && !children.some(Boolean));

    const { root, titleElement, button, paper, popper, list } = useStyles({
        sortDirection,
        open,
        disabled,
        alignment,
    });

    const showButton = !disabled || !!sortDirection;

    return (
        <div className={root} onClick={!disabled ? handleOpen : undefined} ref={anchorRef}>
            <span className={titleElement}>{title}</span>
            {showButton && <IconButton className={button}>{renderIcon(sortDirection)}</IconButton>}
            {!disabled && (
                <Popper
                    className={popper}
                    open={open}
                    anchorEl={anchorRef.current}
                    transition
                    placement={PopperPlacement[alignment]}
                    modifiers={{
                        flip: {
                            enabled: false,
                        },
                        preventOverflow: {
                            enabled: true,
                        },
                    }}>
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: 'top',
                            }}>
                            <Paper className={paper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <List className={list} disablePadding>
                                        {children}
                                    </List>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </div>
    );
};

const useStyles = makeStyles<
    Theme,
    { sortDirection?: number | null; open: boolean; disabled?: boolean; alignment: SochiHeadCellAlignment }
>(theme => ({
    root: ({ disabled, alignment }) => ({
        position: 'relative',
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: FlexRowJustifyContent[alignment],
        lineHeight: '20px',
        cursor: 'pointer',
        margin: '-22px -16px -22px -4px',
        padding: '22px 16px 22px 4px',
        pointerEvents: disabled ? 'none' : undefined,

        '&:hover $button': {
            backgroundColor: hexToRgba(theme.palette.secondary.contrastText, theme.palette.action.activatedOpacity),
        },

        [theme.breakpoints.down('md')]: {
            margin: '-2px -10px',
            padding: '2px 10px',
        },
    }),
    titleElement: {
        paddingRight: 4,
    },
    button: ({ sortDirection, open, disabled }) => ({
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: open
            ? hexToRgba(theme.palette.secondary.contrastText, theme.palette.action.activatedOpacity)
            : 'transparent',
        color: sortDirection ? COLORS.white : 'currentColor',
        opacity: disabled ? 0.5 : 1,
        width: '1em',
        height: '1em',
        padding: 0,
        pointerEvents: 'none',
        fontSize: '1.4em',
    }),
    paper: {
        width: 250,
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
        fontSize: 'inherit',
    },
    popper: {
        zIndex: 999,
        marginTop: '1px !important',
    },
    list: {
        '& .MuiTypography-root': {
            [theme.breakpoints.down('md')]: {
                fontSize: 14,
            },
        },
        '& .MuiInputBase-root': {
            [theme.breakpoints.down('md')]: {
                fontSize: 14,
            },
        },
        '& .MuiIcon-root': {
            [theme.breakpoints.down('md')]: {
                fontSize: 18,
            },
        },
    },
}));
