import { useQuery } from '@apollo/client';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import { timeSpentStore } from '~/@store/timeSpent/timeSpent.store';
import { TimeSpentQuery, TimeSpentQueryVariables } from '~/graphql';

import * as queries from '../timeSpent.queries';

export const useTimeSpent = () => {
    const variables = useDebouncedObservable(() => timeSpentStore.timeSpentQueryVariables, TIMEOUT_ON_QUERY_DEBOUNCE);

    return useQuery<TimeSpentQuery, TimeSpentQueryVariables>(queries.TimeSpentQuery, {
        variables,
    });
};
