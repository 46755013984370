import { useMutation } from '@apollo/client';

import { BatchLogQuery, RemoveBatchAnalysisMutation } from '~/@user-store/landfillBatches/landfillBatch.queries';
import {
    BatchLogQueryVariables,
    RemoveBatchAnalysisMutation as IRemoveBatchAnalysisMutation,
    RemoveBatchAnalysisMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

export const useBatchAnalysisRemove = (landfillId: string, batchId: string) => {
    const [callMutation, data] = useMutation<IRemoveBatchAnalysisMutation, RemoveBatchAnalysisMutationVariables>(
        RemoveBatchAnalysisMutation
    );

    const refetchVariables: BatchLogQueryVariables = { landfillId, batchId };

    const remove = (id: string) =>
        callMutation({
            variables: { landfillId, id },
            refetchQueries: [{ query: BatchLogQuery, variables: refetchVariables }],
            awaitRefetchQueries: true,
        }).catch(handleApolloError);

    return [remove, data.loading] as const;
};
