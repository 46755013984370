import { LinearProgress, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';

import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { showCustomDialog } from '~/@store/common';
import { useCompanyCreateMutation } from '~/@store/companies';
import { companyDetailRoute } from '~/@store/routes';
import { CompanyCreateFormActions } from '~/@views/AdminView/CompaniesPage/CompanyCreateForm/CompanyCreateFormActions';
import { getCreateCompanyFormikConfig } from '~/@views/AdminView/CompaniesPage/CompanyCreateForm/CompanyCreateFormik';
import { CompanyCreate } from '~/graphql';

import { CompanyCreateFormFields } from './CompanyCreateFormFields';

type CompanyCreateFormProps = {
    onClose: () => void;
};

export const CompanyCreateForm = ({ onClose }: CompanyCreateFormProps) => {
    const { root } = useStyles();
    const history = useHistory();
    const [createCompany, isCreating] = useCompanyCreateMutation();

    const handleCreate = async (input: CompanyCreate) => {
        const company = await createCompany(input);

        if (company) history.push(companyDetailRoute(company.id));
    };

    return (
        <>
            {isCreating && <LinearProgress />}
            <Formik {...getCreateCompanyFormikConfig(handleCreate)}>
                <Form className={root}>
                    <CompanyCreateFormFields />
                    <CompanyCreateFormActions onCancel={onClose} />
                </Form>
            </Formik>
        </>
    );
};

export const showCompanyCreateDialog = () =>
    showCustomDialog({
        render: closeDialog => (
            <CardDialog
                onClose={closeDialog}
                title={<LocalizableText code={'CompaniesTable.createCompany'} />}
                maxWidth={false}>
                <CompanyCreateForm onClose={closeDialog} />
            </CardDialog>
        ),
    });

const useStyles = makeStyles({
    root: {
        width: 440,
    },
});
