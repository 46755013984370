import './SochiRadioButton.scss';

import React from 'react';

import { getBem } from '~/@sochi-components/@bem';

type Props<T> = {
    checked: boolean;
    value: T;
    setValue: (v: T) => void;
    label?: React.ReactNode;
    disabled?: boolean;
};

export class SochiRadioButton<T> extends React.PureComponent<Props<T>> {
    bem = getBem(this);

    render() {
        const { label, setValue, checked, value, disabled } = this.props;
        const { className, element } = this.bem;

        return (
            <div className={className}>
                <input
                    type="radio"
                    className={element('input')}
                    checked={checked}
                    onChange={() => setValue(value)}
                    disabled={disabled}
                />

                {label && (
                    <label onClick={disabled ? undefined : () => setValue(value)} className={element('label')}>
                        {label}
                    </label>
                )}
            </div>
        );
    }
}
