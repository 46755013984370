import { makeStyles } from '@material-ui/core';
import React from 'react';

import { BiSideLayout } from '~/@components/BiSideLayout';
import { Divider } from '~/@components/Divider';
import { ISubstance, IToxicSumRule } from '~/@store/toxicLimits';
import { AddSumRuleButton } from '~/@views/AdminView/ToxicLimitsPage/SumRules/AddSumRuleButton';
import { SumRuleForm } from '~/@views/AdminView/ToxicLimitsPage/SumRules/SumRuleForm';
import { ContaminationType } from '~/graphql';

import { SumRulesList } from './SumRulesList';

type Props = {
    substances: ISubstance[];
    rules: IToxicSumRule[];
};

export const SumRulesTab = ({ substances, rules }: Props) => {
    const { listScroll } = useStyles();
    const [selectedRuleId, setSelectedRuleId] = React.useState<string | null>(null);

    const selectedRule = rules.find(r => r.id === selectedRuleId);

    const filteredSubstances = React.useMemo(
        () => substances.filter(s => s.contaminationTypes.includes(ContaminationType.SOLID)),
        [substances]
    );

    return (
        <BiSideLayout>
            <BiSideLayout.LeftSideContent className={listScroll}>
                <SumRulesList rules={rules} selectedRuleId={selectedRuleId} onSelect={setSelectedRuleId} />
                <Divider marginBottom marginTop />
                <AddSumRuleButton substances={filteredSubstances} />
            </BiSideLayout.LeftSideContent>
            <BiSideLayout.RightSideContent>
                {selectedRule && (
                    <SumRuleForm
                        key={selectedRule.id}
                        sumRule={selectedRule}
                        substances={filteredSubstances}
                        closeForm={() => setSelectedRuleId(null)}
                    />
                )}
            </BiSideLayout.RightSideContent>
        </BiSideLayout>
    );
};

const useStyles = makeStyles({
    listScroll: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 170px)',
        paddingBottom: '8px',
    },
});
