import { useUserAbilities } from '~/contexts';
import { canCreateSubstance, canDeleteSubstance, canReadSubstance, canUpdateSubstance } from '~/utils/auth';

type SubstancePermissions = {
    canRead: boolean;
    canCreate: boolean;
    canUpdate: boolean;
    canDelete: boolean;
};

export const useSubstancePermissions = (): SubstancePermissions => {
    const ability = useUserAbilities();

    return {
        canRead: canReadSubstance(ability),
        canCreate: canCreateSubstance(ability),
        canUpdate: canUpdateSubstance(ability),
        canDelete: canDeleteSubstance(ability),
    };
};
