import { getProjectDumpLoadSubject, IProjectUserLink } from '@common/abilities/projects';
import { TransportScheduleWeekFormat } from '@common/constants';
import { AbilityCan, DumpLoadFields, Granularity, ProjectStatus } from '@common/enums';
import { getDiffWithGranularity, getEachGranularity } from '@common/functions';
import { distributeEvenlyTransportSchedule, getTransportScheduleWeeksArray } from '@common/functions';
import type { IWeekAmount } from '@common/types';
import { isValidAmount } from '@common/validations/common.validation';
import {
    add,
    endOfDay,
    format,
    getISOWeek,
    getISOWeekYear,
    isAfter,
    isBefore,
    isValid,
    max,
    startOfDay,
} from 'date-fns';
import type { IObservableArray } from 'mobx';
import { action, computed, observable, toJS } from 'mobx';

import type { IAbilityUserContext } from '~/contexts';
import {
    LoadStatus,
    ProjectQuery_project_dumpLoads_dumpType,
    ProjectQuery_project_dumpLoads_transportSchedule,
} from '~/graphql';
import i18n from '~/i18n';
import { isBetweenDates, isSameOrAfter, isSameOrBefore, parseDateFrom } from '~/utils/date';

export const COLUMN_NUMBER = 10;

export type IProjectProp = {
    id: string;
    startDate: string | null;
    endDate: string | null;
    userLinks: Array<IProjectUserLink>;
    customerId: string;
    status: ProjectStatus;
};

export type IDumpLoadProp = {
    id: string;
    serialNumber: string;
    dumpType: Pick<ProjectQuery_project_dumpLoads_dumpType, 'id' | 'name' | 'tonnsPerCubicM'> | null;
    amountInTons: number | null;
    date: string | null;
    endDate: string | null;
    status: LoadStatus | null;
    comment: string | null;
    transportSchedule: Array<ProjectQuery_project_dumpLoads_transportSchedule>;
};

export type IScheduleDumpLoad = {
    readOnly: boolean;
    transportSchedule: Array<IWeekAmount>;
    amount: number;
    date: Date;
    endDate: Date;
    name: string;
    comment: string;
    id: string;
    offset: number;
    readonly amountSum: number;
    readonly isValid: boolean;
    declarationNumber?: string | null;
};

export type IScheduleColumn = {
    key: string;
    label: string;
    labelV2: string;
};

function getColumns(firstWeek: Date, lastWeek: Date): Array<IScheduleColumn> {
    return getEachGranularity(
        firstWeek,
        max([lastWeek, add(firstWeek, { weeks: COLUMN_NUMBER - 1 })]),
        Granularity.isoWeek
    ).map(d => ({
        key: format(d, TransportScheduleWeekFormat),
        label: getISOWeek(d).toString(),
        labelV2: `${getISOWeek(d)}-${getISOWeekYear(d)}`,
    }));
}

function getIntervalBoundaries(dumpLoads: Array<IScheduleDumpLoad>): [Date | void, Date | void] {
    const b = dumpLoads.reduce((r, d) => {
        if (d.transportSchedule.length > 0) {
            const start = parseDateFrom(d.transportSchedule[0]!.week);
            const end = parseDateFrom(d.transportSchedule[d.transportSchedule.length - 1]!.week);
            r[0] = r[0] && start && isBefore(r[0], start) ? r[0] : start!;
            r[1] = r[1] && end && isAfter(r[1], end) ? r[1] : end!;
        }

        return r;
    }, [] as Date[]);

    return [b[0], b[1]];
}

function getDumpLoadOffset(dumpLoad: IScheduleDumpLoad, firstWeek: Date): number {
    if (dumpLoad.transportSchedule.length === 0) return 0;
    const date = parseDateFrom(dumpLoad.transportSchedule[0]!.week);
    if (!date) return 0;

    return getDiffWithGranularity(date, firstWeek, Granularity.week);
}

function generateFiller(n: number): Array<null> {
    return [...Array(n)].map(() => null);
}

class ScheduleStore {
    initialLayout: { columns: Array<IScheduleColumn>; offset: number; openedDumpLoad: null | string } = {
        columns: [],
        offset: 0,
        openedDumpLoad: null,
    };

    dumpLoads: IObservableArray<IScheduleDumpLoad> = observable([]);

    @computed
    get editableDumpLoads(): Array<IScheduleDumpLoad> {
        return this.dumpLoads.slice().filter(d => !d.readOnly);
    }

    @observable.ref
    project: IProjectProp | null = null;

    @observable
    projectId: string = '';

    @observable
    layout = { ...this.initialLayout };

    @action
    shiftOffset = (value: 1 | -1) => () => {
        this.layout.offset += value;
    };

    @action
    openDumpLoad = (id: string) => (this.layout.openedDumpLoad = this.layout.openedDumpLoad === id ? null : id);

    @action
    handleDateChange = (dumpLoad: IScheduleDumpLoad, field: string, date: Date) => {
        switch (field) {
            case DumpLoadFields.date:
                dumpLoad.date = date;
                break;
            case DumpLoadFields.endDate:
                dumpLoad.endDate = date;
                break;
            default:
        }
        const newTransportSchedule = getTransportScheduleWeeksArray(dumpLoad.date, dumpLoad.endDate);
        newTransportSchedule.forEach(wa => {
            wa.amount = (dumpLoad.transportSchedule.find(waOld => waOld.week === wa.week) || {}).amount || 0;
        });
        dumpLoad.transportSchedule = newTransportSchedule;
        const [firstWeek, lastWeek] = getIntervalBoundaries(this.dumpLoads);
        if (firstWeek && lastWeek) {
            this.dumpLoads.forEach(d => {
                d.offset = getDumpLoadOffset(d, firstWeek);
            });
            this.layout.columns = getColumns(firstWeek, lastWeek);
        }
    };

    @computed
    get minDate() {
        const startDate = parseDateFrom(this.project?.startDate);
        const date = startDate ? startDate : new Date();

        return startOfDay(date);
    }

    @computed
    get maxDate() {
        const endDate = parseDateFrom(this.project?.endDate);
        const date = endDate ? endDate : add(new Date(), { months: 1 });

        return endOfDay(date);
    }

    isDateOutside =
        (dumpLoad: IScheduleDumpLoad, field: string) =>
        (date: Date | null): boolean => {
            if (!date || !this.minDate || !this.maxDate) return true;
            if (!isBetweenDates(date, this.minDate, this.maxDate)) return true;

            return field === DumpLoadFields.date
                ? isValid(dumpLoad.endDate) && !isSameOrBefore(date, dumpLoad.endDate)
                : isValid(dumpLoad.date) && !isSameOrAfter(date, dumpLoad.date);
        };

    @action
    distributeEvenly = (dumpLoad: IScheduleDumpLoad) => {
        dumpLoad.transportSchedule = distributeEvenlyTransportSchedule(
            dumpLoad.date,
            dumpLoad.endDate,
            dumpLoad.amount
        );
    };

    @computed
    get canSave() {
        return !!this.editableDumpLoads.length && this.editableDumpLoads.slice().every(d => d.isValid);
    }

    @computed
    get canNavigateBack() {
        return this.layout.offset === 0;
    }

    @computed
    get canNavigateAhead() {
        return this.layout.offset >= this.layout.columns.length - COLUMN_NUMBER;
    }

    @computed
    get columnsToPrint() {
        return this.layout.columns.slice(this.layout.offset, this.layout.offset + COLUMN_NUMBER);
    }

    @action
    initializeData = (
        project: IProjectProp,
        dumpLoadsProp: IDumpLoadProp[],
        { ability, user }: IAbilityUserContext
    ) => {
        this.project = project;
        this.projectId = project.id;
        const store = this;
        const dumpLoads: Array<IScheduleDumpLoad> = dumpLoadsProp
            .filter(d => d.status !== LoadStatus.DISCARDED && Boolean(d.dumpType))
            .map(d => ({
                readOnly: !ability.can(
                    AbilityCan.UPDATE,
                    getProjectDumpLoadSubject(user, project, d, [DumpLoadFields.transportSchedule])
                ),
                transportSchedule: d.transportSchedule.map(ts => ({ week: ts.week, amount: ts.amount || 0 })),
                declarationNumber: d.serialNumber,
                amount: Math.ceil(d.amountInTons || 0),
                date: parseDateFrom(d.date)!,
                endDate: parseDateFrom(d.endDate)!,
                name: d.dumpType?.name || i18n.NA,
                comment: d.comment || '',
                id: d.id,
                offset: 0,
                get amountSum() {
                    return toJS(this.transportSchedule).reduce((s, ts) => s + ts.amount, 0);
                },
                get isValid(): boolean {
                    return (
                        this.readOnly ||
                        (this.amountSum === this.amount &&
                            !store.isDateOutside(this, DumpLoadFields.date)(this.date) &&
                            !store.isDateOutside(this, DumpLoadFields.endDate)(this.endDate))
                    );
                },
            }));

        const [firstWeek, lastWeek] = getIntervalBoundaries(dumpLoads);
        if (firstWeek && lastWeek) {
            dumpLoads.forEach(d => {
                d.offset = getDumpLoadOffset(d, firstWeek);
            });
            this.layout.columns = getColumns(firstWeek, lastWeek);
        }

        this.dumpLoads.replace(dumpLoads);
    };

    getDumpLoads = (): Array<IScheduleDumpLoad> => this.editableDumpLoads.map(x => toJS(x));

    public handleDateChangeCurry(dl: IScheduleDumpLoad, field: DumpLoadFields.date | DumpLoadFields.endDate) {
        return (date: Date | null) => {
            if (!date) return;
            if (scheduleStore.isDateOutside(dl, field)(date)) return;

            this.handleDateChange(dl, field, date);
        };
    }

    public getScheduleToPrint(dumpLoadId: string) {
        const dumpLoad = this.dumpLoads.find(d => d.id === dumpLoadId);
        if (!dumpLoad) return [];

        const items = [...generateFiller(dumpLoad.offset), ...dumpLoad.transportSchedule].slice(
            this.layout.offset,
            this.layout.offset + COLUMN_NUMBER
        );

        return items.length === COLUMN_NUMBER ? items : [...items, ...generateFiller(COLUMN_NUMBER - items.length)];
    }

    public getScheduleUpdatePayload() {
        const result = this.dumpLoads.map(({ id, date, endDate, transportSchedule }) => ({
            id,
            date: date.toISOString(),
            endDate: endDate.toISOString(),
            transportSchedule: transportSchedule.map(({ amount, week }) => ({ amount, week })),
        }));

        return toJS(result);
    }

    public isDumpLoadOpen(dumpLoadId: string) {
        return this.layout.openedDumpLoad === dumpLoadId;
    }

    @action
    public updateScheduleAmount(originalSchedule: IWeekAmount, amount: number) {
        if (!isValidAmount(amount)) return;
        originalSchedule.amount = amount;
    }

    @action
    clearData = () => {
        this.projectId = '';
        this.dumpLoads.replace([]);
        this.layout = { ...this.initialLayout };
    };
}

export const scheduleStore = new ScheduleStore();
