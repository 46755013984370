import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ExcelIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path d="M13.616 2H7a2.5 2.5 0 0 0-2.5 2.5v15A2.5 2.5 0 0 0 7 22h10a2.5 2.5 0 0 0 2.5-2.5V7.884c0-.332-.132-.65-.366-.884L14.5 2.366A1.25 1.25 0 0 0 13.616 2Zm.259 4.375v-2.5l3.75 3.75h-2.5a1.25 1.25 0 0 1-1.25-1.25Zm-4.52 3.975L12 13.524l2.645-3.175a.625.625 0 0 1 .96.801l-2.791 3.35 2.791 3.35a.625.625 0 0 1-.96.8L12 15.476l-2.645 3.175a.625.625 0 0 1-.96-.801l2.791-3.35-2.791-3.35a.625.625 0 1 1 .96-.8Z" />
        </SvgIcon>
    );
};
