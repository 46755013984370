import Container from '@material-ui/core/Container';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import { makeStyles } from '~/@components/@theme';
import { Grid } from '~/@components/Grid';

type Props = {
    children: ReactNode;
    className?: string;
};

type HeaderProps = Props & {
    distributedHeader?: boolean;
};

type ContentProps = Props & {
    fullHeight?: boolean;
};

export const Page = ({ children, className }: Props) => {
    const { containerStyle } = useStyles();

    return (
        <Container className={cn(containerStyle, className)} maxWidth={false}>
            <Grid container className={containerStyle}>
                {children}
            </Grid>
        </Container>
    );
};

Page.Header = (({ children, distributedHeader, className }: HeaderProps) => {
    const { headerStyle } = headerUseStyles(!!distributedHeader);

    return (
        <Grid item xs={12} className={cn(headerStyle, className)}>
            {children}
        </Grid>
    );
}) as React.FC<HeaderProps>;

Page.Content = (({ children, className, fullHeight }: ContentProps) => {
    const { contentStyle, fullHeightContentStyle } = useStyles();

    return (
        <Grid
            item
            xs={12}
            className={cn(className, { [contentStyle]: !fullHeight, [fullHeightContentStyle]: !!fullHeight })}>
            {children}
        </Grid>
    );
}) as React.FC<ContentProps>;

const useStyles = makeStyles(theme => ({
    containerStyle: {
        height: '100%',
    },
    contentStyle: {
        height: 'calc(100% - 56px)',
        padding: theme.spacing(0, 0),
    },
    fullHeightContentStyle: {
        height: '100%',
        paddingTop: theme.spacing(1),
    },
}));

const headerUseStyles = makeStyles(theme => ({
    headerStyle: distributedHeader => ({
        height: 56,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: distributedHeader ? 'space-between' : 'inherit',
        padding: theme.spacing(1, 0),
        '& > *': {
            marginRight: 24,
            '&:last-child': {
                marginRight: 0,
            },
        },
    }),
}));
