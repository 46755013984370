import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { Page } from '~/@components/Page';
import { StorePagination } from '~/@components/Pagination';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import { IPagedSubareas, ISubarea, subareasStore, usePagedSubareas } from '~/@store/subareas';
import { LandfillViewModeSelect } from '~/@views/AdminView/LandfillsPage/LandfillViewModeSelect';

import { SubareasFilterSummary } from './SubareasFilterSummary';
import { useSubareasColumns } from './useSubareasColumns';

export const SubareasPage = () => {
    const query = usePagedSubareas();

    const { table, tableHeader } = useStyles();

    const columns = useSubareasColumns();

    return (
        <Page>
            <Page.Header>
                <LandfillViewModeSelect />
            </Page.Header>
            <Page.Content>
                <QueryStateHandler
                    query={query}
                    dataGetter={query =>
                        query.data?.landfillSubareasPaged || query.previousData?.landfillSubareasPaged || undefined
                    }>
                    {(pageData: IPagedSubareas) => (
                        <>
                            <div className={tableHeader}>
                                <SubareasFilterSummary />
                                <StorePagination store={subareasStore.pagination} />
                            </div>
                            <Table<ISubarea>
                                columns={columns}
                                items={pageData.edges.map(e => e.node)}
                                keyGetter={s => s.id}
                                className={table}
                                name="SubareaTable"
                            />
                        </>
                    )}
                </QueryStateHandler>
            </Page.Content>
        </Page>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        height: 'calc(100% - 48px)',
    },
    tableHeader: {
        height: 48,
        display: 'flex',
        marginTop: -8,
    },
}));
