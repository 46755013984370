import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { Page } from '~/@components/Page';
import { StorePagination } from '~/@components/Pagination';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import { useDespatchAdviceResponsesPaged } from '~/@store/beastIntegration/beastIntegration.hooks';
import { IDespatchAdviceResponse } from '~/@store/beastIntegration/beastIntegration.types';
import { despatchAdviceResponsesStore } from '~/@store/beastIntegration/despatchAdviceResponses.store';
import { DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged } from '~/graphql';
import i18n from '~/i18n';

import { DespatchAdviceResponseFilterSummary } from './DespatchAdviceResponseFilterSummary';
import { DespatchAdviceResponseRequestButton } from './DespatchAdviceResponseRequestButton';
import { useDespatchAdviceResponsesColumns } from './useDespatchAdviceResponsesColumns';

export const DespatchAdviceResponsesPage = () => {
    const query = useDespatchAdviceResponsesPaged();

    const { table, tableHeader } = useStyles();
    const columns = useDespatchAdviceResponsesColumns();

    return (
        <Page>
            <Page.Header>
                <DespatchAdviceResponseRequestButton />
            </Page.Header>
            <Page.Content>
                <QueryStateHandler
                    query={query}
                    dataGetter={query =>
                        query.data?.despatchAdviceResponsesPaged || query.previousData?.despatchAdviceResponsesPaged
                    }>
                    {(data: DespatchAdviceResponsesPaged_despatchAdviceResponsesPaged) => (
                        <>
                            <div className={tableHeader}>
                                <DespatchAdviceResponseFilterSummary />
                                <StorePagination store={despatchAdviceResponsesStore.pagination} />
                            </div>
                            <Table<IDespatchAdviceResponse>
                                columns={columns}
                                items={data.edges.map(e => e.node)}
                                keyGetter={o => o.id}
                                emptyListMessage={i18n.noDataForFilter}
                                className={table}
                                name="DespatchAdviceResponsesTable"
                            />
                        </>
                    )}
                </QueryStateHandler>
            </Page.Content>
        </Page>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        height: 'calc(100% - 48px)',
    },
    tableHeader: {
        height: 48,
        display: 'flex',
        marginTop: -8,
    },
}));
