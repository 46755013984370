import { gql } from '@apollo/client/core';

export const LandfillDeliveryEventsQuery = gql`
    query LandfillDeliveryEvents($landfillId: ID!) {
        landfillEvents(landfillId: $landfillId) {
            id
            date
            licensePlate
            photoUrl
            deliveryLine {
                id
            }
        }
    }
`;

export const DeliveryLinesMatchedForEventQuery = gql`
    query DeliveryLinesMatchedForEvent($eventId: ID!) {
        deliveryLinesMatchedForEvent(eventId: $eventId) {
            id
            plannedStartDate
            endDeliveryDate
            orderNumber
            truckRegistrationNumber
            truckCapacity
            weight
            dumpTypeName
            solidTestResult
        }
    }
`;

export const DeleteLandfillDeliveryEventMutation = gql`
    mutation DeleteLandfillDeliveryEvent($id: ID!) {
        landfillDeliveryEventDelete(id: $id)
    }
`;

export const LandfillDeliveryEventAttachToLineMutation = gql`
    mutation LandfillDeliveryEventAttachToLine($id: ID!, $deliveryLineId: ID!) {
        landfillDeliveryEventAttachToLine(id: $id, deliveryLineId: $deliveryLineId) {
            id
            deliveryLine {
                id
            }
        }
    }
`;

export const LandfillDeliveryEventDetachFromLineMutation = gql`
    mutation LandfillDeliveryEventDetachFromLine($deliveryLineId: ID!) {
        landfillDeliveryEventDetachFromLine(deliveryLineId: $deliveryLineId) {
            id
            deliveryLine {
                id
            }
        }
    }
`;
