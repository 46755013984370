import { Tooltip as MUITooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

type Props = {
    title: React.ReactNode;
    children: React.ReactNode;
    disabled?: boolean;
};

export const Tooltip = ({ title, children, disabled = false }: Props) => {
    const { root } = useStyles();

    if (!title) return <span>{children}</span>;

    return (
        <MUITooltip
            title={title}
            disableFocusListener={disabled}
            disableHoverListener={disabled}
            disableTouchListener={disabled}>
            <span className={root}>{children}</span>
        </MUITooltip>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
    },
}));
