import { makeStyles } from '@material-ui/core';
import React from 'react';

import { SimpleDialog } from '~/@components/Dialog';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { showCustomDialog } from '~/@store/common';
import { IDetailedDumpLoad, useDetailedDumpLoad } from '~/@store/dumpLoads';

import { DumpLoadCommentsView } from './DumpLoadCommentsView';

type Props = {
    projectId: string;
    dumpLoadId: string;
    onClose: (v: boolean) => void;
};

export const DumpLoadCommentsDialog = ({ onClose, projectId, dumpLoadId }: Props) => {
    const { commentsView } = useStyles();
    const dumpLoadQuery = useDetailedDumpLoad(projectId, dumpLoadId);

    return (
        <SimpleDialog onClose={() => onClose(true)}>
            <QueryStateHandler query={dumpLoadQuery} dataGetter={query => query.data?.projectDumpload}>
                {(dumpLoad: IDetailedDumpLoad) => {
                    return (
                        <DumpLoadCommentsView
                            projectId={projectId}
                            dumpLoadId={dumpLoadId}
                            comments={dumpLoad.comments || []}
                            className={commentsView}
                        />
                    );
                }}
            </QueryStateHandler>
        </SimpleDialog>
    );
};

const useStyles = makeStyles(() => ({
    commentsView: {
        height: '80vh',
        width: 420,
    },
}));

export const showDumpLoadCommentsDialog = (projectId: string, dumpLoadId: string) =>
    showCustomDialog({
        render: closeDialog => (
            <DumpLoadCommentsDialog onClose={closeDialog} projectId={projectId} dumpLoadId={dumpLoadId} />
        ),
    });
