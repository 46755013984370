import { makeStyles } from '@material-ui/core/styles';
import { FormikContextType } from 'formik';
import React from 'react';

import { FormikSelectField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { useLandfillStatusesList } from '~/@store/landfillStatuses';
import { LandfillStatusIcon } from '~/@views/AdminView/common/LandfillStatusIcon';

import { IStatus, LandfillEditFormValues } from '../LandfillEdit.types';

type Props = { formik: FormikContextType<LandfillEditFormValues> };

export const LandfillEditStatus = ({ formik }: Props) => {
    const query = useLandfillStatusesList();

    const { valueStyle } = useStyles();

    return (
        <QueryStateHandler query={query} dataGetter={query => query.data?.landfillStatuses}>
            {(statuses: IStatus[]) => (
                <FormikSelectField
                    items={statuses}
                    label={<LocalizableText code={'LandfillForm.chooseLandfillStatus'} />}
                    name="status"
                    formik={formik}
                    renderValue={(v: IStatus) => (
                        <div className={valueStyle}>
                            <LandfillStatusIcon withTooltip={false} fontSize="small" status={v} /> {v.name}
                        </div>
                    )}
                    keyGetter={v => v.id}
                    addEmptyOption={false}
                />
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(theme => ({
    valueStyle: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
}));
