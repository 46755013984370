import { normalizeSubstanceAmounts } from '@common/toxic-analysis';
import { ISubstanceAmount } from '@common/toxic-analysis/toxicAnalysis.types';
import { formatString } from '@common/utils';
import { add, isSameDay } from 'date-fns';
import { FormikConfig, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { IBeastMaterial } from '~/@store/beastMaterials';
import { getExactValidator, showConfirmDialog } from '~/@store/common';
import { IDestination, IDetailedDumpLoad, UpdateDumpLoadFunc } from '~/@store/dumpLoads';
import { UpdateDumpLoadDestinationFunc } from '~/@store/dumpLoads/dumpLoads.hooks/useDumpLoadUpdateDestinationMutation';
import { UpdateProjectFunc } from '~/@store/projects';
import { ContaminationLevel, DestinationInput, ProjectLoadInput, UnitOfMeasure } from '~/graphql';
import i18n from '~/i18n';
import { formatDate, isAfterDay, isBeforeDay, parseDateFrom } from '~/utils/date';
import { DumpLoadMode, isSimplifiedDumpLoadEditMode } from '~/utils/dumpLoad';

export type IDumpLoadFormDumpType = {
    id: string;
    name: string;
    tonnsPerCubicM: number | null;
    contaminationLevelAvailable: boolean;
};

export enum DumpLoadEditMode {
    COMMON_FIELDS = 'COMMON_FIELDS',
    DESTINATION = 'DESTINATION',
}

export type IDumpLoadFormValues = {
    dumpLoad: IDetailedDumpLoad;
    dumpType: IDumpLoadFormDumpType | null;
    unitOfMeasure: UnitOfMeasure;
    date: Date | null;
    endDate: Date | null;
    amount: number;
    comment: string;
    stackability: boolean;
    over50cm: boolean;
    substances: ISubstanceAmount[];
    solidState: boolean;
    leachingState: boolean;

    FANumber: string;

    destination: IDestination | null | undefined;
    landfillPrice: number | null;
    internalLandfillPrice: number | null;
    forceZeroPrices: boolean;

    toxicAnalysisApprovedExternally: boolean;
    siteConfirmed: boolean;
    creditCheck: boolean;
    yourOrderNumber: string;
    contractSigned: boolean;
    landfillOrdered: boolean;

    solidContaminationLevel: ContaminationLevel | null;
    editMode: DumpLoadEditMode;

    withTOC: boolean;
    TOCPercent: number | null;
    withInvasiveSpecies: boolean;
    invasiveSpecies: string;
    beastMaterial: IBeastMaterial | null;
    mode: DumpLoadMode;
    matchWithPinpointer: boolean;
    landfillName: string | '';
    landfillAddress: string | '';
    destinationLandfillId: string | null;
};

export const useDumpLoadFormikContext = () => useFormikContext<IDumpLoadFormValues>();

const validateStartDate = (
    initialDate: string | null,
    projectStartDate: string | null | void,
    value: Date | null
): string | null => {
    if (!(value instanceof Date)) return formatString(i18n.isRequired, i18n.startDate);
    const initialDateParsed = parseDateFrom(initialDate);
    if (initialDateParsed && isSameDay(value, initialDateParsed)) return null;
    const projectStartDateParsed = parseDateFrom(projectStartDate);
    if (projectStartDateParsed && isBeforeDay(value, projectStartDateParsed))
        return i18n.errorCodes.MASS_START_DATE_BEFORE_PROJECT_START_DATE;

    return null;
};

const validateEndDate = (initialDate: string | null, startDate: Date | null, value: Date | null): string | null => {
    if (!(value instanceof Date)) return formatString(i18n.isRequired, i18n.endDate);
    if (startDate && isBeforeDay(value, startDate)) return i18n.errorCodes.MASS_END_DATE_BEFORE_MASS_START_DATE;
    const initialDateParsed = parseDateFrom(initialDate);
    if (initialDateParsed && isSameDay(value, initialDateParsed)) return null;

    return null;
};

const shouldUpdateProjectEndDate = (
    endDate: Date | null,
    initialEndDate: string | null,
    projectEndDate: string | null
) => {
    if (!endDate) return false;
    const initialEndDateParsed = parseDateFrom(initialEndDate);
    if (initialEndDateParsed && isSameDay(endDate, initialEndDateParsed)) return false;

    const projectEndDateParsed = parseDateFrom(projectEndDate);

    return !!(projectEndDateParsed && isAfterDay(endDate, projectEndDateParsed));
};

const validateInputType = getExactValidator<ProjectLoadInput>();

export function getDumpLoadFormikConfig(
    dumpLoad: IDetailedDumpLoad,
    updateDumpLoad: UpdateDumpLoadFunc,
    updateProject: UpdateProjectFunc,
    updateDumpLoadDestination: UpdateDumpLoadDestinationFunc,
    canUpdateFieldsAffectsDestination: boolean,
    canUpdatePrices: boolean
): FormikConfig<IDumpLoadFormValues> {
    return {
        initialValues: {
            editMode: DumpLoadEditMode.COMMON_FIELDS,
            dumpLoad,
            substances: [...dumpLoad.substances],
            solidState: dumpLoad?.solidState || false,
            leachingState: dumpLoad?.leachingState || false,
            dumpType: dumpLoad?.dumpType || null,
            amount: dumpLoad?.amount || 0,
            unitOfMeasure: dumpLoad?.unitOfMeasure ?? UnitOfMeasure.TONNS,
            date: dumpLoad?.date ? parseDateFrom(dumpLoad.date) : new Date(),
            endDate: dumpLoad?.endDate ? parseDateFrom(dumpLoad.endDate) : add(new Date(), { months: 1 }),
            stackability: dumpLoad ? dumpLoad.stackability ?? false : true,
            over50cm: dumpLoad?.over50cm ?? false,
            comment: dumpLoad?.comment || '',
            FANumber: dumpLoad?.FANumber || '',

            destination: dumpLoad.destinationInfo,

            landfillPrice: dumpLoad?.priceData.landfillPrice,
            internalLandfillPrice: dumpLoad?.priceData.internalLandfillPrice,
            forceZeroPrices: dumpLoad?.priceData.forceZeroPrices || false,

            toxicAnalysisApprovedExternally: dumpLoad?.toxicAnalysisApprovedExternally || false,
            siteConfirmed: dumpLoad?.siteConfirmed || false,
            creditCheck: dumpLoad?.creditCheck || false,
            yourOrderNumber: dumpLoad?.yourOrderNumber || '',
            contractSigned: dumpLoad?.contractSigned || false,
            landfillOrdered: dumpLoad?.landfillOrdered || false,

            solidContaminationLevel: dumpLoad?.solidTestResult || null,
            withTOC: !!dumpLoad?.withTOC,
            TOCPercent: dumpLoad?.TOCPercent || 0,
            withInvasiveSpecies: !!dumpLoad?.withInvasiveSpecies,
            invasiveSpecies: dumpLoad?.invasiveSpecies || '',

            beastMaterial: dumpLoad?.beastMaterial || null,
            mode: dumpLoad.inbound ? 'inbound' : 'outbound',
            matchWithPinpointer: !dumpLoad.skipMatching,
            landfillName: dumpLoad.suggestedLandfillName || '',
            landfillAddress: dumpLoad.suggestedLandfillAddress || '',
            destinationLandfillId: dumpLoad.destinationInfo?.landfill.id || null,
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            dumpType: Yup.object().nullable().required(formatString(i18n.isRequired, i18n.massCategory)),
            amount: Yup.number().min(1, formatString(i18n.isRequired, i18n.amount)),
            unitOfMeasure: Yup.string().required(formatString(i18n.isRequired, i18n.unit)),
            date: Yup.date()
                .nullable()
                .test('startDate validation', '', function (this, date) {
                    if (isSimplifiedDumpLoadEditMode(this.parent)) return true;

                    const message = validateStartDate(dumpLoad.date, dumpLoad.project?.startDate, date as Date | null);

                    if (message) return this.createError({ message, path: 'date' });

                    return true;
                }),
            endDate: Yup.date()
                .nullable()
                .test('endDate validation', '', function (this, endDate) {
                    if (isSimplifiedDumpLoadEditMode(this.parent)) return true;

                    const message = validateEndDate(dumpLoad.endDate, this.parent.date, endDate as Date | null);

                    if (message) return this.createError({ message, path: 'endDate' });

                    return true;
                }),
            TOCPercent: Yup.number(),
            invasiveSpecies: Yup.string().test(
                'Invasive species validation',
                i18n.errorCodes.INVASIVE_SPECIES_REQUIRED,
                function (invasiveSpecies) {
                    return this.parent.withInvasiveSpecies && !isSimplifiedDumpLoadEditMode(this.parent)
                        ? !!invasiveSpecies
                        : true;
                }
            ),
        }),
        onSubmit: async (values, { resetForm }) => {
            const {
                amount,
                dumpLoad,
                dumpType,
                date,
                endDate,
                substances,
                stackability,
                over50cm,
                landfillPrice,
                internalLandfillPrice,
                solidContaminationLevel,
                editMode,
                destination,
                withTOC,
                TOCPercent,
                withInvasiveSpecies,
                invasiveSpecies,
                beastMaterial,
                matchWithPinpointer,
                destinationLandfillId,
                mode,
                landfillName,
                landfillAddress,
                forceZeroPrices,
                ...restValues
            } = values;

            const ver = dumpLoad?.project?.ver || 0;

            switch (editMode) {
                case DumpLoadEditMode.COMMON_FIELDS:
                    const commonInput: ProjectLoadInput = validateInputType({
                        ver,
                        beastMaterialId: beastMaterial?.id,
                        ...restValues,
                    });

                    if (canUpdateFieldsAffectsDestination) {
                        commonInput.substances = normalizeSubstanceAmounts(
                            substances,
                            restValues.solidState,
                            restValues.leachingState
                        );
                        commonInput.dumpTypeId = values.dumpType?.id;
                        commonInput.stackability = stackability;
                        commonInput.over50cm = over50cm;
                        commonInput.date = date?.toISOString();
                        commonInput.endDate = endDate?.toISOString();
                        commonInput.skipMatching = !matchWithPinpointer;
                        commonInput.withTOC = withTOC;
                        commonInput.TOCPercent = withTOC ? TOCPercent : null;
                        commonInput.withInvasiveSpecies = withInvasiveSpecies;
                        commonInput.invasiveSpecies = withInvasiveSpecies ? invasiveSpecies : null;
                    }

                    if (canUpdatePrices)
                        commonInput.priceData = {
                            landfillPrice: !forceZeroPrices ? landfillPrice || 0 : 0,
                            internalLandfillPrice: !forceZeroPrices ? internalLandfillPrice || 0 : 0,
                            forceZeroPrices,
                        };

                    if (
                        dumpLoad.project &&
                        shouldUpdateProjectEndDate(endDate, dumpLoad.endDate, dumpLoad.project.endDate) &&
                        !isSimplifiedDumpLoadEditMode(values)
                    ) {
                        const confirmed = await showConfirmDialog({
                            title: i18n.dates,
                            render: () =>
                                formatString(
                                    i18n.ProjectAddEditMass.changeProjectDateToMatchMassDate,
                                    endDate ? formatDate(endDate) : ''
                                ),
                        });

                        if (!confirmed) return;

                        const updatedProject = await updateProject({
                            id: dumpLoad.project.id,
                            ver,
                            endDate: commonInput.endDate,
                        });

                        commonInput.ver = updatedProject.ver;
                    }

                    await updateDumpLoad(commonInput);
                    break;
                case DumpLoadEditMode.DESTINATION:
                    const useMatchedDestination = matchWithPinpointer && destination;

                    if (dumpLoad.project && (useMatchedDestination || destinationLandfillId)) {
                        const priceData = {
                            landfillPrice: !forceZeroPrices ? landfillPrice || 0 : 0,
                            internalLandfillPrice: !forceZeroPrices ? internalLandfillPrice || 0 : 0,
                            forceZeroPrices,
                        };

                        const destinationInput: DestinationInput = {
                            projectId: dumpLoad.project.id,
                            dumpLoadId: dumpLoad.id,
                            ver: dumpLoad.project.ver,
                            landfillId: useMatchedDestination ? destination.landfill.id : destinationLandfillId!,
                            subareaId: useMatchedDestination ? destination.subarea?.id : null,
                            priceData,
                        };

                        await updateDumpLoadDestination(destinationInput);
                        break;
                    }
            }

            resetForm();
        },
    };
}
