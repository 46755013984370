import { useMutation } from '@apollo/client';

import { BatchLogQuery, UpdateBatchAnalysisMutation } from '~/@user-store/landfillBatches/landfillBatch.queries';
import {
    BatchAnalysisInput,
    BatchLogQueryVariables,
    UpdateBatchAnalysisMutation as IUpdateBatchAnalysisMutation,
    UpdateBatchAnalysisMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

export const useBatchAnalysisUpdate = (landfillId: string, batchId: string) => {
    const [callMutation, data] = useMutation<IUpdateBatchAnalysisMutation, UpdateBatchAnalysisMutationVariables>(
        UpdateBatchAnalysisMutation
    );

    const refetchVariables: BatchLogQueryVariables = { landfillId, batchId };

    const update = (id: string, input: BatchAnalysisInput) =>
        callMutation({
            variables: { landfillId, id, input },
            refetchQueries: [{ query: BatchLogQuery, variables: refetchVariables }],
            awaitRefetchQueries: true,
        }).catch(handleApolloError);

    return [update, data.loading] as const;
};
