import React from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { ActionButton } from '~/@components/ActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { Page } from '~/@components/Page';
import { landfillsStore } from '~/@store/landfills';

import { showLandfillCreateDialog } from './LandfillEditForm';
import { LandfillsTable } from './LandfillsTable';
import { LandfillViewModeSelect } from './LandfillViewModeSelect';

export const LandfillsPage = () => {
    useSyncEffect(() => landfillsStore.setLandfillsPageViewMode());

    return (
        <Page>
            <Page.Header distributedHeader>
                <LandfillViewModeSelect />
                <ActionButton onClick={showLandfillCreateDialog}>
                    <LocalizableText code={'LandfillForm.createLandfill'} />
                </ActionButton>
            </Page.Header>
            <Page.Content>
                <LandfillsTable />
            </Page.Content>
        </Page>
    );
};
