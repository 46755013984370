import { LocalizationString } from '~/@store/localization/localization.types';
import client from '~/apolloClient';
import {
    Language,
    LocalizationStringsQuery as ILocalizationStringsQuery,
    LocalizationStringsQueryVariables,
    LocalizationStringUpdateMutation as ILocalizationStringUpdateMutation,
    LocalizationStringUpdateMutationVariables,
} from '~/graphql';
import { lang } from '~/i18n';
import { handleApolloError } from '~/utils';

import { LocalizationStringsQuery, LocalizationStringUpdateMutation } from './localization.queries';

export const getLocalizationStrings = async (): Promise<LocalizationString[]> => {
    return client
        .query<ILocalizationStringsQuery, LocalizationStringsQueryVariables>({
            query: LocalizationStringsQuery,
            variables: { language: Language[lang] },
            fetchPolicy: 'network-only',
        })
        .then(result => result.data.localizationStrings)
        .catch(handleApolloError);
};

export const updateLocalizationString = async (
    localizationString: LocalizationString
): Promise<LocalizationString[]> => {
    return client
        .mutate<ILocalizationStringUpdateMutation, LocalizationStringUpdateMutationVariables>({
            mutation: LocalizationStringUpdateMutation,
            variables: {
                input: {
                    language: Language[lang],
                    code: localizationString.code,
                    value: localizationString.value,
                },
            },
        })
        .then(result =>
            result.data?.updateLocalizationString
                ? result.data.updateLocalizationString
                : Promise.reject(new Error('Empty response "LocalizationStringUpdateMutation"'))
        )
        .catch(handleApolloError);
};
