import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { AdminDetailedDumpLoadByNumber, AdminDetailedDumpLoadByNumberVariables } from '~/graphql';

import { AdminDetailedDumpLoadByNumberQuery } from '../dumpLoads.queries';

export const useDetailedDumpLoadByNumber = (
    serialNumber: string
): QueryResult<AdminDetailedDumpLoadByNumber, AdminDetailedDumpLoadByNumberVariables> => {
    const variables: AdminDetailedDumpLoadByNumberVariables = { serialNumber };

    return useQuery<AdminDetailedDumpLoadByNumber, AdminDetailedDumpLoadByNumberVariables>(
        AdminDetailedDumpLoadByNumberQuery,
        {
            fetchPolicy: 'cache-first',
            variables,
        }
    );
};
