import { formatString } from '@common/utils';
import { IconButton } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import { EmailFormatter, NullableFormatter } from '~/@components/Formatters';
import { PhoneFormatter } from '~/@components/Formatters/PhoneFormatter';
import { DeleteIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { ISochiTableColumn, SochiTable } from '~/@sochi-components/SochiTable';
import { SochiHeadCellAlignment } from '~/@sochi-components/SochiTable/SochiTableHeadCell';
import { useProjectRemoveUser } from '~/@store/projects';
import {
    ProjectUsersFilterField,
    projectUsersStore,
} from '~/@views/UserView/ProjectPage/ProjectUsers/projectUsers.store';
import { ProjectUsersTableHeadCell } from '~/@views/UserView/ProjectPage/ProjectUsers/ProjectUsersTableHeadCell';
import { useUserAbilities } from '~/contexts';
import { ProjectQuery_project } from '~/graphql';
import i18n from '~/i18n';
import { showConfirmDialog } from '~/services/dialog';
import { globalMessage } from '~/services/message';
import { canRemoveProjectUser } from '~/utils/auth';

import { ProjectUser } from './ProjectUsers';

type Props = {
    users: ProjectUser[];
    className?: string;
    project: ProjectQuery_project;
};

export const ProjectUsersTable: FC<Props> = ({ users, className, project }) => {
    useEffect(() => {
        projectUsersStore.setList(users);
    }, [users]);

    const items = useObservable(() => projectUsersStore.filteredList);

    const abilities = useUserAbilities();
    const removePermitted = canRemoveProjectUser(abilities, project);

    const canRemove = useCallback(
        (user: ProjectUser) => removePermitted && user.id !== abilities.user.id,
        [removePermitted, abilities.user.id]
    );

    const [removeUser, isRemoving] = useProjectRemoveUser();

    const onDelete = useCallback(
        (user: ProjectUser) => {
            showConfirmDialog({
                title: i18n.remove,
                message: formatString(i18n.ProjectForm.confirmRemoveUser, user.firstName),
            })
                .then(confirmed => (confirmed ? Promise.resolve() : Promise.reject()))
                .then(() => removeUser(project.id, user.id))
                .then(() => {
                    globalMessage.success(i18n.projectUpdatedSuccessfully);
                });
        },
        [removeUser, project]
    );

    const columns = useMemo<ISochiTableColumn<ProjectUser>[]>(
        () => [
            {
                title: (
                    <ProjectUsersTableHeadCell
                        fieldName={ProjectUsersFilterField.firstName}
                        title={<LocalizableText code={'firstName'} />}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: u => u.firstName,
                alignLeft: true,
            },
            {
                title: (
                    <ProjectUsersTableHeadCell
                        fieldName={ProjectUsersFilterField.lastName}
                        title={<LocalizableText code={'surName'} />}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: u => <NullableFormatter>{u.lastName}</NullableFormatter>,
                alignLeft: true,
            },
            {
                title: (
                    <ProjectUsersTableHeadCell
                        fieldName={ProjectUsersFilterField.email}
                        title={<LocalizableText code={'email'} />}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: u => <EmailFormatter>{u.email}</EmailFormatter>,
                alignLeft: true,
            },
            {
                title: (
                    <ProjectUsersTableHeadCell
                        fieldName={ProjectUsersFilterField.phone}
                        title={<LocalizableText code={'phoneNumber'} />}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: u => <PhoneFormatter>{u.phone}</PhoneFormatter>,
                alignLeft: true,
            },
            {
                title: (
                    <ProjectUsersTableHeadCell
                        fieldName={ProjectUsersFilterField.role}
                        title={<LocalizableText code={'role'} />}
                        withFilter
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: u => i18n.UserContextRole[u.role],
                alignLeft: true,
            },
            {
                title: '',
                render: u => (
                    <IconButton onClick={() => onDelete(u)} color="primary" disabled={!canRemove(u) || isRemoving}>
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                ),
            },
        ],
        [onDelete, canRemove, isRemoving]
    );

    return <SochiTable columns={columns} items={items} keyGetter={({ id }) => id} className={className} />;
};
