import { formatKg } from '@common/utils/formaters';
import { observer } from 'mobx-react';
import React, { FC, useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { makeStyles } from '~/@components/@theme';
import { LocalizableText } from '~/@components/LocalizableText';
import { DashboardTitle, SimpleDashboardToolTip } from '~/@views/UserView/ProjectPage/ProjectDashboard/common';
import { COLOR } from '~/@views/UserView/ProjectPage/ProjectDashboard/dashboard.theme';
import { dashboardFilterStore } from '~/@views/UserView/ProjectPage/ProjectDashboard/dashboardFilter.store';
import i18n from '~/i18n';

const trucksLabel = i18n.ProjectDashboard.trucks;
const machinesLabel = i18n.ProjectDashboard.machines;

export const ProjectCO2TotalDashboard: FC = observer(() => {
    const { chart, header } = useStyles();

    const series = useMemo(
        () => [
            {
                label: trucksLabel,
                CO2: dashboardFilterStore.filteredDeliveryItemsByDates.reduce((acc, i) => acc + i.CO2Consumption, 0),
            },
            {
                label: machinesLabel,
                CO2: dashboardFilterStore.filteredTimeSpentItemsByDates.reduce((acc, i) => acc + i.CO2Consumption, 0),
            },
        ],
        [dashboardFilterStore.filteredDeliveryItemsByDates, dashboardFilterStore.filteredTimeSpentItemsByDates]
    );

    return (
        <div className={chart}>
            <div className={header}>
                <DashboardTitle>
                    <LocalizableText code={'ProjectDashboard.titleCO2Total'} />
                </DashboardTitle>
            </div>
            <ResponsiveContainer height={220}>
                <BarChart data={series}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" />
                    <YAxis />
                    <Tooltip
                        content={
                            <SimpleDashboardToolTip
                                labelKey="label"
                                valueKey="CO2"
                                valueLabel="CO2"
                                valueFormatter={formatKg}
                            />
                        }
                        shared={false}
                    />
                    <Bar dataKey="CO2">
                        {series.map(entry => (
                            <Cell
                                fill={entry.label === trucksLabel ? COLOR.primaryStartColor : COLOR.secondaryStartColor}
                            />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    chart: {
        height: 'calc(50vh - 160px)',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
        gap: 8,
    },
}));
