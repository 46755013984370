import { makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo, VFC } from 'react';

import { CloseIcon, DeleteIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { Text } from '~/@components/Text';
import { showConfirmDialog } from '~/@store/common';
import { ILandfill, useLandfillDeleteMutation } from '~/@store/landfills';
import { landfillDetailRoute } from '~/@store/routes';
import { LandfillStatusIcon } from '~/@views/AdminView/common/LandfillStatusIcon';
import i18n from '~/i18n';

type Props = {
    landfill: ILandfill;
};

export const LandfillNameCell: VFC<Props> = ({ landfill }) => {
    const { root } = useStyles();

    const [deleteLandfill, isDeleting] = useLandfillDeleteMutation(landfill);

    const isLandfillDeleted = !!landfill.isDeleted;

    const onDelete = useCallback(
        () =>
            showConfirmDialog({
                title: <LocalizableText code={'LandfillsTable.deleteInfo'} />,
                render: () => <LocalizableText code={'LandfillsTable.deleteConfirmation'} />,
                icon: DeleteIcon,
            }).then(confirmed => {
                if (confirmed) {
                    const ignoredPromise = deleteLandfill();
                }
            }),
        [deleteLandfill]
    );

    const actions = useMemo<ITableCellAction[]>(
        () => [
            {
                variant: ActionVariant.LINK,
                to: landfillDetailRoute(landfill.id),
                targetBlank: true,
            },
            {
                variant: ActionVariant.COPY,
                content: landfill.name,
            },
            {
                variant: ActionVariant.DELETE,
                onClick: onDelete,
                disabled: isDeleting || isLandfillDeleted,
                disabledReason: isLandfillDeleted ? i18n.LandfillsTable.landfillDeletedMessage : undefined,
            },
        ],
        [landfill, isDeleting, onDelete, isLandfillDeleted]
    );

    return (
        <TableCellActions actions={actions}>
            <div className={root}>
                {landfill.isDeleted ? (
                    <CloseIcon color="disabled" />
                ) : (
                    <LandfillStatusIcon status={landfill.status} withTooltip={false} />
                )}
                <Text color={landfill.isDeleted ? 'textSecondary' : 'textPrimary'}>{landfill.name}</Text>
            </div>
        </TableCellActions>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
}));
