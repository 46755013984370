import { FormikContextType } from 'formik';
import React, { FC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { FormikAsyncAutocompleteField, FormikBooleanField, FormikNumberField } from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { ICompanySearchRecord, useCompaniesSearchFunc } from '~/@store/companies';
import { IDespatchAdviceSettingsFormValues } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm/DumpLoadPeppolSettingsDialog/DumpLoadPeppolSettingsFormik';
import { commonPrecisions } from '~/config/enum';

type Props = {
    formik: FormikContextType<IDespatchAdviceSettingsFormValues>;
    disabled?: boolean;
};

export const DumpLoadPeppolSettingsFields: FC<Props> = ({ formik, disabled = false }) => {
    const companySearch = useCompaniesSearchFunc(false);

    const { despatchParty } = useStyles();

    const isPriceMandatory = formik.values.isPriceMandatory;
    const gridSize = isPriceMandatory ? 5 : 6;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormikBooleanField
                    name={'isActive'}
                    formik={formik}
                    label={<LocalizableText code={`DespatchAdviceCategory.${formik.values.category}`} />}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={gridSize}>
                <FormikAsyncAutocompleteField
                    label={<LocalizableText code={'DespatchAdviceSettingsDialog.consignee'} />}
                    name={'consignee'}
                    formik={formik}
                    loadOptions={companySearch}
                    renderOption={(company: ICompanySearchRecord) => company.name}
                    disabled={disabled || !formik.values.allowSelect || !formik.values.isActive}
                />
            </Grid>
            <Grid item xs={gridSize}>
                <div className={despatchParty}>
                    <FormikAsyncAutocompleteField
                        label={<LocalizableText code={'DespatchAdviceSettingsDialog.despatchParty'} />}
                        name={'despatchParty'}
                        formik={formik}
                        loadOptions={companySearch}
                        renderOption={(company: ICompanySearchRecord) => company.name}
                        disabled={disabled || !formik.values.allowSelect || !formik.values.isActive}
                    />
                    {formik.values.allowSelect && (
                        <LabelWithHint
                            label=""
                            hint={<LocalizableText code={'DespatchAdviceSettingsDialog.despatchPartyNote'} />}
                        />
                    )}
                </div>
            </Grid>
            {isPriceMandatory && (
                <Grid item xs={2}>
                    <FormikNumberField
                        label={<LocalizableText code={'DespatchAdviceSettingsDialog.pricePerTon'} />}
                        name={'pricePerTon'}
                        formik={formik}
                        disabled={disabled || !formik.values.isActive}
                        precision={commonPrecisions.price}
                    />
                </Grid>
            )}
            <Grid item xs={gridSize}>
                <FormikAsyncAutocompleteField
                    label={<LocalizableText code={'DespatchAdviceSettingsDialog.buyer'} />}
                    name={'buyer'}
                    formik={formik}
                    loadOptions={companySearch}
                    renderOption={(company: ICompanySearchRecord) => company.name}
                    disabled={disabled || !formik.values.isActive}
                />
            </Grid>
            <Grid item xs={gridSize}>
                <FormikAsyncAutocompleteField
                    label={<LocalizableText code={'DespatchAdviceSettingsDialog.seller'} />}
                    name={'seller'}
                    formik={formik}
                    loadOptions={companySearch}
                    renderOption={(company: ICompanySearchRecord) => company.name}
                    disabled={disabled || !formik.values.isActive}
                />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    despatchParty: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'center',
    },
}));
