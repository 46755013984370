import React from 'react';

import { NullableFormatter } from '~/@components/Formatters/NullableFormatter';
import { getUserFullName, IUserInfo } from '~/utils/user';

interface Props {
    children: IUserInfo | null | void;
}

export const UserFormatter = ({ children }: Props) => {
    return <NullableFormatter>{children ? getUserFullName(children) : null}</NullableFormatter>;
};
