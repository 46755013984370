import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { useUserAbilities } from '~/contexts';
import { AccountUpdateMutation, AccountUpdateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../account.queries';
import { AccountUpdateFunc } from '../types';

export const useAccountUpdateMutation = (): [AccountUpdateFunc, boolean] => {
    const { setUser } = useUserAbilities();

    const [callMutation, mutationResult] = useMutation<AccountUpdateMutation, AccountUpdateMutationVariables>(
        queries.AccountUpdateMutation
    );

    const saveAccount = useCallback<AccountUpdateFunc>(
        input =>
            callMutation({
                variables: { input },
                refetchQueries: [{ query: queries.MeQuery }],
                awaitRefetchQueries: true,
            })
                .catch(handleApolloError)
                .then(result => {
                    const profile = result.data?.selfUpdateProfile;
                    if (profile) {
                        setUser(profile);

                        return profile;
                    } else {
                        return Promise.reject(new Error('Profile update empty response'));
                    }
                }),
        [callMutation, setUser]
    );

    return [saveAccount, mutationResult.loading];
};
