import React from 'react';

import { ErrorBoundary } from '~/@components/ErrorBoundary';

import { MapInput as MapInputInternal, Props } from './MapInput';

export const MapInput: React.VFC<Props> = props => (
    <ErrorBoundary name="MapInput">
        <MapInputInternal {...props} />
    </ErrorBoundary>
);
