import { hasConnectedEntities } from '@common/functions';
import React, { useCallback, useMemo } from 'react';

import { showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { ICompany, useDeleteCompanyMutation, useGetConnectedActiveEntities } from '~/@store/companies';
import { companyDetailRoute } from '~/@store/routes';

import { showCompanyConnectedEntitiesDialog } from './CompanyConnectedEntities';

type Props = {
    company: ICompany;
};

export const CompanyNameCell = ({ company }: Props) => {
    const [deleteCompany, isDeleting] = useDeleteCompanyMutation();
    const [isLoading, getEntities] = useGetConnectedActiveEntities();

    const onDelete = useCallback(() => {
        getEntities(company.id).then(data => {
            if (hasConnectedEntities(data)) {
                showCompanyConnectedEntitiesDialog(company, data);
            } else {
                showDeleteConfirmationDialog(
                    <LocalizableText code={'CompaniesTable.deleteConfirmation'} />,
                    company.name
                ).then(confirmed => {
                    if (confirmed) {
                        const ignoredPromise = deleteCompany(company.id);
                    }
                });
            }
        });
    }, [company, deleteCompany, getEntities]);

    const actions: Array<ITableCellAction> = useMemo(
        () => [
            {
                variant: ActionVariant.LINK,
                to: companyDetailRoute(company.id),
            },
            {
                variant: ActionVariant.COPY,
                content: company.name,
            },
            {
                variant: ActionVariant.DELETE,
                onClick: onDelete,
                disabled: isDeleting || isLoading,
            },
        ],
        [company, onDelete, isDeleting, isLoading]
    );

    return <TableCellActions actions={actions}>{company.name}</TableCellActions>;
};
