import { useMutation } from '@apollo/client';

import {
    DeleteLandfillDeliveryEvent,
    DeleteLandfillDeliveryEventVariables,
    LandfillDeliveryEventsVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import { DeleteLandfillDeliveryEventMutation, LandfillDeliveryEventsQuery } from './landfillDeliveryEvents.queries';

export const useRemoveDeliveryEvent = (landfillId: string) => {
    const [callMutation, data] = useMutation<DeleteLandfillDeliveryEvent, DeleteLandfillDeliveryEventVariables>(
        DeleteLandfillDeliveryEventMutation
    );

    const refetchVariables: LandfillDeliveryEventsVariables = { landfillId };

    const remove = (eventId: string) =>
        callMutation({
            variables: { id: eventId },
            refetchQueries: [{ query: LandfillDeliveryEventsQuery, variables: refetchVariables }],
            awaitRefetchQueries: true,
        }).catch(handleApolloError);

    return [remove, data.loading] as const;
};
