import { useMutation } from '@apollo/client';

import { VehicleTypeInput, VehicleTypeUpdate, VehicleTypeUpdateVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import { VehicleTypesQuery, VehicleTypeUpdateMutation } from '../vehicleTypes.queries';
import { VehicleType } from '../vehicleTypes.types';

export const useVehicleTypeUpdate = (id: string) => {
    const [callMutation, { loading }] = useMutation<VehicleTypeUpdate, VehicleTypeUpdateVariables>(
        VehicleTypeUpdateMutation,
        {
            refetchQueries: [{ query: VehicleTypesQuery }],
            awaitRefetchQueries: true,
        }
    );

    const updateVehicleType = (input: VehicleTypeInput): Promise<VehicleType> =>
        callMutation({ variables: { id, input } })
            .then(res => res.data!.vehicleTypeUpdate)
            .catch(handleApolloError);

    return [updateVehicleType, loading] as const;
};
