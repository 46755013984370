import { adminRoutes } from '@common/routes';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { MenuGrow } from '~/@components/MenuGrow';
import { LANDFILL_VIEW_MODE, landfillsStore } from '~/@store/landfills';
import i18n from '~/i18n';

interface IViewModeOption {
    value: LANDFILL_VIEW_MODE;
    title: string;
    path: string;
}

export const VIEW_MODE_OPTIONS: IViewModeOption[] = [
    {
        value: LANDFILL_VIEW_MODE.ALL_LANDFILLS,
        title: i18n.Admin.landfillsPage.allLandfills,
        path: adminRoutes.allLandfills,
    },
    {
        value: LANDFILL_VIEW_MODE.ACTIVE_LANDFILLS,
        title: i18n.Admin.landfillsPage.activeLandfills,
        path: adminRoutes.activeLandfills,
    },
    {
        value: LANDFILL_VIEW_MODE.SUBAREAS,
        title: i18n.Admin.landfillsPage.subareas,
        path: adminRoutes.subareas,
    },
];

const renderValue = (viewModeOption: IViewModeOption) => viewModeOption.title;

export const LandfillViewModeSelect: React.FC = observer(() => {
    const history = useHistory();
    const viewMode = landfillsStore.viewMode;

    const handleChange = useCallback((viewModeOption: IViewModeOption) => history.push(viewModeOption.path), [history]);

    const value = VIEW_MODE_OPTIONS.find(m => m.value === viewMode);

    return (
        <MenuGrow<IViewModeOption>
            value={value}
            onChange={handleChange}
            items={VIEW_MODE_OPTIONS}
            renderValue={renderValue}
        />
    );
});
