import { FormGroup, makeStyles } from '@material-ui/core';
import React from 'react';

import { TextField } from '~/@components/TextField';
import i18n from '~/i18n';

type Props = {
    value: string | number;
    label: React.ReactNode;
    readOnly?: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    multiline?: boolean;
};

export const ConfigurationField = ({ value, onChange, label, readOnly, multiline }: Props) => {
    const { input, group } = useStyles();

    return (
        <FormGroup className={group}>
            <TextField
                disabled={readOnly}
                label={label}
                className={input}
                value={value}
                placeholder={i18n.NA}
                onChange={onChange}
                multiline={multiline}
            />
        </FormGroup>
    );
};

export const useStyles = makeStyles(theme => ({
    input: {
        margin: theme.spacing(1.5, 0),
    },

    group: {
        paddingBottom: 10,
    },
}));
