import React from 'react';

import { canManageDatabase } from '~/@store/database';
import { GenerateFakeFilesButton } from '~/@views/AdminView/SettingsPage/Database/GenerateFakeFilesButton';

export const TestOnly = () => {
    if (!canManageDatabase) return null;

    return (
        <>
            <GenerateFakeFilesButton />
        </>
    );
};
