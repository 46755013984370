import React, { useCallback, useMemo, VFC } from 'react';
import { string } from 'yup';

import { ActionButton } from '~/@components/ActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { useAddToBlacklistMutation } from '~/@store/blacklist';
import { showCustomDialog } from '~/@store/common';
import { PopupTextEdit } from '~/@views/AdminView/common/PopupEditFields';
import { useUserAbilities } from '~/contexts';
import { canCreateUser } from '~/utils/auth';

const validateEmail = (value: string): boolean => string().email().required().isValidSync(value);

export const AddToBlacklistButton: VFC = () => {
    const ability = useUserAbilities();
    const canAddToBlacklist = useMemo(() => canCreateUser(ability), [ability]);

    const [submit, isSubmitting] = useAddToBlacklistMutation();

    const handleClick = useCallback(
        () =>
            showCustomDialog({
                render: closeDialog => (
                    <PopupTextEdit
                        label={<LocalizableText code={'BlacklistPage.email'} />}
                        initialValue=""
                        onSubmit={submit}
                        onClose={closeDialog}
                        validation={validateEmail}
                    />
                ),
            }),
        [submit]
    );

    return (
        <ActionButton onClick={handleClick} disabled={!canAddToBlacklist || isSubmitting}>
            <LocalizableText code={'BlacklistPage.addToBlacklist'} />
        </ActionButton>
    );
};
