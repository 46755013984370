import React from 'react';

import { ProgressButton } from '~/@components/Button/ProgressButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { showAlertDialog, showConfirmDialog } from '~/@store/common';
import {
    recalculateLandfillSolidTestResults,
    recalculateProjectSolidTestResults,
    useDatabasePermissions,
} from '~/@store/database';

export const RecalculateSolidTestResults = () => {
    const { canMaintenanceProjects, canMaintenanceLandfills } = useDatabasePermissions();

    const onRecalculateProjects = async () => {
        const confirmed = await showConfirmDialog({
            title: <LocalizableText code={'settingsPage.database.recalculateProjectsSolidTestResults'} />,
            render: () => <LocalizableText code={'settingsPage.database.recalculateProjectsSolidTestResultsConfirm'} />,
        });

        if (!confirmed) return;

        const { processed, modified } = await recalculateProjectSolidTestResults();

        return showAlertDialog({
            title: <LocalizableText code={'settingsPage.database.recalculateProjectsSolidTestResults'} />,
            render: () => (
                <div>
                    <Text>processed: {processed}</Text>
                    <Text>modified: {modified}</Text>
                </div>
            ),
        });
    };

    const onRecalculateLandfills = async () => {
        const confirmed = await showConfirmDialog({
            title: <LocalizableText code={'settingsPage.database.recalculateLandfillsSolidTestResults'} />,
            render: () => (
                <LocalizableText code={'settingsPage.database.recalculateLandfillsSolidTestResultsConfirm'} />
            ),
        });

        if (!confirmed) return;

        const { processed, modified } = await recalculateLandfillSolidTestResults();

        return showAlertDialog({
            title: <LocalizableText code={'settingsPage.database.recalculateLandfillsSolidTestResults'} />,
            render: () => (
                <div>
                    <Text>processed: {processed}</Text>
                    <Text>modified: {modified}</Text>
                </div>
            ),
        });
    };

    return (
        <>
            <ProgressButton
                variant="contained"
                color="primary"
                disabled={!canMaintenanceProjects}
                onClick={onRecalculateProjects}>
                <LocalizableText code={'settingsPage.database.recalculateProjectsSolidTestResults'} />
            </ProgressButton>
            <ProgressButton
                variant="contained"
                color="primary"
                disabled={!canMaintenanceLandfills}
                onClick={onRecalculateLandfills}>
                <LocalizableText code={'settingsPage.database.recalculateLandfillsSolidTestResults'} />
            </ProgressButton>
        </>
    );
};
