import React, { useEffect } from 'react';

import { makeStyles } from '~/@components/@theme';
import { Page } from '~/@components/Page';
import { StorePagination } from '~/@components/Pagination';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import {
    DELIVERY_LINE_VIEW_MODE,
    deliveryLinesStore,
    IDeliveryLine,
    IDeliveryLinePaged,
    usePagedDeliveryLines,
} from '~/@store/deliveryLines';
import i18n from '~/i18n';

import { DeliveryLineAddButton } from './DeliveryLineAddButton';
import { DeliveryLineModeSwitch } from './DeliveryLineModeSwitch';
import { DeliveryLinesFilterSummary } from './DeliveryLinesFilterSummary';
import { DeliveryLinesPageButtons } from './DeliveryLinesPageButtons';
import { useDeliveryLinesColumns } from './useDeliveryLinesColumns';

export const DeliveryLinesPage = () => {
    const query = usePagedDeliveryLines();

    const { table, tableHeader, header, leftBlock } = useStyles();
    const columns = useDeliveryLinesColumns();

    useEffect(() => {
        return () => {
            deliveryLinesStore.setOrderNumber(null);
            deliveryLinesStore.setMode(DELIVERY_LINE_VIEW_MODE.DELIVERY_LINES);
        };
    }, []);

    return (
        <Page>
            <Page.Header className={header}>
                <div className={leftBlock}>
                    <DeliveryLineModeSwitch />
                    <DeliveryLinesPageButtons />
                </div>
                <DeliveryLineAddButton />
            </Page.Header>
            <Page.Content>
                <QueryStateHandler
                    query={query}
                    dataGetter={query => query.data?.deliveryLinesPaged || query.previousData?.deliveryLinesPaged}>
                    {(data: IDeliveryLinePaged) => (
                        <>
                            <div className={tableHeader}>
                                <DeliveryLinesFilterSummary />
                                <StorePagination store={deliveryLinesStore.pagination} />
                            </div>
                            <Table<IDeliveryLine>
                                columns={columns}
                                items={data.edges.map(e => e.node)}
                                keyGetter={o => o.id}
                                emptyListMessage={i18n.noDataForFilter}
                                className={table}
                                name="DeliveryLinesTable"
                            />
                        </>
                    )}
                </QueryStateHandler>
            </Page.Content>
        </Page>
    );
};

const useStyles = makeStyles(theme => ({
    table: {
        height: 'calc(100% - 48px)',
    },
    tableHeader: {
        height: 48,
        display: 'flex',
        marginTop: -8,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    leftBlock: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(3),
    },
}));
