import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
    DumpTypesListQuery_dumpTypes,
    DumpTypesListQueryVariables,
    DumpTypeUpdatePositionMutation,
    DumpTypeUpdatePositionMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../dumpType.queries';

export type UpdateDumpTypePositionFunc = (input: DumpTypesListQuery_dumpTypes[]) => Promise<unknown>;

export const useDumpTypeUpdatePositionMutation = (
    listQueryVariables: DumpTypesListQueryVariables = {}
): [UpdateDumpTypePositionFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        DumpTypeUpdatePositionMutation,
        DumpTypeUpdatePositionMutationVariables
    >(queries.DumpTypeUpdatePositionMutation);

    const updateDumpTypePosition = useCallback<UpdateDumpTypePositionFunc>(
        input => {
            const items = input.map(({ id }, idx) => ({ id, position: idx }));

            return callMutation({
                variables: { items },
                refetchQueries: [
                    {
                        query: queries.DumpTypesListQuery,
                        variables: listQueryVariables,
                    },
                ],
                awaitRefetchQueries: true,
            }).catch(handleApolloError);
        },
        [callMutation, listQueryVariables]
    );

    return [updateDumpTypePosition, mutationResult.loading];
};
