import { action, observable } from 'mobx';

class LandfillSearchStore {
    @observable
    search: string = '';

    @action
    setSearch(search: string) {
        this.search = search;
    }

    @action
    clearSearch() {
        this.search = '';
    }
}

export const landfillSearchStore = new LandfillSearchStore();
