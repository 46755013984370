import { LinearProgress, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { VFC } from 'react';
import { useHistory } from 'react-router';

import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { showCustomDialog } from '~/@store/common';
import { userDetailRoute } from '~/@store/routes';
import { useUserCreateMutation } from '~/@store/users';
import { UserCreate } from '~/graphql';

import { UserCreateFormActions } from './UserCreateFormActions';
import { UserCreateFormFields } from './UserCreateFormFields';
import { getCreateUserFormikConfig, IUserCreateFormValues } from './UserCreateFormik';

type UserCreateFormProps = {
    onClose: () => void;
    defaultValues?: Partial<IUserCreateFormValues> | null;
};

export const UserCreateForm: VFC<UserCreateFormProps> = ({ onClose, defaultValues }) => {
    const { root } = useStyles();
    const history = useHistory();
    const [createUser, isCreating] = useUserCreateMutation();

    const handleCreate = async (input: UserCreate) => {
        const user = await createUser(input);

        if (user) history.push(userDetailRoute(user.id));
    };

    return (
        <>
            {isCreating && <LinearProgress />}
            <Formik {...getCreateUserFormikConfig(handleCreate, defaultValues)}>
                <Form className={root}>
                    <UserCreateFormFields />
                    <UserCreateFormActions onCancel={onClose} />
                </Form>
            </Formik>
        </>
    );
};

export const showUserCreateDialog = (defaultValues: Partial<IUserCreateFormValues> | null = null) =>
    showCustomDialog({
        render: closeDialog => (
            <CardDialog onClose={closeDialog} title={<LocalizableText code={'UsersTable.addUser'} />} maxWidth={false}>
                <UserCreateForm onClose={closeDialog} defaultValues={defaultValues} />
            </CardDialog>
        ),
    });

const useStyles = makeStyles({
    root: {
        width: 440,
    },
});
