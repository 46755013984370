import { sortBy } from 'lodash';

import { ISubstance, ISubstanceChild } from './substances.types';

export const isSubstanceParentWithChildren = (v: ISubstance | ISubstanceChild): v is ISubstance => {
    return v.__typename === 'Substance' && !!v.children && v.children.length > 0;
};

export const isSubstanceChild = (v: ISubstance | ISubstanceChild): v is ISubstanceChild => {
    return v.__typename === 'SubstanceChild';
};

export const sortSubstancesAndChildrenByPosition = (substances: ISubstance[]) =>
    sortBy(substances, s => s.position).map(substance => ({
        ...substance,
        children: sortBy(substance.children, s => s.position),
    }));

export const flattenSubstancesWithoutParents = (substances: ISubstance[]): Array<ISubstance | ISubstanceChild> =>
    substances.reduce<Array<ISubstance | ISubstanceChild>>((acc, s) => {
        if (s.children?.length) {
            acc.push(...s.children);
        } else {
            acc.push(s);
        }

        return acc;
    }, []);
