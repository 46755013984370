import './TransportReceiptDialog.scss';

import { format } from 'date-fns';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { TextField } from '~/@components/TextField';
import { getBem } from '~/@sochi-components/@bem';
import { downloadPdfReceiptUrl, downloadReceiptUrl } from '~/apolloClient';
import i18n from '~/i18n';
import { parseDateFrom } from '~/utils/date';

import { DocumentTypes } from '../../../../common/enums';
import { isPdf } from '../../../../common/utils';
import { DocumentPreview } from '../DocumentPreview';

export type DeliveryLineInfo = {
    id: string;
    receiptUrl: string | null;
    plannedStartDate: string;
    serialNumber: string;
    truckRegistrationNumber: string;
    weight: number;
};

type TransportReceiptDialogProps = {
    closeDialog: () => void;
    transportedData: DeliveryLineInfo;
    documentType: DocumentTypes;
};

export class TransportReceiptDialog extends React.Component<TransportReceiptDialogProps> {
    bem = getBem(this);

    get transportDate() {
        const { transportedData } = this.props;

        const plannedStartDateParsed = parseDateFrom(transportedData.plannedStartDate);

        return plannedStartDateParsed ? format(plannedStartDateParsed, i18n.dateFormat) : '';
    }

    render() {
        const { transportedData, documentType } = this.props;
        const { className, element } = this.bem;

        const filename = transportedData.receiptUrl || '';
        const isPdfFile = isPdf(filename);

        return (
            <div className={className}>
                <DocumentPreview
                    className={element('image', { 'with-pdf': isPdfFile })}
                    downloadUrl={isPdfFile ? downloadPdfReceiptUrl : downloadReceiptUrl}
                    params={{
                        deliveryLineId: transportedData.id,
                        filename,
                        documentType,
                    }}
                    filename={filename}
                />
                <div className={element('information')}>
                    <div className={element('title')}>{<LocalizableText code={'ReceiptModal.scaleReceiptScan'} />}</div>

                    <TextField
                        label={<LocalizableText code={'ReceiptModal.orderNumber'} />}
                        value={transportedData.serialNumber || ''}
                        disabled
                    />
                    <TextField
                        label={<LocalizableText code={'ReceiptModal.regNumber'} />}
                        value={transportedData.truckRegistrationNumber || ''}
                        disabled
                    />
                    <TextField
                        label={<LocalizableText code={'ReceiptModal.date'} />}
                        value={this.transportDate}
                        disabled
                    />
                    <TextField
                        label={<LocalizableText code={'ReceiptModal.weight'} />}
                        value={transportedData.weight || ''}
                        disabled
                    />
                </div>
            </div>
        );
    }
}
