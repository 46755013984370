import { withFormik } from 'formik';
import type { StringSchema } from 'yup';
import * as Yup from 'yup';

import { FormActions, FormNames, IFormValues } from '~/@user-store/deviations';
import client from '~/apolloClient';
import { MAX_PAYLOAD_SIZE } from '~/config/constants';
import i18n from '~/i18n';
import { ImageForUpload } from '~/utils/ImageForUpload';

import { DeviationStatus } from '../../../../../../common/enums';
import type { DeviationFormProps } from './DeviationForm';
import { DeviationForm } from './DeviationForm';

const formikHOC = withFormik<DeviationFormProps, IFormValues>({
    mapPropsToValues: props => {
        return {
            issue: props.deviation?.issue || '',
            description: props.deviation?.description || '',
            suggestedSolution: props.deviation?.suggestedSolution || '',
            status: props.deviation?.status || DeviationStatus.DRAFT,
            newComment: '',
            newFiles: [],
            action: FormActions.NOT_SET,
            deviation: props.deviation,
            commentSubmitted: false,
        };
    },

    validationSchema: Yup.object().shape({
        issue: Yup.string().trim().required(i18n.DeviationForm.issueIsRequired),
        description: Yup.string().trim().required(i18n.DeviationForm.descriptionIsRequired),
        newComment: Yup.string()
            .trim()
            .when(FormNames.action, (action: FormActions, schema: StringSchema) => {
                switch (action) {
                    case FormActions.NOT_SET:
                        return schema;
                    case FormActions.COMMENT:
                        return schema.required(i18n.DeviationForm.commentIsRequired);
                    default:
                        return schema.length(0, i18n.DeviationForm.pleaseSendComment);
                }
            }),
        newFiles: Yup.array().test(
            'Images size validation',
            i18n.errorCodes.FILE_TOO_LARGE,
            function (images: unknown[] | null | undefined) {
                return (
                    ((images || []) as ImageForUpload[]).reduce((size, file) => size + file.file.size, 0) <
                    MAX_PAYLOAD_SIZE
                );
            }
        ),
    }),

    handleSubmit: async (values, { props, setFieldValue }) => {
        const { parentEntity, deviation, closeDialog, mutations } = props;
        const submitAction = values.action;

        switch (submitAction) {
            case FormActions.CREATE:
                await mutations.createDeviation(client, values, parentEntity.id);
                closeDialog();

                return;

            case FormActions.UPDATE:
                if (!deviation) return;
                await mutations.updateDeviation(client, values, deviation);
                closeDialog();

                return;

            case FormActions.COMMENT:
                if (!deviation) return;
                const updatedDeviation = await mutations.commentDeviation(client, values, deviation);
                setFieldValue(FormNames.newFiles, []);
                setFieldValue(FormNames.deviation, updatedDeviation);
                setFieldValue(FormNames.commentSubmitted, true);
                setFieldValue(FormNames.newComment, '');
                setFieldValue(FormNames.action, FormActions.NOT_SET);

                return;

            case FormActions.CLOSE:
                if (!deviation) return;
                await mutations.closeDeviation(client, values, deviation);
                closeDialog();

                return;

            default:
                console.error('Unknown action:', submitAction);
        }
    },
});

export default formikHOC(DeviationForm);
