import { replaceRouteParams, routes } from '@common/routes';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

import { ButtonGroupSwitch } from '~/@components/ButtonGroupSwitch';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import { SochiSearchInput } from '~/@sochi-components/SochiSearchInput';
import { HomePageMode, IHomePageLandfill, LandfillTypesFilter } from '~/@user-store/homePageMap';
import { AllLandfillFilter, homePageMapStore, LandfillType } from '~/@user-store/homePageMap';
import { isOwnLandfill } from '~/@user-store/homePageMap/homePageMap.utils';

import { LandfillCard } from './LandfillCard';

const options = [
    { label: <LocalizableText code={'MapPage.landfillStatusFilter.ALL'} />, value: AllLandfillFilter.ALL },
    { label: <LocalizableText code={'MapPage.landfillStatusFilter.OWN'} />, value: LandfillType.OWN },
    { label: <LocalizableText code={'MapPage.landfillStatusFilter.EXTERNAL'} />, value: LandfillType.EXTERNAL },
];

export const LandfillList: FC = observer(() => {
    const { root, cards, emptyReplacer, extraHeight } = useStyles();

    const cardRefs = useRef(new Map<string, HTMLDivElement>());

    const history = useHistory();

    useEffect(() => {
        if (homePageMapStore.selectedLandfill?.id) {
            const element = cardRefs.current.get(homePageMapStore.selectedLandfill.id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [homePageMapStore.selectedLandfill?.id]);

    const activateLandfill = (landfill: IHomePageLandfill) => {
        homePageMapStore.onLandfillClick(landfill);
    };

    const saveRef = (id: string, element: HTMLDivElement | null) => {
        if (element) {
            cardRefs.current.set(id, element);
        }
    };

    const openLandfill = (landfill: IHomePageLandfill) =>
        history.push(replaceRouteParams(routes.sochi.landfillDetail, { landfillId: landfill.id }));

    const onTypeChange = (value: LandfillTypesFilter) => {
        homePageMapStore.applyLandfillTypeFilter(value);
    };

    const currentLandfills = homePageMapStore.filteredLandfills;

    return (
        <div className={cn(root, { [extraHeight]: homePageMapStore.mode === HomePageMode.ADMIN })}>
            {homePageMapStore.mode === HomePageMode.USER && (
                <ButtonGroupSwitch
                    color="secondary"
                    options={options}
                    value={homePageMapStore.landfillTypeFilter as LandfillTypesFilter}
                    onChange={onTypeChange}
                    fullWidth
                />
            )}

            <SochiSearchInput
                value={homePageMapStore.searchLandfillFilter}
                onChange={value => {
                    homePageMapStore.applySearchLandfillFilter(value);
                }}
            />

            <div className={cards}>
                <Grid container spacing={2}>
                    {currentLandfills.length > 0 ? (
                        currentLandfills.map(landfill => (
                            <Grid key={landfill.id} ref={element => saveRef(landfill.id, element)} item xs={6} lg={12}>
                                <LandfillCard
                                    onOpen={isOwnLandfill(landfill) ? openLandfill : undefined}
                                    onActivate={activateLandfill}
                                    landfill={landfill}
                                    highlightText={homePageMapStore.searchLandfillFilter}
                                    isSelected={landfill.id === homePageMapStore.selectedLandfill?.id}
                                />
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12}>
                            <div className={emptyReplacer}>
                                <LocalizableText code={'noLandfillsFound'} />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100% - 60px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',

        [theme.breakpoints.down('md')]: {
            height: 'calc(100% - 40px)',
        },
    },
    extraHeight: {
        height: 'calc(100% - 14px)',

        [theme.breakpoints.down('md')]: {
            height: 'calc(100% - 30px)',
        },
    },
    cards: {
        height: 'calc(100% - 115px)',
        overflow: 'scroll',
        overflowX: 'hidden',

        [theme.breakpoints.down('md')]: {
            height: 'calc(100% - 50px)',
        },
    },
    emptyReplacer: {
        fontSize: '18px',
        textAlign: 'center',
    },
}));
