import './ProjectAddEditNotes.scss';

import React, { useState } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { TextField } from '~/@components/TextField';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { ProjectQuery_project } from '~/graphql';
import i18n from '~/i18n';
import { saveNotes } from '~/utils/project';

interface Props {
    project: ProjectQuery_project;
    closeDialog: () => void;
}

export const ProjectAddEditNotes: React.FC<Props> = ({ project, closeDialog }) => {
    const bem = useFunctionalBem(ProjectAddEditNotes);
    const { element, className } = bem;
    const [notes, setNotes] = useState(project.comment);

    const onSubmit = () => {
        saveNotes(notes ? notes : '', project.id, project.ver).then(() => closeDialog());
    };

    return (
        <div className={className}>
            <div className={element('header')}>
                <SochiTitle
                    className={element('title')}
                    title={<LocalizableText code={'priceCalculatorPage.notes'} />}
                />
            </div>
            <TextField
                className={element('form-text')}
                value={notes ? notes : ''}
                onChange={event => setNotes(event.target.value)}
                fullWidth
                multiline
                rows={6}
                rowsMax={6}
            />
            <div className={element('form-buttons')}>
                <Button variant="outlined" color="primary" onClick={closeDialog}>
                    <LocalizableText code={'close'} />
                </Button>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    {i18n.save}
                </Button>
            </div>
        </div>
    );
};
