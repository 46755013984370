import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import {
    ISochiTableHeadCellProps,
    SochiNumberFilter,
    SochiSingleOptionFilter,
    SochiSortSection,
    SochiStringFilter,
    SochiTableHeadCell,
} from '~/@sochi-components/SochiTable/SochiTableHeadCell';
import {
    DumpLoadsFilterField,
    dumpLoadsStore,
    dumpLoadStatusFilterValues,
} from '~/@user-store/dumpLoads/dumpLoadsStore';
import { SortInput } from '~/graphql';
import i18n from '~/i18n';
import { dumpLoadModeFilterValues } from '~/utils/dumpLoad';

type Props = {
    fieldName: DumpLoadsFilterField;
    withFilter?: boolean;
    withSort?: boolean;
} & Omit<ISochiTableHeadCellProps, 'sortDirection' | 'children'>;

export const MassesHeadCell = ({ fieldName, withFilter, withSort, ...restProps }: Props) => {
    const sortValue = useObservable(() => dumpLoadsStore.sort.value);
    const setSortValue = useCallback((v: SortInput) => dumpLoadsStore.sort.setValue(v), []);
    const dumpTypes = useObservable(() => dumpLoadsStore.dumpTypesValues);

    const sortLabels = useMemo(() => {
        switch (fieldName) {
            case DumpLoadsFilterField.weight:
            case DumpLoadsFilterField.price:
            case DumpLoadsFilterField.tripsCount:
                return i18n.SortNumber;
            case DumpLoadsFilterField.serialNumber:
                return i18n.SortLowHigh;
            default:
                return i18n.SortString;
        }
    }, [fieldName]);

    const sortSection = useMemo(() => {
        if (!withSort) return null;

        return <SochiSortSection fieldName={fieldName} sort={sortValue} setSort={setSortValue} labels={sortLabels} />;
    }, [withSort, fieldName, sortValue, setSortValue, sortLabels]);

    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        switch (fieldName) {
            case DumpLoadsFilterField.serialNumber:
            case DumpLoadsFilterField.comment:
                return (
                    <SochiStringFilter
                        valueGetter={() => dumpLoadsStore.filter.values[fieldName]}
                        setValue={v => dumpLoadsStore.filter.setValue(fieldName, v)}
                    />
                );
            case DumpLoadsFilterField.mode:
                return (
                    <SochiSingleOptionFilter
                        values={dumpLoadModeFilterValues}
                        valueGetter={() =>
                            dumpLoadModeFilterValues.find(v => v.value === dumpLoadsStore.filter.values.mode)
                        }
                        setValue={v => dumpLoadsStore.filter.setValue(DumpLoadsFilterField.mode, v?.value)}
                    />
                );
            case DumpLoadsFilterField.status:
                return (
                    <SochiSingleOptionFilter
                        values={dumpLoadStatusFilterValues}
                        valueGetter={() =>
                            dumpLoadStatusFilterValues.find(v => v.value === dumpLoadsStore.filter.values.status)
                        }
                        setValue={v => dumpLoadsStore.filter.setValue(DumpLoadsFilterField.status, v?.value)}
                    />
                );
            case DumpLoadsFilterField.dumpTypeId:
                return (
                    <SochiSingleOptionFilter
                        values={dumpTypes}
                        valueGetter={() => dumpTypes.find(v => v.value === dumpLoadsStore.filter.values.dumpTypeId)}
                        setValue={v => dumpLoadsStore.filter.setValue(DumpLoadsFilterField.dumpTypeId, v?.value)}
                    />
                );
            case DumpLoadsFilterField.weight:
            case DumpLoadsFilterField.price:
            case DumpLoadsFilterField.tripsCount:
                return (
                    <SochiNumberFilter
                        valueGetter={() => dumpLoadsStore.filter.values[fieldName]}
                        setValue={v => dumpLoadsStore.filter.setValue(fieldName, v)}
                    />
                );

            default:
                return null;
        }
    }, [withFilter, fieldName, dumpTypes]);

    return (
        <SochiTableHeadCell {...restProps} sortDirection={sortValue?.field === fieldName ? sortValue.direction : null}>
            {sortSection}
            {filterSection}
        </SochiTableHeadCell>
    );
};
