import { USER_VIEW_MODE } from '@common/viewModes';
import React, { memo, useCallback, VFC } from 'react';

import { RestoreIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { showConfirmDialog, showCustomDialog } from '~/@store/common';
import { userDetailRoute } from '~/@store/routes';
import { IUser, useUserRestoreMutation } from '~/@store/users';
import { UserDeleteConfirmation } from '~/@views/AdminView/UsersPage/UserCells/UserDeleteConfirmation';
import { useUserAbilities } from '~/contexts';
import { canDeleteUser } from '~/utils/auth';
import { getUserFullName } from '~/utils/user';

type Props = {
    user: IUser;
    mode: USER_VIEW_MODE;
};

export const UserNameCell: VFC<Props> = memo(({ user, mode }) => {
    const ability = useUserAbilities();
    const canDeleteOrRestore = canDeleteUser(ability, user);

    const [restoreUser] = useUserRestoreMutation();

    const onDelete = useCallback(() => {
        const ignoredPromise = showCustomDialog({
            render: closeDialog => <UserDeleteConfirmation closeDialog={closeDialog} user={user} />,
        });
    }, [user]);

    const onRestore = useCallback(
        () =>
            showConfirmDialog({
                title: <LocalizableText code={'UsersTable.restoreInfo'} />,
                render: () => <LocalizableText code={'UsersTable.restoreConfirmation'} />,
                icon: RestoreIcon,
            }).then(confirmed => confirmed && restoreUser(user.id)),
        [restoreUser, user]
    );

    const actions: ITableCellAction[] = [];

    const isActiveMode = mode === USER_VIEW_MODE.USERS;

    if (isActiveMode)
        actions.push({
            variant: ActionVariant.LINK,
            to: userDetailRoute(user.id),
        });

    actions.push({
        variant: ActionVariant.COPY,
        content: getUserFullName(user),
    });

    if (isActiveMode) {
        actions.push({
            variant: ActionVariant.DELETE,
            onClick: onDelete,
            disabled: !canDeleteOrRestore,
        });
    } else {
        actions.push({
            variant: ActionVariant.RESTORE,
            onClick: onRestore,
            disabled: !canDeleteOrRestore,
        });
    }

    return <TableCellActions actions={actions}>{user.name}</TableCellActions>;
});
