import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { BatchTransfersQuery } from '~/@user-store/landfillBatches/landfillBatch.queries';
import { BatchTransfers, BatchTransfers_batchTransfers, BatchTransfersVariables } from '~/graphql';

export type IBatchTransfer = Omit<BatchTransfers_batchTransfers, '__typename'>;

export const useBatchTransfers = (landfillId: string): QueryResult<BatchTransfers, BatchTransfersVariables> => {
    return useQuery<BatchTransfers, BatchTransfersVariables>(BatchTransfersQuery, {
        fetchPolicy: 'cache-and-network',
        variables: {
            landfillId,
        },
    });
};
