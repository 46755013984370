import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

import { fmtDecimal } from '~/utils/numbers';

interface Props {
    children: number | null | undefined;
    precision?: number;
}

export const NumberFormatter = ({ children, precision }: Props) => {
    const { root, grey } = useStyles();
    const isGrey = !children && children !== 0;

    return <span className={classNames(root, { [grey]: isGrey })}>{fmtDecimal(children, precision)}</span>;
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    grey: {
        color: theme.palette.grey[400],
    },
}));
