import './TremsAndConditions/TermsAndConditions.scss';

import React from 'react';

import { Button } from '~/@components/Button';
import i18n from '~/i18n';

import { AuthDivider } from '../common/AuthDivider';
import { AuthText } from '../common/AuthText';
import { AuthTitle } from '../common/AuthTitle';
import { AuthWrapper } from '../common/AuthWrapper';

type IRegisterCompletedProps = {
    onChangeEmail: () => void;
    email: string;
};

export const RegisterCompleted = ({ onChangeEmail, email }: IRegisterCompletedProps) => {
    return (
        <AuthWrapper>
            <AuthTitle title={i18n.verifyEmail} subtitle={i18n.checkYourInbox} />
            <AuthText>
                {i18n.emailWasSentTo} <br />
                <b>{email}</b>
            </AuthText>
            <AuthText>{i18n.SignupPage.registrationIsSuccessful}</AuthText>
            <AuthDivider />
            <AuthText transparent>{i18n.changeEmailIfIsIncorrect}</AuthText>
            <Button color="primary" size="large" variant="contained" onClick={onChangeEmail}>
                {i18n.changeEmail}
            </Button>
        </AuthWrapper>
    );
};
