/* eslint-disable pinpointer/equal-enum */
export enum COLOR {
    white = '#ffffff',
    black = '#000000',
    blackTransparent = 'rgba(0, 0, 0, 0.4)',
    blue = '#1a73e9',
    blueLight = '#498fed',
    red = '#ee2737',
    grayLight = '#ededed',
    grayLight2 = '#e1e4e8',
    grayLight3 = '#e4e4e4',
    grayDark = '#92979d',
    grayDarker = '#616569',
    gray3 = '#828282',
    gray4 = '#bdbdbd',
    gray5 = '#e0e0e0',
    textBody = '#2b2b2b',
    grayBackground = '#eeefef',
    transparent = '#0000',
    brand = '#81b7ff',
    brandTransparent = 'rgba(129, 183, 255, 0.5)',
    orange = '#FD5521',
    orangeLight = '#F4A720',
    green = '#05AC5A',
    mainBlue = '#141b4d',
    mainBlueTransparent = 'rgba(20, 27, 77, 0.4)',
    mainBlueHover = '#1f2975',
    whiteTransparent = 'rgba(255, 255, 255, 0.4)',
    grayLightTransparent = 'rgba(189, 189, 189, 0.4)',
}

export enum FONT_FAMILY {
    catamaran = `'Catamaran', sans-serif;`,
}

export enum FONT_WEIGHT {
    thin = 300,
    normal = 400,
    medium = 500,
    semiBold = 600,
    bold = 700,
}

export const BUTTON_SIZE_SMALL = {
    fontSize: 14,
    lineHeight: '16px',
    padding: '5px 18px',
    height: 30,
};

export const BUTTON_SHADOW = '0 3px 6px rgba(0, 0, 0, 0.21)';
