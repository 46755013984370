import IconButton from '@material-ui/core/IconButton';
import { sortBy } from 'lodash';
import React, { Fragment, VFC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { FileUploadButton } from '~/@components/FileUploadButton';
import { DeleteIcon, DocumentStrokeIcon, DownloadIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { IDumpLoad } from '~/@store/dumpLoads';
import { useAnalysisFileDeleteMutation, useAnalysisFileUploadMutation } from '~/@store/projects';
import { projectAnalysisUri } from '~/apolloClient';
import { ACCEPTED_DOCUMENT_FILE_TYPES } from '~/config/constants';
import { useUserAbilities } from '~/contexts';
import { canDeleteProjectDocument, canReadProject, canUploadProjectDocument } from '~/utils/auth';
import { AnalysisFile, downloadDumpLoadAnalysisFile } from '~/utils/dumpLoad';

import { showDocumentViewDialog } from '../../common/DocumentViewDialog';

type Props = {
    dumpLoad: IDumpLoad;
};

export const DumpLoadAnalysisTable: VFC<Props> = ({ dumpLoad }) => {
    const { container, list, button, uploadButton } = useStyles();

    const abilityContext = useUserAbilities();

    const [deleteDumpLoadAnalysisFile] = useAnalysisFileDeleteMutation();
    const [uploadAnalysisFile, isUploading] = useAnalysisFileUploadMutation();

    const project = dumpLoad.project;

    const uploadFile = (file: File) => {
        if (isUploading) return;

        const ignoredPromise = uploadAnalysisFile(project!.id, dumpLoad.id, file);
    };

    const downloadFile = (file: AnalysisFile) => () => {
        const ignoredPromise = downloadDumpLoadAnalysisFile(project!.id, dumpLoad.id, file);
    };

    const deleteFile = (file: AnalysisFile) => () =>
        showDeleteConfirmationDialog(
            <LocalizableText code={'DumpLoadsTable.confirmDeleteToxicAnalysis'} />,
            file.name
        ).then(confirmed => {
            if (confirmed) {
                const ignoredPromise = deleteDumpLoadAnalysisFile(project!.id, dumpLoad.id, file.id);
            }
        });

    const viewFile = (file: AnalysisFile) => () => {
        const ignoredPromise = showDocumentViewDialog(file.name, projectAnalysisUri, {
            projectId: project!.id,
            dumpLoadId: dumpLoad.id,
            fileId: file.id,
        });
    };

    const hasProjectAndCanRead = project && canReadProject(abilityContext, project);
    const hasProjectAndCanDelete = project && canDeleteProjectDocument(abilityContext, project);
    const hasProjectAndCanUpload = project && canUploadProjectDocument(abilityContext, project);

    if (!hasProjectAndCanRead) return null;

    const analysisFiles = sortBy(dumpLoad?.analysisFiles, file => file.uploadDate).reverse();

    return (
        <div className={container}>
            <div className={list}>
                {analysisFiles.map(file => (
                    <Fragment key={file.id}>
                        <Text variant="BodyRegular" ellipsis title={file.name}>
                            {file.name}
                        </Text>
                        <IconButton className={button} onClick={viewFile(file)}>
                            <DocumentStrokeIcon />
                        </IconButton>
                        <IconButton className={button} onClick={downloadFile(file)}>
                            <DownloadIcon />
                        </IconButton>
                        <IconButton className={button} disabled={!hasProjectAndCanDelete} onClick={deleteFile(file)}>
                            <DeleteIcon />
                        </IconButton>
                    </Fragment>
                ))}
            </div>
            <FileUploadButton
                className={uploadButton}
                variant="contained"
                color="primary"
                onChange={uploadFile}
                acceptFileTypes={ACCEPTED_DOCUMENT_FILE_TYPES}
                disabled={!hasProjectAndCanUpload}>
                <LocalizableText code={'DumpLoadsTable.addToxicAnalysis'} />
            </FileUploadButton>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    list: {
        display: 'grid',
        gridTemplateColumns: '1fr auto auto auto',
        columnGap: theme.spacing(1.5),
        rowGap: theme.spacing(2.5),
    },
    button: {
        width: 28,
        height: 28,
        minWidth: 'unset',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        transition: 'none',
        color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: theme.palette.text.secondary,
            color: theme.palette.action.hover,
        },
    },
    uploadButton: {
        alignSelf: 'flex-end',
    },
}));
