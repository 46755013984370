import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { projectsStore } from '~/@store/projects/project.store';
import { ProjectRecoverMutation, ProjectRecoverMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../projects.queries';

export type RecoverProjectFunc = (id: string) => Promise<boolean>;

export const useProjectRecover = (): [RecoverProjectFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<ProjectRecoverMutation, ProjectRecoverMutationVariables>(
        queries.ProjectRecoverMutation,
        {
            refetchQueries: [
                {
                    query: queries.AdminProjectsPagedShortQuery,
                    variables: projectsStore.projectsQueryVariables,
                },
            ],
        }
    );

    const recoverProject = useCallback<RecoverProjectFunc>(
        id => {
            return callMutation({
                variables: { id },
            })
                .then(() => true)
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [recoverProject, mutationResult.loading];
};
