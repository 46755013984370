import './Stepper.scss';

import { formatString } from '@common/utils';
import { isFunction } from 'lodash';
import React, { FC } from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import type { ITextAlign } from '~/config/enum';
import { TextAlign } from '~/config/enum';
import i18n from '~/i18n';

type Props = {
    // eslint-disable-next-line react/no-unused-prop-types
    children?: void;
    steps: Array<() => React.ReactNode>;
    step: number;
    className?: string;
    labelPosition?: ITextAlign;
};

export const Stepper: FC<Props> = ({ step, steps, className, labelPosition = TextAlign.center }) => {
    const { block, element } = useFunctionalBem(Stepper, className);

    if (steps.length === 0) return null;
    if (step < 1 || step > steps.length) return null;
    const currentStepCallback = steps[step - 1];

    return (
        <div className={block()}>
            {steps.length > 1 && (
                <h4 className={element('step-title', { [labelPosition]: true })}>
                    {formatString(i18n.Stepper.stepNumber, String(step), String(steps.length))}
                </h4>
            )}
            {isFunction(currentStepCallback) && currentStepCallback()}
        </div>
    );
};
