import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';

import { useDisable } from '~/@components/@hooks';
import { makeStyles } from '~/@components/@theme';
import { COLORS } from '~/@components/@theme/colors';
import { CardList } from '~/@components/CardList';

import {
    DRAG_ELEM_CLASS_SELECTOR,
    IOrderingItem,
    isEqualSortableListsById,
    prepareSortableList,
    SortableListItem,
} from './helpers';
import { ReorderingButton } from './ReorderingButton';

type ReorderingListProps<TItem extends IOrderingItem> = {
    items: TItem[];
    onOrderChange: (items: TItem[]) => void;
    children: (v: TItem, key: string, reorderingButton: React.ReactNode) => React.ReactNode;
    className?: string;
    disabled?: boolean;
};

export const ReorderingList = <TItem extends IOrderingItem>({
    items,
    onOrderChange,
    children,
    className,
    disabled,
}: ReorderingListProps<TItem>) => {
    const { root, dragging, ghostItem, dragItem } = useStyles();
    const disableContext = useDisable();

    const [list, setList] = useState<SortableListItem<TItem>[]>(prepareSortableList(items));
    useEffect(() => setList(prepareSortableList(items)), [items]);

    const updateList = useCallback(
        (reorderedList: SortableListItem<TItem>[]) => {
            setList(reorderedList);
            if (isEmpty(reorderedList) || isEqualSortableListsById(list, reorderedList)) return;

            onOrderChange(reorderedList.map(i => i.item));
        },
        [list, onOrderChange]
    );

    if (items.length < 2) return <CardList className={className}>{items.map(i => children(i, i.id, null))}</CardList>;

    const isDragging = list.some(i => i.chosen);

    return (
        <ReactSortable
            handle={DRAG_ELEM_CLASS_SELECTOR}
            className={classNames(root, className, { [dragging]: isDragging })}
            ghostClass={ghostItem}
            fallbackClass={dragItem}
            forceFallback
            list={list}
            setList={updateList}>
            {list.map(({ id, item, chosen }) =>
                children(item, id, <ReorderingButton isChosen={chosen} disabled={disabled || disableContext} />)
            )}
        </ReactSortable>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(),
    },
    dragging: {
        cursor: 'move !important',
    },
    dragItem: {
        opacity: '1 !important',
    },
    ghostItem: {
        position: 'relative',
        pointerEvents: 'none',
        '&::after': {
            content: '""',
            position: 'absolute',
            display: 'block',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: COLORS.white,
            border: `1px solid ${COLORS.brandMain}`,
            borderRadius: 4,
            zIndex: 10,
        },
    },
}));
