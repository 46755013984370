import { routes } from '@common/routes';
import React, { VFC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { UserViewLayout } from '~/@views/UserView/UserViewLayout';

import { LandfillPage } from './LandfillPage';
import { OffersPage } from './OffersPage';
import { ProjectPage } from './ProjectPage';

export const UserView: VFC = () => {
    return (
        <UserViewLayout>
            <Switch>
                <Route path={routes.sochi.projectDetail} component={ProjectPage} />
                <Route path={routes.sochi.offers} component={OffersPage} />
                <Route path={routes.sochi.landfillDetail} component={LandfillPage} />
            </Switch>
        </UserViewLayout>
    );
};
