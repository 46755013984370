import { getProjectSubject, IAbilityProject, ProjectSubject } from '@common/abilities/projects';
import { AbilityCan, ProjectFields } from '@common/enums';

import { useUserAbilities } from '~/contexts';

export type IProjectAbilities = {
    canEditProject: boolean;
    canEditLocation: boolean;
    canUploadDocument: boolean;
    canDeleteDocument: boolean;
};

export const useProjectAbilities = (project: IAbilityProject | null): IProjectAbilities => {
    const { ability, user } = useUserAbilities();

    const canEditProject = project ? ability.can(AbilityCan.UPDATE, getProjectSubject(user, project)) : true;

    const canEditLocation = project
        ? ability.can(AbilityCan.UPDATE, getProjectSubject(user, project, [ProjectFields.location]))
        : true;

    const canUploadDocument = ability.can(
        AbilityCan.UPLOAD,
        getProjectSubject(user, project, [ProjectSubject.PROJECT_DOCUMENT])
    );

    const canDeleteDocument = ability.can(
        AbilityCan.DELETE,
        getProjectSubject(user, project, [ProjectSubject.PROJECT_DOCUMENT])
    );

    return { canEditProject, canEditLocation, canUploadDocument, canDeleteDocument };
};
