import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import Markdown_it from 'markdown-it';
import React, { useEffect, useRef } from 'react';

type Props = {
    text: string;
    className?: string;
};

const md = new Markdown_it();

export const Markdown = ({ text, className }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const { root } = useStyles();

    useEffect(() => {
        ref.current!.innerHTML = md.render(text);
    }, [text]);

    return <div className={cn(root, className)} ref={ref} />;
};

const useStyles = makeStyles(theme => ({
    root: {
        '&>*': { marginBottom: 8 },
        '& p': { ...theme.typography.body1, marginBottom: '1em' },
        '& h1': { ...theme.typography.h1, marginBottom: '1em' },
        '& h2': { ...theme.typography.h2, marginBottom: '1em' },
        '& h3': { ...theme.typography.h3, marginBottom: '1em' },
        '& ul': { paddingLeft: 24, listStyle: 'disc' },
        '& ol': { paddingLeft: 24, listStyle: 'auto' },
        '& em': { fontStyle: 'italic' },
        '& strong': { fontWeight: 'bold' },
    },
}));
