import { ContaminationType, UnitOfMeasure } from '@common/enums';
import { formatAmount } from '@common/utils/formaters';
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';

import { Button } from '~/@components/Button';
import { Text } from '~/@components/Text';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { QueryResult } from '~/@sochi-components/QueryResult';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { getContaminationLevelLabel } from '~/@store/toxicLimits';
import { useDeliveryLinesMatchedForEvent } from '~/@user-store/landfillDeliveryEvents/useDeliveryLinesMatchedForEvent';
import i18n from '~/i18n';
import { formatDate, formatTimeShort } from '~/utils/date';

type Props = {
    eventId: string;
    closeDialog: (lineId: string | null) => void;
};

export const SelectMatchedLineDialog = ({ eventId, closeDialog }: Props) => {
    const [lineId, setLineId] = useState<string | null>(null);
    const query = useDeliveryLinesMatchedForEvent(eventId);
    const lines = query.data?.deliveryLinesMatchedForEvent || [];

    const { info } = useStyles();

    return (
        <DialogContent>
            <QueryResult onRetry={query.refetch} error={query.error} loading={query.loading} empty={!lines.length}>
                <List>
                    {lines.map(line => (
                        <ListItem key={line.id} button onClick={() => setLineId(line.id)} selected={lineId === line.id}>
                            <ListItemText
                                primary={`${line.truckRegistrationNumber.toUpperCase()} (${formatAmount(
                                    line.weight,
                                    UnitOfMeasure.TONNS
                                )})`}
                                secondary={line.orderNumber}
                            />
                            <div className={info}>
                                <Text>
                                    {line.dumpTypeName || i18n.NA}{' '}
                                    {line.solidTestResult
                                        ? getContaminationLevelLabel(line.solidTestResult, ContaminationType.SOLID)
                                        : null}
                                </Text>
                                <Text>
                                    {formatDate(line.plannedStartDate)} {formatTimeShort(line.endDeliveryDate)}
                                </Text>
                            </div>
                        </ListItem>
                    ))}
                </List>
            </QueryResult>
            <ButtonGroup>
                <Button variant="outlined" onClick={() => closeDialog(null)}>
                    {i18n.cancel}
                </Button>
                <Button variant="contained" color="primary" onClick={() => closeDialog(lineId)} disabled={!lineId}>
                    {i18n.LandfillDeliveryEvents.attachToLine}
                </Button>
            </ButtonGroup>
        </DialogContent>
    );
};

const useStyles = makeStyles(theme => ({
    info: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(2),
    },
}));
