import { DespatchAdviceResponseType } from '@common/enums';
import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { DespatchAdviceTransactionCell } from '~/@components/BeastDataDialog/BeastDataTable/DespatchAdviceTransactionCell';
import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn } from '~/@components/Table';
import { IDespatchAdvice } from '~/@store/beastIntegration';
import i18n from '~/i18n';

import { DespatchAdviceResponseInfoCell } from './DespatchAdviceResponseInfoCell';
import { DespatchAdviceResponseStatusCell } from './DespatchAdviceResponseStatusCell';
import { DespatchAdviceSendCell } from './DespatchAdviceSendCell';
import { DespatchAdviceStatusCell } from './DespatchAdviceStatusCell';
import { DespatchAdviceTypeChip } from './DespatchAdviceTypeChip';

export type ExtendedDespatchAdvice = IDespatchAdvice & {
    response: {
        id: string;
        date: string;
        body: string;
        responseType: DespatchAdviceResponseType;
        transactionId: string;
        declineReason?: string | null;
    } | null;
};

export const useBeastDataColumns = (): ITableColumn<ExtendedDespatchAdvice>[] => {
    const { typeColumn } = useStyles();

    // noinspection UnnecessaryLocalVariableJS
    const columns: ITableColumn<ExtendedDespatchAdvice>[] = [
        {
            title: <LocalizableText code={'DespatchAdvicesTable.category'} />,
            render: row => i18n.DespatchAdviceCategory[row.category],
            name: 'buyer',
        },
        {
            title: <LocalizableText code={'DespatchAdvicesTable.type'} />,
            render: row => <DespatchAdviceTypeChip type={row.adviceType} />,
            cellClassName: typeColumn,
            name: 'type',
        },
        {
            title: <LocalizableText code={'DespatchAdvicesTable.transactionId'} />,
            render: row => <DespatchAdviceTransactionCell despatchAdvice={row} />,
            name: 'transactionId',
        },
        {
            title: <LocalizableText code={'DespatchAdvicesTable.status'} />,
            render: row => <DespatchAdviceStatusCell despatchAdvice={row} />,
            name: 'status',
        },
        {
            title: <LocalizableText code={'DespatchAdvicesTable.send'} />,
            render: row => <DespatchAdviceSendCell despatchAdvice={row} />,
            name: 'send',
        },
        {
            title: <LocalizableText code={'DespatchAdvicesTable.responseStatus'} />,
            render: row => <DespatchAdviceResponseStatusCell despatchAdvice={row} />,
            name: 'responseStatus',
        },
        {
            title: <LocalizableText code={'DespatchAdvicesTable.responseInfo'} />,
            render: row => <DespatchAdviceResponseInfoCell despatchAdvice={row} />,
            name: 'responseInfo',
        },
    ];

    return columns;
};

const useStyles = makeStyles(() => ({
    typeColumn: {
        overflow: 'auto',
        textOverflow: 'unset',
    },
}));
