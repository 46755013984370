import { validateBatchAmount } from '@common/functions/common.landfillBatches';
import { BatchCheckResult, BatchLogItem } from '@common/types';

import {
    IBatchLogRecord,
    Kind,
} from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/landfillBatchLog.types';
import { TransferType } from '~/graphql';

export const getBatchCheckResult = (batchId: string, items: IBatchLogRecord[]): BatchCheckResult => {
    const list: BatchLogItem[] = items.map(i => ({
        id: i.id,
        weight: i.kind === Kind.ANALYSIS ? 0 : i.weight,
        date: i.date,
        incoming:
            i.kind === Kind.ANALYSIS
                ? true
                : i.kind === Kind.TRANSFER
                ? i.type === TransferType.INCOMING || (i.type === TransferType.INTERNAL && i.toBatch?.id === batchId)
                : i.kind === Kind.LINE_INCOMING,
    }));

    return validateBatchAmount(list);
};
