import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import React from 'react';

type Color = 'default' | 'primary' | 'secondary';

const ACTIVE_CLASS_BY_COLOR: Record<Color, string> = {
    default: 'MuiButton-contained',
    primary: 'MuiButton-containedPrimary',
    secondary: 'MuiButton-containedSecondary',
};

type Props<TOptionValue> = {
    options: Array<{
        label: React.ReactNode;
        value: TOptionValue;
    }>;
    value: TOptionValue | void;
    onChange: (value: TOptionValue) => void;
    disableElevation?: boolean;
    color?: Color;
    orientation?: 'vertical' | 'horizontal';
    size?: 'small' | 'medium' | 'large';
    className?: string;
    fullWidth?: boolean;
    disabled?: boolean;
};

export function ButtonGroupSwitch<TOptionValue>({
    options,
    value: currentValue,
    onChange,
    color = 'default',
    ...restProps
}: Props<TOptionValue>) {
    return (
        <ButtonGroup variant="outlined" color={color} {...restProps}>
            {options.map(({ value, label }) => (
                <Button
                    className={value === currentValue ? ACTIVE_CLASS_BY_COLOR[color] : ''}
                    onClick={() => onChange(value)}
                    key={`${value}`}>
                    {label}
                </Button>
            ))}
        </ButtonGroup>
    );
}
