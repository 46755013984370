import { UserRole } from '../../enums';
import { IAbilityComplexSubject } from '../abilities.types';

export enum CompanySubject {
    ALL_FIELDS_EXCEPT_NAME = 'ALL_FIELDS_EXCEPT_NAME',
    LIST = 'LIST',
    CONNECTED_ENTITIES = 'CONNECTED_ENTITIES',
    TRANSPORT_COMPANY = 'TRANSPORT_COMPANY',
}

export type ICompanySubject = `${CompanySubject}`;

export interface ICompanyCalculatedSubject extends IAbilityComplexSubject {
    relationRole: Array<UserRole>;
    subSubject: Array<ICompanySubject>;
}
