import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
    DeliveryLineCreateInput,
    DeliveryLineCreateMutation,
    DeliveryLineCreateMutation_deliveryLineCreate,
    DeliveryLineCreateMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../deliveryLines.queries';
import { deliveryLinesStore } from '../deliveryLines.store';

export type CreateDeliveryLineFunc = (
    input: DeliveryLineCreateInput
) => Promise<DeliveryLineCreateMutation_deliveryLineCreate>;

export const useDeliveryLineCreateMutation = (): [CreateDeliveryLineFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<DeliveryLineCreateMutation, DeliveryLineCreateMutationVariables>(
        queries.DeliveryLineCreateMutation
    );

    const createDeliveryLine = useCallback<CreateDeliveryLineFunc>(
        input => {
            return callMutation({
                variables: { input },
                refetchQueries: [
                    {
                        query: queries.AdminDeliveryLinesPagedQuery,
                        variables: deliveryLinesStore.deliveryLinesQueryVariables,
                    },
                ],
            })
                .then(result =>
                    result.data?.deliveryLineCreate
                        ? result.data.deliveryLineCreate
                        : Promise.reject(new Error('Empty response "DeliveryLineCreateMutation"'))
                )
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [createDeliveryLine, mutationResult.loading];
};
