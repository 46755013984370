import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import { AdminDeliveryLinesPagedQuery } from '~/@store/deliveryLines/deliveryLines.queries';
import {
    AdminDeliveryLinesPagedQuery as IDeliveryLinesPagedQuery,
    AdminDeliveryLinesPagedQueryVariables,
} from '~/graphql';

import { deliveryLinesStore } from '../deliveryLines.store';

export const usePagedDeliveryLines = (): QueryResult<
    IDeliveryLinesPagedQuery,
    AdminDeliveryLinesPagedQueryVariables
> => {
    const variables: AdminDeliveryLinesPagedQueryVariables = useDebouncedObservable(
        () => deliveryLinesStore.deliveryLinesQueryVariables,
        TIMEOUT_ON_QUERY_DEBOUNCE
    );

    return useQuery<IDeliveryLinesPagedQuery, AdminDeliveryLinesPagedQueryVariables>(AdminDeliveryLinesPagedQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
        onCompleted: data => deliveryLinesStore.pagination.onQueryComplete(data.deliveryLinesPaged),
    });
};
