import { QueryResult, useQuery } from '@apollo/client';

import {
    DeliveryLinesMatchedForEvent,
    DeliveryLinesMatchedForEvent_deliveryLinesMatchedForEvent,
    DeliveryLinesMatchedForEventVariables,
} from '~/graphql';

import { DeliveryLinesMatchedForEventQuery } from './landfillDeliveryEvents.queries';

export type IMatchedLine = DeliveryLinesMatchedForEvent_deliveryLinesMatchedForEvent;

export const useDeliveryLinesMatchedForEvent = (
    eventId: string
): QueryResult<DeliveryLinesMatchedForEvent, DeliveryLinesMatchedForEventVariables> => {
    return useQuery<DeliveryLinesMatchedForEvent, DeliveryLinesMatchedForEventVariables>(
        DeliveryLinesMatchedForEventQuery,
        { variables: { eventId }, fetchPolicy: 'network-only' }
    );
};
