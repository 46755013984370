import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
    CompanyUpdate,
    CompanyUpdateMutation,
    CompanyUpdateMutation_companyUpdate,
    CompanyUpdateMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../companies.queries';

export type UpdateCompanyFunc = (input: CompanyUpdate) => Promise<CompanyUpdateMutation_companyUpdate>;

export const useUpdateCompany = (): [UpdateCompanyFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<CompanyUpdateMutation, CompanyUpdateMutationVariables>(
        queries.CompanyUpdateMutation
    );

    const updateCompany = useCallback<UpdateCompanyFunc>(
        input => {
            return callMutation({
                variables: { input },
            })
                .then(result =>
                    result.data?.companyUpdate
                        ? result.data.companyUpdate
                        : Promise.reject(new Error('Empty response "CompanyUpdateMutation"'))
                )
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [updateCompany, mutationResult.loading];
};
