import cn from 'classnames';
import React, { FC, useMemo } from 'react';

import { makeStyles } from '~/@components/@theme';
import { LocalizableText } from '~/@components/LocalizableText';
import { ISochiTableColumn, SochiTable } from '~/@sochi-components/SochiTable';
import { ProjectDeliveryItem } from '~/@views/UserView/ProjectPage/ProjectDashboard/dashboardFilter.store';

import { DashboardNoData, DashboardTitle } from '../common';

type Props = {
    lines: ProjectDeliveryItem[];
};

type FillRateRow = {
    licensePlate: string;
    value: number;
};

const fmtPercent = (value: number) => `${Math.floor(value)}%`;

export const ProjectFillRateDashboard: FC<Props> = ({ lines }) => {
    const { root, table, tablePaper, subheaderSection, subheaderItem, subheader, value, lowest, average, highest } =
        useStyles();

    const { data, min, max, avg } = useMemo(() => {
        const {
            data: grouped,
            min,
            max,
            total,
            count,
        } = lines.reduce(
            (acc, line) => {
                if (!line.fillRate) return acc;

                if (!acc.data.has(line.licensePlate)) {
                    acc.data.set(line.licensePlate, [line.fillRate!]);
                } else {
                    acc.data.set(line.licensePlate, [...acc.data.get(line.licensePlate)!, line.fillRate!]);
                }

                const min = line.fillRate < acc.min ? line.fillRate : acc.min;
                const max = line.fillRate > acc.max ? line.fillRate : acc.max;

                return {
                    data: acc.data,
                    min,
                    max,
                    total: acc.total + line.fillRate,
                    count: acc.count + 1,
                };
            },
            { data: new Map<string, number[]>(), min: Number.MAX_VALUE, max: Number.MIN_VALUE, total: 0, count: 0 }
        );

        return {
            data: Array.from(grouped, ([licensePlate, fillRates]) => ({
                licensePlate,
                value: fillRates.reduce((acc, fillRate) => acc + fillRate, 0) / fillRates.length,
            })).sort((a, b) => b.value - a.value),
            min,
            max,
            avg: count === 0 ? 0 : total / count,
        };
    }, [lines]);

    const columns: Array<ISochiTableColumn<FillRateRow>> = useMemo(
        () => [
            {
                title: <LocalizableText code={'ProjectDashboard.fillRateTruckHeader'} />,
                render: data => data.licensePlate,
                alignLeft: true,
            },
            {
                title: <LocalizableText code={'ProjectDashboard.fillRateValueHeader'} />,
                render: data => fmtPercent(data.value),
            },
        ],
        []
    );

    return (
        <div className={root}>
            <DashboardTitle>
                <LocalizableText code={'ProjectDashboard.titleFillRate'} />
            </DashboardTitle>
            {data.length === 0 ? (
                <DashboardNoData />
            ) : (
                <>
                    <div className={subheaderSection}>
                        <div className={subheaderItem}>
                            <div className={subheader}>
                                <LocalizableText code={'ProjectDashboard.fillRateLowest'} />
                            </div>
                            <div className={cn(value, lowest)}>{fmtPercent(min)}</div>
                        </div>
                        <div className={subheaderItem}>
                            <div className={subheader}>
                                <LocalizableText code={'ProjectDashboard.fillRateAverage'} />
                            </div>
                            <div className={cn(value, average)}>{fmtPercent(avg)}</div>
                        </div>
                        <div className={subheaderItem}>
                            <div className={subheader}>
                                <LocalizableText code={'ProjectDashboard.fillRateHighest'} />
                            </div>
                            <div className={cn(value, highest)}>{fmtPercent(max)}</div>
                        </div>
                    </div>
                    <SochiTable
                        dense
                        columns={columns}
                        items={data}
                        keyGetter={i => i.licensePlate}
                        className={table}
                        paperClassName={tablePaper}
                    />
                </>
            )}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(50vh - 180px)',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        },
    },
    tablePaper: {
        width: '100%',
        maxWidth: '400px',
    },
    table: {
        overflowY: 'scroll',
        maxHeight: 'calc(50vh - 300px)',
        [theme.breakpoints.down('md')]: {
            maxHeight: '400px',
        },
    },
    subheaderSection: {
        display: 'flex',
        flexDirection: 'row',
        gap: '24px',
    },
    subheaderItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'center',
    },
    subheader: {
        fontSize: '16px',
    },
    value: {
        fontSize: '24px',
    },
    lowest: {
        color: 'orange',
    },
    average: {
        color: 'green',
    },
    highest: {
        color: 'red',
    },
}));
