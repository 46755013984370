import { Icon, IconButton, Input, InputAdornment, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useDebounce } from '~/@components/@hooks';
import { useObservable } from '~/@components/@hooks/use-observable';
import { UserFormatter } from '~/@components/Formatters';
import { IUserSearchRecord, useUsersSearchFunc } from '~/@store/users';
import { UserFilter as UserFilterInput } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    valueGetter: () => IUserSearchRecord | null | undefined;
    setValue: (v: IUserSearchRecord | null) => void;
    filter: UserFilterInput;
};

export const UserFilter = ({ valueGetter, setValue, filter }: Props) => {
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState<IUserSearchRecord[]>([]);
    const debouncedSearch = useDebounce(search);
    const { selected } = useStyles();
    const searchFunc = useUsersSearchFunc(filter);
    const value = useObservable(valueGetter);

    useEffect(() => {
        if (debouncedSearch) {
            searchFunc(debouncedSearch).then(setUsers);
        } else {
            setUsers([]);
        }
    }, [debouncedSearch, searchFunc]);

    return (
        <>
            <ListItem divider>
                <Input
                    placeholder={i18n.search}
                    value={search || ''}
                    onChange={e => setSearch(e.target.value)}
                    disableUnderline
                    fullWidth
                    endAdornment={
                        <InputAdornment position="end">
                            {filter ? (
                                <IconButton onClick={() => setSearch('')} size="small">
                                    <Icon color="secondary">close</Icon>
                                </IconButton>
                            ) : (
                                <Icon>search</Icon>
                            )}
                        </InputAdornment>
                    }
                />
            </ListItem>
            {users.map(u => (
                <ListItem
                    key={u.id}
                    button
                    divider
                    onClick={() => setValue(u)}
                    className={value?.id === u.id ? selected : undefined}>
                    <ListItemText>
                        <UserFormatter>{u}</UserFormatter>
                    </ListItemText>
                </ListItem>
            ))}
        </>
    );
};

const useStyles = makeStyles(theme => ({
    selected: {
        backgroundColor: theme.palette.background.default,
    },
}));
