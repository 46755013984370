import { LinearProgress, OutlinedInputProps } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import React, { ReactNode, useCallback, useRef } from 'react';

import { useDisable } from '~/@components/@hooks';
import { TextField } from '~/@components/TextField';

export type ICommonAutocompleteProps = {
    searchValue: string;
    onSearchChange: (v: string) => void;
    label?: ReactNode;
    disabled?: boolean;
    autoFocus?: boolean;
    className?: string;
    isLoading?: boolean;
    errorMessage?: string;
    InputProps?: Partial<OutlinedInputProps>;
    placeholder?: string;
};

type Props = ICommonAutocompleteProps & {
    popupContent: React.ReactNode;
    open: boolean;
    setOpen: (v: boolean) => void;
};

export const CommonAutocomplete = ({
    label,
    className,
    autoFocus,
    searchValue,
    onSearchChange,
    isLoading,
    disabled,
    errorMessage,
    open,
    setOpen,
    popupContent,
    InputProps,
    placeholder,
}: Props) => {
    const anchorRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(
        (event: React.MouseEvent<Document> | React.MouseEvent<HTMLDListElement>) => {
            if (anchorRef.current?.contains(event.target as Node)) {
                return;
            }

            setOpen(false);
        },
        [setOpen]
    );

    const { popper, paper, input } = useStyles({ popperMinWidth: containerRef.current?.offsetWidth });

    return (
        <>
            <TextField
                ref={containerRef}
                autoFocus={autoFocus}
                fullWidth
                inputRef={anchorRef}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={!useDisable() && !disabled ? handleOpen : undefined}
                label={label}
                value={searchValue}
                onChange={e => onSearchChange(e.target.value)}
                className={className}
                disabled={!!disabled}
                error={!!errorMessage}
                helperText={errorMessage}
                InputProps={InputProps}
                inputProps={{ className: input }}
                placeholder={placeholder}
            />
            <Popper
                className={popper}
                open={open && !!anchorRef.current}
                anchorEl={anchorRef.current}
                placement="bottom-start">
                <Paper className={paper}>
                    {isLoading && <LinearProgress />}
                    <ClickAwayListener onClickAway={handleClose}>{popupContent}</ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
};

const useStyles = makeStyles<Theme, { popperMinWidth?: number }>(theme => ({
    popper: {
        zIndex: theme.customSettings.autocomplete.popper.zIndex,
        marginTop: theme.customSettings.autocomplete.popper.gap,
    },
    paper: {
        border: theme.customSettings.autocomplete.paper.border,
        boxShadow: theme.customSettings.autocomplete.paper.shadow,
        minWidth: ({ popperMinWidth }: { popperMinWidth?: number }) => popperMinWidth || 'none',
    },
    input: {
        textOverflow: 'ellipsis',
    },
}));
