import { Grid } from '@material-ui/core';
import React from 'react';

import { DumpLoadStatusFormatter } from '~/@components/Formatters';
import { FormikBooleanField, FormikDateField, FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { useDumpLoadAbilities } from '~/@store/dumpLoads';
import { DumpLoadEditPeppolFields } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm/DumpLoadEditPeppolFields';
import i18n from '~/i18n';

import { DumpLoadEditAttributesFields } from './DumpLoadEditAttributesFields';
import {
    DumpLoadEditAmount,
    DumpLoadEditDeclaration,
    DumpLoadEditDumpTypeSelect,
    DumpLoadEditSubstances,
    DumpLoadEditUnitSelect,
} from './DumpLoadEditFields';
import { useDumpLoadFormikContext } from './DumpLoadEditFormik';

export const DumpLoadEditCommonFields = () => {
    const formik = useDumpLoadFormikContext();

    const { dumpLoad, mode } = formik.values;

    const { canUpdateFieldsAffectsDestination, canUpdateDumpLoad } = useDumpLoadAbilities(dumpLoad);

    const disabled = !canUpdateDumpLoad;
    const disabledFieldsAffectsDestination = !canUpdateFieldsAffectsDestination;

    return (
        <Grid container spacing={3}>
            <Grid item xs={8}>
                <DumpLoadEditDumpTypeSelect disabled={disabledFieldsAffectsDestination} />
            </Grid>
            <Grid item xs={4}>
                <Text variant="caption">{i18n.status}</Text>
                <Text variant="BodyBold">
                    <DumpLoadStatusFormatter>{dumpLoad.status}</DumpLoadStatusFormatter>
                </Text>
            </Grid>

            <Grid item xs={12}>
                <DumpLoadEditSubstances disabled={disabledFieldsAffectsDestination} />
            </Grid>
            <Grid item xs={6}>
                <DumpLoadEditAmount disabled={disabledFieldsAffectsDestination} />
            </Grid>
            <Grid item xs={6}>
                <DumpLoadEditUnitSelect disabled={disabledFieldsAffectsDestination} />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField
                    label={<LocalizableText code={'DumpLoadCard.comment'} />}
                    name="comment"
                    formik={formik}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={6}>
                <FormikDateField
                    label={<LocalizableText code={'startDate'} />}
                    name="date"
                    formik={formik}
                    disabled={disabledFieldsAffectsDestination}
                    minDate={dumpLoad.project?.startDate}
                />
            </Grid>
            <Grid item xs={6}>
                <FormikDateField
                    label={<LocalizableText code={'endDate'} />}
                    name="endDate"
                    formik={formik}
                    disabled={disabledFieldsAffectsDestination}
                    minDate={dumpLoad.project?.startDate}
                />
            </Grid>
            <Grid item xs={6}>
                <FormikBooleanField
                    name={'matchWithPinpointer'}
                    formik={formik}
                    label={<LocalizableText code={'DumpLoadCard.matchWithPinpointer'} />}
                    disabled={disabledFieldsAffectsDestination}
                />
            </Grid>
            <Grid item xs={6}>
                <Text variant="caption">
                    <LocalizableText code={'DumpLoadCard.mode'} />
                </Text>
                <Text variant="BodyBold">
                    <LocalizableText code={`DumpLoadCard.${mode}`} />
                </Text>
            </Grid>
            <DumpLoadEditAttributesFields />
            <Grid item xs={12}>
                <FormikTextField
                    label={<LocalizableText code={'DumpLoadCard.FANumber'} />}
                    name={'FANumber'}
                    formik={formik}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12}>
                <DumpLoadEditDeclaration />
            </Grid>
            <DumpLoadEditPeppolFields />
        </Grid>
    );
};
