import './TermsAndConditions.scss';

import React from 'react';

import { Button } from '~/@components/Button';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import i18n from '~/i18n';

type ITermsAndConditionsProps = {
    closeDialog: (v: boolean) => void;
};

export const TermsAndConditions = ({ closeDialog }: ITermsAndConditionsProps) => {
    const { className, element } = useFunctionalBem(TermsAndConditions);

    const terms = i18n.SignupPage.terms;

    return (
        <div className={className}>
            <div className={element('terms')}>
                {terms.map(({ header, content }) => (
                    <>
                        <p>
                            <b>{header}</b>
                        </p>
                        {content.map(entry => (
                            <p>{entry}</p>
                        ))}
                    </>
                ))}
            </div>
            <div className={element('buttons')}>
                <Button color="warning" variant="contained" onClick={() => closeDialog(false)}>
                    {i18n.SignupPage.refuse}
                </Button>
                <Button color="primary" variant="contained" onClick={() => closeDialog(true)}>
                    {i18n.SignupPage.agree}
                </Button>
            </div>
        </div>
    );
};
