import { Typography, TypographyProps, TypographyVariant } from '@material-ui/core';
import React from 'react';

const CustomVariants = {
    TitleRegular: { fontSize: 20 },
    TitleBold: { fontSize: 20, fontWeight: 600, lineHeight: 1.2 },
    BodyRegular: { fontSize: 16, lineHeight: 1.375 },
    BodyRegularItalic: { fontSize: 16, fontStyle: 'italic' },
    BodyBold: { fontSize: 16, fontWeight: 600, lineHeight: 1.375 },
    TableRegular: { fontSize: 14 },
    TableBold: { fontSize: 14, fontWeight: 600 },
    SmallText: { fontSize: 12 },
    SmallTextBold: { fontSize: 12, fontWeight: 700 },
} as const;

const ellipsisStyle = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
} as const;

type CustomVariant = keyof typeof CustomVariants;

type IVariant = TypographyVariant | CustomVariant;

export type TextProps = Omit<TypographyProps, 'variant'> & {
    variant?: IVariant;
    ellipsis?: boolean;
};

const isMUIVariant = (v: IVariant): v is TypographyVariant => !CustomVariants[v as CustomVariant];

export const Text = ({ variant, ellipsis, ...props }: TextProps) => {
    const textStyle = variant && !isMUIVariant(variant) ? CustomVariants[variant] : undefined;
    const muiVariant = variant && isMUIVariant(variant) ? variant : undefined;

    const style = { ...textStyle, ...(ellipsis && ellipsisStyle), ...props.style };

    return <Typography {...props} variant={muiVariant} style={style} />;
};
