import { getLandfillSubject } from '@common/abilities/landfills';
import { UserContextRole } from '@common/enums';

import { IDetailedUser, IUserLandfillInfo, IUserProjectInfo } from '~/@store/users/users.hooks';

const contextRoles = new Set(Object.values(UserContextRole));

const isContextRole = (role: string): role is UserContextRole => contextRoles.has(role as UserContextRole);

export const getProjectContextRole = (user: IDetailedUser, project: IUserProjectInfo) =>
    project.userLinks.find(l => l.userId === user.id)?.role || UserContextRole.UNKNOWN;

export const getLandfillContextRole = (user: IDetailedUser, landfill: IUserLandfillInfo) =>
    getLandfillSubject(user, landfill).relationRole.find(isContextRole) || UserContextRole.UNKNOWN;
