import { LinearProgress } from '@material-ui/core';
import React, { FC, useCallback } from 'react';

import { useProgressState } from '~/@components/@hooks';
import { Checkbox } from '~/@components/Checkbox';
import { LandfillWeightReceiptInfo } from '~/@user-store/weightReceipts/landfillWeightReceipts.store';
import { updateDeliveryLineReceiverCheck } from '~/@user-store/weightReceipts/weightReceiptsActions';

type Props = {
    landfillId: string;
    line: LandfillWeightReceiptInfo;
    disabled?: boolean;
};

export const ReceiverCheckField: FC<Props> = ({ landfillId, line, disabled = false }) => {
    const [isLoading, update] = useProgressState(updateDeliveryLineReceiverCheck);

    const onChange = useCallback((value: boolean) => update(landfillId, line.id, value), [landfillId, line, update]);

    return (
        <>
            <Checkbox onChange={onChange} checked={line.receiverCheck} disabled={isLoading || disabled} />
            {isLoading && <LinearProgress />}
        </>
    );
};
