import React from 'react';
import { useParams } from 'react-router';

import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { IDetailedProject, useDetailedProject } from '~/@store/projects';
import { ProjectDetailParams } from '~/@store/routes';

import { ProjectTabs } from './ProjectTabs';

export const ProjectDetailPage = () => {
    const { id: projectId } = useParams<ProjectDetailParams>();

    const projectQuery = useDetailedProject(projectId);

    return (
        <QueryStateHandler query={projectQuery} dataGetter={query => query.data?.project || undefined}>
            {(project: IDetailedProject) => <ProjectTabs project={project} />}
        </QueryStateHandler>
    );
};
