import { ReleaseNoteType } from '@common/enums';
import React from 'react';

import { Text } from '~/@components/Text';
import { IReleaseNote } from '~/@store/releaseNotes/releaseNotes.types';
import i18n from '~/i18n';

import { ReleaseNoteListItem } from './ReleaseNoteListItem';

type Props = {
    notes: IReleaseNote[];
    type: ReleaseNoteType;
};

export const ReleaseNotesList = ({ notes, type }: Props) => {
    const displayNotes = notes.filter(n => n.type === type);

    return (
        <div>
            {displayNotes.map(note => (
                <ReleaseNoteListItem key={note.id} note={note} />
            ))}
            {!displayNotes.length && (
                <Text variant="BodyRegular" color="textSecondary">
                    {i18n.emptyList}
                </Text>
            )}
        </div>
    );
};
