import './TopBarLogo.scss';

import React, { VFC } from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import { useGoHome } from '~/@store/routes';

import brandSign from '../../../../common/assets/pinpointerBrandSign.png';
import logo from '../../../../common/assets/pinpointerLogotypeWhite.png';

export const TopBarLogo: VFC = () => {
    const { className, element } = useFunctionalBem(TopBarLogo);
    const goHome = useGoHome();

    return (
        <div className={className} onClick={goHome}>
            <div className={element('brand-sign')}>
                <img src={brandSign} alt="brand sign" />
            </div>
            <div className={element('logotype')}>
                <img src={logo} alt="logotype" />
            </div>
        </div>
    );
};
