import { FormControl, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { FormikContextType } from 'formik';
import React, { useCallback } from 'react';

import { Checkbox } from '../Checkbox';

type FormikBooleanFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends boolean ? TFieldName : never;
    formik: FormikContextType<TValues>;
    label: React.ReactNode;
    className?: string;
    disabled?: boolean;
};

export function FormikBooleanField<TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    label,
    className = '',
    disabled,
}: FormikBooleanFieldProps<TValues, TFieldName>) {
    const { root } = useStyles();

    const handleChange = useCallback(() => {
        formik.setFieldTouched(name, true);
        formik.setFieldValue(name, !formik.values[name]);
    }, [formik, name]);

    return (
        <FormControl fullWidth className={cn(root, className)}>
            <Checkbox
                color="primary"
                checked={Boolean(formik.values[name])}
                label={label}
                onChange={handleChange}
                disabled={disabled || formik.isSubmitting}
            />
        </FormControl>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(),
    },
}));
