import { observer } from 'mobx-react';
import React from 'react';

import { MenuGrow } from '~/@components/MenuGrow';
import { DELIVERY_LINE_VIEW_MODE, deliveryLinesStore } from '~/@store/deliveryLines';
import i18n from '~/i18n';

type Item = {
    label: string;
    mode: DELIVERY_LINE_VIEW_MODE;
};

const items: Item[] = [
    {
        label: i18n.DeliveryLinesTable.completed,
        mode: DELIVERY_LINE_VIEW_MODE.DELIVERY_LINES,
    },
    {
        label: i18n.DeliveryLinesTable.inProgress,
        mode: DELIVERY_LINE_VIEW_MODE.IN_PROGRESS_LINES,
    },
    {
        label: i18n.DeliveryLinesTable.deleted,
        mode: DELIVERY_LINE_VIEW_MODE.DELETED_LINES,
    },
];

const render = (item: Item) => item.label;

type Props = {
    hideInProgress?: boolean;
};

export const DeliveryLineModeSwitch = observer(({ hideInProgress = false }: Props) => {
    const onChange = (item: Item) => deliveryLinesStore.setMode(item.mode);
    const mode = deliveryLinesStore.mode;
    const value = items.find(item => item.mode === mode);

    const filteredItems = hideInProgress
        ? items.filter(i => i.mode !== DELIVERY_LINE_VIEW_MODE.IN_PROGRESS_LINES)
        : items;

    return <MenuGrow renderValue={render} items={filteredItems} onChange={onChange} value={value} />;
});
