import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { AdminDeliveryLineHistoryQuery } from '~/@store/deliveryLines/deliveryLines.queries';
import {
    AdminDeliveryLineHistoryQuery as IDeliveryLineHistoryQuery,
    AdminDeliveryLineHistoryQueryVariables,
} from '~/graphql';

export const useDeliveryLineHistory = (
    id: string
): QueryResult<IDeliveryLineHistoryQuery, AdminDeliveryLineHistoryQueryVariables> => {
    return useQuery<IDeliveryLineHistoryQuery, AdminDeliveryLineHistoryQueryVariables>(AdminDeliveryLineHistoryQuery, {
        variables: { id },
    });
};
