import React from 'react';

import { useAccountChangePassword, useAccountUpdateMutation } from '~/@store/account';
import { useUserAbilities } from '~/contexts';

import AccountFormik from './AccountFormik';

type Props = {
    onClose: () => void;
};

export const Account = ({ onClose }: Props) => {
    const { user } = useUserAbilities();
    const [updateUser] = useAccountUpdateMutation();
    const [changePassword] = useAccountChangePassword();

    return <AccountFormik user={user} onUpdateUser={updateUser} onChangePassword={changePassword} onClose={onClose} />;
};
