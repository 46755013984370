import './GaugeChart.scss';

import React from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';

export const GaugeChart = ({ current, total }: { current: number; total: number }) => {
    const { className, element } = useFunctionalBem(GaugeChart);

    const percents = total >= 1 ? Math.floor((current / total) * 100) : 0;
    const halfRadius = 295;
    const dashValue = percents ? 295 - (halfRadius / 100) * percents : 296;
    const dasharray = `0, ${dashValue}, ${halfRadius}`;

    return (
        <div className={className}>
            <svg viewBox="0 0 200 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M194 100C194 48.0852 151.915 6 100 6C48.0852 6 6 48.0852 6 100"
                    className={element('diagram-line')}
                    strokeDasharray={dasharray}
                />
                <path
                    d="M194 100C194 48.0852 151.915 6 100 6C48.0852 6 6 48.0852 6 100"
                    className={element('diagram-line-background')}
                />
            </svg>
            <div className={element('percents')} title={`${percents}%`}>
                {percents}%
            </div>
        </div>
    );
};
