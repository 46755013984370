import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const SearchIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 4.00125C7.13401 4.00125 4 7.13526 4 11.0013C4 14.8672 7.13401 18.0013 11 18.0013C14.866 18.0013 18 14.8672 18 11.0013C18 7.13526 14.866 4.00125 11 4.00125ZM2 11.0013C2 6.03069 6.02944 2.00125 11 2.00125C15.9706 2.00125 20 6.03069 20 11.0013C20 15.9718 15.9706 20.0013 11 20.0013C6.02944 20.0013 2 15.9718 2 11.0013Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9429 15.9441C16.3334 15.5536 16.9666 15.5536 17.3571 15.9441L21.7071 20.2941C22.0977 20.6847 22.0977 21.3178 21.7071 21.7084C21.3166 22.0989 20.6834 22.0989 20.2929 21.7084L15.9429 17.3584C15.5524 16.9678 15.5524 16.3347 15.9429 15.9441Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
