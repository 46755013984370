import { useQuery } from '@apollo/client';

import client from '~/apolloClient';
import {
    AdminAllReleaseNotesQuery,
    CreateReleaseNoteMutation,
    CreateReleaseNoteMutationVariables,
    DeleteReleaseNoteMutation,
    DeleteReleaseNoteMutationVariables,
    ReleaseNotesByTypeQuery,
    ReleaseNotesByTypeQueryVariables,
    ReleaseNoteType,
    UpdateReleaseNoteMutation,
    UpdateReleaseNoteMutationVariables,
} from '~/graphql';

import * as queries from './releaseNotes.queries';

export const useReleaseNotes = () => useQuery<AdminAllReleaseNotesQuery>(queries.AdminAllReleaseNotesQuery);

export const createNote = (text: string, releaseDate: Date, type: ReleaseNoteType) =>
    client.mutate<CreateReleaseNoteMutation, CreateReleaseNoteMutationVariables>({
        mutation: queries.CreateReleaseNoteMutation,
        variables: { input: { text, releaseDate: releaseDate.toISOString(), type } },
        refetchQueries: [{ query: queries.AdminAllReleaseNotesQuery }],
        awaitRefetchQueries: true,
    });

export const updateNote = (id: string, text: string, releaseDate: Date, type: ReleaseNoteType) =>
    client.mutate<UpdateReleaseNoteMutation, UpdateReleaseNoteMutationVariables>({
        mutation: queries.UpdateReleaseNoteMutation,
        variables: { id, input: { text, releaseDate: releaseDate.toISOString(), type } },
        refetchQueries: [{ query: queries.AdminAllReleaseNotesQuery }],
        awaitRefetchQueries: true,
    });

export const removeNote = (id: string) =>
    client.mutate<DeleteReleaseNoteMutation, DeleteReleaseNoteMutationVariables>({
        mutation: queries.DeleteReleaseNoteMutation,
        variables: { id },
        refetchQueries: [{ query: queries.AdminAllReleaseNotesQuery }],
        awaitRefetchQueries: true,
    });

export const getReleaseNotesByType = (type: ReleaseNoteType) =>
    client
        .query<ReleaseNotesByTypeQuery, ReleaseNotesByTypeQueryVariables>({
            query: queries.ReleaseNotesByTypeQuery,
            variables: { type },
            fetchPolicy: 'cache-first',
        })
        .then(res => res.data.releaseNotes);
