import './FloatMobilePanel.scss';

import React, { createContext, useContext } from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ArrowDownIcon } from '~/@sochi-components/Icons';

type FloatPanelProps = {
    closeDisabled: boolean;
    open: boolean;
    switchMode: () => void;
};

export const FloatPanelContext = createContext<FloatPanelProps | null>(null);

type Props = {
    children: React.ReactNode;
};

export const FloatMobilePanel = ({ children }: Props) => {
    const { block, element } = useFunctionalBem(FloatMobilePanel);

    const context = useContext(FloatPanelContext);

    const closeDisabled = context?.closeDisabled ?? true;
    const switchMode = context?.switchMode;
    const open = context?.open ?? true;

    const changeState = () => {
        if (!closeDisabled && switchMode) switchMode();
    };

    return (
        <div className={block({ closed: !open })}>
            <div className={element('open-button', { open })} onClick={changeState}>
                <ArrowDownIcon color={closeDisabled ? 'disabled' : 'primary'} fontSize="inherit" />
            </div>
            <div className={element('content')}>{children}</div>
        </div>
    );
};
