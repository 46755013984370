import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useMemo } from 'react';
import { showLandfillBatchForm } from 'src/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { COLOR } from '~/@sochi-components/@theme';
import { LandfillBatchCard } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchCard';
import { useUserAbilities } from '~/contexts';
import { LandfillQuery_landfill } from '~/graphql';
import { canUpdateLandfill } from '~/utils/auth';

type Props = {
    landfill: LandfillQuery_landfill;
};

export const LandfillBatches: FC<Props> = ({ landfill }) => {
    const { root, header, container } = useStyles();

    const { user, ability } = useUserAbilities();

    const readOnly = !canUpdateLandfill(user, ability, landfill);

    const sortedBatches = useMemo(() => {
        const result = Array.from(landfill.batches);
        result.sort((a, b) =>
            a.closedAt ? (b.closedAt ? b.id.localeCompare(a.id) : 1) : b.closedAt ? -1 : b.id.localeCompare(a.id)
        );

        return result;
    }, [landfill]);

    return (
        <div className={root}>
            <div className={header}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => showLandfillBatchForm(landfill, null)}
                    disabled={readOnly}>
                    <LocalizableText code={'LandfillPage.batches.create'} />
                </Button>
            </div>
            <div className={container}>
                {sortedBatches.map(b => (
                    <LandfillBatchCard batch={b} landfill={landfill} readOnly={readOnly} key={b.id} />
                ))}
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        background: COLOR.grayBackground,
        position: 'relative',
        padding: '0 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        [theme.breakpoints.down('md')]: {
            height: '0 10px 20px',
            gap: '10px',
        },
        justifyContent: 'center',
        marginTop: '20px',
        width: '100%',
        alignItems: 'center',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
    },
    container: {
        width: '60%',
        gap: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'calc(100vh - 310px)',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: 'calc(100vh - 130px)',
        },
        overflowY: 'auto',
    },
}));
