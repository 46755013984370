import { getSettingSubject } from '@common/abilities/settings';
import { AbilityCan } from '@common/enums';

import { useUserAbilities } from '~/contexts';

export const useSettingsPermissions = () => {
    const { user, ability } = useUserAbilities();

    return {
        canEdit: ability.can(AbilityCan.UPDATE, getSettingSubject(user)),
    };
};
