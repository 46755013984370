import { ContaminationType } from '@common/enums';
import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { BooleanFilter, SortSection, StringFilter, TableHeadCell } from '~/@components/Table/TableHeadCell';
import { DumpLoadFilterFields, dumpLoadsStore } from '~/@store/dumpLoads';
import { ContaminationLevelFilter } from '~/@views/AdminView/common/ContaminationLevelFilter';
import { DumpTypeFilter } from '~/@views/AdminView/common/DumpTypeFilter';
import { DumpLoadModeFilter } from '~/@views/AdminView/DumpLoadsPage/DumpLoadsHeadCell/DumpLoadModeFilter';
import { SortInput } from '~/graphql';
import i18n from '~/i18n';

import { ToxicAnalysisApprovalStateFilter } from './ToxicAnalysisApprovalStateFilter';

type Props = {
    title: React.ReactNode;
    fieldName: DumpLoadFilterFields;
    withFilter?: boolean;
    withSort?: boolean;
};

export const DumpLoadsHeadCell = ({ title, fieldName, withFilter, withSort }: Props) => {
    const sortValue = useObservable(() => dumpLoadsStore.sort.value);
    const setSortValue = useCallback((v: SortInput) => dumpLoadsStore.sort.setValue(v), []);

    const sortLabels = useMemo(() => {
        switch (fieldName) {
            case DumpLoadFilterFields.serialNumber:
                return i18n.SortNumber;
            default:
                return i18n.SortString;
        }
    }, [fieldName]);

    const sortSection = useMemo(() => {
        if (!withSort) return null;

        return <SortSection fieldName={fieldName} sort={sortValue} setSort={setSortValue} labels={sortLabels} />;
    }, [withSort, fieldName, sortValue, setSortValue, sortLabels]);

    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        switch (fieldName) {
            case DumpLoadFilterFields.serialNumber:
            case DumpLoadFilterFields.projectName:
            case DumpLoadFilterFields.customerName:
            case DumpLoadFilterFields.FANumber:
            case DumpLoadFilterFields.comments:
            case DumpLoadFilterFields.landfillName:
            case DumpLoadFilterFields.ownerName:
            case DumpLoadFilterFields.joinCode:
                return (
                    <StringFilter
                        valueGetter={() => dumpLoadsStore.filter.values[fieldName]}
                        setValue={v => dumpLoadsStore.filter.setValue(fieldName, v)}
                    />
                );
            case DumpLoadFilterFields.hasToxicAnalysisFiles:
            case DumpLoadFilterFields.contractSigned:
            case DumpLoadFilterFields.siteConfirmed:
            case DumpLoadFilterFields.creditCheck:
                return (
                    <BooleanFilter
                        valueGetter={() => dumpLoadsStore.filter.values[fieldName]}
                        setValue={v => dumpLoadsStore.filter.setValue(fieldName, v)}
                    />
                );
            case DumpLoadFilterFields.dumpType:
                return (
                    <DumpTypeFilter
                        valueGetter={() => dumpLoadsStore.filter.values[fieldName]}
                        setValue={v => dumpLoadsStore.filter.setValue(fieldName, v)}
                    />
                );
            case DumpLoadFilterFields.solidTestResult:
                return (
                    <ContaminationLevelFilter
                        valueGetter={() => dumpLoadsStore.filter.values[fieldName]}
                        setValue={v => dumpLoadsStore.filter.setValue(fieldName, v)}
                        mode={ContaminationType.SOLID}
                    />
                );
            case DumpLoadFilterFields.toxicAnalysisApprovalState:
                return <ToxicAnalysisApprovalStateFilter />;
            case DumpLoadFilterFields.mode:
                return <DumpLoadModeFilter />;
            default:
                return null;
        }
    }, [withFilter, fieldName]);

    return (
        <TableHeadCell title={title} sortDirection={sortValue?.field === fieldName ? sortValue.direction : null}>
            {sortSection}
            {filterSection}
        </TableHeadCell>
    );
};
