import { gql } from '@apollo/client';

import { CompanyShortFragment } from '~/@store/companies';
import { UserRecordFragment } from '~/@store/users/users.queries.fragment';

import { DumpLoadDetailedFragment } from '../dumpLoads/dumpLoads.queries.fragment';
import { LocationFragment } from '../locations/location.queries';

export const ProjectShortFragment = gql`
    fragment ProjectShortFragment on Project {
        id
        name
        status
        location {
            ...LocationFragment
        }
        comment
        customerId
        customer {
            ...CompanyShortFragment
        }
        owner {
            ...UserRecordFragment
        }
    }

    ${LocationFragment}
    ${CompanyShortFragment}
    ${UserRecordFragment}
`;

export const ProjectDetailedFragment = gql`
    fragment ProjectDetailedFragment on Project {
        id
        name
        status
        owner {
            id
            name
            surname
            email
            role
        }
        startDate
        endDate
        location {
            ...LocationFragment
        }
        customer {
            ...CompanyShortFragment
        }
        customerId
        dumpLoads {
            ...DumpLoadDetailedFragment
        }
        comment
        ver
        users {
            id
            name
            surname
            customer {
                id
                name
            }
            email
            phoneNumber
            role
        }
        userLinks {
            userId
            role
        }
        invoiceReference
        certificates
        totalAmountInTons
        transportPerWeek
        totalEstimatedPrice
        joinCode
        externalId
        whatsappGroupLink
    }

    ${LocationFragment}
    ${CompanyShortFragment}
    ${DumpLoadDetailedFragment}
`;

export const ProjectIdDumpLoadIdAnalysisFilesFragment = gql`
    fragment ProjectIdDumpLoadIdAnalysisFilesFragment on Project {
        id
        ver
        dumpLoads {
            id
            analysisFiles {
                id
                name
                uploadDate
            }
        }
    }
`;
