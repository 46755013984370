import React from 'react';

import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { showCustomDialog } from '~/@store/common';
import { IDeliveryLine, useDeliveryLine } from '~/@store/deliveryLines';

import { DeliveryLineUpdateForm } from './DeliveryLineUpdateForm';

type DeliveryLineEditDialogProps = {
    deliveryLineId: string;
    onClose: (v: boolean) => void;
    mode: Mode;
};

type Mode = 'update' | 'complete';

const DeliveryLineUpdateDialog = ({ deliveryLineId, onClose, mode }: DeliveryLineEditDialogProps) => {
    const query = useDeliveryLine(deliveryLineId);

    return (
        <CardDialog
            onClose={() => onClose(false)}
            title={
                <LocalizableText
                    code={mode === 'update' ? 'DeliveryLineCard.updateHeader' : 'DeliveryLineCard.completeHeader'}
                />
            }
            maxWidth="lg"
            fullWidth>
            <QueryStateHandler query={query} dataGetter={q => q.data?.pplDeliveryLine || undefined}>
                {(deliveryLine: IDeliveryLine) => (
                    <DeliveryLineUpdateForm onClose={onClose} deliveryLine={deliveryLine} />
                )}
            </QueryStateHandler>
        </CardDialog>
    );
};

export const showDeliveryLineUpdateDialog = (deliveryLineId: string, mode: Mode = 'update') =>
    showCustomDialog({
        render: closeDialog => (
            <DeliveryLineUpdateDialog onClose={closeDialog} deliveryLineId={deliveryLineId} mode={mode} />
        ),
    });
