import { DumpLoadStatus } from '@common/enums';
import { FilterRange } from '@common/types';
import { action, computed, observable } from 'mobx';

import { FilterStore, SortStore } from '~/@store/common';
import { IDumpLoad } from '~/@views/UserView/ProjectPage/ProjectPriceCalculator/AddEditMassForm/AddEditMass.types';
import i18n from '~/i18n';
import { DumpLoadModeMatching, getLandfillPricePerTon } from '~/utils/dumpLoad';
import { filterAndSortCollection, getNumberRangeFilterFn, getStringSearchFilterFn } from '~/utils/filters';

const DEFAULT_DUMPLOAD_SORT = {
    field: 'serialNumber',
    direction: -1,
};

export const dumpLoadStatusFilterValues = Object.keys(DumpLoadStatus).map(key => {
    const status = key as DumpLoadStatus;

    return {
        id: status,
        value: status,
        name: i18n.DumploadStatusesSochi[status],
    };
});

export type DumpLoadsStoreFilter = {
    serialNumber?: string | null;
    dumpTypeId?: string | null;
    comment?: string | null;
    status?: DumpLoadStatus | null;
    weight?: FilterRange<number> | null;
    price?: FilterRange<number> | null;
    tripsCount?: FilterRange<number> | null;
    mode?: DumpLoadModeMatching;
};

export enum DumpLoadsFilterField {
    serialNumber = 'serialNumber',
    dumpTypeId = 'dumpTypeId',
    comment = 'comment',
    status = 'status',
    weight = 'weight',
    price = 'price',
    tripsCount = 'tripsCount',
    mode = 'mode',
}

const modeMatch = (mode: DumpLoadModeMatching) => {
    switch (mode) {
        case DumpLoadModeMatching.OUTBOUND_WITHOUT_MATCHING:
            return (d: IDumpLoad) => !d.inbound && d.skipMatching;
        case DumpLoadModeMatching.OUTBOUND_WITH_MATCHING:
            return (d: IDumpLoad) => !d.inbound && !d.skipMatching;
        case DumpLoadModeMatching.INBOUND_WITHOUT_MATCHING:
            return (d: IDumpLoad) => d.inbound && d.skipMatching;
        case DumpLoadModeMatching.INBOUND_WITH_MATCHING:
            return (d: IDumpLoad) => d.inbound && !d.skipMatching;
    }
};

class DumpLoadsStore {
    filter = new FilterStore<DumpLoadsStoreFilter>();
    sort = new SortStore(DEFAULT_DUMPLOAD_SORT);

    @observable
    list: IDumpLoad[] = [];

    @action
    setList(newList: IDumpLoad[]) {
        this.list = newList;
    }

    @action
    clear() {
        this.filter.clearValues();
        this.sort.clearSort();
    }

    @computed
    get dumpTypesValues() {
        return this.list
            .filter(d => !!d.dumpType)
            .filter((d, ind, a) => a.findIndex(cur => cur.dumpType!.id === d.dumpType!.id) === ind)
            .map(d => ({
                id: d.dumpType!.id,
                value: d.dumpType!.id,
                name: d.dumpType!.name,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    }

    @computed
    get filteredList(): IDumpLoad[] {
        const { serialNumber, dumpTypeId, comment, status, weight, price, tripsCount, mode } = this.filter.values;

        const filters: ((item: IDumpLoad) => boolean)[] = [];

        if (serialNumber) filters.push(getStringSearchFilterFn(serialNumber, r => r.serialNumber));
        if (dumpTypeId) filters.push(item => item.dumpType?.id === dumpTypeId);
        if (comment) filters.push(getStringSearchFilterFn(comment, r => r.comment));
        if (weight) filters.push(getNumberRangeFilterFn(weight, r => r.amountInTons));
        if (status) filters.push(item => item.status === status);
        if (price) filters.push(getNumberRangeFilterFn(price, r => getLandfillPricePerTon(r)));
        if (tripsCount) filters.push(getNumberRangeFilterFn(tripsCount, r => r.totalTrips));
        if (mode) filters.push(item => modeMatch(mode)(item));

        return filterAndSortCollection(this.list, filters, this.sort.value);
    }
}

export const dumpLoadsStore = new DumpLoadsStore();
