import { AbilityCan, AbilitySubjects, DeviationStatus, ProjectStatus, UserContextRole, UserRole } from '../../enums';
import { IAbilityFunction, IAbilityUser } from '../abilities.types';
import { LandfillSubject } from '../landfills';
import { ICaslCondition } from '../utils';
import { IDeviationCalculatedSubject } from './deviationTypes';

const { CRUD, READ, CREATE, UPDATE } = AbilityCan;
const { PROJECT_USER, RECEIVER, AUTHOR, GUEST } = UserContextRole;
const { ADMIN, EMPLOYEE, EXTERNAL_RECEIVER } = UserRole;
const { DELETED } = ProjectStatus;
const { CLOSED } = DeviationStatus;
const { LANDFILL_DELETED } = LandfillSubject;

export function defineAbilitiesForProjectDeviations(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<IDeviationCalculatedSubject>;

    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _allow(can, AbilitySubjects.PROJECT_DEVIATION, shape);

    const forbid = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _forbid(can, AbilitySubjects.PROJECT_DEVIATION, shape);

    allow([READ, CREATE], { relationRole: { $in: [ADMIN, EMPLOYEE, PROJECT_USER] } });
    allow(READ, { relationRole: GUEST });
    allow(UPDATE, { relationRole: { $in: [ADMIN, EMPLOYEE, AUTHOR] } });

    // Forbid everything for deleted projects
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: DELETED });

    // Forbid update for closed deviations
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, subSubject: CLOSED });
}

export function defineAbilitiesForLandfillDeviations(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<IDeviationCalculatedSubject>;

    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _allow(can, AbilitySubjects.LANDFILL_DEVIATION, shape);

    const forbid = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _forbid(can, AbilitySubjects.LANDFILL_DEVIATION, shape);

    allow([READ, CREATE], { relationRole: { $in: [ADMIN, EMPLOYEE, RECEIVER, GUEST] } });
    allow(UPDATE, { relationRole: { $in: [ADMIN, EMPLOYEE, AUTHOR] } });

    // Forbid everything for deleted landfills
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: LANDFILL_DELETED });

    // Forbid update for closed deviations
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, subSubject: CLOSED });
}

export function defineAbilitiesForDeviations(_: IAbilityUser, allow: IAbilityFunction, forbid: IAbilityFunction) {
    defineAbilitiesForProjectDeviations(allow, forbid);
    defineAbilitiesForLandfillDeviations(allow, forbid);
}
