import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';
import { DespatchAdviceSource } from '@common/enums';

import { AdminBeastDataQuery } from '~/@store/beastIntegration/beastIntegration.queries';
import {
    AdminBeastDataQuery as IAdminBeastDataQuery,
    AdminBeastDataQuery_despatchAdviceResponses,
    AdminBeastDataQuery_despatchAdvices,
    AdminBeastDataQueryVariables,
} from '~/graphql';

export type IDespatchAdvice = AdminBeastDataQuery_despatchAdvices;

export type IDespatchAdviceResponse = AdminBeastDataQuery_despatchAdviceResponses;

export const useBeastData = (
    entityId: string,
    source: DespatchAdviceSource
): QueryResult<IAdminBeastDataQuery, AdminBeastDataQueryVariables> => {
    return useQuery<IAdminBeastDataQuery, AdminBeastDataQueryVariables>(AdminBeastDataQuery, {
        variables: { entityId, source },
        fetchPolicy: 'network-only',
    });
};
