import { InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { endOfDay, startOfDay } from 'date-fns';
import React, { FC, ReactNode, useCallback } from 'react';

import { DEFAULT_DATE_PICKER_MAX_DATE, DEFAULT_DATE_PICKER_MIN_DATE } from './constants';
import { SochiDatePicker, TDateIOType } from './SochiDatePicker';

type SochiDateRangePickerProps = {
    onChange?: (value: { startDate: TDateIOType } | { endDate: TDateIOType }) => void;
    isStartDateReadOnly?: boolean;
    isEndDateReadOnly?: boolean;
    values: { startDate?: TDateIOType; endDate?: TDateIOType };
    minDate?: TDateIOType;
    maxDate?: TDateIOType;
    className?: string;
    label?: ReactNode;
    startDatePlaceholder?: string;
    endDatePlaceholder?: string;
    errorMessage?: string;
    message?: string;
    shouldHighlightDate?: (day: TDateIOType) => boolean;
};

export const SochiDateRangePicker: FC<SochiDateRangePickerProps> = ({
    onChange,
    isStartDateReadOnly,
    isEndDateReadOnly,
    values: { startDate = null, endDate = null },
    minDate,
    maxDate,
    className,
    label,
    startDatePlaceholder,
    endDatePlaceholder,
    errorMessage,
    message,
    shouldHighlightDate,
}) => {
    const classes = useStyles();

    const updateStartDate = useCallback(
        (startDate: TDateIOType) => {
            onChange && onChange({ startDate });
        },
        [onChange]
    );

    const updateEndDate = useCallback(
        (endDate: TDateIOType) => {
            onChange && onChange({ endDate });
        },
        [onChange]
    );

    const containerClasses = cn(classes.container, className);

    const hasError = !!errorMessage;
    const labelText = hasError ? errorMessage : label || '';

    return (
        <div className={containerClasses}>
            {labelText ? <InputLabel error={hasError}>{labelText}</InputLabel> : null}
            <div className={classes.pickers}>
                <SochiDatePicker
                    onChange={updateStartDate}
                    value={startDate}
                    autoOk
                    message={message}
                    placeholder={startDatePlaceholder}
                    popoverPosition="left"
                    disabled={isStartDateReadOnly}
                    shouldHighlightDate={shouldHighlightDate}
                    invalidDateMessage={null}
                    minDate={startOfDay(minDate || DEFAULT_DATE_PICKER_MIN_DATE)}
                    minDateMessage={null}
                    maxDate={endOfDay(endDate || DEFAULT_DATE_PICKER_MAX_DATE)}
                    maxDateMessage={null}
                />
                <SochiDatePicker
                    onChange={updateEndDate}
                    value={endDate}
                    autoOk
                    placeholder={endDatePlaceholder}
                    message={message}
                    popoverPosition="right"
                    disabled={isEndDateReadOnly}
                    shouldHighlightDate={shouldHighlightDate}
                    invalidDateMessage={null}
                    minDate={startOfDay(startDate || DEFAULT_DATE_PICKER_MIN_DATE)}
                    minDateMessage={null}
                    maxDate={endOfDay(maxDate || DEFAULT_DATE_PICKER_MAX_DATE)}
                    maxDateMessage={null}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        minWidth: '280px',
    },
    pickers: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px',
    },
});
