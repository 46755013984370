import { defaultEngine, defaultFuel } from '@common/constants';
import { makeMap } from '@common/utils';
import { FormLabel, IconButton, LinearProgress, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { Field, FieldArray } from 'formik';
import { FieldProps } from 'formik/dist/Field';
import { isString, uniqueId } from 'lodash';
import React, { useMemo } from 'react';

import { Grid } from '~/@components/Grid';
import { DeleteIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { NumberField } from '~/@components/NumberField';
import { Select } from '~/@components/Select';
import { Text } from '~/@components/Text';
import { TextField } from '~/@components/TextField';
import { useVehicleTypes } from '~/@store/vehicleTypes';
import { commonPrecisions } from '~/config/enum';
import { Engine, Fuel, UserRole, VehicleKind } from '~/graphql';

import { IFormVehicle, useEditUserFormikContext } from './UserEditFormik';

export const UserEditVehicles = () => {
    const formik = useEditUserFormikContext();
    const role = formik.values.role;
    const { listItem } = useStyles();

    const { data, loading } = useVehicleTypes();

    const vehicleTypeMap = useMemo(() => {
        return makeMap(data?.vehicleTypes || [], vt => vt.id);
    }, [data]);

    const onSiteVehicles = data?.vehicleTypes.filter(vt => vt.kind === VehicleKind.ON_SITE).map(vt => vt.id) || [];
    const transportVehicles = data?.vehicleTypes.filter(vt => vt.kind === VehicleKind.TRANSPORT).map(vt => vt.id) || [];

    const makeNewVehicle = (): IFormVehicle => ({
        __typename: 'Vehicle',
        licensePlate: '',
        capacity: 0,
        vehicleTypeId: '',
        fuel: defaultFuel,
        engine: defaultEngine,
        key: uniqueId(),
    });

    return (
        <div>
            <FormLabel>{<LocalizableText code={'UserEditPage.vehicles'} />}</FormLabel>
            {loading && <LinearProgress color="primary" />}
            <FieldArray name="vehicles" validateOnChange={false}>
                {({ push, handleRemove }) => (
                    <List>
                        {!formik.values.vehicles.length && (
                            <ListItem>
                                <ListItemText>
                                    <Text color="textSecondary" variant="TableRegular">
                                        <LocalizableText code={'UserEditPage.vehiclesEmptyList'} />
                                    </Text>
                                </ListItemText>
                            </ListItem>
                        )}
                        {formik.values.vehicles.map((vehicle, index) => (
                            <ListItem className={listItem} key={vehicle.key}>
                                <Text variant="BodyRegular">{index + 1} </Text>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Field name={`vehicles.${index}.licensePlate`}>
                                            {({ field, meta }: FieldProps) => (
                                                <TextField
                                                    {...field}
                                                    required
                                                    fullWidth
                                                    label={<LocalizableText code={'UserEditPage.licensePlate'} />}
                                                    error={meta.touched && !!meta.error}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field name={`vehicles.${index}.vehicleTypeId`}>
                                            {({ field, form, meta }: FieldProps) => (
                                                <Select
                                                    {...field}
                                                    required
                                                    fullWidth
                                                    onChange={v => {
                                                        form.setFieldTouched(field.name, true);
                                                        form.setFieldValue(field.name, v);
                                                    }}
                                                    label={<LocalizableText code={'UserEditPage.vehicleType'} />}
                                                    items={
                                                        role === UserRole.DRIVER ? transportVehicles : onSiteVehicles
                                                    }
                                                    renderValue={id => vehicleTypeMap.get(id)?.name || ''}
                                                    keyGetter={i => i}
                                                    error={meta.touched && !!meta.error}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field name={`vehicles.${index}.capacity`}>
                                            {({ field, form, meta }: FieldProps) => (
                                                <NumberField
                                                    {...field}
                                                    required
                                                    fullWidth
                                                    placeholder=""
                                                    label={<LocalizableText code={'UserEditPage.capacity'} />}
                                                    precision={commonPrecisions.amount}
                                                    onChange={v => {
                                                        form.setFieldTouched(field.name, true);
                                                        form.setFieldValue(field.name, v);
                                                    }}
                                                    error={meta.touched && !!meta.error}
                                                    disabled={role !== UserRole.DRIVER}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field name={`vehicles.${index}.fuel`}>
                                            {({ field, form, meta }: FieldProps) => (
                                                <Select
                                                    {...field}
                                                    fullWidth
                                                    onChange={v => {
                                                        form.setFieldTouched(field.name, true);
                                                        form.setFieldValue(field.name, v);
                                                    }}
                                                    label={<LocalizableText code={'UserEditPage.fuel'} />}
                                                    items={Object.values(Fuel)}
                                                    renderValue={i => i}
                                                    keyGetter={i => i}
                                                    error={meta.touched && !!meta.error}
                                                    addEmptyOption={false}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field name={`vehicles.${index}.engine`}>
                                            {({ field, form, meta }: FieldProps) => (
                                                <Select
                                                    {...field}
                                                    fullWidth
                                                    onChange={v => {
                                                        form.setFieldTouched(field.name, true);
                                                        form.setFieldValue(field.name, v);
                                                    }}
                                                    label={<LocalizableText code={'UserEditPage.engine'} />}
                                                    items={Object.values(Engine)}
                                                    renderValue={i => i}
                                                    keyGetter={i => i}
                                                    error={meta.touched && !!meta.error}
                                                    addEmptyOption={false}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                                <IconButton onClick={handleRemove(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        ))}
                        {isString(formik.errors.vehicles) && (
                            <ListItem dense>
                                <Text variant="TableBold" color="error">
                                    {formik.errors.vehicles}
                                </Text>
                            </ListItem>
                        )}
                        <ListItem button onClick={() => push(makeNewVehicle())}>
                            <ListItemText>
                                <Text variant="TableBold">
                                    <LocalizableText code={'UserEditPage.addVehicle'} />
                                </Text>
                            </ListItemText>
                        </ListItem>
                    </List>
                )}
            </FieldArray>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    listItem: {
        gap: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
    },
    column: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
}));
