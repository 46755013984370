import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import { SingleOptionFilter } from '~/@components/Table/TableHeadCell';
import {
    ISochiTableHeadCellProps,
    SochiSortSection,
    SochiStringFilter,
    SochiTableHeadCell,
} from '~/@sochi-components/SochiTable/SochiTableHeadCell';
import {
    contextRoleValues,
    ProjectUsersFilterField,
    projectUsersStore,
} from '~/@views/UserView/ProjectPage/ProjectUsers/projectUsers.store';
import { SortInput } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    fieldName: ProjectUsersFilterField;
    withFilter?: boolean;
    withSort?: boolean;
} & Omit<ISochiTableHeadCellProps, 'sortDirection' | 'children'>;

export const ProjectUsersTableHeadCell = ({ fieldName, withFilter, withSort, ...restProps }: Props) => {
    const sortValue = useObservable(() => projectUsersStore.sort.value);
    const setSortValue = useCallback((v: SortInput) => projectUsersStore.sort.setValue(v), []);

    const sortLabels = useMemo(() => {
        switch (fieldName) {
            default:
                return i18n.SortString;
        }
    }, [fieldName]);

    const sortSection = useMemo(() => {
        if (!withSort) return null;

        return <SochiSortSection fieldName={fieldName} sort={sortValue} setSort={setSortValue} labels={sortLabels} />;
    }, [withSort, fieldName, sortValue, setSortValue, sortLabels]);

    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        switch (fieldName) {
            case ProjectUsersFilterField.firstName:
            case ProjectUsersFilterField.lastName:
            case ProjectUsersFilterField.phone:
            case ProjectUsersFilterField.email:
                return (
                    <SochiStringFilter
                        valueGetter={() => projectUsersStore.filter.values[fieldName]}
                        setValue={v => projectUsersStore.filter.setValue(fieldName, v)}
                    />
                );
            case ProjectUsersFilterField.role:
                return (
                    <SingleOptionFilter
                        values={contextRoleValues}
                        valueGetter={() => projectUsersStore.filter.values[fieldName]}
                        setValue={v => projectUsersStore.filter.setValue(fieldName, v)}
                    />
                );

            default:
                return null;
        }
    }, [withFilter, fieldName]);

    return (
        <SochiTableHeadCell {...restProps} sortDirection={sortValue?.field === fieldName ? sortValue.direction : null}>
            {sortSection}
            {filterSection}
        </SochiTableHeadCell>
    );
};
