import './MaterialCard.scss';

import { ContaminationType } from '@common/enums';
import React from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { getBem } from '~/@sochi-components/@bem';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { ToxicLevelColorSchemas } from '~/@store/substances';
import { getContaminationLevelLabel } from '~/@store/toxicLimits/toxicLimits.helpers';
import type { LandfillQuery_landfill_subareas } from '~/graphql';
import i18n from '~/i18n';
import { fmtCurr, fmtVolInTon } from '~/utils/numbers';

type MaterialCardProps = {
    subarea: LandfillQuery_landfill_subareas;
    canReadGrossPrices: boolean;
    canUpdateOnHold: boolean;
    canUpdate: boolean;
    onEdit: (subarea: LandfillQuery_landfill_subareas) => void;
    onHoldToggle: (subarea: LandfillQuery_landfill_subareas) => void;
    currentAmount: number;
};

export class MaterialCard extends React.Component<MaterialCardProps> {
    bem = getBem(this);

    render() {
        const { className, element } = this.bem;
        const { subarea, onHoldToggle, onEdit, canReadGrossPrices, canUpdateOnHold, canUpdate, currentAmount } =
            this.props;

        return (
            <div className={className}>
                <div className={element('basic-container')}>
                    <div className={element('left-column')}>
                        <div className={element('title')} title={subarea.dumpType?.name || i18n.NA}>
                            {subarea.dumpType?.name || i18n.NA}
                        </div>
                        <div>{`${i18n.Materials.externalId}: ${subarea.externalId}`}</div>
                        <div className={element('toc-block')}>
                            <LocalizableText code={'Materials.TOCValue'} />
                            <div className={element('toc-value')}>
                                {subarea.allowTOC ? `${subarea.TOCPercent}%` : '-'}
                            </div>
                        </div>
                        <div className={element('invasive-species')} title={subarea.invasiveSpecies || ''}>
                            <LocalizableText
                                code={
                                    subarea.allowInvasiveSpecies
                                        ? 'Materials.allowInvasiveSpecies'
                                        : 'Materials.invasiveSpeciesNotAllowed'
                                }
                            />
                        </div>
                        <div className={element('comment')} title={subarea.comment || ''}>
                            {subarea.comment}
                        </div>
                    </div>
                    <div className={element('right-column')}>
                        <div className={element('status', { onHold: subarea.onHold })}>
                            <LocalizableText
                                code={
                                    subarea.onHold
                                        ? 'Materials.onHold'
                                        : subarea.isProvider
                                        ? 'Materials.outgoing'
                                        : 'Materials.incoming'
                                }
                            />
                        </div>
                        {subarea.isProvider ? (
                            <Text variant="BodyBold">
                                <LocalizableText code="Materials.isProvider" />
                            </Text>
                        ) : subarea.solidTestResult ? (
                            <div
                                className={element('contamination-info')}
                                style={ToxicLevelColorSchemas[subarea.solidTestResult]}>
                                {getContaminationLevelLabel(subarea.solidTestResult, ContaminationType.SOLID)}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={element('prices-block')}>
                    <div className={element('price-element')}>
                        <div>
                            <LocalizableText code={'Materials.priceBase'} />
                        </div>
                        <div>
                            <span className={element('price-netto')}>{fmtCurr(subarea.priceBase)}</span>
                            {canReadGrossPrices && <LocalizableText code={'Materials.netto'} />}
                        </div>
                        {canReadGrossPrices && (
                            <div>
                                <span className={element('price-brutto')}>{fmtCurr(subarea.grossPriceBase)}</span>
                                <LocalizableText code={'Materials.brutto'} />
                            </div>
                        )}
                    </div>
                    <div className={element('price-element')}>
                        <div>
                            <LocalizableText code={'Materials.priceOver50cm'} />
                        </div>
                        <div>
                            <span className={element('price-netto')}>{fmtCurr(subarea.priceOver50cm, true)}</span>
                            {canReadGrossPrices && <LocalizableText code={'Materials.netto'} />}
                        </div>
                        {canReadGrossPrices && (
                            <div>
                                <span className={element('price-brutto')}>
                                    {fmtCurr(subarea.grossPriceOver50cm, true)}
                                </span>
                                <LocalizableText code={'Materials.brutto'} />
                            </div>
                        )}
                    </div>
                    <div className={element('price-element')}>
                        <div>
                            <LocalizableText code={'Materials.priceNotStackable'} />
                        </div>
                        <div>
                            <span className={element('price-netto')}>{fmtCurr(subarea.priceNotStackable, true)}</span>
                            {canReadGrossPrices && <LocalizableText code={'Materials.netto'} />}
                        </div>
                        {canReadGrossPrices && (
                            <div>
                                <span className={element('price-brutto')}>
                                    {fmtCurr(subarea.grossPriceNotStackable, true)}
                                </span>
                                <LocalizableText code={'Materials.brutto'} />
                            </div>
                        )}
                    </div>
                </div>
                <div className={element('footer')}>
                    <div className={element('current-amount')}>
                        <LocalizableText code={'Materials.currentAmount'} />:{' '}
                        <span className={element('current-amount-value')}>{fmtVolInTon(currentAmount)}</span>
                    </div>
                    <ButtonGroup>
                        {canUpdateOnHold && (
                            <Button color="primary" variant="outlined" onClick={() => onHoldToggle(subarea)}>
                                <LocalizableText code={subarea.onHold ? 'Materials.resume' : 'Materials.hold'} />
                            </Button>
                        )}
                        {canUpdate && (
                            <Button color="primary" variant="contained" onClick={() => onEdit(subarea)}>
                                <LocalizableText code={'edit'} />
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
            </div>
        );
    }
}
