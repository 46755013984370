import React from 'react';

import { ComponentDialogModel, MessageDialogModel } from '../../stores/models';
import { SochiComponentDialog } from './SochiComponentDialog';
import { SochiMessageDialog } from './SochiMessageDialog';

export const SochiDialog = ({ dialog }: { dialog: MessageDialogModel | ComponentDialogModel }) => {
    if (dialog instanceof MessageDialogModel) {
        return <SochiMessageDialog dialog={dialog} />;
    }

    if (dialog instanceof ComponentDialogModel) {
        return <SochiComponentDialog dialog={dialog} />;
    }

    return null;
};
