import React from 'react';

import { NullableFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn } from '~/@components/Table';
import { ICompany } from '~/@store/companies';
import { CompaniesStoreFilterFields } from '~/@store/companies/companies.store';
import { CompaniesHeadCell } from '~/@views/AdminView/CompaniesPage/CompaniesTable/CompaniesHeadCell';
import { CompanyCommentsCell } from '~/@views/AdminView/CompaniesPage/CompaniesTable/CompanyCommentsCell';
import { CompanyNameCell } from '~/@views/AdminView/CompaniesPage/CompaniesTable/CompanyNameCell';

export const useCompaniesColumns = (): ITableColumn<ICompany>[] => {
    const columns: ITableColumn<ICompany>[] = [
        {
            title: (
                <CompaniesHeadCell
                    title={<LocalizableText code={'CompaniesTable.companyName'} />}
                    fieldName={CompaniesStoreFilterFields.name}
                    withFilter
                    withSort
                />
            ),
            render: row => <CompanyNameCell company={row} />,
            name: 'companyName',
        },
        {
            title: (
                <CompaniesHeadCell
                    title={<LocalizableText code={'CompaniesTable.orgNumber'} />}
                    fieldName={CompaniesStoreFilterFields.organizationNumber}
                    withFilter
                />
            ),
            render: row => row.organizationNumber,
            name: 'orgNumber',
        },
        {
            title: (
                <CompaniesHeadCell
                    title={<LocalizableText code={'CompaniesTable.GLN'} />}
                    fieldName={CompaniesStoreFilterFields.GLN}
                    withFilter
                />
            ),
            render: row => <NullableFormatter>{row.GLN}</NullableFormatter>,
            name: 'GLN',
        },
        {
            title: (
                <CompaniesHeadCell
                    title={<LocalizableText code={'CompaniesTable.phone'} />}
                    fieldName={CompaniesStoreFilterFields.phone}
                    withFilter
                />
            ),
            render: row => row.phone,
            name: 'phone',
        },
        {
            title: (
                <CompaniesHeadCell
                    title={<LocalizableText code={'CompaniesTable.email'} />}
                    fieldName={CompaniesStoreFilterFields.email}
                    withFilter
                    withSort
                />
            ),
            render: row => row.email,
            name: 'email',
        },
        {
            title: <LocalizableText code={'CompaniesTable.comments'} />,
            render: row => <CompanyCommentsCell company={row} />,
            name: 'comments',
        },
        { title: <LocalizableText code={'CompaniesTable.location'} />, render: row => row.city, name: 'city' },
    ];

    return columns;
};
