import { isManagerRole } from '@common/abilities/utils';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { globalMessage } from '~/services/message';

import i18n from '../../../../i18n';
import { AccountForm, IAccountFormOwnProps, IFormValues, isPasswordChanged, isUserChanged } from './AccountForm';

const MIN_PASSWORD_LENGTH = 6;

const defaultValues: IFormValues = {
    name: '',
    surname: '',
    email: '',
    companyName: '',
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
    hideCompany: false,
};

const formikHOC = withFormik<IAccountFormOwnProps, IFormValues>({
    displayName: 'AccountFormik',

    enableReinitialize: true,
    mapPropsToValues: ({ user }) => ({
        ...defaultValues,
        name: user.name || defaultValues.name,
        surname: user.surname || defaultValues.surname,
        email: user.email || defaultValues.email,
        companyName: user.customer?.name || defaultValues.companyName,
        hideCompany: isManagerRole(user.role),
    }),

    validationSchema: Yup.object().shape({
        name: Yup.string().trim().required(i18n.nameIsRequired),
        surname: Yup.string().trim().required(i18n.surNameIsRequired),
        oldPassword: Yup.string().trim(),
        newPassword: Yup.string()
            .trim()
            .min(MIN_PASSWORD_LENGTH, i18n.minPasswordLength)
            .test('is-current-password-required', i18n.currentPasswordIsRequired, function (value) {
                return !value || !!this.parent.oldPassword;
            })
            .test('is-different-password', i18n.samePasswordAsBefore, function (value) {
                if (!value && !this.parent.oldPassword) return true;

                return value !== this.parent.oldPassword;
            }),
        repeatPassword: Yup.string()
            .trim()
            .test('do-passwords-match', i18n.passwordsDoNotMatch, function (value) {
                return this.parent.newPassword === value;
            }),
    }),

    handleSubmit: async (values, { props }) => {
        const { user, onUpdateUser, onChangePassword, onClose } = props;

        let gqlErrors = 0;

        if (isUserChanged(values, user)) {
            try {
                const { name, surname } = values;
                await onUpdateUser({ name, surname });
            } catch (_) {
                gqlErrors++;
            }
        }

        if (isPasswordChanged(values)) {
            try {
                const { oldPassword, newPassword, repeatPassword } = values;
                await onChangePassword({ oldPassword, newPassword, repeatPassword });
            } catch (_) {
                gqlErrors++;
            }
        }

        if (!gqlErrors) {
            globalMessage.success(i18n.saved);
            onClose();
        }
    },
});

export default formikHOC(AccountForm);
