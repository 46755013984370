import './AnalysisDocuments.scss';

import { IconButton } from '@material-ui/core';
import { sortBy } from 'lodash';
import React, { VFC } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { showDocumentPreviewDialog } from '~/@sochi-components/DocumentPreview';
import { BinIcon, DocumentIcon, DownloadIcon } from '~/@sochi-components/Icons';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { ISochiTableColumn, SochiTable } from '~/@sochi-components/SochiTable';
import { useAnalysisFileDeleteMutation, useDetailedProject } from '~/@store/projects';
import { projectAnalysisUri } from '~/apolloClient';
import { useUserAbilities } from '~/contexts';
import { ProjectQuery_project_dumpLoads_analysisFiles as IAnalysisFile } from '~/graphql';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';
import { canDeleteProjectDocument, canReadProject, canUploadProjectDocument } from '~/utils/auth';
import { downloadDumpLoadAnalysisFile } from '~/utils/dumpLoad';

import { AnalysisFileUploader } from '../AnalysisFileUploader';

type AnalysisDocumentsProps = {
    projectId: string;
    dumpLoadId: string;
    closeDialog: () => void;
    title?: React.ReactNode;
    className?: string;
};

export const AnalysisDocuments: VFC<AnalysisDocumentsProps> = ({
    projectId,
    dumpLoadId,
    closeDialog,
    title,
    className: classNameCustom,
}) => {
    const { className, element } = useFunctionalBem(AnalysisDocuments, classNameCustom);

    const abilityContext = useUserAbilities();
    const { data } = useDetailedProject(projectId);
    const [deleteDumpLoadAnalysisFile] = useAnalysisFileDeleteMutation();

    const project = data?.project;
    const dumpLoad = data?.project?.dumpLoads.find(d => d.id === dumpLoadId);

    const hasProjectAndCanRead = project && canReadProject(abilityContext, project);
    const сanDelete = project && canDeleteProjectDocument(abilityContext, project);
    const canAdd = project && canUploadProjectDocument(abilityContext, project);

    if (!hasProjectAndCanRead || !dumpLoad) return null;

    const analysisFiles = sortBy(dumpLoad?.analysisFiles, file => file.uploadDate).reverse();

    const columnsConfig: ISochiTableColumn<IAnalysisFile>[] = [
        {
            title: <LocalizableText code={'ProjectDocuments.documentName'} />,
            render: file => (
                <span className={element('table-document-name')} title={file.name}>
                    {file.name}
                </span>
            ),
            alignLeft: true,
        },
        {
            render: file => (
                <IconButton
                    title={i18n.view}
                    onClick={() =>
                        showDocumentPreviewDialog(
                            projectAnalysisUri,
                            { projectId, dumpLoadId, fileId: file.id },
                            file.name
                        )
                    }>
                    <DocumentIcon color="primary" fontSize="inherit" />
                </IconButton>
            ),
        },
        {
            render: file => (
                <IconButton
                    title={i18n.download}
                    onClick={() => downloadDumpLoadAnalysisFile(projectId, dumpLoadId, file)}>
                    <DownloadIcon color="primary" fontSize="inherit" />
                </IconButton>
            ),
        },
        {
            render: file => (
                <IconButton
                    title={i18n.delete}
                    onClick={() => deleteDumpLoadAnalysisFile(projectId, dumpLoadId, file.id)}
                    disabled={!сanDelete}>
                    <BinIcon color="primary" fontSize="inherit" />
                </IconButton>
            ),
        },
    ];

    return (
        <DialogContent className={className}>
            {title ? (
                <Text variant="h2" paragraph>
                    {title}
                </Text>
            ) : null}
            <SochiTable
                className={element('table')}
                items={analysisFiles}
                columns={columnsConfig}
                keyGetter={({ id }) => id}
            />
            <ButtonGroup className={element('button-group')}>
                <Button color="primary" variant="outlined" onClick={closeDialog}>
                    <LocalizableText code={'close'} />
                </Button>
                <AnalysisFileUploader project={project!} dumpLoadId={dumpLoadId}>
                    {onClick => (
                        <Button color="primary" variant="contained" onClick={onClick} disabled={!canAdd}>
                            <LocalizableText code={'ProjectDocuments.addAnalysis'} />
                        </Button>
                    )}
                </AnalysisFileUploader>
            </ButtonGroup>
        </DialogContent>
    );
};

export const showAnalysisDocumentsDialog = (projectId: string, dumpLoadId: string): void => {
    const ignoredPromise = showCustomDialog({
        render: closeDialog => (
            <AnalysisDocuments
                projectId={projectId}
                dumpLoadId={dumpLoadId}
                closeDialog={closeDialog}
                title={<LocalizableText code={'ProjectDocuments.analysisDocuments'} />}
            />
        ),
    });
};
