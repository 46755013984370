import { getCO2Consumption } from '@common/functions';
import { getLoadPercentage } from '@common/functions/common.deliveryLine';
import { parseDateFrom } from '@common/utils';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { ProjectCO2TrucksDashboard } from 'src/@views/UserView/ProjectPage/ProjectDashboard/ProjectCO2TrucksDashboard';

import { useSyncEffect } from '~/@components/@hooks';
import { makeStyles } from '~/@components/@theme';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import { COLOR } from '~/@sochi-components/@theme';
import { ProjectCO2MachinesDashboard } from '~/@views/UserView/ProjectPage/ProjectDashboard/ProjectCO2MachiesDashboard';
import {
    ProjectCO2DriversTableDashboard,
    ProjectCO2OperatorsTableDashboard,
} from '~/@views/UserView/ProjectPage/ProjectDashboard/ProjectCO2TableDashboard';
import { ProjectCO2TotalDashboard } from '~/@views/UserView/ProjectPage/ProjectDashboard/ProjectCO2TotalDashboard';
import i18n from '~/i18n';
import { getUserFullName } from '~/utils/user';

import { ProjectCommonDataProps } from '../ProjectPage/ProjectPage';
import { DashboardNoData, ProjectDashboardFilters } from './common';
import { dashboardFilterStore, ProjectDeliveryItem, ProjectTimeSpentItem } from './dashboardFilter.store';
import { ProjectDumpLoadModeDashboard } from './ProjectDumpLoadModeDashboard';
import { ProjectFillRateDashboard } from './ProjectFillRateDashboard';
import { ProjectSectionDashboard } from './ProjectSectionDashboard';

export type ProjectDashboardFilterItem = {
    id: string;
    name: string;
};

export const ProjectDashboard: FC<ProjectCommonDataProps> = observer(({ project }) => {
    const { root, filter } = useStyles();

    useSyncEffect(() => {
        const { deliveries, timeSpent } = project.dumpLoads.reduce(
            (acc: { deliveries: ProjectDeliveryItem[]; timeSpent: ProjectTimeSpentItem[] }, cur) => {
                cur.deliveryLines
                    .filter(d => !!d.plannedStartDate)
                    .forEach(dl => {
                        acc.deliveries.push({
                            date: parseDateFrom(dl.plannedStartDate) || new Date(),
                            value: dl.weight,
                            inbound: cur.inbound,
                            serialNumber: cur.serialNumber,
                            licensePlate: dl.truckRegistrationNumber,
                            fillRate: getLoadPercentage(dl),
                            excavatorOperator: dl.excavatorOperator,
                            CO2Consumption: dl.vehicleType
                                ? getCO2Consumption(dl.vehicleType, dl.fuel, cur.distance ? cur.distance / 1000 : 0) ||
                                  0
                                : 0,
                            vehicleType: dl.vehicleType,
                            fuel: dl.fuel,
                            engine: dl.engine,
                            distance: (cur.distance || 0) / 1000,
                        });
                    });

                cur.timeSpent.forEach(t => {
                    acc.timeSpent.push({
                        date: parseDateFrom(t.date) || new Date(),
                        hours: t.hoursSpent,
                        serialNumber: t.dumpLoadNumber,
                        licensePlate: t.licensePlate,
                        excavatorOperator: t.user ? { id: t.user.id, name: getUserFullName(t.user) } : null,
                        CO2Consumption: t.vehicleType ? getCO2Consumption(t.vehicleType, t.fuel, t.hoursSpent) || 0 : 0,
                        vehicleType: t.vehicleType,
                        fuel: t.fuel,
                        engine: t.engine,
                    });
                });

                return acc;
            },
            { deliveries: [], timeSpent: [] }
        );

        dashboardFilterStore.init(deliveries, timeSpent);
    }, []);

    return (
        <div className={root}>
            <div className={filter}>
                <ProjectDashboardFilters />
            </div>
            {dashboardFilterStore.filteredDeliveryItemsByDates.length === 0 ? (
                <DashboardNoData />
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <ProjectDumpLoadModeDashboard lines={dashboardFilterStore.filteredDeliveryItems} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ProjectSectionDashboard
                            lines={dashboardFilterStore.filteredDeliveryItems}
                            idGetter={line => line.excavatorOperator?.id}
                            nameGetter={line => line.excavatorOperator?.name || i18n.ProjectDashboard.noOperatorName}
                            title={<LocalizableText code={'ProjectDashboard.titleExcavatorOperator'} />}
                            sectionPlaceHolder={i18n.ProjectDashboard.operatorName}
                            useFilter
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ProjectSectionDashboard
                            lines={dashboardFilterStore.filteredDeliveryItems}
                            idGetter={line => line.licensePlate}
                            nameGetter={line => line.licensePlate}
                            title={<LocalizableText code={'ProjectDashboard.titleTruck'} />}
                            sectionPlaceHolder={i18n.ProjectDashboard.licensePlate}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ProjectFillRateDashboard lines={dashboardFilterStore.filteredDeliveryItemsByDates} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ProjectCO2DriversTableDashboard />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ProjectCO2OperatorsTableDashboard />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ProjectCO2TrucksDashboard />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ProjectCO2MachinesDashboard />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <ProjectCO2TotalDashboard />
                    </Grid>
                </Grid>
            )}
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    root: {
        background: COLOR.grayBackground,
        position: 'relative',
        padding: '0 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '20px',
        [theme.breakpoints.down('md')]: {
            height: '0 10px 20px',
            gap: '10px',
        },
    },
    filter: {
        marginTop: '20px',
        [theme.breakpoints.down('md')]: {
            marginTop: '10px',
        },
    },
}));
