import React, { useCallback } from 'react';

import { ClickableText } from '~/@components/ClickableText/ClickableText';
import { TwoLinesFormatter } from '~/@components/Formatters';
import { TableCellPlaceholder } from '~/@components/Table/TableCellPlaceholder';
import { IDumpLoad } from '~/@store/dumpLoads';
import i18n from '~/i18n';

import { showDumpLoadCommentsDialog } from '../DumpLoadCommentsView';

type Props = {
    dumpLoad: IDumpLoad;
};

export const DumpLoadCommentsCell = ({ dumpLoad }: Props) => {
    const openComments = useCallback(() => {
        const projectId = dumpLoad.project?.id;

        if (!projectId) return;

        const dumpLoadId = dumpLoad.id;

        showDumpLoadCommentsDialog(projectId, dumpLoadId);
    }, [dumpLoad]);

    return (
        <ClickableText onClick={openComments}>
            {dumpLoad.lastCommentText ? (
                <TwoLinesFormatter>{dumpLoad.lastCommentText}</TwoLinesFormatter>
            ) : (
                <TableCellPlaceholder startText="+">{i18n.addComment}</TableCellPlaceholder>
            )}
        </ClickableText>
    );
};
