import { isNumber } from 'lodash';

import { gqlTypeToLocation, ILocation, LatLng } from '~/@store/locations';
import * as queries from '~/@store/locations/location.queries';
import type { GoogleMapsLocation } from '~/@user-store/homePageMap';
import type { Geocode, GeocodeVariables, LocationInput, ReverseGeocode, ReverseGeocodeVariables } from '~/graphql';
import { LocationType } from '~/graphql';
import { handleLoadingPromise } from '~/services/loader';

import client from '../apolloClient';
import { handleApolloError } from './index';

export const toGoogleCoordinates = (point: LatLng): GoogleMapsLocation => {
    return {
        lat: point.lat,
        lng: point.lng,
    };
};

export const toLocation = (point: Partial<ILocation> | null): ILocation | null => {
    if (isNumber(point?.lng) && isNumber(point?.lat) && point?.text) {
        return {
            lat: point.lat,
            lng: point.lng,
            text: point.text || '',
            type: LocationType.VALID,
        };
    }

    return null;
};

export const isSameLocation = (a: LocationInput, b: LocationInput) =>
    a.lat === b.lat && a.lng === b.lng && a.text === b.text;

export const getPositionInfo = (location: LatLng): Promise<ILocation> => {
    return handleLoadingPromise(
        client
            .query<ReverseGeocode, ReverseGeocodeVariables>({
                query: queries.ReverseGeocode,
                variables: {
                    location: {
                        lat: location.lat,
                        lng: location.lng,
                    },
                },
                fetchPolicy: 'network-only',
            })
            .then(result => gqlTypeToLocation(result.data.reverseGeocode))
            .catch(handleApolloError)
    );
};

export const geocodeAddress = (text: string): Promise<ILocation> => {
    return handleLoadingPromise(
        client
            .query<Geocode, GeocodeVariables>({
                query: queries.TextGeocode,
                variables: {
                    text,
                },
                fetchPolicy: 'network-only',
            })
            .then(result => gqlTypeToLocation(result.data.geocode))
            .catch(handleApolloError)
    );
};
