import { Grid, makeStyles } from '@material-ui/core';
import { FormikContextType } from 'formik';
import React from 'react';

import { IOSSwitch } from '~/@components/IOSSwitch';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import i18n from '~/i18n';

import { ContaminationType } from '../../../../../../common/enums';
import { ISubstanceFormValues } from './SubstanceEditFormik';

type Props = {
    formik: FormikContextType<ISubstanceFormValues>;
    disabled?: boolean;
};

export const SubstanceTypeField = ({ formik, disabled }: Props) => {
    const types = formik.values.contaminationTypes;
    const solidChecked = types.includes(ContaminationType.SOLID);
    const leachingChecked = types.includes(ContaminationType.LEACHING);

    const handleSolidChange = () => {
        const newValue = solidChecked
            ? types.filter(t => t !== ContaminationType.SOLID)
            : [...types, ContaminationType.SOLID];

        if (newValue.length === 0) return;

        formik.setFieldValue('contaminationTypes', newValue);
    };

    const handleLeachingChange = () => {
        const newValue = leachingChecked
            ? types.filter(t => t !== ContaminationType.LEACHING)
            : [...types, ContaminationType.LEACHING];

        if (newValue.length === 0) return;

        formik.setFieldValue('contaminationTypes', newValue);
    };

    const { typeLabel } = useStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} container justify="space-between">
                <Grid item>
                    <Text variant="SmallText" color="textSecondary" display="inline" className={typeLabel}>
                        {i18n.type}
                    </Text>
                    <Text variant="BodyBold" display="inline">
                        <LocalizableText code={'solids'} />
                    </Text>
                </Grid>
                <Grid item>
                    <IOSSwitch
                        disabled={disabled}
                        color="primary"
                        checked={solidChecked}
                        onChange={handleSolidChange}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container justify="space-between">
                <Grid item>
                    <Text variant="SmallText" color="textSecondary" display="inline" className={typeLabel}>
                        {i18n.type}
                    </Text>
                    <Text variant="BodyBold" display="inline">
                        <LocalizableText code={'leaching'} />
                    </Text>
                </Grid>
                <Grid item>
                    <IOSSwitch
                        disabled={disabled}
                        color="primary"
                        checked={leachingChecked}
                        onChange={handleLeachingChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    typeLabel: {
        marginRight: theme.spacing(3),
    },
}));
