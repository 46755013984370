import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
    CompanyRemoveApiKeyMutation,
    CompanyRemoveApiKeyMutation_companyRemoveApiKey,
    CompanyRemoveApiKeyMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../companies.queries';

export type RemoveCompanyApiKeyFunc = (customerId: string) => Promise<CompanyRemoveApiKeyMutation_companyRemoveApiKey>;

export const useRemoveCompanyApiKey = (): [RemoveCompanyApiKeyFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        CompanyRemoveApiKeyMutation,
        CompanyRemoveApiKeyMutationVariables
    >(queries.CompanyRemoveApiKeyMutation);

    const removeApiKey = useCallback<RemoveCompanyApiKeyFunc>(
        customerId => {
            return callMutation({
                variables: { id: customerId },
            })
                .then(result => {
                    return result.data?.companyRemoveApiKey
                        ? result.data.companyRemoveApiKey
                        : Promise.reject(new Error('Empty response "CompanyRemoveApiKeyMutation"'));
                })
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [removeApiKey, mutationResult.loading];
};
