import { IconButton, InputAdornment } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { chatGptStore } from 'src/@user-store/chatGpt';

import { SendIcon } from '~/@components/Icon';
import { TextField } from '~/@components/TextField';
import { COLOR } from '~/@sochi-components/@theme/styles';
import { ENTER_KEY_NAME } from '~/@store/common';
import i18n from '~/i18n';

export const GptInput = observer(() => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => chatGptStore.setInputText(e.target.value);
    const handlePress = (e: React.KeyboardEvent<HTMLInputElement>) =>
        e.key === ENTER_KEY_NAME && chatGptStore.sendMessage();

    const onSendClick = () => chatGptStore.sendMessage();

    return (
        <TextField
            value={chatGptStore.inputText}
            onChange={handleChange}
            onKeyPress={handlePress}
            placeholder={i18n.ChatGpt.inputPlaceholder}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={onSendClick}>
                            <SendIcon stroke={COLOR.mainBlue} />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            disabled={chatGptStore.state !== 'idle'}
        />
    );
});
