import { AbilityCan, AbilitySubjects, DeliveryLineStatus, DumpLoadStatus, UserRole } from '../../enums';
import { IAbilityFunction } from '../abilities.types';
import { ICaslCondition } from '../utils';
import { DeliveryLineContextRole, DeliveryLineSubject, IDeliveryLineCalculatedSubject } from './deliveryLineTypes';

const { CRUD, READ, CREATE, UPDATE, DELETE } = AbilityCan;
const { ADMIN, EMPLOYEE, DRIVER, EXCAVATOR_OPERATOR } = UserRole;
const { AUTHOR, PROJECT_EXCAVATOR_OPERATOR, PROJECT_DRIVER, RECEIVER_USER, SENDER_USER } = DeliveryLineContextRole;
const { NO_DESTINATION, RECEIVER_FIELDS, SENDER_FIELDS } = DeliveryLineSubject;

export function defineAbilitiesForDeliveryLines(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<IDeliveryLineCalculatedSubject>;
    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _allow(can, AbilitySubjects.DELIVERY_LINE, shape);

    const forbid = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _forbid(can, AbilitySubjects.DELIVERY_LINE, shape);

    allow(CRUD, { relationRole: { $in: [ADMIN, EMPLOYEE] } });

    allow(READ, { relationRole: AUTHOR });

    allow(CREATE, { relationRole: PROJECT_EXCAVATOR_OPERATOR, subSubject: DeliveryLineStatus.EXCAVATED });

    allow(CREATE, { relationRole: PROJECT_DRIVER, subSubject: DeliveryLineStatus.DELIVERING });
    allow(CREATE, { relationRole: PROJECT_DRIVER, subSubject: DeliveryLineStatus.DELIVERED });

    forbid(CREATE, { relationRole: DRIVER, subSubject: NO_DESTINATION });
    forbid(CREATE, { relationRole: EXCAVATOR_OPERATOR, subSubject: NO_DESTINATION });
    forbid(CREATE, { subSubject: DumpLoadStatus.DONE });

    allow(UPDATE, { relationRole: { $all: [AUTHOR, DRIVER] }, subSubject: DeliveryLineStatus.EXCAVATED });
    allow(UPDATE, { relationRole: { $all: [AUTHOR, DRIVER] }, subSubject: DeliveryLineStatus.DELIVERING });

    // Receiver fields
    forbid(UPDATE, {
        relationRole: { $in: [ADMIN, EMPLOYEE, AUTHOR, DRIVER, PROJECT_EXCAVATOR_OPERATOR, SENDER_USER] },
        subSubject: RECEIVER_FIELDS,
    });
    allow(UPDATE, {
        relationRole: RECEIVER_USER,
        subSubject: RECEIVER_FIELDS,
    });

    // Sender fields
    allow(UPDATE, {
        relationRole: SENDER_USER,
        subSubject: SENDER_FIELDS,
    });

    // Statistic
    forbid(READ, { subSubject: DeliveryLineSubject.STATISTIC });
    allow(READ, { subSubject: DeliveryLineSubject.STATISTIC, relationRole: { $in: [DRIVER, EXCAVATOR_OPERATOR] } });

    forbid(UPDATE, { relationRole: { $all: [AUTHOR, EXCAVATOR_OPERATOR] }, subSubject: DeliveryLineStatus.EXCAVATED });
    forbid(READ, { relationRole: DRIVER, subSubject: DeliveryLineSubject.HISTORY });
    forbid(READ, { relationRole: EXCAVATOR_OPERATOR, subSubject: DeliveryLineSubject.HISTORY });

    allow(DELETE, { relationRole: { $all: [AUTHOR, DRIVER] }, subSubject: DeliveryLineStatus.EXCAVATED });
    allow(DELETE, { relationRole: { $all: [AUTHOR, DRIVER] }, subSubject: DeliveryLineStatus.DELIVERING });

    allow(DELETE, { relationRole: { $all: [AUTHOR, EXCAVATOR_OPERATOR] }, subSubject: DeliveryLineStatus.EXCAVATED });
}
