import { formatUserPhoneNumber } from '@common/utils';
import { validateUserPhoneNumber } from '@common/validations/common.validation';
import { FormikConfig, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { ICompanySearchRecord } from '~/@store/companies';
import { IDetailedUser, UpdateUserFunc, userLandfillsStore, userProjectsStore } from '~/@store/users';
import { IVehicle } from '~/@store/users/users.types';
import { errorCodes } from '~/config/enum';
import { UserRole } from '~/graphql';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';
import { parseDateFrom } from '~/utils/date';

export type IFormVehicle = IVehicle & { key: string };

export type IUserEditFormValues = {
    name: string;
    surname: string;
    email: string;
    phoneNumber: string;
    role: UserRole;
    orgName: string;
    orgNr: string;
    customer: ICompanySearchRecord | null;
    active: boolean;
    createdAt: Date | null;
    vehicles: IFormVehicle[];
};

export const useEditUserFormikContext = () => useFormikContext<IUserEditFormValues>();

export const getEditUserFormikConfig = (
    user: IDetailedUser,
    updateUser: UpdateUserFunc
): FormikConfig<IUserEditFormValues> => {
    return {
        initialValues: {
            name: user.name || '',
            surname: user.surname || '',
            email: user.email || '',
            phoneNumber: formatUserPhoneNumber(user.phoneNumber) || '',
            role: user.role,
            orgName: user.orgName || '',
            orgNr: user.orgNr || '',
            customer: user.customer,
            active: !user.blocked,
            createdAt: user.createdAt ? parseDateFrom(user.createdAt) : null,
            vehicles: user.vehicles.map((v, ind) => ({ ...v, key: ind.toString() })),
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required(i18n.UserEditPage.nameIsRequired),
            role: Yup.string().nullable().required(i18n.UserEditPage.roleIsRequired),
            customer: Yup.object()
                .nullable()
                .when('role', (role: UserRole, schema: Yup.ObjectSchema) => {
                    if (role === UserRole.EXTERNAL_RECEIVER) {
                        return schema.required(i18n.UserEditPage.customerIsRequired);
                    }

                    return schema;
                }),
            phoneNumber: Yup.string()
                .trim()
                .test('User phone number validation', '', function (phoneNumber) {
                    const errorCode = validateUserPhoneNumber(phoneNumber);

                    if (errorCode)
                        return this.createError({
                            path: 'phoneNumber',
                            message: i18n.errorCodes[errorCode] || i18n.errorCodes[errorCodes.INVALID_INPUT],
                        });

                    return true;
                }),
            vehicles: Yup.array()
                .of(
                    user.role === 'EXCAVATOR_OPERATOR'
                        ? Yup.object().shape({
                              licensePlate: Yup.string().trim().required(),
                              vehicleTypeId: Yup.string().required(),
                          })
                        : Yup.object().shape({
                              licensePlate: Yup.string().trim().required(),
                              vehicleTypeId: Yup.string().required(),
                              capacity: Yup.number().required().min(0),
                          })
                )
                .test('uniqueLicensePlate', i18n.UserEditPage.licensePlateShouldBeUnique, function (vehicles) {
                    return vehicles ? vehicles.length === new Set(vehicles.map(v => v?.licensePlate)).size : true;
                }),
        }),
        onSubmit: async (
            { name, surname, phoneNumber, role, orgName, orgNr, customer, active, vehicles },
            { resetForm }
        ) => {
            await updateUser({
                id: user.id,
                name,
                surname,
                phoneNumber,
                role,
                orgName,
                orgNr,
                blocked: !active,
                customerId: customer?.id || null,
                vehicles: vehicles.map(({ key, __typename, ...rest }) => rest),
            });

            globalMessage.success(i18n.UsersTable.saveSuccess);

            userProjectsStore.pagination.onClear();
            userLandfillsStore.pagination.onClear();

            resetForm();
        },
    };
};
