import { makeRecord } from '@common/utils';
import { Grid, IconButton, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldArray, useFormik } from 'formik';
import React, { useCallback, useMemo } from 'react';

import { DeleteButton, showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { FormCardButtons, FormCardContainer } from '~/@components/FormCard';
import { FormikNumberField, FormikTextField, FormikTypeUnsafeNumberField } from '~/@components/FormikFields';
import { DeleteIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { flattenSubstancesWithoutParents } from '~/@store/substances';
import {
    ISubstance,
    IToxicSumRule,
    useToxicLimitsPermissions,
    useToxicSumRuleCreate,
    useToxicSumRuleRemove,
    useToxicSumRuleUpdate,
} from '~/@store/toxicLimits';
import { SubstanceAutocomplete } from '~/@views/AdminView/ToxicLimitsPage/SumRules/SubstanceAutocomplete';
import { getSumRuleFormikConfig } from '~/@views/AdminView/ToxicLimitsPage/SumRules/SumRuleFormik';
import i18n from '~/i18n';

type Props = {
    substances: ISubstance[];
    sumRule: IToxicSumRule | null;
    closeForm: () => void;
};

export const SumRuleForm = ({ sumRule, closeForm, substances }: Props) => {
    const [create, isCreating] = useToxicSumRuleCreate();
    const [update, isUpdating] = useToxicSumRuleUpdate(sumRule?.id || '');
    const [remove, isRemoving] = useToxicSumRuleRemove(sumRule?.id || '');

    const createAndClose = useCallback<typeof create>(input => create(input).then(closeForm), [create, closeForm]);

    const formik = useFormik(getSumRuleFormikConfig(sumRule, sumRule ? update : createAndClose));

    const handleRemove = useCallback(async () => {
        const confirmed = await showDeleteConfirmationDialog(
            <LocalizableText code={'ToxicLimitsPage.confirmDeleteSumRule'} />,
            sumRule!.name
        );

        if (!confirmed) return;
        await remove();
    }, [remove, sumRule]);

    const { canUpdate, canDelete } = useToxicLimitsPermissions();

    const allSubstances = useMemo(() => flattenSubstancesWithoutParents(substances), [substances]);

    const substanceNameMap = useMemo(
        () =>
            makeRecord(
                allSubstances,
                s => s.id,
                s => s.name
            ),
        [allSubstances]
    );

    const { substanceRow, spacer } = useStyles();

    return (
        <FormCardContainer formik={formik} showProgress={isCreating || isUpdating || isRemoving}>
            <Grid container justify="space-between" alignItems="center">
                <Text variant="TitleBold">{sumRule ? i18n.edit : i18n.create}</Text>
                {sumRule && <DeleteButton onClick={handleRemove} disabled={!canDelete} />}
            </Grid>
            <FormikTextField
                label={<LocalizableText code={'ToxicLimitsPage.ruleName'} />}
                name="name"
                formik={formik}
                disabled={!canUpdate}
            />
            <FormikNumberField
                label={<LocalizableText code={'ToxicLimitsPage.faSumLevel'} />}
                name="faSumLevel"
                formik={formik}
                disabled={!canUpdate}
            />
            <FieldArray name="substances">
                {({ push, handleRemove }) => (
                    <>
                        <List>
                            {!formik.values.substances.length && (
                                <ListItem>
                                    <Text>{<LocalizableText code={'ToxicLimitsPage.noSubstances'} />}</Text>
                                </ListItem>
                            )}
                            {formik.values.substances.map((s, index) => (
                                <ListItem key={s.substanceId} className={substanceRow}>
                                    <Text>
                                        <LocalizableText code={'ToxicLimitsPage.substanceName'} />:{' '}
                                        <b>{substanceNameMap[s.substanceId]}</b>
                                    </Text>
                                    <div className={spacer} />
                                    <FormikTypeUnsafeNumberField
                                        name={`substances.${index}.threshold`}
                                        label={<LocalizableText code={'ToxicLimitsPage.threshold'} />}
                                        fullWidth={false}
                                        InputProps={{
                                            startAdornment: '> ',
                                        }}
                                        disabled={!canUpdate}
                                    />
                                    {canUpdate && (
                                        <IconButton onClick={handleRemove(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </ListItem>
                            ))}
                        </List>
                        {canUpdate && (
                            <SubstanceAutocomplete
                                substances={allSubstances}
                                onSubstanceSelect={substanceId => push({ substanceId, threshold: 0 })}
                            />
                        )}
                    </>
                )}
            </FieldArray>
            <FormCardButtons
                formik={formik}
                onCancel={closeForm}
                submitLabel={sumRule ? i18n.save : i18n.create}
                submitDisabled={!canUpdate}
            />
        </FormCardContainer>
    );
};

const useStyles = makeStyles(theme => ({
    substanceRow: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    spacer: {
        flexGrow: 1,
    },
}));
