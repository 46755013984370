import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import { AdminUserLandfillsQuery } from '~/@store/users/users.queries';
import {
    AdminUserLandfillsQuery as IUserLandfillsQuery,
    AdminUserLandfillsQuery_landfillsPaged,
    AdminUserLandfillsQuery_landfillsPaged_edges_node,
    AdminUserLandfillsQueryVariables,
} from '~/graphql';

import { userLandfillsStore } from '../userRelations.store';

export type IUserLandfillsPaged = AdminUserLandfillsQuery_landfillsPaged;

export type IUserLandfillInfo = AdminUserLandfillsQuery_landfillsPaged_edges_node;

export const useUserLandfillsPaged = (): QueryResult<IUserLandfillsQuery, AdminUserLandfillsQueryVariables> => {
    const variables = useDebouncedObservable(
        () => userLandfillsStore.userRelationsQueryVariables,
        TIMEOUT_ON_QUERY_DEBOUNCE
    );

    return useQuery<IUserLandfillsQuery, AdminUserLandfillsQueryVariables>(AdminUserLandfillsQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
        onCompleted: data => userLandfillsStore.pagination.onQueryComplete(data.landfillsPaged),
    });
};
