import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
    CompanyAddCommentMutation,
    CompanyAddCommentMutation_companyAddComment,
    CompanyAddCommentMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../companies.queries';

type AddCompanyCommentFunc = (
    companyId: string,
    text: string
) => Promise<CompanyAddCommentMutation_companyAddComment | null>;

export const useAddCompanyCommentMutation = (): [AddCompanyCommentFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<CompanyAddCommentMutation, CompanyAddCommentMutationVariables>(
        queries.CompanyAddCommentMutation
    );

    const addCompanyComment = useCallback<AddCompanyCommentFunc>(
        (companyId, text) => {
            return callMutation({ variables: { companyId, text } })
                .catch(handleApolloError)
                .then(result => result.data!.companyAddComment);
        },
        [callMutation]
    );

    return [addCompanyComment, mutationResult.loading];
};
