import { DespatchAdviceCategory } from '@common/enums';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IDespatchAdviceDefaultSettings } from '~/@store/beastIntegration';
import { ICompanySearchRecord } from '~/@store/companies';
import { IDespatchAdviceSettings } from '~/@store/dumpLoads';
import i18n from '~/i18n';
import { isConnectedToPeppol } from '~/utils/dumpLoad';

export type IDespatchAdviceSettingsFormValues = {
    isActive: boolean;
    category: DespatchAdviceCategory;
    consignee: ICompanySearchRecord | null;
    despatchParty: ICompanySearchRecord | null;
    buyer: ICompanySearchRecord | null;
    seller: ICompanySearchRecord | null;
    allowSelect: boolean;
    pricePerTon: number | null;
    isPriceMandatory: boolean;
};

export const useDumpLoadPeppolSettingsFormik = (
    settings: IDespatchAdviceSettings | null,
    category: DespatchAdviceCategory,
    defaultSettings: IDespatchAdviceDefaultSettings,
    isPriceMandatory: boolean = false
) =>
    useFormik<IDespatchAdviceSettingsFormValues>({
        initialValues: {
            category,
            isActive: !!settings,
            consignee: settings?.consignee || defaultSettings.consignee || null,
            despatchParty: settings?.despatchParty || defaultSettings.despatchParty || null,
            buyer: settings?.buyer || null,
            seller: settings?.seller || null,
            pricePerTon: settings?.pricePerTon || null,
            allowSelect: defaultSettings.allowSelect,
            isPriceMandatory,
        },
        validationSchema: Yup.object().shape({
            consignee: Yup.object<ICompanySearchRecord>()
                .nullable()
                .test('Is connected to Peppol', i18n.DespatchAdviceSettingsDialog.notConnected, function (this, value) {
                    if (!value || !this.parent.isActive) return true;

                    return isConnectedToPeppol(value.id);
                })
                .test(
                    'Should be specified',
                    i18n.DespatchAdviceSettingsDialog.shouldBeSpecified,
                    function (this, value) {
                        return !!value || !this.parent.isActive;
                    }
                ),
            despatchParty: Yup.object<ICompanySearchRecord>()
                .nullable()
                .test('Is connected to Peppol', i18n.DespatchAdviceSettingsDialog.notConnected, function (this, value) {
                    if (!value || !this.parent.isActive) return true;

                    return isConnectedToPeppol(value.id);
                }),
            buyer: Yup.object()
                .nullable()
                .test(
                    'Should be specified',
                    i18n.DespatchAdviceSettingsDialog.shouldBeSpecified,
                    function (this, value) {
                        return !!value || !this.parent.isActive;
                    }
                ),
            seller: Yup.object()
                .nullable()
                .test(
                    'Should be specified',
                    i18n.DespatchAdviceSettingsDialog.shouldBeSpecified,
                    function (this, value) {
                        return !!value || !this.parent.isActive;
                    }
                ),
            pricePerTon: Yup.number()
                .nullable()
                .test(
                    'Should be specified',
                    i18n.DespatchAdviceSettingsDialog.shouldBeSpecified,
                    function (this, value) {
                        return this.parent.isActive && this.parent.isPriceMandatory ? !!value : true;
                    }
                ),
        }),
        onSubmit: () => {},
    });
