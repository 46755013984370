import { gql } from '@apollo/client';

export const TimeSpentFragment = gql`
    fragment TimeSpentFragment on TimeSpent {
        id
        userId
        user {
            id
            name
            surname
        }
        dumpLoadNumber
        date
        hoursSpent
        licensePlate
        vehicleTypeId
        fuel
        engine
        comment
        beastStatus
    }
`;

export const TimeSpentQuery = gql`
    query TimeSpentQuery($filter: TimeSpentFilterInput!) {
        timeSpentList(filter: $filter) {
            ...TimeSpentFragment
        }
    }

    ${TimeSpentFragment}
`;
