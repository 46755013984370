import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/projects/projects.queries';
import { ProjectRemoveCertificateMutation, ProjectRemoveCertificateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

type RemoveCertificateFunc = (fileName: string) => Promise<unknown>;

export const useProjectRemoveCertificate = (projectId: string): [RemoveCertificateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        ProjectRemoveCertificateMutation,
        ProjectRemoveCertificateMutationVariables
    >(queries.ProjectRemoveCertificateMutation, {
        refetchQueries: [{ query: queries.ProjectQuery, variables: { id: projectId } }],
    });

    const removeCertificate = useCallback<RemoveCertificateFunc>(
        fileName => {
            return callMutation({
                variables: { id: projectId, fileName },
            }).catch(handleApolloError);
        },
        [callMutation, projectId]
    );

    return [removeCertificate, mutationResult.loading];
};
