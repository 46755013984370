import { canUseChatGptForProject } from '@common/functions';
import React from 'react';
import { GptChat } from 'src/@views/UserView/common/GptChat';

import { PageNoScroll } from '~/@views/UserView/common/PageNoScroll';

import { ProjectCommonDataProps } from '../ProjectPage/ProjectPage';

export const ProjectAiTab = ({ project }: ProjectCommonDataProps) => {
    const canUse = canUseChatGptForProject(project);

    if (!canUse) return null;

    return (
        <PageNoScroll>
            <GptChat projectId={project.id} />
        </PageNoScroll>
    );
};
