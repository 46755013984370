import { UserContextRole, UserRole } from '@common/enums';
import { isNullOrUndefined } from '@common/validations/types.validation';
import { action, computed, observable } from 'mobx';

import { FilterStore, SortStore } from '~/@store/common';
import { ProjectUser } from '~/@views/UserView/ProjectPage/ProjectUsers/ProjectUsers';
import i18n from '~/i18n';
import { filterAndSortCollection, getStringSearchFilterFn } from '~/utils/filters';
import { UserContextRoleItem } from '~/utils/user';

export const contextRoleValues: UserContextRoleItem[] = [
    UserContextRole.GUEST,
    UserContextRole.PROJECT_USER,
    UserContextRole.PROJECT_EXCAVATOR_OPERATOR,
    UserContextRole.PROJECT_DRIVER,
].map(v => ({
    id: v.toString(),
    value: v,
    name: i18n.UserContextRole[v],
}));

export const DEFAULT_PROJECT_USERS_SORT = {
    field: 'lastName',
    direction: 1,
};

export type ProjectUsersStoreFilter = {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    role?: UserContextRoleItem | null;
};

export enum ProjectUsersFilterField {
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    phone = 'phone',
    role = 'role',
}

export function contextRoleToUserRole(role: UserContextRole) {
    switch (role) {
        case UserContextRole.PROJECT_DRIVER:
            return UserRole.DRIVER;
        case UserContextRole.PROJECT_EXCAVATOR_OPERATOR:
            return UserRole.EXCAVATOR_OPERATOR;
        default:
            return UserRole.EXTERNAL_RECEIVER;
    }
}

class ProjectUsersStore {
    filter = new FilterStore<ProjectUsersStoreFilter>();
    sort = new SortStore(DEFAULT_PROJECT_USERS_SORT);

    @observable
    list: ProjectUser[] = [];

    @action
    setList(newList: ProjectUser[]) {
        this.list = newList;
        this.filter.clearValues();
        this.sort.clearSort();
    }

    @computed
    get filteredList(): ProjectUser[] {
        const { firstName, lastName, email, phone, role } = this.filter.values;

        const filters: ((item: ProjectUser) => boolean)[] = [];

        if (firstName) filters.push(getStringSearchFilterFn(firstName, r => r.firstName));
        if (lastName) filters.push(getStringSearchFilterFn(lastName, r => r.lastName));
        if (email) filters.push(getStringSearchFilterFn(email, r => r.email));
        if (phone) filters.push(getStringSearchFilterFn(phone, r => r.phone));
        if (role) filters.push(getStringSearchFilterFn(role.value, r => r.role));

        return filterAndSortCollection(this.list, filters, this.sort.value);
    }

    @computed
    get isAnyFilterSet(): boolean {
        return Object.values(this.filter.values).some(f => !isNullOrUndefined(f));
    }
}

export const projectUsersStore = new ProjectUsersStore();
