import { getLandfillSubject, LandfillSubject } from '@common/abilities/landfills';
import { getProjectSubject, ProjectSubject } from '@common/abilities/projects';
import { AbilityCan } from '@common/enums';

import { useUserAbilities } from '~/contexts';

export const useDatabasePermissions = () => {
    const { user, ability } = useUserAbilities();

    return {
        canMaintenanceProjects: ability.can(
            AbilityCan.UPDATE,
            getProjectSubject(user, null, [ProjectSubject.MAINTENANCE])
        ),
        canMaintenanceLandfills: ability.can(
            AbilityCan.UPDATE,
            getLandfillSubject(user, null, LandfillSubject.MAINTENANCE)
        ),
    };
};
