import { adminRoutes } from '@common/routes';
import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';

import { Divider } from '~/@components/Divider';
import i18n from '~/i18n';

import { useEditUserFormikContext } from './UserEditFormik';

export const UserEditFormActions = () => {
    const history = useHistory();

    const formik = useEditUserFormikContext();

    const onCancel = () => {
        if (formik.dirty) {
            formik.handleReset();
        } else {
            history.push(adminRoutes.users);
        }
    };

    return (
        <Grid container spacing={3} direction={'column'}>
            <Grid item>
                <Divider marginTop />
            </Grid>
            <Grid item>
                <Grid container spacing={3} justify="space-between">
                    <Grid item>
                        <Button onClick={onCancel} variant="outlined" color="default">
                            {formik.dirty ? i18n.cancel : i18n.back}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={formik.submitForm}
                            variant="contained"
                            color="primary"
                            disabled={!formik.dirty}>
                            {i18n.save}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
