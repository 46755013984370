import React, { useState } from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { BiSideLayout } from '~/@components/BiSideLayout';
import { PinIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { PageLight } from '~/@components/PageLight';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { ILandfillStatus, useLandfillStatusesList, useLandfillStatusPermissions } from '~/@store/landfillStatuses';
import i18n from '~/i18n';

import { showLandfillStatusCreateDialog } from './LandfillStatusCreateDialog';
import { LandfillStatusesList } from './LandfillStatusesList';
import { LandfillStatusForm } from './LandfillStatusForm';

export const LandfillStatusesPage = () => {
    const query = useLandfillStatusesList();

    const [selectedId, selectId] = useState<string | null>(null);

    const { canCreate } = useLandfillStatusPermissions();

    return (
        <QueryStateHandler query={query} dataGetter={query => query.data?.landfillStatuses}>
            {(data: ILandfillStatus[]) => (
                <PageLight>
                    <PageLight.Header
                        icon={<PinIcon />}
                        title={<LocalizableText code={'Admin.landfillStatuses'} />}
                        rightActions={
                            <ActionButton onClick={showLandfillStatusCreateDialog} disabled={!canCreate}>
                                {i18n.create}
                            </ActionButton>
                        }
                    />
                    <PageLight.Content>
                        <BiSideLayout>
                            <BiSideLayout.LeftSideContent>
                                <LandfillStatusesList statuses={data} selectedId={selectedId} onSelect={selectId} />
                            </BiSideLayout.LeftSideContent>
                            <BiSideLayout.RightSideContent>
                                {selectedId && (
                                    <LandfillStatusForm
                                        key={selectedId}
                                        status={data.find(s => s.id === selectedId) || null}
                                        closeForm={() => selectId(null)}
                                    />
                                )}
                            </BiSideLayout.RightSideContent>
                        </BiSideLayout>
                    </PageLight.Content>
                </PageLight>
            )}
        </QueryStateHandler>
    );
};
