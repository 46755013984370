import { action, computed, observable, reaction } from 'mobx';

import { FilterStore, locationStore, PaginationStore, SortStore } from '~/@store/common';
import {
    ACTIVE_PROJECT_STATUSES,
    ALL_PROJECT_STATUSES,
    DEFAULT_PROJECT_SORT,
    PROJECT_VIEW_MODE,
} from '~/@store/projects/projects.constants';
import { AdminProjectsPagedShortQueryVariables, ProjectFilter, ProjectStatus } from '~/graphql';

export type ProjectStoreFilter = {
    name?: string | null;
    status?: ProjectStatus | null;
    locationText?: string | null;
    customerName?: string | null;
    ownerName?: string | null;
    comment?: string | null;
};

export enum ProjectFilterFields {
    name = 'name',
    status = 'status',
    locationText = 'locationText',
    customerName = 'customerName',
    ownerName = 'ownerName',
    comment = 'comment',
}

export enum AdminProjectColumn {
    name = 'name',
    status = 'status',
    comment = 'comment',
    location = 'location',
    customer = 'customer',
    removeRecover = 'removeRecover',
    owner = 'owner',
}

class ProjectStore {
    constructor() {
        reaction(
            () => this.viewMode,
            () => this.filter.setValue('status', null)
        );
    }

    pagination = new PaginationStore();
    filter = new FilterStore<ProjectStoreFilter>(this.pagination.onClear);
    sort = new SortStore(DEFAULT_PROJECT_SORT, this.pagination.onClear);

    @computed
    get viewMode(): PROJECT_VIEW_MODE {
        const pathname = locationStore.pathname;

        return Object.values(PROJECT_VIEW_MODE).find(v => pathname.includes(v)) || PROJECT_VIEW_MODE.ALL_PROJECTS;
    }

    @observable
    companyId: string | null = null;

    @observable
    hiddenColumns: AdminProjectColumn[] = [];

    @computed
    get projectsQueryVariables(): AdminProjectsPagedShortQueryVariables {
        const { status, customerName, ...rest } = this.filter.values;
        const viewMode = this.viewMode;
        const filter: ProjectFilter = { ...rest };

        if (this.companyId) {
            filter.customerId = this.companyId;
        } else if (customerName) filter.customerName = customerName;

        if (status) {
            filter.statuses = [status];
        } else if (viewMode === PROJECT_VIEW_MODE.ACTIVE_PROJECTS) {
            filter.statuses = [...ACTIVE_PROJECT_STATUSES];
        }

        const sort = this.sort.value;

        const connection = this.pagination.connection;

        return { filter, sort, connection };
    }

    @computed
    get availableStatuses() {
        return this.viewMode === PROJECT_VIEW_MODE.ACTIVE_PROJECTS ? ACTIVE_PROJECT_STATUSES : ALL_PROJECT_STATUSES;
    }

    private resetFilterAndSort() {
        this.filter.clearValues();
        this.sort.clearSort();
    }

    @action
    setCompanyTabViewMode(newCompanyId: string | null) {
        this.companyId = newCompanyId;
        this.hiddenColumns = [AdminProjectColumn.customer];
        this.resetFilterAndSort();
    }

    @action
    setProjectsPageViewMode() {
        this.companyId = null;
        this.hiddenColumns = [];
        this.resetFilterAndSort();
    }
}

export const projectsStore = new ProjectStore();
