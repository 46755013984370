import { makeStyles, TableCell, TableCellProps } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

export const SochiTableCellNoPadding = ({ className, ...props }: TableCellProps) => {
    const { root } = useStyles();

    return <TableCell className={cn(className, root)} {...props} />;
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 2),

        [theme.breakpoints.down('md')]: {
            height: 40,
            padding: '2px 10px',
        },
    },
}));
