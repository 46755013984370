import { Box } from '@material-ui/core';
import React from 'react';

import { DeleteIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { showConfirmDialog } from '~/@store/common';

import { COLORS } from '../@theme/colors';

export const showDeleteConfirmationDialog = (questionText: React.ReactNode, contentText: React.ReactNode) =>
    showConfirmDialog({
        title: <LocalizableText code={'delete'} />,
        icon: DeleteIcon,
        okLabel: <LocalizableText code={'delete'} />,
        render: () => (
            <>
                <Text variant="BodyRegular">{questionText}</Text>
                <Box paddingTop={1} color={COLORS.brandLight}>
                    <Text variant="BodyBold">{contentText}</Text>
                </Box>
            </>
        ),
    });
