import {
    AbilityCan,
    AbilitySubjects,
    LandfillFields,
    LandfillSubareaFields,
    UserContextRole,
    UserRole,
} from '../../enums';
import type { IAbilityFunction } from '../abilities.types';
import { ICaslCondition } from '../utils';
import { ILandfillCalculatedSubject, LandfillSubject } from './landfillTypes';

const {
    receiverIds,
    receivers,
    minMargin,
    marginPercent,
    owner,
    status,
    customerId,
    customer,
    gateFee,
    location,
    capacity,
} = LandfillFields;

const { grossPriceBase, grossPriceOver50cm, grossPriceNotStackable, onHold } = LandfillSubareaFields;
const { CRUD, CREATE, READ, UPDATE } = AbilityCan;
const { ADMIN, EMPLOYEE, EXTERNAL_RECEIVER, DRIVER } = UserRole;
const { RECEIVER, GUEST } = UserContextRole;
const { LANDFILL_DELETED, SUBAREAS_LIST, MAINTENANCE, DELIVERY_EVENTS } = LandfillSubject;

export function defineAbilitiesForLandfills(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<ILandfillCalculatedSubject>;

    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) => _allow(can, AbilitySubjects.LANDFILL, shape);

    const forbid = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _forbid(can, AbilitySubjects.LANDFILL, shape);

    allow(CRUD, { relationRole: ADMIN });
    allow(CRUD, { relationRole: EMPLOYEE });

    // Receiver

    allow(CRUD, { relationRole: RECEIVER });

    forbid(READ, { relationRole: RECEIVER, subSubject: customer });
    forbid(READ, { relationRole: RECEIVER, subSubject: receivers });
    forbid([CREATE, UPDATE], { relationRole: RECEIVER, subSubject: customerId });
    forbid([CREATE, UPDATE], { relationRole: RECEIVER, subSubject: receiverIds });
    forbid([CREATE, UPDATE], { relationRole: RECEIVER, subSubject: capacity });

    // Guest

    allow(READ, { relationRole: GUEST });
    forbid(READ, { relationRole: GUEST, subSubject: customer });
    forbid(READ, { relationRole: GUEST, subSubject: receivers });
    forbid(READ, { relationRole: GUEST, subSubject: DELIVERY_EVENTS });

    // All external_receivers

    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: gateFee });
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: minMargin });
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: marginPercent });
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: owner });
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: status });
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: grossPriceBase });
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: grossPriceOver50cm });
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: grossPriceNotStackable });
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: LANDFILL_DELETED });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, subSubject: onHold });
    forbid(UPDATE, { relationRole: EXTERNAL_RECEIVER, subSubject: location });
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: capacity });
    forbid(CRUD, { relationRole: EXTERNAL_RECEIVER, subSubject: SUBAREAS_LIST });

    // Maintenance

    forbid(CRUD, { relationRole: { $in: [EMPLOYEE, EXTERNAL_RECEIVER, DRIVER] }, subSubject: MAINTENANCE });
}
