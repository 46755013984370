import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { WhatsappIcon } from '~/@components/Icon';
import { Link } from '~/@components/Link';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';

type Props = {
    whatsappGroupLink: string;
};

export const ProjectWhatsappLink = ({ whatsappGroupLink }: Props) => {
    const { root } = useStyles();

    return (
        <Link to={whatsappGroupLink} targetBlank className={root}>
            <WhatsappIcon />
            <Text variant="BodyRegular">
                <LocalizableText code="ProjectWhatsapp.openGroup" />
            </Text>
        </Link>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        height: 40,
        display: 'inline-flex',
        alignItems: 'center',
    },
}));
