import { useEffect, useState } from 'react';

import { TIMEOUT_ON_SEARCH_DEBOUNCE } from '~/@store/common';

export const useDebounce = <TValue>(value: TValue, delay: number = TIMEOUT_ON_SEARCH_DEBOUNCE): TValue => {
    const [debouncedValue, setDebouncedValue] = useState<TValue>(value);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [value, delay]);

    return debouncedValue;
};
