import { UserRole } from '@common/enums';
import { FormikContextType } from 'formik';
import React from 'react';

import { FormikAsyncAutocompleteMultiSelectField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { IUserSearchRecord } from '~/@store/users';
import { useUsersSearchFunc } from '~/@store/users/users.hooks/useUsersSearch';
import { getUserFullName } from '~/utils/user';

import { LandfillEditFormValues } from '../LandfillEdit.types';

type Props = { formik: FormikContextType<LandfillEditFormValues> };

export const LandfillEditReceivers = ({ formik }: Props) => {
    const usersSearch = useUsersSearchFunc({ roles: [UserRole.EXTERNAL_RECEIVER] });

    return (
        <FormikAsyncAutocompleteMultiSelectField
            label={<LocalizableText code={'LandfillForm.contactPeople'} />}
            name="receivers"
            formik={formik}
            renderOption={(user: IUserSearchRecord) => getUserFullName(user)}
            loadOptions={usersSearch}
        />
    );
};
