import axios from 'axios';

import { ContentTypes } from '../../../common/enums';
import type { IContentTypes } from '../../../common/types';
import { handleLoadingPromise } from '../services/loader';
import { getAxiosConfig, handleAxiosError } from './axios';

export const saveToClientBase64 = (base64: string, filename: string) => {
    let tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = base64;
    tempLink.setAttribute('download', filename);

    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
};

export const saveToClient = (
    data: string | ArrayBuffer | ArrayBufferView | Blob,
    filename: string,
    mime: IContentTypes = ContentTypes.applicationOctetStream
) => {
    let blob = new Blob([data], { type: mime });
    // @ts-ignore Navigator.msSaveBlob is deprecated
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // @ts-ignore Navigator.msSaveBlob is deprecated
        window.navigator.msSaveBlob(blob, filename);
    } else {
        let blobURL = window.URL.createObjectURL(blob);
        saveToClientBase64(blobURL, filename);
        window.URL.revokeObjectURL(blobURL);
    }
};

export const downloadFile = (
    uri: string,
    data: unknown = {},
    filename: string,
    save: boolean = true,
    showHourglasses: boolean = true
): Promise<Blob> => {
    const downloadPromise = axios
        .get(uri, getAxiosConfig(data))
        .then(result => {
            if (save) saveToClient(result.data, filename);

            return result.data;
        })
        .catch(save ? handleAxiosError : Promise.reject);

    if (showHourglasses) {
        return handleLoadingPromise(downloadPromise);
    } else {
        return downloadPromise;
    }
};

export const downloadFilePost = (
    uri: string,
    data: unknown = {},
    filename: string,
    save: boolean = true,
    showHourglasses: boolean = true
): Promise<Blob> => {
    const config = getAxiosConfig({}, 'blob');
    const downloadPromise = axios
        .post(uri, data, config)
        .then(result => {
            if (save) saveToClient(result.data, filename);

            return result.data;
        })
        .catch(save ? handleAxiosError : Promise.reject);

    if (showHourglasses) {
        return handleLoadingPromise(downloadPromise);
    } else {
        return downloadPromise;
    }
};

export const convertBlobToBase64 = (data: Blob): Promise<string> => {
    if (data instanceof Blob)
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function () {
                const base64data: string = String(reader.result);
                resolve(base64data);
            };
        });

    return Promise.reject(new Error('`data` must be instance of `Blob`'));
};
