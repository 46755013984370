import { validateUserPhoneNumber } from '@common/validations/common.validation';
import { FormikConfig, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { ObjectSchema } from 'yup';

import { ICompanySearchRecord } from '~/@store/companies';
import { errorCodes } from '~/config/enum';
import { UserCreate, UserRole } from '~/graphql';
import i18n from '~/i18n';

export type IUserCreateFormValues = {
    email: string;
    role: UserRole | null;
    customer: ICompanySearchRecord | null;
    name: string;
    surname: string;
    phoneNumber: string;
};

export const useCreateUserFormikContext = () => useFormikContext<IUserCreateFormValues>();

type UserCreateFunc = (input: UserCreate) => Promise<void>;

export const getCreateUserFormikConfig = (
    createUser: UserCreateFunc,
    defaultValues: Partial<IUserCreateFormValues> | null = null
): FormikConfig<IUserCreateFormValues> => {
    return {
        initialValues: {
            email: '',
            role: null,
            customer: null,
            name: '',
            surname: '',
            phoneNumber: '',
            ...defaultValues,
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().trim().email(i18n.UsersTable.invalidEmail).required(i18n.UsersTable.emailIsRequired),
            role: Yup.string().nullable().required(i18n.UsersTable.roleIsRequired),
            customer: Yup.object()
                .nullable()
                .when('role', (role: UserRole, schema: ObjectSchema) => {
                    if (role === UserRole.EXTERNAL_RECEIVER) {
                        return schema.required(i18n.UsersTable.customerIsRequired);
                    }

                    return schema;
                }),
            name: Yup.string().nullable().required(i18n.UsersTable.nameIsRequired),
            surname: Yup.string().nullable().required(i18n.UsersTable.surnameIsRequired),
            phoneNumber: Yup.string()
                .trim()
                .test('User phone number validation', '', function (phoneNumber) {
                    const errorCode = validateUserPhoneNumber(phoneNumber);

                    if (errorCode)
                        return this.createError({
                            path: 'phoneNumber',
                            message: i18n.errorCodes[errorCode] || i18n.errorCodes[errorCodes.INVALID_INPUT],
                        });

                    return true;
                }),
        }),
        onSubmit: async ({ email, role, customer, name, surname, phoneNumber }) => {
            await createUser({
                email,
                role: role || UserRole.EXTERNAL_RECEIVER,
                customerId: customer?.id || null,
                name,
                surname,
                phoneNumber: phoneNumber || undefined,
            });
        },
    };
};
