import { ApolloClient } from '@apollo/client';

import type {
    ProjectDeviationCloseMutation as ICloseMutation,
    ProjectDeviationCloseMutationVariables,
    ProjectDeviationCommentMutation as ICommentMutation,
    ProjectDeviationCommentMutationVariables,
    ProjectDeviationCreateMutation as ICreateMutation,
    ProjectDeviationCreateMutationVariables,
    ProjectDeviationUpdateMutation as IUpdateMutation,
    ProjectDeviationUpdateMutationVariables,
} from '~/graphql';
import {
    ProjectDeviationRemoveFileMutation as IRemoveFileMutation,
    ProjectDeviationRemoveFileMutationVariables,
} from '~/graphql';
import i18n from '~/i18n';
import { handleLoadingPromise } from '~/services/loader';
import { globalMessage } from '~/services/message';
import { handleApolloError } from '~/utils';

import {
    ProjectDeviationCloseMutation,
    ProjectDeviationCommentMutation,
    ProjectDeviationCreateMutation,
    ProjectDeviationRemoveFileMutation,
    ProjectDeviationUpdateMutation,
} from './deviation.queries';
import { DeviationMutations, IDeviation, IFileDoc, IFormValues } from './deviation.types';

const createDeviation = (client: ApolloClient<unknown>, values: IFormValues, entityId: string): Promise<IDeviation> =>
    handleLoadingPromise(
        client
            .mutate<ICreateMutation, ProjectDeviationCreateMutationVariables>({
                mutation: ProjectDeviationCreateMutation,
                variables: {
                    input: {
                        projectId: entityId,
                        issue: values.issue,
                        description: values.description,
                        suggestedSolution: values.suggestedSolution,
                        status: values.status,
                        files: values.newFiles.map(image => image.file),
                    },
                },
            })
            .catch(handleApolloError)
            .then(({ data }) => {
                globalMessage.success(i18n.DeviationForm.deviationCreated);

                return data?.deviationProjectCreate || Promise.reject(new Error('Empty response'));
            })
    );

const updateDeviation = (
    client: ApolloClient<unknown>,
    values: IFormValues,
    deviation: IDeviation
): Promise<IDeviation> =>
    handleLoadingPromise(
        client
            .mutate<IUpdateMutation, ProjectDeviationUpdateMutationVariables>({
                mutation: ProjectDeviationUpdateMutation,
                variables: {
                    id: deviation.id,
                    input: {
                        issue: values.issue,
                        description: values.description,
                        suggestedSolution: values.suggestedSolution,
                        status: values.status,
                        files: values.newFiles.map(image => image.file),
                    },
                },
            })
            .catch(handleApolloError)
            .then(({ data }) => {
                globalMessage.success(i18n.DeviationForm.deviationUpdated);

                return data?.deviationProjectUpdate || Promise.reject(new Error('Empty response'));
            })
    );

const commentDeviation = (
    client: ApolloClient<unknown>,
    values: IFormValues,
    deviation: IDeviation
): Promise<IDeviation> =>
    handleLoadingPromise(
        client
            .mutate<ICommentMutation, ProjectDeviationCommentMutationVariables>({
                mutation: ProjectDeviationCommentMutation,
                variables: {
                    id: deviation.id,
                    input: {
                        text: values.newComment,
                        suggestedSolution: values.suggestedSolution,
                        files: values.newFiles.map(image => image.file),
                    },
                },
            })
            .catch(handleApolloError)
            .then(({ data }) => {
                return data?.deviationProjectComment || Promise.reject(new Error('Empty response'));
            })
    );

const closeDeviation = (
    client: ApolloClient<unknown>,
    values: IFormValues,
    deviation: IDeviation
): Promise<IDeviation> =>
    handleLoadingPromise(
        client
            .mutate<ICloseMutation, ProjectDeviationCloseMutationVariables>({
                mutation: ProjectDeviationCloseMutation,
                variables: {
                    id: deviation.id,
                    files: values.newFiles.map(image => image.file),
                },
            })
            .catch(handleApolloError)
            .then(({ data }) => {
                globalMessage.success(i18n.DeviationForm.deviationClosed);

                return data?.deviationProjectClose || Promise.reject(new Error('Empty response'));
            })
    );

const removeFile = (client: ApolloClient<unknown>, deviation: IDeviation, file: IFileDoc): Promise<IDeviation> =>
    client
        .mutate<IRemoveFileMutation, ProjectDeviationRemoveFileMutationVariables>({
            mutation: ProjectDeviationRemoveFileMutation,
            variables: {
                id: deviation.id,
                fileId: file.id,
            },
        })
        .catch(handleApolloError)
        .then(({ data }) => {
            return data?.deviationProjectRemoveFile || Promise.reject(new Error('Empty response'));
        });

export const projectDeviationMutations: DeviationMutations = {
    createDeviation,
    updateDeviation,
    commentDeviation,
    closeDeviation,
    removeFile,
};
