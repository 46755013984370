import { action, observable } from 'mobx';

import { LocalizationString } from '~/@store/localization/localization.types';
import { getLocalizationStrings, updateLocalizationString } from '~/@store/localization/localization.utils';

class LocalizationStore {
    @observable
    localizationStrings: Map<string, string> = new Map<string, string>();

    _loadingPromise: undefined | Promise<void>;

    init = () => {
        if (!this._loadingPromise) {
            this._loadingPromise = this.refreshDictionary();
        }
    };

    updateLocalizationString = async (localizationString: LocalizationString) => {
        await updateLocalizationString(localizationString).then(items => this.fillDictionary(items));
    };

    refreshDictionary = async () => {
        await getLocalizationStrings().then(items => this.fillDictionary(items));
    };

    @action
    fillDictionary = (strings: LocalizationString[]) => {
        this.localizationStrings.clear();

        for (const item of strings) {
            this.localizationStrings.set(item.code, item.value);
        }
    };
}

export const localizationStore = new LocalizationStore();
