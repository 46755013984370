import React from 'react';

import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { showCustomDialog } from '~/@store/common';
import { IDetailedProject } from '~/@store/projects';

import { DumpLoadCreateForm } from './DumpLoadCreateForm';

type Props = {
    project: IDetailedProject;
    onClose: () => void;
};

const DumpLoadCreateDialog = ({ project, onClose }: Props) => {
    return (
        <CardDialog title={<LocalizableText code={'addMass'} />} maxWidth="sm" fullWidth onClose={onClose}>
            <DumpLoadCreateForm project={project} onClose={onClose} />
        </CardDialog>
    );
};

export const showDumpLoadCreateDialog = (project: IDetailedProject) =>
    showCustomDialog({
        render: closeDialog => <DumpLoadCreateDialog project={project} onClose={() => closeDialog(false)} />,
    });
