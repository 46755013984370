import { Grid } from '@material-ui/core';
import React from 'react';

import { FormikPhoneNumberField, FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { UserRole } from '~/graphql';

import { FormikCustomerAsyncSelect } from '../../common/FormikFields/FormikCustomerAsyncSelect';
import { FormikUserRoleSelect } from '../../common/FormikFields/FormikUserRoleSelect';
import { useCreateUserFormikContext } from './UserCreateFormik';

export const UserCreateFormFields = () => {
    const formik = useCreateUserFormikContext();

    const isEditableCustomerFields = formik.values.role === UserRole.EXTERNAL_RECEIVER;

    return (
        <Grid container spacing={3} justify={'space-between'}>
            <Grid item xs={12}>
                <FormikTextField label={<LocalizableText code={'UsersTable.email'} />} name="email" formik={formik} />
            </Grid>
            <Grid item xs={12}>
                <FormikUserRoleSelect name="role" formik={formik} />
            </Grid>
            <Grid item xs={12}>
                <FormikCustomerAsyncSelect name="customer" disabled={!isEditableCustomerFields} formik={formik} />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField label={<LocalizableText code={'UsersTable.name'} />} name="name" formik={formik} />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField
                    label={<LocalizableText code={'UsersTable.lastName'} />}
                    name="surname"
                    formik={formik}
                />
            </Grid>
            <Grid item xs={12}>
                <FormikPhoneNumberField
                    label={<LocalizableText code={'UsersTable.phone'} />}
                    name="phoneNumber"
                    formik={formik}
                />
            </Grid>
        </Grid>
    );
};
