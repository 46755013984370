import { Grid } from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import { DisableProvider } from '~/@components/@hooks';
import { FormikLocationField, FormikNumberField, FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { IDetailedLandfill } from '~/@store/landfills';
import { commonPrecisions } from '~/config/enum';
import { LandfillInput } from '~/graphql';

import { LandfillEditActions } from './LandfillEditActions';
import {
    LandfillEditCustomer,
    LandfillEditReceivers,
    LandfillEditStatus,
    LandfillEditWorkTime,
} from './LandfillEditFIelds';
import { getLandfillFormikConfig } from './LandfillEditFormik';

type Props = {
    submit: (input: LandfillInput) => Promise<IDetailedLandfill>;
    landfill: IDetailedLandfill | null;
    onClose?: () => void;
    onComplete?: (landfill: IDetailedLandfill) => void;
};

export const LandfillEditForm = ({ submit, landfill, onClose, onComplete }: Props) => {
    const formik = useFormik(getLandfillFormikConfig(landfill, submit, onComplete));

    return (
        <DisableProvider disabled={formik.isSubmitting}>
            <FormikProvider value={formik}>
                <Form>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormikTextField
                                        label={<LocalizableText code={'LandfillForm.siteName'} />}
                                        name="name"
                                        formik={formik}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <LandfillEditReceivers formik={formik} />
                                </Grid>

                                <Grid item xs={6}>
                                    <LandfillEditStatus formik={formik} />
                                </Grid>

                                <Grid item xs={6}>
                                    <LandfillEditCustomer formik={formik} />
                                </Grid>

                                <Grid item xs={3}>
                                    <FormikNumberField
                                        formik={formik}
                                        name="gateFee"
                                        label={<LocalizableText code={'LandfillForm.gateFee'} />}
                                        precision={commonPrecisions.price}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <FormikNumberField
                                        formik={formik}
                                        name="minMargin"
                                        label={<LocalizableText code={'LandfillForm.minMargin'} />}
                                        precision={commonPrecisions.price}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <FormikNumberField
                                        formik={formik}
                                        name="marginPercent"
                                        label={<LocalizableText code={'LandfillForm.marginPercent'} />}
                                        precision={commonPrecisions.percent}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <FormikNumberField
                                        formik={formik}
                                        name="capacity"
                                        label={<LocalizableText code={'LandfillForm.capacity'} />}
                                        precision={commonPrecisions.amount}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <LandfillEditWorkTime formik={formik} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormikLocationField name="location" formik={formik} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikTextField
                                        label={<LocalizableText code={'LandfillForm.comment'} />}
                                        name="comment"
                                        formik={formik}
                                        multiline={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikTextField
                                        label={<LocalizableText code={'LandfillForm.description'} />}
                                        name="description"
                                        formik={formik}
                                        multiline={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <LandfillEditActions formik={formik} landfill={landfill} onClose={onClose} />
                </Form>
            </FormikProvider>
        </DisableProvider>
    );
};
