import { Ability, AbilityBuilder } from '@casl/ability';
import { ABILITY_TYPE_FIELD as TYPE_FIELD, IAbility, IAbilityUser } from '@common/abilities/abilities.types';
import { defineAbilitiesForBeastIntegration } from '@common/abilities/beastIntegration';
import { defineAbilitiesForCompanies } from '@common/abilities/companies';
import { defineAbilitiesForDeliveryLines } from '@common/abilities/deliveryLines';
import { defineAbilitiesForDeviations } from '@common/abilities/deviations';
import { defineAbilitiesForDumpTypes } from '@common/abilities/dumpTypes';
import { defineAbilitiesForInvoices } from '@common/abilities/invoices';
import { defineAbilitiesForLandfills, defineAbilitiesForLandfillStatus } from '@common/abilities/landfills';
import { defineAbilitiesForLocalization } from '@common/abilities/localization';
import { defineAbilitiesForLocks } from '@common/abilities/locks';
import { defineAbilitiesForOrders } from '@common/abilities/orders';
import { defineAbilitiesForProjectDumpLoad, defineAbilitiesForProjects } from '@common/abilities/projects';
import { defineAbilitiesForReleaseNotes } from '@common/abilities/releaseNotes';
import { defineAbilitiesForSettings } from '@common/abilities/settings';
import { defineAbilitiesForSubstances } from '@common/abilities/substances';
import { defineAbilitiesForTimeSpent } from '@common/abilities/timeSpent';
import { defineAbilitiesForToxicLimits } from '@common/abilities/toxicLimits';
import { defineAbilitiesForUsers } from '@common/abilities/users';
import { defineAbilitiesForVehicleTypes } from '@common/abilities/vehicleTypes';
import type { IAbilitySubject } from '@common/enums';
import { AbilityCan, AbilitySubjects, UserRole } from '@common/enums';

import type { MeQuery_me } from '~/graphql';

const subjects = AbilitySubjects;

export const defineAbilitiesFor = (me: MeQuery_me): IAbility => {
    const { rules, can: allow, cannot: forbid } = AbilityBuilder.extract();

    const user: IAbilityUser = me;

    if (user.role === UserRole.ADMIN) {
        allow(AbilityCan.CRUD, [subjects.COMPANY, subjects.MANAGER_BUSINESS_FLOW]);
        allow(AbilityCan.READ, AbilitySubjects.ADMIN_PAGES);
    }

    if (user.role === UserRole.EMPLOYEE) {
        allow(AbilityCan.CRUD, [subjects.COMPANY, subjects.MANAGER_BUSINESS_FLOW]);
        allow(AbilityCan.READ, AbilitySubjects.ADMIN_PAGES);
    }

    defineAbilitiesForDeviations(user, allow, forbid);

    defineAbilitiesForDumpTypes(allow, forbid);

    defineAbilitiesForUsers(allow, forbid);

    defineAbilitiesForProjects(allow, forbid);

    defineAbilitiesForProjectDumpLoad(allow, forbid);

    defineAbilitiesForOrders(allow, forbid);

    defineAbilitiesForDeliveryLines(allow, forbid);

    defineAbilitiesForSettings(allow, forbid);

    defineAbilitiesForLandfills(allow, forbid);

    defineAbilitiesForLandfillStatus(user, allow, forbid);

    defineAbilitiesForCompanies(allow, forbid);

    defineAbilitiesForSubstances(allow, forbid);

    defineAbilitiesForToxicLimits(allow, forbid);

    defineAbilitiesForInvoices(user, allow, forbid);

    defineAbilitiesForLocks(user, allow, forbid);

    defineAbilitiesForReleaseNotes(allow, forbid);

    defineAbilitiesForLocalization(allow, forbid);

    defineAbilitiesForBeastIntegration(allow, forbid);

    defineAbilitiesForVehicleTypes(allow, forbid);

    defineAbilitiesForTimeSpent(allow, forbid);

    const result: Partial<IAbility> = new Ability(rules, {
        subjectName(subject) {
            return !subject || typeof subject === 'string' ? subject : subject[TYPE_FIELD];
        },
    });

    result.subject = (name: IAbilitySubject, object: {}) => {
        return { [TYPE_FIELD]: name, ...object };
    };

    return result as IAbility;
};
