import React from 'react';

import { FormikSelectField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { IDumpTypeSelect } from '~/@store/dumpTypes';
import { DumpTypeSelectQuery } from '~/@views/AdminView/common/DumpTypeSelectQuery';

import { IDumpLoadFormDumpType, useDumpLoadFormikContext } from '../DumpLoadEditFormik';

type Props = { disabled?: boolean };

export const DumpLoadEditDumpTypeSelect = ({ disabled }: Props) => {
    const formik = useDumpLoadFormikContext();

    return (
        <DumpTypeSelectQuery>
            {(dumpTypes: IDumpTypeSelect[]) => {
                return (
                    <FormikSelectField
                        label={<LocalizableText code={'massCategory'} />}
                        name={'dumpType'}
                        formik={formik}
                        items={dumpTypes as IDumpLoadFormDumpType[]}
                        renderValue={(dumpType: IDumpLoadFormDumpType) => dumpType.name}
                        keyGetter={(dumpType: IDumpLoadFormDumpType) => dumpType.id}
                        addEmptyOption={false}
                        disabled={disabled}
                    />
                );
            }}
        </DumpTypeSelectQuery>
    );
};
