import './Map.scss';

import { DirectionsRenderer, GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import {
    GoogleMapsControlPosition,
    GoogleMapsDirectionResult,
    GoogleMapsMapTypeControlStyle,
    GoogleMapsMapTypeIds,
    GoogleMapsMouseEvent,
    homePageMapStore,
} from '~/@user-store/homePageMap';

import config from '../../../../config';

const options = {
    mapTypeId: GoogleMapsMapTypeIds.HYBRID,
    zoomControl: true,
    zoomControlOptions: {
        position: GoogleMapsControlPosition.TOP_RIGHT,
    },
    mapTypeControl: true,
    mapTypeControlOptions: {
        style: GoogleMapsMapTypeControlStyle.DROPDOWN_MENU,
        position: GoogleMapsControlPosition.TOP_RIGHT,
    },
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
};

type Props = {
    children: React.ReactNode;
    route?: GoogleMapsDirectionResult | null;
    onMapClick?: (event: GoogleMapsMouseEvent) => void;
    mapClassName?: string;
};

export const Map = ({ children, route, onMapClick, mapClassName }: Props) => {
    const { element } = useFunctionalBem(Map);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: config.googleMapsApiKey,
    });

    return isLoaded ? (
        <GoogleMap
            mapContainerClassName={mapClassName || element('container')}
            options={options}
            onLoad={map => homePageMapStore.onMapLoad(map)}
            onZoomChanged={() => homePageMapStore.onZoomChanged()}
            onCenterChanged={() => homePageMapStore.onCenterChanged()}
            onClick={onMapClick}>
            {children}

            {route ? (
                <DirectionsRenderer directions={route} options={{ suppressMarkers: true, preserveViewport: true }} />
            ) : null}
        </GoogleMap>
    ) : null;
};
