import './WorkTimeSelect.scss';

import { format, setISODay } from 'date-fns';
import React from 'react';

import { Checkbox } from '~/@components/Checkbox';
import { LocalizableText } from '~/@components/LocalizableText';
import { TimeInput } from '~/@components/TimeInput';
import { getBem } from '~/@sochi-components/@bem';
import { SochiDivider } from '~/@sochi-components/SochiDivider';

export type IWorkTimeDay = { dayNumber: number; isOpen: boolean; openFrom: string; openTo: string };

type Props = {
    value: Array<IWorkTimeDay>;
    onChange: (data: Array<IWorkTimeDay>) => void;
    readOnly: boolean;
};

export class WorkTimeSelect extends React.Component<Props, { openedDayNumber: number }> {
    bem = getBem(this);

    state = {
        openedDayNumber: 1,
    };

    handleChange = (day: IWorkTimeDay) => {
        const { value, onChange } = this.props;
        const newWorkTime = value.map(d => (d.dayNumber === day.dayNumber ? day : d));
        onChange(newWorkTime);
    };

    render() {
        const { className, element } = this.bem;
        const { value, readOnly } = this.props;
        const { openedDayNumber } = this.state;

        return (
            <div className={className}>
                {value.map(d => {
                    const isOpen = d.dayNumber === openedDayNumber && !readOnly;
                    const isFullDay = d.openFrom === '00:00' && d.openTo === '23:59';

                    return (
                        <div
                            key={d.dayNumber}
                            className={element('day-container', { 'is-open': isOpen })}
                            onClick={() => this.setState({ openedDayNumber: d.dayNumber })}>
                            <Checkbox
                                className={element('day-checkbox')}
                                onChange={() => {}}
                                checked={isOpen}
                                fullWidth
                                label={
                                    <div className={element('day-checkbox-label')}>
                                        <span className={element('day-name')}>
                                            {format(setISODay(new Date(), d.dayNumber), 'EEE')}
                                        </span>
                                        <span className={element('day-time')}>
                                            {d.isOpen ? (
                                                `${d.openFrom} - ${d.openTo}`
                                            ) : (
                                                <LocalizableText code={'LandfillForm.closed'} />
                                            )}
                                        </span>
                                    </div>
                                }
                            />
                            {isOpen && (
                                <div className={element('day-form')}>
                                    <div className={element('form-section')}>
                                        <TimeInput
                                            label={<LocalizableText code={'LandfillForm.openingTime'} />}
                                            value={d.openFrom}
                                            onChange={(_: unknown, time: string) =>
                                                this.handleChange({ ...d, openFrom: time })
                                            }
                                            disabled={!d.isOpen || readOnly}
                                        />
                                        <TimeInput
                                            label={<LocalizableText code={'LandfillForm.closingTime'} />}
                                            value={d.openTo}
                                            onChange={(_: unknown, time: string) =>
                                                this.handleChange({ ...d, openTo: time })
                                            }
                                            disabled={!d.isOpen || readOnly}
                                            placeholder="23:59"
                                        />
                                    </div>
                                    <div className={element('form-section')}>
                                        <Checkbox
                                            className={element('form-checkbox')}
                                            label={
                                                <span className={element('form-checkbox-label')}>
                                                    <LocalizableText code={'LandfillForm.open24hrs'} />
                                                </span>
                                            }
                                            onChange={() =>
                                                this.handleChange({ ...d, openFrom: '00:00', openTo: '23:59' })
                                            }
                                            checked={isFullDay}
                                            disabled={isFullDay || readOnly}
                                        />
                                        <Checkbox
                                            className={element('form-checkbox')}
                                            label={
                                                <span className={element('form-checkbox-label')}>
                                                    <LocalizableText code={'LandfillForm.closed'} />
                                                </span>
                                            }
                                            onChange={() => this.handleChange({ ...d, isOpen: !d.isOpen })}
                                            checked={!d.isOpen}
                                            disabled={readOnly}
                                        />
                                    </div>
                                </div>
                            )}
                            <SochiDivider dense />
                        </div>
                    );
                })}
            </div>
        );
    }
}
