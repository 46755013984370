import { Field, FieldProps } from 'formik';
import React, { ReactNode } from 'react';

import { Checkbox } from '~/@components/Checkbox';

type CheckBoxFieldProps = {
    name: string;
    label: ReactNode;
    className?: string;
    readOnly?: boolean;
};

export function CheckBoxField({ name, label, className, readOnly }: CheckBoxFieldProps) {
    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => (
                <Checkbox
                    className={className}
                    disabled={readOnly}
                    checked={field.value}
                    onChange={(value: boolean) => form.setFieldValue(name, value)}
                    label={label}
                />
            )}
        </Field>
    );
}
