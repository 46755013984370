import React from 'react';
import { useParams } from 'react-router';

import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { IDetailedCompany, useDetailedCompany } from '~/@store/companies';
import { CustomerDetailParams } from '~/@store/routes';

import { CompanyDetailContext } from './CompanyDetailContext';
import { CompanyTabs } from './CompanyTabs';

export const CompanyDetailPage = () => {
    const { id: companyId } = useParams<CustomerDetailParams>();

    const companyQuery = useDetailedCompany(companyId);

    return (
        <QueryStateHandler query={companyQuery} dataGetter={query => query.data?.company || undefined}>
            {(company: IDetailedCompany) => (
                <CompanyDetailContext.Provider value={company}>
                    <CompanyTabs />
                </CompanyDetailContext.Provider>
            )}
        </QueryStateHandler>
    );
};
