import { UserContextRole } from '@common/enums';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useMemo } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { ProjectWhatsappLink } from '~/@views/common/ProjectWhatsappLink';
import { useUserAbilities } from '~/contexts';
import { ProjectQuery_project_userLinks, ProjectQuery_project_users } from '~/graphql';
import { showCustomDialog } from '~/services/dialog';
import { canAddOrInviteProjectUser } from '~/utils/auth';

import { ProjectCommonDataProps } from '../ProjectPage/ProjectPage';
import { showAddUserDialog } from './ProjectAddUserDIalog';
import { ProjectJoinPrintForm } from './ProjectJoinPrintForm';
import { ProjectUsersFilterSummary } from './ProjectUsersFilterSummary';
import { ProjectUsersTable } from './ProjectUsersTable';

type Props = ProjectCommonDataProps;

export type ProjectUser = {
    id: string;
    firstName: string;
    lastName: string | null;
    email: string;
    phone: string | null;
    role: UserContextRole;
};

const convertUserToProjectUser = (
    user: ProjectQuery_project_users,
    userLinks: ProjectQuery_project_userLinks[]
): ProjectUser => {
    return {
        id: user.id,
        firstName: user.name,
        lastName: user.surname,
        email: user.email,
        phone: user.phoneNumber,
        role: userLinks.find(({ userId }) => user.id === userId)?.role || UserContextRole.UNKNOWN,
    };
};

export const ProjectUsers: FC<Props> = ({ project }) => {
    const abilities = useUserAbilities();

    const { root, actions, table } = useStyles();

    const onClick = () => showAddUserDialog(project);

    const canAdd = canAddOrInviteProjectUser(abilities, project);

    const users = useMemo(
        () => (project.users || []).map(u => convertUserToProjectUser(u, project.userLinks)),
        [project]
    );

    const openPrintForm = () => {
        const ignored = showCustomDialog({
            render: closeDialog => <ProjectJoinPrintForm project={project} onClose={closeDialog} />,
        });
    };

    return (
        <div className={root}>
            <SochiTitle title={<LocalizableText code={'users'} />}>
                <div className={actions}>
                    <ProjectUsersFilterSummary />
                    {project.whatsappGroupLink && <ProjectWhatsappLink whatsappGroupLink={project.whatsappGroupLink} />}
                    <Button color="primary" variant="contained" onClick={openPrintForm}>
                        <LocalizableText code={'ProjectForm.joinCode'} />
                    </Button>
                    <Button color="primary" variant="contained" onClick={onClick} disabled={!canAdd}>
                        <LocalizableText code={'add'} />
                    </Button>
                </div>
            </SochiTitle>
            <ProjectUsersTable users={users} className={table} project={project} />
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: { padding: '0 20px 20px' },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        gap: 12,
    },
    table: {
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'scroll',
    },
}));
