import { AbilitySubjects } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { IVehicleTypeCalculatedSubject } from './vehicleTypeTypes';

export const getVehicleTypeSubject = (user: IAbilityUser): IVehicleTypeCalculatedSubject => {
    const relationRole = [user.role!];

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.VEHICLE_TYPE,
        relationRole,
    };
};
