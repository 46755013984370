import React from 'react';

import { LoadStatus } from '~/graphql';
import i18n from '~/i18n';

type Props = { children: LoadStatus | null | void; className?: string };

export const DumpLoadStatusFormatter = ({ children: status, className }: Props) => {
    return (
        <span className={className}>
            {status && status in i18n.DumploadStatusesSochi ? i18n.DumploadStatusesSochi[status] : i18n.NA}
        </span>
    );
};
