import React, { FC } from 'react';

import { FormikContextTextField } from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';

import { FormNames, IFormValues } from '../AddEditMass.types';

type Props = {
    readonly?: boolean;
};

export const LandfillFields: FC<Props> = ({ readonly = false }) => {
    return (
        <>
            <Grid item xs={4}>
                <FormikContextTextField<IFormValues, FormNames.landfillName>
                    name={FormNames.landfillName}
                    label={<LocalizableText code={'ProjectAddEditMass.landfillName'} />}
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={8}>
                <FormikContextTextField<IFormValues, FormNames.landfillAddress>
                    name={FormNames.landfillAddress}
                    label={<LocalizableText code={'ProjectAddEditMass.landfillAddress'} />}
                    disabled={readonly}
                />
            </Grid>
        </>
    );
};
