import { Button, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import { DeleteIcon } from '~/@components/Icon';

import { LocalizableText } from '../LocalizableText';

type Props = {
    onClick: () => void;
    disabled?: boolean;
    className?: string;
};

export const DeleteButton = ({ onClick, disabled, className }: Props) => {
    const { root } = useStyles();

    return (
        <Button onClick={onClick} startIcon={<DeleteIcon />} className={cn(root, className)} disabled={disabled}>
            <LocalizableText code={'delete'} />
        </Button>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        ...theme.customFont.regular,
        color: theme.palette.text.secondary,
        '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: 'transparent',
        },
    },
}));
