import { makeStyles } from '@material-ui/core/styles';
import { useUtils } from '@material-ui/pickers';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import React, { FC } from 'react';

import i18n from '../../i18n';
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from '../@theme/styles';

type DatePickerToolbarProps = ToolbarComponentProps & { message?: string };

export const DatePickerToolbar: FC<DatePickerToolbarProps> = ({ date, setOpenView, message }) => {
    const classes = useStyles();
    const utils = useUtils();

    return (
        <div className={classes.toolbar}>
            {message ? <div className={classes.message}>{message}</div> : null}
            <div className={classes.actions}>
                <button className={classes.action} onClick={() => setOpenView('year')} type="button">
                    {date ? utils.getYearText(date) : i18n.TimeFilters.YEAR}
                </button>
                <button className={classes.action} onClick={() => setOpenView('month')} type="button">
                    {date ? utils.getMonthText(date) : i18n.TimeFilters.MONTH}
                </button>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    toolbar: {
        boxSizing: 'border-box',
        width: '312px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '16px',
        padding: '8px 8px 0',
    },
    message: {
        padding: '8px',
        fontFamily: FONT_FAMILY.catamaran,
        fontSize: '16px',
        color: COLOR.grayDarker,
    },
    actions: {
        boxSizing: 'border-box',
        width: '100%',
        paddingTop: '8px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    action: {
        flexShrink: 0,
        padding: '8px 8px',

        outline: 'none',
        border: 'none',
        borderRadius: 0,

        cursor: 'pointer',

        color: COLOR.blue,
        backgroundColor: COLOR.transparent,
        '&:hover': {
            backgroundColor: COLOR.grayLight,
        },
        '&:active &:focus': {
            color: COLOR.black,
        },

        fontWeight: FONT_WEIGHT.normal,
        lineHeight: '24px',
        fontFamily: FONT_FAMILY.catamaran,
        fontSize: '18px',
        textTransform: 'capitalize',

        '& + &': {
            fontWeight: FONT_WEIGHT.semiBold,
            marginLeft: '10px',
        },
    },
});
