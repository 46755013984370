import React from 'react';

import { FormikAsyncAutocompleteField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { IUserSearchRecord } from '~/@store/users';
import { useUsersSearchFunc } from '~/@store/users/users.hooks/useUsersSearch';
import { UserRole } from '~/graphql';
import { getUserFullName } from '~/utils/user';

import { useProjectFormikContext } from './ProjectEditFormik';

type Props = { disabled?: boolean };

export const ProjectEditManager = ({ disabled }: Props) => {
    const formik = useProjectFormikContext();

    const usersSearch = useUsersSearchFunc({ roles: [UserRole.ADMIN, UserRole.EMPLOYEE] });

    return (
        <FormikAsyncAutocompleteField
            label={<LocalizableText code={'manager'} />}
            name={'owner'}
            formik={formik}
            loadOptions={usersSearch}
            renderOption={(user: IUserSearchRecord) => getUserFullName(user)}
            disabled={disabled}
            clearable
        />
    );
};
