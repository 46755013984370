import { FormControl, FormControlLabel, FormControlLabelProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { FormikContextType } from 'formik';
import React, { useCallback } from 'react';

import { IOSSwitch } from '~/@components/IOSSwitch';
import { Text } from '~/@components/Text';
import i18n from '~/i18n';

import { COLORS } from '../@theme/colors';

type FormikBooleanFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends boolean ? TFieldName : never;
    formik: FormikContextType<TValues>;
    commonLabel?: React.ReactNode;
    checkedLabel?: React.ReactNode;
    uncheckedLabel?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    inverted?: boolean;
} & Pick<FormControlLabelProps, 'labelPlacement'>;

export function FormikBooleanSwitchField<TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    commonLabel = '',
    checkedLabel = i18n.yes,
    uncheckedLabel = i18n.no,
    className = '',
    disabled,
    labelPlacement = 'start',
    inverted = false,
}: FormikBooleanFieldProps<TValues, TFieldName>) {
    const { root, formControlLabel, switchLabel, switchValue } = useStyles();

    const handleChange = useCallback(
        (_event: unknown, value: boolean) => {
            formik.setFieldTouched(name, true);
            formik.setFieldValue(name, inverted ? !value : value);
        },
        [formik, name, inverted]
    );

    const checked = Boolean(inverted ? !formik.values[name] : formik.values[name]);

    return (
        <FormControl className={cn(root, className)}>
            <FormControlLabel
                className={formControlLabel}
                control={<IOSSwitch checked={checked} onChange={handleChange} />}
                label={
                    <>
                        <Text className={switchLabel} variant="SmallText">
                            {commonLabel}
                            {!!commonLabel && ':'}
                        </Text>
                        <Text className={switchValue} variant="BodyBold">
                            {checked ? checkedLabel : uncheckedLabel}
                        </Text>
                    </>
                }
                disabled={disabled || formik.isSubmitting}
                labelPlacement={labelPlacement}
            />
        </FormControl>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    formControlLabel: {
        margin: 0,
        gap: theme.spacing(2),
    },
    switchLabel: {
        color: COLORS.grayDark,
        marginRight: theme.spacing(),
        display: 'inline',
    },
    switchValue: {
        minWidth: 24,
        display: 'inline-block',
    },
}));
