import React, { FC, ReactNode } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { Select } from '~/@components/Select';
import { DumpTypesListQuery } from '~/@store/dumpTypes/dumpType.queries';

import type { DumpTypesListQuery as IDumpTypesListQuery, DumpTypesListQueryVariables } from '../../../../../graphql';
import type { WithGraphqlProps } from '../../../../../services/hoc';
import { handledGraphql } from '../../../../../services/hoc';

type DumpType = { id: string; name: string };

interface ExternalProps {
    massCategory: DumpType | null;
    onChange: (category: DumpType | null) => void;
    errorMessage?: string | null;
    className?: string;
    disabled?: boolean;
    label?: ReactNode;
}

type InjectedProps = WithGraphqlProps<ExternalProps, IDumpTypesListQuery, DumpTypesListQueryVariables>;

const SochiDumpCategoriesSelect: FC<InjectedProps> = ({
    massCategory,
    onChange,
    disabled,
    errorMessage,
    label,
    data,
}) => {
    const options = data?.dumpTypes || [];

    return (
        <Select
            label={label}
            items={options}
            value={massCategory}
            onChange={v => onChange(v)}
            keyGetter={v => v.id}
            renderValue={v => v.name}
            helperText={errorMessage}
            error={Boolean(errorMessage)}
            addEmptyOption={false}
            disabled={disabled}
            emptyLabel={<LocalizableText code={'selectMassType'} />}
            displayEmpty
        />
    );
};

const withDumpTypes = handledGraphql<IDumpTypesListQuery, ExternalProps, DumpTypesListQueryVariables, InjectedProps>(
    DumpTypesListQuery
);

export default withDumpTypes(SochiDumpCategoriesSelect);
