import React from 'react';

import { IDeliveryLine } from '~/@store/deliveryLines';
import { DeliveryLineDeleteRestoreCell } from '~/@views/AdminView/DeliveryLinesPage/DeliveryLineCells';

import { useDeliveryLinesMassEditFormikContext } from '../DeliveryLinesMassEditFormik';

type Props = {
    deliveryLine: IDeliveryLine;
    disabled?: boolean;
};

export const DeliveryLinesMassEditDeleteRestoreCell = ({ deliveryLine, disabled = false }: Props) => {
    const formik = useDeliveryLinesMassEditFormikContext();

    return <DeliveryLineDeleteRestoreCell deliveryLine={deliveryLine} disabled={formik.dirty || disabled} />;
};
