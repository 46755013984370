import './SochiBackButton.scss';

import { TestId } from '@common/testConstants';
import React from 'react';

import { getBem } from '~/@sochi-components/@bem';

import { COLOR } from '../@theme/styles';
import { ArrowLeftIcon } from '../Icons';

export const SochiBackButtonColors = {
    blue: COLOR.blue,
    white: COLOR.white,
} as const;

export class SochiBackButton extends React.Component<{
    onClick: () => void;
    color?: typeof SochiBackButtonColors[keyof typeof SochiBackButtonColors];
    className?: string;
}> {
    static defaultProps = {
        color: SochiBackButtonColors.blue,
    };

    bem = getBem(this);
    render() {
        const { onClick, color } = this.props;
        const { className } = this.bem;

        return (
            <div className={className} onClick={onClick} data-testid={TestId.SochiBackButton}>
                <ArrowLeftIcon htmlColor={color} fontSize="inherit" />
            </div>
        );
    }
}
