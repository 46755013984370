import { ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ProjectFilterFields, projectsStore } from '~/@store/projects/project.store';
import { ProjectStatus } from '~/graphql';
import i18n from '~/i18n';

export const ProjectStatusFilter = observer(() => {
    const { selected } = useStyles();
    const availableStatuses = projectsStore.availableStatuses;
    const value = projectsStore.filter.values.status;

    const handleChange = (v: ProjectStatus | null) => {
        projectsStore.filter.setValue(ProjectFilterFields.status, v);
    };

    return (
        <>
            <ListItem button divider onClick={() => handleChange(null)}>
                ---
            </ListItem>
            {availableStatuses.map(item => (
                <ListItem
                    key={item}
                    button
                    divider
                    onClick={() => handleChange(item)}
                    className={value === item ? selected : undefined}>
                    <ListItemText>{i18n.ProjectStatus[item] || i18n.none}</ListItemText>
                </ListItem>
            ))}
        </>
    );
});

const useStyles = makeStyles(theme => ({
    selected: {
        backgroundColor: theme.palette.background.default,
    },
}));
