import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { Page } from '~/@components/Page';
import { StorePagination } from '~/@components/Pagination';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import { dumpLoadsStore, IDumpLoad, IDumpLoadsPaged, usePagedDumpLoads } from '~/@store/dumpLoads';
import i18n from '~/i18n';

import { DumpLoadsFilterSummary } from './DumpLoadsFilterSummary';
import { StatusFilter } from './StatusFilter';
import { useDumpLoadsColumns } from './useDumpLoadsColumns';

export const DumpLoadsPage: React.FC = () => {
    const { table, tableHeader } = useStyles();
    const query = usePagedDumpLoads();
    const columns = useDumpLoadsColumns();

    return (
        <Page>
            <Page.Header>
                <StatusFilter />
            </Page.Header>
            <Page.Content>
                <QueryStateHandler
                    query={query}
                    dataGetter={query => query.data?.dumpLoadsPaged || query.previousData?.dumpLoadsPaged}>
                    {(data: IDumpLoadsPaged) => (
                        <>
                            <div className={tableHeader}>
                                <DumpLoadsFilterSummary />
                                <StorePagination store={dumpLoadsStore.pagination} />
                            </div>
                            <Table<IDumpLoad>
                                columns={columns}
                                items={data.edges.map(e => e.node)}
                                keyGetter={d => `${d.id}/${d.project?.id}`}
                                emptyListMessage={i18n.noDataForFilter}
                                className={table}
                                name="DumpLoadTable"
                            />
                        </>
                    )}
                </QueryStateHandler>
            </Page.Content>
        </Page>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        height: 'calc(100% - 48px)',
    },
    tableHeader: {
        height: 48,
        display: 'flex',
        marginTop: -8,
    },
}));
