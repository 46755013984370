import { DespatchAdviceResponseType } from '@common/enums';
import React from 'react';

import { Chip } from '~/@components/Chip';
import { DESPATCH_ADVICE_RESPONSE_TYPE } from '~/@store/beastIntegration';
import i18n from '~/i18n';

type Props = { type: DespatchAdviceResponseType };

export const DespatchAdviceResponseTypeChip = ({ type }: Props) => {
    return (
        <Chip color={DESPATCH_ADVICE_RESPONSE_TYPE[type]} variant="default">
            {i18n.DespatchAdviceResponseType[type]}
        </Chip>
    );
};
