import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const LocationMarkerIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 25 25" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.00244 9.00125C5.00244 5.13125 8.13244 2.00125 12.0024 2.00125C15.8724 2.00125 19.0024 5.13125 19.0024 9.00125C19.0024 14.2513 12.0024 22.0013 12.0024 22.0013C12.0024 22.0013 5.00244 14.2513 5.00244 9.00125ZM9.50244 9.00125C9.50244 10.3813 10.6224 11.5013 12.0024 11.5013C13.3824 11.5013 14.5024 10.3813 14.5024 9.00125C14.5024 7.62125 13.3824 6.50125 12.0024 6.50125C10.6224 6.50125 9.50244 7.62125 9.50244 9.00125Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
