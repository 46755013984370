import { ReleaseNoteType } from '@common/enums';
import { FormLabel } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import * as Yup from 'yup';

import { DisableProvider } from '~/@components/@hooks';
import { Button } from '~/@components/Button';
import { CardDialog } from '~/@components/Dialog';
import { FormikDateField, FormikTextField } from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import { Markdown } from '~/@components/Markdown';
import { showCustomDialog } from '~/@store/common';
import { createNote, IReleaseNote, updateNote } from '~/@store/releaseNotes';
import i18n from '~/i18n';

type IFormValues = {
    text: string;
    releaseDate: Date | null;
};

const schema = Yup.object().shape({
    text: Yup.string().trim().required(),
    releaseDate: Yup.date().nullable().required(),
});

const submit = (note: IReleaseNote | null, { text, releaseDate }: IFormValues, type: ReleaseNoteType) => {
    return note ? updateNote(note.id, text, releaseDate!, type) : createNote(text, releaseDate!, type);
};

type Props = {
    note: IReleaseNote | null;
    type: ReleaseNoteType;
    onClose: () => void;
};

export const ReleaseNoteForm = ({ note, onClose, type }: Props) => {
    const initialValues: IFormValues = useMemo(
        () => ({
            text: note?.text || '',
            releaseDate: note?.releaseDate ? new Date(note.releaseDate) : new Date(),
        }),
        [note]
    );

    const onSubmit = async (values: IFormValues) => {
        await submit(note, values, type);

        onClose();
    };

    return (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
            {formik => (
                <Form onSubmit={formik.handleSubmit}>
                    <DisableProvider disabled={formik.isSubmitting}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormikDateField
                                    label={<LocalizableText code={'ReleaseNotes.releaseDate'} />}
                                    name="releaseDate"
                                    formik={formik}
                                    fullWidth={false}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormikTextField
                                    label={<LocalizableText code={'ReleaseNotes.markdownText'} />}
                                    multiline
                                    rows={24}
                                    name="text"
                                    formik={formik}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormLabel>{<LocalizableText code={'ReleaseNotes.preview'} />}</FormLabel>
                                <Markdown text={formik.values.text} />
                            </Grid>
                            <Grid item xs={12} container justify="space-between">
                                <Button variant="contained" onClick={onClose}>
                                    {i18n.cancel}
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => formik.handleSubmit()}>
                                    {i18n.save}
                                </Button>
                            </Grid>
                        </Grid>
                    </DisableProvider>
                </Form>
            )}
        </Formik>
    );
};

export const showReleaseNoteDialog = (note: IReleaseNote | null, type: ReleaseNoteType) =>
    showCustomDialog({
        render: closeDialog => (
            <CardDialog
                title={
                    <LocalizableText code={note ? 'ReleaseNotes.editReleaseNote' : 'ReleaseNotes.createReleaseNote'} />
                }
                fullWidth
                maxWidth="lg"
                onClose={closeDialog}>
                <ReleaseNoteForm note={note} type={type} onClose={() => closeDialog()} />
            </CardDialog>
        ),
    });
