import { ContaminationLevel } from '@common/enums';
import { InputProps } from '@material-ui/core/Input/Input';
import React, { useEffect, useMemo, useState } from 'react';

import { COLORS } from '~/@components/@theme/colors';
import { NumberField } from '~/@components/NumberField';
import { showNewValueErrorMessage, ToxicLimitsStore, useToxicLimitsPermissions } from '~/@store/toxicLimits';
import { commonPrecisions } from '~/config/enum';

type Props = {
    store: ToxicLimitsStore;
    substanceId: string;
    level: ContaminationLevel;
    hasError?: boolean;
    readOnly?: boolean;
};

export const ToxicLimitsValueInput = ({ store, substanceId, level, hasError, readOnly }: Props) => {
    const initialValue = store.getValue(substanceId)(level);
    const [value, setValue] = useState<number | null>(initialValue);
    useEffect(() => setValue(initialValue), [initialValue]);

    const handleBlur = () => {
        if (value === initialValue) return;

        const error = store.validateNewValue(substanceId, level, value);

        if (error) {
            setValue(initialValue);
            showNewValueErrorMessage(error, store.contaminationType);

            return;
        }

        store.updateValue(substanceId, level, value);
    };

    const inputProps: Partial<InputProps> = useMemo(
        () => ({
            style: {
                backgroundColor: readOnly ? COLORS.grayLighter : store.getColor(level),
                color: hasError ? COLORS.brandRed : 'inherit',
            },
        }),
        [store, hasError, readOnly, level]
    );

    const { canUpdate } = useToxicLimitsPermissions();

    return (
        <NumberField
            nullable
            min={0}
            precision={commonPrecisions.decimal}
            value={value}
            onChange={setValue}
            onBlur={handleBlur}
            error={hasError}
            InputProps={inputProps}
            disabled={readOnly || !canUpdate}
        />
    );
};
