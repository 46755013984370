import { ContentTypes } from '@common/enums';
import { IconButton } from '@material-ui/core';
import React from 'react';

import { DespatchAdviceResponseTypeChip } from '~/@components/BeastDataDialog/BeastDataTable/DespatchAdviceResponseTypeChip';
import { DateTimeFormatter, NullableFormatter } from '~/@components/Formatters';
import { DownloadIcon, EditIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn } from '~/@components/Table';
import { IDespatchAdviceResponse } from '~/@store/beastIntegration/beastIntegration.types';
import { showDeliveryLineUpdateDialog } from '~/@views/AdminView/DeliveryLinesPage/DeliveryLineEditForm';
import { saveToClient } from '~/utils/files';

import { DespatchAdviceResponseHeadCell } from './DespatchAdviceResponseHeadCell';

export const useDespatchAdviceResponsesColumns = () => {
    const onEdit = async (response: IDespatchAdviceResponse) => {
        if (!response.deliveryLine) return;

        await showDeliveryLineUpdateDialog(response.deliveryLine.id);
    };

    const onDownLoad = (response: IDespatchAdviceResponse) => {
        saveToClient(response.body, response.transactionId + '.xml', ContentTypes.textXml);
    };

    // noinspection UnnecessaryLocalVariableJS
    const columns: ITableColumn<IDespatchAdviceResponse>[] = [
        {
            title: (
                <DespatchAdviceResponseHeadCell
                    title={<LocalizableText code={'DespatchAdviceResponses.date'} />}
                    fieldName="date"
                    withFilter
                    withSort
                />
            ),
            render: row => <DateTimeFormatter>{row.date}</DateTimeFormatter>,
            name: 'date',
        },
        {
            title: (
                <DespatchAdviceResponseHeadCell
                    title={<LocalizableText code={'DespatchAdviceResponses.responseType'} />}
                    fieldName="responseType"
                    withFilter
                    withSort
                />
            ),
            render: row => <DespatchAdviceResponseTypeChip type={row.responseType} />,
            name: 'responseType',
        },
        {
            title: (
                <DespatchAdviceResponseHeadCell
                    title={<LocalizableText code={'DespatchAdviceResponses.declineReason'} />}
                    fieldName="declineReason"
                    withFilter
                />
            ),
            render: row => <NullableFormatter>{row.declineReason}</NullableFormatter>,
            name: 'declineReason',
        },
        {
            title: '',
            render: row => (
                <>
                    <IconButton onClick={() => onEdit(row)} disabled={!row.deliveryLine}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDownLoad(row)}>
                        <DownloadIcon />
                    </IconButton>
                </>
            ),
            name: 'edit',
        },
    ];

    return columns;
};
