import { useCallback } from 'react';

import { CustomDialogConfig, showCustomDialog } from '~/@store/common';
import { useUserAbilities } from '~/contexts';
import { globalMessage } from '~/services/message';

import { LockEntityType } from '../../../../common/enums';
import { formatLockErrorMessage } from './locks.utils';
import { useLockFunc } from './useLockFunc';
import { useRemoveLockFunc } from './useRemoveLockFunc';

type CustomDialog<T> = (config: CustomDialogConfig<T>) => Promise<T>;

export function useDialogWithLock<T extends unknown = boolean | void>(
    entityType: LockEntityType,
    entityId: string
): CustomDialog<T> {
    const lockFunc = useLockFunc(entityType, entityId);
    const removeLockFunc = useRemoveLockFunc(entityType, entityId);
    const { user: me } = useUserAbilities();

    return useCallback(
        (config: CustomDialogConfig<T>) => {
            let lockSuccess = false;

            return lockFunc()
                .then(lock => {
                    if (lock.userId !== me.id) {
                        globalMessage.info(formatLockErrorMessage(lock));

                        return Promise.reject();
                    } else {
                        lockSuccess = true;
                    }
                })
                .then(() => showCustomDialog(config))
                .finally(() => lockSuccess && removeLockFunc());
        },
        [lockFunc, removeLockFunc, me]
    );
}
