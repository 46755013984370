import React from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { landfillsStore } from '~/@store/landfills';
import { LandfillsTable } from '~/@views/AdminView/LandfillsPage';

import { useCompanyDetailContext } from '../CompanyDetailContext';

export const CompanyLandfillsTab = () => {
    const company = useCompanyDetailContext();

    useSyncEffect(() => landfillsStore.setCompanyTabViewMode(company.id));

    return <LandfillsTable />;
};
