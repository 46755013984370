import { ContaminationLevel } from '@common/enums';
import { ISubstanceAmount, ISubstanceAmountStrict } from '@common/toxic-analysis/toxicAnalysis.types';
import { CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { CardDialog } from '~/@components/Dialog';
import { substanceEditStore } from '~/@store/substances';
import { SubstancesEditForm } from '~/@views/AdminView/common/SubstancesEditDialog/SubstancesEditForm';

type Props = {
    onClose: (v: boolean) => void;
    substanceAmounts: ISubstanceAmount[];
    solidState: boolean;
    leachingState: boolean;
    onEditFinish: (
        values: ISubstanceAmountStrict[],
        solidState: boolean,
        leachingState: boolean,
        solidContaminationLevel: ContaminationLevel | null
    ) => void;
    title: React.ReactNode;
    readOnlySolid: boolean;
    readOnlyLeaching: boolean;
};

export const SubstancesEditDialog = observer(
    ({
        onClose,
        title,
        substanceAmounts,
        solidState,
        leachingState,
        onEditFinish,
        readOnlySolid,
        readOnlyLeaching,
    }: Props) => {
        useSyncEffect(() => {
            substanceEditStore.init(substanceAmounts, solidState, leachingState, readOnlySolid, readOnlyLeaching);
        }, []);

        const handleClose = () => {
            if (substanceEditStore.SOLID && substanceEditStore.LEACHING) {
                onEditFinish(
                    substanceEditStore.serializeAmounts(),
                    substanceEditStore.SOLID.state,
                    substanceEditStore.LEACHING.state,
                    substanceEditStore.SOLID.contaminationLevel
                );
            }
            onClose(true);
        };

        return (
            <CardDialog title={title} onClose={handleClose} fullWidth>
                {substanceEditStore.initialized ? (
                    <SubstancesEditForm editStore={substanceEditStore} />
                ) : (
                    <CircularProgress />
                )}
            </CardDialog>
        );
    }
);
