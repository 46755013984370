import { AbilitySubjects } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { ILocalizationSubject } from './localizationTypes';

export const getLocalizationSubject = (user: IAbilityUser): ILocalizationSubject => {
    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.LOCALIZATION,
        relationRole: user.role,
    };
};
