import { MAX_DUMP_LOAD_AMOUNT } from '@common/constants';
import { FormikContextType } from 'formik';
import React, { FC } from 'react';

import { FormikContextNumberField, FormikContextTextField } from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { SochiUnitOfMeasureSelect } from '~/@sochi-components/SochiUnitOfMeasureSelect';
import { SochiDumpCategoriesSelect } from '~/@views/UserView/ProjectPage/common/SochiDumpCategoriesSelect';
import {
    FormNames,
    IFormValues,
} from '~/@views/UserView/ProjectPage/ProjectPriceCalculator/AddEditMassForm/AddEditMass.types';
import { commonPrecisions } from '~/config/enum';
import i18n from '~/i18n';

type Props = {
    formik: FormikContextType<IFormValues>;
    readOnly: boolean;
};

export const CommonFields: FC<Props> = ({ formik, readOnly }) => {
    const { values, errors, touched, setFieldValue } = formik;

    return (
        <>
            <Grid item xs={12}>
                <Text variant="TitleBold">
                    <LocalizableText code={'ProjectAddEditMass.description'} />
                </Text>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <SochiDumpCategoriesSelect
                    onChange={dt => setFieldValue(FormNames.dumpType, dt)}
                    massCategory={values[FormNames.dumpType]}
                    errorMessage={touched[FormNames.dumpType] ? errors[FormNames.dumpType] : undefined}
                    label={
                        <LabelWithHint
                            label={<LocalizableText code={'ProjectAddEditMass.materialType'} />}
                            hint={<LocalizableText code={'massesFieldHint'} />}
                        />
                    }
                    disabled={readOnly}
                />
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
                <FormikContextNumberField<IFormValues, FormNames.amount>
                    name={FormNames.amount}
                    label={
                        <LabelWithHint
                            label={<LocalizableText code={'amount'} />}
                            hint={<LocalizableText code={'amountFieldHint'} />}
                        />
                    }
                    precision={commonPrecisions.dumpLoadAmount}
                    min={0}
                    max={MAX_DUMP_LOAD_AMOUNT}
                    disabled={readOnly}
                    placeholder={i18n.ProjectAddEditMass.amountPlaceholder}
                />
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
                <SochiUnitOfMeasureSelect
                    label={
                        <LabelWithHint
                            label={<LocalizableText code={'unit'} />}
                            hint={<LocalizableText code={'unitFieldHint'} />}
                        />
                    }
                    value={values[FormNames.unitOfMeasure]}
                    onChange={(v: string) => setFieldValue(FormNames.unitOfMeasure, v)}
                    disabled={readOnly}
                />
            </Grid>
            <Grid item xs={12}>
                <FormikContextTextField<IFormValues, FormNames.comment>
                    name={FormNames.comment}
                    label={<LocalizableText code={'ProjectAddEditMass.massDescription'} />}
                    disabled={readOnly}
                />
            </Grid>
        </>
    );
};
