import React, { useMemo } from 'react';

import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { dumpLoadsStore, IDumpLoad } from '~/@store/dumpLoads';
import { projectDumpLoadRoute } from '~/@store/routes';

type Props = {
    dumpLoad: IDumpLoad;
};

export const DumpLoadProjectCell = ({ dumpLoad }: Props) => {
    const isVolumesMode = dumpLoadsStore.isVolumesMode;

    const actions = useMemo(() => {
        const actions: Array<ITableCellAction> = [
            {
                variant: ActionVariant.LINK,
                to: projectDumpLoadRoute(dumpLoad.project?.id || '', dumpLoad.id),
                targetBlank: true,
            },
        ];

        if (!isVolumesMode)
            actions.push({
                variant: ActionVariant.COPY,
                content: dumpLoad.project?.name,
            });

        return actions;
    }, [dumpLoad, isVolumesMode]);

    return <TableCellActions actions={actions}>{dumpLoad.project?.name}</TableCellActions>;
};
