import { getReceiptFileName } from '@common/functions/common.deliveryLine';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { FilePreview, NewFilePreview } from '~/@components/FilePreview';
import { ImgLoader } from '~/@sochi-components/ImgLoader';
import { IDeliveryLineShort } from '~/@store/deliveryLines';
import { downloadReceiptUrl } from '~/apolloClient';

type Props = {
    deliveryLine?: IDeliveryLineShort;
    newReceipt?: File | null;
};

export const DeliveryLineReceiptView = ({ deliveryLine, newReceipt }: Props) => {
    const receiptUrl = deliveryLine?.receiptUrl;
    const receiptName = deliveryLine ? getReceiptFileName(deliveryLine) : null;
    const { root } = useStyles();

    return (
        <div className={root}>
            {newReceipt ? (
                <NewFilePreview file={newReceipt} />
            ) : deliveryLine && receiptUrl && receiptName ? (
                <ImgLoader
                    url={downloadReceiptUrl}
                    params={{
                        deliveryLineId: deliveryLine.id,
                        filename: receiptUrl,
                    }}>
                    {base64 => <FilePreview base64={base64} filename={receiptName} />}
                </ImgLoader>
            ) : null}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.palette.inputBorder}`,
        borderRadius: theme.shape.borderRadius,
        padding: 2,
    },
}));
