import { Divider, Grid } from '@material-ui/core';
import React from 'react';

import { IDetailedProject } from '~/@store/projects';

import { ProjectCertificatesList } from './ProjectCertificatesList';
import { ProjectDumpLoadsDocuments } from './ProjectDumpLoadsDocuments';

type Props = {
    project: IDetailedProject;
};

export const ProjectDetailDocuments = ({ project }: Props) => {
    return (
        <Grid container spacing={3} wrap="nowrap">
            <Grid item xs={8}>
                <ProjectDumpLoadsDocuments project={project} />
            </Grid>
            <Grid item xs>
                <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={4}>
                <ProjectCertificatesList project={project} />
            </Grid>
        </Grid>
    );
};
