import { AbilityCan, AbilitySubjects, UserRole } from '../../enums';
import { IAbilityFunction } from '../abilities.types';
import { ICaslCondition } from '../utils';
import { ISubstanceCalculatedSubject } from './substanceTypes';

const { CRUD, READ } = AbilityCan;
const { ADMIN, EMPLOYEE, EXTERNAL_RECEIVER } = UserRole;

// forbid argument kept for uniform define ability calls
export function defineAbilitiesForSubstances(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<ISubstanceCalculatedSubject>;

    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _allow(can, AbilitySubjects.SUBSTANCE, shape);

    allow(CRUD, { relationRole: ADMIN });
    allow(READ, { relationRole: EMPLOYEE });
    allow(READ, { relationRole: EXTERNAL_RECEIVER });
}
