import { formatString } from '@common/utils';
import { FormikConfig } from 'formik';
import * as Yup from 'yup';

import { IBatch } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm/LandfillBatchForm';
import i18n from '~/i18n';
import { createBatch, updateBatch } from '~/utils/landfill';

export type IFormValues = {
    landfillId: string;
    name: string;
    dumpType: {
        id: string;
        name: string;
    } | null;
    comment: string;
    archived: boolean;
};

export enum FormNames {
    name = 'name',
    dumpType = 'dumpType',
    comment = 'comment',
}

export const getLandfillBatchFormikConfig = (
    landfillId: string,
    batch: IBatch | null,
    onClose: () => void
): FormikConfig<IFormValues> => ({
    initialValues: {
        landfillId,
        name: batch?.name || '',
        dumpType: batch
            ? {
                  id: batch.dumpType.id,
                  name: batch.dumpType.name,
              }
            : null,
        comment: batch?.comment || '',
        archived: !!batch?.closedAt,
    },
    validationSchema: Yup.object().shape({
        name: Yup.string().nullable().trim().required(formatString(i18n.isRequired, i18n.LandfillPage.batchCard.name)),
        dumpType: Yup.object().nullable().required(formatString(i18n.isRequired, i18n.LandfillPage.batchCard.dumpType)),
    }),
    onSubmit: async values => {
        const { name, dumpType, comment } = values;

        if (!name || dumpType === null) return;

        if (batch) {
            await updateBatch(landfillId, batch.id, {
                name,
                dumpTypeId: dumpType.id,
                comment,
            });
        } else {
            await createBatch(landfillId, {
                name,
                dumpTypeId: dumpType.id,
                comment,
            });
        }

        onClose();
    },
});
