import { ErrorCodes, TransferType } from '@common/enums';
import { formatString } from '@common/utils';
import { isNumber } from '@common/validations/types.validation';
import { FormikConfig } from 'formik';
import * as Yup from 'yup';

import { IBatchTransfer } from '~/@user-store/landfillBatches';
import { ISimpleBatch } from '~/@views/UserView/LandfillPage/common/LandfillBatchSelect';
import {
    canSkipValidation,
    getBatchTransferInput,
} from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchTransferForm/LandfillBatchTransfer.helpers';
import { BatchTransferInput } from '~/graphql';
import i18n from '~/i18n';

import { FormNames, IFormValues } from '../LandfillBatchForm/LandfillBatchFormik.types';

export const getLandfillBatchTransferFormikConfig = (
    transfer: IBatchTransfer | null,
    onSave: (input: BatchTransferInput) => Promise<void>
): FormikConfig<IFormValues> => ({
    initialValues: {
        type: transfer?.type || TransferType.INTERNAL,
        date: transfer?.date ? new Date(transfer.date) : new Date(),
        weight: transfer?.weight || null,
        fromBatch: transfer?.fromBatch || null,
        toBatch: transfer?.toBatch || null,
        externalProjectName: transfer?.externalProject?.name || '',
        externalProjectAddress: transfer?.externalProject?.address || '',
        licensePlate: transfer?.licensePlate || '',
        comment: transfer?.comment || '',
    },
    validationSchema: Yup.object().shape({
        weight: Yup.number()
            .nullable()
            .test(
                'Weight check',
                formatString(i18n.isRequired, i18n.LandfillPage.transferCard.weight),
                function (weight) {
                    if (canSkipValidation(this.parent.type, FormNames.weight, weight)) return true;

                    return isNumber(weight) && weight >= 0;
                }
            ),
        date: Yup.date()
            .nullable()
            .test('Date check', formatString(i18n.isRequired, i18n.LandfillPage.transferCard.date), function (date) {
                if (canSkipValidation(this.parent.type, FormNames.date, date)) return true;

                return !!date;
            }),
        comment: Yup.string()
            .nullable()
            .test(
                'Comment check',
                formatString(i18n.isRequired, i18n.LandfillPage.transferCard.comment),
                function (comment) {
                    if (canSkipValidation(this.parent.type, FormNames.comment, comment)) return true;

                    return !!comment;
                }
            ),
        fromBatch: Yup.object<ISimpleBatch>()
            .nullable()
            .test(
                'From batch check',
                formatString(i18n.isRequired, i18n.LandfillPage.transferCard.fromBatch),
                function (fromBatch) {
                    if (canSkipValidation(this.parent.type, FormNames.fromBatch, fromBatch)) return true;

                    if (!fromBatch) return false;

                    if (this.parent.toBatch?.id === (fromBatch as ISimpleBatch).id)
                        return this.createError({
                            path: FormNames.fromBatch,
                            message: i18n.errorCodes[ErrorCodes.LANDFILL_TRANSFER_BATCHES_SHOULD_BE_DIFFERENT],
                        });

                    return true;
                }
            ),
        toBatch: Yup.object<ISimpleBatch>()
            .nullable()
            .test(
                'To batch check',
                formatString(i18n.isRequired, i18n.LandfillPage.transferCard.toBatch),
                function (toBatch) {
                    if (canSkipValidation(this.parent.type, FormNames.toBatch, toBatch)) return true;

                    if (!toBatch) return false;

                    if (this.parent.fromBatch?.id === (toBatch as ISimpleBatch).id)
                        return this.createError({
                            path: FormNames.toBatch,
                            message: i18n.errorCodes[ErrorCodes.LANDFILL_TRANSFER_BATCHES_SHOULD_BE_DIFFERENT],
                        });

                    return true;
                }
            ),
        externalProjectName: Yup.string()
            .nullable()
            .test(
                'Project name check',
                formatString(i18n.isRequired, i18n.LandfillPage.transferCard.projectName),
                function (projectName) {
                    if (canSkipValidation(this.parent.type, FormNames.externalProjectName, projectName)) return true;

                    return !!projectName;
                }
            ),
        externalProjectAddress: Yup.string()
            .nullable()
            .test(
                'Project address check',
                formatString(i18n.isRequired, i18n.LandfillPage.transferCard.projectAddress),
                function (projectAddress) {
                    if (canSkipValidation(this.parent.type, FormNames.externalProjectAddress, projectAddress))
                        return true;

                    return !!projectAddress;
                }
            ),
        licensePlate: Yup.string()
            .nullable()
            .test(
                'License plate check',
                formatString(i18n.isRequired, i18n.LandfillPage.transferCard.licensePlate),
                function (licensePlate) {
                    if (canSkipValidation(this.parent.type, FormNames.licensePlate, licensePlate)) return true;

                    return !!licensePlate;
                }
            ),
    }),
    onSubmit: async values => {
        const input = getBatchTransferInput(values);
        await onSave(input);
    },
});
