import { List } from '@material-ui/core';
import React, { VFC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { fuelList, VehicleType } from '~/@store/vehicleTypes';

import { VehicleTypeCard } from './VehicleTypeCard';

type VehicleTypesListProps = {
    data: VehicleType[];
    selectItemId: (id: string) => void;
    selectedItemId: string | null;
};

export const VehicleTypesList: VFC<VehicleTypesListProps> = ({ data, selectItemId, selectedItemId }) => {
    const { header } = useStyles();

    return (
        <>
            <Grid container className={header}>
                <Grid item xs={6}>
                    <Text variant="TableBold" color="textSecondary">
                        <LocalizableText code="VehicleType.name" />
                    </Text>
                </Grid>
                {fuelList.map(fuel => (
                    <Grid item xs={2} key={fuel}>
                        <Text variant="TableBold" color="textSecondary">
                            {fuel}
                        </Text>
                    </Grid>
                ))}
            </Grid>
            <List>
                {data.map(vt => (
                    <VehicleTypeCard
                        key={vt.id}
                        vehicleType={vt}
                        isActive={selectedItemId === vt.id}
                        select={() => selectItemId(vt.id)}
                    />
                ))}
            </List>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    header: {
        padding: theme.spacing(0, 2),
    },
}));
