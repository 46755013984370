import './ProjectForm.scss';

import { ProjectSubject } from '@common/abilities/projects';
import InputLabel from '@material-ui/core/InputLabel';
import { Field, FieldProps, Form, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import type { RouteComponentProps } from 'react-router';

import { Button } from '~/@components/Button';
import { FormikContextTextField } from '~/@components/FormikFields';
import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { TextField } from '~/@components/TextField';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { MapWithContentContainer } from '~/@sochi-components/MapWithContentContainer';
import { SideBarTitle } from '~/@sochi-components/SideBarTitle';
import { SochiBackButton } from '~/@sochi-components/SochiBackButton';
import { ILocation } from '~/@store/locations';
import { useGeocodeWithState } from '~/@store/locations/useGeocodeWithState';
import { homePageMapStore } from '~/@user-store/homePageMap';
import { SochiCustomerSelect } from '~/@views/UserView/common/SochiCustomerSelect';
import type { WithAbilitiesProps } from '~/contexts';
import { useUserAbilities } from '~/contexts';
import type {
    CompaniesSearchQuery_pplCompanies as CompaniesSearchQuery_companies,
    ProjectQuery_project,
} from '~/graphql';
import i18n from '~/i18n';
import { canGetCompanyList, canReadProjectCustomer, canUpdateProject } from '~/utils/auth';
import { getJoinCode } from '~/utils/project';

import { FieldName } from './constants';
import { IDates, IMaybeDates, ProjectDateRange } from './ProjectDateRange';
import { ProjectLocationSelect } from './ProjectLocationSelect';
import { ProjectMapInput } from './ProjectMapInput';

export type ProjectFormValues = {
    name: string;
    dates: IDates;
    location: ILocation;
    invoiceReference: string;
    comment: string;
    customer?: Omit<CompaniesSearchQuery_companies, '__typename'> | null;
};

export type ProjectFormExternalProps = {
    project: ProjectQuery_project | null;
    onBack?: () => void;
};

export type ProjectLocationProps = {
    loading: boolean;
    getByLatLng: (lat: number, lng: number) => Promise<ILocation>;
    getByText: (text: string) => Promise<ILocation>;
};

export type ProjectFormProps = ProjectFormExternalProps & RouteComponentProps & WithAbilitiesProps;

export const ProjectForm = (props: ProjectFormProps & FormikProps<ProjectFormValues>) => {
    const context = useUserAbilities();

    const { block, element } = useFunctionalBem(ProjectForm);

    const [loading, getByLatLng, getByText] = useGeocodeWithState();

    useEffect(() => {
        if (!props.project && homePageMapStore.selectedPlacePojo) {
            props.setFieldValue(FieldName.location, homePageMapStore.selectedPlacePojo, true);
        }
    }, []);

    const updateDate = (state: IMaybeDates) => {
        props.setFieldValue(FieldName.dates, { ...props.values.dates, ...state });
    };

    const renderContent = () => {
        const { project, values, errors, onBack, isSubmitting } = props;
        const readOnly = Boolean(project && !canUpdateProject(context, project));
        const fieldReadOnly = !!project && !canUpdateProject(context, project, [ProjectSubject.ALWAYS_EDITABLE_FIELDS]);

        const joinCode = getJoinCode(project);

        return (
            <div className={element('fields')}>
                <div>
                    {onBack && <SochiBackButton onClick={onBack} />}
                    <SideBarTitle
                        title={
                            <LocalizableText
                                code={props.project ? 'ProjectForm.projectInformation' : 'ProjectForm.createProject'}
                            />
                        }
                    />
                </div>

                <div className={element('form')}>
                    <FormikContextTextField<ProjectFormValues, FieldName.name>
                        name={FieldName.name}
                        label={<LocalizableText code={'ProjectForm.projectLabel'} />}
                        placeholder={i18n.ProjectForm.projectName}
                        disabled={readOnly}
                    />
                    {canReadProjectCustomer(context, project) && (
                        <div className={element('input-container')}>
                            <Field name={FieldName.customer}>
                                {({ field, form, meta }: FieldProps) => (
                                    <SochiCustomerSelect
                                        value={field.value || null}
                                        onChange={value => form.setFieldValue(FieldName.customer, value)}
                                        errorMessage={(meta.touched && meta.error) || undefined}
                                        disabled={form.isSubmitting || !canGetCompanyList(context)}
                                    />
                                )}
                            </Field>
                        </div>
                    )}
                    <div className={element('input-container')}>
                        <ProjectDateRange
                            onChange={updateDate}
                            project={project}
                            values={values.dates}
                            label={
                                <LabelWithHint
                                    label={<LocalizableText code={'ProjectForm.startAndEndDates'} />}
                                    hint={<LocalizableText code={'ProjectForm.startAndEndDatesFieldHint'} />}
                                />
                            }
                            errorMessage={errors[FieldName.dates] ? `${errors[FieldName.dates]}` : undefined}
                            readOnly={isSubmitting || readOnly}
                        />
                    </div>
                    <div className={element('divider')} />
                    <div className={element('input-container')}>
                        <ProjectLocationSelect
                            {...props}
                            loading={loading}
                            getByLatLng={getByLatLng}
                            getByText={getByText}
                        />
                    </div>
                    {project && (
                        <FormikContextTextField<ProjectFormValues, FieldName.invoiceReference>
                            disabled={fieldReadOnly}
                            name={FieldName.invoiceReference}
                            label={
                                <LabelWithHint
                                    label={<LocalizableText code={'ProjectForm.invoiceReference'} />}
                                    hint={<LocalizableText code={'ProjectForm.invoiceReferenceFieldHint'} />}
                                />
                            }
                        />
                    )}
                    {joinCode && (
                        <div className={element('input-container')}>
                            <TextField
                                value={joinCode}
                                disabled
                                label={<LocalizableText code={'ProjectForm.joinCode'} />}
                            />
                        </div>
                    )}
                    {props.project && (
                        <FormikContextTextField<ProjectFormValues, FieldName.comment>
                            name={FieldName.comment}
                            label={<LocalizableText code={'ProjectForm.comment'} />}
                            multiline
                            rows={3}
                            rowsMax={3}
                            disabled={fieldReadOnly}
                        />
                    )}
                    <InputLabel error={Boolean(errors.location)}>{errors.location || ' '}</InputLabel>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!props.dirty || props.isSubmitting}>
                        {i18n.save}
                    </Button>
                </div>
            </div>
        );
    };

    const renderMap = () => {
        return <ProjectMapInput {...props} loading={loading} getByLatLng={getByLatLng} getByText={getByText} />;
    };

    return (
        <Form className={block({ edit: !!props.project })}>
            <MapWithContentContainer renderContent={renderContent} renderMap={renderMap} />
        </Form>
    );
};
