import React, { ReactElement } from 'react';

import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { IDumpTypeSelect, useDumpTypeSelect } from '~/@store/dumpTypes';

type DumpTypesQueryProps = {
    children: (dumpTypes: IDumpTypeSelect[]) => ReactElement;
};

export function DumpTypeSelectQuery({ children }: DumpTypesQueryProps) {
    const dumpTypesQuery = useDumpTypeSelect();

    return (
        <QueryStateHandler query={dumpTypesQuery} dataGetter={query => query.data?.dumpTypes} progressSize="linear">
            {(dumpTypes: IDumpTypeSelect[]) => children(dumpTypes)}
        </QueryStateHandler>
    );
}
