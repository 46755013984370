// eslint-disable-next-line pinpointer/equal-enum
export enum COLORS {
    black = '#000000',
    yellow = '#F3BB1C',
    green = '#3CC13B',
    mintGreen = '#9DE09D',
    blue = '#3E66FB',
    blueLight = '#9EB3FD',
    brandMain = '#141B4D',
    hoverDark = '#3D436E',
    brandLight = '#3A4CCC',
    textBody = '#2B2B2B',
    gray = '#C2C9D1',
    grayLight = '#E1E4E8',
    grayLight2 = '#EEEFEF',
    grayLighter = '#F0F2F3',
    grayLightest = '#F9FAFA',
    grayDark = '#92979d',
    grayDarker = '#616569',
    grayDarkest = '#4f4f4f',
    grayMarker = '#BDBDBD',
    white = '#FFFFFF',
    brandRed = '#EE2737',
    transparent = 'transparent',
    layoutDarker = 'rgba(0, 0, 0, 0.15)',
    layoutFade = 'rgba(43, 43, 43, 0.04)',
    orange = '#FFA500',
    darkTurquoise = '#00CED1',
    turquoise = '#40E0D0',
    blackTransparent = 'rgba(0, 0, 0, 0.4)',
}
