import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';
import { normalizeSubstanceAmounts } from '@common/toxic-analysis/toxicAnalysis.common';
import { ISubstanceAmount } from '@common/toxic-analysis/toxicAnalysis.types';

import { IDetailedDumpLoad } from '~/@store/dumpLoads';
import * as queries from '~/@store/dumpLoads/dumpLoads.queries';
import {
    calculateDestinationResult,
    calculateDestinationResult_calculateDestinationResult,
    calculateDestinationResultVariables,
    DestinationFragment,
    DumpLoadDeliveryInfoInput,
    UnitOfMeasure,
} from '~/graphql';

export type IDestinationResult = calculateDestinationResult_calculateDestinationResult;

export type IDestination = DestinationFragment;

export const useDestinationResults = (
    dumpLoad: IDetailedDumpLoad,
    dumpLoadSubstances: ISubstanceAmount[]
): QueryResult<calculateDestinationResult> => {
    const input: DumpLoadDeliveryInfoInput = {
        id: dumpLoad.id,
        dumpTypeId: dumpLoad.dumpType?.id || '',
        unitOfMeasure: dumpLoad.unitOfMeasure || UnitOfMeasure.TONNS,
        amount: dumpLoad.amount || 0,
        stackability: dumpLoad.stackability || false,
        over50cm: dumpLoad.over50cm || false,
        withTOC: dumpLoad.withTOC,
        withInvasiveSpecies: dumpLoad.withInvasiveSpecies,
        inbound: dumpLoad.inbound,
    };

    const substances = normalizeSubstanceAmounts(dumpLoadSubstances, !!dumpLoad.solidState, !!dumpLoad.leachingState);

    return useQuery<calculateDestinationResult, calculateDestinationResultVariables>(
        queries.calculateDestinationResultQuery,
        {
            variables: {
                projectId: dumpLoad.project!.id,
                input,
                substances,
            },
        }
    );
};
