import { IBatch } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm';
import { BatchLogQuery_batchAnalyses, BatchLogQuery_batchTransfers } from '~/graphql';

export enum Kind {
    LINE_INCOMING = 'LINE_INCOMING',
    LINE_OUTGOING = 'LINE_OUTGOING',
    TRANSFER = 'TRANSFER',
    ANALYSIS = 'ANALYSIS',
}

export type ITransfer = Omit<BatchLogQuery_batchTransfers, 'date' | '__typename'> & {
    kind: Kind.TRANSFER;
    amount?: number;
    signSymbol?: '+' | '-';
    date: Date;
    displayRemain?: boolean;
};

type IBatchDeliveryLine = {
    id: string;
    truckRegistrationNumber: string;
    projectName: string;
    weight: number;
    amount?: number;
    signSymbol?: '+' | '-';
    date: Date;
    batch: IBatch;
    displayRemain?: boolean;
};

export type IBatchDeliveryIncomingLine = IBatchDeliveryLine & {
    kind: Kind.LINE_INCOMING;
};

export type IBatchDeliveryOutgoingLine = IBatchDeliveryLine & {
    kind: Kind.LINE_OUTGOING;
};

export type IAnalysis = Omit<BatchLogQuery_batchAnalyses, 'date' | '__typename'> & {
    kind: Kind.ANALYSIS;
    date: Date;
};

export type IBatchLogRecord = ITransfer | IBatchDeliveryIncomingLine | IBatchDeliveryOutgoingLine | IAnalysis;
