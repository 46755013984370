import { MAX_TOC_VALUE, MIN_TOC_VALUE } from '@common/validations/common.validation';
import { Grid } from '@material-ui/core';
import React, { FC } from 'react';

import { FormikBooleanField, FormikNumberField, FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { useDumpLoadAbilities } from '~/@store/dumpLoads';
import { useDumpLoadFormikContext } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm/DumpLoadEditFormik';

export const DumpLoadEditAttributesFields: FC = () => {
    const formik = useDumpLoadFormikContext();
    const { dumpLoad } = formik.values;
    const { canUpdateFieldsAffectsDestination } = useDumpLoadAbilities(dumpLoad);
    const disabledFieldsAffectsDestination = !canUpdateFieldsAffectsDestination;

    return (
        <>
            <Grid item xs={3}>
                <FormikBooleanField
                    label={<LocalizableText code={'stackability'} />}
                    name="stackability"
                    formik={formik}
                    disabled={disabledFieldsAffectsDestination}
                />
            </Grid>
            <Grid item xs={3}>
                <FormikBooleanField
                    label={<LocalizableText code={'over50cm'} />}
                    name="over50cm"
                    formik={formik}
                    disabled={disabledFieldsAffectsDestination}
                />
            </Grid>
            <Grid item xs={3}>
                <FormikBooleanField
                    label={<LocalizableText code={'withTOC'} />}
                    name="withTOC"
                    formik={formik}
                    disabled={disabledFieldsAffectsDestination}
                />
            </Grid>
            <Grid item xs={3}>
                <FormikNumberField
                    label={<LocalizableText code={'TOCValue'} />}
                    name="TOCPercent"
                    formik={formik}
                    disabled={disabledFieldsAffectsDestination || !formik.values.withTOC}
                    min={MIN_TOC_VALUE}
                    max={MAX_TOC_VALUE}
                    required={formik.values.withTOC}
                    forceZero={formik.values.withTOC}
                />
            </Grid>
            <Grid item xs={5}>
                <FormikBooleanField
                    label={<LocalizableText code={'withInvasiveSpecies'} />}
                    name="withInvasiveSpecies"
                    formik={formik}
                    disabled={disabledFieldsAffectsDestination}
                />
            </Grid>
            <Grid item xs={7}>
                <FormikTextField
                    label={<LocalizableText code={'invasiveSpecies'} />}
                    name="invasiveSpecies"
                    formik={formik}
                    disabled={disabledFieldsAffectsDestination || !formik.values.withInvasiveSpecies}
                    required={formik.values.withInvasiveSpecies}
                />
            </Grid>
        </>
    );
};
