import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { projectsStore } from '~/@store/projects/project.store';
import { ProjectRemoveMutation, ProjectRemoveMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../projects.queries';

export type RemoveProjectFunc = (id: string) => Promise<boolean>;

export const useProjectRemove = (): [RemoveProjectFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<ProjectRemoveMutation, ProjectRemoveMutationVariables>(
        queries.ProjectRemoveMutation,
        {
            refetchQueries: [
                {
                    query: queries.AdminProjectsPagedShortQuery,
                    variables: projectsStore.projectsQueryVariables,
                },
            ],
        }
    );

    const removeProject = useCallback<RemoveProjectFunc>(
        id => {
            return callMutation({
                variables: { id },
            })
                .then(() => true)
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [removeProject, mutationResult.loading];
};
