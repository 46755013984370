import { gql } from '@apollo/client';

export const DumpTypeSelectQuery = gql`
    query DumpTypeSelectQuery {
        dumpTypes {
            id
            name
            tonnsPerCubicM
            description
            contaminationLevelAvailable
        }
    }
`;

export const DumpTypeInputFragment = gql`
    fragment DumpTypeInputFragment on DumpType {
        id
        name
        description
        tonnsPerCubicM
        position
    }
`;

// TODO Remove with old Admin
export const DumpTypeUpdateFragment = gql`
    fragment DumpTypeUpdateFragment on DumpType {
        id
        name
        description
        tonnsPerCubicM
        position
        usedInDeclaration
    }
`;

export const DumpTypeFragment = gql`
    fragment DumpTypeFragment on DumpType {
        id
        externalId
        name
        description
        tonnsPerCubicM
        position
        usedInDeclaration
        contaminationLevelAvailable
    }
`;

export const DumpTypeUpdatePositionFragment = gql`
    fragment DumpTypeUpdatePositionFragment on DumpType {
        id
        position
    }
`;

export const DumpTypeRemoveMutation = gql`
    mutation DumpTypeRemoveMutation($id: ID!) {
        dumpTypeRemove(id: $id)
    }
`;

export const DumpTypeUpdateMutation = gql`
    mutation DumpTypeUpdateMutation($input: DumpTypeUpdateInput!) {
        dumpTypeUpdate(input: $input) {
            ...DumpTypeFragment
        }
    }

    ${DumpTypeFragment}
`;

export const DumpTypeUpdatePositionMutation = gql`
    mutation DumpTypeUpdatePositionMutation($items: [DumpTypeUpdatePosition!]!) {
        dumpTypeUpdatePosition(input: $items) {
            ...DumpTypeFragment
        }
    }

    ${DumpTypeFragment}
`;

export const DumpTypeCreateMutation = gql`
    mutation DumpTypeCreateMutation($input: DumpTypeCreateInput!) {
        dumpTypeCreate(input: $input) {
            ...DumpTypeFragment
        }
    }

    ${DumpTypeFragment}
`;

export const DumpTypeByIdQuery = gql`
    query DumpTypeByIdQuery($id: ID!) {
        dumpType(id: $id) {
            ...DumpTypeFragment
        }
    }

    ${DumpTypeFragment}
`;

export const DumpTypesListQuery = gql`
    query DumpTypesListQuery($search: String, $first: Int, $after: ID) {
        dumpTypes(search: $search, first: $first, after: $after) {
            ...DumpTypeFragment
        }
    }

    ${DumpTypeFragment}
`;
