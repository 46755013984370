import React from 'react';

import { FormikAsyncAutocompleteField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { ICompanySearchRecord, useCompaniesSearchFunc } from '~/@store/companies';

import { useDeliveryLineFormikContext } from './DeliveryLineFormikCommon';

type Props = { disabled?: boolean };

export const DeliveryLineEditCompanySelect = ({ disabled }: Props) => {
    const formik = useDeliveryLineFormikContext();

    const customersSearch = useCompaniesSearchFunc(true);

    return (
        <FormikAsyncAutocompleteField
            label={<LocalizableText code={'DeliveryLineCard.transportCompany'} />}
            name={'company'}
            formik={formik}
            loadOptions={customersSearch}
            renderOption={(company: ICompanySearchRecord) => company.name}
            disabled={disabled}
        />
    );
};
