import { FormikContextType } from 'formik';
import { isNumber } from 'lodash';
import React, { useCallback } from 'react';

import { INumberFieldProps, NumberField } from '../NumberField';

type FormikNumberFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends number | null ? TFieldName : never;
    formik: FormikContextType<TValues>;
} & Omit<INumberFieldProps, 'value' | 'onChange'>;

export function FormikNumberField<TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    disabled,
    helperText,
    nullable,
    ...restProps
}: FormikNumberFieldProps<TValues, TFieldName>) {
    const handleChange = useCallback(
        (value: number | null) => {
            formik.setFieldTouched(name, true);
            formik.setFieldValue(name, value);
        },
        [formik, name]
    );

    const value = formik.values[name];

    return (
        <NumberField
            fullWidth
            name={name}
            value={isNumber(value) ? value : null}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[name] && !!formik.errors[name]}
            helperText={(formik.touched[name] && formik.errors[name]) || helperText}
            disabled={disabled || formik.isSubmitting}
            nullable={nullable}
            {...restProps}
        />
    );
}
