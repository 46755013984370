import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import { AdminUsersPagedQuery } from '~/@store/users/users.queries';
import {
    AdminUsersPagedQuery as IUsersPagedQuery,
    AdminUsersPagedQuery_usersPaged,
    AdminUsersPagedQuery_usersPaged_edges_node,
    AdminUsersPagedQueryVariables,
} from '~/graphql';

import { usersStore } from '../users.store';

export type IUser = AdminUsersPagedQuery_usersPaged_edges_node;

export type IUsersPaged = AdminUsersPagedQuery_usersPaged;

export const usePagedUsers = (): QueryResult<IUsersPagedQuery, AdminUsersPagedQueryVariables> => {
    const variables: AdminUsersPagedQueryVariables = useDebouncedObservable(
        () => usersStore.usersQueryVariables,
        TIMEOUT_ON_QUERY_DEBOUNCE
    );

    return useQuery<IUsersPagedQuery, AdminUsersPagedQueryVariables>(AdminUsersPagedQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
        onCompleted: data => usersStore.pagination.onQueryComplete(data.usersPaged),
    });
};
