import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { FileUploadButton } from '~/@components/FileUploadButton';
import { Link } from '~/@components/Link';
import { Tooltip } from '~/@components/Tooltip';

type ButtonProps = {
    isLink?: false;
    isFileUpload?: false;
    onClick: () => void;
    disabled?: boolean;
    disabledReason?: string;
};

type LinkProps = {
    isLink: true;
    isFileUpload?: false;
    to: string;
    targetBlank?: boolean;
    disabled?: boolean;
    disabledReason?: string;
};

type FileUploadButtonProps = {
    isLink?: false;
    isFileUpload: true;
    onChange: (file: File) => void;
    acceptFileTypes?: string;
    checkFileSize?: boolean;
    disabled?: boolean;
    disabledReason?: string;
};

type Props = {
    children: React.ReactNode;
} & (ButtonProps | LinkProps | FileUploadButtonProps);

export const CellButton = (props: Props) => {
    const { root } = useStyles();

    let tooltipChild: React.ReactNode;

    if (props.isLink) {
        const { targetBlank, to, children, disabled = false } = props;

        tooltipChild = (
            <IconButton className={root} disabled={disabled}>
                <Link to={to} className={root} targetBlank={targetBlank}>
                    {children}
                </Link>
            </IconButton>
        );
    } else if (props.isFileUpload) {
        const { children, onChange, acceptFileTypes, checkFileSize, disabled } = props;

        tooltipChild = (
            <FileUploadButton
                className={root}
                onChange={onChange}
                acceptFileTypes={acceptFileTypes}
                checkFileSize={checkFileSize}
                disabled={disabled}>
                {children}
            </FileUploadButton>
        );
    } else {
        const { onClick, children, disabled } = props;

        tooltipChild = (
            <IconButton className={root} onClick={onClick} disabled={disabled}>
                {children}
            </IconButton>
        );
    }

    return (
        <Tooltip title={props.disabledReason} disabled={!props.disabled}>
            {tooltipChild}
        </Tooltip>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: 28,
        height: 28,
        minWidth: 'unset',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        transition: 'none',
        color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: theme.palette.text.secondary,
            color: theme.palette.action.hover,
        },
    },
}));
