import { adminRoutes } from '@common/routes';
import React, { useMemo } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { COLORS, makeStyles } from '~/@components/@theme';
import {
    AccountIconOutlined,
    BoxIcon,
    CommentIcon,
    MailIcon,
    PermissionsIcon,
    PinIcon,
    RecycleIcon,
    SettingsIconOutlined,
    TimeSpentIcon,
    ToxicLimitsIcon,
    TruckIcon,
} from '~/@components/Icon';
import { IMenuGroup, IMenuRoute, IOtherRoute } from '~/@store/routes';
import { BeastMaterialsPage } from '~/@views/AdminView/BeastMaterialsPage';
import { VehicleTypesPage } from '~/@views/AdminView/VehicleTypesPage';

import { AccountPage } from './AccountPage';
import { AdminHeader } from './AdminHeader';
import { BlacklistPage } from './BlacklistPage';
import { AdminDialogContainer } from './common/AdminDialogContainer';
import { CompaniesPage } from './CompaniesPage';
import { CompanyDetailPage } from './CompanyDetailPage';
import { DeliveryLinesPage } from './DeliveryLinesPage';
import { DespatchAdviceResponsesPage } from './DespatchAdviceResponsesPage';
import { DumpLoadsPage } from './DumpLoadsPage';
import { DumpTypesPage } from './DumpTypesPage';
import { LandfillDetailPage } from './LandfillDetailPage';
import { LandfillsPage } from './LandfillsPage';
import { LandfillStatusesPage } from './LandfillStatusesPage';
import { PermissionsPage } from './PermissionsPage';
import { ProjectDetailPage } from './ProjectDetailPage';
import { ProjectsPage } from './ProjectsPage';
import { ReleaseNotesPage } from './ReleaseNotesPage';
import { SettingsPage } from './SettingsPage';
import { SubareasPage } from './SubareasPage/SubareasPage';
import { SubstancesPage } from './SubstancesPage';
import { TimeSpentPage } from './TimeSpentPage';
import { ToxicLimitsPage } from './ToxicLimitsPage';
import { UserDetailPage } from './UserDetailPage';
import { UsersPage } from './UsersPage';

const menuRoutes: Array<IMenuGroup> = [
    {
        title: 'Admin.dumpLoads',
        routes: [
            {
                title: 'AdminDumpLoadTableStatuses.REQUESTED',
                path: adminRoutes.dumpLoadRequested,
                component: DumpLoadsPage,
            },
            {
                title: 'AdminDumpLoadTableStatuses.CONFIRMED',
                path: adminRoutes.dumpLoadConfirmed,
                component: DumpLoadsPage,
            },
            {
                title: 'AdminDumpLoadTableStatuses.ORDERED',
                path: adminRoutes.dumpLoadOrdered,
                component: DumpLoadsPage,
            },
            {
                title: 'AdminDumpLoadTableStatuses.NOT_ORDERED',
                path: adminRoutes.dumpLoadRejected,
                component: DumpLoadsPage,
            },
            {
                title: 'Admin.allDumpLoads',
                path: adminRoutes.dumpLoadAllDumpLoads,
                component: DumpLoadsPage,
            },
        ],
    },
    {
        title: 'Admin.landfills',
        routes: [
            {
                title: 'Admin.landfillsPage.activeLandfills',
                path: adminRoutes.activeLandfills,
                component: LandfillsPage,
            },
            { title: 'Admin.landfillsPage.allLandfills', path: adminRoutes.allLandfills, component: LandfillsPage },
            { title: 'Admin.landfillsPage.subareas', path: adminRoutes.subareas, component: SubareasPage },
        ],
    },
    {
        title: 'Admin.projects',
        routes: [
            { title: 'Admin.projectsPage.activeProjects', path: adminRoutes.activeProjects, component: ProjectsPage },
            { title: 'Admin.projectsPage.allProjects', path: adminRoutes.allProjects, component: ProjectsPage },
        ],
    },
    {
        title: 'Admin.orders',
        routes: [
            { title: 'Admin.orders', path: adminRoutes.ordersInProgress, component: DumpLoadsPage },
            { title: 'Admin.deliveryLines', path: adminRoutes.deliveryLines, component: DeliveryLinesPage },
        ],
    },
    {
        title: 'Admin.customers',
        routes: [
            { title: 'Admin.users', path: adminRoutes.users, component: UsersPage },
            { title: 'Admin.companies', path: adminRoutes.companies, component: CompaniesPage },
        ],
    },
    {
        title: 'Admin.dumpTypes',
        routes: [{ title: 'Admin.dumpTypes', path: adminRoutes.dumpTypes, component: DumpTypesPage }],
        icon: <RecycleIcon stroke={COLORS.white} />,
    },
    {
        title: 'Admin.beastMaterials',
        routes: [{ title: 'Admin.beastMaterials', path: adminRoutes.beastMaterials, component: BeastMaterialsPage }],
        icon: <RecycleIcon stroke={COLORS.white} />,
    },
    {
        title: 'Admin.landfillStatuses',
        routes: [
            { title: 'Admin.landfillStatuses', path: adminRoutes.landfillStatuses, component: LandfillStatusesPage },
        ],
        icon: <PinIcon htmlColor={COLORS.white} />,
    },
    {
        title: 'Admin.substances',
        routes: [{ title: 'Admin.substances', path: adminRoutes.substances, component: SubstancesPage }],
        icon: <BoxIcon stroke={COLORS.white} />,
    },
    {
        title: 'Admin.toxicLimits',
        routes: [{ title: 'Admin.toxicLimits', path: adminRoutes.toxicLimits, component: ToxicLimitsPage }],
        icon: <ToxicLimitsIcon htmlColor={COLORS.white} />,
    },
    {
        title: 'Admin.settings',
        routes: [{ title: 'Admin.settings', path: adminRoutes.settings, component: SettingsPage }],
        icon: <SettingsIconOutlined htmlColor={COLORS.white} />,
    },
    {
        title: 'Admin.vehicleTypes',
        routes: [{ title: 'Admin.vehicleTypes', path: adminRoutes.vehicleTypes, component: VehicleTypesPage }],
        icon: <TruckIcon htmlColor={COLORS.white} />,
    },
    {
        title: 'Admin.timeSpent',
        routes: [{ title: 'Admin.timeSpent', path: adminRoutes.timeSpent, component: TimeSpentPage }],
        icon: <TimeSpentIcon htmlColor={COLORS.white} />,
    },
    {
        title: 'Admin.despatchAdviceResponses',
        routes: [
            {
                title: 'Admin.despatchAdviceResponses',
                path: adminRoutes.despatchAdviceResponses,
                component: DespatchAdviceResponsesPage,
            },
        ],
        icon: <MailIcon htmlColor={COLORS.white} />,
    },
    {
        title: 'ReleaseNotes.releaseNotes',
        routes: [{ title: 'ReleaseNotes.releaseNotes', path: adminRoutes.releaseNotes, component: ReleaseNotesPage }],
        icon: <CommentIcon htmlColor={COLORS.white} />,
    },
    {
        title: 'Admin.permissions',
        routes: [{ title: 'Admin.permissions', path: adminRoutes.permissions, component: PermissionsPage }],
        icon: <PermissionsIcon htmlColor={COLORS.white} />,
    },
    {
        title: 'Admin.account',
        routes: [{ title: 'Admin.account', path: adminRoutes.account, component: AccountPage }],
        icon: <AccountIconOutlined htmlColor={COLORS.white} />,
    },
];

const otherRoutes: Array<IOtherRoute> = [
    {
        path: adminRoutes.project,
        component: ProjectDetailPage,
    },
    {
        path: adminRoutes.company,
        component: CompanyDetailPage,
    },
    {
        path: adminRoutes.user,
        component: UserDetailPage,
    },
    {
        path: adminRoutes.blacklist,
        component: BlacklistPage,
    },
    {
        path: adminRoutes.landfill,
        component: LandfillDetailPage,
    },
    {
        path: adminRoutes.ordersDone,
        component: DumpLoadsPage,
    },
];

const defGroup = menuRoutes[0]!;

export const AdminViewRoot = ({ match, location }: RouteComponentProps) => {
    const [currentGroup, currentRoute] = useMemo<[IMenuGroup | null, IMenuRoute | null]>(() => {
        return menuRoutes.reduce<[IMenuGroup | null, IMenuRoute | null]>(
            (acc, group) => {
                const route = group.routes.find(r => location.pathname.includes(r.path));

                return route ? [group, route] : acc;
            },
            [null, null]
        );
    }, [location.pathname]);

    const { main, root } = useStyles();

    return (
        <div className={root}>
            <AdminHeader groups={menuRoutes} currentGroup={currentGroup} currentRoute={currentRoute} />
            <main className={main}>
                <Switch>
                    {menuRoutes
                        .map(r => r.routes)
                        .flat()
                        .map(({ component, path }) => (
                            <Route key={path} path={path} component={component} exact />
                        ))}
                    {otherRoutes.map(({ component, path }) => (
                        <Route key={path} path={path} component={component} exact />
                    ))}
                    <Redirect exact from={match.url} to={defGroup.routes[0]!.path} />
                </Switch>
            </main>
            <AdminDialogContainer />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    main: {
        height: 'calc(100vh - 60px)',
    },
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100vh',
    },
}));
