import { useQuery } from '@apollo/client';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import {
    LandfillSubareasPagedQuery as LandfillSubareasPagedQueryType,
    LandfillSubareasPagedQuery_landfillSubareasPaged,
    LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node,
    LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_landfill,
    LandfillSubareasPagedQueryVariables,
} from '~/graphql';

import { LandfillSubareasPagedQuery } from '../subareas.queries';
import { subareasStore } from '../subareas.store';

export type ILandfill = LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node_landfill;

export type ISubarea = LandfillSubareasPagedQuery_landfillSubareasPaged_edges_node;

export type IPagedSubareas = LandfillSubareasPagedQuery_landfillSubareasPaged;

export const usePagedSubareas = () => {
    const variables = useDebouncedObservable(() => subareasStore.subareasQueryVariables, TIMEOUT_ON_QUERY_DEBOUNCE);

    return useQuery<LandfillSubareasPagedQueryType, LandfillSubareasPagedQueryVariables>(LandfillSubareasPagedQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
        onCompleted: data => subareasStore.pagination.onQueryComplete(data.landfillSubareasPaged),
    });
};
