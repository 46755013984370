import { isSameOrBefore, makeMap } from '@common/utils';
import { uniq } from 'lodash';

import { IBatchTransfer } from '~/@user-store/landfillBatches/landfillBatches.hooks';
import {
    LandfillFragment,
    LandfillFragment_deliveryLinesInfo,
    LandfillFragment_ordersInfo,
    TransferType,
} from '~/graphql';

type IDeliveryLine = LandfillFragment_deliveryLinesInfo;
type IOrder = LandfillFragment_ordersInfo;
type ITransfer = IBatchTransfer;

type ReportInput = {
    lines: IDeliveryLine[];
    orders: IOrder[];
    transfers: ITransfer[];
};

type IncomingLine = {
    __type: 'DeliveryLine';
    line: IDeliveryLine;
    order: IOrder;
};

type IncomingTransfer = {
    __type: 'Transfer';
    transfer: ITransfer;
};

export type IncomingRow = IncomingLine | IncomingTransfer;

export type ReportRow = {
    line: IDeliveryLine;
    order: IOrder;
    incomingRows: Array<IncomingRow>;
};

export type ReportData = {
    rows: Array<ReportRow>;
};

function isDateAcceptable(inDate: string, outDate: string) {
    return isSameOrBefore(new Date(inDate), new Date(outDate));
}

export function getReportData({ lines, orders, transfers }: ReportInput, projectName: string): ReportData {
    const ordersMap = makeMap(orders, o => o.orderNumber);

    function getIncomingRows(batchId: string, outDate: string, visitedBatches = new Set<string>()): Array<IncomingRow> {
        if (visitedBatches.has(batchId)) return [];
        visitedBatches.add(batchId);

        const incomingLines = lines
            .filter(
                l =>
                    !l.inbound &&
                    ordersMap.has(l.orderNumber) &&
                    l.batch?.id === batchId &&
                    isDateAcceptable(l.plannedStartDate, outDate)
            )
            .map(line => ({
                __type: 'DeliveryLine' as const,
                line,
                order: ordersMap.get(line.orderNumber)!,
            }));

        const incomingTransfers = transfers
            .filter(
                t => t.type === TransferType.INCOMING && t.toBatch?.id === batchId && isDateAcceptable(t.date, outDate)
            )
            .map(transfer => ({
                __type: 'Transfer' as const,
                transfer,
            }));

        const incomingInternalRows = transfers
            .filter(
                t =>
                    t.type === TransferType.INTERNAL &&
                    t.toBatch?.id === batchId &&
                    t.fromBatch &&
                    isDateAcceptable(t.date, outDate)
            )
            .flatMap(t => getIncomingRows(t.fromBatch!.id, t.date, visitedBatches));

        return [...incomingLines, ...incomingTransfers, ...incomingInternalRows];
    }

    const outgoingLines = lines.filter(l => l.inbound && ordersMap.get(l.orderNumber)?.projectName === projectName);

    return {
        rows: outgoingLines.map(line => ({
            line,
            order: ordersMap.get(line.orderNumber)!,
            incomingRows: line.batch ? getIncomingRows(line.batch.id, line.plannedStartDate) : [],
        })),
    };
}

export function getOutgoingProjectNames(landfill: LandfillFragment) {
    const ordersMap = makeMap(landfill.ordersInfo, o => o.orderNumber);

    const projectNames = (landfill.deliveryLinesInfo || [])
        .filter(l => l.inbound)
        .map(l => ordersMap.get(l.orderNumber)?.projectName)
        .filter(Boolean) as string[];

    return uniq(projectNames).sort();
}
