import React, { useMemo } from 'react';

import { SingleOptionFilter, TableHeadCell } from '~/@components/Table/TableHeadCell';
import { userActivitiesStore, UserActivityFilterFields, userActivityMinuteOptions } from '~/@store/userActivities';

type Props = {
    title: React.ReactNode;
    fieldName: UserActivityFilterFields;
    withFilter?: boolean;
};

export const UserActivityHeadCell = ({ title, fieldName, withFilter }: Props) => {
    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        switch (fieldName) {
            case UserActivityFilterFields.minutesToCut:
                return (
                    <SingleOptionFilter
                        valueGetter={() => userActivitiesStore.filter.values.minutesToCut}
                        setValue={v => userActivitiesStore.filter.setValue(fieldName, v)}
                        values={userActivityMinuteOptions}
                    />
                );
            default:
                return null;
        }
    }, [withFilter, fieldName]);

    return (
        <TableHeadCell title={title} sortDirection={null}>
            {filterSection}
        </TableHeadCell>
    );
};
