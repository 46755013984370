import {
    getLeachingContaminationLevel,
    isExactLeachingLevel,
    leachingLevels,
} from '@common/toxic-analysis/analyzeLeaching';
import { getSolidContaminationLevel, isExactSolidLevel, solidLevels } from '@common/toxic-analysis/analyzeSolid';
import {
    calculateParentAmount,
    getAmountsForLevel,
    normalizeSubstanceAmounts,
} from '@common/toxic-analysis/toxicAnalysis.common';
import {
    ISubstanceAmount,
    ISubstanceAmountStrict,
    SubstanceLimitMap,
} from '@common/toxic-analysis/toxicAnalysis.types';
import { makeRecord } from '@common/utils';
import { map } from 'lodash';
import { action, computed, observable, toJS } from 'mobx';

import type { ISubstance, ISubstanceChild, IToxicLimitsData } from '~/@store/toxicLimits';
import { getToxicLimitsData } from '~/@store/toxicLimits/getToxicLimitsData';
import { getSubstanceLimitMap } from '~/@store/toxicLimits/toxicLimits.helpers';
import { ContaminationLevel, ContaminationType } from '~/graphql';
import { NotAnError } from '~/utils/error';

import { isSubstanceChild, sortSubstancesAndChildrenByPosition } from './substances.helpers';

const makeAmountsMap = (amounts: ISubstanceAmount[]) =>
    makeRecord(
        amounts,
        a => a.substanceId,
        a => a.amount
    );

export class ContaminationTypeStore {
    constructor(
        public contaminationType: ContaminationType,
        private getContaminationLevelFunc: (amounts: ISubstanceAmount[]) => ContaminationLevel,
        private limitsMap: SubstanceLimitMap,
        substances: ISubstance[]
    ) {
        this.substances = sortSubstancesAndChildrenByPosition(
            substances.filter(s => s.contaminationTypes.includes(contaminationType))
        );
    }

    @action
    init(amounts: ISubstanceAmount[], state: boolean, readOnly: boolean) {
        this.readOnly = readOnly;
        this.state = state;
        this.substanceAmountsMap = makeAmountsMap(amounts.filter(a => a.contaminationType === this.contaminationType));
    }

    @observable
    readOnly: boolean = true;

    levelsForFill: ContaminationLevel[] = [
        ContaminationLevel.na,
        ...(this.contaminationType === ContaminationType.SOLID ? solidLevels : leachingLevels),
    ];

    substances: ISubstance[] = [];

    @observable
    state!: boolean;

    @action
    toggleState = () => {
        this.state = !this.state;
    };

    @observable
    substanceAmountsMap: Record<string, number | null | undefined> = {};

    @computed
    get substanceAmounts(): ISubstanceAmount[] {
        return map(toJS(this.substanceAmountsMap), (amount, substanceId) => ({
            substanceId,
            amount,
            contaminationType: this.contaminationType,
        }));
    }

    @computed
    get isEmpty() {
        return this.substanceAmounts.every(s => !s.amount);
    }

    @computed
    get contaminationLevel(): ContaminationLevel | null {
        if (!this.state) return null;

        return this.getContaminationLevelFunc(this.substanceAmounts);
    }

    private isExactLevelFunc =
        this.contaminationType === ContaminationType.SOLID ? isExactSolidLevel : isExactLeachingLevel;

    @computed
    get isExactLevel(): boolean {
        if (!this.contaminationLevel) return false;

        return this.isExactLevelFunc(this.contaminationLevel, this.substanceAmounts, this.limitsMap);
    }

    @action
    changeAmount(substance: ISubstance | ISubstanceChild, amount: number) {
        this.substanceAmountsMap[substance.id] = amount;

        // Have to update amount for parent substance;
        if (isSubstanceChild(substance)) {
            const parentSubstance = this.substances.find(s => s.id === substance.parentId)!;
            this.substanceAmountsMap[parentSubstance.id] = calculateParentAmount(
                parentSubstance.groupBy,
                parentSubstance.children?.map(child => this.substanceAmountsMap[child.id]) || []
            );
        }
    }

    @action
    fillLevel(level: ContaminationLevel) {
        if (level === ContaminationLevel.na) {
            this.clearAmounts();
        } else {
            this.substanceAmountsMap = makeAmountsMap(
                getAmountsForLevel(this.contaminationType, this.limitsMap, level)
            );
        }
    }

    @action
    clearAmounts = () => {
        this.substanceAmountsMap = {};
    };
}

export class SubstanceEditStore {
    SOLID?: ContaminationTypeStore;
    LEACHING?: ContaminationTypeStore;

    _loadingPromise: undefined | Promise<IToxicLimitsData>;

    @observable
    initialized = false;

    @action
    setInitialized() {
        this.initialized = true;
    }

    init(
        amounts: ISubstanceAmount[],
        solidState: boolean,
        leachingState: boolean,
        readOnlySolid: boolean,
        readOnlyLeaching: boolean
    ) {
        if (!this._loadingPromise) {
            this._loadingPromise = getToxicLimitsData().catch(() => {
                this._loadingPromise = undefined;

                return Promise.reject(new NotAnError('SubstanceEditStore._loadingPromise'));
            });
        }

        this._loadingPromise.then(data => {
            if (!this.SOLID) {
                const solidLimitsMap = getSubstanceLimitMap(data.toxicLimitsValues.SOLID);
                this.SOLID = new ContaminationTypeStore(
                    ContaminationType.SOLID,
                    amounts => getSolidContaminationLevel(amounts, solidLimitsMap, data.toxicSumRules),
                    solidLimitsMap,
                    data.substances
                );
            }

            if (!this.LEACHING) {
                const leachingLimitsMap = getSubstanceLimitMap(data.toxicLimitsValues.LEACHING);
                this.LEACHING = new ContaminationTypeStore(
                    ContaminationType.LEACHING,
                    amounts => getLeachingContaminationLevel(amounts, leachingLimitsMap),
                    leachingLimitsMap,
                    data.substances
                );
            }

            this.SOLID.init(amounts, solidState, readOnlySolid);
            this.LEACHING.init(amounts, leachingState, readOnlyLeaching);

            this.setInitialized();
        });
    }

    serializeAmounts(): ISubstanceAmountStrict[] {
        if (!this.SOLID || !this.LEACHING) return [];

        return normalizeSubstanceAmounts(
            [...this.SOLID.substanceAmounts, ...this.LEACHING.substanceAmounts],
            this.SOLID.state,
            this.LEACHING.state
        );
    }
}

export const substanceEditStore = new SubstanceEditStore();
