import { apiEndpoints, fullRouteWith } from '@common/routes';
import React from 'react';

import { ProgressButton } from '~/@components/Button/ProgressButton';
import { showCustomDialog } from '~/@store/common/dialogs';
import { PopupNumberEdit } from '~/@views/AdminView/common/PopupEditFields';
import { baseApiUrl } from '~/apolloClient';
import i18n from '~/i18n';
import { downloadFile } from '~/utils/files';

export const DownloadRandomReceipts = () => {
    const downLoadFunc = async (count: number) => {
        if (!count) return Promise.resolve();

        const url = fullRouteWith(baseApiUrl, apiEndpoints.deliveryLinesRandom);

        return downloadFile(url, { count }, `random_receipts_${count}.zip`, true, false);
    };

    const onClick = () =>
        showCustomDialog({
            render: closeDialog => (
                <PopupNumberEdit
                    label={i18n.settingsPage.database.randomReceiptsCountLabel}
                    initialValue={0}
                    onSubmit={downLoadFunc}
                    onClose={closeDialog}
                />
            ),
        });

    return (
        <ProgressButton variant="contained" color="primary" onClick={onClick}>
            {i18n.settingsPage.database.downloadRandomReceipts}
        </ProgressButton>
    );
};
