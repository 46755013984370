import { useMutation } from '@apollo/client';
import { last } from 'lodash';
import { useCallback } from 'react';

import { DumpLoadCreateMutation, DumpLoadCreateMutationVariables, ProjectLoadInput } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../dumpLoads.queries';

export type CreateDumpLoadFunc = (input: ProjectLoadInput) => Promise<{ id: string }>;

export const useDumpLoadCreateMutation = (project: { id: string; ver: number }): [CreateDumpLoadFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<DumpLoadCreateMutation, DumpLoadCreateMutationVariables>(
        queries.DumpLoadCreateMutation
    );

    const createDumpLoad = useCallback<CreateDumpLoadFunc>(
        input => {
            return callMutation({
                variables: { projectId: project.id, input: { ver: project.ver, ...input } },
            })
                .then(
                    ({ data }) =>
                        last(data?.projectLoadCreate?.dumpLoads) ||
                        Promise.reject(new Error('Empty response: DumpLoadCreateMutation'))
                )
                .catch(handleApolloError);
        },
        [project, callMutation]
    );

    return [createDumpLoad, mutationResult.loading];
};
