import { Engine, Fuel } from '@common/enums';
import { VehicleType } from '@common/types';
import { isSameOrAfter, isSameOrBefore } from '@common/utils';
import { uniq } from 'lodash';
import { action, computed, observable } from 'mobx';

export type ProjectDeliveryItem = {
    date: Date;
    value: number;
    distance: number;
    inbound: boolean;
    serialNumber: string;
    licensePlate: string;
    fillRate: number | null;
    excavatorOperator: {
        id: string;
        name: string;
    } | null;
    CO2Consumption: number;
    vehicleType: Pick<VehicleType, 'id' | 'name' | 'fuelConsumptions'> | null;
    fuel: Fuel;
    engine: Engine;
};

export type ProjectTimeSpentItem = {
    date: Date;
    hours: number;
    serialNumber: string;
    licensePlate: string;
    excavatorOperator: {
        id: string;
        name: string;
    } | null;
    CO2Consumption: number;
    vehicleType: Pick<VehicleType, 'id' | 'name' | 'fuelConsumptions'> | null;
    fuel: Fuel;
    engine: Engine;
};

class DashboardFilterStore {
    deliveryItems: ProjectDeliveryItem[] = [];
    timeSpentItems: ProjectTimeSpentItem[] = [];

    @observable
    startDate: Date = new Date();

    @observable
    endDate: Date = new Date();

    @observable
    selectedDumpLoadNumbers: string[] = [];

    @observable
    selectedLicensePlates: string[] = [];

    @action
    setDates(startDate?: Date | null, endDate?: Date | null) {
        if (startDate) this.startDate = startDate;
        if (endDate) this.endDate = endDate;
    }

    @action
    setSelectedDumpLoadNumbers(dumpLoadNumbers: string[]) {
        this.selectedDumpLoadNumbers = dumpLoadNumbers;
    }

    @action
    setSelectedLicensePlates(licensePlates: string[]) {
        this.selectedLicensePlates = licensePlates;
    }

    @action
    init(deliveryItems: ProjectDeliveryItem[], timeSpentItems: ProjectTimeSpentItem[]) {
        this.deliveryItems = deliveryItems;
        this.timeSpentItems = timeSpentItems;
        const now = new Date();
        const curYear = now.getFullYear();
        const curMonth = now.getMonth();
        this.setDates(new Date(curYear, curMonth, 1), new Date(curYear, curMonth + 1, 0));
    }

    @computed
    get filteredDeliveryItemsByDates() {
        return this.deliveryItems.filter(
            line => isSameOrAfter(line.date, this.startDate) && isSameOrBefore(line.date, this.endDate)
        );
    }

    @computed
    get filteredTimeSpentItemsByDates() {
        return this.timeSpentItems.filter(
            timeSpent => isSameOrAfter(timeSpent.date, this.startDate) && isSameOrBefore(timeSpent.date, this.endDate)
        );
    }

    @computed
    get dumpLoadNumbers() {
        return uniq(this.filteredDeliveryItemsByDates.map(i => i.serialNumber));
    }

    @computed
    get licensePlates() {
        return uniq(this.filteredDeliveryItemsByDates.map(i => i.licensePlate));
    }

    @computed
    get filteredDeliveryItems() {
        return this.filteredDeliveryItemsByDates
            .filter(i => this.selectedLicensePlates.length === 0 || this.selectedLicensePlates.includes(i.licensePlate))
            .filter(
                i => this.selectedDumpLoadNumbers.length === 0 || this.selectedDumpLoadNumbers.includes(i.serialNumber)
            );
    }

    @computed
    get filteredTimeSpentItems() {
        return this.filteredTimeSpentItemsByDates
            .filter(i => this.selectedLicensePlates.length === 0 || this.selectedLicensePlates.includes(i.licensePlate))
            .filter(
                i => this.selectedDumpLoadNumbers.length === 0 || this.selectedDumpLoadNumbers.includes(i.serialNumber)
            );
    }
}

export const dashboardFilterStore = new DashboardFilterStore();
