import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';

import { ILocation } from '~/@store/locations';
import { SochiLocationEdit } from '~/@views/UserView/common/SochiLocationEdit/SochiLocationEdit';
import { LocationType } from '~/graphql';
import { canChangeLandfillLocation } from '~/utils/auth';

import { FormNames } from './landfillFormikHOC';
import { useLandfillFormContext } from './LandfillFormWithContext';

type LandfillLocationSelectProps = {
    className?: string;
};

export const LandfillLocationSelect = ({ className }: LandfillLocationSelectProps) => {
    const context = useLandfillFormContext();
    const {
        landfill,
        ability,
        errors,
        touched,
        setFieldValue,
        setFieldValueAndTouchIt,
        values,
        getByLatLng,
        getByText,
        isLoading,
        isSubmitting,
    } = context;

    const onInputLocation = (location: Partial<ILocation>) => {
        setFieldValue(FormNames.location, { ...location, type: LocationType.UNKNOWN });
    };

    const onChangeLatLng = (lat: number | undefined, lng: number | undefined) => {
        if (lat && lng) {
            getByLatLng(lat, lng).then(result => setFieldValueAndTouchIt(FormNames.location, result, true));
        } else {
            setFieldValueAndTouchIt(FormNames.location, { ...values.location, lat, lng }, true);
        }
    };

    const onChangeText = (text: string) => {
        if (text) {
            getByText(text).then(result => setFieldValueAndTouchIt(FormNames.location, result, true));
        } else {
            setFieldValueAndTouchIt(FormNames.location, { ...values.location, text }, true);
        }
    };

    return (
        <div className={className}>
            <SochiLocationEdit
                location={values.location}
                onInputLocation={onInputLocation}
                readOnly={isSubmitting || isLoading || !canChangeLandfillLocation(ability, landfill || undefined)}
                onChangeLatLng={onChangeLatLng}
                onChangeText={onChangeText}
            />
            {errors.location && touched.location && <InputLabel error>{errors.location}</InputLabel>}
        </div>
    );
};
