import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { DumpTypesListQuery, DumpTypesListQueryVariables } from '~/graphql';

import * as queries from '../dumpType.queries';

export const useDumpTypesList = (
    variables: DumpTypesListQueryVariables = {}
): QueryResult<DumpTypesListQuery, DumpTypesListQueryVariables> => {
    return useQuery<DumpTypesListQuery, DumpTypesListQueryVariables>(queries.DumpTypesListQuery, {
        variables,
    });
};
