import { COLORS } from '~/@components/@theme/colors';

export const LandfillStatusesIcons = ['icon0', 'icon1', 'icon2', 'icon3', 'icon4', 'icon5'] as const;

export const LandfillStatusIconColors: Record<string, string> = {
    icon0: '#8BD8F2', // light blue
    icon1: '#D64C82', // red
    icon2: '#9BC744', // green
    icon3: '#C26ADE', // purple
    icon4: '#F5AE45', // orange
    icon5: '#4C65D5', // blue
    iconDisabled: COLORS.grayDark,
} as const;

export const DefaultLandfillStatus = {
    icon: 'iconDisabled',
    name: '',
};
