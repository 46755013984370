import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
    ProjectUpdate,
    ProjectUpdateMutation,
    ProjectUpdateMutation_projectUpdate,
    ProjectUpdateMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../projects.queries';

export type UpdateProjectFunc = (input: ProjectUpdate) => Promise<ProjectUpdateMutation_projectUpdate>;

export const useProjectUpdate = (): [UpdateProjectFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<ProjectUpdateMutation, ProjectUpdateMutationVariables>(
        queries.ProjectUpdateMutation
    );

    const updateProject = useCallback<UpdateProjectFunc>(
        input => {
            return callMutation({
                variables: { input },
            })
                .then(result =>
                    result.data?.projectUpdate
                        ? result.data.projectUpdate
                        : Promise.reject(new Error('Empty response "ProjectUpdateMutation"'))
                )
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [updateProject, mutationResult.loading];
};
