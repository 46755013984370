import './ProjectPage.scss';

import { getProjectDumpLoadSubject } from '@common/abilities/projects';
import { AbilityCan, AbilitySubjects, LoadStatus } from '@common/enums';
import { canUseChatGptForProject } from '@common/functions';
import { replaceRouteParams, routes } from '@common/routes';
import isEmpty from 'lodash/isEmpty';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { matchPath, RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { PageNavMenu } from '~/@sochi-components/PageNavMenu';
import * as queries from '~/@store/projects/projects.queries';
import { projectDetailRoute } from '~/@store/routes';
import { ProjectAiTab } from '~/@views/UserView/ProjectPage/ProjectAiTab';
import { ProjectDashboard } from '~/@views/UserView/ProjectPage/ProjectDashboard';
import { ProjectUsers } from '~/@views/UserView/ProjectPage/ProjectUsers';
import { TopBar } from '~/@views/UserView/UserViewLayout/TopBar';
import { IAbilityUserContext, useSettings, useUserAbilities } from '~/contexts';
import { withAbilities, WithAbilitiesProps } from '~/contexts';
import { LoadStatus as DumpLoadStatus, ProjectQuery, ProjectQuery_project, ProjectQueryVariables } from '~/graphql';
import { handledGraphql, WithGraphqlProps } from '~/services/hoc';
import { canReadPriceCalculator, canReadProjectUsers } from '~/utils/auth';
import { handleProjectGraphqlPermissionError } from '~/utils/project';

import { ProjectDeliveries } from '../ProjectDeliveries';
import { ProjectDeviations } from '../ProjectDeviations';
import { ProjectDocuments } from '../ProjectDocuments';
import { ProjectHistory } from '../ProjectHistory';
import { ProjectInfo } from '../ProjectInfo';
import { ProjectMassesDetails } from '../ProjectMassesDetails';
import { ProjectPriceCalculator } from '../ProjectPriceCalculator';
import { ProjectSchedule } from '../ProjectSchedule';

export type ProjectDetailsDataProps = {
    project: ProjectQuery_project;
    refetch?: () => Promise<unknown>;
};

type MatchParams = {
    projectId: string | undefined;
};

export type CommonMatchParams = Record<string, string | undefined>;

type ExternalProps = RouteComponentProps<MatchParams>;

export type ProjectCommonDataProps = ProjectDetailsDataProps & RouteComponentProps<CommonMatchParams>;

type RouteData = {
    url: string;
    component: ComponentType<ProjectCommonDataProps>;
    showDiscardedDumploads?: true;
};

type MenuItem = {
    title: React.ReactNode;
    url: string;
    abilityCheck?: ({ ability, user }: IAbilityUserContext, project?: ProjectQuery_project | null) => boolean;
};

const menuItems: MenuItem[] = [
    {
        title: <LocalizableText code={'dashboard'} />,
        url: routes.sochi.projectDashboard,
    },
    {
        title: <LocalizableText code={'history'} />,
        url: routes.sochi.projectHistory,
    },
    {
        title: <LocalizableText code={'users'} />,
        url: routes.sochi.projectUsers,
        abilityCheck: canReadProjectUsers,
    },
    {
        title: <LocalizableText code={'priceCalculator'} />,
        url: routes.sochi.projectPriceCalculator,
        abilityCheck: canReadPriceCalculator,
    },
    {
        title: <LocalizableText code={'documents'} />,
        url: routes.sochi.projectDocuments,
    },
    {
        title: <LocalizableText code={'transportSchedule'} />,
        url: routes.sochi.projectSchedule,
    },
    {
        title: <LocalizableText code={'projectDeliveries'} />,
        url: routes.sochi.projectDeliveries,
    },
    {
        title: <LocalizableText code={'deviations'} />,
        url: routes.sochi.projectDeviations,
    },
    {
        title: <LocalizableText code={'projectInfo'} />,
        url: routes.sochi.projectInfo,
    },
];

const routeItems: RouteData[] = [
    { url: routes.sochi.projectPriceCalculator, component: ProjectPriceCalculator, showDiscardedDumploads: true },
    { url: routes.sochi.projectHistory, component: ProjectHistory },
    { url: routes.sochi.projectMassDetail, component: ProjectMassesDetails },
    { url: routes.sochi.projectInfo, component: ProjectInfo },
    { url: routes.sochi.projectSchedule, component: ProjectSchedule },
    { url: routes.sochi.projectDocuments, component: ProjectDocuments },
    { url: routes.sochi.projectDeviations, component: ProjectDeviations },
    { url: routes.sochi.projectUsers, component: ProjectUsers },
    { url: routes.sochi.projectAI, component: ProjectAiTab },
    { url: routes.sochi.projectDeliveries, component: ProjectDeliveries },
    { url: routes.sochi.projectDashboard, component: ProjectDashboard },
];

type InjectedProps = WithGraphqlProps<ExternalProps, ProjectQuery, ProjectQueryVariables>;

const ProjectPage = ({ location, history, match, data }: InjectedProps & WithAbilitiesProps) => {
    const { className } = useFunctionalBem(ProjectPage);
    const abilityContext = useUserAbilities();
    const { user, ability } = abilityContext;
    const projectId = useMemo(() => match.params.projectId || '', [match]);

    const {
        settings: { chatGptEnabled },
    } = useSettings();

    const onBack = () => {
        const isMassDetail = !!matchPath(location.pathname, {
            path: replaceRouteParams(routes.sochi.projectMassDetail, { projectId }),
        });

        if (isMassDetail) {
            history.goBack();
        } else {
            history.push('/');
        }
    };

    const routeRender = useCallback(
        (route: RouteData) => {
            const { refetch, project } = data;
            const discardedSubject = getProjectDumpLoadSubject(user, project!, null, [LoadStatus.DISCARDED]);
            const displayProject = { ...project } as ProjectQuery_project;

            if (!route.showDiscardedDumploads || ability.cannot(AbilityCan.READ, discardedSubject)) {
                displayProject.dumpLoads = displayProject.dumpLoads.filter(d => d.status !== DumpLoadStatus.DISCARDED);
            }

            const CompClass = route.component;

            return (props: RouteComponentProps<CommonMatchParams>) => (
                <CompClass {...props} refetch={refetch} project={displayProject} />
            );
        },
        [ability, data, user]
    );

    const preparedMenuItems = useMemo(() => {
        const items = menuItems
            .filter(item => !item.abilityCheck || item.abilityCheck(abilityContext, data.project))
            .map(item => ({
                ...item,
                url: replaceRouteParams(item.url, { projectId }),
            }));

        if (chatGptEnabled && !!data.project && canUseChatGptForProject(data.project)) {
            items.push({
                title: <LocalizableText code={'ChatGpt.tabTitle'} />,
                url: replaceRouteParams(routes.sochi.projectAI, { projectId }),
            });
        }

        if (ability.can(AbilityCan.READ, AbilitySubjects.ADMIN_PAGES)) {
            items.push({
                title: <LocalizableText code={'admin20'} />,
                url: projectDetailRoute(projectId),
            });
        }

        return items;
    }, [projectId, ability, abilityContext, data.project, chatGptEnabled]);

    if (isEmpty(data.project)) {
        return null;
    }

    const defaultRoute = canReadPriceCalculator(abilityContext, data.project)
        ? replaceRouteParams(routes.sochi.projectPriceCalculator, { projectId })
        : replaceRouteParams(routes.sochi.projectDashboard, { projectId });

    return (
        <>
            <TopBar project={data.project} />
            <div className={className}>
                <PageNavMenu
                    items={preparedMenuItems}
                    onBack={onBack}
                    title={data.project?.name}
                    titleLabel={<LocalizableText code={'topBar.project'} />}
                />
                <Switch key={projectId}>
                    {routeItems.map(route => (
                        <Route key={route.url} path={route.url} render={routeRender(route)} exact />
                    ))}
                    <Redirect from="/" to={defaultRoute} />
                </Switch>
            </div>
        </>
    );
};

const withData = handledGraphql<ProjectQuery, ExternalProps, ProjectQueryVariables, InjectedProps>(
    queries.ProjectQuery,
    {
        options: props => {
            return {
                fetchPolicy: 'network-only',
                variables: {
                    id: props.match.params.projectId || '',
                },
            };
        },
    },
    handleProjectGraphqlPermissionError
);

export default withData(withAbilities(ProjectPage));
