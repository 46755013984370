import './ProjectLandfillLists.scss';

import { TestId } from '@common/testConstants';
import { IconButton } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { ButtonGroupSwitch } from '~/@components/ButtonGroupSwitch';
import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import { COLOR } from '~/@sochi-components/@theme/styles';
import { PlusIcon } from '~/@sochi-components/Icons';
import type { ISidebarTab } from '~/@user-store/homePageMap';
import { CreationMode, homePageMapStore, SidebarTabs } from '~/@user-store/homePageMap';

import { LandfillList } from './LandfillList';
import { ProjectList } from './ProjectList';

const options: Array<{ label: React.ReactNode; value: ISidebarTab }> = [
    { label: <LocalizableText code={'incoming'} />, value: SidebarTabs.INCOMING },
    { label: <LocalizableText code={'outgoing'} />, value: SidebarTabs.OUTGOING },
];

@observer
export class ProjectLandfillLists extends React.Component {
    bem = getBem(this);

    onClose = (): void => {
        homePageMapStore.setCreationMode(null);
    };

    onCreate = (): void => {
        homePageMapStore.setCreationMode(
            homePageMapStore.sidebarActiveTab === SidebarTabs.OUTGOING ? CreationMode.PROJECT : CreationMode.LANDFILL
        );
    };

    render() {
        const { element } = this.bem;

        return (
            <>
                <div className={element('filters')}>
                    <ButtonGroupSwitch
                        color="secondary"
                        className={element('options')}
                        options={options}
                        value={homePageMapStore.sidebarActiveTab}
                        onChange={homePageMapStore.setSidebarActiveTab}
                        fullWidth={true}
                    />
                    <IconButton
                        className={element('add-button')}
                        onClick={this.onCreate}
                        data-testid={TestId.SochiCreateProjectOrLandfillButton}>
                        <PlusIcon htmlColor={COLOR.white} fontSize="inherit" />
                    </IconButton>
                </div>

                {homePageMapStore.sidebarActiveTab === SidebarTabs.OUTGOING ? <ProjectList /> : <LandfillList />}
            </>
        );
    }
}
