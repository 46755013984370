import { SettingsKeysFields } from '@common/enums';
import React from 'react';

import { Divider } from '~/@components/Divider';
import { LocalizableText } from '~/@components/LocalizableText';
import { ISettings } from '~/@store/settings';

import { APIConfigurationField } from './APIConfigurationField';
import { FeatureFlagField } from './FeatureFlagField';

type Props = {
    settings: ISettings;
    setSettings: (value: ISettings) => void;
};

export const APIConfigurationFields = ({ settings, setSettings }: Props) => {
    const keys = settings.keys;
    const flags = settings.featuresFlags;

    const setKeys = (newKeys: ISettings['keys']) => {
        setSettings({
            ...settings,
            keys: newKeys,
        });
    };

    const setFlags = (featuresFlags: ISettings['featuresFlags']) => {
        setSettings({
            ...settings,
            featuresFlags,
        });
    };

    return (
        <>
            <APIConfigurationField
                keys={keys}
                setKeys={setKeys}
                field={SettingsKeysFields.captchaSiteKey}
                label={<LocalizableText code={'settingsPage.keys.captchaSiteKey'} />}
            />
            <APIConfigurationField
                keys={keys}
                setKeys={setKeys}
                field={SettingsKeysFields.captchaSecretKey}
                label={<LocalizableText code={'settingsPage.keys.captchaSecretKey'} />}
            />
            <FeatureFlagField
                flags={flags}
                setFlags={setFlags}
                field={'captchaEnabled'}
                label={<LocalizableText code={'settingsPage.featuresFlags.CAPTCHA'} />}
            />
            <Divider marginBottom marginTop />
            <APIConfigurationField
                keys={keys}
                setKeys={setKeys}
                field={SettingsKeysFields.chatGptApiKey}
                label={<LocalizableText code={'settingsPage.keys.chatGptApiKey'} />}
            />
            <APIConfigurationField
                keys={keys}
                setKeys={setKeys}
                field={SettingsKeysFields.chatGptAssistantId}
                label={<LocalizableText code={'settingsPage.keys.chattGptAssistantId'} />}
            />
            <FeatureFlagField
                flags={flags}
                setFlags={setFlags}
                field={'chatGptEnabled'}
                label={<LocalizableText code={'settingsPage.featuresFlags.ChatGPT'} />}
            />
            <Divider marginBottom marginTop />
            <APIConfigurationField
                keys={keys}
                setKeys={setKeys}
                field={SettingsKeysFields.tickstarClientId}
                label={<LocalizableText code={'settingsPage.keys.tickstarClientId'} />}
            />
            <APIConfigurationField
                keys={keys}
                setKeys={setKeys}
                field={SettingsKeysFields.tickstarClientSecret}
                label={<LocalizableText code={'settingsPage.keys.tickstarClientSecret'} />}
            />
            <APIConfigurationField
                keys={keys}
                setKeys={setKeys}
                field={SettingsKeysFields.tickstarToken}
                label={<LocalizableText code={'settingsPage.keys.tickstarToken'} />}
            />
            <APIConfigurationField
                keys={keys}
                setKeys={setKeys}
                field={SettingsKeysFields.pinpointerGLN}
                label={<LocalizableText code={'settingsPage.keys.pinpointerGLN'} />}
            />
            <APIConfigurationField
                keys={keys}
                setKeys={setKeys}
                field={SettingsKeysFields.testDespatchPartyOrgNumber}
                label={<LocalizableText code={'settingsPage.keys.testDespatchPartyOrgNumber'} />}
            />
            <APIConfigurationField
                keys={keys}
                setKeys={setKeys}
                field={SettingsKeysFields.testConsigneeOrgNumber}
                label={<LocalizableText code={'settingsPage.keys.testConsigneeOrgNumber'} />}
            />
            <FeatureFlagField
                flags={flags}
                setFlags={setFlags}
                field={'peppolEnabled'}
                label={<LocalizableText code={'settingsPage.featuresFlags.Peppol'} />}
            />
            <Divider marginBottom marginTop />
            <APIConfigurationField
                keys={keys}
                setKeys={setKeys}
                field={'mytapiProductId'}
                label={<LocalizableText code={'settingsPage.keys.mytapiProductId'} />}
            />
            <APIConfigurationField
                keys={keys}
                setKeys={setKeys}
                field={'mytapiToken'}
                label={<LocalizableText code={'settingsPage.keys.mytapiToken'} />}
            />
            <FeatureFlagField
                flags={flags}
                setFlags={setFlags}
                field={'whatsappEnabled'}
                label={<LocalizableText code={'settingsPage.featuresFlags.Whatsapp'} />}
            />
        </>
    );
};
