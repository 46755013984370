import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useProgressState } from '~/@components/@hooks';
import { TextField } from '~/@components/TextField';
import { LandfillWeightReceiptInfo } from '~/@user-store/weightReceipts/landfillWeightReceipts.store';
import { updateDeliveryLineReceiverComment } from '~/@user-store/weightReceipts/weightReceiptsActions';

type Props = {
    landfillId: string;
    line: LandfillWeightReceiptInfo;
    disabled?: boolean;
};

export const ReceiverCommentField = ({ line, landfillId, disabled }: Props) => {
    const { root } = useStyles();
    const initialValue = line.receiverComment || '';
    const [value, setValue] = React.useState(initialValue);

    const [isLoading, update] = useProgressState(updateDeliveryLineReceiverComment);

    const onBlur = () => {
        if (initialValue !== value && !disabled) update(landfillId, line.id, value);
    };

    return (
        <>
            <TextField
                classes={{ root }}
                variant="outlined"
                value={value}
                onChange={e => setValue(e.target.value)}
                onBlur={onBlur}
                disabled={disabled || isLoading}
            />
            {isLoading && <LinearProgress />}
        </>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 200,
        border: '1px solid grey',
    },
}));
