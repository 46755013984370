import { RawRule } from '@casl/ability';
import { toUpper } from 'lodash';

import { ICaslCondition } from '../../../../common/abilities/utils';

const id = (s: string) => s;

type Condition = { $all: string[] | null } | { $in: string[] | null } | string[] | string | null | undefined;
type Modifier = (s: string) => string;

const OR = ' or ';
const AND = ' and ';

export const formatCondition = (cond: Condition, mod: Modifier = id): string => {
    if (!cond) return '';
    if (typeof cond === 'string') return mod(cond);
    if (Array.isArray(cond)) return cond.map(mod).join(OR);
    if ('$in' in cond) return cond.$in?.map(mod).join(OR) || '';
    if ('$all' in cond) return cond.$all?.map(mod).join(AND) || '';

    return '';
};

export type CommonRenderRule = { allow: 'allow' | 'forbid'; action: string; role: string };

export type CommonRule = Omit<RawRule, 'conditions'> & {
    conditions?: ICaslCondition<{ relationRole: string[] }>;
};

export const mapCommonPart = (rule: CommonRule): CommonRenderRule => ({
    allow: rule.inverted ? 'forbid' : 'allow',
    action: formatCondition(rule.actions, toUpper),
    role: formatCondition(rule.conditions?.relationRole),
});

export type TypedConditionsRule<TSubject> = Omit<RawRule, 'conditions'> & {
    conditions?: ICaslCondition<TSubject>;
};
