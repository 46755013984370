import { observer } from 'mobx-react';
import React from 'react';

import { ProjectEditForm } from '../ProjectEditForm';
import type { ProjectCommonDataProps } from '../ProjectPage/ProjectPage';

type ProjectInfoProps = ProjectCommonDataProps;

@observer
export class ProjectInfo extends React.Component<ProjectInfoProps> {
    render() {
        return <ProjectEditForm project={this.props.project} />;
    }
}
