import { UnitOfMeasure } from '@common/enums';
import { normalizeAmount } from '@common/UnitOfMeasure';
import { formatString } from '@common/utils';
import { withFormik } from 'formik';
import { isNumber } from 'lodash';
import * as Yup from 'yup';

import { substanceEditStore } from '~/@store/substances';
import type { LandfillQuery_landfill_subareas } from '~/graphql';
import i18n from '~/i18n';
import { handleApolloError } from '~/utils';
import type { ILandfillSubareaInput } from '~/utils/landfill';
import { createSubarea, updateSubarea } from '~/utils/landfill';

import type { IFormValues, MaterialFormProps } from './MaterialForm';
import { MaterialForm } from './MaterialForm';

const convertToTonns = (tonnsPerCubicM: number | null, unitOfMeasure: UnitOfMeasure, amount: number) =>
    normalizeAmount(unitOfMeasure, UnitOfMeasure.TONNS, amount, tonnsPerCubicM || 1);

const convertToSubareaUnits = (
    subarea: LandfillQuery_landfill_subareas | null,
    amount: number | null | undefined
): number =>
    subarea?.dumpType?.tonnsPerCubicM && subarea.unitOfMeasure && amount
        ? normalizeAmount(UnitOfMeasure.TONNS, subarea.unitOfMeasure, amount, subarea.dumpType.tonnsPerCubicM)
        : amount || 0;

const getProviderInput = (values: IFormValues, withOnHold: boolean): ILandfillSubareaInput => {
    const input: ILandfillSubareaInput = {
        isProvider: values.isProvider,
        dumpTypeId: values.dumpType!.id,
        unitOfMeasure: values.unitOfMeasure,
        comment: values.comment,
        priceBase: values.priceBase,
        priceNotStackable: 0,
        priceOver50cm: 0,
        substances: [],
        solidState: false,
        leachingState: false,
        maxAmount: convertToTonns(values.dumpType!.tonnsPerCubicM, values.unitOfMeasure, values.maxAmount || 0),
        reservedAmount: convertToTonns(
            values.dumpType!.tonnsPerCubicM,
            values.unitOfMeasure,
            values.reservedAmount || 0
        ),
        currentAmount: convertToTonns(values.dumpType!.tonnsPerCubicM, values.unitOfMeasure, values.currentAmount || 0),
    };

    if (withOnHold) input.onHold = values.onHold;

    return input;
};

const getRegularInput = (values: IFormValues, withOnHold: boolean): ILandfillSubareaInput => {
    const {
        dumpType,
        maxAmount,
        reservedAmount,
        currentAmount,
        onHold,
        hasNotStackablePrice,
        hasOver50Price,
        ...rest
    } = values;

    const input: ILandfillSubareaInput = {
        ...rest,
        maxAmount: convertToTonns(dumpType!.tonnsPerCubicM, values.unitOfMeasure, maxAmount || 0),
        reservedAmount: convertToTonns(dumpType!.tonnsPerCubicM, values.unitOfMeasure, reservedAmount || 0),
        currentAmount: convertToTonns(dumpType!.tonnsPerCubicM, values.unitOfMeasure, currentAmount || 0),
        dumpTypeId: dumpType!.id,
    };

    input.substances = substanceEditStore.serializeAmounts();
    input.solidState = substanceEditStore.SOLID?.state;
    input.leachingState = substanceEditStore.LEACHING?.state;

    if (withOnHold) input.onHold = onHold;

    if (!hasNotStackablePrice) input.priceNotStackable = null;
    if (!hasOver50Price) input.priceOver50cm = null;

    return input;
};

export const formikHOC = (usePriceSwitch: boolean) =>
    withFormik<MaterialFormProps, IFormValues>({
        mapPropsToValues: ({ subarea }) => {
            return {
                dumpType: subarea?.dumpType || null,
                comment: subarea?.comment || '',
                priceBase: subarea?.priceBase || 0,
                priceOver50cm: subarea?.priceOver50cm || 0,
                priceNotStackable: subarea?.priceNotStackable || 0,
                maxAmount: convertToSubareaUnits(subarea, subarea?.maxAmount),
                reservedAmount: convertToSubareaUnits(subarea, subarea?.reservedAmount),
                currentAmount: convertToSubareaUnits(subarea, subarea?.currentAmount),
                unitOfMeasure: subarea?.unitOfMeasure || UnitOfMeasure.TONNS,
                allowSolidFA: subarea?.allowSolidFA || false,
                allowInvasiveSpecies: !!subarea?.allowInvasiveSpecies,
                invasiveSpecies: subarea?.invasiveSpecies || '',
                allowTOC: !!subarea?.allowTOC,
                TOCPercent: isNumber(subarea?.TOCPercent) ? subarea?.TOCPercent || 0 : null,
                onHold: subarea?.onHold ?? false,
                hasOver50Price: !!subarea?.priceOver50cm || !usePriceSwitch,
                hasNotStackablePrice: !!subarea?.priceNotStackable || !usePriceSwitch,
                isProvider: !!subarea?.isProvider,
            };
        },
        validationSchema: () =>
            Yup.object().shape({
                dumpType: Yup.object().nullable().required(i18n.Materials.materialCategoryIsRequired),
                priceOver50cm: Yup.number().test(
                    'priceOver50cm validation',
                    formatString(i18n.isRequired, i18n.Materials.priceOver50cm),
                    function (priceOver50cm: number | null | undefined) {
                        return (
                            this.parent.isProvider ||
                            !usePriceSwitch ||
                            !this.parent.hasOver50Price ||
                            (this.parent.hasOver50Price && isNumber(priceOver50cm) && priceOver50cm > 0)
                        );
                    }
                ),
                priceNotStackable: Yup.number().test(
                    'priceNotStackable validation',
                    formatString(i18n.isRequired, i18n.Materials.priceNotStackable),
                    function (priceNotStackable: number | null | undefined) {
                        return (
                            this.parent.isProvider ||
                            !usePriceSwitch ||
                            !this.parent.hasNotStackablePrice ||
                            (this.parent.hasNotStackablePrice && isNumber(priceNotStackable) && priceNotStackable > 0)
                        );
                    }
                ),
                TOCPercent: Yup.number()
                    .nullable()
                    .test('TOC-value validation', i18n.errorCodes.TOC_VALUE_REQUIRED, function (TOCValue) {
                        return (
                            this.parent.isProvider ||
                            !this.parent.allowTOC ||
                            (this.parent.allowTOC && isNumber(TOCValue) && TOCValue > 0)
                        );
                    }),
                invasiveSpecies: Yup.string()
                    .nullable()
                    .test(
                        'Invasive species validation',
                        i18n.errorCodes.INVASIVE_SPECIES_REQUIRED,
                        function (invasiveSpecies) {
                            return (
                                this.parent.isProvider ||
                                !this.parent.allowInvasiveSpecies ||
                                (this.parent.allowInvasiveSpecies && !!invasiveSpecies)
                            );
                        }
                    ),
            }),
        handleSubmit: (values, { props }) => {
            const { landfill, subarea, onClose, withOnHold } = props;

            const input = values.isProvider
                ? getProviderInput(values, withOnHold)
                : getRegularInput(values, withOnHold);

            if (subarea) {
                input.id = subarea.id;
                updateSubarea(landfill, input)
                    .then(() => onClose())
                    .catch(handleApolloError);
            } else {
                createSubarea(landfill, input)
                    .then(() => onClose())
                    .catch(handleApolloError);
            }
        },
    });

export default formikHOC(true)(MaterialForm);
