import { AbilitySubjects, UserContextRole, UserRole } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import {
    IAbilityLandfill,
    ILandfillCalculatedSubject,
    ILandfillRelationRole,
    ILandfillSubject,
    LandfillSubject,
} from './landfillTypes';

const isOwnerForLandfill = (user: IAbilityUser, landfill: IAbilityLandfill) => user.id === landfill.ownerUserId;

const isReceiverForLandfill = (user: IAbilityUser, landfill: IAbilityLandfill) =>
    user.role === UserRole.EXTERNAL_RECEIVER &&
    user.customerId === landfill.customerId &&
    landfill.receiverIds.includes(user.id);

const isGuestForLandfill = (user: IAbilityUser, landfill: IAbilityLandfill) =>
    user.role === UserRole.EXTERNAL_RECEIVER &&
    user.customerId !== landfill.customerId &&
    landfill.receiverIds.includes(user.id);

const isReceiver = (user: IAbilityUser) => user.role === UserRole.EXTERNAL_RECEIVER;

export const getLandfillSubject = (
    user: IAbilityUser,
    landfill: IAbilityLandfill | null | undefined,
    subject?: ILandfillSubject
): ILandfillCalculatedSubject => {
    let subSubject = subject ? [subject] : [];
    let relationRole: ILandfillRelationRole[] = [user.role!];

    if (landfill) {
        if (isReceiverForLandfill(user, landfill)) relationRole.push(UserContextRole.RECEIVER);

        if (isGuestForLandfill(user, landfill)) relationRole.push(UserContextRole.GUEST);

        if (isOwnerForLandfill(user, landfill)) relationRole.push(UserContextRole.OWNER);
    }

    if (!landfill) {
        if (isReceiver(user)) relationRole.push(UserContextRole.RECEIVER);
    }

    if (!!landfill?.deletedAt) subSubject.push(LandfillSubject.LANDFILL_DELETED);

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.LANDFILL,
        relationRole,
        subSubject,
    };
};
