import { makeStyles } from '@material-ui/core';
import React from 'react';

import { CardListItem } from '~/@components/CardListItem';
import { Text } from '~/@components/Text';
import { ILandfillStatus } from '~/@store/landfillStatuses';
import { LandfillStatusIcon } from '~/@views/AdminView/common/LandfillStatusIcon';

type Props = {
    status: ILandfillStatus;
    isActive: boolean;
    onSelect: () => void;
};

export const LandfillStatusCard = ({ status, isActive, onSelect }: Props) => {
    const { content } = useStyles();

    return (
        <CardListItem isActive={isActive} onClick={onSelect}>
            <div className={content}>
                <LandfillStatusIcon status={status} withTooltip={false} />
                <Text variant="BodyRegular">{status.name}</Text>
            </div>
        </CardListItem>
    );
};

const useStyles = makeStyles(theme => ({
    content: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
}));
