import './LandfillInfoCard.scss';

import React, { MouseEvent } from 'react';

import { Button } from '~/@components/Button';
import { HintTooltip } from '~/@components/HintTooltip';
import { CalendarIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import { LocationMarkerIcon } from '~/@sochi-components/Icons';
import { LandfillStatusIcon } from '~/@sochi-components/LandfillStatusIcon';
import type { IHomePageLandfill } from '~/@user-store/homePageMap';
import { isOwnLandfill } from '~/@user-store/homePageMap/homePageMap.utils';
import { fmtCoordinates, fmtCurr } from '~/utils/numbers';

import { getWorkTimeLabel, LandfillWorkTime } from './LandfillWorkTime';

type LandfillInfoCardProps = {
    landfill: IHomePageLandfill;
    onOpen?: (landfill: IHomePageLandfill) => void;
};

export class LandfillInfoCard extends React.PureComponent<LandfillInfoCardProps> {
    dayOfWeek = new Date().getDay();

    bem = getBem(this);
    openLandfill = (e: MouseEvent) => {
        const { onOpen, landfill } = this.props;

        onOpen && onOpen(landfill);

        e.stopPropagation();
    };

    render() {
        const { landfill } = this.props;
        const { className, element } = this.bem;
        const curDayNumber = ((this.dayOfWeek + 6) % 7) + 1;
        const workDay = landfill.workTime.find(d => d.dayNumber === curDayNumber);
        const schedule = getWorkTimeLabel(workDay);

        return (
            <div className={className}>
                <div className={element('title')}>
                    <div className={element('name')} title={landfill.name}>
                        {landfill.name}
                    </div>
                </div>
                <div className={element('body')}>
                    {isOwnLandfill(landfill) && (
                        <div className={element('section')}>
                            <LandfillStatusIcon icon={landfill.status?.icon} />
                            <div>{landfill.status?.name}</div>
                        </div>
                    )}
                    <div className={element('section')}>
                        <HintTooltip
                            text={<LandfillWorkTime workTime={landfill.workTime} selectedDayNumber={curDayNumber} />}
                            Icon={CalendarIcon}
                            className={element('section-icon')}
                        />
                        <div>{schedule}</div>
                    </div>
                    {isOwnLandfill(landfill) && (
                        <div className={element('section')}>
                            <div>{fmtCurr(landfill.minMargin)}</div>
                        </div>
                    )}
                    <div className={element('section')}>
                        <LocationMarkerIcon className={element('section-icon')} />
                        <div className={element('location')}>
                            <div>{landfill.location.text}</div>
                            <div>{fmtCoordinates(landfill.location)}</div>
                        </div>
                    </div>
                </div>
                {this.props.onOpen && (
                    <div className={element('action')}>
                        <Button color="primary" variant="outlined" type="button" onClick={this.openLandfill}>
                            <LocalizableText code={'openLandfill'} />
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}
