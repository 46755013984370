import { gql } from '@apollo/client';

import { CompanyShortFragment } from '~/@store/companies';

export const UserFragment = gql`
    fragment UserFragment on User {
        id
        name
        role
        orgName
        orgNr
        customerId
        confirmed
        verified
        blocked
        surname
        phoneNumber
        email
        createdAt
        customer {
            ...CompanyShortFragment
        }
        lastActivityDate
        vehicles {
            licensePlate
            capacity
            vehicleTypeId
            fuel
            engine
        }
        deletedAt
    }

    ${CompanyShortFragment}
`;

export const UserRecordFragment = gql`
    fragment UserRecordFragment on UserRecord {
        id
        name
        surname
        phoneNumber
        email
        role
        customerId
    }
`;

export const SubLandfillFragment = gql`
    fragment SubLandfillFragment on Landfill {
        id
        name
        receiverIds
        customerId
        ownerUserId
    }
`;

export const SubProjectFragment = gql`
    fragment SubProjectFragment on Project {
        id
        name
        userLinks {
            userId
            role
        }
        customerId
        ownerUserId
        status
        ver
    }
`;
