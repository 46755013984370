import { FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import React from 'react';

import { IOSSwitch } from '~/@components/IOSSwitch';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import type { ISettings } from '~/@store/settings';

type FeatureFlags = ISettings['featuresFlags'];

type Props = {
    flags: FeatureFlags;
    setFlags: (value: FeatureFlags) => void;
    field: Exclude<keyof FeatureFlags, '__typename'>;
    label: React.ReactNode;
};

export const FeatureFlagField = ({ flags, setFlags, field, label }: Props) => {
    const { group, subLabel, checkbox } = useStyles();

    return (
        <FormGroup className={group}>
            <Text variant="BodyBold">{label}</Text>
            <FormControlLabel
                control={
                    <IOSSwitch
                        className={checkbox}
                        checked={flags[field]}
                        onChange={event => setFlags({ ...flags, [field]: event.target.checked })}
                    />
                }
                label={
                    <Text className={subLabel} color="textSecondary">
                        <LocalizableText
                            code={
                                flags[field]
                                    ? 'settingsPage.featuresFlags.active'
                                    : 'settingsPage.featuresFlags.notActive'
                            }
                        />
                    </Text>
                }
            />
        </FormGroup>
    );
};

export const useStyles = makeStyles(theme => ({
    group: {
        margin: theme.spacing(1.5, 0),
        display: 'flex',
        flexDirection: 'row',
    },
    subLabel: {
        paddingLeft: theme.spacing(),
    },
    checkbox: {
        marginLeft: theme.spacing(3),
    },
}));
