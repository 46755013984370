import { adminRoutes } from '@common/routes';
import React from 'react';

import { Button } from '~/@components/Button';
import { CardDialog } from '~/@components/Dialog';
import { Link } from '~/@components/Link';
import { LocalizableText } from '~/@components/LocalizableText';
import { Markdown } from '~/@components/Markdown';
import { showCustomDialog } from '~/@store/common';
import { IReleaseNote } from '~/@store/releaseNotes';

type Props = {
    note: IReleaseNote;
    onClose: (hasRead: boolean) => void;
};

const AdminReleaseNoteDialog = ({ note, onClose }: Props) => {
    return (
        <CardDialog
            maxWidth="md"
            fullWidth
            title={<LocalizableText code={'ReleaseNotes.whatsNew'} />}
            onClose={() => onClose(false)}
            buttons={
                <Button variant="contained" onClick={() => onClose(true)}>
                    <LocalizableText code={'close'} />
                </Button>
            }>
            <Markdown text={note.text} />
            <Link to={adminRoutes.releaseNotes}>
                <LocalizableText code={'showMore'} />
            </Link>
        </CardDialog>
    );
};

export const showAdminReleaseNoteDialog = (note: IReleaseNote) => {
    showCustomDialog({
        render: closeDialog => <AdminReleaseNoteDialog onClose={closeDialog} note={note} />,
    });
};
