import { ContaminationLevel } from '@common/enums';
import { FilterRange } from '@common/types';
import { computed } from 'mobx';

import { LandfillSubareasPagedQueryVariables, SubareaFilter } from '~/graphql';

import { FilterStore, PaginationStore, SortStore } from '../common';
import { DEFAULT_SUBAREA_SORT } from './subareas.constants';

export enum SubareaStoreFilterFields {
    landfillName = 'landfillName',
    landfillComment = 'landfillComment',
    landfillGateFee = 'landfillGateFee',
    landfillLocation = 'landfillLocation',
    landfillMarginPercent = 'landfillMarginPercent',
    landfillMinMargin = 'landfillMinMargin',
    landfillStatus = 'landfillStatus',
    dumpType = 'dumpType',
    materialId = 'materialId',
    comment = 'comment',
    currentAmount = 'currentAmount',
    nonStackable = 'nonStackable',
    over50cm = 'over50cm',
    priceBase = 'priceBase',
    priceNotStackable = 'priceNotStackable',
    priceOver50cm = 'priceOver50cm',
    priceUpdatedAt = 'priceUpdatedAt',
    solidTestResult = 'solidTestResult',
    isProvider = 'isProvider',
}

export type SubareaStoreFilter = {
    landfillName?: string | null;
    landfillComment?: string | null;
    landfillGateFee?: FilterRange<number> | null;
    landfillLocation?: string | null;
    landfillMarginPercent?: FilterRange<number> | null;
    landfillMinMargin?: FilterRange<number> | null;
    landfillStatus?: { name: string; id: string } | null;
    dumpType?: { name: string; id: string } | null;
    materialId?: string | null;
    comment?: string | null;
    currentAmount?: FilterRange<number> | null;
    nonStackable?: boolean | null;
    over50cm?: boolean | null;
    priceBase?: FilterRange<number> | null;
    priceNotStackable?: FilterRange<number> | null;
    priceOver50cm?: FilterRange<number> | null;
    priceUpdatedAt?: FilterRange<Date> | null;
    solidTestResult?: ContaminationLevel | null;
    isProvider?: boolean | null;
};

class SubareasStore {
    pagination = new PaginationStore();
    filter = new FilterStore<SubareaStoreFilter>(this.pagination.onClear);
    sort = new SortStore(DEFAULT_SUBAREA_SORT, this.pagination.onClear);

    @computed
    get subareasQueryVariables(): LandfillSubareasPagedQueryVariables {
        const { priceUpdatedAt, dumpType, landfillStatus, ...rest } = this.filter.values;
        const filter: SubareaFilter = rest;
        if (priceUpdatedAt) filter.priceUpdatedAt = priceUpdatedAt.map(d => d?.toISOString() || null);
        if (dumpType) filter.dumpTypeId = dumpType.id;
        if (landfillStatus) filter.landfillStatusId = landfillStatus.id;
        const sort = this.sort.value;
        const connection = this.pagination.connection;

        return { filter, sort, connection };
    }
}

export const subareasStore = new SubareasStore();
