import { gql } from '@apollo/client';

import { LocationFragment } from '../locations/location.queries';

export const LandfillShortFragment = gql`
    fragment LandfillShortFragment on Landfill {
        id
        name
        location {
            ...LocationFragment
        }
        gateFee
        minMargin
        marginPercent
        receivers {
            id
            name
            surname
            email
            role
        }
        workTime {
            dayNumber
            openFrom
            openTo
            isOpen
        }
        comment
        status {
            id
            name
            icon
        }
        isDeleted
        capacity
    }

    ${LocationFragment}
`;
