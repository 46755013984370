import React, { useState } from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { BiSideLayout } from '~/@components/BiSideLayout';
import { RecycleIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { PageLight } from '~/@components/PageLight';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { IBeastMaterial, useBeastMaterialList, useBeastMaterialPermissions } from '~/@store/beastMaterials';
import { showBeastMaterialCreateDialog } from '~/@views/AdminView/BeastMaterialsPage/BeastMaterialCreateDialog';
import { BeastMaterialForm } from '~/@views/AdminView/BeastMaterialsPage/BeastMaterialForm';
import { BeastMaterialList } from '~/@views/AdminView/BeastMaterialsPage/BeastMaterialList';
import i18n from '~/i18n';

export const BeastMaterialsPage = () => {
    const query = useBeastMaterialList();

    const [selectedId, selectId] = useState<string | null>(null);

    const { canCreate } = useBeastMaterialPermissions();

    return (
        <QueryStateHandler query={query} dataGetter={query => query.data?.beastMaterials}>
            {(data: IBeastMaterial[]) => (
                <PageLight>
                    <PageLight.Header
                        icon={<RecycleIcon />}
                        title={<LocalizableText code={'Admin.beastMaterials'} />}
                        rightActions={
                            <ActionButton onClick={showBeastMaterialCreateDialog} disabled={!canCreate}>
                                {i18n.create}
                            </ActionButton>
                        }
                    />
                    <PageLight.Content>
                        <BiSideLayout>
                            <BiSideLayout.LeftSideContent>
                                <BeastMaterialList materials={data} selectedId={selectedId} onSelect={selectId} />
                            </BiSideLayout.LeftSideContent>
                            <BiSideLayout.RightSideContent>
                                {selectedId && (
                                    <BeastMaterialForm
                                        key={selectedId}
                                        material={data.find(s => s.id === selectedId) || null}
                                        closeForm={() => selectId(null)}
                                    />
                                )}
                            </BiSideLayout.RightSideContent>
                        </BiSideLayout>
                    </PageLight.Content>
                </PageLight>
            )}
        </QueryStateHandler>
    );
};
