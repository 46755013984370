import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { COLORS } from '~/@components/@theme';

export const Card: FC = ({ children }) => {
    const { root } = useStyles();

    return <div className={root}>{children}</div>;
};

const useStyles = makeStyles(() => ({
    root: {
        background: COLORS.white,
        borderRadius: '8px',
        padding: '14px 16px',
        fontSize: '18px',
        fontFamily: 'Catamaran',
    },
}));
