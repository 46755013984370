import React from 'react';

import { Autocomplete } from '~/@components/Autocomplete';
import { LocalizableText } from '~/@components/LocalizableText';

type SubstanceLike = { id: string; name: string };

type Props = {
    substances: SubstanceLike[];
    onSubstanceSelect: (id: string) => void;
};

export const SubstanceAutocomplete = ({ substances, onSubstanceSelect }: Props) => {
    const [search, setSearch] = React.useState('');

    const filtered = search ? substances.filter(s => s.name.toLowerCase().includes(search.toLowerCase())) : substances;

    const handleSelect = (v: SubstanceLike | null) => {
        if (v) onSubstanceSelect(v.id);
        setSearch('');
    };

    return (
        <Autocomplete
            label={<LocalizableText code={'ToxicLimitsPage.addSubstance'} />}
            selectedOption={null}
            onOptionSelect={handleSelect}
            options={filtered}
            renderOption={s => s.name}
            searchValue={search}
            onSearchChange={setSearch}
        />
    );
};
