import React from 'react';

import { SimpleDialog } from '~/@components/Dialog';
import { showCustomDialog } from '~/@store/common';
import { ContaminationType } from '~/graphql';

import { SubstanceEditForm } from './SubstanceEditForm';

type Props = {
    closeDialog: () => void;
    contaminationType: ContaminationType;
};

export const SubstanceCreateDialog = ({ closeDialog, contaminationType }: Props) => {
    return (
        <SimpleDialog onClose={closeDialog}>
            <SubstanceEditForm substance={null} contaminationType={contaminationType} closeForm={closeDialog} />
        </SimpleDialog>
    );
};

export const showSubstanceCreateDialog = (contaminationType: ContaminationType) =>
    showCustomDialog({
        render: closeDialog => (
            <SubstanceCreateDialog closeDialog={() => closeDialog(false)} contaminationType={contaminationType} />
        ),
    });
