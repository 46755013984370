import React from 'react';

import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { SochiUserSearchMultiSelect } from '~/@views/UserView/common/SochiUserSearchMultiSelect';
import { canChangeLandfillField, canReadLandfillField } from '~/utils/auth';

import { LandfillFields } from '../../../../../../../common/enums';
import { UserRole } from '../../../../../config/enum';
import { FormNames } from './landfillFormikHOC';
import { useLandfillFormContext } from './LandfillFormWithContext';

type LandfillUserSearchSelectProps = {
    className?: string;
};

export const LandfillUserSearchSelect = ({ className }: LandfillUserSearchSelectProps) => {
    const bem = useFunctionalBem(LandfillUserSearchSelect, className);
    const context = useLandfillFormContext();

    const { ability, landfill, values, setFieldValueAndTouchIt, readOnly, user, isSubmitting } = context;
    if (!canReadLandfillField(user, ability, landfill, LandfillFields.receivers)) return null;

    return (
        <div className={bem.className}>
            <SochiUserSearchMultiSelect
                filter={{
                    roles: [UserRole.EXTERNAL_RECEIVER],
                    customerId: user.customerId || undefined,
                }}
                label={
                    <LabelWithHint
                        label={<LocalizableText code={'LandfillForm.contactPerson'} />}
                        hint={<LocalizableText code={'LandfillForm.contactPersonFieldHint'} />}
                    />
                }
                values={values[FormNames.receivers] || []}
                onChange={v => setFieldValueAndTouchIt(FormNames.receivers, v, true)}
                readOnly={
                    isSubmitting ||
                    readOnly ||
                    !canChangeLandfillField(user, ability, landfill, LandfillFields.receiverIds)
                }
            />
        </div>
    );
};
