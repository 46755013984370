import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback, useState } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { ISimpleBatch, LandfillBatchSelect } from '~/@views/UserView/LandfillPage/common/LandfillBatchSelect';
import { LandfillQuery_landfill } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    landfill: LandfillQuery_landfill;
    onClose: () => void;
    onSave: (batch: ISimpleBatch | null) => void;
    selected: ISimpleBatch | null;
};

export const BatchSelectForm: FC<Props> = ({ landfill, onClose, onSave, selected }) => {
    const [current, setCurrent] = useState<ISimpleBatch | null>(selected);
    const { root, buttons } = useStyles();

    const save = useCallback(() => {
        if (!current) return;

        onSave(current);
        onClose();
    }, [onSave, onClose, current]);

    return (
        <DialogContent>
            <div className={root}>
                <LandfillBatchSelect
                    landfill={landfill}
                    selected={current}
                    onChange={setCurrent}
                    label={<LocalizableText code={'ProjectDocuments.weightReceiptTable.batch'} />}
                />
                <div className={buttons}>
                    <Button variant="outlined" color="primary" onClick={onClose}>
                        {i18n.cancel}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={selected === current || !current}
                        onClick={save}>
                        {i18n.save}
                    </Button>
                </div>
            </div>
        </DialogContent>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));
