import React, { VFC } from 'react';

import { DespatchAdviceResponseTypeChip } from '~/@components/BeastDataDialog/BeastDataTable/DespatchAdviceResponseTypeChip';
import { ExtendedDespatchAdvice } from '~/@components/BeastDataDialog/BeastDataTable/useBeastDataColumns';
import { NullableFormatter } from '~/@components/Formatters';
import { LabelWithHint } from '~/@components/LabelWithHint';

type Props = {
    despatchAdvice: ExtendedDespatchAdvice;
};

export const DespatchAdviceResponseStatusCell: VFC<Props> = ({ despatchAdvice }) => {
    if (!despatchAdvice.response) return <NullableFormatter>{null}</NullableFormatter>;

    return despatchAdvice.response.declineReason ? (
        <LabelWithHint
            label={<DespatchAdviceResponseTypeChip type={despatchAdvice.response.responseType} />}
            hint={despatchAdvice.response.declineReason}
        />
    ) : (
        <DespatchAdviceResponseTypeChip type={despatchAdvice.response.responseType} />
    );
};
