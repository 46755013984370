import React from 'react';

import { IDeviation } from '~/@user-store/deviations';
import * as queries from '~/@user-store/deviations/deviation.queries';
import { DeviationTable } from '~/@views/UserView/common/DeviationTable';
import { AbilityUserContext, IAbilityUserContext } from '~/contexts';
import type {
    DeviationsByLandfillQuery as IDeviationsByLandfillQuery,
    DeviationsByLandfillQueryVariables,
} from '~/graphql';
import { LandfillQuery_landfill } from '~/graphql';
import { showCustomDialog } from '~/services/dialog';
import type { WithGraphqlProps } from '~/services/hoc';
import { handledGraphql } from '~/services/hoc';
import { canCreateLandfillDeviation } from '~/utils/auth';

import { LandfillDeviationForm } from './LandfillDeviationForm';

interface ExternalProps {
    landfill: LandfillQuery_landfill;
}

type InjectedProps = WithGraphqlProps<ExternalProps, IDeviationsByLandfillQuery, DeviationsByLandfillQueryVariables>;

class LandfillDeviations extends React.Component<InjectedProps> {
    static contextType = AbilityUserContext;
    context!: IAbilityUserContext;

    handleOpenDeviation = (deviation: IDeviation) => {
        showCustomDialog({
            render: closeDialog => (
                <LandfillDeviationForm landfill={this.props.landfill} deviation={deviation} closeDialog={closeDialog} />
            ),
        }).then(() => this.props.data.refetch());
    };

    handleAddDeviation = () => {
        showCustomDialog({
            render: closeDialog => <LandfillDeviationForm landfill={this.props.landfill} closeDialog={closeDialog} />,
        }).then(() => this.props.data.refetch());
    };

    render() {
        const { landfill, data } = this.props;

        return (
            <DeviationTable
                deviations={data.deviationsByLandfill || []}
                createDeviation={this.handleAddDeviation}
                openDeviation={this.handleOpenDeviation}
                canCreateDeviation={canCreateLandfillDeviation(this.context, landfill)}
            />
        );
    }
}

const withData = handledGraphql<
    IDeviationsByLandfillQuery,
    ExternalProps,
    DeviationsByLandfillQueryVariables,
    InjectedProps
>(queries.DeviationsByLandfillQuery, {
    options: props => {
        return {
            fetchPolicy: 'network-only',
            variables: {
                id: props.landfill.id,
            },
        };
    },
});

export default withData(LandfillDeviations);
