import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

export const DashboardTitle: FC = ({ children }) => {
    const { title } = useStyles();

    return <h2 className={title}>{children}</h2>;
};

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '18px',
    },
}));
