import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { UpdateToxicLimitMutation, UpdateToxicLimitMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../toxicLimits.queries';

export type ToxicLimitsUpdateFunc = (input: UpdateToxicLimitMutationVariables) => Promise<unknown>;

export const useToxicLimitsUpdate = (): [ToxicLimitsUpdateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<UpdateToxicLimitMutation, UpdateToxicLimitMutationVariables>(
        queries.UpdateToxicLimitMutation,
        {
            refetchQueries: [
                {
                    query: queries.ToxicLimitsQuery,
                },
            ],
        }
    );

    const update = useCallback<ToxicLimitsUpdateFunc>(
        variables => {
            return callMutation({ variables }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [update, mutationResult.loading];
};
