import { formatString } from '@common/utils';
import { Button } from '@material-ui/core';
import React, { useMemo } from 'react';

import { makeStyles } from '~/@components/@theme';
import { CustomTabs, ICustomTabConfig } from '~/@components/CustomTabs';
import { CardDialog } from '~/@components/Dialog';
import { Link } from '~/@components/Link';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { showCustomDialog } from '~/@store/common';
import { ICompany, IConnectedEntitiesInfo } from '~/@store/companies';
import { landfillDetailRoute, projectDetailRoute, userDetailRoute } from '~/@store/routes';
import i18n from '~/i18n';
import { getUserFullName } from '~/utils/user';

type Props = {
    company: ICompany;
    data: IConnectedEntitiesInfo;
    onClose: () => void;
};

const ConnectedItem = ({ name, link, index }: { name: string; link: string; index: number }) => {
    const { item } = useStyles();

    return (
        <div className={item}>
            <Text>{index + 1}.</Text>
            <Link to={link} targetBlank={true}>
                {name}
            </Link>
        </div>
    );
};

export const CompanyConnectedEntitiesDialog = ({ company, data, onClose }: Props) => {
    const { container, dialog } = useStyles();

    const tabs = useMemo<ICustomTabConfig[]>(() => {
        const tabs = [];
        if (data.projects.length)
            tabs.push({
                label: formatString(i18n.CompaniesTable.connectedEntities.projects, data.projects.length.toString()),
                render: () => (
                    <div className={container}>
                        {data.projects.map((e, ind) => (
                            <ConnectedItem link={projectDetailRoute(e.id)} name={e.name} key={e.id} index={ind} />
                        ))}
                    </div>
                ),
            });
        if (data.landfills.length)
            tabs.push({
                label: formatString(i18n.CompaniesTable.connectedEntities.landfills, data.landfills.length.toString()),
                render: () => (
                    <div className={container}>
                        {data.landfills.map((e, ind) => (
                            <ConnectedItem link={landfillDetailRoute(e.id)} name={e.name} key={e.id} index={ind} />
                        ))}
                    </div>
                ),
            });
        if (data.users.length)
            tabs.push({
                label: formatString(i18n.CompaniesTable.connectedEntities.users, data.users.length.toString()),
                render: () => (
                    <div className={container}>
                        {data.users.map((e, ind) => (
                            <ConnectedItem
                                link={userDetailRoute(e.id)}
                                name={getUserFullName(e)}
                                key={e.id}
                                index={ind}
                            />
                        ))}
                    </div>
                ),
            });

        return tabs;
    }, [data, container]);

    return (
        <CardDialog
            title={formatString(i18n.CompaniesTable.canNotBeDeleted, company.name)}
            maxWidth="md"
            fullWidth
            onClose={onClose}
            buttons={
                <Button variant="contained" onClick={onClose}>
                    {i18n.close}
                </Button>
            }>
            <div className={dialog}>
                <Text variant="BodyBold">
                    <LocalizableText code={'CompaniesTable.canNotBeDeletedDescription'} />
                </Text>
                <CustomTabs tabs={tabs} title={<LocalizableText code={'CompaniesTable.connectedEntities.title'} />} />
            </div>
        </CardDialog>
    );
};

const useStyles = makeStyles(theme => ({
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    container: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        height: '40vh',
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'baseline',
    },
}));

export const showCompanyConnectedEntitiesDialog = (company: ICompany, data: IConnectedEntitiesInfo) => {
    const ignoredPromise = showCustomDialog({
        render: closeDialog => <CompanyConnectedEntitiesDialog company={company} data={data} onClose={closeDialog} />,
    });
};
