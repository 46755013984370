import { makeStyles } from '@material-ui/core';
import React from 'react';

export const PageNoScroll: React.FC = ({ children }) => {
    const { root } = useStyles();

    return <div className={root}>{children}</div>;
};

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100vh - 201px)',
        overflow: 'hidden',

        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 50px)',
        },
    },
}));
