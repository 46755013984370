import { LoadStatus } from '@common/enums';
import { makeStyles } from '@material-ui/core';
import React from 'react';

import { Checkbox } from '~/@components/Checkbox';
import { DumpLoadStatusFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { IDetailProjectDumpLoad } from '~/@store/projects';

import { DumpLoadCard } from './DumpLoadCard';

type Props = {
    dumpLoads: IDetailProjectDumpLoad[];
    selectedId: string | null;
    onSelect: (id: string) => void;
};

type StatusFilter = Record<LoadStatus, boolean>;

const defaultFilter: StatusFilter = {
    DISCARDED: false,
    NOT_ORDERED: false,
    DRAFT: true,
    REQUESTED: true,
    ORDERED: true,
    CONFIRMED: true,
    IN_PROGRESS: true,
    DONE: false,
};

export const DumpLoadSelect = ({ dumpLoads, selectedId, onSelect }: Props) => {
    const [filter, setFilter] = React.useState(defaultFilter);

    const handleChangeFilter = (status: LoadStatus) => () =>
        setFilter(filter => ({ ...filter, [status]: !filter[status] }));

    const { root, titleStyle, filterLabel, cardsContainer } = useStyles();

    if (!dumpLoads.length) return <Text>{<LocalizableText code={'noMasses'} />}</Text>;

    const filteredDumpLoads = dumpLoads.filter(d => !d.status || filter[d.status]);

    return (
        <>
            <div className={root}>
                <Text variant="TitleBold" className={titleStyle}>
                    <LocalizableText code={'Admin.dumpLoads'} />
                </Text>
                {Object.values(LoadStatus).map(status => (
                    <Checkbox
                        key={status}
                        color="primary"
                        label={<DumpLoadStatusFormatter className={filterLabel}>{status}</DumpLoadStatusFormatter>}
                        checked={filter[status]}
                        onChange={handleChangeFilter(status)}
                    />
                ))}
            </div>
            <div className={cardsContainer}>
                {filteredDumpLoads.map((d, index) => (
                    <DumpLoadCard
                        key={d.id + index}
                        dumpLoad={d}
                        isSelected={d.id === selectedId}
                        onClick={() => onSelect(d.id)}
                    />
                ))}
            </div>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        height: 40,
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    titleStyle: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(4),
    },
    filterLabel: {
        color: theme.palette.text.secondary,
    },
    cardsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
        alignItems: 'center',
    },
}));
