import React, { VFC } from 'react';

import { CloudUploadIcon } from '~/@components/Icon';
import { CellButton } from '~/@components/Table/TableCellActions/CellButton';
import { IDespatchAdvice, useSendDespatchAdviceMutation } from '~/@store/beastIntegration';
import i18n from '~/i18n';

type Props = {
    despatchAdvice: IDespatchAdvice;
};

export const DespatchAdviceSendCell: VFC<Props> = ({ despatchAdvice }) => {
    const [sendDespatchAdvice, disabled] = useSendDespatchAdviceMutation(despatchAdvice);

    return (
        <CellButton
            onClick={sendDespatchAdvice}
            disabled={disabled}
            disabledReason={i18n.DespatchAdvicesTable.sendDisabledReason}>
            <CloudUploadIcon />
        </CellButton>
    );
};
