import React, { FC, useCallback } from 'react';

import { makeStyles } from '~/@components/@theme';
import { ActionButton } from '~/@components/ActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { IDetailedLandfill } from '~/@store/landfills';
import { useGeocode } from '~/@store/locations';
import { homePageMapStore } from '~/@user-store/homePageMap';
import { useDumpLoadFormikContext } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm/DumpLoadEditFormik';
import { showLandfillCreateDialogDefaultValues } from '~/@views/AdminView/LandfillsPage/LandfillEditForm';
import { LandfillDetailedQuery_landfill_location } from '~/graphql';
import { getLandfillGqlShape } from '~/utils/landfill';

import { DumpLoadSuggestedDestinationInfo } from './DumpLoadSuggestedDestinationInfo';

type Props = {
    onCreateLandfill?: (landfill: IDetailedLandfill) => void;
};

export const DumpLoadSuggestedDestination: FC<Props> = ({ onCreateLandfill }) => {
    const formik = useDumpLoadFormikContext();

    const { root } = useStyles();

    const geocode = useGeocode();

    const onClick = useCallback(async () => {
        const name = formik.values.landfillName;
        const address = formik.values.landfillAddress;

        const locationPromise = address ? geocode(address) : Promise.resolve(null);

        const result: IDetailedLandfill = await locationPromise
            .catch(_ => Promise.resolve(null))
            .then(location =>
                getLandfillGqlShape({
                    name,
                    location: (location || homePageMapStore.defaultLocation) as LandfillDetailedQuery_landfill_location,
                })
            );

        return showLandfillCreateDialogDefaultValues(result, onCreateLandfill);
    }, [formik.values, geocode, formik.setValues, onCreateLandfill]);

    return (
        <div className={root}>
            <DumpLoadSuggestedDestinationInfo />
            <ActionButton onClick={onClick}>
                <LocalizableText code={'LandfillForm.createLandfill'} />
            </ActionButton>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
    },
}));
