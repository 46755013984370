import { Formik } from 'formik';
import React, { useCallback } from 'react';

import { QueryStateHandler } from '~/@components/QueryStateHandler';
import {
    deliveryLinesStore,
    IDeliveryLine,
    useDeliveryLinesUpdateMutation,
    usePagedDeliveryLines,
} from '~/@store/deliveryLines';
import { IDumpLoadSearchRecord } from '~/@store/dumpLoads';

import { DeliveryLinesMassEditForm } from './DeliveryLinesMassEditForm';
import { getDeliveryLinesMassEditFormikConfig, transformInputData } from './DeliveryLinesMassEditFormik';

type Props = {
    dumpLoad: IDumpLoadSearchRecord;
    onClose?: (v: boolean) => void;
};

export const DeliveryLinesMassEditFormContainer = ({ dumpLoad, onClose }: Props) => {
    const query = usePagedDeliveryLines();
    const [updateDeliveryLines] = useDeliveryLinesUpdateMutation();

    const onCloseHandle = useCallback(
        (v: boolean) => {
            deliveryLinesStore.filter.clearValues();
            deliveryLinesStore.sort.clearSort();
            if (onClose) onClose(v);
        },
        [onClose]
    );

    return (
        <QueryStateHandler
            query={query}
            dataGetter={query =>
                query.data?.deliveryLinesPaged.edges.map(e => e.node) ||
                query.previousData?.deliveryLinesPaged.edges.map(e => e.node)
            }>
            {(deliveryLines: IDeliveryLine[]) => (
                <Formik
                    {...getDeliveryLinesMassEditFormikConfig(
                        deliveryLines.map(transformInputData),
                        updateDeliveryLines
                    )}>
                    <DeliveryLinesMassEditForm dumpLoad={dumpLoad} onClose={onClose ? onCloseHandle : undefined} />
                </Formik>
            )}
        </QueryStateHandler>
    );
};
