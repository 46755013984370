import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import { Link, LinkProps } from '~/@components/Link';

export const ButtonLink = ({ className = '', ...restProps }: LinkProps) => {
    const { root } = useStyles();

    return <Link className={cn(root, className)} {...restProps} />;
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1, 2),
        height: 40,
        color: theme.palette.text.primary,
        ...theme.customFont.select,
        cursor: 'pointer',
    },
}));
