import { UnitOfMeasure } from '@common/enums';
import { MAX_TOC_VALUE, MIN_TOC_VALUE } from '@common/validations/common.validation';
import { makeStyles } from '@material-ui/core/styles';
import type { FormikProps } from 'formik';
import { Form } from 'formik';
import React from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { Button } from '~/@components/Button';
import { Checkbox } from '~/@components/Checkbox';
import { FormikContextNumberField, FormikContextTextField } from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { SochiUnitOfMeasureSelect } from '~/@sochi-components/SochiUnitOfMeasureSelect';
import { substanceEditStore } from '~/@store/substances';
import { EditSubstanceView } from '~/@views/UserView/common/EditSubstanceView';
import { SochiDumpCategoriesSelect } from '~/@views/UserView/ProjectPage/common/SochiDumpCategoriesSelect';
import { commonPrecisions } from '~/config/enum';
import type { LandfillQuery_landfill_subareas } from '~/graphql';
import i18n from '~/i18n';

type DumpType = {
    id: string;
    name: string;
    description: string | null;
    tonnsPerCubicM: number | null;
    contaminationLevelAvailable: boolean;
};

export type IFormValues = {
    priceBase: number;
    priceOver50cm: number;
    priceNotStackable: number;
    unitOfMeasure: UnitOfMeasure;
    dumpType: DumpType | null;
    comment: string;
    maxAmount: number | null;
    currentAmount: number | null;
    reservedAmount: number | null;
    allowSolidFA: boolean;
    allowInvasiveSpecies: boolean;
    invasiveSpecies: string;
    allowTOC: boolean;
    TOCPercent: number | null;
    onHold: boolean;
    hasOver50Price: boolean;
    hasNotStackablePrice: boolean;
    isProvider: boolean;
};

enum FormNames {
    priceBase = 'priceBase',
    priceOver50cm = 'priceOver50cm',
    priceNotStackable = 'priceNotStackable',
    unitOfMeasure = 'unitOfMeasure',
    dumpType = 'dumpType',
    comment = 'comment',
    maxAmount = 'maxAmount',
    currentAmount = 'currentAmount',
    reservedAmount = 'reservedAmount',
    allowSolidFA = 'allowSolidFA',
    allowInvasiveSpecies = 'allowInvasiveSpecies',
    invasiveSpecies = 'invasiveSpecies',
    allowTOC = 'allowTOC',
    TOCPercent = 'TOCPercent',
    hasNotStackablePrice = 'hasNotStackablePrice',
    hasOver50Price = 'hasOver50Price',
    isProvider = 'isProvider',
}

export type MaterialFormProps = {
    subarea: LandfillQuery_landfill_subareas | null;
    landfill: { id: string };
    onClose: () => void;
    withOnHold: boolean;
};

export const MaterialForm = ({
    subarea,
    values,
    errors,
    setFieldValue,
    onClose,
}: FormikProps<IFormValues> & MaterialFormProps) => {
    useSyncEffect(
        () =>
            substanceEditStore.init(
                subarea?.substances || [],
                !!subarea?.solidState,
                !!subarea?.leachingState,
                false,
                false
            ),
        [subarea]
    );

    const isProvider = values.isProvider;

    const { root, alignCell } = useStyles();

    const disableContaminationLevels = !!(values.dumpType && !values.dumpType.contaminationLevelAvailable);

    return (
        <DialogContent className={root}>
            <Form>
                <Grid container spacing={2} alignItems={'baseline'}>
                    <Grid item xs={6}>
                        <Text variant="TitleBold">
                            <LocalizableText code={'Materials.description'} />
                        </Text>
                    </Grid>
                    <Grid item xs={6} className={alignCell}>
                        {subarea ? (
                            <Text variant="TitleRegular" color="textSecondary">
                                {isProvider && (
                                    <>
                                        <LocalizableText code={'Materials.isProvider'} /> /{' '}
                                    </>
                                )}
                                <LocalizableText code={'Materials.externalId'} />
                                {`: ${subarea.externalId}`}
                            </Text>
                        ) : (
                            <Checkbox
                                onChange={(v: boolean) => setFieldValue(FormNames.isProvider, v)}
                                checked={values.isProvider}
                                label={<LocalizableText code={'Materials.isProvider'} />}
                            />
                        )}
                    </Grid>
                    <Grid item md={4} xs={8}>
                        <SochiDumpCategoriesSelect
                            label={
                                <LabelWithHint
                                    label={<LocalizableText code={'Materials.materialCategory'} />}
                                    hint={<LocalizableText code={'Materials.materialCategoryFieldHint'} />}
                                />
                            }
                            massCategory={values[FormNames.dumpType]}
                            onChange={d => setFieldValue(FormNames.dumpType, d)}
                            errorMessage={errors.dumpType}
                        />
                    </Grid>
                    <Grid item md={2} xs={4}>
                        <SochiUnitOfMeasureSelect
                            label={i18n.unitOfMeasure}
                            value={values[FormNames.unitOfMeasure]}
                            onChange={(v: string) => setFieldValue(FormNames.unitOfMeasure, v)}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormikContextTextField<IFormValues, FormNames.comment>
                            rowsMax={1}
                            name={FormNames.comment}
                            label={<LocalizableText code={'comment'} />}
                        />
                    </Grid>
                    {!isProvider && (
                        <>
                            <Grid item xs={12}>
                                <Checkbox
                                    disabled={disableContaminationLevels}
                                    onChange={(v: boolean) => setFieldValue(FormNames.allowSolidFA, v)}
                                    checked={values.allowSolidFA && !disableContaminationLevels}
                                    label={
                                        <LabelWithHint
                                            label={<LocalizableText code={'Materials.solidFaAllowed'} />}
                                            hint={
                                                <LocalizableText
                                                    code={
                                                        disableContaminationLevels
                                                            ? 'contaminationLevelsForDumpTypeUnavailable'
                                                            : 'Materials.solidFaAllowedFieldHint'
                                                    }
                                                />
                                            }
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Checkbox
                                    onChange={(v: boolean) => setFieldValue(FormNames.allowInvasiveSpecies, v)}
                                    checked={values.allowInvasiveSpecies}
                                    label={<LocalizableText code={'Materials.allowInvasiveSpecies'} />}
                                />
                            </Grid>
                            {values[FormNames.allowInvasiveSpecies] && (
                                <Grid item xs={12}>
                                    <FormikContextTextField<IFormValues, FormNames.invasiveSpecies>
                                        name={FormNames.invasiveSpecies}
                                        placeholder={i18n.Materials.invasiveSpeciesPlaceHolder}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Checkbox
                                    onChange={(v: boolean) => setFieldValue(FormNames.allowTOC, v)}
                                    checked={values.allowTOC}
                                    label={
                                        <LabelWithHint
                                            label={<LocalizableText code={'Materials.allowTOC'} />}
                                            hint={<LocalizableText code={'Materials.allowTOCDescription'} />}
                                        />
                                    }
                                />
                            </Grid>
                            {values[FormNames.allowTOC] && (
                                <Grid item md={4} xs={12}>
                                    <FormikContextNumberField<IFormValues, FormNames.TOCPercent>
                                        min={MIN_TOC_VALUE}
                                        max={MAX_TOC_VALUE}
                                        name={FormNames.TOCPercent}
                                        precision={commonPrecisions.percent}
                                        nullable
                                        placeholder={i18n.Materials.allowTOCDescription}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Checkbox
                                    onChange={(v: boolean) => setFieldValue(FormNames.hasOver50Price, v)}
                                    checked={values.hasOver50Price}
                                    label={
                                        <LabelWithHint
                                            label={<LocalizableText code={'Materials.hasPriceOver50cm'} />}
                                            hint={<LocalizableText code={'Materials.hasPriceOver50cmHint'} />}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Checkbox
                                    onChange={(v: boolean) => setFieldValue(FormNames.hasNotStackablePrice, v)}
                                    checked={values.hasNotStackablePrice}
                                    label={
                                        <LabelWithHint
                                            label={<LocalizableText code={'Materials.hasNonStackablePrice'} />}
                                            hint={<LocalizableText code={'Materials.hasNonStackablePriceHint'} />}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LabelWithHint
                                    label={
                                        <Text variant="TitleBold">
                                            <LocalizableText code={'contaminationLevels'} />
                                        </Text>
                                    }
                                    hint={
                                        <LocalizableText
                                            code={
                                                disableContaminationLevels
                                                    ? 'contaminationLevelsForDumpTypeUnavailable'
                                                    : 'contaminationLevelsFieldHint'
                                            }
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <EditSubstanceView disableContaminationLevels={disableContaminationLevels} />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <Text variant="TitleBold">
                            <LocalizableText code={'Materials.price'} />
                        </Text>
                    </Grid>

                    <Grid item md={4} xs={12}>
                        <FormikContextNumberField<IFormValues, FormNames.priceBase>
                            name={FormNames.priceBase}
                            label={<LocalizableText code={'Materials.priceBase'} />}
                            placeholder={i18n.Materials.pricePlaceHolder}
                            precision={commonPrecisions.price}
                        />
                        {!isProvider && values[FormNames.hasOver50Price] && (
                            <FormikContextNumberField<IFormValues, FormNames.priceOver50cm>
                                name={FormNames.priceOver50cm}
                                label={<LocalizableText code={'Materials.priceOver50cm'} />}
                                placeholder={i18n.Materials.pricePlaceHolder}
                                precision={commonPrecisions.price}
                            />
                        )}
                        {!isProvider && values[FormNames.hasNotStackablePrice] && (
                            <FormikContextNumberField<IFormValues, FormNames.priceNotStackable>
                                name={FormNames.priceNotStackable}
                                label={<LocalizableText code={'Materials.priceNotStackable'} />}
                                placeholder={i18n.Materials.pricePlaceHolder}
                                precision={commonPrecisions.price}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup>
                            <Button color="primary" variant="outlined" onClick={() => onClose()}>
                                {i18n.cancel}
                            </Button>
                            <Button color="primary" variant="contained" type="submit">
                                {i18n.save}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Form>
        </DialogContent>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '90vw',
        [theme.breakpoints.down('md')]: {
            width: '100vw',
            marginTop: '36px',
        },
    },
    alignCell: {
        textAlign: 'right',
    },
}));
