import { useMutation } from '@apollo/client';

import { CustomScriptAddMutation, CustomScriptAddMutationVariables, CustomScriptInput } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../settings.queries';

export const useCustomScriptAddMutation = () => {
    const [callMutation, mutationResult] = useMutation<CustomScriptAddMutation, CustomScriptAddMutationVariables>(
        queries.CustomScriptAddMutation
    );

    const addCustomScript = ({ text, src, async, order, active }: CustomScriptInput) => {
        const input = { text, src, async, order, active };

        return callMutation({ variables: { input }, refetchQueries: [{ query: queries.CustomScriptsQuery }] }).catch(
            handleApolloError
        );
    };

    return [addCustomScript, mutationResult.loading] as const;
};
