import { Divider as MUIDivider, DividerProps, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

type Props = DividerProps & {
    marginTop?: boolean;
    marginBottom?: boolean;
};

export const Divider = ({ marginTop, marginBottom, className, ...rest }: Props) => {
    const { marginBottomClass, marginTopClass } = useStyles();

    return (
        <MUIDivider
            className={classNames(className, { [marginTopClass]: marginTop, [marginBottomClass]: marginBottom })}
            {...rest}
        />
    );
};

const useStyles = makeStyles(theme => ({
    marginTopClass: {
        marginTop: theme.spacing(4),
    },
    marginBottomClass: {
        marginBottom: theme.spacing(4),
    },
}));
