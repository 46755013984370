import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/substances/substances.queries';
import { SubstanceUpdatePositionMutation, SubstanceUpdatePositionMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import type { ISubstance, ISubstanceChild } from '../substances.types';

export type SubstancesUpdatePositionFunc = (items: Array<ISubstance> | Array<ISubstanceChild>) => Promise<unknown>;

export const useSubstancesUpdatePosition = (): [SubstancesUpdatePositionFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        SubstanceUpdatePositionMutation,
        SubstanceUpdatePositionMutationVariables
    >(queries.SubstanceUpdatePositionMutation);

    const updatePositions: SubstancesUpdatePositionFunc = useCallback(
        substances => {
            const items = substances.map((i, index) => ({ id: i.id, position: index + 1, parentId: i.parentId }));

            return callMutation({ variables: { items } }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [updatePositions, mutationResult.loading];
};
