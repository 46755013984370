import React, { useCallback } from 'react';

import { useNumberFieldState } from '~/@components/@hooks/use-number-field-state';
import { TextField, TextFieldProps } from '~/@components/TextField';
import i18n from '~/i18n';

type INumberFieldBaseProps = {
    precision?: number;
    min?: number;
    max?: number;
    value: number | null;
    forceZero?: boolean;
} & Omit<TextFieldProps, 'onChange'>;

export type INumberFieldProps = INumberFieldBaseProps &
    ({ onChange: (v: number | null) => void; nullable: true } | { onChange: (v: number) => void; nullable?: false });

export function NumberField({
    precision = 0,
    min,
    max,
    value,
    onChange,
    nullable,
    autoFocus,
    onFocus,
    onBlur,
    forceZero,
    ...textFieldProps
}: INumberFieldProps) {
    const { displayValue, handleBlur, handleFocus, handleChangeEventAndGetValue } = useNumberFieldState({
        precision,
        autoFocus,
        value,
        min,
        max,
        onFocus,
        onBlur,
        forceZero,
    });

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        event => {
            const parsedValue = handleChangeEventAndGetValue(event);

            if (event.target.value.trim() === '' && nullable) {
                (onChange as (v: number | null) => void)(null);
            } else if (parsedValue !== undefined) {
                onChange(parsedValue);
            }
        },
        [handleChangeEventAndGetValue, onChange, nullable]
    );

    return (
        <TextField
            fullWidth
            placeholder={forceZero ? '' : i18n.NA}
            value={displayValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoFocus={autoFocus}
            {...textFieldProps}
        />
    );
}
