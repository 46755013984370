import './Loader.scss';

import React from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';

import i18n from '../../../i18n';

type ILoaderProps = {
    message?: string;
    fillForm?: boolean;
};

export const Loader = ({ message = i18n.loading, fillForm }: ILoaderProps) => {
    const { block } = useFunctionalBem(Loader);

    return (
        <div className={block({ 'fill-form': fillForm })}>
            <div className="loading">{message}</div>
        </div>
    );
};
