import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';
import { ContaminationType } from '@common/enums';

import * as queries from '~/@store/substances/substances.queries';
import { SubstancesListQuery, SubstancesListQueryVariables } from '~/graphql';

export const useSubstancesList = (contaminationType?: ContaminationType): QueryResult<SubstancesListQuery> => {
    return useQuery<SubstancesListQuery, SubstancesListQueryVariables>(queries.SubstancesListQuery, {
        variables: {
            contaminationType,
        },
    });
};
