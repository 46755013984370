import { UnitOfMeasure } from '@common/enums';
import { formatAmount } from '@common/utils/formaters';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback } from 'react';

import { Button } from '~/@components/Button';
import { Divider } from '~/@components/Divider';
import { InputIcon, LaunchIcon, SwapHoriz } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { COLOR } from '~/@sochi-components/@theme';
import { useBatchTransferRemove } from '~/@user-store/landfillBatches';
import { IBatch } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm';
import { BatchDateAmount } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/BatchDateAmount';
import { ITransfer } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/landfillBatchLog.types';
import { showLandfillBatchTransferForm } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchTransferForm/LandfillBatchTransferForm';
import { LandfillQuery_landfill, TransferType } from '~/graphql';
import i18n from '~/i18n';
import { showConfirmDialog } from '~/services/dialog';
import { formatDate } from '~/utils/date';

type Props = {
    landfill: LandfillQuery_landfill;
    batch: IBatch;
    item: ITransfer;
    disabled?: boolean;
};

const formatProject = (project: { name: string; address: string }) => `${project.name} - ${project.address}`;

export const BatchTransferCard: FC<Props> = ({ landfill, batch, item, disabled = false }) => {
    const { root, header, card, distributedRow, contentGroup, grayText, contentGroupRightAlign } = useStyles();

    const [remove, isRemoving] = useBatchTransferRemove(landfill.id, batch.id);

    const onRemove = useCallback(async () => {
        const answer = await showConfirmDialog({
            title: <LocalizableText code={'LandfillPage.transferCard.deleteTitle'} />,
            message: <LocalizableText code={'LandfillPage.transferCard.deleteMessage'} />,
        });

        if (answer) {
            await remove(item.id);
        }
    }, [remove, item]);

    const readOnly = isRemoving || disabled;

    return (
        <div className={root}>
            {item.displayRemain && (
                <div className={header}>
                    <BatchDateAmount item={item} />
                </div>
            )}
            <div className={card}>
                <div className={distributedRow}>
                    <div className={contentGroup}>
                        {item.type === TransferType.INCOMING ? (
                            <InputIcon color="disabled" />
                        ) : item.type === TransferType.OUTGOING ? (
                            <LaunchIcon color="disabled" />
                        ) : (
                            <SwapHoriz color="disabled" />
                        )}
                        <Text variant="BodyBold">
                            <LocalizableText code={`LandfillPage.batchLogCard.${item.type}`} />
                        </Text>
                    </div>
                    <div className={contentGroup}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                showLandfillBatchTransferForm(landfill, batch, {
                                    ...item,
                                    date: item.date.toISOString(),
                                })
                            }
                            size="small"
                            disabled={readOnly}>
                            {i18n.edit}
                        </Button>
                        <Button variant="outlined" color="primary" onClick={onRemove} size="small" disabled={readOnly}>
                            {i18n.delete}
                        </Button>
                    </div>
                </div>
                <Grid container spacing={2}>
                    <Grid item lg={3} xs={4}>
                        <Text variant="BodyRegular">
                            <LocalizableText
                                code={`${
                                    item.fromBatch
                                        ? 'LandfillPage.batchLogCard.fromBatch'
                                        : 'LandfillPage.batchLogCard.fromProject'
                                }`}
                            />
                        </Text>
                    </Grid>
                    <Grid item lg={9} xs={8}>
                        {item.fromBatch ? (
                            <Text variant="BodyRegular">{item.fromBatch.name}</Text>
                        ) : (
                            <Text variant="BodyRegular">{formatProject(item.externalProject!)}</Text>
                        )}
                    </Grid>
                    <Grid item lg={3} xs={4}>
                        <Text variant="BodyRegular">
                            <LocalizableText
                                code={`${
                                    item.toBatch
                                        ? 'LandfillPage.batchLogCard.toBatch'
                                        : 'LandfillPage.batchLogCard.toProject'
                                }`}
                            />
                        </Text>
                    </Grid>
                    <Grid item lg={9} xs={8}>
                        {item.toBatch ? (
                            <Text variant="BodyRegular">{item.toBatch.name}</Text>
                        ) : (
                            <Text variant="BodyRegular">{formatProject(item.externalProject!)}</Text>
                        )}
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item lg={3} xs={4}>
                        <Text variant="BodyRegular" className={grayText}>
                            {formatDate(item.date)}
                        </Text>
                    </Grid>
                    <Grid item lg={4} xs={4}>
                        <Text variant="BodyBold">{`${item.signSymbol}${formatAmount(
                            item.weight,
                            UnitOfMeasure.TONNS
                        )}`}</Text>
                    </Grid>
                    <Grid item lg={5} xs={4}>
                        <div className={contentGroupRightAlign}>
                            <Text variant="BodyRegular" className={grayText}>
                                <LocalizableText code={'LandfillPage.batchLogCard.batchAmount'} />
                            </Text>
                            <Text variant="BodyBold">{formatAmount(item.amount, UnitOfMeasure.TONNS)}</Text>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        width: '100%',
    },
    header: {
        display: 'flex',
        justifyContent: 'end',
    },
    card: {
        backgroundColor: COLOR.white,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: '12px',
    },
    distributedRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contentGroup: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'center',
    },
    contentGroupRightAlign: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'end',
    },
    grayText: {
        color: COLOR.grayDarker,
    },
}));
