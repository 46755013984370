import { DespatchAdviceSource } from '@common/enums';
import React, { useMemo } from 'react';

import { showBeastDataDialog } from '~/@components/BeastDataDialog';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { BeastStatus } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    entity: { id: string; beastStatus: BeastStatus | null };
    source: DespatchAdviceSource;
    disabled?: boolean;
};

export const BeastCell = ({ entity, source, disabled = false }: Props) => {
    const actions: Array<ITableCellAction> = useMemo(
        () => [
            {
                variant: ActionVariant.LIST,
                onClick: () => showBeastDataDialog(entity.id, source),
                disabled,
            },
        ],
        [entity, disabled]
    );

    return (
        <TableCellActions actions={actions}>
            {i18n.DeliveryLineBeastStatus[entity.beastStatus || BeastStatus.NOT_SENT]}
        </TableCellActions>
    );
};
