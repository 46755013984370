import './CaptchaField.scss';

import { Field, FieldProps } from 'formik';
import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { getBem } from '~/@sochi-components/@bem';
import { AuthSochiLabel } from '~/@views/AuthView/common/AuthSochiLabel';
import type { WithSettingsProps } from '~/contexts';
import { withSettings } from '~/contexts';
import { lang } from '~/i18n';

type CaptchaFieldProps = {
    name: string;
    label?: string;
    className?: string;
};

class CaptchaField extends React.PureComponent<CaptchaFieldProps & WithSettingsProps> {
    bem = getBem(this);

    render() {
        const {
            name,
            label,
            settings: { captchaSiteKey, captchaEnabled },
        } = this.props;

        if (!captchaEnabled) return null;
        const { className } = this.bem;

        return (
            <Field name={name} key={name}>
                {({ form, meta }: FieldProps) => {
                    const captchaRef = useRef<ReCAPTCHA | null>(null);
                    useEffect(() => {
                        if (!meta.value && captchaRef.current && captchaRef.current.getValue()) {
                            captchaRef.current.reset();
                        }
                    }, [meta.value]);

                    return (
                        <div className={className}>
                            <ReCAPTCHA
                                ref={captchaRef}
                                theme="dark"
                                hl={lang}
                                sitekey={captchaSiteKey}
                                onChange={(token: string | null) => form.setFieldValue(name, token || null)}
                                onExpired={() => form.setFieldValue(name, null)}
                            />
                            <AuthSochiLabel error={meta.touched && !!meta.error}>
                                {(meta.touched && meta.error) || label}
                            </AuthSochiLabel>
                        </div>
                    );
                }}
            </Field>
        );
    }
}

export default withSettings(CaptchaField);
