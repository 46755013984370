import { makeStyles } from '@material-ui/core';
import React, { VFC } from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { LocalizableText } from '~/@components/LocalizableText';
import { StorePagination } from '~/@components/Pagination';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import { IUserLandfillInfo, IUserLandfillsPaged, userLandfillsStore, useUserLandfillsPaged } from '~/@store/users';

import { useUserDetailContext } from '../UserDetailContext';
import { UserLandfillsFilterSummary } from './UserLandfillsFilterSummary';
import { useUserLandfillsColumns } from './useUserLandfillsColumns';

export const UserLandfillsTab: VFC = () => {
    const { table, tableFooter } = useStyles();
    const user = useUserDetailContext();
    useSyncEffect(() => userLandfillsStore.setUserId(user.id), [user.id]);
    const columns = useUserLandfillsColumns(user);
    const query = useUserLandfillsPaged();

    return (
        <QueryStateHandler
            query={query}
            dataGetter={query => query.data?.landfillsPaged || query.previousData?.landfillsPaged}>
            {(data: IUserLandfillsPaged) => (
                <>
                    <Table<IUserLandfillInfo>
                        columns={columns}
                        items={data.edges.map(e => e.node)}
                        keyGetter={l => l.id}
                        emptyListMessage={<LocalizableText code={'UserEditPage.noDataFound'} />}
                        className={table}
                        name="UserLandfillInfoTable"
                    />
                    <div className={tableFooter}>
                        <UserLandfillsFilterSummary />
                        <StorePagination store={userLandfillsStore.pagination} />
                    </div>
                </>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        '& .MuiTableContainer-root': {
            maxHeight: 'calc(100vh - 180px)',
        },
        '& .MuiTable-root': {
            width: '100%',
            minWidth: '100%',
        },
        height: 'calc(100% - 64px)',
    },
    tableFooter: {
        height: 48,
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));
