import React from 'react';

import { FormikContextNumberField } from '~/@components/FormikFields/FormikContextNumberField';
import { LocalizableText } from '~/@components/LocalizableText';
import { commonPrecisions } from '~/config/enum';
import { canChangeLandfillField, canReadLandfillField } from '~/utils/auth';

import { LandfillFields } from '../../../../../../../common/enums';
import { FormNames, LandfillFormValues } from './landfillFormikHOC';
import { useLandfillFormContext } from './LandfillFormWithContext';

export const LandfillPaymentCapacitySection = () => {
    const context = useLandfillFormContext();
    const { user, ability, landfill, readOnly } = context;

    return (
        <>
            {canReadLandfillField(user, ability, landfill, LandfillFields.gateFee) && (
                <FormikContextNumberField<LandfillFormValues, FormNames.gateFee>
                    disabled={readOnly || !canChangeLandfillField(user, ability, landfill, LandfillFields.gateFee)}
                    name={FormNames.gateFee}
                    label={<LocalizableText code={'LandfillForm.gateFee'} />}
                    precision={commonPrecisions.price}
                />
            )}
            {canReadLandfillField(user, ability, landfill, LandfillFields.minMargin) && (
                <FormikContextNumberField<LandfillFormValues, FormNames.minMargin>
                    disabled={readOnly || !canChangeLandfillField(user, ability, landfill, LandfillFields.minMargin)}
                    name={FormNames.minMargin}
                    label={<LocalizableText code={'LandfillForm.minMargin'} />}
                    precision={commonPrecisions.price}
                />
            )}
            {canReadLandfillField(user, ability, landfill, LandfillFields.marginPercent) && (
                <FormikContextNumberField<LandfillFormValues, FormNames.marginPercent>
                    disabled={
                        readOnly || !canChangeLandfillField(user, ability, landfill, LandfillFields.marginPercent)
                    }
                    name={FormNames.marginPercent}
                    label={<LocalizableText code={'LandfillForm.marginPercent'} />}
                    precision={commonPrecisions.percent}
                />
            )}
            {canReadLandfillField(user, ability, landfill, LandfillFields.capacity) && (
                <FormikContextNumberField<LandfillFormValues, FormNames.capacity>
                    disabled={readOnly || !canChangeLandfillField(user, ability, landfill, LandfillFields.capacity)}
                    name={FormNames.capacity}
                    label={<LocalizableText code={'LandfillForm.capacity'} />}
                    precision={commonPrecisions.amount}
                />
            )}
        </>
    );
};
