import React, { memo, VFC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { COLORS } from '~/@components/@theme/colors';
import { CardListItem } from '~/@components/CardListItem';
import { Text } from '~/@components/Text';
import type { IDumpType } from '~/@store/dumpTypes';
import { commonPrecisions } from '~/config/enum';
import i18n from '~/i18n';
import { fmtDecimal } from '~/utils/numbers';

type DumpTypeCardProps = {
    dumpType: IDumpType;
    select: (id: string) => void;
    isActive?: boolean;
    onClick?: () => void;
    reorderingButton: React.ReactNode;
};

export const DumpTypeCard: VFC<DumpTypeCardProps> = memo(({ dumpType, isActive, select, reorderingButton }) => {
    const { content, contentTop, contentDescription, contentCubInTon } = useStyles();

    return (
        <CardListItem buttonEnd={reorderingButton} isActive={isActive} onClick={() => select(dumpType.id)}>
            <div className={content}>
                <div className={contentTop}>
                    <Text variant="BodyRegular" ellipsis>
                        {dumpType.name}
                    </Text>
                    <Text variant="SmallText" color="textSecondary" ellipsis>
                        {`${i18n.DumpTypeCard.id}: ${dumpType.externalId}`}
                    </Text>
                </div>
                <Text className={contentDescription} color="textSecondary" variant="SmallText" ellipsis>
                    {dumpType.description || ''}
                </Text>

                <Text className={contentCubInTon} variant="SmallText">
                    {dumpType.tonnsPerCubicM
                        ? `${i18n.DumpTypeCard.tonnsPerCubicM}: ${fmtDecimal(
                              dumpType.tonnsPerCubicM,
                              commonPrecisions.decimal
                          )}`
                        : null}
                </Text>
            </div>
        </CardListItem>
    );
});

const useStyles = makeStyles(theme => ({
    content: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '22px 14px 14px',
        gap: theme.spacing(0.5),
    },
    contentTop: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    contentDescription: {
        lineHeight: '14px',
    },
    contentCubInTon: {
        color: COLORS.brandLight,
        lineHeight: '14px',
    },
}));
