import React from 'react';

import { EmailFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { SelectList } from '~/@components/SelectList';
import { ILandfill, useLandfillUpdateMutation } from '~/@store/landfills';
import { IUserSearchRecord, useUsersSearchFunc } from '~/@store/users';
import { UserRole } from '~/config/enum';

const renderOption = ({ name }: IUserSearchRecord) => name;
const renderOptionSecondary = ({ email }: IUserSearchRecord) => email;
const renderValueSecondary = ({ email }: IUserSearchRecord) => <EmailFormatter>{email}</EmailFormatter>;

const filter = {
    roles: [UserRole.EXTERNAL_RECEIVER],
};

type Props = {
    landfill: ILandfill;
};

export function LandfillReceiversSelect({ landfill }: Props) {
    const [update, isLoading] = useLandfillUpdateMutation(landfill);
    const userSearch = useUsersSearchFunc(filter);
    const receiversIdsSet = new Set(landfill.receivers?.map(({ id }) => id));
    const loadOptions = (search: string) =>
        userSearch(search).then(results => results.filter(r => !receiversIdsSet.has(r.id)));

    return (
        <SelectList
            onChange={receivers => update({ receiverIds: receivers.map(r => r.id) })}
            selected={landfill.receivers || []}
            loadOptions={loadOptions}
            renderOption={renderOption}
            renderValueSecondary={renderValueSecondary}
            renderOptionSecondary={renderOptionSecondary}
            addLabel={<LocalizableText code={'LandfillReceiversSearch.addContactPerson'} />}
            disabled={isLoading}
            showDivider
        />
    );
}
