import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import {
    AdminDumpLoadsPagedItemQuery as IDumpLoadsPagedItemQuery,
    AdminDumpLoadsPagedItemQueryVariables,
} from '~/graphql';

import { AdminDumpLoadsPagedItemQuery } from '../dumpLoads.queries';

export const usePagedDumpLoadsItem = (
    projectId: string,
    dumpLoadId: string
): QueryResult<IDumpLoadsPagedItemQuery, AdminDumpLoadsPagedItemQueryVariables> => {
    const variables: AdminDumpLoadsPagedItemQueryVariables = { projectId, dumpLoadId };

    return useQuery<IDumpLoadsPagedItemQuery, AdminDumpLoadsPagedItemQueryVariables>(AdminDumpLoadsPagedItemQuery, {
        fetchPolicy: 'cache-first',
        variables,
    });
};
