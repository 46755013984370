import { Button, Checkbox, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { Select } from '~/@components/Select';
import { Text } from '~/@components/Text';
import { COLOR } from '~/@sochi-components/@theme';
import { ContaminationTypeStore, ToxicLevelColorSchemas } from '~/@store/substances';
import { getContaminationLevelLabel } from '~/@store/toxicLimits';
import { ContaminationLevel, ContaminationType } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    store: ContaminationTypeStore;
};

export const CUSTOM = 'CUSTOM';

export const SubstancesEditControls = observer(({ store }: Props) => {
    const { row, spacer, select, control, contaminationLevelCell, disabledText } = useStyles();

    const checked = store.state;
    const level = store.contaminationLevel;
    const isExact = store.isExactLevel;

    const [selectedLevel, setSelectedLevel] = useState<ContaminationLevel | typeof CUSTOM>(
        isExact && level ? level : CUSTOM
    );

    useEffect(() => {
        setSelectedLevel(isExact && level ? level : CUSTOM);
    }, [level, isExact]);

    return (
        <div>
            <div className={row}>
                <Text className={store.readOnly ? disabledText : undefined}>
                    {store.contaminationType === ContaminationType.SOLID ? i18n.solids : i18n.leaching}
                </Text>
                <Text variant="BodyBold" className={store.readOnly ? disabledText : undefined}>
                    {checked ? i18n.on : i18n.off}
                </Text>
                <Checkbox disabled={store.readOnly} checked={checked} onChange={() => store.toggleState()} />
                <div className={spacer} />
                {checked && (
                    <div>
                        <Text variant="TableBold">
                            <LocalizableText code={'contaminationLevel'} />
                        </Text>
                        <div
                            className={contaminationLevelCell}
                            style={level ? ToxicLevelColorSchemas[level] : undefined}>
                            <Text variant="BodyBold">{getContaminationLevelLabel(level, store.contaminationType)}</Text>
                        </div>
                    </div>
                )}
            </div>
            {checked && (
                <>
                    <div className={row}>
                        <Text variant="TableBold">
                            <LocalizableText code={'subarea_associationLevel'} />{' '}
                        </Text>
                    </div>
                    <div className={row}>
                        <Button
                            className={control}
                            variant="outlined"
                            onClick={store.clearAmounts}
                            disabled={store.readOnly}>
                            {i18n.clear}
                        </Button>
                        <Select
                            className={classNames(select, control)}
                            items={store.levelsForFill}
                            keyGetter={i => i}
                            renderValue={v =>
                                v === CUSTOM ? v : getContaminationLevelLabel(v, store.contaminationType)
                            }
                            addEmptyOption={false}
                            value={selectedLevel}
                            onChange={v => setSelectedLevel(v || CUSTOM)}
                            disabled={store.readOnly}
                        />
                        <Button
                            className={control}
                            variant="outlined"
                            onClick={() => selectedLevel !== CUSTOM && store.fillLevel(selectedLevel)}
                            disabled={store.readOnly}>
                            {i18n.fill}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        columnGap: theme.spacing(2),
        alignItems: 'center',
        marginBottom: theme.spacing(3),
    },
    spacer: {
        flexGrow: 1,
    },
    select: {
        flexGrow: 1,
        marginTop: 3,
    },
    control: {
        height: 40,
    },
    contaminationLevelCell: {
        height: 40,
        padding: theme.spacing(1, 2),
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 4,
    },
    disabledText: {
        color: COLOR.grayDark,
    },
}));
