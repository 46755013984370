import React from 'react';

import { AlertDialog, ConfirmDialog } from '~/@components/Dialog';
import type { CommonIconComponent } from '~/@components/Icon';

import { adminDialogStore, Renderer } from './AdminDialogStore';

type AlertDialogConfig = {
    title: React.ReactNode;
    render: () => React.ReactNode;
    icon?: CommonIconComponent;
    closeLabel?: string;
};

export function showAlertDialog({ title, render, icon, closeLabel }: AlertDialogConfig): Promise<boolean> {
    return adminDialogStore.showDialog(closeDialog => (
        <AlertDialog title={title} render={render} icon={icon} closeLabel={closeLabel} onClose={closeDialog} />
    ));
}

type ConfirmDialogConfig = {
    title: React.ReactNode;
    render: () => React.ReactNode;
    icon?: CommonIconComponent;
    okLabel?: React.ReactNode;
    cancelLabel?: React.ReactNode;
};

export function showConfirmDialog({
    title,
    render,
    icon,
    okLabel,
    cancelLabel,
}: ConfirmDialogConfig): Promise<boolean> {
    return adminDialogStore.showDialog(closeDialog => (
        <ConfirmDialog
            title={title}
            render={render}
            icon={icon}
            okLabel={okLabel}
            cancelLabel={cancelLabel}
            onClose={closeDialog}
        />
    ));
}

export type CustomDialogConfig<T> = { render: Renderer<T> };

export const showCustomDialog = <T extends unknown = boolean | void>({ render }: CustomDialogConfig<T>) =>
    adminDialogStore.showDialog(render);
