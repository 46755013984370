import { LinearProgress, makeStyles } from '@material-ui/core';
import { FormikContextType, FormikProvider } from 'formik';
import React from 'react';

import { Section } from '../Section';

type Props<TValues> = {
    formik: FormikContextType<TValues>;
    children: React.ReactNode;
    showProgress?: boolean;
};

export const FormCardContainer = <TValues extends unknown>({ formik, showProgress, children }: Props<TValues>) => {
    const { root } = useStyles();

    return (
        <Section dense>
            <FormikProvider value={formik}>
                {(showProgress || formik.isSubmitting) && <LinearProgress color="primary" />}
                <form onSubmit={formik.handleSubmit} className={root}>
                    {children}
                </form>
            </FormikProvider>
        </Section>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
}));
