import { ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { ILandfillStatus, useLandfillStatusesList } from '~/@store/landfillStatuses';

import { LandfillStatusIcon } from '../../common/LandfillStatusIcon';

type StatusKind = { id: string; name: string };

type Props = {
    valueGetter: () => StatusKind | null | undefined;
    setValue: (v: StatusKind | null) => void;
};

export const LandfillStatusFilter = ({ valueGetter, setValue }: Props) => {
    const statusesQuery = useLandfillStatusesList();

    const { selected } = useStyles();
    const value = useObservable(valueGetter);

    return (
        <QueryStateHandler query={statusesQuery} dataGetter={query => query.data?.landfillStatuses}>
            {(statuses: ILandfillStatus[]) => (
                <>
                    <ListItem button divider onClick={() => setValue(null)}>
                        ---
                    </ListItem>
                    {statuses.map(status => (
                        <ListItem
                            key={status.id}
                            button
                            divider
                            onClick={() => setValue(status)}
                            className={value?.id === status.id ? selected : undefined}>
                            <ListItemIcon>
                                <LandfillStatusIcon status={status} withTooltip={false} />
                            </ListItemIcon>
                            <ListItemText>{status.name}</ListItemText>
                        </ListItem>
                    ))}
                </>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(theme => ({
    selected: {
        backgroundColor: theme.palette.background.default,
    },
}));
