import './SochiMessageDialog.scss';

import React from 'react';

import { Button } from '~/@components/Button';
import { getBem } from '~/@sochi-components/@bem';
import { MessageDialogModel } from '~/stores/models';

import { ButtonGroup } from '../../ButtonGroup';
import { Modal } from '../../Modal';
import { DialogContent } from '../DialogContent';
import { DialogTitle } from '../DialogTitle';
import { SochiCloseDialogButton } from '../SochiCloseDialogButton';

type SochiMessageDialogProps = {
    dialog: MessageDialogModel;
};

export class SochiMessageDialog extends React.Component<SochiMessageDialogProps> {
    bem = getBem(this);

    handleOkClick = () => {
        this.props.dialog.resolver(true);
    };

    handleCancelClick = () => {
        this.props.dialog.resolver(false);
    };

    render() {
        const { className, element } = this.bem;
        const { dialog } = this.props;

        return (
            <Modal>
                <div className={className}>
                    <SochiCloseDialogButton
                        mobileOnly
                        onClose={dialog.buttons.cancel ? this.handleCancelClick : this.handleOkClick}
                    />
                    {dialog.title && <DialogTitle>{dialog.title}</DialogTitle>}

                    <DialogContent>
                        <div className={element('dialog-message')}>{dialog.message}</div>
                        <ButtonGroup>
                            {dialog.buttons.cancel && (
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    size="large"
                                    onClick={this.handleCancelClick}>
                                    {dialog.buttons.cancel}
                                </Button>
                            )}
                            <Button color="primary" variant="contained" size="large" onClick={this.handleOkClick}>
                                {dialog.buttons.ok}
                            </Button>
                        </ButtonGroup>
                    </DialogContent>
                </div>
            </Modal>
        );
    }
}
