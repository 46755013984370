import React from 'react';

import { CheckFormatter } from '~/@components/Formatters';
import { getDumpLoadToxicAnalysisApprovalState, IDumpLoad, ToxicAnalysisApprovalState } from '~/@store/dumpLoads';
import i18n from '~/i18n';

type Props = {
    dumpLoad: IDumpLoad;
};

export const DumpLoadAnalysisApprovalStateCell = ({ dumpLoad }: Props) => {
    const approvalState = getDumpLoadToxicAnalysisApprovalState(dumpLoad);

    if (approvalState === ToxicAnalysisApprovalState.NOT_APPROVED) return <CheckFormatter>{false}</CheckFormatter>;

    return <>{i18n.DumpLoadsTable.analysisApprovalState[approvalState]}</>;
};
