import { ContaminationType } from '@common/enums';

import { substanceEditStore } from '~/@store/substances';
import { ProjectLoadInput } from '~/graphql';

import { IDumpLoad, IFormValues, IProject } from './AddEditMass.types';

const getBaseInput = (values: IFormValues, project: IProject, dumpLoad?: IDumpLoad): ProjectLoadInput => {
    const { dumpType, amount, unitOfMeasure, comment } = values;

    const input: ProjectLoadInput = {
        dumpTypeId: dumpType?.id || '',
        amount,
        unitOfMeasure,
        comment,
        ver: project.ver,
    };

    if (dumpLoad) input.id = dumpLoad.id;

    return input;
};

export const getInboundDumpLoadInput = (
    values: IFormValues,
    project: IProject,
    dumpLoad?: IDumpLoad
): ProjectLoadInput => {
    const input = getBaseInput(values, project, dumpLoad);

    input.skipMatching = false;
    input.inbound = true;

    return input;
};

export const getInboundWithoutMatchingDumpLoadInput = (
    values: IFormValues,
    project: IProject,
    dumpLoad?: IDumpLoad
): ProjectLoadInput => {
    const input = getBaseInput(values, project, dumpLoad);

    input.suggestedLandfillName = values.landfillName;
    input.suggestedLandfillAddress = values.landfillAddress;

    input.skipMatching = true;
    input.inbound = true;

    return input;
};

export const getOutboundWithoutMatchingDumpLoadInput = (
    values: IFormValues,
    project: IProject,
    dumpLoad?: IDumpLoad
): ProjectLoadInput => {
    const input = getBaseInput(values, project, dumpLoad);

    input.solidState = !!substanceEditStore.SOLID?.state;
    input.leachingState = false;
    input.substances = substanceEditStore
        .serializeAmounts()
        .filter(a => a.contaminationType === ContaminationType.SOLID);

    input.suggestedLandfillName = values.landfillName;
    input.suggestedLandfillAddress = values.landfillAddress;

    input.skipMatching = true;
    input.inbound = false;

    return input;
};

export const getOutboundDumpLoadInput = (
    values: IFormValues,
    project: IProject,
    dumpLoad?: IDumpLoad
): ProjectLoadInput => {
    const { stackability, over50cm, withTOC, withInvasiveSpecies, dates } = values;

    const { startDate, endDate } = dates;

    const input = getBaseInput(values, project, dumpLoad);

    input.solidState = !!substanceEditStore.SOLID?.state;
    input.leachingState = !!substanceEditStore.LEACHING?.state;
    input.substances = substanceEditStore.serializeAmounts();

    input.date = startDate?.toISOString();
    input.endDate = endDate?.toISOString();
    input.stackability = stackability;
    input.over50cm = over50cm;
    input.withTOC = withTOC;
    input.TOCPercent = values.TOCPercent;
    input.withInvasiveSpecies = withInvasiveSpecies;
    input.invasiveSpecies = values.invasiveSpecies;

    input.skipMatching = false;
    input.inbound = false;

    return input;
};
