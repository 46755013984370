import './LandfillOverview.scss';

import { DumpLoadStatus } from '@common/enums';
import React, { FC, useMemo, useState } from 'react';

import { ButtonGroupSwitch } from '~/@components/ButtonGroupSwitch';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import type { MassInfo } from '~/@sochi-components/cards/MassCard/MassCard';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { LandfillQuery_landfill_ordersInfo } from '~/graphql';
import i18n from '~/i18n';

import type { LandfillDetailsDataProps } from '../LandfillPage';
import { Masses } from './Masses';

type Status = DumpLoadStatus.IN_PROGRESS | DumpLoadStatus.DONE;

const options: Array<{
    label: string;
    value: Status;
}> = [
    {
        label: i18n.DumploadStatusesSochi.IN_PROGRESS,
        value: DumpLoadStatus.IN_PROGRESS,
    },
    {
        label: i18n.DumploadStatusesSochi.DONE,
        value: DumpLoadStatus.DONE,
    },
];

export const LandfillOverview: FC<LandfillDetailsDataProps> = ({ landfill }) => {
    const { className, element } = useFunctionalBem(LandfillOverview);

    const [status, setStatus] = useState<Status>(DumpLoadStatus.IN_PROGRESS);

    const dumpLoads = useMemo(
        () =>
            landfill.ordersInfo
                .filter(o => o.dumpLoad.status === status)
                .map((order: LandfillQuery_landfill_ordersInfo) => {
                    const mass: MassInfo = {
                        ...order.dumpLoad,
                        deliveredVolume: order.deliveredVolume,
                    };

                    return mass;
                }) || [],
        [landfill, status]
    );

    return (
        <div className={className}>
            <div className={element('header')}>
                <SochiTitle title={<LocalizableText code={'masses'} />} />
                <ButtonGroupSwitch options={options} value={status} onChange={v => setStatus(v)} color="secondary" />
            </div>
            <Masses landfill={landfill} masses={dumpLoads} />
        </div>
    );
};
