import React, { FC } from 'react';
import { TooltipProps } from 'recharts';

import { makeStyles } from '~/@components/@theme';
import { COLOR, FONT_WEIGHT } from '~/@sochi-components/@theme';

type Props = {
    labelFormatter?: (value: string | undefined | null) => string;
    valueFormatter?: (value: number | undefined | null) => string;
} & TooltipProps<number, string>;

export const DashboardToolTip: FC<Props> = ({ active, payload, labelFormatter = v => v, valueFormatter = v => v }) => {
    const { root, label } = useStyles();

    if (!active || !payload || payload.length === 0) return null;

    const firstPayload = payload[0]!;

    return (
        <div className={root}>
            <div className={label}>{firstPayload.payload.name}</div>
            <div className={label}>{labelFormatter(firstPayload.dataKey?.toString())}</div>
            <div>{valueFormatter(firstPayload.value)}</div>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        background: COLOR.grayBackground,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        alignItems: 'center',
    },
    label: {
        fontWeight: FONT_WEIGHT.bold,
    },
});
