import { ApolloClient } from '@apollo/client';
import { IDeviationStatus } from '@common/enums';

import { DeviationFragment } from '~/graphql';
import { ImageForUpload } from '~/utils/ImageForUpload';

import type { IFileDoc } from '../common';

export type { IFileDoc } from '../common';

export type IDeviation = DeviationFragment;

export enum FormActions {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    COMMENT = 'COMMENT',
    CLOSE = 'CLOSE',
    NOT_SET = 'NOT_SET',
}

export interface IFormValues {
    issue: string;
    description: string;
    suggestedSolution: string;
    newComment: string;
    status: IDeviationStatus;
    action: FormActions;
    deviation: IDeviation | undefined | null;
    commentSubmitted: boolean;
    newFiles: ImageForUpload[];
}

export enum FormNames {
    issue = 'issue',
    description = 'description',
    suggestedSolution = 'suggestedSolution',
    status = 'status',
    newComment = 'newComment',
    newFiles = 'newFiles',
    action = 'action',
    deviation = 'deviation',
    commentSubmitted = 'commentSubmitted',
}

export type DeviationMutations = {
    createDeviation: (client: ApolloClient<unknown>, values: IFormValues, entityId: string) => Promise<IDeviation>;
    updateDeviation: (client: ApolloClient<unknown>, values: IFormValues, deviation: IDeviation) => Promise<IDeviation>;
    commentDeviation: (
        client: ApolloClient<unknown>,
        values: IFormValues,
        deviation: IDeviation
    ) => Promise<IDeviation>;
    closeDeviation: (client: ApolloClient<unknown>, values: IFormValues, deviation: IDeviation) => Promise<IDeviation>;
    removeFile: (client: ApolloClient<unknown>, deviation: IDeviation, file: IFileDoc) => Promise<IDeviation>;
};

export type DeviationAbilities = {
    canChange: boolean;
    canClose: boolean;
    canComment: boolean;
};
