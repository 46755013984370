import { CheckboxProps } from '@material-ui/core';
import { Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';
import React from 'react';

import { Checkbox } from '~/@components/Checkbox';

type Props = {
    name: string;
} & Omit<CheckboxProps, 'onChange' | 'name' | 'checked'>;

export const FormikTypeUnsafeBooleanField = ({ name, disabled, ...restProps }: Props) => {
    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => {
                return (
                    <Checkbox
                        checked={field.value}
                        onChange={v => {
                            form.setFieldTouched(field.name, true);
                            form.setFieldValue(field.name, v);
                        }}
                        disabled={disabled || form.isSubmitting}
                        {...restProps}
                    />
                );
            }}
        </Field>
    );
};
