import { MAX_TOC_VALUE, MIN_TOC_VALUE } from '@common/validations/common.validation';
import { Form } from 'formik';
import React, { useEffect } from 'react';

import {
    FormikBooleanField,
    FormikBooleanSwitchField,
    FormikNumberField,
    FormikTextField,
} from '~/@components/FormikFields';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import { substanceEditStore } from '~/@store/substances/SubstancesEdit.store';
import { formikHOC } from '~/@views/UserView/LandfillPage/LandfillMaterials/MaterialForm/MaterialFormik';
import i18n from '~/i18n';

import { ILandfill, ISubarea, ISubareaFormik } from './SubareaEdit.types';
import { SubareaEditActions } from './SubareaEditActions';
import { SubareaDumpTypeSelect, SubareaSubstancesEditButton, SubareaUnitSelect } from './SubareaEditFields';

type Props = {
    subarea: ISubarea | null;
    landfill: ILandfill;
    onClose: () => void;
    withOnHold: boolean;
} & ISubareaFormik;

const SubareaEditForm = ({ subarea, landfill, onClose, withOnHold, ...formik }: Props) => {
    useEffect(
        () =>
            substanceEditStore.init(
                subarea?.substances || [],
                !!subarea?.solidState,
                !!subarea?.leachingState,
                false,
                false
            ),
        [subarea]
    );

    const disableContaminationLevels = !formik.values.dumpType?.contaminationLevelAvailable;

    return (
        <Form>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={4}>
                    <SubareaDumpTypeSelect formik={formik} />
                </Grid>
                <Grid item xs={4}>
                    <SubareaUnitSelect formik={formik} />
                </Grid>
                <Grid item xs={4} container justify="flex-end">
                    {withOnHold && (
                        <FormikBooleanSwitchField
                            formik={formik}
                            name="onHold"
                            commonLabel={i18n.status}
                            checkedLabel={
                                <LocalizableText
                                    code={formik.values.isProvider ? 'Materials.outgoing' : 'Materials.incoming'}
                                />
                            }
                            uncheckedLabel={<LocalizableText code={'Materials.onHold'} />}
                            inverted
                        />
                    )}
                    {!subarea && (
                        <FormikBooleanSwitchField
                            formik={formik}
                            name="isProvider"
                            commonLabel={i18n.Materials.isProvider}
                            checkedLabel={i18n.yes}
                            uncheckedLabel={i18n.no}
                        />
                    )}
                </Grid>

                {!formik.values.isProvider && (
                    <>
                        <Grid item xs={4}>
                            <FormikBooleanField
                                formik={formik}
                                name="allowInvasiveSpecies"
                                label={<LocalizableText code={'Materials.allowInvasiveSpecies'} />}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormikTextField
                                formik={formik}
                                name="invasiveSpecies"
                                label={<LocalizableText code={'Materials.invasiveSpecies'} />}
                                disabled={!formik.values.allowInvasiveSpecies}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormikBooleanField
                                formik={formik}
                                name="allowTOC"
                                label={<LocalizableText code={'Materials.allowTOC'} />}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormikNumberField
                                formik={formik}
                                name="TOCPercent"
                                label={<LocalizableText code={'Materials.TOCValue'} />}
                                disabled={!formik.values.allowTOC}
                                min={MIN_TOC_VALUE}
                                max={MAX_TOC_VALUE}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormikBooleanField
                                formik={formik}
                                name="allowSolidFA"
                                label={<LocalizableText code={'Materials.solidFaAllowed'} />}
                                disabled={disableContaminationLevels}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SubareaSubstancesEditButton disableContaminationLevels={disableContaminationLevels} />
                        </Grid>
                    </>
                )}
                <Grid item xs={4}>
                    <FormikNumberField
                        formik={formik}
                        name="priceBase"
                        label={<LocalizableText code={'Materials.priceBase'} />}
                    />
                </Grid>
                {!formik.values.isProvider && (
                    <>
                        <Grid item xs={4}>
                            <FormikNumberField
                                formik={formik}
                                name="priceOver50cm"
                                label={<LocalizableText code={'Materials.priceOver50cm'} />}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormikNumberField
                                formik={formik}
                                name="priceNotStackable"
                                label={<LocalizableText code={'Materials.priceNotStackable'} />}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <FormikTextField formik={formik} name="comment" multiline label={i18n.comment} />
                </Grid>
            </Grid>
            <SubareaEditActions formik={formik} subarea={subarea} onClose={onClose} />
        </Form>
    );
};

export default formikHOC(false)(SubareaEditForm);
