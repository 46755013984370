import { observer } from 'mobx-react';
import React, { Fragment } from 'react';

import { ContaminationTypeStore, isSubstanceParentWithChildren } from '~/@store/substances';
import { SubstanceInput } from '~/@views/AdminView/common/SubstancesEditDialog/SubstanceInput';

type Props = {
    store: ContaminationTypeStore;
};

export const SubstancesEditInputsList = observer(({ store }: Props) => {
    const substances = store.substances;
    const state = store.state;

    if (!state) return null;

    return (
        <div>
            {substances.map(s => (
                <Fragment key={s.id}>
                    <SubstanceInput store={store} substance={s} readOnly={store.readOnly} />
                    {isSubstanceParentWithChildren(s) &&
                        s.children!.map(sc => (
                            <SubstanceInput key={sc.id} store={store} substance={sc} readOnly={store.readOnly} />
                        ))}
                </Fragment>
            ))}
        </div>
    );
});
