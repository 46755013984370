import { AbilityCan, AbilitySubjects, UserRole } from '../../enums';
import { IAbilityFunction } from '../abilities.types';
import { ICaslCondition } from '../utils';
import { CompanySubject, ICompanyCalculatedSubject } from './companyTypes';

const { CRUD, READ, CREATE } = AbilityCan;
const { ADMIN, EMPLOYEE, EXTERNAL_RECEIVER, DRIVER } = UserRole;
const { ALL_FIELDS_EXCEPT_NAME, LIST, CONNECTED_ENTITIES, TRANSPORT_COMPANY } = CompanySubject;

export function defineAbilitiesForCompanies(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<ICompanyCalculatedSubject>;
    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) => _allow(can, AbilitySubjects.COMPANY, shape);

    const forbid = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _forbid(can, AbilitySubjects.COMPANY, shape);

    allow(CRUD, { relationRole: ADMIN });
    allow(CRUD, { relationRole: EMPLOYEE });
    allow(READ, { relationRole: EXTERNAL_RECEIVER });
    allow(READ, { relationRole: DRIVER });
    allow(CREATE, { relationRole: DRIVER, subSubject: TRANSPORT_COMPANY });

    forbid(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: LIST });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: ALL_FIELDS_EXCEPT_NAME });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: CONNECTED_ENTITIES });
    forbid(READ, { relationRole: DRIVER, subSubject: ALL_FIELDS_EXCEPT_NAME });
    forbid(READ, { relationRole: DRIVER, subSubject: CONNECTED_ENTITIES });
}
