import './ProjectDumpLoadDeclaration.scss';

import type { FetchResult } from '@apollo/client';
import { DataValue, withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { DeclarationConsistenceFields, DeclarationFields, DeclarationRisksFields, FileFormats } from '@common/enums';
import axios from 'axios';
import { filter } from 'graphql-anywhere';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import React, { Component, ComponentType } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import * as queries from '~/@store/dumpLoads/dumpLoads.queries';
import * as fragments from '~/@store/dumpLoads/dumpLoads.queries.fragment';
import { uploadDeclarationUri } from '~/apolloClient';
import { AbilityUserContext, IAbilityUserContext } from '~/contexts';
import {
    AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node,
    AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_dumpType,
    AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_project,
    DumpLoadDeclarationQuery,
    DumpLoadDeclarationQuery_projectLoadDeclaration as IDeclaration,
    DumpLoadDeclarationQuery_projectLoadDeclaration_consistence as IDeclarationConsistence,
    DumpLoadDeclarationQuery_projectLoadDeclaration_risks as IDeclarationRisks,
    DumpLoadDeclarationQueryVariables,
    DumpLoadDeclarationUpdateMutation,
    DumpLoadDeclarationUpdateMutationVariables,
    ProjectQuery_project,
    ProjectQuery_project_dumpLoads,
    ProjectQuery_project_dumpLoads_dumpType,
} from '~/graphql';
import i18n from '~/i18n';
import { handleApolloError } from '~/utils';
import { canReadProject, canUpdateDumploadDeclaration } from '~/utils/auth';
import { getAxiosConfig, handleAxiosError } from '~/utils/axios';
import { saveToClient } from '~/utils/files';

import asterisk from './assets/asterisk.png';
import { ProjectDumpLoadDeclarationCheckbox } from './ProjectDumpLoadDeclarationCheckbox';
import { ProjectDumpLoadDeclarationInput } from './ProjectDumpLoadDeclarationInput';

export type IInputForDeclarationProps = {
    className?: string;
    value?: string | null;
    name?: string;
    onChange: (v: string) => void;
    readOnly?: boolean;
};

export type ICheckboxForDeclarationProps = {
    className?: string;
    checked?: boolean | null;
    onChangeState: (v: boolean) => void;
    label?: React.ReactNode;
    disabled?: boolean;
};

type IProject = ProjectQuery_project | AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_project;
type IDumpLoad = ProjectQuery_project_dumpLoads | AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node;
type IDumpType = ProjectQuery_project_dumpLoads_dumpType | AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node_dumpType;

type PickByValueType<T, ValueType> = Pick<T, { [Key in keyof T]-?: T[Key] extends ValueType ? Key : never }[keyof T]>;

type IDeclarationData =
    | DumpLoadDeclarationQuery
    | DataValue<DumpLoadDeclarationQuery, DumpLoadDeclarationQueryVariables>;

type DeclarationFormFieldsWithoutNestedObjects = Pick<
    IDeclaration,
    Exclude<DeclarationFields, 'consistence' | 'risks'>
>;

type DeclarationFieldsNamesByType<T = string | boolean> = keyof PickByValueType<
    DeclarationFormFieldsWithoutNestedObjects,
    T | null
>;

type ProjectDumpLoadDeclarationProps = WithApolloClient<{
    onClose: () => void;
    onLoad?: (v: boolean) => void;
    InputComp?: ComponentType<IInputForDeclarationProps>;
    CheckBoxComp?: ComponentType<ICheckboxForDeclarationProps>;
    customRef?: (v: ProjectDumpLoadDeclaration) => void;
    customSaveApprove: (onSuccess: () => void, onReject: () => void) => unknown;
    data: IDeclarationData;
    project: IProject;
    dumpload: IDumpLoad;
    projectId: string;
    projectLoadId: string;
    dumpType: IDumpType | null;
    isEmptyDeclaration?: boolean;
}>;

type ProjectDumpLoadDeclarationState = {
    consistence: Partial<IDeclarationConsistence>;
    risks: Partial<IDeclarationRisks>;
    loading: boolean;
    approveSave: (() => void) | null;
} & Partial<DeclarationFormFieldsWithoutNestedObjects>;

const declarationFieldNames = Object.entries(DeclarationFields).map(([, value]) => value);

export class ProjectDumpLoadDeclaration extends Component<
    ProjectDumpLoadDeclarationProps,
    ProjectDumpLoadDeclarationState
> {
    static contextType = AbilityUserContext;
    context!: IAbilityUserContext;
    canIUpdate: boolean;

    constructor(props: ProjectDumpLoadDeclarationProps, context: IAbilityUserContext) {
        super(props, context);
        this.canIUpdate = canUpdateDumploadDeclaration(context, props.project, props.dumpload);
    }

    static defaultProps = {
        onClose: () => {},
        InputComp: ProjectDumpLoadDeclarationInput,
        CheckBoxComp: ProjectDumpLoadDeclarationCheckbox,
        isEmptyDeclaration: false,
    };

    state = {
        consistence: {},
        risks: {},
        loading: false,
        approveSave: null,
    } as ProjectDumpLoadDeclarationState;

    bem = getBem(this);

    componentDidMount() {
        const { customRef } = this.props;
        customRef !== undefined && customRef(this);
        this.extractProjectLoadDeclaration(this.props);
    }

    componentWillReceiveProps(nextProps: ProjectDumpLoadDeclarationProps) {
        if (!isEqual(nextProps.data?.projectLoadDeclaration, this.props.data?.projectLoadDeclaration)) {
            this.extractProjectLoadDeclaration(nextProps);
        }
    }

    extractProjectLoadDeclaration = (newProps: ProjectDumpLoadDeclarationProps) => {
        const { data } = newProps;

        if (data?.projectLoadDeclaration) {
            this.setState({
                ...pick(data.projectLoadDeclaration, declarationFieldNames),
                consistence: data.projectLoadDeclaration.consistence || {},
                risks: data.projectLoadDeclaration.risks || {},
            });
        }
    };

    handleClose = (shouldSave = false) => {
        if (shouldSave && this.hasProjectChanged()) this.handleSave(this.props.onClose);
        else this.props.onClose();
    };

    handleSave = (callback?: () => void) => {
        const { project, client, dumpload } = this.props;

        if (this.canIUpdate && this.hasProjectChanged()) {
            this.toggleLoading(true);
            const payload = filter(fragments.DumpLoadDeclarationFragment, this.state);
            client
                ?.mutate<DumpLoadDeclarationUpdateMutation, DumpLoadDeclarationUpdateMutationVariables>({
                    mutation: queries.DumpLoadDeclarationUpdateMutation,
                    variables: {
                        projectId: project.id,
                        projectLoadId: dumpload.id,
                        input: payload,
                    },
                    update: (cache, { data }: FetchResult<DumpLoadDeclarationUpdateMutation>) => {
                        const projectLoadDeclaration = data?.projectLoadDeclarationUpdate;

                        cache.writeQuery({
                            query: queries.DumpLoadDeclarationQuery,
                            variables: {
                                projectId: project.id,
                                projectLoadId: dumpload.id,
                            },
                            data: {
                                projectLoadDeclaration,
                            },
                        });
                        this.toggleLoading(false);
                        callback?.();
                    },
                })
                .catch(err => {
                    this.toggleLoading(false);
                    handleApolloError(err);
                });
        } else {
            if (callback) callback();
        }
    };

    toggleLoading = (loading: boolean) => {
        this.setState({ loading }, () => {
            const { onLoad } = this.props;
            if (onLoad !== undefined) {
                onLoad(loading);
            }
        });
    };

    hasProjectChanged = () => {
        const { data } = this.props;
        const oldPayload = filter(fragments.DumpLoadDeclarationFragment, data.projectLoadDeclaration);

        const payload = filter(fragments.DumpLoadDeclarationFragment, this.state);

        return !isEqual(oldPayload, payload);
    };

    saveAndDownloadDocument = () => {
        this.state.approveSave?.();
    };

    checkDeclarationAndDownloadDocument = (format: FileFormats) => {
        if (this.hasProjectChanged()) {
            const { customSaveApprove } = this.props;
            const approveCallback = () => this.handleSave(() => this.handleDeclarationDownload(format));

            customSaveApprove(approveCallback, () => this.setState({ approveSave: null }));
        } else this.handleDeclarationDownload(format);
    };

    handleDeclarationDownload = (format: FileFormats) => {
        const { project, dumpload } = this.props;

        if (canReadProject(this.context, project)) {
            const data = {
                projectId: project.id,
                projectLoadId: dumpload.id,
                format,
            };

            const config = getAxiosConfig(data);

            this.toggleLoading(true);
            axios
                .get(uploadDeclarationUri, config)
                .then(result => {
                    saveToClient(result.data, 'declaration.' + format);
                    this.setState({ approveSave: null });
                })
                .catch(err => {
                    this.setState({ approveSave: null });
                    handleAxiosError(err);
                })
                .finally(() => {
                    this.toggleLoading(false);
                });
        }
    };

    renderInput = (
        field: DeclarationFieldsNamesByType<string>,
        label: React.ReactNode = '',
        required: boolean = false,
        className = ''
    ) => {
        const { isEmptyDeclaration, InputComp } = this.props;
        const { element } = this.bem;

        if (!InputComp) return;

        const value = this.state[field];
        const inputClassName = !value && isEmptyDeclaration && required ? element('emptyInput') : '';

        return (
            <div className={className}>
                <label className={element('input-label')}>
                    {label}
                    {required ? <img className={element('asterisk')} src={asterisk} alt="*" /> : ''}
                </label>
                <InputComp
                    value={value}
                    className={inputClassName}
                    readOnly={!this.canIUpdate}
                    onChange={(value: string) =>
                        this.setState<DeclarationFieldsNamesByType<string>>({ [field]: value })
                    }
                    name={field}
                />
            </div>
        );
    };

    renderYesNoCheckbox = (field: DeclarationFieldsNamesByType<boolean>, description: React.ReactNode = '') => {
        const { CheckBoxComp } = this.props;
        const { element } = this.bem;

        if (!CheckBoxComp) return;

        const readOnly = !this.canIUpdate;

        return (
            <div className={element('yes-no-checkbox')}>
                <div className={element('yes-no-checkbox-description')}>{description}</div>
                <CheckBoxComp
                    disabled={readOnly}
                    checked={this.state[field]}
                    label={i18n.yes}
                    onChangeState={() => {
                        this.setState<DeclarationFieldsNamesByType<boolean>>({ [field]: true });
                    }}
                />
                <CheckBoxComp
                    disabled={readOnly}
                    checked={!this.state[field]}
                    label={i18n.no}
                    onChangeState={() => {
                        this.setState<DeclarationFieldsNamesByType<boolean>>({ [field]: false });
                    }}
                />
            </div>
        );
    };

    render() {
        const { InputComp, CheckBoxComp } = this.props;

        const { className, element } = this.bem;

        const readOnly = !this.canIUpdate;

        if (!InputComp || !CheckBoxComp) return;

        return (
            <div className={className}>
                <div className={element('content')}>
                    <div className={element('declaration-wrapper')}>
                        <div className={element('declaration-title')}>
                            <LocalizableText code={'DeclarationOfWaste.title'} />
                        </div>
                        <div className={element('declaration-number')}>
                            <LocalizableText code={'DeclarationOfWaste.serialNumber'} />:
                            <img className={element('asterisk')} src={asterisk} alt="*" />
                            <InputComp
                                readOnly
                                value={this.state[DeclarationFields.serialNumber]}
                                onChange={(serialNumber: string) =>
                                    this.setState({ [DeclarationFields.serialNumber]: serialNumber })
                                }
                            />
                        </div>
                        <div className={element('producer')}>
                            {this.renderInput(
                                DeclarationFields.producer,
                                <LocalizableText code={'DeclarationOfWaste.wasteProducer'} />,
                                true,
                                element('producer-name')
                            )}

                            {this.renderInput(
                                DeclarationFields.customerNr,
                                <LocalizableText code={'DeclarationOfWaste.customerNumber'} />,
                                true,
                                element('producer-number')
                            )}

                            {this.renderInput(
                                DeclarationFields.adressFrom,
                                <LocalizableText code={'DeclarationOfWaste.address'} />,
                                true,
                                element('producer-address')
                            )}

                            {this.renderInput(
                                DeclarationFields.postNrFrom,
                                <LocalizableText code={'DeclarationOfWaste.zipCode'} />,
                                true,
                                element('producer-zip')
                            )}

                            {this.renderInput(
                                DeclarationFields.placeFrom,
                                <LocalizableText code={'DeclarationOfWaste.place'} />,
                                true,
                                element('producer-place')
                            )}

                            {this.renderInput(
                                DeclarationFields.referenceFrom,
                                <LocalizableText code={'DeclarationOfWaste.reference'} />,
                                true,
                                element('producer-reference')
                            )}

                            {this.renderInput(
                                DeclarationFields.telFrom,
                                <LocalizableText code={'DeclarationOfWaste.phone'} />,
                                true,
                                element('producer-phone')
                            )}

                            {this.renderInput(
                                DeclarationFields.organizationNrFrom,
                                <LocalizableText code={'DeclarationOfWaste.organizationNr'} />,
                                true,
                                element('producer-organization-number')
                            )}
                        </div>
                        <div className={element('receiver')}>
                            {this.renderInput(
                                DeclarationFields.invoice,
                                <LocalizableText code={'DeclarationOfWaste.invoice'} />,
                                true,
                                element('receiver-invoice')
                            )}

                            {this.renderInput(
                                DeclarationFields.adressTo,
                                <LocalizableText code={'DeclarationOfWaste.address'} />,
                                true,
                                element('receiver-address')
                            )}

                            {this.renderInput(
                                DeclarationFields.postNrTo,
                                <LocalizableText code={'DeclarationOfWaste.zipCode'} />,
                                true,
                                element('receiver-zip')
                            )}

                            {this.renderInput(
                                DeclarationFields.placeTo,
                                <LocalizableText code={'DeclarationOfWaste.place'} />,
                                true,
                                element('receiver-place')
                            )}

                            {this.renderInput(
                                DeclarationFields.referenceTo,
                                <LocalizableText code={'DeclarationOfWaste.reference'} />,
                                true,
                                element('receiver-reference')
                            )}

                            {this.renderInput(
                                DeclarationFields.telTo,
                                <LocalizableText code={'DeclarationOfWaste.phone'} />,
                                true,
                                element('receiver-phone')
                            )}

                            {this.renderInput(
                                DeclarationFields.organizationNrTo,
                                <LocalizableText code={'DeclarationOfWaste.organizationNr'} />,
                                true,
                                element('receiver-organization-number')
                            )}
                        </div>
                        <div className={element('main-info')}>
                            {this.renderInput(
                                DeclarationFields.ewcCode,
                                <LocalizableText code={'DeclarationOfWaste.ewcCode'} />,
                                true,
                                element('ewc-code')
                            )}
                            {this.renderInput(
                                DeclarationFields.wasteType,
                                <LocalizableText code={'DeclarationOfWaste.wasteType'} />,
                                true,
                                element('waste-type')
                            )}
                            {this.renderInput(
                                DeclarationFields.pollutionType,
                                <LocalizableText code={'DeclarationOfWaste.massType'} />,
                                false,
                                element('mass-type')
                            )}
                            <div className={element('waste-wrapper')}>
                                {this.renderInput(
                                    DeclarationFields.wasteOriginate,
                                    <LocalizableText code={'DeclarationOfWaste.wasteOriginate'} />,
                                    false,
                                    element('waste-originate')
                                )}
                                {this.renderInput(
                                    DeclarationFields.wasteOriginateMunicipality,
                                    <LocalizableText code={'DeclarationOfWaste.wasteOriginateMunicipality'} />,
                                    false,
                                    element('waste-municipality')
                                )}
                            </div>

                            {this.renderYesNoCheckbox(
                                DeclarationFields.hasMassEffluentRecord,
                                <LocalizableText code={'DeclarationOfWaste.hasMassEffluentRecord'} />
                            )}

                            {this.renderYesNoCheckbox(
                                DeclarationFields.hasContaminatedSoilGroundWater,
                                <LocalizableText code={'DeclarationOfWaste.hasContaminatedSoilGroundWater'} />
                            )}

                            {this.renderYesNoCheckbox(
                                DeclarationFields.hasDifferentSmellAndColor,
                                <LocalizableText code={'DeclarationOfWaste.hasDifferentSmellAndColor'} />
                            )}

                            {this.renderYesNoCheckbox(
                                DeclarationFields.hasOccuredByExcavationOfPath,
                                <LocalizableText code={'DeclarationOfWaste.hasOccuredByExcavationOfPath'} />
                            )}

                            {this.renderYesNoCheckbox(
                                DeclarationFields.hasPetroleumProductBeenOnSite,
                                <LocalizableText code={'DeclarationOfWaste.hasPetroleumProductBeenOnSite'} />
                            )}

                            {this.renderYesNoCheckbox(
                                DeclarationFields.hasAnyAccidentsOccuredOnProperty,
                                <LocalizableText code={'DeclarationOfWaste.hasAnyAccidentsOccuredOnProperty'} />
                            )}
                        </div>
                        <div className={element('assessments')}>
                            <div className={element('assessments-description')}>
                                {
                                    <LocalizableText
                                        code={'DeclarationOfWaste.environmentalTechnicalOrOtherAssessments'}
                                    />
                                }
                            </div>

                            <CheckBoxComp
                                disabled={readOnly}
                                className={element('assessments-checkbox')}
                                checked={this.state[DeclarationFields.hasEnvironmentalTechnicalAssessment]}
                                label={
                                    <LocalizableText code={'DeclarationOfWaste.hasEnvironmentalTechnicalAssessment'} />
                                }
                                onChangeState={(isChecked: boolean) => {
                                    this.setState({
                                        [DeclarationFields.hasEnvironmentalTechnicalAssessment]: isChecked,
                                    });
                                }}
                            />

                            <CheckBoxComp
                                disabled={readOnly}
                                className={element('assessments-checkbox')}
                                checked={this.state[DeclarationFields.hasOtherAssessment]}
                                label={<LocalizableText code={'DeclarationOfWaste.hasOtherAssessment'} />}
                                onChangeState={(isChecked: boolean) => {
                                    this.setState({
                                        [DeclarationFields.hasOtherAssessment]: isChecked,
                                    });
                                }}
                            />
                        </div>

                        {this.renderInput(
                            DeclarationFields.pollutionType,
                            <LocalizableText code={'DeclarationOfWaste.pollutionType'} />,
                            false,
                            element('pollution-type')
                        )}

                        {this.renderInput(
                            DeclarationFields.process,
                            <LocalizableText code={'DeclarationOfWaste.process'} />,
                            false,
                            element('process')
                        )}

                        {this.renderInput(
                            DeclarationFields.reason,
                            <LocalizableText code={'DeclarationOfWaste.reason'} />,
                            false,
                            element('reason')
                        )}

                        <div className={element('consistency-and-risks')}>
                            <div className={element('consistency')}>
                                <span className={element('consistency-title')}>
                                    {' '}
                                    <LocalizableText code={'DeclarationOfWaste.consistency'} />
                                </span>
                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.consistence[DeclarationConsistenceFields.isLiquid]}
                                    label={<LocalizableText code={'DeclarationOfWaste.liquid'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const consistence = {
                                            ...this.state.consistence,
                                            [DeclarationConsistenceFields.isLiquid]: isChecked,
                                        };

                                        this.setState({
                                            consistence,
                                        });
                                    }}
                                />
                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.consistence[DeclarationConsistenceFields.isViscous]}
                                    label={<LocalizableText code={'DeclarationOfWaste.viscous'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const consistence = {
                                            ...this.state.consistence,
                                            [DeclarationConsistenceFields.isViscous]: isChecked,
                                        };

                                        this.setState({
                                            consistence,
                                        });
                                    }}
                                />
                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.consistence[DeclarationConsistenceFields.isFixed]}
                                    label={<LocalizableText code={'DeclarationOfWaste.solid'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const consistence = {
                                            ...this.state.consistence,
                                            [DeclarationConsistenceFields.isFixed]: isChecked,
                                        };

                                        this.setState({
                                            consistence,
                                        });
                                    }}
                                />
                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.consistence[DeclarationConsistenceFields.isPasty]}
                                    label={<LocalizableText code={'DeclarationOfWaste.pasty'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const consistence = {
                                            ...this.state.consistence,
                                            [DeclarationConsistenceFields.isPasty]: isChecked,
                                        };

                                        this.setState({
                                            consistence,
                                        });
                                    }}
                                />
                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.consistence[DeclarationConsistenceFields.isPowder]}
                                    label={<LocalizableText code={'DeclarationOfWaste.powder'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const consistence = {
                                            ...this.state.consistence,
                                            [DeclarationConsistenceFields.isPowder]: isChecked,
                                        };

                                        this.setState({
                                            consistence,
                                        });
                                    }}
                                />
                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.consistence[DeclarationConsistenceFields.isSmall]}
                                    label={<LocalizableText code={'DeclarationOfWaste.small'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const consistence = {
                                            ...this.state.consistence,
                                            [DeclarationConsistenceFields.isSmall]: isChecked,
                                        };

                                        this.setState({
                                            consistence,
                                        });
                                    }}
                                />

                                <div className={element('consistency-ovrit')}>
                                    <CheckBoxComp
                                        disabled={readOnly}
                                        checked={this.state.consistence[DeclarationConsistenceFields.isOther]}
                                        label={<LocalizableText code={'DeclarationOfWaste.other'} />}
                                        onChangeState={(isChecked: boolean) => {
                                            const consistence = {
                                                ...this.state.consistence,
                                                [DeclarationConsistenceFields.isOther]: isChecked,
                                            };

                                            this.setState({
                                                consistence,
                                            });
                                        }}
                                    />
                                    <InputComp
                                        readOnly={readOnly}
                                        value={this.state.consistence[DeclarationConsistenceFields.other]}
                                        onChange={(value: string) => {
                                            const consistence = {
                                                ...this.state.consistence,
                                                [DeclarationConsistenceFields.other]: value,
                                            };

                                            this.setState({
                                                consistence,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={element('risks')}>
                                <span className={element('risks-title')}>
                                    <LocalizableText code={'DeclarationOfWaste.risks'} />
                                </span>
                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.risks[DeclarationRisksFields.isToxic]}
                                    label={<LocalizableText code={'DeclarationOfWaste.toxic'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const risks = {
                                            ...this.state.risks,
                                            [DeclarationRisksFields.isToxic]: isChecked,
                                        };

                                        this.setState({
                                            risks,
                                        });
                                    }}
                                />

                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.risks[DeclarationRisksFields.isFlammable]}
                                    label={<LocalizableText code={'DeclarationOfWaste.flammable'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const risks = {
                                            ...this.state.risks,
                                            [DeclarationRisksFields.isFlammable]: isChecked,
                                        };

                                        this.setState({
                                            risks,
                                        });
                                    }}
                                />

                                <div className={element('risks-acid')}>
                                    <CheckBoxComp
                                        disabled={readOnly}
                                        checked={this.state.risks[DeclarationRisksFields.isAcid]}
                                        label={<LocalizableText code={'DeclarationOfWaste.acid'} />}
                                        onChangeState={(isChecked: boolean) => {
                                            const risks = {
                                                ...this.state.risks,
                                                [DeclarationRisksFields.isAcid]: isChecked,
                                            };

                                            this.setState({
                                                risks,
                                            });
                                        }}
                                    />

                                    <InputComp
                                        value={this.state.risks[DeclarationRisksFields.acid]}
                                        readOnly={readOnly}
                                        onChange={(value: string) => {
                                            const risks = {
                                                ...this.state.risks,
                                                [DeclarationRisksFields.acid]: value,
                                            };

                                            this.setState({
                                                risks,
                                            });
                                        }}
                                    />
                                </div>

                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.risks[DeclarationRisksFields.isHazard]}
                                    label={<LocalizableText code={'DeclarationOfWaste.hazard'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const risks = {
                                            ...this.state.risks,
                                            [DeclarationRisksFields.isHazard]: isChecked,
                                        };

                                        this.setState({
                                            risks,
                                        });
                                    }}
                                />

                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.risks[DeclarationRisksFields.isExplosive]}
                                    label={<LocalizableText code={'DeclarationOfWaste.explosive'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const risks = {
                                            ...this.state.risks,
                                            [DeclarationRisksFields.isExplosive]: isChecked,
                                        };

                                        this.setState({
                                            risks,
                                        });
                                    }}
                                />

                                <div className={element('risks-alk')}>
                                    <CheckBoxComp
                                        disabled={readOnly}
                                        checked={this.state.risks[DeclarationRisksFields.isAlk]}
                                        label={<LocalizableText code={'DeclarationOfWaste.alk'} />}
                                        onChangeState={(isChecked: boolean) => {
                                            const risks = {
                                                ...this.state.risks,
                                                [DeclarationRisksFields.isAlk]: isChecked,
                                            };

                                            this.setState({
                                                risks,
                                            });
                                        }}
                                    />
                                    <InputComp
                                        readOnly={readOnly}
                                        value={this.state.risks[DeclarationRisksFields.alk]}
                                        onChange={(value: string) => {
                                            const risks = {
                                                ...this.state.risks,
                                                [DeclarationRisksFields.alk]: value,
                                            };

                                            this.setState({
                                                risks,
                                            });
                                        }}
                                    />
                                </div>

                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.risks[DeclarationRisksFields.isHarmful]}
                                    label={<LocalizableText code={'DeclarationOfWaste.harmful'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const risks = {
                                            ...this.state.risks,
                                            [DeclarationRisksFields.isHarmful]: isChecked,
                                        };

                                        this.setState({
                                            risks,
                                        });
                                    }}
                                />

                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.risks[DeclarationRisksFields.isFarlig]}
                                    label={<LocalizableText code={'DeclarationOfWaste.hazardous'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const risks = {
                                            ...this.state.risks,
                                            [DeclarationRisksFields.isFarlig]: isChecked,
                                        };

                                        this.setState({
                                            risks,
                                        });
                                    }}
                                />

                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.risks[DeclarationRisksFields.isCorrosive]}
                                    label={<LocalizableText code={'DeclarationOfWaste.corrosive'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const risks = {
                                            ...this.state.risks,
                                            [DeclarationRisksFields.isCorrosive]: isChecked,
                                        };

                                        this.setState({
                                            risks,
                                        });
                                    }}
                                />

                                <CheckBoxComp
                                    disabled={readOnly}
                                    checked={this.state.risks[DeclarationRisksFields.isOxidising]}
                                    label={<LocalizableText code={'DeclarationOfWaste.oxidising'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const risks = {
                                            ...this.state.risks,
                                            [DeclarationRisksFields.isOxidising]: isChecked,
                                        };

                                        this.setState({
                                            risks,
                                        });
                                    }}
                                />

                                <CheckBoxComp
                                    disabled={readOnly}
                                    className={element('risks-hygiene')}
                                    checked={this.state.risks[DeclarationRisksFields.isHygiene]}
                                    label={<LocalizableText code={'DeclarationOfWaste.hygiene'} />}
                                    onChangeState={(isChecked: boolean) => {
                                        const risks = {
                                            ...this.state.risks,
                                            [DeclarationRisksFields.isHygiene]: isChecked,
                                        };

                                        this.setState({
                                            risks,
                                        });
                                    }}
                                />

                                {this.renderInput(
                                    DeclarationFields.stability,
                                    <LocalizableText code={'DeclarationOfWaste.stability'} />,
                                    false,
                                    element('risks-stability')
                                )}
                            </div>
                        </div>
                        <div className={element('wastes')}>
                            <div className={element('wastes-title')}>
                                <LocalizableText code={'DeclarationOfWaste.wasteContains'} />
                            </div>

                            {this.renderInput(
                                DeclarationFields.subject1,
                                <LocalizableText code={'DeclarationOfWaste.subject'} />
                            )}

                            {this.renderInput(
                                DeclarationFields.value1,
                                <LocalizableText code={'DeclarationOfWaste.value'} />
                            )}
                            {this.renderInput(
                                DeclarationFields.unit1,
                                <LocalizableText code={'DeclarationOfWaste.unit'} />
                            )}

                            {this.renderInput(
                                DeclarationFields.subject2,
                                <LocalizableText code={'DeclarationOfWaste.subject'} />
                            )}

                            {this.renderInput(
                                DeclarationFields.value2,
                                <LocalizableText code={'DeclarationOfWaste.value'} />
                            )}
                            {this.renderInput(
                                DeclarationFields.unit2,
                                <LocalizableText code={'DeclarationOfWaste.unit'} />
                            )}

                            {this.renderInput(
                                DeclarationFields.subject3,
                                <LocalizableText code={'DeclarationOfWaste.subject'} />
                            )}

                            {this.renderInput(
                                DeclarationFields.value3,
                                <LocalizableText code={'DeclarationOfWaste.value'} />
                            )}
                            {this.renderInput(
                                DeclarationFields.unit3,
                                <LocalizableText code={'DeclarationOfWaste.unit'} />
                            )}

                            {this.renderInput(
                                DeclarationFields.subject4,
                                <LocalizableText code={'DeclarationOfWaste.subject'} />
                            )}

                            {this.renderInput(
                                DeclarationFields.value4,
                                <LocalizableText code={'DeclarationOfWaste.value'} />
                            )}
                            {this.renderInput(
                                DeclarationFields.unit4,
                                <LocalizableText code={'DeclarationOfWaste.unit'} />
                            )}

                            {this.renderInput(
                                DeclarationFields.subject5,
                                <LocalizableText code={'DeclarationOfWaste.subject'} />
                            )}

                            {this.renderInput(
                                DeclarationFields.value5,
                                <LocalizableText code={'DeclarationOfWaste.value'} />
                            )}
                            {this.renderInput(
                                DeclarationFields.unit5,
                                <LocalizableText code={'DeclarationOfWaste.unit'} />
                            )}

                            {this.renderInput(
                                DeclarationFields.subject6,
                                <LocalizableText code={'DeclarationOfWaste.subject'} />
                            )}

                            {this.renderInput(
                                DeclarationFields.value6,
                                <LocalizableText code={'DeclarationOfWaste.value'} />
                            )}
                            {this.renderInput(
                                DeclarationFields.unit6,
                                <LocalizableText code={'DeclarationOfWaste.unit'} />
                            )}
                        </div>
                        <div className={element('total-wastes')}>
                            <div className={element('total-wastes-info')}>
                                <div className={element('total-wastes-amount')}>
                                    <div className={element('total-wastes-title')}>
                                        <LocalizableText code={'DeclarationOfWaste.totalWaste'} />:
                                    </div>

                                    {this.renderInput(
                                        DeclarationFields.totalAmountCa,
                                        <LocalizableText code={'DeclarationOfWaste.ca'} />
                                    )}

                                    {this.renderInput(
                                        DeclarationFields.totalTon,
                                        <LocalizableText code={'DeclarationOfWaste.grossTon'} />
                                    )}

                                    {this.renderInput(
                                        DeclarationFields.packaging1,
                                        <LocalizableText code={'DeclarationOfWaste.packaging'} />
                                    )}

                                    {this.renderInput(
                                        DeclarationFields.quantity1,
                                        <LocalizableText code={'DeclarationOfWaste.quantity'} />
                                    )}

                                    {this.renderInput(
                                        DeclarationFields.packaging3,
                                        <LocalizableText code={'DeclarationOfWaste.packaging'} />
                                    )}

                                    {this.renderInput(
                                        DeclarationFields.quantity3,
                                        <LocalizableText code={'DeclarationOfWaste.quantity'} />
                                    )}
                                </div>
                                <div className={element('total-wastes-per-delivery')}>
                                    <div className={element('total-wastes-title')}>
                                        <LocalizableText code={'DeclarationOfWaste.quantityPerDelivery'} />
                                    </div>

                                    {this.renderInput(
                                        DeclarationFields.deliveryAmountCa,
                                        <LocalizableText code={'DeclarationOfWaste.ca'} />
                                    )}

                                    {this.renderInput(
                                        DeclarationFields.deliveryTon,
                                        <LocalizableText code={'DeclarationOfWaste.grossTon'} />
                                    )}

                                    {this.renderInput(
                                        DeclarationFields.packaging2,
                                        <LocalizableText code={'DeclarationOfWaste.packaging'} />
                                    )}

                                    {this.renderInput(
                                        DeclarationFields.quantity2,
                                        <LocalizableText code={'DeclarationOfWaste.quantity'} />
                                    )}

                                    {this.renderInput(
                                        DeclarationFields.packaging4,
                                        <LocalizableText code={'DeclarationOfWaste.packaging'} />
                                    )}

                                    {this.renderInput(
                                        DeclarationFields.quantity4,
                                        <LocalizableText code={'DeclarationOfWaste.quantity'} />
                                    )}
                                </div>
                                <div className={element('total-wastes-additional')}>
                                    {this.renderInput(
                                        DeclarationFields.conveyor,
                                        <LocalizableText code={'DeclarationOfWaste.conveyor'} />
                                    )}
                                    {this.renderInput(
                                        DeclarationFields.otherInfo,
                                        <LocalizableText code={'DeclarationOfWaste.otherInfo'} />
                                    )}
                                    {this.renderInput(
                                        DeclarationFields.date,
                                        <LocalizableText code={'DeclarationOfWaste.date'} />,
                                        false,
                                        element('total-wastes-additional-date')
                                    )}
                                </div>
                            </div>
                            <div className={element('transportation')}>
                                <div className={element('transportation-tunk')}>
                                    <span
                                        onClick={() => {
                                            this.setState({
                                                [DeclarationFields.isTunk]: !this.state[DeclarationFields.isTunk],
                                            });
                                        }}>
                                        <LocalizableText code={'DeclarationOfWaste.tank'} />
                                    </span>
                                    <CheckBoxComp
                                        disabled={readOnly}
                                        checked={this.state[DeclarationFields.isTunk]}
                                        onChangeState={(isChecked: boolean) => {
                                            this.setState({
                                                [DeclarationFields.isTunk]: isChecked,
                                            });
                                        }}
                                    />
                                </div>

                                <div className={element('transportation-container')}>
                                    <span
                                        onClick={() => {
                                            this.setState({
                                                [DeclarationFields.isContainer]:
                                                    !this.state[DeclarationFields.isContainer],
                                            });
                                        }}>
                                        <LocalizableText code={'DeclarationOfWaste.container'} />
                                    </span>
                                    <CheckBoxComp
                                        disabled={readOnly}
                                        checked={this.state[DeclarationFields.isContainer]}
                                        onChangeState={(isChecked: boolean) => {
                                            this.setState({
                                                [DeclarationFields.isContainer]: isChecked,
                                            });
                                        }}
                                    />
                                </div>

                                <div className={element('transportation-rails')}>
                                    <span
                                        onClick={() => {
                                            this.setState({
                                                [DeclarationFields.isRails]: !this.state[DeclarationFields.isRails],
                                            });
                                        }}>
                                        <LocalizableText code={'DeclarationOfWaste.rail'} />
                                    </span>
                                    <CheckBoxComp
                                        disabled={readOnly}
                                        checked={this.state[DeclarationFields.isRails]}
                                        onChangeState={(isChecked: boolean) => {
                                            this.setState({
                                                [DeclarationFields.isRails]: isChecked,
                                            });
                                        }}
                                    />
                                </div>

                                {this.renderInput(
                                    DeclarationFields.transport,
                                    <LocalizableText code={'DeclarationOfWaste.transport'} />
                                )}

                                {this.renderInput(
                                    DeclarationFields.unNr,
                                    <LocalizableText code={'DeclarationOfWaste.unNr'} />
                                )}

                                {this.renderInput(
                                    DeclarationFields.class,
                                    <LocalizableText code={'DeclarationOfWaste.class'} />
                                )}

                                {this.renderInput(
                                    DeclarationFields.packaging,
                                    <LocalizableText code={'DeclarationOfWaste.pack'} />
                                )}

                                {this.renderInput(
                                    DeclarationFields.organizationNr,
                                    <LocalizableText code={'DeclarationOfWaste.organizationNr'} />,
                                    false,
                                    'organization-number'
                                )}

                                <div className={element('transportation-info-attached')}>
                                    <span
                                        onClick={() =>
                                            this.setState({
                                                [DeclarationFields.infoAttached]:
                                                    !this.state[DeclarationFields.infoAttached],
                                            })
                                        }>
                                        <LocalizableText code={'DeclarationOfWaste.isAttachedProductInfo'} />
                                    </span>
                                    <CheckBoxComp
                                        disabled={readOnly}
                                        checked={this.state[DeclarationFields.infoAttached]}
                                        onChangeState={(isChecked: boolean) => {
                                            this.setState({
                                                [DeclarationFields.infoAttached]: isChecked,
                                            });
                                        }}
                                    />
                                </div>

                                {this.renderInput(
                                    DeclarationFields.otherCar,
                                    <LocalizableText code={'DeclarationOfWaste.otherCar'} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withApollo<ProjectDumpLoadDeclarationProps>(ProjectDumpLoadDeclaration);
