import React, { Fragment } from 'react';

import { Button } from '~/@components/Button';
import { ClickableText } from '~/@components/ClickableText';
import { Divider } from '~/@components/Divider';
import { LocalizableText } from '~/@components/LocalizableText';
import { Markdown } from '~/@components/Markdown';
import { Text } from '~/@components/Text';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { IReleaseNote } from '~/@store/releaseNotes';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';
import { formatDate } from '~/utils/date';

type Props = {
    note: IReleaseNote;
    restNotes: IReleaseNote[];
    onClose: (hasRead: boolean) => void;
};

const UserReleaseNoteDialog = ({ note, restNotes, onClose }: Props) => {
    const [showMore, setShowMore] = React.useState(false);
    const haveMore = !!restNotes.length;

    return (
        <DialogContent>
            <Markdown text={note.text} />

            {!showMore && haveMore && (
                <ClickableText onClick={() => setShowMore(true)}>{i18n.showMore}...</ClickableText>
            )}
            {showMore &&
                restNotes.map(n => (
                    <Fragment key={n.id}>
                        <Divider marginBottom />
                        <Text color="textSecondary">{formatDate(n.releaseDate)}</Text>
                        <Markdown text={n.text} />
                    </Fragment>
                ))}
            <ButtonGroup>
                <Button variant="contained" color="primary" onClick={() => onClose(true)}>
                    <LocalizableText code={'close'} />
                </Button>
            </ButtonGroup>
        </DialogContent>
    );
};

export const showUserReleaseNoteDialog = (note: IReleaseNote, restNotes: IReleaseNote[]) => {
    showCustomDialog({
        title: <LocalizableText code={'ReleaseNotes.whatsNew'} />,
        render: closeDialog => <UserReleaseNoteDialog onClose={closeDialog} note={note} restNotes={restNotes} />,
        forceCrossClose: true,
    });
};
