import { makeStyles } from '@material-ui/core';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn } from '~/@components/Table';
import {
    getProjectContextRole,
    IDetailedUser,
    IUserProjectInfo,
    userProjectsStore,
    UserRelationField,
} from '~/@store/users';
import { UserContextRoleChip } from '~/@views/AdminView/UserDetailPage/UserContextRoleChip/UserContextRoleChip';

import { UserRelationHeadCell } from '../UserRelationHeadCell';
import { UserProjectCell } from './UserProjectCell';
import { UserProjectDeleteCell } from './UserProjectDeleteCell';

export const useUserProjectsColumns = (user: IDetailedUser): ITableColumn<IUserProjectInfo>[] => {
    const { actionCell, nameCell } = useStyles();

    // noinspection UnnecessaryLocalVariableJS
    const columns: ITableColumn<IUserProjectInfo>[] = [
        {
            title: (
                <UserRelationHeadCell
                    title={<LocalizableText code={'UserCard.projects.project'} />}
                    fieldName={UserRelationField.name}
                    store={userProjectsStore}
                    withFilter
                    withSort
                />
            ),
            render: row => <UserProjectCell project={row} />,
            cellClassName: nameCell,
            name: 'project',
        },
        {
            title: <LocalizableText code={'UserCard.projects.role'} />,
            render: row => <UserContextRoleChip role={getProjectContextRole(user, row)} />,
            name: 'role',
        },
        {
            title: null,
            render: row => <UserProjectDeleteCell project={row} />,
            cellClassName: actionCell,
            name: 'delete',
        },
    ];

    return columns;
};

const useStyles = makeStyles(() => ({
    nameCell: {
        // to grow
        width: '99%',
    },
    actionCell: {
        minWidth: 'unset',
        // to shrink correctly
        whiteSpace: 'nowrap',
    },
}));
