import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import type { ISettings } from '~/@store/settings';

import { CompanyConfigurationFields as CompanyConfigFields } from '../../../../../../common/enums';
import { ConfigurationField } from '../ConfigurationField';
import { CompanyConfigurationField } from './CompanyConfigurationField';

type Props = {
    settings: ISettings;
    setSettings: (settings: ISettings) => void;
};

export const CompanyConfigurationFields = ({ settings, setSettings }: Props) => {
    const companyConfiguration = settings.companyConfiguration;

    const setCompanyConfiguration = (newCompanyConfig: ISettings['companyConfiguration']) => {
        setSettings({
            ...settings,
            companyConfiguration: newCompanyConfig,
        });
    };

    return (
        <>
            <CompanyConfigurationField
                companyConfiguration={companyConfiguration}
                setCompanyConfiguration={setCompanyConfiguration}
                field={CompanyConfigFields.orgName}
                label={<LocalizableText code={'settingsPage.companyConfig.orgName'} />}
            />
            <CompanyConfigurationField
                companyConfiguration={companyConfiguration}
                setCompanyConfiguration={setCompanyConfiguration}
                field={CompanyConfigFields.orgNumber}
                label={<LocalizableText code={'settingsPage.companyConfig.orgNumber'} />}
            />
            <CompanyConfigurationField
                companyConfiguration={companyConfiguration}
                setCompanyConfiguration={setCompanyConfiguration}
                field={CompanyConfigFields.place}
                label={<LocalizableText code={'settingsPage.companyConfig.cityPlace'} />}
            />
            <CompanyConfigurationField
                companyConfiguration={companyConfiguration}
                setCompanyConfiguration={setCompanyConfiguration}
                field={CompanyConfigFields.postNumber}
                label={<LocalizableText code={'settingsPage.companyConfig.postNumber'} />}
            />
            <CompanyConfigurationField
                companyConfiguration={companyConfiguration}
                setCompanyConfiguration={setCompanyConfiguration}
                field={CompanyConfigFields.address}
                label={<LocalizableText code={'settingsPage.companyConfig.address'} />}
            />
            <CompanyConfigurationField
                companyConfiguration={companyConfiguration}
                setCompanyConfiguration={setCompanyConfiguration}
                field={CompanyConfigFields.tel}
                label={<LocalizableText code={'settingsPage.companyConfig.contactPhoneNumber'} />}
            />
            <CompanyConfigurationField
                companyConfiguration={companyConfiguration}
                setCompanyConfiguration={setCompanyConfiguration}
                field={CompanyConfigFields.email}
                label={<LocalizableText code={'settingsPage.companyConfig.contactEmail'} />}
            />
            <ConfigurationField
                value={window.__version || ''}
                label={<LocalizableText code={'settingsPage.companyConfig.version'} />}
                onChange={() => {}}
                readOnly
            />
        </>
    );
};
