import './LandfillCreateForm.scss';

import { isFunction } from 'lodash';
import React, { useState } from 'react';

import { Button } from '~/@components/Button';
import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { MapWithContentContainer } from '~/@sochi-components/MapWithContentContainer';
import { SideBarTitle } from '~/@sochi-components/SideBarTitle';
import { SochiBackButton } from '~/@sochi-components/SochiBackButton';
import { Stepper } from '~/@sochi-components/Stepper';
import { LandfillMapInput } from '~/@views/UserView/LandfillPage/common/LandfillFormParts/LandfillMapInput';

import {
    FormNames,
    LandfillFilesInput,
    LandfillFormExternalProps,
    LandfillFormWithContext,
    LandfillLocationSelect,
    LandfillPaymentCapacitySection,
    LandfillStatusSelect,
    LandfillTextField,
    LandfillUserSearchSelect,
    LandfillWorkTime,
} from '../LandfillFormParts';
import { LandfillCustomerSelect } from '../LandfillFormParts/LandfillCustomerSelect';
import { LandfillCreateNextButton } from './LandfillCreateNextButton';

export const LandfillCreateForm = (props: LandfillFormExternalProps) => {
    const { className, element } = useFunctionalBem(LandfillCreateForm);
    const [step, setStep] = useState(1);

    const goToNextStep = () => setStep(step + 1);
    const goToPreviousStep = () => setStep(step - 1 || 1);

    const backButton = () => (
        <Button className={element('previous-step-button')} variant="contained" onClick={goToPreviousStep}>
            <LocalizableText code={'LandfillForm.back'} />
        </Button>
    );

    const step1 = () => {
        const { onBack } = props;

        return (
            <>
                {isFunction(onBack) && <SochiBackButton className={element('back-button')} onClick={onBack} />}
                <SideBarTitle
                    title={
                        <LocalizableText
                            code={props.landfill ? 'LandfillForm.updateLandfill' : 'LandfillForm.createLandfill'}
                        />
                    }
                    subtitle={<LocalizableText code={'LandfillForm.enterDetails'} />}
                />
                <LandfillTextField name={FormNames.name} label={<LocalizableText code={'LandfillForm.siteName'} />} />
                <div className={element('divider')} />
                <LandfillLocationSelect />
                <ButtonGroup>
                    <LandfillCreateNextButton
                        fieldsToValidate={[FormNames.name, FormNames.location]}
                        goToNextStep={goToNextStep}
                    />
                </ButtonGroup>
            </>
        );
    };

    const step2 = () => (
        <>
            <LandfillStatusSelect className={element('form-control')} />
            <LandfillPaymentCapacitySection />
            <LandfillUserSearchSelect className={element('form-control')} />
            <LandfillCustomerSelect className={element('form-control')} />
            <LandfillFilesInput className={element('form-control')} />
            <ButtonGroup>
                {backButton()}
                <LandfillCreateNextButton
                    fieldsToValidate={[FormNames.certificates, FormNames.customer]}
                    goToNextStep={goToNextStep}
                />
            </ButtonGroup>
        </>
    );

    const step3 = () => (
        <>
            <LandfillWorkTime enlargedHeading />
            <ButtonGroup>
                {backButton()}
                <LandfillCreateNextButton fieldsToValidate={[FormNames.workTime]} goToNextStep={goToNextStep} />
            </ButtonGroup>
        </>
    );

    const step4 = () => {
        return (
            <>
                <LandfillTextField
                    name={FormNames.comment}
                    label={
                        <LabelWithHint
                            label={<LocalizableText code={'LandfillForm.comment'} />}
                            hint={<LocalizableText code={'LandfillForm.commentFieldHint'} />}
                        />
                    }
                    rows={4}
                />
                <LandfillTextField
                    name={FormNames.description}
                    label={
                        <LabelWithHint
                            label={<LocalizableText code={'LandfillForm.description'} />}
                            hint={<LocalizableText code={'LandfillForm.descriptionFieldHint'} />}
                        />
                    }
                    rows={10}
                />
                <ButtonGroup>
                    {backButton()}
                    <Button type="submit" color="primary" variant="contained">
                        <LocalizableText code={'continue'} />
                    </Button>
                </ButtonGroup>
            </>
        );
    };

    const renderContent = () => {
        return <Stepper className={element('stepper')} step={step} steps={[step1, step2, step3, step4]} />;
    };

    const renderMap = () => {
        return <LandfillMapInput className={element('map')} />;
    };

    return (
        <LandfillFormWithContext>
            <div className={className}>
                <MapWithContentContainer renderContent={renderContent} renderMap={renderMap} />
            </div>
        </LandfillFormWithContext>
    );
};
