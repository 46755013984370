import { Tab, Tabs } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React from 'react';

import { COLORS } from '~/@components/@theme/colors';

interface StyledTabProps {
    id: string;
    label: React.ReactNode;
    value: number;
    'aria-controls': string;
    disabled?: boolean;
}

export const StyledTabs = styled(Tabs)({
    paddingTop: 4,
    '& .MuiTabs-indicator': {
        display: 'none',
    },
});

export const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(() => ({
    textTransform: 'none',
    fontSize: 16,
    color: COLORS.grayDarker,
    '&.Mui-selected': {
        color: COLORS.textBody,
        textDecorationLine: 'underline',
    },
    '&:hover': {
        color: COLORS.textBody,
        textDecorationLine: 'underline',
    },
    '&.MuiTab-root': {
        minWidth: 80,
    },
}));
