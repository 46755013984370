import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const PlusCircleIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3.00125C7.02944 3.00125 3 7.03069 3 12.0013C3 16.9718 7.02944 21.0013 12 21.0013C16.9706 21.0013 21 16.9718 21 12.0013C21 7.03069 16.9706 3.00125 12 3.00125ZM1 12.0013C1 5.92612 5.92487 1.00125 12 1.00125C18.0751 1.00125 23 5.92612 23 12.0013C23 18.0764 18.0751 23.0013 12 23.0013C5.92487 23.0013 1 18.0764 1 12.0013Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7.00125C12.5523 7.00125 13 7.44897 13 8.00125V16.0013C13 16.5535 12.5523 17.0013 12 17.0013C11.4477 17.0013 11 16.5535 11 16.0013V8.00125C11 7.44897 11.4477 7.00125 12 7.00125Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 12.0013C7 11.449 7.44772 11.0013 8 11.0013H16C16.5523 11.0013 17 11.449 17 12.0013C17 12.5535 16.5523 13.0013 16 13.0013H8C7.44772 13.0013 7 12.5535 7 12.0013Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
