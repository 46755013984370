import './AuthWrapper.scss';

import React from 'react';

import { getBem } from '~/@sochi-components/@bem';
import { SochiThemeContainer } from '~/@sochi-components/@theme';
import { SochiBackButton, SochiBackButtonColors } from '~/@sochi-components/SochiBackButton';
import type { WithSettingsProps } from '~/contexts';
import { withSettings } from '~/contexts';

import logo from '../../assets/pinpointer-logo-new.png';
import { LoginCarousel } from '../LoginCarousel';

type AuthWrapperProps = {
    onBack?: () => void;
    children: React.ReactNode;
} & WithSettingsProps;

class AuthWrapper extends React.Component<AuthWrapperProps> {
    bem = getBem(this);
    authWrapperRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        let vh = window.innerHeight;
        if (this.authWrapperRef.current) this.authWrapperRef.current.style.minHeight = vh + 'px';
    }

    renderLogo = () => {
        const { app } = this.props;

        return <img src={logo} alt={`${app} Logo`} />;
    };

    render() {
        const { onBack, children } = this.props;
        const { className, element } = this.bem;

        return (
            <div className={className} ref={this.authWrapperRef}>
                <SochiThemeContainer>
                    <div className={element('logotype')}>{this.renderLogo()}</div>
                    <div className={element('form-wrapper')}>
                        {!!onBack && (
                            <div className={element('back-button-wrapper')}>
                                <SochiBackButton color={SochiBackButtonColors.white} onClick={onBack} />
                            </div>
                        )}
                        {children}
                    </div>
                    <div className={element('carousel-wrapper')}>
                        <LoginCarousel />
                    </div>
                </SochiThemeContainer>
            </div>
        );
    }
}

export default withSettings(AuthWrapper);
