import './DumpLoadBlock.scss';

import { action } from 'mobx';
import { observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';

import type { IWeekAmount } from '../../../../../../../../common/types';
import { isValidAmount } from '../../../../../../../../common/validations/common.validation';
import { Colors } from '../../../../../../config/enum';
import { DumpLoadDates } from '../DumpLoadDates';
import { COLUMN_NUMBER, IScheduleDumpLoad, scheduleStore } from '../scheduleStore';

enum FillerType {
    offset = 'offset',
    filler = 'filler',
}

function generateFiller(n: number, fillerType: FillerType): Array<FillerType> {
    return [...Array(n)].map(() => fillerType);
}

type Props = { dumpLoad: IScheduleDumpLoad };

@observer
export class DumpLoadBlock extends React.Component<Props> {
    bem = getBem(this);

    openDumpLoad = () => scheduleStore.openDumpLoad(this.props.dumpLoad.id);

    handleAmountChange = (item: IWeekAmount) =>
        action((event: ChangeEvent<HTMLInputElement>) => {
            const newAmount = Number(event.target.value);
            if (!isValidAmount(newAmount)) return;
            item.amount = newAmount;
        });

    render() {
        const { dumpLoad } = this.props;
        const { element, className } = this.bem;
        const opened = scheduleStore.layout.openedDumpLoad === dumpLoad.id;
        const openIfNot = () => !opened && this.openDumpLoad();

        const offset = scheduleStore.layout.offset;

        const offsetWeeks = generateFiller(dumpLoad.offset, FillerType.offset);
        const items: Array<IWeekAmount | string> = [...offsetWeeks, ...dumpLoad.transportSchedule].slice(
            offset,
            offset + COLUMN_NUMBER
        );

        const renderItems =
            items.length === COLUMN_NUMBER
                ? items
                : [...items, ...generateFiller(COLUMN_NUMBER - items.length, FillerType.filler)];

        return (
            <div className={className}>
                <div
                    title={dumpLoad.name}
                    onClick={this.openDumpLoad}
                    className={element('dumpload-title', { readonly: dumpLoad.readOnly })}>
                    <div className={element('dumpload-name', { error: !dumpLoad.isValid })} title={dumpLoad.name}>
                        <span className={element('dumpload-name-text')}>{dumpLoad.name}</span>
                        <span className={element('declaration-number')}>{dumpLoad.declarationNumber}</span>
                    </div>
                    <div className={element('dumpload-comment')} title={dumpLoad.comment}>
                        {dumpLoad.comment}
                    </div>
                </div>
                <div className={element('dumpload-container')}>
                    {renderItems.map((item, ind) =>
                        typeof item === 'string' ? (
                            <div key={ind} className={item} />
                        ) : (
                            <div className="cell" key={item.week}>
                                <input
                                    type="text"
                                    pattern="\d*"
                                    value={item.amount || ''}
                                    onChange={this.handleAmountChange(item)}
                                    onFocus={openIfNot}
                                    disabled={dumpLoad.readOnly}
                                />
                            </div>
                        )
                    )}

                    {opened && (
                        <div className={element('schedule-edit-block')}>
                            <DumpLoadDates dumpLoad={dumpLoad} />
                            <div className="info-block">
                                <span className="info-block-text">
                                    <LocalizableText code={'TransportSchedule.totalMass'} />:
                                    <span
                                        style={{
                                            color: dumpLoad.amountSum === dumpLoad.amount ? Colors.green : Colors.red,
                                        }}>
                                        {dumpLoad.amountSum}/{dumpLoad.amount}
                                    </span>
                                </span>
                                <div className="button-container">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => scheduleStore.distributeEvenly(dumpLoad)}
                                        disabled={dumpLoad.readOnly}>
                                        <LocalizableText code={'TransportSchedule.distributeEvenly'} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
