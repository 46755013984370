import './EditSubstanceDropdown.scss';

import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { Button } from '~/@components/Button';
import { Checkbox } from '~/@components/Checkbox';
import { LocalizableText } from '~/@components/LocalizableText';
import { Select } from '~/@components/Select';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ArrowDownIcon } from '~/@sochi-components/Icons';
import { ContaminationTypeStore } from '~/@store/substances';
import { getContaminationLevelLabel } from '~/@store/toxicLimits';

import { getDropdownTitle } from './EditSubstanceDropdown.helpers';
import { EditSubstanceList } from './EditSubstanceList';

type Props = {
    store: ContaminationTypeStore;
    disableContaminationLevels?: boolean;
};

const EditSubstanceDropdown = ({ store, disableContaminationLevels = false }: Props) => {
    const { className, element } = useFunctionalBem(EditSubstanceDropdown);
    const [isOpen, setOpen] = React.useState(false);
    const isDisabled = store.readOnly || !store.state || disableContaminationLevels;

    return (
        <div className={classNames(className)}>
            <Checkbox
                checked={store.state && !disableContaminationLevels}
                disabled={store.readOnly || disableContaminationLevels}
                onChange={store.toggleState}
            />
            <div
                className={element('dropdown-button', { disabled: isDisabled })}
                onClick={() => !isDisabled && setOpen(open => !open)}>
                {disableContaminationLevels ? (
                    <LocalizableText code={'contaminationLevelUnavailable'} />
                ) : (
                    <div className={element('dropdown-button-text')}>
                        {getDropdownTitle(
                            store.contaminationType,
                            store.isExactLevel ? store.contaminationLevel : null,
                            store.isEmpty
                        )}
                    </div>
                )}
                {!isDisabled && <ArrowDownIcon className={element('dropdown-button-arrow', { rotated: isOpen })} />}
            </div>
            {!disableContaminationLevels && (
                <Select
                    items={store.levelsForFill}
                    value={store.contaminationLevel}
                    onChange={v => v && store.fillLevel(v)}
                    keyGetter={v => v}
                    renderValue={v => getContaminationLevelLabel(v, store.contaminationType)}
                    addEmptyOption={false}
                    disabled={isDisabled}
                    emptyLabel={<LocalizableText code={'contaminationValues.select'} />}
                />
            )}
            {!isDisabled && isOpen ? (
                <div className={element('sochi-large-dropdown-field')}>
                    <EditSubstanceList store={store} element={element} />
                    <div className={element('buttons-block')}>
                        <Button color="primary" variant="contained" onClick={store.clearAmounts}>
                            <LocalizableText code={'clearValues'} />
                        </Button>
                        <Button color="primary" variant="contained" onClick={() => setOpen(open => !open)}>
                            <LocalizableText code={'hide'} />
                        </Button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default observer(EditSubstanceDropdown);
