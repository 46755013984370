import './AccountForm.scss';

import type { FormikProps } from 'formik';
import { Form } from 'formik';
import React, { useMemo } from 'react';

import { Button } from '~/@components/Button';
import { FormikContextTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { AccountPasswordChangeFunc, AccountUpdateFunc } from '~/@store/account';
import type { IUser } from '~/contexts';
import i18n from '~/i18n';

export type IFormValues = {
    name: string;
    surname: string;
    email: string;
    companyName: string;
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
    hideCompany: boolean;
};

export enum FormNames {
    name = 'name',
    surname = 'surname',
    email = 'email',
    companyName = 'companyName',
    oldPassword = 'oldPassword',
    newPassword = 'newPassword',
    repeatPassword = 'repeatPassword',
}

export const isUserChanged = (values: IFormValues, { name = '', surname = '' }: IUser) =>
    name !== values.name || surname !== values.surname;

export const isPasswordChanged = ({ oldPassword, newPassword, repeatPassword }: IFormValues) =>
    !!oldPassword && !!newPassword && !!repeatPassword;

export type IAccountFormOwnProps = {
    user: IUser;
    onUpdateUser: AccountUpdateFunc;
    onChangePassword: AccountPasswordChangeFunc;
    onClose: () => void;
};

export type IAccountFormProps = FormikProps<IFormValues> & IAccountFormOwnProps;

export const AccountForm = (props: IAccountFormProps) => {
    const { user, values, isValid, handleSubmit, isSubmitting, onClose } = props;

    const passwordChanged = useMemo(() => isPasswordChanged(values), [values]);
    const userChanged = useMemo(() => isUserChanged(values, user), [values, user]);
    const canSubmit = (userChanged || passwordChanged) && isValid && !isSubmitting;

    const { className, element } = useFunctionalBem(AccountForm);

    return (
        <div className={className}>
            <Form onSubmit={handleSubmit} className={element('form')}>
                <div>
                    <h3 className={element('title')}>
                        <LocalizableText code={'account'} />
                    </h3>
                    {!values.hideCompany && (
                        <FormikContextTextField<IFormValues, FormNames.companyName>
                            name={FormNames.companyName}
                            label={<LocalizableText code={'company'} />}
                            disabled
                        />
                    )}
                    <FormikContextTextField<IFormValues, FormNames.name>
                        name={FormNames.name}
                        label={<LocalizableText code={'name'} />}
                    />
                    <FormikContextTextField<IFormValues, FormNames.surname>
                        name={FormNames.surname}
                        label={<LocalizableText code={'surName'} />}
                    />
                    <FormikContextTextField<IFormValues, FormNames.email>
                        name={FormNames.email}
                        label={<LocalizableText code={'email'} />}
                        disabled
                    />
                </div>
                <div>
                    <h3 className={element('title')}>{<LocalizableText code={'changePassword'} />}</h3>
                    <FormikContextTextField<IFormValues, FormNames.oldPassword>
                        type="password"
                        name={FormNames.oldPassword}
                        label={<LocalizableText code={'oldPassword'} />}
                    />
                    <FormikContextTextField<IFormValues, FormNames.newPassword>
                        type="password"
                        name={FormNames.newPassword}
                        label={<LocalizableText code={'newPassword'} />}
                    />
                    <FormikContextTextField<IFormValues, FormNames.repeatPassword>
                        type="password"
                        name={FormNames.repeatPassword}
                        label={<LocalizableText code={'repeatPassword'} />}
                    />
                </div>
                <div className={element('buttons')}>
                    <Button type="button" size="large" color="primary" variant="outlined" onClick={onClose}>
                        {i18n.cancel}
                    </Button>
                    <Button type="submit" size="large" color="primary" variant="contained" disabled={!canSubmit}>
                        {i18n.save}
                    </Button>
                </div>
            </Form>
        </div>
    );
};
