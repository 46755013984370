import './ProjectTransportScheduleColumns.scss';

import { IconButton } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { getBem } from '~/@sochi-components/@bem';
import { ArrowLeftIcon, ArrowRightIcon } from '~/@sochi-components/Icons';

import { COLUMN_NUMBER, scheduleStore } from '../scheduleStore';

@observer
export class ProjectTransportScheduleColumns extends React.Component<{}> {
    bem = getBem(this);

    render() {
        const {
            layout: { columns, offset },
        } = scheduleStore;

        const { element, className } = this.bem;

        return (
            <div className={className}>
                <div className={element('first-column')} />
                {columns.slice(offset, offset + COLUMN_NUMBER).map(c => (
                    <div className={element('column')} key={c.key}>
                        {c.label}
                    </div>
                ))}
                <IconButton
                    color="primary"
                    disabled={offset === 0}
                    className={element('arrow-left')}
                    onClick={scheduleStore.shiftOffset(-1)}>
                    <ArrowLeftIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                    color="primary"
                    disabled={offset >= columns.length - COLUMN_NUMBER}
                    className={element('arrow-right')}
                    onClick={scheduleStore.shiftOffset(1)}>
                    <ArrowRightIcon fontSize="inherit" />
                </IconButton>
            </div>
        );
    }
}
