import { formatString } from '@common/utils';
import { isSameDay } from 'date-fns';
import { FormikConfig, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { ICompanySearchRecord } from '~/@store/companies';
import { gqlTypeToLocation, ILocation } from '~/@store/locations';
import { CreateProjectFunc, IDetailedProject, IProjectAbilities, UpdateProjectFunc } from '~/@store/projects';
import { IUserSearchRecord } from '~/@store/users';
import { homePageMapStore } from '~/@user-store/homePageMap/homePageMap.store';
import { LocationType, ProjectCreate, ProjectUpdate } from '~/graphql';
import i18n from '~/i18n';
import { isAfterDay, isBeforeDay, parseDateFrom } from '~/utils/date';

export type IProjectFormValues = {
    project: IDetailedProject | null;
    name: string;
    customer: ICompanySearchRecord | null;
    owner: IUserSearchRecord | null;
    startDate: Date | null;
    endDate: Date | null;
    location: ILocation;
    comment: string;
    invoiceReference: string;
    externalId: string;
};

const validateStartDate = (
    startDate: Date | null,
    dumpLoads: { date: string | null }[],
    initialStartDate: string | null
): string | null => {
    const startDateParsed = parseDateFrom(initialStartDate);
    if (startDate && startDateParsed && isSameDay(startDate, startDateParsed)) return null;
    if (!startDate) return formatString(i18n.isRequired, i18n.startDate);
    if (
        dumpLoads.some(d => {
            const dumpLoadDate = parseDateFrom(d.date);

            return dumpLoadDate && isAfterDay(startDate, dumpLoadDate);
        })
    )
        return i18n.errorCodes.PROJECT_START_DATE_AFTER_MASS_START_DATE;

    return null;
};

const validateEndDate = (
    endDate: Date | null,
    startDate: Date | null,
    dumpLoads: { endDate: string | null }[],
    initialEndDate: string | null
): string | null => {
    const endDateParsed = parseDateFrom(initialEndDate);
    if (endDate && endDateParsed && isSameDay(endDate, endDateParsed)) return null;
    if (!endDate) return formatString(i18n.isRequired, i18n.endDate);
    if (startDate && isAfterDay(startDate, endDate)) return i18n.errorCodes.PROJECT_END_DATE_BEFORE_START_DATE;
    if (
        dumpLoads.some(d => {
            const dumpLoadDate = parseDateFrom(d.endDate);

            return dumpLoadDate && isBeforeDay(endDate, dumpLoadDate);
        })
    )
        return i18n.errorCodes.PROJECT_END_DATE_BEFORE_MASS_END_DATE;

    return null;
};

export const useProjectFormikContext = () => useFormikContext<IProjectFormValues>();

const errorsByLocationType: Record<LocationType, string | null> = {
    VALID: null,
    UNKNOWN: i18n.errorCodes.LOCATION_NOT_GEOCODED,
    ON_WATER: i18n.errorCodes.PROJECT_LOCATION_ON_WATER,
    UNREACHABLE: i18n.errorCodes.LOCATION_UNREACHABLE,
};

export function getProjectFormikConfig(
    project: IDetailedProject | null,
    createProject: CreateProjectFunc,
    updateProject: UpdateProjectFunc,
    projectAbilities: IProjectAbilities
): FormikConfig<IProjectFormValues> {
    return {
        enableReinitialize: true,
        initialValues: {
            project,
            name: project?.name || '',
            customer: project?.customer || null,
            owner: project?.owner || null,
            startDate: project?.startDate ? parseDateFrom(project.startDate) : null,
            endDate: project?.endDate ? parseDateFrom(project.endDate) : null,
            location: project?.location ? gqlTypeToLocation(project.location) : homePageMapStore.defaultLocation,
            comment: project?.comment || '',
            invoiceReference: project?.invoiceReference || '',
            externalId: project?.externalId || '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required(i18n.errorCodes.PROJECT_NAME_CANNOT_BE_EMPTY),
            startDate: Yup.date()
                .nullable()
                .test('startDate validation', '', function (this, startDate) {
                    const message = validateStartDate(
                        startDate as Date | null,
                        project?.dumpLoads || [],
                        project?.startDate || null
                    );

                    if (message) return this.createError({ message, path: 'startDate' });

                    return true;
                }),
            endDate: Yup.date()
                .nullable()
                .test('endDate validation', '', function (this, endDate) {
                    const message = validateEndDate(
                        endDate as Date | null,
                        this.parent.startDate,
                        project?.dumpLoads || [],
                        project?.endDate || null
                    );

                    if (message) return this.createError({ message, path: 'endDate' });

                    return true;
                }),
            location: Yup.object<IProjectFormValues['location']>().test(
                'Location validation',
                '',
                function (this, location) {
                    const message = location
                        ? errorsByLocationType[location.type]
                        : i18n.errorCodes.PROJECT_LOCATION_CANNOT_BE_EMPTY;

                    if (message) return this.createError({ message, path: 'location' });

                    return true;
                }
            ),
            comment: Yup.string(),
            invoiceReference: Yup.string(),
            customer: Yup.object().nullable().required(i18n.errorCodes.PROJECT_CUSTOMER_REQUIRED),
        }),
        onSubmit: async values => {
            const {
                project,
                name,
                customer,
                owner,
                startDate,
                endDate,
                location,
                comment,
                invoiceReference,
                externalId,
            } = values;

            if (project) {
                const ver = project.ver;
                const input: ProjectUpdate = {
                    id: project.id,
                    ver,
                    name,
                    startDate: startDate?.toISOString(),
                    endDate: endDate?.toISOString(),
                    comment,
                    invoiceReference,
                    owner: owner ? { id: owner.id } : null,
                    externalId,
                };

                if (projectAbilities.canEditLocation) {
                    input.location = location;
                }

                if (customer) input.customerId = customer.id;

                await updateProject(input);
            } else {
                if (!location || !customer) return;

                const input: ProjectCreate = {
                    name,
                    startDate: startDate?.toISOString(),
                    endDate: endDate?.toISOString(),
                    comment,
                    invoiceReference,
                    location,
                    customerId: customer.id,
                    externalId,
                };

                await createProject(input);
            }
        },
    };
}
