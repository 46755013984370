import React, { createContext, FC, useContext, useEffect, useState } from 'react';

const hideStyle = document.createElement('style');
hideStyle.id = 'HIDE_SUPPORT_CHAT_STYLE';
hideStyle.innerHTML = `
    html body iframe#LeadboosterContainer {
        display: none !important;
    }
`;

const STORAGE_KEY = 'SUPPORT_CHAT_HIDDEN';

type ISupportChatContext = {
    chatHidden: boolean;
    handleToggleChat: () => void;
};

const defaultValue: ISupportChatContext = {
    chatHidden: false,
    handleToggleChat: () => {},
};

const SupportChatContext = createContext<ISupportChatContext>(defaultValue);

export const SupportChatContextProvider: FC = ({ children }) => {
    const [chatHidden, setChatHidden] = useState(localStorage.getItem(STORAGE_KEY) === 'true');

    const handleToggleChat = () => {
        setChatHidden(!chatHidden);
        localStorage.setItem(STORAGE_KEY, String(!chatHidden));
    };

    useEffect(() => {
        const hasStyle = !!document.getElementById(hideStyle.id);

        if (chatHidden && !hasStyle) {
            document.head.append(hideStyle);
        }

        if (!chatHidden && hasStyle) {
            document.head.removeChild(hideStyle);
        }
    }, [chatHidden]);

    return (
        <SupportChatContext.Provider value={{ chatHidden, handleToggleChat }}>{children}</SupportChatContext.Provider>
    );
};

export const useSupportChatContext = () => useContext(SupportChatContext);
