import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

import { COLORS } from '~/@components/@theme/colors';

const style = { fill: 'transparent' };

export const CornerArrowIcon = ({ stroke = COLORS.gray, ...props }: SvgIconProps) => {
    return (
        <SvgIcon width="24" height="25" viewBox="0 0 24 25" stroke={stroke} style={style} {...props}>
            <path d="M15 10.959L20 15.959L15 20.959" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M4 4.95898V11.959C4 13.0199 4.42143 14.0373 5.17157 14.7874C5.92172 15.5376 6.93913 15.959 8 15.959H20"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
