import { replaceRouteParams, routes } from '@common/routes';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import { COLOR } from '~/@sochi-components/@theme/styles';
import { DocumentIcon } from '~/@sochi-components/Icons';
import { ProjectQuery_project } from '~/graphql';

import offersStore from '../../../../../stores/offersStore';
import { TopBarMenuItem } from '../TopBarMenuItem';

type IOffersLinkProps = {
    project?: ProjectQuery_project | null;
};

@observer
export class OffersLink extends Component<IOffersLinkProps> {
    bem = getBem(this);

    componentDidMount() {
        offersStore.loadOffersCount();
    }

    getProjectId() {
        return this.props.project?.id;
    }

    getOffersLink = () => {
        // TODO: we need same logic for landfill
        const projectId = this.getProjectId();
        if (projectId) {
            return replaceRouteParams(routes.sochi.offerOutgoing, { projectId });
        }

        return replaceRouteParams(routes.sochi.offerOutgoings);
    };

    render() {
        const { className } = this.bem;

        return (
            <TopBarMenuItem
                className={className}
                name={<LocalizableText code={'offers'} />}
                to={this.getOffersLink()}
                badge={offersStore.offersCount}
                icon={<DocumentIcon htmlColor={COLOR.brand} fontSize="inherit" />}
            />
        );
    }
}
