import { FormikProps } from 'formik';
import React from 'react';

import { ILocation } from '~/@store/locations';
import { SochiLocationEdit } from '~/@views/UserView/common/SochiLocationEdit/SochiLocationEdit';
import { useUserAbilities } from '~/contexts';
import { LocationType } from '~/graphql';
import { canChangeProjectLocation } from '~/utils/auth';

import { FieldName } from '../constants';
import type { ProjectFormProps, ProjectFormValues, ProjectLocationProps } from '../ProjectForm';

export const ProjectLocationSelect = (
    props: ProjectFormProps & FormikProps<ProjectFormValues> & ProjectLocationProps
) => {
    const abilityContext = useUserAbilities();
    const { project, values, setFieldValue } = props;

    const onInputLocation = (location: Partial<ILocation>) => {
        setFieldValue(FieldName.location, { ...location, type: LocationType.UNKNOWN });
    };

    const onChangeLatLng = (lat: number | undefined, lng: number | undefined) => {
        if (lat && lng) {
            props.getByLatLng(lat, lng).then(result => setFieldValue(FieldName.location, result));
        } else {
            setFieldValue(FieldName.location, { ...values.location, lat, lng });
        }
    };

    const onChangeText = (text: string) => {
        if (text) {
            props.getByText(text).then(result => setFieldValue(FieldName.location, result));
        } else {
            setFieldValue(FieldName.location, { ...values.location, text });
        }
    };

    return (
        <SochiLocationEdit
            location={values.location}
            onInputLocation={onInputLocation}
            readOnly={props.loading || !!(project && !canChangeProjectLocation(abilityContext, project))}
            onChangeLatLng={onChangeLatLng}
            onChangeText={onChangeText}
            hideCoordinates
        />
    );
};
