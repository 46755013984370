import { action, observable } from 'mobx';

export class FilterStore<FilterValues extends object> {
    constructor(
        private readonly onChangeCallback: () => void = () => {},
        private initialValues: FilterValues = {} as FilterValues
    ) {}

    @observable
    values: FilterValues = { ...this.initialValues };

    @action
    setValue<Key extends keyof FilterValues>(field: Key, value: FilterValues[Key]) {
        this.values[field] = value;
        this.onChangeCallback();
    }

    @action
    clearValues() {
        this.values = { ...this.initialValues };
        this.onChangeCallback();
    }
}
