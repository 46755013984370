import { Colors, DumpLoadFields, Engine, ErrorCodes, Fuel, LoadStatus, UserContextRole, UserRole } from './enums';

export const appStoreLink = 'https://apps.apple.com/by/app/pinpointer/id1470055165';

export const googlePlayLink = 'https://play.google.com/store/apps/details?id=se.pinpointer.mobile';

export const TransportScheduleWeekFormat = "RRRR-'W'II";

export const FieldsAffectsTransportSchedule: DumpLoadFields[] = [
    DumpLoadFields.date,
    DumpLoadFields.endDate,
    DumpLoadFields.amount,
    DumpLoadFields.unitOfMeasure,
    DumpLoadFields.dumpTypeId,
];

export const FieldsAffectsDestinationRecalculate: DumpLoadFields[] = [
    DumpLoadFields.transportLine,
    DumpLoadFields.amount,
    DumpLoadFields.unitOfMeasure,
    DumpLoadFields.dumpTypeId,
    DumpLoadFields.over50cm,
    DumpLoadFields.stackability,
    DumpLoadFields.withTransport,
    DumpLoadFields.solidState,
    DumpLoadFields.leachingState,
    DumpLoadFields.substances,
    DumpLoadFields.withTOC,
    DumpLoadFields.withInvasiveSpecies,
    DumpLoadFields.skipMatching,
    DumpLoadFields.inbound,
];

export const LoadStatusesForCustomerPrice = new Set([
    LoadStatus.CONFIRMED,
    LoadStatus.ORDERED,
    LoadStatus.IN_PROGRESS,
    LoadStatus.DONE,
]);

export const getDefaultWorkTime = () => [
    {
        dayNumber: 1,
        isOpen: true,
        openFrom: '00:00',
        openTo: '23:59',
    },
    {
        dayNumber: 2,
        isOpen: true,
        openFrom: '00:00',
        openTo: '23:59',
    },
    {
        dayNumber: 3,
        isOpen: true,
        openFrom: '00:00',
        openTo: '23:59',
    },
    {
        dayNumber: 4,
        isOpen: true,
        openFrom: '00:00',
        openTo: '23:59',
    },
    {
        dayNumber: 5,
        isOpen: true,
        openFrom: '00:00',
        openTo: '23:59',
    },
    {
        dayNumber: 6,
        isOpen: true,
        openFrom: '00:00',
        openTo: '23:59',
    },
    {
        dayNumber: 7,
        isOpen: true,
        openFrom: '00:00',
        openTo: '23:59',
    },
];

export const StatusColorClasses: Record<string, Colors> = {
    DRAFT: Colors.black,
    IN_PROGRESS: Colors.gray,
    REQUESTED: Colors.orange,
    DONE: Colors.green,
    CONFIRMED: Colors.blue,
    DISCARDED: Colors.red,
};

export const FromScriveErrorToBase: Map<string, ErrorCodes> = new Map([
    ['INTERNAL_ERROR', ErrorCodes.INTERNAL_ERROR],
    ['VALIDATION_ERROR', ErrorCodes.DUMP_LOAD_FIELDS_HAVE_INVALID_VALUES],
    ['EXTERNAL_VALIDATION_ERROR', ErrorCodes.NOT_IMPLEMENTED],
]);

export const GQL_MAX_INT = 2_147_483_647;

export const GQL_MIN_INT = -2_147_483_648;

export const MAX_DUMP_LOAD_AMOUNT = 100_000_000;

export const LIMIT_OF_RECEIPTS_FILES_FOR_DOWNLOAD = 100;

export const VERSION_HEADER = 'X-App-Version';

export const ProjectContextRolesToUserRoles: Partial<Record<UserContextRole, UserRole>> = {
    [UserContextRole.PROJECT_USER]: UserRole.EXTERNAL_RECEIVER,
    [UserContextRole.GUEST]: UserRole.EXTERNAL_RECEIVER,
    [UserContextRole.PROJECT_DRIVER]: UserRole.DRIVER,
    [UserContextRole.PROJECT_EXCAVATOR_OPERATOR]: UserRole.EXCAVATOR_OPERATOR,
};

export const defaultEngine = Engine.Euro6;

export const defaultFuel = Fuel.Diesel;
