import { AbilityCan, AbilitySubjects, UserRole } from '../../enums';
import type { IAbilityFunction, IAbilityUser } from '../abilities.types';

export function defineAbilitiesForLandfillStatus(
    user: IAbilityUser,
    allow: IAbilityFunction,
    _forbid: IAbilityFunction
) {
    if (user.role === UserRole.ADMIN) {
        allow(AbilityCan.CRUD, AbilitySubjects.LANDFILL_STATUS);
    }

    if (user.role === UserRole.EMPLOYEE) {
        allow(AbilityCan.READ, AbilitySubjects.LANDFILL_STATUS);
    }
}
