import uniqueId from 'lodash/uniqueId';
import { action, IObservableArray, observable } from 'mobx';
import React from 'react';

import { NotAnError } from '~/utils/error';

import browserHistory from '../../browserHistory';

type Resolver<T> = (v: T) => void;

export type Renderer<T> = (closeDialog: Resolver<T>) => React.ReactNode;

const noop = () => {};

export class AdminDialog<T> {
    key = uniqueId();
    resolve: (v: T) => void = noop;
    reject: (e: Error) => void = noop;

    constructor(public render: Renderer<T>) {}
}

class AdminDialogStore {
    constructor() {
        browserHistory.listen(this.closeAllDialogs);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dialogs: IObservableArray<AdminDialog<any>> = observable([]);

    closeAllDialogs = () => {
        this.dialogs.forEach(d => {
            d.reject(new NotAnError('Reject dialog due location change'));
        });
    };

    @action
    showDialog = <T>(render: Renderer<T>): Promise<T> => {
        const dialog = new AdminDialog<T>(render);

        return new Promise<T>((resolve, reject) => {
            dialog.resolve = resolve;
            dialog.reject = reject;
            this.dialogs.push(dialog);
        }).finally(() => {
            this.dialogs.remove(dialog);
        });
    };
}

export const adminDialogStore = new AdminDialogStore();
