import { LIMIT_OF_RECEIPTS_FILES_FOR_DOWNLOAD } from '@common/constants';
import { chunk } from 'lodash';
import { action, computed, observable } from 'mobx';

const CHUNK_SIZE = LIMIT_OF_RECEIPTS_FILES_FOR_DOWNLOAD;

type State = 'IDLE' | 'DOWNLOADING' | 'CANCELED';

export class BulkDownloadStore {
    constructor(private downLoadFunc: (ids: string[], index: number) => Promise<unknown>, private ids: string[]) {}

    @observable
    loaded: number = 0;

    @action
    setLoaded(index: number) {
        this.loaded = index * CHUNK_SIZE;
    }

    get total() {
        return this.ids.length;
    }

    @observable
    state: State = 'IDLE';

    @action
    setState(v: State) {
        this.state = v;
    }

    cancel = () => this.setState('CANCELED');

    @computed
    get canceled() {
        return this.state === 'CANCELED';
    }

    @computed
    get started() {
        return this.state !== 'IDLE';
    }

    async download() {
        this.setState('DOWNLOADING');
        const chunks = chunk(this.ids, CHUNK_SIZE);

        for (let i = 0; i < chunks.length; i++) {
            if (this.canceled) return;

            await this.downLoadFunc(chunks[i]!, i);

            this.setLoaded(i + 1);
        }
    }
}
