import { ContaminationType } from '@common/enums';
import { makeStyles } from '@material-ui/core';
import React from 'react';

import { COLORS } from '~/@components/@theme/colors';
import { CustomTabs } from '~/@components/CustomTabs';
import { ToxicLimitsIcon } from '~/@components/Icon/ToxicLimitsIcon';
import { LocalizableText } from '~/@components/LocalizableText';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { ToxicLimitsStore, useToxicLimitsData, useToxicLimitsUpdate } from '~/@store/toxicLimits';
import { ToxicLimitsQuery } from '~/graphql';

import { SumRulesTab } from './SumRules';
import { ToxicLimitsTable } from './ToxicLimitsTable';

export const ToxicLimitsPage = () => {
    const { content } = useStyles();

    const query = useToxicLimitsData();
    const [updateFunc] = useToxicLimitsUpdate();

    return (
        <QueryStateHandler query={query} dataGetter={query => query.data}>
            {(data: ToxicLimitsQuery) => (
                <CustomTabs
                    headerIcon={<ToxicLimitsIcon fill={COLORS.black} />}
                    tabs={[
                        {
                            label: <LocalizableText code={'ToxicLimitsPage.solidLimits'} />,
                            render: () => (
                                <ToxicLimitsTable
                                    store={new ToxicLimitsStore(data, ContaminationType.SOLID, updateFunc)}
                                />
                            ),
                        },
                        {
                            label: <LocalizableText code={'ToxicLimitsPage.faSumRules'} />,
                            render: () => <SumRulesTab rules={data.toxicSumRules} substances={data.substances} />,
                        },
                        {
                            label: <LocalizableText code={'ToxicLimitsPage.leachingLimits'} />,
                            render: () => (
                                <ToxicLimitsTable
                                    store={new ToxicLimitsStore(data, ContaminationType.LEACHING, updateFunc)}
                                />
                            ),
                        },
                    ]}
                    title={<LocalizableText code={'Admin.toxicLimits'} />}
                    contentClassName={content}
                />
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(() => ({
    content: {
        backgroundColor: COLORS.grayLighter,
    },
}));
