import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { COLORS } from '~/@components/@theme/colors';
import { Chip } from '~/@components/Chip';
import { removeEmptyKeys } from '~/@store/common';
import { DumpLoadFilterFields, dumpLoadsStore, ToxicAnalysisApprovalState } from '~/@store/dumpLoads';
import { formatFilterValue } from '~/@views/AdminView/common/utils';
import i18n from '~/i18n';
import { dumpLoadModeFilterValues } from '~/utils/dumpLoad';

type Props = {};

const FieldNames: Record<DumpLoadFilterFields, string> = {
    [DumpLoadFilterFields.serialNumber]: i18n.DumpLoadsTable.orderNumber,
    [DumpLoadFilterFields.projectName]: i18n.DumpLoadsTable.projectName,
    [DumpLoadFilterFields.customerName]: i18n.DumpLoadsTable.customer,
    [DumpLoadFilterFields.dumpType]: i18n.DumpLoadsTable.dumpType,
    [DumpLoadFilterFields.toxicAnalysisApprovalState]: i18n.DumpLoadsTable.analysisApproved,
    [DumpLoadFilterFields.contractSigned]: i18n.DumpLoadsTable.contractSigned,
    [DumpLoadFilterFields.hasToxicAnalysisFiles]: i18n.DumpLoadsTable.toxicAnalysis,
    [DumpLoadFilterFields.FANumber]: i18n.DumpLoadsTable.FANumber,
    [DumpLoadFilterFields.comments]: i18n.DumpLoadsTable.comments,
    [DumpLoadFilterFields.landfillName]: i18n.DumpLoadsTable.receiver,
    [DumpLoadFilterFields.ownerName]: i18n.DumpLoadsTable.salesResponsible,
    [DumpLoadFilterFields.siteConfirmed]: i18n.DumpLoadsTable.siteConfirmed,
    [DumpLoadFilterFields.creditCheck]: i18n.DumpLoadsTable.creditCheck,
    [DumpLoadFilterFields.solidTestResult]: i18n.DumpLoadsTable.contaminationLevel,
    [DumpLoadFilterFields.joinCode]: i18n.DumpLoadsTable.joinCode,
    [DumpLoadFilterFields.mode]: i18n.DumpLoadsTable.mode,
};

export const DumpLoadsFilterSummary = (_props: Props) => {
    const filterValues = useObservable(() => removeEmptyKeys({ ...dumpLoadsStore.filter.values }));
    const { root, chip } = useStyles();

    return (
        <div className={root}>
            {Object.keys(filterValues).map(objectKey => {
                const key = objectKey as DumpLoadFilterFields;
                const value = filterValues[key];
                if (value === null || value === undefined) return null;
                const formattedValue =
                    key === DumpLoadFilterFields.toxicAnalysisApprovalState
                        ? i18n.DumpLoadsTable.analysisApprovalState[value as ToxicAnalysisApprovalState]
                        : key === DumpLoadFilterFields.mode
                        ? dumpLoadModeFilterValues.find(v => v.value === value)?.name
                        : formatFilterValue(value);

                const chipContent = `${FieldNames[key]}: "${formattedValue}"`;

                return (
                    <Chip
                        key={key}
                        className={chip}
                        color={COLORS.black}
                        onDelete={() => dumpLoadsStore.filter.setValue(key, null)}
                        deleteIcon={<Icon>close</Icon>}>
                        {chipContent}
                    </Chip>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        marginRight: theme.spacing(1),
    },
}));
