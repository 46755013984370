import { useQuery } from '@apollo/client';

import { DeliveryLineQuery, DeliveryLineQueryVariables } from '~/graphql';

import * as queries from '../deliveryLines.queries';

export const useDeliveryLine = (id: string) => {
    return useQuery<DeliveryLineQuery, DeliveryLineQueryVariables>(queries.DeliveryLineQuery, {
        variables: { id },
        fetchPolicy: 'network-only',
    });
};
