import { makeStyles } from '@material-ui/core';
import React, { VFC } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn, Table } from '~/@components/Table';
import { BlacklistItemField, IBlacklistItem } from '~/@store/blacklist';

import { BlacklistFilterSummary } from './BlacklistFilterSummary';
import { DeleteFromBlacklistCell } from './DeleteFromBlacklistCell';
import { BlacklistHeadCell } from './HeadCell';

type Props = {
    list: IBlacklistItem[];
};

export const BlacklistTable: VFC<Props> = ({ list }) => {
    const { table, nameCell, actionCell } = useStyles();

    const columns: ITableColumn<IBlacklistItem>[] = [
        {
            title: (
                <BlacklistHeadCell
                    title={<LocalizableText code={'BlacklistPage.item'} />}
                    fieldName={BlacklistItemField.email}
                    withFilter
                />
            ),
            render: item => item.email,
            cellClassName: nameCell,
            name: 'email',
        },
        {
            title: null,
            render: item => <DeleteFromBlacklistCell item={item} />,
            cellClassName: actionCell,
            name: 'delete',
        },
    ];

    return (
        <>
            <Table<IBlacklistItem>
                columns={columns}
                items={list}
                keyGetter={i => i.id}
                emptyListMessage={<LocalizableText code={'BlacklistPage.noDataFound'} />}
                className={table}
                name="BlacklistTable"
            />
            <BlacklistFilterSummary />
        </>
    );
};

const useStyles = makeStyles(theme => ({
    table: {
        '& .MuiTableContainer-root': {
            maxHeight: 'calc(100vh - 190px)',
        },
        '& .MuiTable-root': {
            width: '100%',
            minWidth: '100%',
        },
        marginBottom: theme.spacing(1),
    },
    nameCell: {
        // to grow
        width: '99%',
    },
    actionCell: {
        minWidth: 'unset',
        // to shrink correctly
        whiteSpace: 'nowrap',
    },
}));
