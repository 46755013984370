import './SochiMessage.scss';

import React, { VFC } from 'react';

import { Button } from '~/@components/Button';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { MessageModel } from '~/stores/models';

type SochiMessageProps = {
    onClick: () => void | Promise<void>;
    message: MessageModel;
};

export const SochiMessage: VFC<SochiMessageProps> = ({ message, onClick }) => {
    const { block, element } = useFunctionalBem(SochiMessage);

    return (
        <div className={block(message.type)} onClick={onClick}>
            {message.message}

            {message.action && (
                <Button
                    className={element('button')}
                    color="info"
                    variant="outlined"
                    onClick={message.action.callback}
                    size="small">
                    {message.action.label}
                </Button>
            )}
        </div>
    );
};
