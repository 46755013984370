import { gql } from '@apollo/client';

export const LocationFragment = gql`
    fragment LocationFragment on Location {
        lat
        lng
        text
        type
    }
`;

export const TextGeocode = gql`
    query Geocode($text: String!) {
        geocode(text: $text) {
            ...LocationFragment
        }
    }

    ${LocationFragment}
`;

export const ReverseGeocode = gql`
    query ReverseGeocode($location: LocationReverseInput!) {
        reverseGeocode(location: $location) {
            ...LocationFragment
        }
    }

    ${LocationFragment}
`;
