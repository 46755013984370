import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { COLOR } from '~/@sochi-components/@theme';
import brandSign from '~/@views/common/assets/pinpointerBrandSign.png';
import logo from '~/@views/common/assets/pinpointerLogotypeWhite.png';
import { ProjectQuery_project } from '~/graphql';

type Props = {
    project: Pick<ProjectQuery_project, 'name' | 'location' | 'joinCode'>;
};

export const ProjectJoinCodeContent: FC<Props> = ({ project }) => {
    const { content, codeStyle, logoLayout } = useStyles();

    return (
        <>
            <div className={logoLayout}>
                <img src={brandSign} height={80} alt="Pinpointer img" />
                <img src={logo} alt="Pinpointer logo" />
            </div>
            <div className={content}>
                <Text variant="TitleBold">{project.name}</Text>
                <Text variant="TitleRegular">{project.location.text}</Text>

                <p className={codeStyle}>{project.joinCode}</p>

                <Text variant="TitleRegular">
                    <LocalizableText code="supportInfo" />
                </Text>
            </div>
        </>
    );
};

const useStyles = makeStyles(() => ({
    logoLayout: {
        width: 800,
        backgroundColor: COLOR.mainBlue,
        '& img': {
            marginRight: 40,
        },
    },
    content: {
        width: 800,
        padding: 60,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 24,
        backgroundColor: 'white',
    },
    codeStyle: {
        fontSize: 40,
        fontWeight: 'bold',
        letterSpacing: 20,
    },
}));
