import './LandfillDeclarations.scss';

import React from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import { DocumentCard } from '~/@sochi-components/cards/DocumentCard';
import { NoDocumentation } from '~/@sochi-components/cards/NoDocumentation';
import { SochiSpoilerView } from '~/@sochi-components/SochiSpoilerView';
import type { LandfillQuery_landfill_ordersInfo } from '~/graphql';
import i18n from '~/i18n';
import { downloadDeclarationOfWaste, downloadLandfillDeclarations } from '~/utils/declaration';

import type { LandfillDetailsDataProps } from '../../LandfillPage';

type IOrderInfo = LandfillQuery_landfill_ordersInfo;

export class LandfillDeclarations extends React.Component<LandfillDetailsDataProps> {
    bem = getBem(this);

    handleOpen = (order: IOrderInfo) => () => {
        const params = { orderNumber: order.orderNumber };

        const ignoredPromise = downloadDeclarationOfWaste(params, order.orderNumber);
    };

    handleDownloadAll = () => {
        const { landfill } = this.props;
        const params = { landfillId: landfill.id };

        const ignoredPromise = downloadLandfillDeclarations(params, landfill.name);
    };

    filterOrders = (search: string, orders: IOrderInfo[]): IOrderInfo[] => {
        if (!search) return orders;

        const re = new RegExp(search, 'i');

        return orders.filter(o =>
            [o.dumpLoad.serialNumber || '', o.orderNumber, o.dumpLoad.dumpType.name].some(s => re.test(s))
        );
    };

    render() {
        const { landfill } = this.props;
        const orders = landfill.ordersInfo;
        const { className, element } = this.bem;

        return (
            <div className={className}>
                <SochiSpoilerView
                    title={<LocalizableText code={'declarationOfWaste'} />}
                    cropClassName={element('crop-cards')}
                    withSearch
                    buttons={
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={orders.length === 0}
                            onClick={this.handleDownloadAll}>
                            <LocalizableText code={'ProjectDocuments.buttons.downloadAll'} />
                        </Button>
                    }>
                    {(search: string) => {
                        const filteredOrders = this.filterOrders(search, orders);
                        if (filteredOrders.length === 0) return <NoDocumentation />;

                        return (
                            <div className={element('waste-cards')}>
                                {filteredOrders.map(order => (
                                    <DocumentCard
                                        key={order.id}
                                        onClick={this.handleOpen(order)}
                                        status={order.dumpLoad.status}
                                        name={order.dumpLoad.dumpType.name || i18n.NA}
                                        number={order.dumpLoad.serialNumber || ''}
                                        addon={order.orderNumber}
                                        viewButtonTitle={<LocalizableText code={'inspect'} />}
                                    />
                                ))}
                            </div>
                        );
                    }}
                </SochiSpoilerView>
            </div>
        );
    }
}
