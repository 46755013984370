import { makeStyles, Radio } from '@material-ui/core';
import { FormikContextType } from 'formik';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { LandfillStatusesIcons } from '~/@store/landfillStatuses';
import { LandfillStatusIcon } from '~/@views/AdminView/common/LandfillStatusIcon';

import { ILandfillStatusFormValues } from './LandfillStatusFormik';

type Props = {
    formik: FormikContextType<ILandfillStatusFormValues>;
    disabled?: boolean;
};

export const LandfillStatusIconSelectField = ({ formik, disabled }: Props) => {
    const { radioItem, radioList } = useStyles();
    const selectedIcon = formik.values.icon;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const icon = e.target.value;
        if (!(LandfillStatusesIcons as ReadonlyArray<string>).includes(icon)) return;
        formik.setFieldValue('icon', icon);
    };

    return (
        <div>
            <Text variant="TableBold" color="textSecondary">
                <LocalizableText code={'LandfillStatuses.chooseColor'} />
            </Text>
            <div className={radioList}>
                {LandfillStatusesIcons.map(icon => (
                    <label key={icon} className={radioItem}>
                        <LandfillStatusIcon status={{ icon, name: '' }} withTooltip={false} />
                        <Radio
                            disabled={formik.isSubmitting || disabled}
                            value={icon}
                            checked={icon === selectedIcon}
                            onChange={handleChange}
                        />
                    </label>
                ))}
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    radioList: {
        display: 'flex',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    radioItem: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(),
    },
}));
