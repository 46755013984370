import { useQuery } from '@apollo/client';
import { LandfillFields } from '@common/enums';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { Select } from '~/@components/Select';
import { QueryResult } from '~/@sochi-components/QueryResult';
import * as queries from '~/@store/landfillStatuses/landfillStatuses.queries';
import type { LandfillStatusesListQuery } from '~/graphql';
import { canChangeLandfillField, canReadLandfillField } from '~/utils/auth';

import { FormNames } from './landfillFormikHOC';
import { useLandfillFormContext } from './LandfillFormWithContext';

type Props = {
    className?: string;
};

export const LandfillStatusSelect = ({ className }: Props) => {
    const context = useLandfillFormContext();
    const { user, landfill, values, ability, errors, touched, setFieldValueAndTouchIt, readOnly, isSubmitting } =
        context;

    const canReadField = canReadLandfillField(user, ability, landfill, LandfillFields.status);

    const statusesQuery = useQuery<LandfillStatusesListQuery>(queries.LandfillStatusesListQuery, {
        skip: !canReadField,
    });

    if (!canReadField) return null;

    return (
        <QueryResult
            onRetry={() => statusesQuery.refetch()}
            error={statusesQuery.error}
            loading={statusesQuery.loading}>
            <Select
                className={className}
                label={<LocalizableText code={'LandfillForm.chooseLandfillStatus'} />}
                error={Boolean(errors[FormNames.status])}
                helperText={touched[FormNames.status] ? errors[FormNames.status] : null}
                items={statusesQuery.data?.landfillStatuses || []}
                value={values[FormNames.status]}
                onChange={s => setFieldValueAndTouchIt(FormNames.status, s, true)}
                keyGetter={s => s.id}
                renderValue={s => s.name}
                addEmptyOption={false}
                disabled={
                    isSubmitting || readOnly || !canChangeLandfillField(user, ability, landfill, LandfillFields.status)
                }
            />
        </QueryResult>
    );
};
