import './ProjectSchedule.scss';

import { Granularity, LoadStatus } from '@common/enums';
import { replaceRouteParams, routes } from '@common/routes';
import React, { useCallback, useMemo, useState, VFC } from 'react';
import { useHistory } from 'react-router';

import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { MassCard } from '~/@sochi-components/cards/MassCard';
import { ScheduleChart } from '~/@sochi-components/charts/ScheduleChart';
import { ClickAwayListener } from '~/@sochi-components/ClickAwayListener';
import type { GranularitySwitchValue } from '~/@sochi-components/SochiGranularitySwitch';
import { SochiGranularitySwitch } from '~/@sochi-components/SochiGranularitySwitch';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import type { ProjectQuery_project, ProjectQuery_project_dumpLoads } from '~/graphql';
import { showCustomDialog } from '~/services/dialog';
import type { SeriesData } from '~/utils';
import { SeriesIntervalValue } from '~/utils';
import { convertDumpLoadVolumesToSeriesData } from '~/utils/dumpLoad';

import { ProjectDoneActionHeader } from '../common/ProjectDoneActionHeader';
import { ProjectTransportScheduleContainer } from '../common/ProjectTransportSchedule';

type ProjectScheduleProps = {
    project: ProjectQuery_project;
};

const statusesToShow = [LoadStatus.ORDERED, LoadStatus.IN_PROGRESS, LoadStatus.DONE];

export const ProjectSchedule: VFC<ProjectScheduleProps> = ({ project }) => {
    const { className, element } = useFunctionalBem(ProjectSchedule);
    const [filter, setFilter] = useState<GranularitySwitchValue>(Granularity.week);
    const history = useHistory();

    const onInspect = useCallback(
        (massId: string) => {
            history.push({
                pathname: replaceRouteParams(routes.sochi.projectMassDetail, {
                    projectId: project.id,
                    massId,
                }),
            });
        },
        [history, project]
    );

    const openCard = useCallback(
        (mass: ProjectQuery_project_dumpLoads) => {
            showCustomDialog({
                render: closeDialog => (
                    <ClickAwayListener onClickAway={() => closeDialog(false)}>
                        <MassCard
                            mass={mass}
                            action={{
                                labelCode: 'inspect',
                                onClick: () => {
                                    closeDialog(false);
                                    onInspect(mass.id);
                                },
                                disabled: !mass.deliveredVolume,
                            }}
                        />
                    </ClickAwayListener>
                ),
                noContainer: true,
            });
        },
        [onInspect]
    );

    const seriesData = useMemo<SeriesData[]>(
        () =>
            project.dumpLoads
                .filter(d => statusesToShow.includes(d.status!))
                .map(d => {
                    const seriesData = convertDumpLoadVolumesToSeriesData(d);
                    seriesData.onClick = () => openCard(d);

                    return seriesData;
                }),
        [project, openCard]
    );

    const renderCell = (cell: SeriesIntervalValue) => {
        return <Text variant="TableBold">{cell.planned || ''}</Text>;
    };

    return (
        <div className={className}>
            <ProjectDoneActionHeader project={project} />
            <SochiTitle title={<LocalizableText code={'transportSchedule'} />}>
                <ProjectTransportScheduleContainer className={element('edit-schedule')} project={project} />
                <SochiGranularitySwitch onChange={setFilter} value={filter} />
            </SochiTitle>
            <ScheduleChart data={seriesData} filter={filter} renderCellContent={renderCell} />
        </div>
    );
};
