import { USER_VIEW_MODE } from '@common/viewModes';
import { observer } from 'mobx-react';
import React from 'react';

import { MenuGrow } from '~/@components/MenuGrow';
import { usersStore } from '~/@store/users';
import i18n from '~/i18n';

type Item = {
    label: string;
    mode: USER_VIEW_MODE;
};

const items: Item[] = [
    {
        label: i18n.UsersTable.active,
        mode: USER_VIEW_MODE.USERS,
    },
    {
        label: i18n.UsersTable.deleted,
        mode: USER_VIEW_MODE.DELETED_USERS,
    },
];

const render = (item: Item) => item.label;

export const UserModeSwitch = observer(() => {
    const onChange = (item: Item) => usersStore.setMode(item.mode);
    const mode = usersStore.mode;
    const value = items.find(item => item.mode === mode);

    return <MenuGrow renderValue={render} items={items} onChange={onChange} value={value} />;
});
