import isString from 'lodash/isString';

function isEmpty(v: unknown): boolean {
    if (Array.isArray(v)) {
        return v.every(isEmpty);
    }

    return isString(v) ? v.trim() === '' : v === null || v === undefined;
}

export function removeEmptyKeys<T extends Object>(obj: T): T {
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && isEmpty(obj[key])) delete obj[key];
    }

    return obj;
}

/** https://fettblog.eu/typescript-match-the-exact-object-shape/ */
export type ValidateExact<T, Shape> = T extends Shape
    ? Exclude<keyof T, keyof Shape> extends never
        ? T
        : never
    : never;

export const getExactValidator =
    <Shape>() =>
    <T>(input: ValidateExact<T, Shape>) =>
        input;

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
