import { Button, Divider, Grid } from '@material-ui/core';
import React from 'react';

import i18n from '~/i18n';

import { useDeliveryLineFormikContext } from './DeliveryLineFormikCommon';

type Props = {
    onClose: (v: boolean) => void;
};

export const DeliveryLineEditActions = ({ onClose }: Props) => {
    const formik = useDeliveryLineFormikContext();

    const onSave = () => {
        formik.submitForm().then(() => {
            if (formik.isValid) onClose(true);
        });
    };

    const onCancel = () => {
        formik.handleReset();
        onClose(false);
    };

    return (
        <Grid container spacing={3} justify="space-between">
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item>
                <Button disabled={!formik.dirty || formik.isSubmitting} onClick={onCancel} variant="contained">
                    {i18n.cancel}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    disabled={!formik.dirty || formik.isSubmitting}
                    onClick={onSave}
                    variant="contained"
                    color="primary">
                    {i18n.save}
                </Button>
            </Grid>
        </Grid>
    );
};
