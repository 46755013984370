import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import { dumpLoadsStore } from '~/@store/dumpLoads/dumpLoads.store';
import {
    AdminDumpLoadsPagedQuery as IAdminDumpLoadsPagedQuery,
    AdminDumpLoadsPagedQuery_dumpLoadsPaged,
    AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node,
    AdminDumpLoadsPagedQueryVariables,
} from '~/graphql';

import { AdminDumpLoadsPagedQuery } from '../dumpLoads.queries';

export type IDumpLoad = AdminDumpLoadsPagedQuery_dumpLoadsPaged_edges_node;

export type IDumpLoadsPaged = AdminDumpLoadsPagedQuery_dumpLoadsPaged;

export const usePagedDumpLoads = (): QueryResult<IAdminDumpLoadsPagedQuery, AdminDumpLoadsPagedQueryVariables> => {
    const variables: AdminDumpLoadsPagedQueryVariables = useDebouncedObservable(
        () => dumpLoadsStore.dumpLoadsQueryVariables,
        TIMEOUT_ON_QUERY_DEBOUNCE
    );

    return useQuery<IAdminDumpLoadsPagedQuery, AdminDumpLoadsPagedQueryVariables>(AdminDumpLoadsPagedQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
        onCompleted: data => dumpLoadsStore.pagination.onQueryComplete(data.dumpLoadsPaged),
    });
};
