import { DistributiveOmit } from '@types';
import classNames from 'classnames';
import { isObject, isString } from 'lodash';
import React, { Component, ComponentType, forwardRef, FunctionComponent } from 'react';

type IModifierObject = Record<string, boolean | null | void>;

export type IBemBag = {
    className: string;
    block: (modifiers?: string | IModifierObject) => string;
    element: (element: string, modifiers?: string | IModifierObject) => string;
    childElement: () => void;
};

export type IBemBagProps = { bem: IBemBag };

type PropsWithOptionalClassName = { className?: string | null | undefined; [key: string]: unknown };

function __generateModifiersClassNames(baseClassName: string, modifiers?: string | IModifierObject) {
    if (!modifiers) {
        return baseClassName;
    }

    const modFn = (modifier: string): string => `${baseClassName}--${modifier}`;

    if (isObject(modifiers)) {
        let classes = [baseClassName];
        for (let key in modifiers) {
            if (modifiers.hasOwnProperty.call(modifiers, key) && modifiers[key]) {
                classes.push(modFn(key));
            }
        }

        return classes.join(' ');
    }

    if (isString(modifiers)) {
        return `${baseClassName} ${modFn(modifiers)}`;
    }

    return '';
}

export const block = (owner: string | ComponentType<unknown>, className?: string | null): IBemBag => {
    let ownerName: string;
    if (isString(owner)) {
        ownerName = owner;
    } else {
        ownerName = owner.constructor.name;
    }

    return {
        className: classNames(className, ownerName),
        childElement: () => {
            console.log(owner); // eslint-disable-line
        },
        block: (modifiers?: string | IModifierObject) => {
            return classNames(className, __generateModifiersClassNames(ownerName, modifiers));
        },
        element: (element: string, modifiers?: string | IModifierObject): string => {
            const elementClassName = `${ownerName}__${element}`;

            return __generateModifiersClassNames(elementClassName, modifiers);
        },
    };
};

export const componentName = (WrappedComponent: ComponentType<PropsWithOptionalClassName>): string => {
    if (WrappedComponent.prototype.isReactComponent) {
        return WrappedComponent.name;
    } else {
        return WrappedComponent.prototype.constructor.name;
    }
};

export const bemBlockWithRef = (WrappedComponent: ComponentType<PropsWithOptionalClassName>) =>
    forwardRef<unknown, unknown>((props, ref) => {
        // @ts-ignore deprecated code
        let bem = block(componentName(WrappedComponent), props.className);
        if (WrappedComponent.prototype.isReactComponent) {
            // @ts-ignore deprecated code
            return <WrappedComponent {...props} bem={bem} ref={ref} />;
        } else {
            // @ts-ignore deprecated code
            const StatelessComponent = props => WrappedComponent(props);

            // @ts-ignore deprecated code
            return <StatelessComponent {...props} bem={bem} ref={ref} />;
        }
    });

export const bemBlock =
    <TProps extends { className?: string } & IBemBagProps>(
        WrappedComponent: ComponentType<TProps>
    ): ComponentType<DistributiveOmit<TProps, keyof IBemBagProps>> =>
    (props: DistributiveOmit<TProps, keyof IBemBagProps>) => {
        const className = isString(props.className) ? props.className : undefined;
        let bem = block(componentName(WrappedComponent as ComponentType<unknown>), className);

        const propsWithBem = { ...props, bem } as TProps;

        return <WrappedComponent {...propsWithBem} />;
    };

export const namedBemBlock =
    <TProps extends PropsWithOptionalClassName>(
        name: string,
        WrappedComponent: ComponentType<IBemBagProps & TProps>
    ): ComponentType<TProps> =>
    (props: TProps) => {
        const className = isString(props.className) ? props.className : undefined;
        let bem = block(name, className);

        return <WrappedComponent {...props} bem={bem} />;
    };

const bemCache: Record<string, IBemBag> = {};

function __getBemBag(blockName: string, className: string = '') {
    const cacheName = `${blockName}_${className}`;
    if (!bemCache[cacheName]) {
        bemCache[cacheName] = block(blockName, className);
    }

    return bemCache[cacheName]!;
}

export const getBem = (component: Component<PropsWithOptionalClassName>): IBemBag => {
    const blockName: string = component.constructor.name;
    const customClassName = isString(component.props.className) ? component.props.className : undefined;

    return __getBemBag(blockName, customClassName);
};

export const useFunctionalBem = <P extends unknown>(
    functionalComponent: FunctionComponent<P>,
    customClassName?: string | undefined
) => {
    const blockName = functionalComponent.name;

    return __getBemBag(blockName, customClassName);
};
