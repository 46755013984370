import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';

import { localizationStore } from '~/@store/localization';
import { useUserAbilities } from '~/contexts';

type ILocalizationToggleValue = {
    enabled: boolean;
};

type ILocalizationToggleContext = ILocalizationToggleValue & {
    change: (v: boolean) => void;
};

const defaultValue: ILocalizationToggleContext = {
    enabled: false,
    change: (_: boolean) => {},
};

const LocalizationEditToggleContext = createContext<ILocalizationToggleContext>(defaultValue);

export const LocalizationEditToggleProvider: FC = ({ children }) => {
    const { user } = useUserAbilities();

    useEffect(() => {
        if (user.id) localizationStore.init();
    }, [user.id]);

    const [state, setState] = useState<boolean>(false);

    const change = useCallback((value: boolean) => {
        setState(value);
    }, []);

    return (
        <LocalizationEditToggleContext.Provider value={{ enabled: state, change }}>
            {children}
        </LocalizationEditToggleContext.Provider>
    );
};

export const useLocalizationEditToggle = () => useContext(LocalizationEditToggleContext);
