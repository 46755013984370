import './DialogTitle.scss';

import React from 'react';

import { getBem } from '~/@sochi-components/@bem';

type DialogTitleProps = {
    className?: string;
    secondaryInfo?: React.ReactNode;
    hasCloseButton?: boolean;
    children: React.ReactNode;
};

export class DialogTitle extends React.PureComponent<DialogTitleProps> {
    bem = getBem(this);

    render() {
        const { children, secondaryInfo, hasCloseButton } = this.props;
        const { block, element } = this.bem;

        return (
            <div className={block({ 'has-close-btn': hasCloseButton })}>
                <h2 className={element('title')}>{children}</h2>
                {secondaryInfo && <span className={element('secondary-info')}>{secondaryInfo}</span>}
            </div>
        );
    }
}
