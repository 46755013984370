import { useMutation } from '@apollo/client';

import { despatchAdviceResponsesStore } from '~/@store/beastIntegration/despatchAdviceResponses.store';
import { DespatchAdviceResponsesRequest } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../beastIntegration.queries';

export const useDespatchAdviceResponsesRequest = () => {
    const [callMutation, mutationResult] = useMutation<DespatchAdviceResponsesRequest, {}>(
        queries.DespatchAdviceResponsesRequestMutation
    );

    const requestResponses = () =>
        callMutation({
            refetchQueries: [
                {
                    query: queries.DespatchAdviceResponsesPagedQuery,
                    variables: despatchAdviceResponsesStore.despatchAdviceQueryVariables,
                },
            ],
        })
            .then(res => {
                const newResponsesCount = res.data?.requestAdviceResponses.length || 0;

                if (newResponsesCount) {
                    despatchAdviceResponsesStore.sort.clearSort();
                    despatchAdviceResponsesStore.pagination.onClear();
                }

                return newResponsesCount;
            })
            .catch(handleApolloError);

    return [requestResponses, mutationResult.loading] as const;
};
