import { LandfillFields } from '@common/enums';
import { action, computed, observable } from 'mobx';

import { LandfillFilter, LandfillsPagedQueryVariables } from '~/graphql';

import { FilterStore, locationStore, PaginationStore, SortStore } from '../common';
import { DEFAULT_LANDFILLS_SORT, LANDFILL_VIEW_MODE } from './landfills.constants';
import { getLandfillViewMode } from './landfills.helpers';

export type LandfillsStoreFilterFields = LandfillFields.name | LandfillFields.location | LandfillFields.comment;

export type LandfillsStoreFilter = Record<LandfillsStoreFilterFields, string | null | undefined>;

export enum AdminLandfillColumn {
    name = 'name',
    location = 'location',
    openedAt = 'openedAt',
    gateFee = 'gateFee',
    marginPercent = 'marginPercent',
    minMargin = 'minMargin',
    user = 'user',
    comment = 'comment',
    delete = 'delete',
    capacity = 'capacity',
}

class LandfillsStore {
    pagination = new PaginationStore();
    filter = new FilterStore<LandfillsStoreFilter>(this.pagination.onClear);
    sort = new SortStore(DEFAULT_LANDFILLS_SORT, this.pagination.onClear);

    @observable
    companyId: string | null = null;

    @observable
    hiddenColumns: AdminLandfillColumn[] = [];

    @computed
    get viewMode() {
        return getLandfillViewMode(locationStore.pathname);
    }

    @computed
    get landfillsQueryVariables(): LandfillsPagedQueryVariables {
        const filter: LandfillFilter = {
            ...this.filter.values,
            withDeleted: this.viewMode === LANDFILL_VIEW_MODE.ALL_LANDFILLS,
        };

        if (this.companyId) filter.customerId = this.companyId;

        const sort = this.sort.value;
        const connection = this.pagination.connection;

        return { filter, sort, connection };
    }

    private resetFilterAndSort() {
        this.filter.clearValues();
        this.sort.clearSort();
    }

    @action
    setCompanyTabViewMode(newCompanyId: string | null) {
        this.companyId = newCompanyId;
        this.hiddenColumns = [];
        this.resetFilterAndSort();
    }

    @action
    setLandfillsPageViewMode() {
        this.companyId = null;
        this.hiddenColumns = [];
        this.resetFilterAndSort();
    }
}

export const landfillsStore = new LandfillsStore();
