import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import type { ISettings } from '~/@store/settings';

import { TransportConfigurationFields as TransportConfigFields } from '../../../../../../common/enums';
import { TransportConfigurationField } from './TransportConfigurationField';

type Props = {
    settings: ISettings;
    setSettings: (settings: ISettings) => void;
};

export const TransportConfigurationFields = ({ settings, setSettings }: Props) => {
    const transportConfiguration = settings.transportConfiguration;

    const setTransportConfiguration = (newTransportConfig: ISettings['transportConfiguration']) => {
        setSettings({
            ...settings,
            transportConfiguration: newTransportConfig,
        });
    };

    return (
        <>
            <TransportConfigurationField
                transportConfiguration={transportConfiguration}
                setTransportConfiguration={setTransportConfiguration}
                field={TransportConfigFields.TONNS_PER_TRUCK}
                label={<LocalizableText code={'settingsPage.transportConfig.tonnsPerTruck'} />}
            />
            <TransportConfigurationField
                transportConfiguration={transportConfiguration}
                setTransportConfiguration={setTransportConfiguration}
                field={TransportConfigFields.PRICE_PER_KM}
                label={<LocalizableText code={'settingsPage.transportConfig.pricePerKm'} />}
            />
            <TransportConfigurationField
                transportConfiguration={transportConfiguration}
                setTransportConfiguration={setTransportConfiguration}
                field={TransportConfigFields.PRICE_PER_HOUR}
                label={<LocalizableText code={'settingsPage.transportConfig.pricePerHour'} />}
            />
            <TransportConfigurationField
                transportConfiguration={transportConfiguration}
                setTransportConfiguration={setTransportConfiguration}
                field={TransportConfigFields.TAX}
                label={<LocalizableText code={'settingsPage.transportConfig.tax'} />}
            />
            <TransportConfigurationField
                transportConfiguration={transportConfiguration}
                setTransportConfiguration={setTransportConfiguration}
                field={TransportConfigFields.FIXED_PRICE}
                label={<LocalizableText code={'settingsPage.transportConfig.fixedPrice'} />}
            />
            <TransportConfigurationField
                transportConfiguration={transportConfiguration}
                setTransportConfiguration={setTransportConfiguration}
                field={TransportConfigFields.CO2_PER_KM}
                label={<LocalizableText code={'settingsPage.transportConfig.co2perKm'} />}
            />
        </>
    );
};
