import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { COLORS } from '~/@components/@theme';
import { Button } from '~/@components/Button';

import { FONT_WEIGHT } from '../@theme';

type Props = {
    items: {
        label: React.ReactNode;
        value: string;
    }[];
    action?: {
        label: React.ReactNode;
        onExecute: () => void;
        disabled: boolean;
    };
};

export const Summaries: FC<Props> = ({ items, action }) => {
    const { root, itemsArea, itemCard, label, value } = useStyles();

    return (
        <div className={root}>
            <div className={itemsArea}>
                {items.map((item, n) => (
                    <div className={itemCard} key={`item-${n}`}>
                        <div className={label}>{item.label}</div>
                        <div className={value}>{item.value}</div>
                    </div>
                ))}
            </div>
            {action && (
                <div>
                    <Button
                        variant="contained"
                        size="large"
                        color="success"
                        disabled={action.disabled}
                        onClick={action.onExecute}>
                        {action.label}
                    </Button>
                </div>
            )}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        background: COLORS.white,
        borderTop: `2px solid ${COLORS.brandMain}`,
        padding: '25px 20px',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            padding: '8px 10px',
            gap: '10px',
        },
    },
    itemsArea: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    itemCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    label: {
        color: COLORS.grayDarkest,
        fontSize: '18px',
        fontWeight: FONT_WEIGHT.thin,

        [theme.breakpoints.down('md')]: {
            fontSize: '10px',
        },
    },
    value: {
        fontSize: '28px',
        fontWeight: FONT_WEIGHT.semiBold,

        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
        },
    },
}));
