import React from 'react';

import { SimpleDialog } from '~/@components/Dialog';
import { showCustomDialog } from '~/@store/common';

import { LandfillStatusForm } from './LandfillStatusForm';

type Props = {
    onClose: () => void;
};

export const LandfillStatusCreateDialog = ({ onClose }: Props) => {
    return (
        <SimpleDialog onClose={onClose}>
            <LandfillStatusForm status={null} closeForm={onClose} />
        </SimpleDialog>
    );
};

export const showLandfillStatusCreateDialog = () => {
    const ignoredPromise = showCustomDialog({
        render: closeDialog => <LandfillStatusCreateDialog onClose={() => closeDialog(true)} />,
    });
};
