import { FormikContextType } from 'formik';
import React, { ChangeEvent, useCallback } from 'react';

import { TextField, TextFieldProps } from '~/@components/TextField';

import { transformPhoneNumberInput } from '../../../../common/utils';

type FormikPhoneNumberFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends string ? TFieldName : never;
    formik: FormikContextType<TValues>;
} & Omit<TextFieldProps, 'onChange' | 'value'>;

export function FormikPhoneNumberField<TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    disabled,
    ...textFieldProps
}: FormikPhoneNumberFieldProps<TValues, TFieldName>) {
    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            formik.setFieldTouched(name, true);
            formik.setFieldValue(name, transformPhoneNumberInput(event.target.value));
        },
        [formik, name]
    );

    return (
        <TextField
            fullWidth
            name={name}
            value={formik.values[name]}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[name] && !!formik.errors[name]}
            helperText={formik.touched[name] && formik.errors[name]}
            disabled={disabled || formik.isSubmitting}
            {...textFieldProps}
        />
    );
}
