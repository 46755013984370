import { ContaminationLevel } from '@common/enums';

export const ToxicLimitsSolidColors: Partial<Record<ContaminationLevel, string | undefined>> = {
    [ContaminationLevel.mrr]: '#b8f8c5',
    [ContaminationLevel.km]: '#b8c5f8',
    [ContaminationLevel.mkm]: '#ffe24a',
    [ContaminationLevel.ifa]: '#fc944d',
    [ContaminationLevel.fa]: '#fa5353',
    [ContaminationLevel.limit]: '#FDEBEB',
};

export const ToxicLimitsLeachingColors: Partial<Record<ContaminationLevel, string>> = {
    [ContaminationLevel.inert]: '#FDEBEB',
    [ContaminationLevel.ifa]: '#FDEBEB',
    [ContaminationLevel.limit]: '#FDEBEB',
};
