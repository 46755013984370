import { MAX_DUMP_LOAD_AMOUNT } from '@common/constants';
import { normalizeAmount } from '@common/UnitOfMeasure';
import React from 'react';

import { FormikNumberField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { commonPrecisions } from '~/config/enum';
import { UnitOfMeasure } from '~/graphql';

import { Units } from '../../../../../../../common/enums';
import { useDumpLoadFormikContext } from '../DumpLoadEditFormik';

type Props = { disabled?: boolean };

export const DumpLoadEditAmount = ({ disabled }: Props) => {
    const formik = useDumpLoadFormikContext();

    const additionalInfo =
        formik.values.unitOfMeasure === UnitOfMeasure.CUBIC_METERS
            ? `${normalizeAmount(
                  Units.CUBIC_METERS,
                  Units.TONNS,
                  formik.values.amount,
                  formik.values.dumpType?.tonnsPerCubicM || 1
              )} (Ton)`
            : undefined;

    return (
        <FormikNumberField
            label={<LocalizableText code={'amount'} />}
            name="amount"
            formik={formik}
            min={0}
            max={MAX_DUMP_LOAD_AMOUNT}
            precision={commonPrecisions.dumpLoadAmount}
            helperText={additionalInfo}
            disabled={disabled}
        />
    );
};
