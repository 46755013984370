import { UserRole } from '@common/enums';
import { FormikContextType } from 'formik';
import React, { useMemo } from 'react';

import { FormikSelectField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { USER_ROLES } from '~/@store/users/users.constants';
import { useUserAbilities } from '~/contexts';
import i18n from '~/i18n';
import { canUpdateUserWithRole } from '~/utils/auth';

type FormikUserRoleSelectProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends UserRole | null ? TFieldName : never;
    formik: FormikContextType<TValues>;
    disabled?: boolean;
    label?: React.ReactNode;
};

export const FormikUserRoleSelect = <TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    disabled,
    label = <LocalizableText code={'role'} />,
}: FormikUserRoleSelectProps<TValues, TFieldName>) => {
    const ability = useUserAbilities();
    const options = useMemo(() => USER_ROLES.filter(role => canUpdateUserWithRole(ability, role)), [ability]);

    return (
        <FormikSelectField
            label={label}
            name={name}
            formik={formik}
            items={options}
            renderValue={(role: UserRole) => i18n.UserRole[role]}
            keyGetter={(role: UserRole) => role}
            addEmptyOption={false}
            disabled={formik.isSubmitting || disabled}
        />
    );
};
