import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useState } from 'react';

import { Button } from '~/@components/Button';
import { IDespatchAdviceDefaultSettings } from '~/@store/beastIntegration';
import { IDespatchAdviceSettings, IDumpLoad } from '~/@store/dumpLoads';
import { useApplyDespatchSettingsMutation } from '~/@store/dumpLoads/dumpLoads.hooks/useApplyDespatchSettingsMutation';
import { DumpLoadPeppolSettingsFields } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm/DumpLoadPeppolSettingsDialog/DumpLoadPeppolSettingsFields';
import { DespatchAdviceCategory, DespatchAdviceSettingsInput } from '~/graphql';
import i18n from '~/i18n';

import { IDespatchAdviceSettingsFormValues, useDumpLoadPeppolSettingsFormik } from './DumpLoadPeppolSettingsFormik';

type Props = {
    settings: IDespatchAdviceSettings[];
    defaultSettings: IDespatchAdviceDefaultSettings;
    dumpLoad: IDumpLoad;
    onClose: () => void;
};

const convertFormikValuesToInput = (values: IDespatchAdviceSettingsFormValues): DespatchAdviceSettingsInput => {
    return {
        category: values.category,
        consigneeId: values.consignee?.id || '',
        despatchPartyId: values.despatchParty?.id,
        buyerId: values.buyer?.id || '',
        sellerId: values.seller?.id || '',
        pricePerTon: values.isPriceMandatory ? values.pricePerTon || 0 : null,
    };
};

export const DumpLoadPeppolSettingsForm: FC<Props> = ({ settings, dumpLoad, onClose, defaultSettings }) => {
    const excavatingSettings = settings.find(s => s.category === DespatchAdviceCategory.EXCAVATING) || null;
    const transportingSettings = settings.find(s => s.category === DespatchAdviceCategory.TRANSPORTING) || null;
    const dumpingSettings = settings.find(s => s.category === DespatchAdviceCategory.DUMPING) || null;

    const excavatingFormik = useDumpLoadPeppolSettingsFormik(
        excavatingSettings,
        DespatchAdviceCategory.EXCAVATING,
        defaultSettings,
        true
    );

    const transportingFormik = useDumpLoadPeppolSettingsFormik(
        transportingSettings,
        DespatchAdviceCategory.TRANSPORTING,
        defaultSettings,
        true
    );

    const dumpingFormik = useDumpLoadPeppolSettingsFormik(
        dumpingSettings,
        DespatchAdviceCategory.DUMPING,
        defaultSettings
    );

    const [applySettings, updating] = useApplyDespatchSettingsMutation(dumpLoad);
    const [validating, setValidating] = useState(false);

    const onClick = async () => {
        setValidating(true);
        excavatingFormik
            .submitForm()
            .then(() => excavatingFormik.submitForm())
            .then(() => transportingFormik.submitForm())
            .then(() => dumpingFormik.submitForm())
            .then(() => {
                if (excavatingFormik.isValid && transportingFormik.isValid && dumpingFormik.isValid) {
                    const input: DespatchAdviceSettingsInput[] = [];
                    if (excavatingFormik.values.isActive)
                        input.push(convertFormikValuesToInput(excavatingFormik.values));
                    if (transportingFormik.values.isActive)
                        input.push(convertFormikValuesToInput(transportingFormik.values));
                    if (dumpingFormik.values.isActive) input.push(convertFormikValuesToInput(dumpingFormik.values));

                    applySettings(input).then(onClose);
                }
            })
            .finally(() => setValidating(false));
    };

    const isChanged = excavatingFormik.dirty || transportingFormik.dirty || dumpingFormik.dirty;
    const inProgress = updating || validating;

    const { root, buttons } = useStyles();

    return (
        <div className={root}>
            {inProgress && <LinearProgress />}
            <DumpLoadPeppolSettingsFields formik={excavatingFormik} disabled={inProgress} />
            <DumpLoadPeppolSettingsFields formik={transportingFormik} disabled={inProgress} />
            <DumpLoadPeppolSettingsFields formik={dumpingFormik} disabled={inProgress} />
            <div className={buttons}>
                <Button onClick={onClick} disabled={!isChanged || inProgress} variant="outlined" color="primary">
                    {i18n.save}
                </Button>
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));
