interface IBaseStatus<Type> {
    title: string;
    value: Type;
}

export interface IColoredStatus<Type> extends IBaseStatus<Type> {
    color: string;
}

export interface IImagedStatus<Type> extends IBaseStatus<Type> {
    image: string;
}

export type IStatus<Type> = IColoredStatus<Type> | IImagedStatus<Type>;

export const ENTER_KEY_NAME = 'Enter';

export const ESC_KEY_NAME = 'Esc';

export const DEFAULT_SEARCH_LIMIT = 10;

export const TIMEOUT_ON_QUERY_DEBOUNCE = 750;

export const TIMEOUT_ON_SEARCH_DEBOUNCE = 300;
