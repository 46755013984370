import { useQuery } from '@apollo/client';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { despatchAdviceResponsesStore } from '~/@store/beastIntegration/despatchAdviceResponses.store';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import { DespatchAdviceResponsesPaged, DespatchAdviceResponsesPagedVariables } from '~/graphql';

import { DespatchAdviceResponsesPagedQuery } from '../beastIntegration.queries';

export const useDespatchAdviceResponsesPaged = () => {
    const variables = useDebouncedObservable(
        () => despatchAdviceResponsesStore.despatchAdviceQueryVariables,
        TIMEOUT_ON_QUERY_DEBOUNCE
    );

    return useQuery<DespatchAdviceResponsesPaged, DespatchAdviceResponsesPagedVariables>(
        DespatchAdviceResponsesPagedQuery,
        {
            variables,
        }
    );
};
