import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';

import { FormCardButtons, FormCardContainer } from '~/@components/FormCard';
import { FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { useAccountChangePassword } from '~/@store/account/account.hooks/useAccountChangePassword';
import { useUserPasswordChangeMutation } from '~/@store/users/users.hooks/useUserPasswordChangeMutation';
import { useUserAbilities } from '~/contexts';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';

import { getChangePasswordFormikConfig, IAccountForm } from './ChangePasswordFormik';

type Props = {
    onClose: () => void;
    userId?: string;
};

export const ChangePasswordForm = ({ onClose, userId }: Props) => {
    const { body, buttons } = useStyles();

    const { user: currentUser } = useUserAbilities();

    const [changePassword, isChanging] = useUserPasswordChangeMutation();
    const [changeSelfPassword, isSelfChanging] = useAccountChangePassword();
    const inProgress = isChanging || isSelfChanging;

    const isCurrentUserPassword = !userId || currentUser.id === userId;

    const changePasswordHandler = ({ oldPassword, newPassword, repeatPassword }: IAccountForm) => {
        const promise = isCurrentUserPassword
            ? changeSelfPassword({
                  oldPassword,
                  newPassword,
                  repeatPassword,
              })
            : changePassword({
                  userId,
                  newPassword,
                  repeatPassword,
              });

        promise.then(result => {
            if (!result) return;

            globalMessage.success(i18n.passwordHasBeenChanged);
            onClose();
        });
    };

    const formik = useFormik(getChangePasswordFormikConfig(changePasswordHandler, isCurrentUserPassword));

    const handleCancel = () => {
        onClose();
        formik.resetForm();
    };

    return (
        <FormCardContainer formik={formik} showProgress={inProgress}>
            <Text variant="TitleBold">
                <LocalizableText code={'changePassword'} />
            </Text>
            <div className={body}>
                {isCurrentUserPassword && (
                    <FormikTextField
                        name="oldPassword"
                        label={<LocalizableText code={'AccountPage.oldPassword'} />}
                        type="password"
                        formik={formik}
                    />
                )}
                <FormikTextField
                    name="newPassword"
                    label={<LocalizableText code={'AccountPage.newPassword'} />}
                    type="password"
                    formik={formik}
                />
                <FormikTextField
                    name="repeatPassword"
                    label={<LocalizableText code={'AccountPage.repeatPassword'} />}
                    type="password"
                    formik={formik}
                />
            </div>
            <FormCardButtons formik={formik} onCancel={handleCancel} submitLabel={i18n.save} className={buttons} />
        </FormCardContainer>
    );
};

const useStyles = makeStyles(theme => ({
    body: {
        width: '100%',
        minWidth: 314,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },

    buttons: {
        marginTop: theme.spacing(3),
    },
}));
