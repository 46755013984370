import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
    AddDumpLoadCommentMutation,
    AddDumpLoadCommentMutation_projectLoadAddComment,
    AddDumpLoadCommentMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../dumpLoads.queries';

type AddDumpLoadCommentFunc = (
    projectId: string,
    dumpLoadId: string,
    text: string
) => Promise<AddDumpLoadCommentMutation_projectLoadAddComment>;

export const useAddDumpLoadCommentMutation = (): [AddDumpLoadCommentFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<AddDumpLoadCommentMutation, AddDumpLoadCommentMutationVariables>(
        queries.AddDumpLoadCommentMutation
    );

    const addDumpLoadComment = useCallback<AddDumpLoadCommentFunc>(
        (projectId, dumpLoadId, text) =>
            callMutation({ variables: { projectId, dumpLoadId, text } })
                .catch(handleApolloError)
                .then(result => result.data!.projectLoadAddComment),
        [callMutation]
    );

    return [addDumpLoadComment, mutationResult.loading];
};
