import { AbilitySubjects, UserContextRole } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { getLandfillSubject, IAbilityLandfill } from '../landfills';
import { getProjectSubject, IAbilityProject } from '../projects';
import { IAbilityDeviation, IDeviationCalculatedSubject } from './deviationTypes';

export const getProjectDeviationSubject = (
    user: IAbilityUser,
    project: IAbilityProject,
    deviation: IAbilityDeviation | null
): IDeviationCalculatedSubject => {
    const ps = getProjectSubject(user, project);
    const relationRole: IDeviationCalculatedSubject['relationRole'] = ps.relationRole;
    const subSubject: IDeviationCalculatedSubject['subSubject'] = ps.subSubject;
    if (deviation) subSubject.push(deviation.status);
    if (deviation?.author.id === user.id) relationRole.push(UserContextRole.AUTHOR);

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.PROJECT_DEVIATION,
        relationRole,
        subSubject,
    };
};

export const getLandfillDeviationSubject = (
    user: IAbilityUser,
    landfill: IAbilityLandfill,
    deviation: IAbilityDeviation | null
): IDeviationCalculatedSubject => {
    const ls = getLandfillSubject(user, landfill);
    const relationRole: IDeviationCalculatedSubject['relationRole'] = ls.relationRole;
    const subSubject: IDeviationCalculatedSubject['subSubject'] = ls.subSubject;
    if (deviation) subSubject.push(deviation.status);
    if (deviation?.author.id === user.id) relationRole.push(UserContextRole.AUTHOR);

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.LANDFILL_DEVIATION,
        relationRole,
        subSubject,
    };
};
