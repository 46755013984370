import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/users/users.queries';
import { UserCreate, UserCreateMutation, UserCreateMutation_userCreate, UserCreateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import { usersStore } from '../users.store';

export type UserCreateFunc = (input: UserCreate) => Promise<UserCreateMutation_userCreate | null | undefined>;

export const useUserCreateMutation = (): [UserCreateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<UserCreateMutation, UserCreateMutationVariables>(
        queries.UserCreateMutation
    );

    const createUser = useCallback<UserCreateFunc>(
        input => {
            return callMutation({
                variables: { input },
                refetchQueries: [
                    {
                        query: queries.AdminUsersPagedQuery,
                        variables: usersStore.usersQueryVariables,
                    },
                ],
                awaitRefetchQueries: true,
            })
                .then(({ data }) =>
                    data?.userCreate
                        ? data.userCreate
                        : Promise.reject(new Error('Empty response "UserCreateMutation"'))
                )
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [createUser, mutationResult.loading];
};
