import React, { useCallback, useMemo } from 'react';

import { showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import {
    CheckFormatter,
    ContaminationLevelFormatter,
    CurrencyFormatter,
    DateTimeFormatter,
    NullableFormatter,
    TonnsFormatter,
} from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn } from '~/@components/Table';
import { ActionVariant, TableCellActions } from '~/@components/Table/TableCellActions';
import { IDetailedLandfill, ILandfillSubarea } from '~/@store/landfills';
import { showSubareaUpdateDialog } from '~/@views/AdminView/SubareasPage/SubareaEditForm';
import i18n from '~/i18n';
import { deleteSubarea } from '~/utils/landfill';

export const useLandfillSubareasColumns = (landfill: IDetailedLandfill) => {
    const handleRemove = useCallback(async (landfill: IDetailedLandfill, subarea: ILandfillSubarea) => {
        const confirmed = await showDeleteConfirmationDialog(
            <LocalizableText code={'SubareasTable.confirmDelete'} />,
            subarea.externalId
        );

        if (!confirmed) return;

        await deleteSubarea(landfill.id, subarea.id);
    }, []);

    return useMemo<ITableColumn<ILandfillSubarea>[]>(
        () => [
            {
                title: <LocalizableText code={'SubareasTable.externalId'} />,
                render: row => (
                    <TableCellActions
                        actions={[
                            { variant: ActionVariant.EDIT, onClick: () => showSubareaUpdateDialog(landfill, row) },
                            { variant: ActionVariant.DELETE, onClick: () => handleRemove(landfill, row) },
                        ]}>
                        {row.externalId}
                    </TableCellActions>
                ),
                name: 'externalId',
            },
            {
                title: <LocalizableText code={'SubareasTable.isProvider'} />,
                render: row => <CheckFormatter>{row.isProvider}</CheckFormatter>,
                name: 'isProvider',
            },
            {
                title: <LocalizableText code={'SubareasTable.notOnHold'} />,
                render: row => <CheckFormatter>{!row.onHold}</CheckFormatter>,
                name: 'onHold',
            },
            {
                title: <LocalizableText code={'SubareasTable.dumpType'} />,
                render: row => <NullableFormatter>{row.dumpType?.name}</NullableFormatter>,
                name: 'dumpType',
            },
            {
                title: <LocalizableText code={'SubareasTable.contaminationLevel'} />,
                render: row => <ContaminationLevelFormatter>{row.solidTestResult}</ContaminationLevelFormatter>,
                name: 'solidTestResult',
            },
            {
                title: <LocalizableText code={'SubareasTable.unitOfMeasure'} />,
                render: row => (
                    <NullableFormatter>{row.unitOfMeasure && i18n.unitsOfMeasure[row.unitOfMeasure]}</NullableFormatter>
                ),
                name: 'unitOfMeasure',
            },
            {
                title: <LocalizableText code={'SubareasTable.comment'} />,
                render: row => row.comment,
                name: 'comment',
            },
            {
                title: <LocalizableText code={'SubareasTable.nonStackable'} />,
                render: row => <CheckFormatter>{!row.stackable}</CheckFormatter>,
                name: 'nonStackable',
            },
            {
                title: <LocalizableText code={'SubareasTable.over50'} />,
                render: row => <CheckFormatter>{row.over50cm}</CheckFormatter>,
                name: 'over50',
            },
            {
                title: <LocalizableText code={'SubareasTable.currentAmount'} />,
                render: row => <TonnsFormatter>{row.currentAmount}</TonnsFormatter>,
                name: 'currentAmount',
            },
            {
                title: <LocalizableText code={'SubareasTable.priceOver50cm'} />,
                render: row => <CurrencyFormatter>{row.priceOver50cm}</CurrencyFormatter>,
                name: 'priceOver50cm',
            },
            {
                title: <LocalizableText code={'SubareasTable.priceNotStackable'} />,
                render: row => <CurrencyFormatter>{row.priceNotStackable}</CurrencyFormatter>,
                name: 'priceNotStackable',
            },
            {
                title: <LocalizableText code={'SubareasTable.priceBase'} />,
                render: row => <CurrencyFormatter>{row.priceBase}</CurrencyFormatter>,
                name: 'priceBase',
            },
            {
                title: <LocalizableText code={'SubareasTable.grossPriceBase'} />,
                render: row => <CurrencyFormatter>{row.grossPriceBase}</CurrencyFormatter>,
                name: 'grossPriceBase',
            },
            {
                title: <LocalizableText code={'SubareasTable.priceUpdatedAt'} />,
                render: row => <DateTimeFormatter>{row.priceUpdatedAt}</DateTimeFormatter>,
                name: 'priceUpdatedAt',
            },
        ],
        [landfill, handleRemove]
    );
};
