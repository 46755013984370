import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Text } from '~/@components/Text';
import { COLOR } from '~/@sochi-components/@theme';
import { useBatchLog } from '~/@user-store/landfillBatches';
import { showLandfillBatchAnalysisForm } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchAnalysisForm';
import { LandfillBatchLog } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/LandfillBatchLog';
import { showLandfillBatchTransferForm } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchTransferForm';
import { useUserAbilities } from '~/contexts';
import { BatchLogQuery_batchAnalyses, BatchLogQuery_batchTransfers, LandfillQuery_landfill } from '~/graphql';
import { canUpdateLandfill } from '~/utils/auth';

type Props = {
    landfill: LandfillQuery_landfill;
};

export type IBatchLog = {
    transfers: BatchLogQuery_batchTransfers[];
    analyses: BatchLogQuery_batchAnalyses[];
};

export const LandfillBatchLogPage: FC<Props> = ({ landfill }) => {
    const { batchId } = useParams<{ batchId?: string }>();

    const query = useBatchLog(landfill.id, batchId || '');

    const batch = useMemo(() => landfill.batches.find(b => b.id === batchId), [batchId, landfill]);

    const { root, header } = useStyles();

    const ability = useUserAbilities();

    if (!batchId || !batch) {
        return (
            <Text variant="TitleBold">
                <LocalizableText code={'LandfillPage.batchLog.noDataFound'} />
            </Text>
        );
    }

    const readOnly = !!batch.closedAt || !canUpdateLandfill(ability.user, ability.ability, landfill);

    return (
        <QueryStateHandler
            query={query}
            dataGetter={query => ({
                transfers: query.data?.batchTransfers || query.previousData?.batchTransfers || [],
                analyses: query.data?.batchAnalyses || query.previousData?.batchAnalyses || [],
            })}>
            {(data: IBatchLog) => (
                <div className={root}>
                    <div className={header}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => showLandfillBatchTransferForm(landfill, batch, null)}
                            disabled={readOnly}>
                            <LocalizableText code={'LandfillPage.batchLog.createTransfer'} />
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => showLandfillBatchAnalysisForm(landfill, batch, null)}
                            disabled={readOnly}>
                            <LocalizableText code={'LandfillPage.batchLog.addAnalysis'} />
                        </Button>
                    </div>
                    <LandfillBatchLog landfill={landfill} batch={batch} data={data} disabled={readOnly} />
                </div>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'Catamaran',
        background: COLOR.grayBackground,
        position: 'relative',
        padding: '0 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        [theme.breakpoints.down('md')]: {
            height: '0 10px 20px',
            gap: '10px',
        },
        justifyContent: 'center',
        marginTop: '20px',
        width: '100%',
        alignItems: 'center',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        justifyContent: 'flex-end',
        width: '100%',
    },
}));
