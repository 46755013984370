import { gql } from '@apollo/client';

import { LandfillShortFragment } from '~/@store/landfills/landfills.queries.fragment';
import { LocationFragment } from '~/@store/locations/location.queries';
import { ProjectShortFragment } from '~/@store/projects/projects.queries.fragment';

export const ProjectsAndLandfillsQuery = gql`
    query ProjectsAndLandfillsQuery {
        projects(filter: { statuses: [NEW, IN_PROGRESS, DONE] }, sort: { field: "name", direction: 1 }) {
            ...ProjectShortFragment
            dumpLoads {
                id
                destinationLandfill {
                    id
                    name
                    location {
                        ...LocationFragment
                    }
                    workTime {
                        dayNumber
                        openFrom
                        openTo
                        isOpen
                    }
                }
                serialNumber
                status
            }
        }

        landfills(filter: { withDeleted: false }) {
            ...LandfillShortFragment
            projects {
                id
                name
                location {
                    ...LocationFragment
                }
                dumpLoads {
                    id
                    serialNumber
                }
            }
        }
    }

    ${ProjectShortFragment}
    ${LandfillShortFragment}
    ${LocationFragment}
`;

export const AdminProjectsAndLandfillsQuery = gql`
    query AdminProjectsAndLandfillsQuery {
        projects(filter: { statuses: [NEW, IN_PROGRESS, DONE] }, sort: { field: "updatedAt", direction: -1 }) {
            ...ProjectShortFragment
        }

        landfills(filter: { withDeleted: false }) {
            ...LandfillShortFragment
        }
    }

    ${ProjectShortFragment}
    ${LandfillShortFragment}
    ${LocationFragment}
`;
