import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { dumpLoadsStore } from '~/@store/dumpLoads';
import { DeliveryLinesUpdateMutation, DeliveryLinesUpdateMutationVariables, DeliveryLineUpdateInput } from '~/graphql';
import { handleApolloError } from '~/utils';

import { AdminDumpLoadsPagedQuery } from '../../dumpLoads/dumpLoads.queries';
import * as queries from '../deliveryLines.queries';

export type UpdateDeliveryLinesFunc = (input: DeliveryLineUpdateInput[]) => Promise<unknown>;

export const useDeliveryLinesUpdateMutation = (): [UpdateDeliveryLinesFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        DeliveryLinesUpdateMutation,
        DeliveryLinesUpdateMutationVariables
    >(queries.DeliveryLinesUpdateMutation);

    const updateDeliveryLines = useCallback<UpdateDeliveryLinesFunc>(
        input => {
            return callMutation({
                variables: { input },
                refetchQueries: [
                    { query: AdminDumpLoadsPagedQuery, variables: dumpLoadsStore.dumpLoadsQueryVariables },
                ],
                awaitRefetchQueries: true,
            }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [updateDeliveryLines, mutationResult.loading];
};
