import { IAbility } from '@common/abilities/abilities.types';
import React, { ComponentType } from 'react';

import { AbilityUserContext, IProfileUser, IUser } from './AbilityUserContext';

export type WithAbilitiesProps = {
    user: IUser;
    setUser: (v: IProfileUser) => void;
    ability: IAbility;
};

export const withAbilities =
    <TProps extends object = {}>(WrappedComponent: ComponentType<TProps & WithAbilitiesProps>): ComponentType<TProps> =>
    (props: TProps) => {
        return (
            <AbilityUserContext.Consumer>
                {({ ability, user, setUser = () => {} }) => (
                    <WrappedComponent {...props} ability={ability} user={user} setUser={setUser} />
                )}
            </AbilityUserContext.Consumer>
        );
    };
