import { adminRoutes } from '@common/routes';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { MenuGrow } from '~/@components/MenuGrow';
import { IStatus } from '~/@store/common';
import { AllDumpLoads, DUMP_LOAD_STATUSES, dumpLoadsStore, IDumpLoadStatus, ORDERS_STATUSES } from '~/@store/dumpLoads';
import { LoadStatus } from '~/graphql';

import { StatusValue } from './StatusValue';

const StatusPaths: Record<IDumpLoadStatus, string | null> = {
    [LoadStatus.REQUESTED]: adminRoutes.dumpLoadRequested,
    [LoadStatus.CONFIRMED]: adminRoutes.dumpLoadConfirmed,
    [LoadStatus.NOT_ORDERED]: adminRoutes.dumpLoadRejected,
    [LoadStatus.ORDERED]: adminRoutes.dumpLoadOrdered,
    [AllDumpLoads.ALL_DUMP_LOADS]: adminRoutes.dumpLoadAllDumpLoads,

    [LoadStatus.IN_PROGRESS]: adminRoutes.ordersInProgress,
    [LoadStatus.DONE]: adminRoutes.ordersDone,

    [LoadStatus.DISCARDED]: null,
    [LoadStatus.DRAFT]: null,
};

const getStatusOptions = (status: IDumpLoadStatus) => {
    switch (status) {
        case LoadStatus.IN_PROGRESS:
        case LoadStatus.DONE:
            return ORDERS_STATUSES;
        default:
            return DUMP_LOAD_STATUSES;
    }
};

export const StatusFilter: React.FC = observer(() => {
    const history = useHistory();
    const status = dumpLoadsStore.status;

    const renderValue = useCallback((status: IStatus<IDumpLoadStatus>) => <StatusValue status={status} />, []);

    const options = getStatusOptions(status);

    const value = options.find(s => s.value === status);

    const handleChange = useCallback(
        (status: IStatus<IDumpLoadStatus>) => {
            const path = StatusPaths[status.value];

            if (path) history.push(path);
        },
        [history]
    );

    return (
        <MenuGrow<IStatus<IDumpLoadStatus>>
            value={value}
            onChange={handleChange}
            items={options}
            renderValue={renderValue}
            hideSelectedItem={false}
        />
    );
});
