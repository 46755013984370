import { IconButton } from '@material-ui/core';
import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { COLORS } from '~/@components/@theme/colors';
import { SimpleDialog } from '~/@components/Dialog';
import { CloseIcon } from '~/@components/Icon';
import { LocationEdit } from '~/@components/LocationEdit';
import { IProject } from '~/@store/projects';

import { Text } from '../../../../@components/Text';

type ProjectLocationProps = {
    project: IProject;
    closeDialog: () => void;
};

export const ProjectLocation = (props: ProjectLocationProps) => {
    const { project, closeDialog } = props;
    const { root, header } = useStyles();

    return (
        <SimpleDialog onClose={closeDialog}>
            <div className={root}>
                <div className={header}>
                    <Text variant="BodyRegular">{project.name}</Text>
                    <IconButton onClick={() => closeDialog()}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <LocationEdit location={project.location} onInput={() => {}} onChange={() => {}} readOnly={true} />
            </div>
        </SimpleDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: 960,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        backgroundColor: COLORS.white,
        padding: theme.spacing(4),
    },

    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));
