import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { UserChangePasswordInput, UserPasswordChangeMutation, UserPasswordChangeMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../users.queries';

export type UserPasswordChangeFunc = (input: UserChangePasswordInput) => Promise<boolean>;

export const useUserPasswordChangeMutation = (): [UserPasswordChangeFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<UserPasswordChangeMutation, UserPasswordChangeMutationVariables>(
        queries.UserPasswordChangeMutation
    );

    const changePassword = useCallback<UserPasswordChangeFunc>(
        input =>
            callMutation({ variables: { input } })
                .catch(handleApolloError)
                .then(result => !!result.data?.userChangePassword),
        [callMutation]
    );

    return [changePassword, mutationResult.loading];
};
