import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { COLORS, makeStyles } from '~/@components/@theme';
import { LocalizableText } from '~/@components/LocalizableText';
import { homePageMapStore } from '~/@user-store/homePageMap';

import { Card } from '../Card';

export const LandfillDumpLoads: FC = observer(() => {
    const { noData, root, header, row, even } = useStyles();

    if (!homePageMapStore.landfillDumpLoads) return null;

    if (homePageMapStore.landfillDumpLoads.length === 0)
        return (
            <div className={noData}>
                <LocalizableText code={'PoiInfoPanel.noLandfillDumpLoads'} />
            </div>
        );

    return (
        <Card>
            <TableContainer className={root}>
                <Table size={'small'} stickyHeader>
                    <TableHead>
                        <TableRow className={header}>
                            <TableCell align={'left'}>
                                <LocalizableText code={'PoiInfoPanel.projectName'} />
                            </TableCell>
                            <TableCell align={'left'}>
                                <LocalizableText code={'PoiInfoPanel.serialNumber'} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {homePageMapStore.landfillDumpLoads.map((d, i) => (
                            <TableRow
                                key={d.serialNumber}
                                className={cn(row, {
                                    [even]: i % 2 === 0,
                                })}>
                                <TableCell align={'left'}>{d.projectName}</TableCell>
                                <TableCell align={'left'}>{d.serialNumber}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
});

const useStyles = makeStyles(() => ({
    noData: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '18px',
    },
    root: {
        maxHeight: '240px',
        '& .MuiTableCell-root': {
            fontSize: '14px',
            height: '40px',
        },
    },
    header: {
        '& .MuiTableCell-head': {
            color: COLORS.white,
            backgroundColor: COLORS.brandMain,
        },
    },
    row: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
        },
    },
    even: {
        '& .MuiTableCell-root': {
            background: COLORS.grayLight,
        },
    },
}));
