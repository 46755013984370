import { useMutation } from '@apollo/client';
import { PureQueryOptions } from '@apollo/client/core/types';

import {
    BatchLogQuery,
    BatchTransfersQuery,
    RemoveBatchTransferMutation,
} from '~/@user-store/landfillBatches/landfillBatch.queries';
import {
    RemoveBatchTransferMutation as IRemoveBatchTransferMutation,
    RemoveBatchTransferMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

export const useBatchTransferRemove = (landfillId: string, batchId: string | null) => {
    const [callMutation, data] = useMutation<IRemoveBatchTransferMutation, RemoveBatchTransferMutationVariables>(
        RemoveBatchTransferMutation
    );

    const refetchQueries: PureQueryOptions[] = [{ query: BatchTransfersQuery, variables: { landfillId } }];

    if (batchId) {
        refetchQueries.push({ query: BatchLogQuery, variables: { landfillId, batchId } });
    }

    const remove = (id: string) =>
        callMutation({
            variables: { landfillId, id },
            refetchQueries,
            awaitRefetchQueries: true,
        }).catch(handleApolloError);

    return [remove, data.loading] as const;
};
