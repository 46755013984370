import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';

import { Divider } from '~/@components/Divider';
import i18n from '~/i18n';

import { useCompanyFormikContext } from './CompanyEditFormik';

export const CompanyEditActions = () => {
    const formik = useCompanyFormikContext();
    const history = useHistory();

    const onCancel = () => {
        if (formik.dirty) {
            formik.handleReset();
        } else {
            history.goBack();
        }
    };

    return (
        <Grid container spacing={3} direction={'column'}>
            <Grid item xs={12}>
                <Divider marginTop />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3} justify="space-between">
                    <Grid item>
                        <Button onClick={onCancel} variant="contained">
                            {formik.dirty ? i18n.cancel : i18n.back}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={formik.submitForm}
                            variant="contained"
                            color="primary"
                            disabled={!formik.dirty || formik.isSubmitting}>
                            {i18n.save}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
