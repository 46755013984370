import { Link as MuiLink } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

export type LinkProps = Pick<RouterLinkProps, 'to' | 'children' | 'className'> & {
    targetBlank?: boolean;
};

export const Link = ({ targetBlank, to, children, className }: LinkProps) => {
    const props = useMemo(() => {
        const props: Pick<RouterLinkProps, 'to' | 'target' | 'rel' | 'children' | 'className'> = {
            to,
            children,
            className,
        };

        if (targetBlank) {
            props.target = '_blank';
            props.rel = 'noopener noreferrer';
        }

        return props;
    }, [className, targetBlank, to, children]);

    return <MuiLink component={RouterLink} {...props} />;
};
