import { Button } from '@material-ui/core';
import React, { useCallback } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { showConfirmDialog } from '~/@store/common';
import { IDetailedUser } from '~/@store/users';
import { useUserPasswordResetMutation } from '~/@store/users/users.hooks/useUserPasswordResetMutation';
import { useUserAbilities } from '~/contexts';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';
import { canUpdateUser } from '~/utils/auth';

import { formatString } from '../../../../../../common/utils';

interface Props {
    user: IDetailedUser;
}

export const ResetPasswordControl = ({ user }: Props) => {
    const ability = useUserAbilities();
    const canUpdate = canUpdateUser(ability, user);
    const [resetPassword, isResettingPassword] = useUserPasswordResetMutation();

    const showConfirmation = useCallback(() => {
        const ignoredPromise = showConfirmDialog({
            title: <LocalizableText code={'resetPassword'} />,
            render: () => <LocalizableText code={'UserEditPage.resetPasswordConfirmation'} />,
        })
            .then((result: boolean) => (result ? resetPassword(user.id) : false))
            .then(
                result =>
                    result &&
                    globalMessage.success(formatString(i18n.UserEditPage.newPasswordHasBeenSentTo, user.email))
            );
    }, [resetPassword, user]);

    return (
        <Button variant="outlined" onClick={showConfirmation} disabled={!canUpdate || isResettingPassword}>
            <LocalizableText code={'reset'} />
        </Button>
    );
};
