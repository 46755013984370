import { LinearProgress } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';

import { IDetailedProject, useProjectAbilities, useProjectCreate, useProjectUpdate } from '~/@store/projects';
import { projectDetailRoute } from '~/@store/routes';

import { ProjectEditActions } from './ProjectEditActions';
import { ProjectEditCommonFields } from './ProjectEditCommonFields';
import { getProjectFormikConfig } from './ProjectEditFormik';

type IProjectEditFormProps = {
    project: IDetailedProject | null;
    onClose?: () => void;
};

export const ProjectEditForm = ({ project, onClose }: IProjectEditFormProps) => {
    const [createProject, isProjectCreating] = useProjectCreate();
    const [updateProject, isProjectUpdating] = useProjectUpdate();
    const history = useHistory();

    const handleCreate: typeof createProject = async input => {
        const project = await createProject(input);

        history.push(projectDetailRoute(project.id));

        return project;
    };

    const projectAbilities = useProjectAbilities(project);

    return (
        <div>
            <Formik {...getProjectFormikConfig(project, handleCreate, updateProject, projectAbilities)}>
                <Form>
                    {(isProjectUpdating || isProjectCreating) && <LinearProgress />}
                    <ProjectEditCommonFields />
                    <ProjectEditActions onClose={onClose} />
                </Form>
            </Formik>
        </div>
    );
};
