import { makeStyles } from '@material-ui/core';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { getContaminationLevelLabel, ToxicLimitsStore } from '~/@store/toxicLimits';

import { ToxicLimitsRow } from './ToxicLimitsRow';
import { ToxicLimitsValueCellContainer } from './ToxicLimitsValueCellContainer';

type Props = {
    store: ToxicLimitsStore;
};

export const ToxicLimitsTable = ({ store }: Props) => {
    const { root, header, title, scrollWrapper } = useStyles();

    return (
        <div className={root}>
            <div className={header}>
                <div className={title}>
                    <Text variant="TableBold" color="textSecondary">
                        <LocalizableText code={'Substances.substanceName'} />
                    </Text>
                </div>
                {store.limitLevels.map(level => (
                    <ToxicLimitsValueCellContainer key={level}>
                        <Text variant="TableBold" color="textSecondary">
                            {getContaminationLevelLabel(level, store.contaminationType)}
                        </Text>
                    </ToxicLimitsValueCellContainer>
                ))}
            </div>
            <div className={scrollWrapper}>
                {store.substances.map(s => (
                    <ToxicLimitsRow key={s.id} substance={s} store={store} />
                ))}
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: -theme.spacing(2),
    },
    header: {
        display: 'flex',
        padding: theme.spacing(),
    },
    title: {
        flexGrow: 1,
    },
    scrollWrapper: {
        maxHeight: 'calc(100vh - 172px)',
        overflow: 'auto',
    },
}));
