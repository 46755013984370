import './MessageContainer.scss';

import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { getBem } from '~/@sochi-components/@bem';
import { SochiThemeContainer } from '~/@sochi-components/@theme';
import { SochiMessage } from '~/@sochi-components/SochiMessage';
import type { MessagePlace } from '~/stores/models';
import { MessageModel, MessagePlaces } from '~/stores/models';

import browserHistory from '../../../../browserHistory';
import messageStore from '../../../../stores/messageStore';

type ExternalProps = { inPage?: boolean };

const userActivityEvents = ['mousemove', 'touchstart'];

const waitForUserActivity = (callback: () => void) => {
    function fireCallbackAndDispose() {
        callback();
        dispose();
    }

    function dispose() {
        userActivityEvents.forEach(e => window.removeEventListener(e, fireCallbackAndDispose));
    }

    userActivityEvents.forEach(e => window.addEventListener(e, fireCallbackAndDispose));

    return dispose;
};

@observer
export class MessageContainer extends React.Component<ExternalProps> {
    timeoutID?: ReturnType<typeof setTimeout>;

    nextMessage?: MessageModel | null;

    @observable
    displayMessage?: MessageModel | null;

    disposer?: (() => void) | null;
    unsubscribe?: (() => boolean) | null;
    unlisten?: (() => void) | null;

    bem = getBem(this);
    place: MessagePlace = this.props.inPage ? MessagePlaces.IN_PAGE : MessagePlaces.GLOBAL;

    @observable
    isHiding = false;

    componentDidMount() {
        this.unsubscribe = messageStore.messageSources[this.place].subscribe(this.onNextMessage);
        this.unlisten = browserHistory.listen(() => {
            if (this.displayMessage) this.onRequestClose();
        });
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
        this.disposer && this.disposer();
        this.unlisten && this.unlisten();
    }

    onNextMessage = (message: MessageModel) => {
        if (this.displayMessage) {
            this.nextMessage = message;
            this.onRequestClose();
        } else {
            this.setDisplayMessage(message);
        }
    };

    @action
    setDisplayMessage = (message?: MessageModel | null) => {
        this.displayMessage = message;
        this.timeoutID && clearTimeout(this.timeoutID);
        if (this.disposer) {
            this.disposer();
            this.disposer = undefined;
        }
        const timeout = this.displayMessage?.timeout;
        if (timeout) {
            this.disposer = waitForUserActivity(() => {
                this.timeoutID = setTimeout(this.onRequestClose, timeout);
            });
        }
    };

    @action
    setHiding = (flag: boolean) => (this.isHiding = flag);

    onRequestClose = () => {
        this.setHiding(true);
    };

    onExited = () => {
        this.setDisplayMessage(this.nextMessage);
        this.nextMessage = undefined;
        this.setHiding(false);
    };

    render() {
        const { className, block } = this.bem;

        return (
            <SochiThemeContainer>
                <CSSTransition
                    classNames={className}
                    in={Boolean(this.displayMessage && !this.isHiding)}
                    timeout={200}
                    onExited={this.onExited}>
                    <div className={block(this.props.inPage ? 'in-page' : 'global')}>
                        {this.displayMessage && (
                            <SochiMessage onClick={this.onRequestClose} message={this.displayMessage} />
                        )}
                    </div>
                </CSSTransition>
            </SochiThemeContainer>
        );
    }
}
