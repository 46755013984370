import React, { useCallback, useMemo } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { canEditLineInline, IDeliveryLine, useDeliveryLineUpdateMutation } from '~/@store/deliveryLines';
import { LockEntityType, useDialogWithLock } from '~/@store/locks';

import { PopupTextEdit } from '../../common/PopupEditFields';

type Props = {
    deliveryLine: IDeliveryLine;
};

export const DeliveryLineTruckRegistrationNumberCell = ({ deliveryLine }: Props) => {
    const [updateDeliveryLine] = useDeliveryLineUpdateMutation(deliveryLine);
    const disabled = !canEditLineInline(deliveryLine);

    const submit = useCallback(
        (value: string) => updateDeliveryLine({ truckRegistrationNumber: value }),
        [updateDeliveryLine]
    );

    const dialogWithLock = useDialogWithLock(LockEntityType.ORDER, deliveryLine.orderNumber);

    const onEdit = useCallback(
        () =>
            dialogWithLock({
                render: closeDialog => (
                    <PopupTextEdit
                        label={<LocalizableText code={'DeliveryLinesTable.truckRegistrationNumber'} />}
                        initialValue={deliveryLine.truckRegistrationNumber}
                        onSubmit={submit}
                        onClose={closeDialog}
                    />
                ),
            }),
        [dialogWithLock, deliveryLine, submit]
    );

    const actions: Array<ITableCellAction> = useMemo(
        () => [
            {
                variant: ActionVariant.EDIT,
                onClick: onEdit,
                disabled,
            },
            {
                variant: ActionVariant.COPY,
                content: deliveryLine.truckRegistrationNumber,
            },
        ],
        [onEdit, deliveryLine, disabled]
    );

    return <TableCellActions actions={actions}>{deliveryLine.truckRegistrationNumber}</TableCellActions>;
};
