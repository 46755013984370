import { action, computed, observable } from 'mobx';

class LoaderStore {
    @observable
    loaderCount = 0;

    @computed
    get isLoading() {
        return this.loaderCount > 0;
    }

    @action
    _setLoading = (flag: boolean) => {
        this.loaderCount += flag ? 1 : -1;
    };
}

export default new LoaderStore();
