import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';

import { TextField } from '~/@components/TextField';

import { IFormNames } from './landfillFormikHOC';
import { useLandfillFormContext } from './LandfillFormWithContext';

export type ILandfillTextFieldProps = {
    name: IFormNames;
    label: ReactNode;
    rows?: number;
};

export const LandfillTextField = ({ name, label, rows }: ILandfillTextFieldProps) => {
    const context = useLandfillFormContext();

    const { setFieldValueAndTouchIt, readOnly, values, handleBlur, touched, errors, isSubmitting } = context;

    const { root } = useStyles();

    return (
        <TextField
            className={root}
            value={values[name]}
            name={name}
            label={label}
            error={touched[name] && !!errors[name]}
            helperText={touched[name] && errors[name]}
            rows={rows}
            rowsMax={rows}
            multiline={!!rows}
            disabled={readOnly || isSubmitting}
            onBlur={handleBlur}
            onChange={e => setFieldValueAndTouchIt(name, e.target.value, true)}
        />
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
}));
