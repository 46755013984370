import { Button, makeStyles } from '@material-ui/core';
import { isNumber } from 'lodash';
import React from 'react';

import { ClickableText } from '~/@components/ClickableText';
import { DropDownIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { MenuGrow } from '~/@components/MenuGrow';
import { Text } from '~/@components/Text';
import { DestinationCountOptions, DestinationSort } from '~/@store/dumpLoads';
import i18n from '~/i18n';

const sortTranslate: Record<DestinationSort, string> = {
    Distance: i18n.DumploadSummarySection.Distance,
    LoadFee: i18n.DumploadSummarySection.LoadFee,
    InternalLoadFee: i18n.DumploadSummarySection.InternalLoadFee,
};

type Props = {
    sort: DestinationSort;
    setSort: (v: DestinationSort) => void;
    count: number | null;
    setCount: (v: number | null) => void;
};

export const DumpLoadDestinationControls = ({ sort, setSort, count, setCount }: Props) => {
    const { root, text, spacer } = useStyles();

    return (
        <div className={root}>
            <Text variant="TitleBold">
                <LocalizableText code={'DumpLoadsTable.selectDestination'} />
            </Text>
            {Object.values(DestinationSort).map(sortOption => {
                const isSelected = sortOption === sort;

                return (
                    <ClickableText
                        key={sortOption}
                        className={text}
                        onClick={() => setSort(sortOption)}
                        disabled={isSelected}>
                        <Text color={isSelected ? 'textPrimary' : 'textSecondary'}>{sortTranslate[sortOption]}</Text>
                        <DropDownIcon />
                    </ClickableText>
                );
            })}
            <div className={spacer} />
            <MenuGrow
                value={count || DestinationCountOptions.all}
                items={Object.values(DestinationCountOptions)}
                hideSelectedItem={false}
                onChange={value => setCount(isNumber(value) ? value : null)}
                renderValue={String}
                renderButton={(value, onClick, ref) => (
                    <Button variant="text" ref={ref} onClick={onClick} endIcon={<DropDownIcon />}>
                        {value === DestinationCountOptions.all ? value : `${i18n.elementsCount}: ${value}`}
                    </Button>
                )}
            />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(4),
    },
    text: {
        display: 'flex',
        alignItems: 'center',
    },
    spacer: {
        flexGrow: 1,
    },
}));
