import { makeStyles } from '@material-ui/core';
import React from 'react';

import { FormikContextTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { TextField } from '~/@components/TextField';
import { useUserAbilities } from '~/contexts';
import type { ProjectQuery_project as IProject, ProjectQuery_project_dumpLoads as IDumpLoad } from '~/graphql';
import { canReadDumpLoadComments } from '~/utils/auth';

export const AdminCommentField = <TValues extends {}, TFieldName extends keyof TValues & string>({
    dumpLoad,
    project,
    name,
}: {
    project: IProject;
    dumpLoad: IDumpLoad | null | undefined;
    name: TValues[TFieldName] extends string ? TFieldName : never;
}) => {
    const { root } = useStyles();
    const abilityContext = useUserAbilities();

    if (!canReadDumpLoadComments(abilityContext, project, dumpLoad)) return null;

    return dumpLoad ? (
        <TextField
            fullWidth
            className={root}
            label={<LocalizableText code={'ProjectAddEditMass.lastComment'} />}
            value={dumpLoad.lastCommentText || ''}
            disabled
        />
    ) : (
        <FormikContextTextField<TValues, TFieldName>
            name={name}
            label={<LocalizableText code={'ProjectAddEditMass.newComment'} />}
        />
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
}));
