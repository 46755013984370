import { AbilitySubjects, UserContextRole } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { IAbilityTimeSpent, ITimeSpentCalculatedSubject } from './timeSpentTypes';

export const getTimeSpentSubject = (user: IAbilityUser, timeSpent?: IAbilityTimeSpent): ITimeSpentCalculatedSubject => {
    const relationRole: ITimeSpentCalculatedSubject['relationRole'] = [user.role!];

    if (user.id === timeSpent?.userId) {
        relationRole.push(UserContextRole.AUTHOR);
    }

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.TIME_SPENT,
        relationRole,
    };
};
