import {
    BeastStatus,
    DeliveryLineStatus,
    DespatchAdviceCategory,
    DespatchAdviceResponseType,
    DespatchAdviceType,
    DeviationStatus,
    LandfillSubareaFields,
    OrderStatus,
    SignDocumentStatuses,
    SyncStatus,
    TransferType,
    UnitOfMeasure,
    UserContextRole,
    UserRole,
} from '../../enums';

export const en = {
    preview: 'Preview',
    pinpointerAb: 'Pinpointer AB',
    week: 'Week',
    hiddenAmountsForDeletedSubstances: 'Deleted substances with hidden contamination amounts exists: ',
    export: 'Export',
    pdf: 'Pdf',
    excel: 'Excel',
    word: 'Word',
    perPage: 'Per page:',
    ContaminationLevelButton: {
        serverResult: 'Last server result: ',
    },
    NotFoundPage: {
        title: '404',
        subTitle: 'We are sorry, Page not found!',
        description:
            'The page you are looking for might have been removed had its name changed or is temporarily unavailable.',
        backToHome: 'Back To Homepage',
    },
    UserEditPage: {
        user: 'User',
        userType: 'User type',
        registeredAt: 'Registered At',
        status: 'Status',
        isBlocked: 'Is blocked',
        state: 'State',
        active: 'Active',
        blocked: 'Blocked',
        name: 'First name',
        surname: 'Last name',
        email: 'email',
        phoneNumber: 'Phone number',
        role: 'Role',
        orgName: 'Organization Name',
        orgNr: 'Organization Number',
        customer: 'Customer',
        customerCompanyIsEmpty: 'No company found, try to sync companies.',
        updateSuccess: 'User information updated successfully',
        notifyUserWithEmail: 'Notify user with email',
        nameIsRequired: 'Name is required',
        roleIsRequired: 'Role is required',
        customerIsRequired: 'Customer is required',
        resetPasswordConfirmation: 'Are you sure you want to reset password?',
        newPasswordHasBeenSentTo: 'New password has been sent to $1',
        addLandfill: 'Add site',
        addProject: 'Add project',
        deleteUserLandfillConfirmation: "Are you sure you want to delete user's site?",
        deleteUserProjectConfirmation: "Are you sure you want to delete user's project?",
        noDataFound: 'No data found',
        vehicles: 'Vehicles',
        vehiclesEmptyList: 'There are no trucks here yet',
        licensePlate: 'License plate',
        licensePlateShouldBeUnique: 'License plates should be unique',
        capacity: 'Capacity (t.)',
        vehicleType: 'Vehicle type',
        fuel: 'Fuel',
        engine: 'Engine',
        addVehicle: 'Add vehicle',
    },
    DriverCard: {
        driver: 'Driver',
        status: 'Status',
        registeredAt: 'Registration date',
        state: 'State',
        name: 'Name',
        phoneNumber: 'Phone number',
        email: 'E-mail',
        trucks: 'Trucks',
        trucksEmptyList: 'There are no trucks here yet',
        licensePlate: 'License plate',
        licensePlateShouldBeUnique: 'License plates should be unique',
        capacity: 'Capacity (t.)',
        addTruck: 'Add truck',
        exitWithoutChanges: 'Exit without changes',
        thereAreUnsavedChanges: `There are unsaved changes.\nAre you sure you want to Exit?`,
        projects: {
            project: 'Project',
            deleteDriverProjectConfirmation: "Are you sure you want to delete driver's project?",
            noDataFound: 'No data found',
            addProject: 'Add project',
        },
    },
    ConfirmEmailPage: {
        error: 'Something went wrong...',
        notFound: 'User not found.',
        linkExpired: 'Your link has expired. Please register again.',
        complete: 'Your email is confirmed. Your account should be approved, please wait.',
    },
    NotConfirmedEmailPage: {
        info: 'Your email is not confirmed. Please confirm email.',
    },
    NotApprovedPage: {
        notApproved: 'Your account is not approved yet, please wait',
    },
    UserBlockedPage: {
        userBlocked: 'Your account is blocked by admin.',
    },
    SignupPage: {
        error: 'Something went wrong...',
        userExists: 'An account with this email already exists.',
        userBlacklisted: 'Account with this email is blocked by admin.',
        limitError: 'You have exceeded the daily limit for registrations',
        registrationIsSuccessful:
            'You have been successfully registered. To confirm registration please check your email.',
        refuse: 'Refuse',
        agree: 'Agree',
        acceptTermsNote: 'I have read and accepted the',
        termsAndConditions: 'terms and conditions',
        pleaseAcceptTermsNote: 'Please accept that you have read and accepted the',
        pleasePassCaptcha: 'Please pass the captcha',
        termsHeader: 'ALLMÄNNA VILLKOR - PINPOINTER',
        termsSubheader: '("Allmänna Villkor")',
        terms: [
            {
                header: 'INLEDNING',
                content: [
                    'Dessa Allmänna Villkor avser tillhandahållandet av Applikationen.',
                    'Dessa Allmänna Villkor är inte tillämpliga för Pinpointers övriga tjänster, t.ex. avsättning av restprodukter. För samtliga övriga tjänster som tillhandhålls av Pinpointer gäller separat avtal.',
                ],
            },
            {
                header: 'DEFINITIONER',
                content: [
                    'I dessa Allmänna Villkor skall följande termer och uttryck ha den betydelse som anges nedan:',
                    '"Allmänna Villkor" betyder dessa allmänna villkor jämte samtliga härtill hörande bilagor;',
                    '"Applikationen" betyder den webb- och mobilapplikation som tillhandahålls i enlighet med dessa Allmänna Villkor, enligt vad som närmare beskrivs i Bilaga 1, Tjänstebeskrivning;',
                    '"Slutanvändare" betyder en unik användare av Applikationen;',
                    '"Slutanvändarkonto" betyder det unika användarkonto som ska upprättas för varje unik användare av Applikationen.',
                ],
            },
            {
                header: 'PINPOINTERS SKYLDIGHETER',
                content: [
                    'Pinpointer ska tillhandahålla Applikationen i enlighet med dessa Allmänna Villkor och säkerställa att Applikationen fungerar i enlighet med Bilaga 1, Tjänstebeskrivning, samt i enlighet med vad som i övrigt följer av dessa Allmänna Villkor.',
                ],
            },
            {
                header: 'ANVÄNDNING AV APPLIKATIONEN',
                content: [''],
            },
            {
                header: 'Slutanvändarkonton',
                content: [
                    'Varje Slutanvändarkonto ska vara hänförligt till en unik Slutanvändare. Det är av spårbarhetsskäl inte tillåtet att flera personer använder samma Slutanvändarkonto. För det fall Pinpointer upptäcker att flera personer använder ett Slutanvändarkonto, förbehåller sig Pinpointer rätten att stänga av tillgången till Applikationen.',
                    'Kunden kan själv skapa och ta bort Slutanvändarkonton. Assistans vid skapandet av Slutanvändarkonton tillhandhålls av supporten.',
                ],
            },
            {
                header: 'Kundens ansvar',
                content: [
                    'Kunden ansvarar för att de uppgifter som anges i Applikationen är korrekta och sanningsenliga samt att den kemianalys som ligger till grund för de uppgifter som förs in i Applikationen, är ut-förd av en ackrediterad tredje part. Pinpointer tar inte något ansvar för eventuella inkorrekta, icke uppdaterade eller annars felaktiga uppgifter som förs in i Applikationen.',
                    'Kunden är medveten om att de priser som genereras i Applikationen genom de uppgifter som Kunden anger i Applikationen kan komma att förändras efter att uppgifterna har kontrollerats av Pinpointer. Det pris som meddelas till Kunden efter sådan kontroll är det pris som gäller.',
                    'Innan några uppgifter kan registreras i Applikationen, ska Slutanvändaren bekräfta att de angivna uppgifterna är korrekta.',
                    'Kunden ska försvara och hålla Pinpointer skadeslöst för samtliga krav (inklusive ombudskostnader) från tredje part som riktas mot Pinpointer som ett resultat av att Kunden har angivit inkorrekta, icke uppdaterade eller annars felaktiga uppgifter.',
                    'Kunden åtar sig att följa samtliga relevanta lagar, förordningar, föreskrifter och andar författningar som har koppling till Kundens och dess Slutanvändares användning av Applikationen.',
                ],
            },
            {
                header: 'DRIFTSÅTAGANDE',
                content: [
                    'Pinpointer ska verka för att Applikationen är tillgänglig för Kunden och Slutanvändarna dygnet runt under årets alla dagar. Pinpointer har dock rätt att när som helst och utan föregående meddelande stänga av Kundens och Slutanvändarnas tillgång till Applikationen för underhåll, för att skydda Applikationen från obehöriga angrepp eller liknande eller för att vidta andra åtgärder som är påkallade av tekniska, drifts- och säkerhetsmässiga skäl. Pinpointer ska dock, så långt möjligt, informera Kunden om när sådana avbrott kan komma att äga rum.',
                    'Kunden har inte rätt till någon ersättning för skada (inklusive förlorad inkomst) som uppkommer på grund av att Applikationen inte är tillgänglig.',
                    'Applikationen ska finnas tillgängligt både via webb- och mobilapplikation. Kunden är medveten om att mobilapplikationen utgör en spegling av webbversionen av Applikationen, enligt vad som närmare beskrivs i Bilaga 1, Tjänstebeskrivning.',
                ],
            },
            {
                header: 'SUPPORT OCH UNDERHÅLL',
                content: [
                    'Pinpointer utvecklar och uppdaterar Applikationen löpande. I den mån Pinpointer genomför en väsentlig uppdatering av Applikationen, kommer Kunden att informeras innan en sådan väsentlig uppdatering genomförs, inklusive information om (i den mån möjligt) hur länge Applikationen förväntas vara otillgänglig (om alls) på grund av uppdateringen.',
                    'Pinpointer tillhandahåller första linjens support till Kunden och Slutanvändare, via e-post, telefon och chatt i Applikationen, under helgfria vardagar 08.00 - 16.00. Genom supporten kan Kunden eller en Slutanvändare får svar på enklare frågor rörande användningen av Applikationen samt anmäla eventuella fel eller brister som uppdagas i samband med användningen av Applikationen.',
                    'För det fall ett supportärende inte går att lösa genom första linjens support, eskalerar Pinpointer frågan och meddelar Kunden om att ett supportärende har upprättats hos Pinpointer. Pinpointer lämnar dock inga utfästelser rörande när ett supportärende kan vara hanterat, men åtar sig att hantera ärendet så skyndsamt som möjligt.',
                ],
            },
            {
                header: 'IMMATERIELLA RÄTTIGHETER',
                content: [
                    'Samtliga immateriella rättigheter som ägs av Pinpointer, inklusive utan begränsning namnrättigheter, kännetecken, varumärken (såväl registrerade som oregistrerade), upphovsrätter (inklusive källkod), mjukvarulicenser eller patent, ska fortsatt ägas av Pinpointer och får inte användas av Kunden på något annat sätt än vad som uttryckligen och skriftligen godkänns av Pinpointer. Inget i dessa Allmänna Villkor ska tolkas som en överlåtelse av Pinpointers immateriella rättigheter.',
                    'Samtliga immateriella rättigheter som återfinns i eller skapas som ett resultat av användningen av Applikationen, ska ägas av och/eller tillfalla Pinpointer.',
                    'Kunden erhåller genom dessa Allmänna Villkor en icke-exklusiv och under avtalstiden begränsad rätt att använda Applikationen i dess avsedda ändamål samt i enlighet med dessa Allmänna Villkor. Kunden får inte använda Applikationen (i) för något bedrägligt eller annars olag-ligt ändamål, (ii) på ett sätt som är avsett att störa driften av Applikationen, (iii) för att utreda, ändra, anpassa, översätta, "reverse engineer", dekompliera eller nedmontera någon del av Applikationen, inklusive utan begränsning dess objektkod eller källkod, (iv) för att kopiera, återge, sälja eller på annat sätt exploatera Applikationen eller dess innehåll, (v) för att avlägsna eventuella noteringar avseende upphovsrätt, varumärken eller annan äganderätt från Applikationen eller material som återfinns i Applikationen, eller (vi) för att överföra eller på annat sätt introducera virus, mask, trojaner, eller annan skadlig programvara eller kod, eller på annat sätt skada eller negativt påverka Applikationens funktionalitet eller tillgänglighet.',
                    'Detta avsnitt ska fortsätta att tillämpas även efter att Avtalet har upphört att gälla.',
                ],
            },
            {
                header: 'PERSONUPPGIFTER',
                content: [
                    'Pinpointer är personuppgiftsansvarig för den behandling av Slutanvändarens personuppgifter som sker när Pinpointer administrerar Slutanvändarkonton. För det fall att Kunden skapar Slutanvändarkonton åt sina anställa, uppdragstagare eller andra individer, ska Kunden vara personuppgiftsansvarig för den behandlingen som krävs för att skapa dessa Slutanvändarkonton. Detta påverkar dock inte Pinpointers personuppgiftsansvar i förhållande till administrationen av Slutanvändarkonton.',
                    'Parterna åtar sig, i Parternas respektive roller som självständigt personuppgiftsansvariga, att lämna vederbörlig information till registrerade och i övrigt följa de regler som gäller behandling av personuppgifter i enlighet med tillämplig dataskyddslagstiftning, inklusive GDPR (EU) 2016/679.',
                ],
            },
            {
                header: 'SEKRETESS',
                content: [
                    'Parterna förbinder sig att behandla all information och dokumentation som har samband med dessa Allmänna Villkor och användningen av Applikationen, inklusive all information om den andra Partens verksamhet som den ena Parten kan få tillgång till inom ramen för dessa Allmänna Villkor och användingen av Applikationen, strikt konfidentiellt och inte röja sådan information till någon tredje part utan den andra Partens skriftliga godkännande. Detta åtagande ska dock inte tillämpas i förhållande till sådan information som Part är skyldig att röja enligt lag, förordning eller myndighetsbeslut, varvid den Part som åläggs sådan skyldighet omedelbart ska meddela den andra Parten när sådan skyldighet föreligger. Sekretess enligt detta avsnitt 8 ska gälla under hela avtalstiden och för en tid om fem (5) år därefter.',
                ],
            },
            {
                header: 'ANSVAR',
                content: [
                    'Pinpointer ska endast hållas ansvarig för sådana direkta skador som åsamkas Kunden till följd av Pinpointers brott mot dessa Allmänna Villkor. Pinpointer ska inte hållas ansvarig för indirekta skador, inklusive utan begränsning förlorad inkomst, förlorade affärsmöjligheter eller annan ekonomisk följdskada, om inte skadan har uppkommit till följd av Pinpointers grova vårdslöshet eller uppsåt.',
                    'Utan att det påverkar tillämpningen av punkten ovan ska Pinpointers sammanlagda ansvar enligt Avtalet och dessa Allmänna Villkor under ett (1) år inte överskrida ett (1) prisbasbelopp enligt Socialförsäkringsbalk (2010:110).',
                ],
            },
            {
                header: 'ÖVRIGT',
                content: [''],
            },
            {
                header: 'Överlåtelse',
                content: [
                    'Kunden ska inte äga rätt att helt eller delvis överlåta sin rätt att använda Applikationen (inklusive övriga rättigheter och skyldigheter som följer av dessa Allmänna Villkor) till någon annan part utan Pinpointers skriftliga godkännande.',
                ],
            },
            {
                header: 'Ändringar och tillägg',
                content: [
                    'Pinpointer har efter eget gottfinnande rätt att ändra eller göra tillägg till dessa Allmänna Villkor. Skulle en ändring medföra att Applikationen inte längre tillhandahålls kostnadsfritt, kommer Kunden att meddelas om detta i förväg.',
                ],
            },
            {
                header: 'TILLÄMPLIG LAG OCH TVIST',
                content: [
                    'Dessa Allmänna Villkor ska tolkas och tillämpas i enlighet med svensk lag, utan iakttagande av dess principer för lagval.',
                    'Tvist i anledning av dessa Allmänna Villkor ska i första hand försöka lösas genom förtroliga diskussioner mellan Parterna. Om inte detta låter sig göras ska tvisten slutligt avgöras genom skiljedom vid Stockholms Handelskammares Skiljedomsinstitut ("Institutet"). Institutets Regler för Förenklat Skiljeförfarande ska gälla om inte Institutet med beaktande av målets svå-righetsgrad, tvisteföremålets värde och övriga omständigheter bestämmer att Regler för Stockholms Handelskammares Skiljedomsinstitut ska tillämpas på förfarandet. I sistnämnda fall ska Institutet också bestämma om skiljenämnden ska bestå av en eller tre skiljemän. Skil-jeförfarande ska äga rum i Stockholm och ske på svenska. Skiljeförfaranden som äger rum enligt denna punkt, inklusive all information som avslöjas och all dokumentation som inges eller utfärdas av eller på uppdrag av endera Part eller av skiljenämnden, samt alla beslut och skilje-domar som fattas eller meddelas i samband med skiljeförfarandet, ska vara strikt konfidentiella och inte användas för andra ändamål än skiljeförfarandet i fråga och inte heller röjas till tredje man utan föregående skriftlig överenskommelse mellan Parterna.',
                ],
            },
        ],
        register: 'Register',
    },
    ForgotPasswordPage: {
        resetSuccess:
            'A link has been sent to the email address provided. Follow the description to reset the password.',
        resetFailed: 'Password reset failure',
        Submit: 'Submit',
    },
    Copyable: {
        copy: 'copy',
        copied: 'copied',
    },
    leaveMessage: 'You have unsaved changes, are you sure you want to leave?',
    back: 'Back',
    noElements: 'No Elements',
    city: 'City',
    address: 'Address',
    postalCode: 'Postal Code',
    addressLine1: 'Address Line #1',
    addressLine2: 'Address Line #2',
    of: 'of',
    allowed: 'allowed',
    notAllowed: 'not allowed',
    elementsCount: 'Elements',
    noCertificates: '[No documents]',
    search: 'Search',
    projectTestresult: 'Project Test Result',
    noProjectsFound: 'No projects found',
    noLandfillsFound: 'No sites found',
    subarea_allowFA: 'FA allowed',
    fill: 'Fill',
    on: 'ON',
    off: 'OFF',
    subarea_associationLevel: 'Fill the values with the association level',
    none: 'NONE',
    subarea_toxic: 'Limit values for the sub-area',
    unnamed: '[Unnamed]',
    subareaFields: {
        [LandfillSubareaFields.maxAmount]: 'Max amount',
        [LandfillSubareaFields.currentAmount]: 'Current amount',
        [LandfillSubareaFields.reservedAmount]: 'Reserved amount',
        [LandfillSubareaFields.deliveredAmount]: 'Delivered amount',
        [LandfillSubareaFields.priceBase]: 'Base price',
        [LandfillSubareaFields.priceOver50cm]: 'Price over 50 cm',
        [LandfillSubareaFields.priceNotStackable]: 'Not stackable price',
        [LandfillSubareaFields.solidState]: 'Solid state',
        [LandfillSubareaFields.leachingState]: 'Leaching state',
        [LandfillSubareaFields.allowSolidFA]: 'Is allow solid FA',
        [LandfillSubareaFields.onHold]: 'Is on hold',
        [LandfillSubareaFields.unitOfMeasure]: 'Unit of measure',
        [LandfillSubareaFields.comment]: 'Comment',
    },
    subarea: 'Subarea',
    subareas: 'Subareas',
    subarea_name: 'Subarea name',
    subarea_capacity: 'Capacity',
    subarea_price: 'Price',
    subarea_maxCapacity: 'Max capacity',
    subarea_currentCapacity: 'Current capacity',
    subarea_stackable: 'Stackable',
    subarea_over50cm: 'Over 50cm',
    subarea_pricePerUnit: 'Price per unit',
    subarea_create: 'Create subarea',
    pleaseFillPrint: 'Please enter number of documents to print!',
    nettPrice: 'Net price',
    downloadInProgress: 'Download in progress. Please wait!',
    printed: 'Printed out',
    print: 'Print count',
    selectTemplate: 'Select template...',
    templates: 'Templates',
    closedTo: 'Closed to',
    closedFrom: 'Closed from',
    show: 'Show',
    showAll: 'Show all',
    calculate: 'Calculate',
    prices: 'prices',
    download: 'Download',
    //TODO: Change syntax after removing legacy js/flow from project
    app: {
        name: {
            pinpointer: 'Pinpointer',
        },
        title: {
            pinpointer: 'PINPOINTER',
        },
    },
    send: 'Send',
    contamination: 'Contamination',
    marginPercent: 'Margin(%)',
    hide: 'Hide',
    clear: 'Clear',
    filter: 'Filter',
    testMethod: 'Test method',
    uploadComplete: 'Upload complete',
    declarationOfWaste: 'Declaration of waste',
    scaleReceiptScan: 'Scale receipt scan',
    fileTemplates: 'File templates',
    standardLandfill: 'Standard',
    emptyLandfill: 'Empty',
    editTemplate: 'Edit template',
    defaultContaminationType: 'Default contamination type',
    defaultContaminationLevel: 'Default contamination level',
    contaminationType: 'Contamination type',
    contaminationLevel: 'Contamination level',
    contaminationLevelUnavailable: 'Contamination level unavailable',
    deleteCertificate: 'Delete document',
    addDumpLoad: 'Add dumpload',
    addProject: 'Add project',
    addDriver: 'Add driver',
    addMaximumValue: 'Create maximum value',
    addSpecialValue: 'Create special value',
    addCapacity: 'Create capacity',
    addLandfill: 'Add site',
    addComment: 'Add comment',
    solids: 'Solids',
    leaching: 'Leaching',
    createdFrom: 'Created from',
    createdTo: 'Created to',
    certificate: 'Document',
    upload: 'Upload',
    replace: 'Replace',
    view: 'View',
    onlyMyProjects: 'Only my projects',
    receiver: 'Receiver',
    receivers: 'Receivers',
    sender: 'SENDER-TO-REMOVE',
    senders: 'Senders',
    specialSubstances: 'Special values',
    substancesMaxLevels: 'Maximum values',
    doYouReallyWantRecover: 'Do you really want to recover?',
    doYouReallyWantDestroy: 'Do you really want delete?',
    toTrash: 'To trash',
    recover: 'Recover',
    forgotPassword: 'Forgot password?',
    newPasswordSet: 'New password has been set',
    submit: 'Submit',
    resetItNow: 'Reset now',
    signup: 'Sign up',
    register: 'register',
    hideNA: 'Hide N/A',
    result: 'Result',
    NA: 'N/A',
    toxicOk: 'OK',
    toxicFail: 'FAIL',
    toxicLimits: 'Toxic limits',
    totalLimits: 'Total rule limits',
    sumRuleLimits: 'Sum rule limits',
    mgKgTS: '(mg/kg TS)',
    specifiedContent: 'Specified content',
    limit: 'Limit',
    hazardousType: 'Hazardous type',
    hazardousWasteLimitTitle: 'Hazardous waste limit',
    hazardousWasteLimit: 'Hazardous waste limit (mg/kg TS)',
    measuredMassContent: 'Measured mass content (mg/kg TS)',
    specialLimit: 'Special limit',
    minAmount: 'Min amount',
    maxAmount: 'Max amount',
    yes: 'Yes',
    notifyUserWithEmail: 'NOTIFY USER WITH EMAIL',
    no: 'No',
    pricePerUnit: 'Price per unit',
    default: '[DEFAULT]',
    unknown: '[Unknown]',
    doYouReallyWantDelete: 'Do you really want to delete?',
    oneTripCost: 'One trip price with transport, for {number} ton',
    oneTripCostShort: 'One trip price w/ transport',
    tripCost: 'Trip price',
    useTrips: 'Use trips',
    internalLoadFeePerTon: 'Internal load fee / ton',
    loadFee: 'Load fee',
    loadFeePerTon: 'Load fee / ton',
    total: 'Total',
    fastest: 'Fastest',
    normal: 'Normal',
    enterLocationAddress: 'Enter location address...',
    pricePerTon: 'Price/ton',
    account: 'Account',
    add: 'Add',
    manage: 'Manage',
    created: 'Created',
    lastUpdated: 'Last updated',
    addSubstance: 'Add substance',
    sysAdmin: 'Sys.Admin',
    pplAdmin: 'PPL.Admin',
    amount: 'Amount',
    amountFieldHint: 'How much is it in tonnes or kubic meters',
    overview: 'Overview',
    editSite: 'Edit site',
    materials: 'Materials',
    batches: 'Batches',
    transfers: 'Transfers',
    updateAtErrorMessage:
        'Current data cannot be saved, someone else has changed source data. Please refresh or open this page in a new window.',
    apply: 'Apply',
    availableLandfills: 'Available Sites',
    backToMap: 'Show map',
    cancel: 'Cancel',
    capacity: 'Capacity',
    capacityList: 'Dump types',
    changeEmail: 'Change email',
    changeEmailIfIsIncorrect:
        'If the email address above is incorrect you can change the email by clicking the button below.',
    emailWasSentTo: 'An email was sent to:',
    checkYourInbox: 'Check your inbox',
    verifyEmail: 'Verify email',
    changePassword: 'Change password',
    passwordHasBeenChanged: 'Password has been changed',
    change: 'Change',
    close: 'Close',
    comment: 'Comment',
    //TODO: Change syntax after removing legacy js/flow from project
    contactInfo: {
        pinpointer: {
            email: 'kundtjanst@pinpointer.se',
            phone: '0771-67 10 10',
        },
    },
    create: 'Create',
    createAccount: 'Create account',
    enterYourDetail: 'Enter your details',
    co2: 'co2',
    createNewLandfill: 'Create new Site',
    createNewLandOnMap: 'Create new Site',
    createNewProject: 'Create new Project',
    createNewSubstance: 'Create new Substance',
    createNewCompany: 'Create new Customer',
    createNewMass: 'Create new mass',
    createSubType: 'Add',
    cubicMeters: 'm3',
    currentAmount: 'Current amount',
    currentPosition: 'Current position',
    customer: 'Customer',
    company: 'Company',
    contactsFieldHint: 'A contact person can view all information but not edit or create anything',
    delete: 'Delete',
    shortest: 'Shortest',
    dumpLoad: 'Dump load',
    dumpLoads: 'Dump loads',
    dumpTypeLabelNew: 'Create new Dump type',
    dumpTypeName: 'Dump type name',
    dumpTypes: 'Dump types',
    duration: 'Duration',
    edit: 'Edit',
    editDumpType: 'Edit dump type',
    editLandfill: 'Edit Site',
    editLandfillStatus: 'Edit Site status',
    editProject: 'Edit Project',
    editSubstance: 'Edit Substance',
    editUser: 'Edit User',
    editDriver: 'Edit driver',
    email: 'email',
    emailIsRequired: 'Email is required',
    busAdmin: 'Bus.Admin',
    enterCustomerName: 'Enter customer name...',
    enterNewUserEmail: 'Enter new user email',
    enterNewUserEmailToBlock: 'Enter new user email to Block',
    enterProjectComment: 'Enter project comment...',
    enterLandfillComment: 'Enter Site comment...',
    error: 'An error occurred...',
    errorRetry: 'An error occurred. Please try again later. If the problem persists, contact support.',
    firstName: 'First Name',
    surName: 'Last name',
    gateFee: 'Gate/scale fee',
    minMargin: 'Minimum margin',
    blacklist: 'Blacklist',
    icon: 'Icon',
    invalidCredentials: 'Invalid credentials',
    invalidEmail: 'Email is invalid',
    isActive: 'Active',
    labelNew: 'Create new Site type',
    landfill: 'Site',
    landfillInfo: 'Basic tasks',
    landfillName: 'Site name',
    landfillNamePlaceholder: 'Site name...',
    landfills: 'Sites',
    landfillStatus: 'Site status',
    landfillStatuses: 'Site statuses',
    landfillStatusTitle: 'Site status Title',
    landfillNoToxicResults: 'No Toxic Result Data',
    lastName: 'Last Name',
    loading: 'Loading...',
    loadingMap: 'Loading map...',
    location: 'Location',
    longitude: 'Longitude',
    latitude: 'Latitude',
    dates: 'Dates',
    datesFieldHint: 'The mass start and end date',
    signIn: 'Sign in',
    login: 'Login',
    loginIsRequired: 'Login is required',
    logout: 'Log out',
    manager: 'Manager',
    mapSettings: 'Map Settings',
    minPasswordLength: 'The password has to be minimum 6 characters long!',
    name: 'Name',
    stackability: 'Stackable',
    over50cm: 'Over 50cm',
    withTOC: 'TOC (%)',
    TOCValue: 'TOC-value',
    withInvasiveSpecies: 'Invasive species allowed',
    invasiveSpecies: 'Description of invasive species',
    orderTransportation: 'Order Transportation',
    orderTransportationFieldHint: 'Do you want to buy transport from Pinpointer',
    discardOrder: 'Discard Order',
    order: 'Order',
    noCompany: 'No Company',
    maxTrips: 'Max trips',
    retry: 'Try again',
    status: 'Status',
    registered: 'Registered',
    emailConfirmed: 'email confirmed',
    approved: 'Approved',
    nameIsRequired: 'Name is required',
    surNameIsRequired: 'Last name is required',
    oldPassword: 'Old password',
    newPassword: 'New password',
    repeatPassword: 'Repeat password',
    currentPasswordIsRequired: 'Current password is required',
    samePasswordAsBefore: 'Passwords are same',
    saved: 'Saved',
    noCapacitySpecified: 'No Capacity Specified',
    organizationNumber: 'Organization Number',
    organizationNumberIsRequired: 'Organization number is required',
    organizationNumberMustBeAnInteger: 'Organization number must be an interger',
    organizationName: 'Organization Name',
    organizationNameIsRequired: 'Organization name is required',
    organizationNumberMustBeTenDigit: 'Organization number must be 10 Digit',
    GLNMustBeInCorrectFormat: 'GLN must be 13 Digit',
    open: 'Open',
    openedAt: 'Open at',
    openHours: 'Open Hours',
    openProject: 'Open project',
    openLandfill: 'Open site',
    password: 'Password',
    passwordIsRequired: 'Password is required',
    confirmPassword: 'Confirm Password',
    confirmPasswordIsRequired: 'Confirm password is required',
    confirmTermsIsRequired: 'Accepting terms and conditions is required',
    passwordsDoNotMatch: "Passwords don't match",
    pickPositionOnTheMap: 'Select location on map...',
    project: 'Project',
    projectDumpLoads: 'Project Dump loads',
    projects: 'Projects',
    projectToxicAnalysys: 'Project Toxic Analysys',
    remove: 'Remove',
    report: 'Report',
    resetPassword: 'Reset Password',
    approveUser: 'Approve user',
    role: 'Role',
    roleIsOnlyOne: 'You can select only one role',
    routeDetails: 'Route Details',
    saving: 'Saving!',
    save: 'Save',
    saveFormAlert: 'The information has been changed and must be saved. Do you want to save?',
    saveAndClose: 'Save and close',
    saveRequestAndClose: 'Request quote and close',
    saveAsDraft: 'Save as draft',
    markAsResolved: 'Mark as resolved',
    confirm: 'Confirm',
    searchDumpType: 'Search dump-type...',
    searchLandfill: 'Search Site...',
    searchProject: 'Search project...',
    searchSubstance: 'Search substance...',
    searchUser: 'Search user...',
    searchDriver: 'Search driver...',
    searchCompany: 'Search company...',
    substanceName: 'Substance Name',
    substance: 'Substance',
    substances: 'Substances',
    title: 'Title',
    tonns: 'tonnes',
    tonnsShort: 't.',
    toxic: 'Toxic',
    toxicAnalysys: 'Toxic Analysis',
    toxicResult: 'Toxic Result',
    toxicRule: 'Toxic Rule',
    toxicRule_Alvenas: 'Alvenas',
    toxicRule_GreenList: 'GreenList',
    toxicRule_Inert: 'Laktest / perculationstest',
    toxicRule_SumRule: 'SumRule',
    toxicRule_TotalContent: 'Total Content',
    toxicRuleName: 'Toxic Rule Name',
    toxicRules: 'Toxic Rules',
    configSaved: 'Configuration saved',
    tripCount: 'Trip Count',
    trips: 'Trips',
    type: 'Type',
    unitOfMeasure: 'Unit Of Measure',
    update: 'Update',
    updatedAt: 'Updated At',
    users: 'Users',
    wasteTypes: 'Waste Types',
    zero: 0,
    groupBy: 'Group by',
    max: 'MAX',
    sum: 'SUM',
    invalidLat: 'Latitude is invalid. It should be between -90 and 90. And not 0.',
    invalidLng: 'Longitude is invalid. It should be between -180 and 180. And not 0.',
    locationNotFound: 'Location not found!',
    invalidValues: 'Invalid values, please change:',
    stackable: 'Stackable',
    loginCarouselHeader1: 'Your process made easy',
    loginCarouselText1:
        'We make document handling fully digital and easier for everyone by autogenerating and storing them to make sure all documents reach the right hands.',
    loginCarouselHeader2: 'Our environmental promise',
    loginCarouselText2:
        'The goal of our system is to help reduce carbon dioxide emission by minimizing transport distance and using more environmentally friendly fuels.',
    loginCarouselHeader3: 'View ongoing transports',
    loginCarouselText3:
        'With our management tools you get the possibility to view ongoing transports and other statistics to help optimize and organize your flow of materials.',
    fortnox: {
        title: 'Fortnox',
        deliveryAddress: 'Delivery address',
        orderNumber: 'Order number',
        partNumber: 'Part number',
        contamination: 'Contamination',
        amount: 'Amount',
        deliveryDate: 'Delivery date',
        lastSyncWithError: 'Last synchronisation exited with an error',
        nothingToSync: 'Nothing to synchronize',
        syncStillRunning: 'Synchronisation is still running, please wait for it to finish.',
        syncInProgress: 'In progress ...',
        ordersToSync: 'Orders to synchronize: {count}.',
        getSyncTimeInfo: 'The synchronisation will take about {duration} and will be completed after {remaining}',
        syncPageUpdateInfo: 'This page will be automatically updated after the synchronisation is complete.',
    },
    selectDateRange: 'Select date range',
    projectNumber: 'Project number',
    findOrder: 'Search Order...',
    sync: 'Synchronize',
    titleEdit: 'Order Edit',
    orderNumber: 'Order number',
    orderStatus: 'Order status',
    deliveryAddress: 'Delivery address',
    plannedStartDate: 'Planned Start date',
    deliveredVolume: 'Delivered Volume',
    salesResponsible: 'Sales responsible',
    volume: 'Volume',
    partNumber: 'Part Number',
    startDateFrom: 'Start date',
    startDateTo: 'End date',
    deliveredVolumeFrom: 'Delivered Volume from',
    deliveredVolumeTo: 'Delivered Volume to',
    history: 'History',
    invoices: 'Invoices',
    invoicesPage: {
        title: 'Invoices',
        selectDateRange: 'Select date range',
        alert: 'Please fill form',
    },
    buttons: {
        sync: 'Synchronize',
        syncStatus: 'Synchronize status',
    },
    pplOrder: {
        alert: 'Customer is required',
        selectDateRange: 'Select date range',
        projectNumber: 'Project number',
        findOrder: 'Search Order...',
        sync: 'Synchronize',
        syncStatus: 'Synchronization status',
        syncStop: 'Stop synchronization',
        syncStopped: 'Synchronization has been stopped',
        syncOneOrder: 'Synchronize one order',
        enterDocumentNumber: 'Please enter Fortnox order (document) number. Not "Your order number"!',
        title: 'Orders',
        titleEdit: 'Order Edit',
        orderNumber: 'Order number',
        documentNumber: 'Document number',
        orderStatus: 'Order status',
        deliveryName: 'Delivery Name',
        customer: 'Customer',
        deliveryAddress: 'Delivery address',
        plannedStartDate: 'Planned Start date',
        deliveredVolume: 'Delivered Volume',
        salesResponsible: 'Sales responsible',
        volume: 'Volume',
        partNumber: 'Part Number',
        showDateRange: 'Show date range',
        startDateFrom: 'Start date from',
        startDateTo: 'Start date to',
        deliveredVolumeFrom: 'Delivered Volume from',
        deliveredVolumeTo: 'Delivered Volume to',
        history: 'History',
        create: 'Create',
        createOrder: 'Create a new order, type declaration of waste number',
    },
    pplDeliveryLine: {
        history: 'History',
        search: 'Find delivery line...',
        title: 'Delivery Line',
        orderNumber: 'Order number',
        deliveryLine: 'Delivery Line',
        partNumber: 'Part Number',
        weight: 'Weight',
        receiptPhoto: 'Scale receipt',
        customerInvoiceNumber: 'Customer Invoice number',
        transportationInvoiceNumber: 'Transportation invoice number',
        customerInvoiceNo: 'Customer Invoice no',
        transportationInvoiceNo: 'Receipt invoice no',
        landfillInvoiceNo: 'Site invoice no',
        date: 'Date',
        truckRegistrationNumber: 'Truck registration number',
        transportCompany: 'Transport company',
    },
    MassCard: {
        transported: 'Transported',
        ordered: 'Ordered',
        received: 'Received',
        remaining: 'Remaining',
    },
    pplCompany: {
        mergedTo: 'Merged to:',
        title: 'Customers',
        customerNumber: 'Customer no.',
        organizationNumber: 'Organization no.',
        companyName: 'Name',
        phone: 'Phone',
        phone2: 'Phone 2',
        email: 'Email',
        web: 'Web',
        contactPerson: 'Contact person',
        contactPerson2: 'Contact 2',
        invoiceAddress: 'Invoice address',
        invoiceAddress2: 'Invoice address 2',
        zipCode: 'Zip code',
        city: 'City',
        country: 'Country',
        comment: 'Comment',
        active: 'Active',
        drivers: 'Drivers',
        addDriver: 'Add a driver',
        sync: 'Synchronize',
        syncStatus: 'Synchronization status',
        syncStop: 'Stop synchronization',
        syncStopped: 'Synchronization has been stopped',
        alert: 'Name is required',
        mergeTo: 'Merge',
        selectCompanyToMerge: 'Select another customer',
        toSelectedCompany: 'Selected one is target',
        toCurrentCompany: 'Current one is target',
        apiKey: 'API key',
        generateAPIKey: 'Generate API key',
        generationSuccess: 'API key generated',
    },
    pplSynchronize: {
        completed: 'Completed!',
        error: 'Error: ',
        inprogress: 'In progress: ',
    },
    driver: {
        title: 'Drivers',
        name: 'Name',
        status: 'Status',
        registered: 'Registered',
        email: 'email',
        phoneNumber: 'Phone number',
        companies: 'Companies',
        filter: {
            ACTIVE: 'Active',
            INACTIVE: 'Inactive',
            ARCHIVED: 'Archived',
        },
    },
    errorCodes: {
        INTERNAL_ERROR: 'Internal server error',
        REQUEST_TIMEOUT: 'The server did not receive a complete request message within the requested time.',
        INVALID_ID: 'Invalid id',
        PROJECT_NOT_FOUND: 'Project not found!',
        LANDFILL_NOT_FOUND: 'Site not found!',
        LANDFILL_EVENT_NOT_FOUND: 'Landfill delivery event not found!',
        LANDFILL_EVENT_ALREADY_ATTACHED: 'Landfill delivery event already attached!',
        DRIVER_NOT_FOUND: 'Driver not found!',
        DRIVER_EMPTY_PASSWORD: 'Empty password',
        DRIVER_INVALID_OLD_PASSWORD: 'Invalid old password',
        DRIVER_PASSWORD_NOT_EQUAL: 'Passwords are not equal',
        DRIVER_EMAIL_EXISTS: 'Driver with the same email already exists',
        DRIVER_PHONE_EXISTS: 'Driver with the same phone already exists',
        DRIVER_IS_NOT_ACTIVE: 'Driver is not active',
        INVALID_VERIFICATION_CODE: 'Invalid verification code',
        NETWORK_ERROR: 'A network error occurred!',
        ACCESS_VIOLATION: 'Access violation!',
        NOT_NULL_EXPECTED: 'Not null expected!',
        FORTNOX_ENTITY_NOT_FOUND: 'Fortnox entity is not found! This entity cannot be synchronized at all',
        FORTNOX_SYNC_TIMEOUT: 'Sorry, Fortnox synchronization timeout. Please re-run it',
        FORTNOX_ANOTHER_SYNC_IN_PROGRESS: 'Another sync is in progress. Please re-run it later',
        FORTNOX_SYNC_CANCELED: 'Fortnox sync canceled by user',
        FORTNOX_NETWORK_ERROR:
            "A network error occurred during communications with Fortnox. Please try again. Contact technical support if the error didn't disappear",
        INVALID_INPUT: 'Invalid input!',
        END_DATE_IS_BEFORE_START_DATE: 'End date is before start date!',
        LANDFILL_INVALID_RECEIVER_USER: 'A Site cannot be assigned to the user!',
        SUBAREA_PRICE_NOT_SPECIFIED: 'Material base price should be specified!',
        SUBAREA_USED_IN_MATCHING: 'Material is used in matching!',
        INVALID_DATE: 'Invalid date',
        VALUE_ALREADY_CHANGED:
            'Current data cannot be saved, someone else has changed the source data. Please refresh or reopen this page in a new window.',
        ORDER_NOT_FOUND: 'Order not found in database!',
        ORDER_ALREADY_ATTACHED: 'Not allowed. The order is already attached to a dump load!',
        ORDER_RECEIPTS_DIRECTORY_EXISTS: 'Not allowed. There is the same directory on server',
        ORDER_RECEIPTS_DIRECTORY_RENAME_ERROR: 'Error. Cannot rename order directory',
        ORDER_RECEIPTS_FILE_RENAME_ERROR: 'Error. Cannot rename receipt file',
        PROJECT_NAME_INVALID: 'Invalid project name!',
        PROJECT_HAS_CUSTOMER: 'Not allowed. Project already has a customer!',
        PROJECT_HAS_ORDER_WITH_OTHER_CUSTOMER: 'Not allowed. Project already has order with another customer!',
        PROJECT_ACTIVE_LOCATION_CANNOT_BE_CHANGED: 'Project is active, location cannot be changed!',
        PROJECT_IS_NOT_AVAILABLE: 'Project is not available',
        PROJECT_EXTERNAL_ID_SHOULD_BE_SPECIFIED: 'Project external id should be specified for Despatch Advice settings',
        DUMP_LOAD_NOT_FOUND: 'Dump load not found!',
        DUMP_LOAD_STATUS_UNKNOWN: 'Dump load status is unknown!',
        DUMP_LOAD_INVALID_TARGET_STATUS_OR_NO_PERMISSIONS: 'Dump load have invalid target status or has no permission!',
        DUMP_LOAD_NO_PERMISSIONS: 'Dump load has no permission!',
        DUMP_LOAD_EDIT_FORBIDDEN_IN_CURRENT_STATUS: 'Not allowed to edit dump load in current status!',
        DUMP_LOAD_FIELDS_HAVE_INVALID_VALUES: 'Next fields must be filled',
        DUMP_LOAD_NOT_SPECIFIED: 'Dump load not specified!',
        DUMP_LOAD_NOT_SIGNED: 'Not allowed to update dump load status until the document is signed!',
        DUMP_LOAD_NEED_TO_SEND: 'Not allowed to update dump load status until the document is sent for signing!',
        DUMP_LOAD_CUSTOMER_NEED_FOR_SIGN: 'Not allowed to send documents for signing without a customer!',
        DUMP_LOAD_COMMENT_REQUIRED: 'Mass description cannot be empty!',
        DUMP_LOAD_HAS_DESPATCH_ADVICE: 'Dump load already has sent despatch advice!',
        CANNOT_SET_DATE_IN_PAST: 'Cannot set a date in the past!',
        PROJECT_EDIT_FORBIDDEN_IN_CURRENT_STATUS: 'Not allowed to edit project in current status!',
        LINK_EXPIRED: 'Link expired',
        USER_EXISTS: 'User already exists!',
        USER_REQUIRED_CUSTOMER: 'Users company field cannot be empty!',
        USER_NOT_FOUND: 'User was not found in the database!',
        USER_EMPTY_OLD_PASSWORD: 'Old password missing!',
        USER_INVALID_OLD_PASSWORD: 'Invalid old password!',
        USER_PASSWORD_NOT_EQUAL: 'Passwords are not equal!',
        USER_PASSWORD_SIMILAR_TO_CURRENT: 'New password equal to the current one!',
        USER_HAS_LINKED_PROJECTS:
            'User have linked projects! Please detach all projects before changing the user role!',
        USER_NAME_CANNOT_BE_EMPTY: 'User name cannot be empty!',
        USER_ALREADY_INVITED: 'User already invited!',
        INVALID_ROLE_FOR_EMAIL: 'User with the specified email could not be added for the selected role!',
        USER_ALREADY_JOINED_PROJECT: 'User already joined project!',
        USER_SURNAME_CANNOT_BE_EMPTY: 'User surname cannot be empty!',
        PROJECT_NAME_CANNOT_BE_EMPTY: 'Project name cannot be empty!',
        PROJECT_INVOICE_REFERENCE_CANNOT_BE_EMPTY: 'Project invoice reference cannot be empty!',
        LANDFILL_NAME_CANNOT_BE_EMPTY: 'Site name cannot be empty!',
        LANDFILL_STATUS_NOT_FOUND: 'Site status not found!',
        LANDFILL_STATUS_NAME_CANNOT_BE_EMPTY: 'Site status name cannot be empty!',
        LANDFILL_STATUS_EXISTS: 'Site status already exists!',
        LANDFILL_TYPE_CANNOT_BE_EMPTY: 'Site type cannot be empty!',
        LANDFILL_CUSTOMER_REQUIRED: "Site's customer required",
        TOC_VALUE_REQUIRED: 'TOC-value required',
        TOC_VALUE_INCORRECT: 'TOC-value should be between $1 and $2',
        INVASIVE_SPECIES_REQUIRED: 'Invasive species required',
        LANDFILL_IS_NOT_AVAILABLE: 'Site is not available',
        EMPTY_OWNER: 'Owner cannot be empty!',
        TOXIC_LIMITS_INVALID_VALUE: 'Toxic limits has invalid value!',
        DUMP_LOAD_STATUS_NOT_FOUND: 'Current dump load status is not found!',
        DUMP_TYPE_NOT_FOUND: 'Dump type not found!',
        DUMP_TYPE_EXISTS: 'Dump type with the same name already exists!',
        DUMP_TYPE_NAME_CANNOT_BE_EMPTY: 'Dump type name cannot be empty!',
        DUMP_TYPE_VOLUME_CANNOT_BE_EMPTY: 'Dump type volume cannot be empty!',
        DUMP_TYPE_TONS_PER_M3_MUST_BE_POSITIVE: 'Dump type tons per m3 must be positive!',
        DUMP_LOAD_CAN_NOT_BE_CREATED_FOR_DELETED_CUSTOMER:
            'Dump load can not be created for project for deleted customer',
        EMAIL_EXISTS: 'This email already exists ',
        EMAIL_BLOCKED: 'This email is blocked',
        PERMISSION_ERROR: "You don't have permission to access it!",
        PLEASE_SELECT_ADDRESS_ON_MAP: 'Please select address on map!',
        DELIVERY_LINE_NOT_FOUND: 'Delivery line is not found!',
        COMPANY_NOT_FOUND: 'Company is not found!',
        COMPANY_DUPLICATE_ORGANIZATION_NUMBER: 'Company with the same organization number found',
        COMPANY_DUPLICATE_GLN: 'Company with the same GLN found',
        COMPANY_HAS_CONNECTED_ENTITIES: 'Company can not be removed. It has connected active entities',
        SUBSTANCE_IS_USED: 'Substance is used and can not be removed',
        SUBSTANCE_CONTAMINATION_TYPE_IS_USED: 'Substance contamination type is used!',
        SUBSTANCE_NOT_FOUND: 'Substance not found!',
        EMPTY_COMPANY: 'Empty company!',
        NOT_IMPLEMENTED: 'Not implemented!',
        FILE_NOT_FOUND: 'File not found',
        INVALID_FILE_NAME: 'The file name is invalid!',
        DEVIATION_NO_PERMISSIONS: 'Deviation access violation!',
        DEVIATION_EDIT_WRONG_STATUS: 'Wrong status for deviation!',
        DEVIATION_NOT_FOUND: 'Deviation not found!',
        DEVIATION_INVALID_FIELDS: 'Required fields cannot be empty!',
        CONVERSION_TO_PDF_ERROR: 'Conversion to PDF error!',
        PROJECT_END_DATE_BEFORE_START_DATE: "Project end date can't be before Project start date!",
        PROJECT_NO_DATE_CHANGE_ONGOING_MASS: "Project dates can't be changed due to mass ongoing status!",
        PROJECT_START_DATE_AFTER_MASS_START_DATE: "Project start date can't be after the Mass start date!",
        PROJECT_END_DATE_BEFORE_MASS_END_DATE: "Project end date can't be before the Mass end date!",
        PROJECT_CUSTOMER_REQUIRED: "Project's customer required",
        MASS_START_DATE_BEFORE_PROJECT_START_DATE: "Mass start date can't be before the Project start date!",
        MASS_START_DATE_BEFORE_TODAY: "Mass start date can't be before today!",
        MASS_END_DATE_BEFORE_PROJECT_START_DATE: "Mass end date can't be before the Project start date!",
        MASS_END_DATE_BEFORE_MASS_START_DATE: "Mass end date can't be before the Mass start date!",
        MASS_END_DATE_BEFORE_TODAY: "Mass end date can't be before today!",
        PROJECT_END_DATE_MUST_BE_UPDATED: 'PROJECT_END_DATE_MUST_BE_UPDATED',
        PROJECT_LOCATION_CANNOT_BE_EMPTY: 'Project location could not be empty',
        PROJECT_LOCATION_ON_WATER: 'Project location could be only on land',
        LANDFILL_LOCATION_CANNOT_BE_EMPTY: 'Site location could not be empty',
        LANDFILL_LOCATION_ON_WATER: 'Site location could be only on land',
        LOCATION_UNREACHABLE: 'Location unreachable',
        LOCATION_NOT_GEOCODED: 'Finish input to geocode location',
        OPEN_FROM_AFTER_OPEN_TO: 'Open time should be before close time',
        NO_OPEN_WORK_DAY: 'Should be at least one open work day',
        FILE_EXISTS: 'File with same name already exists!',
        FILE_TOO_LARGE: 'File size is too large',
        API_KEY_NOT_SPECIFIED: 'API key no specified',
        API_KEY_INVALID: 'API key invalid',
        DELIVERY_LINE_ORDER_CANNOT_BE_EMPTY: 'Order should be specified',
        DELIVERY_LINE_WEIGHT_CANNOT_BE_EMPTY: 'Weight should be specified',
        DELIVERY_LINE_DATE_CANNOT_BE_EMPTY: 'Date should be specified',
        DELIVERY_LINE_TRANSPORT_COMPANY_CANNOT_BE_EMPTY: 'Transport company should be specified',
        DELIVERY_LINE_TRUCK_NUMBER_CANNOT_BE_EMPTY: 'Truck registration number should be specified',
        DELIVERY_LINE_VEHICLE_TYPE_CANNOT_BE_EMPTY: 'Vehicle type should be specified',
        DELIVERY_LINE_FUEL_CANNOT_BE_EMPTY: 'Fuel should be specified',
        DELIVERY_LINE_ENGINE_CANNOT_BE_EMPTY: 'Engine should be specified',
        DELIVERY_LINE_RECEIPT_CANNOT_BE_EMPTY: 'Receipt should be attached',
        DELIVERY_LINE_DUMP_LOAD_IS_DONE: 'The mass for this order is in the status DONE',
        INVALID_LOCATION: 'Invalid location',
        TOO_MANY_REQUESTS: 'Daily email limit reached',
        PHONE_NUMBER_EXISTS: 'Phone number is already in use in the application',
        PHONE_NUMBER_HAVE_TO_BE_LOCAL_OR_INTERNATIONAL: 'Phone number have to start with 0 or +46',
        PHONE_NUMBER_TOO_SHORT: 'Phone number is too short',
        PHONE_NUMBER_TOO_LONG: 'Phone number is too long',
        INVALID_PHONE_NUMBER: 'Invalid phone number',
        NOT_AUTHORIZED: 'User not authorized',
        USER_BLOCKED: 'User blocked',
        USER_NOT_CONFIRMED: 'User email not confirmed',
        USER_NOT_VERIFIED: 'User not approved',
        INVALID_ORGANIZATION_NUMBER: 'Invalid organization number',
        INVALID_GLN: 'Invalid GLN',
        ORDER_NUMBER_REQUIRED: 'Order number can not be empty',
        ORDER_PLANNED_START_DATE_REQUIRED: 'Order planned start date can not be empty',
        ORDER_DUPLICATE_ORDER_NUMBER: 'Order with the same order number found',
        VALUE_ALREADY_EXISTS: 'Same value already exists',
        GOOGLE_API_ERROR: 'Geocode service error',
        GOOGLE_API_ZERO_RESULTS: 'Geocode failed, no results found',
        LIMIT_OF_FILES_FOR_DOWNLOAD_IS_OUT_OF_RANGE:
            'The limit of files available for download is out of range. Please choose fewer files to download',
        UNKNOWN_GQL_ERROR: 'Unknown GQL error',
        TRUCK_NOT_FOUND: 'Truck not found',
        ANOTHER_DRIVER_DELIVERY_LINE_IN_PROGRESS: 'Driver already has delivery line in progress',
        ALREADY_TRUCK_DELIVERY_LINE_IN_PROGRESS: 'This truck already has delivery line in progress',
        WRONG_VERSION: `The version of the application you are using is out of date. 
Please reload the page to continue.
Windows: ctrl + F5
MacOS: cmd + shift + R
`,
        BEAST_MATERIAL_EXISTS: 'BEAst material already exists',
        BEAST_MATERIAL_ID_CANNOT_BE_EMPTY: 'BEAst material ID cannot be empty!',
        BEAST_MATERIAL_NAME_CANNOT_BE_EMPTY: 'BEAst material name cannot be empty!',
        DELIVERY_LINE_HAS_PEPPOL_RESPONSE: 'Delivery can not be updated as it has accepted Despatch Advice Response',
        TIME_SPENT_HAS_PEPPOL_RESPONSE: 'Time spent can not be updated as it has accepted Despatch Advice Response',
        INVALID_TEST_PEPPOL_PARTIES: 'Incorrect settings for test PEPPOL parties',
        VEHICLE_TYPE_IS_USED: 'Can not delete vehicle type, its used in users vehicles',
        VEHICLE_TYPE_NOT_FOUND: 'Vehicle type not found',
        NO_ACTIVE_WHATSAPP_PHONE: 'No active phone for whatsapp api',
        INCORRECT_CHAT_GPT_ASSISTANT_ID: 'Incorrect chat GPT assistant id',
        OPEN_AI_ERROR: 'ChatGPT API error occurred',
        LANDFILL_BATCH_NOT_FOUND: 'Landfill batch not found',
        LANDFILL_BATCH_CHANGE_INCORRECT: 'Can not apply changes to the batch log',
        LANDFILL_BATCH_TRANSFER_NOT_FOUND: 'Batch transfer not found',
        LANDFILL_TRANSFER_BATCHES_SHOULD_BE_DIFFERENT: 'Landfill transfer batches should be different',
        LANDFILL_BATCH_ANALYSIS_NOT_FOUND: 'Batch analysis not found',
    },
    signStatuses: {
        [SignDocumentStatuses.CREATED]: 'The signing request has been created. Waiting for the start of the process',
        [SignDocumentStatuses.SIGN_PROGRESS]: 'Awaiting the signing of the parties',
        [SignDocumentStatuses.SIGNED]: 'Document signed',
        [SignDocumentStatuses.ERROR]: 'An unexpected error occurred while signing the document',
        [SignDocumentStatuses.TIME_OUTED]: 'The time for signing the document has been passed',
        [SignDocumentStatuses.CANCELED]: 'The document was canceled by the Pinpointer admin',
        [SignDocumentStatuses.REJECTED]: 'One of the parties refused to sign the document',
    },
    signActions: {
        checkEmail: 'The signing request has been created. Check your email.',
    },
    DumploadStatusQuestMarkAsDone:
        'Are you sure you want to close and mark this Order as done? No more transports will be registered on this order after closure!',
    DumploadActions: {
        REOPEN: 'Re-open',
        REQUEST: 'Request Quote',
        DISCARD: 'Discard Order',
        REJECT: 'Reject',
        CONFIRM: 'Confirm',
        BUY: 'BUY!',
        START_DELIVERY: 'Start deliveries',
        DONE: 'Done',
        waitSender: 'Waiting for an update by sender',
        waitPinpointer: 'Waiting for an update by Pinpointer',
        nextStatusDisabled: 'Unable to proceed to the next status',
        requestQuoteInfo: 'By requesting a Quote you are not bound to buy anything.',
    },
    dateFormat: 'yyyy-MM-dd',
    at: 'at',
    DumploadStatuses: {
        DISCARDED: 'Discarded',
        NOT_ORDERED: 'Quote rejected',
        DRAFT: 'Draft',
        REQUESTED: 'Quote requested',
        CONFIRMED: 'Quote Confirmed',
        ORDERED: 'Quote Ordered',
        IN_PROGRESS: 'In progress',
        DONE: 'Order complete',
    },
    DumploadStatusesSochi: {
        DISCARDED: 'Discarded',
        NOT_ORDERED: 'Rejected',
        DRAFT: 'Draft',
        REQUESTED: 'Requested',
        CONFIRMED: 'Confirmed',
        ORDERED: 'Ordered',
        IN_PROGRESS: 'In progress',
        DONE: 'Done',
    },
    AdminDumpLoadTableStatuses: {
        REQUESTED: 'Requested',
        CONFIRMED: 'Offered',
        ORDERED: 'Ordered',
        NOT_ORDERED: 'Rejected',
        IN_PROGRESS: 'In progress',
        DONE: 'Done',
    },
    InfoMessagesSochi: {
        ACTIVE_OFFER: 'Active offer! Please accept or decline the offer.',
    },
    WeekSummary: {
        weekSummary: 'Weekly overview (Planned)',
        weight: 'Weight',
        transportations: 'Transports',
    },
    TimeFilters: {
        DAY: 'Day',
        WEEK: 'Week',
        MONTH: 'Month',
        YEAR: 'Year',
    },
    contaminationValues: {
        mrr: '<MRR',
        km: '>MRR <KM',
        mkm: '>KM <MKM',
        ifa: '>MKM <FA',
        fa: '>FA',
        inert: 'INERT',
        ifaLeaching: 'IFA',
        limit: 'FA',
        na: 'N/A',
        custom: 'Custom',
        select: 'Select level',
    },
    DumploadSummarySection: {
        amount: 'Amount',
        startDate: 'Start date',
        Distance: 'Distance',
        Duration: 'Duration',
        LoadFee: 'Load fee / ton',
        InternalLoadFee: 'Internal load fee / ton',
        trips: 'Trips',
        landfillPrice: 'Mass(s) price',
        linkForSign: 'Link to sign',
        linkForSignDescription: 'This link is intended for signing an order from the customer side.',
        withoutMatching: 'excl. matching',
        withMatching: 'incl. matching',
        inbound: 'Inbound',
        outbound: 'Outbound',
    },
    AdminDestinationResultInfoCodes: {
        MATCHED_LANDFILLS_NOT_FOUND: 'Matched Site not found.',
        TRANSPORT_CONF_EMPTY: 'Unable to calculate approximated results, transport configuration is invalid.',
        GOOGLE_SERVICE_UNAVAILABLE: 'Unable to calculate distances. Google services unavailable',
        LANDFILL_ROUTE_UNAVAILABLE: 'Unable to calculate distances. Site is in unreachable place',
        AUTOMATIC_PRICES_UNAVAILABLE: 'This one should never appear',
        SKIP_MATCHING_MODE: 'No matching mode',

        DUMP_TYPE_EMPTY: 'Unable to calculate approximated results. Please select dump type.',
        LOCATION_EMPTY: 'Unable to calculate approximated results. Please choose project location.',
        NOT_ALLOCATED_TRIPS: 'Not all the masses allocated.',
        OVER_ALLOCATED_TRIPS: `There're over allocated masses.`,
    },
    SochiDestinationResultInfoCodes: {
        MATCHED_LANDFILLS_NOT_FOUND:
            'No matched receivers found. If you want to be contacted by a representative from Pinpointer for assistance, please click "Request offer" and we will contact you.',
        TRANSPORT_CONF_EMPTY:
            'No matched receivers found. If you want to be contacted by a representative from Pinpointer for assistance, please click "Request offer" and we will contact you.',
        GOOGLE_SERVICE_UNAVAILABLE:
            'No matched receivers found. If you want to be contacted by a representative from Pinpointer for assistance, please click "Request offer" and we will contact you.',
        LANDFILL_ROUTE_UNAVAILABLE:
            'No matched receivers found. If you want to be contacted by a representative from Pinpointer for assistance, please click "Request offer" and we will contact you.',
        AUTOMATIC_PRICES_UNAVAILABLE:
            'No matched receivers found. If you want to be contacted by a representative from Pinpointer for assistance, please click "Request offer" and we will contact you.',
        SKIP_MATCHING_MODE: 'No matching mode',

        DUMP_TYPE_EMPTY: 'Unable to calculate approximated results. Please select dump type.',
        LOCATION_EMPTY: 'Unable to calculate approximated results. Please choose project location.',
        NOT_ALLOCATED_TRIPS:
            'Not all the masses allocated. If you want to be contacted by a representative from Pinpointer for assistance, please click "Request quote" and we will contact you.',
        OVER_ALLOCATED_TRIPS: `There're over allocated masses. If you want to be contacted by a representative from Pinpointer for assistance, please click "Request quote" and we will contact you.`,
    },
    ProjectStatus: {
        NEW: 'New',
        IN_PROGRESS: 'In progress',
        DONE: 'Done',
        DELETED: 'Deleted',
    },
    UserStatus: {
        REGISTERED: 'Waiting for confirmation email',
        EMAIL_CONFIRMED: 'Waiting for approval',
        ENABLED: 'Enabled',
        BLOCKED: 'Blocked',
    },
    DriverStatus: {
        ACTIVE: 'Active',
        ARCHIVED: 'Archived',
        INACTIVE: 'Inactive',
    },
    UserRole: {
        [UserRole.EMPLOYEE]: 'Bus.Admin',
        [UserRole.ADMIN]: 'Sys.Admin',
        [UserRole.EXTERNAL_RECEIVER]: 'User',
        [UserRole.DRIVER]: 'Truck Driver',
        [UserRole.EXCAVATOR_OPERATOR]: 'Excavator Operator',
    },
    UserContextRole: {
        [UserContextRole.OWNER]: 'Owner',
        [UserContextRole.PROJECT_USER]: 'Project user',
        [UserContextRole.RECEIVER]: 'Receiver',
        [UserContextRole.GUEST]: 'Guest',
        [UserContextRole.UNKNOWN]: 'Unknown',
        [UserContextRole.AUTHOR]: 'Author',
        [UserContextRole.PROJECT_EXCAVATOR_OPERATOR]: 'Excavator operator',
        [UserContextRole.PROJECT_DRIVER]: 'Driver',
    },
    DespatchAdviceType: {
        [DespatchAdviceType.ORIGINAL]: 'Original',
        [DespatchAdviceType.REPLACE]: 'Replace',
        [DespatchAdviceType.CANCELLATION]: 'Cancellation',
    },
    DespatchAdviceResponseType: {
        [DespatchAdviceResponseType.ACCEPTED]: 'Accepted',
        [DespatchAdviceResponseType.DECLINED]: 'Declined',
    },
    DeliveryLineBeastStatus: {
        [BeastStatus.DISABLED]: 'Disabled',
        [BeastStatus.NOT_SENT]: 'Not sent',
        [BeastStatus.SENT]: 'Sent',
        [BeastStatus.ACCEPTED]: 'Accepted',
        [BeastStatus.DECLINED]: 'Declined',
    },
    OverviewTab: {
        amount: 'Amount',
        startDate: 'Start date',
        dumpType: 'Material',
    },
    ToxicLimitErrorCodes: {
        equal: '{item1} cannot equal {item2}',
        bigger: '{item1} cannot be bigger {item2}',
        lesser: '{item1} cannot be less than {item2}',
    },
    DumploadPrice: {
        landfillPricePerTon: 'Landfill fee (SEK/ton) to customer',
        internalLandfillPricePerTon: 'Landfill fee (SEK/ton) from reception',
        forceZeroPrices: 'Use zero prices',
    },
    PROJECT: {
        startDate: 'Project start date',
        endDate: 'Project end date',
        status: 'Project status',
        location: {
            lat: 'Project location latitude',
            lng: 'Project location longitude',
        },
    },
    PROJECT_LOAD: {
        declaration: 'Declaration of waste (all fields before "Typ av avfall" needs to be filled)',
        status: 'Dumpload status',
        dumpType: 'Material',
        order: 'Order',
        amount: 'Amount',
        unitOfMeasure: 'Unit of measure',
        date: 'Start date',
        landfillPrice: 'Mass price',
        projectId: 'Project id',
        orderChangeTitle: 'Confirm order change',
        orderNewHasDeliveryLines: 'Order you chose already has delivery lines. Replace with selected order anyway?',
        orderOldHasDeliveryLines: 'Order $0 already has delivery lines. Replace this order with selected one anyway?',
        signStatus: 'Signing status',
        signAction: 'Send for signing',
        signErrorTitle: 'Error',
        signStatusTitle: 'Status',
    },
    DeclarationOfWaste: {
        title: 'DEKLARATION AV AVFALL',
        serialNumber: 'Deklarationsnr',
        wasteProducer: 'Avfallsproducent',
        customerNumber: 'KundNr.',
        invoice: 'Fakturamottagare',
        address: 'Address',
        zipCode: 'Postnr.',
        place: 'Ort',
        reference: 'Referens',
        phone: 'tel',
        organizationNr: 'Organizationsnr.',
        ewcCode: 'Avfallskod',
        wasteType: 'Typ av avfall',
        pollutionType: 'Typ av förorening',
        process: 'Process där avfallet uppstått',
        reason: 'Anledning till avfall',
        consistency: 'Konsistens vid +5 grader',
        risks: 'Risker',
        liquid: 'Flytande',
        viscous: 'Trögflytande',
        solid: 'Fast',
        pasty: 'Pastöst',
        powder: 'Pulver',
        small: 'Småemb. I större emb.',
        other: 'Övrigt',
        toxic: 'Giftigt',
        flammable: 'Brandfarligt, flampunkt',
        acid: 'Surt pH',
        hazard: 'Skadligt',
        explosive: 'Explosivt, riskgr.',
        alk: 'Alk pH',
        harmful: 'Hälsofarligt',
        hazardous: 'Miljöfarligt',
        corrosive: 'Frätande',
        oxidising: 'Oxiderande',
        hygiene: 'AFS hyg. Gränsvärden, grp A eller B',
        stability: 'Stabilitet/Reaktivitet',
        wasteContains: 'Avfallet innehåller(t.ex. vatten, organisk förorening, halogener, alkalimetaller, svavel etc.)',
        subject: 'Ämne',
        value: 'Värde',
        unit: 'Enhet',
        totalWaste: 'Total mängd avfall',
        quantityPerDelivery: 'Mängd per leverans:(vid större partier)',
        tank: 'Lev i tankbil/slamsug',
        container: 'Lev i container/flak',
        rail: 'Lev med järnväg',
        ca: 'ca',
        grossTon: 'Ton brutto',
        pack: 'Förpackning',
        packaging: 'Emballage',
        quantity: 'Antal',
        transport: 'Transport',
        unNr: 'UN-nr',
        class: 'Klass',
        conveyor: 'Transportör',
        otherInfo: 'Övrig information',
        isAttachedProductInfo: 'Varuinfo bifogas',
        otherCar: 'Övriga bil st',
        date: 'Datum',
        wasteOriginate: 'Plats där avfallet uppstått',
        wasteOriginateMunicipality: 'Kommun där avfallet uppstått inklusive kommunkod',
        hasMassEffluentRecord:
            'a) Kommer massorna från en fastighet där utfyllnad, industriverksamhet eller saneringsarbeten förekommit, eller där det historiskt sett förekommit någon form av utsläpp?',
        hasContaminatedSoilGroundWater:
            'b) Finns det eller har det funnits avfallsupplag, kemikalieupplag, cisterner/tankar, oljeavskiljare eller ledningar i mark som kan ha förorenat mark och grundvatten?',
        hasDifferentSmellAndColor: 'c) Har massorna avvikande lukt och färg?',
        hasOccuredByExcavationOfPath:
            'd) Har massorna uppstått vid schaktning av bangård, banvallar eller vägbank vid väg?',
        hasPetroleumProductBeenOnSite: 'e) Har tankning av petroleumprodukter skett på platsen?',
        hasAnyAccidentsOccuredOnProperty: 'f) Har några olyckor inträffat på fastigheten ex spill, brand, explosion?',
        environmentalTechnicalOrOtherAssessments:
            'Om ni svarar ja på någon av de ovanstående frågor (a-f) ska en miljöteknisk bedömning eller annan förklaring bifogas som bekräftar att massorna är utan risk för negativ miljöpåverkan.\n' +
            'Följande bifogas:',
        hasEnvironmentalTechnicalAssessment: 'Miljöteknisk bedömning',
        hasOtherAssessment: 'Annan bedömning',
        massType: 'Typ av massor',
    },
    MapPage: {
        cannotResolveLocation: 'Cannot resolve location. Please change input or try later',
        cannotModifyLocation: 'Project is active, location cannot be changed!',
        landfillStatusFilter: {
            ALL: 'All',
            OWN: 'Own',
            EXTERNAL: 'External',
        },
    },
    PoiInfoPanel: {
        selectSiteOnTheMap: 'Select site on the map for route preview',
        selectProjectOnTheMap: 'Select project on the map for route preview',
        noProjectDumpLoads: 'Project does not contain any orders',
        serialNumber: 'Order',
        landfill: 'Site',
        noLandfillDumpLoads: 'Landfill does not have orders',
        projectName: 'Project',
        noOwnLandfills: 'No sites available',
    },
    ProjectOffers: {
        declarationNumber: 'Declaration Number',
    },
    ReceiptModal: {
        scaleReceiptScan: 'Scale receipt scan',
        orderNumber: 'Order number',
        regNumber: 'Reg. number',
        date: 'Date',
        weight: 'Weight',
    },
    ProjectDocuments: {
        other: 'Other documents',
        weightReceipt: 'Weight receipt',
        analysis: 'Analysis',
        noDocumentationFound: 'No documentation found',
        add: 'Add',
        analysisDocuments: 'Analysis documents',
        documentName: 'Document name',
        addAnalysis: 'Add analysis',
        files: 'Files',
        declarationOfWaste: 'Declaration of waste',
        weightReceiptTable: {
            project: 'Project',
            cantFetchWeightReceiptRightNow: 'Cant fetch weight receipt right now.',
            transportedCompany: 'Transport company',
            declarationNumber: 'Declaration nr',
            customerInvoice: 'Customer invoice no.',
            mass: 'Mass',
            level: 'Level',
            landfillName: 'Receiver',
            registrationNumber: 'Registration no.',
            comment: 'Description',
            notes: 'Notes',
            truckCapacity: 'Truck capacity (Ton)',
            loadPercentage: 'Load percentage',
            date: 'Date',
            weight: 'Weight (Ton)',
            distance: 'Distance one way (km)',
            scaleReceipt: 'Scale receipt',
            cost: 'Price (SEK)',
            excavated: 'Excavated',
            loaded: 'Loaded',
            delivered: 'Delivered',
            excavatorOperator: 'Excavator op.',
            check: 'Checked',
            photo: 'Photo',
            detach: 'Detach',
            confirmDetach: 'Confirm detach',
            photos: 'Photos',
            timestamp: 'Timestamp',
            verifiedByPinpointer: 'Verified by Pinpointer',
            invoiceNumber: 'Invoice number',
            batch: 'Batch',
            attach: 'Attach',
            change: 'Change',
            detachFromBatchTitle: 'Detach delivery from the batch?',
            detachFromBatchMessage: 'Are you sure you want to detach delivery?',
            applyChangesErrorTitle: 'Can not apply changes to then batch log',
            applyChangesErrorMessage: 'Current change causes negative amount at date: ',
        },
        buttons: {
            view: 'View',
            showAll: 'Show all',
            delete: 'Delete',
            download: 'Download',
            downloadAll: 'Download all',
            downloadSelected: 'Download selected',
            downloadAllPhotos: 'Download all photos',
            downloadSelectedPhotos: 'Download selected photos',
            exportToXlsx: 'Export to xlsx',
        },
        maximumFileSizeNote: 'The maximum allowed file size to upload is 20MB!',
        ewc: 'EWC',
        documentType: {
            CERTIFICATE: 'Project certificate',
            ANALYSIS: 'Mass analysis',
        },
    },
    TransportSchedule: {
        editTransportSchedule: 'Edit transport schedule',
        startDate: 'Start date',
        endDate: 'End date',
        totalMass: 'Total mass',
        distributeEvenly: 'Distribute evenly',
        distributeEvenlyConfirm: 'This action will lead to an even redistribution of the transport schedule. Proceed?',
        transportScheduleSaved: 'Transport schedule saved.',
    },
    settingsPage: {
        title: 'Settings',
        featuresFlags: {
            title: 'Features Flags',
            captchaEnabled: 'Is captcha enabled',
            CAPTCHA: 'Captcha',
            ChatGPT: 'ChatGPT',
            Peppol: 'Peppol',
            Whatsapp: 'Whatsapp',
            active: 'Active',
            notActive: 'Not active',
            enabled: 'Enabled',
            disabled: 'Disabled',
        },
        transportConfig: {
            title: 'Transport Configuration',
            tonnsPerTruck: 'Tonnes Per Truck',
            pricePerKm: 'Price per km',
            pricePerHour: 'Price per Hour',
            tax: 'Margin(%)',
            fixedPrice: 'Fixed price',
            co2perKm: 'CO2 emission (kg/km)',
        },
        companyConfig: {
            title: 'Company Configuration',
            orgName: 'Organization Name',
            orgNumber: 'Organization Number',
            place: 'City',
            postNumber: 'Post number',
            address: 'Address',
            tel: 'Tel',
            email: 'e-mail',
            cityPlace: 'City/Place',
            contactPhoneNumber: 'Contact phone number',
            contactEmail: 'Contact E-mail',
            version: 'Version',
        },
        keys: {
            title: 'Api keys and secrets',
            captchaSiteKey: 'ReCaptcha site key (public key)',
            captchaSecretKey: 'ReCaptcha secret key',
            chatGptApiKey: 'ChatGPT API key',
            chattGptAssistantId: 'ChatGPT assistant id',
            tickstarClientId: 'Tickstar client Id',
            tickstarClientSecret: 'Tickstar client secret',
            tickstarToken: 'Participant lookup API token',
            pinpointerGLN: 'Pinpointer GLN',
            testDespatchPartyOrgNumber: 'Despatch party organization number (test only)',
            testConsigneeOrgNumber: 'Consignee organization number (test only)',
            mytapiProductId: 'Mytapi product ID',
            mytapiToken: 'Mytapi token',
        },
        validation: {
            captchaKeysShouldBeFilled: 'Could you fill in the captcha keys if you want to enable this feature',
            chatGptKeysShouldBeFilled: 'Could you fill in the ChatGPT keys if you want to enable this feature',
            noNegativeOrStringValue: 'Field value cannot be negative or string',
            peppolKeysShouldBeFilled: 'Could you fill in the Peppol keys if you want to enable this feature',
            mytapiKeysShouldBeFilled:
                'Could you fill in the Mytapi product ID and Token if you want to enable Whatsapp feature',
        },
        navigationTabs: {
            companyTab: 'Company',
            transportTab: 'Transport',
            apiTab: 'API',
            featuresTab: 'Features',
        },
        customScripts: {
            customScripts: 'Custom scripts',
            addCustomScript: 'Add custom script',
            editCustomScript: 'Edit custom script',
            text: 'text',
            src: 'src',
            async: 'async',
            active: 'Is active',
            order: 'Order',
        },
        database: {
            database: 'Database',
            databaseMaintenance: 'Database maintenance',
            recalculateProjectsSolidTestResults: 'Recalculate solid test results in projects',
            recalculateLandfillsSolidTestResults: 'Recalculate solid test results in landfills',
            recalculateProjectsSolidTestResultsConfirm:
                'This action will recalculate solid test result for all active masses (REQUESTED, CONFIRMED, ORDERED and IN_PROGRESS)',
            recalculateLandfillsSolidTestResultsConfirm:
                'This action will recalculate solid test result for all active subareas',
            downloadRandomReceipts: 'Download random receipts',
            randomReceiptsCountLabel: 'How many receipts to download?',
            testBugsnag: 'Test bugsnag',
        },
    },
    AccountPage: {
        account: 'Account ID',
        name: 'Name',
        surname: 'Surname',
        email: 'E-mail',
        phone: 'Phone',
        password: 'Password',
        passwordChanged: 'Password has been changed',
        logoutMessage: 'Are you sure you want to log out?',
        oldPassword: 'Old password',
        newPassword: 'New password',
        repeatPassword: 'Repeat new password',
        validation: {
            nameEmpty: 'Please fill the name field',
            fieldMustFilled: 'Field must be filled',
            passwordsMustMatch: 'Passwords must match',
        },
    },
    CompaniesTable: {
        route: 'Companies',
        customerNumber: 'Customer number',
        companyName: 'Company',
        orgNumber: 'Org.number',
        phone: 'Phone',
        email: 'Email',
        comments: 'Comments',
        location: 'Location',
        orgName: 'Org. name',
        city: 'City',
        country: 'Country',
        contactPerson1: 'Contact person #1',
        contactPerson2: 'Contact person #2',
        phoneNumber: 'Phone number #1',
        phoneNumber2: 'Phone number #2',
        website: 'Website',
        address: 'Invoice address',
        address2: 'Invoice address #2',
        zipCode: 'ZipCode',
        apiKey: 'API key',
        active: 'Active',
        isTransportCompany: 'Transport company',
        generateAPIKey: 'Generate API key',
        generationSuccess: 'API key generated',
        companySaveSuccess: 'Company successfully saved',
        apiKeyIsClear: 'Api clear successfully removed',
        fortnoxStatus: 'Fortnox status',
        createCompany: 'Create company',
        deleteConfirmation: 'Are you sure you want to delete company',
        canNotBeDeleted: '"$1" can not be deleted',
        canNotBeDeletedDescription: 'Company can not be deleted until it has active connected items',
        connectedEntities: {
            title: 'Connected entities',
            projects: 'Projects ($1)',
            landfills: 'Landfills ($1)',
            users: 'Users ($1)',
            drivers: 'Drivers ($1)',
        },
        saveBeforeAPIOperations: 'Save company before perform any operation with API key',
        GLN: 'GLN',
    },
    CompanyCard: {
        orgNumber: 'Org.number',
        email: 'Email',
        orgName: 'Org. name',
        city: 'City',
        country: 'Country',
        contactPerson1: 'Contact person #1',
        contactPerson2: 'Contact person #2',
        phoneNumber: 'Phone number #1',
        phoneNumber2: 'Phone number #2',
        website: 'Website',
        invalidEmail: 'Email is invalid',
        organizationNumberRequired: 'Organization number can not be empty',
        nameRequired: 'Name can not be empty',
        tabs: {
            edit: 'Edit',
            projects: 'Projects',
            landfills: 'Sites',
            users: 'Users',
        },
        drivers: 'Drivers',
        addDriver: 'Add a driver',
        GLN: 'GLN',
    },

    FileTemplatesPage: {
        tryAgain: 'An error occurred. Please try again later. If the problem persists, contact support',
        declarationNotFound:
            'The file is not found. There is inconsistency on Server. Please contact support and provide the current URL and the screenshot',
    },
    info: 'info',
    DialogButtons: {
        ok: 'OK',
        close: 'Close',
        confirm: 'Confirm',
        cancel: 'Cancel',
    },
    fileUploaded: 'The file uploaded successfully!',
    uploadFile: 'Upload a File',
    uploadReceipt: 'Upload receipt',
    receiptUploaded: 'Receipt uploaded',
    driverNotFound: 'Driver not found',
    reportSent: 'Report successfully sent',
    transportHasNotStartedYet: 'Transport has not started yet.',
    goToOffers: 'Got to offers',
    continue: 'Continue',
    updateProject: 'Update project',
    getStarted: 'Get started',
    addSite: 'Add a site',
    iSender: "I'm a sender",
    iSenderDescription: 'I have a project with masses that I would like to send to Site.',
    iLandfill: "I'm a Receiver",
    iLandfillDescription: 'I have a Site where I would like to receive incoming masses.',
    incoming: 'Incoming',
    masses: 'Masses',
    classification: 'Classification',
    massesFieldHint: 'What kind of material is it',
    projectHistory: 'Project History',
    transportSchedule: 'Transport schedule',
    addMass: 'Add mass',
    editMass: 'Edit',
    noMasses: 'No masses yet',
    doesNotTransportScheduleYet: "Doesn't have a transport schedule yet",
    noTransportScheduleForFilters: 'No transport schedule for selected filters',
    orderSchedule: '$1 schedule',
    hereAdjustTransportSchedule: 'Here you can adjust the transportation schedule.',
    deleteMass: 'Delete',
    rejectMass: 'Reject',
    confirmDelete: 'Confirm mass removal with type',
    confirmReject: 'Confirm mass reject with type',
    requireToFinalizeOfferRequest: 'Feature coming soon....',
    requireAnalysisToOfferRequest:
        'Add other documents here such as surveys, average calculations or if you have multiple analyses connected to the same mass.',
    getBackIn24hrs: 'Pinpointer will get back to you within 24h.',
    totalMassesInT: 'Total masses (Ton)',
    estimatedTotalTransportPerWeek: 'Estimated transports (Per week)',
    estimatedTotalInSek: 'Estimated total price (SEK)',
    file: 'File',
    files: 'Files',
    addFile: 'Add file',
    deleteFileConfirm: 'Are you sure you want to delete this file?',
    editSchedule: 'Edit schedule',
    requestOffer: 'Request quote',
    additionalDocuments: 'Additional documents',
    unit: 'Unit',
    unitFieldHint: 'Unit of measurement',
    savedAsDraft: 'Project masses successfully saved as draft!',
    tooLargeFile: 'The maximum allowed file size to upload is 20MB!',

    massCategory: 'Mass category',
    startDate: 'Start date',
    endDate: 'End date',
    seePriceEstimate: 'See price estimate',
    toxicAnalysisSochi: {
        head: {
            totalest: 'Total content ({})',
            laktest: 'Leaching ({})',
            forLevel: 'Pinpointer standard for {}',
        },
    },
    totalestTestingHead: 'Total Content (Pinpointer Standard for {})',
    laktestTestingHead: 'Leaching (Pinpointer standard for {})',
    clearValues: 'Clear values',
    priceEstimate: 'Price estimate',
    priceEstimateExplaining: 'Only an estimated price and final price may differ',
    selectMassType: 'Select type of material',
    estimatedCO2Emission: 'Estimated CO2 emissions',
    projectUpdatedSuccessfully: 'Project Updated successfully',
    editProjectDetails: 'Edit project details',
    updatingProjectLocation: 'Updating project location, please wait...',
    sent: 'Requested',
    accepted: 'Confirmed',
    declined: 'Declined',
    all: 'All',
    requested: 'Requested',
    rejected: 'Rejected',
    outgoing: 'Outgoing',
    offers: 'Offers',
    documentations: 'Documentation',
    documents: 'Documents',
    projectDeliveries: 'Follow-up',
    landfillDeliveries: 'Follow-up',
    deviations: 'Deviations',
    projectInfo: 'Project info',
    priceCalculator: 'Price calculator',
    dashboard: 'Dashboard',
    admin: 'Admin',
    settings: 'Settings',
    signout: 'Sign out',
    document: 'Document',
    documentType: 'Document type',
    depositionContract: 'Deposition contract',
    mewabDocument: 'Other documents',
    signed: 'Signed',
    signAction: 'Sign offer',
    accept: 'Accept/Buy',
    decline: 'Decline',
    documentsSigned: 'Documents signed',
    noMassSelected: 'No mass selected!',
    massType: 'Mass type',
    viewOffers: 'View offers',
    toProjectPage: 'To project page',
    yourOfferHasBeen: 'Your request has been sent!',
    offerModalNote: 'We will contact you within 24hours.\nIn the meantime, you can visit the project page.',
    phoneNumber: 'Phone number',
    phoneNumberIsRequired: 'Phone number is required!',
    requesting: 'Requesting!',
    contaminationLevels: 'Contamination levels',
    contaminationLevelsFieldHint: 'What is the level of contamination',
    contaminationLevelsForDumpTypeUnavailable: 'Contamination levels for selected dump type can not be specified',
    inspect: 'Inspect',
    uploaded: 'Uploaded',
    itLooksLikeThisProjectIsComplete: 'It looks like this project is completed!',
    projectNoMass: "The project doesn't contain any masses yet!",
    vehicle: 'Vehicle',
    numberShort: 'No.',
    supportInfo: 'Please call 0771-67 10 10 if you need any help',
    loginScreen: {
        welcomeTo: 'Welcome to',
        signInToContinue: 'Sign in to continue',
        notAMember: 'Not a member?',
        join: 'Join',
        today: 'today!',
    },
    priceCalculatorPage: {
        orderNumber: 'Order number',
        declarationNumber: 'Declaration number.',
        massType: 'Mass type',
        classification: 'Classification',
        description: 'Description',
        status: 'Status',
        landfillPricePerTon: 'Price reception (SEK/Ton)',
        weight: 'Weight (Ton)',
        vehicle: 'Transports',
        analysis: 'Analysis',
        add: 'Add',
        edit: 'Edit',
        notes: 'Notes',
        openFiles: 'Open files',
        option: 'Option',
        noNotes: 'There are no notes here yet',
        outboundMode: 'Get rid off',
        inboundMode: 'Receive',
        next: 'Next',
    },
    massDetails: {
        transportedTo: 'Transported to',
        truckTransported: 'Deliveries',
        declarationNumber: 'Declaration number',
    },
    projectStatuses: {
        NEW: 'New',
        IN_PROGRESS: 'Ongoing',
        DONE: 'Completed',
        DELETED: 'Deleted',
    },
    user: {
        firstName: 'First name',
        surName: 'Last name',
    },
    topBar: {
        project: 'Project',
        landfill: 'Site',
    },
    featureNotReady: 'This feature is not ready yet, but soon will be ready for use.',
    receipts: 'Receipts',
    ProjectAddEditMass: {
        title: 'Request a proposal for new masses',
        subtitle:
            'We will match you with suitable sites and get back to you with a proposal as soon as we have a suggestion, our handling time is 1-2 business days.',
        description: 'Description',
        selectPreferOption: 'Choose what is most important for your project',
        massDescription: 'Mass description',
        materialType: 'Material type',
        calendarInfoProjectEndDate: 'Project end date: $1',
        withInvasiveSpecies: 'Contains invasive species',
        invasiveSpeciesDescription: 'Which invasive species?',
        invasiveSpeciesPlaceholder: 'Eg. flower lupine, parkslide, gooseberry ',
        withTOC: 'TOC is parsed',
        withTOCHint: 'Total organic carbon',
        TOCValue: 'TOC (content in %)',
        dates: 'Transport dates',
        datesHint: 'During which time period should the mass be transported away?',
        amountPlaceholder: 'Enter quantity',
        over50cmFieldHint:
            'Does it contain any large pieces that are larger than 50cm in any way. For example big rocks or chunks of concrete',
        stackabilityFieldHint: 'Is it stackable in a pile in 1:1, if it is wet that is not possible',
        newComment: 'Extra information (add admin comment)',
        lastComment: 'Last comment',
        changeProjectDateToMatchMassDate:
            'The mass end date is after the project end date, do you want to change the project end date to $1?',
        requestQuote: 'Request quote now',
        requestQuoteHint: 'Uncheck this if you want to request the quote later',
        landfillName: 'Site name',
        landfillAddress: 'Site address',
        matchWithPinpointer: 'Match with Pinpointer',
        matchWithPinpointerHint: 'Match with Pinpointer means that Pinpointer suggests suitable deposit',
    },
    ProjectPriceCalculator: {
        description: 'Description',
        downloadFile: 'Download file',
    },
    dumpType: {
        __entityName: 'Dump type',
        tonnsPerCubicM: 'Tonnes in m3',
        description: 'Description',
        name: 'Name',
        usedInDeclaration: 'Use in Declaration',
    },
    fileExists: 'File with same name already exists!',
    declarationOfWasteComponent: {
        viewEdit: 'View/Edit',
        view: 'View',
    },
    Deviation: {
        ticketNumber: 'Ticket number',
        issue: 'Issue',
        issueFieldHint: 'What is the problem',
        description: 'Description',
        descriptionFieldHint: 'Describe the issue more in detail',
        suggestedSolution: 'Suggested solution',
        suggestedSolutionFieldHint: 'Do you have a suggested solution now',
        commentText: 'Comment text',
        author: 'Author',
        status: 'Status',
        dateSent: 'Date sent',
    },
    DeviationFilter: {
        DRAFT: 'Draft',
        ACTIVE: 'Active',
        RESOLVED: 'Resolved',
    },
    deviationStatus: {
        [DeviationStatus.DRAFT]: 'Draft',
        [DeviationStatus.REQUESTED]: 'Requested',
        [DeviationStatus.ANSWERED]: 'Answered',
        [DeviationStatus.CLOSED]: 'Closed',
    },
    DeviationForm: {
        newDeviation: 'New deviation',
        deviation: 'Deviation',
        conversationHistory: 'Conversation history',
        newComment: 'New comment',
        issueIsRequired: 'Issue is required',
        descriptionIsRequired: 'Description is required',
        commentIsRequired: 'Comment is required',
        pleaseSendComment: 'Please write a comment first',
        reopen: 'Re-open',
        deviationUpdated: 'Deviation updated successfully',
        deviationCreated: 'New deviation created',
        deviationClosed: 'Deviation resolved',
        confirmDelete: 'Are you sure you want to delete this file from the server?',
        toUpload: 'Upload',
        uploaded: 'Uploaded to server',
    },
    ProgressBar: {
        reservedForPinpointer: 'Available capacity',
        current: 'Delivered',
        booked: 'Incoming',
        total: 'Total',
    },
    MultiSelect: {
        startTyping: 'Start typing',
        selected: 'selected',
    },
    Stepper: {
        stepNumber: 'Step $1 of $2',
    },
    LandfillForm: {
        createLandfill: 'Create Site',
        updateLandfill: 'Edit basic information',
        enterDetails: 'Enter your Site details',
        next: 'Next',
        back: 'Back',
        siteName: 'Name',
        chooseLandfillStatus: 'Status',
        chooseLandfillTypeFieldHint:
            'What type of site are you creating, consider that it is not possible to change this',
        gateFee: 'Gate fee (SEK)',
        minMargin: 'Min margin (SEK)',
        marginPercent: 'Margin (%)',
        contactPerson: 'Contact Person',
        contactPersonFieldHint: 'A contact person can view all information but not edit or create anything',
        comment: 'Contact details',
        commentFieldHint: 'General comments, contact info and such',
        description: 'Description of special criteria',
        descriptionFieldHint: 'Are there any special instructions and/or regulations, exceptions and similar',
        openingHours: 'Opening hours',
        openingTime: 'Opening time',
        closingTime: 'Closing time',
        closed: 'Closed',
        open24hrs: 'Open 24 hrs',
        landfillUpdatedSuccessfully: 'Site updated succesfully',
        permit: 'Permit',
        permitFieldHint: 'Upload any documents like permits for the site',
        noTypeChangeAllowed: 'No type change allowed for type {0}',
        contactPeople: 'Contacts',
        capacity: 'Capacity',
    },
    Materials: {
        material: 'Material',
        incoming: 'Incoming',
        outgoing: 'Outgoing',
        onHold: 'On hold',
        hold: 'On hold',
        resume: 'Resume',
        price: 'Price',
        pricePlaceHolder: 'Price (SEK/ton)',
        priceBase: 'Base price (SEK/ton)',
        priceBaseFull: 'Base price',
        hasPriceOver50cm: 'May contain material larger than 50 cm',
        hasPriceOver50cmHint:
            'The mass may contain stone, concrete or blocks of other material that are larger than 50 cm',
        hasNonStackablePrice: 'May not be stackable',
        hasNonStackablePriceHint:
            'Stackable here means that it is possible to put the mass in a pile with a ratio of 1:1 without it flowing out',
        priceOver50cm: 'Price addon for >50 cm (SEK/ton)',
        priceNotStackable: 'Price addon if not stackable (SEK/ton)',
        netto: 'Net',
        brutto: 'Gross',
        add: 'Add',
        maxAmount: 'Max capacity',
        currentAmount: 'Current amount',
        reservedAmount: 'Available capacity',
        maxAmountDescription: 'The maximum capacity of materials you can fill your Site with.',
        currentAmountDescription:
            'The amount of materials you currently have filled your Site with. Remember to update this when receiving 3rd party materials.',
        reservedAmountDescription: 'The amount of materials available to fill your Site with.',
        deliveredAmount: 'Delivered',
        solidFaAllowed: 'FA allowed',
        solidFaAllowedFieldHint: 'Is it allowed to put FA in this site',
        materialCategory: 'Material category',
        materialCategoryFieldHint: 'What kind of material is it',
        materialCategoryIsRequired: 'Material category is required',
        all: 'All materials',
        externalId: 'Material ID',
        allowTOC: 'TOC (%)',
        allowTOCDescription: 'Total organic carbon',
        TOCValue: 'TOC-value',
        allowInvasiveSpecies: 'Invasive species allowed',
        invasiveSpeciesNotAllowed: 'No invasive species',
        invasiveSpecies: 'Description of invasive species',
        invasiveSpeciesPlaceHolder: 'Eg. flower lupine, parkslide, gooseberry',
        description: 'Description',
        isProvider: 'Provider',
        incomingTotal: 'Total Incoming',
        outgoingTotal: 'Total Outgoing',
    },
    transportHistory: {
        title: 'Transport history',
        transportedCompany: 'Transport co.',
        declarationNumber: 'Declaration no.',
        registrationNumber: 'Registration no.',
        date: 'Date',
        weight: 'Weight (Ton)',
        weightReceipt: 'Weight receipt',
        kgCo2: 'kg CO2',
        cost: 'Price(SEK)',
        distanceKm: 'Distance (Km)',
        loadPercentage: 'Load percentage',
    },
    openInNewDesign: 'Open in new design',
    mustBeGreaterThanZero: '$1 must be greater than zero',
    isRequired: '$1 is required',
    noDataToDisplay: 'No data to display',
    Dates: {
        canNotBeBefore: "Date can't be before $1",
        canNotBeAfter: "Date can't be after $1",
    },
    LandfillMaterialCard: {
        base: 'Base',
        moreThan500: 'Over 50cm',
        supplementNotStackable: 'Not stackable',
        netto: 'Net',
        brutto: 'Gross',
    },
    LandfillSchedule: {
        title: 'Transport schedule',
    },
    ProjectDashboard: {
        serialNumber: 'Order number',
        inbound: 'Ingoing',
        outbound: 'Outgoing',
        noOperatorName: 'No excavator operator',
        titleMode: 'Total tons inbound / outbound',
        titleExcavatorOperator: 'Total tons per operator',
        titleTruck: 'Total tons per truck',
        titleFillRate: 'Fill rate',
        titleCO2Trucks: 'CO2 consumption by drivers trucks',
        titleCO2Machines: 'CO2 consumption by machines',
        titleCO2Total: 'Total CO2 consumption',
        fillRateLowest: 'Lowest',
        fillRateAverage: 'Average',
        fillRateHighest: 'Highest',
        fillRateTruckHeader: 'Truck',
        fillRateValueHeader: 'Average, %',
        licensePlate: 'License plate',
        operatorName: 'Operator name',
        regNumber: 'Reg. number',
        trucks: 'Trucks',
        machines: 'Machines',
        CO2Table: {
            operators: 'Operators',
            drivers: 'Drivers',
            regNumber: 'Reg. number',
            vehicleType: 'Vehicle type',
            engine: 'Engine',
            fuel: 'Fuel',
            distance: 'Distance, km',
            hours: 'Hours, h',
            CO2: 'CO2, kg',
            total: 'Total',
        },
    },
    Admin: {
        exit: 'Exit',
        dumpLoads: 'Orders',
        landfills: 'Sites',
        projects: 'Projects',
        orders: 'Volumes',
        deliveryLines: 'Deliveries',
        customers: 'Customers',
        companies: 'Companies',
        drivers: 'Drivers',
        dumpTypes: 'Dump types',
        beastMaterials: 'BEAst materials',
        landfillStatuses: 'Site statuses',
        substances: 'Substances',
        settings: 'Settings',
        toxicLimits: 'Toxic Limits',
        account: 'Account',
        allDumpLoads: 'All dumpLoads',
        allLandfills: 'All sites',
        users: 'Users',
        activity: 'User activity',
        projectsPage: {
            activeProjects: 'Active projects',
            allProjects: 'All projects',
        },
        landfillsPage: {
            activeLandfills: 'Active sites',
            allLandfills: 'All sites',
            subareas: 'Subareas',
        },
        permissions: 'Permissions',
        locks: 'Locks',
        despatchAdviceResponses: 'Despatch advice responses',
        vehicleTypes: 'Vehicle types',
        timeSpent: 'Time spent',
    },
    Table: {
        rowsPerPage: 'Rows per page',
        prevPage: 'Prev page',
        nextPage: 'Next page',
        of: 'out of',
    },
    DumpLoadsTable: {
        orderNumber: 'Order no.',
        orderStatus: 'Order status',
        projectName: 'Project name',
        dumpType: 'Dumptype',
        customer: 'Customer',
        comments: 'Comments',
        toxicAnalysis: 'Analysis uploaded',
        addToxicAnalysis: 'Add',
        confirmDeleteToxicAnalysis: 'Are you sure you want to delete this file from the server?',
        analysisApproved: 'Analysis approved',
        analysisApprovalState: {
            APPROVED_INTERNALLY: 'Internally',
            APPROVED_EXTERNALLY: 'Externally',
            NOT_APPROVED: 'Not approved',
        },
        receiver: 'Site',
        deliveryPlan: 'Delivery plan',
        order: 'Order connected',
        landfillPricePerTon: 'Price to customer (SEK / Ton)',
        amount: 'Planned volume (Ton)',
        deliveredVolume: 'Delivered volume (Ton)',
        salesResponsible: 'Sales responsible',
        selectDestination: 'Destination',
        noDestinationMessage: 'There is no landfill for this dumpLoad',
        siteConfirmed: 'Site confirmed',
        contractSigned: 'Contract signed',
        creditCheck: 'Credit check',
        editWasteDeclaration: 'Edit',
        FANumber: 'External Reference',
        contaminationLevel: 'Contamination level',
        joinCode: 'Project code',
        mode: 'Mode',
        inbound: 'Inbound',
        outbound: 'Outbound',
        outboundNoMatching: 'No matching',
    },
    DumpLoadCard: {
        dumpLoadInfo: 'Order info',
        transportInfo: 'Transportation',
        orderNumber: 'Order number',
        coordination: 'Coordination',
        orderStatus: 'Order status',
        projectName: 'Project name',
        dumpType: 'Dumptype name',
        customer: 'Customer',
        comment: 'Mass description',
        comments: 'Comments',
        createdAt: 'Created',
        toxicAnalysis: 'Toxic analysis',
        analysisApproved: 'Internally reviewed',
        receiver: 'Receiver',
        quoteSent: 'Quote sent',
        landfillCost: 'Cost/ton (internal)',
        updatedAt: 'Last updated',
        contractSigned: 'Contract signed',
        deliveryPlan: 'Delivery plan',
        order: 'Connected order',
        landfillPrice: 'Price to customer',
        endDate: 'Planned end date',
        amount: 'Planned volume',
        salesResponsible: 'Sales responsible',
        toxicAnalysisApprovedExternally: 'Externally reviewed',
        siteConfirmed: 'Site confirmed',
        yourOrderNumber: 'Your order number',
        creditCheck: 'Credit check',
        attachButton: 'Attach',
        clearButton: 'Clear',
        landfillOrdered: 'Site ordered',
        FANumber: 'External Reference',
        statusWillChange: 'Order status will be changed to $1 [$2]',
        beastMaterial: 'BEAst material',
        mode: 'Mode',
        outbound: 'Outbound',
        inbound: 'Inbound',
        matchWithPinpointer: 'Match with Pinpointer',
        suggestedLandfillName: 'Suggested site name',
        suggestedLandfillAddress: 'Suggested site address',
    },
    CommentsView: {
        comments: 'Comments',
        newCommentPlaceholder: 'Text input goes here',
    },
    SortString: {
        ascending: 'A - Z',
        descending: 'Z - A',
    },
    SortLowHigh: {
        ascending: 'Lowest - Highest',
        descending: 'Highest - Lowest',
    },
    SortNumber: {
        ascending: '1 - 1000',
        descending: '1000 - 1',
    },
    SortDate: {
        ascending: 'Old on top',
        descending: 'New on top',
    },
    OptionFilter: {
        selectAll: 'Select all',
        clearAll: 'Clear all',
        selected: 'Selected',
    },
    StringFilter: {
        includeEmptyPostfix: '(include empty)',
        useIncludeEmpty: 'Include empty',
    },
    ProjectsTable: {
        name: 'Project name',
        status: 'Status',
        location: 'Location',
        customer: 'Customer',
        salesResponsible: 'Sales responsible',
        comment: 'Comment',
        edit: 'Edit',
        deleteInfo: 'Delete project',
        deleteConfirmation: 'Are you sure you want to delete this project?',
        restoreInfo: 'Restore project',
        restoreConfirmation: 'Are you sure you want to restore this project?',
    },
    ProjectCard: {
        location: 'Project location',
    },
    LandfillsTable: {
        landfillName: 'Site name',
        location: 'Location',
        openedAt: 'Open at',
        gateFee: 'Gate/scale fee',
        minMargin: 'Minimum margin (kr)',
        deleteInfo: 'Delete site',
        deleteConfirmation: 'Are you sure you want to delete this site?',
        marginPercent: 'Margin (%)',
        user: 'User',
        comments: 'Comments',
        landfillDeletedMessage: 'Site deleted',
        capacity: 'Capacity',
    },
    SubareasTable: {
        landfillName: 'Site',
        landfillLocation: 'Location',
        landfillReceivers: 'Contact person',
        landfillStatus: 'Status',
        landfillComment: 'Site Comments',
        dumpType: 'DumpType',
        unitOfMeasure: 'Unit',
        comment: 'Subarea description',
        nonStackable: 'Non-Stackable',
        over50: 'Over 50',
        maxAmount: 'Capacity (Ton)',
        currentAmount: 'Used capacity (Ton)',
        reservedAmount: 'Available capacity (Ton)',
        landfillGateFee: 'Scale fee (SEK)',
        landfillMinMargin: 'Min margin (SEK)',
        landfillMarginPercent: 'Margin (%)',
        priceOver50cm: 'Over 50 extra',
        priceNotStackable: 'Non stackable extra',
        grossPriceBase: 'Gross',
        priceBase: 'Base price',
        priceUpdatedAt: 'Price updated',
        externalId: 'Material ID',
        notOnHold: 'Open for Reception',
        contaminationLevel: 'Contamination level',
        isProvider: 'Provider',
        receiversMany: '{} and {} more',
        confirmDelete: 'Are you sure you want to delete this material?',
    },
    OrdersTable: {
        projectName: 'Project name',
        dumpType: 'Dumptype',
        route: 'Volumes',
        orderNumber: 'Order #',
        documentNumber: 'Document #',
        customer: 'Customer',
        status: 'Status',
        deliveryName: 'Delivery name',
        deliveryAddress: 'Delivery address',
        deliveryDate: 'Delivery date',
        endDate: 'End date',
        deliveryLineCount: 'Delivery lines',
        deliveredVolume: 'Delivered volume (Ton)',
        salesResponsible: 'Sales responsible',
        fortnoxStatus: 'Fortnox status',
        massCost: 'Mass cost (SEK)',
        massPrice: 'Mass price (SEK)',
        receiver: 'Receiver',
        noReceiverMessage: 'There is no receiver for this value',
        FANumber: 'FA no.',
    },
    OrderCard: {
        header: 'Edit order',
        status: 'Order status',
        orderNumber: 'Order number',
        documentNumber: 'Document number',
        customer: 'Customer',
        startDate: 'Planned start date',
        deliveredVolume: 'Delivered volume',
        salesResponsible: 'Sales responsible',
        deliveryAddress: 'Delivery address',
        city: 'City',
        postalCode: 'Zip code',
        country: 'Country',
        addressLine1: 'Address line 1',
        addressLine2: 'Address line 2',
        projectNumber: 'Project number',
        projectName: 'Project name',
        receiver: 'Receiver',
        dumpType: 'Dumptype',
        lastComment: 'Last comment',
        landfillCost: 'Cost/ton (internal)',
        landfillCostExt: 'Cost/ton (external)',
        plannedVolume: 'Planned volume',
        FANumber: 'FA no.',
    },
    OrderDeliveryLinesEditDialog: {
        title: 'Order $1',
    },
    OrderSyncDialog: {
        title: 'Synchronization',
        subtitle: 'Synchronize all orders',
        orderSelectLabel: 'Order',
    },
    CompanySyncDialog: {
        title: 'Synchronization',
        subtitle: 'Synchronize all companies',
        companySelectLabel: 'Company',
    },
    FortnoxControls: {
        lastSynchronization: 'Last synchronization',
        never: 'never',
        status: 'Status',
        fetchStatusError: 'Fetch status error',
        [SyncStatus.SUCCESS]: 'Success',
        [SyncStatus.ERROR]: 'Error',
        [SyncStatus.UNKNOWN]: 'Unknown',
        completed: '{0}% completed',
        stop: 'Stop',
    },
    statusOfOrder: {
        [OrderStatus.OPENED]: 'Opened',
        [OrderStatus.CLOSED]: 'Closed',
    },
    DeliveryLinesTable: {
        route: 'Deliveries',
        orderNumber: 'Order no.',
        number: 'Number',
        weight: 'Weight (Ton)',
        totalWeightOnPage: 'Total weight on page',
        date: 'Date',
        transportCompany: 'Transport Co.',
        truckRegistrationNumber: 'Licensplate no.',
        truckCapacity: 'Truck capacity (Ton)',
        loadPercentage: 'Load percentage',
        customerInvoice: 'Customer invoice no.',
        transportInvoice: 'Receipt invoice no.',
        landfillInvoice: 'Site invoice no.',
        receipt: 'Receipt',
        history: 'History',
        deletedAt: 'Deleted at',
        deleteInfo: 'Delete delivery',
        deleteConfirmation: 'Are you sure you want to delete this delivery?',
        restoreInfo: 'Restore delivery',
        restoreConfirmation: 'Are you sure you want to restore this delivery?',
        viewReceipt: 'View',
        downloadReceipt: 'Download',
        exportPdf: 'Export to PDF',
        replaceReceipt: 'Replace',
        replaceComplete: 'Receipt replaced',
        noPreviewForPdf: 'Preview for PDF unavailable',
        addButton: 'New delivery',
        title: 'Delivery lines',
        verified: 'Verified',
        comment: 'Comment',
        completed: 'Completed',
        inProgress: 'In progress',
        deleted: 'Deleted',
        saveBeforeOpenEditDialog: 'Please save data to be able to edit delivery line',
        userNotes: 'User notes',
        senderNotes: 'Sender notes',
        receiverNotes: 'Receiver notes',
        status: 'Status',
        driver: 'Driver',
        excavatorOperator: 'Excavator op.',
        beastStatus: 'BEAst status',
    },
    DeliveryLineCard: {
        orderNumber: 'Order number',
        weight: 'Weight',
        date: 'Date',
        transportCompany: 'Transport company',
        truckRegistrationNumber: 'Licensplate no.',
        truckCapacity: 'Truck capacity',
        customerInvoice: 'Customer invoice no.',
        transportInvoice: 'Receipt invoice no.',
        landfillInvoice: 'Site invoice no.',
        receipt: 'Receipt',
        verified: 'Verified',
        comment: 'Comment',
        createHeader: 'New delivery',
        updateHeader: 'Update delivery',
        completeHeader: 'Complete delivery',
        vehicleType: 'Vehicle type',
        fuel: 'Fuel',
        engine: 'Engine',
    },
    DeliveryLineHistoryCard: {
        caption: 'Delivery line',
        user: 'User',
        driver: 'Driver',
    },
    DeliveryLineStatuses: {
        [DeliveryLineStatus.EXCAVATED]: 'Excavated',
        [DeliveryLineStatus.DELIVERING]: 'Delivering',
        [DeliveryLineStatus.DELIVERED]: 'Delivered',
        [DeliveryLineStatus.VERIFIED]: 'Verified',
    },
    DespatchAdvicesTable: {
        title: 'Despatch Advices',
        category: 'Category',
        type: 'Type',
        transactionId: 'Transaction Id',
        status: 'Status',
        send: 'Send',
        sentAt: 'Sent at',
        noDataFound: 'No data found',
        sendDisabledReason: 'Despatch Advice already sent',
        responseStatus: 'Response status',
        responseInfo: 'Info',
    },
    LandfillStatuses: {
        createLandfillStatus: 'Create site status',
        editLandfillStatus: 'Edit site status',
        chooseColor: 'Choose the color',
        confirmDeleteStatus: 'Are you sure you want to delete this site type?',
    },
    Substances: {
        createSubstance: 'Create substance',
        editSubstance: 'Edit substance',
        createSubstanceChild: 'Create sub substance',
        editSubstanceChild: 'Edit sub substance',
        substanceName: 'Substance name',
        confirmDeleteSubstance: 'Are you sure you want to delete this substance?',
        addSubstance: 'Add Substance',
    },
    DumpTypeTable: {
        title: 'Dump type name',
    },
    DumpTypeCard: {
        id: 'Id',
        createTitle: 'Create dump type',
        editTitle: 'Edit dump type',
        deleteTitle: 'Delete dump type',
        deleteConfirmation: 'Are you sure you want to delete dump type',
        tonnsPerCubicM: 'Tonnes in m3',
        description: 'Description',
        name: 'Dump type name',
        usedInDeclaration: 'Use in Declaration',
        contaminationLevelAvailable: 'Calculate contamination level',
    },
    UsersTable: {
        route: 'Users',
        name: 'First name',
        lastName: 'Last name',
        phone: 'Phone',
        email: 'E-mail',
        role: 'Role',
        status: 'Status',
        created: 'Created',
        deletedAt: 'Deleted',
        orgName: 'Organization Name',
        orgNr: 'Organization Number',
        customer: 'Customer',
        selectCustomer: 'Select customer',
        comments: 'Comments',
        userApproval: 'User Approval',
        approveConfirmation: 'Are you sure you want to approve user?',
        userBlocking: 'User blocking',
        blockingConfirmation: 'Are you sure you want to block user?',
        userEnablement: 'User enablement',
        enablementConfirmation: 'Are you sure you want to enable user?',
        userDeletion: 'Delete user',
        deletionConfirmation: 'Are you sure you want to delete user:\n$1?',
        addUser: 'New user',
        emailIsRequired: 'Email is required',
        invalidEmail: 'Email is invalid',
        roleIsRequired: 'Role is required',
        customerIsRequired: 'Customer is required',
        nameIsRequired: 'Name is required',
        surnameIsRequired: 'Surname is required',
        lastActivityDate: 'Last activity',
        active: 'Active',
        deleted: 'Deleted',
        restoreInfo: 'Restore user',
        restoreConfirmation: 'Are you sure you want to restore this user?',
        saveSuccess: 'User successfully saved',
    },
    BlacklistPage: {
        title: 'Blacklisted email addresses',
        item: 'Blacklisted email address',
        email: 'E-mail',
        blacklist: 'Blacklist',
        addToBlacklist: 'Add to blacklist',
        deleteConfirmation: 'Are you sure you want to remove this email from the blacklist?',
        noDataFound: 'No data found',
    },
    DriversTable: {
        route: 'Drivers',
        name: 'Name',
        email: 'E-mail',
        phone: 'Phone',
        status: 'Status',
        registeredAt: 'Registration date',
        other: 'All possible',
        lastComment: 'Last comment',
        lastActivityDate: 'Last activity',
        deleteInfo: 'Delete driver',
        deleteConfirmation: 'Are you sure you want to delete this driver?',
    },
    UserCard: {
        projects: {
            project: 'Project',
            role: 'Role',
        },
        landfills: {
            landfill: 'Site',
            role: 'Role',
        },
    },
    UserActivityTable: {
        name: 'Name',
        email: 'E-mail',
        role: 'Role',
        lastSession: 'Last session',
        active: 'Active',
        minAgo: 'min. ago',
    },
    ProjectForm: {
        projectInformation: 'Project Information',
        createProject: 'Create project',
        projectLabel: 'Project Name',
        projectName: 'Project name...',
        startAndEndDates: 'Start and end dates',
        startAndEndDatesFieldHint: 'The start and end date for the project. You may change end date later',
        invoiceReference: 'Invoice reference',
        invoiceReferenceFieldHint: 'External reference number',
        comment: 'Comment',
        projectMembers: 'Project members',
        inviteViaEmail: 'or invite via email',
        createNewExcavatorOperator: 'or create new one',
        invite: 'Invite',
        inviteNewMember: 'Invite new project member:',
        invalidEmail: 'Invalid email',
        inviteConfirmation: 'Note: {0}. Do you want to send an invitation?',
        inviteUserType: {
            unknown: 'person is not a member of PinPointer',
            sameCompany: 'this person is a member of your company',
            anotherCompany: 'this person is not a member of your company',
        },
        saveBeforeInvite: 'Save project before invite users',
        next: 'Next',
        selectUserModeTitle: 'Select user type',
        addUser: 'Add user',
        drivers: 'Drivers',
        contactPeople: 'Contacts',
        excavatorOperators: 'Excavator operators',
        joinCode: 'Project code',
        userInvited: 'An invitation email has been sent to the user',
        confirmRemoveUser: 'Are you sure you want to exclude $1 from project?',
        externalId: 'External id',
    },
    PermissionsPage: {
        project: 'Project',
        dumpLoad: 'DumpLoad',
        landfill: 'Landfill',
        user: 'User',
        deviation: 'Deviation',
        projectDeviations: 'Project deviations',
        landfillDeviations: 'Landfill deviations',
        role: 'Role',
        action: 'Action',
        subject: 'Subject',
        projectSubject: 'Project subject',
        dumpLoadSubject: 'DumpLoad subject',
    },
    globalSearch: {
        searchIn: 'Search in $1 ...',
        orders: 'Orders',
        drivers: 'Drivers',
        companies: 'Companies',
    },
    noPreviewForPdf: 'Preview for PDF unavailable',
    copyrights: 'Copyrights protected',
    emptyList: 'Empty List',
    noDataForFilter: 'No data found for current filter',
    sortBy: 'Sort by',
    from: 'From',
    to: 'To',
    admin20: 'Admin 2.0',
    customerView: 'Customer view',
    setBooleanConfirm: 'Set "$1"?',
    unsetBooleanConfirm: 'Unset "$1"?',
    textCopied: 'Text copied to clipboard',
    noContentToCopy: 'No content to copy',
    noLinkToFollow: 'There is no link to follow',
    selected: 'Selected',
    select: 'Select',
    reset: 'Reset',
    showMore: 'Show more',
    localizationMode: 'Localization mode',
    hideSupportChat: 'Hide support chat',
    showSupportChat: 'Show support chat',
    downloadProgress: 'Downloaded $1 of $2',

    LandfillReceiversSearch: {
        addContactPerson: 'Add contact person...',
        contactPersons: 'Contact persons',
    },
    unitsOfMeasure: {
        [UnitOfMeasure.TONNS]: 'tonnes',
        [UnitOfMeasure.CUBIC_METERS]: 'm3',
    },
    externalApi: {
        title: 'Integration',
    },

    projectLandfillReceiptsXlsx: {
        orderNumber: 'Order number',
        comment: 'Notes',
        customerInvoiceNumber: 'Customer invoice no.',
        plannedStartDateFmt: 'Date',
        dumpType: 'Mass',
        solidTestResult: 'Level',
        description: 'Description',
        projectName: 'Project',
        landfillName: 'Receiver',
        excavatorOperatorName: 'Excavator op.',
        truckRegistrationNumber: 'Registration no.',
        excavatedDateFmt: 'Excavated',
        startDateFmt: 'Loaded',
        endDateFmt: 'Delivered',
        truckCapacity: 'Truck capacity (Ton)',
        weight: 'Weight (Ton)',
        loadPercentage: 'Load percentage',
        distance: 'Distance one way (km)',
        receiverCheck: 'Checked',
        pinpointerCheck: 'Verified by Pinpointer',
        invoiceNumber: 'Invoice number',
        driverComment: 'Driver comment',
    },

    lock: {
        entityLocked: 'Entity "$1" with id "$2" was locked by "$3" $4 ago',
        removeAllLocks: 'Remove all locks',
        allLockRemoveConfirm: 'Are you sure you want to remove all locks?',
    },

    mandatoryFields: {
        startDate: 'Start date',
        endDate: 'End date',
        location: 'Location',
        dumpType: 'Dump type',
        amount: 'Amount',
        unitOfMeasure: 'Unit of measure',
        date: 'Start date',
        priceData: 'Prices',
        creditCheck: 'Credit check',
        toxicAnalysisApprovedExternally: 'Externally reviewed',
        contractSigned: 'Contract signed',
        siteConfirmed: 'Site confirmed',
        landfillOrdered: 'Site ordered',
        beastMaterialId: 'BEAst material Id',
    },
    ToxicLimitsPage: {
        solidLimits: 'Special limit',
        leachingLimits: 'Leak test',
        faSumRules: 'FA sum rules',
        confirmDeleteSumRule: 'Are you sure you want to delete this sum rule?',
        ruleName: 'Rule name',
        faSumLevel: 'FA sum level',
        threshold: 'Threshold level',
        substanceName: 'Substance name',
        noSubstances: 'No substances yet...',
        addNewRule: 'Add new sum rule',
        addSubstance: 'Add substance',
    },
    LandfillPage: {
        addSubarea: 'Add subarea',
        editSubarea: 'Edit subarea',
        documents: 'Documents',
        upload: 'Upload',
        batches: {
            create: 'Create batch',
            archive: 'Archive',
            restore: 'Restore',
            archiveTitle: 'Archive batch?',
            archiveMessage: 'Are you sure you want to archive batch?',
            restoreTitle: 'Restore batch?',
            restoreMessage: 'Are you sure you want to restore batch?',
        },
        batchCard: {
            createBatch: 'Create batch',
            editBatch: 'Edit batch',
            name: 'Name',
            dumpType: 'Mass',
            amount: 'Amount',
            comment: 'Comment',
            amountPlaceholder: 'Enter amount',
        },
        batchLog: {
            createTransfer: 'Create transfer',
            addAnalysis: 'Add analysis',
            noDataFound: 'No data found',
            amountOn: 'Amount on',
        },
        batchLogCard: {
            [TransferType.INCOMING]: 'Incoming from the external project',
            [TransferType.OUTGOING]: 'Outgoing to the external project',
            [TransferType.INTERNAL]: 'Internal transfer',
            LINE_INCOMING: 'Incoming from Pinpoiter project',
            LINE_OUTGOING: 'Outgoing to Pinpoiter project',
            analysisTitle: 'Analysis added',
            fromProject: 'From project:',
            fromBatch: 'From batch:',
            toProject: 'To project:',
            toBatch: 'To batch:',
            batchAmount: 'Batch amount:',
            contaminationLevel: 'Contamination level:',
            detach: 'Detach',
            detachTitle: 'Detach delivery from the batch?',
            detachMessage: 'Are you sure you want to detach delivery?',
            applyChangesErrorTitle: 'Can not apply changes to then batch log',
            applyChangesErrorMessage: 'Current change causes negative amount at date: ',
        },
        transferCard: {
            editTransfer: 'Edit transfer',
            createTransfer: 'Create transfer',
            [TransferType.INCOMING]: 'Incoming',
            [TransferType.OUTGOING]: 'Outgoing',
            [TransferType.INTERNAL]: 'Internal',
            date: 'Date',
            weight: 'Weight',
            comment: 'Comment',
            fromBatch: 'From batch',
            toBatch: 'To batch',
            projectName: 'Project name',
            projectAddress: 'Project address',
            licensePlate: 'Reg. number',
            deleteTitle: 'Delete transfer?',
            deleteMessage: 'Are you sure you want to delete transfer?',
        },
        analysisCard: {
            editAnalysis: 'Edit analysis',
            createAnalysis: 'Create analysis',
            date: 'Date',
            contaminationLevel: 'Contamination level',
            report: 'Report',
            deleteTitle: 'Delete analysis?',
            deleteMessage: 'Are you sure you want to delete analysis?',
        },
        batchReport: {
            title: 'Traceability report for project',
            order: 'Order',
            material: 'Material',
            weight: 'Weight',
            date: 'Date',
            regNumber: 'Reg. number',
            sources: 'Sources',
            sourceUnknown: 'Source unknown',
            contLevel: 'Cont. level',
            project: 'Project',
            selectProject: 'Select project',
            noDeliveryLines: 'There is no outgoing delivery lines yet',
        },
    },
    ReleaseNotes: {
        releaseNotes: 'Release notes',
        createReleaseNote: 'Create release note',
        editReleaseNote: 'Edit release note',
        releaseDate: 'Release date',
        markdownText: 'Markdown text',
        preview: 'Preview',
        deleteConfirmation: 'Are you sure you want to delete release note',
        adminNotes: 'Admins notes',
        customerNotes: 'Customers notes',
        driverNotes: 'Drivers notes',
        whatsNew: "What's new?",
    },
    ChatGpt: {
        tabTitle: 'AI',
        inputPlaceholder: 'Ask something...',
        disclaimer:
            'Please note that I am an AI chatbot developed by Pinpointer. I provide general advice and tips in construction and environmental engineering based on the limited information I have been trained on. My advice should not be regarded as or substitute for legal or technical advice and should always be critically reviewed and supplemented with professional advice where necessary.',
    },
    BeastMaterialsPage: {
        confirmDelete: 'Are you sure you want to delete this BEAst material?',
        create: 'Create BEAst material',
        edit: 'Edit BEAst material',
        name: 'Name',
        beastId: 'BEAst Id',
    },
    Excavator: {
        menu: {
            orders: 'Orders',
            deliveries: 'Deliveries',
            users: 'Users',
        },
        orders: {
            title: 'Available orders',
            receiver: 'Receiver',
            emptyList: 'No available orders for excavation',
            direction: 'Direction',
            inbound: 'Inbound',
        },
        joinProject: {
            joinToProject: 'Project code',
            enterCode: 'Enter 6-digit code to join project',
            successJoin: 'You successfully joined to Project',
            failedJoin: 'You cannot join to any project with this code. Contact support for help',
        },
        createDelivery: {
            title: 'Create new excavation',
            truckNumber: 'Truck registration number',
            truckNumberRequired: 'Truck registration number is required',
            successCreate: 'New delivery started',
            noDriversInProject: 'There are no truck drivers assigned to the project',
        },
        deliveries: {
            serialNumber: 'Declaration nr.',
            date: 'Date',
            dumpType: 'Mass',
            comment: 'Description',
            landfill: 'Receiver',
            truckNumber: 'Registration no.',
            truckCapacity: 'Truck capacity (Ton)',
            weight: 'Weight (Ton)',
            loadPercent: 'Load percentage',
            distance: 'Distance',
        },
        deliveryUpdate: {
            deliveryAccepted: 'Delivery line for order $1 has been approved by truck $2',
            deliveryDeclined: 'Delivery line for order $1 has been declined by truck $2',
        },
        users: {
            title: 'Users of projects',
            projectName: 'Project name',
            userName: 'User name',
            truckNumber: 'Truck number',
            phoneNumber: 'Phone number',
            email: 'E-mail',
            status: 'Status',
            role: 'Role',
        },
    },

    LandfillDeliveryEvents: {
        title: 'Undistributed delivery events (magic pole)',
        date: 'Date',
        licensePlate: 'License plate',
        photo: 'Photo',
        selectDeliveryLine: 'Select delivery to attach',
        deleteConfirm: 'Are you sure you want to delete this event?',
        attachToLine: 'Attach to line',
        successAttach: 'Delivery event attached to delivery line',
        successDelete: 'Delivery event deleted',
    },

    DriverState: {
        delivering: 'Delivering',
        free: 'Free',
    },
    DespatchAdviceResponses: {
        date: 'Date',
        responseType: 'Response type',
        declineReason: 'Decline reason',
        loadNewResponses: 'Load new responses',
        noResponsesReceived: 'No new responses received',
        numberResponsesReceived: '$1 new responses received!',
    },
    DespatchAdviceSettingsDialog: {
        title: 'Despatch Advice settings',
        consignee: 'Consignee',
        despatchParty: 'Despatch party',
        buyer: 'Buyer',
        seller: 'Seller',
        shouldBeSpecified: 'Should be specified',
        connected: 'Connected to PEPPOL',
        notConnected: 'Not connected to PEPPOL',
        despatchPartyNote: 'Pinpointer company will be used if not specified',
        settings: 'PEPPOL settings',
        pricePerTon: 'Price/ton',
    },
    DespatchAdviceCategory: {
        [DespatchAdviceCategory.EXCAVATING]: 'Excavating',
        [DespatchAdviceCategory.TRANSPORTING]: 'Transporting',
        [DespatchAdviceCategory.DUMPING]: 'Dumping',
    },

    ProjectWhatsapp: {
        enableWhatsapp: 'Enable whatsapp',
        openGroup: 'Open group',
        enableWhatsappForProject: 'Enable whatsapp for project',
        enableWhatsappConfirm: 'All Project members will receive an email with the invitation link. Confirm?',
        groupDescription: 'Group for communication on issues related to the Pinpointer project "$1". Project link: $2',
    },

    VehicleType: {
        id: 'Id',
        createTitle: 'Create vehicle type',
        editTitle: 'Edit vehicle type',
        deleteTitle: 'Delete vehicle type',
        deleteConfirmation: 'Are you sure you want to delete vehicle type',
        kind: 'Kind',
        name: 'Vehicle type name',
        externalId: 'External ID',
        fuelConsumptions: 'Fuel consumptions',
        perKm: 'per km',
        perHour: 'per hour',
    },

    TimeSpent: {
        dumpLoadNumber: 'Order',
        user: 'User',
        licensePlate: 'Registration number',
        date: 'Date',
        hoursSpent: 'Hours spent',
        comment: 'Comment',
    },

    appStore: 'iPhone (App Store)',
    googlePlay: 'Android (Google Play)',
};

export default en;
