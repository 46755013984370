import { ContaminationLevel } from '@common/enums';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import { ContaminationLevelFormatter } from '~/@components/Formatters/ContaminationLevelFormatter';
import { NullableFormatter } from '~/@components/Formatters/NullableFormatter';

export type DumpLoadLike = { dumpType: { name: string } | null; solidTestResult: ContaminationLevel | null };

type Props = { children: DumpLoadLike | null | void; fullWidth?: boolean };

export const DumpLoadTypeWithContaminationLevelFormatter = ({ children: dumpLoad, fullWidth = true }: Props) => {
    const { root, name, chip, fullWidthClass } = useStyles();

    return (
        <NullableFormatter>
            {dumpLoad?.dumpType?.name && (
                <span className={cn(root, { [fullWidthClass]: fullWidth })} title={dumpLoad.dumpType.name}>
                    <span className={name}>{dumpLoad.dumpType.name}</span>
                    {dumpLoad.solidTestResult && (
                        <span className={chip}>
                            <ContaminationLevelFormatter>{dumpLoad.solidTestResult}</ContaminationLevelFormatter>
                        </span>
                    )}
                </span>
            )}
        </NullableFormatter>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
        maxWidth: '100%',
    },
    fullWidthClass: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    name: {
        flexShrink: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    chip: {
        marginLeft: theme.spacing(),
    },
}));
