import { I18nKeys } from '@common/i18n/types';
import { Card, FormControlLabel, Radio, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import classNames from 'classnames';
import { isBoolean } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { makeStyles } from '~/@components/@theme';
import { COLORS } from '~/@components/@theme/colors';
import { DistanceFormatter, NumberFormatter, TonnsFormatter } from '~/@components/Formatters';
import { InfoIconOutlined } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { showAlertDialog } from '~/@store/common';
import { IDestination } from '~/@store/dumpLoads';
import { commonPrecisions } from '~/config/enum';
import i18n from '~/i18n';

import { DumpLoadLandfillInfo } from '../DumpLoadLandfillInfo';

type Props = {
    destination: IDestination;
    selected?: boolean;
    onDestinationSelect?: (v: IDestination) => void;
};

export const DumpLoadDestinationRow = ({ destination, selected, onDestinationSelect }: Props) => {
    const {
        spacer,
        selectedStyle,
        landfillInfoContainer,
        header,
        subHeader,
        additionalInfo,
        landfillInfoItem,
        infoLabel,
        infoValue,
        tableContainer,
        tableHeadCell,
        tableBodyCell,
        selectLabel,
    } = useStyles();

    const isSelectable = isBoolean(selected) && !!onDestinationSelect;

    const pricesInfo: Array<[I18nKeys, number]> = useMemo(
        () => [
            ['oneTripCostShort', destination.costPerTrip],
            ['internalLoadFeePerTon', destination.internalLoadFee],
            ['loadFeePerTon', destination.loadFee],
        ],
        [destination]
    );

    const handleClick = useCallback(() => {
        onDestinationSelect && onDestinationSelect(destination);
    }, [destination, onDestinationSelect]);

    const landfillDataFields: Array<[I18nKeys, string | null]> = [
        ['location', destination.landfill.location.text],
        ['LandfillForm.description', destination.landfill.description],
        ['LandfillForm.comment', destination.landfill.comment],
    ];

    const showAdditionalInfo = () =>
        showAlertDialog({
            title: destination.landfill.name,
            icon: InfoIconOutlined,
            render: () => (
                <div className={additionalInfo}>
                    {landfillDataFields.map(([label, value]) => (
                        <Text key={label}>
                            <Text variant="BodyBold" display="inline">
                                <LocalizableText code={label} />:{' '}
                            </Text>
                            {value || i18n.NA}
                        </Text>
                    ))}
                </div>
            ),
        });

    return (
        <Card>
            <div className={classNames(landfillInfoContainer, { [selectedStyle]: selected })}>
                <div className={header}>
                    <DumpLoadLandfillInfo landfill={destination.landfill} showAdditionalInfo={showAdditionalInfo} />
                    <div className={spacer} />
                    <div className={landfillInfoItem}>
                        <Text variant="SmallText" className={infoLabel}>
                            <LocalizableText code={'DumploadSummarySection.Distance'} />
                        </Text>
                        <Text variant="BodyBold" className={infoValue}>
                            <DistanceFormatter>{destination.distance}</DistanceFormatter>
                        </Text>
                    </div>
                    {isSelectable && (
                        <FormControlLabel
                            className={selectLabel}
                            control={<Radio checked={selected} onClick={handleClick} value="" />}
                            label={<Text variant="BodyBold">{selected ? i18n.selected : i18n.select}</Text>}
                        />
                    )}
                </div>
                <div className={subHeader}>
                    <Text variant="SmallText" color="textSecondary" display="inline">
                        {destination.landfill.location.text}
                    </Text>
                    <Text variant="SmallText" color="textSecondary" display="inline">
                        {destination.subarea?.externalId}
                    </Text>
                    <Text variant="SmallText" color="textSecondary" display="inline">{`${i18n.over50cm}: ${
                        destination.subarea?.over50cm ? i18n.allowed : i18n.notAllowed
                    }`}</Text>
                    {destination.subarea?.allowTOC && (
                        <Text variant="SmallText" color="textSecondary" display="inline">
                            {`${i18n.TOCValue}: ${destination.subarea?.TOCPercent}%`}
                        </Text>
                    )}
                    {destination.subarea?.allowInvasiveSpecies && (
                        <Text variant="SmallText" color="textSecondary" display="inline">
                            <LocalizableText code={'withInvasiveSpecies'} />
                        </Text>
                    )}
                    {destination.subarea?.comment && (
                        <Text variant="SmallText" color="textSecondary" display="inline">
                            {destination.subarea?.comment}
                        </Text>
                    )}
                    <div className={spacer} />
                </div>
            </div>
            <div className={tableContainer}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={tableHeadCell}>
                                <LocalizableText code={'Materials.reservedAmount'} />
                            </TableCell>
                            {pricesInfo.map(([label], index) => (
                                <TableCell key={index} className={tableHeadCell}>
                                    <LocalizableText code={label} />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={tableBodyCell}>
                                <TonnsFormatter>{destination.subarea?.reservedAmount}</TonnsFormatter>
                            </TableCell>
                            {pricesInfo.map(([_, value], index) => (
                                <TableCell key={index} className={tableBodyCell}>
                                    <NumberFormatter precision={commonPrecisions.price}>{value}</NumberFormatter>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </Card>
    );
};

const useStyles = makeStyles(theme => ({
    selectedStyle: {
        backgroundColor: COLORS.grayLight,
    },
    landfillInfoContainer: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(4),
    },
    subHeader: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(4),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    additionalInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        textAlign: 'left',
    },
    infoLabel: {
        display: 'inline',
        color: COLORS.grayDark,
        marginRight: theme.spacing(),
    },
    infoValue: {
        display: 'inline',
    },
    spacer: {
        flexGrow: 1,
    },
    tableContainer: {
        borderTop: `1px solid ${COLORS.gray}`,
        overflow: 'hidden',
    },
    tableHeadCell: {
        maxWidth: '180px',
        height: 48,
        verticalAlign: 'bottom',
    },
    tableBodyCell: {
        height: 40,
    },
    landfillInfoItem: {
        display: 'flex',
        alignItems: 'center',
    },
    selectLabel: {
        width: 92,
        marginBottom: 0,
    },
}));
