import { LinearProgress } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { SimpleDialog } from '~/@components/Dialog';
import { FilePreview } from '~/@components/FilePreview';
import { ImgLoader } from '~/@sochi-components/ImgLoader';
import { showCustomDialog } from '~/@store/common';

type Params = Record<string, string>;

type Props = {
    onClose: (v: boolean) => void;
    downloadUrl: string;
    params?: Params;
    filename: string;
};

export const DocumentViewDialog = ({ downloadUrl, params = {}, filename, onClose }: Props) => {
    const { container } = useStyles();

    return (
        <SimpleDialog maxWidth="lg" onClose={() => onClose(false)} fullWidth>
            <DialogContent>
                <div className={container}>
                    <ImgLoader url={downloadUrl} params={params} loadingPlaceholder={<LinearProgress />}>
                        {base64 => <FilePreview base64={base64} filename={filename} />}
                    </ImgLoader>
                </div>
            </DialogContent>
        </SimpleDialog>
    );
};

export const showDocumentViewDialog = (filename: string, downloadUrl: string, params?: Params) =>
    showCustomDialog({
        render: closeDialog => (
            <DocumentViewDialog downloadUrl={downloadUrl} params={params} filename={filename} onClose={closeDialog} />
        ),
    });

const useStyles = makeStyles(() => ({
    container: {
        borderRadius: '4px',
        width: '100%',
        height: `80vh`,
    },
}));
