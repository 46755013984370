import { Button, Grid, IconButton } from '@material-ui/core';
import React from 'react';

import { FormikBooleanSwitchField, FormikPhoneNumberField, FormikTextField } from '~/@components/FormikFields';
import { DeleteIcon } from '~/@components/Icon';
import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { IDetailedCompany, useGenerateCompanyApiKey, useRemoveCompanyApiKey } from '~/@store/companies';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';

import { useCompanyFormikContext } from './CompanyEditFormik';

export const CompanyEditCommonFields = ({ company }: { company: IDetailedCompany }) => {
    const formik = useCompanyFormikContext();

    const [generateApiKey] = useGenerateCompanyApiKey();
    const [removeApiKey] = useRemoveCompanyApiKey();

    const disableAPIOperations = formik.dirty || formik.isSubmitting;

    const onGenerateApiKey = () => {
        generateApiKey(company.id).then(() => {
            globalMessage.success(i18n.CompaniesTable.generationSuccess);
            formik.handleReset();
        });
    };

    const onClearApiKey = () => {
        removeApiKey(company.id).then(() => {
            globalMessage.success(i18n.CompaniesTable.apiKeyIsClear);
            formik.handleReset();
        });
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3} justify={'space-between'}>
                    <Grid item xs={12} lg={6}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.customerNumber'} />}
                            name="customerNumber"
                            formik={formik}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} container justify="flex-end">
                        <FormikBooleanSwitchField
                            checkedLabel={<LocalizableText code={'CompaniesTable.active'} />}
                            uncheckedLabel={<LocalizableText code={'CompaniesTable.active'} />}
                            name="active"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.orgNumber'} />}
                            name="organizationNumber"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} container justify="flex-end">
                        <FormikBooleanSwitchField
                            commonLabel={<LocalizableText code={'CompaniesTable.isTransportCompany'} />}
                            name="isTransportCompany"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.orgName'} />}
                            name="name"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.GLN'} />}
                            name="GLN"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikPhoneNumberField
                            label={<LocalizableText code={'CompaniesTable.phoneNumber'} />}
                            name="phone"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikPhoneNumberField
                            label={<LocalizableText code={'CompaniesTable.phoneNumber2'} />}
                            name="phone2"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.email'} />}
                            name="email"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.website'} />}
                            name="web"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.city'} />}
                            name="city"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.zipCode'} />}
                            name="zipCode"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.country'} />}
                            name="country"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.contactPerson1'} />}
                            name="contactPerson"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.contactPerson2'} />}
                            name="contactPerson2"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.address'} />}
                            name="address"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.address2'} />}
                            name="address2"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <FormikTextField
                            label={<LocalizableText code={'CompaniesTable.apiKey'} />}
                            name="APIKey"
                            formik={formik}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container spacing={1} alignItems={'center'}>
                            <Grid item xs={2}>
                                <LabelWithHint
                                    label={''}
                                    hint={<LocalizableText code={'CompaniesTable.saveBeforeAPIOperations'} />}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Button
                                    onClick={onGenerateApiKey}
                                    variant="outlined"
                                    fullWidth
                                    disabled={disableAPIOperations}>
                                    <LocalizableText code={'CompaniesTable.generateAPIKey'} />
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={onClearApiKey} disabled={disableAPIOperations}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
