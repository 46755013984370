import { Collapse, makeStyles } from '@material-ui/core';
import React from 'react';

import { DropDownIcon } from '~/@components/Icon';
import i18n from '~/i18n';

import { ClickableText } from '../ClickableText';
import { Text } from '../Text';

enum State {
    open = 'open',
    closed = 'closed',
}

const COLLAPSE = 'COLLAPSE_';

type Props = {
    title?: React.ReactNode;
    defaultOpen?: boolean;
    persistStateName?: string;
};

export const CollapseView: React.FC<Props> = ({ defaultOpen, title, persistStateName, children }) => {
    const { titleContainer, showMoreContainer, showMoreButton, spacer } = useStyles();

    const withTitle = !!title;
    const persistKey = persistStateName ? COLLAPSE + persistStateName : null;
    const persistState = persistKey ? localStorage.getItem(persistKey) : null;
    const initialState = persistState ? persistState === State.open : defaultOpen;
    const [isOpen, setOpen] = React.useState(initialState);

    const handleChange = () => {
        const newState = !isOpen;
        if (persistKey) {
            localStorage.setItem(persistKey, newState ? State.open : State.closed);
        }
        setOpen(newState);
    };

    return (
        <>
            {withTitle ? (
                <div className={titleContainer} onClick={handleChange}>
                    <Text variant="TitleBold">{title}</Text>
                    <DropDownIcon revert={isOpen} />
                </div>
            ) : (
                <div className={showMoreContainer}>
                    <ClickableText onClick={handleChange} className={showMoreButton}>
                        {i18n.showMore} <DropDownIcon revert={isOpen} />
                    </ClickableText>
                </div>
            )}
            <Collapse in={isOpen}>
                {withTitle && <div className={spacer} />}
                {children}
            </Collapse>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    showMoreContainer: {
        position: 'relative',
        top: -16,
        height: 0,
        overflow: 'visible',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    showMoreButton: {
        display: 'flex',
        alignItems: 'center',
    },
    spacer: {
        height: theme.spacing(2),
    },
}));
