import { UserContextRole } from '@common/enums';
import React, { VFC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { LocalizableText } from '~/@components/LocalizableText';
import { SochiRadioButton } from '~/@sochi-components/SochiRadioButton';
import i18n from '~/i18n';

type Props = {
    currentRole: UserContextRole;
    onRoleSelect: (role: UserContextRole) => void;
};

const options = [
    UserContextRole.PROJECT_USER,
    UserContextRole.GUEST,
    UserContextRole.PROJECT_DRIVER,
    UserContextRole.PROJECT_EXCAVATOR_OPERATOR,
] as const;

export const ProjectUsersSelectRole: VFC<Props> = ({ currentRole, onRoleSelect }) => {
    const { root, title } = useStyles();

    return (
        <div className={root}>
            <div className={title}>
                <LocalizableText code={'ProjectForm.selectUserModeTitle'} />
            </div>
            {options.map(role => (
                <SochiRadioButton
                    key={role}
                    checked={currentRole === role}
                    value={role}
                    label={i18n.UserContextRole[role]}
                    setValue={onRoleSelect}
                />
            ))}
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        marginBottom: '32px',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '18px',
    },
}));
