import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { DEFAULT_SEARCH_LIMIT } from '~/@store/common';
import { CompaniesSearchQuery, CompaniesSearchQueryVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../companies.queries';
import type { ICompanySearchRecord } from './useCompaniesSearch';

type UseCompaniesSearchFunc = (search: string) => Promise<Array<ICompanySearchRecord>>;

export const useCompaniesSearchFunc = (
    onlyTransportCompanies: boolean,
    limit: number = DEFAULT_SEARCH_LIMIT
): UseCompaniesSearchFunc => {
    const client = useApolloClient();

    return useCallback(
        (search: string) => {
            const variables: CompaniesSearchQueryVariables = {
                search,
                limit,
            };

            if (onlyTransportCompanies) variables.isTransportCompany = true;

            return client
                .query<CompaniesSearchQuery, CompaniesSearchQueryVariables>({
                    query: queries.CompaniesSearchQuery,
                    variables,
                })
                .then(res => res.data?.pplCompanies || [])
                .catch(handleApolloError);
        },
        [client, limit, onlyTransportCompanies]
    );
};
