import React, { useCallback } from 'react';

import { DeleteIcon, RestoreIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { CellButton } from '~/@components/Table/TableCellActions/CellButton';
import { showConfirmDialog } from '~/@store/common';
import { IDeliveryLine, useDeliveryLineDeleteMutation, useDeliveryLineRestoreMutation } from '~/@store/deliveryLines';

type Props = {
    deliveryLine: IDeliveryLine;
    disabled?: boolean;
};

export const DeliveryLineDeleteRestoreCell = ({ deliveryLine, disabled = false }: Props) => {
    const [deleteDeliveryLine, isDeleting] = useDeliveryLineDeleteMutation(deliveryLine);
    const [restoreDeliveryLine, isRestoring] = useDeliveryLineRestoreMutation(deliveryLine);

    const onDelete = useCallback(
        () =>
            showConfirmDialog({
                title: <LocalizableText code={'DeliveryLinesTable.deleteInfo'} />,
                render: () => <LocalizableText code={'DeliveryLinesTable.deleteConfirmation'} />,
                icon: DeleteIcon,
            }).then(confirmed => confirmed && deleteDeliveryLine()),
        [deleteDeliveryLine]
    );

    const onRestore = useCallback(
        () =>
            showConfirmDialog({
                title: <LocalizableText code={'DeliveryLinesTable.restoreInfo'} />,
                render: () => <LocalizableText code={'DeliveryLinesTable.restoreConfirmation'} />,
                icon: RestoreIcon,
            }).then(confirmed => confirmed && restoreDeliveryLine()),
        [restoreDeliveryLine]
    );

    const isDeleted = !!deliveryLine.deletedAt;

    return (
        <CellButton onClick={isDeleted ? onRestore : onDelete} disabled={isDeleting || isRestoring || disabled}>
            {isDeleted ? <RestoreIcon /> : <DeleteIcon />}
        </CellButton>
    );
};
