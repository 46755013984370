import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ArrowRightIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <path
                d="M12 20.0013L10.59 18.5913L16.17 13.0013L4 13.0013L4 11.0013L16.17 11.0013L10.59 5.41125L12 4.00125L20 12.0013L12 20.0013Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
