import React from 'react';

import type { ISettings } from '~/@store/settings';

import { ConfigurationField } from '../ConfigurationField';

type SettingsKeys = ISettings['keys'];

type APIInputProps = {
    keys: SettingsKeys;
    setKeys: (value: SettingsKeys) => void;
    field: keyof SettingsKeys;
    label: React.ReactNode;
};

export const APIConfigurationField = ({ keys, field, label, setKeys }: APIInputProps) => {
    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeys({
            ...keys,
            [field]: event.target.value,
        });
    };

    return <ConfigurationField value={keys[field]} onChange={handleInput} label={label} />;
};
