import i18n from '~/i18n';

import { UserActivityMinuteItem } from './userActivities.store';

const MINUTES = [5, 15, 60, 180];

export const userActivityMinuteOptions: UserActivityMinuteItem[] = MINUTES.map(m => ({
    id: m.toString(),
    name: `${m} ${i18n.UserActivityTable.minAgo}`,
    value: m,
}));
