import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { DeleteFromBlacklistMutation, DeleteFromBlacklistMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../blacklist.queries';
import { blacklistStore } from '../blacklist.store';

type DeleteFromBlacklistFunc = (id: string) => Promise<unknown>;

export const useDeleteFromBlacklistMutation = (): [DeleteFromBlacklistFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        DeleteFromBlacklistMutation,
        DeleteFromBlacklistMutationVariables
    >(queries.DeleteFromBlacklistMutation);

    const deleteFromBlacklist = useCallback<DeleteFromBlacklistFunc>(
        (id: string) => {
            return callMutation({
                variables: { id },
                refetchQueries: [{ query: queries.BlacklistQuery, variables: blacklistStore.blacklistQueryVariables }],
            }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [deleteFromBlacklist, mutationResult.loading];
};
