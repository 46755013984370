import { gql } from '@apollo/client';

export const ReleaseNoteFragment = gql`
    fragment ReleaseNoteFragment on ReleaseNote {
        id
        text
        releaseDate
        type
    }
`;

export const ReleaseNotesByTypeQuery = gql`
    query ReleaseNotesByTypeQuery($type: ReleaseNoteType!) {
        releaseNotes(type: $type, onlyReleased: true) {
            ...ReleaseNoteFragment
        }
    }

    ${ReleaseNoteFragment}
`;

export const AdminAllReleaseNotesQuery = gql`
    query AdminAllReleaseNotesQuery {
        releaseNotes {
            ...ReleaseNoteFragment
        }
    }

    ${ReleaseNoteFragment}
`;

export const CreateReleaseNoteMutation = gql`
    mutation CreateReleaseNoteMutation($input: ReleaseNoteInput!) {
        createReleaseNote(input: $input) {
            ...ReleaseNoteFragment
        }
    }

    ${ReleaseNoteFragment}
`;

export const UpdateReleaseNoteMutation = gql`
    mutation UpdateReleaseNoteMutation($id: String!, $input: ReleaseNoteInput!) {
        updateReleaseNote(id: $id, input: $input) {
            ...ReleaseNoteFragment
        }
    }

    ${ReleaseNoteFragment}
`;

export const DeleteReleaseNoteMutation = gql`
    mutation DeleteReleaseNoteMutation($id: String!) {
        deleteReleaseNote(id: $id)
    }
`;
