import { UserContextRole } from '@common/enums';
import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { AutocompleteAsync } from '~/@components/Autocomplete';
import { SimpleDialog } from '~/@components/Dialog';
import { Select } from '~/@components/Select';
import { IProjectSearchRecord, useProjectsSearchFunc } from '~/@store/projects';
import { IDetailedUser } from '~/@store/users';
import { UserRole } from '~/graphql';
import i18n from '~/i18n';

const renderProject = (project: IProjectSearchRecord) => project.name;

function getContextRole(role: UserRole): UserContextRole {
    switch (role) {
        case UserRole.ADMIN:
        case UserRole.EMPLOYEE:
            return UserContextRole.OWNER;
        case UserRole.DRIVER:
            return UserContextRole.PROJECT_DRIVER;
        case UserRole.EXCAVATOR_OPERATOR:
            return UserContextRole.PROJECT_EXCAVATOR_OPERATOR;
        case UserRole.EXTERNAL_RECEIVER:
            return UserContextRole.PROJECT_USER;
    }
}

type Props = {
    user: IDetailedUser;
    onSubmit: (value: { role: UserContextRole; project: IProjectSearchRecord } | null) => void;
};

export const ProjectAndRoleSelectDialog = ({ user, onSubmit }: Props) => {
    const { root, textField, buttons } = useStyles();

    const [project, setProject] = useState<IProjectSearchRecord | null>(null);
    const [role, setRole] = useState(getContextRole(user.role));

    const handleSubmit = () => {
        if (project) onSubmit({ project, role });
    };

    const handleCancel = () => {
        onSubmit(null);
    };

    const projectSearch = useProjectsSearchFunc({ excludeUserId: user.id });

    return (
        <SimpleDialog onClose={handleCancel}>
            <Paper className={root}>
                <AutocompleteAsync
                    label={i18n.project}
                    selectedOption={project}
                    onOptionSelect={setProject}
                    renderOption={renderProject}
                    loadOptions={projectSearch}
                    className={textField}
                />
                <Select
                    label={i18n.role}
                    items={
                        user.role === UserRole.EXTERNAL_RECEIVER
                            ? [UserContextRole.PROJECT_USER, UserContextRole.GUEST]
                            : [role]
                    }
                    renderValue={role => i18n.UserContextRole[role]}
                    keyGetter={r => r}
                    value={role}
                    onChange={role => role && setRole(role)}
                    addEmptyOption={false}
                    disabled={user.role !== UserRole.EXTERNAL_RECEIVER}
                />
                <div className={buttons}>
                    <Button variant="contained" onClick={handleCancel}>
                        {i18n.cancel}
                    </Button>
                    <Button variant="contained" color="primary" disabled={!project} onClick={handleSubmit}>
                        {i18n.add}
                    </Button>
                </div>
            </Paper>
        </SimpleDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
        width: 400,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
    },
    textField: {
        backgroundColor: theme.palette.background.paper,
    },
}));
