import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useCallback } from 'react';

import { COLORS, makeStyles } from '~/@components/@theme';
import { LocalizableText } from '~/@components/LocalizableText';
import { homePageMapStore, IDumpLoad } from '~/@user-store/homePageMap';

import { Card } from '../Card';

export const ProjectDumpLoads: FC = observer(() => {
    const onSelect = useCallback((dumpLoad: IDumpLoad) => {
        homePageMapStore.onProjectDumpLoadClick(dumpLoad);
    }, []);

    const { noData, root, header, row, even, selected } = useStyles();

    if (!homePageMapStore.projectDumpLoads) return null;

    if (homePageMapStore.projectDumpLoads.length === 0)
        return (
            <div className={noData}>
                <LocalizableText code={'PoiInfoPanel.noProjectDumpLoads'} />
            </div>
        );

    return (
        <Card>
            <TableContainer className={root}>
                <Table size={'small'} stickyHeader>
                    <TableHead>
                        <TableRow className={header}>
                            <TableCell align={'left'}>
                                <LocalizableText code={'PoiInfoPanel.serialNumber'} />
                            </TableCell>
                            <TableCell align={'left'}>
                                <LocalizableText code={'PoiInfoPanel.landfill'} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {homePageMapStore.projectDumpLoads.map((d, i) => (
                            <TableRow
                                key={d.serialNumber}
                                className={cn(row, {
                                    [even]: i % 2 === 0,
                                    [selected]:
                                        d.serialNumber === homePageMapStore.selectedProjectDumpLoad?.serialNumber,
                                })}
                                onClick={() => onSelect(d)}>
                                <TableCell align={'left'}>{d.serialNumber}</TableCell>
                                <TableCell align={'left'}>{d.destinationLandfill?.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
});

const useStyles = makeStyles(() => ({
    noData: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '18px',
    },
    root: {
        maxHeight: '240px',
        '& .MuiTableCell-root': {
            fontSize: '14px',
            height: '40px',
        },
    },
    header: {
        '& .MuiTableCell-head': {
            color: COLORS.white,
            backgroundColor: COLORS.brandMain,
        },
    },
    row: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            cursor: 'pointer',
        },
    },
    even: {
        '& .MuiTableCell-root': {
            background: COLORS.grayLight,
        },
    },
    selected: {
        '& .MuiTableCell-root': {
            background: COLORS.blueLight,
        },
    },
}));
