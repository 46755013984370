import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { COLORS } from '~/@components/@theme/colors';
import { Chip } from '~/@components/Chip';
import { removeEmptyKeys } from '~/@store/common';
import { DeliveryLineField, DeliveryLineFilterField, deliveryLinesStore } from '~/@store/deliveryLines';
import { formatFilterValue } from '~/@views/AdminView/common/utils';
import i18n from '~/i18n';

type Props = {
    disabled?: boolean;
};

const FieldNames: Record<DeliveryLineFilterField, string> = {
    [DeliveryLineField.orderNumber]: i18n.DeliveryLinesTable.orderNumber,
    [DeliveryLineField.weight]: i18n.DeliveryLinesTable.weight,
    [DeliveryLineField.plannedStartDate]: i18n.DeliveryLinesTable.date,
    [DeliveryLineField.transportCompany]: i18n.DeliveryLinesTable.transportCompany,
    [DeliveryLineField.truckRegistrationNumber]: i18n.DeliveryLinesTable.truckRegistrationNumber,
    [DeliveryLineField.customerInvoiceNumber]: i18n.DeliveryLinesTable.customerInvoice,
    [DeliveryLineField.transportationInvoiceNumber]: i18n.DeliveryLinesTable.transportInvoice,
    [DeliveryLineField.landfillInvoiceNumber]: i18n.DeliveryLinesTable.landfillInvoice,
    [DeliveryLineField.verified]: i18n.DeliveryLinesTable.verified,
    [DeliveryLineField.truckCapacity]: i18n.DeliveryLinesTable.truckCapacity,
    [DeliveryLineField.comment]: i18n.DeliveryLinesTable.comment,
    [DeliveryLineField.status]: i18n.DeliveryLinesTable.status,
};

export const DeliveryLinesFilterSummary = ({ disabled = false }: Props) => {
    const filterValues = useObservable(() => removeEmptyKeys({ ...deliveryLinesStore.filter.values }));

    const { root, chip } = useStyles();

    return (
        <div className={root}>
            {Object.keys(filterValues).map(objectKey => {
                const key = objectKey as DeliveryLineFilterField;
                const value = filterValues[key];
                if (value === null || value === undefined) return null;
                const chipContent = `${FieldNames[key]}: "${formatFilterValue(value)}"`;

                return (
                    <Chip
                        key={key}
                        className={chip}
                        color={COLORS.black}
                        onDelete={() => deliveryLinesStore.filter.setValue(key, null)}
                        deleteIcon={<Icon>close</Icon>}
                        disabled={!deliveryLinesStore.canResetFilter(key) || disabled}>
                        {chipContent}
                    </Chip>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        marginRight: theme.spacing(1),
    },
}));
