import { CircularProgress } from '@material-ui/core';
import React from 'react';

import { useProgressState } from '~/@components/@hooks/use-progress-state';

import { Button, ButtonProps } from './Button';

type Props = Omit<ButtonProps, 'onClick'> & { onClick: () => Promise<unknown> };

export const ProgressButton = ({ onClick, disabled, children, ...rest }: Props) => {
    const [loading, handleClick] = useProgressState(onClick);

    return (
        <Button {...rest} onClick={() => handleClick()} disabled={disabled || loading}>
            {children}
            {loading && <CircularProgress style={{ position: 'absolute' }} size={30} />}
        </Button>
    );
};
