import React from 'react';

import i18n from '../i18n';
import { convertBlobToBase64, downloadFile } from '../utils/files';

type ImgLoaderProps = {
    url: string;
    params?: { [key: string]: string };
    children: (imgBase64: string) => React.ReactNode;
    loadingPlaceholder?: React.ReactNode;
    errorPlaceholder?: React.ReactNode;
};

interface ImgLoaderState {
    base64: string;
    isError: boolean;
}

export class ImgLoader extends React.Component<ImgLoaderProps, ImgLoaderState> {
    state = { base64: '', isError: false };

    componentDidMount() {
        downloadFile(this.props.url, this.props.params || {}, '', false, false)
            .then(convertBlobToBase64)
            .then(base64 => this.setState({ base64 }))
            .catch(() => {
                this.setState({ isError: true });
            });
    }

    render() {
        const { children, loadingPlaceholder, errorPlaceholder } = this.props;
        const { isError, base64 } = this.state;

        if (isError) return errorPlaceholder || i18n.error;
        if (!base64) return loadingPlaceholder || i18n.loading;

        return children(base64);
    }
}
