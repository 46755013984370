import { makeStyles } from '@material-ui/core';
import React from 'react';

import { CornerArrowIcon } from '~/@components/Icon';
import { Text } from '~/@components/Text';
import { getSubstanceErrorMessage, ISubstance, ISubstanceChild, ToxicLimitsStore } from '~/@store/toxicLimits';

import { ToxicLimitsValueCellContainer } from './ToxicLimitsValueCellContainer';
import { ToxicLimitsValueInput } from './ToxicLimitsValueInput';

type Props = {
    substance: ISubstance | ISubstanceChild;
    store: ToxicLimitsStore;
};

export const ToxicLimitsSubstanceRow = ({ substance, store }: Props) => {
    const { root, title, subItemIcon } = useStyles();

    const errorLevels = store.validateSubstanceValues(substance.id);
    const errorMessage = errorLevels ? getSubstanceErrorMessage(errorLevels, store.contaminationType) : null;

    const isChild = substance.__typename === 'SubstanceChild';
    const readOnly = substance.__typename === 'Substance' && !!substance.children?.length;

    return (
        <div className={root}>
            {isChild && <CornerArrowIcon className={subItemIcon} />}
            <div className={title}>
                <Text variant="BodyRegular">{substance.name}</Text>
                <Text variant="SmallText" color="error">
                    {errorMessage}
                </Text>
            </div>
            {store.limitLevels.map(level => (
                <ToxicLimitsValueCellContainer key={level}>
                    <ToxicLimitsValueInput
                        store={store}
                        substanceId={substance.id}
                        level={level}
                        hasError={errorLevels?.includes(level)}
                        readOnly={readOnly}
                    />
                </ToxicLimitsValueCellContainer>
            ))}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: 56,
        padding: theme.spacing(),
        paddingLeft: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    subItemIcon: {
        marginRight: theme.spacing(),
    },
}));
