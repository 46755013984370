import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { StorePagination } from '~/@components/Pagination';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import { ILandfill, IPagedLandfills, landfillsStore, usePagedLandfills } from '~/@store/landfills';
import { LandfillsFilterSummary } from '~/@views/AdminView/LandfillsPage/LandfillsFilterSummary';
import { useLandfillsColumns } from '~/@views/AdminView/LandfillsPage/useLandfillsColumns';

export const LandfillsTable = () => {
    const query = usePagedLandfills();

    const columns = useLandfillsColumns();

    const { table, tableHeader } = useStyles();

    return (
        <QueryStateHandler
            query={query}
            dataGetter={query => query.data?.landfillsPaged || query.previousData?.landfillsPaged}>
            {(data: IPagedLandfills) => (
                <>
                    <div className={tableHeader}>
                        <LandfillsFilterSummary />
                        <StorePagination store={landfillsStore.pagination} />
                    </div>
                    <Table<ILandfill>
                        columns={columns}
                        items={data.edges.map(e => e.node)}
                        keyGetter={o => o.id}
                        className={table}
                        name="LandfillTable"
                    />
                </>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        height: 'calc(100% - 48px)',
    },
    tableHeader: {
        height: 48,
        display: 'flex',
        marginTop: -8,
    },
}));
