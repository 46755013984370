import { DocumentTypes } from '@common/enums';
import { IconButton, LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React from 'react';

import { showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { FileUploadButton } from '~/@components/FileUploadButton';
import { DeleteIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import {
    IDetailedProject,
    useProjectAbilities,
    useProjectAddCertificate,
    useProjectRemoveCertificate,
} from '~/@store/projects';
import { showDocumentViewDialog } from '~/@views/AdminView/common/DocumentViewDialog';
import { downloadCertificateUri } from '~/apolloClient';
import i18n from '~/i18n';

type Props = {
    project: IDetailedProject;
};

export const ProjectCertificatesList = ({ project }: Props) => {
    const [addCertificate, isAdding] = useProjectAddCertificate(project.id);
    const [removeCertificate, isRemoving] = useProjectRemoveCertificate(project.id);
    const isLoading = isAdding || isRemoving;

    const { canUploadDocument, canDeleteDocument } = useProjectAbilities(project);

    const handleOpenFile = (filename: string) => () =>
        showDocumentViewDialog(filename, downloadCertificateUri, {
            type: DocumentTypes.PROJECT,
            id: project.id,
            filename,
        });

    const handleRemove = (fileName: string) => () =>
        showDeleteConfirmationDialog(<LocalizableText code={'deleteFileConfirm'} />, fileName).then(
            confirmed => confirmed && removeCertificate(fileName)
        );

    return (
        <>
            <Text variant="BodyBold" color="textSecondary">
                <LocalizableText code={'ProjectDocuments.other'} />
            </Text>
            {isLoading && <LinearProgress />}
            <List>
                {project.certificates.map(file => (
                    <ListItem key={file} button onClick={handleOpenFile(file)}>
                        <ListItemText>{file}</ListItemText>
                        <ListItemSecondaryAction>
                            {canDeleteDocument && (
                                <IconButton onClick={handleRemove(file)} disabled={isLoading}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
                {!project.certificates.length && (
                    <ListItem>
                        <ListItemText>{i18n.emptyList}</ListItemText>
                    </ListItem>
                )}
            </List>
            <FileUploadButton
                variant="contained"
                onChange={addCertificate}
                fullWidth
                disabled={!canUploadDocument || isLoading}>
                {i18n.addFile}
            </FileUploadButton>
        </>
    );
};
