import { makeStyles } from '@material-ui/core';
import clamp from 'lodash/clamp';
import React from 'react';

import { ControlButtons } from '~/@components/FilePreview/ControlButtons';
import { DownloadIcon, ZoomInIcon, ZoomOutIcon } from '~/@components/Icon';
import { PdfDocument, PdfPage } from '~/@components/PdfDocument';
import { saveToClientBase64 } from '~/utils/files';

type Props = {
    base64src: string;
    filename: string;
};

const MIN_ZOOM = 0.5;
const MAX_ZOOM = 5;
const STEP_ZOOM = 0.3;

export const PdfPreview = ({ base64src, filename }: Props) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const width = containerRef.current?.clientWidth || 0;
    const [numPages, setNumPages] = React.useState(0);
    const [zoom, setZoom] = React.useState(1);

    const handleZoom = (dir: 1 | -1) => {
        let newZoom = zoom * (1 + STEP_ZOOM * dir);
        newZoom = clamp(newZoom, MIN_ZOOM, MAX_ZOOM);
        setZoom(newZoom);
    };

    const { root, scrollContainer } = useStyles();

    return (
        <div ref={containerRef} className={root}>
            <div className={scrollContainer}>
                <PdfDocument file={base64src} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                    {Array.from({ length: numPages })
                        .fill(null)
                        .map((_, index) => (
                            <PdfPage key={index} pageIndex={index} width={width * zoom} />
                        ))}
                </PdfDocument>
            </div>
            <ControlButtons
                buttons={[
                    { icon: ZoomOutIcon, onClick: () => handleZoom(-1) },
                    { icon: ZoomInIcon, onClick: () => handleZoom(1) },
                    null,
                    { icon: DownloadIcon, onClick: () => saveToClientBase64(base64src, filename) },
                ]}
            />
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
    },
    scrollContainer: {
        width: '100%',
        height: '100%',
        overflow: 'scroll',
    },
});
