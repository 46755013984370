import React, { FC } from 'react';
import { TooltipProps } from 'recharts';

import { makeStyles } from '~/@components/@theme';
import { COLOR, FONT_WEIGHT } from '~/@sochi-components/@theme';

type Props = {
    labelKey: string;
    labelFormatter?: (value: string | undefined | null) => string;
    valueKey: string;
    valueLabel: string;
    valueFormatter?: (value: number | undefined | null) => string;
} & TooltipProps<number, string>;

export const SimpleDashboardToolTip: FC<Props> = ({
    active,
    payload,
    labelKey,
    labelFormatter,
    valueKey,
    valueLabel,
    valueFormatter,
}) => {
    const { root, label } = useStyles();

    if (!active || !payload || payload.length === 0) return null;

    const firstPayload = payload[0]!.payload;
    const headerLabel = firstPayload.hasOwnProperty(labelKey) ? firstPayload[labelKey] : '';

    return (
        <div className={root}>
            <div className={label}>{labelFormatter ? labelFormatter(headerLabel) : headerLabel}</div>
            <div>
                {`${valueLabel}: ${
                    valueFormatter
                        ? valueFormatter(firstPayload.hasOwnProperty(valueKey) ? firstPayload[valueKey] : '')
                        : firstPayload.hasOwnProperty(valueKey)
                        ? firstPayload[valueKey]
                        : ''
                }`}
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        background: COLOR.grayBackground,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        alignItems: 'center',
    },
    label: {
        fontWeight: FONT_WEIGHT.bold,
    },
});
