import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isValid } from 'date-fns';
import React, { KeyboardEventHandler, useState } from 'react';

import { useProgressState } from '~/@components/@hooks/use-progress-state';
import { DatePicker } from '~/@components/DatePicker';
import { ENTER_KEY_NAME, ESC_KEY_NAME } from '~/@store/common';
import { PopupEditDialog } from '~/@views/AdminView/common/PopupEditFields/PopupEditDialog';
import i18n from '~/i18n';
import { parseDateFrom } from '~/utils/date';

const defaultValidation: IValidation = v => v instanceof Date && isValid(v);

type IValidation = (v: Date | null) => boolean;

type Props = {
    label: React.ReactNode;
    initialValue: string | Date | null;
    onSubmit: (value: Date | null) => Promise<unknown>;
    onClose: (v: boolean) => void;
    validation?: IValidation;
    nullable?: boolean;
};

export const PopupDateEdit = ({
    label,
    initialValue,
    onClose,
    validation = defaultValidation,
    onSubmit,
    nullable = true,
}: Props) => {
    const { root, textField } = useStyles();

    const [value, setValue] = useState(initialValue ? parseDateFrom(initialValue) : null);

    const [isLoading, submit] = useProgressState(onSubmit);

    const canSubmit = validation(value);

    const handleClear = () => {
        if (nullable) submit(null).then(() => onClose(true));
    };

    const handleSubmit = () => {
        if (nullable || defaultValidation(value)) submit(value).then(() => onClose(true));
    };

    const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = event => {
        if (event.key === ENTER_KEY_NAME && canSubmit) handleSubmit();
        if (event.key === ESC_KEY_NAME) onClose(false);
    };

    const handleChange = (v: Date | null) => setValue(v);

    return (
        <PopupEditDialog onClose={onClose}>
            <div className={root}>
                <DatePicker
                    autoFocus
                    clearable
                    autoOk
                    label={label}
                    value={value || null}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    className={textField}
                    disabled={isLoading}
                />
                <Button disabled={isLoading || !canSubmit} variant="contained" color="primary" onClick={handleSubmit}>
                    {i18n.save}
                </Button>
                {nullable && (
                    <Button disabled={isLoading} variant="contained" onClick={handleClear}>
                        {i18n.clear}
                    </Button>
                )}
            </div>
        </PopupEditDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        gap: theme.spacing(2),
    },
    textField: {
        flexGrow: 1,
    },
}));
