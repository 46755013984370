import { useProgressState } from '~/@components/@hooks';
import * as queries from '~/@store/companies/companies.queries';
import client from '~/apolloClient';
import {
    CompanyActiveConnectedEntitiesQuery,
    CompanyActiveConnectedEntitiesQuery_companyActiveConnectedEntities,
    CompanyActiveConnectedEntitiesQueryVariables,
} from '~/graphql';

export type IConnectedEntitiesInfo = CompanyActiveConnectedEntitiesQuery_companyActiveConnectedEntities;

type GetConnectedEntitiesFunc = (is: string) => Promise<IConnectedEntitiesInfo>;

export const useGetConnectedActiveEntities = (): [boolean, GetConnectedEntitiesFunc] => {
    const result = (id: string) =>
        client
            .query<CompanyActiveConnectedEntitiesQuery, CompanyActiveConnectedEntitiesQueryVariables>({
                query: queries.CompanyActiveConnectedEntitiesQuery,
                variables: { id },
            })
            .then(({ data }) => {
                return data.companyActiveConnectedEntities;
            });

    return useProgressState(result);
};
