import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { DEFAULT_SEARCH_LIMIT } from '~/@store/common';
import * as queries from '~/@store/users/users.queries';
import {
    AdminUsersSearchQuery,
    AdminUsersSearchQuery_users,
    AdminUsersSearchQueryVariables,
    ProjectQuery_project_users,
    UserFilter,
} from '~/graphql';
import { handleApolloError } from '~/utils';

export type IUserSearchRecord =
    | Omit<AdminUsersSearchQuery_users, '__typename'>
    | Omit<ProjectQuery_project_users, '__typename'>;

type UseUsersSearchFunc = (search: string) => Promise<Array<IUserSearchRecord>>;

export const useUsersSearchFunc = (
    filter: UserFilter = {},
    limit: number = DEFAULT_SEARCH_LIMIT
): UseUsersSearchFunc => {
    const client = useApolloClient();

    return useCallback(
        (search: string) => {
            const variables: AdminUsersSearchQueryVariables = {
                filter: {
                    ...filter,
                    search,
                },
                limit,
            };

            return client
                .query<AdminUsersSearchQuery, AdminUsersSearchQueryVariables>({
                    query: queries.AdminUsersSearchQuery,
                    variables,
                })
                .then(res => res.data?.users || [])
                .catch(handleApolloError);
        },
        [client, limit, filter]
    );
};
