import { IDestination } from '~/@store/dumpLoads/dumpLoads.hooks';
import i18n from '~/i18n';

export enum DestinationSort {
    Distance = 'Distance',
    LoadFee = 'LoadFee',
    InternalLoadFee = 'InternalLoadFee',
}

export const getInitialDestinationSort = () => DestinationSort.Distance;

const sortFunctions: Record<DestinationSort, (a: IDestination, b: IDestination) => number> = {
    Distance: (a, b) => a.distance - b.distance,
    LoadFee: (a, b) => a.loadFee - b.loadFee,
    InternalLoadFee: (a, b) => a.internalLoadFee - b.internalLoadFee,
};

export const sortDestinations = (sort: DestinationSort, destinations: IDestination[]) =>
    destinations.slice().sort(sortFunctions[sort]);

export const DestinationCountOptions = {
    five: 5,
    ten: 10,
    fifteen: 15,
    all: i18n.showAll,
} as const;
