import {
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';
import React from 'react';

import { showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { FileUploadButton } from '~/@components/FileUploadButton';
import { DeleteIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import {
    IDetailedLandfill,
    IFileDoc,
    useLandfillRemoveCertificateMutation,
    useLandfillUpdateMutation,
} from '~/@store/landfills';
import { showDocumentViewDialog } from '~/@views/AdminView/common/DocumentViewDialog';
import { landfillCertificateUri } from '~/apolloClient';
import i18n from '~/i18n';

type Props = {
    landfill: IDetailedLandfill;
};

export const LandfillDocuments = ({ landfill }: Props) => {
    const [remove, isRemoving] = useLandfillRemoveCertificateMutation(landfill);
    const [update, isUpdating] = useLandfillUpdateMutation(landfill);

    const handleOpenFile = (file: IFileDoc) => () =>
        showDocumentViewDialog(file.name, landfillCertificateUri, {
            landfillId: landfill.id,
            fileId: file.id,
        });

    const handleUpload = (file: File) => update({ certificates: [file] });
    const handleRemove = (file: IFileDoc) => async () =>
        showDeleteConfirmationDialog(<LocalizableText code={'deleteFileConfirm'} />, file.name).then(
            confirmed => confirmed && remove(file.id)
        );

    return (
        <Grid container>
            <Grid item xs={4}>
                {(isRemoving || isUpdating) && <LinearProgress />}
                <List>
                    {landfill.certificates.map(file => (
                        <ListItem key={file.id} button onClick={handleOpenFile(file)}>
                            <ListItemText>{file.name}</ListItemText>
                            <ListItemSecondaryAction>
                                <IconButton onClick={handleRemove(file)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                    {!landfill.certificates.length && (
                        <ListItem>
                            <ListItemText>{i18n.emptyList}</ListItemText>
                        </ListItem>
                    )}
                </List>
                <FileUploadButton color="primary" variant="contained" onChange={handleUpload} fullWidth>
                    <LocalizableText code={'addFile'} />
                </FileUploadButton>
            </Grid>
        </Grid>
    );
};
