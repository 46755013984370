import { IconProps } from '@material-ui/core';
import React from 'react';

import {
    AddIcon,
    CancelIcon,
    CardIcon,
    CheckIcon,
    CopyIcon,
    DeleteIcon,
    DownloadIcon,
    EditIcon,
    LinkIcon,
    ListIcon,
    RestoreIcon,
} from '~/@components/Icon';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';

import { CellButton } from './CellButton';
import { ActionVariant, ITableCellAction } from './interfaces';

type IconClass = React.FC<Pick<IconProps, 'fontSize'>>;

const TypeIcons: Record<ActionVariant, IconClass> = {
    [ActionVariant.COPY]: CopyIcon,
    [ActionVariant.LINK]: LinkIcon,
    [ActionVariant.CARD]: CardIcon,
    [ActionVariant.EDIT]: EditIcon,
    [ActionVariant.ADD]: AddIcon,
    [ActionVariant.LIST]: ListIcon,
    [ActionVariant.APPROVE]: CheckIcon,
    [ActionVariant.UPLOAD_FILE]: AddIcon,
    [ActionVariant.DELETE]: DeleteIcon,
    [ActionVariant.RESTORE]: RestoreIcon,
    [ActionVariant.REJECT]: CancelIcon,
    [ActionVariant.DOWNLOAD_FILE]: DownloadIcon,
};

type ActionButtonProps = {
    action: ITableCellAction;
};

export const TableActionButton = ({ action }: ActionButtonProps) => {
    const Icon = TypeIcons[action.variant];

    switch (action.variant) {
        case ActionVariant.CARD:
        case ActionVariant.EDIT:
        case ActionVariant.ADD:
        case ActionVariant.LIST:
        case ActionVariant.APPROVE:
        case ActionVariant.DELETE:
        case ActionVariant.RESTORE:
        case ActionVariant.REJECT:
        case ActionVariant.DOWNLOAD_FILE:
            return (
                <CellButton onClick={action.onClick} disabled={action.disabled} disabledReason={action.disabledReason}>
                    <Icon />
                </CellButton>
            );
        case ActionVariant.COPY:
            const { content } = action;

            const onCopy = () =>
                navigator.clipboard.writeText(content || '').then(() => globalMessage.success(i18n.textCopied));

            return (
                <CellButton
                    onClick={onCopy}
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    disabled={action.disabled || !navigator.clipboard || !content}
                    disabledReason={action.disabledReason || i18n.noContentToCopy}>
                    <Icon />
                </CellButton>
            );
        case ActionVariant.LINK:
            return (
                <CellButton
                    isLink
                    to={action.to ?? ''}
                    targetBlank={action.targetBlank}
                    disabled={!action.to || action.disabled}
                    disabledReason={action.disabledReason || i18n.noLinkToFollow}>
                    <Icon />
                </CellButton>
            );
        case ActionVariant.UPLOAD_FILE:
            return (
                <CellButton
                    isFileUpload
                    onChange={action.onChange}
                    acceptFileTypes={action.acceptFileTypes}
                    checkFileSize={action.checkFileSize}
                    disabled={action.disabled}
                    disabledReason={action.disabledReason}>
                    <Icon />
                </CellButton>
            );
        default:
            return null;
    }
};
