import { makeStyles } from '@material-ui/core';
import React, { VFC } from 'react';

import { COLOR } from '../@theme/styles';

type Props = {
    children: React.ReactNode;
};

export const PageWrapper: VFC<Props> = ({ children }) => {
    const { root } = useStyles();

    return <div className={root}>{children}</div>;
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px 20px 20px',
        backgroundColor: COLOR.grayBackground,
        [theme.breakpoints.down('lg')]: {
            padding: '0px 10px 20px',
        },
    },
}));
