import { Checkbox as MuiCheckbox, CheckboxProps, FormControlLabel, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import { useDisable } from '~/@components/@hooks';
import { CHECKED_ICON_CHEVRON_CLASSNAME } from '~/@sochi-components/Icons';

interface Props extends Omit<CheckboxProps, 'onChange'> {
    label?: React.ReactNode;
    inverted?: boolean;
    onChange: (checked: boolean) => void;
    labelPlacement?: 'start' | 'top' | 'bottom' | 'end';
    fullWidth?: boolean;
    title?: string;
}

export const Checkbox = ({
    label,
    disabled,
    className,
    inverted,
    onChange,
    labelPlacement,
    fullWidth,
    title,
    ...restProps
}: Props) => {
    const { labelClass, checkboxClass, invertedClass, fullWithClass } = useStyles();

    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked),
        [onChange]
    );

    disabled = useDisable() || disabled;

    if (!label) {
        return (
            <MuiCheckbox
                className={cn(className, checkboxClass, { [invertedClass]: inverted })}
                onChange={handleChange}
                disabled={disabled}
                {...restProps}
            />
        );
    }

    return (
        <FormControlLabel
            className={cn(labelClass, className, { [fullWithClass]: fullWidth })}
            control={
                <MuiCheckbox
                    className={cn(checkboxClass, { [invertedClass]: inverted })}
                    onChange={handleChange}
                    {...restProps}
                />
            }
            label={label}
            disabled={disabled}
            labelPlacement={labelPlacement}
            title={title}
        />
    );
};

const useStyles = makeStyles(theme => ({
    labelClass: {
        '& .MuiFormControlLabel-root': {
            marginLeft: theme.spacing(),

            '&.MuiFormControlLabel-labelPlacementStart': {
                marginLeft: 0,
                marginRight: theme.spacing(),
            },
            '&.MuiFormControlLabel-labelPlacementTop': {
                marginLeft: 0,
                marginBottom: theme.spacing(),
            },
            '&.MuiFormControlLabel-labelPlacementBottom': {
                marginLeft: 0,
                marginTop: theme.spacing(),
            },
        },
    },
    fullWithClass: {
        '&.MuiFormControlLabel-root': {
            width: '100%',
        },
        '& .MuiFormControlLabel-label': {
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
    checkboxClass: {
        [`& .${CHECKED_ICON_CHEVRON_CLASSNAME}`]: {
            fill: theme.palette.common.white,
        },
    },
    invertedClass: {
        color: `${theme.palette.common.white} !important`,
        [`&.MuiCheckbox-colorPrimary .${CHECKED_ICON_CHEVRON_CLASSNAME}`]: {
            fill: theme.palette.primary.main,
        },
        [`&.MuiCheckbox-colorSecondary .${CHECKED_ICON_CHEVRON_CLASSNAME}`]: {
            fill: theme.palette.secondary.main,
        },
    },
}));
