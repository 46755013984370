import React from 'react';

import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { IDespatchAdviceDefaultSettings, useDespatchAdviceDefaultSettings } from '~/@store/beastIntegration';
import { showCustomDialog } from '~/@store/common';
import { IDespatchAdviceSettings, IDumpLoad, useDespatchAdviceSettings } from '~/@store/dumpLoads';
import { DumpLoadPeppolSettingsForm } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm/DumpLoadPeppolSettingsDialog/DumpLoadPeppolSettingsForm';

type Props = {
    dumpLoad: IDumpLoad;
    onClose: () => void;
};

const DumpLoadPeppolSettingsDialog = ({ dumpLoad, onClose }: Props) => {
    const query = useDespatchAdviceSettings(dumpLoad.serialNumber);
    const defaultSettingsQuery = useDespatchAdviceDefaultSettings();

    return (
        <CardDialog
            title={<LocalizableText code={'DespatchAdviceSettingsDialog.title'} />}
            onClose={onClose}
            maxWidth="lg"
            fullWidth>
            <QueryStateHandler
                query={defaultSettingsQuery}
                dataGetter={query => query.data?.despatchAdviceDefaultSettings}>
                {(defaultSettings: IDespatchAdviceDefaultSettings) => (
                    <QueryStateHandler
                        query={query}
                        dataGetter={query => query.data?.despatchAdviceSettingsForDumpLoad}>
                        {(data: IDespatchAdviceSettings[]) => {
                            return (
                                <DumpLoadPeppolSettingsForm
                                    settings={data}
                                    dumpLoad={dumpLoad}
                                    onClose={onClose}
                                    defaultSettings={defaultSettings}
                                />
                            );
                        }}
                    </QueryStateHandler>
                )}
            </QueryStateHandler>
        </CardDialog>
    );
};

export const showDumpLoadPeppolSettingsDialog = (dumpLoad: IDumpLoad) => {
    const ignoredPromise = showCustomDialog({
        render: closeDialog => <DumpLoadPeppolSettingsDialog dumpLoad={dumpLoad} onClose={() => closeDialog(true)} />,
    });
};
