import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import { SubArrowIcon } from '~/@components/Icon';
import { NumberField } from '~/@components/NumberField';
import { Text } from '~/@components/Text';
import {
    ContaminationTypeStore,
    isSubstanceChild,
    isSubstanceParentWithChildren,
    ISubstance,
    ISubstanceChild,
} from '~/@store/substances';
import { commonPrecisions } from '~/config/enum';

type Props = {
    store: ContaminationTypeStore;
    substance: ISubstance | ISubstanceChild;
    readOnly?: boolean;
};

export const SubstanceInput = observer(({ store, substance, readOnly }: Props) => {
    const disabled = readOnly || isSubstanceParentWithChildren(substance);
    const { root, inputWrapper, inputEnabled, icon } = useStyles();

    const value = store.substanceAmountsMap[substance.id] || null;

    const onChange = (amount: number) => {
        store.changeAmount(substance, amount);
    };

    return (
        <div className={root}>
            {isSubstanceChild(substance) && <SubArrowIcon className={icon} />}
            <div className={inputWrapper}>
                <Text>{substance.name}</Text>
                <NumberField
                    className={disabled ? undefined : inputEnabled}
                    value={value}
                    onChange={onChange}
                    precision={commonPrecisions.decimal}
                    fullWidth={false}
                    disabled={disabled}
                />
            </div>
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    icon: {
        margin: theme.spacing(0, 2),
    },
    inputWrapper: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.background.input,
        alignItems: 'center',
    },
    inputEnabled: {
        backgroundColor: theme.palette.background.paper,
    },
}));
