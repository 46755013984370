import './SochiCloseDialogButton.scss';

import { IconButton } from '@material-ui/core';
import React from 'react';

import { getBem } from '~/@sochi-components/@bem';
import { COLOR } from '~/@sochi-components/@theme/styles';
import { CloseCrossIcon } from '~/@sochi-components/Icons';

type SochiCloseDialogButtonProps = {
    onClose: () => void;
    mobileOnly?: boolean;
    className?: string;
};

export class SochiCloseDialogButton extends React.PureComponent<SochiCloseDialogButtonProps> {
    bem = getBem(this);

    render() {
        const { onClose, mobileOnly } = this.props;
        const { block, element } = this.bem;

        return (
            <div className={block({ mobileOnly })}>
                <IconButton onClick={onClose} className={element('button')}>
                    <CloseCrossIcon htmlColor={COLOR.black} />
                </IconButton>
            </div>
        );
    }
}
