import { getDefaultWorkTime } from '@common/constants';
import { validateWorkTime } from '@common/validations/common.validation';
import { FormikConfig } from 'formik';
import * as Yup from 'yup';

import { ICompanySearchRecord } from '~/@store/companies';
import { IDetailedLandfill } from '~/@store/landfills';
import { gqlTypeToLocation } from '~/@store/locations';
import { homePageMapStore } from '~/@user-store/homePageMap';
import { LandfillInput, LocationType, WorkTimeDayInput } from '~/graphql';
import i18n from '~/i18n';

import { LandfillEditFormValues } from './LandfillEdit.types';

const errorsByLocationType: Record<LocationType, string | null> = {
    VALID: null,
    UNKNOWN: i18n.errorCodes.LOCATION_NOT_GEOCODED,
    ON_WATER: i18n.errorCodes.LANDFILL_LOCATION_ON_WATER,
    UNREACHABLE: i18n.errorCodes.LOCATION_UNREACHABLE,
};

export const getLandfillFormikConfig = (
    landfill: IDetailedLandfill | null,
    submit: (input: LandfillInput) => Promise<IDetailedLandfill>,
    onComplete?: (landfill: IDetailedLandfill) => void
): FormikConfig<LandfillEditFormValues> => ({
    initialValues: {
        name: landfill?.name || '',
        comment: landfill?.comment || '',
        description: landfill?.description || '',
        minMargin: landfill?.minMargin || 0,
        marginPercent: landfill?.marginPercent || 0,
        gateFee: landfill?.gateFee || 0,
        location: landfill?.location ? gqlTypeToLocation(landfill.location) : homePageMapStore.defaultLocation,
        status: landfill?.status || null,
        workTime: landfill?.workTime || getDefaultWorkTime(),
        receivers: landfill?.receivers || [],
        customer: landfill?.customer || null,
        capacity: landfill?.capacity || 0,
    },
    validationSchema: Yup.object().shape({
        name: Yup.string().required(),
        location: Yup.object<LandfillEditFormValues['location']>().test(
            'Location validation',
            '',
            function (this, location) {
                const message = location
                    ? errorsByLocationType[location.type]
                    : i18n.errorCodes.LANDFILL_LOCATION_CANNOT_BE_EMPTY;

                if (message) return this.createError({ message, path: 'location' });

                return true;
            }
        ),
        workTime: Yup.array().test('WorkTime validation', '', function (workTime) {
            if (!workTime) return true;
            const errorCode = validateWorkTime([...workTime] as WorkTimeDayInput[]);

            if (errorCode) return this.createError({ message: i18n.errorCodes[errorCode], path: 'workTime' });

            return true;
        }),
        customer: Yup.object<ICompanySearchRecord>().nullable().required(i18n.errorCodes.LANDFILL_CUSTOMER_REQUIRED),
    }),
    onSubmit: async values => {
        const { customer, receivers, status, workTime, ...restValues } = values;

        const input: LandfillInput = {
            ...restValues,
            customerId: customer?.id,
            receiverIds: receivers.map(r => r.id),
            status: status?.id,
            workTime: workTime.map(({ dayNumber, isOpen, openFrom, openTo }) => ({
                dayNumber,
                isOpen,
                openFrom,
                openTo,
            })),
        };

        submit(input).then(result => (onComplete ? onComplete(result) : undefined));
    },
});
