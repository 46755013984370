import './ProjectMapInput.scss';

import { FormikProps } from 'formik';
import React from 'react';

import { MapInput } from '~/@components/MapInput';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { GoogleMapsMouseEvent, homePageMapStore } from '~/@user-store/homePageMap';
import { useUserAbilities } from '~/contexts';
import { canChangeProjectLocation } from '~/utils/auth';

import { FieldName } from '../constants';
import type { ProjectFormProps, ProjectFormValues, ProjectLocationProps } from '../ProjectForm';

export const ProjectMapInput = (props: ProjectFormProps & FormikProps<ProjectFormValues> & ProjectLocationProps) => {
    const abilityContext = useUserAbilities();
    const { project, values, setFieldValue } = props;
    const { className } = useFunctionalBem(ProjectMapInput);

    const updateLocationByLatLng = (arg: GoogleMapsMouseEvent) => {
        const lat = arg.latLng!.lat();
        const lng = arg.latLng!.lng();
        setFieldValue(FieldName.location, { ...values.location, lat, lng });
        props.getByLatLng(lat, lng).then(result => setFieldValue(FieldName.location, result));
    };

    return (
        <MapInput
            location={values.location}
            onClick={updateLocationByLatLng}
            readOnly={props.loading || !!(project && !canChangeProjectLocation(abilityContext, project))}
            className={className}
            zoom={homePageMapStore.currentZoom}
        />
    );
};
