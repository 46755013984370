import { KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { format, isValid, parse } from 'date-fns';
import { isString, uniqueId } from 'lodash';
import React, { useMemo, useState } from 'react';

import { useDisable } from '~/@components/@hooks/use-disable';

import { emptyEqualValue, formatTimeTextInput, isValidInput } from './helpers';

const timeFormat = 'HH:mm';
const mask = '__:__';
const keyboardButtonProps = { disabled: true, style: { display: 'none' } };
const regexp = /^\d{2}:\d{2}$/u;

const enrichInputValue = (date: ParsableDate): ParsableDate =>
    isString(date) && regexp.test(date) ? parse(date, timeFormat, new Date()) : date;

const inputValueToText = (date: ParsableDate) =>
    date instanceof Date && isValid(date) ? format(date, timeFormat) : '';

export type TimeInputProps = Omit<
    KeyboardTimePickerProps,
    'onBlur' | 'onFocus' | 'mask' | 'ampm' | 'inputValue' | 'onChange'
> & {
    onChange: (date: Date | null, input: string) => void;
};

export const TimeInput = ({ disabled, onChange, value: originValue, ...restProps }: TimeInputProps) => {
    const value = useMemo(() => enrichInputValue(originValue), [originValue]);

    const [state, setState] = useState<{ localValue: string; isFocused: boolean; key: string }>({
        localValue: inputValueToText(value),
        isFocused: false,
        key: uniqueId(),
    });

    const handleFocus = () => setState(state => ({ ...state, localValue: inputValueToText(value), isFocused: true }));

    const handleChange = (date: Date | null, input?: string | null) => {
        if (date !== null && isValid(date)) {
            onChange(date, input || emptyEqualValue);
        }

        setState(state => ({ ...state, localValue: input || '' }));
    };

    const handleBlur = () => {
        const enrichedValue = formatTimeTextInput(state.localValue.replaceAll('_', ''));

        if (isValidInput(enrichedValue)) {
            onChange(parse(enrichedValue, timeFormat, new Date()), enrichedValue);
        }

        setState({ isFocused: false, localValue: inputValueToText(value), key: uniqueId() });
    };

    return (
        <KeyboardTimePicker
            key={state.key}
            inputVariant="outlined"
            variant="inline"
            value={value}
            mask={mask}
            ampm={false}
            KeyboardButtonProps={keyboardButtonProps}
            disabled={useDisable() || disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            {...restProps}
        />
    );
};
