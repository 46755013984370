import { makeStyles } from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import React, { VFC } from 'react';

import { COLORS } from '~/@components/@theme/colors';
import { FadeProgress } from '~/@components/FadeProgress';
import { FormikPhoneNumberField, FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';

import { IAccountFormValues } from '../AccountForm/AccountFormik';

export const AccountForm: VFC = () => {
    const { root, input } = useStyles();
    const formik = useFormikContext<IAccountFormValues>();

    return (
        <Form className={root}>
            <FadeProgress show={formik.isSubmitting} />
            <FormikTextField
                label={<LocalizableText code={'AccountPage.account'} />}
                className={input}
                name="accountId"
                formik={formik}
                disabled
                fullWidth
            />
            <FormikTextField
                label={<LocalizableText code={'AccountPage.name'} />}
                className={input}
                name="name"
                formik={formik}
                fullWidth
            />
            <FormikTextField
                label={<LocalizableText code={'AccountPage.surname'} />}
                className={input}
                name="surname"
                formik={formik}
                fullWidth
            />
            <FormikPhoneNumberField
                label={<LocalizableText code={'AccountPage.phone'} />}
                className={input}
                name="phoneNumber"
                formik={formik}
                fullWidth
            />
            <FormikTextField
                label={<LocalizableText code={'AccountPage.email'} />}
                className={input}
                name="email"
                formik={formik}
                disabled
                fullWidth
            />
        </Form>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    input: {
        backgroundColor: COLORS.white,
    },
}));
