import { Button, Divider, Grid } from '@material-ui/core';
import React from 'react';

import i18n from '~/i18n';

import { ISubarea, ISubareaFormik } from './SubareaEdit.types';

type Props = {
    formik: ISubareaFormik;
    subarea: ISubarea | null;
    onClose?: () => void;
};

export const SubareaEditActions = ({ formik, subarea, onClose }: Props) => {
    const disabled = formik.isSubmitting;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3} justify="space-between">
                    <Grid item>
                        <Button onClick={onClose} variant="contained">
                            {i18n.cancel}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={disabled} onClick={formik.submitForm} variant="contained" color="primary">
                            {subarea ? i18n.save : i18n.create}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
