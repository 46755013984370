import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { LandfillStatusInput, LandfillStatusUpdateMutation, LandfillStatusUpdateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../landfillStatuses.queries';

export type LandfillStatusUpdateFunc = (input: LandfillStatusInput) => Promise<void>;

export const useLandfillStatusUpdate = (): [LandfillStatusUpdateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        LandfillStatusUpdateMutation,
        LandfillStatusUpdateMutationVariables
    >(queries.LandfillStatusUpdateMutation);

    const updateFunc = useCallback(
        (input: LandfillStatusInput) => {
            return callMutation({
                variables: { input },
                refetchQueries: [
                    {
                        query: queries.LandfillStatusesListQuery,
                        variables: {},
                    },
                ],
                awaitRefetchQueries: true,
            })
                .then(() => {})
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [updateFunc, mutationResult.loading];
};
