import { AbilityCan, AbilitySubjects } from '@common/enums';
import React, { useMemo, VFC } from 'react';

import { CustomTabs, ICustomTabConfig } from '~/@components/CustomTabs';
import { AccountIconOutlined } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { IAccount } from '~/@store/account';
import { useUserAbilities } from '~/contexts';

import { AccountFormSaveButton } from './AccountForm';
import { LocksTab } from './LocksTab';
import { UserAccountTab } from './UserAccountTab';
import { UserActivityTab } from './UserActivityTab';

type Props = {
    account: IAccount;
};

export const AccountPageTabs: VFC<Props> = ({ account }) => {
    const { ability } = useUserAbilities();
    const canViewLocks = ability.can(AbilityCan.READ, AbilitySubjects.LOCK);

    const tabs = useMemo<ICustomTabConfig[]>(
        () => [
            {
                label: <LocalizableText code={'Admin.account'} />,
                render: () => <UserAccountTab account={account} />,
                rightActionButton: () => <AccountFormSaveButton />,
            },
            { label: <LocalizableText code={'Admin.activity'} />, render: () => <UserActivityTab /> },
            { label: <LocalizableText code={'Admin.locks'} />, render: () => <LocksTab />, hidden: !canViewLocks },
        ],
        [account, canViewLocks]
    );

    return (
        <CustomTabs
            headerIcon={<AccountIconOutlined />}
            tabs={tabs}
            title={<LocalizableText code={'Admin.account'} />}
        />
    );
};
