import DateUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { Router } from 'react-router-dom';

import { LocalizationEditToggleProvider, SupportChatContextProvider } from '~/@store/common';
import { AbilityUserLoaderAndProvider } from '~/contexts/AbilityUserContext/AbilityUserLoaderAndProvider';

import { GlobalDialogContainer } from './@views/UserView/common/GlobalDialogContainer';
import { GlobalLoader } from './@views/UserView/common/GlobalLoader';
import { MessageContainer } from './@views/UserView/common/MessageContainer';
import browserHistory from './browserHistory';
import { getBugsnag } from './bugsnag';
import { DeviceMediaProvider, SettingsProvider } from './contexts';
import { RootRoutes } from './RootRoutes';

const ErrorBoundary = getBugsnag();

// Dummy change to rebuild app 0526

const App = () => {
    return (
        <ErrorBoundary>
            <MessageContainer />
            <AbilityUserLoaderAndProvider>
                <MuiPickersUtilsProvider utils={DateUtils}>
                    <DeviceMediaProvider>
                        <SettingsProvider>
                            <LocalizationEditToggleProvider>
                                <SupportChatContextProvider>
                                    <Router history={browserHistory}>
                                        <RootRoutes />
                                        <GlobalDialogContainer />
                                    </Router>
                                    <GlobalLoader />
                                </SupportChatContextProvider>
                            </LocalizationEditToggleProvider>
                        </SettingsProvider>
                    </DeviceMediaProvider>
                </MuiPickersUtilsProvider>
            </AbilityUserLoaderAndProvider>
        </ErrorBoundary>
    );
};

export default App;
