import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IBeastCreateInput } from '~/@store/beastMaterials';
import { BeastMaterialCreateMutation, BeastMaterialCreateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../beastMaterials.queries';

export type BeastMaterialCreateFunc = (input: IBeastCreateInput) => Promise<void>;

export const useBeastMaterialCreate = (): [BeastMaterialCreateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        BeastMaterialCreateMutation,
        BeastMaterialCreateMutationVariables
    >(queries.BeastMaterialCreateMutation);

    const createFunc: BeastMaterialCreateFunc = useCallback(
        (input: IBeastCreateInput) => {
            return callMutation({
                variables: { input },
                refetchQueries: [
                    {
                        query: queries.BeastMaterialListQuery,
                        variables: {},
                    },
                ],
                awaitRefetchQueries: true,
            })
                .then(() => {})
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [createFunc, mutationResult.loading];
};
