import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/users/users.queries';
import { UserRemoveMutation, UserRemoveMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import { usersStore } from '../users.store';

export type RemoveUserFunc = (id: string, sendNotify: boolean) => Promise<unknown>;

export const useUserDeleteMutation = (): [RemoveUserFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<UserRemoveMutation, UserRemoveMutationVariables>(
        queries.UserRemoveMutation
    );

    const deleteUser = useCallback<RemoveUserFunc>(
        (id: string, sendNotify: boolean) => {
            return callMutation({
                variables: { id, sendNotify },
                refetchQueries: [
                    {
                        query: queries.AdminUsersPagedQuery,
                        variables: usersStore.usersQueryVariables,
                    },
                ],
            }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [deleteUser, mutationResult.loading];
};
