import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { UserUpdate, UserUpdateMutation, UserUpdateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../users.queries';

export type UpdateUserFunc = (input: UserUpdate) => Promise<unknown>;

export const useUserUpdateMutation = (): [UpdateUserFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<UserUpdateMutation, UserUpdateMutationVariables>(
        queries.UserUpdateMutation
    );

    const updateUser = useCallback<UpdateUserFunc>(
        input => {
            return callMutation({
                variables: { input },
            })
                .then(result =>
                    result.data?.userUpdate
                        ? result.data.userUpdate
                        : Promise.reject(new Error('Empty response "UserUpdateMutation"'))
                )
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [updateUser, mutationResult.loading];
};
