import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';

import {
    DateFilter,
    SingleOptionFilter,
    SortSection,
    StringFilter,
    TableHeadCell,
} from '~/@components/Table/TableHeadCell';
import {
    despatchAdviceResponsesStore,
    DespatchAdviceResponsesStoreFilterField,
} from '~/@store/beastIntegration/despatchAdviceResponses.store';
import { DespatchAdviceResponseType, SortInput } from '~/graphql';
import i18n from '~/i18n';

const responseTypeOptions = [
    { id: DespatchAdviceResponseType.ACCEPTED, name: 'Accepted' },
    { id: DespatchAdviceResponseType.DECLINED, name: 'Declined' },
];

const getOption = (type: DespatchAdviceResponseType | null | undefined) => {
    if (!type) return null;

    return responseTypeOptions.find(o => o.id === type);
};

type Props = {
    title: React.ReactNode;
    fieldName: DespatchAdviceResponsesStoreFilterField;
    withFilter?: boolean;
    withSort?: boolean;
    disabled?: boolean;
};

export const DespatchAdviceResponseHeadCell = observer(
    ({ title, fieldName, withFilter, withSort, disabled }: Props) => {
        const sortValue = despatchAdviceResponsesStore.sort.value;
        const setSortValue = useCallback((v: SortInput) => despatchAdviceResponsesStore.sort.setValue(v), []);

        const sortLabels = useMemo(() => {
            switch (fieldName) {
                case 'date':
                    return i18n.SortDate;
                default:
                    return i18n.SortString;
            }
        }, [fieldName]);

        const sortSection = useMemo(() => {
            if (!withSort) return null;

            return <SortSection fieldName={fieldName} sort={sortValue} setSort={setSortValue} labels={sortLabels} />;
        }, [withSort, fieldName, sortValue, setSortValue, sortLabels]);

        const filterSection = useMemo(() => {
            if (!withFilter) return null;

            switch (fieldName) {
                case 'declineReason':
                    return (
                        <StringFilter
                            valueGetter={() => despatchAdviceResponsesStore.filter.values[fieldName]}
                            setValue={v => despatchAdviceResponsesStore.filter.setValue(fieldName, v)}
                        />
                    );
                case 'date':
                    return (
                        <DateFilter
                            valueGetter={() => despatchAdviceResponsesStore.filter.values[fieldName]}
                            setValue={v => despatchAdviceResponsesStore.filter.setValue(fieldName, v)}
                        />
                    );
                case 'responseType':
                    return (
                        <SingleOptionFilter
                            values={responseTypeOptions}
                            valueGetter={() => getOption(despatchAdviceResponsesStore.filter.values[fieldName])}
                            setValue={v => despatchAdviceResponsesStore.filter.setValue(fieldName, v?.id || undefined)}
                        />
                    );

                default:
                    return null;
            }
        }, [withFilter, fieldName]);

        return (
            <TableHeadCell
                title={title}
                sortDirection={sortValue?.field === fieldName ? sortValue.direction : null}
                readonly={disabled}>
                {sortSection}
                {filterSection}
            </TableHeadCell>
        );
    }
);
