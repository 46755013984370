import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/users/users.queries';
import { UserRestoreMutation, UserRestoreMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import { usersStore } from '../users.store';

export type RestoreUserFunc = (id: string) => Promise<unknown>;

export const useUserRestoreMutation = (): [RestoreUserFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<UserRestoreMutation, UserRestoreMutationVariables>(
        queries.UserRestoreMutation
    );

    const deleteUser = useCallback<RestoreUserFunc>(
        (id: string) => {
            return callMutation({
                variables: { id },
                refetchQueries: [
                    {
                        query: queries.AdminUsersPagedQuery,
                        variables: usersStore.usersQueryVariables,
                    },
                ],
            }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [deleteUser, mutationResult.loading];
};
