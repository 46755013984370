import { formatString } from '@common/utils';
import React, { useEffect } from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { useDespatchAdviceResponsesRequest } from '~/@store/beastIntegration/beastIntegration.hooks';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';

export const DespatchAdviceResponseRequestButton = () => {
    const [requestResponses, loading] = useDespatchAdviceResponsesRequest();

    const handleClick = () =>
        requestResponses().then(count => {
            if (count) {
                globalMessage.success(
                    formatString(i18n.DespatchAdviceResponses.numberResponsesReceived, String(count))
                );
            } else {
                globalMessage.info(i18n.DespatchAdviceResponses.noResponsesReceived);
            }
        });

    useEffect(() => {
        handleClick();
    }, []);

    return (
        <ActionButton disabled={loading} onClick={handleClick}>
            {loading ? i18n.loading : i18n.DespatchAdviceResponses.loadNewResponses}
        </ActionButton>
    );
};
