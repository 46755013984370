import './Masses.scss';

import { replaceRouteParams, routes } from '@common/routes';
import React, { useCallback, VFC } from 'react';
import { useHistory } from 'react-router';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { MassCard, MassInfo } from '~/@sochi-components/cards/MassCard';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { LandfillQuery_landfill } from '~/graphql';

type Props = {
    landfill: LandfillQuery_landfill;
    masses: MassInfo[];
    customClassName?: string;
};

export const Masses: VFC<Props> = ({ landfill, masses, customClassName }) => {
    const { className, element } = useFunctionalBem(Masses, customClassName);

    const history = useHistory();

    const onView = useCallback(
        (massSerialNumber: string) => {
            history.push({
                pathname: replaceRouteParams(routes.sochi.landfillDeliveriesForMass, {
                    landfillId: landfill.id,
                    massSerialNumber,
                }),
            });
        },
        [history, landfill]
    );

    return (
        <div className={className}>
            {!!masses.length ? (
                <div className={element('cards')}>
                    {masses.map(el => {
                        return (
                            <MassCard
                                mass={el}
                                key={el.id}
                                action={{
                                    onClick: () => onView(el.serialNumber),
                                    labelCode: 'view',
                                    disabled: !el.deliveredVolume,
                                }}
                            />
                        );
                    })}
                </div>
            ) : (
                <SochiTitle title={<LocalizableText code={'noDataForFilter'} />} />
            )}
        </div>
    );
};
