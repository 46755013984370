import { AbilitySubjects } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { IOrderCalculatedSubject, IOrderRelationRole, IOrderSubject } from './orderTypes';

export const getOrderSubject = (user: IAbilityUser, subject?: IOrderSubject): IOrderCalculatedSubject => {
    const subSubject = subject ? [subject] : [];
    const relationRole: IOrderRelationRole[] = [user.role!];

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.ORDER,
        relationRole,
        subSubject,
    };
};
