import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IBeastUpdateInput } from '~/@store/beastMaterials';
import { BeastMaterialUpdateMutation, BeastMaterialUpdateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../beastMaterials.queries';

export type BeastMaterialUpdateFunc = (input: IBeastUpdateInput) => Promise<void>;

export const useBeastMaterialUpdate = (): [BeastMaterialUpdateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        BeastMaterialUpdateMutation,
        BeastMaterialUpdateMutationVariables
    >(queries.BeastMaterialUpdateMutation);

    const updateFunc: BeastMaterialUpdateFunc = useCallback(
        (input: IBeastUpdateInput) => {
            return callMutation({
                variables: { input },
                refetchQueries: [
                    {
                        query: queries.BeastMaterialListQuery,
                        variables: {},
                    },
                ],
                awaitRefetchQueries: true,
            })
                .then(() => {})
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [updateFunc, mutationResult.loading];
};
