import { ContaminationType } from '@common/enums';
import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import {
    BooleanFilter,
    DateFilter,
    NumberFilter,
    SortSection,
    StringFilter,
    TableHeadCell,
} from '~/@components/Table/TableHeadCell';
import { subareasStore, SubareaStoreFilterFields } from '~/@store/subareas';
import { ContaminationLevelFilter } from '~/@views/AdminView/common/ContaminationLevelFilter';
import { DumpTypeFilter } from '~/@views/AdminView/common/DumpTypeFilter';
import { LandfillStatusFilter } from '~/@views/AdminView/SubareasPage/SubareasHeadCell/LandfillStatusFilter';
import { SortInput } from '~/graphql';
import i18n from '~/i18n';

export interface SubareaHeadCellProps {
    title: React.ReactNode;
    fieldName: SubareaStoreFilterFields;
    withFilter?: boolean;
    withSort?: boolean;
}

export const SubareasHeadCell = (props: SubareaHeadCellProps) => {
    const { title, fieldName, withFilter, withSort } = props;
    const sortValue = useObservable(() => subareasStore.sort.value);
    const setSortValue = useCallback((v: SortInput) => subareasStore.sort.setValue(v), []);

    const sortLabels = useMemo(() => {
        switch (fieldName) {
            case SubareaStoreFilterFields.landfillGateFee:
            case SubareaStoreFilterFields.landfillMarginPercent:
            case SubareaStoreFilterFields.landfillMinMargin:
            case SubareaStoreFilterFields.currentAmount:
            case SubareaStoreFilterFields.priceBase:
            case SubareaStoreFilterFields.priceNotStackable:
            case SubareaStoreFilterFields.priceOver50cm:
                return i18n.SortNumber;
            case SubareaStoreFilterFields.priceUpdatedAt:
                return i18n.SortDate;
            default:
                return i18n.SortString;
        }
    }, [fieldName]);

    const sortSection = useMemo(() => {
        if (!withSort) return null;

        return <SortSection fieldName={fieldName} sort={sortValue} setSort={setSortValue} labels={sortLabels} />;
    }, [withSort, fieldName, sortValue, setSortValue, sortLabels]);

    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        switch (fieldName) {
            case SubareaStoreFilterFields.landfillName:
            case SubareaStoreFilterFields.landfillComment:
            case SubareaStoreFilterFields.landfillLocation:
            case SubareaStoreFilterFields.comment:
            case SubareaStoreFilterFields.materialId:
                return (
                    <StringFilter
                        valueGetter={() => subareasStore.filter.values[fieldName]}
                        setValue={v => subareasStore.filter.setValue(fieldName, v)}
                    />
                );
            case SubareaStoreFilterFields.landfillGateFee:
            case SubareaStoreFilterFields.landfillMarginPercent:
            case SubareaStoreFilterFields.landfillMinMargin:
            case SubareaStoreFilterFields.currentAmount:
            case SubareaStoreFilterFields.priceBase:
            case SubareaStoreFilterFields.priceNotStackable:
            case SubareaStoreFilterFields.priceOver50cm:
                return (
                    <NumberFilter
                        valueGetter={() => subareasStore.filter.values[fieldName]}
                        setValue={v => subareasStore.filter.setValue(fieldName, v)}
                    />
                );
            case SubareaStoreFilterFields.priceUpdatedAt:
                return (
                    <DateFilter
                        valueGetter={() => subareasStore.filter.values[fieldName]}
                        setValue={v => subareasStore.filter.setValue(fieldName, v)}
                    />
                );
            case SubareaStoreFilterFields.over50cm:
            case SubareaStoreFilterFields.nonStackable:
            case SubareaStoreFilterFields.isProvider:
                return (
                    <BooleanFilter
                        valueGetter={() => subareasStore.filter.values[fieldName]}
                        setValue={v => subareasStore.filter.setValue(fieldName, v)}
                    />
                );
            case SubareaStoreFilterFields.dumpType:
                return (
                    <DumpTypeFilter
                        valueGetter={() => subareasStore.filter.values[fieldName]}
                        setValue={v => subareasStore.filter.setValue(fieldName, v)}
                    />
                );
            case SubareaStoreFilterFields.solidTestResult:
                return (
                    <ContaminationLevelFilter
                        valueGetter={() => subareasStore.filter.values[fieldName]}
                        setValue={v => subareasStore.filter.setValue(fieldName, v)}
                        mode={ContaminationType.SOLID}
                    />
                );
            case SubareaStoreFilterFields.landfillStatus:
                return (
                    <LandfillStatusFilter
                        valueGetter={() => subareasStore.filter.values[fieldName]}
                        setValue={v => subareasStore.filter.setValue(fieldName, v)}
                    />
                );
            default:
                return null;
        }
    }, [withFilter, fieldName]);

    return (
        <TableHeadCell title={title} sortDirection={sortValue?.field === fieldName ? sortValue.direction : null}>
            {sortSection}
            {filterSection}
        </TableHeadCell>
    );
};
