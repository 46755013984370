import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import {
    DateFilter,
    MultiOptionFilter,
    SingleOptionFilter,
    SortSection,
    StringFilter,
    TableHeadCell,
} from '~/@components/Table/TableHeadCell';
import { USER_ROLES, USER_STATUSES, UserField, usersStore } from '~/@store/users';
import { SortInput } from '~/graphql';
import i18n from '~/i18n';
import { UserStatusTranslation } from '~/utils/user';

type Props = {
    title: React.ReactNode;
    fieldName: UserField;
    withFilter?: boolean;
    withSort?: boolean;
};

export const UserHeadCell = ({ title, fieldName, withFilter, withSort }: Props) => {
    const sortValue = useObservable(() => usersStore.sort.value);
    const setSortValue = useCallback((v: SortInput) => usersStore.sort.setValue(v), []);

    const sortLabels = useMemo(() => {
        switch (fieldName) {
            case UserField.createdAt:
            case UserField.deletedAt:
            case UserField.lastActivityDate:
                return i18n.SortDate;
            default:
                return i18n.SortString;
        }
    }, [fieldName]);

    const sortSection = useMemo(() => {
        if (!withSort) return null;

        return <SortSection fieldName={fieldName} sort={sortValue} setSort={setSortValue} labels={sortLabels} />;
    }, [withSort, fieldName, sortValue, setSortValue, sortLabels]);

    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        switch (fieldName) {
            case UserField.email:
            case UserField.name:
            case UserField.surname:
            case UserField.phoneNumber:
            case UserField.customerName:
                return (
                    <StringFilter
                        valueGetter={() => usersStore.filter.values[fieldName]}
                        setValue={v => usersStore.filter.setValue(fieldName, v)}
                    />
                );
            case UserField.roles:
                return (
                    <MultiOptionFilter
                        valueGetter={() => usersStore.filter.values[fieldName]}
                        setValues={v => usersStore.filter.setValue(fieldName, v)}
                        values={USER_ROLES.map(role => ({ id: role, name: i18n.UserRole[role] }))}
                        showMassActions
                    />
                );
            case UserField.status:
                return (
                    <SingleOptionFilter
                        valueGetter={() => usersStore.filter.values[fieldName]}
                        setValue={v => usersStore.filter.setValue(fieldName, v)}
                        values={USER_STATUSES.map(status => ({ id: status, name: UserStatusTranslation[status] }))}
                    />
                );
            case UserField.createdAt:
            case UserField.lastActivityDate:
                return (
                    <DateFilter
                        valueGetter={() => usersStore.filter.values[fieldName]}
                        setValue={v => usersStore.filter.setValue(fieldName, v)}
                    />
                );

            default:
                return null;
        }
    }, [withFilter, fieldName]);

    return (
        <TableHeadCell title={title} sortDirection={sortValue?.field === fieldName ? sortValue.direction : null}>
            {sortSection}
            {filterSection}
        </TableHeadCell>
    );
};
