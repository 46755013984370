import '../../common/DeliveriesTable/DeliveriesTable.scss';

import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import type { ProjectQuery_project } from '~/graphql';

import { DeliveriesTable } from '../../common';

type Props = {
    project: ProjectQuery_project;
    title?: React.ReactNode | null;
};

export const WeightReceipt = ({
    project,
    title = <LocalizableText code={'ProjectDocuments.weightReceipt'} />,
}: Props) => {
    return (
        <DeliveriesTable projectId={project.id} dumpLoads={project.dumpLoads} downloadParams={project} title={title} />
    );
};
