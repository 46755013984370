import { FormikContextType } from 'formik';
import React from 'react';

import { FormikAsyncAutocompleteField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { ICompanySearchRecord, useCompaniesSearchFunc } from '~/@store/companies';

type FormikCustomerAsyncSelectProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends ICompanySearchRecord | null ? TFieldName : never;
    formik: FormikContextType<TValues>;
    disabled?: boolean;
    label?: React.ReactNode;
    onlyTransportCompanies?: boolean;
};

export const FormikCustomerAsyncSelect = <TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    disabled,
    onlyTransportCompanies = false,
    label = <LocalizableText code={'customer'} />,
}: FormikCustomerAsyncSelectProps<TValues, TFieldName>) => {
    const customersSearch = useCompaniesSearchFunc(onlyTransportCompanies);

    return (
        <FormikAsyncAutocompleteField
            label={label}
            name={name}
            formik={formik}
            renderOption={(customer: ICompanySearchRecord) => customer?.name}
            loadOptions={customersSearch}
            disabled={formik.isSubmitting || disabled}
        />
    );
};
