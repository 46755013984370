import './SochiLoader.scss';

import classNames from 'classnames';
import React, { FC } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';

type SochiLoaderProps = {
    show: boolean;
    hover?: boolean;
    onlyIcon?: boolean;
};

export const SochiLoader: FC<SochiLoaderProps> = ({ show, hover, children, onlyIcon }) => {
    const { element, className } = useFunctionalBem(SochiLoader);

    if (show && hover)
        return (
            <div className={classNames(className, className + '--hover')}>
                {children}
                <div className={element('hover-panel')}>
                    <div className={element('loader-round')} />
                    <LocalizableText code={'loading'} />
                </div>
            </div>
        );

    if (show)
        return (
            <div className={className}>
                <div className={element('loader-round')} />
                {onlyIcon ? null : <LocalizableText code={'loading'} />}
            </div>
        );

    return children ? <>{children}</> : null;
};
