import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ToxicLimitsIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.0929 3.47903C2.25675 3.12586 2.61069 2.8999 3.00001 2.8999L21 2.8999C21.3893 2.8999 21.7433 3.12586 21.9071 3.47903C22.071 3.83219 22.015 4.24835 21.7636 4.54563L14.8 12.7801L14.8 20.0999C14.8 20.4465 14.6206 20.7683 14.3257 20.9506C14.0309 21.1328 13.6628 21.1493 13.3528 20.9943L9.7528 19.1943C9.41402 19.0249 9.20001 18.6787 9.20001 18.2999L9.20001 12.78L2.23644 4.54563C1.98505 4.24835 1.92904 3.83219 2.0929 3.47903ZM5.15532 4.8999L10.9636 11.7682C11.1162 11.9487 11.2 12.1775 11.2 12.4139V17.6819L12.8 18.4819V12.4139C12.8 12.1775 12.8838 11.9487 13.0364 11.7682L18.8447 4.8999L5.15532 4.8999Z"
                fill={props.fill || 'white'}
            />
        </SvgIcon>
    );
};
