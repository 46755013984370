import { action, computed, observable } from 'mobx';

import { ConnectionInput } from '~/graphql';

export type IGqlConnection = {
    pageInfo: {
        currentPage: number;
    };
    totalCount: number;
};

function calculateNumbersOfPages(currentPage: number, pageCount: number): Array<number | null> {
    const arr: Array<number | null> = [];

    if (!currentPage || !pageCount) return arr;

    const lenToFirst = currentPage - 1;
    const lenToLast = pageCount - currentPage;

    arr.push(currentPage);

    if (lenToFirst > 1) arr.unshift(currentPage - 1);
    if (lenToFirst > 2) arr.unshift(null);
    if (lenToFirst > 0) arr.unshift(1);

    if (lenToLast > 1) arr.push(currentPage + 1);
    if (lenToLast > 2) arr.push(null);
    if (lenToLast > 0) arr.push(pageCount);

    return arr;
}

export class PaginationStore {
    ROWS_PER_PAGE_OPTIONS = [20, 50, 100];

    @observable
    totalCount: number = 0;

    @action
    setTotal(total: number) {
        this.totalCount = total;
    }

    @action
    onQueryComplete({ totalCount, pageInfo }: IGqlConnection) {
        this.totalCount = totalCount;
        this.page = pageInfo.currentPage;
    }

    @observable
    rowsPerPage: number = this.ROWS_PER_PAGE_OPTIONS[0]!;

    @action
    setRowsPerPage(rows: number) {
        this.rowsPerPage = rows;
        this.page = 1;
    }

    @observable
    page: number = 1;

    @action
    setPage(n: number) {
        this.page = n;
    }

    onClear = () => {
        this.setPage(1);
    };

    @computed
    get pageCount(): number {
        return Math.ceil(this.totalCount / this.rowsPerPage);
    }

    @computed
    get connection(): ConnectionInput {
        const page = this.page;
        const first = this.rowsPerPage;

        return { page, first };
    }

    @computed
    get numbersOfPages() {
        return calculateNumbersOfPages(this.page, this.pageCount);
    }
}
