import { FilterRange, FilterSearchParams } from '@common/types';
import { isNullOrUndefined } from '@common/validations/types.validation';
import { isBoolean, isNumber, isObject, isString } from 'lodash';

import { OptionValue } from '~/@components/Table/TableHeadCell';
import i18n from '~/i18n';
import { formatDate } from '~/utils/date';

const maxOptionsToDisplay = 3;

function isOptionValue(v: unknown): v is OptionValue {
    return isObject(v) && isString((v as Partial<OptionValue>).name) && isString((v as Partial<OptionValue>).id);
}

function isArrayOfOptionValue(v: unknown): v is Array<OptionValue> {
    return Array.isArray(v) && v.length > 0 && v.every(isOptionValue);
}

function isFilterSearchParams(v: unknown): v is FilterSearchParams<unknown> {
    return isObject(v) && v.hasOwnProperty('includeEmpty');
}

export type IFormatFilterValue =
    | string
    | Boolean
    | Date
    | number
    | { name: string }
    | FilterRange<Date>
    | FilterRange<number>
    | Array<OptionValue>
    | FilterSearchParams<string>;

export const formatFilterValue = (v: IFormatFilterValue): string => {
    if (isArrayOfOptionValue(v)) {
        if (v.length <= maxOptionsToDisplay) return v.map(item => (item as OptionValue).name).join(', ');

        return `${v.length} ${i18n.OptionFilter.selected}`;
    } else if (Array.isArray(v)) {
        return `${isNullOrUndefined(v[0]) ? '...' : formatFilterValue(v[0])} - ${
            isNullOrUndefined(v[1]) ? '...' : formatFilterValue(v[1])
        }`;
    } else if (isFilterSearchParams(v)) {
        return `${formatFilterValue(v.value || '')}${
            v.includeEmpty ? ` ${i18n.StringFilter.includeEmptyPostfix}` : ''
        }`;
    } else if (isString(v)) {
        return v;
    } else if (isNumber(v)) {
        return String(v);
    } else if (v instanceof Date) {
        return formatDate(v);
    } else if (isBoolean(v)) {
        return v ? i18n.yes : i18n.no;
    } else if ('name' in v) {
        return v.name;
    } else {
        return '';
    }
};
