import { QueryResult } from '@apollo/client/react/types/types';
import { I18nKeys } from '@common/i18n/types';
import { LinearProgress } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement, useMemo } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { getErrorMessages } from '~/utils';

type Size = 'small' | 'big' | 'linear';

type Props<TQuery, TData> = {
    query: QueryResult<TQuery>;
    dataGetter: (q: QueryResult<TQuery>) => TData | undefined | null;
    children: (data: TData) => ReactElement;
    progressSize?: Size;
    emptyContentMessage?: I18nKeys;
};

export const ProgressElement = ({ size }: { size: Size }) => {
    const { circularProgressContainer } = useStyles();

    switch (size) {
        case 'big':
        case 'small':
            return (
                <div className={circularProgressContainer}>
                    <CircularProgress size={size === 'big' ? 120 : 40} />
                </div>
            );
        case 'linear':
            return <LinearProgress />;
        default:
            return null;
    }
};

export const QueryStateHandler = <TQuery, TData>({
    children,
    dataGetter,
    query,
    progressSize = 'big',
    emptyContentMessage = 'noDataToDisplay',
}: Props<TQuery, TData>) => {
    const { errorWrapper } = useStyles();
    const { error, loading } = query;

    const data = dataGetter(query);

    const content = useMemo(() => {
        return data ? children(data) : null;
    }, [data, children]);

    const { linearProgress } = useStyles();

    if (error)
        return (
            <div className={errorWrapper}>
                <Text variant="BodyBold" color="error">
                    {getErrorMessages(error)[0]?.text}
                </Text>
            </div>
        );

    if (query.data && !data)
        return (
            <div className={errorWrapper}>
                <Text variant="BodyBold" color="error">
                    <LocalizableText code={emptyContentMessage} />
                </Text>
            </div>
        );

    if (!query.data) return <ProgressElement size={progressSize} />;

    return (
        <>
            {loading && <LinearProgress color="secondary" className={linearProgress} />}
            {content}
        </>
    );
};

export const useStyles = makeStyles(() => ({
    errorWrapper: {
        padding: 16,
    },
    linearProgress: {
        marginTop: -4,
    },
    circularProgressContainer: {
        display: 'flex',
        height: '100%',
        maxHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
