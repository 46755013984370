import { gql } from '@apollo/client';

import { SubstanceFragment } from '~/@store/substances/substances.queries';

export const ToxicLimitFragment = gql`
    fragment ToxicLimitFragment on ToxicLimit {
        substanceId
        limits {
            levelName
            value
        }
    }
`;

export const ToxicSumRuleFragment = gql`
    fragment ToxicSumRuleFragment on ToxicSumRule {
        id
        name
        faSumLevel
        substances {
            substanceId
            threshold
        }
    }
`;

export const ToxicLimitsQuery = gql`
    query ToxicLimitsQuery {
        substances {
            ...SubstanceFragment
        }

        toxicLimitsValues {
            SOLID {
                ...ToxicLimitFragment
            }
            LEACHING {
                ...ToxicLimitFragment
            }
        }

        toxicSumRules {
            ...ToxicSumRuleFragment
        }
    }

    ${SubstanceFragment}
    ${ToxicLimitFragment}
    ${ToxicSumRuleFragment}
`;

export const UpdateToxicLimitMutation = gql`
    mutation UpdateToxicLimitMutation(
        $contaminationType: ContaminationType!
        $substanceId: ID!
        $levelName: ContaminationLevel!
        $value: Float
    ) {
        changeLimitValue(
            contaminationType: $contaminationType
            substanceId: $substanceId
            levelName: $levelName
            value: $value
        )
    }
`;

export const CreateSumRuleMutation = gql`
    mutation CreateSumRuleMutation($input: ToxicSumRuleInput!) {
        createSumRule(input: $input) {
            ...ToxicSumRuleFragment
        }
    }

    ${ToxicSumRuleFragment}
`;

export const UpdateSumRuleMutation = gql`
    mutation UpdateSumRuleMutation($id: ID!, $input: ToxicSumRuleInput!) {
        updateSumRule(id: $id, input: $input) {
            ...ToxicSumRuleFragment
        }
    }

    ${ToxicSumRuleFragment}
`;

export const RemoveSumRuleMutation = gql`
    mutation RemoveSumRuleMutation($id: ID!) {
        removeSumRule(id: $id)
    }
`;
