import { Formik } from 'formik';
import React, { VFC } from 'react';

import { useAccountUpdateMutation } from '~/@store/account';
import { useUserAbilities } from '~/contexts';

import { getAccountFormikConfig } from './AccountForm/AccountFormik';
import { AccountPageTabs } from './AccountPageTabs';

export const AccountPage: VFC = () => {
    const { user } = useUserAbilities();
    const [saveAccount] = useAccountUpdateMutation();

    return (
        <Formik {...getAccountFormikConfig(user, saveAccount)}>
            <AccountPageTabs account={user} />
        </Formik>
    );
};
