import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { RemoveSumRuleMutation, RemoveSumRuleMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../toxicLimits.queries';

type ToxicSumRuleRemoveFunc = () => Promise<unknown>;

export const useToxicSumRuleRemove = (id: string): [ToxicSumRuleRemoveFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<RemoveSumRuleMutation, RemoveSumRuleMutationVariables>(
        queries.RemoveSumRuleMutation,
        {
            refetchQueries: [
                {
                    query: queries.ToxicLimitsQuery,
                },
            ],
        }
    );

    const remove: ToxicSumRuleRemoveFunc = useCallback(
        () => callMutation({ variables: { id } }).catch(handleApolloError),
        [callMutation]
    );

    return [remove, mutationResult.loading];
};
