import { createMuiTheme } from '@material-ui/core/styles';
import { Palette } from '@material-ui/core/styles/createPalette';

import { COLORS } from './colors';

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        customFont: {
            regular: object;
            bold: object;
            select: object;
            action: object;
        };

        size: {
            rowHeight: number;
            lineHeight: number;
        };

        customShadows: {
            1: string;
            2: string;
        };

        customSettings: {
            autocomplete: {
                popper: {
                    zIndex: number;
                    gap: number;
                };
                paper: {
                    border: string;
                    shadow: string;
                };
            };
        };
    }

    export interface ThemeOptions {
        customFont?: {
            regular?: object;
            bold?: object;
            select?: object;
            action?: object;
        };

        size?: {
            rowHeight?: number;
            lineHeight?: number;
        };

        customShadows?: {
            1?: string;
            2?: string;
        };

        customSettings?: {
            autocomplete?: {
                popper?: {
                    zIndex?: number;
                    gap?: number;
                };
                paper: {
                    border?: string;
                    shadow?: string;
                };
            };
        };
    }
}

declare module '@material-ui/core/styles/createPalette' {
    interface CommonColors {
        yellow: string;
        green: string;
        blue: string;
        gray: string;
    }

    interface Palette {
        inputBorder: string;
        tableBorder: string;
        sectionBorder: string;
        footerTextColor: string;
        greyMarker: string;
    }

    interface PaletteOptions {
        inputBorder?: string;
        tableBorder?: string;
        sectionBorder?: string;
        footerTextColor?: string;
        greyMarker?: string;
    }

    interface TypeBackground {
        input: string;
    }
}

const theme = createMuiTheme();

const palette: Palette = {
    ...theme.palette,
    text: {
        ...theme.palette.text,
        primary: COLORS.textBody,
        secondary: COLORS.grayDark,
    },
    action: {
        ...theme.palette.action,
        hover: COLORS.grayLightest,
    },
    background: {
        input: COLORS.grayLighter,
        default: '#F0F0F0',
        paper: COLORS.white,
    },
    primary: {
        ...theme.palette.primary,
        light: COLORS.hoverDark,
        main: COLORS.brandMain,
        contrastText: COLORS.white,
    },
    secondary: {
        ...theme.palette.secondary,
        main: COLORS.brandRed,
    },
    success: {
        ...theme.palette.success,
        main: COLORS.green,
    },
    inputBorder: COLORS.gray,
    tableBorder: COLORS.grayLighter,
    sectionBorder: COLORS.grayLight,
    footerTextColor: '#BDC3EE',
    greyMarker: '#BDBDBD',
    common: {
        ...theme.palette.common,
        yellow: COLORS.yellow,
        green: COLORS.green,
        blue: COLORS.blue,
        gray: COLORS.gray,
    },
};

const font = {
    regular: {
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: 400,
    },
    bold: {
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: 600,
    },
    smallTextBold: {
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: 700,
    },
    title: {
        fontFamily: "'Inter', sans-serif",
        fontWeight: 700,
    },
};

const size = {
    rowHeight: 48,
    lineHeight: 32,
};

export const lightTheme = createMuiTheme({
    palette,
    size,
    typography: {
        fontFamily: "'Source Sans Pro', sans-serif",
        h1: {
            fontSize: 24,
        },
        h2: {
            ...font.bold,
            fontSize: 20,
        },
        h3: {
            fontSize: 17,
        },
        h4: {
            fontSize: 16,
        },
        h5: {
            ...font.bold,
            fontSize: 16,
        },
        h6: {
            ...font.title,
            fontSize: 15.84,
        },
        subtitle1: {
            ...font.bold,
            fontSize: 14,
        },
        subtitle2: {
            fontSize: 13,
        },
        body1: {
            fontSize: 14,
        },
        body2: {
            fontSize: 14,
        },
        caption: {
            fontSize: 11,
        },
        button: {
            fontSize: 12,
        },
        overline: {
            fontSize: 16,
        },
    },
    overrides: {
        MuiButton: {
            textSizeLarge: {
                ...font.regular,
                fontSize: 16,
            },
            root: {
                ...font.bold,
                fontSize: 14,
                textTransform: 'none',
                height: 40,
            },
            contained: {
                boxShadow: 'none',
                '&.Mui-disabled': {
                    backgroundColor: COLORS.gray,
                },
            },
        },
        MuiChip: {
            root: {
                ...font.smallTextBold,
                borderRadius: 4,
                fontSize: 12,
                height: 24,
            },
        },
        MuiDialog: {
            paper: {
                backgroundColor: COLORS.transparent,
            },
        },
        MuiDialogActions: {
            root: {
                backgroundColor: palette.background.paper,
            },
        },
        MuiDialogContent: {
            root: {
                backgroundColor: palette.background.paper,
                padding: theme.spacing(4, 3, 3),
            },
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: palette.primary.main,
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
                color: palette.primary.contrastText,
            },
        },
        MuiIconButton: {
            root: {
                fontSize: 12,
                height: 32,
                width: 32,
                borderRadius: 4,
                color: palette.text.secondary,
                '&:hover': {
                    color: palette.text.primary,
                },
            },
            sizeSmall: {
                fontSize: 8,
                height: 24,
                width: 24,
            },
        },
        MuiInputBase: {
            input: {
                fontSize: 14,
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(20px, 13px) scale(1)',
            },
        },
        MuiOutlinedInput: {
            input: {
                boxSizing: 'border-box',
                minHeight: 40,
                lineHeight: '23px',
                padding: '8.01px 14px',
                '& > div': {
                    paddingTop: 1,
                },
                '& > .MuiListItemText-root': {
                    marginTop: 1,
                    marginBottom: 1,
                },
            },
            notchedOutline: {
                borderColor: palette.inputBorder,
            },
        },
        MuiSelect: {
            iconOutlined: {
                right: 16,
            },
            selectMenu: {
                minHeight: 'unset',
            },
            select: {
                '&:focus': {
                    backgroundColor: 'inherit',
                },
            },
        },
        MuiSvgIcon: {
            fontSizeSmall: {
                fontSize: 20,
            },
            root: {
                fontSize: 24,
            },
        },
        MuiTableCell: {
            body: {
                fontSize: 14,
            },
            head: {
                ...font.bold,
                color: 'rgba(0, 0, 0, 0.5)',
                height: size.rowHeight,
                fontSize: 14,
                lineHeight: 1.29,
            },
            root: {
                borderBottom: `1px solid ${palette.tableBorder}`,
            },
            stickyHeader: {
                backgroundColor: palette.background.paper,
            },
        },
        MuiTablePagination: {
            root: {
                color: COLORS.grayDarker,
            },
            toolbar: {
                minHeight: size.rowHeight,
            },
            select: {
                ...font.bold,
                color: 'inherit',
                lineHeight: 1.43,
                paddingTop: 6,
                paddingBottom: 6,
                borderRadius: 4,
                '&.MuiSelect-select': {
                    paddingRight: 28,
                },
                '&:hover': { backgroundColor: COLORS.grayLight },
                '&[aria-expanded="true"]': { backgroundColor: COLORS.grayLight },
                '&:focus': {
                    borderRadius: 4,
                },
            },
            selectIcon: {
                top: 'calc(50% - 10px)',
                right: 6,
                color: 'inherit',
            },
            menuItem: {
                ...font.bold,
                color: COLORS.grayDarker,
                height: 32,
                padding: '6px 17px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                    backgroundColor: palette.primary.light,
                    color: palette.primary.contrastText,
                },
                '&.Mui-selected': {
                    backgroundColor: palette.background.input,
                    color: COLORS.grayDarker,
                },
            },
        },
        MuiTableRow: {
            root: {
                '& > th': {
                    backgroundColor: palette.background.paper,
                    borderRight: `1px solid ${palette.tableBorder}`,
                },
                '&:nth-of-type(odd)': {
                    backgroundColor: palette.action.hover,
                    '& > th': {
                        backgroundColor: palette.action.hover,
                        borderRight: `1px solid ${palette.tableBorder}`,
                    },
                },
            },
            head: {
                '& > th': {
                    backgroundColor: palette.background.paper,
                    borderRight: `none`,
                },
                '&:nth-of-type(odd)': {
                    backgroundColor: palette.background.paper,
                    '& > th': {
                        backgroundColor: palette.background.paper,
                        borderRight: `none`,
                    },
                },
            },
        },
        MuiToolbar: {
            gutters: {
                [theme.breakpoints.up('md')]: {
                    paddingLeft: 37,
                    paddingRight: 48,
                },
            },
            regular: {
                [theme.breakpoints.up('md')]: {
                    minHeight: 60,
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                ...font.regular,
                fontSize: 12,
            },
        },
        MuiMenuItem: {
            root: {
                color: palette.primary.main,
                height: 48,
                '&:hover': { backgroundColor: COLORS.grayLight },
            },
        },
        MuiLink: {
            root: {
                '&:hover': {
                    color: palette.primary.main,
                },
            },
        },
        MuiCard: {
            root: {
                border: `1px solid ${COLORS.gray}`,
                borderRadius: 4,
            },
        },
        MuiCardContent: {
            root: {
                padding: theme.spacing(2),
                '&:last-child': {
                    paddingBottom: theme.spacing(2),
                },
            },
        },
        MuiRadio: {
            root: {
                // Fix safari glitch https://github.com/mui/material-ui/issues/19846
                '& svg:first-child': { transform: 'scale(1)' },
            },
            colorSecondary: {
                '&$checked': {
                    color: COLORS.blue,
                },
            },
        },
        MuiCheckbox: {
            root: {
                '&:not(.MuiCheckbox-colorSecondary):not(.MuiCheckbox-colorPrimary)': {
                    '&$checked': {
                        color: palette.common.blue,
                    },
                },
            },
        },
        MuiTypography: {
            gutterBottom: {
                marginBottom: '1em',
            },
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true, // No more ripple, on the whole application
        },
        MuiButtonGroup: {
            disableRipple: true, // No more ripple, on the whole application
        },
        MuiCard: {
            elevation: 0,
        },
        MuiCheckbox: {
            color: 'default',
        },
        MuiTextField: {
            variant: 'outlined',
        },
        MuiFormControl: {
            variant: 'outlined',
        },
    },
    customFont: {
        regular: {
            ...font.regular,
        },
        bold: {
            ...font.bold,
        },
        select: {
            ...font.regular,
            fontSize: 16,
        },
        action: {
            ...font.bold,
            fontSize: 16,
        },
    },
    customShadows: {
        1: '0px 2px 4px rgba(0, 0, 0, 0.24)',
        2: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    customSettings: {
        autocomplete: {
            popper: {
                zIndex: 1501,
                gap: theme.spacing(1),
            },
            paper: {
                border: `1px solid ${theme.palette.inputBorder}`,
                shadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
            },
        },
    },
});
