import { action, observable } from 'mobx';

import { SortInput } from '~/graphql';

export class SortStore {
    constructor(private readonly initialSort: SortInput, private readonly onChangeCallback: () => void = () => {}) {
        this.value = initialSort;
    }

    @observable
    value: SortInput;

    @action
    setValue(v: SortInput) {
        this.value = v;
        this.onChangeCallback();
    }

    @action
    clearSort() {
        this.value = this.initialSort;
        this.onChangeCallback();
    }
}
