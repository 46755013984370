import { Card, CardActions, CardContent, LinearProgress } from '@material-ui/core';
import React, { FC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { DeleteButton } from '~/@components/DeleteButton';
import { DialogActionButton } from '~/@components/Dialog';
import { Text } from '~/@components/Text';
import i18n from '~/i18n';

type FormCardProps = {
    className?: string;
    title?: React.ReactNode;
    subTitle?: string;
    onClose: () => void;
    onSubmit: () => void;
    onDelete?: () => void;
    cancelLabel?: string;
    submitLabel?: string;
    isDeleteDisabled?: boolean;
    isSubmitDisabled?: boolean;
    isCloseDisabled?: boolean;
    showProgress?: boolean;
};

export const FormCard: FC<FormCardProps> = ({
    className = '',
    title = '',
    subTitle = '',
    onClose,
    onSubmit,
    onDelete,
    cancelLabel = i18n.cancel,
    submitLabel = i18n.save,
    children,
    isDeleteDisabled = false,
    isSubmitDisabled = false,
    isCloseDisabled = false,
    showProgress = false,
}) => {
    const { header, subHeader, content, actions, deleteAction } = useStyles();

    return (
        <form onSubmit={onSubmit} className={className}>
            <Card elevation={0}>
                <CardContent className={content}>
                    {showProgress && <LinearProgress color="primary" />}
                    <div className={header}>
                        <Text variant="TitleBold">{title}</Text>
                        {onDelete && (
                            <DeleteButton onClick={onDelete} disabled={isDeleteDisabled} className={deleteAction} />
                        )}
                    </div>
                    {subTitle && (
                        <div className={subHeader}>
                            <Text variant="SmallText" color="textSecondary">
                                {subTitle}
                            </Text>
                        </div>
                    )}
                    {children}
                </CardContent>
                <CardActions className={actions}>
                    <DialogActionButton onClick={onClose} disabled={isCloseDisabled} variant="outlined" color="default">
                        {cancelLabel}
                    </DialogActionButton>
                    <DialogActionButton type="submit" color="primary" disabled={isSubmitDisabled}>
                        {submitLabel}
                    </DialogActionButton>
                </CardActions>
            </Card>
        </form>
    );
};

const useStyles = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(4, 2, 2),
    },
    header: {
        marginBottom: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
    },
    subHeader: {
        marginBottom: theme.spacing(2),
    },
    deleteAction: {
        marginLeft: 'auto',
        padding: 0,
    },
}));
