import { IconButton } from '@material-ui/core';
import React, { FC, useEffect } from 'react';

import { useObservable } from '~/@components/@hooks';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { FilePreview } from '~/@sochi-components/FilePreview';
import { BinIcon, EditIcon } from '~/@sochi-components/Icons';
import { ISochiTableColumn, SochiTable } from '~/@sochi-components/SochiTable';
import {
    LandfillDeliveryEventsFilterField,
    landfillDeliveryEventsStore,
} from '~/@user-store/landfillDeliveryEvents/landfillDeliveryEvents.store';
import { useAttachDeliveryEvent } from '~/@user-store/landfillDeliveryEvents/useAttachDeliveryEvent';
import { IDeliveryEvent } from '~/@user-store/landfillDeliveryEvents/useDeliveryEvents';
import { useRemoveDeliveryEvent } from '~/@user-store/landfillDeliveryEvents/useRemoveDeliveryEvent';
import { LandfillDeliveryEventsTableHeadCell } from '~/@views/UserView/LandfillPage/LandfillDeviationsTab/LandfillDeliveryEvents/LandfillDeliveryEventsTableHeadCell';
import { SelectMatchedLineDialog } from '~/@views/UserView/LandfillPage/LandfillDeviationsTab/LandfillDeliveryEvents/SelectMatchedLineDialog';
import { getContentUrl } from '~/apolloClient';
import { LandfillQuery_landfill } from '~/graphql';
import i18n from '~/i18n';
import { showConfirmDialog, showCustomDialog } from '~/services/dialog';
import { globalMessage } from '~/services/message';
import { formatDateTimeShort } from '~/utils/date';

type Props = {
    landfill: LandfillQuery_landfill;
    events: IDeliveryEvent[];
};

export const LandfillDeliveryEventsTable: FC<Props> = ({ landfill, events }) => {
    useEffect(() => {
        landfillDeliveryEventsStore.setList(events);
    }, [events]);

    const filteredEvents: IDeliveryEvent[] = useObservable(() => landfillDeliveryEventsStore.filteredList);

    const [attach, isAttaching] = useAttachDeliveryEvent(landfill.id);
    const [remove, isRemoving] = useRemoveDeliveryEvent(landfill.id);
    const isLoading = isAttaching || isRemoving;

    const onAttach = async (row: IDeliveryEvent) => {
        const lineId = await showCustomDialog<string | null>({
            title: <LocalizableText code="LandfillDeliveryEvents.selectDeliveryLine" />,
            render: closeDialog => <SelectMatchedLineDialog eventId={row.id} closeDialog={closeDialog} />,
            forceCrossClose: true,
        });

        if (!lineId) return;

        attach(row.id, lineId).then(() => globalMessage.success(i18n.LandfillDeliveryEvents.successAttach));
    };

    const onRemove = async (row: IDeliveryEvent) => {
        const confirmed = await showConfirmDialog({
            title: i18n.delete,
            message: (
                <>
                    <Text paragraph>
                        <LocalizableText code="LandfillDeliveryEvents.deleteConfirm" />
                    </Text>
                    <Text paragraph variant="TitleBold">
                        {formatDateTimeShort(row.date)}
                    </Text>
                </>
            ),
            okLabel: i18n.delete,
        });

        if (!confirmed) return;

        remove(row.id).then(() => globalMessage.info(i18n.LandfillDeliveryEvents.successDelete));
    };

    const columns: ISochiTableColumn<IDeliveryEvent>[] = [
        {
            title: (
                <LandfillDeliveryEventsTableHeadCell
                    title={<LocalizableText code="LandfillDeliveryEvents.date" />}
                    fieldName={LandfillDeliveryEventsFilterField.date}
                    withFilter
                />
            ),
            render: row => formatDateTimeShort(row.date),
            alignLeft: true,
        },
        {
            title: (
                <LandfillDeliveryEventsTableHeadCell
                    title={<LocalizableText code="LandfillDeliveryEvents.licensePlate" />}
                    fieldName={LandfillDeliveryEventsFilterField.licensePlate}
                    withFilter
                />
            ),
            render: row => row.licensePlate.toUpperCase(),
        },
        {
            title: <LocalizableText code="LandfillDeliveryEvents.photo" />,
            render: row => <FilePreview url={getContentUrl(row.photoUrl)} filename={row.photoUrl} />,
        },
        {
            title: '',
            render: row => (
                <>
                    <IconButton onClick={() => onAttach(row)} disabled={isLoading}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onRemove(row)} disabled={isLoading}>
                        <BinIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    return <SochiTable items={filteredEvents} columns={columns} keyGetter={r => r.id} />;
};
