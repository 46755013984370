import './MassSchedules.scss';

import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { DeliveryChart } from '~/@sochi-components/charts/DeliveryChart';
import { GranularitySwitchValue } from '~/@sochi-components/SochiGranularitySwitch';
import type { ProjectQuery_project_dumpLoads } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    scheduleViewFilter: GranularitySwitchValue;
    massData: ProjectQuery_project_dumpLoads;
};

export const MassSchedules = ({ scheduleViewFilter, massData }: Props) => {
    const { className, element } = useFunctionalBem(MassSchedules);

    const totalTrips = massData.totalTrips || i18n.NA;
    const transportedTo = massData.destinationLandfill?.name || '';
    const transportationCount = massData.deliveryLineCount;
    const volumes = {
        weeks: massData.volumesPerPeriods.weeks.map(w => ({ ...w, month: 0 })),
        months: massData.volumesPerPeriods.months.map(w => ({ ...w, week: 0 })),
        years: massData.volumesPerPeriods.years.map(w => ({ ...w, month: 0, week: 0 })),
    };

    return (
        <div className={className}>
            <div className={element('info')}>
                <div className={element('info-item')}>
                    <div className={element('info-item__title')}>
                        <LocalizableText code={'massDetails.transportedTo'} />
                    </div>
                    {transportedTo}
                </div>
                <div className={element('info-item')}>
                    <div className={element('info-item__title')}>
                        <LocalizableText code={'massDetails.truckTransported'} />
                    </div>
                    {transportationCount} of {totalTrips}
                </div>
                <div className={element('info-item')}>
                    <div className={element('info-item__title')}>
                        <LocalizableText code={'massDetails.declarationNumber'} />
                    </div>
                    {massData.serialNumber}
                </div>
            </div>
            <DeliveryChart elements={volumes} scheduleViewFilter={scheduleViewFilter} />
        </div>
    );
};
