import './DocumentCard.scss';

import { isFunction } from 'lodash';
import React from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';

import { StatusColorClasses } from '../../../../../common/constants';
import i18n from '../../../i18n';

type DocumentCardProps = {
    status?: string;
    title?: string | null;
    number?: string | null;
    info?: string | null;
    name?: string;
    addon?: string | null;
    onClick: () => void;
    onDelete?: () => void;
    viewButtonTitle?: React.ReactNode;
};

export const DocumentCard = ({
    status = '',
    title,
    number,
    info,
    name,
    addon,
    viewButtonTitle = <LocalizableText code={'ProjectDocuments.buttons.view'} />,
    onClick,
    onDelete,
}: DocumentCardProps) => {
    const { element, className } = useFunctionalBem(DocumentCard);

    const statusClassName = String(StatusColorClasses[status]);
    const hasDeleteButton = isFunction(onDelete);

    return (
        <div className={className}>
            <div
                className={element('status', {
                    [statusClassName]: true,
                })}>
                {title || i18n.DumploadStatusesSochi[status as keyof typeof i18n.DumploadStatusesSochi]}
            </div>
            <div className={element('number')} title={number || ''}>
                {number}
            </div>
            <div className={element('info')} title={info || ''}>
                {info}
            </div>
            <div className={element('name')} title={name || ''}>
                {name}
            </div>
            {!hasDeleteButton && (
                <div className={element('addon')} title={addon || ''}>
                    {addon}
                </div>
            )}
            <div className={element('buttons')}>
                <Button onClick={onClick} color="primary" variant="outlined">
                    {viewButtonTitle}
                </Button>
                {hasDeleteButton && (
                    <Button onClick={onDelete} color="warning" variant="contained">
                        <LocalizableText code={'ProjectDocuments.buttons.delete'} />
                    </Button>
                )}
            </div>
        </div>
    );
};
