import { AbilityBuilder } from '@casl/ability';
import { useMemo } from 'react';

import {
    defineAbilitiesForProjectDumpLoad,
    IProjectDumpLoadCalculatedSubject,
} from '../../../../common/abilities/projects';
import { CommonRenderRule, formatCondition, mapCommonPart, TypedConditionsRule } from './common';

type DumpLoadRule = TypedConditionsRule<IProjectDumpLoadCalculatedSubject>;

type DumpLoadRenderRule = CommonRenderRule & {
    projectSubject: string;
    dumpLoadSubject: string;
};

export const useDumpLoadRules = (): DumpLoadRenderRule[] =>
    useMemo(() => {
        const { rules, can, cannot } = AbilityBuilder.extract();

        defineAbilitiesForProjectDumpLoad(can, cannot);

        return (rules as DumpLoadRule[]).map(r => ({
            ...mapCommonPart(r),
            projectSubject: formatCondition(r.conditions?.projectSubject),
            dumpLoadSubject: formatCondition(r.conditions?.dumpLoadSubject),
        }));
    }, []);
