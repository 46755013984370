import './Error.scss';

import { ApolloError } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';

import i18n from '../../../i18n';
import { getErrorMessages } from '../../../utils';

type IGenericError = { message?: string };

export type IError = string | ApolloError | IGenericError;

const getErrorMessage = (error: IError) => {
    if (typeof error === 'string') return error;
    if (error instanceof ApolloError) return getErrorMessages(error)[0]?.text;
    if (error.message) return error.message;

    return '';
};

export type IErrorProps = {
    error: IError;
    onRetry?: (variables?: Partial<unknown>) => void | Promise<unknown>;
};

export const Error = ({ error, onRetry }: IErrorProps) => {
    const { className, element } = useFunctionalBem(Error);

    const message = useMemo(() => getErrorMessage(error), [error]);
    const [isVisible, setIsVisible] = useState(true);

    const handleRetry = useCallback(() => {
        setIsVisible(false);
        onRetry && onRetry();
    }, [onRetry]);

    if (!message || !isVisible) return null;

    return (
        <div className={className}>
            <div className={element('error')}>
                <span className={element('text')} onClick={() => setIsVisible(false)}>
                    {message}
                </span>
                {!!onRetry && (
                    <button className={element('button')} onClick={handleRetry}>
                        {i18n.retry}
                    </button>
                )}
            </div>
        </div>
    );
};
