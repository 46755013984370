import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { PageNavMenu } from '~/@sochi-components/PageNavMenu';
import { ProjectQuery, ProjectQueryVariables } from '~/graphql';
import type { WithGraphqlProps } from '~/services/hoc';
import { handledGraphql } from '~/services/hoc';
import { handleProjectGraphqlPermissionError } from '~/utils/project';

import * as queries from '../../../../@store/projects/projects.queries';

type ProjectOffersTopBarProps = {
    projectId: string;
    onBack: () => void;
};

type InjectedProps = WithGraphqlProps<ProjectOffersTopBarProps, ProjectQuery, ProjectQueryVariables>;

const ProjectOffersTopBar = ({ data, onBack }: InjectedProps) => (
    <PageNavMenu
        onBack={onBack}
        title={data.project?.name ? data.project?.name : ''}
        titleLabel={<LocalizableText code={'topBar.project'} />}
    />
);

const withData = handledGraphql<ProjectQuery, ProjectOffersTopBarProps, ProjectQueryVariables, InjectedProps>(
    queries.ProjectQuery,
    {
        options: props => {
            return {
                fetchPolicy: 'cache-first',
                variables: {
                    id: props.projectId,
                },
            };
        },
    },
    handleProjectGraphqlPermissionError
);

export default withData(ProjectOffersTopBar);
