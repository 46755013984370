import { DeliveryLineStatus } from '@common/enums';
import { FilterRange, FilterSearchParams } from '@common/types';
import { isBoolean } from '@common/validations/types.validation';
import { sub } from 'date-fns';
import { action, computed, observable } from 'mobx';

import { FilterStore, PaginationStore, SortStore } from '~/@store/common';
import {
    DEFAULT_DELIVERY_LINES_SORT,
    DEFAULT_MASS_EDIT_LINES_SORT,
    DELIVERY_LINE_VIEW_MODE,
} from '~/@store/deliveryLines/deliveryLines.constants';
import { AdminDeliveryLinesPagedQueryVariables, DeliveryLineFilterInput, SortInput } from '~/graphql';
import i18n from '~/i18n';
import { serializeDateRange } from '~/utils/date';

export const MAX_DATE_INTERVAL_IN_MONTHS = 6;

export type DeliveryLinesStoreFilter = {
    orderNumber?: string | null;
    deliveryLineIdx?: number | null;
    weight?: FilterRange<number> | null;
    truckCapacity?: FilterRange<number> | null;
    plannedStartDate?: FilterRange<Date> | null;
    transportCompany?: string | null;
    truckRegistrationNumber?: string | null;
    customerInvoiceNumber?: FilterSearchParams<string> | null;
    transportationInvoiceNumber?: FilterSearchParams<string> | null;
    landfillInvoiceNumber?: FilterSearchParams<string> | null;
    comment?: string | null;
    verified?: boolean | null;
    status?: StatusOption | null;
};

export enum DeliveryLineField {
    orderNumber = 'orderNumber',
    deliveryLineIdx = 'deliveryLineIdx',
    weight = 'weight',
    plannedStartDate = 'plannedStartDate',
    transportCompany = 'transportCompany',
    truckRegistrationNumber = 'truckRegistrationNumber',
    customerInvoiceNumber = 'customerInvoiceNumber',
    transportationInvoiceNumber = 'transportationInvoiceNumber',
    landfillInvoiceNumber = 'landfillInvoiceNumber',
    verified = 'verified',
    truckCapacity = 'truckCapacity',
    comment = 'comment',
    status = 'status',
}

export type DeliveryLineFilterField = Exclude<DeliveryLineField, DeliveryLineField.deliveryLineIdx>;

export type StatusOption = {
    id: string;
    name: string;
    value: DeliveryLineStatus;
};

const statusesForMode: Record<DELIVERY_LINE_VIEW_MODE, DeliveryLineStatus[]> = {
    [DELIVERY_LINE_VIEW_MODE.DELIVERY_LINES]: [DeliveryLineStatus.DELIVERED, DeliveryLineStatus.VERIFIED],
    [DELIVERY_LINE_VIEW_MODE.IN_PROGRESS_LINES]: [DeliveryLineStatus.EXCAVATED, DeliveryLineStatus.DELIVERING],
    [DELIVERY_LINE_VIEW_MODE.DELETED_LINES]: [
        DeliveryLineStatus.EXCAVATED,
        DeliveryLineStatus.DELIVERING,
        DeliveryLineStatus.DELIVERED,
        DeliveryLineStatus.VERIFIED,
    ],
};

class DeliveryLinesStore {
    private getDefaultFilter = () => ({
        plannedStartDate: [sub(new Date(), { months: MAX_DATE_INTERVAL_IN_MONTHS }), new Date()],
    });

    pagination = new PaginationStore();
    filter = new FilterStore<DeliveryLinesStoreFilter>(this.pagination.onClear, this.getDefaultFilter());

    sort = new SortStore(DEFAULT_DELIVERY_LINES_SORT, this.pagination.onClear);

    @observable
    orderNumber: string | null = null;

    @observable
    mode: DELIVERY_LINE_VIEW_MODE = DELIVERY_LINE_VIEW_MODE.DELIVERY_LINES;

    @computed
    get deliveryLinesQueryVariables(): AdminDeliveryLinesPagedQueryVariables {
        const {
            plannedStartDate,
            status,
            customerInvoiceNumber,
            transportationInvoiceNumber,
            landfillInvoiceNumber,
            verified,
            ...rest
        } = this.filter.values;

        const filter: DeliveryLineFilterInput = { ...rest };
        if (plannedStartDate) filter.date = serializeDateRange(plannedStartDate);
        if (this.orderNumber) filter.orderNumbers = [this.orderNumber];
        if (customerInvoiceNumber) {
            filter.customerInvoiceNumber = customerInvoiceNumber;
        }
        if (transportationInvoiceNumber) {
            filter.transportationInvoiceNumber = transportationInvoiceNumber;
        }
        if (landfillInvoiceNumber) {
            filter.landfillInvoiceNumber = landfillInvoiceNumber;
        }

        if (!!this.orderNumber) {
            let statuses = statusesForMode[this.mode];

            if (isBoolean(verified)) {
                if (verified) {
                    statuses = [DeliveryLineStatus.VERIFIED];
                } else {
                    statuses = statuses.filter(s => s !== DeliveryLineStatus.VERIFIED);
                }
            }

            filter.statuses = statuses;
        } else {
            filter.statuses = status ? [status.value] : statusesForMode[this.mode];
        }

        let sort: SortInput;
        if (this.mode === DELIVERY_LINE_VIEW_MODE.DELETED_LINES) {
            filter.isDeleted = true;
            sort = { field: 'deletedAt', direction: -1 };
        } else {
            sort = this.sort.value;
        }

        return { filter, sort, connection: this.pagination.connection };
    }

    @computed
    get statusOptions(): StatusOption[] {
        return statusesForMode[this.mode].map(s => ({ id: s, name: i18n.DeliveryLineStatuses[s], value: s }));
    }

    @action
    setOrderNumber(newOrderNumber: string | null) {
        this.orderNumber = newOrderNumber;
        if (newOrderNumber) {
            this.filter.values = {};
            this.sort.value = DEFAULT_MASS_EDIT_LINES_SORT;
        } else {
            this.filter.values = this.getDefaultFilter();
            this.sort.value = DEFAULT_DELIVERY_LINES_SORT;
        }
    }

    @action
    setMode(newMode: DELIVERY_LINE_VIEW_MODE) {
        this.mode = newMode;
    }

    canResetFilter(field: DeliveryLineField): boolean {
        return !!this.orderNumber || field !== DeliveryLineField.plannedStartDate;
    }
}

export const deliveryLinesStore = new DeliveryLinesStore();
