import { VERSION_HEADER } from '@common/constants';
import type { ICustomScript, IPublicSettings } from '@common/types';
import axios, { AxiosPromise } from 'axios';
import React, { ComponentType, createContext, FC, useContext, useEffect, useState } from 'react';

import { publicSettingsUri } from '~/apolloClient';
import i18n from '~/i18n';
import { handleAxiosError } from '~/utils/axios';

interface ISettingsContext {
    app: string;
    settings: IPublicSettings;
}

const defaultSettings: IPublicSettings = {
    captchaSiteKey: '',
    captchaEnabled: false,
    customScripts: [],
    chatGptEnabled: false,
};

const defaultContext: ISettingsContext = {
    app: '',
    settings: defaultSettings,
};

export const SettingsContext = createContext<ISettingsContext>(defaultContext);

interface IPublicSettingsData {
    settings: IPublicSettings;
}

const getPublicSettings = (): AxiosPromise<IPublicSettingsData> =>
    axios.get(publicSettingsUri, { headers: { [VERSION_HEADER]: window.__version } });

const addScriptToHead = (script: ICustomScript) => {
    const scriptNode = document.createElement('script');
    if (script.text) scriptNode.text = script.text;
    if (script.src) scriptNode.src = script.src;
    scriptNode.async = script.async;

    document.head.append(scriptNode);
};

const applySettingsToPage = (settings: IPublicSettings): void => {
    settings.customScripts.forEach(addScriptToHead);
};

export const SettingsProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState<IPublicSettings>(defaultSettings);
    const app = i18n.app.name.pinpointer;

    useEffect(() => {
        getPublicSettings()
            .then(({ data }) => {
                setValue(data.settings);
                applySettingsToPage(data.settings);
            })
            .catch(handleAxiosError)
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) return null;

    return <SettingsContext.Provider value={{ app, settings: value }}>{children}</SettingsContext.Provider>;
};

export type WithSettingsProps = ISettingsContext;

export const withSettings = <TProps extends {}>(WrappedComponent: ComponentType<WithSettingsProps & TProps>) => {
    return (props: TProps) => (
        <SettingsContext.Consumer>
            {({ app, settings }) => <WrappedComponent {...props} app={app} settings={settings} />}
        </SettingsContext.Consumer>
    );
};

export const useSettings = () => useContext(SettingsContext);
