import React, { FC } from 'react';

import { Grid } from '~/@components/Grid';
import { HintTooltip } from '~/@components/HintTooltip';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { EditSubstanceView } from '~/@views/UserView/common/EditSubstanceView';

type Props = {
    disabled: boolean;
    showLeaching: boolean;
};

export const SubstancesFields: FC<Props> = ({ disabled, showLeaching }) => {
    return (
        <>
            <Grid item xs={12}>
                <Text variant="TitleBold">
                    <LocalizableText code={'contaminationLevels'} />
                    <HintTooltip
                        text={
                            <LocalizableText
                                code={
                                    disabled
                                        ? 'contaminationLevelsForDumpTypeUnavailable'
                                        : 'contaminationLevelsFieldHint'
                                }
                            />
                        }
                    />
                </Text>
            </Grid>
            <Grid item xs={12}>
                <EditSubstanceView disableContaminationLevels={disabled} showLeaching={showLeaching} />
            </Grid>
        </>
    );
};
