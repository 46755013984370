import { reduce } from 'lodash';
import { parse } from 'qs';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useSearchQuery = (): Record<string, string | undefined> => {
    const { search } = useLocation();

    return useMemo(() => {
        const parsed = parse((search || '').slice(1));

        return reduce(
            parsed,
            (acc, val, key) => {
                acc[key] = String(val);

                return acc;
            },
            {} as Record<string, string | undefined>
        );
    }, [search]);
};
