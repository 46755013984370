import './LandfillFollowUp.scss';

import React, { FC } from 'react';
import { useParams } from 'react-router';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import { LandfillDetailsDataProps } from '~/@views/UserView/LandfillPage/LandfillPage';

import { LandfillWeightReceipts } from './LandfillWeightReceipts';

export const LandfillFollowUp: FC<LandfillDetailsDataProps> = ({ landfill }) => {
    const { className } = useFunctionalBem(LandfillFollowUp);

    const { massSerialNumber } = useParams<{ massSerialNumber?: string }>();

    return (
        <div className={className}>
            <LandfillWeightReceipts landfill={landfill} serialNumber={massSerialNumber} />
        </div>
    );
};
