import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const UNCHECKED_ICON_BORDER_CLASSNAME = 'customUncheckedIconBorder';

export const UNCHECKED_ICON_BORDER_CLASS = `.${UNCHECKED_ICON_BORDER_CLASSNAME}`;

export const UncheckedIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 25 25" fill="none" {...props}>
            <path
                className={UNCHECKED_ICON_BORDER_CLASSNAME}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.0024 0.00125122H0.00244141V24.0013H24.0024V0.00125122ZM22.0024 2.00125H2.00244V22.0013H22.0024V2.00125Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
