import { COLORS } from '~/@components/@theme';
import { DespatchAdviceResponseType, DespatchAdviceType } from '~/config/enum';

export const DESPATCH_ADVICE_TYPE: Record<DespatchAdviceType, COLORS> = {
    [DespatchAdviceType.ORIGINAL]: COLORS.blueLight,
    [DespatchAdviceType.REPLACE]: COLORS.gray,
    [DespatchAdviceType.CANCELLATION]: COLORS.orange,
};

export const DESPATCH_ADVICE_RESPONSE_TYPE: Record<DespatchAdviceResponseType, COLORS> = {
    [DespatchAdviceResponseType.ACCEPTED]: COLORS.green,
    [DespatchAdviceResponseType.DECLINED]: COLORS.orange,
};
