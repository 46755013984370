import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { LandfillRemoveMutation, LandfillRemoveMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../landfills.queries';
import { landfillsStore } from '../landfills.store';
import type { ILandfill } from '../landfills.types';

type DeleteLandfillFunc = () => Promise<unknown>;

export const useLandfillDeleteMutation = (landfill: ILandfill): [DeleteLandfillFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<LandfillRemoveMutation, LandfillRemoveMutationVariables>(
        queries.AdminLandfillRemoveMutation
    );

    const deleteLandfill = useCallback<DeleteLandfillFunc>(() => {
        const { id } = landfill;

        return callMutation({
            variables: { id },
            refetchQueries: [
                {
                    query: queries.AdminLandfillsPagedQuery,
                    variables: landfillsStore.landfillsQueryVariables,
                },
            ],
        }).catch(handleApolloError);
    }, [landfill, callMutation]);

    return [deleteLandfill, mutationResult.loading];
};
