import { withStyles } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';

import { COLORS } from '~/@components/@theme/colors';

export const ListActionButton = withStyles(theme => ({
    root: {
        minWidth: '40px',
        padding: theme.spacing(1),
        color: COLORS.grayDarker,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: COLORS.hoverDark,
            borderColor: COLORS.hoverDark,
        },
    },
}))((props: ButtonProps) => <Button variant="outlined" {...props} />);
