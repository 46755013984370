import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
    CompanyGenerateAPIKeyMutation,
    CompanyGenerateAPIKeyMutation_companyGenerateAPIKey,
    CompanyGenerateAPIKeyMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../companies.queries';

export type GenerateCompanyApiKeyFunc = (
    customerId: string
) => Promise<CompanyGenerateAPIKeyMutation_companyGenerateAPIKey>;

export const useGenerateCompanyApiKey = (): [GenerateCompanyApiKeyFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        CompanyGenerateAPIKeyMutation,
        CompanyGenerateAPIKeyMutationVariables
    >(queries.CompanyGenerateAPIKeyMutation);

    const generateNewApiKey = useCallback<GenerateCompanyApiKeyFunc>(
        customerId => {
            return callMutation({
                variables: { id: customerId },
            })
                .then(result => {
                    return result.data?.companyGenerateAPIKey
                        ? result.data.companyGenerateAPIKey
                        : Promise.reject(new Error('Empty response "CompanyGenerateAPIKeyMutation"'));
                })
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [generateNewApiKey, mutationResult.loading];
};
