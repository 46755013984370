import React from 'react';

import { CardList } from '~/@components/CardList';
import { IBeastMaterial } from '~/@store/beastMaterials';
import { BeastMaterialCard } from '~/@views/AdminView/BeastMaterialsPage/BeastMaterialCard';

type Props = {
    materials: IBeastMaterial[];
    selectedId: string | null;
    onSelect: (id: string) => void;
};

export const BeastMaterialList = ({ materials, selectedId, onSelect }: Props) => {
    return (
        <CardList>
            {materials.map(material => (
                <BeastMaterialCard
                    key={material.id}
                    material={material}
                    isActive={selectedId === material.id}
                    onSelect={() => onSelect(material.id)}
                />
            ))}
        </CardList>
    );
};
