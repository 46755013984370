import './DeliveryChart.scss';

import { Granularity } from '@common/enums';
import isEmpty from 'lodash/isEmpty';
import maxBy from 'lodash/maxBy';
import React from 'react';

import { getBem } from '~/@sochi-components/@bem';
import { COLOR } from '~/@sochi-components/@theme/styles';
import { ArrowLeftIcon, ArrowRightIcon } from '~/@sochi-components/Icons';
import { fmtVolInTon } from '~/utils/numbers';

import { GranularitySwitchValue } from '../../SochiGranularitySwitch';

type VolumePerPeriod = {
    text: string;
    delivered: number;
    planned: number;
    week: number;
    month: number;
    year: number;
};
type DisplayedVolumePerPeriod = VolumePerPeriod & {
    spacer: boolean;
    hidden: boolean;
};

export type ScheduleVolumes = {
    weeks: VolumePerPeriod[];
    months: VolumePerPeriod[];
    years: VolumePerPeriod[];
};

type MassScheduleChartProps = {
    elements: ScheduleVolumes;
    scheduleViewFilter: GranularitySwitchValue;
};
type MassScheduleChartState = {
    filter: GranularitySwitchValue;
    position: number;
    maxColumns: number;
};

export class DeliveryChart extends React.Component<MassScheduleChartProps, MassScheduleChartState> {
    state = {
        filter: Granularity.week as GranularitySwitchValue,
        position: 0,
        maxColumns: 10,
    };

    bem = getBem(this);

    static getDerivedStateFromProps(props: MassScheduleChartProps, prevState: MassScheduleChartState) {
        return {
            filter: props.scheduleViewFilter,
            position: prevState.filter !== props.scheduleViewFilter ? 0 : prevState.position,
        };
    }

    brickHeight(value: number, maxVolume: number) {
        if (!maxVolume) return '0%';

        return (value / maxVolume) * 100 + '%';
    }

    canScrollRight() {
        return this.getElements().length - this.state.position > this.state.maxColumns;
    }

    increasePosition = () => {
        if (this.canScrollRight()) {
            this.setState({
                position: this.state.position + 1,
            });
        }
    };

    decreasePosition = () => {
        if (this.state.position > 0) {
            this.setState({
                position: this.state.position - 1,
            });
        }
    };

    normalizeDeliveryElements = (elements: VolumePerPeriod[]): DisplayedVolumePerPeriod[] => {
        const isEmpty = (el: VolumePerPeriod) => el.delivered === 0 && el.planned === 0;

        return elements.map((elem, index: number) => {
            if (index === 0 || index === elements.length - 1 || !isEmpty(elem))
                return { ...elem, spacer: false, hidden: false };
            const prevElem = elements[index - 1]!;
            const nextElem = elements[index + 1]!;
            if (!isEmpty(prevElem) && isEmpty(nextElem)) return { ...elem, spacer: true, hidden: false };
            if (isEmpty(prevElem)) return { ...elem, spacer: false, hidden: true };

            return { ...elem, spacer: false, hidden: false };
        });
    };

    getElements(): Array<DisplayedVolumePerPeriod> {
        if (isEmpty(this.props.elements)) return [];

        switch (this.state.filter) {
            case Granularity.week:
                return this.normalizeDeliveryElements(this.props.elements.weeks);

            case Granularity.month:
                return this.normalizeDeliveryElements(this.props.elements.months);

            case Granularity.year:
                return this.normalizeDeliveryElements(this.props.elements.years);

            default:
                return [];
        }
    }

    getMaxVolume(elements: Array<DisplayedVolumePerPeriod>) {
        const maxValueExtractor = (el?: DisplayedVolumePerPeriod) => (el ? Math.max(el.planned, el.delivered) : 0);

        const maxElement = maxBy(elements, maxValueExtractor);

        return maxValueExtractor(maxElement);
    }

    getColumnLabel(el: VolumePerPeriod) {
        switch (this.state.filter) {
            case Granularity.week:
                return String(el.week);

            case Granularity.month:
                return String(el.month);

            case Granularity.year:
                return String(el.year);

            default:
                return '';
        }
    }

    render() {
        const elements = this.getElements();
        const maxVolume = this.getMaxVolume(elements);
        const { element, className } = this.bem;

        return (
            <div className={className}>
                <div className={element('body')}>
                    {elements
                        .filter(el => !el.hidden)
                        .slice(this.state.position, this.state.position + this.state.maxColumns)
                        .map(el => {
                            return el.spacer ? (
                                <div className={element('column')} key={el.text}>
                                    <div className={element('column-number')}>...</div>
                                </div>
                            ) : (
                                <div className={element('column')} key={el.text}>
                                    <span className={element('value')}>{fmtVolInTon(el.delivered)}</span>
                                    <div className={element('brick-wrapper')}>
                                        <div
                                            className={element('planned')}
                                            style={{
                                                height: this.brickHeight(el.planned, maxVolume),
                                            }}
                                        />
                                        <div
                                            className={element('delivered', {
                                                inProgress: el.delivered < el.planned,
                                            })}
                                            style={{
                                                height: this.brickHeight(el.delivered, maxVolume),
                                            }}
                                        />
                                    </div>
                                    <div className={element('column-number')}>{this.getColumnLabel(el)}</div>
                                </div>
                            );
                        })}
                    <div
                        className={element('arrow-left', {
                            disabled: this.state.position === 0,
                        })}
                        onClick={this.decreasePosition}>
                        <ArrowLeftIcon
                            htmlColor={this.state.position !== 0 ? COLOR.blue : COLOR.gray3}
                            fontSize="inherit"
                        />
                    </div>
                    <div
                        className={element('arrow-right', {
                            disabled: !this.canScrollRight(),
                        })}
                        onClick={this.increasePosition}>
                        <ArrowRightIcon
                            htmlColor={this.canScrollRight() ? COLOR.blue : COLOR.gray3}
                            fontSize="inherit"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
