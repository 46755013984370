import { LoadStatusesForCustomerPrice } from '@common/constants';
import { ContaminationType } from '@common/enums';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useEffect, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import { COLORS } from '~/@components/@theme';
import { Button } from '~/@components/Button';
import {
    CurrencyFormatter,
    DumpLoadModeFormatter,
    DumpLoadStatusFormatter,
    NullableFormatter,
    TonnsFormatter,
} from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { FONT_WEIGHT } from '~/@sochi-components/@theme';
import { DocumentIcon, PlusCircleIcon } from '~/@sochi-components/Icons';
import { ISochiTableColumn, SochiTableWithRowSelect } from '~/@sochi-components/SochiTable';
import { SochiHeadCellAlignment } from '~/@sochi-components/SochiTable/SochiTableHeadCell';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { getContaminationLevelLabel } from '~/@store/toxicLimits';
import { DumpLoadsFilterField, dumpLoadsStore } from '~/@user-store/dumpLoads/dumpLoadsStore';
import { showAnalysisDocumentsDialog } from '~/@views/UserView/ProjectPage/common/AnalysisDocuments';
import { AnalysisFileUploader } from '~/@views/UserView/ProjectPage/common/AnalysisFileUploader';
import { ProjectCommonDataProps } from '~/@views/UserView/ProjectPage/ProjectPage/ProjectPage';
import { IDumpLoad } from '~/@views/UserView/ProjectPage/ProjectPriceCalculator/AddEditMassForm/AddEditMass.types';
import { CommentsDialogButton } from '~/@views/UserView/ProjectPage/ProjectPriceCalculator/CommentsDialogButton';
import {
    MassesHeadCell,
    PriceCalculatorDeclarationNumberCell,
} from '~/@views/UserView/ProjectPage/ProjectPriceCalculator/ProjectPriceCalculator/PriceCalculatorCells';
import { useUserAbilities } from '~/contexts';
import { LoadStatus } from '~/graphql';
import i18n from '~/i18n';
import { canReadDumpLoadComments, canUpdateDumpLoad } from '~/utils/auth';
import { getLandfillPricePerTon } from '~/utils/dumpLoad';

type Props = ProjectCommonDataProps & {
    onTableSelectionChange: (newItems: IDumpLoad[]) => void;
    onRequestOffers: () => void;
    renderAddEditModal: (dumpLoad?: IDumpLoad) => void;
    selectedItems: IDumpLoad[];
};

const tableKeyGetter = (rowData: IDumpLoad) => rowData.id;

const tableIsRowSelectable = (rowData: IDumpLoad) => rowData.status === LoadStatus.DRAFT;

const showPrice = (d: IDumpLoad) => LoadStatusesForCustomerPrice.has(d.status!);

export const MassesTable: FC<Props> = ({
    project,
    refetch,
    onTableSelectionChange,
    onRequestOffers,
    renderAddEditModal,
    selectedItems,
}) => {
    const abilities = useUserAbilities();

    const { table, addMassButton, commentColumn, dumpTypeColumn } = useStyles();

    useEffect(() => {
        dumpLoadsStore.setList(project.dumpLoads);
    }, [project]);

    useEffect(() => {
        return () => dumpLoadsStore.clear();
    }, []);

    const columns = useMemo(() => {
        const columns: ISochiTableColumn<IDumpLoad>[] = [
            {
                title: (
                    <MassesHeadCell
                        fieldName={DumpLoadsFilterField.serialNumber}
                        title={<LocalizableText code={'priceCalculatorPage.declarationNumber'} />}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: data => (
                    <PriceCalculatorDeclarationNumberCell
                        dumpLoad={data}
                        project={project}
                        onEdit={() => renderAddEditModal(data)}
                    />
                ),
                alignLeft: true,
            },
            {
                title: (
                    <MassesHeadCell
                        fieldName={DumpLoadsFilterField.dumpTypeId}
                        title={<LocalizableText code={'priceCalculatorPage.massType'} />}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: data => (
                    <>
                        {data.dumpType?.name || i18n.NA}{' '}
                        {data.solidTestResult
                            ? getContaminationLevelLabel(data.solidTestResult, ContaminationType.SOLID)
                            : null}
                    </>
                ),
                cellClassName: dumpTypeColumn,
                alignLeft: true,
            },
            {
                title: (
                    <MassesHeadCell
                        fieldName={DumpLoadsFilterField.comment}
                        title={<LocalizableText code={'priceCalculatorPage.description'} />}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: data => data.comment,
                cellClassName: commentColumn,
                alignLeft: true,
            },
            {
                title: (
                    <MassesHeadCell
                        fieldName={DumpLoadsFilterField.status}
                        title={<LocalizableText code={'priceCalculatorPage.status'} />}
                        withFilter
                    />
                ),
                render: data => <DumpLoadStatusFormatter>{data.status}</DumpLoadStatusFormatter>,
                alignLeft: true,
            },
            {
                title: (
                    <MassesHeadCell
                        fieldName={DumpLoadsFilterField.weight}
                        title={<LocalizableText code={'priceCalculatorPage.weight'} />}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: data => <TonnsFormatter>{data.amountInTons}</TonnsFormatter>,
            },
            {
                title: (
                    <MassesHeadCell
                        fieldName={DumpLoadsFilterField.price}
                        title={<LocalizableText code={'priceCalculatorPage.landfillPricePerTon'} />}
                        withFilter
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: data => (
                    <CurrencyFormatter>{showPrice(data) ? getLandfillPricePerTon(data) : null}</CurrencyFormatter>
                ),
            },
            {
                title: (
                    <MassesHeadCell
                        fieldName={DumpLoadsFilterField.tripsCount}
                        title={<LocalizableText code={'priceCalculatorPage.vehicle'} />}
                        withFilter
                        withSort
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: data => <NullableFormatter>{data.totalTrips}</NullableFormatter>,
            },
            {
                title: (
                    <MassesHeadCell
                        fieldName={DumpLoadsFilterField.mode}
                        title={<LocalizableText code={'priceCalculatorPage.option'} />}
                        withFilter
                        alignment={SochiHeadCellAlignment.LEFT}
                    />
                ),
                render: data => <DumpLoadModeFormatter>{data}</DumpLoadModeFormatter>,
                alignLeft: true,
            },
            {
                title: <LocalizableText code={'priceCalculatorPage.analysis'} />,
                render: data => {
                    if (data.analysisFiles.length) {
                        return (
                            <IconButton
                                color="primary"
                                onClick={() => showAnalysisDocumentsDialog(project.id, data.id)}
                                title={i18n.ProjectDocuments.analysisDocuments}>
                                <DocumentIcon fontSize="inherit" />
                            </IconButton>
                        );
                    }

                    return (
                        <AnalysisFileUploader project={project!} dumpLoadId={data.id} onUploadFinish={refetch}>
                            {onClick => (
                                <IconButton
                                    color="primary"
                                    onClick={onClick}
                                    disabled={!canUpdateDumpLoad(abilities, { ...project }, data)}
                                    title={i18n.ProjectDocuments.addAnalysis}>
                                    <PlusCircleIcon fontSize="inherit" />
                                </IconButton>
                            )}
                        </AnalysisFileUploader>
                    );
                },
            },
        ];

        if (canReadDumpLoadComments(abilities, project, null)) {
            columns.push({
                title: '',
                render: dumpLoad => <CommentsDialogButton projectId={project.id} dumpLoadId={dumpLoad.id} />,
            });
        }

        return columns;
    }, [abilities, project, refetch, renderAddEditModal, dumpTypeColumn, commentColumn]);

    const items = useObservable(() => dumpLoadsStore.filteredList);

    return (
        <div>
            <SochiTitle title={<LocalizableText code={'masses'} />}>
                <Button
                    children={<LocalizableText code={'requestOffer'} />}
                    variant="contained"
                    color="primary"
                    onClick={onRequestOffers}
                    disabled={!selectedItems.length}
                />
                <Button
                    children={<LocalizableText code={'priceCalculatorPage.add'} />}
                    variant="contained"
                    color="primary"
                    onClick={() => renderAddEditModal()}
                />
            </SochiTitle>
            <SochiTableWithRowSelect
                className={table}
                columns={columns}
                items={items}
                selectedItems={selectedItems}
                keyGetter={tableKeyGetter}
                isRowSelectable={tableIsRowSelectable}
                onChange={onTableSelectionChange}
                stickyFirstColumn
            />
            <div className={addMassButton} onClick={() => renderAddEditModal()}>
                <LocalizableText code={'add'} />
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    table: {
        zIndex: 1,
        maxHeight: 'calc(100vh - 175px)',
        overflowY: 'scroll',

        [theme.breakpoints.down('md')]: {
            maxHeight: 'calc(100vh - 150px)',
        },
    },
    addMassButton: {
        width: '100%',
        background: COLORS.white,
        fontSize: '18px',
        fontWeight: FONT_WEIGHT.thin,
        padding: '22px 0 22px 30px',
        color: COLORS.blackTransparent,
        cursor: 'pointer',
        userSelect: 'none',

        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            padding: '8px 8px 8px 24px',
        },

        '&:hover': {
            boxShadow: `0 0 7px ${COLORS.blackTransparent}`,
        },
    },
    dumpTypeColumn: {
        maxWidth: '15vw',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        [theme.breakpoints.down('md')]: {
            maxWidth: '130px',
        },
    },
    commentColumn: {
        maxWidth: '10vw',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        [theme.breakpoints.down('md')]: {
            maxWidth: '100px',
        },
    },
}));
