import { intersection } from 'lodash';

import { UserRole } from '../enums';

export const isAdminRole = (role: UserRole | string): boolean => {
    return role === UserRole.ADMIN;
};

// @deprecated. Please base logic on CASL.
export const isEmployeeRole = (role: UserRole | string): boolean => {
    return role === UserRole.EMPLOYEE;
};

// Managers and external users might have different cases, this one is allowed for now.
export const isManagerRole = (role: UserRole | string): boolean => {
    return isAdminRole(role) || isEmployeeRole(role);
};

export type ICaslCondition<T> = T extends Array<infer A>
    ? { $in: Array<A> } | { $all: Array<A> } | Array<A> | A
    : T extends {}
    ? { [Key in keyof T]?: ICaslCondition<T[Key]> }
    : T;

export const isArraysIntersect = <T>(arr1: T[], arr2: T[]) => !!intersection(arr1, arr2).length;
