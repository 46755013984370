import React from 'react';

import { makeStyles, Theme } from '~/@components/@theme';
import { IColoredStatus, IImagedStatus, IStatus } from '~/@store/common';
import { IDumpLoadStatus } from '~/@store/dumpLoads';

interface Props {
    status: IStatus<IDumpLoadStatus>;
}

export const StatusValue = ({ status }: Props) => {
    const { selectCircle, selectImage, valueStyle } = useStyles();

    return (
        <div className={valueStyle}>
            {(status as IColoredStatus<IDumpLoadStatus>).color ? (
                <div
                    className={selectCircle}
                    style={{ background: (status as IColoredStatus<IDumpLoadStatus>).color }}
                />
            ) : (status as IImagedStatus<IDumpLoadStatus>).image ? (
                <img
                    className={selectImage}
                    src={(status as IImagedStatus<IDumpLoadStatus>).image}
                    alt={status.title}
                />
            ) : null}
            <span>{status.title}</span>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    selectCircle: {
        borderRadius: '50%',
        height: 8.33,
        marginRight: theme.spacing(2),
        width: 8.33,
    },
    selectImage: {
        height: 8.33,
        marginRight: theme.spacing(2),
        width: 8.33,
    },
    valueStyle: {
        alignItems: 'center',
        display: 'flex',
        flexFlow: 'row nowrap',
        paddingLeft: 7,
        '& > span': {
            ...theme.customFont.select,
        },
    },
}));
