import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { usePdfExport } from '~/@components/@hooks';
import { Button } from '~/@components/Button';
import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { showCustomDialog } from '~/@store/common';
import { ProjectJoinCodeContent } from '~/@views/UserView/common/ProjectJoinCodeContent/ProjectJoinCodeContent';
import { AdminProjectQuery_project } from '~/graphql';
import { processJoinCodePDf } from '~/utils/project';

type ProjectJoinCodeInfo = Pick<AdminProjectQuery_project, 'name' | 'location' | 'joinCode'>;

type Props = {
    project: ProjectJoinCodeInfo;
    onClose: () => void;
};

export const ProjectJoinCodeForm: FC<Props> = ({ project, onClose }) => {
    const [ExportContainer, generatePdf] = usePdfExport(project.name + '.pdf', processJoinCodePDf);

    const { root, buttons } = useStyles();

    return (
        <CardDialog title="">
            <div className={root}>
                <ExportContainer>
                    <ProjectJoinCodeContent project={project} />
                </ExportContainer>
                <div className={buttons}>
                    <Button color="primary" variant="outlined" onClick={() => onClose()}>
                        <LocalizableText code={'close'} />
                    </Button>
                    <Button color="primary" variant="contained" onClick={generatePdf}>
                        <LocalizableText code={'pdf'} />
                    </Button>
                </div>
            </div>
        </CardDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));

export const showProjectJoinCodeDialog = (project: ProjectJoinCodeInfo) =>
    showCustomDialog({
        render: closeDialog => <ProjectJoinCodeForm onClose={closeDialog} project={project} />,
    });
