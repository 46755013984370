import { Accordion, AccordionActions, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { Button } from '~/@components/Button';
import { ProgressButton } from '~/@components/Button/ProgressButton';
import { showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { DateFormatter } from '~/@components/Formatters';
import { ArrowRightIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Markdown } from '~/@components/Markdown';
import { removeNote } from '~/@store/releaseNotes/releaseNotes.hooks';
import { useReleaseNotesPermissions } from '~/@store/releaseNotes/releaseNotes.permissions';
import { IReleaseNote } from '~/@store/releaseNotes/releaseNotes.types';
import { showReleaseNoteDialog } from '~/@views/AdminView/ReleaseNotesPage/ReleaseNoteForm';
import i18n from '~/i18n';
import { formatDate } from '~/utils/date';

type Props = {
    note: IReleaseNote;
};

export const ReleaseNoteListItem = ({ note }: Props) => {
    const { canUpdate, canDelete } = useReleaseNotesPermissions();
    const { markdown } = useStyles();

    const handleRemove = async () => {
        const confirmed = await showDeleteConfirmationDialog(
            <LocalizableText code={'ReleaseNotes.deleteConfirmation'} />,
            formatDate(note.releaseDate)
        );

        if (confirmed) await removeNote(note.id);
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ArrowRightIcon />} aria-controls="panel1a-content" id="panel1a-header">
                {<LocalizableText code={'ReleaseNotes.releaseDate'} />}: &nbsp;{' '}
                <DateFormatter>{note.releaseDate}</DateFormatter>
            </AccordionSummary>
            <AccordionDetails>
                <Markdown text={note.text} className={markdown} />
            </AccordionDetails>
            {(canUpdate || canDelete) && (
                <AccordionActions>
                    <Button
                        color="primary"
                        onClick={() => showReleaseNoteDialog(note, note.type)}
                        disabled={!canUpdate}>
                        {i18n.edit}
                    </Button>
                    <ProgressButton color="secondary" onClick={handleRemove} disabled={!canDelete}>
                        {i18n.remove}
                    </ProgressButton>
                </AccordionActions>
            )}
        </Accordion>
    );
};

const useStyles = makeStyles(theme => ({
    markdown: {
        padding: theme.spacing(0, 4),
        width: '100%',
    },
}));
