import gql from 'graphql-tag';

export const AuthorFragment = gql`
    fragment AuthorFragment on User {
        id
        name
        role
    }
`;

export const FileDocFragment = gql`
    fragment FileDocFragment on FileDoc {
        id
        name
        uploadDate
    }
`;
