import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect } from 'react';

import { Grid } from '~/@components/Grid';
import { MapInput } from '~/@components/MapInput';
import { Text } from '~/@components/Text';
import { TextField } from '~/@components/TextField';
import { ILocation, useGeocode, useReverseGeocode } from '~/@store/locations';
import { homePageMapStore } from '~/@user-store/homePageMap';
import { GoogleMapsMouseEvent } from '~/@user-store/homePageMap';

import { LAT_MAX, LAT_MIN, LNG_MAX, LNG_MIN } from '../../../../common/utils';
import { NumberField } from '../NumberField';

export type ILocationEditProps = {
    location: ILocation;
    onInput: (v: ILocation) => void;
    onChange: (v: ILocation) => void;
    label?: string;
    readOnly?: boolean;
    error?: boolean;
    helperText?: React.ReactNode;
};

const LAT_LNG_PRECISION = 15;

export function LocationEdit({
    location,
    onChange,
    onInput,
    label,
    readOnly = false,
    error = false,
    helperText = null,
}: ILocationEditProps) {
    const geocode = useGeocode();

    const reverseGeocode = useReverseGeocode();

    const applyAddress = useCallback(() => {
        const { text } = location;
        if (!text) return;

        geocode(text).then(location => {
            if (location) onChange(location);
        });
    }, [geocode, onChange]);

    const applyLatLng = useCallback(() => {
        const { lat, lng } = location;
        if (!lat || !lng) return;
        reverseGeocode(lat, lng).then(location => {
            if (location) onChange(location);
        });
    }, [reverseGeocode, onChange]);

    const mapClick = useCallback(
        (mapLocation: GoogleMapsMouseEvent) => {
            reverseGeocode(mapLocation.latLng!.lat(), mapLocation.latLng!.lng()).then(location => {
                if (location) onChange(location);
            });
        },
        [reverseGeocode, onChange]
    );

    useEffect(() => {
        const mapPoint = location || homePageMapStore.defaultLocation;

        homePageMapStore.setSelectedPlaceAndCenter(mapPoint);
    }, [location]);

    const { map } = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {label ? <Text variant="caption">{label}</Text> : null}
                <MapInput location={location} onClick={mapClick} className={map} readOnly={readOnly} />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    value={location.text}
                    onBlur={applyAddress}
                    onChange={e => {
                        onInput({ ...location, text: e.target.value });
                    }}
                    disabled={readOnly}
                    error={error}
                    helperText={helperText}
                />
            </Grid>
            <Grid item xs={6}>
                <NumberField
                    fullWidth
                    value={location.lat || 0}
                    onBlur={applyLatLng}
                    onChange={value => {
                        onInput({ ...location, lat: value });
                    }}
                    precision={LAT_LNG_PRECISION}
                    min={LAT_MIN}
                    max={LAT_MAX}
                    disabled={readOnly}
                />
            </Grid>
            <Grid item xs={6}>
                <NumberField
                    fullWidth
                    value={location.lng || 0}
                    onBlur={applyLatLng}
                    onChange={value => {
                        onInput({ ...location, lng: value });
                    }}
                    precision={LAT_LNG_PRECISION}
                    min={LNG_MIN}
                    max={LNG_MAX}
                    disabled={readOnly}
                />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(() => ({
    map: {
        width: '100%',
        minHeight: '300px',
    },
}));
