import { formatTonns } from '@common/utils/formaters';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'formik';
import { sum } from 'lodash';
import React from 'react';

import { ExcelIcon } from '~/@components/Icon';
import { ListActionButton } from '~/@components/ListActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { StorePagination } from '~/@components/Pagination';
import { Table } from '~/@components/Table';
import { Text } from '~/@components/Text';
import { deliveryLinesStore } from '~/@store/deliveryLines';
import { useDownloadDeliveryLinesReport } from '~/@store/deliveryLines';
import { IDumpLoadSearchRecord } from '~/@store/dumpLoads';
import { DeliveryLineAddButton } from '~/@views/AdminView/DeliveryLinesPage/DeliveryLineAddButton';
import { DeliveryLineModeSwitch } from '~/@views/AdminView/DeliveryLinesPage/DeliveryLineModeSwitch';
import { DeliveryLinesFilterSummary } from '~/@views/AdminView/DeliveryLinesPage/DeliveryLinesFilterSummary';
import i18n from '~/i18n';

import { DeliveryLinesMassEditActions } from './DeliveryLinesMassEditActions';
import { IDeliveryLineValues, useDeliveryLinesMassEditFormikContext } from './DeliveryLinesMassEditFormik';
import { useDeliveryLinesMassEditColumns } from './useDeliveryLinesMassEditColumns';

type Props = {
    dumpLoad: IDumpLoadSearchRecord;
    onClose?: (v: boolean) => void;
};

export const DeliveryLinesMassEditForm = ({ dumpLoad, onClose }: Props) => {
    const { progress, table, header, actions, tableFooter } = useStyles();

    const formik = useDeliveryLinesMassEditFormikContext();
    const columns = useDeliveryLinesMassEditColumns(formik.dirty);
    const handleDownloadFile = useDownloadDeliveryLinesReport();

    const totalWeight = sum(formik.values.deliveryLines.map(d => d.weight));

    return (
        <Form>
            <div className={header}>
                <div className={actions}>
                    <Text variant="TitleBold">
                        <LocalizableText code={'DeliveryLinesTable.title'} />
                    </Text>
                    <DeliveryLineModeSwitch hideInProgress />
                    <Text variant="BodyRegular">
                        <LocalizableText code={'DeliveryLinesTable.totalWeightOnPage'} />: {formatTonns(totalWeight)}
                    </Text>
                </div>
                <div className={actions}>
                    <ListActionButton onClick={() => handleDownloadFile('xlsx')} disabled={formik.dirty}>
                        <ExcelIcon />
                    </ListActionButton>
                    <DeliveryLineAddButton dumpLoad={dumpLoad} disabled={formik.dirty} />
                </div>
            </div>
            {formik.isSubmitting && <LinearProgress className={progress} />}
            <Table<IDeliveryLineValues>
                columns={columns}
                className={table}
                items={formik.values.deliveryLines}
                keyGetter={o => o.line.id}
                emptyListMessage={i18n.noDataForFilter}
                name="DeliveryLinesForOrderTable"
            />
            <div className={tableFooter}>
                <DeliveryLinesFilterSummary disabled={formik.dirty} />
                <StorePagination
                    store={deliveryLinesStore.pagination}
                    disabled={formik.dirty}
                    canChangeRowsPerPage={false}
                />
            </div>
            <DeliveryLinesMassEditActions onClose={onClose} />
        </Form>
    );
};

const useStyles = makeStyles(theme => ({
    progress: {
        position: 'relative',
        top: theme.spacing(-4),
    },
    table: {
        height: '55vh',
        marginBottom: theme.spacing(1),
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    tableFooter: {
        height: 48,
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
