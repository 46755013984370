import { Button as MuiButton, ButtonProps as MuiButtonProps, makeStyles, PropTypes } from '@material-ui/core';
import cn from 'classnames';
import { camelCase } from 'lodash';
import React from 'react';

import { hasOwnProperty } from '~/utils';
import { hexToRgba } from '~/utils/theme';

const CustomColors = {
    error: 'error',
    warning: 'warning',
    success: 'success',
    info: 'info',
} as const;

type CustomColor = keyof typeof CustomColors;

type IColor = PropTypes.Color | CustomColor;

export type ButtonProps = Omit<MuiButtonProps, 'color'> & { color?: IColor };

const isMUIColor = (c: IColor): c is PropTypes.Color => !hasOwnProperty(CustomColors, c);

// To enable style managing in the theme
const getCustomColorMuiAdditionalClass = (variant: MuiButtonProps['variant'] = 'text', color: CustomColor) => {
    return `MuiButton-${camelCase([variant, color].join(' '))}`;
};

export const Button = ({ color, variant, className, ...restProps }: ButtonProps) => {
    const classes = useStyles();

    if (!color || isMUIColor(color))
        return <MuiButton className={className} color={color} variant={variant} {...restProps} />;

    return (
        <MuiButton
            className={cn(className, classes[color], getCustomColorMuiAdditionalClass(variant, color))}
            variant={variant}
            {...restProps}
        />
    );
};

const useStyles = makeStyles(theme => ({
    error: {
        '&.MuiButton-text:not(.Mui-disabled)': {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: hexToRgba(theme.palette.error.main, theme.palette.action.hoverOpacity),
            },
        },
        '&.MuiButton-outlined:not(.Mui-disabled)': {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: hexToRgba(theme.palette.error.main, theme.palette.action.hoverOpacity),
            },
        },
        '&.MuiButton-contained:not(.Mui-disabled)': {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                color: theme.palette.error.contrastText,
                backgroundColor: theme.palette.error.light,
            },
        },
    },
    warning: {
        '&.MuiButton-text:not(.Mui-disabled)': {
            color: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: hexToRgba(theme.palette.warning.main, theme.palette.action.hoverOpacity),
            },
        },
        '&.MuiButton-outlined:not(.Mui-disabled)': {
            color: theme.palette.warning.main,
            borderColor: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: hexToRgba(theme.palette.warning.main, theme.palette.action.hoverOpacity),
            },
        },
        '&.MuiButton-contained:not(.Mui-disabled)': {
            color: theme.palette.warning.contrastText,
            backgroundColor: theme.palette.warning.main,
            '&:hover': {
                color: theme.palette.warning.contrastText,
                backgroundColor: theme.palette.warning.light,
            },
        },
    },
    success: {
        '&.MuiButton-text:not(.Mui-disabled)': {
            color: theme.palette.success.main,
            '&:hover': {
                backgroundColor: hexToRgba(theme.palette.success.main, theme.palette.action.hoverOpacity),
            },
        },
        '&.MuiButton-outlined:not(.Mui-disabled)': {
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: hexToRgba(theme.palette.success.main, theme.palette.action.hoverOpacity),
            },
        },
        '&.MuiButton-contained:not(.Mui-disabled)': {
            color: theme.palette.success.contrastText,
            backgroundColor: theme.palette.success.main,
            '&:hover': {
                color: theme.palette.success.contrastText,
                backgroundColor: theme.palette.success.light,
            },
        },
    },
    info: {
        '&.MuiButton-text:not(.Mui-disabled)': {
            color: theme.palette.info.main,
            '&:hover': {
                backgroundColor: hexToRgba(theme.palette.info.main, theme.palette.action.hoverOpacity),
            },
        },
        '&.MuiButton-outlined:not(.Mui-disabled)': {
            color: theme.palette.info.main,
            borderColor: theme.palette.info.main,
            '&:hover': {
                backgroundColor: hexToRgba(theme.palette.info.main, theme.palette.action.hoverOpacity),
            },
        },
        '&.MuiButton-contained:not(.Mui-disabled)': {
            color: theme.palette.info.contrastText,
            backgroundColor: theme.palette.info.main,
            '&:hover': {
                color: theme.palette.info.contrastText,
                backgroundColor: theme.palette.info.light,
            },
        },
    },
}));
