import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import { LandfillsPagedQuery, LandfillsPagedQueryVariables } from '~/graphql';

import * as queries from '../landfills.queries';
import { landfillsStore } from '../landfills.store';

export const usePagedLandfills = (): QueryResult<LandfillsPagedQuery, LandfillsPagedQueryVariables> => {
    const variables: LandfillsPagedQueryVariables = useDebouncedObservable(
        () => landfillsStore.landfillsQueryVariables,
        TIMEOUT_ON_QUERY_DEBOUNCE
    );

    return useQuery<LandfillsPagedQuery, LandfillsPagedQueryVariables>(queries.AdminLandfillsPagedQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
        onCompleted: data => landfillsStore.pagination.onQueryComplete(data.landfillsPaged),
    });
};
