import { useApolloClient } from '@apollo/client';

import * as queries from '~/@store/locations/location.queries';
import { gqlTypeToLocation, ILocation } from '~/@store/locations/locations.types';
import { ReverseGeocode, ReverseGeocodeVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

type UseReverseGeocodeResult = (lat: number, lng: number) => Promise<ILocation>;

export const useReverseGeocode = (): UseReverseGeocodeResult => {
    const client = useApolloClient();

    return (lat: number, lng: number) =>
        client
            .query<ReverseGeocode, ReverseGeocodeVariables>({
                query: queries.ReverseGeocode,
                variables: {
                    location: {
                        lat,
                        lng,
                    },
                },
            })
            .then(result => {
                return gqlTypeToLocation(result.data.reverseGeocode);
            })
            .catch(handleApolloError);
};
