import './ProjectPriceCalculator.scss';

import { Colors } from '@common/enums';
import { replaceRouteParams, routes } from '@common/routes';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { SochiInfoPanel } from '~/@sochi-components/SochiInfoPanel';
import { IUpdateStatusDumpload } from '~/@store/dumpLoads';
import client from '~/apolloClient';
import { useUserAbilities } from '~/contexts';
import { LoadStatus } from '~/graphql';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';
import offersStore from '~/stores/offersStore';
import { canReadPriceCalculator } from '~/utils/auth';
import { changeDumpLoadStatus } from '~/utils/dumpLoad';
import { handleProjectPermissionError } from '~/utils/project';

import type { ProjectCommonDataProps } from '../../ProjectPage/ProjectPage';
import { AddEditMassForm } from '../AddEditMassForm';
import { IDumpLoad } from '../AddEditMassForm/AddEditMass.types';
import { ProjectAdditionalDocuments } from '../ProjectAdditionalDocuments';
import { ProjectNotes } from '../ProjectNotes/ProjectNotes';
import { SuccessOfferDialog } from '../SuccessOfferDialog';
import { MassesTable } from './MassesTable';

export const ProjectPriceCalculator: FC<ProjectCommonDataProps> = props => {
    const { project, refetch } = props;
    const { className, element } = useFunctionalBem(ProjectPriceCalculator);
    const history = useHistory();
    const [selectedItems, setSelectedItems] = useState<IDumpLoad[]>([]);
    const abilities = useUserAbilities();

    const handlePermissionError = useCallback(
        (error: unknown): Promise<never> => handleProjectPermissionError(abilities.ability, error),
        [abilities]
    );

    const renderAddEditModal = (dumpLoad?: IDumpLoad) => {
        showCustomDialog({
            render: closeDialog => (
                <AddEditMassForm
                    project={project}
                    dumpLoad={dumpLoad}
                    onClose={closeDialog}
                    handleError={handlePermissionError}
                />
            ),
        }).then(shouldRefetch => {
            if (shouldRefetch) refetch?.();
        });
    };

    useEffect(() => {
        if (project.dumpLoads.length === 0) renderAddEditModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToOffersPage = useCallback(() => {
        history.push(replaceRouteParams(routes.sochi.offerOutgoing, { projectId: project.id }));
    }, [history, project]);

    const showOffersDialog = useCallback(
        (dumpLoads: Array<IUpdateStatusDumpload>) => {
            const ignoredPromise = showCustomDialog({
                title: <LocalizableText code={'yourOfferHasBeen'} />,
                render: closeDialog => (
                    <SuccessOfferDialog
                        closeDialog={closeDialog}
                        goToOffersPage={goToOffersPage}
                        dumpLoads={dumpLoads}
                    />
                ),
            });
        },
        [goToOffersPage]
    );

    const requestOfferForDrafts = useCallback(async () => {
        const draftDumpLoads = selectedItems.filter((d: IDumpLoad) => d.status === LoadStatus.DRAFT);

        // It should not happen since button will be disabled, just in case
        if (!draftDumpLoads.length) return;

        changeDumpLoadStatus({
            client,
            dumpLoadIds: draftDumpLoads.map(d => d.id),
            project,
            status: LoadStatus.REQUESTED,
        })
            .then(
                updatedProject =>
                    updatedProject?.dumpLoads.filter(d => draftDumpLoads.some((dl: IDumpLoad) => dl.id === d.id)) || []
            )
            .then(dumpLoads => {
                refetch?.();
                showOffersDialog(dumpLoads);
                offersStore.loadOffersCount();
            })
            .catch(handlePermissionError);
    }, [project, refetch, selectedItems, showOffersDialog, handlePermissionError]);

    const onTableSelectionChange = (newItems: IDumpLoad[]) => {
        setSelectedItems(newItems);
    };

    if (!canReadPriceCalculator(abilities, project)) {
        return <SochiInfoPanel color={Colors.orange} text={i18n.errorCodes.PERMISSION_ERROR} />;
    }

    return (
        <div className={className}>
            <div className={element('document-section')}>
                <ProjectNotes project={project} />
                <ProjectAdditionalDocuments project={project} refetch={refetch} />
            </div>
            <MassesTable
                onTableSelectionChange={onTableSelectionChange}
                onRequestOffers={requestOfferForDrafts}
                renderAddEditModal={renderAddEditModal}
                selectedItems={selectedItems}
                {...props}
            />
        </div>
    );
};
