import React from 'react';

import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { ISubstance, useSubstancesList } from '~/@store/substances';

import { SubstancesPageTabs } from './SubstancesPageTabs';

export const SubstancesPage = () => {
    const query = useSubstancesList();

    return (
        <QueryStateHandler query={query} dataGetter={query => query.data?.substances}>
            {(substances: ISubstance[]) => <SubstancesPageTabs substances={substances} />}
        </QueryStateHandler>
    );
};
