import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { AdminDespatchAdviceDefaultSettingsQuery } from '~/@store/beastIntegration/beastIntegration.queries';
import {
    AdminDespatchAdviceDefaultSettingsQuery as IAdminDespatchAdviceDefaultSettingsQuery,
    AdminDespatchAdviceDefaultSettingsQuery_despatchAdviceDefaultSettings,
} from '~/graphql';

export type IDespatchAdviceDefaultSettings = Omit<
    AdminDespatchAdviceDefaultSettingsQuery_despatchAdviceDefaultSettings,
    '__typename'
>;

export const useDespatchAdviceDefaultSettings = (): QueryResult<
    IAdminDespatchAdviceDefaultSettingsQuery,
    undefined
> => {
    return useQuery<IAdminDespatchAdviceDefaultSettingsQuery, undefined>(AdminDespatchAdviceDefaultSettingsQuery, {
        fetchPolicy: 'network-only',
    });
};
