import { withStyles } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';

export const DialogTitleButton = withStyles(theme => ({
    root: {
        color: theme.palette.background.paper,
        height: 32,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
}))((props: ButtonProps) => <Button {...props} />);
