import React, { useMemo } from 'react';

import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { IDespatchAdvice } from '~/@store/beastIntegration';
import { saveToClient } from '~/utils/files';

type Props = {
    despatchAdvice: IDespatchAdvice;
};

export const DespatchAdviceTransactionCell = ({ despatchAdvice }: Props) => {
    const actions: Array<ITableCellAction> = useMemo(
        () => [
            {
                variant: ActionVariant.COPY,
                content: despatchAdvice.transactionId,
            },
            {
                variant: ActionVariant.DOWNLOAD_FILE,
                onClick: () => saveToClient(despatchAdvice.body, `${despatchAdvice.transactionId}.xml`),
            },
        ],
        [despatchAdvice]
    );

    return <TableCellActions actions={actions}>{despatchAdvice.transactionId}</TableCellActions>;
};
