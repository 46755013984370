import { TextFieldProps } from '@material-ui/core';
import { Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';
import React from 'react';

import { TextField } from '~/@components/TextField';

type Props = {
    name: string;
} & Omit<TextFieldProps, 'value' | 'name' | 'onChange'>;

export const FormikTypeUnsafeTextField = ({ name, disabled, ...restProps }: Props) => {
    return (
        <Field name={name}>
            {({ field, meta, form }: FieldProps) => (
                <TextField
                    {...field}
                    error={meta.touched && !!meta.error}
                    {...restProps}
                    disabled={disabled || form.isSubmitting}
                />
            )}
        </Field>
    );
};
