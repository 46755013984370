import { uniqueId } from 'lodash';

export class ImageForUpload {
    id = uniqueId();
    file: File;
    name: string;
    localSrc: string;

    constructor(file: File) {
        this.file = file;
        this.name = file.name;
        this.localSrc = URL.createObjectURL(file);
    }

    static fromFileList(files: FileList): ImageForUpload[] {
        const fileArray = Array.from(files);

        return fileArray.map(f => new ImageForUpload(f));
    }
}
