import React from 'react';

import { ButtonGroupSwitch } from '~/@components/ButtonGroupSwitch';
import { LocalizableText } from '~/@components/LocalizableText';

import { Granularity } from '../../../common/enums';

export type GranularitySwitchValue = Granularity.week | Granularity.month | Granularity.year;

type SochiGranularitySwitchProps = {
    onChange: (arg0: GranularitySwitchValue) => void;
    value: GranularitySwitchValue;
    readOnly?: boolean;
};

const filterCases: { label: React.ReactNode; value: GranularitySwitchValue }[] = [
    {
        label: <LocalizableText code={'TimeFilters.WEEK'} />,
        value: Granularity.week,
    },
    {
        label: <LocalizableText code={'TimeFilters.MONTH'} />,
        value: Granularity.month,
    },
    {
        label: <LocalizableText code={'TimeFilters.YEAR'} />,
        value: Granularity.year,
    },
];

export const SochiGranularitySwitch = ({ onChange, value, readOnly }: SochiGranularitySwitchProps) => {
    return (
        <ButtonGroupSwitch
            color="secondary"
            options={filterCases}
            value={value}
            onChange={onChange}
            disabled={readOnly}
        />
    );
};
