import React, { memo } from 'react';

import { CheckFormatter } from '~/@components/Formatters';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { IDumpLoad } from '~/@store/dumpLoads';
import { useAnalysisFileUploadMutation } from '~/@store/projects';
import { ACCEPTED_DOCUMENT_FILE_TYPES } from '~/config/constants';

import { showDumpLoadEditAnalysisDialog } from '../DumpLoadEditAnalysisDialog';

type Props = {
    dumpLoad: IDumpLoad;
};

export const DumpLoadAnalysisCell = memo(({ dumpLoad }: Props) => {
    const [uploadAnalysisFile, isUploading] = useAnalysisFileUploadMutation();

    if (!dumpLoad.project) return null;

    const uploadFile = (file: File) => {
        if (isUploading) return;

        const ignoredPromise = uploadAnalysisFile(dumpLoad.project!.id, dumpLoad.id, file);
    };

    const hasAnalysisFiles = !!dumpLoad.analysisFiles.length;

    const action: ITableCellAction = hasAnalysisFiles
        ? {
              variant: ActionVariant.EDIT,
              onClick: () => showDumpLoadEditAnalysisDialog(dumpLoad.project!.id, dumpLoad.id),
          }
        : {
              variant: ActionVariant.UPLOAD_FILE,
              acceptFileTypes: ACCEPTED_DOCUMENT_FILE_TYPES,
              onChange: uploadFile,
          };

    const actions: Array<ITableCellAction> = [action];

    return (
        <TableCellActions actions={actions}>
            <CheckFormatter>{!!dumpLoad.analysisFiles.length}</CheckFormatter>
        </TableCellActions>
    );
});
