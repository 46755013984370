import { format, setISODay } from 'date-fns';
import React, { FC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { IHomePageLandfill } from '~/@user-store/homePageMap';
import i18n from '~/i18n';

type Props = {
    workTime: IHomePageLandfill['workTime'];
    selectedDayNumber?: number;
};

export const getWorkTimeLabel = (
    workDay:
        | {
              openFrom: string;
              openTo: string;
              isOpen: boolean;
          }
        | undefined
) => (workDay && workDay.isOpen ? workDay.openFrom + ' - ' + workDay.openTo : i18n.LandfillForm.closed);

export const LandfillWorkTime: FC<Props> = ({ workTime, selectedDayNumber }) => {
    const dayNumbers = Array.from(Array(7).keys()).map(i => i + 1);

    const { root, column, selected } = useStyles();

    return (
        <div className={root}>
            <div className={column}>
                {dayNumbers.map(dayNumber => (
                    <div key={dayNumber} className={dayNumber === selectedDayNumber ? selected : undefined}>
                        {format(setISODay(new Date(), dayNumber % 7), 'EEEE')}
                    </div>
                ))}
            </div>
            <div className={column}>
                {dayNumbers.map(dayNumber => (
                    <div key={dayNumber} className={dayNumber === selectedDayNumber ? selected : undefined}>
                        {getWorkTimeLabel(workTime.find(d => d.dayNumber === dayNumber))}
                    </div>
                ))}
            </div>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    selected: {
        fontWeight: 700,
    },
}));
