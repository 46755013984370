import { COLORS } from '~/@components/@theme/colors';
import { IStatus } from '~/@store/common';
import { LoadStatus } from '~/graphql';
import i18n from '~/i18n';

import AllDumpLoadsPng from './all-dump-loads.png';

export const DEFAULT_DUMP_LOAD_SORT = {
    field: 'serialNumber',
    direction: -1,
};

export enum AllDumpLoads {
    ALL_DUMP_LOADS = 'ALL_DUMP_LOADS',
}

export type IDumpLoadStatus = AllDumpLoads | LoadStatus;

export const DUMP_LOAD_STATUSES: Array<IStatus<IDumpLoadStatus>> = [
    {
        color: COLORS.yellow,
        title: i18n.AdminDumpLoadTableStatuses.REQUESTED,
        value: LoadStatus.REQUESTED,
    },
    {
        color: COLORS.green,
        title: i18n.AdminDumpLoadTableStatuses.CONFIRMED,
        value: LoadStatus.CONFIRMED,
    },
    {
        color: COLORS.blueLight,
        title: i18n.AdminDumpLoadTableStatuses.ORDERED,
        value: LoadStatus.ORDERED,
    },
    {
        color: COLORS.brandRed,
        title: i18n.AdminDumpLoadTableStatuses.NOT_ORDERED,
        value: LoadStatus.NOT_ORDERED,
    },
    {
        image: AllDumpLoadsPng,
        title: i18n.Admin.allDumpLoads,
        value: AllDumpLoads.ALL_DUMP_LOADS,
    },
];

export const ORDERS_STATUSES: Array<IStatus<IDumpLoadStatus>> = [
    {
        color: COLORS.blue,
        title: i18n.AdminDumpLoadTableStatuses.IN_PROGRESS,
        value: LoadStatus.IN_PROGRESS,
    },
    {
        color: COLORS.gray,
        title: i18n.AdminDumpLoadTableStatuses.DONE,
        value: LoadStatus.DONE,
    },
];

export enum ToxicAnalysisApprovalState {
    APPROVED_EXTERNALLY = 'APPROVED_EXTERNALLY',
    NOT_APPROVED = 'NOT_APPROVED',
}
