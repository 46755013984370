import { LoadStatus } from '@common/enums';
import { canCalculateDestination } from '@common/functions';
import { routes } from '@common/routes';
import { getISOWeek, parse } from 'date-fns';

import { TransportScheduleWeekFormat } from '~/../../common/constants';
import { AllDumpLoads, IDumpLoadStatus, ToxicAnalysisApprovalState } from '~/@store/dumpLoads/dumpLoads.constants';
import { IDumpLoad } from '~/@store/dumpLoads/dumpLoads.hooks';
import { projectAnalysisUri } from '~/apolloClient';
import { DumpLoadsFilter } from '~/graphql';
import i18n from '~/i18n';
import type { AnalysisFile } from '~/utils/dumpLoad';
import { downloadFile } from '~/utils/files';

export function getLandfillCostPerTonn(dumpLoad: IDumpLoad): number | null {
    return dumpLoad.amountInTons ? (dumpLoad.priceData.internalLandfillPrice || 0) / dumpLoad.amountInTons : null;
}

export function getLandfillPricePerTonn(dumpLoad: IDumpLoad): number | null {
    return dumpLoad.amountInTons ? dumpLoad.priceData.landfillPrice / dumpLoad.amountInTons : null;
}

function getWeek(scheduleItem: { week: string }) {
    return getISOWeek(parse(scheduleItem.week, TransportScheduleWeekFormat, new Date())).toString();
}

export function getTransportScheduleText(dumpLoad: IDumpLoad): string {
    const filledWeeks = dumpLoad.transportSchedule.filter(s => s.amount);

    switch (filledWeeks.length) {
        case 0:
            return i18n.NA;
        case 1:
            return `v${getWeek(filledWeeks[0]!)}`;
        default:
            return `v${getWeek(filledWeeks[0]!)}-${getWeek(filledWeeks[filledWeeks.length - 1]!)}`;
    }
}

export function downloadDumpLoadAnalysis(
    projectId: string,
    dumpLoadId: string,
    analysisFile: AnalysisFile
): Promise<Blob> {
    return downloadFile(projectAnalysisUri, { projectId, dumpLoadId, fileId: analysisFile.id }, analysisFile.name);
}

export function canChangeDestination(dumpLoad: IDumpLoad) {
    return (
        !dumpLoad.siteConfirmed &&
        canCalculateDestination(dumpLoad.dumpType?.id, dumpLoad.status, dumpLoad.amount, dumpLoad.unitOfMeasure)
    );
}

export function isLoadStatus(v: string): v is LoadStatus {
    return v in LoadStatus;
}

type ToxicAnalysisFilters = Pick<DumpLoadsFilter, 'toxicAnalysisApprovedExternally'>;

export const getToxicAnalysisFiltersFromApprovalState = (
    approvalState: ToxicAnalysisApprovalState
): ToxicAnalysisFilters => {
    switch (approvalState) {
        case ToxicAnalysisApprovalState.APPROVED_EXTERNALLY:
            return {
                toxicAnalysisApprovedExternally: true,
            };
        case ToxicAnalysisApprovalState.NOT_APPROVED:
            return {
                toxicAnalysisApprovedExternally: false,
            };
        default:
            return {};
    }
};

type ToxicAnalysisValues = Pick<IDumpLoad, 'toxicAnalysisApprovedExternally'>;

export const getDumpLoadToxicAnalysisApprovalState = ({
    toxicAnalysisApprovedExternally,
}: ToxicAnalysisValues): ToxicAnalysisApprovalState => {
    if (toxicAnalysisApprovedExternally) return ToxicAnalysisApprovalState.APPROVED_EXTERNALLY;

    return ToxicAnalysisApprovalState.NOT_APPROVED;
};

const routeToStatus: Record<string, LoadStatus> = {
    [routes.admin.dumpLoadRequested]: LoadStatus.REQUESTED,
    [routes.admin.dumpLoadConfirmed]: LoadStatus.CONFIRMED,
    [routes.admin.dumpLoadOrdered]: LoadStatus.ORDERED,
    [routes.admin.dumpLoadRejected]: LoadStatus.NOT_ORDERED,
    [routes.admin.ordersInProgress]: LoadStatus.IN_PROGRESS,
    [routes.admin.ordersDone]: LoadStatus.DONE,
};

export const getStatusFromPathname = (path: string): IDumpLoadStatus => {
    return routeToStatus[path] || AllDumpLoads.ALL_DUMP_LOADS;
};
