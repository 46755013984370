import { routes } from '@common/routes';
import React, { FC } from 'react';
import { RouteComponentProps, RouteProps, withRouter } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import { useUserAbilities } from '~/contexts';
import { removeAuthToken } from '~/utils/auth';

import { NotApprovedPage, UserBlockedPage } from '../../@views/AuthView';

const AuthRouteComponent: FC<RouteProps & RouteComponentProps> = ({ component: Component, render = null, ...rest }) => {
    const { user } = useUserAbilities();

    return (
        <Route
            {...rest}
            render={props => {
                if (!user || !user.id) {
                    // redirect to login screen if not authenticated
                    return (
                        <Redirect
                            to={{
                                pathname: routes.login,
                                state: { from: props.location },
                            }}
                        />
                    );
                }

                if (!user.confirmed) {
                    removeAuthToken();

                    return (
                        <Redirect
                            to={{
                                pathname: routes.notConfirmedEmail,
                                state: { from: props.location },
                            }}
                        />
                    );
                }

                if (!user.verified) {
                    return <NotApprovedPage {...props} />;
                }

                if (user.blocked) {
                    return <UserBlockedPage {...props} />;
                }

                if (typeof render === 'function') {
                    return render(props);
                }

                // render component otherwise
                return Component && <Component {...props} />;
            }}
        />
    );
};

export default withRouter(AuthRouteComponent);
