import { defaultEngine, defaultFuel } from '@common/constants';
import { FormikConfig } from 'formik';

import { CreateDeliveryLineFunc } from '~/@store/deliveryLines';
import { IDumpLoadSearchRecord } from '~/@store/dumpLoads/dumpLoads.hooks/useDumpLoadSearchFunc';
import { DeliveryLineCreateInput } from '~/graphql';
import { booleanToDeliveryLineStatus } from '~/utils/deliveryLine';

import { getDeliveryLineValidationSchema, IDeliveryLineFormValues } from './DeliveryLineFormikCommon';

export function getDeliveryLineCreateFormikConfig(
    createDeliveryLine: CreateDeliveryLineFunc,
    dumpLoad: IDumpLoadSearchRecord | null = null,
    canCreateDeliveryLine: (dumpLoad: IDumpLoadSearchRecord) => boolean
): FormikConfig<IDeliveryLineFormValues> {
    return {
        initialValues: {
            dumpLoad,
            weight: null,
            date: new Date(),
            company: null,
            truckRegistrationNumber: '',
            truckCapacity: null,
            customerInvoice: '',
            transportInvoice: '',
            landfillInvoice: '',
            receipt: null,
            canSelectOrder: !dumpLoad,
            comment: '',
            verified: false,
            vehicleType: null,
            fuel: defaultFuel,
            engine: defaultEngine,
        },
        validationSchema: getDeliveryLineValidationSchema(canCreateDeliveryLine, undefined),
        onSubmit: async (values, { resetForm }) => {
            const {
                dumpLoad,
                weight,
                date,
                company,
                truckRegistrationNumber,
                truckCapacity,
                customerInvoice,
                transportInvoice,
                landfillInvoice,
                receipt,
                comment,
                verified,
                vehicleType,
                fuel,
                engine,
            } = values;

            if (!dumpLoad || !weight || !date || !receipt || !vehicleType || !fuel || !engine) {
                throw new Error('Internal error');
            }

            const input: DeliveryLineCreateInput = {
                orderNumber: dumpLoad.serialNumber,
                weight,
                plannedStartDate: date.toISOString(),
                companyId: company?.id || null,
                truckRegistrationNumber,
                truckCapacity: truckCapacity || 0,
                customerInvoiceNumber: customerInvoice,
                transportationInvoiceNumber: transportInvoice,
                landfillInvoiceNumber: landfillInvoice,
                receipt,
                comment,
                status: booleanToDeliveryLineStatus(verified),
                vehicleTypeId: vehicleType.id,
                fuel,
                engine,
            };

            await createDeliveryLine(input);
            resetForm();
        },
    };
}
