import { Grid } from '@material-ui/core';
import React from 'react';

import { FormikDateField, FormikLocationField, FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { TextField } from '~/@components/TextField';
import { useProjectAbilities } from '~/@store/projects';
import { getJoinCode } from '~/utils/project';

import { ProjectEditCustomer } from './ProjectEditCustomer';
import { useProjectFormikContext } from './ProjectEditFormik';
import { ProjectEditManager } from './ProjectEditManager';

export const ProjectEditCommonFields = () => {
    const formik = useProjectFormikContext();

    const { project, startDate, endDate } = formik.values;

    const { canEditLocation, canEditProject } = useProjectAbilities(project);

    const disabledLocation = !canEditLocation;
    const disabledCommon = !canEditProject;
    const joinCode = getJoinCode(project);

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormikTextField
                            label={<LocalizableText code={'ProjectForm.projectName'} />}
                            name="name"
                            formik={formik}
                            disabled={disabledCommon}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ProjectEditCustomer disabled={disabledCommon} />
                    </Grid>

                    {project && (
                        <Grid item xs={12}>
                            <ProjectEditManager disabled={disabledCommon} />
                        </Grid>
                    )}

                    <Grid item xs={6}>
                        <FormikDateField
                            label={<LocalizableText code={'startDate'} />}
                            name="startDate"
                            formik={formik}
                            maxDate={endDate}
                            disabled={disabledCommon}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormikDateField
                            label={<LocalizableText code={'endDate'} />}
                            name="endDate"
                            formik={formik}
                            minDate={startDate}
                            disabled={disabledCommon}
                        />
                    </Grid>

                    {joinCode && (
                        <Grid item xs={4}>
                            <TextField
                                value={joinCode}
                                label={<LocalizableText code={'ProjectForm.joinCode'} />}
                                fullWidth
                                disabled
                            />
                        </Grid>
                    )}

                    <Grid item xs={joinCode ? 4 : 6}>
                        <FormikTextField
                            label={<LocalizableText code={'ProjectForm.invoiceReference'} />}
                            name="invoiceReference"
                            formik={formik}
                            disabled={disabledCommon}
                        />
                    </Grid>

                    <Grid item xs={joinCode ? 4 : 6}>
                        <FormikTextField
                            label={<LocalizableText code={'ProjectForm.externalId'} />}
                            name="externalId"
                            formik={formik}
                            disabled={disabledCommon}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormikTextField
                            label={<LocalizableText code={'ProjectForm.comment'} />}
                            name="comment"
                            formik={formik}
                            multiline={true}
                            disabled={disabledCommon}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <FormikLocationField name={'location'} formik={formik} disabled={disabledLocation || disabledCommon} />
            </Grid>
        </Grid>
    );
};
