import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { CommentIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { showAlertDialog } from '~/@store/common';
import { IDeliveryLine } from '~/@store/deliveryLines';

type Props = {
    line: IDeliveryLine;
};

const UserComments = ({ line }: Props) => {
    const { root, paragraph } = useStyles();

    return (
        <div className={root}>
            {line.senderComment && (
                <div>
                    <Text variant="BodyBold">{<LocalizableText code={'DeliveryLinesTable.senderNotes'} />}:</Text>
                    <Text className={paragraph} variant="BodyRegular">
                        {line.senderComment}
                    </Text>
                </div>
            )}
            {line.receiverComment && (
                <div>
                    <Text variant="BodyBold">{<LocalizableText code={'DeliveryLinesTable.receiverNotes'} />}:</Text>
                    <Text className={paragraph} variant="BodyRegular">
                        {line.receiverComment}
                    </Text>
                </div>
            )}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    paragraph: {
        paddingLeft: theme.spacing(2),
    },
}));

export const showDeliveryLineUserComments = (line: IDeliveryLine) =>
    showAlertDialog({
        title: <LocalizableText code={'DeliveryLinesTable.userNotes'} />,
        icon: CommentIcon,
        render: () => <UserComments line={line} />,
    });
