import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const PdfIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path d="M8.26702 14.6799C8.08302 14.6799 7.95902 14.6979 7.89502 14.7159V15.8939C7.97102 15.9119 8.06602 15.9169 8.19702 15.9169C8.67602 15.9169 8.97102 15.6749 8.97102 15.2659C8.97102 14.8999 8.71702 14.6799 8.26702 14.6799ZM11.754 14.6919C11.554 14.6919 11.424 14.7099 11.347 14.7279V17.3379C11.424 17.3559 11.548 17.3559 11.66 17.3559C12.477 17.3619 13.009 16.9119 13.009 15.9599C13.015 15.1299 12.53 14.6919 11.754 14.6919Z" />
            <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2ZM9.498 16.19C9.189 16.48 8.733 16.61 8.202 16.61C8.09904 16.6111 7.99613 16.6051 7.894 16.592V18.018H7V14.082C7.40345 14.0218 7.81112 13.9944 8.219 14C8.776 14 9.172 14.106 9.439 14.319C9.693 14.521 9.865 14.852 9.865 15.242C9.864 15.634 9.734 15.965 9.498 16.19V16.19ZM13.305 17.545C12.885 17.894 12.246 18.06 11.465 18.06C10.997 18.06 10.666 18.03 10.441 18V14.083C10.8446 14.0241 11.2521 13.9963 11.66 14C12.417 14 12.909 14.136 13.293 14.426C13.708 14.734 13.968 15.225 13.968 15.93C13.968 16.693 13.689 17.22 13.305 17.545V17.545ZM17 14.77H15.468V15.681H16.9V16.415H15.468V18.019H14.562V14.03H17V14.77ZM14 9H13V4L18 9H14Z" />
        </SvgIcon>
    );
};
