import React, { VFC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { DateTimeFormatter } from '~/@components/Formatters';
import { LabelWithHint } from '~/@components/LabelWithHint';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { IDespatchAdvice } from '~/@store/beastIntegration';
import i18n from '~/i18n';

type Props = {
    despatchAdvice: IDespatchAdvice;
};

export const DespatchAdviceStatusCell: VFC<Props> = ({ despatchAdvice }) => {
    const { cell } = useStyles();

    if (despatchAdvice.sentAt) {
        return (
            <div className={cell}>
                <Text color="textSecondary">
                    <LocalizableText code={'DespatchAdvicesTable.sentAt'} />
                </Text>
                <DateTimeFormatter>{despatchAdvice.sentAt}</DateTimeFormatter>
            </div>
        );
    }

    return (
        <div className={cell}>
            <LabelWithHint label={<Text color="error">{i18n.error}</Text>} hint={despatchAdvice.error || ''} />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    cell: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
    },
}));
