import { withStyles } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';

export const ActionButton = withStyles(theme => ({
    root: {
        height: 40,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        ...theme.customFont.action,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
}))((props: ButtonProps) => <Button variant="outlined" {...props} />);
