import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ArrowDownwardIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <path
                d="M20 12.0013L18.59 10.5913L13 16.1713V4.00125H11V16.1713L5.42 10.5813L4 12.0013L12 20.0013L20 12.0013Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
