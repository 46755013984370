import { FormikTouched } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';

import { IFormNames, LandfillFormValues, useLandfillFormContext } from '../LandfillFormParts';

type LandfillCreateNextButtonProps = {
    fieldsToValidate: Array<IFormNames>;
    goToNextStep: () => void;
};

export const LandfillCreateNextButton = ({ fieldsToValidate, goToNextStep }: LandfillCreateNextButtonProps) => {
    const context = useLandfillFormContext();

    const [needForCheckAndMoveOn, setNeedForCheckAndMoveOn] = useState<boolean>(false);
    const fieldsObject = useMemo(
        () => fieldsToValidate.reduce((o: FormikTouched<LandfillFormValues>, f) => ({ ...o, [f]: true }), {}),
        [fieldsToValidate]
    );

    useEffect(() => {
        if (!needForCheckAndMoveOn) return;

        const hasErrorsOnStep = Object.keys(context?.errors).some(f => fieldsObject[f as IFormNames]);

        !hasErrorsOnStep ? goToNextStep() : setNeedForCheckAndMoveOn(false);
    }, [context, fieldsObject, goToNextStep, needForCheckAndMoveOn]);

    const onClick = () => {
        context.setTouched(fieldsObject);
        setTimeout(() => setNeedForCheckAndMoveOn(true));
    };

    return (
        <Button color="primary" variant="contained" onClick={onClick} disabled={context.isLoading}>
            <LocalizableText code={'LandfillForm.next'} />
        </Button>
    );
};
