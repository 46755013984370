import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import React from 'react';

type Props = {
    children: React.ReactNode;
    onClick: () => unknown;
    disabled?: boolean;
    className?: string;
};

export const ClickableText = ({ children, className, onClick, disabled = false }: Props) => {
    const { active } = useStyles();

    return (
        <div onClick={disabled ? undefined : onClick} className={cn(className, { [active]: !disabled })}>
            {children}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    active: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
}));
