import { formatDistance } from '@common/utils/formaters';
import { isNumber } from '@common/validations/types.validation';
import React from 'react';

import { NullableFormatter } from './NullableFormatter';

type Props = {
    children: number | null | undefined;
};

export const DistanceFormatter = (props: Props) => (
    <NullableFormatter>{isNumber(props.children) ? formatDistance(props.children) : null}</NullableFormatter>
);
