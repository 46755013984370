import './MassCard.scss';

import { StatusColorClasses } from '@common/constants';
import { ContaminationType } from '@common/enums';
import { I18nKeys } from '@common/i18n/types';
import React from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { getContaminationLevelLabel } from '~/@store/toxicLimits';
import { DumpLoadShortFragment } from '~/graphql';
import i18n from '~/i18n';
import { fmtVolInTon } from '~/utils/numbers';

import { GaugeChart } from '../GaugeChart';

export type MassProject = {
    id: string;
};

export type MassInfo = Pick<
    DumpLoadShortFragment,
    'id' | 'amountInTons' | 'deliveredVolume' | 'status' | 'comment' | 'serialNumber' | 'solidTestResult'
> & {
    dumpType: { id: string; name: string } | null;
};

export type MassCardAction = {
    labelCode: I18nKeys;
    onClick: () => void;
    disabled?: boolean;
};

type Props = {
    mass: MassInfo;
    action?: MassCardAction;
};

export const MassCard = ({ mass, action }: Props) => {
    const { className, element } = useFunctionalBem(MassCard);

    const { amountInTons, status, comment } = mass;

    const transported = mass.deliveredVolume || 0;
    const dumpTypeName = mass.dumpType?.name || i18n.NA;
    const solidTestResult = mass.solidTestResult
        ? getContaminationLevelLabel(mass.solidTestResult, ContaminationType.SOLID)
        : null;

    return (
        <div className={className}>
            <div className={element('head')}>
                <div className={element('id')}>{mass.serialNumber}</div>
                <div className={element('status', (status && StatusColorClasses[status]) || '')}>
                    {status ? i18n.DumploadStatusesSochi[status] : i18n.NA}
                </div>
            </div>
            <div className={element('title')}>
                <div className={element('name-container')} title={dumpTypeName}>
                    <div className={element('name')}>{dumpTypeName}</div>
                    {solidTestResult && <div className={element('test-result')}>{solidTestResult}</div>}
                </div>
                <div className={element('comment')} title={comment!}>
                    {comment}
                </div>
            </div>
            <GaugeChart current={transported} total={amountInTons || 0} />
            <div className={element('data')}>
                <div className={element('transported')}>
                    <LocalizableText code={'MassCard.transported'} />
                    <b>{fmtVolInTon(transported)}</b>
                </div>
                <div>
                    <LocalizableText code={'MassCard.ordered'} />
                    <b>{fmtVolInTon(amountInTons || 0)}</b>
                </div>
            </div>

            {action ? (
                <Button color="primary" variant="outlined" onClick={action.onClick} disabled={action.disabled || false}>
                    <LocalizableText code={action.labelCode} />
                </Button>
            ) : null}
        </div>
    );
};
