import { makeStyles } from '@material-ui/core';
import React from 'react';

import { CardListItem } from '~/@components/CardListItem';
import { ReorderingList } from '~/@components/ReorderingList';
import { Text } from '~/@components/Text';
import { ISubstanceChild, useSubstancePermissions, useSubstancesContext } from '~/@store/substances';

type Props = {
    substanceChildren: ISubstanceChild[] | null;
};

export const SubstanceChildrenCards = ({ substanceChildren }: Props) => {
    const { root } = useStyles();

    const { updatePositions, isPositionsUpdating, selectSubstance, selectedSubstance } = useSubstancesContext();

    const { canUpdate } = useSubstancePermissions();

    if (!substanceChildren?.length) return null;

    return (
        <ReorderingList<ISubstanceChild>
            className={root}
            items={substanceChildren}
            onOrderChange={updatePositions}
            disabled={isPositionsUpdating || !canUpdate}>
            {(child, id, reorderButton) => (
                <CardListItem
                    key={id}
                    buttonEnd={reorderButton}
                    isActive={selectedSubstance === child}
                    onClick={() => selectSubstance(child)}
                    isSubItem>
                    <Text variant="BodyRegular">{child.name}</Text>
                </CardListItem>
            )}
        </ReorderingList>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(),
    },
}));
