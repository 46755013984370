import { IconButton } from '@material-ui/core';
import React from 'react';

import { AdminThemeContainer } from '~/@components/@theme';
import { CommentIcon } from '~/@components/Icon';
import { DumpLoadCommentsDialog } from '~/@views/AdminView/DumpLoadsPage/DumpLoadCommentsView';
import { showCustomDialog } from '~/services/dialog';

type Props = { projectId: string; dumpLoadId: string };

export const CommentsDialogButton = ({ projectId, dumpLoadId }: Props) => {
    const showDialog = React.useCallback(
        () =>
            showCustomDialog({
                noContainer: true,
                noModalComponent: true,
                render: closeDialog => (
                    <AdminThemeContainer>
                        <DumpLoadCommentsDialog onClose={closeDialog} projectId={projectId} dumpLoadId={dumpLoadId} />
                    </AdminThemeContainer>
                ),
            }),
        [projectId, dumpLoadId]
    );

    return (
        <IconButton color="primary" onClick={showDialog}>
            <CommentIcon fontSize="inherit" />
        </IconButton>
    );
};
