import { isNullOrUndefined } from '@common/validations/types.validation';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { COLORS } from '~/@components/@theme/colors';
import { Chip } from '~/@components/Chip';
import { removeEmptyKeys } from '~/@store/common';
import { formatFilterValue } from '~/@views/AdminView/common/utils';
import i18n from '~/i18n';

import { ProjectUsersFilterField, projectUsersStore } from './projectUsers.store';

const FieldNames: Record<ProjectUsersFilterField, string> = {
    [ProjectUsersFilterField.firstName]: i18n.name,
    [ProjectUsersFilterField.lastName]: i18n.surName,
    [ProjectUsersFilterField.email]: i18n.email,
    [ProjectUsersFilterField.phone]: i18n.phoneNumber,
    [ProjectUsersFilterField.role]: i18n.role,
};

export function ProjectUsersFilterSummary() {
    const filterValues = useObservable(() => removeEmptyKeys({ ...projectUsersStore.filter.values }));
    const { root, chip } = useStyles();

    return (
        <div className={root}>
            {Object.keys(filterValues).map(objectKey => {
                const key = objectKey as ProjectUsersFilterField;
                const value = filterValues[key];
                if (isNullOrUndefined(value)) return null;

                const chipContent = `${FieldNames[key]}: "${formatFilterValue(value)}"`;

                return (
                    <Chip
                        key={key}
                        className={chip}
                        color={COLORS.black}
                        onDelete={() => projectUsersStore.filter.setValue(key, null)}
                        deleteIcon={<Icon>close</Icon>}>
                        {chipContent}
                    </Chip>
                );
            })}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        marginRight: theme.spacing(1),
    },
}));
