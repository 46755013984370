import { TestId } from '@common/testConstants';
import { ListItemIcon } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { LocalizationModeButton } from '~/@components/Header/LocalizationModeButton';
import { LogoutIcon, MoreVertIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Logo } from '~/@components/Logo';
import { IMenuGroup, IMenuRoute } from '~/@store/routes';

import { GlobalSearch } from '../GlobalSearch';
import { HeaderMenuDropdown } from './HeaderMenuDropdown';

interface Props {
    groups: IMenuGroup[];
    homeRoute: string;
    currentGroup: IMenuGroup | null;
    currentRoute: IMenuRoute | null;
    title: React.ReactNode;
    exitTitle: React.ReactNode;
    onExit: () => void;
    displayedItemsNumber?: number;
}

export const Header = observer(
    ({ groups, currentGroup, currentRoute, homeRoute, title, exitTitle, onExit, displayedItemsNumber = 5 }: Props) => {
        const {
            logoBlock,
            menuButton,
            tabsStyle,
            tabButtons,
            tabButtonStyle,
            toolbarStyle,
            clearLinkStyles,
            moreItemsIcon,
            moreListItem,
            selectedItem,
            twoColumnsLists,
            twoColumnsList,
            rightButton,
        } = useStyles();

        const visibleRoutes = useMemo(() => groups.slice(0, displayedItemsNumber), [groups, displayedItemsNumber]);
        const hiddenRoutes = useMemo(() => groups.slice(displayedItemsNumber, -3), [groups, displayedItemsNumber]);
        const hiddenRoutesRest = useMemo(() => groups.slice(-3), [groups]);

        return (
            <AppBar position="static" elevation={0} data-testid={TestId.Header}>
                <Container maxWidth={false}>
                    <Toolbar className={classNames(toolbarStyle, clearLinkStyles)} disableGutters>
                        <ButtonBase
                            className={logoBlock}
                            component={Link}
                            to={homeRoute}
                            data-testid={TestId.HeaderLogoButton}>
                            <Logo className={menuButton} aria-label="logo" />
                            <Typography variant="h6">{title}</Typography>
                        </ButtonBase>
                        <div className={tabsStyle}>
                            <div aria-label="navigation menu" data-testid={TestId.NavigationMenu}>
                                {visibleRoutes.map(group =>
                                    group.routes.length === 1 ? (
                                        <Button
                                            key={group.routes[0]!.path}
                                            className={classNames(tabButtonStyle, {
                                                [selectedItem]: group === currentGroup,
                                            })}
                                            component={Link}
                                            to={group.routes[0]!.path}>
                                            <LocalizableText code={group.title} />
                                        </Button>
                                    ) : (
                                        <HeaderMenuDropdown
                                            key={group.routes[0]!.path}
                                            button={
                                                <Button
                                                    className={classNames(tabButtonStyle, {
                                                        [selectedItem]: group === currentGroup,
                                                    })}
                                                    component={Link}
                                                    to={group.routes[0]!.path}>
                                                    <LocalizableText code={group.title} />
                                                </Button>
                                            }>
                                            <List
                                                disablePadding
                                                className={clearLinkStyles}
                                                data-testid={TestId.NavigationSubMenu}>
                                                {group.routes.map(route => (
                                                    <ListItem
                                                        key={route.path}
                                                        className={classNames(moreListItem, {
                                                            [selectedItem]: route === currentRoute,
                                                        })}
                                                        button
                                                        component={Link}
                                                        to={route.path}>
                                                        <ListItemText disableTypography>
                                                            <LocalizableText code={route.title} />
                                                        </ListItemText>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </HeaderMenuDropdown>
                                    )
                                )}
                                <HeaderMenuDropdown
                                    button={
                                        <Button className={tabButtonStyle} data-testid={TestId.NavigationMenuMore}>
                                            <MoreVertIcon className={moreItemsIcon} />
                                        </Button>
                                    }>
                                    <div className={twoColumnsLists} data-testid={TestId.NavigationSubMenu}>
                                        {[hiddenRoutes, hiddenRoutesRest].map((routes, idx) => (
                                            <List
                                                key={`list-${idx}`}
                                                disablePadding
                                                className={classNames(clearLinkStyles, twoColumnsList)}>
                                                {routes.map(route => (
                                                    <ListItem
                                                        key={route.routes[0]!.path}
                                                        className={classNames(moreListItem, {
                                                            [selectedItem]: route === currentGroup,
                                                        })}
                                                        button
                                                        component={Link}
                                                        to={route.routes[0]!.path}>
                                                        <ListItemIcon>{route.icon}</ListItemIcon>
                                                        <ListItemText title={route.title} disableTypography>
                                                            <LocalizableText code={route.title} />
                                                        </ListItemText>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ))}
                                    </div>
                                </HeaderMenuDropdown>
                            </div>
                            <GlobalSearch />
                            <div className={tabButtons}>
                                <LocalizationModeButton className={rightButton} />
                                <Button
                                    onClick={onExit}
                                    size="large"
                                    startIcon={<LogoutIcon />}
                                    className={rightButton}
                                    data-testid={TestId.HeaderExitButton}>
                                    {exitTitle}
                                </Button>
                            </div>
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }
);

const useStyles = makeStyles(theme => ({
    clearLinkStyles: {
        '& a:hover, a:focus': {
            textDecoration: 'none',
            outline: 'none',
            color: theme.palette.primary.contrastText,
        },
    },
    twoColumnsLists: {
        display: 'flex',

        '& $moreListItem': {
            width: 'auto',
        },
    },
    twoColumnsList: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,

        '& $moreListItem': {
            width: 'auto',
        },

        '&:nth-child(1)': {
            borderRight: `1px solid ${theme.palette.common.white}`,
        },
        '&:nth-child(2)': {
            justifyContent: 'flex-end',

            '& $moreListItem': {
                minWidth: 'unset',
            },
        },
    },
    toolbarStyle: {
        margin: theme.spacing(0, -1),
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
    },
    rightButton: {
        height: 40,
        flexShrink: 0,
        alignItems: 'center',
        display: 'flex',
        flexFlow: 'row nowrap',
        whiteSpace: 'nowrap',
        lineHeight: 1.5,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(0.5, 1),
        borderRadius: 4,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        '& .MuiButton-startIcon': {
            marginLeft: 1,
        },
        '& .MuiIcon-root': {
            fontSize: 24,
        },
    },
    logoBlock: {
        alignItems: 'center',
        display: 'flex',
        flexFlow: 'row nowrap',
        height: 60,
        marginRight: theme.spacing(2),
        padding: theme.spacing(0, 1),
    },
    menuButton: {
        marginRight: 10,
    },
    tabsStyle: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tabButtons: {
        display: 'flex',
        marginLeft: theme.spacing(4),
        gap: theme.spacing(2),
    },
    tabButtonStyle: {
        fontSize: 16,
        fontWeight: 400,
        minHeight: 60,
        padding: '6px 16px',
        textTransform: 'none',
        opacity: 1,
        color: theme.palette.primary.contrastText,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        '@media (max-width: 1100px)': {
            padding: '6px 5px',
        },
    },
    moreItemsIcon: {
        fontSize: '20px',
        width: 24,
    },
    moreListItem: {
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 400,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.contrastText,
        padding: '13px 27px',
        minWidth: 256,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },

        // To prevent changing of element width when font-weight is changed from 400 to 600
        '& .MuiListItemText-root::after': {
            display: 'block',
            content: 'attr(title)',
            fontWeight: 600,
            height: 0,
            color: 'transparent',
            overflow: 'hidden',
            visibility: 'hidden',
        },
    },
    selectedItem: {
        backgroundColor: theme.palette.secondary.main,
        fontWeight: 600,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
}));
