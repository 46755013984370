import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ArrowForwardIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 22 23" fill="none" {...props}>
            <g clipPath="url(#clip0_41_747)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.25127 21.6323C4.86074 21.2417 4.86074 20.6086 5.25127 20.218L14.6275 10.8418L5.25127 1.46559C4.86074 1.07507 4.86074 0.441904 5.25127 0.0513802C5.64179 -0.339144 6.27496 -0.339144 6.66548 0.0513802L16.7488 10.1347C17.1393 10.5252 17.1393 11.1584 16.7488 11.5489L6.66548 21.6323C6.27496 22.0228 5.64179 22.0228 5.25127 21.6323Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_41_747">
                    <rect width="22" height="22" fill="transparent" transform="translate(0 0.00125122)" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
