import React, { useMemo } from 'react';

import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { DELIVERY_LINE_VIEW_MODE } from '~/@store/deliveryLines/deliveryLines.constants';
import { deliveryLinesStore } from '~/@store/deliveryLines/deliveryLines.store';
import { IDeliveryLine } from '~/@store/deliveryLines/deliveryLines.types';
import { showDeliveryLinesMassEditDialog } from '~/@views/AdminView/common/DeliveryLinesMassEditForm';
import { showDeliveryLineUpdateDialog } from '~/@views/AdminView/DeliveryLinesPage/DeliveryLineEditForm';

type Props = {
    deliveryLine: IDeliveryLine;
};

export const DeliveryLineOrderNumberCell = ({ deliveryLine }: Props) => {
    const disabled = !!deliveryLine.deletedAt;

    const actions: Array<ITableCellAction> = useMemo(
        () => [
            {
                variant: ActionVariant.EDIT,
                onClick: () => showDeliveryLinesMassEditDialog(deliveryLine.orderNumber),
                disabled,
            },
            {
                variant: ActionVariant.CARD,
                onClick: () =>
                    showDeliveryLineUpdateDialog(
                        deliveryLine.id,
                        deliveryLinesStore.mode === DELIVERY_LINE_VIEW_MODE.DELIVERY_LINES ? 'update' : 'complete'
                    ),
                disabled,
            },
        ],
        [deliveryLine, disabled]
    );

    return <TableCellActions actions={actions}>{deliveryLine.orderNumber}</TableCellActions>;
};
