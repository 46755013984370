import React, { FC, useMemo } from 'react';

import { Select } from '~/@components/Select';
import { LandfillQuery_landfill, LandfillQuery_landfill_batches } from '~/graphql';

export type ISimpleBatch = Pick<LandfillQuery_landfill_batches, 'id' | 'name'>;

type Props = {
    landfill: LandfillQuery_landfill;
    selected: ISimpleBatch | null;
    onChange: (batch: ISimpleBatch | null) => void;
    label: React.ReactNode;
    errorMessage?: string | null;
    disabled?: boolean;
};

export const LandfillBatchSelect: FC<Props> = ({
    landfill,
    selected,
    onChange,
    label,
    errorMessage,
    disabled = false,
}) => {
    const options = useMemo(() => landfill.batches.filter(b => !b.closedAt), [landfill]);

    return (
        <Select
            items={options}
            value={selected}
            onChange={onChange}
            keyGetter={v => v.id}
            renderValue={v => v.name}
            addEmptyOption={false}
            label={label}
            disabled={disabled}
            helperText={errorMessage}
            error={Boolean(errorMessage)}
        />
    );
};
