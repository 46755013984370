import './UserMenu.scss';

import { adminRoutes, routes } from '@common/routes';
import React, { MouseEventHandler, ReactNode, SyntheticEvent, useMemo, VFC } from 'react';
import { Link } from 'react-router-dom';

import { QuestionIcon, TranslateIcon, VisibilityIcon, VisibilityOffIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { LogOutIcon, UserRoundFilledIcon, UsersRoundFilledIcon } from '~/@sochi-components/Icons';
import { useLocalizationEditToggle, useSupportChatContext } from '~/@store/common';
import { getReleaseNotesByType } from '~/@store/releaseNotes';
import { showUserReleaseNoteDialog } from '~/@views/UserView/common/UserReleaseNoteDialog';
import { Account } from '~/@views/UserView/UserViewLayout/Account';
import { useUserAbilities } from '~/contexts';
import { ReleaseNoteType } from '~/graphql';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';
import { canGetUserList, canUpdateLocalization, removeAuthToken } from '~/utils/auth';

export enum ItemType {
    Link = 'Link',
    Action = 'Action',
}

export type UserMenuItem = {
    action:
        | { type: ItemType.Link; link: string; action?: () => void }
        | { type: ItemType.Action; action: MouseEventHandler };
    title: ReactNode;
    icon: ReactNode;
    visible: boolean;
};

export const UserMenu: VFC<{ onMouseLeave: () => void }> = ({ onMouseLeave }) => {
    const { className, element } = useFunctionalBem(UserMenu);
    const abilityContext = useUserAbilities();
    const { enabled, change } = useLocalizationEditToggle();
    const { chatHidden, handleToggleChat } = useSupportChatContext();

    const userMenuItems: UserMenuItem[] = useMemo(
        () => [
            {
                action: {
                    type: ItemType.Action,
                    action: (e: SyntheticEvent) => {
                        e.preventDefault();
                        const ignoredPromise = showCustomDialog({
                            render: closeDialog => <Account onClose={closeDialog} />,
                        });
                    },
                },
                title: <LocalizableText code={'account'} />,
                icon: <UserRoundFilledIcon color="primary" fontSize="inherit" />,
                visible: true,
            },
            {
                action: { type: ItemType.Link, link: adminRoutes.users },
                title: <LocalizableText code={'users'} />,
                icon: <UsersRoundFilledIcon color="primary" fontSize="inherit" />,
                visible: canGetUserList(abilityContext),
            },
            {
                action: {
                    type: ItemType.Action,
                    action: (e: SyntheticEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                        change(!enabled);
                    },
                },
                title: i18n.localizationMode,
                icon: <TranslateIcon color={enabled ? 'error' : 'primary'} fontSize="inherit" />,
                visible: canUpdateLocalization(abilityContext),
            },
            {
                action: {
                    type: ItemType.Action,
                    action: (e: SyntheticEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleToggleChat();
                    },
                },
                title: chatHidden ? i18n.showSupportChat : i18n.hideSupportChat,
                icon: chatHidden ? (
                    <VisibilityOffIcon color="disabled" fontSize="inherit" />
                ) : (
                    <VisibilityIcon color="primary" fontSize="inherit" />
                ),
                visible: true,
            },
            {
                action: {
                    type: ItemType.Action,
                    action: e => {
                        e.preventDefault();
                        getReleaseNotesByType(ReleaseNoteType.CUSTOMER).then(([first, ...rest]) => {
                            if (first) {
                                showUserReleaseNoteDialog(first, rest);
                            }
                        });
                    },
                },
                title: <LocalizableText code={'ReleaseNotes.whatsNew'} />,
                icon: <QuestionIcon color="primary" fontSize="inherit" />,
                visible: true,
            },
            {
                action: { type: ItemType.Link, link: routes.login, action: () => removeAuthToken() },
                title: <LocalizableText code={'signout'} />,
                icon: <LogOutIcon color="primary" fontSize="inherit" />,
                visible: true,
            },
        ],
        [abilityContext, change, enabled, handleToggleChat, chatHidden]
    );

    return (
        <div className={className} onMouseLeave={onMouseLeave}>
            <ul className={element('list')}>
                {userMenuItems.map(
                    (item, index) =>
                        item.visible && (
                            <li className={element('list-item')} key={index}>
                                {item.action.type === ItemType.Link ? (
                                    <Link to={item.action.link} onClick={item.action.action}>
                                        <span className={element('list-item-icon')}>{item.icon}</span>
                                        {item.title}
                                    </Link>
                                ) : (
                                    <a href="/#" role="button" tabIndex={0} onClick={item.action.action}>
                                        <span className={element('list-item-icon')}>{item.icon}</span>
                                        {item.title}
                                    </a>
                                )}
                            </li>
                        )
                )}
            </ul>
        </div>
    );
};
