import React, { useCallback } from 'react';

import { ClickableText } from '~/@components/ClickableText/ClickableText';
import { TwoLinesFormatter } from '~/@components/Formatters';
import { TableCellPlaceholder } from '~/@components/Table/TableCellPlaceholder';
import { showCustomDialog } from '~/@store/common';
import i18n from '~/i18n';

import { UserCommentsDialog } from '../UserComments';

type Props = {
    user: { id: string; lastCommentText?: string | null };
    disabled?: boolean;
};

export const UserCommentsCell = ({ user, disabled = false }: Props) => {
    const openComments = useCallback(() => {
        const ignoredPromise = showCustomDialog({
            render: closeDialog => <UserCommentsDialog onClose={closeDialog} userId={user.id} />,
        });
    }, [user]);

    return (
        <ClickableText onClick={openComments} disabled={disabled}>
            {user.lastCommentText ? (
                <TwoLinesFormatter>{user.lastCommentText}</TwoLinesFormatter>
            ) : (
                <TableCellPlaceholder startText="+">{i18n.addComment}</TableCellPlaceholder>
            )}
        </ClickableText>
    );
};
