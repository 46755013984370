import React from 'react';

const DisableContext = React.createContext<boolean>(false);

export const useDisable = () => React.useContext(DisableContext);

export const DisableProvider: React.FC<{ disabled: boolean }> = ({ children, disabled }) => {
    const upperDisabled = useDisable();

    return <DisableContext.Provider value={upperDisabled || disabled}>{children}</DisableContext.Provider>;
};
