import { ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { useCallback } from 'react';

import { ChevronRightIcon } from '~/@sochi-components/Icons';
import { SortInput } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    fieldName: string;
    sort: SortInput | null | void;
    setSort: (v: SortInput) => void;
    labels?: { ascending: string; descending: string };
};

export const SochiSortSection = ({ fieldName, sort, setSort, labels = i18n.SortString }: Props) => {
    const handleSortAZ = useCallback(() => {
        setSort({ field: fieldName, direction: 1 });
    }, [setSort]);

    const handleSortZA = useCallback(() => {
        setSort({ field: fieldName, direction: -1 });
    }, [setSort]);

    if (!setSort) return null;

    return (
        <>
            <ListItem divider>
                <ListItemText>{i18n.sortBy}:</ListItemText>
            </ListItem>
            <ListItem
                selected={sort?.field === fieldName && sort?.direction === 1}
                divider
                button
                onClick={handleSortAZ}>
                <ListItemText>{labels.ascending}</ListItemText>
                <ListItemSecondaryAction>
                    <ChevronRightIcon />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem
                selected={sort?.field === fieldName && sort?.direction === -1}
                divider
                button
                onClick={handleSortZA}>
                <ListItemText>{labels.descending}</ListItemText>
                <ListItemSecondaryAction>
                    <ChevronRightIcon />
                </ListItemSecondaryAction>
            </ListItem>
        </>
    );
};
