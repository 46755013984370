import { makeStyles } from '@material-ui/core';
import clamp from 'lodash/clamp';
import React, { useCallback, useEffect } from 'react';

import { DownloadIcon, RotateLeftIcon, RotateRightIcon, ZoomInIcon, ZoomOutIcon } from '~/@components/Icon';
import { saveToClientBase64 } from '~/utils/files';

import { ControlButtons } from './ControlButtons';

type Props = {
    imageSrc: string;
    filename: string;
};

type State = {
    x: number;
    y: number;
    zoom: number;
    rotate: number;
    imgOrientation: 'vertical' | 'horizontal';
};

type SyncState = {
    moving: boolean;
    startX: number;
    startY: number;
    animated: boolean;
};

const MIN_ZOOM = 0.7;
const MAX_ZOOM = 5;
const STEP_ZOOM = 0.3;

export const ImgPreview = ({ imageSrc, filename }: Props) => {
    const syncStateRef = React.useRef<SyncState>({
        animated: false,
        moving: false,
        startX: 0,
        startY: 0,
    });

    const syncState = syncStateRef.current;

    const [state, setState] = React.useState<State>({
        x: 0,
        y: 0,
        zoom: 1,
        rotate: 0,
        imgOrientation: 'vertical',
    });

    const onImageLoad: React.ReactEventHandler<HTMLImageElement> = ({ target: t }) => {
        const target = t as HTMLImageElement | null;
        if (target && target.offsetHeight && target.offsetWidth) {
            setState(state => ({
                ...state,
                imgOrientation: target.offsetHeight > target.offsetWidth ? 'vertical' : 'horizontal',
            }));
        }
    };

    const handleWheel = ({ deltaY }: { deltaY: number }) => {
        const { zoom } = state;
        let newZoom = zoom * (1 - STEP_ZOOM * Math.sign(deltaY));
        newZoom = clamp(newZoom, MIN_ZOOM, MAX_ZOOM);
        setState(state => ({ ...state, zoom: newZoom }));
    };

    const handleMouseDown: React.MouseEventHandler<HTMLImageElement> = event => {
        event.preventDefault();
        const { clientX, clientY } = event;
        const { x, y } = state;
        syncState.moving = true;
        syncState.startX = clientX - x;
        syncState.startY = clientY - y;
    };

    const handleMouseMove: React.MouseEventHandler<HTMLImageElement> = event => {
        if (!syncState.moving) return;
        const { clientX, clientY } = event;
        const difX = clientX - syncState.startX;
        const difY = clientY - syncState.startY;

        !syncState.animated &&
            requestAnimationFrame(() => {
                syncState.animated = false;
                setState(state => ({ ...state, x: difX, y: difY }));
            });
        syncState.animated = true;
    };

    const handleMouseUp = useCallback(() => {
        syncState.moving = false;
        syncState.startX = 0;
        syncState.startY = 0;
    }, [syncState]);

    useEffect(() => {
        window.addEventListener('mouseup', handleMouseUp);

        return () => window.removeEventListener('mouseup', handleMouseUp);
    }, [handleMouseUp]);

    const handleRotate = (direction: number) => {
        setState(state => ({ ...state, rotate: state.rotate + direction }));
    };

    const orientationRelatedProps = state.imgOrientation === 'horizontal' ? { width: '90%' } : { height: '90%' };

    const { root } = useStyles();

    return (
        <div className={root}>
            <img
                alt="preview"
                onLoad={onImageLoad}
                onWheel={handleWheel}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                src={imageSrc}
                style={getImgStyle(state)}
                {...orientationRelatedProps}
            />
            <ControlButtons
                buttons={[
                    { icon: RotateLeftIcon, onClick: () => handleRotate(-1) },
                    { icon: ZoomOutIcon, onClick: () => handleWheel({ deltaY: 1 }) },
                    { icon: ZoomInIcon, onClick: () => handleWheel({ deltaY: -1 }) },
                    { icon: RotateRightIcon, onClick: () => handleRotate(1) },
                    null,
                    { icon: DownloadIcon, onClick: () => saveToClientBase64(imageSrc, filename) },
                ]}
            />
        </div>
    );
};

function getImgStyle({ x, y, zoom, rotate }: State) {
    return {
        cursor: 'grab',
        transformOrigin: 'center',
        transform: `translate3d(${x}px,${y}px,0) scale3d(${zoom},${zoom},1) rotate(${rotate * 90}deg)`,
    };
}

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
