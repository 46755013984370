import { CircularProgress, LinearProgress, makeStyles } from '@material-ui/core';
import React from 'react';

import { COLORS } from '~/@components/@theme/colors';

type Props = {
    show: boolean;
    variant?: 'circular' | 'linear';
};

export const FadeProgress = ({ show, variant = 'circular' }: Props) => {
    const { root, progressStyles } = useStyles({ show, variant });

    const ProgressComponent = variant === 'circular' ? CircularProgress : LinearProgress;

    return (
        <div className={root}>
            <ProgressComponent color="primary" className={progressStyles} />
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: ({ show }: Props) => ({
        display: show ? 'block' : 'none',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: COLORS.layoutDarker,
        zIndex: 100,
    }),
    progressStyles: ({ variant }: Props) =>
        variant === 'circular'
            ? {
                  position: 'relative',
                  top: '40%',
                  left: '50%',
                  zIndex: 101,
              }
            : {
                  zIndex: 101,
              },
}));
