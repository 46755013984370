import { isValidOrgNr, validateGLN, validateUserPhoneNumber } from '@common/validations/common.validation';
import { FormikConfig, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { IComment } from '~/@store/comments';
import { IDetailedCompany, UpdateCompanyFunc } from '~/@store/companies';
import { errorCodes } from '~/config/enum';
import { CompanyUpdate } from '~/graphql';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';

export type ICompanyFormValues = {
    organizationNumber: string;
    customerNumber: string;
    name: string;
    phone: string;
    phone2: string;
    email: string;
    web: string;
    contactPerson: string;
    contactPerson2: string;
    address: string;
    address2: string;
    zipCode: string;
    city: string;
    country: string;
    comment: string;
    comments: IComment[];
    active: boolean;
    APIKey: string;
    isTransportCompany: boolean;
    GLN: string;
};

export const useCompanyFormikContext = () => useFormikContext<ICompanyFormValues>();

export function getCompanyFormikConfig(
    company: IDetailedCompany,
    updateCompany: UpdateCompanyFunc
): FormikConfig<ICompanyFormValues> {
    return {
        initialValues: {
            organizationNumber: company?.organizationNumber || '',
            customerNumber: company?.customerNumber || '',
            name: company?.name || '',
            phone: company?.phone || '',
            phone2: company?.phone2 || '',
            email: company?.email || '',
            web: company?.web || '',
            contactPerson: company?.contactPerson || '',
            contactPerson2: company?.contactPerson2 || '',
            address: company?.address || '',
            address2: company?.address2 || '',
            zipCode: company?.zipCode || '',
            city: company?.city || '',
            country: company?.country || '',
            comment: company?.comment || '',
            comments: company?.comments || [],
            active: company?.active || false,
            APIKey: company?.APIKey || '',
            isTransportCompany: company?.isTransportCompany || false,
            GLN: company?.GLN || '',
        },
        validationSchema: Yup.object().shape({
            organizationNumber: Yup.string()
                .trim()
                .required(i18n.CompanyCard.organizationNumberRequired)
                .test('Organization number validation', i18n.errorCodes.INVALID_ORGANIZATION_NUMBER, isValidOrgNr),
            name: Yup.string().required(i18n.CompanyCard.nameRequired),
            email: Yup.string().nullable().email(i18n.CompanyCard.invalidEmail),
            phone: Yup.string()
                .trim()
                .nullable()
                .test('Company phone number validation', '', function (phoneNumber) {
                    const errorCode = validateUserPhoneNumber(phoneNumber);

                    if (errorCode)
                        return this.createError({
                            path: 'phone',
                            message: i18n.errorCodes[errorCode] || i18n.errorCodes[errorCodes.INVALID_INPUT],
                        });

                    return true;
                }),
            phone2: Yup.string()
                .trim()
                .nullable()
                .test('Company phone number 2 validation', '', function (phoneNumber) {
                    const errorCode = validateUserPhoneNumber(phoneNumber);

                    if (errorCode)
                        return this.createError({
                            path: 'phone2',
                            message: i18n.errorCodes[errorCode] || i18n.errorCodes[errorCodes.INVALID_INPUT],
                        });

                    return true;
                }),
            GLN: Yup.string()
                .trim()
                .nullable()
                .test('GLN validation', '', function (GLN) {
                    if (!GLN) return true;

                    const errorCode = validateGLN(GLN);

                    if (errorCode)
                        return this.createError({
                            path: 'GLN',
                            message: i18n.errorCodes[errorCode] || i18n.errorCodes[errorCodes.INVALID_INPUT],
                        });

                    return true;
                }),
        }),
        onSubmit: async (values, { resetForm }) => {
            const {
                organizationNumber,
                name,
                phone,
                phone2,
                email,
                web,
                contactPerson,
                contactPerson2,
                address,
                address2,
                zipCode,
                city,
                country,
                comment,
                active,
                isTransportCompany,
                GLN,
            } = values;

            const input: CompanyUpdate = {
                id: company.id,
                organizationNumber,
                name,
                phone,
                phone2,
                email,
                web,
                contactPerson,
                contactPerson2,
                address,
                address2,
                zipCode,
                city,
                country,
                comment,
                active,
                isTransportCompany,
                GLN,
            };

            await updateCompany(input);

            globalMessage.success(i18n.CompaniesTable.companySaveSuccess);

            resetForm();
        },
    };
}
