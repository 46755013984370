import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import {
    ISochiTableHeadCellProps,
    SochiNumberFilter,
    SochiSortSection,
    SochiStringFilter,
    SochiTableHeadCell,
} from '~/@sochi-components/SochiTable/SochiTableHeadCell';
import { SochiDateFilter } from '~/@sochi-components/SochiTable/SochiTableHeadCell/SochiDateFilter';
import {
    LandfillWeightReceiptFilterField,
    landfillWeightReceiptsStore,
} from '~/@user-store/weightReceipts/landfillWeightReceipts.store';
import { SortInput } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    fieldName: LandfillWeightReceiptFilterField;
    withFilter?: boolean;
    withSort?: boolean;
} & Omit<ISochiTableHeadCellProps, 'sortDirection' | 'children'>;

export const LandfillWeightReceiptTableHeadCell = ({ fieldName, withFilter, withSort, ...restProps }: Props) => {
    const sortValue = useObservable(() => landfillWeightReceiptsStore.sort.value);
    const setSortValue = useCallback((v: SortInput) => landfillWeightReceiptsStore.sort.setValue(v), []);

    const sortLabels = useMemo(() => {
        switch (fieldName) {
            case LandfillWeightReceiptFilterField.weight:
                return i18n.SortNumber;
            case LandfillWeightReceiptFilterField.serialNumber:
                return i18n.SortLowHigh;
            case LandfillWeightReceiptFilterField.plannedStartDate:
                return i18n.SortDate;
            default:
                return i18n.SortString;
        }
    }, [fieldName]);

    const sortSection = useMemo(() => {
        if (!withSort) return null;

        return <SochiSortSection fieldName={fieldName} sort={sortValue} setSort={setSortValue} labels={sortLabels} />;
    }, [withSort, fieldName, sortValue, setSortValue, sortLabels]);

    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        switch (fieldName) {
            case LandfillWeightReceiptFilterField.serialNumber:
            case LandfillWeightReceiptFilterField.projectName:
            case LandfillWeightReceiptFilterField.massCategory:
            case LandfillWeightReceiptFilterField.solidTestResult:
            case LandfillWeightReceiptFilterField.truckRegistrationNumber:
            case LandfillWeightReceiptFilterField.landfillInvoiceNumber:
                return (
                    <SochiStringFilter
                        valueGetter={() => landfillWeightReceiptsStore.filter.values[fieldName]}
                        setValue={v => landfillWeightReceiptsStore.filter.setValue(fieldName, v)}
                    />
                );
            case LandfillWeightReceiptFilterField.weight:
                return (
                    <SochiNumberFilter
                        valueGetter={() => landfillWeightReceiptsStore.filter.values[fieldName]}
                        setValue={v => landfillWeightReceiptsStore.filter.setValue(fieldName, v)}
                    />
                );
            case LandfillWeightReceiptFilterField.plannedStartDate:
                return (
                    <SochiDateFilter
                        valueGetter={() => landfillWeightReceiptsStore.filter.values[fieldName]}
                        setValue={v => landfillWeightReceiptsStore.filter.setValue(fieldName, v)}
                    />
                );

            default:
                return null;
        }
    }, [withFilter, fieldName]);

    return (
        <SochiTableHeadCell {...restProps} sortDirection={sortValue?.field === fieldName ? sortValue.direction : null}>
            {sortSection}
            {filterSection}
        </SochiTableHeadCell>
    );
};
