import React from 'react';

import { Button } from '~/@components/Button';
import { showConfirmDialog } from '~/@store/common';
import { generateFakeFiles } from '~/@store/database';

export const GenerateFakeFilesButton = () => {
    const [progress, setProgress] = React.useState<string | null>(null);

    const onClick = async () => {
        const confirmed = await showConfirmDialog({
            title: 'Warning!',
            render: () =>
                'This action will permanently delete ALL documents on server and generate fake files instead. ' +
                'Are you sure you want to proceed?',
            okLabel: 'Lets burn it!!!',
        });

        if (!confirmed) return;

        setProgress('In progress...');
        generateFakeFiles(setProgress);
    };

    return (
        <Button variant="contained" color="secondary" onClick={onClick} disabled={!!progress}>
            {progress || 'Generate fake files'}
        </Button>
    );
};
