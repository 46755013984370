import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

import { COLORS } from '../@theme/colors';

export const SendIcon = ({ stroke = COLORS.white, ...props }: SvgIconProps) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" stroke={stroke} {...props}>
            <path d="M22 2L11 13" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path
                d="M22 2L15 22L11 13L2 9L22 2Z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </SvgIcon>
    );
};
