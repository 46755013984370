/// <reference types="@types/google.maps" />
/*global google*/

import { ProjectStatus } from '@common/enums';

import type {
    ProjectsAndLandfillsQuery_landfills,
    ProjectsAndLandfillsQuery_projects,
    ProjectsAndLandfillsQuery_projects_dumpLoads,
    ProjectsAndLandfillsQuery_projects_dumpLoads_destinationLandfill,
} from '~/graphql';

export enum LandfillType {
    OWN = 'OWN',
    EXTERNAL = 'EXTERNAL',
}

export enum ProjectType {
    OWN = 'OWN',
    EXTERNAL = 'EXTERNAL',
}

export enum HomePageMode {
    ADMIN = 'ADMIN',
    USER = 'USER',
}

export type IProject = {
    id: string;
    name: string;
    location: ProjectsAndLandfillsQuery_projects['location'];
    status: ProjectStatus;
    customer?: {
        name: string;
    } | null;
    dumpLoads?: IDumpLoad[];
    type: ProjectType;
};

export type IDestinationLandfill = Omit<ProjectsAndLandfillsQuery_projects_dumpLoads_destinationLandfill, '__typename'>;

export type ILandfill = ProjectsAndLandfillsQuery_landfills;

export type IDumpLoad = Omit<ProjectsAndLandfillsQuery_projects_dumpLoads, '__typename' | 'destinationLandfill'> & {
    destinationLandfill: IDestinationLandfill | null;
};

export type ILandfillDumpLoad = IDumpLoad & {
    projectName: string;
};

export type IOwnLandfill = {
    type: LandfillType.OWN;
} & ILandfill;

export type IExternalLandfill = {
    type: LandfillType.EXTERNAL;
} & IDestinationLandfill;

export type IHomePageLandfill = IOwnLandfill | IExternalLandfill;

export type IHomePageLandfillDistance = {
    landfill: IHomePageLandfill;
    distance: number;
};

export type GoogleMapsTravelMode = google.maps.TravelMode;

export const GoogleMapsRouteStatuses = { OK: 'OK' };

export const GoogleMapsMapTypeIds = { HYBRID: 'hybrid' };

export const GoogleMapsControlPosition = { TOP_RIGHT: 3 };

export const GoogleMapsMapTypeControlStyle = { DROPDOWN_MENU: 2 };

export type GoogleMapsLocation = google.maps.LatLngLiteral;

export type GoogleMapsMouseEvent = google.maps.MapMouseEvent;

export type GoogleMapsMap = google.maps.Map;

export type GoogleMapsDirectionResult = google.maps.DirectionsResult;

export type GoogleMapsDirectionService = google.maps.DirectionsService;
