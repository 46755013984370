import {
    IconButton,
    InputAdornment,
    TextField as TextFieldMUI,
    TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import React, { ForwardedRef, forwardRef } from 'react';

import { useDisable } from '~/@components/@hooks';
import { CopyIcon } from '~/@components/Icon';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';

export type TextFieldProps = MuiTextFieldProps & {
    copyable?: boolean;
};

export const TextField = forwardRef(
    ({ copyable, disabled, ...restProps }: TextFieldProps, ref: ForwardedRef<HTMLDivElement>) => (
        <TextFieldMUI
            disabled={useDisable() || disabled}
            ref={ref}
            InputProps={{
                endAdornment: copyable ? (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() =>
                                navigator.clipboard
                                    .writeText((restProps.value as string) || '')
                                    .then(() => globalMessage.success(i18n.textCopied))
                            }>
                            <CopyIcon />
                        </IconButton>
                    </InputAdornment>
                ) : undefined,
            }}
            {...restProps}
        />
    )
);
