import React from 'react';

import { CardListItem } from '~/@components/CardListItem';
import { Text } from '~/@components/Text';
import { ISubstance, useSubstancesContext } from '~/@store/substances';

import { SubstanceChildrenCards } from './SubstanceChildrenCards';

type Props = {
    substance: ISubstance;
    reorderButton: React.ReactNode;
};

export const SubstanceCard = ({ substance, reorderButton }: Props) => {
    const { selectedSubstance, selectSubstance } = useSubstancesContext();

    return (
        <div>
            <CardListItem
                buttonEnd={reorderButton}
                isActive={substance === selectedSubstance}
                onClick={() => selectSubstance(substance)}>
                <Text variant="BodyRegular">{substance.name}</Text>
            </CardListItem>
            <SubstanceChildrenCards substanceChildren={substance.children} />
        </div>
    );
};
