import { gql } from '@apollo/client';

import { CommentFragment } from '~/@store/comments';
import { PageInfoFragment } from '~/@store/common/common.queries';
import {
    ProjectDetailedFragment,
    ProjectIdDumpLoadIdAnalysisFilesFragment,
} from '~/@store/projects/projects.queries.fragment';

import {
    DestinationFragment,
    DumpLoadDeclarationFragment,
    DumpLoadDetailedFragment,
    DumpLoadProjectFragment,
    DumpLoadShortFragment,
} from './dumpLoads.queries.fragment';

export const AdminDumpLoadsPagedQuery = gql`
    query AdminDumpLoadsPagedQuery($filter: DumpLoadsFilter, $sort: SortInput, $connection: ConnectionInput) {
        dumpLoadsPaged(filter: $filter, sort: $sort, connection: $connection) {
            edges {
                cursor
                node {
                    ...DumpLoadShortFragment
                    ...DumpLoadProjectFragment
                }
            }
            pageInfo {
                ...PageInfoFragment
            }
            pageCount
            totalCount
        }
    }

    ${DumpLoadShortFragment}
    ${DumpLoadProjectFragment}
    ${PageInfoFragment}
`;

export const AdminDumpLoadsPagedItemQuery = gql`
    query AdminDumpLoadsPagedItemQuery($projectId: ID!, $dumpLoadId: ID!) {
        projectDumpload(projectId: $projectId, dumploadId: $dumpLoadId) {
            ...DumpLoadShortFragment
            ...DumpLoadProjectFragment
        }
    }

    ${DumpLoadShortFragment}
    ${DumpLoadProjectFragment}
`;

export const AdminDetailedDumpLoadQuery = gql`
    query AdminDetailedDumpLoadQuery($projectId: ID!, $dumpLoadId: ID!) {
        projectDumpload(projectId: $projectId, dumploadId: $dumpLoadId) {
            ...DumpLoadDetailedFragment
            ...DumpLoadProjectFragment
        }
    }

    ${DumpLoadDetailedFragment}
    ${DumpLoadProjectFragment}
`;

export const AdminDetailedDumpLoadByNumberQuery = gql`
    query AdminDetailedDumpLoadByNumber($serialNumber: String!) {
        dumpLoadByNumber(serialNumber: $serialNumber) {
            ...DumpLoadDetailedFragment
            ...DumpLoadProjectFragment
        }
    }

    ${DumpLoadDetailedFragment}
    ${DumpLoadProjectFragment}
`;

export const calculateDestinationResultQuery = gql`
    query calculateDestinationResult(
        $projectId: ID!
        $input: DumpLoadDeliveryInfoInput!
        $substances: [SubstanceItemInput!]
    ) {
        calculateDestinationResult(projectId: $projectId, input: $input, substances: $substances) {
            infoCode
            destinations {
                ...DestinationFragment
            }
        }
    }

    ${DestinationFragment}
`;

export const DumpLoadDeclarationQuery = gql`
    query DumpLoadDeclarationQuery($projectId: ID!, $projectLoadId: ID!) {
        projectLoadDeclaration(projectId: $projectId, projectLoadId: $projectLoadId) {
            ...DumpLoadDeclarationFragment
        }
    }

    ${DumpLoadDeclarationFragment}
`;

export const AddDumpLoadCommentMutation = gql`
    mutation AddDumpLoadCommentMutation($projectId: ID!, $dumpLoadId: ID!, $text: String!) {
        projectLoadAddComment(projectId: $projectId, dumpLoadId: $dumpLoadId, text: $text) {
            id
            lastCommentText
            comments {
                ...CommentFragment
            }
            project {
                id
                ver
            }
        }
    }

    ${CommentFragment}
`;

export const DumpLoadUpdateMutation = gql`
    mutation DumpLoadUpdateMutation($projectId: ID!, $input: ProjectLoadInput!) {
        projectLoadUpdate(projectId: $projectId, input: $input) {
            dumpLoads {
                ...DumpLoadShortFragment
                ...DumpLoadProjectFragment
            }
        }
    }

    ${DumpLoadShortFragment}
    ${DumpLoadProjectFragment}
`;

export const DumpLoadCreateMutation = gql`
    mutation DumpLoadCreateMutation($projectId: ID!, $input: ProjectLoadInput!) {
        projectLoadCreate(projectId: $projectId, input: $input) {
            id
            status
            dumpLoads {
                id
            }
            ver
        }
    }
`;

export const DumpLoadsUpdateStatusMutation = gql`
    mutation DumpLoadsUpdateStatusMutation($input: DumpLoadsStatusUpdateInput!) {
        projectLoadsUpdateStatus(input: $input) {
            id
            status
            ver
            dumpLoads {
                id
                status
                dumpType {
                    id
                    name
                }
                solidTestResult
                comment
                amountInTons
                priceData {
                    landfillPrice
                }
            }
        }
    }
`;

export const DumpLoadDeclarationUpdateMutation = gql`
    mutation DumpLoadDeclarationUpdateMutation(
        $projectId: ID!
        $projectLoadId: ID!
        $input: ProjectLoadDeclarationInput!
    ) {
        projectLoadDeclarationUpdate(projectId: $projectId, projectLoadId: $projectLoadId, input: $input) {
            ...DumpLoadDeclarationFragment
        }
    }

    ${DumpLoadDeclarationFragment}
`;

export const DumpLoadSendForSignMutation = gql`
    mutation DumpLoadSendForSignMutation($projectId: ID!, $dumpLoadId: ID!) {
        sendDumpLoadForSign(projectId: $projectId, dumpLoadId: $dumpLoadId) {
            ...ProjectDetailedFragment
        }
    }

    ${ProjectDetailedFragment}
`;

export const DumpLoadUploadAnalysisMutation = gql`
    mutation DumpLoadUploadAnalysisMutation($projectId: ID!, $dumpLoadId: ID!, $file: Upload!) {
        projectLoadUploadAnalysis(projectId: $projectId, dumpLoadId: $dumpLoadId, file: $file) {
            ...ProjectIdDumpLoadIdAnalysisFilesFragment
        }
    }

    ${ProjectIdDumpLoadIdAnalysisFilesFragment}
`;

export const DumpLoadRemoveAnalysisMutation = gql`
    mutation DumpLoadRemoveAnalysisMutation($projectId: ID!, $dumpLoadId: ID!, $fileId: String!) {
        projectLoadRemoveAnalysis(projectId: $projectId, dumpLoadId: $dumpLoadId, fileId: $fileId) {
            ...ProjectIdDumpLoadIdAnalysisFilesFragment
        }
    }

    ${ProjectIdDumpLoadIdAnalysisFilesFragment}
`;

export const AdminDumpLoadDestinationUpdateMutation = gql`
    mutation AdminDumpLoadDestinationUpdateMutation($input: DestinationInput!) {
        projectLoadUpdateDestination(input: $input) {
            dumpLoads {
                ...DumpLoadShortFragment
                ...DumpLoadProjectFragment
            }
        }
    }

    ${DumpLoadShortFragment}
    ${DumpLoadProjectFragment}
`;

export const DumpLoadSearchQuery = gql`
    query DumpLoadSearch($filter: DumpLoadsFilter!, $limit: Int!) {
        dumpLoadsPaged(filter: $filter, connection: { first: $limit }) {
            edges {
                cursor
                node {
                    id
                    serialNumber
                    status
                }
            }
            totalCount
        }
    }
`;

export const AdminDespatchAdviceSettingsQuery = gql`
    query AdminDespatchAdviceSettingsQuery($orderNumber: String!) {
        despatchAdviceSettingsForDumpLoad(orderNumber: $orderNumber) {
            id
            category
            consignee {
                id
                name
                organizationNumber
            }
            despatchParty {
                id
                name
                organizationNumber
            }
            buyer {
                id
                name
                organizationNumber
            }
            seller {
                id
                name
                organizationNumber
            }
            pricePerTon
        }
    }
`;

export const AdminCheckConnectedToPeppolQuery = gql`
    query AdminCheckConnectedToPeppolQuery($companyId: ID!) {
        connectedToPeppol(companyId: $companyId)
    }
`;

export const ApplyDespatchAdviceSettingsMutation = gql`
    mutation ApplyDespatchAdviceSettingsMutation($orderNumber: String!, $settings: [DespatchAdviceSettingsInput!]!) {
        applyDespatchAdviceSettingsForDumpLoad(orderNumber: $orderNumber, settings: $settings) {
            id
        }
    }
`;
