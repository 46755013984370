import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { CompanyRemoveMutation, CompanyRemoveMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../companies.queries';

export type RemoveCompanyFunc = (id: string) => Promise<unknown>;

export const useDeleteCompanyMutation = (): [RemoveCompanyFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<CompanyRemoveMutation, CompanyRemoveMutationVariables>(
        queries.CompanyRemoveMutation
    );

    const deleteCompany = useCallback<RemoveCompanyFunc>(
        (id: string) => {
            return callMutation({
                variables: { id },
                refetchQueries: [{ query: queries.CompaniesPagedQuery }],
            }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [deleteCompany, mutationResult.loading];
};
