import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { LandfillInput, LandfillUpdateMutation, LandfillUpdateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../landfills.queries';
import { landfillsStore } from '../landfills.store';
import type { IDetailedLandfill } from '../landfills.types';

type UpdateLandfillFunc = (input: LandfillInput) => Promise<IDetailedLandfill>;

export const useLandfillUpdateMutation = (landfill: { id: string }): [UpdateLandfillFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<LandfillUpdateMutation, LandfillUpdateMutationVariables>(
        queries.LandfillUpdateMutation
    );

    const updateLandfill = useCallback<UpdateLandfillFunc>(
        input => {
            const { id } = landfill;

            return callMutation({
                variables: { input: { id, ...input } },
                refetchQueries: [
                    {
                        query: queries.AdminLandfillsPagedQuery,
                        variables: landfillsStore.landfillsQueryVariables,
                    },
                    {
                        query: queries.AdminLandfillQuery,
                        variables: { id },
                    },
                ],
            })
                .then(result => result.data?.landfillUpdate || Promise.reject(new Error('Empty response')))
                .catch(handleApolloError);
        },
        [landfill, callMutation]
    );

    return [updateLandfill, mutationResult.loading];
};
