import { FormHelperText, ListItemText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect, { SelectProps } from '@material-ui/core/Select';
import { uniqueId } from 'lodash';
import React, { ReactNode, useCallback, useMemo } from 'react';

import { useDisable } from '~/@components/@hooks';
import i18n from '~/i18n';

const noWrapAndEllipsisProperty = { noWrap: true };

export type ISelectProps<TValue> = Omit<SelectProps, 'onChange' | 'renderValue'> & {
    items: Array<TValue>;
    renderValue: (v: TValue, inList: boolean) => React.ReactNode;
    keyGetter: (v: TValue) => string;
    value: TValue | null | void;
    onChange: (v: TValue | null) => void;
    label?: ReactNode;
    addEmptyOption?: boolean;
    disableOption?: (v: TValue) => boolean;
    helperText?: string | null;
    secondaryLabel?: (v: TValue, inList: boolean) => string;
    emptyLabel?: ReactNode;
    valueClassName?: string;
    valueCaption?: (v: TValue) => string;
};

export const Select = <TValue extends unknown = string>({
    label,
    items,
    value,
    onChange,
    addEmptyOption = true,
    renderValue,
    disableOption,
    keyGetter,
    helperText,
    error,
    secondaryLabel,
    emptyLabel = i18n.NA,
    disabled,
    valueClassName,
    valueCaption,
    ...rest
}: ISelectProps<TValue>) => {
    const idPart = useMemo(() => uniqueId('custom-select-component'), []);
    const labelId = `${idPart}-label`;
    const selectId = `${idPart}-select`;

    const handleChange = useCallback(
        (event: React.ChangeEvent<{ value: unknown }>) => {
            const selectedItem = items.find(i => keyGetter(i) === event.target.value);
            onChange(selectedItem || null);
        },
        [items, keyGetter, onChange]
    );

    const itemsToRender = useMemo(
        () => (!value || items.some(i => keyGetter(i) === keyGetter(value)) ? items : [...items, value]),
        [items, value, keyGetter]
    );

    const renderEmpty = useCallback(
        () => <ListItemText primary={emptyLabel} primaryTypographyProps={noWrapAndEllipsisProperty} />,
        [emptyLabel]
    );

    const localRenderValue = useCallback(
        (key: string) => {
            if (!key) return renderEmpty();

            const itemToRender = itemsToRender.find(i => keyGetter(i) === key);
            if (!itemToRender) return renderEmpty();

            return (
                <ListItemText
                    primary={renderValue(itemToRender, false)}
                    secondary={secondaryLabel ? secondaryLabel(itemToRender, false) : null}
                    primaryTypographyProps={noWrapAndEllipsisProperty}
                    secondaryTypographyProps={noWrapAndEllipsisProperty}
                />
            );
        },
        [itemsToRender, keyGetter, renderEmpty, renderValue, secondaryLabel]
    );

    return (
        <FormControl error={error} variant="outlined" fullWidth>
            {Boolean(label) && (
                <InputLabel error={error} id={labelId}>
                    {label}
                </InputLabel>
            )}
            <MuiSelect
                labelId={labelId}
                id={selectId}
                value={value ? keyGetter(value) : ''}
                label={label}
                onChange={handleChange}
                renderValue={localRenderValue as SelectProps['renderValue']}
                error={error}
                disabled={useDisable() || disabled}
                {...rest}>
                {addEmptyOption && <MenuItem value="">{renderEmpty()}</MenuItem>}
                {itemsToRender.map((value, index) => (
                    <MenuItem
                        key={index}
                        value={keyGetter(value)}
                        disabled={disableOption ? disableOption(value) : false}>
                        <ListItemText
                            title={valueCaption ? valueCaption(value) : undefined}
                            className={valueClassName}
                            primary={renderValue(value, true)}
                            primaryTypographyProps={{
                                style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
                            }}
                            secondary={secondaryLabel ? secondaryLabel(value, true) : null}
                        />
                    </MenuItem>
                ))}
            </MuiSelect>
            {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};
