import { FormikContextType } from 'formik';
import React, { useCallback } from 'react';

import { isValidLocationType } from '~/@store/locations';
import { homePageMapStore } from '~/@user-store/homePageMap';
import { IProjectFormValues } from '~/@views/AdminView/ProjectsPage/ProjectEditForm/ProjectEditFormik';
import { LocationType } from '~/graphql';

import { LocationEdit } from '../LocationEdit';

type FormikLocationFieldProps<TValues extends object, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends IProjectFormValues['location'] ? TFieldName : never;
    formik: FormikContextType<TValues>;
    label?: string;
    disabled?: boolean;
};

export function FormikLocationField<TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    label,
    disabled = false,
}: FormikLocationFieldProps<TValues, TFieldName>) {
    const handleInput = useCallback(
        v => {
            formik.setFieldValue(name, { ...v, type: LocationType.UNKNOWN });
        },
        [formik, name]
    );

    const handleChange = useCallback(
        v => {
            formik.setFieldTouched(name, true);
            formik.setFieldValue(name, v);
        },
        [formik, name]
    );

    const formValue = formik.values[name];

    return (
        <LocationEdit
            location={isValidLocationType(formValue) ? formValue : homePageMapStore.defaultLocation}
            onInput={handleInput}
            onChange={handleChange}
            label={label}
            readOnly={disabled}
            error={formik.touched[name] && !!formik.errors[name]}
            helperText={formik.touched[name] && formik.errors[name]}
        />
    );
}
