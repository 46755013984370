import cn from 'classnames';
import React, { useRef, VFC } from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import { useAnalysisFileUploadMutation } from '~/@store/projects';
import { MAX_PAYLOAD_SIZE } from '~/config/constants';
import { useUserAbilities } from '~/contexts';
import i18n from '~/i18n';
import { handleLoadingPromise } from '~/services/loader';
import { globalMessage } from '~/services/message';
import { canUploadProjectDocument, IAbilityProject } from '~/utils/auth';

type PromiseHandler<T> = (v: Promise<T>) => Promise<T>;

export type IAnalysisFileUploaderProps = {
    project: IAbilityProject;
    dumpLoadId?: string | null;
    canUpload?: boolean;
    children: (onClick: () => void) => React.ReactNode;
    onUploadFinish?: () => unknown;
    loaderHandler?: PromiseHandler<unknown>;
};

export const AnalysisFileUploader: VFC<IAnalysisFileUploaderProps> = ({
    project,
    dumpLoadId,
    onUploadFinish,
    canUpload = true,
    loaderHandler = handleLoadingPromise,
    children,
}) => {
    const { className } = useFunctionalBem(AnalysisFileUploader);
    const analysisUploadInputRef = useRef<HTMLInputElement>(null);

    const abilityContext = useUserAbilities();
    const canUploadAnalysisFile = canUpload && canUploadProjectDocument(abilityContext, project);

    const [uploadAnalysisFile, isUploading] = useAnalysisFileUploadMutation();

    const upload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (canUploadAnalysisFile && dumpLoadId) {
            const {
                target: { files },
            } = event;

            const file = files?.[0];
            if (!file) return;

            if (file.size > MAX_PAYLOAD_SIZE) return globalMessage.error(i18n.ProjectDocuments.maximumFileSizeNote);

            event.target.value = '';

            return loaderHandler(uploadAnalysisFile(project.id, dumpLoadId, file).finally(onUploadFinish));
        }
    };

    return (
        <>
            {children(() => {
                if (analysisUploadInputRef.current) analysisUploadInputRef.current.click();
            })}
            <input
                className={cn(className, 'hide')}
                type="file"
                ref={analysisUploadInputRef}
                onChange={upload}
                disabled={!canUploadAnalysisFile || isUploading}
            />
        </>
    );
};
