import './ProjectAdditionalDocuments.scss';

import { DocumentTypes } from '@common/enums';
import React, { ChangeEvent } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { FilePreview } from '~/@sochi-components/FilePreview';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { downloadCertificateUri } from '~/apolloClient';
import { uploadProjectCertificate } from '~/utils/project';

import { ProjectDetailsDataProps } from '../../ProjectPage/ProjectPage';

export const ProjectAdditionalDocuments: React.FC<ProjectDetailsDataProps> = ({ project, refetch }) => {
    const bem = useFunctionalBem(ProjectAdditionalDocuments);
    const match = useRouteMatch();
    const { element } = bem;
    const { certificates } = project;

    const MAX_CERTIFICATES = 10;
    const lastCertificates =
        certificates.length >= MAX_CERTIFICATES
            ? certificates.slice(certificates.length - MAX_CERTIFICATES)
            : certificates;

    const sanitizedUrl = match.url.replace(/\/+$/, '');

    const files = {
        url: sanitizedUrl.substring(0, sanitizedUrl.lastIndexOf('/') + 1) + 'documents',
        title: <LocalizableText code={'priceCalculatorPage.openFiles'} />,
    };

    const uploadInput = React.createRef<HTMLInputElement>();

    const handleCertificateUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { files },
        } = event;

        const file = files?.[0];
        if (!file) return;

        uploadProjectCertificate(file, project.id, refetch);

        event.target.value = '';
    };

    return (
        <>
            <div className={element('documents-wrapper')}>
                <div className={element('documents-header')}>
                    <SochiTitle
                        className={element('documents-title')}
                        title={<LocalizableText code={'additionalDocuments'} />}
                    />
                    <div className={element('documents-buttons')}>
                        <Link className={element('documents-open-files')} to={files.url}>
                            {files.title}
                        </Link>
                        <Button
                            className={element('documents-add-file')}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                uploadInput.current?.click();
                            }}>
                            <LocalizableText code={'addFile'} />
                        </Button>
                    </div>
                </div>
                <div className={`sochi-card ${element('mobile-documents')}`}>
                    {lastCertificates?.length ? (
                        <div className="sochi-card-body">
                            <div className={element('documents')}>
                                {((lastCertificates || []).filter(Boolean) as string[]).map((cert: string, index) => (
                                    <div className={element('documents-item')} title={cert} key={index}>
                                        <FilePreview
                                            url={downloadCertificateUri}
                                            filename={cert}
                                            params={{
                                                id: project.id,
                                                filename: cert,
                                                type: DocumentTypes.PROJECT,
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className={`sochi-card-body ${element('no-content')}`}>
                            <span className="sochi-info">
                                <LocalizableText code={'requireAnalysisToOfferRequest'} />
                                <LocalizableText code={'getBackIn24hrs'} />
                            </span>
                        </div>
                    )}
                    <input
                        className={`hide ${element('upload-input')}`}
                        ref={uploadInput}
                        type="file"
                        onChange={handleCertificateUpload}
                    />
                </div>
            </div>
        </>
    );
};
