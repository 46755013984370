import {
    RecalculateLandfillSolidTestResultsMutation,
    RecalculateProjectSolidTestResultsMutation,
} from '~/@store/database/database.queries';
import client, { generateFakeFilesUri } from '~/apolloClient';
import { RecalculateLandfillSolidTestResults, RecalculateProjectSolidTestResults } from '~/graphql';
import { globalMessage } from '~/services/message';
import { handleApolloError } from '~/utils';
import { getAuthToken } from '~/utils/auth';

export const generateFakeFiles = (onProgress: (v: string | null) => void) => {
    const request = new XMLHttpRequest();

    request.onprogress = function (e) {
        const lines = (e.currentTarget as XMLHttpRequest).responseText.split('\n');
        const lastLine = lines[lines.length - 1];
        if (lastLine) onProgress(lastLine);
    };

    request.onloadend = function () {
        globalMessage.success('Fake files generated successfully');
        onProgress(null);
    };

    request.onerror = function () {
        globalMessage.error('Something went wrong...');
        onProgress(null);
    };

    request.open('GET', generateFakeFilesUri, true);
    request.responseType = 'text';
    const token = getAuthToken();
    request.setRequestHeader('Authorization', `JWT ${token}`);
    request.send();
};

export const recalculateProjectSolidTestResults = () =>
    client
        .mutate<RecalculateProjectSolidTestResults>({ mutation: RecalculateProjectSolidTestResultsMutation })
        .catch(handleApolloError)
        .then(
            res =>
                res.data?.recalculateProjectSolidTestResults ||
                Promise.reject(new Error('RecalculateProjectSolidTestResults empty response'))
        );

export const recalculateLandfillSolidTestResults = () =>
    client
        .mutate<RecalculateLandfillSolidTestResults>({ mutation: RecalculateLandfillSolidTestResultsMutation })
        .catch(handleApolloError)
        .then(
            res =>
                res.data?.recalculateLandfillSolidTestResults ||
                Promise.reject(new Error('RecalculateLandfillSolidTestResults empty response'))
        );
