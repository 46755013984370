import { ContaminationLevel, ContaminationType, DeliveryLineStatus } from '@common/enums';
import { toKm } from '@common/functions';
import { FilterRange } from '@common/types';
import { isNullOrUndefined } from '@common/validations/types.validation';
import { action, computed, observable } from 'mobx';

import { FilterStore, SortStore } from '~/@store/common';
import { getContaminationLevelLabel } from '~/@store/toxicLimits';
import { parseDateFrom } from '~/utils/date';
import {
    filterAndSortCollection,
    getDateRangeFilterFn,
    getNumberRangeFilterFn,
    getStringSearchFilterFn,
} from '~/utils/filters';

const DEFAULT_PROJECT_WEIGHT_RECEIPT_SORT = {
    field: 'plannedStartDate',
    direction: -1,
};

export type ProjectWeightReceiptsStoreFilter = {
    serialNumber?: string | null;
    dumpTypeName?: string | null;
    solidTestResult?: string | null;
    landfillName?: string | null;
    truckRegistrationNumber?: string | null;
    plannedStartDate?: FilterRange<Date> | null;
    weight?: FilterRange<number> | null;
    customerInvoice?: string | null;
    comment?: string | null;
    senderComment?: string | null;
    driverComment?: string | null;
    excavatorOperatorName?: string | null;
    truckCapacity?: FilterRange<number> | null;
    loadPercentage?: FilterRange<number> | null;
    distance?: FilterRange<number> | null;
};

export enum ProjectWeightReceiptFilterField {
    serialNumber = 'serialNumber',
    dumpTypeName = 'dumpTypeName',
    solidTestResult = 'solidTestResult',
    landfillName = 'landfillName',
    truckRegistrationNumber = 'truckRegistrationNumber',
    plannedStartDate = 'plannedStartDate',
    weight = 'weight',
    customerInvoice = 'customerInvoice',
    comment = 'comment',
    senderComment = 'senderComment',
    truckCapacity = 'truckCapacity',
    loadPercentage = 'loadPercentage',
    distance = 'distance',
    excavatorOperatorName = 'excavatorOperatorName',
    driverComment = 'driverComment',
}

export type ProjectWeightReceiptInfo = {
    id: string;
    receiptUrl: string | null;
    plannedStartDate: string;
    excavationDate: string | null;
    startDeliveryDate: string | null;
    endDeliveryDate: string | null;
    orderNumber: string;
    truckRegistrationNumber: string;
    weight: number;
    truckCapacity: number | null;
    customerInvoice: string | null;
    landfillName: string | null;
    distance: number | null;
    dumpType: { name: string } | null;
    solidTestResult: ContaminationLevel | null;
    serialNumber: string;
    comment: string | null;
    senderComment: string | null;
    loadPercentage: number | null;
    company: { name: string } | null;
    excavatorOperatorName: string | null;
    status: DeliveryLineStatus;
    driverComment: string | null;
};

class ProjectWeightReceiptsStore {
    filter = new FilterStore<ProjectWeightReceiptsStoreFilter>();
    sort = new SortStore(DEFAULT_PROJECT_WEIGHT_RECEIPT_SORT);

    readonly numberOfItemsToDisplayAllOptions = [20, 50, 100];

    @computed
    get numberOfItemsToDisplayOptions(): number[] {
        return this.numberOfItemsToDisplayAllOptions.filter(v => v <= this.totalCount);
    }

    @observable
    numberOfItemsToDisplay = this.numberOfItemsToDisplayAllOptions[0] || null;

    @action
    setNumberOfItemsToDisplay(num: number | null) {
        this.numberOfItemsToDisplay = num;
    }

    @observable
    list: ProjectWeightReceiptInfo[] = [];

    @action
    setList(newList: ProjectWeightReceiptInfo[]) {
        this.list = newList;
    }

    @action
    clear() {
        this.filter.clearValues();
        this.sort.clearSort();
        this.numberOfItemsToDisplay = this.numberOfItemsToDisplayOptions[0] || null;
    }

    @computed
    get filteredList(): ProjectWeightReceiptInfo[] {
        const {
            serialNumber,
            dumpTypeName,
            landfillName,
            truckRegistrationNumber,
            plannedStartDate,
            weight,
            customerInvoice,
            comment,
            senderComment,
            truckCapacity,
            loadPercentage,
            distance,
            solidTestResult,
            excavatorOperatorName,
            driverComment,
        } = this.filter.values;

        const filters: ((item: ProjectWeightReceiptInfo) => boolean)[] = [];

        if (serialNumber) filters.push(getStringSearchFilterFn(serialNumber, r => r.serialNumber));
        if (dumpTypeName) filters.push(getStringSearchFilterFn(dumpTypeName, r => r.dumpType?.name));
        if (landfillName) filters.push(getStringSearchFilterFn(landfillName, r => r.landfillName));
        if (truckRegistrationNumber)
            filters.push(getStringSearchFilterFn(truckRegistrationNumber, r => r.truckRegistrationNumber));
        if (plannedStartDate)
            filters.push(getDateRangeFilterFn(plannedStartDate, r => parseDateFrom(r.plannedStartDate)));
        if (weight) filters.push(getNumberRangeFilterFn(weight, r => r.weight));
        if (customerInvoice) filters.push(getStringSearchFilterFn(customerInvoice, r => r.customerInvoice));
        if (comment) filters.push(getStringSearchFilterFn(comment, r => r.comment));
        if (senderComment) filters.push(getStringSearchFilterFn(senderComment, r => r.senderComment));
        if (driverComment) filters.push(getStringSearchFilterFn(driverComment, r => r.driverComment));
        if (excavatorOperatorName)
            filters.push(getStringSearchFilterFn(excavatorOperatorName, r => r.excavatorOperatorName));
        if (truckCapacity) filters.push(getNumberRangeFilterFn(truckCapacity, r => r.truckCapacity));
        if (loadPercentage) filters.push(getNumberRangeFilterFn(loadPercentage, r => r.loadPercentage));
        if (distance) filters.push(getNumberRangeFilterFn(distance, r => toKm(r.distance)));
        if (solidTestResult)
            filters.push(
                getStringSearchFilterFn(solidTestResult, r =>
                    getContaminationLevelLabel(r.solidTestResult, ContaminationType.SOLID)
                )
            );

        return filterAndSortCollection(this.list, filters, this.sort.value);
    }

    @computed
    get isAnyFilterSet(): boolean {
        return Object.values(this.filter.values).some(f => !isNullOrUndefined(f));
    }

    @computed
    get itemsToDisplay(): ProjectWeightReceiptInfo[] {
        return this.numberOfItemsToDisplay === null
            ? this.filteredList
            : this.filteredList.slice(0, this.numberOfItemsToDisplay);
    }

    @computed
    get totalCount() {
        return this.filteredList.length;
    }

    @computed
    get filteredIdsList(): string[] {
        return this.filteredList.map(item => item.id);
    }
}

export const projectWeightReceiptsStore = new ProjectWeightReceiptsStore();
