import { getReleaseNoteSubject } from '@common/abilities/releaseNotes';
import { AbilityCan } from '@common/enums';

import { useUserAbilities } from '~/contexts';

export const useReleaseNotesPermissions = () => {
    const { user, ability } = useUserAbilities();
    const subject = getReleaseNoteSubject(user);

    return {
        canCreate: ability.can(AbilityCan.CREATE, subject),
        canUpdate: ability.can(AbilityCan.UPDATE, subject),
        canDelete: ability.can(AbilityCan.DELETE, subject),
    };
};
