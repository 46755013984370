import { isValidOrgNr } from '@common/validations/common.validation';
import { FormikConfig, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { CompanyCreate } from '~/graphql';
import i18n from '~/i18n';

export type ICompanyCreateFormValues = {
    name: string;
    organizationNumber: string;
};

export const useCreateCompanyFormikContext = () => useFormikContext<ICompanyCreateFormValues>();

type CompanyCreateFunc = (input: CompanyCreate) => Promise<void>;

export const getCreateCompanyFormikConfig = (
    createCompany: CompanyCreateFunc
): FormikConfig<ICompanyCreateFormValues> => {
    return {
        initialValues: {
            name: '',
            organizationNumber: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required(i18n.CompanyCard.nameRequired),
            organizationNumber: Yup.string()
                .trim()
                .required(i18n.CompanyCard.organizationNumberRequired)
                .test('Organization number validation', i18n.errorCodes.INVALID_ORGANIZATION_NUMBER, isValidOrgNr),
        }),
        onSubmit: async ({ name, organizationNumber }) => {
            await createCompany({ name, organizationNumber });
        },
    };
};
