import { ContaminationLevel, ContaminationType } from '@common/enums';
import { FilterRange } from '@common/types';
import { isNullOrUndefined } from '@common/validations/types.validation';
import { action, computed, observable } from 'mobx';

import { FilterStore, SortStore } from '~/@store/common';
import { getContaminationLevelLabel } from '~/@store/toxicLimits/toxicLimits.helpers';
import { parseDateFrom } from '~/utils/date';
import {
    filterAndSortCollection,
    getDateRangeFilterFn,
    getNumberRangeFilterFn,
    getStringSearchFilterFn,
} from '~/utils/filters';

export const DEFAULT_LANDFILL_WEIGHT_RECEIPT_SORT = {
    field: 'serialNumber',
    direction: -1,
};

export type LandfillWeightReceiptsStoreFilter = {
    serialNumber?: string | null;
    projectName?: string | null;
    massCategory?: string | null;
    solidTestResult?: string | null;
    transportCompanyName?: string | null;
    truckRegistrationNumber?: string | null;
    plannedStartDate?: FilterRange<Date> | null;
    weight?: FilterRange<number> | null;
    cost?: FilterRange<number> | null;
    comment?: string | null;
    receiverComment?: string | null;
    landfillInvoiceNumber?: string | null;
    driverComment?: string | null;
};

export enum LandfillWeightReceiptFilterField {
    serialNumber = 'serialNumber',
    projectName = 'projectName',
    massCategory = 'massCategory',
    solidTestResult = 'solidTestResult',
    truckRegistrationNumber = 'truckRegistrationNumber',
    plannedStartDate = 'plannedStartDate',
    weight = 'weight',
    comment = 'comment',
    receiverComment = 'receiverComment',
    landfillInvoiceNumber = 'landfillInvoiceNumber',
    driverComment = 'driverComment',
}

export type LandfillWeightReceiptInfo = {
    id: string;
    serialNumber: string | null;
    projectName: string | null;
    massCategory: string;
    solidTestResult: ContaminationLevel | null;
    comment: string | null;
    receiverComment: string;
    receiverCheck: boolean;
    driverComment: string;
    truckRegistrationNumber: string;
    plannedStartDate: string;
    truckCapacity?: number | null;
    excavationDate: string | null;
    startDeliveryDate: string | null;
    endDeliveryDate: string | null;
    weight: number;
    receiptUrl: string | null;
    photoUrl: string | null;
    pinpointerCheck: boolean;
    landfillInvoiceNumber: string;
    batch: {
        id: string;
        name: string;
    } | null;
    incoming: boolean;
};

class LandfillWeightReceiptsStore {
    filter = new FilterStore<LandfillWeightReceiptsStoreFilter>();
    sort = new SortStore(DEFAULT_LANDFILL_WEIGHT_RECEIPT_SORT);

    readonly numberOfItemsToDisplayAllOptions = [20, 50, 100];

    @computed
    get numberOfItemsToDisplayOptions(): number[] {
        return this.numberOfItemsToDisplayAllOptions.filter(v => v <= this.totalCount);
    }

    @observable
    numberOfItemsToDisplay = this.numberOfItemsToDisplayAllOptions[0] || null;

    @action
    setNumberOfItemsToDisplay(num: number | null) {
        this.numberOfItemsToDisplay = num;
    }

    @observable
    list: LandfillWeightReceiptInfo[] = [];

    @action
    setList(newList: LandfillWeightReceiptInfo[], serialNumber?: string) {
        this.list = newList;
        if (serialNumber) this.filter.values.serialNumber = serialNumber;
    }

    @action
    clear() {
        this.filter.clearValues();
        this.sort.clearSort();
        this.numberOfItemsToDisplay = this.numberOfItemsToDisplayOptions[0] || null;
    }

    @computed
    get filteredList(): LandfillWeightReceiptInfo[] {
        const {
            serialNumber,
            massCategory,
            projectName,
            truckRegistrationNumber,
            plannedStartDate,
            weight,
            comment,
            receiverComment,
            solidTestResult,
            landfillInvoiceNumber,
            driverComment,
        } = this.filter.values;

        const filters: ((item: LandfillWeightReceiptInfo) => boolean)[] = [];

        if (serialNumber) filters.push(getStringSearchFilterFn(serialNumber, r => r.serialNumber));
        if (projectName) filters.push(getStringSearchFilterFn(projectName, r => r.projectName));
        if (massCategory) filters.push(getStringSearchFilterFn(massCategory, r => r.massCategory));
        if (truckRegistrationNumber)
            filters.push(getStringSearchFilterFn(truckRegistrationNumber, r => r.truckRegistrationNumber));
        if (plannedStartDate)
            filters.push(getDateRangeFilterFn(plannedStartDate, r => parseDateFrom(r.plannedStartDate)));
        if (weight) filters.push(getNumberRangeFilterFn(weight, r => r.weight));
        if (comment) filters.push(getStringSearchFilterFn(comment, r => r.comment));
        if (receiverComment) filters.push(getStringSearchFilterFn(receiverComment, r => r.receiverComment));
        if (solidTestResult)
            filters.push(
                getStringSearchFilterFn(solidTestResult, r =>
                    getContaminationLevelLabel(r.solidTestResult, ContaminationType.SOLID)
                )
            );
        if (landfillInvoiceNumber)
            filters.push(getStringSearchFilterFn(landfillInvoiceNumber, r => r.landfillInvoiceNumber));
        if (driverComment) filters.push(getStringSearchFilterFn(driverComment, r => r.driverComment));

        return filterAndSortCollection(this.list, filters, this.sort.value);
    }

    @computed
    get isAnyFilterSet(): boolean {
        return Object.values(this.filter.values).some(f => !isNullOrUndefined(f));
    }

    @computed
    get itemsToDisplay(): LandfillWeightReceiptInfo[] {
        return this.numberOfItemsToDisplay === null
            ? this.filteredList
            : this.filteredList.slice(0, this.numberOfItemsToDisplay);
    }

    @computed
    get totalCount() {
        return this.filteredList.length;
    }

    @computed
    get filteredIdsList(): string[] {
        return this.filteredList.map(item => item.id);
    }
}

export const landfillWeightReceiptsStore = new LandfillWeightReceiptsStore();
