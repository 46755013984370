import { useMutation } from '@apollo/client';

import { BatchLogQuery, UpdateBatchTransferMutation } from '~/@user-store/landfillBatches/landfillBatch.queries';
import {
    BatchLogQueryVariables,
    BatchTransferInput,
    UpdateBatchTransferMutation as IUpdateBatchTransferMutation,
    UpdateBatchTransferMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

export const useBatchTransferUpdate = (landfillId: string, batchId: string | null) => {
    const [callMutation, data] = useMutation<IUpdateBatchTransferMutation, UpdateBatchTransferMutationVariables>(
        UpdateBatchTransferMutation
    );

    const refetchVariables: BatchLogQueryVariables = { landfillId, batchId: batchId || '' };

    const update = (id: string, input: BatchTransferInput) =>
        callMutation({
            variables: { landfillId, id, input },
            refetchQueries: batchId ? [{ query: BatchLogQuery, variables: refetchVariables }] : [],
            awaitRefetchQueries: true,
        }).catch(handleApolloError);

    return [update, data.loading] as const;
};
