import React from 'react';

import { PageWrapper } from '~/@sochi-components/PageWrapper';
import { useUserAbilities } from '~/contexts';
import { LandfillQuery_landfill } from '~/graphql';
import { canReadLandfillEvents } from '~/utils/auth';

import { LandfillDeliveryEvents } from './LandfillDeliveryEvents';
import { LandfillDeviations } from './LandfillDeviations';

type Props = {
    landfill: LandfillQuery_landfill;
};

export const LandfillDeviationsTab = ({ landfill }: Props) => {
    const { user, ability } = useUserAbilities();

    return (
        <PageWrapper>
            {canReadLandfillEvents(user, ability, landfill) && <LandfillDeliveryEvents landfill={landfill} />}
            <LandfillDeviations landfill={landfill} />
        </PageWrapper>
    );
};
