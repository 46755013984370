import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { useProgressState } from '~/@components/@hooks/use-progress-state';
import { AutocompleteAsync } from '~/@components/Autocomplete';
import { ICompanySearchRecord, useCompaniesSearchFunc } from '~/@store/companies';
import i18n from '~/i18n';

import { PopupEditDialog } from './PopupEditDialog';

type Props = {
    label: React.ReactNode;
    initialValue: ICompanySearchRecord | null;
    onSubmit: (value: ICompanySearchRecord | null) => Promise<unknown>;
    onClose: (v: boolean) => void;
    nullable?: boolean;
    onlyTransportCompanies?: boolean;
};

const renderItem = ({ name }: ICompanySearchRecord) => name;

export const PopupCompanySelect = ({
    label,
    initialValue,
    onClose,
    onSubmit,
    nullable = true,
    onlyTransportCompanies = false,
}: Props) => {
    const { root, textField } = useStyles();

    const [value, setValue] = useState(initialValue);

    const [isSubmitting, submit] = useProgressState(onSubmit);

    const handleSubmit = () => {
        if (value) submit(value).then(() => onClose(true));
    };

    const handleClear = () => {
        if (nullable) submit(null).then(() => onClose(true));
    };

    const customersSearch = useCompaniesSearchFunc(onlyTransportCompanies);

    return (
        <PopupEditDialog onClose={onClose}>
            <div className={root}>
                <AutocompleteAsync
                    label={label}
                    selectedOption={value}
                    onOptionSelect={setValue}
                    renderOption={renderItem}
                    loadOptions={customersSearch}
                    autoFocus
                    className={textField}
                />
                <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
                    {i18n.save}
                </Button>
                {nullable && (
                    <Button disabled={isSubmitting} variant="contained" onClick={handleClear}>
                        {i18n.clear}
                    </Button>
                )}
            </div>
        </PopupEditDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        gap: theme.spacing(),
    },
    textField: {
        backgroundColor: theme.palette.background.paper,
    },
}));
