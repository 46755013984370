import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { BeastMaterialRemoveMutation, BeastMaterialRemoveMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../beastMaterials.queries';

export type BeastMaterialRemoveFunc = (id: string) => Promise<void>;

export const useBeastMaterialRemove = (): [BeastMaterialRemoveFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        BeastMaterialRemoveMutation,
        BeastMaterialRemoveMutationVariables
    >(queries.BeastMaterialRemoveMutation);

    const removeFunc: BeastMaterialRemoveFunc = useCallback(
        (id: string) => {
            return callMutation({
                variables: { id },
                refetchQueries: [
                    {
                        query: queries.BeastMaterialListQuery,
                        variables: {},
                    },
                ],
                awaitRefetchQueries: true,
            })
                .then(() => {})
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [removeFunc, mutationResult.loading];
};
