import { Box, Grid } from '@material-ui/core';
import React from 'react';

import {
    FormikBooleanSwitchField,
    FormikDateField,
    FormikPhoneNumberField,
    FormikTextField,
} from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { TextField } from '~/@components/TextField';
import { IDetailedUser } from '~/@store/users';
import { UserEditVehicles } from '~/@views/AdminView/UserDetailPage/UserEditForm/UserEditVehicles';
import { UserRole } from '~/graphql';
import i18n from '~/i18n';
import { getUserStatus, UserStatusTranslation } from '~/utils/user';

import { ChangePasswordControl } from '../../common/ChangePassword/ChangePasswordControl';
import { FormikCustomerAsyncSelect } from '../../common/FormikFields/FormikCustomerAsyncSelect';
import { FormikUserRoleSelect } from '../../common/FormikFields/FormikUserRoleSelect';
import { useEditUserFormikContext } from './UserEditFormik';

interface Props {
    user: IDetailedUser;
}

export const UserEditFormFields = ({ user }: Props) => {
    const formik = useEditUserFormikContext();

    const status = getUserStatus({ ...user, blocked: !formik.values.active });

    const isEditableCustomerFields = formik.values.role === UserRole.EXTERNAL_RECEIVER;

    return (
        <Grid container spacing={3} justify={'space-between'}>
            <Grid item xs={6}>
                <FormikDateField
                    label={<LocalizableText code={'UserEditPage.registeredAt'} />}
                    name="createdAt"
                    formik={formik}
                    disabled
                />
            </Grid>
            <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                    <FormikBooleanSwitchField
                        name="active"
                        commonLabel={<LocalizableText code={'UserEditPage.state'} />}
                        checkedLabel={<LocalizableText code={'UserEditPage.active'} />}
                        uncheckedLabel={<LocalizableText code={'UserEditPage.blocked'} />}
                        formik={formik}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={<LocalizableText code={'UserEditPage.status'} />}
                    value={status ? UserStatusTranslation[status] : i18n.NA}
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField label={<LocalizableText code={'UserEditPage.name'} />} name="name" formik={formik} />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField
                    label={<LocalizableText code={'UserEditPage.surname'} />}
                    name="surname"
                    formik={formik}
                />
            </Grid>
            <Grid item xs={6}>
                <FormikPhoneNumberField
                    label={<LocalizableText code={'UserEditPage.phoneNumber'} />}
                    name="phoneNumber"
                    formik={formik}
                />
            </Grid>
            <Grid item xs={6}>
                <FormikTextField
                    label={<LocalizableText code={'UserEditPage.email'} />}
                    name="email"
                    formik={formik}
                    disabled
                />
            </Grid>
            <Grid item xs={6}>
                <FormikUserRoleSelect name="role" formik={formik} />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField
                    label={<LocalizableText code={'UserEditPage.orgName'} />}
                    name="orgName"
                    formik={formik}
                    disabled={!isEditableCustomerFields}
                />
            </Grid>
            <Grid item xs={12}>
                <FormikTextField
                    label={<LocalizableText code={'UserEditPage.orgNr'} />}
                    name="orgNr"
                    formik={formik}
                    disabled={!isEditableCustomerFields}
                />
            </Grid>
            <Grid item xs={12}>
                <FormikCustomerAsyncSelect name="customer" disabled={!isEditableCustomerFields} formik={formik} />
            </Grid>
            {[UserRole.DRIVER, UserRole.EXCAVATOR_OPERATOR].includes(formik.values.role) && (
                <Grid item xs={12}>
                    <UserEditVehicles />
                </Grid>
            )}
            <Grid item xs={12}>
                <ChangePasswordControl user={user} />
            </Grid>
        </Grid>
    );
};
