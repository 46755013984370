import React, { useMemo, useState } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { Select } from '~/@components/Select';
import { Text } from '~/@components/Text';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { getOutgoingProjectNames } from '~/@user-store/landfillBatches/landfillBatchReport.helpers';
import { LandfillFragment } from '~/graphql';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';

type Props = {
    landfill: LandfillFragment;
    onClose: (projectName: string | null) => void;
};

const LandfillBatchSelectProjectDialog = ({ landfill, onClose }: Props) => {
    const projectNames = useMemo(() => getOutgoingProjectNames(landfill), [landfill]);
    const [projectName, setName] = useState(projectNames[0] || '');

    if (!projectNames.length)
        return (
            <DialogContent>
                <Text>
                    <LocalizableText code="LandfillPage.batchReport.noDeliveryLines" />
                </Text>
                <ButtonGroup>
                    <Button variant="contained" color="primary" onClick={() => onClose(null)}>
                        {i18n.close}
                    </Button>
                </ButtonGroup>
            </DialogContent>
        );

    return (
        <DialogContent>
            <Select
                items={projectNames}
                renderValue={v => v}
                keyGetter={v => v}
                onChange={v => setName(v || '')}
                value={projectName}
                addEmptyOption={false}
            />
            <ButtonGroup>
                <Button variant="contained" onClick={() => onClose(null)}>
                    {i18n.cancel}
                </Button>
                <Button
                    variant="contained"
                    disabled={!projectName}
                    onClick={() => onClose(projectName)}
                    color="primary">
                    {i18n.continue}
                </Button>
            </ButtonGroup>
        </DialogContent>
    );
};

export const showLandfillBatchSelectProjectDialog = (landfill: LandfillFragment) =>
    showCustomDialog<string | null>({
        title: i18n.LandfillPage.batchReport.selectProject,
        render: closeDialog => <LandfillBatchSelectProjectDialog onClose={closeDialog} landfill={landfill} />,
    });
