import './ForgotPassword.scss';

import type { FormikProps } from 'formik';
import { Form } from 'formik';
import React, { Component } from 'react';
import type { RouteComponentProps } from 'react-router';

import { Button } from '~/@components/Button';
import { FormikContextTextField } from '~/@components/FormikFields';
import { getBem } from '~/@sochi-components/@bem';
import i18n from '~/i18n';

import { AuthText } from '../common/AuthText';
import { AuthTitle } from '../common/AuthTitle';
import { AuthWrapper } from '../common/AuthWrapper';

export type IForgotPasswordValues = {
    login: string;
    isResetSuccess: boolean;
};

export type IForgotPasswordProps = RouteComponentProps & FormikProps<IForgotPasswordValues>;

enum FormNames {
    login = 'login',
}

export class ForgotPassword extends Component<IForgotPasswordProps> {
    bem = getBem(this);
    goBack = () => this.props.history.goBack();

    render() {
        const { isResetSuccess } = this.props.values;
        const { element } = this.bem;

        return (
            <AuthWrapper onBack={this.goBack}>
                <AuthTitle title={i18n.forgotPassword} subtitle={i18n.resetItNow} />
                <Form className={element('form')}>
                    <FormikContextTextField<IForgotPasswordValues, FormNames.login>
                        disabled={isResetSuccess}
                        name={FormNames.login}
                        label={i18n.email}
                    />
                    <div className={element('buttons')}>
                        <Button type="submit" color="primary" size="large" variant="contained">
                            {i18n.ForgotPasswordPage.Submit}
                        </Button>
                    </div>
                    {isResetSuccess ? <AuthText>{i18n.ForgotPasswordPage.resetSuccess}</AuthText> : ''}
                </Form>
            </AuthWrapper>
        );
    }
}
