import { ApolloClient } from '@apollo/client';
import { parseISO } from 'date-fns';

import * as queries from '~/@store/dumpLoads/dumpLoads.queries';
import { DumpLoadSendForSignMutation } from '~/@store/dumpLoads/dumpLoads.queries';
import type { IAbilityUserContext } from '~/contexts';
import type {
    DumpLoadDetailedFragment_analysisFiles,
    DumpLoadsUpdateStatusMutation,
    DumpLoadsUpdateStatusMutationVariables,
    ProjectQuery_project_dumpLoads,
} from '~/graphql';
import { DumpLoadsUpdateStatusMutation_projectLoadsUpdateStatus, LoadStatus } from '~/graphql';
import i18n from '~/i18n';
import { handleLoadingPromise } from '~/services/loader';
import { isSameOrAfter } from '~/utils/date';

import client, { projectAnalysisUri, uploadDeclarationUri } from '../apolloClient';
import { DumpLoadStatus, FileFormats } from '../config/enum';
import { canReadProject, IAbilityProject } from './auth';
import { downloadFile } from './files';
import type { SeriesData } from './index';
import { handleApolloError, ScheduleChartColors } from './index';

export type AnalysisFile = Omit<DumpLoadDetailedFragment_analysisFiles, '__typename'>;

export type DumpLoadMode = 'outbound' | 'inbound';

export const changeDumpLoadStatus = ({
    client,
    dumpLoadIds,
    project,
    status,
}: {
    client: ApolloClient<object>;
    dumpLoadIds: string[];
    project: { id: string; ver: number };
    status: LoadStatus;
    refetch?: () => Promise<unknown>;
}): Promise<DumpLoadsUpdateStatusMutation_projectLoadsUpdateStatus | null | undefined> => {
    const payload = { projectId: project.id, ver: project.ver, dumpLoads: dumpLoadIds.map(id => ({ id, status })) };

    return handleLoadingPromise(
        client
            .mutate<DumpLoadsUpdateStatusMutation, DumpLoadsUpdateStatusMutationVariables>({
                mutation: queries.DumpLoadsUpdateStatusMutation,
                variables: {
                    input: payload,
                },
            })
            .then(({ data }) => data?.projectLoadsUpdateStatus)
            .catch(handleApolloError)
    );
};

export const sendDumpLoadForSign = ({
    client,
    dumpLoadId,
    projectId,
}: {
    client: ApolloClient<object>;
    dumpLoadId: string;
    projectId: string;
}) => {
    const promise = client
        .mutate({
            mutation: DumpLoadSendForSignMutation,
            variables: {
                dumpLoadId,
                projectId,
            },
        })
        .catch(handleApolloError);

    return handleLoadingPromise(promise);
};

export const downloadDeclarationOfWaste = (
    project: IAbilityProject,
    projectLoadId: string,
    context: IAbilityUserContext,
    format = FileFormats.PDF
) => {
    if (!canReadProject(context, project)) {
        return Promise.resolve(false);
    }

    const data = {
        projectId: project.id,
        projectLoadId,
        format,
    };

    return downloadFile(uploadDeclarationUri, data, `declaration.${format}`);
};

export const downloadDumpLoadAnalysisFile = (projectId: string, dumpLoadId: string, file: AnalysisFile | null) => {
    if (!file) {
        return Promise.resolve(false);
    }

    return downloadFile(projectAnalysisUri, { projectId, dumpLoadId, fileId: file!.id }, file!.name);
};

export const convertDumpLoadVolumesToSeriesData = (dumpLoad: ProjectQuery_project_dumpLoads): SeriesData => {
    return {
        id: dumpLoad.id,
        name: dumpLoad.dumpType?.name,
        addInfo: dumpLoad.declaration?.serialNumber,
        comment: dumpLoad.comment,
        color: dumpLoad.status ? ScheduleChartColors[dumpLoad.status] : ScheduleChartColors[DumpLoadStatus.DRAFT],
        values: {
            weeks: dumpLoad.volumesPerPeriods.weeks,
            months: dumpLoad.volumesPerPeriods.months,
            years: dumpLoad.volumesPerPeriods.years,
        },
    };
};

export const getLastAnalysisFile = (analysisFiles: AnalysisFile[]): AnalysisFile | null => {
    if (!analysisFiles.length) return null;

    return analysisFiles.reduce((acc, file) => {
        const fileUploadDate = parseISO(file.uploadDate);
        const accUploadDate = parseISO(acc.uploadDate);

        return isSameOrAfter(fileUploadDate, accUploadDate) ? file : acc;
    });
};

export const isSimplifiedDumpLoadEditMode = (values: { mode: DumpLoadMode; matchWithPinpointer: boolean }) =>
    values.mode === 'inbound' || !values.matchWithPinpointer;

export const isConnectedToPeppol = async (companyId: string) => {
    return handleLoadingPromise(
        client.query({
            query: queries.AdminCheckConnectedToPeppolQuery,
            variables: {
                companyId,
            },
        })
    )
        .then(q => q.data?.connectedToPeppol || false)
        .catch(handleApolloError);
};

export enum DumpLoadModeMatching {
    INBOUND_WITH_MATCHING = 'INBOUND_WITH_MATCHING',
    INBOUND_WITHOUT_MATCHING = 'INBOUND_WITHOUT_MATCHING',
    OUTBOUND_WITH_MATCHING = 'OUTBOUND_WITH_MATCHING',
    OUTBOUND_WITHOUT_MATCHING = 'OUTBOUND_WITHOUT_MATCHING',
}

export const dumpLoadModeFilterValues: Array<{ id: string; name: string; value: DumpLoadModeMatching }> = [
    {
        id: DumpLoadModeMatching.INBOUND_WITH_MATCHING,
        value: DumpLoadModeMatching.INBOUND_WITH_MATCHING,
        name: i18n.DumploadSummarySection.inbound + ' ' + i18n.DumploadSummarySection.withMatching,
    },
    {
        id: DumpLoadModeMatching.INBOUND_WITHOUT_MATCHING,
        value: DumpLoadModeMatching.INBOUND_WITHOUT_MATCHING,
        name: i18n.DumploadSummarySection.inbound + ' ' + i18n.DumploadSummarySection.withoutMatching,
    },
    {
        id: DumpLoadModeMatching.OUTBOUND_WITH_MATCHING,
        value: DumpLoadModeMatching.OUTBOUND_WITH_MATCHING,
        name: i18n.DumploadSummarySection.outbound + ' ' + i18n.DumploadSummarySection.withMatching,
    },
    {
        id: DumpLoadModeMatching.OUTBOUND_WITHOUT_MATCHING,
        value: DumpLoadModeMatching.OUTBOUND_WITHOUT_MATCHING,
        name: i18n.DumploadSummarySection.outbound + ' ' + i18n.DumploadSummarySection.withoutMatching,
    },
];

export const getLandfillPricePerTon = (d: { amountInTons: number | null; priceData: { landfillPrice: number } }) =>
    d.amountInTons ? d.priceData.landfillPrice / d.amountInTons : null;
