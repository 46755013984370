import { I18nKeys } from '@common/i18n/types';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, MouseEventHandler, useCallback } from 'react';

import { AdminThemeContainer, COLORS } from '~/@components/@theme';
import { useLocalizationEditToggle } from '~/@store/common';
import { localizationStore } from '~/@store/localization';
import { PopupTextEdit } from '~/@views/AdminView/common/PopupEditFields';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';

type Props = {
    code: I18nKeys;
};

const editLocalizationString = (code: string, curValue: string | undefined) => {
    const ignoredPromise = showCustomDialog({
        render: closeDialog => {
            const submit = (value: string) => {
                return localizationStore.updateLocalizationString({
                    code,
                    value,
                });
            };

            return (
                <AdminThemeContainer>
                    <PopupTextEdit
                        label={get(i18n, code)}
                        initialValue={curValue || ''}
                        onSubmit={submit}
                        onClose={closeDialog}
                        placeholder={get(i18n, code)}
                        nullable
                    />
                </AdminThemeContainer>
            );
        },
    });
};

export const LocalizableText: FC<Props> = observer(({ code }: Props) => {
    const { enabled } = useLocalizationEditToggle();

    const { root, editModeChanged, editModeNotChanged } = useStyles();

    const curValue = localizationStore.localizationStrings.get(code);

    const onEdit: MouseEventHandler = useCallback(
        e => {
            e.stopPropagation();
            e.preventDefault();

            editLocalizationString(code, curValue);
        },
        [curValue, code]
    );

    return (
        <span
            className={enabled ? cn(root, !!curValue ? editModeChanged : editModeNotChanged) : undefined}
            onClick={enabled ? onEdit : undefined}>
            {curValue || get(i18n, code)}
        </span>
    );
});

const useStyles = makeStyles(() => ({
    root: {
        pointerEvents: 'all',
    },
    editModeChanged: {
        color: COLORS.turquoise,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    editModeNotChanged: {
        color: COLORS.darkTurquoise,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));
