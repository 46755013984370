import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

type Props = {
    form: React.ReactNode;
    preview: React.ReactNode;
};

export const DeliveryLineEditLayout = ({ form, preview }: Props) => {
    const { viewSection } = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={5}>
                {form}
            </Grid>
            <Grid item xs={7} className={viewSection}>
                {preview}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    viewSection: {
        height: '80vh',
    },
}));
