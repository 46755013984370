import { FormikContextType } from 'formik';
import React, { useCallback } from 'react';

import { AutocompleteMultiSelectAsync, AutocompleteMultiSelectAsyncProps } from '~/@components/Autocomplete';

type FormikAsyncAutocompleteMultiSelectFieldProps<
    TValueType,
    TValues extends {},
    TFieldName extends keyof TValues & string
> = {
    name: TValues[TFieldName] extends TValueType[] ? TFieldName : never;
    formik: FormikContextType<TValues>;
} & Omit<AutocompleteMultiSelectAsyncProps<TValueType>, 'selectedOptions' | 'onChange' | 'errorMessage'>;

export function FormikAsyncAutocompleteMultiSelectField<
    TValueType extends { id: string },
    TValues extends {},
    TFieldName extends keyof TValues & string
>({ name, formik, ...restProps }: FormikAsyncAutocompleteMultiSelectFieldProps<TValueType, TValues, TFieldName>) {
    const handleChange = useCallback(
        (v: Array<TValueType>) => {
            formik.setFieldTouched(name, true);
            formik.setFieldValue(name, v);
        },
        [formik, name]
    );

    return (
        <AutocompleteMultiSelectAsync
            selectedOptions={formik.values[name] as unknown as TValueType[]}
            onChange={handleChange}
            errorMessage={formik.touched[name] && formik.errors[name]?.toString()}
            {...restProps}
        />
    );
}
