import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const CloseCrossIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <path
                d="M20 5.61268L18.3886 4.00125L12 10.3898L5.61143 4.00125L4 5.61268L10.3886 12.0013L4 18.3898L5.61143 20.0013L12 13.6127L18.3886 20.0013L20 18.3898L13.6114 12.0013L20 5.61268Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
