import { makeStyles } from '@material-ui/core';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn } from '~/@components/Table';
import {
    getLandfillContextRole,
    IDetailedUser,
    IUserLandfillInfo,
    userLandfillsStore,
    UserRelationField,
} from '~/@store/users';

import { UserContextRoleChip } from '../UserContextRoleChip';
import { UserRelationHeadCell } from '../UserRelationHeadCell';
import { UserLandfillCell } from './UserLandfillCell';
import { UserLandfillDeleteCell } from './UserLandfillDeleteCell';

export const useUserLandfillsColumns = (user: IDetailedUser): ITableColumn<IUserLandfillInfo>[] => {
    const { actionCell, nameCell } = useStyles();

    // noinspection UnnecessaryLocalVariableJS
    const columns: ITableColumn<IUserLandfillInfo>[] = [
        {
            title: (
                <UserRelationHeadCell
                    title={<LocalizableText code={'UserCard.landfills.landfill'} />}
                    fieldName={UserRelationField.name}
                    store={userLandfillsStore}
                    withFilter
                    withSort
                />
            ),
            render: row => <UserLandfillCell landfill={row} />,
            cellClassName: nameCell,
            name: 'landfill',
        },
        {
            title: <LocalizableText code={'UserCard.landfills.role'} />,
            render: row => <UserContextRoleChip role={getLandfillContextRole(user, row)} />,
            name: 'role',
        },
        {
            title: null,
            render: row => <UserLandfillDeleteCell landfill={row} />,
            cellClassName: actionCell,
            name: 'delete',
        },
    ];

    return columns;
};

const useStyles = makeStyles(() => ({
    nameCell: {
        // to grow
        width: '99%',
    },
    actionCell: {
        minWidth: 'unset',
        // to shrink correctly
        whiteSpace: 'nowrap',
    },
}));
