import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { difference, isString, noop, uniqBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { Checkbox } from '~/@components/Checkbox';

import { ISochiTableColumn, ISochiTableProps, SochiTable } from './SochiTable';

interface SochiTableWithRowSelectProps<TRow> extends ISochiTableProps<TRow> {
    isRowSelectable?: (row: TRow) => boolean;
    onChange?: (rows: TRow[]) => void;
    selectedItems: TRow[];
}

export const SochiTableWithRowSelect = <TRow extends unknown>({
    columns,
    items,
    selectedItems,
    keyGetter,
    isRowSelectable,
    onChange,
    ...restProps
}: SochiTableWithRowSelectProps<TRow>) => {
    const { checkbox, transparent } = useStyles();

    const activeItems = useMemo(
        () => (isRowSelectable ? items.filter(isRowSelectable) : items),
        [items, isRowSelectable]
    );

    const useSelection = useMemo(() => activeItems.length > 0, [activeItems.length]);

    const activeKeys = useMemo(() => activeItems.map(keyGetter), [activeItems, keyGetter]);

    const selectedKeys = useMemo(() => selectedItems.map(keyGetter), [selectedItems, keyGetter]);

    const onChangeRowHandler = useCallback(
        (item: TRow) => (checked: boolean) => {
            const key = keyGetter(item);

            onChange?.(
                checked ? uniqBy([...selectedItems, item], keyGetter) : selectedItems.filter(i => keyGetter(i) !== key)
            );
        },
        [keyGetter, onChange, selectedItems]
    );

    const onChangeAllHandler = useCallback(checked => onChange?.(checked ? activeItems : []), [activeItems, onChange]);

    const areAllSelected = useMemo(() => difference(activeKeys, selectedKeys).length === 0, [activeKeys, selectedKeys]);

    const updatedColumns: ISochiTableColumn<TRow>[] = columns.length
        ? [
              {
                  ...columns[0],
                  title: (
                      <Checkbox
                          className={checkbox}
                          checked={areAllSelected}
                          onChange={onChangeAllHandler}
                          label={columns[0]!.title}
                      />
                  ),
                  render: row => {
                      const value = columns[0]!.render(row);

                      return isRowSelectable?.(row) ? (
                          <Checkbox
                              className={checkbox}
                              checked={selectedKeys.includes(keyGetter(row))}
                              onChange={onChangeRowHandler(row)}
                              label={value}
                              fullWidth
                              title={isString(value) ? value : undefined}
                          />
                      ) : (
                          <Checkbox
                              className={classnames(checkbox, transparent)}
                              onChange={noop}
                              checked={false}
                              label={value}
                              fullWidth
                              title={isString(value) ? value : undefined}
                          />
                      );
                  },
              } as ISochiTableColumn<TRow>,
              ...columns.slice(1),
          ]
        : columns;

    return (
        <SochiTable
            columns={useSelection ? updatedColumns : columns}
            items={items}
            keyGetter={keyGetter}
            {...restProps}
        />
    );
};

const useStyles = makeStyles(() => ({
    checkbox: {
        '& .MuiFormControlLabel-label': {
            fontWeight: 'inherit',
        },
    },
    transparent: {
        '& .MuiFormControlLabel-label': {
            cursor: 'text',
        },
        '& .MuiCheckbox-root, & .MuiCheckbox-colorPrimary, & .MuiCheckbox-colorSecondary': {
            color: 'transparent !important',
            backgroundColor: 'transparent !important',
            cursor: 'text',
        },
    },
}));
