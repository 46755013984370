import './ProjectDumpLoadDeclarationCheckbox.scss';

import React, { VFC } from 'react';

import { Checkbox } from '~/@components/Checkbox';
import { useFunctionalBem } from '~/@sochi-components/@bem';

import type { ICheckboxForDeclarationProps } from '../ProjectDumpLoadDeclaration';

export const ProjectDumpLoadDeclarationCheckbox: VFC<ICheckboxForDeclarationProps> = ({
    checked,
    onChangeState,
    label,
    disabled,
    className: classNameExternal,
}) => {
    const { className } = useFunctionalBem(ProjectDumpLoadDeclarationCheckbox, classNameExternal);

    return (
        <Checkbox
            className={className}
            checked={Boolean(checked)}
            onChange={onChangeState}
            label={label}
            disabled={disabled}
        />
    );
};
