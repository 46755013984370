import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props {
    children: string | null | void;
}

export const TwoLinesFormatter = ({ children }: Props) => {
    const { root } = useStyles();

    const content = children || '';

    return (
        <div className={root} title={content}>
            {content}
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        lineHeight: '18px',
        minHeight: 20,
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        whiteSpace: 'normal',
    },
}));
