import { LinearProgress } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';

import { useDeliveryLineCreateMutation } from '~/@store/deliveryLines';
import { IDumpLoadSearchRecord } from '~/@store/dumpLoads/dumpLoads.hooks/useDumpLoadSearchFunc';
import { useUserAbilities } from '~/contexts';
import { canCreateDeliveryLine } from '~/utils/auth';

import { getDeliveryLineCreateFormikConfig } from './DeliveryLineCreateFormik';
import { DeliveryLineEditActions } from './DeliveryLineEditActions';
import { DeliveryLineEditFields } from './DeliveryLineEditFields';
import { DeliveryLineEditLayout } from './DeliveryLineEditLayout';
import { DeliveryLineReceiptView } from './DeliveryLineReceiptView';

type Props = {
    dumpLoad: IDumpLoadSearchRecord | null;
    onClose: (v: boolean) => void;
};

export const DeliveryLineCreateForm = ({ dumpLoad, onClose }: Props) => {
    const [createDeliveryLine, isCreating] = useDeliveryLineCreateMutation();
    const abilityContext = useUserAbilities();
    const canCreate = (dumpLoad: IDumpLoadSearchRecord) => canCreateDeliveryLine(abilityContext, dumpLoad);

    return (
        <div>
            <Formik {...getDeliveryLineCreateFormikConfig(createDeliveryLine, dumpLoad, canCreate)}>
                {({ values }) => (
                    <DeliveryLineEditLayout
                        form={
                            <Form>
                                {isCreating && <LinearProgress />}
                                <DeliveryLineEditFields />
                                <DeliveryLineEditActions onClose={onClose} />
                            </Form>
                        }
                        preview={<DeliveryLineReceiptView newReceipt={values.receipt} />}
                    />
                )}
            </Formik>
        </div>
    );
};
