import { formatString } from '@common/utils';
import { FormikConfig, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { CreateDumpTypeFunc, IDumpType, UpdateDumpTypeFunc } from '~/@store/dumpTypes';
import i18n from '~/i18n';

export type IDumpTypeFormValues = {
    id: string;
    name: string;
    description: string;
    tonnsPerCubicM: number;
    usedInDeclaration: boolean;
    contaminationLevelAvailable: boolean;
};

export const useDumpTypeFormikContext = () => useFormikContext<IDumpTypeFormValues>();

export function getDumpTypeFormikConfig(
    dumpType: IDumpType | null,
    handleSubmitFunc: CreateDumpTypeFunc | UpdateDumpTypeFunc,
    closeForm: () => void
): FormikConfig<IDumpTypeFormValues> {
    return {
        initialValues: {
            id: dumpType?.id || '',
            name: dumpType?.name || '',
            description: dumpType?.description || '',
            tonnsPerCubicM: dumpType?.tonnsPerCubicM || 1,
            usedInDeclaration: dumpType?.usedInDeclaration || false,
            contaminationLevelAvailable: dumpType ? dumpType.contaminationLevelAvailable : true,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required(formatString(i18n.isRequired, i18n.DumpTypeCard.name)),
            tonnsPerCubicM: Yup.number().moreThan(
                0,
                formatString(i18n.mustBeGreaterThanZero, i18n.DumpTypeCard.tonnsPerCubicM)
            ),
        }),
        onSubmit: async (values, { resetForm }) => {
            const { tonnsPerCubicM, ...restValues } = values;

            const input = {
                tonnsPerCubicM: tonnsPerCubicM || 1,
                ...restValues,
            };

            await handleSubmitFunc(input);
            dumpType ? resetForm() : closeForm();
        },
    };
}
