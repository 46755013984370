import { gql } from '@apollo/client';

export const BeastMaterialFragment = gql`
    fragment BeastMaterialFragment on BeastMaterial {
        id
        beastId
        name
    }
`;

export const BeastMaterialListQuery = gql`
    query BeastMaterialListQuery {
        beastMaterials {
            ...BeastMaterialFragment
        }
    }

    ${BeastMaterialFragment}
`;

export const BeastMaterialCreateMutation = gql`
    mutation BeastMaterialCreateMutation($input: BeastMaterialCreateInput!) {
        createBeastMaterial(input: $input) {
            ...BeastMaterialFragment
        }
    }

    ${BeastMaterialFragment}
`;

export const BeastMaterialUpdateMutation = gql`
    mutation BeastMaterialUpdateMutation($input: BeastMaterialUpdateInput!) {
        updateBeastMaterial(input: $input) {
            ...BeastMaterialFragment
        }
    }

    ${BeastMaterialFragment}
`;

export const BeastMaterialRemoveMutation = gql`
    mutation BeastMaterialRemoveMutation($id: ID!) {
        removeBeastMaterial(id: $id)
    }
`;
