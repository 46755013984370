import numbro from 'numbro';

import { UnitOfMeasure, Units } from '../enums';
import { isNumber } from '../validations/types.validation';

const NBSP = String.fromCharCode(160);

numbro.registerLanguage(
    {
        languageTag: 'sv-SE',
        delimiters: {
            thousands: NBSP,
            decimal: ',',
        },
        abbreviations: {
            thousand: 't',
            million: 'M',
            billion: 'md',
            trillion: 'tmd',
        },
        ordinal: (value: number) => `${value}`,
        currency: {
            symbol: 'SEK',
            position: 'postfix',
            code: 'SEK',
        },
        currencyFormat: {
            thousandSeparated: true,
            spaceSeparated: true,
            spaceSeparatedCurrency: true,
            mantissa: 0,
            average: false,
            currencyPosition: 'prefix',
        },
        defaults: {
            thousandSeparated: true,
        },
        formats: {
            fourDigits: {
                totalLength: 4,
                spaceSeparated: true,
                average: true,
            },
            fullWithTwoDecimals: {
                output: 'currency',
                mantissa: 2,
                spaceSeparated: true,
                thousandSeparated: true,
            },
            fullWithTwoDecimalsNoCurrency: {
                mantissa: 2,
                thousandSeparated: true,
            },
            fullWithNoDecimals: {
                output: 'currency',
                spaceSeparated: true,
                thousandSeparated: true,
                mantissa: 0,
            },
        },
    },
    true
);

export const abbreviations = {
    na: 'N/A',
    ton: 'Ton',
    m3: 'm3',
    sek: 'SEK',
    km: 'km',
    min: 'min',
    kg: 'kg',
} as const;

export const precisions = {
    amount: 2,
    dumpLoadAmount: 0,
    price: 2,
    location: 6,
    distance: 1,
    decimal: 6,
    percent: 0,
} as const;

export const formatNumber = (
    v: number | null | undefined,
    precision?: number,
    forcePrecision = false,
    postfix = '',
    forceSign = false
): string => {
    if (!isNumber(v)) return abbreviations.na;

    postfix = postfix && NBSP + postfix;

    return numbro(v).format({
        mantissa: precision,
        optionalMantissa: false,
        trimMantissa: !forcePrecision,
        postfix,
        forceSign,
    });
};

export const formatCurrency = (v: number | null | undefined, forceSign?: boolean) =>
    formatNumber(v, precisions.price, true, abbreviations.sek, forceSign);

export const formatDistanceKm = (v: number | null | undefined, showPostix: boolean = true) =>
    formatNumber(v, precisions.distance, false, showPostix ? abbreviations.km : '');

export const formatDistance = (v: number | null | undefined, showPostix: boolean = true) =>
    formatDistanceKm(isNumber(v) ? v / 1000 : null, showPostix);

export const formatAmount = (v: number | null | undefined, unitOfMeasure: Units) =>
    formatNumber(
        v,
        precisions.amount,
        true,
        unitOfMeasure === UnitOfMeasure.TONNS ? abbreviations.ton : abbreviations.m3
    );

export const formatTonns = (v: number | null | undefined) => formatAmount(v, UnitOfMeasure.TONNS);

export const formatMinutes = (v: number | null | undefined) =>
    formatNumber(isNumber(v) ? v / 60 : null, 0, false, abbreviations.min);

export const formatCoordinate = (v: number | undefined | number) =>
    formatNumber(v, precisions.location, false).replace(',', '.');

export const formatCoordinates = (v: null | undefined | { lat: number; lng: number }) => {
    if (!v) return abbreviations.na;

    return `${formatCoordinate(v.lat)}, ${formatCoordinate(v.lng)}`;
};

export const formatKg = (v: number | null | undefined, showPostfix: boolean = true) =>
    formatNumber(v, precisions.amount, true, showPostfix ? abbreviations.kg : '');
