import './LandfillWorkTime.scss';

import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { SideBarTitle } from '~/@sochi-components/SideBarTitle';
import { canUpdateLandfill } from '~/utils/auth';

import { FormNames } from '../landfillFormikHOC';
import { useLandfillFormContext } from '../LandfillFormWithContext';
import { WorkTimeSelect } from './WorkTimeSelect';

type LandfillWorkTimeProps = {
    className?: string;
    enlargedHeading?: boolean;
};

export const LandfillWorkTime = ({ className, enlargedHeading = false }: LandfillWorkTimeProps) => {
    const bem = useFunctionalBem(LandfillWorkTime, className);
    const context = useLandfillFormContext();

    const { user, landfill, ability, values, errors, setFieldValueAndTouchIt } = context;

    return (
        <div className={bem.className}>
            {enlargedHeading ? (
                <>
                    <SideBarTitle title={<LocalizableText code={'LandfillForm.openingHours'} />} />
                    {errors.workTime ? <InputLabel error>{errors.workTime}</InputLabel> : null}
                </>
            ) : (
                <InputLabel className={bem.element('non-enlarged-heading')} error={Boolean(errors.workTime)}>
                    {errors.workTime ? errors.workTime : <LocalizableText code={'LandfillForm.openingHours'} />}
                </InputLabel>
            )}
            <WorkTimeSelect
                readOnly={Boolean(landfill && !canUpdateLandfill(user, ability, landfill))}
                value={values.workTime}
                onChange={value => setFieldValueAndTouchIt(FormNames.workTime, value, true)}
            />
        </div>
    );
};
