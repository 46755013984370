import './MapInput.scss';

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ILocation } from '~/@store/locations';
import {
    defaultZoom,
    GoogleMapsControlPosition,
    GoogleMapsMapTypeControlStyle,
    GoogleMapsMapTypeIds,
    GoogleMapsMouseEvent,
} from '~/@user-store/homePageMap';
import selectedPlace from '~/@views/UserView/HomePage/assets/default-marker-p.png';
import config from '~/config';

export type Props = {
    location: ILocation;
    onClick?: (e: GoogleMapsMouseEvent) => void;
    className?: string;
    readOnly?: boolean;
    zoom?: number;
};

const options = {
    mapTypeId: GoogleMapsMapTypeIds.HYBRID,
    zoomControl: true,
    zoomControlOptions: {
        position: GoogleMapsControlPosition.TOP_RIGHT,
    },
    mapTypeControl: true,
    mapTypeControlOptions: {
        style: GoogleMapsMapTypeControlStyle.DROPDOWN_MENU,
        position: GoogleMapsControlPosition.TOP_RIGHT,
    },
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
};

type MarkerProps = {
    location: ILocation;
    draggable?: boolean;
    onDrag?: (e: GoogleMapsMouseEvent) => void;
};

const DraggableMarker = ({ location, draggable = false, onDrag }: MarkerProps) => {
    return <Marker draggable={draggable} onDragEnd={onDrag} position={location} icon={selectedPlace} />;
};

export const MapInput = ({ location, onClick, className: propClassName, readOnly = false, zoom }: Props) => {
    const { className } = useFunctionalBem(MapInput);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: config.googleMapsApiKey,
    });

    return isLoaded ? (
        <GoogleMap
            mapContainerClassName={propClassName || className}
            options={options}
            onClick={readOnly ? undefined : onClick}
            zoom={zoom || defaultZoom}
            center={location}>
            <DraggableMarker location={location} draggable={!readOnly} onDrag={onClick} />
        </GoogleMap>
    ) : null;
};
