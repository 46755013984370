import { gql } from '@apollo/client';

import { CommentFragment } from '~/@store/comments';
import { DeliveryLineFragment } from '~/@store/deliveryLines/deliveryLines.queries';

import { LocationFragment } from '../locations/location.queries';

export const DestinationFragment = gql`
    fragment DestinationFragment on IDestination {
        landfill {
            id
            name
            workTime {
                openFrom
                openTo
                isOpen
            }
            location {
                text
            }
            gateFee
            status {
                id
                icon
                name
            }
            comment
            description
            isDeleted
        }
        subarea {
            id
            externalId
            allowTOC
            TOCPercent
            allowInvasiveSpecies
            comment
            over50cm
            reservedAmount
        }
        internalLoadFee
        loadFee
        distance
        distanceKm
        costPerTrip
    }
`;

export const DumpLoadDeclarationFragment = gql`
    fragment DumpLoadDeclarationFragment on ProjectLoadDeclaration {
        serialNumber
        producer
        invoice
        customerNr
        adressFrom
        adressTo
        placeFrom
        placeTo
        postNrFrom
        postNrTo
        referenceFrom
        referenceTo
        telFrom
        telTo
        organizationNrFrom
        organizationNrTo
        ewcCode
        process
        reason
        wasteType
        pollutionType
        subject1
        subject2
        subject3
        subject4
        subject5
        subject6
        value1
        value2
        value3
        value4
        value5
        value6
        unit1
        unit2
        unit3
        unit4
        unit5
        unit6
        isTunk
        isContainer
        isRails
        totalAmountCa
        deliveryAmountCa
        totalTon
        deliveryTon
        packaging1
        packaging2
        packaging3
        packaging4
        quantity1
        quantity2
        quantity3
        quantity4
        transport
        unNr
        class
        packaging
        conveyor
        organizationNr
        otherInfo
        infoAttached
        otherCar
        date
        stability
        consistence {
            isLiquid
            isViscous
            isFixed
            isPasty
            isPowder
            isSmall
            isOther
            other
        }
        risks {
            isToxic
            isHazard
            isHarmful
            isCorrosive
            isOxidising

            isFlammable
            isExplosive
            isFarlig
            isHygiene

            isAcid
            acid
            isAlk
            alk
        }

        hasMassEffluentRecord
        hasContaminatedSoilGroundWater
        hasDifferentSmellAndColor
        hasOccuredByExcavationOfPath
        hasPetroleumProductBeenOnSite
        hasAnyAccidentsOccuredOnProperty
        hasEnvironmentalTechnicalAssessment
        hasOtherAssessment
        wasteOriginate
        wasteOriginateMunicipality
    }
`;

export const DumpLoadProjectFragment = gql`
    fragment DumpLoadProjectFragment on ProjectLoad {
        project {
            id
            name
            comment
            startDate
            endDate
            userLinks {
                userId
                role
            }
            location {
                ...LocationFragment
            }
            customer {
                id
                name
            }
            customerId
            owner {
                id
                name
                surname
                email
            }
            status
            ver
            joinCode
        }
    }

    ${LocationFragment}
`;

export const DumpLoadShortFragment = gql`
    fragment DumpLoadShortFragment on ProjectLoad {
        id
        serialNumber
        status
        date
        endDate
        comment
        analysisFiles {
            id
            name
            uploadDate
        }
        priceData {
            landfillPrice
            internalLandfillPrice
            forceZeroPrices
        }
        amount
        amountInTons
        unitOfMeasure
        stackability
        over50cm
        createdAt
        updatedAt
        statusUpdatedAt
        contractSigned
        lastCommentText
        dumpType {
            id
            name
            tonnsPerCubicM
            contaminationLevelAvailable
        }
        landfill {
            id
            name
        }
        transportSchedule {
            week
            amount
        }
        FANumber
        toxicAnalysisApprovedExternally
        siteConfirmed
        creditCheck
        landfillOrdered
        solidTestResult
        withTOC
        TOCPercent
        withInvasiveSpecies
        invasiveSpecies
        inbound
        skipMatching

        deliveredVolume
        deliveryLineCount
        notVerifiedDeliveryLineCount
    }
`;

export const DumpLoadDetailedFragment = gql`
    fragment DumpLoadDetailedFragment on ProjectLoad {
        id
        serialNumber
        status
        date
        endDate
        comment
        analysisFiles {
            id
            name
            uploadDate
        }
        priceData {
            landfillPrice
            internalLandfillPrice
            forceZeroPrices
        }
        amount
        amountInTons
        unitOfMeasure
        stackability
        over50cm
        createdAt
        updatedAt
        statusUpdatedAt
        contractSigned
        lastCommentText
        dumpType {
            id
            name
            tonnsPerCubicM
            contaminationLevelAvailable
        }
        destinationLandfill {
            name
        }
        landfill {
            id
            name
        }
        transportSchedule {
            week
            amount
        }
        FANumber
        toxicAnalysisApprovedExternally
        siteConfirmed
        creditCheck
        landfillOrdered
        solidTestResult
        withTOC
        TOCPercent
        withInvasiveSpecies
        invasiveSpecies
        declaration {
            serialNumber
        }
        comments {
            ...CommentFragment
        }
        solidState
        leachingState
        destinationInfo {
            ...DestinationFragment
        }
        distance
        totalTrips
        actionsToShow {
            toStatus
            type
            actionName
        }
        yourOrderNumber
        contractSigned
        substances {
            substanceId
            contaminationType
            amount
        }
        deliveryLines {
            ...DeliveryLineFragment
        }
        timeSpent {
            id
            vehicleType {
                id
                name
                fuelConsumptions {
                    fuel
                    value
                }
            }
            date
            hoursSpent
            dumpLoadNumber
            licensePlate
            fuel
            engine
            user {
                id
                name
                surname
            }
        }
        deliveredVolume
        deliveryLineCount
        notVerifiedDeliveryLineCount
        isManualScheduled
        volumesPerPeriods {
            weeks {
                year
                week
                delivered
                planned
                text
            }
            months {
                year
                month
                delivered
                planned
                text
            }
            years {
                year
                delivered
                planned
                text
            }
        }
        volumesSplitPerPeriods {
            weeks {
                year
                week
                delivered
                planned
                text
            }
            months {
                year
                month
                delivered
                planned
                text
            }
            years {
                year
                delivered
                planned
                text
            }
        }
        beastMaterial {
            id
            beastId
            name
        }
        inbound
        skipMatching
        suggestedLandfillName
        suggestedLandfillAddress
        hasPeppolSettings
    }

    ${CommentFragment}
    ${DestinationFragment}
    ${DeliveryLineFragment}
`;
