import { gql } from '@apollo/client';

export const BlacklistItemFragment = gql`
    fragment BlacklistItemFragment on EmailsBlacklisted {
        id
        email
    }
`;

export const BlacklistQuery = gql`
    query BlacklistQuery($search: String) {
        emailsblackedlist(search: $search) {
            ...BlacklistItemFragment
        }
    }

    ${BlacklistItemFragment}
`;

export const DeleteFromBlacklistMutation = gql`
    mutation DeleteFromBlacklistMutation($id: ID!) {
        emailBlackedRemove(id: $id)
    }
`;

export const AddToBlacklistMutation = gql`
    mutation AddToBlacklistMutation($input: AddBlackedlist!) {
        emailBlackedCreate(input: $input) {
            ...BlacklistItemFragment
        }
    }

    ${BlacklistItemFragment}
`;
