import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { useProgressState } from '~/@components/@hooks/use-progress-state';
import { AutocompleteAsync } from '~/@components/Autocomplete';
import { IUserSearchRecord } from '~/@store/users';
import { useUsersSearchFunc } from '~/@store/users';
import { UserFilter } from '~/graphql';
import i18n from '~/i18n';
import { getUserFullName } from '~/utils/user';

import { PopupEditDialog } from './PopupEditDialog';

type Props = {
    label: string;
    initialValue: IUserSearchRecord | null;
    onSubmit: (value: IUserSearchRecord | null) => Promise<unknown>;
    onClose: (v: boolean) => void;
    usersFilter?: UserFilter;
    nullable?: boolean;
};

export const PopupUserSelect = ({ label, initialValue, onClose, onSubmit, usersFilter, nullable }: Props) => {
    const { root, textField } = useStyles();

    const [value, setValue] = useState(initialValue);

    const [isSubmitting, submit] = useProgressState(onSubmit);

    const handleSubmit = () => {
        if (value) submit(value).then(() => onClose(true));
    };

    const handleClear = () => {
        if (nullable) submit(null).then(() => onClose(true));
    };

    const usersSearch = useUsersSearchFunc(usersFilter);

    return (
        <PopupEditDialog onClose={onClose}>
            <div className={root}>
                <AutocompleteAsync
                    label={label}
                    selectedOption={value}
                    onOptionSelect={setValue}
                    renderOption={getUserFullName}
                    loadOptions={usersSearch}
                    autoFocus
                    className={textField}
                />
                <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
                    {i18n.save}
                </Button>
                {nullable && (
                    <Button disabled={isSubmitting} variant="contained" onClick={handleClear}>
                        {i18n.clear}
                    </Button>
                )}
            </div>
        </PopupEditDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        gap: theme.spacing(),
    },
    textField: {
        backgroundColor: theme.palette.background.paper,
    },
}));
