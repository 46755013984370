import { LandfillFields, LandfillSubareaFields, UserContextRole, UserRole } from '../../enums';
import { IAbilityComplexSubject } from '../abilities.types';

export interface IAbilityLandfill {
    customerId: string;
    receiverIds: string[];
    deletedAt?: string | Date | null;
    ownerUserId?: string | null;
}

export enum LandfillSubject {
    LANDFILL_DELETED = 'LANDFILL_DELETED',
    SUBAREAS_LIST = 'SUBAREAS_LIST',
    MAINTENANCE = 'MAINTENANCE',
    DELIVERY_EVENTS = 'DELIVERY_EVENTS',
}

export type ILandfillRelationRole = UserContextRole | UserRole;

export type ILandfillSubject = `${LandfillSubject | LandfillFields | LandfillSubareaFields}`;

export interface ILandfillCalculatedSubject extends IAbilityComplexSubject {
    relationRole: Array<ILandfillRelationRole>;
    subSubject: Array<ILandfillSubject>;
}
