import { MessageAction, MessagePlaces, MessageTypes, TimeoutModifier } from '~/stores/models';

import messageStore from '../../stores/messageStore';

const global = {
    /**
     * Show success message on top of the page
     * @param message Message to show
     * @param action Optional { label: string, callback: func } - for button in message
     * @param isLong Optional Long lasting message (30 sec)
     */
    success: (message: string, action?: MessageAction, timeoutModifier?: TimeoutModifier) =>
        messageStore._showMessage(MessagePlaces.GLOBAL, MessageTypes.SUCCESS, message, action, timeoutModifier),
    /**
     * Show error message on top of the page
     * @param message Message to show
     * @param action Optional { label: string, callback: func } - for button in message
     * @param isLong Optional Long lasting message (30 sec)
     */
    error: (message: string, action?: MessageAction, timeoutModifier?: TimeoutModifier) =>
        messageStore._showMessage(MessagePlaces.GLOBAL, MessageTypes.ERROR, message, action, timeoutModifier),
    /**
     * Show info message on top of the page
     * @param message Message to show
     * @param action Optional { label: string, callback: func } - for button in message
     * @param isLong Optional Long lasting message (30 sec)
     */
    info: (message: string, action?: MessageAction, timeoutModifier?: TimeoutModifier) =>
        messageStore._showMessage(MessagePlaces.GLOBAL, MessageTypes.INFO, message, action, timeoutModifier),
};

const inPage = {
    /**
     * Show success message inside of page
     * @param message Message to show
     * @param action Optional { label: string, callback: func } - for button in message
     */
    success: (message: string, action?: MessageAction) =>
        messageStore._showMessage(MessagePlaces.IN_PAGE, MessageTypes.SUCCESS, message, action),
    /**
     * Show error message inside of page
     * @param message Message to show
     * @param action Optional { label: string, callback: func } - for button in message
     */
    error: (message: string, action?: MessageAction) =>
        messageStore._showMessage(MessagePlaces.IN_PAGE, MessageTypes.ERROR, message, action),
    /**
     * Show info message inside of page
     * @param message Message to show
     * @param action Optional { label: string, callback: func } - for button in message
     */
    info: (message: string, action?: MessageAction) =>
        messageStore._showMessage(MessagePlaces.IN_PAGE, MessageTypes.INFO, message, action),
};

export const globalMessage = global;

export const inPageMessage = inPage;

const defaultExport = { global, inPage };

export default defaultExport;

// Debug and testing purpose
if (window.location.href.includes('localhost')) {
    // @ts-ignore Global variable for testing
    window.test_message = { global, inPage };
}
