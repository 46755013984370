import './ProjectOfferModal.scss';

import { ErrorCodes } from '@common/enums';
import { FileFormats } from '@common/enums';
import type { History as RouterHistory } from 'history';
import React, { Component } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { getBem } from '~/@sochi-components/@bem';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { ArrowRightIcon } from '~/@sochi-components/Icons';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import { DialogTitle } from '~/@sochi-components/SochiDialog/DialogTitle';
import { SochiPdfPreview } from '~/@sochi-components/SochiPdfPreview';
import { ISochiTableColumn, SochiTable } from '~/@sochi-components/SochiTable';
import { projectDetailRoute } from '~/@store/routes';
import client, { uploadDeclarationUri } from '~/apolloClient';
import { AbilityUserContext, IAbilityUserContext } from '~/contexts';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';
import message from '~/services/message';
import offersStore from '~/stores/offersStore';
import { translateInvalidFields } from '~/utils';
import { changeDumpLoadStatus, downloadDeclarationOfWaste, sendDumpLoadForSign } from '~/utils/dumpLoad';
import { handleProjectPermissionError } from '~/utils/project';

import { IOffer, IProjectOffer, LoadStatus } from '../types';

type ProjectOfferModalProps = {
    dumpLoad: IOffer;
    project: IProjectOffer;
    onClose: (arg: boolean) => void;
    history: RouterHistory;
};

type IDocument = { id: string; status: string; documentType: string };

export class ProjectOfferModal extends Component<ProjectOfferModalProps> {
    static contextType = AbilityUserContext;
    context!: IAbilityUserContext;

    bem = getBem(this);

    canBuy(): boolean {
        return this.props.dumpLoad.status === LoadStatus.CONFIRMED;
    }

    onBuy = async () => {
        const { project, dumpLoad } = this.props;
        if (!this.canBuy()) return;

        changeDumpLoadStatus({
            client,
            dumpLoadIds: [dumpLoad.id],
            project,
            status: LoadStatus.ORDERED,
        })
            .then(() => {
                this.onClose(true);
                offersStore.loadOffersCount();
            })
            .catch(err => {
                const error = err.graphQLErrors?.[0];
                if (error?.code === ErrorCodes.DUMP_LOAD_FIELDS_HAVE_INVALID_VALUES) {
                    const text = i18n.errorCodes.DUMP_LOAD_FIELDS_HAVE_INVALID_VALUES + translateInvalidFields(error);

                    message.global.error(text, {
                        label: i18n.toProjectPage,
                        callback: () => {
                            this.props.history.push(projectDetailRoute(project.id));
                        },
                    });
                } else {
                    handleProjectPermissionError(this.context.ability, err);
                }
            });
    };

    signDumpLoad = async () => {
        await sendDumpLoadForSign({ client, dumpLoadId: this.props.dumpLoad.id, projectId: this.props.project.id });
        message.global.success(i18n.signActions.checkEmail);
        this.onClose(true);
    };

    onDecline = () => {
        const { project, dumpLoad } = this.props;

        changeDumpLoadStatus({
            client,
            dumpLoadIds: [dumpLoad.id],
            project,
            status: LoadStatus.NOT_ORDERED,
        }).then(() => {
            this.onClose(true);
            offersStore.loadOffersCount();
        });
    };

    onClose = (reload: boolean = false) => {
        this.props.onClose(reload);
    };

    getColumns = (): Array<ISochiTableColumn<IDocument>> => {
        const { element } = this.bem;

        return [
            {
                title: <LocalizableText code={'status'} />,
                render: data => {
                    return <span className={element('status-td')}>{data.status || ''}</span>;
                },
                alignLeft: true,
            },
            { title: <LocalizableText code={'documentType'} />, render: data => data.documentType, alignLeft: true },
            // TODO: it must be refactored after scrive issue completed! https://redmine.pinpointer.se/issues/1437
            {
                title: '',
                render: data => {
                    if (data.documentType === i18n.declarationOfWaste)
                        return (
                            <span
                                onClick={() => this.showDeclarationOfWastePreview()}
                                className={element('view-offer-fortnox')}>
                                <LocalizableText code={'view'} />{' '}
                                <ArrowRightIcon
                                    className={element('arrow-right-icon')}
                                    color="primary"
                                    fontSize="inherit"
                                />
                            </span>
                        );

                    return i18n.NA;
                },
            },
        ];
    };

    showDeclarationOfWastePreview = () => {
        const { project, dumpLoad } = this.props;
        showCustomDialog({
            title: <LocalizableText code={'declarationOfWaste'} />,
            render: closeDialog => (
                <DialogContent className={this.bem.element('declaration-preview')}>
                    <SochiPdfPreview
                        url={uploadDeclarationUri}
                        fetchData={{
                            projectId: project.id,
                            projectLoadId: dumpLoad.id,
                            format: FileFormats.PDF,
                        }}
                    />
                    <ButtonGroup>
                        <Button size="large" variant="outlined" color="primary" onClick={() => closeDialog(false)}>
                            <LocalizableText code={'close'} />
                        </Button>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={this.downloadDeclarationOfWaste}>
                            <LocalizableText code={'download'} />
                        </Button>
                    </ButtonGroup>
                </DialogContent>
            ),
        });
    };

    getData = (): Array<IDocument> => {
        return [
            { id: '1', status: i18n.signed, documentType: i18n.depositionContract },
            { id: '2', status: i18n.signed, documentType: i18n.declarationOfWaste },
            { id: '3', status: i18n.signed, documentType: i18n.mewabDocument },
        ];
    };

    isWaitingForPinpointer = () => {
        const {
            dumpLoad: { status },
        } = this.props;

        return status === LoadStatus.REQUESTED || status === LoadStatus.ORDERED;
    };

    downloadDeclarationOfWaste = () => {
        const { project, dumpLoad } = this.props;
        downloadDeclarationOfWaste(project, dumpLoad.id, this.context);
    };

    render() {
        const { dumpLoad } = this.props;
        const { className, element } = this.bem;
        const hideButtons = dumpLoad.status === LoadStatus.NOT_ORDERED || this.isWaitingForPinpointer();

        const showWaitingInfo = this.isWaitingForPinpointer();

        return (
            <div className={className}>
                <DialogTitle className={element('offers-padding')} secondaryInfo={`3/3 ${i18n.documentsSigned}`}>
                    <LocalizableText code={'report'} />
                </DialogTitle>
                <DialogContent>
                    <SochiTable
                        className={element('table')}
                        columns={this.getColumns()}
                        items={this.getData()}
                        keyGetter={({ id }) => id}
                    />
                    {showWaitingInfo && (
                        <p className={element('action-info-text')}>
                            <LocalizableText code={'DumploadActions.waitPinpointer'} />
                        </p>
                    )}
                    <ButtonGroup>
                        <Button color="primary" variant="outlined" size="large" onClick={() => this.onClose(false)}>
                            <LocalizableText code={'close'} />
                        </Button>
                        {!hideButtons && (
                            <>
                                <Button onClick={this.onDecline} color="primary" variant="outlined" size="large">
                                    <LocalizableText code={'decline'} />
                                </Button>
                                {this.canBuy() && (
                                    <Button onClick={this.onBuy} color="primary" variant="contained" size="large">
                                        <LocalizableText code={'accept'} />
                                    </Button>
                                )}
                                {dumpLoad.signDetails?.canSendForSign && (
                                    <Button
                                        onClick={this.signDumpLoad.bind(this)}
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        // TODO: Restore in: https://redmine.pinpointer.se/issues/3273
                                        disabled={true}>
                                        <LocalizableText code={'signAction'} />
                                    </Button>
                                )}
                            </>
                        )}
                    </ButtonGroup>
                </DialogContent>
            </div>
        );
    }
}
