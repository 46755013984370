import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { StorePagination } from '~/@components/Pagination';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import { IPagedProjects, IProject, usePagedProjects } from '~/@store/projects';
import { projectsStore } from '~/@store/projects/project.store';

import { ProjectsFilterSummary } from './ProjectsFilterSummary';
import { useProjectsColumns } from './useProjectsColumns';

export const ProjectsTable = () => {
    const query = usePagedProjects();
    const { table, tableHeader } = useStyles();

    const columns = useProjectsColumns();

    return (
        <QueryStateHandler
            query={query}
            dataGetter={query => query.data?.projectsPaged || query.previousData?.projectsPaged}>
            {(data: IPagedProjects) => (
                <>
                    <div className={tableHeader}>
                        <ProjectsFilterSummary />
                        <StorePagination store={projectsStore.pagination} />
                    </div>
                    <Table<IProject>
                        columns={columns}
                        items={data.edges.map(e => e.node)}
                        keyGetter={o => o.id}
                        className={table}
                        name="ProjectTable"
                    />
                </>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        height: 'calc(100% - 48px)',
    },
    tableHeader: {
        height: 48,
        display: 'flex',
        marginTop: -8,
    },
}));
