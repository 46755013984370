import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { SortSection, StringFilter, TableHeadCell } from '~/@components/Table/TableHeadCell';
import { companiesStore, CompaniesStoreFilterFields } from '~/@store/companies/companies.store';
import { SortInput } from '~/graphql';
import i18n from '~/i18n';

export interface CompaniesHeadCellProps {
    title: React.ReactNode;
    fieldName: CompaniesStoreFilterFields;
    withFilter?: boolean;
    withSort?: boolean;
}

export const CompaniesHeadCell = (options: CompaniesHeadCellProps) => {
    const { title, fieldName, withFilter, withSort } = options;
    const sortValue = useObservable(() => companiesStore.sort.value);
    const setSortValue = useCallback((v: SortInput) => companiesStore.sort.setValue(v), []);

    const sortSection = useMemo(() => {
        if (!withSort) return null;

        return (
            <SortSection
                fieldName={fieldName.toString()}
                sort={sortValue}
                setSort={setSortValue}
                labels={i18n.SortString}
            />
        );
    }, [withSort, fieldName, sortValue, setSortValue]);

    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        return (
            <StringFilter
                valueGetter={() => companiesStore.filter.values[fieldName]}
                setValue={v => companiesStore.filter.setValue(fieldName, v)}
            />
        );
    }, [withFilter, fieldName]);

    return (
        <TableHeadCell title={title} sortDirection={sortValue?.field === fieldName ? sortValue.direction : null}>
            {sortSection}
            {filterSection}
        </TableHeadCell>
    );
};
