import React, { FC } from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { makeStyles } from '~/@components/@theme';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { ILandfillLight, landfillsStore } from '~/@store/landfills';
import { useLandfills } from '~/@store/landfills/landfills.hooks/useLandfills';

import { DumpLoadLandfillDestinationFilter } from './DumpLoadLandfillDestinationFilter';
import { DumpLoadLandfillDestinationList } from './DumpLoadLandfillDestinationList';

export const DumpLoadEditLandfillDestination: FC = () => {
    useSyncEffect(() => landfillsStore.setLandfillsPageViewMode());

    const query = useLandfills();

    const { root, list } = useStyles();

    return (
        <QueryStateHandler query={query} dataGetter={query => query.data?.landfills || query.previousData?.landfills}>
            {(data: ILandfillLight[]) => (
                <div className={root}>
                    <DumpLoadLandfillDestinationFilter />
                    <DumpLoadLandfillDestinationList className={list} landfills={data} />
                </div>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    list: {
        height: 'calc(100vh - 400px)',
        overflowY: 'scroll',
    },
}));
