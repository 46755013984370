import React, { VFC } from 'react';

import { SimpleDialog } from '~/@components/Dialog';
import { showCustomDialog } from '~/@store/common';

import { DumpTypeForm } from './DumpTypeForm';

type DumpTypeCreateDialogProps = {
    onClose: () => void;
};

const DumpTypeCreateDialog: VFC<DumpTypeCreateDialogProps> = ({ onClose }) => {
    return (
        <SimpleDialog onClose={onClose} maxWidth="lg">
            <DumpTypeForm onClose={onClose} />
        </SimpleDialog>
    );
};

export const showDumpTypeCreateDialog = () =>
    showCustomDialog({
        render: closeDialog => <DumpTypeCreateDialog onClose={closeDialog} />,
    });
