import { makeStyles } from '@material-ui/core';
import { FormikContextType } from 'formik';
import React, { FC, useCallback } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { SochiRadioButton } from '~/@sochi-components/SochiRadioButton';
import { DumpLoadMode } from '~/utils/dumpLoad';

import { IFormValues } from '../AddEditMass.types';

type Props = {
    formik: FormikContextType<IFormValues>;
    readOnly: boolean;
};

export const ModeSelector: FC<Props> = ({ formik, readOnly }) => {
    const { values, setFieldValue } = formik;

    const { container } = useStyles();

    const { mode } = values;

    const setMode = useCallback(
        (value: DumpLoadMode) => {
            setFieldValue('mode', value);
        },
        [setFieldValue]
    );

    return (
        <div className={container}>
            <SochiRadioButton
                checked={mode === 'outbound'}
                value={'outbound'}
                setValue={() => setMode('outbound')}
                label={<LocalizableText code={'priceCalculatorPage.outboundMode'} />}
                disabled={readOnly}
            />
            <SochiRadioButton
                checked={mode === 'inbound'}
                value={'inbound'}
                setValue={() => setMode('inbound')}
                label={<LocalizableText code={'priceCalculatorPage.inboundMode'} />}
                disabled={readOnly}
            />
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        alignItems: 'center',
    },
});
