import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo, VFC } from 'react';

import { COLORS } from '~/@components/@theme';
import { Grid } from '~/@components/Grid';
import { ArrowForwardIcon } from '~/@components/Icon';
import { Link } from '~/@components/Link';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { IDeliveryLineHistoryRecord } from '~/@store/deliveryLines';
import { userDetailRoute } from '~/@store/routes';
import { UserRole } from '~/graphql';
import i18n from '~/i18n';
import { formatDateTime } from '~/utils/date';
import { getUserFullName } from '~/utils/user';

type HistoryUserProps = {
    user: IDeliveryLineHistoryRecord['user'];
};

const HistoryUser = ({ user }: HistoryUserProps) => {
    if (!user)
        return (
            <Text variant="TableRegular" color="textSecondary">
                {i18n.NA}
            </Text>
        );

    const href = userDetailRoute(user.id);

    return (
        <Text variant="TableRegular">
            <LocalizableText
                code={user.role === UserRole.DRIVER ? 'DeliveryLineHistoryCard.driver' : 'DeliveryLineHistoryCard.user'}
            />
            {': '}
            <Link to={href} targetBlank>
                {getUserFullName(user)}
            </Link>
        </Text>
    );
};

type ItemProps = {
    line: IDeliveryLineHistoryRecord;
};

const DeliveryLineHistoryItem: VFC<ItemProps> = ({ line }: ItemProps) => {
    const { item, arrow, borderCell, lastBorderCell } = useStyles();

    const totalChanges = useMemo(() => line.changes.length, [line]);

    return (
        <div className={item}>
            <Text variant="BodyBold">{formatDateTime(line.createdAt)}</Text>
            <HistoryUser user={line.user} />
            <Grid container>
                {line.changes.map((change, index) => {
                    const style = index === totalChanges - 1 ? lastBorderCell : borderCell;

                    return (
                        <React.Fragment key={index}>
                            <Grid item xs={5} className={style}>
                                <Text variant="TableRegular">{change.field}</Text>
                            </Grid>
                            <Grid item xs={3} className={style}>
                                <Text variant="TableRegular" noWrap>
                                    {change.valueOld}
                                </Text>
                            </Grid>
                            <Grid item xs={1} className={style}>
                                <ArrowForwardIcon className={arrow} />
                            </Grid>
                            <Grid item xs={3} className={style}>
                                <Text variant="TableRegular" noWrap>
                                    {change.valueNew}
                                </Text>
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>
        </div>
    );
};

type Props = {
    historyLines: IDeliveryLineHistoryRecord[];
};

export const DeliveryLineHistoryForm: VFC<Props> = ({ historyLines }: Props) => {
    const { container } = useStyles();

    if (!historyLines.length) return <h1>{i18n.emptyList}</h1>;

    return (
        <div className={container}>
            {historyLines.map(line => (
                <DeliveryLineHistoryItem line={line} />
            ))}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
    },
    arrow: {
        fontSize: 'small',
        color: COLORS.grayDark,
    },
    borderCell: {
        borderBottom: `1px solid ${COLORS.gray}`,
        padding: theme.spacing(1, 0),
    },
    lastBorderCell: {
        borderBottom: '0px',
        padding: theme.spacing(1, 0),
    },
}));
