import { AbilityCan, AbilitySubjects, UserRole } from '../../enums';
import { IAbilityFunction } from '../abilities.types';
import { ICaslCondition } from '../utils';
import { IVehicleTypeCalculatedSubject } from './vehicleTypeTypes';

const { CRUD, READ } = AbilityCan;
const { ADMIN, EMPLOYEE, DRIVER, EXCAVATOR_OPERATOR } = UserRole;

// forbid argument kept for uniform define ability calls
export function defineAbilitiesForVehicleTypes(_allow: IAbilityFunction, _forbid: IAbilityFunction) {
    type SubjectShape = ICaslCondition<IVehicleTypeCalculatedSubject>;

    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) =>
        _allow(can, AbilitySubjects.VEHICLE_TYPE, shape);

    allow(CRUD, { relationRole: ADMIN });
    allow(READ, { relationRole: EMPLOYEE });
    allow(READ, { relationRole: DRIVER });
    allow(READ, { relationRole: EXCAVATOR_OPERATOR });
}
