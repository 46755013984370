import './LandfillEditFormCommonFields.scss';

import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';

import {
    FormNames,
    LandfillCustomerSelect,
    LandfillLocationSelect,
    LandfillPaymentCapacitySection,
    LandfillStatusSelect,
    LandfillTextField,
    LandfillUserSearchSelect,
} from '../../LandfillFormParts';

type LandfillEditFormCommonFieldsProps = {
    className?: string;
};

export const LandfillEditFormCommonFields = ({ className }: LandfillEditFormCommonFieldsProps) => {
    const bem = useFunctionalBem(LandfillEditFormCommonFields, className);

    return (
        <div className={bem.className}>
            <LandfillTextField name={FormNames.name} label={<LocalizableText code={'LandfillForm.siteName'} />} />
            <LandfillLocationSelect className={bem.element('form-control')} />
            <LandfillStatusSelect className={bem.element('form-control')} />
            <LandfillPaymentCapacitySection />
            <LandfillUserSearchSelect className={bem.element('form-control')} />
            <LandfillCustomerSelect className={bem.element('form-control')} />
        </div>
    );
};
