import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useDumpLoadRules } from '~/@store/permissions';

import { PermissionsTable } from './PermissionsTable';

export const DumpLoadPermissions = () => {
    const rules = useDumpLoadRules();
    const extendedColumns = [
        {
            field: 'projectSubject' as const,
            title: <LocalizableText code={'PermissionsPage.projectSubject'} />,
        },
        {
            field: 'dumpLoadSubject' as const,
            title: <LocalizableText code={'PermissionsPage.dumpLoadSubject'} />,
        },
    ];

    return (
        <PermissionsTable
            title={<LocalizableText code={'PermissionsPage.dumpLoad'} />}
            rules={rules}
            extendedColumns={extendedColumns}
        />
    );
};
