import React, { memo, VFC } from 'react';

import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { projectDetailRoute } from '~/@store/routes';

type Props = {
    project: { id: string; name: string };
};

export const UserProjectCell: VFC<Props> = memo(({ project }) => {
    const actions: ITableCellAction[] = [
        {
            variant: ActionVariant.LINK,
            to: projectDetailRoute(project.id),
        },
    ];

    return <TableCellActions actions={actions}>{project.name}</TableCellActions>;
});
