import React, { useCallback, useMemo } from 'react';

import { DateFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { canEditLineInline, IDeliveryLine, useDeliveryLineUpdateMutation } from '~/@store/deliveryLines';
import { LockEntityType, useDialogWithLock } from '~/@store/locks';

import { PopupDateEdit } from '../../common/PopupEditFields';

type Props = {
    deliveryLine: IDeliveryLine;
};

export const DeliveryLineDateCell = ({ deliveryLine }: Props) => {
    const [updateDeliveryLine] = useDeliveryLineUpdateMutation(deliveryLine);

    const disabled = !canEditLineInline(deliveryLine);

    const submit = useCallback(
        (value: Date | null) => {
            if (value) {
                return updateDeliveryLine({ plannedStartDate: value.toISOString() });
            } else return Promise.resolve();
        },
        [updateDeliveryLine]
    );

    const dialogWithLock = useDialogWithLock(LockEntityType.ORDER, deliveryLine.orderNumber);

    const onEdit = useCallback(
        () =>
            dialogWithLock({
                render: closeDialog => (
                    <PopupDateEdit
                        label={<LocalizableText code={'DeliveryLinesTable.date'} />}
                        initialValue={deliveryLine.plannedStartDate}
                        onSubmit={submit}
                        onClose={closeDialog}
                        nullable={false}
                    />
                ),
            }),
        [dialogWithLock, deliveryLine, submit]
    );

    const actions: Array<ITableCellAction> = useMemo(
        () => [
            {
                variant: ActionVariant.EDIT,
                onClick: onEdit,
                disabled,
            },
        ],
        [onEdit, disabled]
    );

    return (
        <TableCellActions actions={actions}>
            <DateFormatter>{deliveryLine.plannedStartDate}</DateFormatter>
        </TableCellActions>
    );
};
