import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
    UserCommentCreateMutation,
    UserCommentCreateMutation_userAddComment,
    UserCommentCreateMutationVariables,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../users.queries';

type UserCommentCreateFunc = (userId: string, text: string) => Promise<UserCommentCreateMutation_userAddComment | null>;

export const useUserCommentCreateMutation = (): [UserCommentCreateFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<UserCommentCreateMutation, UserCommentCreateMutationVariables>(
        queries.UserCommentCreateMutation
    );

    const userCommentCreate = useCallback<UserCommentCreateFunc>(
        (userId, text) => {
            return callMutation({ variables: { userId, text } })
                .catch(handleApolloError)
                .then(result => result.data!.userAddComment);
        },
        [callMutation]
    );

    return [userCommentCreate, mutationResult.loading];
};
