import { Colors } from '../../../common/enums';
import { LandfillQuery_landfill_ordersInfo, LandfillQuery_landfill_ordersInfo_volumes, LoadStatus } from '../graphql';
import type { SeriesData, SeriesIntervalValue } from './index';

const getOrderDisplayColor = (order: LandfillQuery_landfill_ordersInfo): Colors => {
    if (order.dumpLoad.status === LoadStatus.DONE) return Colors.green;
    if (order.deliveredVolume > 0) return Colors.orange;

    return Colors.gray;
};

const toIntervalValue = (interval: { delivered: number; planned: number; text: string }): SeriesIntervalValue => {
    return {
        delivered: interval.delivered,
        planned: interval.planned,
        text: interval.text,
        info: '',
    };
};

export const convertOrderVolumesToSeriesData = (order: LandfillQuery_landfill_ordersInfo): SeriesData => {
    const volumes: LandfillQuery_landfill_ordersInfo_volumes = order.volumes;

    return {
        id: order.id,
        name: order.orderNumber,
        color: getOrderDisplayColor(order),
        values: {
            weeks: volumes.weeks.map(toIntervalValue),
            months: volumes.months.map(toIntervalValue),
            years: volumes.years.map(toIntervalValue),
        },
    };
};
