import cn from 'classnames';
import React, { ReactNode } from 'react';

import { makeStyles } from '~/@components/@theme';

type Props = {
    className?: string;
    children: ReactNode | null;
};

export const BiSideLayout = ({ className = '', children }: Props) => {
    const { layout } = useStyles();

    return <div className={cn(className, layout)}>{children}</div>;
};

const LeftSideContent = ({ className = '', children }: Props) => {
    const { leftSide } = useStyles();

    return <div className={cn(className, leftSide)}>{children}</div>;
};

const RightSideContent = ({ className = '', children }: Props) => {
    const { rightSide } = useStyles();

    return <div className={cn(className, rightSide)}>{children}</div>;
};

BiSideLayout.LeftSideContent = LeftSideContent;
BiSideLayout.RightSideContent = RightSideContent;

const useStyles = makeStyles({
    layout: {
        display: 'grid',
        gridAutoColumns: '524px 1fr',
        gridColumnGap: 80,
    },
    leftSide: {
        gridColumn: 1,
    },
    rightSide: {
        gridColumn: 2,
    },
});
