import './ProjectMassesDetails.scss';

import InputLabel from '@material-ui/core/InputLabel';
import React, { VFC } from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { DeliveriesTable } from '~/@views/UserView/ProjectPage/common';
import i18n from '~/i18n';

import { ProjectDoneActionHeader } from '../../common/ProjectDoneActionHeader';
import type { ProjectCommonDataProps } from '../../ProjectPage/ProjectPage';
import { ProjectMassSchedule } from '../ProjectMassSchedule';

export const ProjectMassesDetails: VFC<ProjectCommonDataProps> = props => {
    const { className } = useFunctionalBem(ProjectMassesDetails);

    const { match, project } = props;
    const {
        params: { massId },
    } = match;

    const mass = project.dumpLoads.find(el => el.id === massId);

    if (!mass) return <InputLabel error>{i18n.errorCodes.DUMP_LOAD_NOT_FOUND}</InputLabel>;

    return (
        <div className={className}>
            <ProjectDoneActionHeader project={project} />
            <ProjectMassSchedule {...props} />
            <DeliveriesTable
                projectId={project.id}
                dumpLoads={[mass]}
                title={<LocalizableText code={'transportHistory.title'} />}
            />
        </div>
    );
};
