import ChipMUI, { ChipProps } from '@material-ui/core/Chip';
import React, { useMemo } from 'react';

import { COLORS } from '~/@components/@theme/colors';

interface Props extends Omit<ChipProps, 'children' | 'color' | 'onClick'> {
    color?: string | null | void;
    children: React.ReactNode;
    onClick?: () => void;
    className?: string;
}

export const Chip = ({ children, color, onClick, className, ...rest }: Props) => {
    const { variant = 'outlined' } = { ...rest };

    const style = useMemo(
        () =>
            variant === 'outlined'
                ? {
                      borderColor: color || COLORS.gray,
                      color: color || COLORS.gray,
                  }
                : {
                      backgroundColor: color || COLORS.gray,
                      color: COLORS.black,
                  },
        [color, variant]
    );

    return (
        <ChipMUI onClick={onClick} className={className} style={style} label={children} variant="outlined" {...rest} />
    );
};
