import { FilterRange } from '@common/types';
import { USER_VIEW_MODE } from '@common/viewModes';
import { action, computed, observable } from 'mobx';

import { DEFAULT_USERS_SORT } from '~/@store/users/users.constants';
import { AdminUsersPagedQueryVariables, UserFilter, UserRole } from '~/graphql';
import { serializeDateRange } from '~/utils/date';
import { UserStatus, userStatusToFilterValues } from '~/utils/user';

import { FilterStore, PaginationStore, SortStore } from '../common';

type UserStoreFilter = {
    email?: string | null;
    name?: string | null;
    surname?: string | null;
    phoneNumber?: string | null;
    status?: { id: UserStatus; name: string } | null;
    roles?: { id: UserRole; name: string }[] | null;
    createdAt?: FilterRange<Date> | null;
    deletedAt?: FilterRange<Date> | null;
    lastActivityDate?: FilterRange<Date> | null;
    customerName: string | null;
};

export enum UserField {
    email = 'email',
    name = 'name',
    surname = 'surname',
    phoneNumber = 'phoneNumber',
    status = 'status',
    roles = 'roles',
    createdAt = 'createdAt',
    customerName = 'customerName',
    lastActivityDate = 'lastActivityDate',
    deletedAt = 'deletedAt',
}

export enum AdminUserColumn {
    name = 'name',
    lastName = 'lastName',
    email = 'email',
    phone = 'phone',
    customer = 'customer',
    status = 'status',
    role = 'role',
    comments = 'comments',
    created = 'created',
    lastActivityDate = 'lastActivityDate',
    deletedAt = 'deletedAt',
}

class UsersStore {
    pagination = new PaginationStore();
    filter = new FilterStore<UserStoreFilter>(this.pagination.onClear);
    sort = new SortStore(DEFAULT_USERS_SORT, this.pagination.onClear);

    @observable
    companyId: string | null = null;

    @computed
    get hiddenColumns(): AdminUserColumn[] {
        const result: AdminUserColumn[] = [];

        if (this.companyId) result.push(AdminUserColumn.customer);
        if (this.mode === USER_VIEW_MODE.USERS) result.push(AdminUserColumn.deletedAt);

        return result;
    }

    @observable
    mode: USER_VIEW_MODE = USER_VIEW_MODE.USERS;

    @computed
    get usersQueryVariables(): AdminUsersPagedQueryVariables {
        const { roles, status, createdAt, lastActivityDate, ...restFilters } = this.filter.values;

        const filter: UserFilter = {
            roles: roles && roles.length ? roles.map(({ id }) => id) : null,
            ...(status ? userStatusToFilterValues[status.id] : {}),
            ...restFilters,
        };

        if (createdAt) filter.createdAt = serializeDateRange(createdAt);

        if (lastActivityDate) filter.lastActivityDate = serializeDateRange(lastActivityDate);

        if (this.companyId) filter.customerId = this.companyId;

        if (this.mode === USER_VIEW_MODE.DELETED_USERS) {
            filter.isDeleted = true;
        }

        return { filter, sort: this.sort.value, connection: this.pagination.connection };
    }

    private resetFilterAndSort() {
        this.filter.clearValues();
        this.sort.clearSort();
    }

    @action
    setCompanyTabViewMode(newCompanyId: string | null) {
        this.companyId = newCompanyId;
        this.resetFilterAndSort();
    }

    @action
    setUsersPageViewMode() {
        this.companyId = null;
        this.resetFilterAndSort();
    }

    @action
    setMode(newMode: USER_VIEW_MODE) {
        this.mode = newMode;
    }
}

export const usersStore = new UsersStore();
