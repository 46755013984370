import { useQuery } from '@apollo/client';

import { ToxicLimitsQuery } from '~/graphql';

import * as queries from '../toxicLimits.queries';

export type IToxicLimitsData = ToxicLimitsQuery;

export const useToxicLimitsData = () => {
    return useQuery<ToxicLimitsQuery>(queries.ToxicLimitsQuery);
};
