import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/users/users.queries';
import { UserLandfillCreateMutation, UserLandfillCreateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import { userLandfillsStore } from '../userRelations.store';

export type CreateUserLandfillFunc = (userId: string, landfillId: string) => Promise<unknown>;

/**
 * TODO remove or update after after getting solution https://redmine.pinpointer.se/issues/4103 and server update
 *
 * We have to use update mutation to create user-landfill relation
 * because UserService.createLandfillConnection/UserService.updateLandfillConnection are written this way
 *
 * Actually UserService.updateLandfillConnection calls LandfillService.addLandfillReceiverUser
 * so this case can be rewritten
 **/

export const useUserLandfillCreateMutation = (): [CreateUserLandfillFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<UserLandfillCreateMutation, UserLandfillCreateMutationVariables>(
        queries.UserLandfillCreateMutation
    );

    const createUserLandfill = useCallback<CreateUserLandfillFunc>(
        (userId: string, landfillId: string) => {
            return callMutation({
                variables: {
                    userId,
                    landfillId,
                },
                refetchQueries: [
                    {
                        query: queries.AdminUserLandfillsQuery,
                        variables: userLandfillsStore.userRelationsQueryVariables,
                    },
                ],
            })
                .then(result =>
                    result.data?.userLandfillCreate
                        ? result.data.userLandfillCreate
                        : Promise.reject(new Error('Empty response "UserLandfillCreateMutation"'))
                )
                .catch(handleApolloError);
        },
        [callMutation]
    );

    return [createUserLandfill, mutationResult.loading];
};
