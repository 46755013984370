import { AbilitySubjects } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { IDumpTypeCalculatedSubject } from './dumpTypeTypes';

export const getDumpTypeSubject = (user: IAbilityUser): IDumpTypeCalculatedSubject => {
    const relationRole = [user.role!];

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.DUMP_TYPE,
        relationRole,
    };
};
