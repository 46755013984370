import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import { userProjectsStore } from '~/@store/users/userRelations.store';
import { AdminUserProjectsQuery } from '~/@store/users/users.queries';
import {
    AdminUserProjectsQuery as IUserProjectsQuery,
    AdminUserProjectsQuery_projectsPaged,
    AdminUserProjectsQuery_projectsPaged_edges_node,
    AdminUserProjectsQueryVariables,
} from '~/graphql';

export type IUserProjectsPaged = AdminUserProjectsQuery_projectsPaged;

export type IUserProjectInfo = AdminUserProjectsQuery_projectsPaged_edges_node;

export const useUserProjectsPaged = (): QueryResult<IUserProjectsQuery, AdminUserProjectsQueryVariables> => {
    const variables = useDebouncedObservable(
        () => userProjectsStore.userRelationsQueryVariables,
        TIMEOUT_ON_QUERY_DEBOUNCE
    );

    return useQuery<IUserProjectsQuery, AdminUserProjectsQueryVariables>(AdminUserProjectsQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
        onCompleted: data => userProjectsStore.pagination.onQueryComplete(data.projectsPaged),
    });
};
