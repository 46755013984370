import React, { VFC } from 'react';

import { StringFilter, TableHeadCell } from '~/@components/Table/TableHeadCell';
import { BlacklistItemField, blacklistStore } from '~/@store/blacklist';

type Props = {
    title: React.ReactNode;
    fieldName: BlacklistItemField;
    withFilter?: boolean;
};

export const BlacklistHeadCell: VFC<Props> = ({ title, fieldName, withFilter }) => {
    return (
        <TableHeadCell title={title}>
            {withFilter ? (
                <StringFilter
                    valueGetter={() => blacklistStore.filter.values[fieldName]}
                    setValue={v => blacklistStore.filter.setValue(fieldName, v)}
                />
            ) : null}
        </TableHeadCell>
    );
};
