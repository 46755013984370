import React, { VFC } from 'react';

import { PageWrapper } from '~/@sochi-components/PageWrapper';

import { WeightReceipt } from '../ProjectDocuments/WeightReceipt';
import { ProjectCommonDataProps } from '../ProjectPage/ProjectPage';

type Props = ProjectCommonDataProps;

export const ProjectDeliveries: VFC<Props> = ({ project }) => {
    return (
        <PageWrapper>
            <WeightReceipt project={project} title={null} />
        </PageWrapper>
    );
};
