import { replaceRouteParams, routes } from '@common/routes';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { COLOR } from '~/@sochi-components/@theme';
import { IBatch, showLandfillBatchForm } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm';
import { LandfillQuery_landfill } from '~/graphql';
import i18n from '~/i18n';
import { showConfirmDialog } from '~/services/dialog';
import { archiveBatch, restoreBatch } from '~/utils/landfill';

type Props = {
    batch: IBatch;
    landfill: LandfillQuery_landfill;
    readOnly?: boolean;
};

export const LandfillBatchCard: FC<Props> = ({ batch, landfill, readOnly = false }) => {
    const { root, header, buttons, row, archived } = useStyles();

    const canEdit = readOnly || !!batch.closedAt;

    const history = useHistory();

    const archive = useCallback(async () => {
        const answer = await showConfirmDialog({
            title: <LocalizableText code={'LandfillPage.batches.archiveTitle'} />,
            message: <LocalizableText code={'LandfillPage.batches.archiveMessage'} />,
        });

        if (answer) {
            await archiveBatch(landfill.id, batch.id);
        }
    }, [landfill, batch]);

    const restore = useCallback(async () => {
        const answer = await showConfirmDialog({
            title: <LocalizableText code={'LandfillPage.batches.restoreTitle'} />,
            message: <LocalizableText code={'LandfillPage.batches.restoreMessage'} />,
        });

        if (answer) {
            await restoreBatch(landfill.id, batch.id);
        }
    }, [landfill, batch]);

    const viewLog = useCallback(
        (batchId: string) => {
            history.push({
                pathname: replaceRouteParams(routes.sochi.landfillBatchLog, {
                    landfillId: landfill.id,
                    batchId,
                }),
            });
        },
        [history, landfill]
    );

    return (
        <div className={cn(root, { [archived]: !!batch.closedAt })}>
            <div className={header}>
                <Text variant="TitleBold">{batch.name}</Text>
                <div className={buttons}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={canEdit}
                        onClick={() => showLandfillBatchForm(landfill, batch)}>
                        {i18n.edit}
                    </Button>
                    {!batch.closedAt ? (
                        <Button variant="outlined" color="primary" size="small" disabled={readOnly} onClick={archive}>
                            <LocalizableText code={'LandfillPage.batches.archive'} />
                        </Button>
                    ) : (
                        <Button variant="outlined" color="primary" size="small" disabled={readOnly} onClick={restore}>
                            <LocalizableText code={'LandfillPage.batches.restore'} />
                        </Button>
                    )}
                    <Button variant="outlined" color="primary" size="small" onClick={() => viewLog(batch.id)}>
                        View log
                    </Button>
                </div>
            </div>
            <div className={row}>
                <Text variant="BodyRegular">{batch.dumpType.name}</Text>
            </div>
            {batch.comment && (
                <Text variant="BodyRegular" color="textSecondary" ellipsis>
                    {batch.comment}
                </Text>
            )}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        fontFamily: 'Catamaran',
        background: COLOR.white,
        width: '100%',
        padding: '15px',
        [theme.breakpoints.down('md')]: {
            padding: '8px',
        },
    },
    archived: {
        backgroundColor: COLOR.grayLight2,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(2),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));
