import { ApolloError } from '@apollo/client';
import { ErrorCodes } from '@common/enums';
import { routes } from '@common/routes';
import { withFormik } from 'formik';
import { compose } from 'recompose';
import * as Yup from 'yup';

import { MeQuery } from '~/@store/account/account.queries';
import client, { loginUri } from '~/apolloClient';
import { withAbilities, withSettings } from '~/contexts';
import type { MeQuery_me } from '~/graphql';
import i18n, { lang } from '~/i18n';
import { globalMessage } from '~/services/message';
import { handleApolloError } from '~/utils';
import { postRequest, setAuthToken } from '~/utils/auth';
import { FrontApiError } from '~/utils/error';

import type { ILoginFormProps, ILoginFormValues } from './LoginPage';
import { LoginPage } from './LoginPage';

const routesByErrorCode: Partial<Record<ErrorCodes, string>> = {
    [ErrorCodes.USER_BLOCKED]: routes.userBlocked,
    [ErrorCodes.USER_NOT_CONFIRMED]: routes.notConfirmedEmail,
    [ErrorCodes.USER_NOT_VERIFIED]: routes.notApproved,
};

const formikHOC = withFormik<ILoginFormProps, ILoginFormValues>({
    mapPropsToValues: () => ({
        login: '',
        password: '',
    }),

    validationSchema: Yup.object().shape({
        login: Yup.string().trim().required(i18n.emailIsRequired),
        password: Yup.string().trim().required(i18n.passwordIsRequired),
    }),

    handleSubmit: async (values, { props }) => {
        const { login, password } = values;
        const { history, setUser } = props;

        return postRequest<{ token: string }>(loginUri, {
            login,
            password,
            language: lang,
        })
            .then(({ token }) => setAuthToken(token))
            .then(() => client.query({ query: MeQuery, fetchPolicy: 'network-only' }))
            .then(({ data }) => {
                const user: MeQuery_me | undefined = data?.me;
                if (user) {
                    setUser(user);
                    history.push(user.startPage || routes.root);
                }
            })
            .catch((err: ApolloError | FrontApiError) => {
                if (err instanceof FrontApiError) {
                    if (routesByErrorCode[err.code]) {
                        history.push(routesByErrorCode[err.code]);
                    } else if (err.code === ErrorCodes.NOT_AUTHORIZED) {
                        globalMessage.error(i18n.invalidCredentials);
                    } else {
                        globalMessage.error(err.message);
                    }
                } else {
                    handleApolloError(err);
                }
            });
    },
});

export default compose<ILoginFormProps, {}>(withAbilities, withSettings, formikHOC)(LoginPage);
