import { FieldsAffectsDestinationRecalculate } from '../../constants';
import { AbilitySubjects } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { isArraysIntersect } from '../utils';
import { getProjectSubject } from './projectSubject';
import {
    DumpLoadSubject,
    IAbilityDumpLoad,
    IAbilityProject,
    IDumpLoadSubject,
    IProjectDumpLoadCalculatedSubject,
} from './projectTypes';

export const getProjectDumpLoadSubject = (
    user: IAbilityUser,
    project: IAbilityProject,
    dumpLoad: IAbilityDumpLoad | null | undefined,
    subjects?: IDumpLoadSubject[]
): IProjectDumpLoadCalculatedSubject => {
    const { relationRole, subSubject } = getProjectSubject(user, project);
    const dumpLoadSubject = subjects || [];

    if (dumpLoad?.status) {
        dumpLoadSubject.push(dumpLoad.status);
    }

    if (isArraysIntersect(FieldsAffectsDestinationRecalculate, dumpLoadSubject))
        dumpLoadSubject.push(DumpLoadSubject.FIELD_AFFECT_DESTINATION);

    return {
        relationRole,
        projectSubject: subSubject,
        dumpLoadSubject,
        [ABILITY_TYPE_FIELD]: AbilitySubjects.PROJECT_DUMPLOAD,
    };
};
