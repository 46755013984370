import React from 'react';

import { ExcelIcon, PdfIcon } from '~/@components/Icon';
import { ListActionButton } from '~/@components/ListActionButton';
import { useDownloadDeliveryLinesReport } from '~/@store/deliveryLines';

export const DeliveryLinesPageButtons = () => {
    const handleDownloadFile = useDownloadDeliveryLinesReport();

    return (
        <>
            <ListActionButton onClick={() => handleDownloadFile('pdf')}>
                <PdfIcon />
            </ListActionButton>
            <ListActionButton onClick={() => handleDownloadFile('xlsx')}>
                <ExcelIcon />
            </ListActionButton>
        </>
    );
};
