import { ContaminationLevel } from '@common/enums';

export type IFormValues = {
    date: Date;
    result: ContaminationLevel;
    file: File | null;
};

export enum FormNames {
    date = 'date',
    result = 'result',
    file = 'file',
}
