import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { COLORS } from '~/@components/@theme/colors';
import { Chip } from '~/@components/Chip';
import { removeEmptyKeys } from '~/@store/common';
import { TimeSpentFilterField, timeSpentStore } from '~/@store/timeSpent';
import { formatFilterValue } from '~/@views/AdminView/common/utils';
import i18n from '~/i18n';

type Props = {
    disabled?: boolean;
};

const FieldNames: Record<TimeSpentFilterField, string> = {
    date: i18n.TimeSpent.date,
    user: i18n.TimeSpent.user,
    dumpLoadNumber: i18n.TimeSpent.dumpLoadNumber,
};

export const TimeSpentFilterSummary = ({ disabled = false }: Props) => {
    const filterValues = useObservable(() => removeEmptyKeys({ ...timeSpentStore.filter.values }));

    const { root, chip } = useStyles();

    return (
        <div className={root}>
            {Object.keys(filterValues).map(objectKey => {
                const key = objectKey as TimeSpentFilterField;
                const value = filterValues[key];
                if (value === null || value === undefined) return null;
                const chipContent = `${FieldNames[key]}: "${formatFilterValue(value)}"`;

                return (
                    <Chip
                        key={key}
                        className={chip}
                        color={COLORS.black}
                        onDelete={() => timeSpentStore.filter.setValue(key, null)}
                        deleteIcon={<Icon>close</Icon>}
                        disabled={!timeSpentStore.canResetFilter(key) || disabled}>
                        {chipContent}
                    </Chip>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        marginRight: theme.spacing(1),
    },
}));
