import { EventSource } from './helpers';
import { MessageAction, MessagePlace, MessageType, TimeoutModifier } from './models';
import { MessageModel, MessageTypes } from './models';

const MessageTimeouts = {
    [MessageTypes.ERROR]: 7 * 1000, // 7 seconds
    [MessageTypes.SUCCESS]: 5 * 1000, // 5 seconds
    [MessageTypes.INFO]: 7 * 1000, // 7 seconds
    [TimeoutModifier.LONG]: 10 * 1000, // 10 seconds
    [TimeoutModifier.SHORT]: 3 * 1000, // 3 seconds
};

class MessageStore {
    messageSources = {
        GLOBAL: new EventSource<MessageModel>(),
        IN_PAGE: new EventSource<MessageModel>(),
    };

    _showMessage = (
        place: MessagePlace,
        type: MessageType,
        message: string,
        action?: MessageAction,
        timeoutModifier?: TimeoutModifier
    ) => {
        const timeout = timeoutModifier ? MessageTimeouts[timeoutModifier] : MessageTimeouts[type];
        const newMessage = new MessageModel(type, message, timeout, action);
        this.messageSources[place].fire(newMessage);
    };
}

export default new MessageStore();
