import React from 'react';

import type { ISettings } from '~/@store/settings';

import { ConfigurationField } from '../ConfigurationField';

type CompanyConfigInputProps = {
    companyConfiguration: ISettings['companyConfiguration'];
    setCompanyConfiguration: (value: ISettings['companyConfiguration']) => void;
    field: keyof ISettings['companyConfiguration'];
    label: React.ReactNode;
};

export const CompanyConfigurationField = ({
    companyConfiguration,
    field,
    label,
    setCompanyConfiguration,
}: CompanyConfigInputProps) => {
    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyConfiguration({
            ...companyConfiguration,
            [field]: event.target.value,
        });
    };

    return <ConfigurationField value={companyConfiguration[field]} label={label} onChange={handleInput} />;
};
