import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { AdminDetailedDumpLoadQuery as IDetailedDumpLoadQuery, AdminDetailedDumpLoadQueryVariables } from '~/graphql';

import { AdminDetailedDumpLoadQuery } from '../dumpLoads.queries';

export type IDetailedDumpLoad = IDetailedDumpLoadQuery['projectDumpload'];

export const useDetailedDumpLoad = (
    projectId: string,
    dumpLoadId: string
): QueryResult<IDetailedDumpLoadQuery, AdminDetailedDumpLoadQueryVariables> => {
    const variables: AdminDetailedDumpLoadQueryVariables = { projectId, dumpLoadId };

    return useQuery<IDetailedDumpLoadQuery, AdminDetailedDumpLoadQueryVariables>(AdminDetailedDumpLoadQuery, {
        fetchPolicy: 'cache-first',
        variables,
    });
};
