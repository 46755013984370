import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IDespatchAdvice } from '~/@store/beastIntegration';
import * as queries from '~/@store/beastIntegration/beastIntegration.queries';
import { AdminSendDespatchAdvicesMutation, AdminSendDespatchAdvicesMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

export type ResendDespatchAdviceFunc = () => Promise<unknown>;

export const useSendDespatchAdviceMutation = (despatchAdvice: IDespatchAdvice): [ResendDespatchAdviceFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        AdminSendDespatchAdvicesMutation,
        AdminSendDespatchAdvicesMutationVariables
    >(queries.AdminSendDespatchAdvicesMutation);

    const updateDeliveryLine = useCallback<ResendDespatchAdviceFunc>(() => {
        return callMutation({
            variables: { id: despatchAdvice.id },
        }).catch(handleApolloError);
    }, [despatchAdvice, callMutation]);

    const disabled = !despatchAdvice.canSend;

    return [updateDeliveryLine, mutationResult.loading || disabled];
};
