import { Button, Grid } from '@material-ui/core';
import React from 'react';

import { Divider } from '~/@components/Divider';
import i18n from '~/i18n';

import { useCreateCompanyFormikContext } from './CompanyCreateFormik';

type Props = {
    onCancel: () => void;
};

export const CompanyCreateFormActions = ({ onCancel }: Props) => {
    const formik = useCreateCompanyFormikContext();

    return (
        <Grid container spacing={3} direction={'column'}>
            <Grid item>
                <Divider marginTop />
            </Grid>
            <Grid item>
                <Grid container spacing={3} justify="space-between">
                    <Grid item>
                        <Button onClick={onCancel} variant="outlined" color="default">
                            {i18n.cancel}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={formik.submitForm}
                            variant="contained"
                            color="primary"
                            disabled={!formik.dirty || formik.isSubmitting}>
                            {i18n.save}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
