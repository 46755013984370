import { IconButton } from '@material-ui/core';
import React from 'react';

import { CardList } from '~/@components/CardList';
import { CardListItem } from '~/@components/CardListItem';
import { AddIcon, DeleteIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { ISubstance, ISubstanceChild, useSubstancePermissions } from '~/@store/substances';

type Props = {
    substance: ISubstance;
    handleRemove: (v: ISubstanceChild) => Promise<unknown>;
    handleCreate: () => void;
};

export const SubstanceEditChildrenList = ({ substance, handleCreate, handleRemove }: Props) => {
    const { canCreate, canDelete } = useSubstancePermissions();

    return (
        <CardList>
            {(substance.children || []).map(child => (
                <CardListItem
                    buttonEnd={
                        <IconButton onClick={() => handleRemove(child)} disabled={!canDelete}>
                            <DeleteIcon />
                        </IconButton>
                    }>
                    <Text variant="BodyRegular">{child.name}</Text>
                </CardListItem>
            ))}
            <CardListItem
                buttonEnd={
                    <IconButton onClick={handleCreate} disabled={!canCreate}>
                        <AddIcon />
                    </IconButton>
                }>
                <Text variant="BodyRegular" color="textSecondary">
                    <LocalizableText code={'Substances.addSubstance'} />
                </Text>
            </CardListItem>
        </CardList>
    );
};
