import React, { ReactElement } from 'react';

import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { IBeastMaterial, useBeastMaterialList } from '~/@store/beastMaterials';

type Props = {
    children: (beastMaterials: IBeastMaterial[]) => ReactElement;
};

export function BeastMaterialSelectQuery({ children }: Props) {
    const query = useBeastMaterialList();

    return (
        <QueryStateHandler query={query} dataGetter={query => query.data?.beastMaterials} progressSize="linear">
            {(beastMaterials: IBeastMaterial[]) => children(beastMaterials)}
        </QueryStateHandler>
    );
}
