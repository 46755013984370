import './SignupPage.scss';

import { transformPhoneNumberInput } from '@common/utils';
import type { FormikProps } from 'formik';
import { Form } from 'formik';
import React, { Component } from 'react';
import type { RouteComponentProps } from 'react-router';

import { Button } from '~/@components/Button';
import { Checkbox } from '~/@components/Checkbox';
import { FormikContextTextField } from '~/@components/FormikFields';
import { getBem } from '~/@sochi-components/@bem';
import { CaptchaField } from '~/@sochi-components/FormikFields/CaptchaField';
import { AuthSochiLabel } from '~/@views/AuthView/common/AuthSochiLabel';
import type { WithSettingsProps } from '~/contexts';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';

import { AuthTitle } from '../../common/AuthTitle';
import { AuthWrapper } from '../../common/AuthWrapper';
import { RegisterCompleted } from '../RegisterCompleted';
import { TermsAndConditions } from '../TremsAndConditions';

export type ISignupPageValues = {
    email: string;
    password: string;
    confirmPassword: string;
    name: string;
    surName: string;
    phoneNumber: string;
    organizationNumber: string;
    organizationName: string;
    isTermsAgreed: boolean;
    isRegisterCompleted: boolean;
    captchaResult: string | null;
};

export enum FormNames {
    email = 'email',
    password = 'password',
    confirmPassword = 'confirmPassword',
    name = 'name',
    surName = 'surName',
    phoneNumber = 'phoneNumber',
    organizationNumber = 'organizationNumber',
    organizationName = 'organizationName',
    isTermsAgreed = 'isTermsAgreed',
    isRegisterCompleted = 'isRegisterCompleted',
    captchaResult = 'captchaResult',
}

export type ISignupPageProps = FormikProps<ISignupPageValues> & RouteComponentProps & WithSettingsProps;

export class SignupPage extends Component<ISignupPageProps> {
    bem = getBem(this);

    goBack = () => {
        const { resetForm, history } = this.props;
        resetForm();
        history.goBack();
    };

    openTerms = () => {
        showCustomDialog({
            render: closeDialog => <TermsAndConditions closeDialog={closeDialog} />,
        }).then(this.handleTerms);
    };

    handleTerms = (v: boolean) => {
        this.props.setFieldValue(FormNames.isTermsAgreed, v);
    };

    onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.setFieldValue(FormNames.phoneNumber, transformPhoneNumberInput(e.target.value));
    };

    onChangeEmail = () => {
        this.props.resetForm();
    };

    render() {
        const { values, errors } = this.props;
        const { element } = this.bem;

        if (values.isRegisterCompleted)
            return <RegisterCompleted onChangeEmail={this.onChangeEmail} email={values.email} />;

        return (
            <AuthWrapper onBack={this.goBack}>
                <AuthTitle title={i18n.createAccount} subtitle={i18n.enterYourDetail} />

                <Form className={element('form')}>
                    <FormikContextTextField<ISignupPageValues, FormNames.name>
                        name={FormNames.name}
                        label={i18n.user.firstName}
                    />
                    <FormikContextTextField<ISignupPageValues, FormNames.surName>
                        name={FormNames.surName}
                        label={i18n.user.surName}
                    />
                    <FormikContextTextField<ISignupPageValues, FormNames.phoneNumber>
                        name={FormNames.phoneNumber}
                        label={i18n.phoneNumber}
                        onChange={this.onChangePhoneNumber}
                    />
                    <FormikContextTextField<ISignupPageValues, FormNames.organizationNumber>
                        name={FormNames.organizationNumber}
                        label={i18n.organizationNumber}
                    />
                    <FormikContextTextField<ISignupPageValues, FormNames.organizationName>
                        name={FormNames.organizationName}
                        label={i18n.organizationName}
                    />
                    <FormikContextTextField<ISignupPageValues, FormNames.email>
                        name={FormNames.email}
                        label={i18n.email}
                    />
                    <FormikContextTextField<ISignupPageValues, FormNames.password>
                        type="password"
                        name={FormNames.password}
                        label={i18n.password}
                    />
                    <FormikContextTextField<ISignupPageValues, FormNames.confirmPassword>
                        type="password"
                        name={FormNames.confirmPassword}
                        label={i18n.confirmPassword}
                    />
                    <div className={element('checkbox-group')}>
                        <Checkbox
                            checked={values.isTermsAgreed}
                            onChange={this.handleTerms}
                            color="secondary"
                            inverted
                        />
                        <AuthSochiLabel error={Boolean(errors.isTermsAgreed)}>
                            {Boolean(errors.isTermsAgreed)
                                ? i18n.SignupPage.pleaseAcceptTermsNote
                                : i18n.SignupPage.acceptTermsNote}{' '}
                            <span className={element('link')} onClick={this.openTerms}>
                                {i18n.SignupPage.termsAndConditions}
                            </span>
                        </AuthSochiLabel>
                    </div>
                    <CaptchaField name={FormNames.captchaResult} />
                    <div className={element('buttons')}>
                        <Button type="submit" color="primary" size="large" variant="contained">
                            {i18n.SignupPage.register}
                        </Button>
                    </div>
                </Form>
            </AuthWrapper>
        );
    }
}
