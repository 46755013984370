import type { ApolloClient } from '@apollo/client';

import type {
    LandfillDeviationCloseMutation as ICloseMutation,
    LandfillDeviationCloseMutationVariables,
    LandfillDeviationCommentMutation as ICommentMutation,
    LandfillDeviationCommentMutationVariables,
    LandfillDeviationCreateMutation as ICreateMutation,
    LandfillDeviationCreateMutationVariables,
    LandfillDeviationRemoveFileMutation as IRemoveFileMutation,
    LandfillDeviationRemoveFileMutationVariables,
    LandfillDeviationUpdateMutation as IUpdateMutation,
    LandfillDeviationUpdateMutationVariables,
} from '~/graphql';
import i18n from '~/i18n';
import { handleLoadingPromise } from '~/services/loader';
import { globalMessage } from '~/services/message';
import { handleApolloError } from '~/utils';

import {
    LandfillDeviationCloseMutation,
    LandfillDeviationCommentMutation,
    LandfillDeviationCreateMutation,
    LandfillDeviationRemoveFileMutation,
    LandfillDeviationUpdateMutation,
} from './deviation.queries';
import type { IDeviation, IFileDoc, IFormValues } from './deviation.types';
import { DeviationMutations } from './deviation.types';

const createDeviation = (client: ApolloClient<unknown>, values: IFormValues, entityId: string): Promise<IDeviation> => {
    const variables: LandfillDeviationCreateMutationVariables = {
        input: {
            landfillId: entityId,
            issue: values.issue,
            description: values.description,
            suggestedSolution: values.suggestedSolution,
            status: values.status,
            files: values.newFiles.map(image => image.file),
        },
    };

    return handleLoadingPromise(
        client
            .mutate<ICreateMutation>({
                mutation: LandfillDeviationCreateMutation,
                variables,
            })
            .then(({ data }) => {
                globalMessage.success(i18n.DeviationForm.deviationCreated);

                return data?.deviationLandfillCreate || Promise.reject(new Error('Empty response'));
            })
            .catch(handleApolloError)
    );
};

const updateDeviation = (
    client: ApolloClient<unknown>,
    values: IFormValues,
    deviation: IDeviation
): Promise<IDeviation> => {
    const variables: LandfillDeviationUpdateMutationVariables = {
        id: deviation.id,
        input: {
            issue: values.issue,
            description: values.description,
            suggestedSolution: values.suggestedSolution,
            status: values.status,
            files: values.newFiles.map(image => image.file),
        },
    };

    return handleLoadingPromise(
        client
            .mutate<IUpdateMutation>({
                mutation: LandfillDeviationUpdateMutation,
                variables,
            })
            .then(({ data }) => {
                globalMessage.success(i18n.DeviationForm.deviationUpdated);

                return data?.deviationLandfillUpdate || Promise.reject(new Error('Empty response'));
            })
            .catch(handleApolloError)
    );
};

const commentDeviation = (
    client: ApolloClient<unknown>,
    values: IFormValues,
    deviation: IDeviation
): Promise<IDeviation> => {
    const variables: LandfillDeviationCommentMutationVariables = {
        id: deviation.id,
        input: {
            text: values.newComment,
            suggestedSolution: values.suggestedSolution,
            files: values.newFiles.map(image => image.file),
        },
    };

    return handleLoadingPromise(
        client
            .mutate<ICommentMutation>({
                mutation: LandfillDeviationCommentMutation,
                variables,
            })
            .then(({ data }) => {
                return data?.deviationLandfillComment || Promise.reject(new Error('Empty response'));
            })
            .catch(handleApolloError)
    );
};

const closeDeviation = (
    client: ApolloClient<unknown>,
    values: IFormValues,
    deviation: IDeviation
): Promise<IDeviation> => {
    const variables: LandfillDeviationCloseMutationVariables = {
        id: deviation.id,
        files: values.newFiles.map(image => image.file),
    };

    return handleLoadingPromise(
        client
            .mutate<ICloseMutation>({
                mutation: LandfillDeviationCloseMutation,
                variables,
            })
            .catch(handleApolloError)
            .then(({ data }) => {
                globalMessage.success(i18n.DeviationForm.deviationClosed);

                return data?.deviationLandfillClose || Promise.reject(new Error('Empty response'));
            })
    );
};

const removeFile = async (client: ApolloClient<unknown>, deviation: IDeviation, file: IFileDoc): Promise<IDeviation> =>
    handleLoadingPromise(
        client
            .mutate<IRemoveFileMutation, LandfillDeviationRemoveFileMutationVariables>({
                mutation: LandfillDeviationRemoveFileMutation,
                variables: {
                    id: deviation.id,
                    fileId: file.id,
                },
            })
            .catch(handleApolloError)
            .then(({ data }) => {
                return data?.deviationLandfillRemoveFile || Promise.reject(new Error('Empty response'));
            })
    );

export const landfillDeviationMutations: DeviationMutations = {
    createDeviation,
    updateDeviation,
    commentDeviation,
    closeDeviation,
    removeFile,
};
