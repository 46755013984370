import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const DownloadIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <g clipPath="url(#clip0_379_7741)">
                <path
                    d="M19 12.0013V19.0013H5V12.0013H3V19.0013C3 20.1013 3.9 21.0013 5 21.0013H19C20.1 21.0013 21 20.1013 21 19.0013V12.0013H19ZM13 12.6713L15.59 10.0913L17 11.5013L12 16.5013L7 11.5013L8.41 10.0913L11 12.6713V3.00125H13V12.6713Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_379_7741">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.00125122)" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
