import React, { VFC } from 'react';

import { BlockIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { PageLight } from '~/@components/PageLight';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { IBlacklistItem, useBlacklist } from '~/@store/blacklist';

import { AddToBlacklistButton } from './AddToBlacklistButton';
import { BlacklistTable } from './BlacklistTable';

export const BlacklistPage: VFC = () => {
    const blacklistQuery = useBlacklist();

    return (
        <PageLight>
            <PageLight.Header
                icon={<BlockIcon />}
                title={<LocalizableText code={'BlacklistPage.title'} />}
                rightActions={<AddToBlacklistButton />}
            />
            <PageLight.Content>
                <QueryStateHandler query={blacklistQuery} dataGetter={query => query.data?.emailsblackedlist}>
                    {(list: IBlacklistItem[]) => <BlacklistTable list={list} />}
                </QueryStateHandler>
            </PageLight.Content>
        </PageLight>
    );
};
