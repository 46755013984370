import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { AccountChangePassword, AccountChangePasswordVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import { AccountChangePasswordMutation } from '../account.queries';
import { AccountPasswordChangeFunc } from '../types';

export const useAccountChangePassword = (): [AccountPasswordChangeFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<AccountChangePassword, AccountChangePasswordVariables>(
        AccountChangePasswordMutation
    );

    const changeSelfPassword = useCallback<AccountPasswordChangeFunc>(
        input =>
            callMutation({ variables: { input } })
                .catch(handleApolloError)
                .then(result => !!result.data?.selfChangePassword),
        [callMutation]
    );

    return [changeSelfPassword, mutationResult.loading];
};
