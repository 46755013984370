import './ImagesInput.scss';

import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';

import { getBem, IBemBagProps } from '~/@sochi-components/@bem';
import { COLOR } from '~/@sochi-components/@theme/styles';
import { CloudDoneIcon, CloudUploadIcon } from '~/@sochi-components/Icons';
import { ImgLoader } from '~/@sochi-components/ImgLoader';
import { SochiLoader } from '~/@sochi-components/SochiLoader';
import { IFileDoc } from '~/@user-store/common';
import i18n from '~/i18n';

import { MAX_PAYLOAD_SIZE } from '../../config/constants';
import { globalMessage } from '../../services/message';
import { ImageForUpload } from '../../utils/ImageForUpload';
import { LightBox } from './LightBox/LightBox';

const ImageLoading = ({ bem }: IBemBagProps) => (
    <div className={bem.element('image-loading')}>
        <SochiLoader show onlyIcon />
    </div>
);

const ImageError = ({ bem }: IBemBagProps) => <div className={bem.element('image-error')} />;

type ImagesInputProps = {
    baseFilesUri: string;
    params: { [key: string]: string };
    newFiles: Array<ImageForUpload> | null | undefined;
    onChange: (files: ImageForUpload[]) => void;
    filesOnServer: IFileDoc[];
    onDelete?: (file: IFileDoc) => void;
    canAdd: boolean;
    canDelete: boolean;
    errorMessage?: string | null;
};

export class ImagesInput extends React.Component<ImagesInputProps> {
    bem = getBem(this);

    handleAddFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const files = ImageForUpload.fromFileList(event.target.files);
        event.target.value = '';

        if (files.some(f => f.file.size >= MAX_PAYLOAD_SIZE)) {
            return globalMessage.error(i18n.tooLargeFile);
        }

        this.props.onChange([...files, ...(this.props.newFiles || [])]);
    };

    handleRemoveFile = (file: ImageForUpload) => () => {
        this.props.onChange((this.props.newFiles || []).filter(f => f.id !== file.id));
    };

    handleDeleteFile = (file: IFileDoc) => () => {
        const { onDelete } = this.props;
        onDelete && onDelete(file);
    };

    render() {
        const { className, element } = this.bem;
        const { filesOnServer, canAdd, canDelete, newFiles, baseFilesUri, params, errorMessage } = this.props;

        return (
            <LightBox>
                {showImageInLightBox => (
                    <>
                        <InputLabel error>{errorMessage || ''}&nbsp;</InputLabel>
                        <div className={className}>
                            {canAdd && (
                                <div className={element('image-box')}>
                                    <input
                                        className={element('input')}
                                        id="image-input"
                                        type="file"
                                        accept="image/*"
                                        onChange={this.handleAddFiles}
                                    />
                                    <label className={element('input-label')} htmlFor="image-input" />
                                </div>
                            )}
                            {(newFiles || []).map(f => (
                                <div key={f.id} className={element('image-box')}>
                                    <img
                                        className={element('image')}
                                        src={f.localSrc}
                                        onClick={() => showImageInLightBox(f.localSrc, f.name)}
                                    />
                                    <span className={element('image-icon')} title={i18n.DeviationForm.toUpload}>
                                        <CloudUploadIcon htmlColor={COLOR.orange} />
                                    </span>
                                    <CloudUploadIcon htmlColor={COLOR.white} className={element('image-icon-shadow')} />

                                    <div className={element('remove-button')} onClick={this.handleRemoveFile(f)} />
                                </div>
                            ))}
                            {filesOnServer.map(f => (
                                <div key={f.id} className={element('image-box')}>
                                    <ImgLoader
                                        url={baseFilesUri}
                                        key={f.id}
                                        loadingPlaceholder={<ImageLoading bem={this.bem} />}
                                        errorPlaceholder={<ImageError bem={this.bem} />}
                                        params={{ ...params, fileId: f.id }}>
                                        {(src: string) => (
                                            <img
                                                className={element('image')}
                                                src={src}
                                                onClick={() => showImageInLightBox(src, f.name)}
                                            />
                                        )}
                                    </ImgLoader>
                                    <span className={element('image-icon')} title={i18n.DeviationForm.uploaded}>
                                        <CloudDoneIcon color="primary" />
                                    </span>
                                    <CloudDoneIcon htmlColor={COLOR.white} className={element('image-icon-shadow')} />

                                    {canDelete && (
                                        <div
                                            className={element('remove-button', { red: true })}
                                            onClick={this.handleDeleteFile(f)}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </LightBox>
        );
    }
}
