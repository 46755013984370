import { jsPDF } from 'jspdf';
import React, { MutableRefObject, useCallback, useMemo, useRef } from 'react';

/** Documentation: https://github.com/eKoopmans/html2pdf.js */
const generatePdfFromElement = async (
    element: HTMLDivElement,
    fileName: string,
    processPdf?: (pdf: jsPDF) => Promise<jsPDF>
): Promise<unknown> => {
    const { default: html2pdf } = await import('html2pdf.js');

    const jsPdfOptions = {
        margin: 4,
        filename: fileName,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { orientation: 'p', unit: 'mm', format: 'a4', putOnlyUsedFonts: true },
        pagebreak: { mode: 'css', avoid: 'pdf-no-break', before: 'pdf-break' },
    };

    let res = html2pdf().set(jsPdfOptions).from(element).toPdf();

    if (processPdf) {
        res = res.get('pdf').then(processPdf);
    }

    return await res.save();
};

const displayContentStyle = { display: 'content' };

const getPdfExportContainer =
    (ref: MutableRefObject<HTMLDivElement | null>): React.FC =>
    ({ children }) =>
        (
            <div ref={ref} style={displayContentStyle}>
                {children}
            </div>
        );

export const PdfNoBreakPage: React.FC = ({ children }) => (
    <div className="pdf-no-break" style={displayContentStyle}>
        {children}
    </div>
);

export const PdfBreakPage: React.FC = () => <div className="pdf-break" />;

export const PdfHide: React.FC = ({ children }) => (
    <div data-html2canvas-ignore={true} style={displayContentStyle}>
        {children}
    </div>
);

/** Returns [ExportContainer: React.FC, generatePdf: () => void] */
export const usePdfExport = (fileName: string, processPdf?: (pdf: jsPDF) => Promise<jsPDF>): [React.FC, () => void] => {
    const containerRef = useRef<HTMLDivElement>(null);

    const ExportContainer = useMemo(() => getPdfExportContainer(containerRef), []);

    const generatePdf = useCallback(async () => {
        const containerElement = containerRef.current;
        if (!containerElement) {
            console.error('No ExportContainer used!');

            return;
        }

        return await generatePdfFromElement(containerElement, fileName, processPdf);
    }, [fileName, processPdf]);

    return [ExportContainer, generatePdf];
};
