import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

import { COLORS } from '~/@components/@theme/colors';

const style = { fill: 'transparent' };

export const BoxIcon = ({ stroke = COLORS.textBody, ...props }: SvgIconProps) => {
    return (
        <SvgIcon width="24" height="25" viewBox="0 0 24 25" stroke={stroke} {...props} style={style}>
            <g clipPath="url(#clip0_6289_35425)">
                <path
                    d="M21 16.9589V8.95889C20.9996 8.60816 20.9071 8.2637 20.7315 7.96005C20.556 7.6564 20.3037 7.40425 20 7.22889L13 3.22889C12.696 3.05335 12.3511 2.96094 12 2.96094C11.6489 2.96094 11.304 3.05335 11 3.22889L4 7.22889C3.69626 7.40425 3.44398 7.6564 3.26846 7.96005C3.09294 8.2637 3.00036 8.60816 3 8.95889V16.9589C3.00036 17.3096 3.09294 17.6541 3.26846 17.9577C3.44398 18.2614 3.69626 18.5135 4 18.6889L11 22.6889C11.304 22.8644 11.6489 22.9568 12 22.9568C12.3511 22.9568 12.696 22.8644 13 22.6889L20 18.6889C20.3037 18.5135 20.556 18.2614 20.7315 17.9577C20.9071 17.6541 20.9996 17.3096 21 16.9589Z"
                    stroke={stroke}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.27002 7.91992L12 12.9699L20.73 7.91992"
                    stroke={stroke}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M12 23.039V12.959" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_6289_35425">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.958984)" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
