import React from 'react';

import type { ISettings } from '~/@store/settings';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';

import { ConfigurationField } from '../ConfigurationField';

type TransportConfigInputProps = {
    transportConfiguration: ISettings['transportConfiguration'];
    setTransportConfiguration: (value: ISettings['transportConfiguration']) => void;
    field: keyof ISettings['transportConfiguration'];
    label: React.ReactNode;
};

export const TransportConfigurationField = ({
    transportConfiguration,
    field,
    label,
    setTransportConfiguration,
}: TransportConfigInputProps) => {
    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value);
        if (newValue >= 0) {
            setTransportConfiguration({
                ...transportConfiguration,
                [field]: Number(event.target.value),
            });
        } else {
            return globalMessage.error(i18n.settingsPage.validation.noNegativeOrStringValue);
        }
    };

    return <ConfigurationField value={transportConfiguration[field]} label={label} onChange={handleInput} />;
};
