import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { DEFAULT_SEARCH_LIMIT } from '~/@store/common';
import {
    DumpLoadSearch,
    DumpLoadSearch_dumpLoadsPaged_edges_node,
    DumpLoadSearchVariables,
    DumpLoadsFilter,
} from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../dumpLoads.queries';

export type IDumpLoadSearchRecord = Omit<DumpLoadSearch_dumpLoadsPaged_edges_node, '__typename'>;

type UseDumpLoadSearchFunc = (search: string) => Promise<Array<IDumpLoadSearchRecord>>;

export const useDumpLoadSearchFunc = (
    filter: DumpLoadsFilter = {},
    limit: number = DEFAULT_SEARCH_LIMIT
): UseDumpLoadSearchFunc => {
    const client = useApolloClient();

    return useCallback<UseDumpLoadSearchFunc>(
        search =>
            client
                .query<DumpLoadSearch, DumpLoadSearchVariables>({
                    query: queries.DumpLoadSearchQuery,
                    variables: {
                        limit,
                        filter: {
                            ...filter,
                            serialNumber: search,
                        },
                    },
                })
                .then(
                    result =>
                        result.data?.dumpLoadsPaged.edges.map(({ node: { __typename, ...dumpLoad } }) => dumpLoad) || []
                )
                .catch(handleApolloError),
        [client, filter, limit]
    );
};
