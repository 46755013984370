import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import * as queries from '~/@store/dumpLoads/dumpLoads.queries';
import { DumpLoadUploadAnalysisMutation, DumpLoadUploadAnalysisMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

type UploadAnalysisFileFunc = (projectId: string, dumpLoadId: string, file: File) => Promise<void>;

export const useAnalysisFileUploadMutation = (): [UploadAnalysisFileFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<
        DumpLoadUploadAnalysisMutation,
        DumpLoadUploadAnalysisMutationVariables
    >(queries.DumpLoadUploadAnalysisMutation);

    const uploadAnalysisFile = useCallback<UploadAnalysisFileFunc>(
        (projectId: string, dumpLoadId: string, file: File) => {
            return callMutation({
                variables: { projectId, dumpLoadId, file },
            })
                .catch(handleApolloError)
                .then(() => {});
        },
        [callMutation]
    );

    return [uploadAnalysisFile, mutationResult.loading];
};
