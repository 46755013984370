import React from 'react';

import { ContaminationTypeStore } from '~/@store/substances';

import { SubstancesEditControls } from './SubstancesEditControls';
import { SubstancesEditInputsList } from './SubstancesEditInputsList';

type Props = {
    store: ContaminationTypeStore;
};

export const SubstancesEditPanel = ({ store }: Props) => {
    return (
        <div>
            <SubstancesEditControls store={store} />
            <SubstancesEditInputsList store={store} />
        </div>
    );
};
