import React, { useCallback, useMemo } from 'react';

import { NullableFormatter } from '~/@components/Formatters';
import { DeleteIcon, RestoreIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { showConfirmDialog } from '~/@store/common';
import { IProject, useProjectRecover, useProjectRemove } from '~/@store/projects';
import { projectDetailRoute } from '~/@store/routes';
import { ProjectStatus } from '~/graphql';

type Props = {
    project: IProject;
};

export const ProjectNameCell = ({ project }: Props) => {
    const [recoverProject, isRecovering] = useProjectRecover();
    const [removeProject, isRemoving] = useProjectRemove();

    const onRecover = useCallback(
        () =>
            showConfirmDialog({
                title: <LocalizableText code={'ProjectsTable.restoreInfo'} />,
                render: () => <LocalizableText code={'ProjectsTable.restoreConfirmation'} />,
                icon: RestoreIcon,
            }).then(confirmed => confirmed && recoverProject(project.id)),
        [recoverProject, project]
    );

    const onRemove = useCallback(
        () =>
            showConfirmDialog({
                title: <LocalizableText code={'ProjectsTable.deleteInfo'} />,
                render: () => <LocalizableText code={'ProjectsTable.deleteConfirmation'} />,
                icon: DeleteIcon,
            }).then(confirmed => confirmed && removeProject(project.id)),
        [removeProject, project]
    );

    const actions: Array<ITableCellAction> = useMemo(
        () => [
            {
                variant: ActionVariant.LINK,
                to: projectDetailRoute(project.id),
                targetBlank: true,
            },
            {
                variant: ActionVariant.COPY,
                content: project.name,
            },
            project.status === ProjectStatus.DELETED
                ? {
                      variant: ActionVariant.RESTORE,
                      onClick: onRecover,
                      disabled: isRecovering,
                  }
                : {
                      variant: ActionVariant.DELETE,
                      onClick: onRemove,
                      disabled: isRemoving,
                  },
        ],
        [project, onRemove, isRemoving, onRecover, isRecovering]
    );

    return (
        <TableCellActions actions={actions}>
            <NullableFormatter>{project.name}</NullableFormatter>
        </TableCellActions>
    );
};
