import './ProjectNotes.scss';

import { ProjectSubject } from '@common/abilities/projects';
import React from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { useUserAbilities } from '~/contexts';
import { ProjectQuery_project } from '~/graphql';
import { showCustomDialog } from '~/services/dialog';
import { canUpdateProject } from '~/utils/auth';

import { ProjectAddEditNotes } from '../ProjectAddEditNotes/ProjectAddEditNotes';

interface Props {
    project: ProjectQuery_project;
}

export const ProjectNotes: React.FC<Props> = ({ project }) => {
    const bem = useFunctionalBem(ProjectNotes);
    const { element, className } = bem;
    const notes = project.comment;

    const abilityContext = useUserAbilities();
    const canUpdate = canUpdateProject(abilityContext, project, [ProjectSubject.ALWAYS_EDITABLE_FIELDS]);

    return (
        <div className={className}>
            <div className={element('notes-header')}>
                <SochiTitle
                    className={element('notes-title')}
                    title={<LocalizableText code={'priceCalculatorPage.notes'} />}
                />
                <Button
                    className={element('notes-edit')}
                    variant="contained"
                    color="primary"
                    onClick={() => onAddEditNotes(project)}
                    disabled={!canUpdate}>
                    <LocalizableText code={notes ? 'priceCalculatorPage.edit' : 'priceCalculatorPage.add'} />
                </Button>
            </div>
            <div className={`${element('sochi-card')} ${element('notes-wrapper')}`}>
                <div className={element('sochi-card__body')}>
                    {notes ? (
                        <textarea className={element('notes-text')} rows={6} disabled value={notes} />
                    ) : (
                        <div className={`${element('sochi-card__body')} ${element('no-content')}`}>
                            <span className={element('sochi-card__body-info')}>
                                <LocalizableText code={'priceCalculatorPage.noNotes'} />
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const onAddEditNotes = (project: ProjectQuery_project) => {
    showCustomDialog({
        render: closeDialog => {
            return <ProjectAddEditNotes project={project} closeDialog={closeDialog} />;
        },
    });
};
