import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import i18n from '~/i18n';
import { formatDate, formatTime } from '~/utils/date';

interface Props {
    children: Date | string | null | void;
}

export const DateTimeFormatter = ({ children }: Props) => {
    const { root, time } = useStyles();

    if (!children) {
        return (
            <div className={root}>
                <div>{i18n.NA}</div>
                <div className={time}>--:--:--</div>
            </div>
        );
    }

    return (
        <div className={root}>
            <div>{formatDate(children)}</div>
            <div className={time}>{formatTime(children)}</div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 120,
    },
    time: {
        color: theme.palette.text.secondary,
    },
}));
