import { routes } from '@common/routes';
import React, { VFC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ProjectOffers } from './ProjectOffers';

export const OffersPage: VFC = () => {
    return (
        <Switch>
            <Route path={routes.sochi.offerOutgoing} component={ProjectOffers} />
            <Route path={routes.sochi.offerOutgoings} component={ProjectOffers} />
            <Redirect from="/" to={routes.sochi.offerOutgoing} />
        </Switch>
    );
};
