import { ContaminationLevel, ContaminationType } from '@common/enums';
import { leachingLevels } from '@common/toxic-analysis/analyzeLeaching';
import { solidLevels } from '@common/toxic-analysis/analyzeSolid';
import { SubstanceLimitMap } from '@common/toxic-analysis/toxicAnalysis.types';
import { isNumber } from '@common/validations/types.validation';

import { COLORS } from '~/@components/@theme/colors';
import { sortSubstancesAndChildrenByPosition } from '~/@store/substances';
import { ToxicLimitsLeachingColors, ToxicLimitsSolidColors } from '~/@store/toxicLimits/toxicLimits.constants';
import {
    checkArrayIsAscendingAndGetErrorIndexes,
    checkNewValueIsAscending,
    getSubstanceLimitMap,
} from '~/@store/toxicLimits/toxicLimits.helpers';
import { ToxicLimitsUpdateFunc } from '~/@store/toxicLimits/toxicLimits.hooks/useToxicLimitsUpdate';
import { ToxicLimitsQuery } from '~/graphql';

import { ISubstance, ValueValidationResult } from './toxicLimits.types';

export class ToxicLimitsStore {
    private readonly valuesMap: SubstanceLimitMap;
    private readonly colorScheme: Partial<Record<ContaminationLevel, string>>;
    public readonly substances: ISubstance[];
    public readonly limitLevels: ContaminationLevel[];

    constructor(
        data: ToxicLimitsQuery,
        public contaminationType: ContaminationType,
        private readonly updateFunc: ToxicLimitsUpdateFunc
    ) {
        this.valuesMap = getSubstanceLimitMap(data.toxicLimitsValues[contaminationType]);
        this.substances = sortSubstancesAndChildrenByPosition(
            data.substances.filter(s => s.contaminationTypes.includes(contaminationType))
        );
        this.limitLevels = contaminationType === ContaminationType.SOLID ? solidLevels : leachingLevels;
        this.colorScheme =
            contaminationType === ContaminationType.SOLID ? ToxicLimitsSolidColors : ToxicLimitsLeachingColors;
    }

    getColor(level: ContaminationLevel) {
        return this.colorScheme[level] || COLORS.gray;
    }

    getValue =
        (substanceId: string) =>
        (level: ContaminationLevel): number | null => {
            const value = this.valuesMap[substanceId]?.[level];

            return isNumber(value) ? value : null;
        };

    validateSubstanceValues(substanceId: string): ContaminationLevel[] | null {
        const getValue = this.getValue(substanceId);
        const errorIndexes = checkArrayIsAscendingAndGetErrorIndexes(this.limitLevels.map(getValue));

        return errorIndexes.length === 0 ? null : errorIndexes.map(i => this.limitLevels[i]!);
    }

    validateNewValue(
        substanceId: string,
        limitLevel: ContaminationLevel,
        newValue: number | null
    ): ValueValidationResult | null {
        if (newValue === null) return null;
        const levelIndex = this.limitLevels.indexOf(limitLevel);
        if (levelIndex === -1) {
            console.error('Wrong limitLevel: ' + limitLevel);

            return null;
        }
        const getValue = this.getValue(substanceId);
        const values = this.limitLevels.map(getValue);
        const result = checkNewValueIsAscending(values, newValue, levelIndex);

        if (!result) return null;

        return {
            valueLevel: limitLevel,
            otherLevel: this.limitLevels[result.errorIndex]!,
            errorType: result.errorType,
        };
    }

    async updateValue(substanceId: string, levelName: ContaminationLevel, value: number | null): Promise<unknown> {
        return this.updateFunc({
            contaminationType: this.contaminationType,
            substanceId,
            levelName,
            value,
        });
    }
}
