import React from 'react';

import { Table } from '~/@components/Table';
import { IDetailedLandfill } from '~/@store/landfills';

import { useLandfillSubareasColumns } from './useLandfillSubareasColumns';

type Props = {
    landfill: IDetailedLandfill;
};

export const LandfillSubareas = ({ landfill }: Props) => {
    const columns = useLandfillSubareasColumns(landfill);

    return <Table columns={columns} items={landfill.subareas} keyGetter={s => s.id} name="LandfillSubareas" />;
};
