import { isEmpty } from 'lodash';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { QueryResult } from '~/@sochi-components/QueryResult';
import { SochiTitle } from '~/@sochi-components/SochiTitle';
import { useDeliveryEvents } from '~/@user-store/landfillDeliveryEvents/useDeliveryEvents';
import { LandfillDeliveryEventsTable } from '~/@views/UserView/LandfillPage/LandfillDeviationsTab/LandfillDeliveryEvents/LandfillDeliveryEventsTable';
import { LandfillQuery_landfill } from '~/graphql';

type Props = {
    landfill: LandfillQuery_landfill;
};

export const LandfillDeliveryEvents: React.VFC<Props> = ({ landfill }) => {
    const query = useDeliveryEvents(landfill.id);

    return (
        <QueryResult loading={query.loading} empty={false} onRetry={query.refetch} error={query.error}>
            {isEmpty(query.data?.landfillEvents) ? null : (
                <div>
                    <SochiTitle title={<LocalizableText code={'LandfillDeliveryEvents.title'} />} />
                    <LandfillDeliveryEventsTable events={query.data!.landfillEvents} landfill={landfill} />
                </div>
            )}
        </QueryResult>
    );
};
