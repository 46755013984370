import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import i18n from '~/i18n';

interface Props {
    children: React.ReactNode | null | void;
}

export const NullableFormatter = (props: Props) => {
    const { grey } = useStyles();
    const { children } = props;

    return <span className={children ? undefined : grey}>{children || i18n.NA}</span>;
};

const useStyles = makeStyles(theme => ({
    grey: {
        color: theme.palette.grey[400],
    },
}));
