import { makeStyles } from '@material-ui/core';
import React from 'react';

import { CardListItem } from '~/@components/CardListItem';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { IToxicSumRule } from '~/@store/toxicLimits';
import i18n from '~/i18n';

type Props = {
    rules: IToxicSumRule[];
    selectedRuleId: string | null;
    onSelect: (ruleId: string) => void;
};

export const SumRulesList = ({ rules, selectedRuleId, onSelect }: Props) => {
    const { item } = useStyles();

    return (
        <div>
            <Text variant="TableBold" color="textSecondary" gutterBottom>
                <LocalizableText code={'ToxicLimitsPage.faSumRules'} />
            </Text>
            {rules.map(rule => (
                <CardListItem
                    className={item}
                    isActive={selectedRuleId === rule.id}
                    key={rule.id}
                    onClick={() => onSelect(rule.id)}>
                    <Text variant="BodyRegular">{rule.name}</Text>
                </CardListItem>
            ))}
            {!rules.length && <Text variant="BodyRegular">{i18n.emptyList}</Text>}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    item: {
        marginBottom: theme.spacing(2),
    },
}));
