import { AbilityCan, AbilitySubjects, OrderFields, UserRole } from '../../enums';
import { IAbilityFunction } from '../abilities.types';
import { ICaslCondition } from '../utils';
import { IOrderCalculatedSubject } from './orderTypes';

const { CRUD, READ } = AbilityCan;
const { ADMIN, EMPLOYEE, EXTERNAL_RECEIVER } = UserRole;

export const defineAbilitiesForOrders = (_allow: IAbilityFunction, _forbid: IAbilityFunction) => {
    type SubjectShape = ICaslCondition<IOrderCalculatedSubject>;

    const allow = (can: AbilityCan | AbilityCan[], shape: SubjectShape) => _allow(can, AbilitySubjects.ORDER, shape);
    const forbid = (can: AbilityCan | AbilityCan[], shape: SubjectShape) => _forbid(can, AbilitySubjects.ORDER, shape);

    allow(CRUD, { relationRole: { $in: [ADMIN, EMPLOYEE] } });

    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.orderStatus });
    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.plannedStartDate });
    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.projectNumber });
    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.deliveryName });
    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.project });
    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.dumpLoad });
    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.dumpLoadComment });
    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.customer });
    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.deliveryLines });
    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.pricePerTonn });
    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.dumpLoadDumpType });
    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.places });
    allow(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.deliveryLineCount });

    forbid(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.history });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.salesResponsible });
    forbid(READ, { relationRole: EXTERNAL_RECEIVER, subSubject: OrderFields.targetSubarea });
};
