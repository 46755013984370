import React, { useMemo } from 'react';

import { useObservable } from '~/@components/@hooks';
import { makeStyles } from '~/@components/@theme';
import { Select } from '~/@components/Select';
import i18n from '~/i18n';

type Props = {
    store: {
        totalCount: number;
        numberOfItemsToDisplay: number | null;
        numberOfItemsToDisplayOptions: number[];
        setNumberOfItemsToDisplay: (v: number | null) => void;
    };
};

const MIN_LIST_SIZE = 5;

export const DisplayItemCountSelect = ({ store }: Props) => {
    const { root } = useStyles();
    const totalCount: number = useObservable(() => store.totalCount);
    const value: number | null = useObservable(() => store.numberOfItemsToDisplay);

    const isBlocked = useMemo(() => totalCount <= MIN_LIST_SIZE, [totalCount]);

    return (
        <div className={root}>
            <Select
                items={store.numberOfItemsToDisplayOptions}
                value={isBlocked ? null : value}
                onChange={v => store.setNumberOfItemsToDisplay(v)}
                keyGetter={v => `${v}`}
                renderValue={v => `${v} ${i18n.Table.of} ${totalCount}`}
                emptyLabel={i18n.showAll}
                displayEmpty
                disabled={isBlocked}
            />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: 180,
        display: 'inlineBlock',

        [theme.breakpoints.down('md')]: {
            order: -1,
            width: '100%',
        },
    },
}));
