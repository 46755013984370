import { IconButton, ListItemText } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import React, { SyntheticEvent, useCallback, useState } from 'react';

import { CloseIcon } from '~/@components/Icon';
import i18n from '~/i18n';

import { CommonAutocomplete, ICommonAutocompleteProps } from './CommonAutocomplete';

const noWrapAndEllipsisProperty = { noWrap: true };

export type AutocompleteProps<TValue> = ICommonAutocompleteProps & {
    options: Array<TValue>;
    selectedOption: TValue | null;
    onOptionSelect: (v: TValue | null) => void;
    renderOption: (v: TValue) => string;
    renderOptionSecondary?: (v: TValue) => string;
    clearable?: boolean;
};

export const Autocomplete = <TValue extends unknown>({
    options,
    selectedOption,
    onOptionSelect,
    searchValue,
    renderOption,
    renderOptionSecondary,
    clearable = false,
    InputProps,
    ...restProps
}: AutocompleteProps<TValue>) => {
    const { clearButton, menuList } = useStyles();
    const [open, setOpen] = useState(!!restProps.autoFocus);

    const handleOptionClick = useCallback(
        (v: TValue) => {
            onOptionSelect(v);
            setOpen(false);
        },
        [setOpen, onOptionSelect]
    );

    const handleClearClick = useCallback(
        (e: SyntheticEvent) => {
            e.stopPropagation();
            onOptionSelect(null);
        },
        [onOptionSelect]
    );

    return (
        <CommonAutocomplete
            {...restProps}
            open={open}
            setOpen={setOpen}
            searchValue={open ? searchValue : selectedOption ? renderOption(selectedOption) : ''}
            popupContent={
                <MenuList className={menuList}>
                    {options.length ? (
                        options.map(o => (
                            <MenuItem onClick={() => handleOptionClick(o)} key={JSON.stringify(o)}>
                                <ListItemText
                                    primary={renderOption(o)}
                                    secondary={renderOptionSecondary ? renderOptionSecondary(o) : null}
                                    primaryTypographyProps={noWrapAndEllipsisProperty}
                                    secondaryTypographyProps={noWrapAndEllipsisProperty}
                                />
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>{i18n.noDataForFilter}</MenuItem>
                    )}
                </MenuList>
            }
            InputProps={
                InputProps ||
                (clearable && !open
                    ? {
                          endAdornment: (
                              <IconButton
                                  className={clearButton}
                                  aria-label="clear"
                                  component="span"
                                  size="small"
                                  onClick={handleClearClick}>
                                  <CloseIcon fontSize="small" />
                              </IconButton>
                          ),
                      }
                    : undefined)
            }
        />
    );
};

const useStyles = makeStyles({
    menuList: {
        maxHeight: '40vh',
        overflow: 'auto',
    },
    clearButton: {
        borderRadius: '50%',
        marginRight: 4,
    },
});
