import { useFormikContext } from 'formik';
import React, { VFC } from 'react';

import { ActionButton } from '~/@components/ActionButton';
import i18n from '~/i18n';

export const AccountFormSaveButton: VFC = () => {
    const formik = useFormikContext();

    return (
        <ActionButton
            onClick={() => formik.handleSubmit()}
            disabled={formik.isSubmitting || !formik.dirty}
            type="submit">
            {i18n.save}
        </ActionButton>
    );
};
