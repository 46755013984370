import { IUnits, Units } from './enums';

export function toCubic(tonns: number, tonPerKubM: number | null | undefined) {
    tonPerKubM = tonPerKubM || 1;

    if (tonPerKubM < 0.00001) {
        return 0;
    } else {
        return Math.ceil(tonns / tonPerKubM);
    }
}

export function toTonns(kubik: number, tonPerKubM: number | null | undefined) {
    tonPerKubM = tonPerKubM || 1;

    if (tonPerKubM < 0.00001) {
        return 0;
    } else {
        return Math.ceil(kubik * tonPerKubM);
    }
}

export function toString(val: IUnits) {
    switch (val) {
        case Units.CUBIC_METERS:
            return 'm3';

        case Units.TONNS:
            return 'tn';

        default:
            return 'm3';
    }
}

export function normalizeAmount(
    sourceUom: IUnits | null | undefined,
    targetUom: IUnits,
    amount: number,
    tonnsPerCubicM: number
): number {
    const needCubic = sourceUom === Units.TONNS && targetUom === Units.CUBIC_METERS;
    const needTonns = sourceUom === Units.CUBIC_METERS && targetUom === Units.TONNS;

    return sourceUom === targetUom
        ? amount
        : needTonns
        ? toTonns(amount, tonnsPerCubicM)
        : needCubic
        ? toCubic(amount, tonnsPerCubicM)
        : amount;
}
