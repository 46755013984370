import type { IAbilityCan, IAbilitySubject } from '../enums';
import { UserRole } from '../enums';

export const ABILITY_TYPE_FIELD = Symbol('subject');

export interface IAbilityComplexSubject {
    [ABILITY_TYPE_FIELD]: IAbilitySubject;
}

export type IAbilityCheckFunction = (
    action: IAbilityCan,
    subjectName: IAbilitySubject | IAbilityComplexSubject,
    field?: string
) => boolean;

type EmptyObject = {};

function AbilityFunction(action: IAbilityCan | IAbilityCan[], subjectName: IAbilitySubject): void;

function AbilityFunction(
    action: IAbilityCan | IAbilityCan[],
    subjectName: IAbilitySubject,
    instanceShape: EmptyObject
): void;

function AbilityFunction(
    action: IAbilityCan | IAbilityCan[],
    subjectName: IAbilitySubject,
    fields: string[] | string
): void;

function AbilityFunction(
    action: IAbilityCan | IAbilityCan[],
    subjectName: IAbilitySubject,
    fields?: string[] | string | EmptyObject,
    instanceShape?: EmptyObject
): void;

function AbilityFunction(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    action: IAbilityCan | IAbilityCan[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    subjectName: IAbilitySubject,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    fields?: string[] | string | EmptyObject,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    instanceShape?: EmptyObject
): void {}

export type IAbilityFunction = typeof AbilityFunction;

export type IAbility = {
    can: IAbilityCheckFunction;
    cannot: IAbilityCheckFunction;
    /** @deprecated - You should use the typed function of the corresponding entity */
    subject: (action: IAbilitySubject, conditions: {}) => IAbilityComplexSubject;
};

export interface IAbilityUser {
    id: string;
    role: UserRole;
    customerId: string | null;
}
