import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { COLORS } from '~/@components/@theme/colors';
import { Chip } from '~/@components/Chip';
import { removeEmptyKeys } from '~/@store/common';
import { ProjectFilterFields, projectsStore } from '~/@store/projects/project.store';
import { formatFilterValue } from '~/@views/AdminView/common/utils';
import i18n from '~/i18n';

type Props = {};

const FieldNames: Record<ProjectFilterFields, string> = {
    [ProjectFilterFields.name]: i18n.ProjectsTable.name,
    [ProjectFilterFields.customerName]: i18n.ProjectsTable.customer,
    [ProjectFilterFields.locationText]: i18n.ProjectsTable.location,
    [ProjectFilterFields.status]: i18n.ProjectsTable.status,
    [ProjectFilterFields.comment]: i18n.ProjectsTable.comment,
    [ProjectFilterFields.ownerName]: i18n.ProjectsTable.salesResponsible,
};

export const ProjectsFilterSummary = (_props: Props) => {
    const filterValues = useObservable(() => removeEmptyKeys({ ...projectsStore.filter.values }));
    const { root, chip } = useStyles();

    return (
        <div className={root}>
            {Object.keys(filterValues).map(objectKey => {
                const key = objectKey as ProjectFilterFields;
                const value = filterValues[key];
                if (value === null || value === undefined) return null;
                const chipContent = `${FieldNames[key]}: "${formatFilterValue(value)}"`;

                return (
                    <Chip
                        key={key}
                        className={chip}
                        color={COLORS.black}
                        onDelete={() => projectsStore.filter.setValue(key, null)}
                        deleteIcon={<Icon>close</Icon>}>
                        {chipContent}
                    </Chip>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        marginRight: theme.spacing(1),
    },
}));
