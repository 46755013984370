import { Icon, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback } from 'react';

import { SortInput } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    fieldName: string;
    sort: SortInput | null | void;
    setSort: (v: SortInput) => void;
    labels?: { ascending: string; descending: string };
};

export const SortSection = ({ fieldName, sort, setSort, labels = i18n.SortString }: Props) => {
    const { selectedItem } = useStyles();

    const handleSortAZ = useCallback(() => {
        setSort({ field: fieldName, direction: 1 });
    }, [setSort]);

    const handleSortZA = useCallback(() => {
        setSort({ field: fieldName, direction: -1 });
    }, [setSort]);

    if (!setSort) return null;

    return (
        <>
            <ListItem divider className={selectedItem}>
                <ListItemText>{i18n.sortBy}:</ListItemText>
            </ListItem>
            <ListItem
                divider
                button
                onClick={handleSortAZ}
                className={sort?.field === fieldName && sort?.direction === 1 ? selectedItem : ''}>
                <ListItemText>{labels.ascending}</ListItemText>
                <ListItemSecondaryAction>
                    <Icon>chevron_right</Icon>
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem
                divider
                button
                onClick={handleSortZA}
                className={sort?.field === fieldName && sort?.direction === -1 ? selectedItem : ''}>
                <ListItemText>{labels.descending}</ListItemText>
                <ListItemSecondaryAction>
                    <Icon>chevron_right</Icon>
                </ListItemSecondaryAction>
            </ListItem>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    selectedItem: {
        backgroundColor: theme.palette.background.default,
    },
}));
