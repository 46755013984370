import { ContaminationType } from '@common/enums';
import { Card, CardActionArea, CardContent, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import { COLORS } from '~/@components/@theme';
import { DumpLoadStatusFormatter } from '~/@components/Formatters';
import { InboundIcon, NoMatchingIcon } from '~/@components/Icon';
import { Text } from '~/@components/Text';
import { getContaminationLevelLabel } from '~/@store/toxicLimits';
import { LandfillStatusIcon } from '~/@views/AdminView/common/LandfillStatusIcon';
import { ProjectQuery_project_dumpLoads } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    dumpLoad: ProjectQuery_project_dumpLoads;
    isSelected: boolean;
    onClick: () => void;
};

export const DumpLoadCard = ({ dumpLoad, isSelected, onClick }: Props) => {
    const { root, selected, landfillSection, row, column, icons } = useStyles();

    return (
        <Card>
            <CardActionArea onClick={onClick}>
                <CardContent className={cn(root, { [selected]: isSelected })}>
                    <div className={row}>
                        <Text variant="SmallText">{dumpLoad.serialNumber}</Text>
                        <Text variant="SmallText" color="textSecondary">
                            {getContaminationLevelLabel(dumpLoad.solidTestResult, ContaminationType.SOLID)}
                        </Text>
                    </div>

                    <div className={row}>
                        <div className={column}>
                            <Text variant="TableBold" ellipsis title={dumpLoad.dumpType?.name}>
                                {dumpLoad.dumpType?.name || i18n.NA}
                            </Text>

                            <Text variant="SmallText" color="textSecondary">
                                <DumpLoadStatusFormatter>{dumpLoad.status}</DumpLoadStatusFormatter>
                            </Text>
                        </div>
                        <div className={icons}>
                            {dumpLoad.skipMatching && <NoMatchingIcon />}
                            {dumpLoad.inbound && <InboundIcon />}
                        </div>
                    </div>
                    <div className={landfillSection}>
                        <LandfillStatusIcon
                            status={dumpLoad.destinationInfo?.landfill?.status}
                            fontSize="small"
                            withTooltip={false}
                        />
                        <Text
                            variant="SmallText"
                            color="textSecondary"
                            ellipsis
                            title={dumpLoad.destinationInfo?.landfill?.name}>
                            {dumpLoad.destinationInfo?.landfill?.name || i18n.NA}
                        </Text>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: 180,
        padding: 12,
        backgroundColor: COLORS.grayLightest,
        borderColor: COLORS.grayLight,
    },
    selected: {
        backgroundColor: COLORS.grayLight,
        borderColor: COLORS.grayDark,
    },
    landfillSection: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(),

        '& svg': {
            color: COLORS.blue,
            width: 16,
            height: 16,
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icons: {
        display: 'flex',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
