import { ApolloError } from '@apollo/client';
import { DumpLoadStatus, ErrorCodes } from '@common/enums';
import { areMassDatesInvalid } from '@common/functions';
import { formatString } from '@common/utils';
import { shouldTransportScheduleEvenly } from '@common/validations/common.validation';
import { isAfter, isBefore, isEqual, isValid } from 'date-fns';
import { FormikConfig } from 'formik';
import { isNumber, last } from 'lodash';
import React from 'react';
import * as Yup from 'yup';

import { LocalizableText } from '~/@components/LocalizableText';
import { DEFAULT_DATE_PICKER_MAX_DATE, DEFAULT_DATE_PICKER_MIN_DATE } from '~/@sochi-components/SochiDatePicker';
import * as queries from '~/@store/dumpLoads/dumpLoads.queries';
import * as projectQueries from '~/@store/projects/projects.queries';
import {
    getInboundDumpLoadInput,
    getInboundWithoutMatchingDumpLoadInput,
    getOutboundDumpLoadInput,
    getOutboundWithoutMatchingDumpLoadInput,
} from '~/@views/UserView/ProjectPage/ProjectPriceCalculator/AddEditMassForm/AddEditMass.helpers';
import apolloClient from '~/apolloClient';
import {
    AddDumpLoadCommentMutation,
    AddDumpLoadCommentMutationVariables,
    DumpLoadActionName,
    DumpLoadCreateMutation,
    DumpLoadCreateMutationVariables,
    DumpLoadsUpdateStatusMutation,
    DumpLoadsUpdateStatusMutationVariables,
    DumpLoadUpdateMutation,
    DumpLoadUpdateMutationVariables,
    DumpLoadUploadAnalysisMutation,
    DumpLoadUploadAnalysisMutationVariables,
    ProjectLoadInput,
    ProjectUpdateMutation,
    ProjectUpdateMutationVariables,
    UnitOfMeasure,
} from '~/graphql';
import i18n from '~/i18n';
import { showConfirmDialog } from '~/services/dialog';
import { handleLoadingPromise } from '~/services/loader';
import { formatDate, parseDateFrom } from '~/utils/date';
import { isSimplifiedDumpLoadEditMode } from '~/utils/dumpLoad';

import { FormNames, IDates, IFormValues, IProject } from './AddEditMass.types';
import type { AddEditMassProps } from './AddEditMassForm';

const updateProjectEndDate = (
    project: IProject,
    endDate: string | null | undefined,
    handleError: (error: ApolloError) => Promise<never>
): Promise<number> =>
    handleLoadingPromise(
        apolloClient.mutate<ProjectUpdateMutation, ProjectUpdateMutationVariables>({
            mutation: projectQueries.ProjectUpdateMutation,
            variables: {
                input: {
                    id: project.id,
                    endDate,
                    ver: project.ver,
                },
            },
        })
    )
        .catch(handleError)
        .then(resp => {
            const project = resp.data?.projectUpdate;
            if (!project) return Promise.reject(new Error('Empty response'));

            return project.ver;
        });

export const getAddEditMassFormikConfig = ({
    project,
    dumpLoad,
    onClose,
    handleError,
}: AddEditMassProps): FormikConfig<IFormValues> => ({
    initialValues: {
        id: dumpLoad?.id || null,
        dumpType: dumpLoad?.dumpType || null,
        mode: dumpLoad ? (dumpLoad.inbound ? 'inbound' : 'outbound') : 'outbound',
        matchWithPinpointer: dumpLoad ? !dumpLoad.skipMatching : false,
        unitOfMeasure: dumpLoad?.unitOfMeasure || UnitOfMeasure.TONNS,
        dates: {
            startDate: dumpLoad?.date ? parseDateFrom(dumpLoad.date) : null,
            endDate: dumpLoad?.endDate ? parseDateFrom(dumpLoad.endDate) : null,
        },
        amount: dumpLoad?.amount || 0,
        comment: dumpLoad?.comment || '',

        stackability: dumpLoad ? Boolean(dumpLoad.stackability) : true,
        over50cm: dumpLoad?.over50cm || false,
        withTOC: !!dumpLoad?.withTOC,
        TOCPercent: dumpLoad?.TOCPercent || null,
        withInvasiveSpecies: !!dumpLoad?.withInvasiveSpecies,
        invasiveSpecies: dumpLoad?.invasiveSpecies || '',

        newComment: '',
        requestQuote: true,
        canRequestQuote: dumpLoad
            ? dumpLoad.actionsToShow.some(a => a.actionName === DumpLoadActionName.REQUEST)
            : true,

        landfillName: dumpLoad?.suggestedLandfillName || '',
        landfillAddress: dumpLoad?.suggestedLandfillAddress || '',

        analysisFile: null,
    },
    validationSchema: Yup.object().shape({
        unitOfMeasure: Yup.string().required(formatString(i18n.isRequired, i18n.unit)),
        dumpType: Yup.object().nullable().required(formatString(i18n.isRequired, i18n.massCategory)),
        amount: Yup.number().min(1, formatString(i18n.isRequired, i18n.amount)),
        comment: Yup.string()
            .nullable()
            .trim()
            .test('Mass description validation', i18n.errorCodes.DUMP_LOAD_COMMENT_REQUIRED, function (comment) {
                return this.parent.mode === 'outbound' || !!comment;
            }),
        dates: Yup.object<IDates>().test('Dates validation', '', function (dates) {
            if (isSimplifiedDumpLoadEditMode(this.parent)) return true;

            if (!dates) return false;

            const { startDate, endDate } = dates;

            if (!startDate || !endDate)
                return this.createError({
                    message: formatString(i18n.isRequired, i18n.dates),
                    path: FormNames.dates,
                });

            if (!isValid(startDate) || !isValid(endDate))
                return this.createError({
                    message: formatString(i18n.errorCodes.INVALID_DATE, i18n.dates),
                    path: FormNames.dates,
                });

            if (isBefore(startDate, DEFAULT_DATE_PICKER_MIN_DATE) || isBefore(endDate, DEFAULT_DATE_PICKER_MIN_DATE))
                return this.createError({
                    message: formatString(i18n.Dates.canNotBeBefore, formatDate(DEFAULT_DATE_PICKER_MIN_DATE)),
                    path: FormNames.dates,
                });

            if (isAfter(startDate, DEFAULT_DATE_PICKER_MAX_DATE) || isAfter(endDate, DEFAULT_DATE_PICKER_MAX_DATE))
                return this.createError({
                    message: formatString(i18n.Dates.canNotBeAfter, formatDate(DEFAULT_DATE_PICKER_MAX_DATE)),
                    path: FormNames.dates,
                });

            const dumpLoadDate = parseDateFrom(dumpLoad?.date);
            const dumpLoadEndDate = parseDateFrom(dumpLoad?.endDate);
            if (
                dumpLoadDate &&
                dumpLoadEndDate &&
                isEqual(startDate, dumpLoadDate) &&
                isEqual(endDate, dumpLoadEndDate)
            )
                return true;

            const errorCode = areMassDatesInvalid(project, {
                status: dumpLoad?.status,
                date: startDate.toISOString(),
                endDate: endDate.toISOString(),
            });

            if (!errorCode || errorCode === ErrorCodes.PROJECT_END_DATE_MUST_BE_UPDATED) return true;

            return this.createError({ message: i18n.errorCodes[errorCode], path: FormNames.dates });
        }),
        TOCPercent: Yup.number()
            .nullable()
            .test('TOC-value validation', i18n.errorCodes.TOC_VALUE_REQUIRED, function (TOCValue) {
                return !this.parent.withTOC || (this.parent.withTOC && isNumber(TOCValue) && TOCValue > 0);
            }),
        invasiveSpecies: Yup.string()
            .nullable()
            .trim()
            .test('Invasive species validation', i18n.errorCodes.INVASIVE_SPECIES_REQUIRED, function (invasiveSpecies) {
                return !this.parent.withInvasiveSpecies || (this.parent.withInvasiveSpecies && !!invasiveSpecies);
            }),
        landfillName: Yup.string()
            .nullable()
            .trim()
            .test('Landfill name validation', i18n.errorCodes.LANDFILL_NAME_CANNOT_BE_EMPTY, function (landfillName) {
                return this.parent.matchWithPinpointer || !!landfillName;
            }),
        landfillAddress: Yup.string()
            .nullable()
            .trim()
            .test(
                'Landfill address validation',
                i18n.errorCodes.LANDFILL_LOCATION_CANNOT_BE_EMPTY,
                function (landfillAddress) {
                    return this.parent.matchWithPinpointer || !!landfillAddress;
                }
            ),
    }),
    onSubmit: async values => {
        const { dumpType, dates, newComment, requestQuote, matchWithPinpointer, mode, analysisFile } = values;

        if (!dumpType) return;

        const input: ProjectLoadInput =
            mode === 'inbound'
                ? matchWithPinpointer
                    ? getInboundDumpLoadInput(values, project, dumpLoad)
                    : getInboundWithoutMatchingDumpLoadInput(values, project, dumpLoad)
                : matchWithPinpointer
                ? getOutboundDumpLoadInput(values, project, dumpLoad)
                : getOutboundWithoutMatchingDumpLoadInput(values, project, dumpLoad);

        if (mode === 'outbound' && matchWithPinpointer) {
            if (dumpLoad?.isManualScheduled && shouldTransportScheduleEvenly(dumpLoad, input)) {
                const confirmed = await showConfirmDialog({
                    title: <LocalizableText code={'TransportSchedule.editTransportSchedule'} />,
                    message: <LocalizableText code={'TransportSchedule.distributeEvenlyConfirm'} />,
                });

                if (!confirmed) return;
            }

            const { endDate } = dates;
            if (!endDate) return;

            const projectEndDate = parseDateFrom(project.endDate);

            if (projectEndDate && isAfter(endDate, projectEndDate)) {
                const confirmed = await showConfirmDialog({
                    message: formatString(
                        i18n.ProjectAddEditMass.changeProjectDateToMatchMassDate,
                        formatDate(endDate)
                    ),
                });

                if (!confirmed) return;

                input.ver = await updateProjectEndDate(project, input.endDate, handleError);
            }
        }

        let dumpLoadId: string;
        let ver: number;

        if (dumpLoad) {
            dumpLoadId = dumpLoad.id;
            const result = await handleLoadingPromise(
                apolloClient.mutate<DumpLoadUpdateMutation, DumpLoadUpdateMutationVariables>({
                    mutation: queries.DumpLoadUpdateMutation,
                    variables: {
                        input,
                        projectId: project.id,
                    },
                })
            ).catch(handleError);

            ver = last(result.data?.projectLoadUpdate?.dumpLoads)?.project?.ver!;
        } else {
            const result = await handleLoadingPromise(
                apolloClient.mutate<DumpLoadCreateMutation, DumpLoadCreateMutationVariables>({
                    mutation: queries.DumpLoadCreateMutation,
                    variables: {
                        input,
                        projectId: project.id,
                    },
                })
            ).catch(handleError);

            const lastDumpLoad = last(result.data?.projectLoadCreate?.dumpLoads);
            dumpLoadId = lastDumpLoad?.id!;
            ver = result.data?.projectLoadCreate?.ver!;

            if (newComment) {
                const newCommentResult = await handleLoadingPromise(
                    apolloClient.mutate<AddDumpLoadCommentMutation, AddDumpLoadCommentMutationVariables>({
                        mutation: queries.AddDumpLoadCommentMutation,
                        variables: {
                            projectId: project.id,
                            dumpLoadId,
                            text: newComment,
                        },
                    })
                ).catch(handleError);

                ver = newCommentResult.data?.projectLoadAddComment?.project?.ver!;
            }

            if (analysisFile) {
                const analysisFileResult = await handleLoadingPromise(
                    apolloClient.mutate<DumpLoadUploadAnalysisMutation, DumpLoadUploadAnalysisMutationVariables>({
                        mutation: queries.DumpLoadUploadAnalysisMutation,
                        variables: {
                            projectId: project.id,
                            dumpLoadId,
                            file: analysisFile,
                        },
                    })
                ).catch(handleError);

                ver = analysisFileResult.data?.projectLoadUploadAnalysis?.ver!;
            }
        }

        if (requestQuote) {
            await handleLoadingPromise(
                apolloClient.mutate<DumpLoadsUpdateStatusMutation, DumpLoadsUpdateStatusMutationVariables>({
                    mutation: queries.DumpLoadsUpdateStatusMutation,
                    variables: {
                        input: {
                            projectId: project.id,
                            dumpLoads: [
                                {
                                    id: dumpLoadId,
                                    status: DumpLoadStatus.REQUESTED,
                                },
                            ],
                            ver,
                        },
                    },
                })
            ).catch(handleError);
        }

        onClose(true);
    },
});
