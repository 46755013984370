import { AbilitySubjects } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { IReleaseNoteCalculatedSubject } from './releaseNoteTypes';

export const getReleaseNoteSubject = (user: IAbilityUser): IReleaseNoteCalculatedSubject => {
    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.RELEASE_NOTE,
        relationRole: user.role,
    };
};
