import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { BiSideLayout } from '~/@components/BiSideLayout';
import { useUserDetailContext } from '~/@views/AdminView/UserDetailPage/UserDetailContext';
import { UserComments } from '~/@views/AdminView/UsersPage/UserComments';

import { UserEditForm } from './UserEditForm';

export const UserEditTab = () => {
    const user = useUserDetailContext();
    const { container, comments } = useStyles();

    return (
        <BiSideLayout>
            <BiSideLayout.LeftSideContent>
                <UserEditForm user={user} />
            </BiSideLayout.LeftSideContent>
            <BiSideLayout.RightSideContent className={container}>
                <UserComments className={comments} comments={user.comments} userId={user.id} />
            </BiSideLayout.RightSideContent>
        </BiSideLayout>
    );
};

const useStyles = makeStyles({
    container: {
        maxHeight: 'calc(100vh - 180px)',
        display: 'flex',
        flexDirection: 'column',
    },
    comments: {
        maxHeight: '100%',
    },
});
