import { createMuiTheme } from '@material-ui/core/styles';
import React from 'react';

import { ArrowDownIcon, CHECKED_ICON_CHEVRON_CLASSNAME, CheckedIcon, UncheckedIcon } from '~/@sochi-components/Icons';

import { BUTTON_SHADOW, BUTTON_SIZE_SMALL, COLOR, FONT_FAMILY, FONT_WEIGHT } from './styles';

/**
 Sochi to MUI breakpoints matches
 The name of range is used in sass mixin "breakpoints", like @include breakpoints(responsive)

 large-desktop       theme.breakpoints.up('xl')
 desktop             theme.breakpoints.up('lg')
 responsive          theme.breakpoints.down('md')
 tablet              theme.breakpoints.only('md')
 smartphone          theme.breakpoints.down('sm')
 small-smartphone    theme.breakpoints.down('xs')
 */

const BREAKPOINTS = {
    xs: 0,
    sm: 360,
    md: 768,
    lg: 1240,
    xl: 1920,
};

// temporal theme
const theme = createMuiTheme({
    // TODO update typography
    typography: {
        fontFamily: FONT_FAMILY.catamaran,
        h1: {
            fontSize: 28,
        },
        h2: {
            fontWeight: FONT_WEIGHT.semiBold,
            fontSize: 28,
        },
        h3: {
            fontSize: 28,
        },
        h4: {
            fontSize: 28,
        },
        h5: {
            fontSize: 28,
        },
        h6: {
            fontSize: 18,
        },
        subtitle1: {
            fontWeight: FONT_WEIGHT.bold,
            fontSize: 18,
        },
        subtitle2: {
            fontSize: 18,
        },
        body1: {
            fontSize: 18,
        },
        body2: {
            fontSize: 18,
        },
        caption: {
            fontSize: 18,
        },
        button: {
            fontSize: 18,
            lineHeight: 1.5,
        },
        overline: {
            fontSize: 18,
        },
    },
    // TODO update palette
    palette: {
        common: {
            black: COLOR.black,
            white: COLOR.white,
        },
        text: {
            primary: COLOR.black,
            secondary: COLOR.gray3,
            disabled: COLOR.gray3,
        },
        action: {
            hoverOpacity: 0.04,
            disabledOpacity: 0.6,
            hover: COLOR.grayLight3,
        },
        background: {
            input: COLOR.white,
            default: COLOR.grayBackground,
            paper: COLOR.white,
        },
        primary: {
            main: COLOR.blue,
            light: COLOR.blueLight,
            contrastText: COLOR.white,
        },
        secondary: {
            main: COLOR.mainBlue,
            light: COLOR.mainBlueHover,
            contrastText: COLOR.white,
        },
        error: {
            main: COLOR.red,
        },
        warning: {
            main: COLOR.orange,
        },
        success: {
            main: COLOR.green,
            contrastText: COLOR.white,
        },
        tableBorder: COLOR.mainBlueTransparent,
    },
    breakpoints: {
        values: BREAKPOINTS,
    },
});

export const sochiLightTheme = createMuiTheme({
    ...theme,
    overrides: {
        // TODO change html fontSize and use rem after removing old admin panel
        MuiSvgIcon: {
            root: {
                fontSize: 24,
            },
            fontSizeSmall: {
                fontSize: 18,
            },
            fontSizeLarge: {
                fontSize: 28,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 30,
                textTransform: 'none',
                transition: 'unset',
                height: 40,
                whiteSpace: 'nowrap',

                [theme.breakpoints.down('md')]: {
                    ...BUTTON_SIZE_SMALL,
                },
            },
            sizeSmall: BUTTON_SIZE_SMALL,
            containedSizeSmall: {
                fontWeight: FONT_WEIGHT.semiBold,
            },
            sizeLarge: {
                padding: '15px 30px',
                height: 60,
                fontSize: 18,

                [theme.breakpoints.down('md')]: {
                    ...BUTTON_SIZE_SMALL,
                },
            },
            text: {
                padding: '5px 30px',
                [theme.breakpoints.down('md')]: {
                    ...BUTTON_SIZE_SMALL,
                },

                // White button
                '&.MuiButton-root.MuiButton-textInfo': {
                    '&:not(.Mui-disabled)': {
                        color: theme.palette.common.white,

                        '&:hover': {
                            backgroundColor: `rgba(255,255,255, 0.06)`,
                        },
                    },

                    '&.Mui-disabled': {
                        color: theme.palette.common.white,
                        opacity: theme.palette.action.disabledOpacity,
                    },
                },
            },
            outlined: {
                boxShadow: `${BUTTON_SHADOW} !important`,
                padding: '5px 30px',
                [theme.breakpoints.down('md')]: {
                    ...BUTTON_SIZE_SMALL,
                },

                // Error button
                '&.MuiButton-root.MuiButton-outlinedError': {
                    '&:not(.Mui-disabled)': {
                        '&:hover': {
                            backgroundColor: theme.palette.error.main,
                            color: theme.palette.error.contrastText,
                        },
                    },

                    '&.Mui-disabled': {
                        color: theme.palette.error.main,
                        opacity: theme.palette.action.disabledOpacity,
                    },
                },

                // Warning button
                '&.MuiButton-root.MuiButton-outlinedWarning': {
                    '&:not(.Mui-disabled)': {
                        '&:hover': {
                            backgroundColor: theme.palette.warning.main,
                            color: theme.palette.warning.contrastText,
                        },
                    },

                    '&.Mui-disabled': {
                        color: theme.palette.warning.main,
                        opacity: theme.palette.action.disabledOpacity,
                    },
                },

                // Warning button
                '&.MuiButton-root.MuiButton-outlinedSuccess': {
                    '&:not(.Mui-disabled)': {
                        '&:hover': {
                            backgroundColor: theme.palette.success.main,
                            color: theme.palette.success.contrastText,
                        },
                    },

                    '&.Mui-disabled': {
                        color: theme.palette.success.main,
                        opacity: theme.palette.action.disabledOpacity,
                    },
                },

                // White button
                '&.MuiButton-root.MuiButton-outlinedInfo': {
                    '&:not(.Mui-disabled)': {
                        backgroundColor: COLOR.transparent,
                        color: theme.palette.common.white,
                        borderColor: theme.palette.common.white,

                        '&:hover': {
                            backgroundColor: theme.palette.common.white,
                            color: theme.palette.secondary.main,
                        },
                    },

                    '&.Mui-disabled': {
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.secondary.main,
                        opacity: theme.palette.action.disabledOpacity,
                    },
                },
            },
            contained: {
                boxShadow: `${BUTTON_SHADOW} !important`,
                padding: '5px 30px',
                [theme.breakpoints.down('md')]: {
                    padding: '5px 18px',
                    fontWeight: FONT_WEIGHT.semiBold,
                },

                // Error button
                '&.MuiButton-root.MuiButton-containedError': {
                    '&:not(.Mui-disabled)': {
                        '&:hover': {
                            backgroundColor: theme.palette.error.light,
                            color: theme.palette.error.contrastText,
                        },
                    },

                    '&.Mui-disabled': {
                        backgroundColor: theme.palette.error.main,
                        color: theme.palette.error.contrastText,
                        opacity: theme.palette.action.disabledOpacity,
                    },
                },

                // Warning button
                '&.MuiButton-root.MuiButton-containedWarning': {
                    '&:not(.Mui-disabled)': {
                        '&:hover': {
                            backgroundColor: theme.palette.warning.light,
                            color: theme.palette.warning.contrastText,
                        },
                    },

                    '&.Mui-disabled': {
                        backgroundColor: theme.palette.warning.main,
                        color: theme.palette.warning.contrastText,
                        opacity: theme.palette.action.disabledOpacity,
                    },
                },

                // Success button
                '&.MuiButton-root.MuiButton-containedSuccess': {
                    '&:not(.Mui-disabled)': {
                        '&:hover': {
                            backgroundColor: theme.palette.success.light,
                            color: theme.palette.success.contrastText,
                        },
                    },

                    '&.Mui-disabled': {
                        backgroundColor: theme.palette.success.main,
                        color: theme.palette.success.contrastText,
                        opacity: theme.palette.action.disabledOpacity,
                    },
                },

                // White button
                '&.MuiButton-root.MuiButton-containedInfo': {
                    '&:not(.Mui-disabled)': {
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.primary.main,

                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                        },
                    },

                    '&.Mui-disabled': {
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.primary.main,
                        opacity: theme.palette.action.disabledOpacity,
                    },
                },
            },
            outlinedPrimary: {
                '&:not(.Mui-disabled)': {
                    '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                    },
                },

                '&.Mui-disabled': {
                    color: theme.palette.primary.main,
                    opacity: theme.palette.action.disabledOpacity,
                },
            },
            containedPrimary: {
                '&:not(.Mui-disabled)': {
                    '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.contrastText,
                    },
                },
                '&.Mui-disabled': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    opacity: theme.palette.action.disabledOpacity,
                },
                [theme.breakpoints.down('md')]: {
                    fontWeight: FONT_WEIGHT.semiBold,
                },
            },
            outlinedSecondary: {
                '&:not(.Mui-disabled)': {
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                    },
                },

                '&.Mui-disabled': {
                    color: theme.palette.secondary.main,
                    opacity: theme.palette.action.disabledOpacity,
                },
            },
            containedSecondary: {
                '&:not(.Mui-disabled)': {
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.contrastText,
                    },
                },
                '&.Mui-disabled': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    opacity: theme.palette.action.disabledOpacity,
                },

                [theme.breakpoints.down('md')]: {
                    fontWeight: FONT_WEIGHT.semiBold,
                },
            },
        },
        MuiButtonGroup: {
            root: {
                borderRadius: 30,
                height: 40,
                '& .MuiButton-root': {
                    boxShadow: 'none !important',
                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                    },
                },
                '&[role="group"]:not(.MuiButtonGroup-disableElevation)': {
                    boxShadow: `${BUTTON_SHADOW} !important`,
                },
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                    height: 30,
                },
            },
        },
        MuiCheckbox: {
            root: {
                padding: 0,
                '& .MuiSvgIcon-root': {
                    width: '24px',
                    height: '24px',
                    [theme.breakpoints.down('md')]: {
                        width: '20px',
                        height: '20px',
                    },
                },
                '&:hover': {
                    backgroundColor: 'unset !important',
                },
            },
            colorPrimary: {
                '&:not(checked)': {
                    color: theme.palette.primary.main,
                },
            },
            colorSecondary: {
                '&:not(checked)': {
                    color: theme.palette.secondary.main,
                },
            },
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: 'unset',
                marginRight: 'unset',
            },
            label: {
                fontWeight: FONT_WEIGHT.semiBold,
                lineHeight: '24px',
                [theme.breakpoints.down('md')]: {
                    fontSize: 14,
                    lineHeight: '20px',
                },
                '.MuiCheckbox-root + &': {
                    paddingLeft: theme.spacing(),

                    '.MuiFormControlLabel-labelPlacementStart &': {
                        paddingLeft: 0,
                        paddingRight: theme.spacing(),
                    },
                    '.MuiFormControlLabel-labelPlacementTop &': {
                        paddingLeft: 0,
                        paddingRight: theme.spacing(),
                    },
                    '.MuiFormControlLabel-labelPlacementBottom &': {
                        paddingLeft: 0,
                        paddingRight: theme.spacing(),
                    },
                },
            },
        },
        MuiTableBody: {
            root: {
                '& .MuiTableRow-root:last-of-type': {
                    '& .MuiTableCell-root': {
                        borderBottomColor: COLOR.transparent,
                    },
                },
                '& .MuiTableRow-root > th.MuiTableCell-root': {
                    fontWeight: FONT_WEIGHT.semiBold,
                    '& .MuiFormControlLabel-root': {
                        fontWeight: FONT_WEIGHT.semiBold,
                    },
                },
            },
        },
        MuiTableRow: {
            head: {
                '& .MuiTableCell-head': {
                    backgroundColor: theme.palette.secondary.main,
                    color: COLOR.whiteTransparent,
                    fontWeight: FONT_WEIGHT.thin,
                },

                '& .MuiCheckbox-root': {
                    color: `${theme.palette.common.white} !important`,

                    [`& .${CHECKED_ICON_CHEVRON_CLASSNAME}`]: {
                        fill: theme.palette.secondary.main,
                    },
                },
            },
        },
        MuiTableCell: {
            root: {
                borderBottomColor: theme.palette.tableBorder,
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                fontWeight: FONT_WEIGHT.thin,
                padding: theme.spacing(2.75, 2, 2.75, 0.5),
                lineHeight: 1,
                height: 80,
                boxSizing: 'border-box',
                whiteSpace: 'nowrap',
                textAlign: 'right',

                [theme.breakpoints.down('md')]: {
                    padding: theme.spacing(0.25, 1.25),
                    fontSize: 14,
                    height: 40,
                },

                '& .MuiFormControlLabel-root': {
                    marginBottom: 0,
                },
            },
        },
        MuiTextField: {
            root: {
                borderRadius: 0,
                padding: 0,
                backgroundColor: COLOR.transparent,
                color: theme.palette.common.black,

                '& .MuiFormHelperText-root': {
                    order: 0,
                    margin: 0,
                    fontWeight: FONT_WEIGHT.semiBold,
                    lineHeight: 1.2232,
                    padding: '4px 0',

                    [theme.breakpoints.down('md')]: {
                        padding: 0,
                        lineHeight: 1.5,
                        fontSize: '14px',
                    },
                },
            },
        },
        MuiFormControl: {
            root: {
                // For MuiTextField and Select components. We use helper text to display error message
                '& > .MuiInputLabel-outlined.Mui-error': {
                    display: 'none',
                },
            },
        },
        MuiFormHelperText: {
            root: {
                order: 0,
                margin: 0,
                marginTop: 0,
                fontWeight: FONT_WEIGHT.semiBold,
                lineHeight: 1.22,
                padding: '4px 0',
                minHeight: '30px',
                boxSizing: 'border-box',

                [theme.breakpoints.down('md')]: {
                    padding: 0,
                    lineHeight: 1.5,
                    fontSize: '14px',
                    minHeight: '21px',
                },
            },
            contained: {
                marginLeft: 0,
                marginRight: 0,
            },
        },
        MuiOutlinedInput: {
            root: {
                order: 1,
                backgroundColor: theme.palette.common.white,
                borderRadius: 0,

                '& input:autofill': {
                    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.common.white} inset`,
                },

                '& input:-webkit-autofill,': {
                    WebkitBoxShadow: `0 0 0 1000px ${theme.palette.common.white} inset`,
                },

                '&.MuiInputBase-root.Mui-error fieldset': {
                    borderColor: COLOR.transparent,
                },
                '&.MuiInputBase-root.Mui-focused fieldset': {
                    border: '1px solid',
                    borderColor: theme.palette.primary.main,
                },
                '&.MuiInputBase-root:hover fieldset': {
                    border: '1px solid',
                    borderColor: theme.palette.primary.main,
                },

                '&.MuiInputBase-root.Mui-disabled': {
                    backgroundColor: COLOR.gray5,
                    color: theme.palette.text.disabled,

                    '& fieldset': {
                        borderColor: COLOR.gray4,
                    },

                    '& input:autofill': {
                        WebkitBoxShadow: `0 0 0 1000px ${COLOR.gray5} inset`,
                    },

                    '& input:-webkit-autofill,': {
                        WebkitBoxShadow: `0 0 0 1000px ${COLOR.gray5} inset`,
                    },
                },

                '& fieldset': {
                    border: '1px solid',
                    borderColor: theme.palette.common.white,
                    top: 0,
                    padding: 0,

                    '& legend': {
                        display: 'none',
                    },
                },
            },

            input: {
                height: 24,
                padding: '10px 11px 10px 16px',
            },

            multiline: {
                lineHeight: '22px',
                padding: '11px 16px',
                [theme.breakpoints.down('md')]: {
                    padding: '10px 16px',
                },
            },
        },
        MuiInputLabel: {
            outlined: {
                marginBottom: 0,
                pointerEvents: 'auto',
                '&.MuiInputLabel-formControl': {
                    transform: 'none',
                    position: 'relative',
                },
                '&.MuiInputLabel-animated': {
                    transition: 'none',
                },
                '&.MuiInputLabel-shrink': {
                    transform: 'none',
                },
                transform: 'none',
                '&.Mui-focused.Mui-focused': {
                    transform: 'none',
                },
                '&.MuiFormLabel-root': {
                    color: 'currentColor',
                    fontWeight: FONT_WEIGHT.semiBold,
                    lineHeight: 1.22,
                    padding: '4px 0',
                    minHeight: '30px',
                    boxSizing: 'border-box',

                    [theme.breakpoints.down('md')]: {
                        padding: 0,
                        lineHeight: 1.5,
                        fontSize: '14px',
                        minHeight: '21px',
                    },
                },
                '&.Mui-error': {
                    color: theme.palette.error.main,
                },
            },
        },
        MuiIconButton: {
            root: {
                textTransform: 'none',
                transition: 'unset',
                whiteSpace: 'nowrap',
                fontSize: 24,
                padding: 8,

                [theme.breakpoints.down('md')]: {
                    fontSize: 18,
                    padding: 6,
                },
            },
            sizeSmall: {
                fontSize: 18,
                padding: 6,
            },
        },
        MuiTooltip: {
            tooltip: {
                padding: theme.spacing(1, 2),
                backgroundColor: theme.palette.common.white,
                border: `1px solid ${COLOR.gray4}`,
                borderRadius: 0,
                color: theme.palette.common.black,
                fontSize: 14,
            },
        },
        MuiSelect: {
            root: {
                borderRadius: 0,
                padding: 0,
                backgroundColor: COLOR.transparent,
                color: theme.palette.common.black,
                lineHeight: 1.2,
            },
            select: {
                padding: '0px 11px 0px 16px',
                '&:focus': {
                    backgroundColor: 'rgba(0, 0, 0, 0.01)',
                },
                '&.Mui-disabled': {
                    color: theme.palette.text.disabled,
                },
            },
            outlined: {
                '&&': {
                    height: 44,
                    display: 'flex',
                    alignItems: 'center',
                },
            },
            iconOutlined: {
                color: theme.palette.primary.main,
                top: 'calc(50% - 11px)',
            },
            iconOpen: {
                transform: 'rotate(180deg) translateY(3px)',
            },
        },
        MuiList: {
            root: {
                backgroundColor: COLOR.grayLight3,
            },
        },
        MuiListItem: {
            root: {
                height: 48,
            },
        },
        MuiListItemText: {
            primary: {
                lineHeight: 1.2,
            },
            secondary: {
                fontSize: 12,
                lineHeight: 1.2,
            },
            inset: {
                paddingLeft: 36,
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 36,
            },
        },
        MuiTypography: {
            h2: {
                lineHeight: 1,
                '&.MuiTypography-paragraph': {
                    marginBottom: '40px',
                },
            },
        },
        MuiChip: {
            root: {
                fontSize: 'inherit',
                borderRadius: 'none',
            },
        },
    },
    props: {
        MuiButton: {
            disableElevation: true, // remove button multi shadows
        },
        MuiButtonBase: {
            disableRipple: true, // No more ripple, on the whole application
        },
        MuiButtonGroup: {
            disableRipple: true, // No more ripple, on the whole application
        },
        MuiIconButton: {
            disableRipple: true, // No more ripple, on the whole application
        },
        MuiCard: {
            elevation: 0,
        },
        MuiCheckbox: {
            icon: <UncheckedIcon fontSize="small" />,
            checkedIcon: <CheckedIcon fontSize="small" />,
            color: 'primary',
        },
        MuiTextField: {
            variant: 'outlined',
            fullWidth: true,
        },
        MuiInputLabel: {
            shrink: true, // to show placeholder all time
            disableAnimation: true,
            variant: 'outlined',
        },
        MuiPaper: {
            elevation: 0,
            square: true,
        },
        MuiSelect: {
            variant: 'outlined',
            MenuProps: {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            },
            IconComponent: ArrowDownIcon,
        },
        MuiTypography: {
            variantMapping: {
                body1: 'span',
                body2: 'span',
            },
        },
    },
    customSettings: {
        autocomplete: {
            popper: {
                zIndex: 1501,
                gap: theme.spacing(0),
            },
            paper: {
                border: 'none',
                shadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
            },
        },
    },
});
