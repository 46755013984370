import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { DeliveryLineStatus } from '~/graphql';
import { formatTimeShort } from '~/utils/date';

type LineKind = {
    status?: DeliveryLineStatus;
    excavationDate: string | null;
    startDeliveryDate: string | null;
    endDeliveryDate: string | null;
};

type Props = {
    line: LineKind;
};

export const DeliveryLineTimesField = ({ line }: Props) => {
    const { excavationDate, startDeliveryDate, endDeliveryDate } = line;

    const { root, text, orange, green, blue, gray } = useStyles();

    const excavatedClassName = line.status === DeliveryLineStatus.EXCAVATED ? orange : gray;
    const loadedClassName = line.status === DeliveryLineStatus.DELIVERING ? green : gray;
    const deliveredClassName =
        line.status === DeliveryLineStatus.DELIVERED || line.status === DeliveryLineStatus.VERIFIED ? blue : gray;

    return (
        <div className={root}>
            <Text variant="TableRegular" className={cn(text, excavatedClassName)}>
                <LocalizableText code="ProjectDocuments.weightReceiptTable.excavated" />:{' '}
                {formatTimeShort(excavationDate)}
            </Text>
            <Text variant="TableRegular" className={cn(text, loadedClassName)}>
                <LocalizableText code="ProjectDocuments.weightReceiptTable.loaded" />:{' '}
                {formatTimeShort(startDeliveryDate)}
            </Text>
            <Text variant="TableRegular" className={cn(text, deliveredClassName)}>
                <LocalizableText code="ProjectDocuments.weightReceiptTable.delivered" />:{' '}
                {formatTimeShort(endDeliveryDate)}
            </Text>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: 110,
    },
    text: {
        [theme.breakpoints.down('md')]: {
            fontSize: '12px !important',
            lineHeight: 1,
        },
    },
    orange: {
        color: theme.palette.warning.main,
    },
    green: {
        color: theme.palette.success.main,
    },
    blue: {
        color: theme.palette.primary.main,
    },
    gray: {
        color: theme.palette.text.secondary,
    },
}));
