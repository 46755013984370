import { Button } from '@material-ui/core';
import React from 'react';

import { SimpleDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { showCustomDialog } from '~/@store/common';
import { ISubstance } from '~/@store/substances';
import { useToxicLimitsPermissions } from '~/@store/toxicLimits';
import { SumRuleForm } from '~/@views/AdminView/ToxicLimitsPage/SumRules/SumRuleForm';

type Props = {
    substances: ISubstance[];
};

export const AddSumRuleButton = ({ substances }: Props) => {
    const handleAdd = () =>
        showCustomDialog({
            render: closeDialog => (
                <SimpleDialog onClose={closeDialog} maxWidth="sm" fullWidth>
                    <SumRuleForm substances={substances} sumRule={null} closeForm={closeDialog} />
                </SimpleDialog>
            ),
        });

    const { canCreate } = useToxicLimitsPermissions();

    return (
        <Button fullWidth variant="contained" color="primary" onClick={handleAdd} disabled={!canCreate}>
            <LocalizableText code={'ToxicLimitsPage.addNewRule'} />
        </Button>
    );
};
