import { Form, Formik } from 'formik';
import React from 'react';

import { DisableProvider } from '~/@components/@hooks';
import { makeStyles } from '~/@components/@theme';
import { CollapseView } from '~/@components/CollapseView';
import { Divider } from '~/@components/Divider';
import { FadeProgress } from '~/@components/FadeProgress';
import { Grid } from '~/@components/Grid';
import { LocalizableText } from '~/@components/LocalizableText';
import {
    IDetailedDumpLoad,
    useDumpLoadAbilities,
    useDumpLoadChangeStatusMutation,
    useDumpLoadUpdateDestinationMutation,
    useDumpLoadUpdateMutation,
} from '~/@store/dumpLoads';
import { useProjectUpdate } from '~/@store/projects';
import { DumpLoadEditCoordinationFields } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditForm/DumpLoadEditCoordinationFields';

import { DumpLoadCommentsView } from '../DumpLoadCommentsView';
import { DumpLoadEditAmountAndTransportFields } from './DumpLoadEditAmountAndTransportFields';
import { DumpLoadEditCommonFields } from './DumpLoadEditCommonFields';
import { DumpLoadEditDestination } from './DumpLoadEditDestination';
import { DumpLoadEditMode, getDumpLoadFormikConfig, IDumpLoadFormValues } from './DumpLoadEditFormik';
import { DumpLoadEditPricesAndActions } from './DumpLoadEditPricesAndActions';

type IDumpLoadEditFormProps = {
    dumpLoad: IDetailedDumpLoad;
};

export const DumpLoadEditForm = ({ dumpLoad }: IDumpLoadEditFormProps) => {
    const [updateDumpLoad, isDumpLoadUpdating] = useDumpLoadUpdateMutation(dumpLoad);
    const [updateStatus, isStatusUpdating] = useDumpLoadChangeStatusMutation(dumpLoad);
    const [updateProject, isProjectUpdating] = useProjectUpdate();
    const [updateDumpLoadDestination, isDumpLoadDestinationUpdating] = useDumpLoadUpdateDestinationMutation(dumpLoad);

    const { commentsView } = useStyles();

    const { canUpdateFieldsAffectsDestination, canUpdatePrices, canUpdateDumpLoad } = useDumpLoadAbilities(dumpLoad);

    const { project } = dumpLoad;
    if (!project) return null;

    return (
        <Formik<IDumpLoadFormValues>
            {...getDumpLoadFormikConfig(
                dumpLoad,
                updateDumpLoad,
                updateProject,
                updateDumpLoadDestination,
                !!canUpdateFieldsAffectsDestination,
                !!canUpdatePrices
            )}>
            {({ values }) => (
                <Form>
                    <FadeProgress
                        show={
                            isDumpLoadUpdating || isProjectUpdating || isStatusUpdating || isDumpLoadDestinationUpdating
                        }
                    />
                    <DisableProvider disabled={values.editMode === DumpLoadEditMode.DESTINATION}>
                        <CollapseView
                            title={<LocalizableText code={'DumpLoadCard.dumpLoadInfo'} />}
                            defaultOpen
                            persistStateName="dumpLoadInfo">
                            <Grid container spacing={2}>
                                <Grid item xs={7}>
                                    <DumpLoadEditCommonFields />
                                </Grid>
                                <Grid item xs={5}>
                                    <DumpLoadCommentsView
                                        projectId={project.id}
                                        dumpLoadId={dumpLoad.id}
                                        comments={dumpLoad.comments || []}
                                        className={commentsView}
                                        readOnly={!canUpdateDumpLoad}
                                    />
                                </Grid>
                            </Grid>
                        </CollapseView>
                        <Divider marginTop marginBottom />
                        <DumpLoadEditCoordinationFields />
                        <Divider marginTop marginBottom />
                        <CollapseView
                            title={<LocalizableText code={'DumpLoadCard.transportInfo'} />}
                            defaultOpen
                            persistStateName="transportInfo">
                            <DumpLoadEditAmountAndTransportFields />
                        </CollapseView>
                    </DisableProvider>
                    <DumpLoadEditPricesAndActions updateStatus={updateStatus} />
                    <Divider marginTop marginBottom />
                    {values.editMode === DumpLoadEditMode.DESTINATION && <DumpLoadEditDestination />}
                </Form>
            )}
        </Formik>
    );
};

const useStyles = makeStyles(() => ({
    commentsView: {
        height: 730,
    },
}));
