import { Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';
import React from 'react';

import { INumberFieldProps, NumberField } from '~/@components/NumberField';

type Props = {
    name: string;
} & Omit<INumberFieldProps, 'onChange' | 'nullable' | 'name' | 'value'>;

export const FormikTypeUnsafeNumberField = ({ name, disabled, ...restProps }: Props) => {
    return (
        <Field name={name}>
            {({ field, form, meta }: FieldProps) => (
                <NumberField
                    {...field}
                    onChange={v => {
                        form.setFieldTouched(field.name, true);
                        form.setFieldValue(field.name, v);
                    }}
                    error={meta.touched && !!meta.error}
                    {...restProps}
                    disabled={disabled || form.isSubmitting}
                />
            )}
        </Field>
    );
};
