import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { CommonRenderRule } from '~/@store/permissions';

type Props<TRenderRule extends CommonRenderRule> = {
    title: React.ReactNode;
    rules: TRenderRule[];
    extendedColumns: Array<{ field: keyof TRenderRule & string; title: React.ReactNode }>;
};

const EVERYONE_ROLE_PLACEHOLDER = 'EVERYONE';

export function PermissionsTable<TRenderRule extends CommonRenderRule>({
    title,
    rules,
    extendedColumns,
}: Props<TRenderRule>) {
    const classes = useStyles();

    return (
        <>
            <Text variant="TitleRegular">{title}</Text>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>
                            <LocalizableText code={'PermissionsPage.role'} />
                        </TableCell>
                        <TableCell>
                            <LocalizableText code={'PermissionsPage.action'} />
                        </TableCell>
                        {extendedColumns.map(({ field, title }) => (
                            <TableCell key={field}>{title}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rules.map((rule, index) => (
                        <TableRow key={index} className={classes[rule.allow]}>
                            <TableCell>{rule.allow}</TableCell>
                            <TableCell>{rule.role || EVERYONE_ROLE_PLACEHOLDER}</TableCell>
                            <TableCell>{rule.action}</TableCell>
                            {extendedColumns.map(({ field }) => (
                                <TableCell key={field}>{rule[field]}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}

const useStyles = makeStyles(() => ({
    allow: { '& td': { color: green[900] } },
    forbid: { '& td': { color: red[900] } },
}));
