import { routes } from '@common/routes';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AdminView } from '~/@views/AdminView';
import { NotFoundPage } from '~/@views/idlePages';
import { UserView } from '~/@views/UserView';
import { HomePage } from '~/@views/UserView/HomePage';

import {
    ConfirmEmailPage,
    ForgotPasswordPage,
    LoginPage,
    NotApprovedPage,
    NotConfirmedEmailPage,
    SignupPage,
    UserBlockedPage,
} from './@views/AuthView';
import { AuthRoute, NoAuthRoute } from './routes';

export const RootRoutes = () => (
    <Switch>
        <NoAuthRoute path={routes.login} component={LoginPage} />
        <NoAuthRoute path={routes.signup} component={SignupPage} />
        <NoAuthRoute path={routes.forgotPassword} component={ForgotPasswordPage} />

        <NoAuthRoute path={routes.confirmEmailID} component={ConfirmEmailPage} />
        <NoAuthRoute path={routes.notConfirmedEmail} component={NotConfirmedEmailPage} />

        <NoAuthRoute path={routes.notApproved} component={NotApprovedPage} />
        <NoAuthRoute path={routes.userBlocked} component={UserBlockedPage} />

        <AuthRoute exact path={routes.root} component={HomePage} />

        <AuthRoute path={routes.sochi.base} component={UserView} />

        <AuthRoute path={routes.admin.base} component={AdminView} />

        <Route path={routes.notFound} component={NotFoundPage} />
        <Route component={NotFoundPage} />
    </Switch>
);
