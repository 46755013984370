import { gql } from '@apollo/client/core';

export const LockMutation = gql`
    mutation LockMutation($input: LockInput!) {
        lock(input: $input) {
            id
            entityType
            entityId
            expire
            userId
            user {
                id
                name
                surname
            }
        }
    }
`;

export const RemoveLockMutation = gql`
    mutation RemoveLockMutation($input: LockInput!) {
        removeLock(input: $input)
    }
`;

export const RemoveAllLocksMutation = gql`
    mutation RemoveAllLocksMutation {
        removeAllLocks
    }
`;
