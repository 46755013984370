import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const BinIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 6.00125C2 5.44897 2.44772 5.00125 3 5.00125H21C21.5523 5.00125 22 5.44897 22 6.00125C22 6.55354 21.5523 7.00125 21 7.00125H3C2.44772 7.00125 2 6.55354 2 6.00125Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 3.00125C9.73478 3.00125 9.48043 3.10661 9.29289 3.29414C9.10536 3.48168 9 3.73604 9 4.00125V5.00125H15V4.00125C15 3.73604 14.8946 3.48168 14.7071 3.29414C14.5196 3.10661 14.2652 3.00125 14 3.00125H10ZM17 5.00125V4.00125C17 3.2056 16.6839 2.44254 16.1213 1.87993C15.5587 1.31732 14.7956 1.00125 14 1.00125H10C9.20435 1.00125 8.44129 1.31732 7.87868 1.87993C7.31607 2.44254 7 3.2056 7 4.00125V5.00125H5C4.44772 5.00125 4 5.44897 4 6.00125V20.0013C4 20.7969 4.31607 21.56 4.87868 22.1226C5.44129 22.6852 6.20435 23.0013 7 23.0013H17C17.7957 23.0013 18.5587 22.6852 19.1213 22.1226C19.6839 21.56 20 20.7969 20 20.0013V6.00125C20 5.44897 19.5523 5.00125 19 5.00125H17ZM6 7.00125V20.0013C6 20.2665 6.10536 20.5208 6.29289 20.7084C6.48043 20.8959 6.73478 21.0013 7 21.0013H17C17.2652 21.0013 17.5196 20.8959 17.7071 20.7084C17.8946 20.5208 18 20.2665 18 20.0013V7.00125H6Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 10.0013C10.5523 10.0013 11 10.449 11 11.0013V17.0013C11 17.5535 10.5523 18.0013 10 18.0013C9.44772 18.0013 9 17.5535 9 17.0013V11.0013C9 10.449 9.44772 10.0013 10 10.0013Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 10.0013C14.5523 10.0013 15 10.449 15 11.0013V17.0013C15 17.5535 14.5523 18.0013 14 18.0013C13.4477 18.0013 13 17.5535 13 17.0013V11.0013C13 10.449 13.4477 10.0013 14 10.0013Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
