const regexp = /^\d{0,2}?:?\d{0,2}?$/u;

const colon = ':';

export const emptyEqualValue = '00:00';

const isValidMinute = (minute: number): boolean => {
    return minute >= 0 && minute < 60;
};

const isValidHour = (hour: number): boolean => {
    return hour >= 0 && hour < 24;
};

const safeParseInt = (val: string): number => parseInt(val) || 0;

export const isValidInput = (value: string): boolean => {
    if (!regexp.test(value)) {
        return false;
    }

    const parts = value.split(colon);
    const hourString = parts[0] ?? '';
    const minuteString = parts[1] ?? '';
    const hours = safeParseInt(hourString);
    const minutes = safeParseInt(minuteString);

    return isValidHour(hours) && isValidMinute(minutes);
};

export const formatTimeTextInput = (value: string): string => {
    const colonIndex = value.indexOf(colon);

    switch (value.length) {
        case 1:
            return value === colon ? emptyEqualValue : `0${value}:00`;
        case 2:
            if (value.endsWith(colon)) return `0${value}00`;
            if (value.startsWith(colon)) return `00${value}0`;

            return isValidHour(safeParseInt(value)) ? `${value}:00` : emptyEqualValue;
        case 3:
            if (colonIndex === 0) return `00${value}`;
            if (colonIndex === 2) return `${value}00`;

            const splittedValue = value.split(colon);

            return `0${splittedValue[0]}:0${splittedValue[1]}`;
        case 4:
            return colonIndex === 1 ? `0${value}` : `${value}0`;
        case 5:
            return value;
    }

    return emptyEqualValue;
};
