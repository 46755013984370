import { styled, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { IncomingRow, ReportData, ReportRow } from '~/@user-store/landfillBatches/landfillBatchReport.helpers';
import { formatDate } from '~/utils/date';

const TC = styled(TableCell)({
    border: '1px solid black',
    borderBottomColor: 'black !important',
    textAlign: 'left',
    height: 'auto',
});

const TH = styled(TC)({
    fontWeight: 'bold',
});

type Props = {
    data: ReportData;
};

const OutgoingRow = ({ line, order }: ReportRow) => {
    return (
        <TableRow>
            <TC>{order.orderNumber}</TC>
            <TC>{order.dumpLoad?.dumpType.name}</TC>
            <TC>{line.truckRegistrationNumber}</TC>
            <TC>{line.weight}</TC>
            <TC>{formatDate(new Date(line.plannedStartDate))}</TC>
        </TableRow>
    );
};

const IncomingRows = ({ incomingRows }: ReportRow) => {
    const getRowContent = (row: IncomingRow) => {
        switch (row.__type) {
            case 'DeliveryLine':
                return (
                    <>
                        <TC>{row.order.projectName}</TC>
                        <TC>{row.order.dumpLoad.solidTestResult}</TC>
                        <TC>{formatDate(new Date(row.line.plannedStartDate))}</TC>
                    </>
                );
            case 'Transfer':
                return (
                    <>
                        <TC>
                            {row.transfer.externalProject?.name}
                            <br />
                            {row.transfer.externalProject?.address}
                        </TC>
                        <TC />
                        <TC>{formatDate(new Date(row.transfer.date))}</TC>
                    </>
                );
        }
    };

    return (
        <TableRow>
            <TC>
                <LocalizableText code="LandfillPage.batchReport.sources" />:
            </TC>
            <TC colSpan={4}>
                {!incomingRows.length ? (
                    <Text>
                        <LocalizableText code="LandfillPage.batchReport.sourceUnknown" />
                    </Text>
                ) : (
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TH style={{ width: '50%' }}>
                                    <LocalizableText code="LandfillPage.batchReport.project" />
                                </TH>
                                <TH style={{ width: '25%' }}>
                                    <LocalizableText code="LandfillPage.batchReport.contLevel" />
                                </TH>
                                <TH style={{ width: '25%' }}>
                                    <LocalizableText code="LandfillPage.batchReport.date" />
                                </TH>
                            </TableRow>
                            {incomingRows.map((row, index) => (
                                <TableRow key={index}>{getRowContent(row)}</TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TC>
        </TableRow>
    );
};

export const LandfillBatchReportView = ({ data }: Props) => {
    return (
        <Table size="small">
            <TableBody>
                <TableRow>
                    <TH>
                        <LocalizableText code="LandfillPage.batchReport.order" />
                    </TH>
                    <TH>
                        <LocalizableText code="LandfillPage.batchReport.material" />
                    </TH>
                    <TH>
                        <LocalizableText code="LandfillPage.batchReport.regNumber" />
                    </TH>
                    <TH>
                        <LocalizableText code="LandfillPage.batchReport.weight" />
                    </TH>
                    <TH>
                        <LocalizableText code="LandfillPage.batchReport.date" />
                    </TH>
                </TableRow>
                {data.rows.map(row => (
                    <React.Fragment key={row.line.id}>
                        <OutgoingRow {...row} />
                        <IncomingRows {...row} />
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>
    );
};
