import { UserContextRole } from '@common/enums';
import React, { useCallback, VFC } from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { LocalizableText } from '~/@components/LocalizableText';
import { showCustomDialog } from '~/@store/common';
import { IProjectSearchRecord } from '~/@store/projects';
import { useAddUserToProject } from '~/@store/users';
import { useUserAbilities } from '~/contexts';
import { canUpdateUser } from '~/utils/auth';

import { useUserDetailContext } from '../../UserDetailContext';
import { ProjectAndRoleSelectDialog } from './ProjectAndRoleSelectDialog';

export const UserProjectAddButton: VFC = () => {
    const ability = useUserAbilities();
    const user = useUserDetailContext();
    const [addUserToProject, adding] = useAddUserToProject();

    const handleClick = useCallback(
        () =>
            showCustomDialog<{ project: IProjectSearchRecord; role: UserContextRole } | null>({
                render: closeDialog => <ProjectAndRoleSelectDialog user={user} onSubmit={closeDialog} />,
            }).then(result => {
                if (result) {
                    return addUserToProject(user.id, result.project.id, result.role, result.project.ver);
                }
            }),
        [user, addUserToProject]
    );

    return (
        <ActionButton onClick={handleClick} disabled={adding || !canUpdateUser(ability, user)}>
            <LocalizableText code={'UserEditPage.addProject'} />
        </ActionButton>
    );
};
