import { getLocalizationSubject } from '@common/abilities/localization';
import { VERSION_HEADER } from '@common/constants';
import { isErrorDto } from '@common/validations/types.validation';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { add } from 'date-fns';

import type { IAbilityContext, IAbilityUserContext } from '~/contexts';
import { FrontApiError } from '~/utils/error';

import { IAbilityUser } from '../../../common/abilities/abilities.types';
import { getCompanySubject } from '../../../common/abilities/companies/companySubject';
import { CompanySubject } from '../../../common/abilities/companies/companyTypes';
import {
    DeliveryLineSubject,
    getDeliveryLineSubject,
    IAbilityDeliveryLine,
} from '../../../common/abilities/deliveryLines';
import { getLandfillDeviationSubject, getProjectDeviationSubject } from '../../../common/abilities/deviations';
import { getDumpTypeSubject } from '../../../common/abilities/dumpTypes';
import { getFileTemplateSubject } from '../../../common/abilities/fileTemplates';
import { getLandfillSubject, IAbilityLandfill, LandfillSubject } from '../../../common/abilities/landfills';
import { getOrderSubject } from '../../../common/abilities/orders';
import {
    DumpLoadSubject,
    getProjectDumpLoadSubject,
    getProjectSubject,
    IAbilityDumpLoad,
    IAbilityProject as ICommonAbilityProject,
    IProjectSubject,
    ProjectSubject,
} from '../../../common/abilities/projects';
import { getSubstanceSubject } from '../../../common/abilities/substances';
import { getUserSubject, IAbilityTargetUser } from '../../../common/abilities/users';
import {
    AbilityCan,
    AbilitySubjects,
    DumpLoadFields,
    HttpCodes,
    LandfillFields,
    LandfillSubareaFields,
    ProjectFields,
    UserRole,
} from '../../../common/enums';
import type { LandfillQuery_landfill, LandfillQuery_landfill_subareas } from '../graphql';
import i18n from '../i18n';
import { handleLoadingPromise } from '../services/loader';

export type IAbilityProject = ICommonAbilityProject & { id: string };

export const canReadProject = (
    { user, ability }: IAbilityUserContext,
    project: IAbilityProject,
    subjects?: Array<IProjectSubject>
): boolean => {
    return ability.can(AbilityCan.READ, getProjectSubject(user, project, subjects));
};

export const canDeleteProjectDocument = ({ ability, user }: IAbilityUserContext, project: IAbilityProject): boolean => {
    return ability.can(AbilityCan.DELETE, getProjectSubject(user, project, [ProjectSubject.PROJECT_DOCUMENT]));
};

export const canUploadProjectDocument = ({ ability, user }: IAbilityUserContext, project: IAbilityProject): boolean => {
    return ability.can(AbilityCan.UPLOAD, getProjectSubject(user, project, [ProjectSubject.PROJECT_DOCUMENT]));
};

export const canChangeProjectLocation = ({ ability, user }: IAbilityUserContext, project: IAbilityProject) =>
    ability.can(AbilityCan.UPDATE, getProjectSubject(user, project, [ProjectFields.location]));

export const canChangeLandfillLocation = (context: IAbilityContext, landfill?: IAbilityLandfill | null): boolean =>
    !landfill || context.can(AbilityCan.READ, AbilitySubjects.MANAGER_BUSINESS_FLOW);

export const canUpdateProject = (
    { user, ability }: IAbilityUserContext,
    project?: IAbilityProject | null,
    subjects?: Array<IProjectSubject>
): boolean => {
    return ability.can(AbilityCan.UPDATE, getProjectSubject(user, project, subjects));
};

export const canUpdateDumpLoad = (
    { ability, user }: IAbilityUserContext,
    project: IAbilityProject,
    dumpLoad: IAbilityDumpLoad,
    subjects?: Array<DumpLoadSubject>
): boolean => {
    return ability.can(AbilityCan.UPDATE, getProjectDumpLoadSubject(user, project, dumpLoad, subjects));
};

export const canReadDumpLoadComments = (
    { ability, user }: IAbilityUserContext,
    project: IAbilityProject,
    dumpLoad: IAbilityDumpLoad | null | undefined
): boolean => {
    return ability.can(AbilityCan.READ, getProjectDumpLoadSubject(user, project, dumpLoad, [DumpLoadFields.comments]));
};

export const canUpdateProjectEndDate = (
    { user, ability }: IAbilityUserContext,
    project: IAbilityProject | null
): boolean => {
    return ability.can(AbilityCan.UPDATE, getProjectSubject(user, project, [ProjectFields.endDate]));
};

export const canUpdateProjectDateBeforeToday = (
    { user, ability }: IAbilityUserContext,
    project: IAbilityProject | null | undefined
): boolean => {
    return ability.can(
        AbilityCan.UPDATE,
        getProjectSubject(user, project, [ProjectSubject.PROJECT_DATES_BEFORE_TODAY])
    );
};

export const canUpdateProjectDatesInProgress = (
    { user, ability }: IAbilityUserContext,
    project: IAbilityProject | null | undefined
): boolean => {
    return ability.can(
        AbilityCan.UPDATE,
        getProjectSubject(user, project, [ProjectFields.startDate, ProjectFields.endDate])
    );
};

export const canUpdateDumploadDeclaration = (
    { ability, user }: IAbilityUserContext,
    project: IAbilityProject,
    dumpLoad: IAbilityDumpLoad
) => {
    return ability.can(
        AbilityCan.UPDATE,
        getProjectDumpLoadSubject(user, project, dumpLoad, [DumpLoadSubject.DUMP_LOAD_DECLARATION])
    );
};

export const canCreateProjectDeviation = (context: IAbilityUserContext, project: IAbilityProject) => {
    return context.ability.can(AbilityCan.CREATE, getProjectDeviationSubject(context.user, project, null));
};

export const canReadProjectUsers = ({ ability, user }: IAbilityUserContext, project?: IAbilityProject | null) => {
    return ability.can(AbilityCan.READ, getProjectSubject(user, project, [ProjectSubject.PROJECT_USERS]));
};

export const canReadPriceCalculator = ({ ability, user }: IAbilityUserContext, project?: IAbilityProject | null) => {
    return ability.can(AbilityCan.READ, getProjectSubject(user, project, [ProjectSubject.PRICE_CALCULATOR_PAGE]));
};

export const canReadOffers = ({ ability, user }: IAbilityUserContext, project: IAbilityProject | null) => {
    return ability.can(AbilityCan.READ, getProjectSubject(user, project, [ProjectSubject.OFFERS]));
};

export const canReadProjectCustomer = ({ ability, user }: IAbilityUserContext, project?: IAbilityProject | null) => {
    return ability.can(AbilityCan.READ, getProjectSubject(user, project, [ProjectFields.customer]));
};

export const canAddOrInviteProjectUser = ({ ability, user }: IAbilityUserContext, project: IAbilityProject) => {
    return ability.can(AbilityCan.UPDATE, getProjectSubject(user, project, [ProjectSubject.PROJECT_USERS]));
};

export const canRemoveProjectUser = ({ ability, user }: IAbilityUserContext, project: IAbilityProject) => {
    return ability.can(AbilityCan.DELETE, getProjectSubject(user, project, [ProjectSubject.PROJECT_USERS]));
};

export const canUpdateProjectCustomer = ({ ability, user }: IAbilityUserContext, project?: IAbilityProject | null) => {
    return ability.can(AbilityCan.UPDATE, getProjectSubject(user, project, [ProjectFields.customerId]));
};

export const hasAdminPermissions = (context: IAbilityContext) => {
    return context.can(AbilityCan.READ, AbilitySubjects.MANAGER_BUSINESS_FLOW);
};

export const canCreateLandfill = (user: IAbilityUser, context: IAbilityContext) => {
    return context.can(AbilityCan.CREATE, getLandfillSubject(user, null));
};

export const canUpdateLandfill = (user: IAbilityUser, context: IAbilityContext, landfill: IAbilityLandfill) => {
    return context.can(AbilityCan.UPDATE, getLandfillSubject(user, landfill));
};

export const canReadLandfillEvents = (user: IAbilityUser, context: IAbilityContext, landfill: IAbilityLandfill) => {
    return context.can(AbilityCan.READ, getLandfillSubject(user, landfill, LandfillSubject.DELIVERY_EVENTS));
};

export type ILandfillAbilityField =
    | (`${LandfillFields}` & keyof LandfillQuery_landfill)
    | (`${LandfillSubareaFields}` & keyof LandfillQuery_landfill_subareas);

export const canReadLandfillField = (
    user: IAbilityUser,
    context: IAbilityContext,
    landfill: IAbilityLandfill | null | undefined,
    field: ILandfillAbilityField
): boolean => {
    return context.can(AbilityCan.READ, getLandfillSubject(user, landfill, field));
};

export const canChangeLandfillField = (
    user: IAbilityUser,
    context: IAbilityContext,
    landfill: IAbilityLandfill | null | undefined,
    field:
        | (`${LandfillFields}` & keyof LandfillQuery_landfill)
        | (`${LandfillSubareaFields}` & keyof LandfillQuery_landfill_subareas)
) => {
    return context.can(landfill ? AbilityCan.UPDATE : AbilityCan.CREATE, getLandfillSubject(user, landfill, field));
};

export const canCreateLandfillDeviation = (context: IAbilityUserContext, landfill: LandfillQuery_landfill) => {
    return context.ability.can(AbilityCan.CREATE, getLandfillDeviationSubject(context.user, landfill, null));
};

export function canReadCompany({ user, ability }: IAbilityUserContext) {
    return ability.can(AbilityCan.READ, getCompanySubject(user));
}

export function canGetCompanyList({ user, ability }: IAbilityUserContext) {
    return ability.can(AbilityCan.READ, getCompanySubject(user, [CompanySubject.LIST]));
}

export const canUpdateUserWithRole = ({ user, ability }: IAbilityUserContext, role: UserRole): boolean => {
    return ability.can(AbilityCan.UPDATE, getUserSubject(user, null, role));
};

export const canCreateUserWithRole = ({ user, ability }: IAbilityUserContext, role: UserRole): boolean => {
    return ability.can(AbilityCan.CREATE, getUserSubject(user, null, role));
};

export const canCreateUser = ({ user, ability }: IAbilityUserContext): boolean => {
    return Object.values(UserRole).some(role => ability.can(AbilityCan.CREATE, getUserSubject(user, null, role)));
};

export const canUpdateUser = ({ user, ability }: IAbilityUserContext, targetUser: IAbilityTargetUser): boolean => {
    return ability.can(AbilityCan.UPDATE, getUserSubject(user, targetUser));
};

export const canDeleteUser = ({ user, ability }: IAbilityUserContext, targetUser?: IAbilityTargetUser): boolean => {
    return ability.can(AbilityCan.DELETE, getUserSubject(user, targetUser));
};

export function canGetUserList({ user, ability }: IAbilityUserContext) {
    return ability.can(AbilityCan.READ, getUserSubject(user));
}

export function canUpdateOrder({ user, ability }: IAbilityUserContext) {
    return ability.can(AbilityCan.UPDATE, getOrderSubject(user));
}

export function canCreateDeliveryLine({ user, ability }: IAbilityUserContext, dumpLoad: IAbilityDumpLoad) {
    return ability.can(AbilityCan.CREATE, getDeliveryLineSubject(user, null, dumpLoad));
}

export function canDeleteDeliveryLine({ user, ability }: IAbilityUserContext, deliveryLine?: IAbilityDeliveryLine) {
    return ability.can(AbilityCan.DELETE, getDeliveryLineSubject(user, deliveryLine));
}

export function canUpdateDeliveryLineReceiverFields(
    { user, ability }: IAbilityUserContext,
    landfill: IAbilityLandfill
) {
    return ability.can(
        AbilityCan.UPDATE,
        getDeliveryLineSubject(user, null, null, null, landfill, [DeliveryLineSubject.RECEIVER_FIELDS])
    );
}

export function canReadDumpType({ user, ability }: IAbilityUserContext) {
    return ability.can(AbilityCan.READ, getDumpTypeSubject(user));
}

export function canUpdateDumpType({ user, ability }: IAbilityUserContext) {
    return ability.can(AbilityCan.UPDATE, getDumpTypeSubject(user));
}

export function canCreateDumpType({ user, ability }: IAbilityUserContext) {
    return ability.can(AbilityCan.CREATE, getDumpTypeSubject(user));
}

export function canDeleteDumpType({ user, ability }: IAbilityUserContext) {
    return ability.can(AbilityCan.DELETE, getDumpTypeSubject(user));
}

export function canReadSubstance({ user, ability }: IAbilityUserContext) {
    return ability.can(AbilityCan.READ, getSubstanceSubject(user));
}

export function canUpdateSubstance({ user, ability }: IAbilityUserContext) {
    return ability.can(AbilityCan.UPDATE, getSubstanceSubject(user));
}

export function canCreateSubstance({ user, ability }: IAbilityUserContext) {
    return ability.can(AbilityCan.CREATE, getSubstanceSubject(user));
}

export function canDeleteSubstance({ user, ability }: IAbilityUserContext) {
    return ability.can(AbilityCan.DELETE, getSubstanceSubject(user));
}

export const canUpdateFileTemplate = ({ user, ability }: IAbilityUserContext) => {
    return ability.can(AbilityCan.UPDATE, getFileTemplateSubject(user));
};

export const canReadFileTemplate = ({ user, ability }: IAbilityUserContext) => {
    return ability.can(AbilityCan.READ, getFileTemplateSubject(user));
};

export const canUpdateLocalization = ({ user, ability }: IAbilityUserContext) => {
    return ability.can(AbilityCan.UPDATE, getLocalizationSubject(user));
};

export const canDetachEventFromLine = ({ user, ability }: IAbilityUserContext, landfill: IAbilityLandfill) =>
    ability.can(AbilityCan.UPDATE, getLandfillSubject(user, landfill, LandfillSubject.DELIVERY_EVENTS));

function setAuthCookieForVoyagerAndGrahpiQL(token: string) {
    // Only for `(*.)test.*` branches
    if (!window.location.hostname.includes('test.')) return;

    document.cookie = `JWT=${token}; expires=${add(new Date(), { days: token ? 360 : -1 }).toISOString()}; path=/api`;
}

const tokenKey = 'token';

export const getAuthToken = (): null | string => localStorage.getItem(tokenKey) || null;

export const setAuthToken = (token: string): void => {
    localStorage.setItem(tokenKey, token);
    setAuthCookieForVoyagerAndGrahpiQL(token);
};

export const removeAuthToken = () => {
    localStorage.removeItem(tokenKey);
    setAuthCookieForVoyagerAndGrahpiQL('');
};

export const postRequest = async <TResponse extends unknown>(url: string, payload: unknown): Promise<TResponse> => {
    let error!: AxiosError | undefined;

    const responseData: TResponse | void = await handleLoadingPromise(
        axios
            .post<TResponse>(url, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    [VERSION_HEADER]: window.__version,
                },
            })
            .then(res => res.data)
            .catch(err => {
                error = err;
            })
    );

    if (responseData) return responseData;

    if (isErrorDto(error?.response?.data)) throw FrontApiError.fromDto(error?.response?.data);

    if (error?.response?.status === HttpCodes.NOT_ACCEPTABLE) {
        alert(i18n.errorCodes.WRONG_VERSION);
    }

    throw FrontApiError.fromError(error);
};
