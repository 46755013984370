import React, { useMemo } from 'react';

import { DateTimeFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn } from '~/@components/Table';
import { IUserActivity, UserActivityFilterFields } from '~/@store/userActivities';
import i18n from '~/i18n';
import { getUserFullName } from '~/utils/user';

import { UserActivityHeadCell } from './UserActivityHeadCell';

export const useUserActivityColumns = (): ITableColumn<IUserActivity>[] => {
    const columns: ITableColumn<IUserActivity>[] = useMemo(
        () => [
            {
                title: <LocalizableText code={'UserActivityTable.name'} />,
                render: row => getUserFullName(row.user),
                name: 'name',
            },
            {
                title: <LocalizableText code={'UserActivityTable.email'} />,
                render: row => row.user.email,
                name: 'email',
            },
            {
                title: <LocalizableText code={'UserActivityTable.role'} />,
                render: row => i18n.UserRole[row.user.role!],
                name: 'role',
            },
            {
                title: <LocalizableText code={'UserActivityTable.lastSession'} />,
                render: row => <DateTimeFormatter>{row.lastActivityDate}</DateTimeFormatter>,
                name: 'lastSession',
            },
            {
                title: (
                    <UserActivityHeadCell
                        title={<LocalizableText code={'UserActivityTable.active'} />}
                        fieldName={UserActivityFilterFields.minutesToCut}
                        withFilter
                    />
                ),
                render: row => `${row.minutesAgo} ${i18n.UserActivityTable.minAgo}`,
                name: 'minutesAgo',
            },
        ],
        []
    );

    return columns;
};
