import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { BatchLogQuery } from '~/@user-store/landfillBatches/landfillBatch.queries';
import { BatchLogQuery as IBatchLogQuery, BatchLogQueryVariables } from '~/graphql';

export const useBatchLog = (
    landfillId: string,
    batchId: string
): QueryResult<IBatchLogQuery, BatchLogQueryVariables> => {
    return useQuery<IBatchLogQuery, BatchLogQueryVariables>(BatchLogQuery, {
        fetchPolicy: 'cache-and-network',
        variables: {
            landfillId,
            batchId,
        },
    });
};
