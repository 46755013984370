import { useMutation } from '@apollo/client';

import { ProjectEnableWhatsappMutation, ProjectQuery } from '~/@store/projects';
import { ProjectEnableWhatsapp, ProjectEnableWhatsappVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

export const useProjectEnableWhatsapp = (projectId: string) => {
    const [callMutation, { loading }] = useMutation<ProjectEnableWhatsapp, ProjectEnableWhatsappVariables>(
        ProjectEnableWhatsappMutation,
        {
            variables: { projectId },
            refetchQueries: [{ query: ProjectQuery, variables: { id: projectId } }],
            awaitRefetchQueries: true,
        }
    );

    const enableWhatsapp = () => {
        callMutation().catch(handleApolloError);
    };

    return [enableWhatsapp, loading] as const;
};
