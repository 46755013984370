import React, { useCallback } from 'react';

import { ClickableText } from '~/@components/ClickableText';
import { showCustomDialog } from '~/@store/common';
import { ILandfill } from '~/@store/subareas';
import { LandfillLocation } from '~/@views/AdminView/SubareasPage/LandfillLocation';

type LandfillLocationCellProps = {
    landfill: ILandfill;
};

export const LandfillLocationCell = ({ landfill }: LandfillLocationCellProps) => {
    const showLocation = useCallback(() => {
        const ignoredPromise = showCustomDialog({
            render: closeDialog => <LandfillLocation closeDialog={closeDialog} landfill={landfill} />,
        });
    }, [landfill]);

    return <ClickableText onClick={showLocation}>{landfill.location.text}</ClickableText>;
};
