import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { ProjectRemoveUser, ProjectRemoveUserVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import { ProjectRemoveUserMutation } from '../projects.queries';

export const useProjectRemoveUser = () => {
    const [callMutation, { loading }] = useMutation<ProjectRemoveUser, ProjectRemoveUserVariables>(
        ProjectRemoveUserMutation
    );

    const removeUser = useCallback(
        async (projectId: string, userId: string): Promise<unknown> => {
            const variables: ProjectRemoveUserVariables = { projectId, userId };

            return callMutation({ variables }).catch(handleApolloError);
        },
        [callMutation]
    );

    return [removeUser, loading] as const;
};
