import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { KeyboardEventHandler, useState } from 'react';

import { useProgressState } from '~/@components/@hooks/use-progress-state';
import { TextField } from '~/@components/TextField';
import { ENTER_KEY_NAME, ESC_KEY_NAME } from '~/@store/common';
import { PopupEditDialog } from '~/@views/AdminView/common/PopupEditFields/PopupEditDialog';
import i18n from '~/i18n';

type Props = {
    label: React.ReactNode;
    initialValue: string;
    onSubmit: (value: string) => Promise<unknown>;
    onClose: (v: boolean) => void;
    validation?: (v: string) => boolean;
    placeholder?: string;
    nullable?: boolean;
};

export const PopupTextEdit = ({
    label,
    initialValue,
    onClose,
    validation,
    onSubmit,
    placeholder,
    nullable = false,
}: Props) => {
    const { root, textField } = useStyles();

    const [value, setValue] = useState(initialValue);

    const [isLoading, submit] = useProgressState(onSubmit);

    const canSubmit = validation ? validation(value) : true;

    const handleSubmit = () => {
        submit(value.trim()).then(() => onClose(true));
    };

    const handleClear = () => {
        submit('').then(() => onClose(true));
    };

    const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = event => {
        if (event.key === ENTER_KEY_NAME && canSubmit) handleSubmit();
        if (event.key === ESC_KEY_NAME) onClose(false);
    };

    return (
        <PopupEditDialog onClose={onClose}>
            <div className={root}>
                <TextField
                    autoFocus
                    label={label}
                    value={value}
                    onKeyPress={handleKeyPress}
                    onChange={e => setValue(e.target.value)}
                    className={textField}
                    disabled={isLoading}
                    placeholder={placeholder}
                />
                <Button disabled={isLoading || !canSubmit} variant="contained" color="primary" onClick={handleSubmit}>
                    {i18n.save}
                </Button>
                {nullable && (
                    <Button disabled={isLoading} variant="contained" color="secondary" onClick={handleClear}>
                        {i18n.clear}
                    </Button>
                )}
            </div>
        </PopupEditDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        gap: theme.spacing(),
    },
    textField: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));
