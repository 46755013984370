import React from 'react';

import { isPdf } from '../../../../common/utils';
import { ImgPreview } from './ImgPreview';
import { PdfPreview } from './PdfPreview';

type Props = {
    base64: string;
    filename: string;
};

export const FilePreview = ({ base64, filename }: Props) =>
    isPdf(filename) ? (
        <PdfPreview base64src={base64} filename={filename} />
    ) : (
        <ImgPreview imageSrc={base64} filename={filename} />
    );
