import { isNullOrUndefined } from '@common/validations/types.validation';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { COLORS } from '~/@components/@theme/colors';
import { Chip } from '~/@components/Chip';
import { removeEmptyKeys } from '~/@store/common';
import { UserField, usersStore } from '~/@store/users';
import { formatFilterValue } from '~/@views/AdminView/common/utils';
import i18n from '~/i18n';

const FieldNames: Record<UserField, string> = {
    [UserField.email]: i18n.UsersTable.email,
    [UserField.name]: i18n.UsersTable.name,
    [UserField.surname]: i18n.UsersTable.lastName,
    [UserField.phoneNumber]: i18n.UsersTable.phone,
    [UserField.status]: i18n.UsersTable.status,
    [UserField.roles]: i18n.UsersTable.role,
    [UserField.createdAt]: i18n.UsersTable.created,
    [UserField.customerName]: i18n.UsersTable.customer,
    [UserField.lastActivityDate]: i18n.UsersTable.lastActivityDate,
    [UserField.deletedAt]: i18n.UsersTable.deletedAt,
};

export const UserFilterSummary = () => {
    const filterValues = useObservable(() => removeEmptyKeys({ ...usersStore.filter.values }));
    const { root, chip } = useStyles();

    return (
        <div className={root}>
            {Object.keys(filterValues).map(objectKey => {
                const key = objectKey as UserField;
                const value = filterValues[key];
                if (isNullOrUndefined(value)) return null;

                const chipContent = `${FieldNames[key]}: "${formatFilterValue(value)}"`;

                return (
                    <Chip
                        key={key}
                        className={chip}
                        color={COLORS.black}
                        onDelete={() => usersStore.filter.setValue(key, null)}
                        deleteIcon={<Icon>close</Icon>}>
                        {chipContent}
                    </Chip>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        marginRight: theme.spacing(1),
    },
}));
