import { sortBy } from 'lodash';
import React, { useMemo, VFC } from 'react';

import { ReorderingList } from '~/@components/ReorderingList';
import type { IDumpType } from '~/@store/dumpTypes';
import { useDumpTypePermissions, useDumpTypeUpdatePositionMutation } from '~/@store/dumpTypes';

import { DumpTypeCard } from './DumpTypeCard';

type DumpTypesListProps = {
    data: IDumpType[];
    selectItem: (id: string) => void;
    selectedItemId?: string | null;
};

export const DumpTypesList: VFC<DumpTypesListProps> = ({ data = [], selectItem, selectedItemId }) => {
    const [updatePositions, isPositionsUpdating] = useDumpTypeUpdatePositionMutation();
    const items = useMemo(() => sortBy(data, d => d.position), [data]);

    const { canUpdate } = useDumpTypePermissions();

    return (
        <ReorderingList<IDumpType>
            items={items}
            onOrderChange={updatePositions}
            disabled={isPositionsUpdating || !canUpdate}>
            {(dumpType, id, reorderingButton) => (
                <DumpTypeCard
                    key={id}
                    dumpType={dumpType}
                    isActive={selectedItemId === id}
                    select={selectItem}
                    reorderingButton={reorderingButton}
                />
            )}
        </ReorderingList>
    );
};
