import { LinearProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';

import { Button } from '~/@components/Button';
import { FileUploadButton } from '~/@components/FileUploadButton';
import { DumpLoadStatusFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { IDetailedDumpLoad } from '~/@store/dumpLoads';
import { IDetailedProject, useAnalysisFileUploadMutation, useProjectAbilities } from '~/@store/projects';
import { showDumpLoadEditAnalysisDialog } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditAnalysisDialog';
import { showDumpLoadWasteDeclarationDialog } from '~/@views/AdminView/DumpLoadsPage/DumpLoadEditDeclarationDialog';
import { LoadStatus } from '~/graphql';
import i18n from '~/i18n';

type Props = {
    project: IDetailedProject;
};

export const ProjectDumpLoadsDocuments = ({ project }: Props) => {
    const dumpLoadsToShow: IDetailedDumpLoad[] = project.dumpLoads
        .filter(d => d.status !== LoadStatus.DISCARDED)
        .map(d => ({ ...d, project }));

    const { canUploadDocument } = useProjectAbilities(project);

    const [uploadAnalysisFile, isUploading] = useAnalysisFileUploadMutation();
    const handleUploadAnalysisFile = (dumpLoadId: string) => (file: File) =>
        uploadAnalysisFile(project.id, dumpLoadId, file);

    const handleShowAnalysisFiles = (dumpLoadId: string) => () =>
        showDumpLoadEditAnalysisDialog(project.id, dumpLoadId);

    if (!dumpLoadsToShow.length) return <Text>{<LocalizableText code={'noMasses'} />}</Text>;

    return (
        <>
            {isUploading && <LinearProgress />}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{<LocalizableText code={'DumpLoadsTable.orderNumber'} />}</TableCell>
                        <TableCell>{<LocalizableText code={'DumpLoadsTable.orderStatus'} />}</TableCell>
                        <TableCell>{<LocalizableText code={'declarationOfWaste'} />}</TableCell>
                        <TableCell>{<LocalizableText code={'DumpLoadsTable.toxicAnalysis'} />}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dumpLoadsToShow.map(d => (
                        <TableRow key={d.id}>
                            <TableCell>{d.serialNumber}</TableCell>
                            <TableCell>
                                <DumpLoadStatusFormatter>{d.status}</DumpLoadStatusFormatter>
                            </TableCell>
                            <TableCell>
                                <Button variant="contained" onClick={() => showDumpLoadWasteDeclarationDialog(d)}>
                                    {<LocalizableText code={'DumpLoadsTable.editWasteDeclaration'} />}
                                </Button>
                            </TableCell>
                            <TableCell>
                                {d.analysisFiles.length ? (
                                    <Button variant="contained" onClick={handleShowAnalysisFiles(d.id)}>
                                        {d.analysisFiles.length} {d.analysisFiles.length === 1 ? i18n.file : i18n.files}
                                    </Button>
                                ) : (
                                    <FileUploadButton
                                        variant="contained"
                                        onChange={handleUploadAnalysisFile(d.id)}
                                        disabled={!canUploadDocument || isUploading}>
                                        {i18n.addFile}
                                    </FileUploadButton>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};
