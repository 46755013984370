import React from 'react';

import { ReorderingList } from '~/@components/ReorderingList';
import { ISubstance, useSubstancePermissions, useSubstancesContext } from '~/@store/substances';

import { SubstanceCard } from './SubstanceCard';

export const SubstancesList = () => {
    const { substances, updatePositions, isPositionsUpdating } = useSubstancesContext();

    const { canUpdate } = useSubstancePermissions();

    return (
        <ReorderingList<ISubstance>
            items={substances}
            onOrderChange={updatePositions}
            disabled={isPositionsUpdating || !canUpdate}>
            {(substance, id, reorderButton) => (
                <SubstanceCard key={id} substance={substance} reorderButton={reorderButton} />
            )}
        </ReorderingList>
    );
};
