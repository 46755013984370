import { replaceRouteParams, routes } from '@common/routes';
import { IconButton, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router';

import { COLORS } from '~/@components/@theme';
import { LocalizableText } from '~/@components/LocalizableText';
import { COLOR } from '~/@sochi-components/@theme/styles';
import { ArrowDownwardIcon, ArrowLeftIcon, CloseCrossIcon } from '~/@sochi-components/Icons';
import { homePageMapStore } from '~/@user-store/homePageMap';
import { isOwnLandfill, isOwnProject } from '~/@user-store/homePageMap/homePageMap.utils';
import { useDeviceMedia } from '~/contexts';

import { LandfillInfoCard } from '../LandfillInfoCard';
import { ProjectCard } from '../ProjectCard';
import { RouteInfoCard } from '../RouteInfoCard';
import { LandfillDumpLoads } from './LandfillDumpLoads';
import { OwnLandfills } from './OwnLandfills';
import { ProjectDumpLoads } from './ProjectDumpLoads';

export const PoiInfoPanel: FC = observer(() => {
    const history = useHistory();

    const { root, closeButton, routeMessage, icon } = useStyles();

    const project = homePageMapStore.selectedProject;
    const landfill = homePageMapStore.selectedLandfill;
    const routeData = homePageMapStore.route?.routes[0]?.legs[0] || null;

    const onProjectOpen = useCallback(() => {
        if (project) {
            history.push(replaceRouteParams(routes.sochi.projectDetail, { projectId: project.id }));
        }
    }, [history, project]);

    const onLandfillOpen = useCallback(() => {
        if (landfill) {
            history.push(replaceRouteParams(routes.sochi.landfillDetail, { landfillId: landfill.id }));
        }
    }, [history, landfill]);

    const { isMobileOrTablet } = useDeviceMedia();

    if (!project && !landfill) return null;

    return (
        <div className={root}>
            <div className={closeButton}>
                <IconButton onClick={homePageMapStore.clearSelected}>
                    <CloseCrossIcon htmlColor={COLOR.gray3} />
                </IconButton>
            </div>

            {project ? (
                <>
                    <ProjectCard
                        project={project}
                        onOpen={isOwnProject(project) ? onProjectOpen : undefined}
                        isSelected={false}
                    />
                    <ProjectDumpLoads />
                    <OwnLandfills />
                </>
            ) : null}
            {routeData && (
                <RouteInfoCard distance={routeData.distance?.text || ''} duration={routeData.duration?.text || ''} />
            )}
            {landfill ? (
                <>
                    <LandfillInfoCard
                        landfill={landfill}
                        onOpen={isOwnLandfill(landfill) ? onLandfillOpen : undefined}
                    />
                    <LandfillDumpLoads />
                </>
            ) : null}
            {!routeData && (
                <div className={routeMessage}>
                    {isMobileOrTablet ? <ArrowDownwardIcon className={icon} /> : <ArrowLeftIcon className={icon} />}
                    <LocalizableText
                        code={project ? 'PoiInfoPanel.selectSiteOnTheMap' : 'PoiInfoPanel.selectProjectOnTheMap'}
                    />
                </div>
            )}
        </div>
    );
});

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        background: COLOR.grayLight3,
        fontFamily: 'Catamaran',
        borderRadius: '0 0 0 8px',
        position: 'absolute',
        right: 0,
        width: '420px',
        height: 'auto',
        maxHeight: 'calc(100% - 120px)',
        gap: '12px',
        padding: '12px',
        overflowY: 'scroll',
    },
    closeButton: {
        position: 'absolute',
        top: '16px',
        right: '16px',
    },
    routeMessage: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '18px',
        color: COLORS.grayDarkest,
        alignItems: 'center',
        gap: '8px',
    },
    icon: {
        color: COLORS.black,
    },
}));
