import { TransferType } from '@common/enums';

import { ISimpleBatch } from '~/@views/UserView/LandfillPage/common/LandfillBatchSelect';

export type IFormValues = {
    type: TransferType;
    date: Date;
    weight: number | null;
    fromBatch: ISimpleBatch | null;
    toBatch: ISimpleBatch | null;
    externalProjectName: string;
    externalProjectAddress: string;
    licensePlate: string;
    comment: string;
};

export enum FormNames {
    comment = 'comment',
    type = 'type',
    date = 'date',
    weight = 'weight',
    fromBatch = 'fromBatch',
    toBatch = 'toBatch',
    externalProjectName = 'externalProjectName',
    externalProjectAddress = 'externalProjectAddress',
    licensePlate = 'licensePlate',
}
