import { FilterSearchParams } from '@common/types';
import { Icon, IconButton, Input, InputAdornment, ListItem } from '@material-ui/core';
import React, { useCallback } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { Checkbox } from '~/@components/Checkbox';
import { LocalizableText } from '~/@components/LocalizableText';
import i18n from '~/i18n';

type Props = {
    valueGetter: () => FilterSearchParams<string> | null | void;
    setValue: (v: FilterSearchParams<string> | null) => void;
};

export const StringFilterWithEmptyOption = ({ valueGetter, setValue }: Props) => {
    const filter = useObservable(valueGetter);

    const handleFilterChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;

            setValue({
                value,
                includeEmpty: filter?.includeEmpty || false,
            });
        },
        [filter, setValue]
    );

    const handleOptionChange = useCallback(
        (newValue: boolean) => {
            setValue({
                value: filter?.value || null,
                includeEmpty: newValue,
            });
        },
        [filter, setValue]
    );

    const handleFilterClear = useCallback(() => {
        setValue(null);
    }, [setValue]);

    return (
        <>
            <ListItem>
                <Input
                    placeholder={i18n.search}
                    value={filter?.value || ''}
                    onChange={handleFilterChange}
                    disableUnderline
                    fullWidth
                    endAdornment={
                        <InputAdornment position="end">
                            {filter ? (
                                <IconButton onClick={handleFilterClear} size="small">
                                    <Icon color="secondary">close</Icon>
                                </IconButton>
                            ) : (
                                <Icon>search</Icon>
                            )}
                        </InputAdornment>
                    }
                />
            </ListItem>
            <ListItem>
                <Checkbox
                    onChange={handleOptionChange}
                    checked={filter?.includeEmpty || false}
                    label={<LocalizableText code={'StringFilter.useIncludeEmpty'} />}
                    disabled={!filter}
                />
            </ListItem>
        </>
    );
};
