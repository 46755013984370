import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { useLandfillDeviationRules, useProjectDeviationRules } from '~/@store/permissions';

import { PermissionsTable } from './PermissionsTable';

export const DeviationPermissions = () => {
    const projectDeviationRules = useProjectDeviationRules();
    const landfillDeviationRules = useLandfillDeviationRules();
    const extendedColumns = [
        {
            field: 'subject' as const,
            title: <LocalizableText code={'PermissionsPage.subject'} />,
        },
    ];

    return (
        <>
            <PermissionsTable
                title={<LocalizableText code={'PermissionsPage.projectDeviations'} />}
                rules={projectDeviationRules}
                extendedColumns={extendedColumns}
            />
            <br />
            <PermissionsTable
                title={<LocalizableText code={'PermissionsPage.landfillDeviations'} />}
                rules={landfillDeviationRules}
                extendedColumns={extendedColumns}
            />
        </>
    );
};
