import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { TextField } from '~/@components/TextField';
import { IDumpLoadProject } from '~/@store/dumpLoads';
import { showProjectJoinCodeDialog } from '~/@views/AdminView/common/ProjectJoinCodeForm/ProjectJoinCodeForm';
import i18n from '~/i18n';
import { formatDate } from '~/utils/date';

type Props = {
    project: IDumpLoadProject;
};

export const ProjectShortInfo = ({ project }: Props) => {
    const { root } = useStyles();

    return (
        <div className={root}>
            <Text variant="BodyBold">
                <LocalizableText code={'projectInfo'} />
            </Text>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label={<LocalizableText code={'ProjectsTable.location'} />}
                        value={project.location.text}
                        disabled
                        copyable
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={<LocalizableText code={'ProjectsTable.customer'} />}
                        value={project.customer?.name || i18n.NA}
                        disabled
                        copyable
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={<LocalizableText code={'ProjectsTable.comment'} />}
                        value={project.comment}
                        disabled
                        copyable
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label={<LocalizableText code={'ProjectForm.startAndEndDates'} />}
                        value={`${formatDate(project.startDate)} / ${formatDate(project.endDate)}`}
                        disabled
                        copyable
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        label={<LocalizableText code={'ProjectForm.joinCode'} />}
                        value={project.joinCode}
                        disabled
                        copyable
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button fullWidth onClick={() => showProjectJoinCodeDialog(project)} variant="contained">
                        <LocalizableText code={'download'} />
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));
