import { QueryResult, useQuery } from '@apollo/client';

import {
    LandfillDeliveryEvents,
    LandfillDeliveryEvents_landfillEvents,
    LandfillDeliveryEventsVariables,
} from '~/graphql';

import { LandfillDeliveryEventsQuery } from './landfillDeliveryEvents.queries';

export type IDeliveryEvent = LandfillDeliveryEvents_landfillEvents;

export const useDeliveryEvents = (
    landfillId: string
): QueryResult<LandfillDeliveryEvents, LandfillDeliveryEventsVariables> => {
    return useQuery<LandfillDeliveryEvents, LandfillDeliveryEventsVariables>(LandfillDeliveryEventsQuery, {
        variables: { landfillId },
    });
};
