import { makeStyles } from '@material-ui/core';
import React from 'react';

import { SimpleDialog } from '~/@components/Dialog';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { IDetailedCompany, useDetailedCompany } from '~/@store/companies';

import { CompanyCommentsView } from './CompanyCommentsView';

type Props = {
    companyId: string;
    onClose: (v: boolean) => void;
};

export const CompanyCommentsDialog = ({ onClose, companyId }: Props) => {
    const query = useDetailedCompany(companyId);
    const { commentsView } = useStyles();

    return (
        <SimpleDialog onClose={() => onClose(true)}>
            <QueryStateHandler query={query} dataGetter={query => query.data?.company || undefined}>
                {(company: IDetailedCompany) => {
                    return (
                        <CompanyCommentsView
                            className={commentsView}
                            comments={company.comments || []}
                            companyId={company.id}
                        />
                    );
                }}
            </QueryStateHandler>
        </SimpleDialog>
    );
};

const useStyles = makeStyles(() => ({
    commentsView: {
        width: 420,
    },
}));
