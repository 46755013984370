import { UserContextRole } from '@common/enums';
import React from 'react';

import { Chip } from '~/@components/Chip';
import { USER_CONTEXT_ROLES } from '~/@store/users';
import i18n from '~/i18n';

type Props = { role: UserContextRole; onClick?: () => void };

export const UserContextRoleChip = ({ role, onClick }: Props) => {
    return (
        <Chip color={USER_CONTEXT_ROLES.get(role)!} onClick={onClick} variant="default">
            {i18n.UserContextRole[role]!}
        </Chip>
    );
};
