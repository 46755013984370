import React from 'react';

import { CardDialog } from '~/@components/Dialog';
import { showCustomDialog } from '~/@store/common';
import { ILandfillSubarea } from '~/@store/landfills';
import i18n from '~/i18n';

import { ILandfill } from './SubareaEdit.types';
import SubareaEditForm from './SubareaEditForm';

type Props = {
    onClose: () => void;
    landfill: ILandfill;
    subarea: ILandfillSubarea;
};

export const SubareaUpdateDialog = ({ onClose, landfill, subarea }: Props) => {
    return (
        <CardDialog
            title={`${i18n.LandfillPage.editSubarea} ${subarea.externalId} ${
                subarea.isProvider ? i18n.Materials.isProvider : ''
            }`}
            onClose={onClose}>
            <SubareaEditForm landfill={landfill} onClose={onClose} subarea={subarea} withOnHold={true} />
        </CardDialog>
    );
};

export const showSubareaUpdateDialog = (landfill: ILandfill, subarea: ILandfillSubarea) =>
    showCustomDialog({
        render: close => <SubareaUpdateDialog onClose={() => close(false)} landfill={landfill} subarea={subarea} />,
    });
