import React, { useMemo } from 'react';

import { makeStyles } from '~/@components/@theme';
import {
    CheckFormatter,
    ContaminationLevelFormatter,
    CurrencyFormatter,
    DateTimeFormatter,
    NullableFormatter,
    PercentFormatter,
    TonnsFormatter,
} from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn } from '~/@components/Table';
import { ISubarea, SubareaStoreFilterFields } from '~/@store/subareas';
import { LandfillNameCell } from '~/@views/AdminView/LandfillsPage/LandfillsRowCells';
import i18n from '~/i18n';

import { LandfillLocationCell, SubareaReceiversCell } from './SubareasCells';
import { SubareasHeadCell } from './SubareasHeadCell';

export const useSubareasColumns = (): ITableColumn<ISubarea>[] => {
    const { name, location } = useStyles();

    return useMemo(
        () => [
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.landfillName'} />}
                        fieldName={SubareaStoreFilterFields.landfillName}
                        withFilter
                        withSort
                    />
                ),
                render: row => <LandfillNameCell landfill={row.landfill} />,
                cellClassName: name,
                name: 'name',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.landfillLocation'} />}
                        fieldName={SubareaStoreFilterFields.landfillLocation}
                        withFilter
                        withSort
                    />
                ),
                render: row => <LandfillLocationCell landfill={row.landfill} />,
                cellClassName: location,
                name: 'landfillLocation',
            },
            {
                title: <LocalizableText code={'SubareasTable.landfillReceivers'} />,
                render: row => <SubareaReceiversCell landfill={row.landfill} />,
                name: 'landfillReceivers',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.landfillStatus'} />}
                        fieldName={SubareaStoreFilterFields.landfillStatus}
                        withFilter
                    />
                ),
                render: row => (
                    <NullableFormatter>{row.landfill.status?.id ? row.landfill.status.name : null}</NullableFormatter>
                ),
                name: 'landfillStatus',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.landfillComment'} />}
                        fieldName={SubareaStoreFilterFields.landfillComment}
                        withFilter
                        withSort
                    />
                ),
                render: row => row.landfill.comment,
                name: 'landfillComment',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.isProvider'} />}
                        fieldName={SubareaStoreFilterFields.isProvider}
                        withFilter
                    />
                ),
                render: row => <CheckFormatter>{row.isProvider}</CheckFormatter>,
                name: 'isProvider',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.dumpType'} />}
                        fieldName={SubareaStoreFilterFields.dumpType}
                        withFilter
                    />
                ),
                render: row => <NullableFormatter>{row.dumpType?.name}</NullableFormatter>,
                name: 'dumpType',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.contaminationLevel'} />}
                        fieldName={SubareaStoreFilterFields.solidTestResult}
                        withFilter
                        withSort
                    />
                ),
                render: row => <ContaminationLevelFormatter>{row.solidTestResult}</ContaminationLevelFormatter>,
                name: 'solidTestResult',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.externalId'} />}
                        fieldName={SubareaStoreFilterFields.materialId}
                        withFilter
                        withSort
                    />
                ),
                render: row => row.externalId,
                name: 'externalId',
            },
            {
                title: <LocalizableText code={'SubareasTable.unitOfMeasure'} />,
                render: row => (row.unitOfMeasure && i18n.unitsOfMeasure[row.unitOfMeasure]) || i18n.NA,
                name: 'unitOfMeasure',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.comment'} />}
                        fieldName={SubareaStoreFilterFields.comment}
                        withFilter
                        withSort
                    />
                ),
                render: row => row.comment,
                name: 'comment',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.nonStackable'} />}
                        fieldName={SubareaStoreFilterFields.nonStackable}
                        withFilter
                    />
                ),
                render: row => <CheckFormatter>{!row.stackable}</CheckFormatter>,
                name: 'nonStackable',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.over50'} />}
                        fieldName={SubareaStoreFilterFields.over50cm}
                        withFilter
                    />
                ),
                render: row => <CheckFormatter>{row.over50cm}</CheckFormatter>,
                name: 'over50',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.currentAmount'} />}
                        fieldName={SubareaStoreFilterFields.currentAmount}
                        withFilter
                        withSort
                    />
                ),
                render: row => <TonnsFormatter>{row.currentAmount}</TonnsFormatter>,
                name: 'currentAmount',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.landfillGateFee'} />}
                        fieldName={SubareaStoreFilterFields.landfillGateFee}
                        withFilter
                        withSort
                    />
                ),
                render: row => <CurrencyFormatter>{row.landfill.gateFee}</CurrencyFormatter>,
                name: 'landfillGateFee',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.landfillMinMargin'} />}
                        fieldName={SubareaStoreFilterFields.landfillMinMargin}
                        withFilter
                        withSort
                    />
                ),
                render: row => <CurrencyFormatter>{row.landfill.minMargin}</CurrencyFormatter>,
                name: 'landfillMinMargin',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.landfillMarginPercent'} />}
                        fieldName={SubareaStoreFilterFields.landfillMarginPercent}
                        withFilter
                        withSort
                    />
                ),
                render: row => <PercentFormatter>{row.landfill.marginPercent}</PercentFormatter>,
                name: 'landfillMarginPercent',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.priceOver50cm'} />}
                        fieldName={SubareaStoreFilterFields.priceOver50cm}
                        withFilter
                        withSort
                    />
                ),
                render: row => <CurrencyFormatter>{row.priceOver50cm}</CurrencyFormatter>,
                name: 'priceOver50cm',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.priceNotStackable'} />}
                        fieldName={SubareaStoreFilterFields.priceNotStackable}
                        withFilter
                        withSort
                    />
                ),
                render: row => <CurrencyFormatter>{row.priceNotStackable}</CurrencyFormatter>,
                name: 'priceNotStackable',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.priceBase'} />}
                        fieldName={SubareaStoreFilterFields.priceBase}
                        withFilter
                        withSort
                    />
                ),
                render: row => <CurrencyFormatter>{row.priceBase}</CurrencyFormatter>,
                name: 'priceBase',
            },
            {
                title: <LocalizableText code={'SubareasTable.grossPriceBase'} />,
                render: row => <CurrencyFormatter>{row.grossPriceBase}</CurrencyFormatter>,
                name: 'grossPriceBase',
            },
            {
                title: (
                    <SubareasHeadCell
                        title={<LocalizableText code={'SubareasTable.priceUpdatedAt'} />}
                        fieldName={SubareaStoreFilterFields.priceUpdatedAt}
                        withFilter
                        withSort
                    />
                ),
                render: row => <DateTimeFormatter>{row.priceUpdatedAt}</DateTimeFormatter>,
                name: 'priceUpdatedAt',
            },
        ],
        [name, location]
    );
};

const useStyles = makeStyles(() => ({
    name: {
        maxWidth: 300,
    },
    location: {
        maxWidth: 300,
    },
}));
