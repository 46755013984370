import { COLORS } from '~/@components/@theme/colors';
import { ProjectStatus } from '~/graphql';
import i18n from '~/i18n';

import { ProjectFields } from '../../../../common/enums';
import { PROJECT_VIEW_MODE } from '../../../../common/viewModes';
import { IColoredStatus } from '../common';

export { PROJECT_VIEW_MODE };

export const DEFAULT_PROJECT_SORT = {
    field: ProjectFields.name,
    direction: 1,
};

export const PROJECT_STATUSES: Array<IColoredStatus<ProjectStatus>> = [
    {
        color: COLORS.brandRed,
        title: i18n.ProjectStatus.NEW,
        value: ProjectStatus.NEW,
    },
    {
        color: COLORS.blue,
        title: i18n.ProjectStatus.IN_PROGRESS,
        value: ProjectStatus.IN_PROGRESS,
    },
    {
        color: COLORS.gray,
        title: i18n.ProjectStatus.DONE,
        value: ProjectStatus.DONE,
    },
    {
        color: COLORS.gray,
        title: i18n.ProjectStatus.DELETED,
        value: ProjectStatus.DELETED,
    },
];

export const ACTIVE_PROJECT_STATUSES = [ProjectStatus.NEW, ProjectStatus.IN_PROGRESS];

export const ALL_PROJECT_STATUSES = [...ACTIVE_PROJECT_STATUSES, ProjectStatus.DONE, ProjectStatus.DELETED];

export enum PROJECT_TABS {
    documentation = 'documentation',
}
