import React, { useState, VFC } from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { Select } from '~/@components/Select';
import { useFunctionalBem } from '~/@sochi-components/@bem';
import { ButtonGroup } from '~/@sochi-components/ButtonGroup';
import { DialogContent } from '~/@sochi-components/SochiDialog/DialogContent';
import type { ProjectQuery_project } from '~/graphql';
import i18n from '~/i18n';
import { showCustomDialog } from '~/services/dialog';

import { AnalysisFileUploader } from './AnalysisFileUploader';

type IProject = ProjectQuery_project;
type IDumpLoad = IProject['dumpLoads'][number];

function getDumpLoadById(dumpLoads: IDumpLoad[], dumpLoadId?: string): IDumpLoad | null {
    return dumpLoads.find((d: IDumpLoad) => d.id === dumpLoadId) || dumpLoads[0] || null;
}

type AnalysisDialogProps = {
    closeDialog: () => void;
    project: IProject;
    dumpLoadId?: string;
};

const getVisibleValue = (item: IDumpLoad, items: IDumpLoad[]) => {
    const index = items.findIndex(d => d.id === item.id);

    return `${index + 1}. ${item.dumpType?.name || i18n.NA} (${
        item.status ? i18n.DumploadStatusesSochi[item.status] : ''
    })`;
};

export const AnalysisDialog: VFC<AnalysisDialogProps> = ({ project, dumpLoadId, closeDialog }) => {
    const { className } = useFunctionalBem(AnalysisDialog);
    const [selectedDumpLoad, setSelectedDumpLoad] = useState(getDumpLoadById(project.dumpLoads, dumpLoadId));

    return (
        <DialogContent className={className}>
            <Select
                label={<LocalizableText code={'ProjectDocuments.weightReceiptTable.mass'} />}
                items={project.dumpLoads}
                value={selectedDumpLoad}
                onChange={d => setSelectedDumpLoad(d)}
                keyGetter={d => d!.id}
                renderValue={d => getVisibleValue(d, project.dumpLoads)}
                secondaryLabel={d => (d.comment ? d.comment : '')}
                addEmptyOption={false}
            />
            <ButtonGroup>
                <Button color="primary" variant="outlined" size="large" onClick={closeDialog}>
                    {i18n.cancel}
                </Button>
                <AnalysisFileUploader
                    project={project}
                    dumpLoadId={selectedDumpLoad?.id}
                    canUpload={Boolean(selectedDumpLoad)}
                    onUploadFinish={closeDialog}>
                    {onClick => (
                        <Button color="primary" variant="contained" size="large" onClick={onClick}>
                            <LocalizableText code={'upload'} />
                        </Button>
                    )}
                </AnalysisFileUploader>
            </ButtonGroup>
        </DialogContent>
    );
};

export const showAddAnalysisDialog = (project: IProject, dumpLoadId?: string) => {
    const ignoredPromise = showCustomDialog({
        title: <LocalizableText code={'ProjectDocuments.addAnalysis'} />,
        render: closeDialog => <AnalysisDialog closeDialog={closeDialog} project={project} dumpLoadId={dumpLoadId} />,
    });
};
