import React from 'react';

import { CardList } from '~/@components/CardList';
import { ILandfillStatus } from '~/@store/landfillStatuses';

import { LandfillStatusCard } from './LandfillStatusCard';

type Props = {
    statuses: ILandfillStatus[];
    selectedId: string | null;
    onSelect: (id: string) => void;
};

export const LandfillStatusesList = ({ statuses, selectedId, onSelect }: Props) => {
    return (
        <CardList>
            {statuses.map(status => (
                <LandfillStatusCard
                    key={status.id}
                    status={status}
                    isActive={selectedId === status.id}
                    onSelect={() => onSelect(status.id)}
                />
            ))}
        </CardList>
    );
};
