import { FormikConfig } from 'formik';
import * as Yup from 'yup';

import { IBeastCreateInput, IBeastMaterial, IBeastUpdateInput } from '~/@store/beastMaterials';
import i18n from '~/i18n';

export type IBeastMaterialFormValues = {
    beastId: string;
    name: string;
};

type CreateFunc = (values: IBeastCreateInput) => Promise<unknown>;

type UpdateFunc = (values: IBeastUpdateInput) => Promise<unknown>;

export const getBeastMaterialFormikConfig = (
    settings:
        | {
              material: IBeastMaterial;
              saveFunc: UpdateFunc;
          }
        | {
              material: null;
              saveFunc: CreateFunc;
          },
    onSuccessSubmit: () => void
): FormikConfig<IBeastMaterialFormValues> => {
    const material = settings.material;

    return {
        initialValues: {
            beastId: material?.beastId || '',
            name: material?.name || '',
        },
        validationSchema: Yup.object().shape({
            beastId: Yup.string().trim().required(i18n.errorCodes.BEAST_MATERIAL_ID_CANNOT_BE_EMPTY),
            name: Yup.string().trim().required(i18n.errorCodes.BEAST_MATERIAL_NAME_CANNOT_BE_EMPTY),
        }),
        onSubmit: async values => {
            await (material ? settings.saveFunc({ ...values, id: material.id }) : settings.saveFunc(values));
            onSuccessSubmit();
        },
    };
};
