import { LinearProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { SimpleDialog } from '~/@components/Dialog';

interface Props {
    showProgress?: boolean;
    onClose: (v: boolean) => void;
    children: React.ReactNode;
}

export const PopupEditDialog = ({ showProgress, onClose, children }: Props) => {
    const { root, linearProgress } = useStyles();

    return (
        <SimpleDialog onClose={() => onClose(false)} maxWidth="lg">
            <Paper className={root}>
                {showProgress && <LinearProgress className={linearProgress} />}
                {children}
            </Paper>
        </SimpleDialog>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        width: 600,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
    },
    linearProgress: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(3),
        right: theme.spacing(3),
    },
}));
