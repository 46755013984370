import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { ProjectQuery as IProjectQuery, ProjectQuery_project_dumpLoads, ProjectQueryVariables } from '~/graphql';

import { ProjectQuery } from '../projects.queries';

export type IDetailedProject = Exclude<IProjectQuery['project'], null>;

export type IDetailProjectDumpLoad = ProjectQuery_project_dumpLoads;

export const useDetailedProject = (projectId: string): QueryResult<IProjectQuery, ProjectQueryVariables> => {
    const variables: ProjectQueryVariables = { id: projectId };

    return useQuery<IProjectQuery, ProjectQueryVariables>(ProjectQuery, {
        fetchPolicy: 'cache-first',
        variables,
    });
};
