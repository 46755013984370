import { Icon, IconProps, SvgIconProps } from '@material-ui/core';
import React, { ComponentType } from 'react';

export { Icon } from '@material-ui/core';

export { default as EditIcon } from '@material-ui/icons/Edit';

export { default as CardIcon } from '@material-ui/icons/DescriptionOutlined';

export const CopyIcon = (props: IconProps) => <Icon {...props}>content_copy</Icon>;

export const LogoutIcon = (props: IconProps) => <Icon {...props}>logout</Icon>;

export { default as ArrowDropDownIcon } from '@material-ui/icons/ArrowDropDown';

export { default as QuestionIcon } from '@material-ui/icons/Help';

export { default as ExclamationIcon } from '@material-ui/icons/Error';

export { default as LinkIcon } from '@material-ui/icons/OpenInNewOutlined';

export { default as MoreVertIcon } from '@material-ui/icons/MoreVert';

export { default as KeyboardArrowDownIcon } from '@material-ui/icons/KeyboardArrowDown';

export { default as CheckIcon } from '@material-ui/icons/Check';

export { default as MinusIcon } from '@material-ui/icons/Remove';

export { default as DeleteIcon } from '@material-ui/icons/Delete';

export { default as CloseIcon } from '@material-ui/icons/Close';

export { default as SubArrowIcon } from '@material-ui/icons/SubdirectoryArrowRight';

export { default as ErrorOutlineIcon } from '@material-ui/icons/ErrorOutline';

export { default as SettingsIconOutlined } from '@material-ui/icons/SettingsOutlined';

export { default as CalendarIcon } from '@material-ui/icons/CalendarTodayOutlined';

export { default as MenuRounded } from '@material-ui/icons/MenuRounded';

export { default as MenuIcon } from '@material-ui/icons/Menu';

export { default as AddIcon } from '@material-ui/icons/Add';

export { default as ListIcon } from '@material-ui/icons/FormatListBulleted';

export { default as DoneIcon } from '@material-ui/icons/Done';

export { default as BlockIcon } from '@material-ui/icons/Block';

export { default as DownloadIcon } from '@material-ui/icons/GetAppRounded';

export { default as InfoIconOutlined } from '@material-ui/icons/InfoOutlined';

export { default as HistoryIcon } from '@material-ui/icons/AccessTime';

export { default as HelpIcon } from '@material-ui/icons/Help';

export { default as ArrowForwardIcon } from '@material-ui/icons/ArrowForwardIos';

export { default as RestoreIcon } from '@material-ui/icons/RestoreFromTrash';

export { default as PermissionsIcon } from '@material-ui/icons/LockOutlined';

export { default as SearchIcon } from '@material-ui/icons/Search';

export { default as CloudUploadIcon } from '@material-ui/icons/CloudUpload';

export { default as RotateLeftIcon } from '@material-ui/icons/RotateLeft';

export { default as RotateRightIcon } from '@material-ui/icons/RotateRight';

export { default as ZoomInIcon } from '@material-ui/icons/ZoomIn';

export { default as ZoomOutIcon } from '@material-ui/icons/ZoomOut';

export { default as MailIcon } from '@material-ui/icons/EmailOutlined';

export { default as CommentIcon } from '@material-ui/icons/ChatOutlined';

export { default as TranslateIcon } from '@material-ui/icons/Translate';

export { default as VisibilityIcon } from '@material-ui/icons/Visibility';

export { default as VisibilityOffIcon } from '@material-ui/icons/VisibilityOff';

export { default as ProjectCodeIcon } from '@material-ui/icons/CropFree';

export { default as NoMatchingIcon } from '@material-ui/icons/LocationOffOutlined';

export { default as InboundIcon } from '@material-ui/icons/InputOutlined';

export { default as TruckIcon } from '@material-ui/icons/LocalShippingOutlined';

export { default as InputIcon } from '@material-ui/icons/InputOutlined';

export { default as LaunchIcon } from '@material-ui/icons/LaunchOutlined';

export { default as SwapHoriz } from '@material-ui/icons/SwapHorizOutlined';

export * from './ExcelIcon';

export * from './PdfIcon';

export * from './SendIcon';

export * from './RecycleIcon';

export * from './PinIcon';

export * from './BoxIcon';

export * from './CornerArrowIcon';

export * from './DoneAllIcon';

export * from './CancelIcon';

export * from './AccountIconOutlined';

export * from './ToxicLimitsIcon';

export * from './DocumentStrokeIcon';

export * from './CompanyIcon';

export * from './CustomSearchIcon';

export * from './DropDownIcon';

export * from './ArrowRightIcon';

export * from './WhatsappIcon';

export * from './TimeSpentIcon';

export type CommonIconComponent = ComponentType<IconProps> | ComponentType<SvgIconProps>;

export { default as LaptopIcon } from '@material-ui/icons/Laptop';
