import React, { useCallback } from 'react';

import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { showCustomDialog } from '~/@store/common';
import { IDetailedLandfill, useLandfillCreateMutation } from '~/@store/landfills';
import { landfillDetailRoute } from '~/@store/routes';
import browserHistory from '~/browserHistory';

import { LandfillEditForm } from './LandfillEditForm';

type Props = {
    onClose: () => void;
    defaultValues?: IDetailedLandfill | null;
    onCreate: (landfill: IDetailedLandfill) => void;
};

const LandfillCreateDialog = ({ onClose, defaultValues = null, onCreate }: Props) => {
    const [create] = useLandfillCreateMutation();

    const onComplete = useCallback(
        (landfill: IDetailedLandfill) => {
            onClose();
            onCreate(landfill);
        },
        [onCreate, onClose]
    );

    return (
        <CardDialog
            title={<LocalizableText code={'LandfillForm.createLandfill'} />}
            maxWidth="lg"
            fullWidth
            onClose={onClose}>
            <LandfillEditForm landfill={defaultValues} onClose={onClose} submit={create} onComplete={onComplete} />
        </CardDialog>
    );
};

export const showLandfillCreateDialog = () => showLandfillCreateDialogDefaultValues();

export const showLandfillCreateDialogDefaultValues = (
    defaultValues?: IDetailedLandfill,
    onCreate: (landfill: IDetailedLandfill) => void = landfill => {
        browserHistory.push(landfillDetailRoute(landfill.id));
    }
) =>
    showCustomDialog({
        render: closeDialog => (
            <LandfillCreateDialog
                onClose={() => closeDialog(false)}
                defaultValues={defaultValues}
                onCreate={onCreate}
            />
        ),
    });
