import React, { useMemo } from 'react';

import { NullableFormatter } from '~/@components/Formatters';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { IDumpLoad } from '~/@store/dumpLoads';
import { landfillDetailRoute } from '~/@store/routes';
import i18n from '~/i18n';

type Props = {
    dumpLoad: IDumpLoad;
};

export const DumpLoadReceiverCell = ({ dumpLoad }: Props) => {
    const actions: Array<ITableCellAction> = useMemo(() => {
        const actions: Array<ITableCellAction> = [
            {
                variant: ActionVariant.LINK,
                to: dumpLoad.landfill && landfillDetailRoute(dumpLoad.landfill.id),
                targetBlank: true,
                disabled: !dumpLoad.landfill,
                disabledReason: i18n.DumpLoadsTable.noDestinationMessage,
            },
        ];

        return actions;
    }, [dumpLoad]);

    return (
        <TableCellActions actions={actions}>
            <NullableFormatter>{dumpLoad.landfill?.name}</NullableFormatter>
        </TableCellActions>
    );
};
