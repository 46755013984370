import './ConfirmEmailPage.scss';

import { ErrorCodes } from '@common/enums';
import { replaceRouteParams, routes } from '@common/routes';
import axios, { AxiosError } from 'axios';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import { confirmEmailUri } from '~/apolloClient';
import i18n from '~/i18n';
import { FrontApiError } from '~/utils/error';

import bg from '../assets/utrustning_page.jpg';

type Props = RouteComponentProps<{ id: string }>;

export const ConfirmEmailPage = ({ match }: Props) => {
    const { className, element } = useFunctionalBem(ConfirmEmailPage);

    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const setFailure = useCallback((msg: string) => {
        setError(msg);
        setIsLoading(false);
    }, []);

    const setSuccess = useCallback(() => {
        setIsConfirmed(true);
        setIsLoading(false);
    }, []);

    const handleError = useCallback(
        (error: AxiosError) => {
            const apiError = FrontApiError.fromDto(error.response?.data);

            switch (apiError.code) {
                case ErrorCodes.USER_NOT_FOUND:
                    return setFailure(i18n.ConfirmEmailPage.notFound);
                case ErrorCodes.LINK_EXPIRED:
                    return setFailure(i18n.ConfirmEmailPage.linkExpired);
                default:
                    return setFailure(i18n.ConfirmEmailPage.error);
            }
        },
        [setFailure]
    );

    useEffect(() => {
        if (isLoading || isConfirmed || error) return;

        const id = match.params.id;

        if (!id) return setSuccess();

        setIsLoading(true);

        const url = replaceRouteParams(confirmEmailUri, { link: id });
        axios.get(url).then(setSuccess).catch(handleError);
    }, [match.params.id, isConfirmed, isLoading, error, setSuccess, setFailure, handleError]);

    return (
        <div className={className}>
            <img className={element('background')} src={bg} alt="" />

            <div className={element('confirm-email-container')}>
                <div className={element('confirm-email-panel')}>
                    {isLoading && <p>{i18n.loading}</p>}
                    {error && (
                        <Fragment>
                            <p>{error}</p>

                            <Link to={routes.signup}>{i18n.signup}</Link>
                        </Fragment>
                    )}
                    {isConfirmed && (
                        <Fragment>
                            <p>{i18n.ConfirmEmailPage.complete}</p>

                            <Link to={routes.login}>{i18n.login}</Link>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};
