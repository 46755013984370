import React from 'react';

import { Header } from '~/@components/Header';
import { LocalizableText } from '~/@components/LocalizableText';
import { IMenuGroup, IMenuRoute, useGoHome } from '~/@store/routes';

interface Props {
    groups: IMenuGroup[];
    currentGroup: IMenuGroup | null;
    currentRoute: IMenuRoute | null;
}

export const AdminHeader = ({ groups, currentGroup, currentRoute }: Props) => {
    const goHome = useGoHome();

    return (
        <Header
            title={<LocalizableText code={'app.name.pinpointer'} />}
            exitTitle={<LocalizableText code={'Admin.exit'} />}
            onExit={goHome}
            groups={groups}
            currentGroup={currentGroup}
            currentRoute={currentRoute}
            homeRoute="/"
        />
    );
};
