import { VehicleKind } from '@common/enums';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { FormikSelectField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { VehicleType } from '~/@store/vehicleTypes';
import { VehicleTypeSelectQuery } from '~/@views/AdminView/common/VehicleTypeSelectQuery';
import { useDeliveryLineFormikContext } from '~/@views/AdminView/DeliveryLinesPage/DeliveryLineEditForm/DeliveryLineFormikCommon';

type Props = { disabled?: boolean };

export const DeliveryLineVehicleTypeSelect = ({ disabled }: Props) => {
    const formik = useDeliveryLineFormikContext();

    const { item } = useStyles();

    const getValue = (vehicleType: VehicleType) => `${vehicleType.name}`;

    return (
        <VehicleTypeSelectQuery>
            {(vehicleTypes: VehicleType[]) => {
                return (
                    <FormikSelectField
                        label={<LocalizableText code={'DeliveryLineCard.vehicleType'} />}
                        name={'vehicleType'}
                        formik={formik}
                        items={vehicleTypes.filter(t => t.kind === VehicleKind.TRANSPORT)}
                        renderValue={getValue}
                        keyGetter={(vehicleType: VehicleType) => vehicleType.id}
                        addEmptyOption={false}
                        disabled={disabled}
                        valueClassName={item}
                        valueCaption={getValue}
                    />
                );
            }}
        </VehicleTypeSelectQuery>
    );
};

const useStyles = makeStyles(() => ({
    item: {
        maxWidth: 600,
    },
}));
