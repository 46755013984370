import { ProjectContextRolesToUserRoles } from '@common/constants';
import { UserContextRole } from '@common/enums';
import { LinearProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { AutocompleteAsync } from '~/@components/Autocomplete';
import { Button } from '~/@components/Button';
import { SimpleDialog } from '~/@components/Dialog';
import { Select } from '~/@components/Select';
import { showCustomDialog } from '~/@store/common';
import { IDetailedProject } from '~/@store/projects';
import { IUserSearchRecord, useAddUserToProject, useUsersSearchFunc } from '~/@store/users';
import i18n from '~/i18n';
import { getUserFullName } from '~/utils/user';

const roleOptions = Object.keys(ProjectContextRolesToUserRoles) as UserContextRole[];

type DialogProps = {
    project: IDetailedProject;
    closeDialog: () => void;
};

const ProjectDetailAddUserDialog = ({ project, closeDialog }: DialogProps) => {
    const { root, buttons } = useStyles();
    const [addUser, isAdding] = useAddUserToProject();
    const [role, setRole] = useState(UserContextRole.PROJECT_USER);
    const [user, setUser] = useState<IUserSearchRecord | null>(null);

    useEffect(() => {
        setUser(user => (user?.role !== ProjectContextRolesToUserRoles[role] ? null : user));
    }, [role]);

    const rawSearch = useUsersSearchFunc({ roles: [ProjectContextRolesToUserRoles[role]!] });
    const projectUserIds = useMemo(() => new Set(project.userLinks.map(l => l.userId)), [project]);

    const searchFunc: typeof rawSearch = useCallback(
        search => rawSearch(search).then(users => users.filter(u => !projectUserIds.has(u.id))),
        [rawSearch, projectUserIds]
    );

    const onSubmit = () => {
        if (!user) return;

        addUser(user.id, project.id, role, project.ver).then(() => closeDialog());
    };

    return (
        <SimpleDialog>
            <Paper className={root}>
                <Select
                    label={i18n.role}
                    items={roleOptions}
                    renderValue={role => i18n.UserContextRole[role]}
                    keyGetter={r => r}
                    value={role}
                    onChange={v => v && setRole(v)}
                    addEmptyOption={false}
                />
                <AutocompleteAsync
                    label={i18n.searchUser}
                    selectedOption={user}
                    onOptionSelect={setUser}
                    renderOption={u => `${getUserFullName(u)} (${u.email})`}
                    loadOptions={searchFunc}
                />
                {isAdding && <LinearProgress />}
                <div className={buttons}>
                    <Button variant="contained" onClick={closeDialog} disabled={isAdding}>
                        {i18n.cancel}
                    </Button>
                    <Button variant="contained" color="primary" disabled={!user || isAdding} onClick={onSubmit}>
                        {i18n.add}
                    </Button>
                </div>
            </Paper>
        </SimpleDialog>
    );
};

type Props = {
    project: IDetailedProject;
};

export const ProjectDetailAddUser = ({ project }: Props) => {
    const onClick = () =>
        showCustomDialog({
            render: closeDialog => <ProjectDetailAddUserDialog project={project} closeDialog={closeDialog} />,
        });

    return <ActionButton onClick={onClick}>{i18n.ProjectForm.addUser}</ActionButton>;
};

const useStyles = makeStyles(theme => ({
    root: {
        width: 600,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
    },
    buttons: {
        display: 'flex',
        gap: theme.spacing(2),
        justifyContent: 'flex-end',
    },
}));
