import { DumpLoadFields, LoadStatus, ProjectFields, ProjectStatus, UserContextRole, UserRole } from '../../enums';
import { IAbilityComplexSubject } from '../abilities.types';

export interface IProjectUserLink {
    userId: string;
    role: UserContextRole;
}

export interface IAbilityProject {
    customerId: string;
    ownerUserId?: string | null;
    userLinks: IProjectUserLink[];
    status: ProjectStatus;
}

export interface IAbilityDumpLoad {
    status: `${LoadStatus}` | null;
    destination?: {
        subareaId?: string | null | undefined;
        landfillId?: string | null | undefined;
    };
}

export type IProjectRelationRole = UserContextRole | UserRole;

export enum ProjectSubject {
    DUMP_LOAD_LIST = 'DUMP_LOAD_LIST',
    EXCAVATOR_DUMP_LOAD_LIST = 'EXCAVATOR_DUMP_LOAD_LIST',
    PROJECT_DOCUMENT = 'PROJECT_DOCUMENT',
    PROJECT_DATES_BEFORE_TODAY = 'PROJECT_DATES_BEFORE_TODAY',
    PROJECT_END_DATE_EXCLUSIVELY = 'PROJECT_END_DATE_EXCLUSIVELY',
    PROJECT_USERS = 'PROJECT_USERS',
    MAINTENANCE = 'MAINTENANCE',
    ALWAYS_EDITABLE_FIELDS = 'ALWAYS_EDITABLE_FIELDS',
    PRICE_CALCULATOR_PAGE = 'PRICE_CALCULATOR_PAGE',
    OFFERS = 'OFFERS',
    WHATSAPP = 'WHATSAPP',
}

export type IProjectSubject = `${ProjectFields}` | `${ProjectStatus}` | `${ProjectSubject}`;

export enum DumpLoadSubject {
    FIELD_AFFECT_DESTINATION = 'FIELD_AFFECT_DESTINATION',
    DUMP_LOAD_DECLARATION = 'DUMP_LOAD_DECLARATION',
    STATUS_EXCLUSIVELY = 'STATUS_EXCLUSIVELY',
    INTERNAL_PRICES = 'INTERNAL_PRICES',
}

export type IDumpLoadSubject = `${DumpLoadFields}` | `${LoadStatus}` | `${DumpLoadSubject}`;

export interface IProjectCalculatedSubject extends IAbilityComplexSubject {
    relationRole: Array<IProjectRelationRole>;
    subSubject: Array<IProjectSubject>;
}

export interface IProjectDumpLoadCalculatedSubject extends IAbilityComplexSubject {
    relationRole: Array<IProjectRelationRole>;
    projectSubject: Array<IProjectSubject>;
    dumpLoadSubject: Array<IDumpLoadSubject>;
}
