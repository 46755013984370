import './LandfillDocumentation.scss';

import React from 'react';

import { getBem } from '~/@sochi-components/@bem';
import { SochiDivider } from '~/@sochi-components/SochiDivider';

import type { LandfillDetailsDataProps } from '../LandfillPage';
import { LandfillCertificates } from './LandfillCertificates';
import { LandfillDeclarations } from './LandfillDeclarations';

export class LandfillDocumentation extends React.Component<LandfillDetailsDataProps> {
    bem = getBem(this);

    render() {
        const { className } = this.bem;

        return (
            <div className={className}>
                <LandfillCertificates landfill={this.props.landfill} refetch={this.props.refetch} />
                <SochiDivider />
                <LandfillDeclarations landfill={this.props.landfill} />
            </div>
        );
    }
}
