import { makeStyles } from '@material-ui/core/styles';
import React, { memo, VFC } from 'react';

import { CheckFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ActionVariant, ITableCellAction, TableCellActions } from '~/@components/Table/TableCellActions';
import { showCustomDialog } from '~/@store/common';
import { ICompanySearchRecord } from '~/@store/companies';
import { IUser, useUserUpdateMutation } from '~/@store/users';
import { PopupCompanySelect } from '~/@views/AdminView/common/PopupEditFields';
import { useUserAbilities } from '~/contexts';
import i18n from '~/i18n';
import { canUpdateUser } from '~/utils/auth';
import { isExternalReceiver } from '~/utils/user';

type UserCustomerCellProps = {
    user: IUser;
    disabled?: boolean;
};

export const UserCustomerCell: VFC<UserCustomerCellProps> = memo(({ user, disabled = false }) => {
    const { grey } = useStyles();
    const ability = useUserAbilities();
    const [updateUser] = useUserUpdateMutation();

    if (!isExternalReceiver(user) || !canUpdateUser(ability, user)) return <span className={grey}>{i18n.NA}</span>;

    const submit = (value: ICompanySearchRecord | null) => updateUser({ id: user.id, customerId: value?.id || null });

    const onEdit = () =>
        showCustomDialog({
            render: closeDialog => (
                <PopupCompanySelect
                    label={<LocalizableText code={'UsersTable.selectCustomer'} />}
                    initialValue={user.customer}
                    onSubmit={submit}
                    onClose={closeDialog}
                    nullable={false}
                />
            ),
        });

    const actions: ITableCellAction[] = [
        {
            variant: ActionVariant.EDIT,
            onClick: onEdit,
            disabled,
        },
        {
            variant: ActionVariant.COPY,
            content: user.customer?.name,
            disabled: !user.customer,
        },
    ];

    return (
        <TableCellActions actions={actions}>
            {user.customer?.name || <CheckFormatter>{false}</CheckFormatter>}
        </TableCellActions>
    );
});

const useStyles = makeStyles(theme => ({
    grey: {
        color: theme.palette.grey[400],
    },
}));
