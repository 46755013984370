import './NotApprovedPage.scss';

import { routes } from '@common/routes';
import React from 'react';
import type { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';

import { Button } from '~/@components/Button';
import { getBem } from '~/@sochi-components/@bem';
import i18n from '~/i18n';

import { AuthTitle } from '../common/AuthTitle';
import { AuthWrapper } from '../common/AuthWrapper';

class NotApprovedPage extends React.PureComponent<RouteComponentProps> {
    bem = getBem(this);

    logOut = () => {
        localStorage.removeItem('token');
        this.props.history.push(routes.login);
    };

    render() {
        const { className, element } = this.bem;

        return (
            <div className={className}>
                <AuthWrapper>
                    <AuthTitle title={i18n.NotApprovedPage.notApproved} />
                    <div className={element('buttons')}>
                        <Button color="primary" variant="contained" onClick={this.logOut}>
                            {i18n.logout}
                        </Button>
                    </div>
                </AuthWrapper>
            </div>
        );
    }
}

export default withRouter(NotApprovedPage);
