import React from 'react';

import { makeStyles } from '~/@components/@theme';
import { CardDialog } from '~/@components/Dialog';
import { LocalizableText } from '~/@components/LocalizableText';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { showCustomDialog } from '~/@store/common';
import { IDumpLoad, usePagedDumpLoadsItem } from '~/@store/dumpLoads';

import { DumpLoadAnalysisTable } from './DumpLoadAnalysisTable';

type DumpLoadEditDialogProps = {
    dumpLoadId: string;
    projectId: string;
    onClose: (v: boolean) => void;
};

const DumpLoadEditAnalysisDialog = ({ dumpLoadId, projectId, onClose }: DumpLoadEditDialogProps) => {
    const { root } = useStyles();
    const closeHandler = () => onClose(false);
    const query = usePagedDumpLoadsItem(projectId, dumpLoadId);

    return (
        <CardDialog
            className={root}
            onClose={closeHandler}
            title={<LocalizableText code={'DumpLoadsTable.toxicAnalysis'} />}
            maxWidth="lg">
            <QueryStateHandler query={query} dataGetter={query => query.data?.projectDumpload || undefined}>
                {(dumpLoad: IDumpLoad) => {
                    if (!dumpLoad.analysisFiles.length) closeHandler();

                    return <DumpLoadAnalysisTable dumpLoad={dumpLoad} />;
                }}
            </QueryStateHandler>
        </CardDialog>
    );
};

export const showDumpLoadEditAnalysisDialog = (projectId: string, dumpLoadId: string) =>
    showCustomDialog({
        render: closeDialog => (
            <DumpLoadEditAnalysisDialog dumpLoadId={dumpLoadId} projectId={projectId} onClose={closeDialog} />
        ),
    });

const useStyles = makeStyles({
    root: {
        minWidth: 522,
    },
});
