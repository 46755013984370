import { computed } from 'mobx';

import { FilterStore, PaginationStore } from '~/@store/common';
import { UserActivityPagedQueryVariables } from '~/graphql';

export type UserActivityMinuteItem = {
    id: string;
    name: string;
    value: number;
};

export type UserActivityStoreFilter = {
    minutesToCut?: UserActivityMinuteItem | null;
};

export enum UserActivityFilterFields {
    minutesToCut = 'minutesToCut',
}

class UserActivityStore {
    pagination = new PaginationStore();
    filter = new FilterStore<UserActivityStoreFilter>(this.pagination.onClear);

    @computed
    get userActivityQueryVariables(): UserActivityPagedQueryVariables {
        const connection = this.pagination.connection;

        return { minutesToCut: this.filter.values.minutesToCut?.value, connection };
    }
}

export const userActivitiesStore = new UserActivityStore();
