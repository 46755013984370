import { Divider, makeStyles } from '@material-ui/core';
import React, { VFC } from 'react';

import { BiSideLayout } from '~/@components/BiSideLayout';
import { IAccount } from '~/@store/account';

import { ChangePasswordControl } from '../common/ChangePassword/ChangePasswordControl';
import { AccountForm } from './AccountForm';
import { AccountLogoutButton } from './AccountLogoutButton/AccountLogoutButton';

type Props = {
    account: IAccount;
};

export const UserAccountTab: VFC<Props> = () => {
    const { content, leftSide, divider } = useStyles();

    return (
        <>
            <BiSideLayout className={content}>
                <BiSideLayout.LeftSideContent className={leftSide}>
                    <AccountForm />
                    <ChangePasswordControl />
                </BiSideLayout.LeftSideContent>
            </BiSideLayout>
            <Divider className={divider} light />
            <AccountLogoutButton />
        </>
    );
};

const useStyles = makeStyles(theme => ({
    content: {
        minHeight: 466,
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    divider: {
        marginBottom: theme.spacing(2),
    },
}));
