import { isManagerRole } from '@common/abilities/utils';
import { DumpLoadStatus, UserRole } from '@common/enums';
import { replaceRouteParams, routes } from '@common/routes';
import { defaultCenter } from '@common/utils';
import { action, computed, observable, toJS } from 'mobx';

import { ILocation, LatLng } from '~/@store/locations';
import client from '~/apolloClient';
import history from '~/browserHistory';
import {
    AdminProjectsAndLandfillsQuery,
    LocationInput,
    LocationType,
    ProjectsAndLandfillsQuery,
    ProjectStatus,
} from '~/graphql';
import { handleLoadingPromise } from '~/services/loader';
import { getPositionInfo, toGoogleCoordinates, toLocation } from '~/utils/map';

import { getDistance, isOwnLandfill } from './homePageMap.utils';
import * as queries from './queries';
import {
    GoogleMapsDirectionResult,
    GoogleMapsDirectionService,
    GoogleMapsMap,
    GoogleMapsRouteStatuses,
    GoogleMapsTravelMode,
    HomePageMode,
    IDumpLoad,
    IExternalLandfill,
    IHomePageLandfill,
    IHomePageLandfillDistance,
    ILandfillDumpLoad,
    IOwnLandfill,
    IProject,
    LandfillType,
    ProjectType,
} from './types';

export enum AllLandfillFilter {
    ALL = 'ALL',
}

export type LandfillTypesFilter = `${LandfillType | AllLandfillFilter}`;

export enum SidebarTabs {
    INCOMING = 'INCOMING',
    OUTGOING = 'OUTGOING',
}

export enum CreationMode {
    LANDFILL = 'LANDFILL',
    PROJECT = 'PROJECT',
    CHOOSE = 'CHOOSE',
}

export type ISidebarTab = `${SidebarTabs}`;

const lsZoomKey = 'current_zoom';
const lsCenterKey = 'current_center';

export const defaultZoom = 7;

export const includeDumpLoadStatuses = [
    DumpLoadStatus.DRAFT,
    DumpLoadStatus.REQUESTED,
    DumpLoadStatus.CONFIRMED,
    DumpLoadStatus.ORDERED,
    DumpLoadStatus.IN_PROGRESS,
];

class HomePageMapStore {
    private mapEntity: GoogleMapsMap | null = null;
    private zoom: number;
    private _directionService: GoogleMapsDirectionService | null = null;
    private center: LatLng = defaultCenter;

    @observable
    _isFloatPanelOpen: boolean = true;

    @observable
    route: GoogleMapsDirectionResult | null = null;

    @observable
    searchProjectFilter: string = '';

    @observable
    searchLandfillFilter: string = '';

    @observable
    projectStatusFilter: ProjectStatus = ProjectStatus.NEW;

    @observable
    landfillTypeFilter: LandfillTypesFilter = AllLandfillFilter.ALL;

    @observable
    selectedPlace: ILocation | null = null;

    @observable
    isSelectPlaceMode: boolean = false;

    @observable
    sidebarActiveTab: ISidebarTab = SidebarTabs.OUTGOING;

    @observable
    creationMode: CreationMode | null = null;

    @observable
    previousCreationMode: CreationMode | null = null;

    @observable
    mode: HomePageMode = HomePageMode.USER;

    constructor() {
        this.mapEntity = null;

        this.zoom = defaultZoom;
        let storageValue = localStorage.getItem(lsZoomKey);
        if (storageValue) {
            const zoom = JSON.parse(storageValue);
            if (typeof zoom === 'number') this.zoom = zoom;
        }

        storageValue = localStorage.getItem(lsCenterKey);
        if (storageValue) {
            const center = JSON.parse(storageValue);
            if ('lat' in center && 'lng' in center) {
                this.center = {
                    lat: center['lat'],
                    lng: center['lng'],
                };
            }
        }
    }

    @observable
    projects: IProject[] = [];

    @observable
    landfills: IHomePageLandfill[] = [];

    @observable
    selectedProject: IProject | null = null;

    @observable
    selectedLandfill: IHomePageLandfill | null = null;

    @computed
    get ownLandfillDistances(): IHomePageLandfillDistance[] | null {
        if (!this.selectedProjectDumpLoad || this.selectedProjectDumpLoad.status === DumpLoadStatus.IN_PROGRESS)
            return null;

        return this.landfills
            .filter(l => l.type === LandfillType.OWN)
            .map(landfill => ({
                landfill,
                distance: getDistance(this.selectedProject!.location, landfill.location),
            }))
            .sort((a, b) => a.distance - b.distance);
    }

    @computed
    get projectDumpLoads(): IDumpLoad[] | null {
        if (!this.selectedProject || this.mode === HomePageMode.ADMIN) return null;

        return this.filterProjectDumpLoadsByStatus(this.selectedProject);
    }

    @observable
    selectedProjectDumpLoad: IDumpLoad | null = null;

    @computed
    get landfillDumpLoads(): ILandfillDumpLoad[] | null {
        if (!this.selectedLandfill || this.mode === HomePageMode.ADMIN) return null;

        return (
            this.projects
                .reduce((acc: ILandfillDumpLoad[], project) => {
                    const dumpLoads = this.filterProjectDumpLoadsByStatus(project)
                        ?.filter(d => d.destinationLandfill?.id === this.selectedLandfill!.id)
                        .map(d => ({ ...d, projectName: project.name }));

                    if (dumpLoads) acc.push(...dumpLoads);

                    return acc;
                }, [])
                .sort((a, b) => a.projectName.localeCompare(b.projectName)) || []
        );
    }

    @action
    changeFloatPanelState() {
        if (!this.isFloatPanelDisabled) this._isFloatPanelOpen = !this._isFloatPanelOpen;
    }

    @computed
    get isFloatPanelOpen(): boolean {
        return (
            (this.creationMode !== null || this._isFloatPanelOpen) &&
            this.selectedProject === null &&
            this.selectedLandfill === null
        );
    }

    @computed
    get isFloatPanelDisabled(): boolean {
        return this.creationMode !== null || this.selectedProject !== null || this.selectedLandfill !== null;
    }

    private sideEffectsPerformed = false;

    performSideEffects(
        role: UserRole,
        projects: Array<{ id: string; status: ProjectStatus }>,
        landfills: Array<{ id: string }>
    ) {
        if (isManagerRole(role)) return;

        if (this.sideEffectsPerformed) return;
        this.sideEffectsPerformed = true;

        if (landfills.length > 0) {
            if (projects.length === 0) {
                this.setSidebarActiveTab(SidebarTabs.INCOMING);
            }

            return;
        }

        const inProgressProjects = projects.filter(p => p.status === ProjectStatus.IN_PROGRESS);
        const newProjects = projects.filter(p => p.status === ProjectStatus.NEW);

        if (inProgressProjects.length > 0) {
            this.applyProjectStatusFilter(ProjectStatus.IN_PROGRESS);
        }

        if (inProgressProjects.length === 1) {
            history.push(replaceRouteParams(routes.sochi.projectDetail, { projectId: inProgressProjects[0]!.id }));
        }

        if (inProgressProjects.length === 0 && newProjects.length === 1) {
            history.push(replaceRouteParams(routes.sochi.projectDetail, { projectId: newProjects[0]!.id }));
        }
    }

    @action
    async init(role: UserRole) {
        const queryPromise = isManagerRole(role)
            ? client.query<AdminProjectsAndLandfillsQuery, {}>({
                  query: queries.AdminProjectsAndLandfillsQuery,
                  fetchPolicy: 'network-only',
              })
            : client.query<ProjectsAndLandfillsQuery, {}>({
                  query: queries.ProjectsAndLandfillsQuery,
                  fetchPolicy: 'network-only',
              });

        const result = await handleLoadingPromise(queryPromise);
        this.mode = isManagerRole(role) ? HomePageMode.ADMIN : HomePageMode.USER;

        const { projects, landfills } = result.data;

        this.performSideEffects(role, projects, landfills);

        const ownProjects: IProject[] = projects.map(p => ({ ...p, type: ProjectType.OWN }));
        const ownLandfills: IOwnLandfill[] = landfills.map(landfill => ({
            type: LandfillType.OWN,
            projects: [],
            ...landfill,
        }));

        let externalProjects: IProject[] = [];
        let externalLandfills: IExternalLandfill[] = [];

        if (this.mode === HomePageMode.USER) {
            const ownProjectSet = new Set(projects.map(p => p.id));
            const externalProjectMap = new Map<string, IProject>();

            externalProjects = Array.from(
                ownLandfills
                    .reduce((acc: Map<string, IProject>, landfill) => {
                        landfill.projects.forEach(p => {
                            if (!ownProjectSet.has(p.id)) {
                                const dumpLoads = p.dumpLoads.map(d => ({
                                    ...d,
                                    status: DumpLoadStatus.IN_PROGRESS,
                                    destinationLandfill: landfill,
                                }));

                                if (!externalProjectMap.has(p.id)) {
                                    acc.set(p.id, {
                                        ...p,
                                        status: ProjectStatus.IN_PROGRESS,
                                        type: ProjectType.EXTERNAL,

                                        dumpLoads,
                                    });
                                } else {
                                    const extProject = externalProjectMap.get(p.id)!;
                                    const newDumpLoads = dumpLoads.filter(
                                        d => !extProject.dumpLoads!.some(item => item.id === d.id)
                                    );

                                    extProject.dumpLoads!.push(...newDumpLoads);
                                }
                            }
                        });

                        return acc;
                    }, new Map<string, IProject>())
                    .values()
            );

            const landfillSet = new Set(landfills.map(l => l.id));

            externalLandfills = ownProjects.reduce((acc: IExternalLandfill[], project) => {
                this.filterProjectDumpLoadsByStatus(project)
                    ?.filter(d => d.destinationLandfill)
                    .forEach(d => {
                        if (!d.destinationLandfill || landfillSet.has(d.destinationLandfill.id)) return;

                        acc.push({
                            type: LandfillType.EXTERNAL,
                            ...d.destinationLandfill!,
                        });

                        landfillSet.add(d.destinationLandfill.id);
                    });

                return acc;
            }, []);
        }

        const resultLandfills: IHomePageLandfill[] = [...ownLandfills, ...externalLandfills];
        const resultProjects: IProject[] = [...ownProjects, ...externalProjects];

        this.landfills = observable(resultLandfills);
        this.projects = observable(resultProjects);
    }

    @action
    setSelectedPlaceAndCenter = (newValue: ILocation | null) => {
        this.selectedPlace = newValue;
        this.isSelectPlaceMode = newValue !== null;
        this.clearSelected();

        if (newValue) {
            this._setCenterToLocation(newValue);
        }
    };

    @action
    setRoute(newValue: GoogleMapsDirectionResult | null) {
        this.route = newValue;
    }

    @action
    applySearchProjectFilter = (newValue: string) => {
        this.searchProjectFilter = newValue.toLowerCase();
    };

    @action
    applySearchLandfillFilter = (newValue: string) => {
        this.searchLandfillFilter = newValue.toLowerCase();
    };

    @action
    applyProjectStatusFilter = (newValue: ProjectStatus) => {
        this.projectStatusFilter = newValue;
    };

    @action
    applyLandfillTypeFilter = (newValue: LandfillTypesFilter) => {
        this.landfillTypeFilter = newValue;
    };

    @action
    setSidebarActiveTab = (newValue: ISidebarTab) => {
        this.sidebarActiveTab = newValue;
    };

    private _resetSelectPlaceMode() {
        this.setSelectedPlaceAndCenter(null);
    }

    clearSelected = () => {
        this.setSelectedProject(null);
        this.setSelectedLandfill(null);
        this.setRoute(null);
    };

    @computed
    get filteredProjects(): IProject[] {
        const filter = this.searchProjectFilter.toLowerCase();
        const statusFilter = this.projectStatusFilter;

        return this.projects.filter(p => this._isConformProjectFilter(p, filter, statusFilter));
    }

    @computed
    get filteredLandfills(): IHomePageLandfill[] {
        const filter = this.searchLandfillFilter.toLowerCase();
        const typeFilter = this.landfillTypeFilter;

        return this.landfills
            .sort((l1, l2) => l1.name.localeCompare(l2.name))
            .filter(l => this._isConformLandfillFilter(l, filter, typeFilter));
    }

    @computed
    get landfillsOnMap(): IHomePageLandfill[] {
        if (!this.selectedLandfill) return this.filteredLandfills;

        return this.filteredLandfills.includes(this.selectedLandfill)
            ? this.filteredLandfills
            : [...this.filteredLandfills, this.selectedLandfill];
    }

    @computed
    get projectsOnMap(): IProject[] {
        if (!this.selectedProject) return this.filteredProjects;

        return this.filteredProjects.includes(this.selectedProject)
            ? this.filteredProjects
            : [...this.filteredProjects, this.selectedProject];
    }

    get defaultLocation(): Readonly<ILocation> {
        return defaultCenter;
    }

    @computed
    get isOnWater(): boolean {
        if (!this.selectedPlace) return false;

        return this.selectedPlace.type === LocationType.ON_WATER;
    }

    @computed
    get selectedPlacePojo(): HomePageMapStore['selectedPlace'] {
        return toJS(this.selectedPlace);
    }

    @action
    setCreationMode(newMode: CreationMode | null) {
        if (newMode) {
            this.previousCreationMode = this.creationMode;
        } else {
            this.previousCreationMode = null;
        }
        this.creationMode = newMode;

        if (this.creationMode === null) this._resetSelectPlaceMode();
    }

    @action
    revertCreationMode() {
        this.creationMode = this.previousCreationMode;
        this.previousCreationMode = null;

        if (this.creationMode === null) this._resetSelectPlaceMode();
    }

    onMapLoad(map: GoogleMapsMap) {
        this.mapEntity = map;
        map.setCenter(toGoogleCoordinates(this.center));
        map.setZoom(this.zoom);

        // Should call reset when map is reloaded.
        this.route = null;
        this._directionService = null;
        this._updateRoute();
    }

    onZoomChanged() {
        if (this.mapEntity) {
            this.zoom = this.mapEntity.getZoom()!;
            localStorage.setItem(lsZoomKey, this.zoom.toString());
        }
    }

    onCenterChanged() {
        if (this.mapEntity) {
            const newValue = this.mapEntity.getCenter()!;
            const location = {
                lat: newValue.lat(),
                lng: newValue.lng(),
            };

            this._saveMapCenter(location);
        }
    }

    onProjectClick(project: IProject) {
        const projectLocation = toLocation(project.location);
        if (projectLocation) {
            if (this.isSelectPlaceMode) {
                this.processPositionChange(projectLocation);
            } else {
                this.handleProjectSelect(project);
                this._updateRoute();
            }

            this._setCenterToLocation(projectLocation);
        }
    }

    onLandfillClick(landfill: IHomePageLandfill) {
        const landfillLocation = toLocation(landfill.location);

        if (landfillLocation) {
            if (this.isSelectPlaceMode) {
                this.processPositionChange(landfillLocation);
            } else {
                this.handleLandfillSelect(landfill);
                this._updateRoute();
            }

            this._setCenterToLocation(landfillLocation);
        }
    }

    onProjectDumpLoadClick(dumpLoad: IDumpLoad) {
        this.handleProjectDumpLoadSelect(dumpLoad);
        this._updateRoute();
    }

    processPositionChange(position: LatLng) {
        getPositionInfo({
            lat: position.lat,
            lng: position.lng,
        }).then(this.setSelectedPlaceAndCenter);
    }

    get directionService(): GoogleMapsDirectionService {
        if (!this._directionService) {
            this._directionService = new window.google.maps.DirectionsService();
        }

        return this._directionService;
    }

    get currentZoom(): number {
        return this.zoom;
    }

    private async _createRoute(
        start: LocationInput,
        end: LocationInput,
        travelMode: GoogleMapsTravelMode
    ): Promise<GoogleMapsDirectionResult> {
        return new Promise((resolve, reject) =>
            this.directionService.route(
                { origin: toGoogleCoordinates(start), destination: toGoogleCoordinates(end), travelMode },
                (result, status) => {
                    if (status === GoogleMapsRouteStatuses.OK) {
                        resolve(result!);
                    } else {
                        reject();
                    }
                }
            )
        );
    }

    private _updateRoute() {
        const projectLocation = this.selectedProject && toLocation(this.selectedProject.location);
        const landfillLocation = this.selectedLandfill && toLocation(this.selectedLandfill.location);
        if (projectLocation && landfillLocation) {
            this._createRoute(projectLocation, landfillLocation, window.google.maps.TravelMode.DRIVING)
                .then(result => this.setRoute(result))
                .catch(() => this.setRoute(null));
        } else this.setRoute(null);
    }

    private _isConformProjectFilter = (
        input: IProject,
        filter: string | null,
        statusFilter: ProjectStatus
    ): boolean => {
        return (
            (!filter ||
                input.name.toLowerCase().includes(filter) ||
                (input.location.text || '').toLowerCase().includes(filter) ||
                (input.customer?.name || '').toLowerCase().includes(filter)) &&
            statusFilter === input.status
        );
    };

    private _isConformLandfillFilter = (
        input: IHomePageLandfill,
        filter: string | null,
        typeFilter: LandfillTypesFilter
    ): boolean => {
        return (
            (!filter ||
                input.name.toLowerCase().includes(filter) ||
                input.location.text.toLowerCase().includes(filter) ||
                (isOwnLandfill(input) &&
                    !!input.receivers?.some(
                        receiver =>
                            receiver.name.toLowerCase().includes(filter) ||
                            receiver.surname?.toLowerCase().includes(filter)
                    ))) &&
            (typeFilter === AllLandfillFilter.ALL || input.type === this.landfillTypeFilter)
        );
    };

    private _saveMapCenter = (location: LatLng) => {
        this.center = { ...location };
        localStorage.setItem(lsCenterKey, JSON.stringify(this.center));
    };

    private _setCenterToLocation(location: ILocation) {
        // We need save new center location implicitly as map could be not mounted
        this._saveMapCenter(location);
        if (this.mapEntity) {
            this.mapEntity.panTo(toGoogleCoordinates(location));
        }
    }

    @action handleProjectSelect(project: IProject | null) {
        this.setSelectedProject(project);

        if (this.selectedLandfill) {
            const dumpLoad = this.projectDumpLoads?.find(d => d.destinationLandfill?.id === this.selectedLandfill?.id);
            if (dumpLoad) {
                this.setSelectedProjectDumpLoad(dumpLoad);
            } else {
                this.setSelectedLandfill(null);
            }
        }
    }

    @action handleLandfillSelect(landfill: IHomePageLandfill | null) {
        this.setSelectedLandfill(landfill);
        // I.e. project already selected, but not landfill distances displayed
        if (landfill && this.projectDumpLoads && !this.ownLandfillDistances?.some(l => l.landfill.id === landfill.id)) {
            // Try to find corresponding IN_PROGRESS dump load
            const connectedDumpLoad = this.projectDumpLoads.find(d => d.destinationLandfill?.id === landfill.id);
            if (connectedDumpLoad) {
                this.setSelectedProjectDumpLoad(connectedDumpLoad);
            } else if (!this.ownLandfillDistances?.some(l => l.landfill.id === landfill.id)) {
                // If new landfill not owned then project should be cleared
                this.setSelectedProject(null);
            }
        }
    }

    @action
    handleProjectDumpLoadSelect(dumpLoad: IDumpLoad) {
        if (this.mode === HomePageMode.ADMIN) return;

        this.setSelectedProjectDumpLoad(dumpLoad);

        if (dumpLoad.status === DumpLoadStatus.IN_PROGRESS) {
            const landfill = this.landfills.find(l => l.id === dumpLoad.destinationLandfill?.id) || null;

            this.setSelectedLandfill(landfill);
        }
    }

    @action
    setSelectedProject(project: IProject | null) {
        this.selectedProject = project;

        if (this.mode === HomePageMode.USER) {
            this.selectedProjectDumpLoad = null;
        }
    }

    @action
    setSelectedLandfill(landfill: IHomePageLandfill | null) {
        this.selectedLandfill = landfill;
    }

    @action
    setSelectedProjectDumpLoad(dumpLoad: IDumpLoad) {
        if (this.mode === HomePageMode.USER) {
            this.selectedProjectDumpLoad = dumpLoad;
        }
    }

    filterProjectDumpLoadsByStatus(project: IProject): IDumpLoad[] | null {
        return project.dumpLoads?.filter(d => d.status && includeDumpLoadStatuses.includes(d.status)) || null;
    }
}

export const homePageMapStore = new HomePageMapStore();
