import React from 'react';

import { SimpleDialog } from '~/@components/Dialog';
import { showCustomDialog } from '~/@store/common';
import { BeastMaterialForm } from '~/@views/AdminView/BeastMaterialsPage/BeastMaterialForm';

type Props = {
    onClose: () => void;
};

export const BeastMaterialCreateDialog = ({ onClose }: Props) => {
    return (
        <SimpleDialog onClose={onClose}>
            <BeastMaterialForm material={null} closeForm={onClose} />
        </SimpleDialog>
    );
};

export const showBeastMaterialCreateDialog = () => {
    const ignoredPromise = showCustomDialog({
        render: closeDialog => <BeastMaterialCreateDialog onClose={() => closeDialog(true)} />,
    });
};
