import { ReleaseNoteType } from '@common/enums';
import React from 'react';

import { CustomTabs } from '~/@components/CustomTabs';
import { LocalizableText } from '~/@components/LocalizableText';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { IReleaseNote, useReleaseNotes } from '~/@store/releaseNotes';

import { AddReleaseNoteButton } from './AddReleaseNoteButton';
import { ReleaseNotesList } from './ReleaseNotesList';

export const ReleaseNotesPage = () => {
    const query = useReleaseNotes();

    return (
        <QueryStateHandler
            query={query}
            dataGetter={query => query.data?.releaseNotes || query.previousData?.releaseNotes}>
            {(notes: IReleaseNote[]) => (
                <CustomTabs
                    title={<LocalizableText code={'ReleaseNotes.releaseNotes'} />}
                    tabs={[
                        {
                            label: <LocalizableText code={'ReleaseNotes.adminNotes'} />,
                            subTitle: <LocalizableText code={'ReleaseNotes.adminNotes'} />,
                            render: () => <ReleaseNotesList notes={notes} type={ReleaseNoteType.ADMIN} />,
                            rightActionButton: () => <AddReleaseNoteButton type={ReleaseNoteType.ADMIN} />,
                        },
                        {
                            label: <LocalizableText code={'ReleaseNotes.customerNotes'} />,
                            subTitle: <LocalizableText code={'ReleaseNotes.customerNotes'} />,
                            render: () => <ReleaseNotesList notes={notes} type={ReleaseNoteType.CUSTOMER} />,
                            rightActionButton: () => <AddReleaseNoteButton type={ReleaseNoteType.CUSTOMER} />,
                        },
                        {
                            label: <LocalizableText code={'ReleaseNotes.driverNotes'} />,
                            subTitle: <LocalizableText code={'ReleaseNotes.driverNotes'} />,
                            render: () => <ReleaseNotesList notes={notes} type={ReleaseNoteType.DRIVER} />,
                            rightActionButton: () => <AddReleaseNoteButton type={ReleaseNoteType.DRIVER} />,
                        },
                    ]}
                />
            )}
        </QueryStateHandler>
    );
};
