import { observer } from 'mobx-react';
import React from 'react';

import { getBem } from '~/@sochi-components/@bem';
import { SochiThemeContainer } from '~/@sochi-components/@theme';
import { SochiDialog } from '~/@sochi-components/SochiDialog';

import dialogStore from '../../../../stores/dialogStore';

@observer
export class GlobalDialogContainer extends React.Component<{}> {
    bem = getBem(this);

    render() {
        const { dialogs } = dialogStore;
        const { className } = this.bem;

        return (
            <div className={className}>
                <SochiThemeContainer>
                    {dialogs.map(dialog => (
                        <SochiDialog key={dialog.key} dialog={dialog} />
                    ))}
                </SochiThemeContainer>
            </div>
        );
    }
}
