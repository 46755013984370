import { ProjectStatus } from '../enums';

type ProjectKind = {
    status: ProjectStatus;
};

type LandfillKind = {
    subareas: Array<{ onHold: boolean }>;
    deletedAt?: unknown;
};

export const canUseChatGptForProject = (project: ProjectKind) => {
    return project.status === ProjectStatus.IN_PROGRESS;
};

export const canUseChatGptForLandfill = (landfill: LandfillKind) => {
    return !landfill.deletedAt && landfill.subareas.some(s => !s.onHold);
};
