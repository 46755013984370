import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { AdminLandfillsQuery, AdminLandfillsQueryVariables } from '~/graphql';

import * as queries from '../landfills.queries';

export const useLandfills = (): QueryResult<AdminLandfillsQuery, AdminLandfillsQueryVariables> => {
    const variables: AdminLandfillsQueryVariables = {
        sort: {
            field: 'name',
            direction: 1,
        },
    };

    return useQuery<AdminLandfillsQuery, AdminLandfillsQueryVariables>(queries.AdminLandfillsQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
    });
};
