import { gql } from '@apollo/client';

export const ISubstanceFragment = gql`
    fragment ISubstanceFragment on ISubstance {
        id
        name
        position
        parentId
    }
`;

export const SubstanceChildFragment = gql`
    fragment SubstanceChildFragment on SubstanceChild {
        id
        name
        position
        parentId
    }
`;

export const SubstanceFragment = gql`
    fragment SubstanceFragment on Substance {
        id
        name
        position
        parentId
        contaminationTypes
        groupBy
        children {
            ...SubstanceChildFragment
        }
    }

    ${SubstanceChildFragment}
`;

export const SubstanceRemoveMutation = gql`
    mutation SubstanceRemoveMutation($id: ID!) {
        substanceRemove(id: $id)
    }
`;

export const SubstanceUpdateMutation = gql`
    mutation SubstanceUpdateMutation(
        $id: ID!
        $name: String
        $contaminationTypes: [ContaminationType!]
        $groupBy: SubstanceGroupBy
    ) {
        substanceUpdate(input: { id: $id, name: $name, contaminationTypes: $contaminationTypes, groupBy: $groupBy }) {
            ...ISubstanceFragment
        }
    }

    ${ISubstanceFragment}
`;

export const SubstanceCreateMutation = gql`
    mutation SubstanceCreateMutation($name: String!, $parentId: ID, $contaminationTypes: [ContaminationType!]) {
        substanceCreate(input: { name: $name, parentId: $parentId, contaminationTypes: $contaminationTypes }) {
            ...ISubstanceFragment
        }
    }

    ${ISubstanceFragment}
`;

export const SubstanceUpdatePositionMutation = gql`
    mutation SubstanceUpdatePositionMutation($items: [SubstanceUpdatePosition!]) {
        substanceUpdatePosition(input: $items) {
            ...ISubstanceFragment
        }
    }

    ${ISubstanceFragment}
`;

export const SubstanceCheckUsageQuery = gql`
    query SubstanceCheckUsageQuery($id: ID!) {
        substanceCheckUsage(id: $id)
    }
`;

export const SubstanceByIdQuery = gql`
    query SubstanceByIdQuery($id: ID!) {
        substance(id: $id) {
            ...ISubstanceFragment
        }
    }

    ${ISubstanceFragment}
`;

export const SubstancesListQuery = gql`
    query SubstancesListQuery($search: String, $first: Int, $after: ID, $contaminationType: ContaminationType) {
        substances(search: $search, first: $first, after: $after, contaminationType: $contaminationType) {
            ...SubstanceFragment
        }
    }

    ${SubstanceFragment}
`;
