import { isEmpty } from 'lodash';
import React from 'react';

import { Button } from '~/@components/Button';
import i18n from '~/i18n';

import { useLandfillFormContext } from '../LandfillFormParts';

export const LandfillEditFormSubmitButton = () => {
    const context = useLandfillFormContext();

    return (
        <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isEmpty(context.touched) || context.readOnly}>
            {i18n.save}
        </Button>
    );
};
