import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IDeliveryLineShort } from '~/@store/deliveryLines';
import { DeliveryLineUpdateInput, DeliveryLineUpdateMutation, DeliveryLineUpdateMutationVariables } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../deliveryLines.queries';

export type UpdateDeliveryLineFunc = (input: Omit<DeliveryLineUpdateInput, 'id'>) => Promise<unknown>;

export const useDeliveryLineUpdateMutation = (
    deliveryLine: IDeliveryLineShort
): [UpdateDeliveryLineFunc, boolean, boolean] => {
    const [callMutation, mutationResult] = useMutation<DeliveryLineUpdateMutation, DeliveryLineUpdateMutationVariables>(
        queries.DeliveryLineUpdateMutation
    );

    const updateDeliveryLine = useCallback<UpdateDeliveryLineFunc>(
        input => {
            return callMutation({
                variables: { input: { ...input, id: deliveryLine.id } },
            }).catch(handleApolloError);
        },
        [deliveryLine, callMutation]
    );

    const disabled = !!deliveryLine.deletedAt;

    return [updateDeliveryLine, disabled, mutationResult.loading];
};
