import React, { FC } from 'react';

import { makeStyles } from '~/@components/@theme';
import { LocalizableText } from '~/@components/LocalizableText';
import { SochiTitle } from '~/@sochi-components/SochiTitle';

export const DashboardNoData: FC = () => {
    const { root } = useStyles();

    return (
        <div className={root}>
            <SochiTitle title={<LocalizableText code={'noDataToDisplay'} />} />
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
    },
}));
