import React, { useCallback, useMemo } from 'react';

import { useObservable } from '~/@components/@hooks/use-observable';
import { SortSection, StringFilter, TableHeadCell } from '~/@components/Table/TableHeadCell';
import { ProjectFilterFields, projectsStore } from '~/@store/projects/project.store';
import { SortInput } from '~/graphql';
import i18n from '~/i18n';

import { ProjectStatusFilter } from './ProjectStatusFilter';

type Props = {
    title: React.ReactNode;
    fieldName: ProjectFilterFields;
    withFilter?: boolean;
    withSort?: boolean;
};

export const ProjectHeadCell = ({ title, fieldName, withFilter, withSort }: Props) => {
    const sortValue = useObservable(() => projectsStore.sort.value);
    const setSortValue = useCallback((v: SortInput) => projectsStore.sort.setValue(v), []);

    const sortSection = useMemo(() => {
        if (!withSort) return null;

        return <SortSection fieldName={fieldName} sort={sortValue} setSort={setSortValue} labels={i18n.SortString} />;
    }, [withSort, fieldName, sortValue, setSortValue]);

    const filterSection = useMemo(() => {
        if (!withFilter) return null;

        if (fieldName === ProjectFilterFields.status) {
            return <ProjectStatusFilter />;
        }

        return (
            <StringFilter
                valueGetter={() => projectsStore.filter.values[fieldName]}
                setValue={v => projectsStore.filter.setValue(fieldName, v)}
            />
        );
    }, [withFilter, fieldName]);

    return (
        <TableHeadCell title={title} sortDirection={sortValue?.field === fieldName ? sortValue.direction : null}>
            {sortSection}
            {filterSection}
        </TableHeadCell>
    );
};
