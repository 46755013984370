import React, { VFC } from 'react';

import { SimpleDialog } from '~/@components/Dialog';
import { showCustomDialog } from '~/@store/common';

import { VehicleTypeForm } from './VehicleTypeForm';

type VehicleTypeCreateDialogProps = {
    onClose: () => void;
};

const VehicleTypeCreateDialog: VFC<VehicleTypeCreateDialogProps> = ({ onClose }) => {
    return (
        <SimpleDialog onClose={onClose} maxWidth="lg">
            <VehicleTypeForm onClose={onClose} />
        </SimpleDialog>
    );
};

export const showVehicleTypeCreateDialog = () =>
    showCustomDialog({
        render: closeDialog => <VehicleTypeCreateDialog onClose={closeDialog} />,
    });
