import { makeStyles } from '@material-ui/core';
import React, { useState, VFC } from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { BiSideLayout } from '~/@components/BiSideLayout';
import { TruckIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { PageLight } from '~/@components/PageLight';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { useVehicleTypePermissions, useVehicleTypes, VehicleType } from '~/@store/vehicleTypes';

import { showVehicleTypeCreateDialog, VehicleTypeForm } from './VehicleTypesForms';
import { VehicleTypesList } from './VehicleTypesList';

export const VehicleTypesPage: VFC = () => {
    const { listScroll } = useStyles();

    const listQuery = useVehicleTypes();

    const openCreateVehicleTypeForm = () => {
        const ignoredPromise = showVehicleTypeCreateDialog();
    };

    const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState<string | null>(null);
    const closeEditForm = () => setSelectedVehicleTypeId(null);

    const { canCreate } = useVehicleTypePermissions();

    return (
        <QueryStateHandler query={listQuery} dataGetter={query => query.data?.vehicleTypes}>
            {(data: VehicleType[]) => {
                const selectedItem = data.find(vt => vt.id === selectedVehicleTypeId);

                return (
                    <PageLight>
                        <PageLight.Header
                            icon={<TruckIcon />}
                            title={<LocalizableText code={'Admin.vehicleTypes'} />}
                            rightActions={
                                <ActionButton onClick={openCreateVehicleTypeForm} disabled={!canCreate}>
                                    <LocalizableText code={'create'} />
                                </ActionButton>
                            }
                        />
                        <PageLight.Content>
                            <BiSideLayout>
                                <BiSideLayout.LeftSideContent className={listScroll}>
                                    <VehicleTypesList
                                        data={data}
                                        selectItemId={setSelectedVehicleTypeId}
                                        selectedItemId={selectedVehicleTypeId}
                                    />
                                </BiSideLayout.LeftSideContent>
                                <BiSideLayout.RightSideContent>
                                    {selectedItem ? (
                                        <VehicleTypeForm
                                            key={selectedItem.id}
                                            vehicleType={selectedItem}
                                            onClose={closeEditForm}
                                        />
                                    ) : null}
                                </BiSideLayout.RightSideContent>
                            </BiSideLayout>
                        </PageLight.Content>
                    </PageLight>
                );
            }}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles({
    listScroll: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 170px)',
        paddingBottom: '8px',
    },
});
