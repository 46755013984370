import { endOfDay, format, formatDistance, startOfDay } from 'date-fns';

import i18n from '~/i18n';

import { FilterRange } from '../../../common/types';
import { DateLike, parseDateFrom } from '../../../common/utils';

export * from '../../../common/utils/date';

const formatDateByPattern = (value: DateLike, pattern: string): string => {
    const date = parseDateFrom(value);
    if (!date) return i18n.NA;

    return format(date, pattern);
};

export const formatDate = (value: DateLike): string => {
    return formatDateByPattern(value, i18n.dateFormat);
};

export const formatTime = (value: DateLike): string => {
    return formatDateByPattern(value, 'pp');
};

export const formatTimeShort = (value: DateLike): string => {
    return formatDateByPattern(value, 'HH:mm');
};

export const formatDateTimeShort = (value: DateLike): string => {
    if (!value) return i18n.NA;

    return `${formatDate(value)} ${formatDateByPattern(value, 'p')}`;
};

export const formatDateTime = (value: DateLike): string => {
    if (!value) return i18n.NA;

    return formatDateByPattern(value, 'PPPPp');
};

export const serializeDateRange = ([from, to]: FilterRange<Date>): FilterRange<string> => {
    return [from ? startOfDay(from).toISOString() : null, to ? endOfDay(to).toISOString() : null];
};

export const formatFromNow = (value: DateLike): string => {
    const date = parseDateFrom(value);
    if (!date) return '';

    return formatDistance(new Date(), date, { includeSeconds: true });
};
