import { Grid, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';

import { DeleteButton, showDeleteConfirmationDialog } from '~/@components/DeleteButton';
import { SimpleDialog } from '~/@components/Dialog';
import { FormCardButtons, FormCardContainer } from '~/@components/FormCard';
import { FormikTextField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { showCustomDialog } from '~/@store/common';
import {
    ISubstance,
    ISubstanceChild,
    SubstanceCreateFunc,
    useSubstanceCreate,
    useSubstanceDelete,
    useSubstancePermissions,
    useSubstanceUpdate,
} from '~/@store/substances';
import { ContaminationType } from '~/graphql';
import i18n from '~/i18n';

import { SubstanceEditChildrenList } from './SubstanceEditChildrenList';
import { getSubstanceEditFormikConfig } from './SubstanceEditFormik';
import { SubstanceGroupBySelect } from './SubstanceGroupBySelect';
import { SubstanceTypeField } from './SubstanceTypeField';

type Props = {
    substance: ISubstance | ISubstanceChild | null;
    closeForm: () => void;
    parentId?: string | null;
    contaminationType?: ContaminationType | null;
};

export const SubstanceEditForm = ({ substance, parentId, contaminationType, closeForm }: Props) => {
    const { buttonsContainer } = useStyles();

    const [createSubstance, isCreating] = useSubstanceCreate();
    const [updateSubstance, isUpdating] = useSubstanceUpdate();
    const [deleteSubstance, isDeleting] = useSubstanceDelete();

    const createSubstanceAndClose = useCallback<SubstanceCreateFunc>(
        input => createSubstance(input).then(closeForm),
        [createSubstance, closeForm]
    );

    const handleCreateChild = useCallback(() => {
        if (!substance) return;

        const ignoredPromise = showCustomDialog({
            render: closeDialog => (
                <SimpleDialog onClose={() => closeDialog(false)}>
                    <SubstanceEditForm substance={null} parentId={substance.id} closeForm={() => closeDialog(false)} />
                </SimpleDialog>
            ),
        });
    }, [substance]);

    const handleRemove = useCallback(
        async (substance: ISubstance | ISubstanceChild) => {
            if (!substance) return;
            const confirmed = await showDeleteConfirmationDialog(
                <LocalizableText code={'Substances.confirmDeleteSubstance'} />,
                substance.name
            );

            if (!confirmed) return;
            await deleteSubstance(substance.id);
        },
        [deleteSubstance]
    );

    const formik = useFormik(
        getSubstanceEditFormikConfig(
            substance,
            parentId,
            contaminationType,
            substance ? updateSubstance : createSubstanceAndClose
        )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => formik.resetForm(), [substance]);

    const isParent = !formik.values.parentId;

    const title = (
        <LocalizableText
            code={
                substance
                    ? isParent
                        ? 'Substances.editSubstance'
                        : 'Substances.editSubstanceChild'
                    : isParent
                    ? 'Substances.createSubstance'
                    : 'Substances.createSubstanceChild'
            }
        />
    );

    const { canCreate, canUpdate, canDelete } = useSubstancePermissions();

    const canEdit = substance ? canUpdate : canCreate;

    return (
        <FormCardContainer formik={formik} showProgress={isCreating || isUpdating || isDeleting}>
            <Grid container justify="space-between" alignItems="center">
                <Text variant="TitleBold">{title}</Text>
                {substance && <DeleteButton onClick={() => handleRemove(substance)} disabled={!canDelete} />}
            </Grid>
            <FormikTextField
                label={<LocalizableText code={'Substances.substanceName'} />}
                name="name"
                formik={formik}
                disabled={!canEdit}
            />
            {isParent && substance && <SubstanceGroupBySelect formik={formik} disabled={!canEdit} />}
            {isParent && <SubstanceTypeField formik={formik} disabled={!canEdit} />}
            {substance && substance.__typename === 'Substance' && (
                <SubstanceEditChildrenList
                    substance={substance}
                    handleCreate={handleCreateChild}
                    handleRemove={handleRemove}
                />
            )}
            <FormCardButtons
                formik={formik}
                onCancel={closeForm}
                submitLabel={substance ? i18n.save : i18n.create}
                submitDisabled={!canEdit}
                className={buttonsContainer}
            />
        </FormCardContainer>
    );
};

const useStyles = makeStyles(theme => ({
    buttonsContainer: {
        paddingTop: theme.spacing(3),
    },
}));
