import { AbilitySubjects } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { ICompanyCalculatedSubject, ICompanySubject } from './companyTypes';

export const getCompanySubject = (user: IAbilityUser, subjects?: ICompanySubject[]): ICompanyCalculatedSubject => {
    const relationRole: ICompanyCalculatedSubject['relationRole'] = [user.role!];
    const subSubject = subjects || [];

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.COMPANY,
        relationRole,
        subSubject,
    };
};
