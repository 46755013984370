import { makeStyles } from '@material-ui/core';
import React from 'react';

import { Grid } from '~/@components/Grid';
import { SubstanceEditStore } from '~/@store/substances';
import { SubstancesEditPanel } from '~/@views/AdminView/common/SubstancesEditDialog/SubstancesEditPanel';

type Props = {
    editStore: SubstanceEditStore;
};

export const SubstancesEditForm = ({ editStore }: Props) => {
    const { root } = useStyles();
    const { SOLID, LEACHING } = editStore;

    if (!SOLID || !LEACHING) return null;

    return (
        <Grid container spacing={5} className={root}>
            <Grid item xs={6}>
                <SubstancesEditPanel store={SOLID} />
            </Grid>
            <Grid item xs={6}>
                <SubstancesEditPanel store={LEACHING} />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        minHeight: 'calc(100vh - 128px)',
    },
}));
