import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { setHours } from 'date-fns';
import React from 'react';

import { useDisable } from '~/@components/@hooks';
import { CalendarIcon } from '~/@components/Icon';
import i18n from '~/i18n';

export type DatePickerProps = KeyboardDatePickerProps;

export const DatePicker = ({ maxDate, minDate, disabled, onChange, ...restProps }: DatePickerProps) => (
    <KeyboardDatePicker
        keyboardIcon={<CalendarIcon fontSize="small" />}
        inputVariant="outlined"
        format={i18n.dateFormat}
        maxDate={maxDate || undefined}
        minDate={minDate || undefined}
        disabled={useDisable() || disabled}
        onChange={raw => onChange(raw ? setHours(raw, 12) : raw)}
        {...restProps}
    />
);
