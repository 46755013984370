import { LinearProgress } from '@material-ui/core';
import React, { useCallback } from 'react';

import { IComment } from '~/@store/comments';
import { useAddCompanyCommentMutation } from '~/@store/companies';
import { CommentsBlock } from '~/@views/AdminView/common/CommentsBlock';

type CompanyCommentsViewProps = {
    className?: string;
    comments: IComment[];
    companyId: string;
};

export const CompanyCommentsView = ({ className, comments, companyId }: CompanyCommentsViewProps) => {
    const [addCompanyCommentMutation, isAdding] = useAddCompanyCommentMutation();
    const onNewComment = useCallback(
        (text: string) => addCompanyCommentMutation(companyId, text),
        [companyId, addCompanyCommentMutation]
    );

    return (
        <div className={className}>
            {isAdding && <LinearProgress />}
            <CommentsBlock readOnly={isAdding} comments={comments} onNewComment={onNewComment} />
        </div>
    );
};
