import { UnitOfMeasure } from '@common/enums';
import { formatAmount } from '@common/utils/formaters';
import { Grid, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback } from 'react';

import { useProgressState } from '~/@components/@hooks';
import { Button } from '~/@components/Button';
import { Divider } from '~/@components/Divider';
import { LocalizableText } from '~/@components/LocalizableText';
import { Text } from '~/@components/Text';
import { COLOR } from '~/@sochi-components/@theme';
import { Output } from '~/@sochi-components/Icons';
import { InboundIcon } from '~/@sochi-components/Icons';
import { updateDeliveryLineBatch } from '~/@user-store/weightReceipts/weightReceiptsActions';
import { ISimpleBatch } from '~/@views/UserView/LandfillPage/common/LandfillBatchSelect';
import { IBatch } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchForm';
import { BatchDateAmount } from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/BatchDateAmount';
import {
    IBatchDeliveryIncomingLine,
    IBatchDeliveryOutgoingLine,
    Kind,
} from '~/@views/UserView/LandfillPage/LandfillBatches/LandfillBatchLogPage/landfillBatchLog.types';
import { showSelectBatchDialog } from '~/@views/UserView/LandfillPage/LandfillFollowUp/LandfillWeightReceipts/BatchAssignmentField';
import { LandfillQuery_landfill } from '~/graphql';
import i18n from '~/i18n';
import { showConfirmDialog } from '~/services/dialog';
import { formatDate } from '~/utils/date';

type Props = {
    landfill: LandfillQuery_landfill;
    item: IBatchDeliveryIncomingLine | IBatchDeliveryOutgoingLine;
    disabled?: boolean;
};

export const BatchDeliveryLineCard: FC<Props> = ({ item, landfill, disabled = false }) => {
    const { root, header, card, distributedRow, contentGroup, grayText, contentGroupRightAlign } = useStyles();

    const [isLoading, update] = useProgressState(updateDeliveryLineBatch);

    const onSave = useCallback(
        async (newBatch: ISimpleBatch | null, batch: IBatch) => {
            if (!newBatch) {
                const answer = await showConfirmDialog({
                    title: <LocalizableText code={'LandfillPage.batchLogCard.detachTitle'} />,
                    message: <LocalizableText code={'LandfillPage.batchLogCard.detachMessage'} />,
                });

                if (!answer) return;
            }

            const ignored = update(landfill.id, item.id, newBatch?.id || null, batch.id);
        },
        [landfill, item, update]
    );

    return (
        <div className={root}>
            {item.displayRemain && (
                <div className={header}>
                    <BatchDateAmount item={item} />
                </div>
            )}
            <div className={card}>
                <div className={distributedRow}>
                    <div className={contentGroup}>
                        {item.kind === Kind.LINE_INCOMING ? (
                            <InboundIcon color="disabled" />
                        ) : (
                            <Output color="disabled" />
                        )}
                        <Text variant="BodyBold" color="primary">
                            <LocalizableText code={`LandfillPage.batchLogCard.${item.kind}`} />
                        </Text>
                    </div>
                    <div className={contentGroup}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() =>
                                showSelectBatchDialog(landfill, newBatch => onSave(newBatch, item.batch), item.batch)
                            }
                            disabled={disabled}>
                            {i18n.edit}
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => onSave(null, item.batch)}
                            size="small"
                            disabled={disabled}>
                            <LocalizableText code={'LandfillPage.batchLogCard.detach'} />
                        </Button>
                    </div>
                </div>
                <Grid container spacing={2}>
                    <Grid item lg={3} xs={4}>
                        <Text variant="BodyRegular">
                            <LocalizableText
                                code={`${
                                    item.kind === Kind.LINE_OUTGOING
                                        ? 'LandfillPage.batchLogCard.fromBatch'
                                        : 'LandfillPage.batchLogCard.fromProject'
                                }`}
                            />
                        </Text>
                    </Grid>
                    <Grid item lg={9} xs={8}>
                        {item.kind === Kind.LINE_OUTGOING ? (
                            <Text variant="BodyRegular">{item.batch.name}</Text>
                        ) : (
                            <Text variant="BodyRegular">{item.projectName}</Text>
                        )}
                    </Grid>
                    <Grid item lg={3} xs={4}>
                        <Text variant="BodyRegular">
                            <LocalizableText
                                code={`${
                                    item.kind === Kind.LINE_OUTGOING
                                        ? 'LandfillPage.batchLogCard.toProject'
                                        : 'LandfillPage.batchLogCard.toBatch'
                                }`}
                            />
                        </Text>
                    </Grid>
                    <Grid item lg={9} xs={8}>
                        {item.kind === Kind.LINE_OUTGOING ? (
                            <Text variant="BodyRegular">{item.projectName}</Text>
                        ) : (
                            <Text variant="BodyRegular">{item.batch.name}</Text>
                        )}
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item lg={3} xs={4}>
                        <Text variant="BodyRegular" className={grayText}>
                            {formatDate(item.date)}
                        </Text>
                    </Grid>
                    <Grid item lg={4} xs={4}>
                        <Text variant="BodyBold">{`${item.signSymbol}${formatAmount(
                            item.weight,
                            UnitOfMeasure.TONNS
                        )}`}</Text>
                    </Grid>
                    <Grid item lg={5} xs={4}>
                        <div className={contentGroupRightAlign}>
                            <Text variant="BodyRegular" className={grayText}>
                                <LocalizableText code={'LandfillPage.batchLogCard.batchAmount'} />
                            </Text>
                            <Text variant="BodyBold">{formatAmount(item.amount, UnitOfMeasure.TONNS)}</Text>
                        </div>
                    </Grid>
                </Grid>
                {isLoading && <LinearProgress />}
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        width: '100%',
    },
    header: {
        display: 'flex',
        justifyContent: 'end',
    },
    card: {
        backgroundColor: COLOR.white,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: '12px',
    },
    distributedRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contentGroup: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'center',
    },
    contentGroupRightAlign: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'end',
    },
    grayText: {
        color: COLOR.grayDarker,
    },
}));
