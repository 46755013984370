import { Button } from '@material-ui/core';
import React from 'react';

import { LocalizableText } from '~/@components/LocalizableText';
import { showConfirmDialog } from '~/@store/common';
import { useRemoveAllLocksMutation } from '~/@store/locks';

export const LocksTab = () => {
    const removeAllLocks = useRemoveAllLocksMutation();

    const handleClick = () =>
        showConfirmDialog({
            title: <LocalizableText code={'lock.removeAllLocks'} />,
            render: () => <LocalizableText code={'lock.allLockRemoveConfirm'} />,
        }).then(confirmed => {
            if (confirmed) return removeAllLocks();
        });

    return (
        <Button variant="contained" color="primary" onClick={handleClick}>
            <LocalizableText code={'lock.removeAllLocks'} />
        </Button>
    );
};
