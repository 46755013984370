import { QueryResult } from '@apollo/client';
import { isValidGLN } from '@common/validations/common.validation';
import { CircularProgress } from '@material-ui/core';
import { SettingsOutlined } from '@material-ui/icons';
import { filter } from 'graphql-anywhere';
import React, { ReactNode, useState } from 'react';

import { makeStyles } from '~/@components/@theme';
import { COLORS } from '~/@components/@theme/colors';
import { ActionButton } from '~/@components/ActionButton';
import { BiSideLayout } from '~/@components/BiSideLayout';
import { CustomTabs, ICustomTabConfig } from '~/@components/CustomTabs';
import { LocalizableText } from '~/@components/LocalizableText';
import type { ISettings } from '~/@store/settings';
import { SettingsFragment, useSettingsPermissions, useSettingsUpdateMutation } from '~/@store/settings';
import { SettingsQuery } from '~/graphql';
import i18n from '~/i18n';
import { globalMessage } from '~/services/message';

import { APIConfigurationFields } from './API';
import { CompanyConfigurationFields } from './CompanyConfiguration';
import { CustomScripts } from './CustomScripts';
import { addNewScript } from './CustomScripts/CustomScriptForm';
import { Database } from './Database';
import { TransportConfigurationFields } from './TransportConfiguration';

type SettingsProps = {
    query: QueryResult<SettingsQuery, {}>;
    queryData: ISettings;
};

const wrapToLayout = (component: ReactNode) => (
    <BiSideLayout>
        <BiSideLayout.LeftSideContent>{component}</BiSideLayout.LeftSideContent>
    </BiSideLayout>
);

export const SettingsTabs = ({ queryData, query }: SettingsProps) => {
    const [settings, setSettings] = useState(queryData);

    const [saveSettings, isSaving] = useSettingsUpdateMutation();

    const { circularProgress, loaderLayout } = useStyles();

    const validateSettings = (settings: ISettings): string | null => {
        if (
            settings.featuresFlags.captchaEnabled &&
            (!settings.keys.captchaSiteKey || !settings.keys.captchaSecretKey)
        ) {
            return i18n.settingsPage.validation.captchaKeysShouldBeFilled;
        }

        if (
            settings.featuresFlags.chatGptEnabled &&
            (!settings.keys.chatGptApiKey || !settings.keys.chatGptAssistantId)
        ) {
            return i18n.settingsPage.validation.chatGptKeysShouldBeFilled;
        }

        if (settings.featuresFlags.peppolEnabled) {
            if (
                !settings.keys.tickstarClientId ||
                !settings.keys.tickstarClientSecret ||
                !settings.keys.tickstarToken ||
                !settings.keys.pinpointerGLN
            ) {
                return i18n.settingsPage.validation.peppolKeysShouldBeFilled;
            }

            if (settings.keys.pinpointerGLN && !isValidGLN(settings.keys.pinpointerGLN)) {
                return i18n.GLNMustBeInCorrectFormat;
            }
        }

        if (settings.featuresFlags.whatsappEnabled) {
            if (!settings.keys.mytapiToken || !settings.keys.mytapiProductId) {
                return i18n.settingsPage.validation.mytapiKeysShouldBeFilled;
            }
        }

        return null;
    };

    const onSubmit = () => {
        const error = validateSettings(settings);
        if (error) return globalMessage.error(error);

        saveSettings(filter(SettingsFragment, settings))
            .then(() => globalMessage.success(i18n.configSaved))
            .then(() => query.refetch());
    };

    const { canEdit } = useSettingsPermissions();

    const saveSettingsButton = () => (
        <ActionButton
            onClick={onSubmit}
            disabled={isSaving || !canEdit || JSON.stringify(queryData) === JSON.stringify(settings)}>
            {i18n.save}
        </ActionButton>
    );

    const tabs: Array<ICustomTabConfig> = [
        {
            label: <LocalizableText code={'settingsPage.navigationTabs.companyTab'} />,
            render: () => wrapToLayout(<CompanyConfigurationFields settings={settings} setSettings={setSettings} />),
            rightActionButton: saveSettingsButton,
        },
        {
            label: <LocalizableText code={'settingsPage.navigationTabs.transportTab'} />,
            render: () => wrapToLayout(<TransportConfigurationFields settings={settings} setSettings={setSettings} />),
            rightActionButton: saveSettingsButton,
        },
        {
            label: <LocalizableText code={'settingsPage.navigationTabs.apiTab'} />,
            render: () => wrapToLayout(<APIConfigurationFields settings={settings} setSettings={setSettings} />),
            rightActionButton: saveSettingsButton,
        },
        {
            label: <LocalizableText code={'settingsPage.customScripts.customScripts'} />,
            render: () => <CustomScripts />,
            rightActionButton: () => (
                <ActionButton disabled={!canEdit} onClick={addNewScript}>
                    {i18n.add}
                </ActionButton>
            ),
        },
        {
            label: <LocalizableText code={'settingsPage.database.database'} />,
            subTitle: <LocalizableText code={'settingsPage.database.databaseMaintenance'} />,
            render: () => <Database />,
        },
    ];

    return (
        <>
            {isSaving ? (
                <div className={loaderLayout}>
                    <CircularProgress className={circularProgress} />
                </div>
            ) : null}

            <CustomTabs
                tabs={tabs}
                headerIcon={<SettingsOutlined />}
                title={<LocalizableText code={'Admin.settings'} />}
                isDisabled={isSaving}
            />
        </>
    );
};

const useStyles = makeStyles({
    circularProgress: {
        position: 'relative',
        top: '40%',
        left: '50%',
        zIndex: 101,
    },

    loaderLayout: {
        position: 'absolute',
        width: '100%',
        height: 'calc(100vh - 60px)',
        backgroundColor: COLORS.layoutDarker,
        zIndex: 100,
    },
});
