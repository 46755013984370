import { UserContextRole } from '@common/enums';
import { makeMap2 } from '@common/utils';
import React, { useMemo } from 'react';

import { EmailFormatter, NullableFormatter, PhoneFormatter } from '~/@components/Formatters';
import { LocalizableText } from '~/@components/LocalizableText';
import { ITableColumn, Table } from '~/@components/Table';
import { IDetailedProject } from '~/@store/projects';
import { UserContextRoleChip } from '~/@views/AdminView/UserDetailPage/UserContextRoleChip';
import { ProjectQuery_project_users } from '~/graphql';

import { ProjectUserDeleteCell } from './ProjectUserDeleteCell';

type IProjectUser = ProjectQuery_project_users & { contextRole: UserContextRole };

type Props = {
    project: IDetailedProject;
};

export const ProjectDetailUsers = ({ project }: Props) => {
    const users: IProjectUser[] = useMemo(() => {
        const userRoleMap = makeMap2(
            project.userLinks,
            l => l.userId,
            l => l.role
        );

        return project.users?.map(u => ({ ...u, contextRole: userRoleMap.get(u.id) || UserContextRole.UNKNOWN })) || [];
    }, [project]);

    const columns = useMemo<ITableColumn<IProjectUser>[]>(
        () => [
            {
                title: <LocalizableText code={'firstName'} />,
                render: u => u.name,
                name: 'firstName',
            },
            {
                title: <LocalizableText code={'surName'} />,
                render: u => <NullableFormatter>{u.surname}</NullableFormatter>,
                name: 'surName',
            },
            {
                title: <LocalizableText code={'email'} />,
                render: u => <EmailFormatter>{u.email}</EmailFormatter>,
                name: 'email',
            },
            {
                title: <LocalizableText code={'phoneNumber'} />,
                render: u => <PhoneFormatter>{u.phoneNumber}</PhoneFormatter>,
                name: 'phoneNumber',
            },
            {
                title: <LocalizableText code={'role'} />,
                render: u => <UserContextRoleChip role={u.contextRole} />,
                name: 'role',
            },
            {
                title: '',
                render: u => <ProjectUserDeleteCell projectId={project.id} user={u} />,
                name: 'delete',
            },
        ],
        [project]
    );

    return (
        <Table<IProjectUser>
            columns={columns}
            items={users}
            keyGetter={p => p.id}
            emptyListMessage={<LocalizableText code={'UserEditPage.noDataFound'} />}
            name="ProjectDetailUsersTable"
        />
    );
};
