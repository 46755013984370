import { makeStyles } from '@material-ui/core';
import React, { useState, VFC } from 'react';

import { ActionButton } from '~/@components/ActionButton';
import { BiSideLayout } from '~/@components/BiSideLayout';
import { RecycleIcon } from '~/@components/Icon';
import { LocalizableText } from '~/@components/LocalizableText';
import { PageLight } from '~/@components/PageLight';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { IDumpType, useDumpTypePermissions, useDumpTypesList } from '~/@store/dumpTypes';

import { DumpTypeEditBlock, showDumpTypeCreateDialog } from './DumpTypeCreateEditForms';
import { DumpTypesList } from './DumpTypesList';

export const DumpTypesPage: VFC = () => {
    const { dumpTypeListScroll } = useStyles();

    const listQuery = useDumpTypesList();

    const openCreateDumpTypeForm = () => {
        const ignoredPromise = showDumpTypeCreateDialog();
    };

    const [selectedDumpTypeId, setSelectedDumpTypeId] = useState<string | null>(null);
    const closeEditForm = () => setSelectedDumpTypeId(null);

    const { canCreate } = useDumpTypePermissions();

    return (
        <QueryStateHandler query={listQuery} dataGetter={query => query.data?.dumpTypes}>
            {(data: IDumpType[]) => (
                <PageLight>
                    <PageLight.Header
                        icon={<RecycleIcon />}
                        title={<LocalizableText code={'Admin.dumpTypes'} />}
                        rightActions={
                            <ActionButton onClick={openCreateDumpTypeForm} disabled={!canCreate}>
                                <LocalizableText code={'create'} />
                            </ActionButton>
                        }
                    />
                    <PageLight.Content subTitle={<LocalizableText code={'DumpTypeTable.title'} />}>
                        <BiSideLayout>
                            <BiSideLayout.LeftSideContent className={dumpTypeListScroll}>
                                <DumpTypesList
                                    data={data}
                                    selectItem={setSelectedDumpTypeId}
                                    selectedItemId={selectedDumpTypeId}
                                />
                            </BiSideLayout.LeftSideContent>
                            <BiSideLayout.RightSideContent>
                                {selectedDumpTypeId ? (
                                    <DumpTypeEditBlock
                                        key={selectedDumpTypeId}
                                        dumpTypeId={selectedDumpTypeId}
                                        onClose={closeEditForm}
                                    />
                                ) : null}
                            </BiSideLayout.RightSideContent>
                        </BiSideLayout>
                    </PageLight.Content>
                </PageLight>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles({
    dumpTypeListScroll: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 170px)',
        paddingBottom: '8px',
    },
});
