import { makeStyles } from '@material-ui/core';
import React, { VFC } from 'react';

import { useSyncEffect } from '~/@components/@hooks';
import { LocalizableText } from '~/@components/LocalizableText';
import { StorePagination } from '~/@components/Pagination';
import { QueryStateHandler } from '~/@components/QueryStateHandler';
import { Table } from '~/@components/Table';
import { IUserProjectInfo, IUserProjectsPaged, userProjectsStore, useUserProjectsPaged } from '~/@store/users';

import { useUserDetailContext } from '../UserDetailContext';
import { UserProjectsFilterSummary } from './UserProjectsFilterSummary';
import { useUserProjectsColumns } from './useUserProjectsColumns';

export const UserProjectsTab: VFC = () => {
    const { table, tableFooter } = useStyles();
    const user = useUserDetailContext();
    useSyncEffect(() => userProjectsStore.setUserId(user.id), [user.id]);
    const columns = useUserProjectsColumns(user);
    const query = useUserProjectsPaged();

    return (
        <QueryStateHandler
            query={query}
            dataGetter={query => query.data?.projectsPaged || query.previousData?.projectsPaged}>
            {(data: IUserProjectsPaged) => (
                <>
                    <Table<IUserProjectInfo>
                        columns={columns}
                        items={data.edges.map(e => e.node)}
                        keyGetter={p => p.id}
                        emptyListMessage={<LocalizableText code={'UserEditPage.noDataFound'} />}
                        className={table}
                        name="UserProjectInfoTable"
                    />
                    <div className={tableFooter}>
                        <UserProjectsFilterSummary />
                        <StorePagination store={userProjectsStore.pagination} />
                    </div>
                </>
            )}
        </QueryStateHandler>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        '& .MuiTableContainer-root': {
            maxHeight: 'calc(100vh - 180px)',
        },
        '& .MuiTable-root': {
            width: '100%',
            minWidth: '100%',
        },
        height: 'calc(100% - 64px)',
    },
    tableFooter: {
        height: 48,
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));
