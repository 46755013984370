import './NotFoundPage.scss';

import { routes } from '@common/routes';
import React from 'react';

import { useFunctionalBem } from '~/@sochi-components/@bem';
import { useUserAbilities } from '~/contexts';

import i18n from '../../../i18n';

const ns = i18n.NotFoundPage;

export const NotFoundPage = () => {
    const { className, element } = useFunctionalBem(NotFoundPage);
    const { user } = useUserAbilities();

    return (
        <div className={className}>
            <div className={element('notfound')}>
                <div className={element('notfound-404')}>
                    <h1>{ns.title}</h1>
                </div>

                <h2>{ns.subTitle}</h2>
                <p>{ns.description}</p>
                <a className={element('link')} href={user.startPage || routes.root}>
                    {ns.backToHome}
                </a>
            </div>
        </div>
    );
};
