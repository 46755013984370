import { Link } from '@material-ui/core';
import React from 'react';

type Props = { children: string | null | void };

export const EmailFormatter = ({ children: email }: Props) => {
    return email ? (
        <Link href={`mailto:${email}`} color="primary" target="_blank">
            {email}
        </Link>
    ) : null;
};
