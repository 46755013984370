import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { FormikSelectField } from '~/@components/FormikFields';
import { LocalizableText } from '~/@components/LocalizableText';
import { IBeastMaterial } from '~/@store/beastMaterials';
import { BeastMaterialSelectQuery } from '~/@views/AdminView/common/BeastMaterialSelectQuery';

import { useDumpLoadFormikContext } from '../DumpLoadEditFormik';

type Props = { disabled?: boolean };

export const DumpLoadEditBeastMaterialSelect = ({ disabled }: Props) => {
    const formik = useDumpLoadFormikContext();

    const { item } = useStyles();

    const getValue = (material: IBeastMaterial) => `${material.beastId} - ${material.name}`;

    return (
        <BeastMaterialSelectQuery>
            {(materials: IBeastMaterial[]) => {
                return (
                    <FormikSelectField
                        label={<LocalizableText code={'DumpLoadCard.beastMaterial'} />}
                        name={'beastMaterial'}
                        formik={formik}
                        items={materials}
                        renderValue={getValue}
                        keyGetter={(material: IBeastMaterial) => material.id}
                        addEmptyOption={false}
                        disabled={disabled}
                        valueClassName={item}
                        valueCaption={getValue}
                    />
                );
            }}
        </BeastMaterialSelectQuery>
    );
};

const useStyles = makeStyles(() => ({
    item: {
        maxWidth: 600,
    },
}));
