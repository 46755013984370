import { UserContextRole, UserRole } from '@common/enums';

import { COLORS } from '~/@components/@theme';
import { UserStatus } from '~/utils/user';

export const DEFAULT_USERS_SORT = {
    field: 'name',
    direction: 1,
};

export const DEFAULT_USER_RELATIONS_SORT = {
    field: 'name',
    direction: 1,
};

export const USER_STATUSES = [UserStatus.Blocked, UserStatus.Enabled, UserStatus.EmailConfirmed, UserStatus.Registered];

export const USER_ROLES = [
    UserRole.ADMIN,
    UserRole.EMPLOYEE,
    UserRole.EXTERNAL_RECEIVER,
    UserRole.EXCAVATOR_OPERATOR,
    UserRole.DRIVER,
];

export const USER_CONTEXT_ROLES: Map<UserContextRole, COLORS> = new Map([
    [UserContextRole.OWNER, COLORS.blueLight],
    [UserContextRole.GUEST, COLORS.grayLight],
    [UserContextRole.PROJECT_USER, COLORS.mintGreen],
    [UserContextRole.RECEIVER, COLORS.mintGreen],
    [UserContextRole.PROJECT_EXCAVATOR_OPERATOR, COLORS.orange],
    [UserContextRole.PROJECT_DRIVER, COLORS.turquoise],
    [UserContextRole.UNKNOWN, COLORS.white],
]);
