import React from 'react';

import { CardListItem } from '~/@components/CardListItem';
import { Grid } from '~/@components/Grid';
import { Text } from '~/@components/Text';
import { IBeastMaterial } from '~/@store/beastMaterials';

type Props = {
    material: IBeastMaterial;
    isActive: boolean;
    onSelect: () => void;
};

export const BeastMaterialCard = ({ material, isActive, onSelect }: Props) => {
    return (
        <CardListItem isActive={isActive} onClick={onSelect}>
            <Grid container>
                <Grid item xs={10}>
                    <Text>{material.name}</Text>
                </Grid>
                <Grid item xs={2}>
                    <Text variant="SmallText">{material.beastId}</Text>
                </Grid>
            </Grid>
        </CardListItem>
    );
};
