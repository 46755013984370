import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { DumpLoadUpdateMutation, DumpLoadUpdateMutationVariables, ProjectLoadInput } from '~/graphql';
import { handleApolloError } from '~/utils';

import * as queries from '../dumpLoads.queries';
import type { IDumpLoad } from './usePagedDumpLoads';

export type UpdateDumpLoadFunc = (input: ProjectLoadInput) => Promise<unknown>;

export const useDumpLoadUpdateMutation = (dumpLoad: IDumpLoad): [UpdateDumpLoadFunc, boolean] => {
    const [callMutation, mutationResult] = useMutation<DumpLoadUpdateMutation, DumpLoadUpdateMutationVariables>(
        queries.DumpLoadUpdateMutation
    );

    const updateDumpLoad = useCallback<UpdateDumpLoadFunc>(
        input => {
            const { id, project } = dumpLoad;

            if (!project) return Promise.reject(new Error('Cannot update dumpLoad without project'));

            return callMutation({
                variables: { projectId: project.id, input: { id, ver: project.ver, ...input } },
                refetchQueries: [
                    {
                        query: queries.AdminDetailedDumpLoadQuery,
                        variables: {
                            projectId: project.id,
                            dumpLoadId: dumpLoad.id,
                        },
                    },
                ],
                awaitRefetchQueries: true,
            }).catch(handleApolloError);
        },
        [dumpLoad, callMutation]
    );

    return [updateDumpLoad, mutationResult.loading];
};
