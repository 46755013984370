import { useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

import { useDebouncedObservable } from '~/@components/@hooks/use-debounced-observable';
import { TIMEOUT_ON_QUERY_DEBOUNCE } from '~/@store/common';
import {
    AdminProjectsPagedShortQuery as ProjectsPagedShortQueryType,
    AdminProjectsPagedShortQuery_projectsPaged,
    AdminProjectsPagedShortQuery_projectsPaged_edges_node,
    AdminProjectsPagedShortQueryVariables,
} from '~/graphql';

import { projectsStore } from '../project.store';
import { AdminProjectsPagedShortQuery } from '../projects.queries';

export type IProject = AdminProjectsPagedShortQuery_projectsPaged_edges_node;

export type IPagedProjects = AdminProjectsPagedShortQuery_projectsPaged;

export const usePagedProjects = (): QueryResult<ProjectsPagedShortQueryType, AdminProjectsPagedShortQueryVariables> => {
    const variables = useDebouncedObservable(() => projectsStore.projectsQueryVariables, TIMEOUT_ON_QUERY_DEBOUNCE);

    return useQuery<ProjectsPagedShortQueryType, AdminProjectsPagedShortQueryVariables>(AdminProjectsPagedShortQuery, {
        fetchPolicy: 'cache-and-network',
        variables,
        onCompleted: data => projectsStore.pagination.onQueryComplete(data.projectsPaged),
    });
};
