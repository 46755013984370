import { Grid } from '@material-ui/core';
import React from 'react';

import { Button } from '~/@components/Button';
import { LocalizableText } from '~/@components/LocalizableText';
import { canChangeDestination, useDumpLoadAbilities } from '~/@store/dumpLoads';

import {
    DumpLoadDestinationRow,
    DumpLoadLandfillSelectedDestination,
    DumpLoadSuggestedDestinationInfo,
} from './DumpLoadEditDestination';
import { DumpLoadEditMode, useDumpLoadFormikContext } from './DumpLoadEditFormik';

export const DumpLoadEditAmountAndTransportFields = () => {
    const formik = useDumpLoadFormikContext();
    const { dumpLoad } = formik.values;

    const { canUpdateDumpLoad, canUpdateFieldsAffectsDestination } = useDumpLoadAbilities(dumpLoad);
    const disabled = !canUpdateDumpLoad;
    const disabledFieldsAffectsDestination = !canUpdateFieldsAffectsDestination;

    const canEditDestination =
        canChangeDestination(dumpLoad) && !disabled && !disabledFieldsAffectsDestination && !formik.dirty;

    return (
        <Grid container spacing={2}>
            <Grid item xs={11}>
                {formik.values.mode === 'inbound' || !formik.values.matchWithPinpointer ? (
                    <DumpLoadSuggestedDestinationInfo />
                ) : null}
            </Grid>
            <Grid item xs={1}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => formik.setFieldValue('editMode', DumpLoadEditMode.DESTINATION)}
                    disabled={!canEditDestination}
                    fullWidth>
                    <LocalizableText code={'edit'} />
                </Button>
            </Grid>

            {dumpLoad.destinationInfo && (
                <Grid item xs={12}>
                    {formik.values.matchWithPinpointer ? (
                        <DumpLoadDestinationRow destination={dumpLoad.destinationInfo} />
                    ) : (
                        <DumpLoadLandfillSelectedDestination />
                    )}
                </Grid>
            )}
        </Grid>
    );
};
