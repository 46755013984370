import { FormikConfig } from 'formik';
import * as Yup from 'yup';

import { ILandfillStatus, ILandfillStatusInput } from '~/@store/landfillStatuses';
import i18n from '~/i18n';

export type ILandfillStatusFormValues = {
    name: string;
    icon: string;
};

type SaveFunc = (values: ILandfillStatusInput) => Promise<unknown>;

export const getLandfillStatusFormikConfig = (
    status: ILandfillStatus | null,
    saveFunc: SaveFunc,
    onSuccessSubmit: () => void
): FormikConfig<ILandfillStatusFormValues> => {
    return {
        initialValues: {
            name: status?.name || '',
            icon: status?.icon || 'icon0',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required(i18n.errorCodes.LANDFILL_STATUS_NAME_CANNOT_BE_EMPTY),
        }),
        onSubmit: async values => {
            await saveFunc(status ? { ...values, id: status.id } : values);
            onSuccessSubmit();
        },
    };
};
