import { AbilitySubjects, UserContextRole, UserRole } from '../../enums';
import { ABILITY_TYPE_FIELD, IAbilityUser } from '../abilities.types';
import { IAbilityProject, IProjectCalculatedSubject, IProjectRelationRole, IProjectSubject } from './projectTypes';

const isExternalReceiver = (user: IAbilityUser) => user.role === UserRole.EXTERNAL_RECEIVER;

const isManager = (user: IAbilityUser) => [UserRole.ADMIN, UserRole.EMPLOYEE].includes(user.role);

const isUserForProject = (user: IAbilityUser, project: IAbilityProject) =>
    isExternalReceiver(user) &&
    project.userLinks.some(l => l.userId === user.id && l.role === UserContextRole.PROJECT_USER);

const isOwnerForProject = (user: IAbilityUser, project: IAbilityProject) =>
    isManager(user) && user.id === project.ownerUserId;

const isGuestForProject = (user: IAbilityUser, project: IAbilityProject) =>
    isExternalReceiver(user) && project.userLinks.some(l => l.userId === user.id && l.role === UserContextRole.GUEST);

const isDriverForProject = (user: IAbilityUser, project: IAbilityProject) =>
    user.role === UserRole.DRIVER &&
    project.userLinks.some(l => l.userId === user.id && l.role === UserContextRole.PROJECT_DRIVER);

const isExcavatorOperatorForProject = (user: IAbilityUser, project: IAbilityProject) =>
    user.role === UserRole.EXCAVATOR_OPERATOR &&
    project.userLinks.some(l => l.userId === user.id && l.role === UserContextRole.PROJECT_EXCAVATOR_OPERATOR);

export const getProjectSubject = (
    user: IAbilityUser,
    project: IAbilityProject | null | undefined,
    subjects?: IProjectSubject[]
): IProjectCalculatedSubject => {
    const subSubject = subjects || [];
    const relationRole: IProjectRelationRole[] = [user.role!];

    if (project) {
        if (isUserForProject(user, project)) relationRole.push(UserContextRole.PROJECT_USER);

        if (isGuestForProject(user, project)) relationRole.push(UserContextRole.GUEST);

        if (isOwnerForProject(user, project)) relationRole.push(UserContextRole.OWNER);

        if (isExcavatorOperatorForProject(user, project)) relationRole.push(UserContextRole.PROJECT_EXCAVATOR_OPERATOR);

        if (isDriverForProject(user, project)) relationRole.push(UserContextRole.PROJECT_DRIVER);

        subSubject.push(project.status);
    }

    return {
        [ABILITY_TYPE_FIELD]: AbilitySubjects.PROJECT,
        relationRole,
        subSubject,
    };
};
